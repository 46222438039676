import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LinkModule } from "@lcs/link/link.module";
import { MobileFocusOverlayModule } from "@lcs/mobile-focus-overlay/mobile-focus-overlay.module";
import { DialogModule as PrimeDialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";

import { ConfirmationDialogComponent } from "./components/confirmation-dialog.component";
import { DialogComponent } from "./dialog.component";

@NgModule({
   imports: [
      CommonModule,
      ScrollingModule,
      FormsModule,
      ReactiveFormsModule,
      ValidationModule,
      PrimeDialogModule,
      TooltipModule,
      LinkModule,
      FormRegistrationModule,
      MobileFocusOverlayModule,
      MatProgressSpinnerModule,
   ],
   declarations: [DialogComponent, ConfirmationDialogComponent],
   exports: [DialogComponent, ConfirmationDialogComponent],
})
export class DialogModule {}
