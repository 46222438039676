import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { ChangeEmailAddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/change-emailaddress.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../../authentication/authentication.service";
import { LinkedAccountManagementComponent } from "../../linked-account-management/linked-account-management.component";
import { OWASessionService } from "../../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";
import { UpdateAccountInformationComponent } from "../../update-account-information/update-account-information.component";

@Component({
   selector: "owa-account-context-menu",
   templateUrl: "./account-context-menu.component.html",
   styleUrls: ["./account-context-menu.component.css"],
})
export class AccountContextMenuComponent implements OnInit, OnDestroy {
   public currentOwner: OwnerModel;

   public currentUser: WebUserModel;

   public result: ChangeEmailAddressModel;

   public model: OwnerModel;

   public personalInfoLabel: string = "Update Account Information";

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public sourceStep: BehaviorSubject<string> = new BehaviorSubject<string>(null);

   public menuItemClasses: string[] = ["account-context-menu-item"];

   public availableAccounts: Array<OWAWebUserAccountModel>;

   public currentOwnerProspect: OwnerProspectModel;

   isOwner: boolean = false;

   @Output() menuItemClick = new EventEmitter<void>();

   private unsubscribe = new Subject<void>();
   constructor(
      private authenticationService: AuthenticationService,
      private owaSessionService: OWASessionService,
      private dialogCreationService: DialogCreationService,
      private systemWebPreferencesSessionService: SystemWebPreferencesSessionService,
      private router: Router
   ) {
      this.currentOwner = new OwnerModel();
      this.currentOwnerProspect = new OwnerProspectModel();
      this.currentUser = new WebUserModel();
      this.availableAccounts = new Array<OWAWebUserAccountModel>();
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((owaSessionInfo) => {
         if (owaSessionInfo) {
            if (owaSessionInfo.CurrentWebUserAccount.AccountType == AccountType.Owner) {
               this.isOwner = true;
               this.currentOwner = owaSessionInfo.CurrentAccount;
            } else {
               this.currentOwner = new OwnerModel();
               this.isOwner = false;
               this.currentOwnerProspect = owaSessionInfo.CurrentOwnerProspectAccount;
            }
            this.currentUser = owaSessionInfo.CurrentUser;
            this.availableAccounts = owaSessionInfo.AvailableAccounts;
         }
      });
   }

   ngOnInit() {
      const owaSessionInfo = this.owaSessionService.currentOWASessionInfo;
      if (owaSessionInfo) {
         if (owaSessionInfo.CurrentWebUserAccount.AccountType == AccountType.Owner) {
            this.isOwner = true;
            this.currentOwner = owaSessionInfo.CurrentAccount;
         } else {
            this.currentOwnerProspect = owaSessionInfo.CurrentOwnerProspectAccount;
         }
         this.currentUser = owaSessionInfo.CurrentUser;
      }
      this.systemWebPreferencesSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               const pref = prefs.find((a) => a.Name === "AllowOwnerToChangePersonalInformationOWA");
               const canUpdatePersonalInformation = pref && pref.Value === "True";
               if (!canUpdatePersonalInformation) {
                  this.personalInfoLabel = "View Account Information";
               }
            } else {
               this.personalInfoLabel = "Update Account Information";
            }
         }
      );
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   logOut() {
      this.authenticationService.logOut();
      this.menuItemClick.emit();
   }

   openAccountInformationDialog() {
      this.menuItemClick.emit();

      const dialogConfig = new DialogConfigurationModel();
      dialogConfig.componentInstance = UpdateAccountInformationComponent;
      dialogConfig.title = "Account Information";
      // dialogConfig.width = "900"; //fixing a width causes flexing issues on mobile view
      this.dialogCreationService.open(dialogConfig, null);
   }

   openLinkedAccountManagementDialog() {
      this.menuItemClick.emit();

      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = LinkedAccountManagementComponent;
      dialogConfigModel.title = "Linked Account Management";
      dialogConfigModel.styleClass = "linked-management-dialog";
      this.dialogCreationService.open(dialogConfigModel, null);
   }

   openUserProfilePage() {
      this.router.navigate(["./userprofile"]);
      this.menuItemClick.emit();
   }
}
