export class SortableListItemModel {
   displayProperty: string;

   itemObject: any;

   isSelected: boolean;

   sortOrder: number;

   constructor(displayProperty: string, itemObject: any) {
      this.displayProperty = displayProperty;
      this.itemObject = itemObject;
   }
}
