<ng-container *ngIf="errors">
   <li
      *ngFor="let validationMessage of errors.validationMessages"
      class="error-message"
   >
      {{ displayName }} {{ validationMessage }}
   </li>
   <li
      *ngIf="errors.required || errors.notUnset"
      class="error-message"
   > {{ displayName }} is required. </li>
   <li
      *ngIf="errors.requiredTrue"
      class="error-message"
   > {{ displayName }} must be true. </li>
   <li
      *ngIf="errors.requiredIfDirty"
      class="error-message"
   >
      {{ displayName }} {{ errors.requiredIfDirty.errorMessage || "is required once modified" }}.
   </li>
   <li
      *ngIf="errors.minlength != null"
      class="error-message"
   >
      {{ displayName }} length {{ errors.minlength.actualLength }} is less than minimum of
      {{ errors.minlength.requiredLength }} characters.
   </li>
   <li
      *ngIf="errors.maxlength != null"
      class="error-message"
   >
      {{ displayName }} length {{ errors.maxlength.actualLength }} exceeds maximum of
      {{ errors.maxlength.requiredLength }} characters.
   </li>
   <li
      *ngIf="errors.digits != null"
      class="error-message"
   >
      {{ displayName }} must be {{ errors.digits.requiredDigits }} digits. Actual length:
      {{ errors.digits.actualDigits }}.
   </li>
   <li
      *ngIf="errors.pattern"
      class="error-message"
   > {{ displayName }} {{ errors.pattern.patternValidationMessage }} </li>
   <li
      *ngIf="errors.min != null"
      class="error-message"
   >
      {{ displayName }} must not be less than {{ errors.min.min }}.
   </li>
   <li
      *ngIf="errors.max != null"
      class="error-message"
   >
      {{ displayName }} must not be greater than {{ errors.max.max }}.
   </li>
   <li
      *ngIf="errors.dateRangeStart"
      class="error-message"
   > {{ displayName }} must not be greater than end date. </li>
   <li
      *ngIf="errors.dateRangeEnd"
      class="error-message"
   > {{ displayName }} must not be less than start date. </li>
   <li
      *ngIf="errors.numberRangeMin"
      class="error-message"
   > {{ displayName }} must not be greater than maximum. </li>
   <li
      *ngIf="errors.numberRangeMax"
      class="error-message"
   > {{ displayName }} must not be less than minimum. </li>
   <li
      *ngIf="errors.blacklist"
      class="error-message"
   >
      {{ displayName }} must not be any of the following: {{ errors.blacklist }}.
   </li>
   <li
      *ngIf="errors.whitelist"
      class="error-message"
   >
      {{ displayName }} must only be one of the following: {{ errors.whitelist }}.
   </li>
   <li
      *ngIf="errors.minDate"
      class="error-message"
   >
      {{ displayName }} must not be less than {{ errors.minDate | date: "MM/dd/yyyy" }}.
   </li>
   <li
      *ngIf="errors.maxDate"
      class="error-message"
   >
      {{ displayName }} must not be greater than {{ errors.maxDate | date: "MM/dd/yyyy" }}.
   </li>
   <li
      *ngIf="errors.range"
      class="error-message"
   >
      {{ displayName }} must be between {{ errors.range.start }} and {{ errors.range.end }}.
   </li>
   <li
      *ngIf="errors.maxFractionalDigits != null"
      class="error-message"
   >
      The maximum decimal digits allowed for {{ displayName }} is {{ errors.maxFractionalDigits }}.
   </li>
   <li
      *ngIf="errors.minNumberOccurrences != null"
      class="error-message"
   >
      {{ displayName }} must contain at least {{ errors.minNumberOccurrences }} number(s).
   </li>
   <li
      *ngIf="errors.minSpecialCharacterOccurrences != null"
      class="error-message"
   >
      {{ displayName }} must contain at least {{ errors.minSpecialCharacterOccurrences }} special character(s).
   </li>
   <li
      *ngIf="errors.requireMatch"
      class="error-message"
   > {{ displayName }} does not match. </li>
   <li
      *ngIf="errors.confirmNewPassword"
      class="error-message"
   > {{ displayName }} does not match with new password. </li>
   <li
      *ngIf="errors.time"
      class="error-message"
   > {{ displayName }} must be a valid time. </li>
   <li
      *ngIf="errors.phoneNumber"
      class="error-message"
   >
      {{ displayName }} must contain only numbers, parentheses, and dashes.
   </li>
   <li
      *ngIf="errors.accountNumber"
      class="error-message"
   > {{ displayName }} is invalid. </li>
   <li
      *ngIf="errors.emails"
      class="error-message"
   > {{ displayName }} contains an invalid email address. </li>
   <li
      *ngIf="errors.email"
      class="error-message"
   > {{ displayName }} must be a valid email address. </li>
   <li
      *ngIf="errors.routingNumber"
      class="error-message"
   > {{ displayName }} is invalid. </li>
   <li
      *ngIf="errors.NoLocationsSelected"
      class="error-message"
   > Please select one or more locations </li>
</ng-container>