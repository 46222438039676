<lcs-overlay-panel
   *ngIf="parentElement && control"
   [parentElement]="parentElement"
   [toggleOnClick]="true"
   [show]="showValidation"
   [showOnFocus]="showValidation"
   [hideOnBlur]="true"
   [hideOnOutsideClick]="true"
   [showOnClick]="true"
   [showIndependent]="true"
   [innerElementTags]="validationOverlayInnerElementTags"
>
   <lcs-validation-error-display-panel
      *ngIf="control.invalid"
      [displayName]="displayName"
      [errors]="$any(errors | async)"
   >
   </lcs-validation-error-display-panel>
</lcs-overlay-panel>