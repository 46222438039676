////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum PhoneNumberRelatedObjectTypes {
   NotSet = 0,
   Property = 3,
   Vendor = 6,
   Contact = 8,
   LoanContact = 9,
   User = 10,
   Utility = 26,
   WebUser = 116,
   OwnerProspect = 121,
}
