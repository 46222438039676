import { OwaAuthenticationModel } from "projects/owa/src/app/authentication/models/owa-authentication.model";

import { AccountSignupUserDefinedFieldViewModel } from "./account-signup-userdefinedfield-view.model";
import { OwnerContractModel } from "./generated/owner-contract.model";
import { PhoneNumberModel } from "./generated/phone-number.model";

export class AccountSignupViewModel extends OwaAuthenticationModel {
   AccountNumber?: number;
   FullTaxNumber: string;
   RequireFullTaxNumber: boolean;
   ShortTaxNumber: string;
   RequireShortTaxNumber: boolean;
   RequirePropertyOwnership: boolean;
   PropertyOwnership: Array<OwnerContractModel>;
   PhoneNumber: string;
   RequirePhoneNumber: string;
   RequireUserDefinedValue1: boolean;
   UserDefinedValue1: AccountSignupUserDefinedFieldViewModel;
   RequireUserDefinedValue2: boolean;
   UserDefinedValue2: AccountSignupUserDefinedFieldViewModel;
   RequireUserDefinedValue3: boolean;
   UserDefinedValue3: AccountSignupUserDefinedFieldViewModel;
   SkipValidation: boolean;
   RequireEmailAddress: boolean;
   EmailAddress: string;
   WorkflowSteps: string;
   Token: string;
   FirstName: string;
   LastName: string;
   ProfilePhoneNumbers: Array<PhoneNumberModel>;
   ReCaptchaResponseToken: string;
   ReCaptchaSiteKey: string;
   ShowTextingDisclaimer: boolean;
   TextingCompany: string;
   TextingPrivacyPolicyLink: string;
   TextingToSLink: string;
}