import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";

import { SearchPostBodyModel } from "./search-post-body.model";

export class SearchPostBodyTransferModel {
   Embeds: string | null = null;

   Fields: string | null = null;

   FilterExpression: string | null = null;

   OrderingOptions: string | null = null;

   GetOptions: string | null = null;

   PageNumber: number | null = null;

   PageSize: number | null = null;

   constructor(postModel: SearchPostBodyModel | null = null) {
      if (postModel) {
         this.Embeds = EntityEmbedOption.toApiString(postModel.embeds);
         this.Fields = EntityField.toApiString(postModel.fields);
         this.FilterExpression = postModel.filterExpression?.toApiString(false) ?? null;
         this.OrderingOptions = OrderingOption.toApiString(postModel.orderingOptions);
         this.GetOptions = EntityGetOption.toApiString(postModel.getOptions);
         this.PageNumber = postModel.pageNumber;
         this.PageSize = postModel.pageSize;
      }
   }
}
