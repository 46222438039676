import { Component, OnInit } from "@angular/core";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";

@Component({
   selector: "owa-owner-contributions-list",
   templateUrl: "./owner-contributions-list.component.html",
})
export class OwnerContributionsListComponent implements OnInit {
   public unsubscribe: Subject<void> = new Subject<void>();
   public systemWebPrefernces = new Array<SystemWebPreferenceModel>();
   public showBalance: boolean;
   public showSecurityDeposit: boolean;
   public showPercentageOwnerShip: boolean;
   public showReserve: boolean;
   public prefList: string[] = ["ShowOwnershipBalanceOWA", "ShowOwnershipPercentOWA", "ShowSecurityDepositsOWA"];
   public isLoaded: BehaviorSubject<boolean>;

   constructor(private systemWebPreferenceSessionService: SystemWebPreferencesSessionService) {
      this.isLoaded = new BehaviorSubject<boolean>(false);
   }

   ngOnInit() {
      this.isLoaded.next(false);
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               this.systemWebPrefernces = prefs.filter(
                  (res) =>
                     res.Name === "ShowOwnershipBalanceOWA" ||
                     res.Name === "ShowReserveOWA" ||
                     res.Name === "ShowOwnershipPercentOWA" ||
                     res.Name === "ShowSecurityDepositsOWA"
               );

               this.showBalance =
                  this.systemWebPrefernces.find((a) => a.Name === "ShowOwnershipBalanceOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       this.systemWebPrefernces.find((a) => a.Name === "ShowOwnershipBalanceOWA").Value === "True"
                        ? true
                        : false
                     : false;
               this.showSecurityDeposit =
                  this.systemWebPrefernces.find((a) => a.Name === "ShowSecurityDepositsOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       this.systemWebPrefernces.find((a) => a.Name === "ShowSecurityDepositsOWA").Value === "True"
                        ? true
                        : false
                     : false;
               this.showPercentageOwnerShip =
                  this.systemWebPrefernces.find((a) => a.Name === "ShowOwnershipPercentOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       this.systemWebPrefernces.find((a) => a.Name === "ShowOwnershipPercentOWA").Value === "True"
                        ? true
                        : false
                     : false;
               this.showReserve =
                  this.systemWebPrefernces.find((a) => a.Name === "ShowReserveOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       this.systemWebPrefernces.find((a) => a.Name === "ShowReserveOWA").Value === "True"
                        ? true
                        : false
                     : false;
               this.isLoaded.next(true);
            }
         }
      );
   }
}
