import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectModule } from "@lcs/select/select.module";

import { AccountSelectorComponent } from "./account-selector.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      SelectModule,
   ],
   declarations: [
      AccountSelectorComponent
   ],
   exports: [
      AccountSelectorComponent,
   ],
})
export class AccountSelectorModule { }
