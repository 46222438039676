<div class="flex-container">
   <div
      class="flex-half date-picker-wrapper"
      [ngClass]="{ 'toggleable-input-wrapper toggleable-input-inline': toggleable }"
   >
      <lcs-checkbox
         *ngIf="toggleable"
         [name]="dateName + 'Toggle'"
         [displayName]="dateDisplayName+' Toggle'"
         [ngModel]="checked"
         (ngModelChange)="onCheckedChange($event)"
         [disabled]="disabled"
         [ngModelOptions]="{ standalone: true }"
      ></lcs-checkbox>
      <lcs-date-picker
         #datePicker="ngModel"
         [name]="dateName"
         [displayName]="dateDisplayName+' Calendar'"
         [ngModel]="datePart"
         [modelRef]="datePicker"
         (ngModelChange)="updateDatePart($event)"
         [ngModelOptions]="{ standalone: true }"
         [lcsValidate]="dateValidation"
         [disabled]="disabled || disableDateParts"
         (inputValueChange)="onDateInputValueChange($event)"
      >
      </lcs-date-picker>
   </div>
   <div class="flex-half">
      <lcs-time-picker
         #timePicker="ngModel"
         [name]="timeName"
         [displayName]="timeDisplayName"
         [ngModel]="timePart"
         [modelRef]="timePicker"
         (ngModelChange)="updateTimePart($event)"
         [ngModelOptions]="{ standalone: true }"
         [lcsValidate]="timeValidation"
         [disabled]="disabled || disableDateParts"
         (inputValueChange)="onTimeInputValueChange($event)"
      ></lcs-time-picker>
   </div>
</div>