<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>
   <div>
      <label>NOTE: Do not refresh this page or you will be redirected to start over.</label>
   </div>
   <div class="flex">
      <div class="flex flex-half">
         <h5 class="form-header">Account Information</h5>
         <div class="form-element flex-full">
            <label>Card Number *</label>
            <lcs-text-box
               #accountNumberModel="ngModel"
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.AccountNumber"
               name="AccountNumber"
               displayName="Account Number"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-fourth">
            <label>Exp Month *</label>
            <lcs-select
               lcsLegacySingleSelector
               [valueSource]="monthValueSource"
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.ExpirationMonth"
               name="ExpirationMonth"
               displayName="Month"
               [required]="true"
            >
            </lcs-select>
         </div>
         <div class="form-element flex-fourth">
            <label>Exp Year *</label>
            <lcs-select
               lcsLegacySingleSelector
               [valueSource]="yearValueSource"
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.ExpirationYear"
               name="ExpirationYear"
               displayName="Year"
               [required]="true"
            >
            </lcs-select>
         </div>
         <div class="form-element flex-half">
            <label>CVV2 *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.Cvv2"
               name="CVV2"
               displayName="CVV2"
               [required]="true"
            ></lcs-text-box>
         </div>

         <h5 class="form-header">Payment Information</h5>
         <!-- TODO: styles for this block -->
         <div class="div-text flex flex-full ">
            <div class="flex-half">Amount</div>
            <div class="label-value flex-half">{{ ownerContributionPaymentModel.EpayCC.Amount | currency }}</div>
         </div>
         <div class="div-text flex flex-full ">
            <div class="flex-half">Convenience Fee</div>
            <div class="label-value flex-half">{{
               (ownerContributionPaymentModel.EpayCC.ConvenienceFee === null
               ? 0
               : ownerContributionPaymentModel.EpayCC.ConvenienceFee) | currency
               }}</div>
         </div>
         <div class="div-text flex flex-full ">
            <div class="flex-half">Total</div>
            <div class="label-value flex-half">{{
               (ownerContributionPaymentModel.EpayCC.Total != 0
               ? ownerContributionPaymentModel.EpayCC.Total
               : ownerContributionPaymentModel.Total) | currency
               }}</div>
         </div>
      </div>
      <div class="flex flex-half">
         <h5 class="form-header flex-space-between">
            Billing Address
         </h5>
         <div class="form-element flex-half">
            <label>First Name *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.Firstname"
               name="firstName"
               displayName="First Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Last Name *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.LastName"
               name="lastName"
               displayName="Last Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Street 1 *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.BillingAddressStreet1"
               name="Street1"
               displayName="Street"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Street 2</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.BillingAddressStreet2"
               name="Street2"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-third">
            <label>City *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.BillingAddressCity"
               name="City"
               displayName="City"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-third">
            <label>State *</label>
            <lcs-select
               lcsStaticSetSelector
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.BillingAddressState"
               name="State"
               displayName="State"
               [items]="stateOptions"
               [required]="true"
            ></lcs-select>
         </div>
         <div class="form-element flex-third">
            <label>Postal Code *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayCC.BillingAddressPostalCode"
               name="PostalCode"
               displayName="Postal Code"
               [required]="true"
            ></lcs-text-box>
         </div>
      </div>
   </div>
   <div>
      <div *ngIf="ownerContributionPaymentModel.EpayCC.IsDisclaimerRequired">
         <lcs-checkbox
            [name]="'ownerContributionPaymentModel.EpayCC.IsDisclaimerMessage'"
            [ngModel]="checked"
            (ngModelChange)="allSelected($event)"
         >{{ ownerContributionPaymentModel.EpayCC.DisclaimerMessage }}</lcs-checkbox>
      </div>
      <div lcsFooter>
         <lcs-progress-button
            class="contribution-button"
            [(status)]="buttonStatus"
            [raised]="true"
         >Make Contribution</lcs-progress-button>
         <a
            class="zego-link"
            target="_blank"
            [href]="constantsService.ZegoPrivacyPage"
         > Zego Privacy Policy </a>
      </div>
   </div>
</form>