import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { filter, Subject, takeUntil } from "rxjs";

import { AccountRecoveryModel } from "../../../../libraries/owa-gateway-sdk/src/lib/models/account-recovery.model";
import { AccountRecoveryService } from "../../../../libraries/owa-gateway-sdk/src/lib/services/account-recovery.service";
import { LocationComponent } from "../authentication/rm-location/rm-location.component";
import { LocationService } from "../authentication/rm-location/rm-location.service";
import { TopBarService } from "../top-bar/top-bar.service";

@Component({
   selector: "owa-account-recovery",
   templateUrl: "./account-recovery.component.html",
   styleUrls: ["./account-recovery.component.css"],
})
export class AccountRecoveryComponent implements OnInit, OnDestroy {
   public recoveryModel: AccountRecoveryModel = new AccountRecoveryModel();
   @ViewChild(LocationComponent, { static: true }) locationComponent;

   submitButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   cancelSubmitButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
   public errorMessage: string = null;
   public loginUrl: string = "/login";
   public queryParameter: Params;
   private unsubscribe = new Subject<void>();

   constructor(
      private accountRecoveryService: AccountRecoveryService,
      private errorMessageService: ErrorMessageService,
      private router: Router,
      private sessionCheckService: SessionCheckService,
      private topbarservice: TopBarService,
      private cacheMonitorService: CacheMonitorService,
      private activatedRoute: ActivatedRoute,
      private locationService: LocationService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.locationComponent.authlocationId.pipe(takeUntil(this.unsubscribe)).subscribe((locationid) => {
         if (locationid) {
            this.recoveryModel.LocationId = locationid;
         }
      });

      this.locationComponent.errorMessage
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((message) => (this.errorMessage = message));

      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });

      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         if (queryParams != null) {
            this.queryParameter = this.locationService.toLowerCaseParams(queryParams);
            if (
               this.queryParameter["locations"] != null &&
               this.queryParameter["locations"] !== undefined &&
               this.queryParameter["locations"] !== ""
            ) {
               this.loginUrl += "?locations=" + this.queryParameter["locations"];
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
      this.topbarservice.topBarIsVisible.next(this.cacheMonitorService.cachesLoadedState);
   }

   onClick() {
      this.submitButtonStatus = LcsProgressButtonStatus.InProgress;
      this.accountRecoveryService
         .recover(this.recoveryModel)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            () => {
               this.submitButtonStatus = LcsProgressButtonStatus.Success;
               this.router.navigateByUrl("/password/verifypending");
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
               this.submitButtonStatus = LcsProgressButtonStatus.Error;
            }
         );
   }

   onCancelClick() {
      if (this.loginUrl.includes("locations")) {
         this.loginUrl += "&";
      } else {
         this.loginUrl += "?";
      }
      this.loginUrl += "locationId=" + this.recoveryModel.LocationId;

      this.cancelSubmitButtonStatus = LcsProgressButtonStatus.InProgress;
      this.router.navigateByUrl(this.loginUrl);
   }
}
