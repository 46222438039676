<lcs-input
   #selector
   class="white datatable-filter-input-control"
   [value]="value"
   [controlType]="controlType"
   (valueChange)="onValueChange($event)"
   (selectionChange)="onSelectionChange($event)"
   name="Property.PropertyGroups.PropertyGroupID"
   displayName="Property Group"
   [entityType]="entityType"
   [hideSelectorLabel]="true"
   [embeds]="additionalDataFields"
   [additionalDataFields]="additionalDataFields"
   [standalone]="standalone"
>
</lcs-input>