import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { filter, Subject, takeUntil } from "rxjs";

import { AllValueType } from "../all-value.type";
import { ChartAccountsToIncludeReportParameterComponent } from "../chart-accounts-to-include/chart-accounts-to-include-report-parameter.component";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-accounts-wrapper-report-parameter",
   templateUrl: "accounts-wrapper-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class AccountsWrapperReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() parameter: ReportReportParameterComponentModel;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() entityValueSourceFilters = new Array<FilterOption>();

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() set reportReportParameters(values: Array<ReportReportParameterViewModel>) {
      this._reportReportParameters = values;
   }

   @Input() hasAsk: boolean;

   showMapping: boolean = false;

   chartAccountsReportParameter: ReportReportParameterViewModel;

   chartMapReportParameter: ReportReportParameterViewModel;

   chartMapParameterValue: ReportParameterValueModel;

   chartAccountsParameterValue: ReportParameterValueModel;

   selectedItems: Array<number | AllValueType> = new Array<number | AllValueType>();

   isLoading: boolean = true;

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.chartMapReportParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === this.parameter.ReportReportParameter.ReportParameterID
      )[0];
      this.chartAccountsReportParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === this.parameter.ReportReportParameter.ReportParameterID
      )[0];
      if (this.chartAccountsReportParameter.ReportParameterValueSource.IsRequired) {
         if (this.validation) {
            this.validation.required = true;
         } else {
            const validation = new ValidationModel();
            validation.required = true;
            validation.dataType = ExpressDataTypes.Key;
            this.validation = validation;
         }
      }
      this.initializeReportParameterValue();
      if (!this.displayName) {
         this.displayName = ChartAccountsToIncludeReportParameterComponent.defaultDisplayName;
      }
   }

   initializeReportParameterValue() {
      if (this.chartMapReportParameter) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
         this.chartMapParameterValue = this.reportParametersService.reportParameterValues.get(
            this.chartMapReportParameter.ReportParameterID
         );
      }
      if (this.chartAccountsReportParameter) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
         this.chartAccountsParameterValue = this.reportParametersService.reportParameterValues.get(
            this.chartAccountsReportParameter.ReportParameterID
         );
         if (!this.displayName && this.chartAccountsReportParameter.DisplayName) {
            // only overwrite if not explicitly passed as input
            this.displayName = this.chartAccountsReportParameter.DisplayName;
         }
      }
      this.selectedItems = this.chartAccountsParameterValue.value.split(",");
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === this.chartAccountsReportParameter.ReportParameterID) {
               this.reportParameterValuesUpdated(updatedValue);
            }
         });
      this.reportParametersService.reportParameterStatusChanged
         .pipe(
            filter(
               (updatedValue) => updatedValue.reportParameter === this.chartAccountsReportParameter.ReportParameterID
            ),
            takeUntil(this.unsubscribe)
         )
         .subscribe((status) => {
            this.disabled = status.disabled;
         });
   }

   askChanged(isAsk: boolean) {
      this.chartAccountsReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.chartAccountsReportParameter.ReportParameterID, isAsk);
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel) {
      const checkedItems = selectionChangeModel.checkedItems.filter((c) => c.value != null && c.value !== "");
      let values = new Array<any>();
      const selectAll = selectionChangeModel.selectAll;
      if (selectAll) {
         this.chartAccountsParameterValue.value = "all";
         values = ["all"];
      } else if (checkedItems.length > 0) {
         values = checkedItems.map((f) => f.value);
         this.chartAccountsParameterValue.value = "(" + values.join(",") + ")";
      } else {
         this.chartAccountsParameterValue.value = "";
      }
      this.chartAccountsParameterValue.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.chartAccountsParameterValue);
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      let valueChanged = false;
      let updatedRawValues = updatedValue.rawValues;
      if (updatedRawValues == null) {
         updatedRawValues = new Array<any>();
      }
      if (updatedRawValues.length !== this.selectedItems.length) {
         valueChanged = true;
      } else {
         for (const value of updatedRawValues) {
            // if one of the updatedRawValues is not in the list of selectedItems then value changed
            if (this.selectedItems.indexOf(value) === ConstantsService.ArrayIndexNotFound) {
               valueChanged = true;
               break;
            }
         }
      }
      if (!valueChanged) {
         return;
      }
      this.selectedItems = updatedRawValues;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }
}
