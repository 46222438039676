import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OwnerGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group.model";
import { PropertyGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-group.model";
import { OwnersService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owners.service";
import { PropertiesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/properties.service";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { Subject, takeUntil } from "rxjs";

import { ConstantsService } from "../../core/constants.service";
import { FilterInputValueType } from "../datatable-filters/datatable-filter-values.types";
import { PropertyOwnerSelectorComponent } from "../property-owner-selector/property-owner-selector.component";

@Component({
   selector: "lcs-property-ownership-selector",
   templateUrl: "property-ownership-selector.component.html",
})
export class PropertyOwnershipSelectorComponent implements AfterViewInit, OnDestroy, OnInit {
   @Input() set selectedPropertyIDs(selectedPropertyIDs: FilterInputValueType | Array<FilterInputValueType>) {
      this._selectedPropertyIDs = selectedPropertyIDs;
   }

   get selectedPropertyIDs(): FilterInputValueType | Array<FilterInputValueType> {
      return this._selectedPropertyIDs;
   }

   @Input() set showInactivePropertiesDefault(value: boolean) {
      if (typeof value !== "undefined") {
         this.onShowInactivePropertiesChange(value, false);
      } else {
         this.onShowInactivePropertiesChange(true, false);
      }
   }
   @Input() propertyGroupID: number;

   @Input() hideInactiveCheckbox: boolean;

   @Input() hideOwnerGroupSelector: boolean;

   @Input() showAllItemSelector: boolean;

   @Input() hasAllPropertiesOverride: boolean;

   @Input() parentFormGroup: UntypedFormGroup | undefined;

   @Output() selectedPropertyIDsChanged = new EventEmitter<Array<number>>();

   @Output() selectedPropertyGroupIDChanged = new EventEmitter<number>();

   @ViewChild("propertyOwnerSelector", { static: true }) propertyOwnerSelector: PropertyOwnerSelectorComponent;

   selectedOwnerIDs: Array<number>;

   ownerGroupID: number;

   tabIndex: number = 0;

   allCollectionItem: SelectorItemModel;

   propertyPluralReplacementName: string;

   isAllSelected: boolean;

   private _selectedPropertyIDs: FilterInputValueType | Array<FilterInputValueType>;

   get displayValue(): string {
      if (this.propertyOwnerSelector) {
         const displayValue = this.propertyOwnerSelector.displayValue;
         if (this.propertyOwnerSelector.tabIndex === 0) {
            return displayValue;
         } else {
            if (!this.propertyOwnerSelector.ownerSelector) {
               return displayValue;
            } else if (this.propertyOwnerSelector.ownerSelector.selectedItemCount === 1) {
               return `${displayValue}'s Ownerships`;
            } else {
               return `Ownerships of ${displayValue} Owners`;
            }
         }
      }
      return "";
   }

   showInactiveProperties: boolean;

   private unsubscribe = new Subject<void>();

   constructor(
      private ownersService: OwnersService,
      private propertiesService: PropertiesService,
      private entityInformationService: EntityInformationService,
      private currentUserService: CurrentUserService
   ) {
      const propertyInformation = this.entityInformationService.getEntityTypeInformation(EntityType.Property);
      this.propertyPluralReplacementName = propertyInformation.CollectionLabel;
   }

   ngOnInit() {
      if (
         this.showAllItemSelector &&
         (this.hasAllPropertiesOverride || this.currentUserService.hasProperties([ConstantsService.NullFK]))
      ) {
         const allPropertiesItem = new SelectorItemModel(
            ConstantsService.NullFK,
            `*** All ${this.propertyPluralReplacementName}`
         );
         allPropertiesItem.isMutuallyExclusiveAll = true;

         this.allCollectionItem = allPropertiesItem;
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   ngAfterViewInit() {
      this.realignInkBar();
      if (!this.selectedPropertyIDs) {
         this.isAllSelected = true;
      } else {
         this.isAllSelected = false;
      }
   }

   onAllSelected(isAllSelected: boolean) {
      this.isAllSelected = isAllSelected;
   }

   onPropertySelectionChange(change: SelectionChangeModel) {
      this.propertyIDsChanged(change.checkedItems.map((i) => +i.value));
      this.updateForm();
   }

   onOwnerSelectionChange(change: SelectionChangeModel) {
      if (!change.selectAll) {
         const ids = change.checkedItems.map((i) => +i.value);
         this.ownerIDsChanged(ids);
      } else {
         this.ownerIDsChanged([ConstantsService.ZeroFK]);
      }
      this.updateForm();
   }

   onTabChange(change: EntityType) {
      if (change === EntityType.Property) {
         this.tabIndex = 0;
         this.propertyIDsChanged(this.selectedPropertyIDs as Array<number>);
      } else {
         this.tabIndex = 1;
         this.ownerIDsChanged(this.selectedOwnerIDs);
      }
   }

   onShowInactivePropertiesChange(showInactive: boolean, dirtyForm: boolean = true) {
      this.showInactiveProperties = showInactive;
      if (this.tabIndex === 0) {
         const filters = new Array<FilterOption>();
         if (!this.showInactiveProperties) {
            filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
         }

         const _isAllSelected = this.isAllSelected;

         this.propertiesService
            .getCollection(filters)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((results) => {
               if (results) {
                  if (_isAllSelected) {
                     this.selectedPropertyIDs = results.map((p) => p.PropertyID);
                  } else if (this.selectedPropertyIDs) {
                     if (this.selectedPropertyIDs[0] === ConstantsService.NullFK) {
                        this.selectedPropertyIDs = [ConstantsService.NullFK];
                     } else {
                        this.selectedPropertyIDs = results
                           .map((p) => p.PropertyID)
                           .filter((p) => (this.selectedPropertyIDs as Array<number>)?.some((id) => id === p));
                     }
                  }
               } else {
                  this.selectedPropertyIDs = [];
               }
               this.propertyIDsChanged(this.selectedPropertyIDs as Array<number>);
            });
      } else {
         this.ownerIDsChanged(this.selectedOwnerIDs);
      }

      if (dirtyForm) {
         this.updateForm();
      }
   }

   onPropertyGroupChange(group: PropertyGroupModel) {
      if (!group) {
         return;
      }
      this.selectedPropertyGroupIDChanged.emit(group.GroupID);
      this.selectedPropertyIDs = group.Properties.map((p) => p.PropertyID);
      this.propertyIDsChanged(this.selectedPropertyIDs as Array<number>);
      this.updateForm();
   }

   onOwnerGroupChange(group: OwnerGroupModel) {
      if (!group) {
         return;
      }
      this.selectedOwnerIDs = group.Owners.map((o) => o.OwnerID);
      this.ownerIDsChanged(this.selectedOwnerIDs);
      this.updateForm();
   }

   realignInkBar() {
      if (this.propertyOwnerSelector) {
         this.propertyOwnerSelector.realignInkBar();
      }
   }

   private propertyIDsChanged(ids: Array<number>) {
      this.selectedPropertyIDs = ids;
      this.selectedPropertyIDsChanged.emit(ids);
   }

   private ownerIDsChanged(ids: Array<number>) {
      if (!ids || ids.length === 0) {
         return;
      }
      if (ids.length === 1 && ids[0] === ConstantsService.ZeroFK) {
         this.ownersService
            .getCollection(null, ["Ownerships", "Ownerships.Property"])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((results) => {
               if (results && results.length > 0) {
                  const ownershipPropertyIDs = results
                     .filter((o) => o.Ownerships && o.Ownerships.length > 0)
                     .map((o) =>
                        o.Ownerships.filter((os) =>
                           this.showInactiveProperties ? true : os.Property.IsActive === true
                        ).map((oc) => oc.PropertyID)
                     )
                     .reduce(function (a, b) {
                        return a.concat(b);
                     }, new Array<number>())
                     .filter((x, i, a) => a.indexOf(x) === i);
                  this.selectedPropertyIDsChanged.emit(ownershipPropertyIDs);
               }
            });
      } else {
         const filter = new FilterOption("OwnerID", FilterOperations.In, ids);
         this.ownersService
            .getCollection([filter], ["Ownerships", "Ownerships.Property"])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((results) => {
               if (results && results.length > 0) {
                  const ownershipPropertyIDs = results
                     .filter((o) => o.Ownerships && o.Ownerships.length > 0)
                     .map((o) =>
                        o.Ownerships.filter((os) =>
                           this.showInactiveProperties ? true : os.Property.IsActive === true
                        ).map((oc) => oc.PropertyID)
                     )
                     .reduce(function (a, b) {
                        return a.concat(b);
                     }, new Array<number>());
                  this.selectedPropertyIDsChanged.emit(ownershipPropertyIDs);
               }
            });
      }
   }

   private updateForm(): void {
      if (this.parentFormGroup) {
         this.parentFormGroup.markAsDirty();
         this.parentFormGroup.updateValueAndValidity();
      }
   }
}
