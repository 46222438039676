<div class="tile-wrapper">
   <div class="signable-document-tile">
      <div class="signable-document-name">
         {{signableDocumentPacket.Name}}
      </div>
      <div class="signable-document-list-item-detail">
         <div class="detail-container">
            <div
               *ngIf="signableDocumentPacket.SignableDocuments.length == 1"
               [class.flex-fourth]="hasFields"
               [class.flex-third]="!hasFields"
            >
               <div class="title">Pages</div>
               <div class="title-value">{{signableDocumentPacket.PacketPageCount}}</div>
            </div>
            <div
               *ngIf="signableDocumentPacket.SignableDocuments.length > 1"
               [class.flex-fourth]="hasFields"
               [class.flex-third]="!hasFields"
            >
               <div class="title">Documents</div>
               <div class="title-value">{{signableDocumentPacket.SignableDocuments.length}}</div>
            </div>
            <div
               [class.flex-fourth]="hasFields"
               [class.flex-third]="!hasFields"
            >
               <div class="title">Signers</div>
               <div class="title-value">
                  {{signableDocumentPacket.FinishedSignerCount}} of {{signableDocumentPacket.SignerCount}}
               </div>
            </div>
            <div
               [class.flex-fourth]="hasFields"
               [class.flex-third]="!hasFields"
            >
               <div class="title">Signatures</div>
               <div class="title-value">
                  {{signableDocumentPacket.SignedBlockCount}} of {{signableDocumentPacket.SignatureBlockCount}}
               </div>
            </div>
            <div
               class="flex-fourth"
               *ngIf="hasFields"
            >
               <div class="title no-wrap">Required Fields</div>
               <div class="title-value">
                  {{signableDocumentPacket.FinishedRequiredFieldsCount}} of
                  {{signableDocumentPacket.TotalRequiredFieldsCount}}
               </div>
            </div>
         </div>
         <div class="status-container">

            <div class="status-container-item">
               <i
                  *ngIf="isSignable && hasThemeSignableDocuments"
                  class="material-icons signable-icon-black"
               >
                  insert_drive_file
               </i>
               <i
                  *ngIf="isSignable && !hasThemeSignableDocuments"
                  class="material-icons signable-icon"
               >
                  insert_drive_file
               </i>
               <i
                  *ngIf="!isSignable"
                  class="material-icons complete-icon"
               >
                  check_circle_outline
               </i>
               {{signableDocumentPacket.StatusText}}
            </div>
            <div
               *ngIf="showExpiration"
               class="status-container-item expiration"
            >
               <span class="expiration-heading">Expires</span>
               <span class="expiration-value">{{signableDocumentPacket.ExpirationDate | date}}</span>
            </div>

         </div>
      </div>
      <div
         *ngIf="signableDocumentPacket.SignableDocuments.length == 1"
         class="button-wrapper"
      >
         <a
            *ngIf="isSignable"
            (click)="signDocument()"
         >
            <div class="button-container">
               Sign Document
            </div>
         </a>

         <a
            *ngIf="!isSignable"
            (click)="viewDocument()"
         >
            <div class="button-container">
               Review Document
            </div>
         </a>
      </div>
      <div
         *ngIf="signableDocumentPacket.SignableDocuments.length > 1"
         class="button-wrapper"
      >
         <a
            *ngIf="isSignable"
            (click)="signDocument()"
         >
            <div class="button-container">
               Sign Packet
            </div>
         </a>

         <a
            *ngIf="!isSignable"
            (click)="viewDocument()"
         >
            <div class="button-container">
               Review Packet
            </div>
         </a>
      </div>
   </div>
</div>