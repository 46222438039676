import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { OverlayPanelsModule } from "../../overlay-panel/overlay-panels.module";
import { TextBoxModule } from "../text-box/text-box.module";
import { ValidationModule } from "../validation/validation.module";
import { TextAreaComponent } from "./text-area.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, OverlayPanelsModule, TextBoxModule],
   declarations: [TextAreaComponent],
   exports: [TextAreaComponent],
})
export class TextAreaModule {}
