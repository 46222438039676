import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { Subject, takeUntil } from "rxjs";

import { OWASessionModel } from "../../session/models/owa-session.model";
import { OWASessionService } from "../../session/owa-session.service";

@Component({
   selector: "owa-dashboard-messages",
   templateUrl: "./dashboard-tile-messages.component.html",
})
export class DashboardMessagesComponent implements OnInit, OnDestroy {
   generalMessage: string;
   ownerMessage: string;
   owaSessionModel: OWASessionModel = new OWASessionModel();

   private unsubscribe = new Subject<void>();

   constructor(private owaSessionService: OWASessionService) {}

   ngOnInit() {
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
         if (res != null) {
            this.owaSessionModel = res;
            if (this.owaSessionModel != null) {
               this.generalMessage = this.owaSessionModel.GeneralMessage;
               if (this.owaSessionModel.CurrentWebUserAccount.AccountType == AccountType.Owner) {
                  this.ownerMessage = this.owaSessionModel.CurrentAccount.WebMessage;
               } else {
                  this.ownerMessage = this.owaSessionModel.CurrentOwnerProspectAccount.WebMessage;
               }
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
