<div>
   <div class="report-parameter-label">
      <label class="parameter-label">
         Cleared/Open Options
      </label>
   </div>
   <lcs-checkbox
      *ngFor="let item of itemSet.items;"
      (ngModelChange)="item.isChecked = $event; valueChanged(item)"
      [ngModel]="item.isChecked"
      [disabled]="item.isDisabled"
      [ngModelOptions]="{ standalone: true }"
   >
      {{ item.displayValue }}
   </lcs-checkbox>
</div>
