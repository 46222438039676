////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressLayoutControlTypes {
   NotSet = 0,
   EntityInformationSection = 1,
   Contacts = 2,
   UserDefinedValues = 3,
   Leases = 4,
   History = 5,
   Transactions = 6,
   Addresses = 7,
   RecurringCharges = 8,
   ImageCarousel = 9,
   ColorPicker = 10,
   NumericTextBox = 11,
   EntityDataTable = 12,
   Label = 13,
   EntitySelector = 14,
   ComingSoon = 15,
   TextBox = 16,
   Checkbox = 17,
   SingleSelector = 18,
   SearchableSelector = 19,
   DatePicker = 20,
   Button = 21,
   EnumSelector = 22,
   TimePicker = 23,
   Slider = 24,
   TextArea = 25,
   InputWithOptions = 26,
   MarketRent = 27,
   PreferredUnitUserDefinedValues = 28,
   DateTimePicker = 29,
   DateRangePicker = 30,
   CalculatorInput = 31,
   ServiceManagerIssueHistory = 32,
   WorkOrders = 33,
   FileUpload = 34,
   ImageUpload = 35,
   PhoneNumberInput = 36,
   CustomSubCollectionSelector = 37,
   PasswordInput = 38,
   ImageContainer = 40,
   ServiceManagerRelatedIssues = 41,
   RentPeriod = 42,
   ProspectReservedUnitSelector = 43,
   ProspectReservedMoveInDate = 44,
   ProspectReservedMoveOutDate = 45,
   TenantsAndProspectsSelector = 46,
   MemorizedWorkOrders = 47,
   EntityMultiSelector = 48,
   EnumMultiSelector = 49,
   NumberRangeInput = 50,
   SingleLineMultiSelector = 51,
   ConfirmDatePicker = 52,
   TenantLeasePropertySelector = 53,
   RecurringWorkOrders = 54,
   ServiceManagerIssueLocks = 55,
   AccountSelector = 56,
   AccountMultiSelector = 57,
   PropertyOwnerSelector = 58,
   EntityLinkSelector = 59,
   PropertyOwnershipSelector = 60,
   BankSelector = 61,
   OwnerBillPayMethodSelector = 62,
   TaxIDInput = 63,
   ContactEmailInput = 64,
   ContactNameInput = 65,
   BillPayMethodSelector = 66,
   AssociatedPropertiesSelector = 67,
   VendorTransactions = 68,
   ChecklistItems = 69,
   AutomaticUnitCreation = 70,
   AssignedToUser = 71,
   OwnerEstimatePropertySelector = 72,
   OwnerTransactions = 73,
   Vendor1099YTDBalance = 74,
   PropertyGroupSelector = 75,
   SocialSecurityNumber = 76,
   SecureCurrency = 77,
   ServiceIssuePayeeAccountSelector = 78,
   ServiceIssueJobSelector = 79,
   PrimaryContact = 80,
   ScriptField = 81,
   PropertyOwnerships = 82,
   ScreenSettingsFieldMappings = 83,
   OwnerProspectStatus = 84,
   ScreenSettingsMinimumDecision = 85,
   OwnerCheckSetup = 86,
   SingleUserDefinedField = 87,
   PropertyGroupPropertySelector = 88,
   SinglePhoneNumberField = 89,
   ChargeTypeBanks = 90,
   JobLinkTypeSelector = 91,
   ButtonLabel = 92,
   SortSelector = 96,
   JobBudget = 97,
   JobTransactions = 98,
   UnitAmenities = 101,
   AssetLocation = 102,
   InventoryItemTransactions = 105,
   ManagementFeeTemplateSelector = 106,
   PropertyTextNumbers = 107,
   ProspectLeaseTermSelector = 108,
   RadioButton = 110,
   OpenReceivables = 111,
   ServiceManagerMemorizedIssueLinks = 112,
   UnitDefaultSecurityDeposit = 113,
   UnitMatchingCriteria = 114,
   ServiceManagerRecurringIssueRecurrence = 115,
   ServiceManagerRecurringIssueLinks = 116,
   PropertyFiscalYearSetup = 117,
   PropertyTimeZoneSetup = 118,
   PrimaryLeaseUnitSelector = 119,
   OwnerProspectProperties = 120,
   AssetDepreciation = 121,
   ServiceManagerIssueLinks = 122,
   AddSecurityDeposit = 123,
   PhoneNumbers = 124,
   OwnerManageAccount = 125,
   AssetStatusHistory = 126,
   MultipleUnitsUnitNameSetup = 127,
   MultipleUnitsAddressSetup = 128,
   SecurityDepositChargeTypeSelector = 130,
   FormEventButton = 131,
   Charges = 132,
   RecurringChargeAdd = 133,
   ShortTermReservationInfo = 134,
   VehicleInfo = 135,
   RichTextEditor = 136,
   TenantDetailSTRReservations = 137,
   PetInfo = 138,
   PhoneNumberWithPhoneTypeSelector = 139,
   WebColorThemePicker = 140,
   ProspectDetailsSTRRentals = 141,
   TWADashboardTilePicker = 142,
   HolidaySetup = 143,
   LinkedAssets = 145,
   AccountingCloseDate = 146,
   WorkflowRentalInfoSection = 147,
   AmortizationSchedule = 148,
   WebColorThemeBucket = 149,
   ExpandableLabel = 150,
   ViolationHistoryForTenant = 151,
   ViolationStageInformation = 152,
   TWAServiceIssuesTitleControl = 153,
   ViolationWorkflowCurrentStageInformation = 154,
   ReservableAmenities = 156,
   PropertyAssociationBoardMembers = 157,
   STRRateScheduleDetails = 158,
   ViolationEntityInformation = 159,
   PropertyAssociationCommitteeMembers = 160,
   ProspectStageSelector = 161,
   WriteLettersSetup = 162,
   LoanContacts = 163,
   StaticSelector = 164,
   ServiceManagerTechTime = 165,
   UDFEncryptedText = 167,
   BlueMoonForms = 168,
   BlueMoonFieldSetup = 169,
   ProspectInterestedRentals = 170,
   PropertyFiles = 171,
   PropertySecurityDepositInterestOptions = 172,
   TextBoxWithIcon = 173,
   StatementMethod = 174,
   PublishedTWAReports = 178,
   ScheduledTWAReports = 179,
   LcsColorPicker = 180,
   ViolationStageSelector = 181,
   ViolationStageColorTable = 182,
   TWAPropertyGroupPropertySelector = 183,
}
