import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import sortBy from "lodash/sortBy";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ReportSortOptionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-sort-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { ReportsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/reports.service";
import { Subject, takeUntil } from "rxjs";

import { ValidationHelper } from "../../../inputs/validation/validation-helper";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-sort-options-report-parameter",
   templateUrl: "sort-options-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class SortOptionsReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
      this.reportParamDisplayLabel =
         this._parameter.IsAsk && this._parameter.Report
            ? this._parameter.Report?.Description + " - " + this._parameter.ReportParameter.DisplayName
            : this.parameter?.DisplayName;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   valueSources: Array<ValueSourceModel>;
   reportParamDisplayLabel: string;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(private reportsService: ReportsService, private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeReportParameterValue();
      this.initializeValueSource();
   }

   initializeValueSource() {
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;
      this.fetchData(valueSourceModel);
   }

   fetchData(valueSourceModel) {
      this.reportsService
         .getReportSortOptionsCollection(this.parameter.ReportID)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            this.processSortOptions(results, valueSourceModel);
         });
   }

   processSortOptions(results: Array<ReportSortOptionModel>, valueSourceModel: ValueSourceModel) {
      const selectedValueSources = new Array<ValueSourceModel>();
      if (results) {
         for (const sortOption of sortBy(results, "SortOrder")) {
            const item = new SelectorItemModel();
            item.displayValue = sortOption.DisplayName;
            item.value = sortOption.ReportSortOptionID;
            valueSourceModel.StaticValues.push(item);
         }
         if (ValidationHelper.isNullOrEmpty(this.reportParameterValueModel.value)) {
            this.reportParameterValueModel.value = valueSourceModel.StaticValues[0].value;
         }
         valueSourceModel.SelectedValues.push(+this.reportParameterValueModel.value);
         selectedValueSources.push(valueSourceModel);
         this.valueSources = selectedValueSources;
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel) {
      const values = selectionChangeModel.checkedItems;
      this.reportParameterValueModel.value = +values[0].value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }
}
