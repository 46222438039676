import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class UserFields extends EntityField {
   static readonly Banks: UserFields = new UserFields("Banks");

   static readonly CreateDate: UserFields = new UserFields("CreateDate");

   static readonly CreateUser: UserFields = new UserFields("CreateUser");

   static readonly CreateUserID: UserFields = new UserFields("CreateUserID");

   static readonly CreditCards: UserFields = new UserFields("CreditCards");

   static readonly CustomFormUserGroupID: UserFields = new UserFields("CustomFormUserGroupID");

   static readonly CustomProductUserPreferences: UserFields = new UserFields("CustomProductUserPreferences");

   static readonly CustomToolbarGroupID: UserFields = new UserFields("CustomToolbarGroupID");

   static readonly DefaultLocationID: UserFields = new UserFields("DefaultLocationID");

   static readonly EffectivePrivileges: UserFields = new UserFields("EffectivePrivileges");

   static readonly Email: UserFields = new UserFields("Email");

   static readonly Firstname: UserFields = new UserFields("Firstname");

   static readonly HasAPIAccess: UserFields = new UserFields("HasAPIAccess");

   static readonly HasMobileRentManagerAccess: UserFields = new UserFields("HasMobileRentManagerAccess");

   static readonly HasSiteManagerAccess: UserFields = new UserFields("HasSiteManagerAccess");

   static readonly IsActive: UserFields = new UserFields("IsActive");

   static readonly IsAdmin: UserFields = new UserFields("IsAdmin");

   static readonly IsGroup: UserFields = new UserFields("IsGroup");

   static readonly IsInspector: UserFields = new UserFields("IsInspector");

   static readonly IsLeasingAgent: UserFields = new UserFields("IsLeasingAgent");

   static readonly IsOverrideTimeZone: UserFields = new UserFields("IsOverrideTimeZone");

   static readonly IsOwnerSalesRepresentative: UserFields = new UserFields("IsOwnerSalesRepresentative");

   static readonly IsPartnerAuthorized: UserFields = new UserFields("IsPartnerAuthorized");

   static readonly IsPartnerUser: UserFields = new UserFields("IsPartnerUser");

   static readonly IsPasswordReset: UserFields = new UserFields("IsPasswordReset");

   static readonly IsPhoneSystemUser: UserFields = new UserFields("IsPhoneSystemUser");

   static readonly IsReadOnly: UserFields = new UserFields("IsReadOnly");

   static readonly IsRmServiceUser: UserFields = new UserFields("IsRmServiceUser");

   static readonly IsSalesRepresentative: UserFields = new UserFields("IsSalesRepresentative");

   static readonly IsStrongPassword: UserFields = new UserFields("IsStrongPassword");

   static readonly IsTimeZoneDaylightSavingsTime: UserFields = new UserFields("IsTimeZoneDaylightSavingsTime");

   static readonly IsTimeZoneEnabled: UserFields = new UserFields("IsTimeZoneEnabled");

   static readonly IsToolbarSearch: UserFields = new UserFields("IsToolbarSearch");

   static readonly Lastname: UserFields = new UserFields("Lastname");

   static readonly LastPartnerProfileRefreshDate: UserFields = new UserFields("LastPartnerProfileRefreshDate");

   static readonly LastPasswordChangeDate: UserFields = new UserFields("LastPasswordChangeDate");

   static readonly LastWebLoginDate: UserFields = new UserFields("LastWebLoginDate");

   static readonly LetterTemplates: UserFields = new UserFields("LetterTemplates");

   static readonly Locations: UserFields = new UserFields("Locations");

   static readonly LockoutDate: UserFields = new UserFields("LockoutDate");

   static readonly MainLocationUserID: UserFields = new UserFields("MainLocationUserID");

   static readonly MetaTag: UserFields = new UserFields("MetaTag");

   static readonly Name: UserFields = new UserFields("Name");

   static readonly PartnerID: UserFields = new UserFields("PartnerID");

   static readonly PartnerToken: UserFields = new UserFields("PartnerToken");

   static readonly PhoneNumbers: UserFields = new UserFields("PhoneNumbers");

   static readonly PhoneSystemExtension: UserFields = new UserFields("PhoneSystemExtension");

   static readonly Properties: UserFields = new UserFields("Properties");

   static readonly Supervisor: UserFields = new UserFields("Supervisor");

   static readonly SupervisorID: UserFields = new UserFields("SupervisorID");

   static readonly TabGroup: UserFields = new UserFields("TabGroup");

   static readonly TaskUsers: UserFields = new UserFields("TaskUsers");

   static readonly TimeZoneOffset: UserFields = new UserFields("TimeZoneOffset");

   static readonly ToolbarSize: UserFields = new UserFields("ToolbarSize");

   static readonly ToolbarText: UserFields = new UserFields("ToolbarText");

   static readonly UpdateDate: UserFields = new UserFields("UpdateDate");

   static readonly UpdateUser: UserFields = new UserFields("UpdateUser");

   static readonly UpdateUserID: UserFields = new UserFields("UpdateUserID");

   static readonly UserBanks: UserFields = new UserFields("UserBanks");

   static readonly UserDefinedValues: UserFields = new UserFields("UserDefinedValues");

   static readonly UserID: UserFields = new UserFields("UserID");

   static readonly UserLocations: UserFields = new UserFields("UserLocations");

   static readonly Username: UserFields = new UserFields("Username");

   static readonly UserPrivileges: UserFields = new UserFields("UserPrivileges");

   static readonly UserProperties: UserFields = new UserFields("UserProperties");

   static readonly UserPropertyGroups: UserFields = new UserFields("UserPropertyGroups");

   static readonly UserReports: UserFields = new UserFields("UserReports");

   static readonly UserRoles: UserFields = new UserFields("UserRoles");

   static readonly UserTasks: UserFields = new UserFields("UserTasks");
}
