<table
   #table
   cdk-table
   [dataSource]="dataSource"
   class="lcs-table"
   [multiTemplateDataRows]="true"
   [trackBy]="trackBy"
>
   <ng-container
      lcsColumnDef="responsive-toggle"
      [width]="2"
      [priority]="0"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
         class="expand-collapse-all-header"
         title="{{ allRowsExpanded ? 'Collapse all rows' : 'Expand all rows' }}"
      >
         <div class="expand-collapse-header-wrapper"><button
               type="button"
               (click)="resetExpansionToggle()"
               class="material-icons"
            >
               {{ allRowsExpanded ? "keyboard_arrow_down" : "keyboard_arrow_right" }}
            </button></div>
      </th>
      <td
         lcs-cell
         *lcsCellDef="let row"
      >
         <button
            type="button"
            (click)="toggleRowExpanded(row)"
            class="material-icons"
         >
            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            {{ rowIsExpanded($any(null), row) ? "keyboard_arrow_down" : "keyboard_arrow_right" }}
         </button>
      </td>
   </ng-container>

   <ng-container lcsColumnDef="hiddenColumnPlaceHolder">
      <td
         lcs-cell
         *lcsCellDef="let row; let dataIndex=dataIndex; let renderIndex=renderIndex; let count=count; let first=first; let last=last; let even=even; let odd=odd"
         [attr.colspan]="hiddenPlaceHolderSpan"
      ></td>
   </ng-container>

   <ng-container lcsColumnDef="hiddenColumns">
      <td
         lcs-cell
         *lcsCellDef="let row; let dataIndex=dataIndex; let renderIndex=renderIndex; let count=count; let first=first; let last=last; let even=even; let odd=odd"
         [attr.colspan]="hiddenColumnSpan"
      >
         <div
            *ngFor="let hiddenColumn of hiddenColumns"
            class="lcs-table-responsive-item"
            role="grid"
         >
            <div
               class="lcs-table-responsive-item-header"
               [ngStyle]="responsiveItemHeaderStyle"
            >
               <ng-container
                  *ngTemplateOutlet="hiddenColumn.headerCell.template; context: { $implicit: row, renderIndex: renderIndex, dataIndex: dataIndex, count: count, first: first, last: last, even: even, odd: odd }"
               ></ng-container>
            </div>
            <div class="lcs-table-responsive-item-cell">
               <ng-container
                  *ngTemplateOutlet="hiddenColumn.cell.template; context: { $implicit: row, renderIndex: renderIndex, dataIndex: dataIndex, count: count, first: first, last: last, even: even, odd: odd }"
               ></ng-container>
            </div>
         </div>
      </td>
   </ng-container>
</table>

<tr
   lcs-row
   *lcsRowDef="let row; columns: responsiveRowColumnNames; when: rowIsExpanded"
></tr>