import { Injectable, OnDestroy } from "@angular/core";
import { KEY_ESCAPE } from "keycode-js";
import { filter, fromEvent, Subject, takeUntil } from "rxjs";

import { OverlayPanelRegistrationModel } from "./overlay-panel-registration.model";

@Injectable({
   providedIn: "root",
})
export class OverlayPanelRegistrationService implements OnDestroy {
   openOverlayPanels = new Map<number, OverlayPanelRegistrationModel>();

   private overlayPanelStack = new Array<number>();

   private unsubscribe = new Subject<void>();

   constructor() {
      fromEvent<KeyboardEvent>(document, "keydown")
         .pipe(
            filter((event) => this.overlayPanelStack.length > 0 && event.keyCode === KEY_ESCAPE),
            takeUntil(this.unsubscribe)
         )
         .subscribe((event) => {
            this.closeCurrentOverlayPanel();
            event.preventDefault();
            event.stopPropagation();
         });

      fromEvent<MouseEvent>(document, "mousedown")
         .pipe(
            filter(() => this.overlayPanelStack.length > 0),
            takeUntil(this.unsubscribe)
         )
         .subscribe((event) => {
            let overlayClicked = false;
            for (const panel of Array.from(this.openOverlayPanels.values())) {
               if (panel.closeWhenNonOverlayClicked) {
                  if (panel.contentElement.nativeElement.contains(event.target)) {
                     overlayClicked = true;
                     break;
                  }
               }
            }
            if (!overlayClicked) {
               for (const panel of Array.from(this.openOverlayPanels.values())) {
                  if (panel.closeWhenNonOverlayClicked) {
                     panel.closeCallback();
                  }
               }
            }
         });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   register(uniqueID: number, panel: OverlayPanelRegistrationModel) {
      this.overlayPanelStack.push(uniqueID);
      this.openOverlayPanels.set(uniqueID, panel);
      if (!panel.showIndependent) {
         this.closeOtherOverlayPanels(uniqueID, panel);
      }
   }

   closeOtherOverlayPanels(uniqueID: number, currentPanel: OverlayPanelRegistrationModel) {
      this.openOverlayPanels.forEach((panel, id) => {
         if (!panel.isGloballyWhitelist) {
            if (currentPanel.whiteList) {
               if (currentPanel.whiteList.indexOf(id) === -1) {
                  panel.closeCallback();
               }
            } else {
               panel.closeCallback();
            }
         }
      });
   }

   hasOverlayPanel(uniqueID: number) {
      return this.openOverlayPanels.has(uniqueID);
   }

   overlayPanelClosed(closedUniqueID: number) {
      this.overlayPanelStack.reduce((uniqueIDs: Array<number>, uniqueID: number) => {
         if (uniqueID !== closedUniqueID) {
            uniqueIDs.push(uniqueID);
         }
         return uniqueIDs;
      }, new Array<number>());
      this.openOverlayPanels.delete(closedUniqueID);
   }

   private closeCurrentOverlayPanel() {
      const currentOverlayPanelUniqueID = this.overlayPanelStack.pop();
      if (currentOverlayPanelUniqueID && this.openOverlayPanels.has(currentOverlayPanelUniqueID)) {
         const currentOverlayPanelCloseCallBackMethod =
            this.openOverlayPanels.get(currentOverlayPanelUniqueID)!.closeCallback;
         currentOverlayPanelCloseCallBackMethod();
      }
   }
}
