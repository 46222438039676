<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && reportPeriodTypeParameter.IsAsk === true)"
   >Report Type
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="reportPeriodTypeParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'report-period-type'"
      [disabled]="disabled || (hasAsk === true && reportPeriodTypeParameter.IsAsk === true)"
      [parameter]="reportPeriodTypeParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'week-start'"
      [disabled]="disabled || weekStartDisabled || (hasAsk === true && reportPeriodTypeParameter.IsAsk === true)"
      [parameter]="weekStartParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>