import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FooterModule } from "@lcs/footer/footer.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { DatePickerModule } from "@lcs/inputs/date-picker/date-picker.module";
import { NumericInputModule } from "@lcs/inputs/numeric-input/numeric-input.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";

import { LayoutModule } from "../layout/layout.module";
import { OWATableModule } from "../owa-table/owa-table.module";
import { CompletedComponent } from "./Completed/completed.component";
import { EpayACHComponent } from "./epay-ach/epay-ach.component";
import { RoutingNumberInfoComponent } from "./epay-ach/routing-number-info/routing-number-info.component";
import { EpayCCComponent } from "./epay-cc/epay-cc.component";
import { EpayComponent } from "./epay/epay.component";
import { OwnerContributionsListComponent } from "./owner-contributions-list.component";
import { OwnerContributionsComponent } from "./owner-contributions.component";

@NgModule({
   imports: [
      CommonModule,
      DataTableModule,
      DatePickerModule,
      FooterModule,
      FormRegistrationModule,
      FormsModule,
      LayoutModule,
      LcsProgressButtonModule,
      NumericInputModule,
      OWATableModule,
      SelectModule,
      TextBoxModule,
      ValidationModule,
      CheckboxModule,
   ],
   declarations: [
      OwnerContributionsListComponent,
      OwnerContributionsComponent,
      EpayACHComponent,
      EpayCCComponent,
      EpayComponent,
      CompletedComponent,
      RoutingNumberInfoComponent,
   ],
   exports: [OwnerContributionsListComponent, OwnerContributionsComponent],
})
export class OwnerContributionsModule {}
