import { ChangeDetectorRef, Component, EventEmitter, Input, isDevMode, OnDestroy, OnInit, Output, Renderer2, TemplateRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ApplicationStateService } from "@lcs/application-status/application-state.service";
import { ExpressActionViewModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-action-view-modes.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { ExpressActionEntityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action-entity-event.model";
import { BehaviorSubject, combineLatest, filter, Subject, takeUntil } from "rxjs";

import { ContextService } from "../action-context/context.service";
import { CreateUpdateUserInfoModel } from "../action-context/create-update-user-info.model";
import { LinkedInformationModel } from "../action-context/linked-information.model";
import { EntityDeleteService } from "../entity-events/entity-delete.service";
import { EntityEventResult } from "../entity-events/entity-event-result.enum";
import { EntityEventResultModel } from "../entity-events/entity-event-result.model";
import { EntityEventService } from "../entity-events/entity-event.service";
import { TriggerActionEventModel } from "../entity-events/trigger-action-event.model";
import { FormControlRegistrationModel } from "../forms/form-registration/form-control-registration.model";
import { FormRegistrationService } from "../forms/form-registration/form-registration.service";
import { FormStatuses } from "../forms/form-registration/form-statuses.enum";
import { ProgressButtonExtComponent } from "../progress-button/progress-button-ext/progress-button-ext.component";
import { CurrentFooterService } from "./current-footer.service";
import { ExpressFooterButtonModel } from "./express-footer-button.model";
import { FooterConfigurationModel } from "./footer-configuration.model";
import { FooterRegistrationService } from "./footer-registration.service";
import { convertActionEntityEventsToFooterButtons, FooterService } from "./footer.service";

@Component({
   selector: "lcs-footer",
   templateUrl: "footer.component.html",
})
export class FooterComponent implements OnInit, OnDestroy {
   @Input() set footerButtons(val: Array<ExpressFooterButtonModel>) {
      if (val) {
         this.footerButtonsOverridden = true;
         this.setFooterButtons(val);
      }
   }

   get footerButtons(): Array<ExpressFooterButtonModel> {
      return this._footerButtons;
   }

   @Input() set footerTemplate(val: TemplateRef<any> | null) {
      this._footerTemplate = val;
      this.updateFooterConfiguration();
   }

   get footerTemplate(): TemplateRef<any> | null {
      return this._footerTemplate;
   }

   @Input() set informationTemplate(informationTemplate: TemplateRef<any> | null) {
      this._informationTemplate = informationTemplate;
      this.updateFooterConfiguration();
   }

   get informationTemplate(): TemplateRef<any> | null {
      return this._informationTemplate ?? this.contextChanges.informationTemplate;
   }

   @Input() visible: boolean = true;

   @Input() siteFooter: boolean;

   @Input() progressButtonAdditionalStyling: string;

   @Input() set additionalFooterStyling(additionalFooterStyling: string | null) {
      this._additionalFooterStyling = additionalFooterStyling;
   }

   get additionalFooterStyling(): string {
      return this._additionalFooterStyling ?? this.contextChanges.additionalFooterStyling ?? "";
   }

   @Input() footerButtonsAdditionalStyling: {};

   @Input() set maxNumberOfButtons(maxNumberOfButtons: number | null) {
      this._maxNumberOfButtons = maxNumberOfButtons;
   }

   get maxNumberOfButtons(): number {
      return (
         this._maxNumberOfButtons ?? this.contextChanges.maxNumberOfButtons ?? FooterComponent.defaultMaxNumberOfButtons
      );
   }

   @Input() set footerConfiguration(config: FooterConfigurationModel | null) {
      this.footerConfigurationOverride = config;
      this.updateFooterConfiguration();
   }

   get footerConfiguration(): FooterConfigurationModel {
      return this._footerConfiguration;
   }

   @Output() buttonStatusChange = new EventEmitter<EntityEventResult>();

   entityEvents = ExpressEntityEvents;

   form: NgForm;

   formDisabled: boolean;

   formId: string;

   touchedInvalidControls = new Array<FormControlRegistrationModel>();

   allInvalidControls = new Array<FormControlRegistrationModel>();

   showErrors: boolean;

   showManualErrors: boolean;

   createAndUpdateUser: CreateUpdateUserInfoModel | null;

   contextChanges: {
      informationTemplate: TemplateRef<any> | null;
      additionalFooterStyling: string | null;
      maxNumberOfButtons: number | null;
   };

   additionalInfo: string | null;

   linkedInfo: LinkedInformationModel | null;

   footerID: string;

   animateFooter: boolean = false;

   private _footerConfiguration: FooterConfigurationModel;

   private footerConfigurationOverride: FooterConfigurationModel | null = null;

   private _footerButtons: Array<ExpressFooterButtonModel>;

   private currentViewMode: ExpressActionViewModes | undefined;

   private initialHasFooterVisibilityClass: boolean | undefined;

   private _footerTemplate: TemplateRef<any> | null;

   private _informationTemplate: TemplateRef<any> | null;

   private _additionalFooterStyling: string | null;

   private _maxNumberOfButtons: number | null;

   private footerButtonsOverridden;

   private get footerVisibilityClass(): string {
      switch (this.currentViewMode) {
         case ExpressActionViewModes.FullPage:
            return "site-footer-visible";
         case ExpressActionViewModes.Slideup:
            return "slide-panel-footer-visible";
         case ExpressActionViewModes.Dialog:
            return "dialog-footer-visible";
         default:
            return "";
      }
   }

   private disableButtonOverrides = {
      allButtons: false,
      allSiteButtons: false,
      saveButtons: false,
   };

   private expressActionFormExclusions = [
      ExpressActions.ReportBatch_Details,
      ExpressActions.ReportBatch_Add,
      ExpressActions.Estimate_Details,
   ];

   private expressActionFormDirtyExclusions = [ExpressActions.SystemPreferences];

   private isProcessingButtonClick: boolean = false;

   private static readonly defaultMaxNumberOfButtons = 3;

   private waitingOnFooterStatusDelay = new BehaviorSubject<boolean>(false);

   private stopListeningForFormValueChanges = new Subject<void>();

   private unsubscribe = new Subject<void>();

   constructor(
      private renderer: Renderer2,
      private formRegistrationService: FormRegistrationService,
      private entityEventService: EntityEventService,
      private contextService: ContextService,
      private applicationStateService: ApplicationStateService,
      private entityDeleteService: EntityDeleteService,
      private footerService: FooterService,
      private changeDetectorRef: ChangeDetectorRef,
      private currentFooterService: CurrentFooterService,
      private footerRegistrationService: FooterRegistrationService
   ) {
      this.currentFooterService.initialize(this);

      this.contextChanges = {
         informationTemplate: null,
         additionalFooterStyling: null,
         maxNumberOfButtons: null,
      };

      this.entityEventService.entityEventState.pipe(takeUntil(this.unsubscribe)).subscribe((entityEventState) => {
         if (
            entityEventState.entityEvents &&
            entityEventState.entityEvents.length > 0 &&
            !this.footerService.enabledStateOverriddenForAllButtons
         ) {
            this._footerButtons.forEach((footerButton) => {
               if (
                  entityEventState.entityEvents.indexOf(footerButton.expressActionEntityEvent?.ExpressEntityEvent) > -1
               ) {
                  footerButton.disabled = this.applyButtonDisableOverrides(
                     !entityEventState.enable,
                     footerButton.expressActionEntityEvent?.ExpressEntityEvent
                  );
                  footerButton.overridden = !entityEventState.enable;

                  if (isDevMode()) {
                     // This devMode log statement is to help clarify during development where footer button state is being
                     // implicitly modified.
                     console.log(
                        `DevMode: entityEventService.entityEventState ~ this._footerButtons.forEach ~ footerButton=`,
                        JSON.stringify(footerButton),
                        ` entityEventState=`,
                        JSON.stringify(entityEventState)
                     );
                  }
               }
            });
         }
      });

      this.applicationStateService.isRouting.pipe(takeUntil(this.unsubscribe)).subscribe((isRouting) => {
         if (isRouting && this.footerButtonsOverridden === false) {
            this._footerButtons = new Array<ExpressFooterButtonModel>();
            this.footerButtonsOverridden = false;
            this.visible = false;
         }
      });

      this.entityEventService.entityEventResult.pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
         if (
            this.isProcessingButtonClick &&
            (result.expressEntityEvent === ExpressEntityEvents.Save ||
               result.expressEntityEvent === ExpressEntityEvents.Cancel)
         ) {
            this.onFooterButtonProcessingEventUpdate(result);
         }

         this.updateFooterButtonEventResults(result);
      });

      this.footerService.disableSave.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         this.disableButtonOverrides.saveButtons = value;
         this.disableSave(value, true);
      });

      this.footerService.disableAll.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         this.disableButtonOverrides.allButtons = value;
         this.disableAll(value, true);
      });

      this.footerService.disableAllSite.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         this.disableButtonOverrides.allSiteButtons = value;
         if (this.siteFooter) {
            this.disableAll(value, true);
         }
      });

      this.footerService.siteFooterVisibilitySubject.pipe(takeUntil(this.unsubscribe)).subscribe((showSiteFooter) => {
         if (this.siteFooter && !this.footerConfiguration?.HideWhenPristine) {
            this.visible = showSiteFooter;
         }
      });

      this.footerService.hideFooter.pipe(takeUntil(this.unsubscribe)).subscribe((isFooterHidden: boolean) => {
         this.hideFooter(isFooterHidden);
      });

      this.contextService.additionalInformation.pipe(takeUntil(this.unsubscribe)).subscribe((additionalInfo) => {
         this.additionalInfo = additionalInfo;
         this.updateFooterConfiguration();
      });

      this.contextService.linkedInformation.pipe(takeUntil(this.unsubscribe)).subscribe((linkedInfo) => {
         this.linkedInfo = linkedInfo;
         this.updateFooterConfiguration();
      });
   }

   ngOnInit() {
      this.contextService.currentViewMode.pipe(takeUntil(this.unsubscribe)).subscribe((viewMode) => {
         this.resetFooterVisibilityClass();
         this.currentViewMode = viewMode;
         this.initialHasFooterVisibilityClass = document.body.classList?.contains(this.footerVisibilityClass);
         this.updateFooterConfiguration();
      });

      this.contextService.actionEntityEvents
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((actionEntityEvents) => this.setActionEntityEvents(actionEntityEvents));

      this.contextService.footerButtons.pipe(takeUntil(this.unsubscribe)).subscribe((footerButtons) => {
         if (footerButtons && !this.footerButtonsOverridden) {
            this.setFooterButtons(footerButtons);
         }
      });

      this.contextService.maxFooterButtonsMobile
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((maxFooterButtons: number | null) => {
            this.contextChanges.maxNumberOfButtons = maxFooterButtons;
            this.updateFooterConfiguration();
         });

      this.contextService.footerButtonAdditionalClasses
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((footerButtonAdditionalClasses) => {
            this.contextChanges.additionalFooterStyling = footerButtonAdditionalClasses;
            this.updateFooterConfiguration();
         });

      this.formRegistrationService.allErrors.pipe(takeUntil(this.unsubscribe)).subscribe((invalidControls) => {
         this.allInvalidControls = invalidControls;
         const touchedInvalidControls = invalidControls.filter((c) => c.FormControl.touched);
         this.touchedInvalidControls = touchedInvalidControls;
      });

      this.contextService.createAndUpdateUserInfo
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((createAndUpdateUserInfo) => {
            this.createAndUpdateUser = createAndUpdateUserInfo;
            this.updateFooterConfiguration();
         });

      this.contextService.footerInformationTemplate
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((informationTemplate: TemplateRef<any> | null) => {
            this.contextChanges.informationTemplate = informationTemplate;
            this.updateFooterConfiguration();
         });

      this.formRegistrationService.formSubject.pipe(takeUntil(this.unsubscribe)).subscribe((form: NgForm) => {
         this.form = form;
         if (form) {
            this.updateFooterConfiguration();
            this.setUpFormValueChangeListener();
            this.formId = this.formRegistrationService.formId;
         }
      });

      this.formRegistrationService.formStatusChange
         .pipe(
            filter((v) => v !== null),
            takeUntil(this.unsubscribe)
         )
         .subscribe((formStatus: FormStatuses) => {
            if (this.expressActionFormExclusions.some((e) => e == this.contextService.actionID)) {
               // some of the forms are not yet updated for reactive forms. when one control is set up for reactive forms and some are not, issues arise.
               return;
            }
            this.formDisabled = formStatus === FormStatuses.Disabled;
            const footerButtons = this.adjustButtonsOnDisabledForm(this._footerButtons, formStatus);
            this.setFooterButtons(footerButtons, false);
         });
   }

   ngOnDestroy() {
      this.resetFooterVisibilityClass();
      this.footerRegistrationService.deregister(this.footerID);
      this.unsubscribe.next();
   }

   setActionEntityEvents(actionEntityEvents: ExpressActionEntityEventModel[]) {
      const footerButtons = convertActionEntityEventsToFooterButtons(actionEntityEvents);

      if (!this.footerButtonsOverridden) {
         this.setFooterButtons(footerButtons);
      }
   }

   setFooterButtons(footerButtons: Array<ExpressFooterButtonModel>, updateFooterConfiguration: boolean = true) {
      if (this.formDisabled && updateFooterConfiguration) {
         footerButtons = this.adjustButtonsOnDisabledForm(footerButtons);
      }

      this._footerButtons = footerButtons;
      if (updateFooterConfiguration) {
         this.updateFooterConfiguration();
      }
   }

   onButtonClick(footerEventButton: ExpressFooterButtonModel) {
      if (footerEventButton.onClick) {
         footerEventButton.onClick();
      } else if (footerEventButton.expressActionEntityEvent) {
         this.isProcessingButtonClick = true;
         footerEventButton.entityEventResult = EntityEventResult.InProgress;
         this.changeDetectorRef.detectChanges();
         this.entityEventService.buildAndEmitEntityEventResult(
            footerEventButton.expressActionEntityEvent.ExpressEntityEvent,
            EntityEventResult.InProgress
         );
         const isValid =
            this.allInvalidControls.length === 0 &&
            ((this.formRegistrationService?.form?.valid ?? false) ||
               (!this.formRegistrationService?.form?.invalid ?? false));

         if (isValid || footerEventButton.expressActionEntityEvent.ExpressEntityEvent !== ExpressEntityEvents.Save) {
            if (footerEventButton.expressActionEntityEvent.ExpressEntityEvent === ExpressEntityEvents.Delete) {
               this.entityDeleteService.confirmEntityDelete(
                  this.contextService.entityType,
                  footerEventButton.deleteConfirmationOverride
               );
            } else {
               this.entityEventService.entityEvents.next(footerEventButton.expressActionEntityEvent.ExpressEntityEvent);
               this.entityEventService.triggerActionEvent.next(
                  new TriggerActionEventModel(
                     footerEventButton.expressActionEntityEvent.ExpressEntityEvent,
                     footerEventButton.expressActionEntityEvent.TriggerActionID as ExpressActions
                  )
               );
            }
         } else {
            for (const control of this.allInvalidControls) {
               control.FormControl.markAsTouched();
               control.FormControl.markAsDirty();
            }
            footerEventButton.entityEventResult = EntityEventResult.FailedValidation;
            this.entityEventService.buildAndEmitEntityEventResult(
               footerEventButton.expressActionEntityEvent.ExpressEntityEvent,
               EntityEventResult.FailedValidation
            );

            if (!this.expressActionFormDirtyExclusions.some((e) => e == this.contextService.actionID)) {
               this.formRegistrationService.markFormAsTouchedAndDirty();
            }
         }
      }
   }

   onErrorClick(control: FormControlRegistrationModel) {
      this.formRegistrationService.focusOnControl(control);
   }

   onShowErrorPanelChange(show: boolean) {
      if (this.showErrors !== show) {
         this.showErrors = show;
      }
   }

   onShowManualErrorPanelChange(show: boolean) {
      if (this.showManualErrors !== show) {
         this.showManualErrors = show;
      }
   }

   onButtonStatusChange(result: EntityEventResult): void {
      this.buttonStatusChange.emit(result);
   }

   onButtonDestroyed(button: ProgressButtonExtComponent): void {
      if (!this.isProcessingButtonClick && button.entityEventResult === EntityEventResult.InProgress) {
         this.buttonStatusChange.emit(EntityEventResult.Unset);
      }
   }

   disableSave(isDisabled: boolean, overrideConfiguration: boolean = false) {
      if (!this.footerConfiguration?.AllowAutoButtonDisableStateChanging && !overrideConfiguration) {
         return;
      }

      isDisabled = this.applyButtonDisableOverrides(isDisabled, ExpressEntityEvents.Save);
      if (this._footerButtons && this._footerButtons.length > 0) {
         this.forEachFooterButton({
            filter: (button) =>
               !button.overridden &&
               button.expressActionEntityEvent &&
               button.expressActionEntityEvent.ExpressEntityEvent === ExpressEntityEvents.Save,
            next: (button: ExpressFooterButtonModel) => {
               button.disabled = isDisabled;
            },
            changeDetector: (button) => button.disabled !== isDisabled,
         });
      }
   }

   disableAll(isDisabled: boolean, overrideConfiguration: boolean = false) {
      if (!this.footerConfiguration?.AllowAutoButtonDisableStateChanging && !overrideConfiguration) {
         return;
      }

      isDisabled = this.applyButtonDisableOverrides(isDisabled);
      if (this._footerButtons && this._footerButtons.length > 0) {
         this.forEachFooterButton({
            filter: (button) => !button.overridden,
            next: (button: ExpressFooterButtonModel) => {
               button.disabled = isDisabled;
            },
            changeDetector: (button) => button.disabled !== isDisabled,
         });
      }
   }

   disableCancel(isDisabled: boolean, overrideConfiguration: boolean = false) {
      if (!this.footerConfiguration?.AllowAutoButtonDisableStateChanging && !overrideConfiguration) {
         return;
      }

      isDisabled = this.applyButtonDisableOverrides(isDisabled);
      if (this._footerButtons && this._footerButtons.length > 0) {
         this.forEachFooterButton({
            filter: (button) =>
               !button.overridden &&
               button.expressActionEntityEvent &&
               button.expressActionEntityEvent.ExpressEntityEvent === ExpressEntityEvents.Cancel,
            next: (button: ExpressFooterButtonModel) => {
               button.disabled = isDisabled;
            },
            changeDetector: (button) => button.disabled !== isDisabled,
         });
      }
   }

   hideFooter(isFooterHidden: boolean, animateFooter: boolean = false) {
      const visibilityChanged = this.visible === isFooterHidden;
      this.visible = !isFooterHidden;
      this.animateFooter = animateFooter;

      if (visibilityChanged) {
         this.forEachFooterButton({
            next: (fb) => (fb.entityEventResult = EntityEventResult.Unset),
         });
         this.changeDetectorRef.detectChanges();
      }

      this.updateFooterVisibilityClass(this.visible);
   }

   resetFooterVisibility(): void {
      if (this.footerConfiguration?.HideWhenPristine) {
         this.toggleFooter(true);
         this.setUpFormValueChangeListener();
      } else {
         this.hideFooter(false);
      }
   }

   private applyButtonDisableOverrides(isDisabled: boolean, buttonAction?: ExpressEntityEvents): boolean {
      const disableAll =
         this.disableButtonOverrides.allButtons || (this.siteFooter && this.disableButtonOverrides.allSiteButtons);

      if (buttonAction === ExpressEntityEvents.Save) {
         return isDisabled || disableAll || this.disableButtonOverrides.saveButtons;
      } else {
         return isDisabled || disableAll;
      }
   }

   private forEachFooterButton(
      iterator: Partial<{
         next: (button: ExpressFooterButtonModel) => void;
         changeDetector?: (button: ExpressFooterButtonModel) => boolean;
         filter: (button: ExpressFooterButtonModel) => boolean;
      }>
   ): void {
      let changeDetected = false;
      this._footerButtons
         ?.filter((button) => (iterator.filter ? iterator.filter(button) : true))
         .forEach((button: ExpressFooterButtonModel) => {
            if (iterator.changeDetector) {
               changeDetected = changeDetected || iterator.changeDetector(button);
            }
            if (iterator.next) {
               iterator.next(button);
            }
         });
      if (changeDetected && iterator.changeDetector) {
         this.changeDetectorRef.markForCheck();
      }
   }

   private waitOnFooterButtonStatusDelay(callback: () => void): void {
      if (this.visible) {
         this.footerRegistrationService.waitOnFooterButtonStatusDelay(this.footerID, (_result) => callback());
      } else {
         callback();
      }
   }

   private updateFooterButtonEventResults(result: EntityEventResultModel): void {
      this.forEachFooterButton({
         filter: (button) => {
            return (
               button.expressActionEntityEvent &&
               button.expressActionEntityEvent.ExpressEntityEvent === result.expressEntityEvent &&
               ((result.triggerAction && button.expressActionEntityEvent.TriggerActionID === result.triggerAction) ||
                  (!result.triggerAction && !button.expressActionEntityEvent.TriggerActionID))
            );
         },
         next: (button) => (button.entityEventResult = result.entityEventResult),
         changeDetector: (button) => {
            return this.footerService.runCD ? button.entityEventResult !== result.entityEventResult : false;
         },
      });
   }

   private onFooterButtonProcessingEventUpdate(result: EntityEventResultModel): void {
      switch (result.entityEventResult) {
         case EntityEventResult.InProgress:
            this.onFooterButtonEventInProgress(result);
            break;
         case EntityEventResult.Success:
            this.onFooterButtonEventSuccess();
            break;
         case EntityEventResult.Error:
         case EntityEventResult.FailedValidation:
            this.onFooterButtonEventError();
            break;
         case EntityEventResult.Unset:
            this.onFooterButtonEventUnset();
            break;
      }
   }

   private onFooterButtonEventInProgress(result): void {
      if (result.expressEntityEvent === ExpressEntityEvents.Save) {
         this.disableCancel(true);
      } else {
         this.disableSave(true);
      }
   }

   private onFooterButtonEventSuccess(): void {
      this.setUpFormValueChangeListener();
      this.waitingOnFooterStatusDelay.next(true);

      if (this.footerConfiguration?.MarkFormAsPristineOnSuccess) {
         this.formRegistrationService.markAllFormsAsPristine();
      }

      this.waitOnFooterButtonStatusDelay(() => {
         this.disableSave(false);
         this.disableCancel(false);
         if (!this.form?.dirty) {
            this.toggleFooter(true);
         }
         this.waitingOnFooterStatusDelay.next(false);
      });

      this.isProcessingButtonClick = false;
   }

   private onFooterButtonEventError(): void {
      this.waitingOnFooterStatusDelay.next(true);

      if (!this.visible) {
         this.toggleFooter(false);
      }

      this.waitOnFooterButtonStatusDelay(() => {
         this.disableSave(false);
         this.disableCancel(false);
         this.waitingOnFooterStatusDelay.next(false);
      });

      this.isProcessingButtonClick = false;
   }

   private onFooterButtonEventUnset(): void {
      this.disableSave(false);
      this.disableCancel(false);
      this.isProcessingButtonClick = false;
   }

   private adjustButtonsOnDisabledForm(
      buttons: Array<ExpressFooterButtonModel>,
      formStatus?: FormStatuses
   ): Array<ExpressFooterButtonModel> {
      return buttons.map((fb) => {
         if (
            (!fb.expressActionEntityEvent ||
               fb.expressActionEntityEvent.ExpressEntityEvent !== ExpressEntityEvents.Cancel) &&
            !fb.overridden &&
            !this.footerService.enabledStateOverriddenForAllButtons &&
            this.footerConfiguration?.AllowAutoButtonDisableStateChanging
         ) {
            fb.disabled = this.applyButtonDisableOverrides(this.formDisabled);
            if (isDevMode() && formStatus != undefined) {
               // This devMode log statement is to help clarify during development where footer button state is being
               // implicitly modified.
               console.log(
                  `DevMode: formRegistrationService.formStatusChange("${formStatus}") ~ footerButtons ~ fb.label="${fb.label}" fb.disabled=${fb.disabled} fb=`,
                  JSON.stringify(fb)
               );
            }
         }
         return fb;
      });
   }

   private updateFooterVisibilityClass(addFooterVisibilityClass: boolean) {
      if (
         !this.footerVisibilityClass ||
         (this.currentViewMode === ExpressActionViewModes.FullPage && !this.siteFooter)
      ) {
         return;
      }

      if (addFooterVisibilityClass) {
         this.renderer.addClass(document.body, this.footerVisibilityClass);
      } else {
         this.renderer.removeClass(document.body, this.footerVisibilityClass);
      }
   }

   private resetFooterVisibilityClass(): void {
      if (this.initialHasFooterVisibilityClass != undefined) {
         this.updateFooterVisibilityClass(this.initialHasFooterVisibilityClass);
      }
   }

   private toggleFooter(isFooterHidden: boolean) {
      if (this.footerConfiguration?.HideWhenPristine || (this.visible && this.form?.disabled)) {
         this.hideFooter(isFooterHidden, true);
      }
   }

   private setUpFormValueChangeListener() {
      if (!this.form?.valueChanges) {
         return;
      }

      this.stopListeningForFormValueChanges.next();

      combineLatest([this.form.valueChanges, this.waitingOnFooterStatusDelay])
         .pipe(takeUntil(this.stopListeningForFormValueChanges))
         .subscribe(([_valueChanges, waitingOnFooterStatusDelay]) => {
            if (this.form?.dirty && !waitingOnFooterStatusDelay) {
               this.disableSave(false);
               this.disableCancel(false);
               this.toggleFooter(false);

               this.stopListeningForFormValueChanges.next();
            }
         });
   }

   private updateFooterConfiguration(): void {
      if (this.currentViewMode == null) {
         return;
      }

      if (this.footerConfigurationOverride) {
         this._footerConfiguration = this.footerConfigurationOverride;
      } else {
         if (this.isAutomaticallyHiddenFooter(this.footerButtons, this.currentViewMode)) {
            this._footerConfiguration = FooterConfigurationModel.AutomaticHideConfiguration;
         } else {
            this._footerConfiguration = FooterConfigurationModel.AlwaysDisplayConfiguration;
         }
      }

      if (this.footerConfiguration.ToggleOnConfigurationChange) {
         if (this.footerConfiguration.HideWhenPristine) {
            this.hideFooter(!this.form?.dirty, this.animateFooter);
         } else {
            this.hideFooter(!this.hasContent());
         }
      }
   }

   private hasContent(): boolean {
      return (this.footerButtons && this.footerButtons.length > 0) || this.hasExtraContent();
   }

   private hasExtraContent(): boolean {
      return (
         this.footerTemplate != null ||
         this.informationTemplate != null ||
         this.linkedInfo != null ||
         this.createAndUpdateUser != null ||
         !!this.additionalInfo
      );
   }

   private isAutomaticallyHiddenFooter(
      footerButtons: Array<ExpressFooterButtonModel> | null,
      viewMode: ExpressActionViewModes | undefined
   ) {
      const hasSaveButton = footerButtons?.some(
         (fb) => fb.expressActionEntityEvent?.ExpressEntityEvent === ExpressEntityEvents.Save
      );
      const hasCancelButton = footerButtons?.some(
         (fb) => fb.expressActionEntityEvent?.ExpressEntityEvent === ExpressEntityEvents.Cancel
      );
      const supportedViewModes: Array<ExpressActionViewModes | undefined> = [
         ExpressActionViewModes.FullPage,
         ExpressActionViewModes.Slideup,
      ];

      const hasExtraContent: boolean = this.hasExtraContent();

      return (
         footerButtons?.length == 2 &&
         hasSaveButton &&
         hasCancelButton &&
         supportedViewModes.includes(viewMode) &&
         !hasExtraContent
      );
   }
}
