<lcs-loading-overlay
   [show]="status === progressButtonStatus.InProgress"
   [configuration]="loadingOverlayConfiguration"
   [showSpinner]="true"
>
   <button
      type="{{ type }}"
      mat-button
      [disabled]="disabled"
      [ngStyle]="buttonCustomStyle"
      [ngClass]="buttonCustomClasses"
      [attr.form]="formId"
      [class.mat-raised-button]="raised"
      [class.for-multi-select-action-button]="multiSelectActionButton"
      [class.successful]="status === progressButtonStatus.Success"
      [class.error]="status === progressButtonStatus.Error"
      [class.warning]="status === progressButtonStatus.Warning"
      (click)="onButtonClick()"
   >
      <span
         #buttonText
         class="progress-button-text"
         [class.icon-visible]="!!status"
      >
         <ng-content></ng-content>
      </span>
      <i
         *ngIf="
            status === progressButtonStatus.Success ||
            status === progressButtonStatus.Error ||
            status === progressButtonStatus.Warning
         "
         class="material-icons progress-icon"
      >
         {{ status === progressButtonStatus.Success ? "check" : "close" }}
      </i>
   </button>
</lcs-loading-overlay>
