import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/scrolling";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActionBarService } from "@lcs/action-bar/action-bar.service";
import { ActionPayloadsService } from "@lcs/action-context/action-payloads.service";
import { ContextService } from "@lcs/action-context/context.service";
import { SubComponentStatusService } from "@lcs/action-context/sub-component-status.service";
import { globalVariables } from "@lcs/core/globals";
import { EntityDeleteService } from "@lcs/entity-events/entity-delete.service";
import { EntityEventService } from "@lcs/entity-events/entity-event.service";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { MobileFocusOverlayService } from "@lcs/mobile-focus-overlay/mobile-focus-overlay.service";
import { OverlayPanelRegistrationModel } from "@lcs/overlay-panel/overlay-panel-registration.model";
import { OverlayPanelRegistrationService } from "@lcs/overlay-panel/overlay-panel-registration.service";
import { ZIndexContextService } from "@lcs/styles/z-index-context.service";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { Dialog } from "primeng/dialog";
import { DomHandler } from "primeng/dom";
import { ExpressActionViewModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-action-view-modes.enum";
import { Subject } from "rxjs";

import { CurrentDialogService } from "./services/current-dialog.service";

@Component({
   selector: "lcs-dialog",
   templateUrl: "dialog.component.html",
   providers: [
      DomHandler,
      FormRegistrationService,
      ContextService,
      EntityEventService,
      ZIndexContextService,
      SubComponentStatusService,
      EntityDeleteService,
      CurrentDialogService,
      ActionPayloadsService,
      ActionBarService,
   ],
})
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {
   @Input() canClose: boolean = true;

   @Input() closeButtonInFooter: boolean;

   @Input() closeButtonText: string;

   @Input() set disabled(value: boolean) {
      if (value) {
         this.formRegistrationService.disableForm();
      } else {
         this.formRegistrationService.enableForm();
      }
   }

   @Input() draggable: boolean = false;

   @Input() height: number;

   @Input() heightUnit: string;

   @Input() closeOnOutsideClick: boolean = false;

   @Input() modal: boolean = true;

   @Input() resizable: boolean = false;

   @Input() showHeader: boolean = false;

   @Input() styleClass: string = "";

   @Input() title: string;

   @Input() set visible(val: boolean) {
      if (val !== this._visible) {
         this._visible = val;
         this.visibleChange.emit(this._visible);
      }
   }

   @Input() width: number;

   @Input() widthUnit: string;

   @Input() baseZIndex: number = 0;

   @Input() helpUrl: string;

   @Input() overrideFocus: boolean;

   @Input() headingButtonsTemplate: TemplateRef<any>;

   @Input() closeCallbackOverride: (() => void) | null = null;

   @Input() overflowYHidden: boolean;

   @Output() visibleChange = new EventEmitter<boolean>();

   @ViewChild(NgForm, { static: true }) public form: NgForm;

   @ViewChild("contentPlaceholder", { read: ViewContainerRef, static: true })
   public contentPlaceholder: ViewContainerRef;

   @ViewChild("footerPlaceholder", { read: ViewContainerRef, static: true }) public footerPlaceholder: ViewContainerRef;

   @ViewChild("Dialog", { static: true }) dialog: Dialog;

   get visible(): boolean {
      return this._visible;
   }

   childComponentType?: any;

   isIOS = globalVariables.isIOS;

   onClose: Function;

   onShow: Function;

   get inlineStyle(): any {
      const style: any = {
         minHeight: "auto",
      };
      if (this.height) {
         style.height = `${this.height}${this.heightUnit}`;
      }
      if (this.width) {
         style.width = `${this.width}${this.widthUnit}`;
      }
      return style;
   }

   private _visible: boolean;

   private unsubscribe = new Subject<void>();

   private uniqueID: number;

   constructor(
      private scrollDispatcher: ScrollDispatcher,
      private zone: NgZone,
      private overlayPanelRegistrationService: OverlayPanelRegistrationService,
      private currentDialogService: CurrentDialogService,
      private uniqueIDService: UniqueIDService,
      private formRegistrationService: FormRegistrationService,
      private mobileFocusOverlayService: MobileFocusOverlayService,
      private contextService: ContextService
   ) {
      this.uniqueID = this.uniqueIDService.getUniqueID();
      this.currentDialogService.initialize(this);
      this.contextService.currentViewMode.next(ExpressActionViewModes.Dialog);
   }

   ngOnInit() {
      if (this.onShow) {
         this.onShow();
      }

      if (this.canClose && !this.overlayPanelRegistrationService.hasOverlayPanel(this.uniqueID)) {
         const registration = new OverlayPanelRegistrationModel();
         registration.closeCallback = this.closeDialog.bind(this);
         this.overlayPanelRegistrationService.register(this.uniqueID, registration);
      }
   }

   ngAfterViewInit() {
      if (this.dialog.contentViewChild) {
         this.initScrollEvents(this.dialog.contentViewChild);
      }

      if (!this.overrideFocus && !this.mobileFocusOverlayService.canGoIntoMobileFocusMode()) {
         setTimeout(() => {
            const inputsBody = this.contentPlaceholder.element.nativeElement.parentElement.querySelectorAll(
               'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            let focusableInput;
            if (inputsBody.length > 0) {
               for (let i = 0; i < inputsBody.length; i++) {
                  if (
                     !inputsBody[i].disabled &&
                     inputsBody[i].style.display !== "none" &&
                     inputsBody[i].localName !== "tr"
                  ) {
                     focusableInput = inputsBody[i];
                     focusableInput.focus();
                     break;
                  }
               }
            }
            if (!focusableInput) {
               const inputsFooter =
                  this.footerPlaceholder.element.nativeElement.parentElement.querySelectorAll("button");
               if (inputsFooter.length > 0) {
                  for (let i = 0; i < inputsFooter.length; i++) {
                     if (!inputsFooter[i].disabled) {
                        focusableInput = inputsFooter[i];
                        focusableInput.focus();
                        break;
                     }
                  }
               }
            }
         });
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      if (this.onClose) {
         this.onClose();
      }
   }

   closeDialog(ignoreCloseCallbackOverride?: boolean) {
      if (this.closeCallbackOverride && !ignoreCloseCallbackOverride) {
         this.closeCallbackOverride();
         return;
      }
      this.visible = false;
      this.overlayPanelRegistrationService.overlayPanelClosed(this.uniqueID);
   }

   private initScrollEvents(contentElement: ElementRef) {
      this.scrollDispatcher.register(new CdkScrollable(contentElement, this.scrollDispatcher, this.zone));
   }
}
