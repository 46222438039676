import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";

import { ApiUrlConstants } from "../core/api-url.constants";
import { LogicalOperators } from "../enumerations/generated/logical-operators.enum";
import { FilterOption } from "./filter-option.model";
import { FilterExpressionTransferModel } from "./generated/filter-expression-transfer.model";

export class FilterExpression {
   SubExpressions: Array<FilterExpression>;
   FilterOptions: Array<FilterOption>;
   LogicalOperator: LogicalOperators;

   ExcludeAllOverride: boolean;

   constructor(logicalOperator?: LogicalOperators) {
      this.ExcludeAllOverride = false;
      this.SubExpressions = new Array<FilterExpression>();
      this.FilterOptions = new Array<FilterOption>();
      this.LogicalOperator = logicalOperator ? logicalOperator : LogicalOperators.And;
   }

   toApiString(uriEncode = true): string {
      return FilterExpression.toApiString(
         this.LogicalOperator,
         cloneDeep(this.SubExpressions),
         cloneDeep(this.FilterOptions),
         uriEncode
      );
   }

   /**
    * This ONLY supports expressions that contain only 'And' operators.
    * If any 'Or' or 'NotSet' LogicalOperators are found an error will be thrown.
    * Otherwise, it will flatten all filterOptions and nested SubExpressions into a single
    * flattened list of filterOptions 'And'ed together
    */
   toFilterOptionsArray(): Array<FilterOption> {
      if (!(this.FilterOptions?.length > 0) && !(this.SubExpressions?.length > 0)) {
         // no filterOptions and no SubExpressions then return empty array
         return new Array<FilterOption>();
      }
      if (this.LogicalOperator !== LogicalOperators.And) {
         throw new Error(
            `toFilterOptionsArray() failed. Method only supports filterExpressions containing only 'And' operators. FilterExpress=${JSON.stringify(
               this,
               null,
               2
            )}`
         );
      }
      const filterOptions: Array<FilterOption> =
         this.FilterOptions?.length > 0 ? this.FilterOptions : new Array<FilterOption>();
      return filterOptions.concat(
         flatten(
            this.SubExpressions.map((subExpression: FilterExpression) => {
               return subExpression.toFilterOptionsArray();
            })
         )
      );
   }

   toTransferModel(): FilterExpressionTransferModel {
      const transferModel: FilterExpressionTransferModel = new FilterExpressionTransferModel();
      transferModel.LogicalOperator = this.LogicalOperator;
      transferModel.FilterOptions = this.FilterOptions?.map((f) => f.toTransferModel());
      transferModel.SubExpressions = this.SubExpressions.map((s) => s.toTransferModel());
      return transferModel;
   }

   private static toApiString(
      logicalOperator: LogicalOperators,
      subExpressions: Array<FilterExpression>,
      filterOptions: Array<FilterOption>,
      uriEncode: boolean
   ): string {
      const clauses = new Array<string>();

      if (subExpressions) {
         // If this expression and all subexpressions are ands, they can be collapsed - we dont need to, but it makes for easier parsing and mocking
         if (
            logicalOperator === LogicalOperators.And &&
            subExpressions.filter((se) => se.LogicalOperator === LogicalOperators.Or).length === 0
         ) {
            const expressionsWithoutSubexpressions = subExpressions.filter((se) => se.SubExpressions.length === 0);
            filterOptions = filterOptions.concat(...expressionsWithoutSubexpressions.map((se) => se.FilterOptions));

            const expressionsWithSubexpressions = subExpressions.filter((se) => se.SubExpressions.length > 0);
            subExpressions = expressionsWithSubexpressions;
         }
         for (const expression of subExpressions) {
            const subExpressionString = expression.toApiString(uriEncode);
            if (subExpressionString) {
               clauses.push(ApiUrlConstants.wrapExpression(subExpressionString));
            }
         }
      }

      if (filterOptions) {
         for (const filterOption of filterOptions) {
            clauses.push(filterOption.toApiString(uriEncode));
         }
      }

      let separator = ApiUrlConstants.AndSeparator;
      if (logicalOperator === LogicalOperators.Or) {
         separator = ApiUrlConstants.OrSeparator;
      }
      return clauses.join(separator);
   }
}
