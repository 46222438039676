import { Observable, Subject, timer } from "rxjs";

export class ImmediateAuditor<T> {
   private _timespan: number;
   private _auditing: boolean;
   private _hasReceivedValueSinceLastAudit: boolean;
   private _storedValue: T;

   observable: Subject<T> = new Subject<T>();

   constructor(inputObservable: Observable<T>, timespan: number) {
      this._timespan = timespan;
      inputObservable.subscribe((item) => {
         if (this._auditing) {
            this._hasReceivedValueSinceLastAudit = true;
            this._storedValue = item;
         } else {
            this.observable.next(item);

            this._auditing = true;
            this.scheduleNextEmission();
         }
      });
   }

   scheduleNextEmission() {
      timer(this._timespan).subscribe(() => {
         if (this._hasReceivedValueSinceLastAudit) {
            this._hasReceivedValueSinceLastAudit = false;
            this.observable.next(this._storedValue);
            this.scheduleNextEmission();
         } else {
            this._auditing = false;
         }
      });
   }
}
