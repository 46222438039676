import { BillPayMethod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/bill-pay-method.enum";
import { OwnerStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/owner-status.enum";

import { AddressModel } from "./address.model";
import { BillableExpenseModel } from "./billable-expense.model";
import { ChargeModel } from "./charge.model";
import { ColorModel } from "./color.model";
import { ContactModel } from "./contact.model";
import { EpayInformationModel } from "./epay-information.model";
import { GLAccountModel } from "./glaccount.model";
import { HistoryCallModel } from "./history-call.model";
import { HistoryEmailModel } from "./history-email.model";
import { HistoryNoteModel } from "./history-note.model";
import { HistorySystemNoteModel } from "./history-system-note.model";
import { HistoryVisitModel } from "./history-visit.model";
import { HistoryModel } from "./history.model";
import { NachaOwnerBankModel } from "./nacha-owner-bank.model";
import { OwnerCheckSummaryModel } from "./owner-check-summary.model";
import { OwnerContractModel } from "./owner-contract.model";
import { OwnerGroupDetailModel } from "./owner-group-detail.model";
import { OwnerGroupModel } from "./owner-group.model";
import { OwnerWebAccessOwnerPreferenceModel } from "./owner-web-access-owner-preference.model";
import { OwnerWebAccessReportPrivilegeModel } from "./owner-web-access-report-privilege.model";
import { PaymentModel } from "./payment.model";
import { PhoneNumberModel } from "./phone-number.model";
import { RMVoIPCallHistoryModel } from "./rmvo-ipcall-history.model";
import { UserDefinedValueModel } from "./user-defined-value.model";
import { UserModel } from "./user.model";
import { WebUserAccountModel } from "./web-user-account.model";
import { WebUserModel } from "./web-user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OwnerModel {
   Addresses: Array<AddressModel>;
   Balance?: number | null ;
   BalanceAccount: GLAccountModel;
   BalanceAccountID?: number | null ;
   BalancingJournalMemo: string;
   BillableExpenses: Array<BillableExpenseModel>;
   Charges: Array<ChargeModel>;
   CheckPayeeName: string;
   Color: ColorModel;
   ColorID?: number | null ;
   Comment: string;
   Contact: ContactModel;
   ContributionAccount: GLAccountModel;
   ContributionAccountID?: number | null ;
   CreateDate: Date;
   CreateUser: UserModel;
   CreateUserID: number;
   DefaultPayMethod: BillPayMethod;
   DisplayName: string;
   DrawAccount: GLAccountModel;
   DrawAccountID?: number | null ;
   EcheckEmail: string;
   EpayInfo: Array<EpayInformationModel>;
   History: Array<HistoryModel>;
   HistoryCalls: Array<HistoryCallModel>;
   HistoryEmails: Array<HistoryEmailModel>;
   HistoryNotes: Array<HistoryNoteModel>;
   HistorySystemNotes: Array<HistorySystemNoteModel>;
   HistoryVisits: Array<HistoryVisitModel>;
   HistoryWithUnitHistory: Array<HistoryModel>;
   IsBalanceReserves: boolean;
   IsOverridingCheckDefault: boolean;
   IsSeparateChecks: boolean;
   IsStopPrintChecks: boolean;
   LastPayDate?: Date | null ;
   MetaTag?: string;
   NachaOwnerBank: NachaOwnerBankModel;
   Name: string;
   OwnerChecks: Array<OwnerCheckSummaryModel>;
   OwnerContracts: Array<OwnerContractModel>;
   OwnerGroupDetails: Array<OwnerGroupDetailModel>;
   OwnerGroups: Array<OwnerGroupModel>;
   OwnerID: number;
   OwnerReportBatchID?: number | null ;
   Ownerships: Array<OwnerContractModel>;
   OwnerWebAccessOwnerPreference: OwnerWebAccessOwnerPreferenceModel;
   OwnerWebAccessOwnerPreferences: OwnerWebAccessOwnerPreferenceModel;
   OwnerWebAccessReportPrivileges: Array<OwnerWebAccessReportPrivilegeModel>;
   Payments: Array<PaymentModel>;
   PhoneNumbers: Array<PhoneNumberModel>;
   RMVoIPCallHistory: Array<RMVoIPCallHistoryModel>;
   StatementComment: string;
   Status: OwnerStatus;
   TaxID: string;
   UpdateDate: Date;
   UpdateUser: UserModel;
   UpdateUserID: number;
   UserDefinedValues: Array<UserDefinedValueModel>;
   WebMessage: string;
   WebUserAccounts: Array<WebUserAccountModel>;
   WebUsers: Array<WebUserModel>;
}
