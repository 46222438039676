<lcs-loading-overlay [show]="$any(isLoading | async)">
   <div class="header-message">Documents to Sign</div>
   <div class="content-wrapper detail-layout">
      <div class="signable-documents-list-container">
         <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
         <div
            *ngIf="!signableDocumentPackets || $any((signableDocumentPackets | async)).length === 0"
            class=no-documents
         >
            There are currently no documents available for sign or view.
         </div>
         <owa-signable-documents-list-item
            class="flex-stay"
            *ngFor="let signableDocumentPacket of signableDocumentPackets | async"
            [signableDocumentPacket]="signableDocumentPacket"
         >
         </owa-signable-documents-list-item>
      </div>
   </div>
</lcs-loading-overlay>