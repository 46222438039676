<owa-layout
   [headerTitle]="loggedInUser | titlecase"
   [backgroundColorClass]="'detail-layout'"
>
   <div class="dashboard-container">
      <div class="dashboard-header">
         <owa-signable-documents-dashboard id="SignableDocumentsMessageTile"></owa-signable-documents-dashboard>
      </div>
      <lcs-loading-overlay [show]="!(ownerLoaded | async)">
         <div class="dashboard-header">
            <owa-estimates-dashboard id="EstimatesMessageTile"></owa-estimates-dashboard>
         </div>
         <div
            class="dashboard-content-wrapper"
            *ngIf="(ownerLoaded | async) &&(preferencesLoaded | async)"
         >
            <div
               *ngIf="(generalMessage !== '' || ownerMessage !== '') && MessagesSortOrder !== undefined   "
               [style.order]="MessagesSortOrder"
               class="flex-third"
            >
               <owa-dashboard-messages id="MessagesTile"></owa-dashboard-messages>
            </div>
            <div
               *ngIf="!isOwner"
               class="flex-third"
            >
               <owa-dashboard-owner-prospect-info id="OwnerProspectTile"></owa-dashboard-owner-prospect-info>
            </div>
            <div
               *ngIf="!isOwner"
               class="flex-third"
            >
               <owa-dashboard-signable-documents id="SignableDocumentsTile"></owa-dashboard-signable-documents>
            </div>

            <div
               *ngIf="isOwner && (preferencesLoaded | async) && OwnershipsSortOrder !== undefined  "
               [style.order]="OwnershipsSortOrder"
               class="flex-third"
            >
               <owa-dashboard-ownerships
                  [showReserve]="showReserve"
                  [showOwnershipPercentage]="showOwnershipPercentage"
                  [showSecurityDepositHeld]="showSecurityDepositHeld"
                  [allowOwnerToEmailPropertyManager]="allowOwnerToEmailPropertyManager"
                  id="OwnershipsTile"
               >
               </owa-dashboard-ownerships>
            </div>

            <div
               *ngIf="showNotes && isOwner && NotesSortOrder != undefined "
               [style.order]="NotesSortOrder"
               class="flex-third"
            >
               <owa-dashboard-notes id="NotesTile"></owa-dashboard-notes>
            </div>
            <div
               *ngIf="showBills && isOwner && BillsSortOrder !== undefined "
               [style.order]="BillsSortOrder"
               class="flex-third"
            >
               <owa-dashboard-bills id="BillsTile"></owa-dashboard-bills>
            </div>
            <div
               *ngIf="showInspectionsPreference && isOwner && InspectionsSortOrder != undefined "
               [style.order]="InspectionsSortOrder"
               class="flex-third"
            >
               <owa-dashboard-inspection-report id="InspectionReportsTile"></owa-dashboard-inspection-report>
            </div>
            <div
               *ngIf="showChecksPreference && isOwner && ChecksSortOrder !== undefined "
               [style.order]="ChecksSortOrder"
               class="flex-third"
            >
               <owa-dashboard-checks id="ChecksTile"></owa-dashboard-checks>
            </div>
            <div
               *ngIf="showManagementFees && isOwner && ManagementFeesSortOrder !== undefined "
               [style.order]="ManagementFeesSortOrder"
               class="flex-third"
            >
               <owa-dashboard-tile-management-fee id="ManagementFeeTile"></owa-dashboard-tile-management-fee>
            </div>
            <div
               *ngIf="showOwnerDistributions && isOwner && OwnerDistributionsSortOrder !== undefined "
               [style.order]="OwnerDistributionsSortOrder"
               class="flex-third"
            >
               <owa-dashboard-owner-distributions id="OwnerDistributionsTile"></owa-dashboard-owner-distributions>
            </div>
            <div
               *ngIf="(showOpenServiceIssuesOWAPreference || showClosedServiceIssuesOWAPreference) && isOwner && ServiceIssuesSortOrder != undefined
              "
               [style.order]="ServiceIssuesSortOrder"
               class="flex-third"
            >
               <owa-dashboard-issues id="IssuesTile"></owa-dashboard-issues>
            </div>
            <div
               *ngIf="showOpenEstimatesOWAPreference && EstimatesSortOrder != undefined "
               [style.order]="EstimatesSortOrder"
               class="flex-third"
            >
               <owa-dashboard-estimates id="EstimatesTile"></owa-dashboard-estimates>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>