import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ConstantsService {
   static readonly ArrayIndexNotFound = -1;

   static readonly StringIndexNotFound = -1;
   static readonly NullFK: number = -1;
   static readonly ZeroFK: number = -2;

   /**
    * Currently the Javascript number type is a 64-bit floating point value and there is no constant max value for a 32 bit integer.
    * @returns **2147483647**
    */
   static readonly Int32MaxValue: number = 2147483647;

   static readonly qManageTicketURL: string = "https://lcs.app.qmanage.com/tickets/";

   static readonly DefaultNumericMin: number = -999999999;
   static readonly DefaultNumericMax: number = 999999999;
   static readonly allValue: string = "all";

   static readonly unselected = "unselected";

   static readonly RM12ProductName: string = "RM12";

   static readonly RMXFirstReleaseNotesVersion: string = "12.600";

   static get RMXFirstReleaseNotesDate(): Date {
      return new Date(2020, 4); // May 2020, month is 0-indexed
   }

   static readonly ReviewInRM12Blurb: string = `Please use ${ConstantsService.RM12ProductName} to review it.`;

   static readonly reportNotSupportedHeader = "Report not supported";

   static readonly reportNotSupportedInformation = "Some of the report parameters are not supported";

   static readonly ECheckDefaultNextCheckNumber = 20000;

   static readonly PayleaseUtilityBillingNullApplicantType = "** Contacts with no applicant type";

   static readonly PayleaseUtilityBillingNullContactType = "** Contacts with no contact type";

   static readonly NoValidTextNumbersFoundSingularMessage =
      "No valid, authorized, text ready phone number available for the selected entity."; // NOTE: entity will be replaced by singular label for the entity type

   static readonly NoValidTextNumbersFoundPluralMessage =
      "No valid, authorized, text ready phone numbers available for the selected entities."; // NOTE: entities will be replaced by collection label for entity type

   static readonly pageOutOfDateRequiresRefreshMessage =
      "Some information on this page is out of date. This page will now refresh to show updated information. ";

   static readonly FullMenuZIndex = 100000;

   constructor() {}
}
