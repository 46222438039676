import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { RouterModule } from "@angular/router";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { TableModule } from "@lcs/table/table.module";

import { EmailModule } from "../email/email.module";
import { EstimatesDashboardComponent } from "../estimates-dashboard/estimates-dashboard.component";
import { LayoutModule } from "../layout/layout.module";
import { SignableDocumentsListModule } from "../signable-documents-list/signable-documents-list.module";
import { SignableDocumentsDashboardComponent } from "../signable-documents/signable-documents-dashboard/signable-documents-dashboard.component";
import { UserProfileModule } from "./../user-profile/user-profile.module";
import { DashboardListViewComponent } from "./dashboard-list-view.component";
import { DashboardTileViewComponent } from "./dashboard-tile-view.component";
import { DashboardBillsComponent } from "./tiles/dashboard-tile-bills.component";
import { DashboardChecksComponent } from "./tiles/dashboard-tile-checks.component";
import { DashboardEstimatesComponent } from "./tiles/dashboard-tile-estimates.component";
import { DashboardNotesComponent } from "./tiles/dashboard-tile-history.component";
import { DashboardInspectionReportComponent } from "./tiles/dashboard-tile-inspection-report.component";
import { DashboardIssuesComponent } from "./tiles/dashboard-tile-issues.component";
import { DashboardManagementFeeComponent } from "./tiles/dashboard-tile-management-fee.component";
import { DashboardMessagesComponent } from "./tiles/dashboard-tile-messages.component";
import { DashboardOwnerDistributionsComponent } from "./tiles/dashboard-tile-owner-distributions.component";
import { DashboardOwnerProspectInfoComponent } from "./tiles/dashboard-tile-owner-prospect-info.component";
import { DashboardOwnershipComponent } from "./tiles/dashboard-tile-ownerships.component";
import { DashboardSignableDocumentsComponent } from "./tiles/dashboard-tile-signable-documents.component";

@NgModule({
   imports: [
      CommonModule,
      MatTooltipModule,
      LayoutModule,
      TableModule,
      RouterModule,
      EmailModule,
      SignableDocumentsListModule,
      LoadingOverlayModule,
      UserProfileModule,
   ],
   declarations: [
      DashboardListViewComponent,
      DashboardTileViewComponent,
      DashboardOwnershipComponent,
      DashboardChecksComponent,
      DashboardIssuesComponent,
      DashboardBillsComponent,
      DashboardNotesComponent,
      DashboardManagementFeeComponent,
      DashboardOwnerDistributionsComponent,
      SignableDocumentsDashboardComponent,
      DashboardMessagesComponent,
      DashboardInspectionReportComponent,
      DashboardOwnerProspectInfoComponent,
      DashboardSignableDocumentsComponent,
      EstimatesDashboardComponent,
      DashboardEstimatesComponent,
   ],
   exports: [DashboardListViewComponent],
})
export class DashboardModule {}
