import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { OverlayPanelsModule } from "../../overlay-panel/overlay-panels.module";
import { ValidationModule } from "../validation/validation.module";
import { CalculatorInputComponent } from "./calculator-input.component";

@NgModule({
   imports: [CommonModule, FormsModule, OverlayPanelsModule, ValidationModule],
   declarations: [CalculatorInputComponent],
   providers: [],
   exports: [CalculatorInputComponent],
})
export class CalculatorModule {}
