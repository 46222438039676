import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { DataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-types.model";
import { ExpressControlDataSourceFilterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-control-data-source-filter.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable, of } from "rxjs";

import { EntitySearchConfigurationServiceBase } from "./entity-search-configuration-service.base";
import { EntitySearchConfigurationModel } from "./entity-search-configuration.model";

@Injectable()
export class EntitySearchConfigurationService implements EntitySearchConfigurationServiceBase {
   constructor() {}

   getSearchConfiguration(entityType: EntityType): Observable<EntitySearchConfigurationModel> {
      let result = new EntitySearchConfigurationModel();
      let filterField: FilterField;
      let operation: FilterOperationModel;

      switch (entityType) {
         case EntityType.Tenant:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);

            filterField = new FilterField();
            filterField.FilterName = "TenantDisplayID";
            filterField.PropertyName = "TenantDisplayID";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "Int";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.EqualTo;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.Property:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.Owner:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.ChartAccountsMappings:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.Bank:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);

            filterField = new FilterField();
            filterField.FilterName = "Reference";
            filterField.PropertyName = "Reference";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.Vendor:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Name";
            filterField.PropertyName = "Name";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);
            break;
         case EntityType.User:
            result.DefaultSearchFields = new Array<FilterField>();

            filterField = new FilterField();
            filterField.FilterName = "Username";
            filterField.PropertyName = "Username";
            filterField.DataType = new DataTypes();
            filterField.DataType.Name = "String";
            filterField.Operations = new Array<FilterOperationModel>();
            operation = new FilterOperationModel();
            operation.BackingEnumeration = FilterOperations.Contains;
            filterField.Operations.push(operation);
            result.DefaultSearchFields.push(filterField);

            result.DefaultOrderingOption = new OrderingOption("FirstNameLastName");

            result.GlobalFilters = new Array<ExpressControlDataSourceFilterModel>();

            const isActiveFilter = new ExpressControlDataSourceFilterModel();
            isActiveFilter.PropertyPath = "IsActive";
            isActiveFilter.FilterOperation = FilterOperations.EqualTo;
            isActiveFilter.Value = true.toString();
            result.GlobalFilters.push(isActiveFilter);

            const isGroupFilter = new ExpressControlDataSourceFilterModel();
            isGroupFilter.PropertyPath = "IsGroup";
            isGroupFilter.FilterOperation = FilterOperations.EqualTo;
            isGroupFilter.Value = false.toString();
            result.GlobalFilters.push(isGroupFilter);
            break;
      }
      return of(result);
   }
}
