<label *ngIf="!hidelabel && itemSets && itemSets.length == 1 && itemSets[0].label">
   {{ itemSets[0].label }}
</label>
<div
   class="multiselector-wrapper"
   #multiselectorWrapper
   [attr.id]="name"
>
   <lcs-loading-overlay
      [show]="!allSourcesLoaded && !errorMessage"
      [diameter]="30"
   >
      <ng-container *ngIf="itemSets && itemSets.length == 1">
         <lcs-checkbox
            *ngIf="allowsSelectAll && allSourcesLoaded"
            [ngModel]="selectAll"
            [disabled]="disabled"
            (ngModelChange)="updateSelectAll($event)"
            [displayName]="'All'"
         >
            All
         </lcs-checkbox>
         <lcs-checkbox
            *ngFor="let value of itemSets[0].items"
            [ngModel]="value.isChecked"
            [disabled]="disabled"
            (ngModelChange)="value.isChecked = $event; update($event)"
            [displayName]="value.displayValue"
            [ngClass]="value.alternateStyleClass"
         >
            <div *ngIf="!value.additionalInfoValue">
               {{ value.displayValue }}
            </div>
            <div *ngIf="value.additionalInfoValue">
               {{ value.displayValue }} - {{ value.additionalInfoValue }}
            </div>
         </lcs-checkbox>
      </ng-container>
      <ng-container *ngIf="itemSets && itemSets.length > 1">
         <ul
            class="tabs"
            data-tabs
         >
            <li
               class="tabs-title"
               *ngFor="let itemSet of itemSets; let first = first; let index = index"
               [ngClass]="{ 'is-active': first }"
            >
               <lcs-link
                  [href]="'#' + itemSet.identifier"
                  [text]="itemSet.name"
                  (clicked)="toggleTab(index, $event)"
               ></lcs-link>
            </li>
         </ul>
         <div class="tabs-content">
            <div
               class="tabs-panel"
               *ngFor="let itemSet of itemSets; let first = first; let index = index"
               [ngClass]="{ 'is-active': first }"
               id="{{ itemSet.identifier }}"
            >
               <lcs-checkbox
                  *ngIf="allowsSelectAll && allSourcesLoaded"
                  [disabled]="disabled"
                  [ngModel]="selectAll"
                  (ngModelChange)="updateSelectAll($event)"
                  (click)="onControlClick()"
                  [displayName]="displayName"
               >All</lcs-checkbox>
               <lcs-checkbox
                  *ngFor="let value of itemSet.items"
                  [disabled]="disabled"
                  [ngModel]="value.isChecked"
                  (ngModelChange)="value.isChecked = $event; update($event)"
                  (click)="onControlClick()"
                  [displayName]="value.displayValue"
               >{{ value.displayValue }}</lcs-checkbox>
            </div>
         </div>
      </ng-container>
   </lcs-loading-overlay>
   <div *ngIf="errorMessage">{{ errorMessage }}</div>
   <i class="material-icons validation-error-icon">error_outline</i>
</div>
<lcs-validation-overlay-panel
   [modelRef]="ngControl"
   [parentElement]="multiselectorWrapper"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>