export enum FileType {
   PDF = ".pdf",
   DOC = ".doc",
   DOCX = ".docx",
   XLSX = ".xlsx",
   XLS = ".xls",
   CSV = ".csv",
   PPTX = ".pptx",
   ZIP = ".zip",
}
