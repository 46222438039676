import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { FilterInputValueType } from "../datatable-filter-values.types";
import { EntityLinkFormGroup } from "./entity-link-selector/entity-link-form-group";
import { EntityLinkModel } from "./entity-link-selector/entity-link.model";

@Component({
   selector: "lcs-entity-link-selector-filter",
   templateUrl: "entity-link-selector-filter.component.html",
})
export class EntityLinkSelectorFilterComponent {
   @Input() set entityLink(value: FilterInputValueType | Array<FilterInputValueType>) {
      this._entityLink = value;
      if (value as EntityLinkModel) {
         value = value as EntityLinkModel;
         if (value && !this.group) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            const entityLinkFormGroup = new EntityLinkFormGroup(null, value.value, value.entityType);
            this.group = this.formBuilder.group(entityLinkFormGroup);
         }
      } else if (!this.group) {
         const entityLinkFormGroup = new EntityLinkFormGroup();
         this.group = this.formBuilder.group(entityLinkFormGroup);
      }
   }

   @Input() entityTypesToInclude: Array<string>;

   @Input() entityTypeSelectorLabel: string;

   @Input() entitySelectorLabel: string;

   @Output() linkEntitySelected = new EventEmitter<EntityLinkModel>();

   @Output() formGroup = new EventEmitter<UntypedFormGroup>();

   get entityLink(): FilterInputValueType | Array<FilterInputValueType> {
      return this._entityLink;
   }

   set group(group: UntypedFormGroup) {
      this._group = group;
      this.formGroup.emit(group);
   }

   get group(): UntypedFormGroup {
      return this._group;
   }

   private _group: UntypedFormGroup;

   private _entityLink: FilterInputValueType | Array<FilterInputValueType>;

   constructor(private formBuilder: UntypedFormBuilder) {}

   onLinkEntitySelected(link: EntityLinkModel) {
      this.linkEntitySelected.emit(link);
   }
}
