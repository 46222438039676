import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";

export class TriggerActionEventModel {
   expressEntityEvent: ExpressEntityEvents = ExpressEntityEvents.Unset;

   triggerAction: ExpressActions = ExpressActions.NotSet;

   public constructor(expressEntityEvent: ExpressEntityEvents | null, triggerAction?: ExpressActions) {
      if (expressEntityEvent != null) {
         this.expressEntityEvent = expressEntityEvent;
      }
      if (triggerAction != null) {
         this.triggerAction = triggerAction;
      }
   }
}
