<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ parameter?.DisplayName }}
   </label>
</div>
<div class="multiselector-wrapper">
   <div class="report-parameter-label">
      <lcs-checkbox
         [name]="name"
         (ngModelChange)="generateCheckboxValueChanged($event)"
         [ngModel]="checked"
         [ngModelOptions]="{ standalone: true }"
         [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      >
         {{ generateLabel }}
      </lcs-checkbox>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="parameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>

   <div class="report-parameter-label">
      <lcs-checkbox
         [name]="name + '-detail-cam'"
         (ngModelChange)="detailCheckboxValueChanged($event)"
         [ngModel]="detailChecked"
         [ngModelOptions]="{ standalone: true }"
         [disabled]="detailDisabled"
      >
         {{ detailLabel }}
      </lcs-checkbox>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="parameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
</div>