import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { Subject, takeUntil } from "rxjs";

import { OWASessionService } from "../../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-site-header",
   templateUrl: "./site-header.component.html",
   styleUrls: ["./site-header.component.css"],
})
export class SiteHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
   @ViewChild("imagebackground") imagebackground: ElementRef;
   @ViewChild("imagebackgroundchild") imagebackgroundchild: ElementRef;
   localStorageKey = "siteHeaderImageURL";
   overrideDefaultHeaderImageOWA: string = "false";
   owaCustomHeaderBackgroundColor: string = "#F2F5FA";
   owaCustomHeaderImageAlignment: string = "left";
   siteHeaderImage: string;
   private unsubscribe = new Subject<void>();

   constructor(
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private owaSessionInfo: OWASessionService,
      private sessionCheckService: SessionCheckService,
      private elementRef: ElementRef
   ) {}

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   ngAfterViewInit(): void {
      this.getSiteHeaderWebPreference();
      this.getAuthenticatedSiteHeaderFile();
   }

   public getSiteHeaderWebPreference() {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               this.overrideDefaultHeaderImageOWA =
                  prefs.find((a) => a.Name === "OverrideDefaultHeaderImageOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       prefs.find((a) => a.Name === "OverrideDefaultHeaderImageOWA").Value.toLowerCase()
                     : "false";
               if (this.overrideDefaultHeaderImageOWA === "true") {
                  const val = document.getElementsByTagName("body")[0];

                  // @ts-ingore
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.

                  this.owaCustomHeaderBackgroundColor =
                     prefs.find((a) => a.Name === "OWACustomHeaderBackgroundColor") != null
                        ? prefs.find((a) => a.Name === "OWACustomHeaderBackgroundColor")?.Value.toString()
                        : "#F2F5FA";

                  // @ts-ingore
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'
                  this.owaCustomHeaderImageAlignment =
                     prefs.find((a) => a.Name === "OWACustomHeaderImageAlignment") != null
                        ? prefs.find((a) => a.Name === "OWACustomHeaderImageAlignment")?.Value.toLowerCase()
                        : "left";

                  this.elementRef.nativeElement.style.setProperty("text-align", this.owaCustomHeaderImageAlignment);
                  this.owaCustomHeaderBackgroundColor =
                     ";--site-header-background-color:" + this.owaCustomHeaderBackgroundColor;
                  val.style.cssText += this.owaCustomHeaderBackgroundColor;
               }
            }
         }
      );
   }

   getAuthenticatedSiteHeaderFile() {
      this.sessionCheckService
         .checkSession()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((session) => {
            if (session) {
               if (this.owaSessionInfo != null) {
                  this.owaSessionInfo.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((image) => {
                     if (image != null) {
                        this.siteHeaderImage = image.SiteHeaderImageURL;
                     }
                  });
               }
            }
            this.getSiteHeaderWebPreference();
         });
   }
}
