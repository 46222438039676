import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationHelper } from "@lcs/inputs/validation/validation-helper";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { StateSelectorItemHelper } from "@lcs/select/helpers/state-selector-item.helper";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { Countries } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/countries.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { OwnerContributionPaymentDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-paymentdetail-view.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { OwnerContributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-contributions.service";
import { Subject, takeUntil } from "rxjs";

import { CompletedComponent } from "../Completed/completed.component";
import { ConstantsService } from "./../../../../../libraries/owa-gateway-sdk/src/lib/services/constants.service";
import { RoutingNumberInfoComponent } from "./routing-number-info/routing-number-info.component";

@Component({
   selector: "owa-epay-ach",
   templateUrl: "./epay-ach.component.html",
   styleUrls: ["./epay-ach.component.css"],
})
export class EpayACHComponent implements OnInit, OnDestroy {
   test: Countries;
   public ownerContributionPaymentModel = new OwnerContributionPaymentDetailViewModel();
   routingNumberValidation: ValidationModel;
   accountNumberValidation: ValidationModel;
   accountTypeValueSource: ValueSourceModel;
   buttonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   configuration: OwnerContributionPaymentDetailViewModel;
   contributionType = new Array<string>();
   accountTypeControlName: string = "accountType";
   selectedAccountType: EpayAccountType = EpayAccountType.Checking;
   unitedStates: boolean;
   checked: boolean;
   stateOptions: Array<SelectorItemModel>;

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(refresh: b... Remove this comment to see the full error message
   valueCallBack: (refresh: boolean) => void = null;

   private unsubscribe = new Subject<void>();

   constructor(
      private ownerContributionService: OwnerContributionsService,
      private errorMessageService: ErrorMessageService,
      private dialogueService: CurrentDialogService,
      private dialogCreationService: DialogCreationService,
      public constantsService: ConstantsService
   ) {}

   ngOnInit() {
      this.ownerContributionPaymentModel = this.configuration;
      this.unitedStates = this.ownerContributionPaymentModel.BankCountryID === Countries.UnitedStates ? true : false;
      this.accountTypeValueSource = new ValueSourceModel();
      this.accountTypeValueSource.Type = ValueSourceTypes.Enumeration;
      this.accountTypeValueSource.EnumerationName = "eEpayAccountType";
      this.accountTypeValueSource.ValuesToExclude = [
         EpayAccountType.NotSet,
         EpayAccountType.Visa,
         EpayAccountType.MasterCard,
         EpayAccountType.AmericanExpress,
         EpayAccountType.Discover,
         EpayAccountType.DinersClub,
         EpayAccountType.BusinessChecking,
         EpayAccountType.GenericCC,
         EpayAccountType.GenericACH,
         EpayAccountType.CashPay,
      ];

      this.accountNumberValidation = new ValidationModel();
      this.accountNumberValidation.maxLength = 20;
      this.accountNumberValidation.required = true;
      this.checked = this.ownerContributionPaymentModel.EpayACH.IsDisclaimerChecked;
      this.routingNumberValidation = ValidationHelper.RoutingNumber();
      this.stateOptions = StateSelectorItemHelper.buildStateOptions(this.unitedStates);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onAccountTypeChange(value: string): void {
      if (EpayAccountType[value] === EpayAccountType.Savings) {
         this.ownerContributionPaymentModel.EpayACH.AccountTypeID = EpayAccountType.Savings;
      } else {
         this.ownerContributionPaymentModel.EpayACH.AccountTypeID = EpayAccountType.Checking;
      }
   }

   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.buttonStatus = LcsProgressButtonStatus.InProgress;
         this.ownerContributionService
            .SaveContributions(this.ownerContributionPaymentModel)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (result) => {
                  if (result) {
                     this.dialogueService.closeDialog();
                     const config = new DialogConfigurationModel();
                     config.componentInstance = CompletedComponent;
                     config.title = "Payment Submitted";
                     this.ownerContributionService.PaymentType.next("ACH");
                     this.dialogCreationService.open(config, null, null, null, null, null, (v) => {
                        this.valueCallBack(v);
                     });
                  }
               },
               (_err) => {
                  this.errorMessageService.triggerHttpErrorMessage(_err);
                  this.buttonStatus = LcsProgressButtonStatus.Error;
               }
            );
      } else {
         this.buttonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   allSelected(value: boolean) {
      this.ownerContributionPaymentModel.EpayACH.IsDisclaimerChecked = value;
      this.checked = value;
   }

   showRoutingNumberInfo() {
      const dialogConfigurationModel = new DialogConfigurationModel();
      dialogConfigurationModel.componentInstance = RoutingNumberInfoComponent;
      dialogConfigurationModel.title = "Check Routing Number";
      this.dialogCreationService.open(dialogConfigurationModel, null);
   }
}
