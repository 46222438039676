////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum DateRangeModes {
   NotSet = 0,
   Monthly = 1,
   Quarterly = 2,
   Yearly = 3,
}
