import { OwnerDistributionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-distribution.model";

export class OwnerDistributionListItemModel extends OwnerDistributionModel {
   RowGroupExpanded: boolean = false;

   constructor(ownerDistribution?: OwnerDistributionModel) {
      super();
      if (ownerDistribution) {
         this.CopyFromOwnerDistributionModel(ownerDistribution);
      }
   }

   CopyFromOwnerDistributionModel(ownerDistribution: OwnerDistributionModel) {
      for (const prop of Object.keys(ownerDistribution)) {
         this[prop] = ownerDistribution[prop];
      }
   }
}
