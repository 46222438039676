import { SignableDocumentPacketStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/signable-document-packet-status.enum";

import { SignableDocumentField } from "./signable-document-field.model";
import { SignableDocumentStatusModel } from "./signable-document-status.model";
import { SignatureBlock } from "./signature-block.model";

export class SignableDocumentPacketSummaryModel {
   SignableDocumentPacketID: number;
   Name: string;
   SignableDocuments: Array<SignableDocumentStatusModel>;
   Status: SignableDocumentPacketStatus;
   StatusText: string;
   FinishedSignerCount: number;
   UserHasFinishedPacket: boolean;
   SignerCount: number;
   SignedBlockCount: number;
   SignedBlockcountForOtherUsers: number;
   SignatureBlockCount: number;
   TotalRequiredFieldsCount: number;
   FinishedRequiredFieldsCount: number;
   PacketPageCount: number;
   FirstDocumentID: number;
   LastDocumentID: number;
   IsViewOnly: boolean;
   IncompleteBlocksForCurrentWebUser: number;
   ExpirationDate: Date;
   SignableDocumentFieldList: Array<SignableDocumentField>;
   SignatureBlockList: Array<SignatureBlock>;
   SignatureBlockListForCurrentWebUser: Array<SignatureBlock>;
   PendingSignatureBlockListCount: number;
}
