import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { ActionPayloadsService } from "@lcs/action-context/action-payloads.service";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ReceivesPayload } from "@lcs/component-interfaces/receives-payload.interface";
import { ConstantsService } from "@lcs/core/constants.service";
import { ErrorDialogFooterComponent } from "@lcs/error-message/error-dialog-footer.component";
import { ErrorMessageComponent } from "@lcs/error-message/error-message.component";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FormStatuses } from "@lcs/forms/form-registration/form-statuses.enum";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";

import { DialogContentComponent } from "../dialog-content-component.interface";
import { DialogComponent } from "../dialog.component";
import { DialogConfigurationModel } from "../dialog.configuration.model";
import { isInstanceOfValueResultDialog } from "../interfaces/value-result-dialog.interface";

@Injectable({
   providedIn: "root",
})
export class DialogCreationService {
   private viewContainerRef: ViewContainerRef;

   constructor(private errorMessageService: ErrorMessageService) {
      this.errorMessageService.errors.subscribe((error) => {
         const dialogConfiguration = new DialogConfigurationModel();
         dialogConfiguration.componentInstance = ErrorMessageComponent;
         dialogConfiguration.disableClose = error.Dismissible === false;
         dialogConfiguration.closeDialogOnOutsideClick = error.Dismissible === true;
         dialogConfiguration.title = (error ? error.Title : null) || "An error has occurred";
         dialogConfiguration.payloads = [new ActionTriggerPayloadModel(ExpressPayloadFields.Value, error)];
         dialogConfiguration.styleClass = "error-dialog";
         this.open(
            dialogConfiguration,
            null,
            (dialogRef: ComponentRef<DialogComponent>) =>
               this.createErrorMessageFooter(dialogRef, error.Dismissible ?? false),
            null,
            null,
            error.closeCallBack
         );
      });
   }

   public initialize(viewContainerRef: ViewContainerRef) {
      this.viewContainerRef = viewContainerRef;
   }

   public open(
      configuration: DialogConfigurationModel,
      componentConfigurationModel: any,
      createDialogCallback: ((dialogComponentRef: ComponentRef<DialogComponent>) => void) | null = null,
      createInternalComponentCallback: ((internalComponentRef: ComponentRef<any>) => void) | null = null,
      openCallBack: (() => void) | null = null,
      closeCallBack: (() => void) | null = null,
      valueCallBack: ((value: any) => void) | null = null,
      overrideFocus: boolean = false
   ) {
      setTimeout(() =>
         this.openDialogCallback(
            configuration,
            componentConfigurationModel,
            createDialogCallback,
            createInternalComponentCallback,
            openCallBack,
            closeCallBack,
            valueCallBack,
            overrideFocus
         )
      );
   }

   public openDialogCallback(
      configuration: DialogConfigurationModel,
      componentConfigurationModel: any,
      createDialogCallback: ((dialogComponentRef: ComponentRef<DialogComponent>) => void) | null = null,
      createInternalComponentCallback: ((internalComponentRef: ComponentRef<any>) => void) | null = null,
      openCallBack: (() => void) | null = null,
      closeCallBack: (() => void) | null = null,
      valueCallBack: ((value: any) => void) | null = null,
      overrideFocus: boolean = false
   ) {
      const dialogComponentRef = this.viewContainerRef.createComponent(DialogComponent);

      const dialogComponentInstance: DialogComponent = dialogComponentRef.instance;

      if (configuration.payloads && configuration.payloads.length) {
         const actionPayloadsService = dialogComponentRef.injector.get(ActionPayloadsService);
         actionPayloadsService.payloads = configuration.payloads;
      }

      const currentDialog: DialogComponent = dialogComponentRef.instance;
      currentDialog.childComponentType = configuration.componentInstance;
      currentDialog.closeButtonInFooter = configuration.closeButtonInFooter;
      currentDialog.closeButtonText = configuration.closeButtonText;

      // set our configuration values
      if (configuration.width) {
         currentDialog.width = configuration.width;
      }
      if (configuration.widthUnit) {
         currentDialog.widthUnit = configuration.widthUnit;
      }
      if (configuration.height) {
         currentDialog.height = configuration.height;
      }
      if (configuration.heightUnit) {
         currentDialog.heightUnit = configuration.heightUnit;
      }
      if (configuration.disableClose) {
         currentDialog.canClose = false;
      }
      if (configuration.closeDialogOnOutsideClick) {
         currentDialog.closeOnOutsideClick = configuration.closeDialogOnOutsideClick;
      }
      if (configuration.title) {
         currentDialog.title = configuration.title;
      }
      if (configuration.styleClass) {
         currentDialog.styleClass = configuration.styleClass;
      }
      if (configuration.closeCallbackOverride) {
         currentDialog.closeCallbackOverride = configuration.closeCallbackOverride;
      }
      if (configuration.headingButtonsTemplate) {
         currentDialog.headingButtonsTemplate = configuration.headingButtonsTemplate;
      }
      if (overrideFocus) {
         currentDialog.overrideFocus = overrideFocus;
      }
      if (configuration.overflowYHidden) {
         currentDialog.overflowYHidden = configuration.overflowYHidden;
      }

      if (openCallBack) {
         currentDialog.onShow = openCallBack;
      }

      if (closeCallBack) {
         currentDialog.onClose = closeCallBack;
      }

      currentDialog.baseZIndex = ConstantsService.FullMenuZIndex;

      if (createDialogCallback) {
         createDialogCallback(dialogComponentRef);
      }

      setTimeout(() => {
         if (!configuration.componentInstance) {
            throw new Error("Cannot create dialog - null component instance");
         }
         const internalComponentRef = dialogComponentInstance.contentPlaceholder.createComponent(
            configuration.componentInstance
         );
         // close our dialog and remove it from our list when visibility is false
         currentDialog?.visibleChange.subscribe((isVisible) => {
            if (!isVisible) {
               internalComponentRef.destroy();
               dialogComponentRef.destroy();
            }
         });

         if (valueCallBack && isInstanceOfValueResultDialog(internalComponentRef.instance)) {
            internalComponentRef.instance.valueCallBack = valueCallBack;
         }

         if (configuration.payloads && configuration.payloads.length) {
            (<ReceivesPayload>internalComponentRef.instance).payloads = configuration.payloads;
            configuration.payloads.forEach((payload: ActionTriggerPayloadModel) => {
               if (payload.PayloadKey === FormStatuses.Disabled) {
                  if (currentDialog) {
                     currentDialog.disabled = payload.Value;
                  }
               }
            });
         }

         // set up our component with configuration and closing subject
         (<DialogContentComponent>internalComponentRef.instance).configuration = componentConfigurationModel;

         if (createInternalComponentCallback) {
            createInternalComponentCallback(internalComponentRef);
         }
         if (currentDialog) {
            currentDialog.visible = true;
         }
      });
   }

   private createErrorMessageFooter(dialogRef: ComponentRef<DialogComponent>, dismissible?: boolean): void {
      if (dismissible) {
         dialogRef.instance.footerPlaceholder.createComponent(ErrorDialogFooterComponent);
      }
   }
}
