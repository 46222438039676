import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { CheckListItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check-list-item.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { ProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect.embed-options";
import { ServiceManagerCategoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-category.embed-options";
import { ServiceManagerPriorityEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-priority.embed-options";
import { ServiceManagerRecurringIssueLinkedPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-issue-linked-property.embed-options";
import { ServiceManagerRecurringIssueLinkedProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-issue-linked-prospect.embed-options";
import { ServiceManagerRecurringIssueLinkedTenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-issue-linked-tenant.embed-options";
import { ServiceManagerRecurringIssueLinkedUnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-issue-linked-unit.embed-options";
import { ServiceManagerRecurringIssueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-issue.embed-options";
import { ServiceManagerRecurringWorkOrderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-recurring-work-order.embed-options";
import { ServiceManagerStatusEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-status.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { UnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { VendorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor.embed-options";
import { UnitGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/unit.get-options";
import { CheckListItemSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check-list-item.save-options";
import { ServiceManagerRecurringIssueSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/service-manager-recurring-issue.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { CheckListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check-list-item.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { ProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect.model";
import { ServiceManagerCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-category.model";
import { ServiceManagerPriorityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-priority.model";
import { ServiceManagerRecurringIssueLinkedPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-issue-linked-property.model";
import { ServiceManagerRecurringIssueLinkedProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-issue-linked-prospect.model";
import { ServiceManagerRecurringIssueLinkedTenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-issue-linked-tenant.model";
import { ServiceManagerRecurringIssueLinkedUnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-issue-linked-unit.model";
import { ServiceManagerRecurringIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-issue.model";
import { ServiceManagerRecurringWorkOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-recurring-work-order.model";
import { ServiceManagerStatusModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-status.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { ServiceManagerIssueSearchPostModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/service-manager-issue-search-post.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ServiceManagerRecurringIssuesService {
   static readonly AssignedToUserResource = "AssignedToUser";
   static readonly AvailableChildLinksForProspectResource = "AvailableChildLinksForProspect";
   static readonly AvailableChildLinksForTenantResource = "AvailableChildLinksForTenant";
   static readonly AvailableChildLinksForUnitResource = "AvailableChildLinksForUnit";
   static readonly CategoryResource = "Category";
   static readonly CheckListItemsResource = "CheckListItems";
   static readonly CreateUserResource = "CreateUser";
   static readonly GetByPostResource = "GetByPost";
   static readonly LinkedPropertiesResource = "LinkedProperties";
   static readonly LinkedProspectsResource = "LinkedProspects";
   static readonly LinkedTenantsResource = "LinkedTenants";
   static readonly LinkedUnitsResource = "LinkedUnits";
   static readonly LinkPropertyResource = "LinkProperty";
   static readonly LinkProspectResource = "LinkProspect";
   static readonly LinkTenantResource = "LinkTenant";
   static readonly LinkUnitResource = "LinkUnit";
   static readonly PayeeAccountResource = "PayeeAccount";
   static readonly PriorityResource = "Priority";
   static readonly PropertiesResource = "Properties";
   static readonly ProspectsResource = "Prospects";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly SearchExtResource = "SearchExt";
   static readonly ServiceManagerRecurringIssueLinkedPropertiesResource =
      "ServiceManagerRecurringIssueLinkedProperties";
   static readonly ServiceManagerRecurringIssueLinkedProspectsResource = "ServiceManagerRecurringIssueLinkedProspects";
   static readonly ServiceManagerRecurringIssueLinkedTenantsResource = "ServiceManagerRecurringIssueLinkedTenants";
   static readonly ServiceManagerRecurringIssueLinkedUnitsResource = "ServiceManagerRecurringIssueLinkedUnits";
   static readonly ServiceManagerRecurringWorkOrdersResource = "ServiceManagerRecurringWorkOrders";
   static readonly StatusResource = "Status";
   static readonly TenantsResource = "Tenants";
   static readonly UnitsResource = "Units";
   static readonly UnlinkPropertyResource = "UnlinkProperty";
   static readonly UnlinkProspectResource = "UnlinkProspect";
   static readonly UnlinkTenantResource = "UnlinkTenant";
   static readonly UnlinkUnitResource = "UnlinkUnit";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly UsersThatCanBeAssignedToRecurringServiceIssueResource =
      "UsersThatCanBeAssignedToRecurringServiceIssue";
   static readonly VendorResource = "Vendor";
   static readonly endpoint = "ServiceManagerRecurringIssuesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | ServiceManagerRecurringIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ServiceManagerRecurringIssueModel | null,
      saveOptions?: Array<string | ServiceManagerRecurringIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | ServiceManagerRecurringIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ServiceManagerRecurringIssueModel>,
      saveOptions?: Array<string | ServiceManagerRecurringIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint,
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAssignedToUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/AssignedToUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssignedToUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getAssignedToUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getAvailableChildLinksForProspectUrl(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/AvailableChildLinksForProspect",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForProspect(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedProspectModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForProspectUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForTenantUrl(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/AvailableChildLinksForTenant",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForTenant(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedTenantModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForTenantUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForUnitUrl(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/AvailableChildLinksForUnit",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForUnit(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedUnitModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForUnitUrl(id, embeds, additionalParameters));
   }

   getCategoryUrl(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Category",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCategory(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerCategoryModel> {
      return this.apiService.getSingle(this.getCategoryUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCheckListItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CheckListItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCheckListItems(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.getSingle(
         this.getCheckListItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCheckListItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CheckListItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCheckListItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.getCollection(
         this.getCheckListItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCheckListItemsUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItems(
      id: number,
      item?: CheckListItemModel | null,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.postSingle(
         this.postCheckListItemsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCheckListItemsCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItemsCollection(
      id: number,
      items: Array<CheckListItemModel>,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.postCollection(
         this.postCheckListItemsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCheckListItemsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CheckListItems",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCheckListItems(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCheckListItemsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getLinkedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getLinkedPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getLinkedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedProspects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(
         this.getLinkedProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedProspects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ProspectModel>> {
      return this.apiService.getCollection(
         this.getLinkedProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(
         this.getLinkedTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getLinkedTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedUnits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(
         this.getLinkedUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkedUnits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getLinkedUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLinkPropertyUrl(id: number, propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkProperty",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkProperty(
      id: number,
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkPropertyUrl(id, propertyID, additionalParameters));
   }

   postLinkProspectUrl(id: number, prospectID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("prospectID=" + prospectID);
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkProspect",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkProspect(
      id: number,
      prospectID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkProspectUrl(id, prospectID, additionalParameters));
   }

   postLinkTenantUrl(id: number, tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkTenant",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkTenant(id: number, tenantID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkTenantUrl(id, tenantID, additionalParameters));
   }

   postLinkUnitUrl(id: number, unitID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/LinkUnit",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkUnit(id: number, unitID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkUnitUrl(id, unitID, additionalParameters));
   }

   getPayeeAccountUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/PayeeAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPayeeAccount(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getPayeeAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPriorityUrl(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Priority",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPriority(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerPriorityModel> {
      return this.apiService.getSingle(this.getPriorityUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Properties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Properties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Prospects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(
         this.getProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Prospects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ProspectModel>> {
      return this.apiService.getCollection(
         this.getProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerRecurringIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   postSearchExtUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/SearchExt",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearchExt(
      serviceIssueSearchPostModel?: ServiceManagerIssueSearchPostModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueModel>> {
      return this.apiService
         .post(this.postSearchExtUrl(additionalParameters), serviceIssueSearchPostModel)
         .pipe(map((response) => response.body));
   }

   getServiceManagerRecurringIssueLinkedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedPropertyModel> {
      return this.apiService.getSingle(
         this.getServiceManagerRecurringIssueLinkedPropertiesUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerRecurringIssueLinkedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedPropertyModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerRecurringIssueLinkedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerRecurringIssueLinkedPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedProperties(
      id: number,
      item?: ServiceManagerRecurringIssueLinkedPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedPropertyModel> {
      return this.apiService.postSingle(
         this.postServiceManagerRecurringIssueLinkedPropertiesUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         item
      );
   }

   postServiceManagerRecurringIssueLinkedPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedPropertiesCollection(
      id: number,
      propertiesToLink?: Array<ServiceManagerRecurringIssueLinkedPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedPropertyModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerRecurringIssueLinkedPropertiesCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         propertiesToLink
      );
   }

   deleteServiceManagerRecurringIssueLinkedPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerRecurringIssueLinkedProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerRecurringIssueLinkedPropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerRecurringIssueLinkedProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProspects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedProspectModel> {
      return this.apiService.getSingle(
         this.getServiceManagerRecurringIssueLinkedProspectsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerRecurringIssueLinkedProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProspects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedProspectModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerRecurringIssueLinkedProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerRecurringIssueLinkedProspectsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedProspects(
      id: number,
      item?: ServiceManagerRecurringIssueLinkedProspectModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedProspectModel> {
      return this.apiService.postSingle(
         this.postServiceManagerRecurringIssueLinkedProspectsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerRecurringIssueLinkedProspectsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedProspectsCollection(
      id: number,
      prospectsToLink?: Array<ServiceManagerRecurringIssueLinkedProspectModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedProspectModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerRecurringIssueLinkedProspectsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         prospectsToLink
      );
   }

   deleteServiceManagerRecurringIssueLinkedProspectsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedProspects",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerRecurringIssueLinkedProspects(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerRecurringIssueLinkedProspectsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerRecurringIssueLinkedTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedTenantModel> {
      return this.apiService.getSingle(
         this.getServiceManagerRecurringIssueLinkedTenantsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerRecurringIssueLinkedTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedTenantModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerRecurringIssueLinkedTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerRecurringIssueLinkedTenantsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedTenants(
      id: number,
      item?: ServiceManagerRecurringIssueLinkedTenantModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedTenantModel> {
      return this.apiService.postSingle(
         this.postServiceManagerRecurringIssueLinkedTenantsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerRecurringIssueLinkedTenantsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedTenantsCollection(
      id: number,
      tenantsToLink?: Array<ServiceManagerRecurringIssueLinkedTenantModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedTenantModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerRecurringIssueLinkedTenantsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         tenantsToLink
      );
   }

   deleteServiceManagerRecurringIssueLinkedTenantsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedTenants",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerRecurringIssueLinkedTenants(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerRecurringIssueLinkedTenantsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerRecurringIssueLinkedUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedUnits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedUnitModel> {
      return this.apiService.getSingle(
         this.getServiceManagerRecurringIssueLinkedUnitsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerRecurringIssueLinkedUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedUnits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringIssueLinkedUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedUnitModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerRecurringIssueLinkedUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerRecurringIssueLinkedUnitsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedUnits(
      id: number,
      item?: ServiceManagerRecurringIssueLinkedUnitModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringIssueLinkedUnitModel> {
      return this.apiService.postSingle(
         this.postServiceManagerRecurringIssueLinkedUnitsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerRecurringIssueLinkedUnitsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringIssueLinkedUnitsCollection(
      id: number,
      unitsToLink?: Array<ServiceManagerRecurringIssueLinkedUnitModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringIssueLinkedUnitModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerRecurringIssueLinkedUnitsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         unitsToLink
      );
   }

   deleteServiceManagerRecurringIssueLinkedUnitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringIssueLinkedUnits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerRecurringIssueLinkedUnits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerRecurringIssueLinkedUnitsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerRecurringWorkOrdersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringWorkOrders",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringWorkOrders(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringWorkOrderModel> {
      return this.apiService.getSingle(
         this.getServiceManagerRecurringWorkOrdersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerRecurringWorkOrdersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringWorkOrders",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerRecurringWorkOrdersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringWorkOrderModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerRecurringWorkOrdersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerRecurringWorkOrdersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringWorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringWorkOrders(
      id: number,
      item?: ServiceManagerRecurringWorkOrderModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerRecurringWorkOrderModel> {
      return this.apiService.postSingle(
         this.postServiceManagerRecurringWorkOrdersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerRecurringWorkOrdersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringWorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerRecurringWorkOrdersCollection(
      id: number,
      items: Array<ServiceManagerRecurringWorkOrderModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerRecurringWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerRecurringWorkOrderModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerRecurringWorkOrdersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteServiceManagerRecurringWorkOrdersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/ServiceManagerRecurringWorkOrders",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerRecurringWorkOrders(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerRecurringWorkOrdersUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getStatusUrl(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Status",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getStatus(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerStatusModel> {
      return this.apiService.getSingle(this.getStatusUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Tenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(
         this.getTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Tenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Units",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(this.getUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Units",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   deleteUnlinkPropertyUrl(id: number, propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UnlinkProperty",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkProperty(
      id: number,
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkPropertyUrl(id, propertyID, additionalParameters));
   }

   deleteUnlinkProspectUrl(id: number, prospectID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("prospectID=" + prospectID);
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UnlinkProspect",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkProspect(
      id: number,
      prospectID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkProspectUrl(id, prospectID, additionalParameters));
   }

   deleteUnlinkTenantUrl(id: number, tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UnlinkTenant",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkTenant(
      id: number,
      tenantID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkTenantUrl(id, tenantID, additionalParameters));
   }

   deleteUnlinkUnitUrl(id: number, unitID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UnlinkUnit",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkUnit(id: number, unitID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkUnitUrl(id, unitID, additionalParameters));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUserDefinedFieldsUrl(saveOptions, fields, additionalParameters), item);
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      id: number,
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      id: number,
      udvs?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         udvs
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUsersThatCanBeAssignedToRecurringServiceIssueCollectionUrl(
      recurringIssueID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("recurringIssueID=" + recurringIssueID);
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/UsersThatCanBeAssignedToRecurringServiceIssue",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsersThatCanBeAssignedToRecurringServiceIssueCollection(
      recurringIssueID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersThatCanBeAssignedToRecurringServiceIssueCollectionUrl(recurringIssueID, additionalParameters)
      );
   }

   getVendorUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerRecurringIssuesService.endpoint + "/Vendor",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getVendorUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
