<owa-dashboard-tile
   [title]="'Owner Prospect Information'"
   class="owner-prospect-information"
>

   <div *ngIf="observableOwnerProspect != null">
      <div>
         <div class="scoreboard-wrapper">
            <div class="scoreboard-item flex-half">
               <span class="scoreboard-label">Primary Phone</span>
               <span>
                  <span
                     class="prospect-phone"
                     *ngFor="let phone of ownerProspect?.Contact?.PhoneNumbers"
                  >
                     <span
                        class="prospect-phone"
                        *ngIf="phone.IsPrimary"
                     >{{phone.PhoneNumber}}</span>
                  </span>
               </span>
            </div>
            <div class="scoreboard-item flex-half">
               <span class="scoreboard-label">Email</span>
               <span>{{ownerProspect?.Email}}</span>
            </div>
            <div class="scoreboard-item flex-full">
               <span class="scoreboard-label">Primary Address</span>
               <span>
                  <span
                     class="prospect-address"
                     *ngFor="let address of ownerProspect?.Addresses"
                  >
                     <span
                        class="prospect-address"
                        *ngIf="address.IsPrimary"
                     >{{address.Address}}</span>
                  </span>
               </span>
            </div>
         </div>
      </div>
   </div>

</owa-dashboard-tile>