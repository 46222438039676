<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ displayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-single-line-multi-select
   [name]="name"
   [displayName]="displayName"
   [valueSource]="valueSource"
   [(ngModel)]="selectedValues"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   [afterItemsPortal]="portal"
   [lcsValidate]="validation"
   (afterItemsPortalAttached)="onPortalAttached($event)"
   (selectionChange)="selectionChange = $event"
   (panelClose)="selectionChanged()"
></lcs-single-line-multi-select>

<ng-template #accountSelector>
   <div
      *ngIf="hasSelector"
      class="group-selection"
   >
      <label>Group Selection</label>
      <lcs-select
         lcsStaticSetSelector
         [name]="'account-type-selector'"
         [items]="accountTypes"
         [(ngModel)]="selectedAccountType"
         (ngModelChange)="onSelectedAccountsChange($event)"
         [parentOverlayPanelRef]="parentOverlayPanelRef"
      >
      </lcs-select>
   </div>
</ng-template>