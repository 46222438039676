import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/account-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-types.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { ExpressAccountsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/express-accounts.service";

@Component({
   selector: "lcs-account-selector",
   templateUrl: "account-selector.component.html",
})
export class AccountSelectorComponent extends ValueAccessorBase<any> implements OnChanges {
   @Input() accountTypes: Array<AccountType>;

   @Input() accountTypesToExclude: Array<string>;

   @Input() addBlankItem: boolean = true;

   @Input() disabled: boolean;

   @Input() filters: Array<FilterOption> = [];

   @Input() hideLabel: boolean;

   @Input() inlineLabel: boolean;

   @Input() label: string = "Accounts";

   @ViewChild(SelectComponent) accountSelector: SelectComponent;

   @Output() panelClose = new EventEmitter<void>();

   selectedItem: SelectorItemModel;

   selectorConfigured: boolean;

   endpoint: string;

   searchFields: Array<FilterField>;

   orderingOptions: Array<string>;

   valuesToExclude: Array<string | number | boolean>;

   loadDefaultSetOnShow: boolean;

   valueSourcePath: string;

   displayValueSourcePath: string;

   additionalInfoSourcePath: string;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private expressAccountsService: ExpressAccountsService
   ) {
      super(changeDetectorRef, ngControl);

      this.configureSelector();
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (
         (changes.accountTypes && changes.accountTypes.currentValue !== changes.accountTypes.previousValue) ||
         (changes.filters && changes.filters.currentValue !== changes.filters.previousValue) ||
         (changes.accountTypesToExclude &&
            changes.accountTypesToExclude.currentValue !== changes.accountTypesToExclude.previousValue)
      ) {
         this.configureSelector();
      }
   }

   onTouched(): void {
      this.propagateTouched();
   }

   onSelectedChange(val: any) {
      this.selectedItem = this.accountSelector.selectedItem;
      this.value = val;
   }

   private configureSelector() {
      if (this.accountTypes && this.accountTypes.length > 0) {
         this.filters.push(new FilterOption("AccountType", FilterOperations.In, this.accountTypes));
      }
      if (this.accountTypesToExclude && this.accountTypesToExclude.length > 0) {
         this.filters.push(new FilterOption("AccountType", FilterOperations.NotIn, this.accountTypesToExclude));
      }

      this.endpoint = this.expressAccountsService.getAccountsWithAccountTypeNameCollectionUrl();
      this.orderingOptions = ["Name"];

      const nameFilterField = new FilterField();
      nameFilterField.FilterName = "Name";
      nameFilterField.DataType = new DataTypes();
      nameFilterField.DataType.Name = "string";
      this.searchFields = [nameFilterField];
      this.displayValueSourcePath = "Name";
      this.valueSourcePath = "AccountID";
      this.additionalInfoSourcePath = "AccountTypeDescription";

      this.selectorConfigured = true;
   }
}
