import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { Observable } from "rxjs";

import { EmailAddressSummaryModel } from "../models/generated/email-address-summary.model";
import { TextingSystemPreferencesModel } from "../models/texting-system-preferences-model";

@Injectable({
   providedIn: "root",
})
export class VerifyEmailAddressService {
   readonly endpoint = "changeemailaddress/GetTokenInformation";

   constructor(private httpClient: HttpClient, private apiRequestHelperService: ApiRequestHelperService) {}

   getTokenInformation(token: string): Observable<EmailAddressSummaryModel> {
      return this.httpClient.get<EmailAddressSummaryModel>(
         this.apiRequestHelperService.url + "changeemailaddress/GetTokenInformation?token=" + token,
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }

   verifyEmailAddress(resetModel: EmailAddressSummaryModel): Observable<EmailAddressSummaryModel> {
      return this.httpClient.post<EmailAddressSummaryModel>(
         this.apiRequestHelperService.url + "changeemailaddress/VerifyEmailAddress",
         JSON.stringify(resetModel),
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   getTextingPreferences(token: string): Observable<TextingSystemPreferencesModel> {
      return this.httpClient.get<TextingSystemPreferencesModel>(
         this.apiRequestHelperService.url + "changeemailaddress/GetTextingSystemPreferences?token=" + token,
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }
}
