import reduce from "lodash/reduce";

import { ReportParameterComponents } from "../report-parameter-components.enum";
import { ReportReportParameterComponentModel } from "./report-report-parameter-component.model";

export class ReportReportParameterGroupModel {
   ReportParameterComponent: ReportParameterComponents;
   ReportReportParameterComponents: Array<ReportReportParameterComponentModel>;
   Order: number;
   displayName: string;
   isCombinedReportParameter: boolean;

   constructor(
      reportParameterComponent?: ReportParameterComponents,
      reportReportParameterComponents?: Array<ReportReportParameterComponentModel>,
      order?: number
   ) {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterComponents | undefined' is no... Remove this comment to see the full error message
      this.ReportParameterComponent = reportParameterComponent;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterComponentModel[] | unde... Remove this comment to see the full error message
      this.ReportReportParameterComponents = reportReportParameterComponents;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      this.Order = order;
   }

   get RowSpan(): number {
      return reduce(
         this.ReportReportParameterComponents,
         (sum, reportParameterComponent) => {
            return sum + reportParameterComponent.RowSpan;
         },
         0
      );
   }

   copyPropertiesWithValues(reportReportParameterGroup: ReportReportParameterGroupModel) {
      if (reportReportParameterGroup.ReportParameterComponent) {
         this.ReportParameterComponent = reportReportParameterGroup.ReportParameterComponent;
      }
      if (reportReportParameterGroup.ReportReportParameterComponents) {
         this.ReportReportParameterComponents = reportReportParameterGroup.ReportReportParameterComponents;
      }
      if (reportReportParameterGroup.Order) {
         this.Order = reportReportParameterGroup.Order;
      }
   }
}
