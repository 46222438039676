import { CdkCell, CdkColumnDef } from "@angular/cdk/table";
import { Directive, ElementRef, HostBinding } from "@angular/core";

@Directive({
   // eslint-disable-next-line @angular-eslint/directive-selector
   selector: "lcs-cell, td[lcs-cell]",
})
export class LcsCellDirective extends CdkCell {
   @HostBinding("class.lcs-cell") cellClass: boolean = true;

   @HostBinding("attr.role") role: string = "gridcell";

   constructor(columnDef: CdkColumnDef, elementRef: ElementRef<HTMLElement>) {
      super(columnDef, elementRef);
      elementRef.nativeElement.classList.add(`lcs-column-${columnDef.cssClassFriendlyName}`);
   }
}
