<ng-container *ngIf="notSupportedReportParametersArray.length === 0">
   <div
      *ngIf="ownerCoverPageInfo"
      class="report-parameter-tile"
   >
      <lcs-owner-cover-page-information
         [ownerCoverPageInformation]="ownerCoverPageInfo"
         (ownerCoverPageInformationChanged)="onOwnerCoverPageInfoChanged($event)"
      >
      </lcs-owner-cover-page-information>
   </div>
   <div
      class="
      report-parameter-tile"
      *ngFor="let reportReportParameterGroup of reportReportParameterGroups; let groupIndex = index"
      #tiles
      [ngStyle]="{ order: reportReportParameterGroup.Order, gridRowStart: tileRowHeights.get(groupIndex)}"
   >
      <ng-container *ngFor="
         let reportReportParameterComponent of reportReportParameterGroup.ReportReportParameterComponents;
         let componentIndex = index
      ">
         <div [ngStyle]="{ order: reportReportParameterComponent.Order }">
            <ng-container *ngIf="overriddenReportParameterRenderer; else normalReportParameters">
               <ng-container *ngTemplateOutlet="
                  overriddenReportParameterRenderer;
                  context: {
                     controlName: 'report-parameter-' + groupIndex + '-' + componentIndex,
                     displayName: reportReportParameterGroup.displayName,
                     parameter: reportReportParameterComponent,
                     parameterComponents: reportReportParameterGroup.ReportReportParameterComponents,
                     hasAsk: hasAsk,
                     isRelative: isRelative
                  }
               "></ng-container>
            </ng-container>
            <ng-template #normalReportParameters>
               <owa-report-parameter-renderer
                  [controlName]="'report-parameter-' + groupIndex + '-' + componentIndex"
                  [displayName]="reportReportParameterGroup.displayName"
                  [parameter]="reportReportParameterComponent"
                  [parameterComponents]="reportReportParameterGroup.ReportReportParameterComponents"
                  [hasAsk]="hasAsk"
                  [isRelative]="isRelative"
                  [report]="report"
               >
               </owa-report-parameter-renderer>
            </ng-template>
         </div>
      </ng-container>
   </div>