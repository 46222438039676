import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";

import { ValidatePasswordFormatComponent } from "./validate-password-format.component";

@NgModule({
   imports: [CommonModule, FormsModule, MatTooltipModule],
   declarations: [ValidatePasswordFormatComponent],
   exports: [ValidatePasswordFormatComponent],
})
export class ValidatePasswordFormatModule {}
