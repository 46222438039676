import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   Renderer2,
   ViewChild,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP } from "keycode-js";
import { debounce, Subject, takeUntil, tap } from "rxjs";

@Component({
   selector: "lcs-time-picker",
   templateUrl: "time-picker.component.html",
})
export class TimePickerComponent extends ValueAccessorBase<Date> implements AfterViewInit, OnDestroy, OnInit {
   @Input() modelRef: NgControl;

   @Input() tooltip: string = "";

   @Output() inputValueChange = new EventEmitter<string>();

   @Output() blur = new EventEmitter<FocusEvent>();

   @Output() focus = new EventEmitter<FocusEvent>();

   @ViewChild("inputEl", { static: true }) inputEl: ElementRef;

   set maskedValue(value: string) {
      this._maskedValue = value;
      this.maskedValueChange.next(value);
   }

   get maskedValue() {
      return this._maskedValue;
   }

   timeInputElement: HTMLInputElement;

   private _maskedValue: string;

   private maskedValueChange = new Subject<string>();

   private unsubscribe = new Subject<void>();

   constructor(
      public elementRef: ElementRef,
      private renderer2: Renderer2,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.registerOnValueWritten(() => {
         this.maskedValue = this.getMaskedTimeFromDate(this.value);
      });
      this.registerOnChange(() => {
         this.maskedValue = this.getMaskedTimeFromDate(this.value);
      });
   }

   ngOnInit(): RequiredSuperCallFlag {
      if (!this.modelRef) {
         this.modelRef = this.ngControl;
      }
      return super.ngOnInit();
   }

   ngAfterViewInit() {
      this.timeInputElement = this.inputEl.nativeElement;

      this.maskedValueChange
         .pipe(
            tap((value: string) => this.inputValueChange.emit(value)),
            debounce(() => this.blur),
            takeUntil(this.unsubscribe)
         )
         .subscribe((value) => {
            let masked: string = "";
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
            let date: Date = null;
            if (value) {
               masked = this.mask(value);
               date = this.getDateFromMaskedTime(masked);
            }

            if (!masked) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
               date = null;
               masked = this.getMaskedTimeFromDate(date);
            }
            this.maskedValue = masked;
            this.value = date;
         });
   }

   mask(value: string): string {
      const unmasked = value.toUpperCase().replace(/[:\s]/g, ""); // 12:34 pm => 1234PM
      const digitsMatch = unmasked.match(/\d+/);
      if (!digitsMatch) {
         return "";
      }
      const numbers = digitsMatch[0];
      const numeric = +numbers;

      if (isNaN(numeric)) {
         return "";
      }

      let hours = "";
      let minutes = "";
      let period = "AM";

      if (value.indexOf(":") > -1) {
         const split = value.split(":");
         hours = split[0];
         if (split[1]) {
            const minutesNumeric = parseInt(split[1]);
            if (!isNaN(minutesNumeric)) {
               minutes = minutesNumeric.toString();
            } else {
               minutes = "00";
            }
         }
      } else {
         if (numbers.length < 3) {
            // 1, 12, 01, 99
            if (numeric === 0) {
               return "";
            }
            if (numeric < 13) {
               // 1, 12, 01
               hours = numbers;
               minutes = "00";
            } else {
               // 99
               hours = numbers[0];
               minutes = numbers.substr(1);
            }
         } else if (numbers.length < 4) {
            // 123, 012
            if (numbers[0] === "0") {
               return "";
            }
            if (numeric < 13) {
               hours = numbers;
               minutes = "00";
            } else {
               hours = "0" + numbers[0];
               minutes = numbers.substr(1, 2);
            }
         } else {
            // 1234, 0123
            hours = numbers.substr(0, 2);
            minutes = numbers.substr(2, 2);
         }
      }

      while (hours.length < 2) {
         hours = "0" + hours;
      }
      while (minutes.length < 2) {
         minutes = "0" + minutes;
      }

      if (!+hours || +hours > 12 || +minutes > 59) {
         return "";
      }

      if (unmasked.indexOf("P") > -1 && unmasked.indexOf("A") === -1) {
         period = "PM";
      }

      return hours + ":" + minutes + " " + period;
   }

   getDateFromMaskedTime(value: string) {
      let hours = value.substr(0, 2);
      const minutes = value.substr(3, 2);
      const period = value.substr(6, 2);
      if (period === "PM" && +hours < 12) {
         hours = (+hours + 12).toString();
      } else if (period === "AM" && +hours === 12) {
         hours = (+hours - 12).toString();
      }
      return new Date(0, 0, 0, +hours, +minutes);
   }

   getMaskedTimeFromDate(date: Date): string {
      if (date) {
         let period = "AM";
         let hours: string | number = date.getHours();
         if (hours > 11) {
            period = "PM";
            if (hours > 12) {
               hours -= 12;
            }
         } else if (hours === 0) {
            hours += 12;
         }
         let minutes: string | number = date.getMinutes();
         hours = hours < 10 ? "0" + hours.toString() : hours.toString();
         minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
         return `${hours}:${minutes} ${period}`;
      } else {
         return "";
      }
   }

   setInputSelection(position: number, keyCode: number = 0) {
      const value = this.maskedValue;
      if (value == null || value.length === 0) {
         return;
      }
      const maskedValue = this.mask(value);
      if (maskedValue.length === 0) {
         return;
      }
      if (position < 0 || position > maskedValue.length) {
         return;
      }
      const selectedChar = maskedValue[position];
      let selectionStart = position;
      let selectionEnd = position;

      const currentDate = this.getDateFromMaskedTime(maskedValue);
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();

      if (position === maskedValue.length) {
         return this.setInputSelection(position - 1, keyCode);
      } else if (selectedChar === " ") {
         // select [mm]
         const colonIndex = maskedValue.indexOf(":");
         if (colonIndex < 0 || colonIndex > position) {
            return;
         }
         selectionStart = colonIndex + 1;
         selectionEnd = position;
         if (keyCode === KEY_RIGHT) {
            if (position + 1 <= maskedValue.length) {
               return this.setInputSelection(position + 1);
            }
         } else if (keyCode === KEY_LEFT) {
            return this.setInputSelection(0);
         } else if (keyCode === KEY_UP) {
            ++hours;
         } else if (keyCode === KEY_DOWN) {
            --hours;
         }
      } else if (!isNaN(Number(selectedChar))) {
         // [hh:mm]
         const colonIndex = maskedValue.indexOf(":");
         if (colonIndex < 0 || colonIndex >= maskedValue.length) {
            return;
         }
         if (colonIndex > position) {
            // select [hh]
            selectionStart = 0;
            selectionEnd = colonIndex;
            if (keyCode === KEY_RIGHT) {
               const colonPosition = maskedValue.indexOf(":");
               if (colonPosition > position && colonPosition + 1 <= maskedValue.length) {
                  return this.setInputSelection(colonPosition + 1);
               }
            } else if (keyCode === KEY_UP) {
               ++hours;
            } else if (keyCode === KEY_DOWN) {
               --hours;
            }
         } else {
            // select [mm]
            const spacePosition = maskedValue.indexOf(" ");
            if (spacePosition > position) {
               selectionStart = colonIndex + 1;
               selectionEnd = spacePosition;
            } else if (spacePosition === -1) {
               selectionStart = colonIndex + 1;
               selectionEnd = maskedValue.length - 1;
            }
            if (keyCode === KEY_RIGHT) {
               if (spacePosition + 1 <= maskedValue.length) {
                  return this.setInputSelection(spacePosition + 1);
               }
            } else if (keyCode === KEY_LEFT) {
               const colonPosition = maskedValue.indexOf(":");
               if (colonPosition > 0 && colonPosition < position) {
                  return this.setInputSelection(colonPosition);
               }
            } else if (keyCode === KEY_UP) {
               ++minutes;
            } else if (keyCode === KEY_DOWN) {
               --minutes;
            }
         }
      } else if (selectedChar === ":") {
         // select [hh]
         selectionStart = 0;
         selectionEnd = position;
         if (keyCode === KEY_RIGHT) {
            if (position + 1 <= maskedValue.length) {
               return this.setInputSelection(position + 1);
            }
         } else if (keyCode === KEY_UP) {
            ++hours;
         } else if (keyCode === KEY_DOWN) {
            --hours;
         }
      } else if (["a", "p"].indexOf(selectedChar.toLowerCase()) > -1) {
         // select [am] [pm]
         if (position < maskedValue.length - 1 && maskedValue[position + 1].toLowerCase() === "m") {
            selectionStart = position;
            selectionEnd = position + 2;

            if (keyCode === KEY_LEFT) {
               const colonPosition = maskedValue.indexOf(":");
               if (colonPosition > 0 && colonPosition + 1 < position) {
                  return this.setInputSelection(colonPosition + 1);
               }
            } else if (keyCode === KEY_UP) {
               hours += 12;
            } else if (keyCode === KEY_DOWN) {
               hours -= 12;
            }
         }
      } else if (selectedChar.toLowerCase() === "m") {
         // select [am] [pm]
         if (position > 0 && ["a", "p"].indexOf(maskedValue[position - 1].toLowerCase()) > -1) {
            selectionStart = position - 1;
            selectionEnd = position + 1;

            if (keyCode === KEY_LEFT) {
               const colonPosition = maskedValue.indexOf(":");
               if (colonPosition > 0 && colonPosition + 1 < position) {
                  return this.setInputSelection(colonPosition + 1);
               }
            } else if (keyCode === KEY_UP) {
               hours += 12;
            } else if (keyCode === KEY_DOWN) {
               hours -= 12;
            }
         }
      }
      if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
         let originalTime: Date;
         if (this.value == null) {
            originalTime = new Date();
         } else {
            originalTime = this.value;
         }
         const newTime = new Date();
         newTime.setHours(hours, minutes);
         newTime.setFullYear(originalTime.getFullYear(), originalTime.getMonth(), originalTime.getDate());
         this._maskedValue = this.getMaskedTimeFromDate(newTime);
         this.renderer2.setProperty(this.inputEl.nativeElement, "value", this._maskedValue);
         this.value = newTime;
         return this.setInputSelection(position);
      }
      if (
         selectionStart !== this.timeInputElement.selectionStart ||
         selectionEnd !== this.timeInputElement.selectionEnd
      ) {
         this.timeInputElement.setSelectionRange(selectionStart, selectionEnd);
      }
   }

   onFocus(event: FocusEvent) {
      this.focus.emit(event);
   }

   onBlur(event: FocusEvent) {
      this.blur.emit(event);
      this.propagateTouched();
   }

   onEnter(event: Event) {
      (event as KeyboardEvent).preventDefault();
   }

   onKeyDown(event: KeyboardEvent) {
      if (
         event.keyCode === KEY_DOWN ||
         event.keyCode === KEY_UP ||
         event.keyCode === KEY_LEFT ||
         event.keyCode === KEY_RIGHT
      ) {
         const selectionStart = this.timeInputElement.selectionStart;
         if (event.keyCode === KEY_LEFT || event.keyCode === KEY_RIGHT) {
            // Left and right arrow keys need to trigger a remask here.
            // Up and down arrow keys already mask in the setInputSelection method.
            this.maskedValue = this.getMaskedTimeFromDate(this.value);
         }
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
         this.setInputSelection(selectionStart, event.keyCode);
         event.preventDefault();
      }
   }

   onClick() {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      this.setInputSelection(this.timeInputElement.selectionStart);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }
}
