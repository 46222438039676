import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { Observable, of } from "rxjs";

import { EntityRequestEndpointInformationModel } from "./entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "./entity-request-endpoint-service.base";

@Injectable()
export class EntityRequestEndpointService implements EntityRequestEndpointServiceBase {
   constructor() {}

   getEndpointInformation(entityType: EntityType): Observable<EntityRequestEndpointInformationModel> {
      let result: EntityRequestEndpointInformationModel;
      switch (entityType) {
         case EntityType.Tenant:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Tenants";
            result.IsSearchEnabled = true;
            break;
         case EntityType.Property:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Properties";
            result.IsSearchEnabled = false;
            break;
         case EntityType.Owner:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Owners";
            result.IsSearchEnabled = false;
            break;
         case EntityType.ChartAccountsMappings:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "ChartOfAccountsMappings";
            result.IsSearchEnabled = false;
            break;
         case EntityType.ChargeTypes:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "ChargeTypes";
            result.IsSearchEnabled = false;
            break;
         case EntityType.Amenities:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Amenities";
            result.IsSearchEnabled = false;
            break;
         case EntityType.Bank:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Banks";
            result.IsSearchEnabled = true;
            break;
         case EntityType.CreditCard:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "CreditCards";
            result.IsSearchEnabled = true;
            break;
         case EntityType.GLAccount:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "GLAccounts";
            result.IsSearchEnabled = false;
            break;
         case EntityType.Bill:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Bills";
            result.IsSearchEnabled = false;
            break;
         case EntityType.GLAccount:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "GLAccounts";
            result.IsSearchEnabled = false;
            break;
         case EntityType.Vendor:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Vendors";
            result.IsSearchEnabled = true;
            break;
         case EntityType.Report:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "Reports";
            result.IsSearchEnabled = false;
            break;
         case EntityType.InventoryItem:
            result = new EntityRequestEndpointInformationModel();
            result.APIUrl = "InventoryItem";
            result.IsSearchEnabled = false;
            break;
         default:
            throw new Error(`No request endppoint information defined for ${EntityType[entityType]}`);
      }
      return of(result);
   }
}
