<input
   #inputEl
   type="text"
   [attr.id]="path"
   placeholder="--:-- --"
   [(ngModel)]="maskedValue"
   [ngModelOptions]="{ standalone: true }"
   [disabled]="disabled"
   (blur)="onBlur($event)"
   (focus)="onFocus($event)"
   (keydown.enter)="onEnter($event)"
   (keydown)="onKeyDown($event)"
   (click)="onClick()"
   title="{{tooltip}}"
   [disabled]="disabled"
/>
<i class="material-icons validation-error-icon">error_outline</i>
<lcs-validation-overlay-panel
   [parentElement]="inputEl"
   [modelRef]="modelRef"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>