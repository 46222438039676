<div class="dashboard-tile-wrapper">
   <div class="dashboard-tile">
      <div class="dashboard-tile-header">
         <div class="notification-count" *ngIf="notificationCount > 0">
            {{notificationCount}}
         </div>
         <div class=dashboard-tile-title>
            {{title}}
         </div>
         <div *ngIf="isViewMore" class="is-view-more" (click)="openViewMoreAction(viewMoreComponent)">
            <i class="material-icons">filter_none</i>
         </div>
      </div>

      <div class="dashboard-tile-content {{contentClass}}">
         <ng-content></ng-content>
      </div>
   </div>
</div>