import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { UIDService } from "@lcs/unique-ids/uid.service";
import isEqual from "lodash/isEqual";

@Component({
   selector: "lcs-value-accessor-base",
   template: "",
})
export abstract class ValueAccessorBase<T> implements ControlValueAccessor, OnInit {
   /** The display value to use when referring to this control, like in a form error overlay. */
   @Input() displayName: string;

   /** This control's name in the form. */
   @Input() name: string;

   /** This control's name in for reactive forms. */
   @Input("formControlName") set reactiveName(name: string) {
      this.name = name;
   }

   /** The path to this control in the form. */
   @Input() path: string;

   /** Prevents input and value emission for this control */
   @Input() disabled: boolean = false;

   protected innerValue: T;

   get value(): T {
      return this.innerValue;
   }

   set value(value: T) {
      if (!isEqual(this.innerValue, value)) {
         this.innerValue = value;
         this.propagateChanged();
      }
   }

   protected changed = new Array<(value: T) => void>();

   protected touchHandlers = new Array<() => void>();

   protected valueWritten = new Array<(value: T) => void>();

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      if (this.ngControl) {
         this.ngControl.valueAccessor = this;
      }
   }

   ngOnInit(): RequiredSuperCallFlag {
      if (!this.path) {
         const path = this.ngControl.path?.join("-");
         if (path) {
            this.path = path;
         } else {
            this.path = `va-uid` + UIDService.getUID();
         }
      }
      this.disabled = this.disabled ?? false;
      return new RequiredSuperCallFlag();
   }

   writeValue(value: T) {
      this.innerValue = value;
      this.propagateValueWritten(value);
      this.changeDetectorRef.markForCheck();
   }

   propagateChanged() {
      this.changed.forEach((f) => f(this.value));
   }

   propagateValueWritten(value: T) {
      this.valueWritten.forEach((f) => f(value));
   }

   propagateTouched() {
      this.touchHandlers.forEach((f) => f());
   }

   registerOnChange(fn: (value: T, internal?: boolean) => void) {
      this.changed.push(fn);
   }

   registerOnValueWritten(fn: (value: T) => void) {
      this.valueWritten.push(fn);
   }

   registerOnTouched(fn: () => void) {
      this.touchHandlers.push(fn);
   }

   setDisabledState(isDisabled: boolean) {
      this.disabled = isDisabled;
      this.changeDetectorRef.markForCheck();
   }
}
