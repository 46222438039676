import { AddressRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/address-related-object-types.enum";

import { AddressTypeModel } from "./address-type.model";
import { AssetManufacturerModel } from "./asset-manufacturer.model";
import { ContactModel } from "./contact.model";
import { LoanContactModel } from "./loan-contact.model";
import { OwnerProspectModel } from "./owner-prospect.model";
import { OwnerModel } from "./owner.model";
import { PropertyModel } from "./property.model";
import { ProspectModel } from "./prospect.model";
import { TenantModel } from "./tenant.model";
import { UnitModel } from "./unit.model";
import { UtilityModel } from "./utility.model";
import { VendorModel } from "./vendor.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class AddressModel {
   Address: string;
   AddressID: number;
   AddressType: AddressTypeModel;
   AddressTypeID: number;
   AssetManufacturer: AssetManufacturerModel;
   City: string;
   Contact: ContactModel;
   IsBilling: boolean;
   IsPrimary: boolean;
   LoanContact: LoanContactModel;
   MetaTag?: string;
   Owner: OwnerModel;
   OwnerProspect: OwnerProspectModel;
   ParentID: number;
   ParentType: AddressRelatedObjectTypes;
   PostalCode: string;
   Property: PropertyModel;
   Prospect: ProspectModel;
   State: string;
   Street: string;
   Tenant: TenantModel;
   Unit: UnitModel;
   Utility: UtilityModel;
   Vendor: VendorModel;
}
