import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
   selector: "owa-dashboard-tile",
   templateUrl: "./dashboard-tile-view.component.html",
   styleUrls: ["./dashboard.component.css"],
})
export class DashboardTileViewComponent {
   @Input() title: string;
   @Input() notificationCount: number;
   @Input() contentClass: string;
   @Input() isViewMore: boolean = false;
   @Input() viewMoreComponent: string;

   constructor(private router: Router) {}

   openViewMoreAction(event) {
      if (event === undefined || event === "") {
         return;
      } else {
         this.router.navigate(["/" + event]);
      }
   }
}
