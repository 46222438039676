/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

import { FormRegistrationModel } from "./form-registration.model";

@Injectable({
   providedIn: "root",
})
export class FormRegistrationBaseService implements OnDestroy {
   forms: Array<FormRegistrationModel> = new Array<FormRegistrationModel>();

   private unsubscribe = new Subject<void>();

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   markAllFormsAsPristine(): void {
      this.forms.forEach((form) => form.form.form.markAsPristine());
   }

   markFormGroupAsPristine(formId: string) {
      const form = this.forms.find((form) => form.formId === formId);
      if (form) {
         form.form.form.markAsPristine();
      }
   }
}
