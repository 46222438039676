<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true)"
   >
      Transactions to Include
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="transactionsToIncludeReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'transactions-to-include'"
      [displayName]="'Transactions to Include'"
      [disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true)"
      [parameter]="transactionsToIncludeReportParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="parameter-margin-left-md">
   <lcs-checkbox
      [name]="'show-deposit-breakdown'"
      [displayName]="'Show Deposit Breakdown'"
      [(ngModel)]="showDepositBreakdownValue.value"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true) || (+transactionsToIncludeValue.value === 1)"
   >
      {{ $any(showDepositBreakdownReportParameter.PromptText?.length) > 0 ?
      showDepositBreakdownReportParameter.PromptText : showDepositBreakdownReportParameter.DisplayName }}
   </lcs-checkbox>
   <lcs-checkbox
      [name]="'show-check-breakdown'"
      [displayName]="'Show Check Breakdown'"
      [(ngModel)]="showCheckBreakdownValue.value"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true) || (+transactionsToIncludeValue.value === 2)"
   >
      {{ $any(showCheckBreakdownReportParameter.PromptText?.length) > 0 ? showCheckBreakdownReportParameter.PromptText :
      showCheckBreakdownReportParameter.DisplayName }}
   </lcs-checkbox>
   <lcs-select
      owaEntitySelector
      [entityType]="vendorEntityType"
      [(ngModel)]="vendorAccnoValue.value"
      [ngModelOptions]="{ standalone: true }"
      [name]="'vendor-selector'"
      [displayName]="$any(vendorsReportParameter.PromptText?.length) > 0 ? vendorsReportParameter.PromptText : vendorsReportParameter.DisplayName"
      [disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true) || (+transactionsToIncludeValue.value === 2)"
      [staticValues]="[anyVendorItem]"
   >
   </lcs-select>
</div>
<lcs-checkbox
   [name]="name"
   [displayName]="$any(showJournalsReportParameter?.DisplayName)"
   [(ngModel)]="showJournalValue.value"
   [ngModelOptions]="{ standalone: true }"
   [disabled]="disabled || (hasAsk === true && transactionsToIncludeReportParameter.IsAsk === true)"
>
   {{ $any(showJournalsReportParameter.PromptText?.length) > 0 ? showJournalsReportParameter.PromptText :
   showJournalsReportParameter.DisplayName }}
</lcs-checkbox>