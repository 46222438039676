import uniq from "lodash/uniq";

export class ApiServiceHelpers {
   static extractEmbeds(propertyPaths: Array<string>): Array<string> {
      const embeds = new Array<string>();
      if (!propertyPaths) {
         return embeds;
      }
      for (const propertyPath of propertyPaths) {
         const extractedEmbeds = propertyPath
            .split(".")
            .filter((value) => isNaN(Number(value)))
            .slice(0, -1);

         if (extractedEmbeds.length > 1) {
            embeds.push(extractedEmbeds.join("."));
         } else if (extractedEmbeds.length === 1) {
            embeds.push(extractedEmbeds[0]);
         }
      }
      return uniq(embeds);
   }
}
