<owa-layout [headerTitle]="'Checks'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="checksFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>

      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{ results | async }} of {{ totalResults | async }} Checks
      </div>

      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(checks | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>
               <ng-container
                  lcsColumnDef="Reference"
                  [width]="10"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Reference</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.Reference }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Date"
                  [width]="8"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{
                     row.TransactionDate | date: "MM/dd/yyyy"
                     }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Payee"
                  [width]="10"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Payee </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.Payee }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Comment"
                  [width]="10"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Comment </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  > {{row.ReversalDate ? "VOID: " + row.Comment : row.Comment}} </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Amount"
                  [width]="8"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Amount</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="numeric"
                  >{{
                     row.Amount | currency: "USD":"symbol":"1.2-2"
                     }}</td>
               </ng-container>
            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any(checks | async)?.length < $any(totalResults | async)"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>