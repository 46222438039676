import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IconModule } from "@lcs/icon/icon.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { SelectorOverlayPanelModule } from "@lcs/selector-overlay-panel/selector-overlay-panel.module";

import { InputWithOptionsComponent } from "./input-with-options.component";

@NgModule({
   imports: [CommonModule, FormsModule, TextBoxModule, SelectorOverlayPanelModule, IconModule, ValidationModule],
   declarations: [InputWithOptionsComponent],
   exports: [InputWithOptionsComponent],
})
export class InputWithOptionsModule {}
