import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { OwnerApprovalStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/owner-approval-status.enum";
import { BillViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-view.model";
import { BillsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/bills.service";
import { switchMap } from "rxjs";

import { BillDetailPreferences } from "../bill-detail-preferences.interface";

@Component({
   selector: "owa-bill-details",
   templateUrl: "./bill-details.component.html",
})
export class BillDetailsComponent {
   @Input() loading: boolean;
   @Input() bill: BillViewModel;
   @Input() preferences: BillDetailPreferences;

   approveButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private errorMessageService: ErrorMessageService,
      private billService: BillsService
   ) {}

   openBillDetailsMore(): void {
      this.router.navigate(["./billdetails"], { relativeTo: this.activatedRoute });
   }

   approveBill(id: number) {
      this.approveButtonStatus = LcsProgressButtonStatus.InProgress;
      this.billService
         .ApproveBillByOwner(id)
         .pipe(
            switchMap(() => {
               return this.billService.get(this.bill.ID);
            })
         )
         .subscribe(
            (bill: BillViewModel) => {
               this.bill = bill;
               if (this.bill.OwnerApprovalStatus === OwnerApprovalStatus.Required) {
                  this.approveButtonStatus = LcsProgressButtonStatus.Error;
               } else if (this.bill.OwnerApprovalStatus === OwnerApprovalStatus.Approved) {
                  this.approveButtonStatus = LcsProgressButtonStatus.Success;
               }
            },
            (error) => {
               this.approveButtonStatus = LcsProgressButtonStatus.Error;
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }
}
