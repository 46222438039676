import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { FileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/file.embed-options";
import { OwnerProspectUserDefinedFieldLinkEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-prospect-user-defined-field-link.embed-options";
import { ProspectUserDefinedFieldLinkEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect-user-defined-field-link.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file.model";
import { OwnerProspectUserDefinedFieldLinkModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-prospect-user-defined-field-link.model";
import { ProspectUserDefinedFieldLinkModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect-user-defined-field-link.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class UserDefinedFieldsService {
   static readonly CreateUserResource = "CreateUser";
   static readonly DecryptedUDVResource = "DecryptedUDV";
   static readonly DefaultAttachmentResource = "DefaultAttachment";
   static readonly GetByPostResource = "GetByPost";
   static readonly OwnerProspectUserDefinedFieldLinkResource = "OwnerProspectUserDefinedFieldLink";
   static readonly ProspectUserDefinedFieldLinkResource = "ProspectUserDefinedFieldLink";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly endpoint = "UserDefinedFieldsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<UserDefinedFieldModel>,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UserDefinedFieldsService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(UserDefinedFieldsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getDecryptedUDVUrl(id: number, itemID: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/DecryptedUDV",
         id,
         itemID,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDecryptedUDV(id: number, itemID: number, additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getDecryptedUDVUrl(id, itemID, additionalParameters));
   }

   getDefaultAttachmentUrl(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/DefaultAttachment",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultAttachment(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService.getSingle(
         this.getDefaultAttachmentUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postDefaultAttachmentUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint + "/DefaultAttachment",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postDefaultAttachment(
      id: number,
      item?: FileModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService
         .post(this.postDefaultAttachmentUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteDefaultAttachmentUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         UserDefinedFieldsService.endpoint + "/DefaultAttachment",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteDefaultAttachment(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteDefaultAttachmentUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getOwnerProspectUserDefinedFieldLinkUrl(
      id: number,
      embeds?: Array<string | OwnerProspectUserDefinedFieldLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/OwnerProspectUserDefinedFieldLink",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerProspectUserDefinedFieldLink(
      id: number,
      embeds?: Array<string | OwnerProspectUserDefinedFieldLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerProspectUserDefinedFieldLinkModel> {
      return this.apiService.getSingle(
         this.getOwnerProspectUserDefinedFieldLinkUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postOwnerProspectUserDefinedFieldLinkUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerProspectUserDefinedFieldLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint + "/OwnerProspectUserDefinedFieldLink",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnerProspectUserDefinedFieldLink(
      id: number,
      item?: OwnerProspectUserDefinedFieldLinkModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerProspectUserDefinedFieldLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerProspectUserDefinedFieldLinkModel> {
      return this.apiService
         .post(
            this.postOwnerProspectUserDefinedFieldLinkUrl(id, saveOptions, fields, embeds, additionalParameters),
            item
         )
         .pipe(map((response) => response.body));
   }

   deleteOwnerProspectUserDefinedFieldLinkUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UserDefinedFieldsService.endpoint + "/OwnerProspectUserDefinedFieldLink",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteOwnerProspectUserDefinedFieldLink(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteOwnerProspectUserDefinedFieldLinkUrl(id, deleteOptions, additionalParameters)
      );
   }

   getProspectUserDefinedFieldLinkUrl(
      id: number,
      embeds?: Array<string | ProspectUserDefinedFieldLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/ProspectUserDefinedFieldLink",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspectUserDefinedFieldLink(
      id: number,
      embeds?: Array<string | ProspectUserDefinedFieldLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectUserDefinedFieldLinkModel> {
      return this.apiService.getSingle(
         this.getProspectUserDefinedFieldLinkUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postProspectUserDefinedFieldLinkUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ProspectUserDefinedFieldLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint + "/ProspectUserDefinedFieldLink",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postProspectUserDefinedFieldLink(
      id: number,
      item?: ProspectUserDefinedFieldLinkModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ProspectUserDefinedFieldLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectUserDefinedFieldLinkModel> {
      return this.apiService
         .post(this.postProspectUserDefinedFieldLinkUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteProspectUserDefinedFieldLinkUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UserDefinedFieldsService.endpoint + "/ProspectUserDefinedFieldLink",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteProspectUserDefinedFieldLink(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteProspectUserDefinedFieldLinkUrl(id, deleteOptions, additionalParameters)
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UserDefinedFieldsService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UserDefinedFieldsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
