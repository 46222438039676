import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { LeaseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { LeasesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/leases.service";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-select-lease-report-parameter",
   templateUrl: "select-lease-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class SelectLeaseReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   @Input() validation: ValidationModel;

   customerReportParameter: ReportReportParameterViewModel;

   leaseReportParameter: ReportReportParameterViewModel;

   customerValueModel: ReportParameterValueModel;

   leaseValueModel: ReportParameterValueModel;

   customers = new Array<SelectorItemModel>();

   leases = new Array<SelectorItemModel>();

   tenantReplacementLabel: string = "Tenant";

   private customerIDLeaseMap = new Map<number, Array<LeaseModel>>();

   private customerIDMap = new Map<number, TenantModel>();

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private leaseService: LeasesService,
      private entityViewInformationServiceBase: EntityViewInformationServiceBase
   ) {
      this.entityViewInformationServiceBase
         .getViewInformation(EntityType.Tenant)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((info) => {
            if (info) {
               this.tenantReplacementLabel = info.SingleLabel;
            }
         });
   }

   ngOnInit() {
      this.initializeValidation();
      this.initializeParameters();
      this.initializeSelectors();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.customerReportParameter.IsAsk = isAsk;
      this.leaseReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(ReportParameter.CUSTOMER, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.LeaseID, isAsk);
   }

   onCustomerChange(value: number) {
      this.customerValueModel.value = +value;
      this.reportParametersService.updateParameterValue.next(this.customerValueModel);
      this.fillLeases(value);
   }

   onLeaseChange(value: number) {
      this.leaseValueModel.value = +value;
      this.reportParametersService.updateParameterValue.next(this.leaseValueModel);
   }

   private initializeValidation() {
      this.validation = new ValidationModel();
      this.validation.required = true;
      this.validation.dataType = ExpressDataTypes.Key;
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.customerReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CUSTOMER
      );
      this.customerReportParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.leaseReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.LeaseID
      );
      this.leaseReportParameter.DisplayName = "";

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.customerValueModel = this.reportParametersService.reportParameterValues.get(ReportParameter.CUSTOMER);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.leaseValueModel = this.reportParametersService.reportParameterValues.get(ReportParameter.LeaseID);
   }

   private initializeSelectors() {
      this.customerIDLeaseMap = new Map<number, Array<LeaseModel>>();
      this.customerIDMap = new Map<number, TenantModel>();
      this.leaseService
         .getCollection([new FilterOption("IsCommercial", FilterOperations.EqualTo, [true])], ["Tenant", "Unit"])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((result) => {
            const customers = new Array<SelectorItemModel>();
            const leases = new Array<SelectorItemModel>();
            if (result) {
               result.forEach((lease) => {
                  const customerID = lease.Tenant.TenantID;
                  this.customerIDMap.set(customerID, lease.Tenant);

                  let leaseEntry = this.customerIDLeaseMap.get(customerID);
                  if (!leaseEntry) {
                     leaseEntry = [];
                  }
                  leaseEntry.push(lease);
                  this.customerIDLeaseMap.set(customerID, leaseEntry);
               });
               this.customerIDMap.forEach((value, key) => {
                  const item = new SelectorItemModel(key, value.Name);
                  customers.push(item);
               });
            }
            this.customers = customers.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
            this.leases = leases;
            if (
               this.customers.length > 0 &&
               this.customerValueModel &&
               (!this.customerValueModel.value || +this.customerValueModel.value <= 0)
            ) {
               this.onCustomerChange(this.customers[0].value);
            } else if (
               this.customerValueModel &&
               this.customerValueModel.value != null &&
               +this.customerValueModel.value > 0
            ) {
               this.onCustomerChange(+this.customerValueModel.value);
            } else {
               this.onCustomerChange(ConstantsService.NullFK);
            }
         });
   }

   private fillLeases(customerID: number) {
      const items = new Array<SelectorItemModel>();
      if (customerID) {
         const leases = this.customerIDLeaseMap.get(customerID);
         if (leases) {
            leases.forEach((l) => {
               const item = new SelectorItemModel(l.LeaseID, l.Unit.Name);
               items.push(item);
            });
         }
      }
      if (items && items.length > 0) {
         if (
            !this.leaseValueModel.value ||
            +this.leaseValueModel.value <= 0 ||
            !items.some((i) => i.value === +this.leaseValueModel.value)
         ) {
            this.onLeaseChange(items[0].value);
         }
      } else {
         this.onLeaseChange(ConstantsService.NullFK);
      }
      this.leases = items;
   }
}
