import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { ObjectMapResolverService } from "../../../../pipes/object-map-resolver.service";
import { EntityLinkFormGroup } from "./entity-link-form-group";

@Component({
   selector: "lcs-entity-link-selector-view",
   templateUrl: "entity-link-selector-view.component.html",
})
export class EntityLinkSelectorViewComponent {
   @Input() displayHorizontal: boolean = false;

   @Input() entityFilters: Array<FilterOption>;

   @Input() entityLinkValueComparer: (v1: any, v2: any) => boolean;

   @Input() entityLinkValueSource: ValueSourceModel;

   @Input() entityTypeSelectorLabel: string;

   @Input() entitySelectorLabel: string;

   @Input() entityType: EntityType;

   @Input() group: UntypedFormGroup;

   @Input() isAddHidden: boolean = false;

   @Output() linkEntityClicked = new EventEmitter<void>();

   @ViewChild("entityLinkValue") entityLinkValue;

   entityLinkEntityTypeName = EntityLinkFormGroup.entityLinkEntityTypeName;

   entityLinkValueName = EntityLinkFormGroup.entityLinkValueName;

   rootPath: string = ObjectMapResolverService.rootPath;

   onLinkEntityClicked() {
      this.linkEntityClicked.emit();
   }
}
