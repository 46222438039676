import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";

import { OverlayPanelItemAdditionalInfoLabelDirective } from "./overlay-panel-section/overlay-panel-item-additional-info-label.directive";
import { OverlayPanelItemLabelDirective } from "./overlay-panel-section/overlay-panel-item-label.directive";
import { OverlayPanelItemDirective } from "./overlay-panel-section/overlay-panel-item.directive";
import { OverlayPanelSectionComponent } from "./overlay-panel-section/overlay-panel-section.component";
import { OverlayPanelComponent } from "./overlay-panel.component";
import { OverlayTooltipArrowComponent } from "./overlay-tooltip-arrow/overlay-tooltip-arrow.component";

@NgModule({
   imports: [CommonModule, LoadingOverlayModule, ScrollingModule, MatDividerModule],
   declarations: [
      OverlayPanelComponent,
      OverlayPanelSectionComponent,
      OverlayTooltipArrowComponent,
      OverlayPanelItemDirective,
      OverlayPanelItemLabelDirective,
      OverlayPanelItemAdditionalInfoLabelDirective,
   ],
   exports: [
      OverlayPanelComponent,
      OverlayPanelSectionComponent,
      OverlayPanelItemDirective,
      OverlayPanelItemLabelDirective,
      OverlayPanelItemAdditionalInfoLabelDirective,
   ],
})
export class OverlayPanelsModule {}
