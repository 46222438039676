import { UntypedFormControl } from "@angular/forms";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";

import { EntityLinkEntityTypes } from "./entity-link-entity-types.enum";

export class EntityLinkFormGroup {
   static entityLinkEntityTypeName = "entityLinkEntityType";

   static entityLinkValueName = "entityLinkValue";

   static entityTypeName = "entityType";

   entityLinkEntityType: UntypedFormControl;

   entityLinkValue: UntypedFormControl;

   entityType: UntypedFormControl;

   constructor(entityLinkEntityType?: EntityLinkEntityTypes, entityLinkValue?: number | any, entityType?: EntityType) {
      this.entityLinkEntityType = new UntypedFormControl(entityLinkEntityType);
      this.entityLinkValue = new UntypedFormControl(entityLinkValue);
      this.entityType = new UntypedFormControl(entityType);
   }
}
