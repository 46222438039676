import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { InfiniteScrollModule } from "@lcs/infinite-scroll/infinite-scroll.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { TableModule } from "@lcs/table/table.module";

import { LayoutModule } from "../layout/layout.module";
import { OWATableModule } from "../owa-table/owa-table.module";
import { InspectionReportListComponent } from "./inspection-report-list.component";

@NgModule({
   imports: [
      CommonModule,
      TableModule,
      LayoutModule,
      InfiniteScrollModule,
      OWATableModule,
      DataTableModule,
      FormRegistrationModule,
      FormsModule,
      LoadingOverlayModule,
   ],
   declarations: [InspectionReportListComponent],
})
export class InspectionReportListModule {}
