import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectModule } from "@lcs/select/select.module";

import { ValidationModule } from "../../../../../libraries/lcs/src/lib/inputs/validation/validation.module";
import { MultiSelectModule } from "../../../../../libraries/lcs/src/lib/multi-select/multi-select.module";
import { DynamicSelectorComponent } from "./dynamic-selector.component";

@NgModule({
   imports: [CommonModule, FormsModule, MultiSelectModule, SelectModule, ValidationModule],
   declarations: [DynamicSelectorComponent],
   exports: [DynamicSelectorComponent],
})
export class DynamicSelectorModule {}
