<owa-layout [headerTitle]="'Owner Distributions'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{filter.Label}} {{filter.shortOperationLabel()}}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{filter.DisplayValue}}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>

   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="distributionFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>


      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{results | async}} of {{totalResults | async}} Owner Distributions
      </div>


      <lcs-loading-overlay [show]="$any(isDataLoading | async)">

         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table
               [dataSource]="$any(observableOwnerDistributions | async)"
               (visibleColumnsChange)="onVisibleColumnsChanged($event)"
            >

               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>

               <ng-container *ngIf="groupRows">
                  <tr
                     class="lcs-group-cell"
                     lcs-row
                     *lcsRowDef="let row; columns: rowGroupColumns; when: isFirstInGroup"
                  ></tr>
               </ng-container>

               <ng-container lcsColumnDef="group-header-toggle">
                  <td
                     lcs-cell
                     *lcsCellDef="let row"
                     [attr.colspan]="groupToggleColspan"
                  >
                     <div
                        class="row-group-wrapper"
                        (click)="toggleRowGroup(row.CheckDate)"
                     >
                        <div
                           class="material-icons row-group-icon"
                           [ngClass]="row.RowGroupExpanded ? 'row-group-expanded' : 'row-group-collapsed'"
                        >arrow_drop_down</div>
                        <div class="group-header">{{row.CheckDate | date: 'MM/dd/yyyy'}}</div>
                     </div>
                  </td>
               </ng-container>

               <ng-container lcsColumnDef="group-header-amount">
                  <td
                     lcs-cell
                     *lcsCellDef="let row"
                     class="numeric"
                  >
                     <div class="group-header">
                        <div>{{row.TotalAmount | currency:'USD':'symbol':'1.2-2'}}</div>
                     </div>
                  </td>
               </ng-container>

               <ng-container lcsColumnDef="group-header-spacer">
                  <td
                     lcs-cell
                     *lcsCellDef="let row"
                     [attr.colspan]="spacerToggleColspan"
                  ></td>
               </ng-container>

               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
                  [hidden]="!row.RowGroupExpanded"
               ></tr>

               <ng-container
                  lcsColumnDef="Date"
                  [width]="6"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  ></th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                  ></td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Amount"
                  [width]="6"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Amount</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="numeric"
                  >{{row.Amount | currency:'USD':'symbol':'1.2-2'}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Property"
                  [width]="20"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                  >{{row.Property}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Reference"
                  [width]="8"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Reference</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="numeric"
                  >{{row.Reference}}</td>
               </ng-container>

            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="((!(isDataLoading | async)) && ($any((results | async)) < $any((totalResults | async)))) "
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>