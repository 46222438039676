import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IconService } from "./icon.service";

@Component({
   selector: "lcs-icon",
   templateUrl: "./icon.component.html",
   styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
   @Input() set icon(val: string) {
      if (val !== this._icon) {
         const iconDefinition = this.iconService.buildIconDefinition(val);
         this.iconClass = iconDefinition.iconClass;
         this.iconInnerHtml = iconDefinition.iconInnerHtml;
      }
   }

   get icon(): string {
      return this._icon;
   }

   @Input() label: string;

   @Input() labelTooltip: string;

   @Input() iconTooltip: string;

   @Input() showIcon = true;

   @Input() applyClickableStylesToIcon = true;

   @Input() applyClickableStylesToLabel = true;

   @Input() disabled = false;

   @Input() iconName: string;

   @Output() iconClicked = new EventEmitter();

   iconClass: string;

   iconInnerHtml: string;

   private _icon: string;

   constructor(private iconService: IconService) {}

   onClick(event) {
      this.iconClicked.emit(event);
   }
}
