import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class TopBarService implements OnDestroy {
   public topBarIsVisible: BehaviorSubject<boolean>;

   private unsubscribe = new Subject<void>();

   constructor(private cacheMonitorService: CacheMonitorService) {
      this.topBarIsVisible = new BehaviorSubject(false);

      this.cacheMonitorService.cachesLoaded.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.topBarIsVisible.next(true);
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
