<lcs-overlay-panel
   #overlayPanelComponentRef
   [(show)]="show"
   [showOnFocus]="$any(!disabled && control?.invalid)"
   [hideOnBlur]="hideOnBlur"
   [parentElement]="parentElement"
   [innerElementTags]="innerElementTags"
   [innerElementSelector]="innerElementSelector"
   [alignToRight]="alignToRight"
   [staticOverlay]="staticOverlay"
   [disabled]="disabled"
   [toggleOnClick]="toggleOnClick"
   [parentOverlayPanelRef]="parentOverlayPanelRef"
   [overlayPanelStaticWidth]="overlayPanelStaticWidth"
>
   <lcs-validation-error-display-panel
      *ngIf="control?.invalid && (control?.touched || control?.dirty) && !errorMessage"
      [displayName]="displayName"
      [errors]="$any(control?.errors)"
   >
   </lcs-validation-error-display-panel>
   <lcs-overlay-panel-section
      [ngClass]="classes"
      [itemSet]="itemSet"
      [itemSetIsLoading]="itemSetIsLoading"
      [errorMessage]="errorMessage"
      [additionalMessage]="additionalMessage"
      [selectorOptionTemplate]="selectorOptionTemplate"
      [selectable]="selectable"
      [selectedItemIndex]="$any(selectedItemIndex)"
      (selectedItemIndexChange)="onSelectedItemIndexChange($event)"
      (selected)="onItemSelected($event)"
   >
   </lcs-overlay-panel-section>
</lcs-overlay-panel>