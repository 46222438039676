import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { LogicalOperators } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/logical-operators.enum";
import { ProjectStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/project-status.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";

import { FilterValueType } from "../../filters/filter-value.types";
import { RelativeDateOptions } from "../../inputs/date-picker/relative-date-options.enum";
import { RelativeDatesService } from "../../inputs/date-picker/relative-dates.service";
import { warnInDevMode } from "../../utils/logging";
import { ReportParameterValueModel } from "./models/report-parameter-value.model";
import { ReportParameterDependencyService } from "./report-parameter-dependency.service";

@Injectable()
export class ReportParameterFiltersService {
   constructor(private dependencyService: ReportParameterDependencyService) {}

   getInitialEntityValueSourceFilterExpression(
      reportID: Report,
      reportParameter: ReportParameter,
      allValues: Map<ReportParameter, ReportParameterValueModel>
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): FilterExpression {
      const dependencies = this.dependencyService.getDependencies(reportID);
      if (dependencies.includes(reportParameter)) {
         if (reportParameter === ReportParameter.UNITID && allValues.has(ReportParameter.PropertyID)) {
            return this.getSelectUnitUnitFilterExpression(allValues);
         }
      }
   }

   getInitialEntityValueSourceFilters(
      reportID: number,
      reportParameter: ReportParameter,
      allValues: Map<ReportParameter, ReportParameterValueModel>
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): Array<FilterOption> {
      const dependencies = this.dependencyService.getDependencies(reportID);
      if (dependencies.includes(reportParameter)) {
         if (
            reportParameter === ReportParameter.DEPOSITS &&
            allValues.has(ReportParameter.StartDate) &&
            allValues.has(ReportParameter.EndDate) &&
            allValues.has(ReportParameter.BANKS)
         ) {
            return this.getFilteredDeposits(allValues);
         } else if (
            reportParameter === ReportParameter.DepositID &&
            allValues.has(ReportParameter.StartDate) &&
            allValues.has(ReportParameter.EndDate)
         ) {
            return this.getFilteredDeposits(allValues);
         } else if (
            reportParameter === ReportParameter.PropertyID &&
            allValues.has(ReportParameter.SHOWINACTIVEPROPS)
         ) {
            return this.getSelectUnitPropertyFilters(allValues);
         } else if (reportParameter === ReportParameter.CUSTIDS && allValues.has(ReportParameter.PropertyIDs)) {
            return this.getSelectCustomerFilters(allValues, ReportParameter.PropertyIDs);
         } else if (
            reportParameter === ReportParameter.UNITIDS &&
            (allValues.has(ReportParameter.PropertyIDs) || allValues.has(ReportParameter.PropOwnerIDs))
         ) {
            return this.getMultiSelectUnitUnitFilters(allValues, true);
         } else if (
            reportParameter === ReportParameter.NonAssetORIUnitsToInclude &&
            (allValues.has(ReportParameter.PropertyIDs) || allValues.has(ReportParameter.PropOwnerIDs))
         ) {
            return this.getMultiSelectUnitUnitFilters(allValues, false);
         } else if (
            reportParameter === ReportParameter.EPAYBATCHES &&
            allValues.has(ReportParameter.StartDate) &&
            allValues.has(ReportParameter.EndDate)
         ) {
            return this.getMultiSelectEPayFilters(allValues);
         } else if (reportParameter === ReportParameter.OWNERIDS && allValues.has(ReportParameter.YEAR1099)) {
            return this.getMultiSelectOwnerYearFilters(allValues);
         } else if (reportParameter === ReportParameter.OWNERIDS && allValues.has(ReportParameter.AsOfDate)) {
            return this.getMultiSelectOwnerFilters(allValues);
         } else if (reportParameter === ReportParameter.PROJECTS && allValues.has(ReportParameter.PROJECTTYPE)) {
            return this.getSelectProjectFilters(allValues);
         } else if (
            reportParameter === ReportParameter.PropertyIDs &&
            allValues.has(ReportParameter.SHOWINACTIVEPROPS)
         ) {
            return this.getPropertiesFilters(allValues);
         } else if (reportParameter === ReportParameter.JobIDs && allValues.has(ReportParameter.IncludeInactiveJobs)) {
            return this.getJobsFilters(allValues);
         }
      }
   }

   public getUpdatedEntityValueSourceFilterExpression(
      reportID: number,
      reportParameter: ReportParameter,
      updatedValue: ReportParameterValueModel,
      allValues: Map<ReportParameter, ReportParameterValueModel>
   ): FilterExpression | null {
      const dependencies = this.dependencyService.getDependencies(reportID);

      if (dependencies.includes(reportParameter)) {
         if (
            reportParameter === ReportParameter.UNITID &&
            updatedValue.reportParameter === ReportParameter.PropertyID
         ) {
            return this.getSelectUnitUnitFilterExpression(allValues);
         }
      }
      return null;
   }

   public getUpdatedEntityValueSourceFilters(
      reportID: number,
      reportParameter: ReportParameter,
      updatedValue: ReportParameterValueModel,
      allValues: Map<ReportParameter, ReportParameterValueModel>
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): Array<FilterOption> {
      const dependencies = this.dependencyService.getDependencies(reportID);
      if (dependencies.includes(reportParameter)) {
         if (
            reportParameter === ReportParameter.DEPOSITS &&
            (updatedValue.reportParameter === ReportParameter.StartDate ||
               updatedValue.reportParameter === ReportParameter.EndDate ||
               updatedValue.reportParameter === ReportParameter.BANKS)
         ) {
            return this.getFilteredDeposits(allValues);
         } else if (
            reportParameter === ReportParameter.DepositID &&
            (updatedValue.reportParameter === ReportParameter.StartDate ||
               updatedValue.reportParameter === ReportParameter.EndDate)
         ) {
            return this.getFilteredDeposits(allValues);
         } else if (
            reportParameter === ReportParameter.PropertyID &&
            updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS
         ) {
            return this.getSelectUnitPropertyFilters(allValues);
         } else if (
            reportParameter === ReportParameter.CUSTIDS &&
            (updatedValue.reportParameter === ReportParameter.PropertyIDs ||
               updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS ||
               updatedValue.reportParameter === ReportParameter.POSTINGPERIOD)
         ) {
            return this.getSelectCustomerFilters(allValues, updatedValue.reportParameter);
         } else if (
            reportParameter === ReportParameter.UNITIDS &&
            (updatedValue.reportParameter === ReportParameter.PropertyIDs ||
               updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS ||
               updatedValue.reportParameter === ReportParameter.PropOwnerIDs ||
               updatedValue.reportParameter === ReportParameter.RUNBYOWNER)
         ) {
            return this.getMultiSelectUnitUnitFilters(allValues, true);
         } else if (
            reportParameter === ReportParameter.NonAssetORIUnitsToInclude &&
            (updatedValue.reportParameter === ReportParameter.PropertyIDs ||
               updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS ||
               updatedValue.reportParameter === ReportParameter.PropOwnerIDs ||
               updatedValue.reportParameter === ReportParameter.RUNBYOWNER)
         ) {
            return this.getMultiSelectUnitUnitFilters(allValues, false);
         } else if (
            reportParameter === ReportParameter.PROJECTS &&
            updatedValue.reportParameter === ReportParameter.PROJECTTYPE
         ) {
            return this.getSelectProjectFilters(allValues);
         } else if (
            reportParameter === ReportParameter.EPAYBATCHES &&
            (updatedValue.reportParameter === ReportParameter.StartDate ||
               updatedValue.reportParameter === ReportParameter.EndDate)
         ) {
            return this.getMultiSelectEPayFilters(allValues);
         } else if (
            reportParameter === ReportParameter.OWNERIDS &&
            updatedValue.reportParameter === ReportParameter.AsOfDate
         ) {
            return this.getMultiSelectOwnerFilters(allValues);
         } else if (
            reportParameter === ReportParameter.OWNERIDS &&
            updatedValue.reportParameter === ReportParameter.YEAR1099
         ) {
            return this.getMultiSelectOwnerYearFilters(allValues);
         } else if (
            reportParameter === ReportParameter.PropertyIDs &&
            updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS
         ) {
            return this.getPropertiesFilters(allValues);
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Filter... Remove this comment to see the full error message
         return undefined;
      }
   }

   private getFilteredDeposits(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      let startDateValue = allValues.get(ReportParameter.StartDate).value;
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      let endDateValue = allValues.get(ReportParameter.EndDate).value;
      if (isNaN(new Date(startDateValue).getTime())) {
         // Start date is set to a relative date - needs converted to a corresponding date.
         const dateValue = RelativeDatesService.getRelativeDate(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
            RelativeDatesService.relativeDateValueMap.get(startDateValue)
         );
         startDateValue = formatDate(dateValue, "MM/dd/yyyy", GlobalsService.locale);
      }
      if (isNaN(new Date(endDateValue).getTime())) {
         // End date is set to a relative date - needs converted to a corresponding date.
         const dateValue = RelativeDatesService.getRelativeDate(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
            RelativeDatesService.relativeDateValueMap.get(endDateValue)
         );
         endDateValue = formatDate(dateValue, "MM/dd/yyyy", GlobalsService.locale);
      }

      // "AllValues" report param map is capable of returning type "any"
      if (!FilterOption.validValueTypeForFilter(startDateValue)) {
         warnInDevMode("Invalid Filter Value Type");
      }
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
      filters.push(new FilterOption("TransactionDate", FilterOperations.GreaterThanOrEqualTo, [startDateValue]));

      if (!FilterOption.validValueTypeForFilter(endDateValue)) {
         warnInDevMode("Invalid Filter Value Type");
      }
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
      filters.push(new FilterOption("TransactionDate", FilterOperations.LessThanOrEqualTo, [endDateValue]));
      const bankParameter = allValues.get(ReportParameter.BANKS);
      if (bankParameter) {
         const banks: string = bankParameter.value;
         if (banks === "") {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("BankID", FilterOperations.EqualTo, [-1]));
         } else if (banks && banks.toLowerCase() !== ConstantsService.allValue) {
            const bankIDs = banks.replace("(", "").replace(")", "").split(",");
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("BankID", FilterOperations.In, bankIDs));
         }
      }
      return filters;
   }

   private getSelectUnitPropertyFilters(
      allValues: Map<ReportParameter, ReportParameterValueModel>
   ): Array<FilterOption> {
      return this.getPropertiesFilters(allValues);
   }

   private getSelectUnitUnitFilterExpression(
      allValues: Map<ReportParameter, ReportParameterValueModel>
   ): FilterExpression {
      let filterExpression: FilterExpression | null = null;
      const value = allValues.get(ReportParameter.PropertyID);
      if (
         value != null &&
         value.value != null &&
         value.value !== "" &&
         value.value.toString().toLocaleLowerCase() !== ConstantsService.allValue
      ) {
         let filterValues: FilterValueType[] = ["-1"];
         if (value.rawValues && value.rawValues.length > 0) {
            filterValues = value.rawValues;
         } else if (value.value !== "") {
            filterValues = value.value.toString().split(",");
         }

         filterExpression = new FilterExpression();
         filterExpression.LogicalOperator = LogicalOperators.And;
         filterExpression.FilterOptions.push(new FilterOption("PropertyID", FilterOperations.EqualTo, filterValues));

         // add filterExpression to restrict to ActiveUnits only determined by there either not being a CurrentUnitStatus
         // or the CurrentUnitStatusTypeID != 1 INACTV
         const InactiveUnitStatusTypeId = 1; // NOTE: UnitStatusTypeID values are customer definable, however 1 is reserved for identifying INACTV units and cannot be changed or deleted
         const activeUnitFilterExpression = new FilterExpression();
         activeUnitFilterExpression.LogicalOperator = LogicalOperators.Or;
         activeUnitFilterExpression.FilterOptions.push(
            new FilterOption("CurrentUnitStatus", FilterOperations.HasValue, [false])
         );
         activeUnitFilterExpression.FilterOptions.push(
            new FilterOption("CurrentUnitStatus.UnitStatusTypeID", FilterOperations.NotEqualTo, [
               InactiveUnitStatusTypeId,
            ])
         );

         filterExpression.SubExpressions.push(activeUnitFilterExpression);
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterExpression | null' is not assignable t... Remove this comment to see the full error message
      return filterExpression;
   }

   private getPropertiesFilters(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const showInactiveValue = allValues.get(ReportParameter.SHOWINACTIVEPROPS).value;
      if (showInactiveValue != null && showInactiveValue.toString().toLocaleLowerCase() === "false") {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      }
      return filters;
   }

   private getSelectCustomerFilters(
      allValues: Map<ReportParameter, ReportParameterValueModel>,
      updatedParameter: ReportParameter
   ): Array<FilterOption> {
      const filters = [];
      const value = allValues.get(ReportParameter.PropertyIDs);
      if (
         value != null &&
         value.value != null &&
         value.value.toString().toLocaleLowerCase() !== ConstantsService.allValue
      ) {
         let filterValues: FilterValueType[] = ["-1"];
         if (value.rawValues && value.rawValues.length > 0) {
            filterValues = value.rawValues;
         } else if (value.value !== "") {
            filterValues = value.value.toString().split(",");
         }
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("PropertyID", FilterOperations.In, filterValues));
      }

      const showInactive = allValues.get(ReportParameter.SHOWINACTIVEPROPS);
      if (showInactive != null && showInactive.value.toString().toLocaleLowerCase() === "false") {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("Property.IsActive", FilterOperations.EqualTo, [true]));
      }

      if (updatedParameter === ReportParameter.PropertyIDs) {
         const customersValue = allValues.get(ReportParameter.CUSTIDS);
         if (customersValue) {
            customersValue.value = ConstantsService.allValue;
         }
      }
      const postingperiod = allValues.get(ReportParameter.POSTINGPERIOD);
      if (postingperiod && postingperiod.value) {
         filters.push(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            new FilterOption("RentPeriod", FilterOperations.EqualTo, [
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               allValues.get(ReportParameter.POSTINGPERIOD).value,
            ])
         );
      }
      return filters;
   }

   private getMultiSelectUnitUnitFilters(
      allValues: Map<ReportParameter, ReportParameterValueModel>,
      includeAssets: boolean
   ): Array<FilterOption> {
      const filters = [];
      const value = allValues.get(ReportParameter.PropertyIDs);
      if (
         value != null &&
         value.value != null &&
         value.value.toString().toLocaleLowerCase() !== ConstantsService.allValue
      ) {
         let filterValues: FilterValueType[] = ["-1"];
         if (value.rawValues && value.rawValues.length > 0) {
            filterValues = value.rawValues;
         } else if (value.value !== "") {
            filterValues = value.value.toString().split(",");
         }
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("PropertyID", FilterOperations.In, filterValues));
      }

      const propOwnerValue = allValues.get(ReportParameter.PropOwnerIDs);

      if (propOwnerValue != null && propOwnerValue.value.toString().toLocaleLowerCase() !== ConstantsService.allValue) {
         const runByOwnerValue = allValues.get(ReportParameter.RUNBYOWNER);
         if (runByOwnerValue && runByOwnerValue.value.toString().toLocaleLowerCase() === "true") {
            let filterValues: FilterValueType[] = ["-1"];
            if (propOwnerValue.rawValues && propOwnerValue.rawValues.length > 0) {
               filterValues = propOwnerValue.rawValues;
            } else if (propOwnerValue.value !== "") {
               filterValues = propOwnerValue.value.toString().split(",");
            }
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("Property.Ownerships.OwnerID", FilterOperations.In, filterValues));
         } else {
            let filterValues: FilterValueType[] = ["-1"];
            if (propOwnerValue.rawValues && propOwnerValue.rawValues.length > 0) {
               filterValues = propOwnerValue.rawValues;
            } else if (propOwnerValue.value !== "") {
               filterValues = propOwnerValue.value.toString().split(",");
            }
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("PropertyID", FilterOperations.EqualTo, filterValues));
         }
      }

      const showInactive = allValues.get(ReportParameter.SHOWINACTIVEPROPS);
      if (showInactive != null && showInactive.value.toString().toLocaleLowerCase() === "false") {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("Property.IsActive", FilterOperations.EqualTo, [true]));
      }

      if (!includeAssets) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("IsAsset", FilterOperations.EqualTo, [false]));
      }
      const unitsValue = allValues.get(ReportParameter.UNITIDS);
      if (unitsValue) {
         unitsValue.value = ConstantsService.allValue;
      }
      return filters;
   }

   private getSelectProjectFilters(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const projectType = +allValues.get(ReportParameter.PROJECTTYPE).value;
      if (projectType !== ProjectStatus.All) {
         if (projectType === ProjectStatus.Completed) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("IsClosed", FilterOperations.EqualTo, [true]));
         } else if (projectType === ProjectStatus.None) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("StatusID", FilterOperations.EqualTo, [ProjectStatus.None]));
         } else {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            filters.push(new FilterOption("IsClosed", FilterOperations.NotEqualTo, [true]));
         }
      }
      return filters;
   }

   private getJobsFilters(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      const showInactive = allValues.get(ReportParameter.IncludeInactiveJobs);
      if (showInactive != null && showInactive.value.toString().toLocaleLowerCase() === "false") {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, ["true"]));
      }
      return filters;
   }

   private getMultiSelectEPayFilters(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const startValue = allValues.get(ReportParameter.StartDate).value;
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const endValue = allValues.get(ReportParameter.EndDate).value;
      let startDate: Date;
      let endDate: Date;

      if (startValue) {
         if (this.isRelativeDateOptionsEnum(startValue)) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
            startDate = RelativeDatesService.getRelativeDate(RelativeDatesService.relativeDateValueMap.get(startValue));
         } else {
            startDate = startValue;
         }

         filters.push(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            new FilterOption(
               "BatchDate",
               FilterOperations.GreaterThanOrEqualTo,
               [startDate],
               null,
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
               null,
               null,
               ExpressDataTypes.DateTime
            )
         );
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'endDate' is used before being assigned.
      if (endDate) {
         if (this.isRelativeDateOptionsEnum(endValue)) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
            endDate = RelativeDatesService.getRelativeDate(RelativeDatesService.relativeDateValueMap.get(startValue));
         } else {
            endDate = endValue;
         }
         filters.push(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
            new FilterOption(
               "BatchDate",
               FilterOperations.LessThanOrEqualTo,
               [endDate],
               null,
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
               null,
               null,
               ExpressDataTypes.DateTime
            )
         );
      }
      return filters;
   }

   private getMultiSelectOwnerFilters(allValues: Map<ReportParameter, ReportParameterValueModel>): Array<FilterOption> {
      const filters = [];
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const asOfDateValue = allValues.get(ReportParameter.AsOfDate).value;
      let dateValue: Date;

      if (this.isRelativeDateOptionsEnum(asOfDateValue)) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
         dateValue = RelativeDatesService.getRelativeDate(RelativeDatesService.relativeDateValueMap.get(asOfDateValue));
      } else {
         dateValue = asOfDateValue;
      }

      filters.push(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         new FilterOption(
            "ownerships.ContractTo",
            FilterOperations.GreaterThanOrEqualToOrNull,
            [dateValue],
            null,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            ExpressDataTypes.DateTime
         )
      );
      filters.push(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         new FilterOption(
            "ownerships.ContractFrom",
            FilterOperations.LessThanOrEqualToOrNull,
            [dateValue],
            null,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            ExpressDataTypes.DateTime
         )
      );
      return filters;
   }

   private getMultiSelectOwnerYearFilters(
      allValues: Map<ReportParameter, ReportParameterValueModel>
   ): Array<FilterOption> {
      const filters = [];
      filters.push(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         new FilterOption("ownerships.ContractTo", FilterOperations.GreaterThanOrEqualToOrNull, [
            // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            "1/1/" + allValues.get(ReportParameter.YEAR1099).value,
         ])
      );
      filters.push(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption' is not assignable ... Remove this comment to see the full error message
         new FilterOption("ownerships.ContractFrom", FilterOperations.LessThanOrEqualToOrNull, [
            // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            "12/31/" + allValues.get(ReportParameter.YEAR1099).value,
         ])
      );
      return filters;
   }

   private isRelativeDateOptionsEnum(value: string | number) {
      return Object.values(RelativeDateOptions).includes(value);
   }
}
