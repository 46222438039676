import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { ControlContainerViewProvider } from "./control-container-view-providers";

@Component({
   selector: "lcs-input",
   templateUrl: "input.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class InputComponent {
   @Input() addBlankItem: boolean;

   @Input() allowNegative: boolean = false;

   @Input() additionalInformationValue: any;

   @Input() controlType: ExpressLayoutControlTypes;

   @Input() subControlType: ExpressLayoutControlTypes;

   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() endpointOverride: string;

   @Input() entityID: number;

   @Input() entityType: EntityType;

   @Input() enumName: string;

   @Input() expandable: boolean;

   @Input() filters: Array<FilterOption>;

   @Input() hideCheckBoxLabel: boolean;

   @Input() hideSelectorLabel: boolean;

   @Input() isInverted: boolean;

   @Input() isSubItem: boolean;

   @Input() name: string;

   @Input() parentEntityType: EntityType;

   @Input() placeholder: string = "";

   @Input() resource: string;

   @Input() secondaryValidation: ValidationModel;

   @Input() standalone: boolean;

   @Input() staticValues: Array<SelectorItemModel>;

   @Input() showSeparator: boolean = true;
   @Input() tooltip: string;

   @Input() set validation(validation: ValidationModel) {
      this._validation = validation;
      if (validation && validation.maxFractionalDigits != null) {
         this.numberFormat = `1.${validation.maxFractionalDigits}-${validation.maxFractionalDigits}`;
      }
   }
   get validation(): ValidationModel {
      return this._validation;
   }

   @Input() value: any;

   @Input() valueSource: ValueSourceModel;

   @Input() valuesToExclude: Array<string>;

   @Input() updateOn: "change" | "blur" | "submit";

   @Input() inlineLabel: boolean;

   @Input() hideIcon: boolean;

   @Input() embeds: Array<string>;

   @Input() additionalDataFields: Array<string>;

   @Input() checkboxCustomStyle = {};

   @Output() valueChange = new EventEmitter<any>();

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() singleSelectorChange = new EventEmitter<number>();

   @ViewChild("selector") selector: {
      selectedItem: SelectorItemModel;
      selector?: { items?: Array<SelectorItemModel> };
   };

   controlTypes = ExpressLayoutControlTypes;

   numberFormat: string;

   selectedItem: SelectorItemModel;

   private _validation: ValidationModel;

   onValueChange(value: any) {
      this.value = value;
      if (this.selector) {
         this.selectedItem = this.selector.selectedItem;
      }
      this.valueChange.emit(value);
   }

   onSelected(selectedItem: SelectorItemModel) {
      this.selectedItem = selectedItem;
      this.value = selectedItem.value;
      this.valueChange.emit(this.value);
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.selectionChange.emit(selectionChange);
   }

   onSelectionPanelClose() {
      this.valueChange.emit(this.value);
   }

   onSingleSelectorChange(selectionChange: number) {
      this.singleSelectorChange.emit(selectionChange);
   }
}
