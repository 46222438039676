import { Injectable } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { IssuesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/serviceissues.service";
import { catchError, combineLatest, distinctUntilChanged, filter, first, map, mapTo, merge, Observable, of, shareReplay, Subject, switchMap } from "rxjs";

import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { ServiceIssuePreferences } from "./service-issue-preferences.interface";

@Injectable()
export class ServiceIssueDetailService {
   loading = new Observable<boolean>();

   issue: Observable<ServiceManagerIssueModel>;

   preferences: Observable<ServiceIssuePreferences>;

   private _loading = new Subject<boolean>();

   constructor(
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private issueService: IssuesService,
      private route: ActivatedRoute,
      private errorMessageService: ErrorMessageService,
      private router: Router
   ) {
      const serviceIssueId = this.route.paramMap.pipe(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
         map((paramMap: ParamMap) => parseInt(paramMap.get("id"))),
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         filter((id: number) => id && !isNaN(id)), // ignore invalid urls for now. TODO: throw an error? re-route to the list view?
         distinctUntilChanged() // ignore duplicates
      );

      this.preferences = this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            return of({
               // TODO: are these default values correct?
               excludeVendorInfo: true,
               excludeTenantInfo: true,
               showWorkOrders: false,
               showBills: false,
               showHistory: false,
            });
         }),
         first(),
         map((preferences: SystemWebPreferenceModel[]) => ({
            excludeVendorInfo:
               preferences.filter((f) => f.Name === "ExcludeVendorInformationFromServiceIssuesOWA").length !== 0
                  ? preferences
                       .filter((f) => f.Name === "ExcludeVendorInformationFromServiceIssuesOWA")[0]
                       .Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : true,
            excludeTenantInfo:
               preferences.filter((f) => f.Name === "ExcludeTenantInformationFromServiceIssuesOWA").length !== 0
                  ? preferences
                       .filter((f) => f.Name === "ExcludeTenantInformationFromServiceIssuesOWA")[0]
                       .Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : true,
            showWorkOrders:
               preferences.filter((f) => f.Name === "ShowWorkOrdersOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowWorkOrdersOWA")[0].Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : false,
            showBills:
               preferences.filter((f) => f.Name === "ShowBillsOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowBillsOWA")[0].Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : false,
            showHistory:
               preferences.filter((f) => f.Name === "SelectedServiceIssueNotesCategoriesOWA").length !== 0
                  ? preferences
                       .filter((f) => f.Name === "SelectedServiceIssueNotesCategoriesOWA")[0]
                       .Value.toLocaleLowerCase() === ""
                     ? false
                     : true
                  : false,
         })),
         shareReplay(1) // share the results among all subscribers (avoid duplicate API calls)
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<ServiceManagerIssueModel | null>'... Remove this comment to see the full error message
      this.issue = serviceIssueId.pipe(
         switchMap((id: number) => {
            this._loading.next(true);
            return this.issueService.get(id);
         }),
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            this._loading.next(false);
            this.router.navigate(["/serviceissues"]);
            return of(null);
         }),
         shareReplay(1) // share the results among all subscribers (avoid duplicate API calls)
      );

      this.loading = merge(this._loading, combineLatest(this.preferences, this.issue).pipe(mapTo(false)));
   }
}
