import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-consolidate-address-report-parameter",
   templateUrl: "consolidate-address-report-parameter.component.html",
})
export class ConsolidateAddressReportParameterComponent implements OnInit, UserInputComponent, OnDestroy {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   manualOverride: boolean = false;

   primaryParameter: ReportReportParameterViewModel;

   loanAddressToUseParameter: ReportReportParameterViewModel;

   consolidate1096PropertyParameter: ReportReportParameterViewModel;

   consolidate1099PropertyParameter: ReportReportParameterViewModel;

   nameParameter: ReportReportParameterViewModel;

   streetParameter: ReportReportParameterViewModel;

   cityParameter: ReportReportParameterViewModel;

   stateParameter: ReportReportParameterViewModel;

   zipParameter: ReportReportParameterViewModel;

   phoneParameter: ReportReportParameterViewModel;

   emailParameter: ReportReportParameterViewModel;

   taxIDParameter: ReportReportParameterViewModel;

   faxParameter: ReportReportParameterViewModel;

   contactParameter: ReportReportParameterViewModel;

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.Loan1098AddressToUse && this.loanAddressToUseParameter) {
         this.manualOverride = updatedValue.value.toLowerCase() === "manual";
      }
      if (updatedValue.reportParameter === ReportParameter.CONSOLIDATE1096 && this.consolidate1096PropertyParameter) {
         this.manualOverride = updatedValue.value;
      }
      if (updatedValue.reportParameter === ReportParameter.CONSOLIDATE1099 && this.consolidate1099PropertyParameter) {
         this.manualOverride = updatedValue.value;
      }
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.loanAddressToUseParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.Loan1098AddressToUse
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.consolidate1096PropertyParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLIDATE1096
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.consolidate1099PropertyParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLIDATE1099
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.nameParameter = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.CONSOLNAME);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.streetParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLADDR1
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.cityParameter = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.CONSOLCITY);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.stateParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLSTATE
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.zipParameter = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.CONSOLZIP);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.phoneParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLPHONE
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.emailParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLEMAIL
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.taxIDParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CONSOLTAXID
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.faxParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ConsolidatedFax
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.contactParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ConsolidatedContact
      );
   }

   private initializeReportParameterValues() {
      if (this.loanAddressToUseParameter) {
         this.primaryParameter = this.loanAddressToUseParameter;
         this.manualOverride =
            this.loanAddressToUseParameter.ReportParameterValueSource.DefaultValue.toLowerCase() === "manual";
      }
      if (this.consolidate1096PropertyParameter) {
         this.primaryParameter = this.consolidate1096PropertyParameter;
         this.manualOverride =
            this.consolidate1096PropertyParameter.ReportParameterValueSource.DefaultValue.toLowerCase() === "true";
      }
      if (this.consolidate1099PropertyParameter) {
         this.primaryParameter = this.consolidate1099PropertyParameter;
         this.manualOverride =
            this.consolidate1099PropertyParameter.ReportParameterValueSource.DefaultValue.toLowerCase() === "true";
      }
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
