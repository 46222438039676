import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SessionCheckResult } from "./session-check-result.enum";

@Injectable()
export abstract class SessionCheckService {
   abstract checkSessionOnFocus: boolean;

   abstract get sessionCheckResult(): Observable<SessionCheckResult>;

   abstract checkSession(forRouting?: boolean): Observable<SessionCheckResult>;

   abstract forceCheckSession(forRouting: boolean): Observable<SessionCheckResult>;

   abstract checkSessionAsync(forRouting: boolean): void;

   abstract forceCheckSessionAsync(forRouting: boolean): void;
}
