import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class PropertyFields extends EntityField {
   static readonly AccountingClose: PropertyFields = new PropertyFields("AccountingClose");

   static readonly AccountingCloseOption: PropertyFields = new PropertyFields("AccountingCloseOption");

   static readonly Addresses: PropertyFields = new PropertyFields("Addresses");

   static readonly AllocationOrder: PropertyFields = new PropertyFields("AllocationOrder");

   static readonly ApplicationSettings: PropertyFields = new PropertyFields("ApplicationSettings");

   static readonly ApplicationTemplates: PropertyFields = new PropertyFields("ApplicationTemplates");

   static readonly ARAutomationSchedule: PropertyFields = new PropertyFields("ARAutomationSchedule");

   static readonly ARAutomationScheduleID: PropertyFields = new PropertyFields("ARAutomationScheduleID");

   static readonly ARAutomationUseSystemPreference: PropertyFields = new PropertyFields(
      "ARAutomationUseSystemPreference"
   );

   static readonly AssetLocationHistory: PropertyFields = new PropertyFields("AssetLocationHistory");

   static readonly AssociationSettings: PropertyFields = new PropertyFields("AssociationSettings");

   static readonly Banks: PropertyFields = new PropertyFields("Banks");

   static readonly BillingAddress: PropertyFields = new PropertyFields("BillingAddress");

   static readonly BillingName1: PropertyFields = new PropertyFields("BillingName1");

   static readonly BillingName2: PropertyFields = new PropertyFields("BillingName2");

   static readonly BoardMembers: PropertyFields = new PropertyFields("BoardMembers");

   static readonly ChargeTypeBanks: PropertyFields = new PropertyFields("ChargeTypeBanks");

   static readonly CheckTemplateID: PropertyFields = new PropertyFields("CheckTemplateID");

   static readonly Color: PropertyFields = new PropertyFields("Color");

   static readonly ColorID: PropertyFields = new PropertyFields("ColorID");

   static readonly Comment: PropertyFields = new PropertyFields("Comment");

   static readonly CommitteeMembers: PropertyFields = new PropertyFields("CommitteeMembers");

   static readonly CommunityEvents: PropertyFields = new PropertyFields("CommunityEvents");

   static readonly ConcurrencyID: PropertyFields = new PropertyFields("ConcurrencyID");

   static readonly CreateDate: PropertyFields = new PropertyFields("CreateDate");

   static readonly CreateUser: PropertyFields = new PropertyFields("CreateUser");

   static readonly CreateUserID: PropertyFields = new PropertyFields("CreateUserID");

   static readonly CurrentAssetLocation: PropertyFields = new PropertyFields("CurrentAssetLocation");

   static readonly CurrentOwners: PropertyFields = new PropertyFields("CurrentOwners");

   static readonly DefaultBank: PropertyFields = new PropertyFields("DefaultBank");

   static readonly DefaultBankID: PropertyFields = new PropertyFields("DefaultBankID");

   static readonly DefaultInspector: PropertyFields = new PropertyFields("DefaultInspector");

   static readonly DefaultInspectorID: PropertyFields = new PropertyFields("DefaultInspectorID");

   static readonly Email: PropertyFields = new PropertyFields("Email");

   static readonly EndorsementOverride: PropertyFields = new PropertyFields("EndorsementOverride");

   static readonly FiscalYearEndDay: PropertyFields = new PropertyFields("FiscalYearEndDay");

   static readonly FiscalYearEndMonth: PropertyFields = new PropertyFields("FiscalYearEndMonth");

   static readonly FiscalYearStartDay: PropertyFields = new PropertyFields("FiscalYearStartDay");

   static readonly FiscalYearStartMonth: PropertyFields = new PropertyFields("FiscalYearStartMonth");

   static readonly Floors: PropertyFields = new PropertyFields("Floors");

   static readonly History: PropertyFields = new PropertyFields("History");

   static readonly HistoryNotes: PropertyFields = new PropertyFields("HistoryNotes");

   static readonly HistorySystemNotes: PropertyFields = new PropertyFields("HistorySystemNotes");

   static readonly Holidays: PropertyFields = new PropertyFields("Holidays");

   static readonly Images: PropertyFields = new PropertyFields("Images");

   static readonly IsActive: PropertyFields = new PropertyFields("IsActive");

   static readonly IsAllocationOrderDisabled: PropertyFields = new PropertyFields("IsAllocationOrderDisabled");

   static readonly IsAllocationOrderSortedByMonth: PropertyFields = new PropertyFields(
      "IsAllocationOrderSortedByMonth"
   );

   static readonly IsCommercial: PropertyFields = new PropertyFields("IsCommercial");

   static readonly IsDaylightSavingsTime: PropertyFields = new PropertyFields("IsDaylightSavingsTime");

   static readonly IsEndorsementOverride: PropertyFields = new PropertyFields("IsEndorsementOverride");

   static readonly IsEpayEnabled: PropertyFields = new PropertyFields("IsEpayEnabled");

   static readonly IsLateChargeEnabled: PropertyFields = new PropertyFields("IsLateChargeEnabled");

   static readonly IsLockboxEnabled: PropertyFields = new PropertyFields("IsLockboxEnabled");

   static readonly IsOnlyIncludePropertyListing: PropertyFields = new PropertyFields("IsOnlyIncludePropertyListing");

   static readonly IsOptOutCreditReporting: PropertyFields = new PropertyFields("IsOptOutCreditReporting");

   static readonly IsOverrideAssignedUserID: PropertyFields = new PropertyFields("IsOverrideAssignedUserID");

   static readonly IsOverrideDefaultHolidays: PropertyFields = new PropertyFields("IsOverrideDefaultHolidays");

   static readonly IsOverrideFiscalYear: PropertyFields = new PropertyFields("IsOverrideFiscalYear");

   static readonly IsOverwriteTimeZone: PropertyFields = new PropertyFields("IsOverwriteTimeZone");

   static readonly IsOwnerPayPerUnit: PropertyFields = new PropertyFields("IsOwnerPayPerUnit");

   static readonly IsPropertyLevelOnlineListings: PropertyFields = new PropertyFields("IsPropertyLevelOnlineListings");

   static readonly IsSecurityDepositInterestEnabled: PropertyFields = new PropertyFields(
      "IsSecurityDepositInterestEnabled"
   );

   static readonly IsShortTermRental: PropertyFields = new PropertyFields("IsShortTermRental");

   static readonly IsStrOnlineBookingEnabled: PropertyFields = new PropertyFields("IsStrOnlineBookingEnabled");

   static readonly IsSystemDefaultAssignedUser: PropertyFields = new PropertyFields("IsSystemDefaultAssignedUser");

   static readonly IsTimeZoneOffsetEnabled: PropertyFields = new PropertyFields("IsTimeZoneOffsetEnabled");

   static readonly IsTrackRentersInsurance: PropertyFields = new PropertyFields("IsTrackRentersInsurance");

   static readonly IsUseMasterPolicy: PropertyFields = new PropertyFields("IsUseMasterPolicy");

   static readonly LastDailyPost: PropertyFields = new PropertyFields("LastDailyPost");

   static readonly LastManagementFeePost: PropertyFields = new PropertyFields("LastManagementFeePost");

   static readonly LastMonthlyPost: PropertyFields = new PropertyFields("LastMonthlyPost");

   static readonly LastSuccessfulOnlineListingsPosting: PropertyFields = new PropertyFields(
      "LastSuccessfulOnlineListingsPosting"
   );

   static readonly LastUnitCountPost: PropertyFields = new PropertyFields("LastUnitCountPost");

   static readonly LastWeeklyPost: PropertyFields = new PropertyFields("LastWeeklyPost");

   static readonly LateFeeSetup: PropertyFields = new PropertyFields("LateFeeSetup");

   static readonly LeaseTermProperties: PropertyFields = new PropertyFields("LeaseTermProperties");

   static readonly LogoFile: PropertyFields = new PropertyFields("LogoFile");

   static readonly LogoFileID: PropertyFields = new PropertyFields("LogoFileID");

   static readonly MakeReadyTemplateProperties: PropertyFields = new PropertyFields("MakeReadyTemplateProperties");

   static readonly ManagementFeeHistory: PropertyFields = new PropertyFields("ManagementFeeHistory");

   static readonly ManagementFeeSetup: PropertyFields = new PropertyFields("ManagementFeeSetup");

   static readonly ManagerName: PropertyFields = new PropertyFields("ManagerName");

   static readonly MarketingData: PropertyFields = new PropertyFields("MarketingData");

   static readonly MarketingSetup: PropertyFields = new PropertyFields("MarketingSetup");

   static readonly MarketingValues: PropertyFields = new PropertyFields("MarketingValues");

   static readonly MasterPolicyChargeAmount: PropertyFields = new PropertyFields("MasterPolicyChargeAmount");

   static readonly MetaTag: PropertyFields = new PropertyFields("MetaTag");

   static readonly Name: PropertyFields = new PropertyFields("Name");

   static readonly OccupancyUnitCount: PropertyFields = new PropertyFields("OccupancyUnitCount");

   static readonly OccupiedUnitCount: PropertyFields = new PropertyFields("OccupiedUnitCount");

   static readonly OccupiedUnitSquareFootage: PropertyFields = new PropertyFields("OccupiedUnitSquareFootage");

   static readonly OnlineBookingPolicies: PropertyFields = new PropertyFields("OnlineBookingPolicies");

   static readonly OnlineListingsUsage: PropertyFields = new PropertyFields("OnlineListingsUsage");

   static readonly OpenViolations: PropertyFields = new PropertyFields("OpenViolations");

   static readonly OwnerCheckSetup: PropertyFields = new PropertyFields("OwnerCheckSetup");

   static readonly OwnerCheckSetupID: PropertyFields = new PropertyFields("OwnerCheckSetupID");

   static readonly OwnerContracts: PropertyFields = new PropertyFields("OwnerContracts");

   static readonly Ownerships: PropertyFields = new PropertyFields("Ownerships");

   static readonly PetTypeProperties: PropertyFields = new PropertyFields("PetTypeProperties");

   static readonly PetTypes: PropertyFields = new PropertyFields("PetTypes");

   static readonly PhoneNumbers: PropertyFields = new PropertyFields("PhoneNumbers");

   static readonly Polls: PropertyFields = new PropertyFields("Polls");

   static readonly PostingDay: PropertyFields = new PropertyFields("PostingDay");

   static readonly PrimaryAddress: PropertyFields = new PropertyFields("PrimaryAddress");

   static readonly PrimaryOwner: PropertyFields = new PropertyFields("PrimaryOwner");

   static readonly PrimaryOwnerID: PropertyFields = new PropertyFields("PrimaryOwnerID");

   static readonly PrimaryPhoneNumber: PropertyFields = new PropertyFields("PrimaryPhoneNumber");

   static readonly PropertyAmenities: PropertyFields = new PropertyFields("PropertyAmenities");

   static readonly PropertyBanks: PropertyFields = new PropertyFields("PropertyBanks");

   static readonly PropertyFileFolders: PropertyFields = new PropertyFields("PropertyFileFolders");

   static readonly PropertyFiles: PropertyFields = new PropertyFields("PropertyFiles");

   static readonly PropertyFloors: PropertyFields = new PropertyFields("PropertyFloors");

   static readonly PropertyGroupDetails: PropertyFields = new PropertyFields("PropertyGroupDetails");

   static readonly PropertyGroups: PropertyFields = new PropertyFields("PropertyGroups");

   static readonly PropertyID: PropertyFields = new PropertyFields("PropertyID");

   static readonly PropertyManagementFeeSetups: PropertyFields = new PropertyFields("PropertyManagementFeeSetups");

   static readonly PropertyRentChargeTypes: PropertyFields = new PropertyFields("PropertyRentChargeTypes");

   static readonly PropertyType: PropertyFields = new PropertyFields("PropertyType");

   static readonly PublishedTWAReports: PropertyFields = new PropertyFields("PublishedTWAReports");

   static readonly RateDiscounts: PropertyFields = new PropertyFields("RateDiscounts");

   static readonly RateSchedules: PropertyFields = new PropertyFields("RateSchedules");

   static readonly RateSetting: PropertyFields = new PropertyFields("RateSetting");

   static readonly RateTaxes: PropertyFields = new PropertyFields("RateTaxes");

   static readonly RecurringCharges: PropertyFields = new PropertyFields("RecurringCharges");

   static readonly RecurringChargeSummaries: PropertyFields = new PropertyFields("RecurringChargeSummaries");

   static readonly RentChargeTypeItems: PropertyFields = new PropertyFields("RentChargeTypeItems");

   static readonly RentChargeTypes: PropertyFields = new PropertyFields("RentChargeTypes");

   static readonly ReservableAmenities: PropertyFields = new PropertyFields("ReservableAmenities");

   static readonly RMVoIPCallHistory: PropertyFields = new PropertyFields("RMVoIPCallHistory");

   static readonly ScreeningSetting: PropertyFields = new PropertyFields("ScreeningSetting");

   static readonly SecurityDepositInterest: PropertyFields = new PropertyFields("SecurityDepositInterest");

   static readonly ServiceManagerAssignedUser: PropertyFields = new PropertyFields("ServiceManagerAssignedUser");

   static readonly ServiceManagerAssignedUserID: PropertyFields = new PropertyFields("ServiceManagerAssignedUserID");

   static readonly ShortName: PropertyFields = new PropertyFields("ShortName");

   static readonly ShortTermRentalOnlineBookingSetting: PropertyFields = new PropertyFields(
      "ShortTermRentalOnlineBookingSetting"
   );

   static readonly SiteGroups: PropertyFields = new PropertyFields("SiteGroups");

   static readonly SquareFootage: PropertyFields = new PropertyFields("SquareFootage");

   static readonly StatementMethod: PropertyFields = new PropertyFields("StatementMethod");

   static readonly TaxID: PropertyFields = new PropertyFields("TaxID");

   static readonly TextNumberProperties: PropertyFields = new PropertyFields("TextNumberProperties");

   static readonly TextNumbers: PropertyFields = new PropertyFields("TextNumbers");

   static readonly TimeZoneOffset: PropertyFields = new PropertyFields("TimeZoneOffset");

   static readonly TWAReportTemplates: PropertyFields = new PropertyFields("TWAReportTemplates");

   static readonly UnitCount: PropertyFields = new PropertyFields("UnitCount");

   static readonly Units: PropertyFields = new PropertyFields("Units");

   static readonly UnitTypeProperties: PropertyFields = new PropertyFields("UnitTypeProperties");

   static readonly UpdateDate: PropertyFields = new PropertyFields("UpdateDate");

   static readonly UpdateUser: PropertyFields = new PropertyFields("UpdateUser");

   static readonly UpdateUserID: PropertyFields = new PropertyFields("UpdateUserID");

   static readonly UserDefinedValues: PropertyFields = new PropertyFields("UserDefinedValues");

   static readonly UserProperties: PropertyFields = new PropertyFields("UserProperties");

   static readonly Users: PropertyFields = new PropertyFields("Users");

   static readonly VacantUnitCount: PropertyFields = new PropertyFields("VacantUnitCount");

   static readonly VacantUnitSquareFootage: PropertyFields = new PropertyFields("VacantUnitSquareFootage");

   static readonly Violations: PropertyFields = new PropertyFields("Violations");
}
