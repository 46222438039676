export enum EntityLinkEntityTypes {
   NotSet = 0,
   AllTenant = 1,
   CurrentTenant = 2,
   PastOrFutureTenant = 3,
   Prospect = 4,
   Unit = 5,
   Property = 6,
   Vendor = 7,
   Owner = 8,
   ServiceManagerIssue = 9,
   OwnerProspect = 10,
}
