<div class="report-parameter-label">
   <label class="parameter-label">
      {{ parameterLabel }}
   </label>
</div>
<div class="values-wrapper">
   <div class="report-parameter-label">
      <label class="parameter-label">
         {{ consumptionLabel }}
      </label>
   </div>
   <div class="input-container">
      <div class="individual-report-parameter-option parameter-padding">
         <lcs-numeric-input-report-parameter
            *ngIf="fromConsumption"
            [name]="name + '-name'"
            [parameter]="fromConsumption"
            [validation]="consumptionValidation"
         ></lcs-numeric-input-report-parameter>
      </div>
      <div class="individual-report-parameter-option parameter-padding">
         <lcs-numeric-input-report-parameter
            *ngIf="toConsumption"
            [name]="name + '-street'"
            [parameter]="toConsumption"
            [validation]="consumptionValidation"
         ></lcs-numeric-input-report-parameter>
      </div>
   </div>
   <div class="report-parameter-label">
      <label class="parameter-label">
         {{ chargesLabel }}
      </label>
   </div>
   <div class="input-container">
      <div class="individual-report-parameter-option parameter-padding">
         <label>{{fromCharges.DisplayName}}</label>
         <lcs-calculator-input
            [ngModel]="fromChargeValueModel.value"
            (ngModelChange)="fromChargeChanged($event)"
            [name]="'from-charge'"
            [lcsValidate]="chargeAmountValidation"
            [displayName]="fromCharges.DisplayName"
            numberFormat="1.2-2"
         ></lcs-calculator-input>
      </div>
      <div class="individual-report-parameter-option parameter-padding">
         <label>{{toCharges.DisplayName}}</label>
         <lcs-calculator-input
            [ngModel]="toChargeValueModel.value"
            (ngModelChange)="toChargeChanged($event)"
            [name]="'to-charge'"
            [lcsValidate]="chargeAmountValidation"
            [displayName]="toCharges.DisplayName"
            numberFormat="1.2-2"
         ></lcs-calculator-input>
      </div>
   </div>
</div>