////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum OwnerStatus {
   NotSet = 0,
   Past = 1,
   Current = 2,
   Future = 4,
}
