import { AccountType } from "../enumerations/eaccounttype.enum";
import { OwnerProspectModel } from "./generated/owner-prospect.model";
import { OwnerModel } from "./generated/owner.model";
import { ProspectModel } from "./generated/prospect.model";
import { TenantModel } from "./generated/tenant.model";
import { WebUserAccountAutomaticPaymentSettingModel } from "./generated/web-user-account-automatic-payment-setting.model";
import { WebUserModel } from "./generated/web-user.model";

export class OWAWebUserAccountModel {
   AccountID: number;
   AccountType: AccountType;
   CreateDate: Date;
   DisplayID: number;
   IsDefault: boolean;
   IsEnabled: boolean;
   MetaTag?: string;
   Nickname: string;
   Owner: OwnerModel;
   OwnerProspect: OwnerProspectModel;
   Prospect: ProspectModel;
   Tenant: TenantModel;
   UpdateDate: Date;
   WebUser: WebUserModel;
   WebUserAccountAutomaticPaymentSetting: WebUserAccountAutomaticPaymentSettingModel;
   WebUserAccountID: number;
   WebUserID: number;
}
