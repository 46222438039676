<div class="datatable-filter-label-section">
   <label>{{ datatableFilterModel.Label }}</label>
   <lcs-filter-operation-selector
      [ngModel]="datatableFilterModel.FilterOption.Operation"
      (ngModelChange)="onFilterOperationChanged($event)"
      [filterOperations]="trimmedOperations"
      [path]="operationPath"
   ></lcs-filter-operation-selector>
</div>
<ng-container [ngSwitch]="filterInputControlType">
   <lcs-account-selector
      *ngSwitchCase="expressLayoutControlTypes.AccountSelector"
      #accountSelector
      class="white datatable-filter-input-control"
      [accountTypesToExclude]="datatableFilterModel.ValuesToExclude"
      [disabled]="disabled"
      [hideLabel]="true"
      [ngModel]="filterInputValue"
      (ngModelChange)="onValueChange($event)"
   ></lcs-account-selector>
   <lcs-account-multi-selector
      *ngSwitchCase="expressLayoutControlTypes.AccountMultiSelector"
      class="white datatable-filter-input-control"
      [accountTypesToExclude]="datatableFilterModel.ValuesToExclude"
      [disabled]="disabled"
      [hideLabel]="true"
      [ngModel]="filterInputValue"
      (ngModelChange)="onValueChange($event)"
      (selectionChange)="onSelectionChange($event)"
   ></lcs-account-multi-selector>
   <lcs-property-ownership-selector
      *ngSwitchCase="expressLayoutControlTypes.PropertyOwnershipSelector"
      #propertyOwnershipSelector
      [selectedPropertyIDs]="filterInputValue"
      [showInactivePropertiesDefault]="showInactivePropertiesDefault"
      (selectedPropertyIDsChanged)="onValueChange($event)"
   ></lcs-property-ownership-selector>
   <lcs-entity-link-selector-filter
      *ngSwitchCase="expressLayoutControlTypes.EntityLinkSelector"
      #entityLinkSelectorFilter
      [entityLink]="filterInputValue"
      [entityTypesToInclude]="datatableFilterModel.ValuesToInclude"
      (linkEntitySelected)="onValueChange($event)"
   ></lcs-entity-link-selector-filter>
   <lcs-property-group-selector
      #propertyGroupSelector
      *ngSwitchCase="expressLayoutControlTypes.PropertyGroupSelector"
      [initialValue]="initialValue"
      [initialValueString]="initialValueString"
      [initialOperation]="initialOperation"
      (propertiesChange)="onValueChange($event)"
   >
   </lcs-property-group-selector>
   <lcs-input
      *ngSwitchDefault
      #filterInput
      class="white datatable-filter-input-control"
      [disabled]="disabled"
      [controlType]="filterInputControlType"
      [value]="filterInputValue"
      (valueChange)="onValueChange($event)"
      [name]="datatableFilterModel.FilterName"
      [displayName]="datatableFilterModel.Label"
      [entityType]="datatableFilterModel.EntityType"
      [filters]="datatableFilterModel.DataSourceFilters"
      [enumName]="datatableFilterModel.EnumerationType"
      [valuesToExclude]="datatableFilterModel.ValuesToExclude"
      [hideSelectorLabel]="true"
      [validation]="validation"
      [staticValues]="$any(datatableFilterModel.StaticValues)"
      [subControlType]="filterInputSubControlType"
      [valueSource]="datatableFilterModel.ValueSource"
      [showSeparator]="showSeparator"
      (selectionChange)="onSelectionChange($event)"
   >
   </lcs-input>
</ng-container>