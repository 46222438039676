import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EntitySelectorModule } from "@lcs/entity-selector/entity-selector.module";
import { LinkModule } from "@lcs/link/link.module";
import { SelectModule } from "@lcs/select/select.module";

import { EntityLinkSelectorViewComponent } from "./entity-link-selector-view.component";
import { EntityLinkSelectorComponent } from "./entity-link-selector.component";

@NgModule({
   imports: [SelectModule, EntitySelectorModule, LinkModule, CommonModule, FormsModule, ReactiveFormsModule],
   declarations: [EntityLinkSelectorComponent, EntityLinkSelectorViewComponent],
   exports: [EntityLinkSelectorComponent],
})
export class EntityLinkSelectorModule {}
