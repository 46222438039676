////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressReports {
   NotSet = 0,
   TenantProfileFromLink = 1,
   TenantStatement85x11FromLink = 2,
   TenantStatement85x14MailerFromLink = 3,
   TenantStatementPostcardFromLink = 4,
   TenantMailingLabelsFromLink = 5,
   TenantPaymentCouponsFromLink = 6,
   TenantLeaseAbstractFromLink = 7,
   TenantMinnCRPFromLink = 8,
   TenantCAMReconciliationFromLink = 9,
   ProspectMailingLabelsFromLink = 10,
   VendorMailingLabelsFromLink = 11,
   OwnerStatementFromLink = 12,
   OwnerMailingLabelsFromLink = 13,
   OwnerProfileFromLink = 14,
   ServiceOrderListFromLink = 15,
   DepositDetailFromDeposit = 16,
   DepositDetailMICRFromDeposit = 17,
   OwnerReportBatchFromLink = 18,
   Owner1096FromLink = 19,
   Owner1099FromLink = 20,
   Owner1099CopyBFromLink = 21,
   TenantMinnCRP2018AndPriorFromLink = 22,
   DepositDetailBreakDownFromDeposit = 23,
   AssetProfileFromLink = 24,
   AssetDepreciationFromLink = 25,
   MakeReadyDetailFromMakeReady = 26,
   BasicOwnerStatementFromLink = 27,
   ViolationCodeGroupPrintFromLink = 28,
   Vendor1099CopyBFromLink = 32,
}
