import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ExpressControlDataSourceFilterModel {
   FilterOperation: FilterOperations;
   PropertyPath: string;
   Value: string;
   Values: Array<string>;
}
