import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Observable } from "rxjs";

import { WebUserModel } from "../models/generated/web-user.model";

@Injectable({ providedIn: "root" })
export class WebUserService {
   readonly endpoint = "WebUsers";
   constructor(private apiService: ApiService) {}
   getUserProfile(webUserId: number): Observable<WebUserModel> {
      return this.apiService.getSingle(this.apiService.getUrl(this.endpoint + "/GetWebUserProfile?id=" + webUserId));
   }

   saveUserProfile(webUserModel: WebUserModel): Observable<HttpResponse<any>> {
      return this.apiService.post(this.endpoint + "/SaveWebUserProfile", webUserModel);
   }
}
