import { Component, EventEmitter, Input, Optional, Output, ViewChild } from "@angular/core";
import { AccountSelectorComponent } from "@lcs/account-selector/account-selector.component";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import cloneDeep from "lodash/cloneDeep";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOption, FilterOptionKeyValuePair } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";

import { ContextService } from "../../action-context/context.service";
import { ConstantsService } from "../../core/constants.service";
import { DateRangeModel } from "../../inputs/date-range-picker/date-range.model";
import { InputComponent } from "../../inputs/input.component";
import { NumberRangeModel } from "../../inputs/number-range-input/number-range.model";
import { DatesService } from "../../utils/dates.service";
import { warnInDevMode } from "../../utils/logging";
import { ControlTypeAdapterService } from "../control-type-adapter.service";
import { PropertyGroupSelectorComponent } from "../property-group-selector/property-group-selector.component";
import { PropertyOwnershipSelectorComponent } from "../property-ownership-selector/property-ownership-selector.component";
import { FilterInputValueType } from "./datatable-filter-values.types";
import { DataTableFilterModel } from "./datatable-filter.model";
import { DataTableFiltersService } from "./datatable-filters.service";
import { EntityLinkSelectorFilterComponent } from "./entity-link-selector-filter/entity-link-selector-filter.component";
import { EntityLinkModel } from "./entity-link-selector-filter/entity-link-selector/entity-link.model";

@Component({
   selector: "lcs-datatable-filter-input",
   templateUrl: "datatable-filter-input.component.html",
})
export class DataTableFilterInputComponent {
   @ViewChild("filterInput") filterInput: InputComponent;

   @ViewChild("entityLinkSelectorFilter")
   entityLinkSelectorFilter: EntityLinkSelectorFilterComponent;

   @ViewChild("propertyOwnershipSelector")
   propertyOwnershipSelector: PropertyOwnershipSelectorComponent;

   @ViewChild("propertyGroupSelector")
   propertyGroupSelector: PropertyGroupSelectorComponent;

   @ViewChild("accountSelector")
   accountSelector: AccountSelectorComponent;

   @Input() set filtersCollapsed(value: boolean) {
      this.onFilterVisibilityChanged(!value);
   }

   @Input() set datatableFilterModel(value: DataTableFilterModel) {
      this.loaded = false;
      this._datatableFilterModel = value;

      this.operationPath = `${this.datatableFilterModel.FilterName}-operation`;

      if (this.datatableFilterModel.FilterOption.Operation == null) {
         this.datatableFilterModel.FilterOption.Operation = this.datatableFilterModel.DefaultOperation;
      }

      this.initializeInput();

      if (this.datatableFilterModel.FilterOption.Operation != null) {
         if (this.datatableFilterModel.ControlType === ExpressLayoutControlTypes.Checkbox) {
            this.datatableFilterModel.ControlType = ExpressLayoutControlTypes.SingleSelector;
         }
         this.onFilterOperationChanged(this.datatableFilterModel.FilterOption.Operation);
      }

      if (
         value.ControlType === ExpressLayoutControlTypes.NumericTextBox &&
         value.DataType === ExpressDataTypes.String
      ) {
         this.showSeparator = false;
      }
   }

   get datatableFilterModel(): DataTableFilterModel {
      return this._datatableFilterModel;
   }

   @Input() set filterOperations(operations: Array<FilterOperationModel>) {
      this._filterOperations = operations;
      this.trimOperationSet();
   }

   @Input() entitySearchType: EntityType;

   @Input() showInactivePropertiesDefault: boolean;

   @Output() filterOptionChange = new EventEmitter<FilterOptionKeyValuePair>();

   expressLayoutControlTypes = ExpressLayoutControlTypes;

   filterInputValue: FilterInputValueType | Array<FilterInputValueType>;

   filterInputControlType: ExpressLayoutControlTypes;

   filterInputSubControlType: ExpressLayoutControlTypes;

   trimmedOperations: Array<FilterOperationModel>;

   validation: ValidationModel;

   staticValue: Array<SelectorItemModel>;

   disabled = false;

   operationPath: string;

   showSeparator: boolean = true;

   entityType: EntityType;

   initialValue: any; // Need this for property group selector to work with applied filters

   initialValueString: string; // Need this for property group selector to work with applied filters

   initialOperation: any; // Need this for property group selector to work with applied filters

   loaded: boolean = false;

   private previousInputControlType: ExpressLayoutControlTypes;

   private selectorItem: SelectorItemModel;

   private _datatableFilterModel: DataTableFilterModel;

   private _filterOperations: Array<FilterOperationModel>;

   constructor(
      private dataTableFiltersService: DataTableFiltersService,
      @Optional() private entityInformationService: EntityInformationService,
      @Optional() private contextService: ContextService
   ) {}

   onFilterOperationChanged(operation: FilterOperations) {
      const oldOperation = this.datatableFilterModel.FilterOption.Operation;
      this.datatableFilterModel.FilterOption.Operation = operation;
      this.convertFilterOptionToInputControlType();

      if (
         this.filterInputControlType === ExpressLayoutControlTypes.PropertyGroupSelector &&
         (this.loaded || this.datatableFilterModel.FilterOption.FilterOperationToDisplay)
      ) {
         if (this.loaded) {
            this.propertyGroupSelector.userSelectedFilterOperation = operation;
         } else {
            this.initialOperation = this.datatableFilterModel.FilterOption.FilterOperationToDisplay;
         }
      } else if (
         this.hasChangedFromMultiToSingleSelectControlType(
            this.previousInputControlType,
            this.filterInputControlType
         ) &&
         this.updateFilterInputSelectionAfterChangingFromMultiToSingleSelectOperation()
      ) {
         return;
      } else if (this.filterInputValue != null) {
         this.filterInputValue = this.dataTableFiltersService.convertFilterValue(
            this.filterInputValue,
            oldOperation,
            operation,
            this.filterInputControlType
         );
      }

      this.setFilterValues();
   }

   onValueChange(filterInputValue) {
      this.filterInputValue = filterInputValue;
      this.setFilterValues();
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.datatableFilterModel.FilterOption.DisplayValue =
         this.dataTableFiltersService.getDisplayLabelFromSelectionChange(selectionChange);
   }

   onFilterVisibilityChanged(isVisible: boolean) {
      if (
         isVisible &&
         this.filterInputControlType === ExpressLayoutControlTypes.PropertyOwnershipSelector &&
         this.propertyOwnershipSelector
      ) {
         this.propertyOwnershipSelector.realignInkBar();
      }
   }

   private setFilterValues() {
      if (this.filterInputValue === ConstantsService.unselected) {
         this.filterOptionChange.emit({
            filterName: this.datatableFilterModel.FilterName,
            filterOption: null,
         });
         return;
      }

      let filterOption = new FilterOption(
         this.datatableFilterModel.FilterOption.FilterName,
         this.datatableFilterModel.FilterOption.Operation,
         this.dataTableFiltersService.buildAPIFilterValue(
            this.filterInputValue,
            this.filterInputControlType,
            this.datatableFilterModel.DataType
         )
      );

      filterOption.Label = this.datatableFilterModel.Label;

      if (
         this.filterInputControlType === ExpressLayoutControlTypes.SingleSelector &&
         this.datatableFilterModel.DataType === ExpressDataTypes.Boolean &&
         !this.datatableFilterModel.ValueSource?.StaticValues?.length
      ) {
         this.datatableFilterModel.ValueSource = this.dataTableFiltersService.buildBoolSingleSelector();
      }

      if (this.filterInputControlType === ExpressLayoutControlTypes.DateRangePicker) {
         const dateRangeModel: DateRangeModel = cloneDeep(<DateRangeModel>this.filterInputValue);
         if (dateRangeModel !== undefined && dateRangeModel !== null) {
            filterOption = DatesService.getDateFilter(
               filterOption.FilterName,
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
               dateRangeModel.startDate,
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
               dateRangeModel.endDate
            );
            if (!dateRangeModel.startDate && !dateRangeModel.endDate) {
               filterOption.Operation = FilterOperations.Between;
            }
         }
      }
      if (this.filterInputControlType === ExpressLayoutControlTypes.EntityLinkSelector && this.filterInputValue) {
         const entityLinkModel = this.filterInputValue as EntityLinkModel;
         if (entityLinkModel.value) {
            filterOption.DisplayValue = entityLinkModel.displayName;
         }
      } else if (
         this.filterInputControlType === ExpressLayoutControlTypes.EnumMultiSelector ||
         this.filterInputControlType === ExpressLayoutControlTypes.EntityMultiSelector ||
         this.filterInputControlType === ExpressLayoutControlTypes.AccountMultiSelector
      ) {
         filterOption.DisplayValue = this.datatableFilterModel.FilterOption.DisplayValue;
      } else if (this.filterInputControlType === ExpressLayoutControlTypes.PropertyOwnershipSelector) {
         setTimeout(() => {
            filterOption.DisplayValue = this.propertyOwnershipSelector.displayValue;
         }, 300);
      } else if (this.filterInputControlType === ExpressLayoutControlTypes.PropertyGroupSelector) {
         if (this.loaded) {
            filterOption.DisplayValue = this.propertyGroupSelector.displayValue;
            filterOption.AdditionalData = this.propertyGroupSelector.value;
         } else {
            filterOption.DisplayValue = this.datatableFilterModel.FilterOption.DisplayValue;
            filterOption.AdditionalData = this.datatableFilterModel.FilterOption.AdditionalData;
            if (this.datatableFilterModel.FilterOption.FilterOperationToDisplay) {
               filterOption.Operation = this.datatableFilterModel.FilterOption.FilterOperationToDisplay;
            }
         }
      } else if (
         this.datatableFilterModel.ControlType === ExpressLayoutControlTypes.ColorPicker &&
         this.filterInput &&
         !this.filterInput.selectedItem
      ) {
         filterOption.DisplayValue = this.datatableFilterModel.FilterOption.DisplayValue;
      } else if (this.filterInput && this.filterInput.selectedItem) {
         filterOption.DisplayValue = this.filterInput.selectedItem.displayValue;
      } else if (this.accountSelector && this.accountSelector.selectedItem) {
         filterOption.DisplayValue = this.accountSelector.selectedItem.displayValue;
      } else if (this.selectorItem) {
         filterOption.DisplayValue = this.selectorItem.displayValue;
      } else if (!this.loaded) {
         filterOption.DisplayValue = this.datatableFilterModel.FilterOption.DisplayValue;
      } else {
         filterOption.DisplayValue = this.dataTableFiltersService.buildDisplayValue(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterValueType[] | null' is not... Remove this comment to see the full error message
            filterOption.Values,
            this.filterInputControlType
         );
      }

      this.datatableFilterModel.FilterOption = filterOption;

      if (this.filterInputControlType === ExpressLayoutControlTypes.PropertyGroupSelector) {
         filterOption = cloneDeep(filterOption);
         filterOption.Operation = this.loaded
            ? this.propertyGroupSelector.operation
            : this.datatableFilterModel.FilterOption.Operation;
      }

      this.loaded = true;

      this.filterOptionChange.emit({
         filterName: this.datatableFilterModel.FilterOption.FilterName,
         filterOption: filterOption,
      });
   }

   private convertFilterOptionToInputControlType() {
      // Control type may change depending on filter operation
      this.previousInputControlType = this.filterInputControlType;

      this.filterInputControlType = ControlTypeAdapterService.convertFilterControlTypeToFilterInputControlType(
         this.datatableFilterModel.ControlType,
         this.datatableFilterModel.FilterOption.Operation
      );
      if (
         this.filterInputControlType !== ExpressLayoutControlTypes.NotSet &&
         this.filterInputControlType !== this.previousInputControlType
      ) {
         this.validation = cloneDeep(this.datatableFilterModel.ValidationModel);
      }
      if (this.filterInputControlType === ExpressLayoutControlTypes.NumberRangeInput) {
         this.filterInputSubControlType = ExpressLayoutControlTypes.NumericTextBox;
      } else {
         this.filterInputSubControlType = ExpressLayoutControlTypes.NotSet;
      }

      if (this.filterInputControlType === ExpressLayoutControlTypes.NotSet) {
         this.disabled = true;
      } else {
         this.disabled = false;
      }
   }

   private initializeInput() {
      if (this.datatableFilterModel && this.datatableFilterModel.ValidationModel) {
         this.validation = cloneDeep(this.datatableFilterModel.ValidationModel);
      }
      if (this.datatableFilterModel && this.datatableFilterModel.FilterOption) {
         this.filterInputControlType = ControlTypeAdapterService.convertFilterControlTypeToFilterInputControlType(
            this.datatableFilterModel.ControlType,
            this.datatableFilterModel.FilterOption.Operation
         );
         if (
            [
               ExpressLayoutControlTypes.EnumMultiSelector,
               ExpressLayoutControlTypes.EntityMultiSelector,
               ExpressLayoutControlTypes.AccountMultiSelector,
            ].some((f) => f === this.filterInputControlType)
         ) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType[] | null' is not assignable ... Remove this comment to see the full error message
            this.filterInputValue = this.datatableFilterModel.FilterOption.Values;
         } else if (this.filterInputControlType === ExpressLayoutControlTypes.PropertyGroupSelector) {
            if (this.datatableFilterModel.FilterOption.AdditionalData) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType[] | null' is not assignable ... Remove this comment to see the full error message
               this.filterInputValue = this.datatableFilterModel.FilterOption.Values;
               if (
                  this.datatableFilterModel.FilterOption.Values !== null &&
                  this.datatableFilterModel.FilterOption.Values.length !== 0
               ) {
                  this.initialValue = this.datatableFilterModel.FilterOption.AdditionalData;
                  this.initialValueString = this.datatableFilterModel.FilterOption.DisplayValue;
               }
            }
         } else if (this.filterInputControlType === ExpressLayoutControlTypes.DateRangePicker) {
            const values = this.datatableFilterModel.FilterOption.Values;
            const startDate = values && values.length > 0 ? new Date(values[0] as string) : null;
            const endDate = values && values.length > 1 ? new Date(values[1] as string) : null;
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
            this.filterInputValue = new DateRangeModel(startDate, endDate);
         } else if (
            this.datatableFilterModel.ControlType === ExpressLayoutControlTypes.ColorPicker &&
            this.datatableFilterModel.FilterOption.Operation === FilterOperations.HasValue &&
            this.filterInputControlType !== ExpressLayoutControlTypes.NotSet
         ) {
            this.filterInputControlType = ExpressLayoutControlTypes.ColorPicker;
            if (this.datatableFilterModel.FilterOption.Value !== null) {
               this.datatableFilterModel.FilterOption.Operation =
                  this.datatableFilterModel.FilterOption.Value.toString().toLowerCase() === "false"
                     ? FilterOperations.EqualTo
                     : FilterOperations.NotEqualTo;
               this.filterInputValue = -1;
            }
         } else if (this.filterInputControlType === ExpressLayoutControlTypes.NumberRangeInput) {
            this.filterInputValue = new NumberRangeModel();
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType | null' is not assignable to... Remove this comment to see the full error message
            this.filterInputValue = this.datatableFilterModel.FilterOption.Value;
         }
         this.convertFilterOptionToInputControlType();
      }
   }

   private trimOperationSet() {
      let operations = new Array<FilterOperationModel>();

      if (this._filterOperations && this.datatableFilterModel) {
         const filterOperations = this.datatableFilterModel.Operations;

         if (!filterOperations) {
            warnInDevMode("No Filter Operations", this.datatableFilterModel);
            return;
         }

         operations = this._filterOperations.filter((o) => filterOperations.indexOf(o.BackingEnumeration) > -1);

         // This code can to be removed after all the related endpoints are made searchable

         //if there is an entity type and it is an entity search, then set it

         if (this.entitySearchType) {
            this.entityType = this.entitySearchType;
         } else {
            this.entityType = this.contextService.parentEntityType;
         }

         if (
            this.entityInformationService != null &&
            this.contextService != null &&
            !this.entityInformationService.entityTypeHasSearchEndpoint(this.entityType) &&
            (this.datatableFilterModel.ControlType === ExpressLayoutControlTypes.DatePicker ||
               this.datatableFilterModel.ControlType === ExpressLayoutControlTypes.ConfirmDatePicker)
         ) {
            const index = operations.findIndex((f) => f.BackingEnumeration === FilterOperations.NotEqualTo);
            if (index > -1) {
               operations.splice(index, 1);
            }
         }
      }

      this.trimmedOperations = operations;
   }

   /**
    * Determine if filterInputControlType has changed from a multi-selector type to a single-selector type
    */
   private hasChangedFromMultiToSingleSelectControlType(
      previousFilterInputControlType: ExpressLayoutControlTypes,
      currentFilterInputControlType: ExpressLayoutControlTypes
   ): boolean {
      let changedFromMultiSelectControlTypeToSingleSelectControlType: boolean = false;
      const multiSelectorControlTypes: Set<ExpressLayoutControlTypes> = new Set<ExpressLayoutControlTypes>([
         ExpressLayoutControlTypes.AccountMultiSelector,
         ExpressLayoutControlTypes.EntityMultiSelector,
         ExpressLayoutControlTypes.EnumMultiSelector,
         ExpressLayoutControlTypes.SingleLineMultiSelector,
      ]);
      const singleSelectorControlTypes: Set<ExpressLayoutControlTypes> = new Set<ExpressLayoutControlTypes>([
         ExpressLayoutControlTypes.AccountSelector,
         ExpressLayoutControlTypes.EntitySelector,
         ExpressLayoutControlTypes.EnumSelector,
         ExpressLayoutControlTypes.SingleSelector,
      ]);
      changedFromMultiSelectControlTypeToSingleSelectControlType =
         multiSelectorControlTypes.has(previousFilterInputControlType) &&
         singleSelectorControlTypes.has(currentFilterInputControlType);
      return changedFromMultiSelectControlTypeToSingleSelectControlType;
   }

   /**
    * Update the FilterInput selection after changing from a mult-selector to a single selector
    * if successful this implicitly calls filterInput.onSelected(...) with a newSelectedItem
    * which updates the selected value, and emits a valueChange updating the filterInputValue and
    * calling setFilterValues.
    */
   private updateFilterInputSelectionAfterChangingFromMultiToSingleSelectOperation(): boolean {
      const selector = this.filterInput?.selector;
      let succeeded: boolean = false;
      if (selector?.selector?.items) {
         const newSelectedItem: SelectorItemModel | undefined = selector.selector.items?.find(
            (i: SelectorItemModel) => i.value === this.filterInputValue[0]
         );
         if (newSelectedItem) {
            // update the filterInput selected value which allows the displayValue to be set properly in setFilterValues
            this.filterInput.onSelected(newSelectedItem);
            return (succeeded = true);
         }
      } else {
         warnInDevMode("Expected selector.selector.items to be set.");
      }
      return succeeded;
   }
}
