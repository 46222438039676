import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { IncludeExpectedMoveInMoveOutDates } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/include-expected-move-in-move-out-dates.enum";
import { forkJoin, Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-include-expected-move-dates-report-parameter",
   templateUrl: "include-expected-move-dates-report-parameter.component.html",
})
export class IncludeExpectedMoveDatesReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() set name(val: string) {
      this._name = val;
      this.tenantCheckboxName = val + "-tenant-checkbox";
      this.prospectCheckboxName = val + "-prospect-checkbox";
   }

   get name(): string {
      return this._name;
   }

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   isTenantChecked: boolean = false;

   isProspectChecked: boolean = false;

   tenantCheckboxName: string;

   prospectCheckboxName: string;

   tenantCheckboxLabel: string;

   prospectCheckboxLabel: string;

   private _name: string;

   private _parameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private entityViewInformationServiceBase: EntityViewInformationServiceBase
   ) {
      forkJoin([
         this.entityViewInformationServiceBase.getViewInformation(EntityType.Tenant),
         this.entityViewInformationServiceBase.getViewInformation(EntityType.Prospect),
      ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(([tenantInformation, prospectInformation]) => {
            this.tenantCheckboxLabel = `${tenantInformation.CollectionLabel} with expected move out dates`;
            this.prospectCheckboxLabel = `${prospectInformation.CollectionLabel} with reserved move in dates`;
         });

      this.reportParametersService.reportParameterStatusChanged
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((status) => {
            if (this._parameter && status.reportParameter === this._parameter.ReportParameterID) {
               this.disabled = status.disabled;
            }
         });
   }

   ngOnInit() {
      this.initializeReportParameterValue();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onTenantChecked(checked: boolean) {
      if (this.isTenantChecked === checked) {
         return;
      }
      this.isTenantChecked = checked;
      this.updateValue();
   }

   onProspectChecked(checked: boolean) {
      if (this.isProspectChecked === checked) {
         return;
      }
      this.isProspectChecked = checked;
      this.updateValue();
   }

   updateValue() {
      let value = IncludeExpectedMoveInMoveOutDates.NotSet;
      if (this.isTenantChecked) {
         // eslint-disable-next-line no-bitwise
         value |= IncludeExpectedMoveInMoveOutDates.TenantMoveOut;
      }
      if (this.isProspectChecked) {
         // eslint-disable-next-line no-bitwise
         value |= IncludeExpectedMoveInMoveOutDates.ProspectMoveIn;
      }

      this.reportParameterValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      let value = +this.reportParameterValueModel.value;
      if (isNaN(value)) {
         value = 0;
      }

      // eslint-disable-next-line no-bitwise
      if (value & IncludeExpectedMoveInMoveOutDates.TenantMoveOut) {
         this.isTenantChecked = true;
      }

      // eslint-disable-next-line no-bitwise
      if (value & IncludeExpectedMoveInMoveOutDates.ProspectMoveIn) {
         this.isProspectChecked = true;
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }
}
