import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";

import { SearchPostBodyTransferModel } from "./search-post-body-transfer.model";
import { ServiceManagerIssueSearchPostModel } from "./service-manager-issue-search-post.model";

export class ServiceManagerIssueSearchPostTransferModel extends SearchPostBodyTransferModel {
   LinkedEntityID?: number;
   LinkedEntityType?: EntityType;
   OwnerIDs: Array<number>;
   PropertyIDs: Array<number>;

   constructor(postModel: ServiceManagerIssueSearchPostModel | null) {
      super(postModel);
      if (postModel) {
         this.LinkedEntityID = postModel.LinkedEntityID;
         this.LinkedEntityType = postModel.LinkedEntityType;
         this.OwnerIDs = postModel.OwnerIDs;
         this.PropertyIDs = postModel.PropertyIDs;
      }
   }
}
