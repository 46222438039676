import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { DialogRegistrationService } from "@lcs/dialog/services/dialog-registration.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { AuthenticationService } from "projects/owa/src/app/authentication/authentication.service";
import { OwaAuthenticationModel } from "projects/owa/src/app/authentication/models/owa-authentication.model";
import { Observable, Subject, takeUntil } from "rxjs";

import { PasswordResetModel } from "../models/password-reset.model";
import { VerificationTokenInformationModel } from "../models/verification-token-information.model";

@Injectable({
   providedIn: "root",
})
export class PasswordResetService implements OnDestroy {
   readonly endpoint = "Password/Reset";
   public Results = new Subject<Response>();
   public authModel: OwaAuthenticationModel = new OwaAuthenticationModel();

   private unsubscribe = new Subject<void>();
   constructor(
      private authenticationservice: AuthenticationService,
      private httpClient: HttpClient,
      private apiRequestHelperService: ApiRequestHelperService,
      private dialogRegistrationService: DialogRegistrationService
   ) {}
   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   getTokenInformation(token: string): Observable<VerificationTokenInformationModel> {
      return this.httpClient.get<VerificationTokenInformationModel>(
         this.apiRequestHelperService.url + "AccountRecovery/GetDetail?token=" + token,
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   updatePassword(resetModel: PasswordResetModel) {
      this.httpClient
         .post(this.apiRequestHelperService.url + "AccountRecovery/UpdatePassword", JSON.stringify(resetModel), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               this.authModel.Username = resetModel.Username;
               this.authModel.Password = resetModel.Newpassword;
               this.authModel.LocationId = resetModel.LocationID;
               this.authenticationservice.logIn(this.authModel);
            },
            (_err) => {
               this.Results.next(_err);
            }
         );
   }

   changePassword(resetModel: PasswordResetModel) {
      this.httpClient
         .post(this.apiRequestHelperService.url + "AccountRecovery/ChangePassword", JSON.stringify(resetModel), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            () => {
               this.dialogRegistrationService.closeAllModals();
            },
            (_err) => {
               this.Results.next(_err);
            }
         );
   }

   forcePasswordReset(resetModel: PasswordResetModel): Observable<HttpResponse<Object>> {
      return this.httpClient
         .post(this.apiRequestHelperService.url + "AccountRecovery/ChangePassword", JSON.stringify(resetModel), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
            observe: "response",
         })
         .pipe(takeUntil(this.unsubscribe));
   }
}
