export class SidebarMenuItemModel {
   uniqueIdentifier: any;

   label: string;

   id: string;

   tooltip: string;

   routerLink: string | Array<string>;

   clickEvent?: () => void;

   queryParams: { [k: string]: any };

   children: Array<SidebarMenuItemModel>;
}
