import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { LeaseRenewalDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/lease-renewal.delete-options";
import { LeaseDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/lease.delete-options";
import { LeaseRenewalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease-renewal.embed-options";
import { LeaseEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { RenterInsurancePolicyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/renter-insurance-policy.embed-options";
import { RetailSaleEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/retail-sale.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { UnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { LeaseRenewalGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/lease-renewal.get-options";
import { UnitGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/unit.get-options";
import { LeaseRenewalSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/lease-renewal.save-options";
import { LeaseSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/lease.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { LeaseRenewalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease-renewal.model";
import { LeaseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { RenterInsurancePolicyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/renter-insurance-policy.model";
import { RetailSaleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/retail-sale.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class LeasesService {
   static readonly ActiveLeaseRenewalResource = "ActiveLeaseRenewal";
   static readonly CreateUserResource = "CreateUser";
   static readonly GetByPostResource = "GetByPost";
   static readonly LeaseRenewResource = "LeaseRenew";
   static readonly LeaseRenewalsResource = "LeaseRenewals";
   static readonly PropertyResource = "Property";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RenterInsurancePoliciesResource = "RenterInsurancePolicies";
   static readonly RetailSalesResource = "RetailSales";
   static readonly SaveResource = "Save";
   static readonly SearchResource = "Search";
   static readonly TenantResource = "Tenant";
   static readonly UnitResource = "Unit";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly endpoint = "LeasesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(LeasesService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   post(
      item?: LeaseModel | null,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(LeasesService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   postCollection(
      items: Array<LeaseModel>,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | LeaseDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(LeasesService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | LeaseDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(LeasesService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getActiveLeaseRenewalUrl(
      id: number,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/ActiveLeaseRenewal",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getActiveLeaseRenewal(
      id: number,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalModel> {
      return this.apiService.getSingle(
         this.getActiveLeaseRenewalUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(LeasesService.endpoint + "/GetByPost", id, null, null, null, additionalParameters);
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postLeaseRenewUrl(
      id: number,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/LeaseRenew",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeaseRenew(
      id: number,
      item?: LeaseRenewalModel | null,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalModel> {
      return this.apiService.postSingle(
         this.postLeaseRenewUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postLeaseRenewCollectionUrl(
      id: number,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/LeaseRenew",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeaseRenewCollection(
      id: number,
      leaseRenew?: Array<LeaseRenewalModel> | null,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseRenewalModel>> {
      return this.apiService.postCollection(
         this.postLeaseRenewCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         leaseRenew
      );
   }

   getLeaseRenewalsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/LeaseRenewals",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLeaseRenewals(
      id: number,
      itemID: number,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalModel> {
      return this.apiService.getSingle(
         this.getLeaseRenewalsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLeaseRenewalsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/LeaseRenewals",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLeaseRenewalsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | LeaseRenewalGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseRenewalModel>> {
      return this.apiService.getCollection(
         this.getLeaseRenewalsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLeaseRenewalsUrl(
      id: number,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/LeaseRenewals",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeaseRenewals(
      id: number,
      item?: LeaseRenewalModel | null,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalModel> {
      return this.apiService.postSingle(
         this.postLeaseRenewalsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postLeaseRenewalsCollectionUrl(
      id: number,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/LeaseRenewals",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeaseRenewalsCollection(
      id: number,
      leaseRenewals?: Array<LeaseRenewalModel> | null,
      saveOptions?: Array<string | LeaseRenewalSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseRenewalModel>> {
      return this.apiService.postCollection(
         this.postLeaseRenewalsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         leaseRenewals
      );
   }

   deleteLeaseRenewalsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LeaseRenewalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         LeasesService.endpoint + "/LeaseRenewals",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLeaseRenewals(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LeaseRenewalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLeaseRenewalsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPropertyUrl(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/Property",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperty(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(this.getPropertyUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         LeasesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRenterInsurancePoliciesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RenterInsurancePolicyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/RenterInsurancePolicies",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRenterInsurancePolicies(
      id: number,
      itemID: number,
      embeds?: Array<string | RenterInsurancePolicyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RenterInsurancePolicyModel> {
      return this.apiService.getSingle(
         this.getRenterInsurancePoliciesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRenterInsurancePoliciesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RenterInsurancePolicyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/RenterInsurancePolicies",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRenterInsurancePoliciesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RenterInsurancePolicyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RenterInsurancePolicyModel>> {
      return this.apiService.getCollection(
         this.getRenterInsurancePoliciesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRetailSalesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RetailSaleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/RetailSales",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRetailSales(
      id: number,
      itemID: number,
      embeds?: Array<string | RetailSaleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RetailSaleModel> {
      return this.apiService.getSingle(
         this.getRetailSalesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRetailSalesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RetailSaleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/RetailSales",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRetailSalesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RetailSaleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RetailSaleModel>> {
      return this.apiService.getCollection(
         this.getRetailSalesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRetailSalesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/RetailSales",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRetailSales(
      id: number,
      item?: RetailSaleModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<RetailSaleModel> {
      return this.apiService.postSingle(this.postRetailSalesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postRetailSalesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/RetailSales",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRetailSalesCollection(
      id: number,
      retailSales?: Array<RetailSaleModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RetailSaleModel>> {
      return this.apiService.postCollection(
         this.postRetailSalesCollectionUrl(id, saveOptions, fields, additionalParameters),
         retailSales
      );
   }

   deleteRetailSalesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         LeasesService.endpoint + "/RetailSales",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRetailSales(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRetailSalesUrl(id, ids, deleteOptions, additionalParameters));
   }

   postSaveUrl(
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         LeasesService.endpoint + "/Save",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSave(
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService
         .post(this.postSaveUrl(saveOptions, fields, embeds, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(LeasesService.endpoint + "/Search", null, null, null, null, additionalParameters);
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getTenantUrl(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/Tenant",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenant(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(this.getTenantUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUnitUrl(
      id: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/Unit",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnit(
      id: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(this.getUnitUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LeasesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
