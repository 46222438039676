import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { filter, Subject, takeUntil } from "rxjs";

import { TopBarService } from "../top-bar/top-bar.service";

@Component({
   selector: "owa-resend-verification",
   templateUrl: "./resend-verification.component.html",
   styleUrls: ["./resend-verification.component.css"],
})
export class ResendVerificationComponent implements OnInit, OnDestroy {
   private unsubscribe = new Subject<void>();
   constructor(private sessionCheckService: SessionCheckService, private topbarservice: TopBarService) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      this.sessionCheckService.checkSessionOnFocus = true;
   }
}
