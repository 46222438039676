import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { fromEvent, Observable, Subject, Subscription, takeUntil } from "rxjs";

import { SidebarMenuItemModel } from "../models/sidebar-menu-item.model";
import { SidebarMenuService } from "../sidebar-menu.service";

@Component({
   selector: "lcs-sidebar-menu",
   templateUrl: "./sidebar-menu.component.html",
   providers: [SidebarMenuService],
})
export class SidebarMenuComponent implements OnDestroy {
   @Input() menuItems: Array<SidebarMenuItemModel>;

   @Input() set show(val: boolean) {
      this.sidebarMenuService.show.next(val);
   }

   @Output() showChange = new EventEmitter<boolean>();

   @ViewChild("overlay", { static: true }) overlay: ElementRef;

   get show(): boolean {
      return this._show;
   }

   widthStyle: Observable<string>;

   leftStyle: Observable<string>;

   scrollerWidthStyle: Observable<string>;

   transitionStyle: Observable<string>;

   private _show: boolean;

   private unsubscribe = new Subject<void>();

   private bodyClickSubscription: Subscription | null;

   constructor(private sidebarMenuService: SidebarMenuService, private elementRef: ElementRef) {
      this.widthStyle = this.sidebarMenuService.widthStyle;
      this.leftStyle = this.sidebarMenuService.leftStyle;
      this.scrollerWidthStyle = this.sidebarMenuService.scrollerWidthStyle;
      this.transitionStyle = this.sidebarMenuService.transitionStyle;

      this.sidebarMenuService.show.pipe(takeUntil(this.unsubscribe)).subscribe((show) => {
         if (show !== this._show) {
            this._show = show;
            this.updateVisibility();
            if (this._show) {
               this.sidebarMenuService.showPage.next(this.sidebarMenuService.rootIdentifier);
            }
         }
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private updateVisibility() {
      if (this._show) {
         this.sidebarMenuService.left.next(0);
         setTimeout(() => this.addClickEvent());
      } else {
         this.removeClickEvent();
      }
      this.showChange.emit(this._show);
   }

   private addClickEvent() {
      this.bodyClickSubscription = fromEvent(document, "click").subscribe((event) => {
         this.bodyClick((<MouseEvent>event).target);
      });
   }

   private removeClickEvent() {
      if (this.bodyClickSubscription) {
         this.bodyClickSubscription.unsubscribe();
         this.bodyClickSubscription = null;
      }
   }

   private bodyClick(eventTarget: EventTarget | null) {
      const targetElement = <Element>eventTarget;
      if (targetElement === this.overlay.nativeElement || !this.elementRef.nativeElement.contains(targetElement)) {
         this.show = false;
      }
   }
}
