import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";

import { FormSectionHeaderComponent } from "./form-section-header.component";

@NgModule({
   imports: [
      CommonModule,
      MatDividerModule,
   ],
   declarations: [
      FormSectionHeaderComponent,
   ],
   exports: [
      FormSectionHeaderComponent,
   ],
})
export class FormSectionHeaderModule { }
