<ng-container *ngIf="!mobilePlatform; else pdfViewerLink">
   <iframe
      id="pdf-viewer-iframe-id"
      class="pdf-viewer-iframe"
      [src]="safeUrl"
   ></iframe>
</ng-container>

<ng-template #pdfViewerLink>
   <a
      #pdfAnchor
      class="pdf-viewer-link"
      [text]="'Click to View Document'"
      [href]="safeUrl"
   > </a>
</ng-template>