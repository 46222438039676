<ng-container [ngSwitch]="controlType">
   <div class="flex-container">
      <div class="number-range-numeric-wrapper">
         <lcs-numeric-input
            *ngSwitchCase="controlTypes.NumericTextBox"
            (ngModelChange)="onMinimumChange($event)"
            [ngModel]="value.min"
            [ngModelOptions]="{ standalone: standalone }"
            [lcsValidate]="minValidation"
            [name]="minName"
            [disabled]="disabled"
            [customValidatorData]="customValidatorData"
            [displayName]="minName"
         ></lcs-numeric-input>
         <lcs-calculator-input
            *ngSwitchCase="controlTypes.CalculatorInput"
            (ngModelChange)="onMinimumChange($event)"
            [ngModel]="value.min"
            [ngModelOptions]="{ standalone: standalone }"
            [lcsValidate]="minValidation"
            [name]="minName"
            [disabled]="disabled"
            [customValidatorData]="customValidatorData"
            [displayName]="minName"
         ></lcs-calculator-input>
      </div>
      <div class="number-range-delimiter">to</div>
      <div class="number-range-numeric-wrapper">
         <lcs-numeric-input
            *ngSwitchCase="controlTypes.NumericTextBox"
            (ngModelChange)="onMaximumChange($event)"
            [ngModel]="value.max"
            [ngModelOptions]="{ standalone: standalone }"
            [lcsValidate]="maxValidation"
            [name]="maxName"
            [disabled]="disabled"
            [customValidatorData]="customValidatorData"
            [displayName]="maxName"
         ></lcs-numeric-input>
         <lcs-calculator-input
            *ngSwitchCase="controlTypes.CalculatorInput"
            (ngModelChange)="onMaximumChange($event)"
            [ngModel]="value.max"
            [ngModelOptions]="{ standalone: standalone }"
            [lcsValidate]="maxValidation"
            [name]="maxName"
            [disabled]="disabled"
            [customValidatorData]="customValidatorData"
            [displayName]="maxName"
         ></lcs-calculator-input>
      </div>
   </div>
</ng-container>