<ul
   class="side-bar-submenu"
   *ngIf="sideBarSubMenu"
>
   <li
      *ngIf="sideBarSubMenu.ParentItem"
      class="side-bar-menu-section-header"
      [class.disabled]="disabled"
   >
      {{ sideBarSubMenu.ParentItem?.Label }}
   </li>
   <li
      [class.disabled]="disabled"
      *ngIf="sideBarSubMenu && sideBarSubMenu.ParentItem && !sideBarSubMenu.IsSearching"
   >
      <lcs-link
         [cssClasses]="['side-bar-menu-back']"
         [contentTemplate]="contentTemplate"
         (clicked)="backSelected.emit()"
      >
         <ng-template #contentTemplate>
            <i class="material-icons">keyboard_arrow_left</i>
            BACK
         </ng-template>
      </lcs-link>
   </li>
   <li
      [class.disabled]="disabled"
      *ngFor="let menuItem of sideBarSubMenu.MenuItems"
   >
      <div *ngIf="!useActionLinks">
         <lcs-link
            *ngIf="
               !menuItem.Action?.ExpressActionID &&
               menuItem.Children &&
               menuItem.Children.length !== 0
            "
            [cssClasses]="['justify-content-space-between']"
            [contentTemplate]="contentTemplate1"
            (clicked)="parentMenuItemSelected.emit(menuItem)"
         >
            <ng-template #contentTemplate1>
               <div class="side-bar-menu-display-title">
                  {{ menuItem.Label }}
               </div>
               <i
                  class="material-icons arrow-right"
                  *ngIf="menuItem.Children && menuItem.Children.length !== 0"
               >keyboard_arrow_right</i>
            </ng-template>
         </lcs-link>
         <lcs-link
            *ngIf="!menuItem.Children || menuItem.Children.length === 0"
            [cssClasses]="['side-bar-menu-label', menuItem?.EntityID === currentEntityID ? 'active' : '']"
            [contentTemplate]="contentTemplate2"
            (clicked)="childMenuItemSelected.emit(menuItem)"
         >
            <ng-template #contentTemplate2>
               <div [class.side-bar-menu-item-display]="
                     showInformation && menuItem.Information && menuItem.Information.length > 0
                  ">
                  <div class="side-bar-menu-display-title">{{ menuItem.Label }}</div>
                  <span
                     *ngIf="showInformation && menuItem.Information && menuItem.Information.length > 0"
                     class="side-bar-menu-display-information"
                  >{{ menuItem.Information }}</span>
               </div>
            </ng-template>
         </lcs-link>
      </div>
      <div *ngIf="useActionLinks">
         <lcs-action-link
            *ngIf="menuItem.Action?.ExpressActionID"
            [expressAction]="$any(menuItem.Action?.ExpressActionID)"
            [entityID]="$any(menuItem?.EntityID)"
            [cssClasses]="[
               'side-bar-menu-label',
               menuItem?.EntityID && currentEntityID === menuItem.EntityID ? 'active' : ''
            ]"
            [contentTemplate]="contentTemplate3"
         >
            <ng-template #contentTemplate3>
               <div [class.side-bar-menu-item-display]="
                     showInformation && menuItem.Information && menuItem.Information.length > 0
                  ">
                  <div class="side-bar-menu-display-title">{{ menuItem.Label }}</div>
                  <span
                     *ngIf="showInformation && menuItem.Information && menuItem.Information.length > 0"
                     class="side-bar-menu-display-information"
                  >{{ menuItem.Information }}</span>
               </div>
            </ng-template>
         </lcs-action-link>
         <lcs-link
            *ngIf="
               !menuItem.Action?.ExpressActionID &&
               menuItem.Children &&
               menuItem.Children.length !== 0
            "
            [cssClasses]="['justify-content-space-between']"
            [contentTemplate]="contentTemplate4"
            (clicked)="parentMenuItemSelected.emit(menuItem)"
         >
            <ng-template #contentTemplate4>
               <div class="side-bar-menu-display-title">
                  {{ menuItem.Label }}
               </div>
               <i
                  class="material-icons arrow-right"
                  *ngIf="menuItem.Children && menuItem.Children.length !== 0"
               >keyboard_arrow_right</i>
            </ng-template>
         </lcs-link>
         <lcs-link
            *ngIf="!menuItem.HasActionOrChildren"
            [contentTemplate]="contentTemplate5"
            [disabled]="true"
         >
            <ng-template #contentTemplate5>
               <div [class.side-bar-menu-item-display]="
                     showInformation && menuItem.Information && menuItem.Information.length > 0
                  ">
                  <div class="side-bar-menu-display-title strike-through">{{ menuItem.Label }}</div>
                  <span
                     *ngIf="showInformation && menuItem.Information && menuItem.Information.length > 0"
                     class="side-bar-menu-display-information"
                  >{{ menuItem.Information }}</span>
               </div>
            </ng-template>
         </lcs-link>
      </div>
   </li>
</ul>