import { ActionPayloadsCacheService } from "@lcs/action-context/action-payloads-cache.service";
import { CloseResultFn } from "@lcs/dialog/interfaces/close-result-dialog.interface";
import { ValueResultFn } from "@lcs/dialog/interfaces/value-result-dialog.interface";
import { ExpressActionViewModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-action-view-modes.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";
import { ExpressFavoriteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-favorite.model";

import { ActionTriggerPayloadModel } from "./action-trigger-payload.model";

export class ActionTriggerModel {
   ExpressActionID: ExpressActions | null;

   Action: ExpressActionModel | null;

   CloseCallBack?: CloseResultFn;

   Payloads: Array<ActionTriggerPayloadModel>;

   Favorite: ExpressFavoriteModel;

   ValueCallBack?: ValueResultFn;

   ViewMode: ExpressActionViewModes | null;

   Dismissible: boolean = true;

   CustomTitle: string;

   constructor(expressActionID?: ExpressActions, viewMode?: ExpressActionViewModes) {
      this.ExpressActionID = expressActionID ?? null;
      this.ViewMode = viewMode ?? null;
      this.Payloads = new Array<ActionTriggerPayloadModel>();
   }

   /**
    * Add Key/Value payload to action trigger. Prefer over pushing models directly onto Payloads array.
    */
   addPayload(key: string, value: any) {
      if (!this.Payloads) {
         this.Payloads = new Array<ActionTriggerPayloadModel>();
      }
      this.Payloads.push(new ActionTriggerPayloadModel(null, value, key));
   }

   /**
    * Convenience method for Legacy technique of adding a payload using the payloadField and value.
    * New preferred method uses a key, value pair
    * @param payloadField
    * @param value
    */
   addPayloadFieldValue(payloadField: ExpressPayloadFields, value: any) {
      this.Payloads.push(new ActionTriggerPayloadModel(payloadField, value));
   }

   /**
    * Caches an array of ActionTriggerPayloadModel and adds a payload to actionTriggerModel
    * where the key is the ActionTriggerPayloadModel.actionTriggerPayloadsCacheKey
    * and the value is the hashCode of the data which is used to retrieve all the
    * array of cached payloads.
    *
    * @returns payloadsCacheKeyValue
    */
   addCachedPayloads(
      actionTriggerPayloadModels: Array<ActionTriggerPayloadModel>,
      actionPayloadsCacheService: ActionPayloadsCacheService
   ): string {
      const payloadsCacheKeyValue: string = actionPayloadsCacheService.cachePayloads(actionTriggerPayloadModels);
      if (payloadsCacheKeyValue) {
         this.addPayload(ActionTriggerPayloadModel.actionTriggerPayloadsCacheKey, payloadsCacheKeyValue);
      }
      return payloadsCacheKeyValue;
   }
}
