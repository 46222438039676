import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { LcsCellDefDirective } from "./cdk-overrides/cell-def.directive";
import { LcsCellDirective } from "./cdk-overrides/cell.directive";
import { LcsColumnDefDirective } from "./cdk-overrides/column-def.directive";
import { LcsFooterCellDefDirective } from "./cdk-overrides/footer-cell-def.directive";
import { LcsFooterCellDirective } from "./cdk-overrides/footer-cell.directive";
import { LcsFooterRowDefDirective } from "./cdk-overrides/footer-row-def.directive";
import { LcsFooterRowComponent } from "./cdk-overrides/footer-row.component";
import { LcsHeaderCellDefDirective } from "./cdk-overrides/header-cell-def.directive";
import { LcsHeaderCellDirective } from "./cdk-overrides/header-cell.directive";
import { LcsHeaderRowDefDirective } from "./cdk-overrides/header-row-def.directive";
import { LcsHeaderRowComponent } from "./cdk-overrides/header-row.component";
import { LcsRowDefDirective } from "./cdk-overrides/row-def.directive";
import { LcsRowComponent } from "./cdk-overrides/row.component";
import { LcsTableNoResultsFoundMessageDirective } from "./directives/lcs-table-no-results-found-message.directive";
import { TableComponent } from "./table.component";

const exportedDeclarations = [
   TableComponent,
   LcsCellDefDirective,
   LcsCellDirective,
   LcsColumnDefDirective,
   LcsFooterCellDefDirective,
   LcsFooterCellDirective,
   LcsFooterRowComponent,
   LcsFooterRowDefDirective,
   LcsHeaderCellDefDirective,
   LcsHeaderCellDirective,
   LcsHeaderRowComponent,
   LcsHeaderRowDefDirective,
   LcsRowComponent,
   LcsRowDefDirective,
   LcsTableNoResultsFoundMessageDirective,
];

@NgModule({
   imports: [
      CommonModule,
      CdkTableModule,
   ],
   declarations: exportedDeclarations,
   exports: exportedDeclarations,
})
export class TableModule { }
