import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LinkModule } from "@lcs/link/link.module";

import { OverlayPanelsModule } from "../overlay-panel/overlay-panels.module";
import { InformationOverlayComponent } from "./information-overlay.component";

@NgModule({
   imports: [CommonModule, LinkModule, OverlayPanelsModule],
   declarations: [InformationOverlayComponent],
   exports: [InformationOverlayComponent],
})
export class InformationOverlayModule {}
