<input
   #input
   [type]="htmlInputType"
   class="no-spin"
   [attr.id]="path"
   [(ngModel)]="value"
   [ngModelOptions]="{ standalone: true }"
   [placeholder]="placeholder"
   [disabled]="disabled"
   (blur)="onBlur($event)"
   (focus)="onFocus($event)"
   (keyup.enter)="onEnterKey($event)"
   step="{{ step }}"
   min="{{ min }}"
   max="{{ max }}"
/>
<i class="material-icons validation-error-icon">error_outline</i>
<lcs-validation-overlay-panel
   [parentElement]="input"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>