import { NgModule } from "@angular/core";

import { PropertyOwnerSelectorModule } from "../property-owner-selector/property-owner-selector.module";
import { PropertyOwnershipSelectorComponent } from "./property-ownership-selector.component";

@NgModule({
   imports: [
      PropertyOwnerSelectorModule,
   ],
   declarations: [
      PropertyOwnershipSelectorComponent,
   ],
   exports: [
      PropertyOwnershipSelectorComponent,
   ]
})
export class PropertyOwnershipSelectorModule {}