import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WebChatComponent } from "./web-chat.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [WebChatComponent],
  exports: [
     WebChatComponent,
  ],
})
export class WebChatModule { }
