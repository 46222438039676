import { Injectable, OnDestroy, TemplateRef } from "@angular/core";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { Observable, Subject, take, takeUntil } from "rxjs";

import { EntityEventResult } from "../entity-events/entity-event-result.enum";
import { errorInDevMode } from "../utils/logging";
import { FooterConfigurationModel } from "./footer-configuration.model";
import { FooterRegistrationService } from "./footer-registration.service";
import { FooterComponent } from "./footer.component";

@Injectable({
   providedIn: "root",
})
export class CurrentFooterService implements OnDestroy {
   get footerID(): string | undefined {
      return this._footerID;
   }

   get isFooterVisible(): boolean {
      return !!this.footer?.visible;
   }

   private set footerID(footerID: string | undefined) {
      this._footerID = footerID;
   }

   private _footerID: string | undefined;

   private footer: FooterComponent | undefined;

   private unsubscribe = new Subject<void>();

   constructor(private uniqueIdService: UniqueIDService, private footerRegistrationService: FooterRegistrationService) {
      this.footerRegistrationService.currentFooter.pipe(takeUntil(this.unsubscribe)).subscribe((currentFooter) => {
         this.footer = currentFooter;
         this.footerID = currentFooter?.footerID;
      });
   }

   initialize(footerComponent: FooterComponent) {
      this.footer = footerComponent;
      this.footerID = `footer${this.uniqueIdService.getUniqueID()}`;
      footerComponent.footerID = this.footerID;
      this.footerRegistrationService.register(this.footerID, footerComponent);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   /**
    * Hides/Displays the latest registered footer.
    * @param isFooterHidden true if the footer should be hidden.
    * @param animateFooter true if the footer should use the animation when appearing
    */
   toggleFooter(isFooterHidden: boolean, animateFooter: boolean = true): void {
      if (this.footer) {
         this.footer.hideFooter(isFooterHidden, animateFooter);
      }
   }

   /**
    * Disables/Enables all buttons with the save entity event for the latest registered footer.
    * @param isDisabled true if the button should be disabled.
    */
   disableSaveButton(isDisabled: boolean): void {
      if (this.footer) {
         this.footer.disableSave(isDisabled, true);
      }
   }

   /**
    * Disables/Enables all buttons with the cancel entity event for the latest registered footer.
    * @param isDisabled true if the button should be disabled.
    */
   disableCancelButton(isDisabled: boolean): void {
      if (this.footer) {
         this.footer.disableCancel(isDisabled, true);
      }
   }

   /**
    * Disables/Enables all buttons for the latest registered footer.
    * @param isDisabled true if the button should be disabled.
    */
   disableAllButtons(isDisabled: boolean): void {
      if (this.footer) {
         this.footer.disableAll(isDisabled, true);
      }
   }

   /**
    * Add footer configuration to the current footer.
    * @param config The footer configuration override to apply to the footer.
    * @param onRemoveConfiguration Required for site footers. An observable that emits when the component footer configuration should be removed.
    */
   addFooterConfiguration(config: FooterConfigurationModel, onRemoveConfiguration?: Observable<void>): void {
      if (this.footer && this.isValidSiteFooterAction("footer configuration", onRemoveConfiguration)) {
         this.footer.footerConfiguration = config;

         if (onRemoveConfiguration) {
            const currentFooter = this.footer;
            onRemoveConfiguration.pipe(take(1)).subscribe(() => {
               if (currentFooter) {
                  currentFooter.footerConfiguration = null;
               }
            });
         }
      }
   }

   /**
    * Sets the information template of the current footer.
    * @param informationTemplate the information template to add to the current footer.
    * @param onRemoveTemplate Required for site footers. An observable that emits when the information template should be removed.
    */
   setInformationTemplate(informationTemplate: TemplateRef<any> | null, onRemoveTemplate?: Observable<void>): void {
      if (this.footer && this.isValidSiteFooterAction("information template", onRemoveTemplate)) {
         this.footer.informationTemplate = informationTemplate;

         if (onRemoveTemplate) {
            const currentFooter = this.footer;
            onRemoveTemplate.pipe(take(1)).subscribe(() => {
               if (currentFooter) {
                  currentFooter.informationTemplate = null;
               }
            });
         }
      }
   }

   /**
    * Sets the additional footer styling of the current footer.
    * @param additionalFooterStyling the additional footer styling to add to the current footer.
    * @param onRemoveStyling Required for site footers. An observable that emits when the additional footer styling should be removed.
    */
   setAdditionalFooterStyling(additionalFooterStyling: string | null, onRemoveStyling?: Observable<void>): void {
      if (this.footer && this.isValidSiteFooterAction("additional footer styling", onRemoveStyling)) {
         this.footer.additionalFooterStyling = additionalFooterStyling;

         if (onRemoveStyling) {
            const currentFooter = this.footer;
            onRemoveStyling.pipe(take(1)).subscribe(() => {
               if (currentFooter) {
                  currentFooter.additionalFooterStyling = null;
               }
            });
         }
      }
   }

   /**
    * Sets the maximum number of footer buttons that can display before collapsing the buttons.
    * @param maxFooterButtons maximum number of footer buttons.
    * @param onResetAmount Required for site footers. An observable that emits when the max number of buttons should reset.
    */
   setMaxNumberOfButtons(maxFooterButtons: number | null, onResetAmount?: Observable<void>): void {
      if (this.footer && this.isValidSiteFooterAction("max footer buttons mobile", onResetAmount)) {
         this.footer.maxNumberOfButtons = maxFooterButtons;

         if (onResetAmount) {
            const currentFooter = this.footer;
            onResetAmount.pipe(take(1)).subscribe(() => {
               if (currentFooter) {
                  currentFooter.maxNumberOfButtons = null;
               }
            });
         }
      }
   }

   /**
    * Waits for an update on the status display of any footer button before invoking the callback method.
    * @param callback The method to invoke a single time when the button status changes.
    */
   waitOnFooterButtonStatusDelay(callback: (result: EntityEventResult) => void) {
      this.footer?.buttonStatusChange.pipe(take(1)).subscribe((result) => {
         callback(result);
      });
   }

   /**
    * Resets the current footer's visibility based on the footer's configuration and restarts the footer's form listeners.
    */
   resetFooterVisibility(): void {
      this.footer?.resetFooterVisibility();
   }

   /**
    * Creates a function that will call the provided current footer service method on the footer instance that was current at the time this method was called.
    * This function allows us to delay changes to the current footer so that they can be called when this footer is no longer registered as the current footer.
    *
    * @example Delay the current footer's reset visibility method so that the site footer will reset when a confirmation dialog is accepted.
    * const resetFooterCallback = this.currentFooterService.getDelayedFunction(this.currentFooterService.resetFooterVisibility);
    * const dialog = new ConfirmationDialogConfigurationModel();
    * dialog.accept = () => resetFooterCallback();
    *
    * @param callback The method of the current footer service that will be delayed.
    * @returns a callback method bounded to the data for the current footer.
    */
   getDelayedFunction<T extends Function>(callback: T): T {
      const currentFooterData = {
         footer: this.footer,
      };

      return callback.bind(currentFooterData);
   }

   private isValidSiteFooterAction(footerProperty: string, onComponentDestroy?: Observable<any>): boolean {
      if (this.footer?.siteFooter && !onComponentDestroy) {
         errorInDevMode(`
            Error: Attempted to change the ${footerProperty} to the site footer without an exit plan. 
            Since the ${footerProperty} will persist for all components that use the site footer, 
            you must include an observable that will emit when the component is destroyed at the latest.
         `);
         return false;
      } else {
         return true;
      }
   }
}
