import { Directive, Input, OnInit, ElementRef, Renderer2 } from "@angular/core";

@Directive({
   selector: "[lcsFooter]",
})
export class FooterDirective implements OnInit {
   @Input() set lcsFooterIsEmpty(val: boolean) {
      if (this._isEmpty !== val) {
         this._isEmpty = val;
         this.updateStyles();
      }
   }

   private _isEmpty: boolean;

   constructor(private el: ElementRef, private renderer: Renderer2) {}

   ngOnInit() {
      this.updateStyles();
   }

   private updateStyles() {
      if (this._isEmpty) {
         this.renderer.addClass(this.el.nativeElement, "lcs-footer-empty");
      } else {
         this.renderer.removeClass(this.el.nativeElement, "lcs-footer-empty");
      }
   }
}
