import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   Input,
   NgZone,
   OnDestroy,
   OnInit,
   TemplateRef,
   ViewChild,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { fromEvent, Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-color-picker",
   templateUrl: "color-picker.component.html",
   styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit, OnDestroy {
   @Input() icon: TemplateRef<any>;

   @Input() disabled: boolean;
   /**
    * Toggle to show/hide OK button in color picker
    *
    * @type {boolean}
    * @memberof ColorPickerComponent
    */
   @Input() showOKButton: boolean;

   /**
    * Toggle to show/hide Cancel button in color picker
    *
    * @type {boolean}
    * @memberof ColorPickerComponent
    */
   @Input() showCancelButton: boolean;

   /**
    * Set the dialog display for the color picker
    * popup: dialog is showed when user clicks in the directive.
    * inline: dialog is showed permanently.
    * default: popup
    * @type {string}
    * @memberof ColorPickerComponent
    */
   @Input() cpDialogDisplay: string = "popup";

   @ViewChild("colorPickerInput") colorPickerInput: ElementRef;

   /**
    * Allows changing the type from default text to button if you do not want or need and input cursor
    * in your color swatch.
    *
    * @type {string}
    * @memberof ColorPickerComponent
    */
   @Input() inputType: string = "text";

   /**
    * Width of ColorSwatch
    *
    * @type {string}
    * @memberof ColorPickerComponent
    */
   @Input() width: string = "";

   /**
    * Height of ColorSwatch
    *
    * @type {string}
    * @memberof ColorPickerComponent
    */
   @Input() height: string = "";

   /**
    * Allows the display of the alpha channel
    *
    * @type {boolean}
    * @memberof ColorPickerComponent
    */
   @Input() displayAlphaChannel: boolean = false;

   cpToggle: boolean;

   private unsubscribe = new Subject<void>();

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl, private ngZone: NgZone) {
      super(changeDetectorRef, ngControl);
   }

   ngOnInit() {
      return super.ngOnInit();
   }

   ngAfterViewInit() {
      this.ngZone.runOutsideAngular(() => {
         fromEvent(this.colorPickerInput.nativeElement, "keydown")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((e: KeyboardEvent) => {
               this.ngZone.run(() => {
                  this.onKeyDown(e);
               });
            });
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onKeyDown(event: KeyboardEvent) {
      if (event.code !== "Tab") {
         event.preventDefault();
      } else {
         // first tab will close the color picker, second tab will move to next element
         this.cpToggle = false;
         this.focusNextElementExcludingAnchorTags(<HTMLInputElement>event.target);
      }
   }

   onColorConfirmed(color: string) {
      this.value = color;
   }

   /**
    * This allows forward tab navigation through color picker.
    * It specifically ignores focusing on the anchor elements inside the control.
    * @param currentFocusedElement
    */
   private focusNextElementExcludingAnchorTags(currentFocusedElement: HTMLInputElement) {
      // add all elements we want to include in our selection
      const focusableElementSelector = `button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not(a):not([disabled]):not([tabindex='-1'])`;
      if (currentFocusedElement && currentFocusedElement.form) {
         const focusableElements = Array.prototype.filter.call(
            currentFocusedElement.form.querySelectorAll(focusableElementSelector),
            function (element: HTMLElement) {
               // check for visibility while always including the current activeElement
               return element.offsetWidth > 0 || element.offsetHeight > 0 || element === currentFocusedElement;
            }
         );
         const index = focusableElements.indexOf(currentFocusedElement);
         if (index > -1) {
            const nextElement = focusableElements[index + 1] || focusableElements[0];
            nextElement.focus();
         } else {
            console.warn(
               "Currently focused element not found in list of focusableElements. Unable to focus on next focusable element."
            );
         }
      }
   }
}
