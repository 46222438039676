import { Optional, SkipSelf } from "@angular/core";
import { ControlContainer } from "@angular/forms";

export function controlContainerFactory(container: ControlContainer): ControlContainer {
   return container || null;
}
export const ControlContainerViewProvider: any = {
   provide: ControlContainer,
   deps: [[ControlContainer, new Optional(), new SkipSelf()]],
   useFactory: controlContainerFactory,
};
