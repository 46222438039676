import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { AccountUpdatedComponent } from "./account-updated.component";


@NgModule({
   declarations: [
      AccountUpdatedComponent,
   ],
   imports: [
      CommonModule,
      FormsModule,
      LcsProgressButtonModule,
   ],
})
export class AccountUpdatedModule { }