import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { CamAutoDivisionMethodCustomer } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/cam-auto-division-method-customer.enum";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ReportParameterValueStaticSourceItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-static-source-item.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-cam-division-report-parameter",
   templateUrl: "cam-division-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class CAMDivisionReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   selectedCamDivisionMethod: CamAutoDivisionMethodCustomer =
      CamAutoDivisionMethodCustomer.PercentPropertySquareFootage;

   camDivisionMethods = CamAutoDivisionMethodCustomer;

   tenantUDFFieldValueSources: Array<ValueSourceModel>;

   camDivReportParameter: ReportReportParameterViewModel;

   tenantUDFReportParameter: ReportReportParameterViewModel;

   proRataShareReportParameter: ReportReportParameterViewModel;

   adminFeesReportParameter: ReportReportParameterViewModel;

   proRataShareValidation: ValidationModel;

   adminFeesValidation: ValidationModel;

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private tenantReplacementName: string;

   constructor(
      private _apiService: ApiService,
      private reportParametersService: ReportParametersService,
      private entityInformationService: EntityInformationService
   ) {
      const entityInformationTenant = this.entityInformationService.getEntityTypeInformation(EntityType.Tenant);
      if (entityInformationTenant) {
         this.tenantReplacementName = entityInformationTenant.SingleLabel;
      }
   }

   ngOnInit() {
      const apiURL = "Tenants/UserDefinedFields";
      const fields = ["UserDefinedFieldID", "Name"];
      this._apiService
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         .getCollection(apiURL, null, [], [], fields)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => this.processCollection(results));

      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      const camDivReportParameter: ReportReportParameterViewModel = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CAMDIVEXPMETHOD
      );

      if (camDivReportParameter.ReportID === Report.CommercialRentRoll) {
         camDivReportParameter.ReportParameterValueSource.EnumSources[0].ValuesToExclude.push(
            CamAutoDivisionMethodCustomer.EqualAmongUnits.toString()
         );
         camDivReportParameter.DisplayName = "Pro Rata Share";
      }
      this.camDivReportParameter = camDivReportParameter;

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.tenantUDFReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CAMTenantUDF
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.proRataShareReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IsUseProRataShare
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.adminFeesReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IsUseAdminFees
      );
   }

   processCollection(results: Array<string>) {
      this.tenantUDFReportParameter.ReportParameterValueSource.StaticValues = [];
      const tenantUDFs = [];
      if (results) {
         for (const udfObj of results) {
            const item = new ReportParameterValueStaticSourceItemModel();
            item.DisplayValue = udfObj["Name"];
            item.Value = udfObj["UserDefinedFieldID"];
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameterValueStaticSource... Remove this comment to see the full error message
            tenantUDFs.push(item);
         }
      }
      this.tenantUDFReportParameter.ReportParameterValueSource.StaticValues = tenantUDFs;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.CAMDIVEXPMETHOD) {
         const camDivValue = updatedValue.value;
         if (camDivValue) {
            this.selectedCamDivisionMethod = this.getCamDivisionMethod(camDivValue);

            if (this.camDivReportParameter.ReportID === Report.CommercialRentRoll) {
               if (this.selectedCamDivisionMethod === CamAutoDivisionMethodCustomer.CRESetup) {
                  this.reportParametersService.addErrorMessage(
                     ReportParameter.CAMDIVEXPMETHOD,
                     "Pro rata share or Admin fees must be selected when you choose 'Default percentage from lease CRE setup.'"
                  );
               } else if (this.selectedCamDivisionMethod === CamAutoDivisionMethodCustomer.UDFField) {
                  this.reportParametersService.addErrorMessage(
                     ReportParameter.CAMDIVEXPMETHOD,
                     "A User Defined Field must be selected when you choose 'Percentage in " +
                        this.tenantReplacementName.toLowerCase() +
                        "'s user defined field.'"
                  );
               } else {
                  this.reportParametersService.removeErrorMessage(ReportParameter.CAMDIVEXPMETHOD);
               }
            }
         }
      } else if (
         this.camDivReportParameter.ReportID === Report.CommercialRentRoll &&
         (updatedValue.reportParameter === ReportParameter.IsUseProRataShare ||
            updatedValue.reportParameter === ReportParameter.IsUseAdminFees)
      ) {
         const isUseProRataShareValue = this.reportParametersService.reportParameterValues.get(
            ReportParameter.IsUseProRataShare
         );
         const isUseAdminFees = this.reportParametersService.reportParameterValues.get(ReportParameter.IsUseAdminFees);
         if (!isUseProRataShareValue?.value && !isUseAdminFees?.value) {
            this.reportParametersService.addErrorMessage(
               ReportParameter.CAMDIVEXPMETHOD,
               "Pro rata share or Admin fees must be selected when you choose 'Default percentage from lease CRE setup.'"
            );
         } else {
            this.reportParametersService.removeErrorMessage(ReportParameter.CAMDIVEXPMETHOD);
         }
      } else if (
         this.camDivReportParameter.ReportID === Report.CommercialRentRoll &&
         updatedValue.reportParameter === ReportParameter.CAMTenantUDF
      ) {
         const CAMTenantUDFValue = this.reportParametersService.reportParameterValues.get(ReportParameter.CAMTenantUDF);
         if (CAMTenantUDFValue && CAMTenantUDFValue?.value !== null && CAMTenantUDFValue?.value !== undefined) {
            this.reportParametersService.removeErrorMessage(ReportParameter.CAMDIVEXPMETHOD);
         } else {
            this.reportParametersService.addErrorMessage(
               ReportParameter.CAMDIVEXPMETHOD,
               "A User Defined Field must be selected when you choose 'Percentage in " +
                  this.tenantReplacementName.toLowerCase() +
                  "'s user defined field.'"
            );
         }
      }
   }

   private initializeReportParameterValues() {
      if (this.camDivReportParameter.DefaultValue) {
         this.selectedCamDivisionMethod = this.getCamDivisionMethod(this.camDivReportParameter.DefaultValue);
      }
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }

   private getCamDivisionMethod(value: any): CamAutoDivisionMethodCustomer {
      switch (+value) {
         case +CamAutoDivisionMethodCustomer.EqualAmongUnits:
            return CamAutoDivisionMethodCustomer.EqualAmongUnits;
         case +CamAutoDivisionMethodCustomer.CRESetup:
            return CamAutoDivisionMethodCustomer.CRESetup;
         case +CamAutoDivisionMethodCustomer.UDFField:
            return CamAutoDivisionMethodCustomer.UDFField;
         default:
            return CamAutoDivisionMethodCustomer.PercentPropertySquareFootage;
      }
   }
}
