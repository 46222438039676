import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { OverlayPanelsModule } from "../../overlay-panel/overlay-panels.module";
import { ValidationErrorDisplayPanelComponent } from "./components/validation-error-display-panel.component";
import { ValidationErrorItemComponent } from "./components/validation-error-item.component";
import { ValidationOverlayPanelComponent } from "./components/validation-overlay-panel.component";
import { ValidationSummaryComponent } from "./components/validation-summary.component";
import { ValidationDirective } from "./validators/validation.directive";

@NgModule({
   imports: [CommonModule, FormsModule, ReactiveFormsModule, OverlayPanelsModule],
   declarations: [
      ValidationErrorDisplayPanelComponent,
      ValidationDirective,
      ValidationErrorItemComponent,
      ValidationOverlayPanelComponent,
      ValidationSummaryComponent,
   ],
   exports: [
      ValidationErrorDisplayPanelComponent,
      ValidationDirective,
      ValidationOverlayPanelComponent,
      ValidationSummaryComponent,
   ],
})
export class ValidationModule {}
