import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-address-type-report-parameter",
   templateUrl: "address-type-report-parameter.component.html",
})
export class AddressTypeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   allValuesLoaded: boolean = false;

   valueSources: Array<ValueSourceModel>;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(private apiService: ApiService, private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeReportParameterValue();
      this.initializeAddressTypes();
   }

   processCollection(results: Array<AddressTypeModel>, valueSourceModel: ValueSourceModel) {
      const sources = new Array<ValueSourceModel>();
      if (results) {
         const useDefaultSelectorItem = new SelectorItemModel();
         useDefaultSelectorItem.displayValue = "< Use Default >";
         useDefaultSelectorItem.value = "0";
         valueSourceModel.StaticValues.push(useDefaultSelectorItem);

         for (const addressType of results) {
            const item = new SelectorItemModel();
            item.displayValue = addressType.Name;
            item.value = addressType.AddressTypeID.toString();
            valueSourceModel.StaticValues.push(item);
         }
         valueSourceModel.SelectedValues.push(this.parameter.DefaultValue);
         sources.push(valueSourceModel);
         this.valueSources = sources;
         this.allValuesLoaded = true;
      }
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel) {
      const values = selectionChangeModel.checkedItems;
      let value = "";
      if (values.length > 1) {
         value = "(" + values.map((f) => f.value).join(",") + ")";
      } else if (values.length === 1) {
         value = values[0].value;
      }
      this.reportParameterValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private initializeAddressTypes() {
      let apiURL = "Tenants/AddressTypes";

      if (this.parameter.ReportID) {
         switch (this.parameter.ReportID.toString()) {
            case Report[Report.ProspectLabels]:
               apiURL = "Prospects/AddressTypes";
               break;
            case Report[Report.VendorLabels]:
               apiURL = "Vendors/AddressTypes";
               break;
            case Report[Report.OwnerLabels]:
               apiURL = "Owners/AddressTypes";
               break;
         }
      }

      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;
      this.apiService
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         .getCollection(apiURL, null, [], [], [])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => this.processCollection(results, valueSourceModel));
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }
}
