<div
   class="sidebar-layout-list-item-wrapper"
   *ngFor="let item of items[0]"
>
   <div
      class="sidebar-layout-list-item"
      (click)="onItemClick(item.UniqueIdentifier)"
      [class.selected]="selectedUniqueIdentifier === item?.UniqueIdentifier"
      [class.disabled]="selectedUniqueIdentifier !== item?.UniqueIdentifier && disableUnselected"
   >
      <ng-container *ngIf="itemTemplate; else defaultTemplate">
         <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
      <ng-template #defaultTemplate>
         {{ item | objectMapResolver: displayPropertyPath }}
      </ng-template>
   </div>
</div>