import { Component, isDevMode, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ReceivesPayload } from "@lcs/component-interfaces/receives-payload.interface";
import { ErrorMessageModel } from "@lcs/error-message/error-message.model";
import { KEY_E } from "keycode-js";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { filter, fromEvent, Subject, takeUntil, throttleTime } from "rxjs";

@Component({
   selector: "lcs-error-message",
   templateUrl: "error-message.component.html",
})
export class ErrorMessageComponent implements OnInit, OnDestroy, ReceivesPayload {
   errorMessage: ErrorMessageModel;

   payloads: Array<ActionTriggerPayloadModel>;

   showDetails: boolean;

   private unsubscribe = new Subject<void>();

   constructor(private ngZone: NgZone) {}

   ngOnInit() {
      this.ngZone.runOutsideAngular(() => {
         fromEvent(window, "keydown")
            .pipe(
               throttleTime(100),
               filter(
                  (keyboardEvent: KeyboardEvent) =>
                     keyboardEvent.keyCode === KEY_E && keyboardEvent.ctrlKey && keyboardEvent.altKey
               ),
               takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
               this.ngZone.run(() => {
                  this.showDetails = !this.showDetails;
               });
            });
      });
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.errorMessage = this.payloads.find((payload) => payload.PayloadField === ExpressPayloadFields.Value).Value;
      this.showDetails = isDevMode() && this.errorMessage?.AdditionalDetails;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }
}
