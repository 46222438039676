import { Injectable } from "@angular/core";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";

import { ObjectMapResolverService } from "./object-map-resolver.service";

@Injectable()
export class UserDefinedValueDisplayValueService {
   constructor(private objectMapResolverService: ObjectMapResolverService) {}

   public getDisplayValue(obj: any, path: string, controlTypePropertyPath?: string): string {
      let expressDataType: ExpressDataTypes;
      if (controlTypePropertyPath) {
         const controlType = ObjectMapResolverService.getPropertyValue(obj, controlTypePropertyPath);
         if (controlType === UserDefinedFieldType.Date) {
            expressDataType = ExpressDataTypes.Date;
         } else if (controlType === UserDefinedFieldType.Numeric) {
            expressDataType = ExpressDataTypes.String;
         } else if (controlType === UserDefinedFieldType.Checkedlist) {
            // @ts-ignore ts-migrate(2454) FIXME: Variable 'expressDataType' is used before being as... Remove this comment to see the full error message
            const rawValue = this.objectMapResolverService.getDisplayValue(obj, path, expressDataType);
            if (rawValue) {
               return rawValue.replace(/,/g, ", ");
            } else {
               return rawValue;
            }
         }
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'expressDataType' is used before being as... Remove this comment to see the full error message
      return this.objectMapResolverService.getDisplayValue(obj, path, expressDataType);
   }
}
