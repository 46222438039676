////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ProjectStatus {
   None = 0,
   Current = 1,
   Completed = 2,
   All = 3,
}
