import { ChangeDetectorRef, Component, Input, OnInit, PipeTransform } from "@angular/core";
import { NgControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";

@Component({
   selector: "lcs-static-field",
   templateUrl: "./static-field.component.html",
   styleUrls: ["./static-field.component.scss"],
})
export class StaticFieldComponent extends ValueAccessorBase<any> implements OnInit {
   @Input() label: string;

   /** ExpressDataType - Should set unless using a custom PipeTransform */
   @Input() formatDataType: ExpressDataTypes;

   /**
    * Use to use a custom PipeTransform to format the display value instead of the default ValueFormatter associated with the ExpressDataType.
    *
    * This can be anything that implements the PipeTransform interface
    */
   @Input() customPipeTransform?: PipeTransform;

   /** Argument(s) to pass to the custom PipeTransform, for example the date format string if using a DatePipe */
   @Input() customPipeTransformArgs?: any | any[];

   @Input() labelTooltip: string = "";

   @Input() tooltipOverride: string | undefined;

   @Input() wrapperStyleOverride: any = "";

   @Input() labelStyleOverride: any = "";

   @Input() valueStyleOverride: any = "";

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   ngOnInit(): RequiredSuperCallFlag {
      return super.ngOnInit();
   }
}
