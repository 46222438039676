import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SignableDocumentPacketStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/signable-document-packet-status.enum";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-signable-documents-list-item",
   templateUrl: "./signable-documents-list-item.component.html",
   styleUrls: ["./signable-documents-list-item.component.css"],
})
export class SignableDocumentsListItemComponent implements OnInit {
   @Input() signableDocumentPacket: SignableDocumentPacketSummaryModel;

   private unsubscribe = new Subject<void>();
   hasThemeSignableDocuments: boolean;

   get isSignable(): boolean {
      return (
         !this.signableDocumentPacket.IsViewOnly &&
         this.signableDocumentPacket.Status === SignableDocumentPacketStatus.Published
      );
   }

   get hasFields(): boolean {
      return this.signableDocumentPacket.TotalRequiredFieldsCount > 0;
   }

   get showExpiration(): boolean {
      return this.isSignable && this.signableDocumentPacket.ExpirationDate.toString() !== "0001-01-01T00:00:00";
   }
   constructor(private router: Router, private systemWebPreferenceSessionService: SystemWebPreferencesSessionService) {}

   ngOnInit() {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               var themePref = prefs.find((p) => p.Name === "OWAToolbarColor");
               if (themePref != null && themePref.Value != "#F28228") {
                  this.hasThemeSignableDocuments = true;
               } else {
                  this.hasThemeSignableDocuments = false;
               }
            }
         }
      );
   }

   signDocument() {
      const route = "/signabledocuments/" + this.signableDocumentPacket.SignableDocumentPacketID + "/sign";
      this.router.navigate([route]);
   }

   viewDocument() {
      const route = "/signabledocuments/" + this.signableDocumentPacket.SignableDocumentPacketID + "/view";
      this.router.navigate([route]);
   }
}
