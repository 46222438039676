import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { NgControl, UntypedFormControl } from "@angular/forms";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { PrivilegeType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege-type.enum";
import { Privilege } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege.enum";
import { UserDefinedFieldRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-related-object-types.enum";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserDefinedFieldsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/user-defined-fields.service";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { Subject, take, takeUntil } from "rxjs";

import { EntityEventResult } from "../../entity-events/entity-event-result.enum";
import { EntityEventResultModel } from "../../entity-events/entity-event-result.model";
import { EntityEventService } from "../../entity-events/entity-event.service";

@Component({
   selector: "lcs-udf-encrypted-text",
   templateUrl: "udf-encrypted-text.component.html",
   styleUrls: ["udf-encrypted-text.component.scss"],
})
export class UDFEncryptedTextComponent extends ValueAccessorBase<string> implements AfterViewInit, OnDestroy {
   @Input() set placeholder(value: string) {
      this._placeholder = value || "";
   }
   get placeholder() {
      return this._placeholder;
   }

   @Input() autofocus: boolean;

   @Input() displayName: string;

   @Input() tooltipOverride: string;

   @Input() isReadOnly: boolean;

   @Input() set userDefinedData(userDefinedData: UserDefinedValueModel) {
      if (userDefinedData) {
         this.checkUserPrivilege(userDefinedData.UserDefinedField.ParentType);
         this.getDecryptedUserDefinedValue(userDefinedData.UserDefinedFieldID, userDefinedData.UserDefinedValueID);
      }
   }

   @Output() blurEvent = new EventEmitter<any>();

   @Output() focusEvent = new EventEmitter<any>();

   @Output() enterKeyEvent = new EventEmitter<any>();

   @ViewChild("textBox", { static: false }) inputEl: ElementRef;

   get hideEyeIcon(): boolean {
      return !this.hasPrivilege || this.isEmpty || this.isLoading || this.isAdd;
   }

   hasPrivilege: boolean;

   isValueHidden: boolean;

   displayValue: string;

   isEmpty: boolean;

   isLoading: boolean;

   control: UntypedFormControl;

   private isAdd: boolean = false;

   private _placeholder: string = "";

   private unsubscribe = new Subject<void>();

   private readonly encryptedUDFMask: string = "XXXXXXXXXX";

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private currentUserService: CurrentUserService,
      private userDefinedFieldsService: UserDefinedFieldsService,
      private errorMessageService: ErrorMessageService,
      private entityEventService: EntityEventService
   ) {
      super(changeDetectorRef, ngControl);

      this.initializeData();

      this.entityEventService.entityEventResult
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((event: EntityEventResultModel) => {
            if (
               event.expressEntityEvent === ExpressEntityEvents.Save &&
               event.entityEventResult === EntityEventResult.Success
            ) {
               this.isValueHidden = !this.isEmpty;
               this.displayValue = this.isEmpty ? "" : this.encryptedUDFMask;
               this.isAdd = false;
            }
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   ngAfterViewInit() {
      if (this.autofocus) {
         this.inputEl.nativeElement.focus();
      }
   }

   onFocus(event: FocusEvent) {
      this.focusEvent.emit(event);
      if (this.isAdd) {
         this.displayValue = this.value;
      }
   }

   onBlur(event: FocusEvent) {
      this.blurEvent.emit(event);
      this.propagateTouched();
      if (this.isAdd) {
         this.displayValue = this.encryptedUDFMask;
      }
   }

   onEnterKey(event: Event) {
      this.enterKeyEvent.emit(event as MouseEvent);
   }

   onModelChange(value: string) {
      if (!this.isValueHidden) {
         this.value = value;
         this.isEmpty = this.value === "";
      }
   }

   onVisibilityChanged() {
      this.isValueHidden = !this.isValueHidden;
      if (this.isValueHidden && this.value !== "") {
         this.displayValue = this.encryptedUDFMask;
      } else {
         this.displayValue = this.value;
      }
   }

   private initializeData() {
      this.innerValue = "";
      this.isEmpty = true;
      this.isValueHidden = false;
      this.displayValue = "";
   }

   private checkUserPrivilege(entityType: UserDefinedFieldRelatedObjectTypes) {
      const privilege = this.getUserPrivilege(entityType);
      if (privilege !== Privilege.NotSet) {
         this.hasPrivilege = this.currentUserService.hasPrivilege(privilege, PrivilegeType.On);
      } else {
         this.hasPrivilege = false;
      }
   }

   private getUserPrivilege(entityType: UserDefinedFieldRelatedObjectTypes): Privilege {
      switch (entityType) {
         case UserDefinedFieldRelatedObjectTypes.Asset:
            return Privilege.AccessEncryptedUDF_Asset;
         case UserDefinedFieldRelatedObjectTypes.CommercialLeaseRenewal:
            return Privilege.AccessEncryptedUDF_CommercialLease;
         case UserDefinedFieldRelatedObjectTypes.Contact:
            return Privilege.AccessEncryptedUDF_Contact;
         case UserDefinedFieldRelatedObjectTypes.InventoryItem:
            return Privilege.AccessEncryptedUDF_Inventory;
         case UserDefinedFieldRelatedObjectTypes.ServiceManagerIssue:
         case UserDefinedFieldRelatedObjectTypes.ServiceManagerRecurringIssue:
            return Privilege.AccessEncryptedUDF_Issue;
         case UserDefinedFieldRelatedObjectTypes.Job:
            return Privilege.AccessEncryptedUDF_Job;
         case UserDefinedFieldRelatedObjectTypes.Loan:
            return Privilege.AccessEncryptedUDF_Loan;
         case UserDefinedFieldRelatedObjectTypes.NonCommercialLeaseRenewal:
            return Privilege.AccessEncryptedUDF_NonCommercialLease;
         case UserDefinedFieldRelatedObjectTypes.Owner:
            return Privilege.AccessEncryptedUDF_Owner;
         case UserDefinedFieldRelatedObjectTypes.OwnerProspects:
            return Privilege.AccessEncryptedUDF_OwnerProspect;
         case UserDefinedFieldRelatedObjectTypes.Property:
            return Privilege.AccessEncryptedUDF_Property;
         case UserDefinedFieldRelatedObjectTypes.System:
            return Privilege.AccessEncryptedUDF_System;
         case UserDefinedFieldRelatedObjectTypes.Prospect:
            return Privilege.AccessEncryptedUDF_Prospect;
         case UserDefinedFieldRelatedObjectTypes.Tenant:
            return Privilege.AccessEncryptedUDF_Tenant;
         case UserDefinedFieldRelatedObjectTypes.Unit:
            return Privilege.AccessEncryptedUDF_Unit;
         case UserDefinedFieldRelatedObjectTypes.User:
            return Privilege.AccessEncryptedUDF_User;
         case UserDefinedFieldRelatedObjectTypes.Vendor:
            return Privilege.AccessEncryptedUDF_Vendor;
         default:
            return Privilege.NotSet;
      }
   }

   private getDecryptedUserDefinedValue(userDefinedFieldID: number, userDefinedValueID: number) {
      if (userDefinedFieldID > 0 && userDefinedValueID > 0) {
         this.isLoading = true;
         this.userDefinedFieldsService
            .getDecryptedUDV(userDefinedFieldID, userDefinedValueID)
            .pipe(take(1))
            .subscribe({
               next: (decryptedValue: string) => {
                  this.innerValue = decryptedValue;
                  this.isEmpty = this.value === "";
                  this.isValueHidden = !this.isEmpty;
                  this.displayValue = this.isEmpty ? "" : this.encryptedUDFMask;
                  this.changeDetectorRef.detectChanges();
               },
               error: (error) => {
                  if (error) {
                     this.errorMessageService.triggerHttpErrorMessage(error);
                     this.isEmpty = true;
                     this.isLoading = false;
                  }
               },
               complete: () => {
                  this.isLoading = false;
               },
            });
      } else {
         this.isAdd = true;
         this.initializeData();
      }
   }
}
