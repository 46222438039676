import { Component, Input, OnInit } from "@angular/core";
import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { ReportParameterComponents } from "@lcs/reports/report-parameters/report-parameter-components.enum";
import { ReportParameterRendererComponentBase } from "projects/owa/src/app/report-parameter-override/report-parameter-override-component.base";

@Component({
   selector: "owa-report-parameter-override",
   templateUrl: "./report-parameter-override.component.html",
})
export class ReportParameterOverrideComponent implements OnInit, ReportParameterRendererComponentBase {
   @Input() controlName: string;

   @Input() parameter: ReportReportParameterComponentModel;

   @Input() parameterComponents: ReportReportParameterComponentModel[];

   @Input() hasAsk: boolean;

   @Input() isRelative: boolean;

   reportParameterComponents = ReportParameterComponents;

   constructor() {}

   ngOnInit() {
      if (
         this.parameter.ReportParameterComponent === ReportParameterComponents.ChartAccountsToInclude ||
         this.parameter.ReportParameterComponent === ReportParameterComponents.BankAccountSelector ||
         this.parameter.ReportParameterComponent === ReportParameterComponents.VendorNameAndUDFName
      ) {
         this.parameter.ReportParameterComponent = ReportParameterComponents.Selector;
      }
   }
}
