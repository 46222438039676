import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-numeric-input-report-parameter",
   templateUrl: "numeric-input-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class NumericInputReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   @Input() showSeparator: boolean = true;

   reportParameterValueModel: ReportParameterValueModel;

   formatString: string = "";

   private _parameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {}

   ngOnInit() {
      this.initializeReportParameterValue();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   update() {
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameters(updatedValue);
         });

      if (!this.disabled) {
         this.disabled = this.reportParameterControlStatusService.getInitialDisableValue(
            this._parameter.ReportID,
            this.reportParameterValueModel.reportParameter,
            Array.from(this.reportParametersService.reportParameterValues.values())
         );
      }
      this.initializeValueSource();
   }

   private initializeValueSource() {
      if (
         this.parameter.ReportParameterValueSource &&
         this.parameter.ReportParameterValueSource.Validation &&
         Object.keys(this.parameter.ReportParameterValueSource.Validation).length > 0
      ) {
         const parameterValidation = this.parameter.ReportParameterValueSource.Validation;
         const validation = new ValidationModel();
         validation.dataType = ExpressDataTypes.Numeric;
         if (
            parameterValidation.InclusiveRangeMax != null &&
            parameterValidation.InclusiveRangeMin != null &&
            !isNaN(parameterValidation.InclusiveRangeMax) &&
            !isNaN(parameterValidation.InclusiveRangeMin)
         ) {
            validation.inclusiveRange = [parameterValidation.InclusiveRangeMin, parameterValidation.InclusiveRangeMax];
         }
         if (parameterValidation.MaxFractionalDigits != null && !isNaN(parameterValidation.MaxFractionalDigits)) {
            validation.maxFractionalDigits = parameterValidation.MaxFractionalDigits;
         }
         if (parameterValidation.MinimumValue != null && !isNaN(parameterValidation.MinimumValue)) {
            validation.min = parameterValidation.MinimumValue;
         }
         if (parameterValidation.MaximumValue != null && !isNaN(parameterValidation.MaximumValue)) {
            validation.max = parameterValidation.MaximumValue;
         }
         if (
            this.parameter.ReportParameterValueSource.FormatString &&
            this.parameter.ReportParameterValueSource.FormatString.length > 0
         ) {
            this.formatString = this.parameter.ReportParameterValueSource.FormatString;
         }
         this.validation = validation;
      }
   }

   private updateReportParameters(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.parameter.ReportID,
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
