////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ApplicationFieldInputFieldTypes {
   Text = 0,
   Dropdown = 1,
   YesNo = 2,
   Date = 3,
   Numeric = 4,
   File = 5,
   Image = 6,
   SSN = 7,
   PhoneNumber = 8,
   Email = 9,
   State = 10,
   MultiLineText = 11,
   Property = 12,
   UnitUnitType = 13,
   LeadSource = 14,
   PetTypes = 15,
   NotSet = -1,
}
