import { Component, Input, OnInit } from "@angular/core";
import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { ReportParameterComponents } from "@lcs/reports/report-parameters/report-parameter-components.enum";
import { ReportParameterRendererComponentBase } from "@lcs/reports/report-parameters/report-parameter-override-component.base";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";

@Component({
   selector: "owa-report-parameter-renderer",
   templateUrl: "./report-parameter-renderer.component.html",
})
export class ReportParameterRendererComponent implements ReportParameterRendererComponentBase, OnInit {
   @Input() controlName: string;

   @Input() displayName: string;

   @Input() parameter: ReportReportParameterComponentModel;

   @Input() parameterComponents: Array<ReportReportParameterComponentModel>;

   @Input() hasAsk: boolean;

   @Input() isRelative: boolean;

   @Input() report: ReportModel;

   reportParameterComponents = ReportParameterComponents;

   ngOnInit() {
      if (!this.displayName && this.parameter && this.parameter.ReportReportParameter) {
         this.displayName = ReportParameterRendererComponentBase.getDisplayNameFromReportReportParameter(
            this.parameter.ReportReportParameter
         );
      }
   }
}
