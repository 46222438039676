import { AbstractControl } from "@angular/forms";

export class FormControlRegistrationModel {
   Name: string;

   DisplayName: string;

   FormControl: AbstractControl;

   constructor(name: string, control: AbstractControl, displayName: string) {
      this.Name = name;
      this.DisplayName = displayName;
      this.FormControl = control;
   }
}
