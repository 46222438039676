import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";

import { LcsProgressButtonComponent } from "./progress-button.component";

@NgModule({
   imports: [
      CommonModule,
      LoadingOverlayModule,
      MatButtonModule,
   ],
   declarations: [LcsProgressButtonComponent],
   exports: [LcsProgressButtonComponent],
})
export class LcsProgressButtonModule { }
