import { Injectable, OnDestroy } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ActionTriggerModel } from "@lcs/action-trigger/action-trigger.model";
import { ActionTriggerService } from "@lcs/action-trigger/action-trigger.service";
import { ConfirmationDialogService } from "@lcs/dialog/components/confirmation-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { CurrentSystemPreferencesService } from "@lcs/session/current-system-preferences.service";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { OutboundCallRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/outbound-call-request.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { NDTService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/ndt.service";
import { UsersService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/users.service";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";

import { ClickToCallBaseService } from "./click-to-call-base.service";

@Injectable()
export class ClickToCallService extends ClickToCallBaseService implements OnDestroy {
   users: Array<UserModel>;
   toolTip: Observable<string>;

   availablePhoneNumbers: Array<string>;

   entityID: number;

   entityType: EntityType;

   private unsubscribe = new Subject<void>();

   private _toolTip = new BehaviorSubject<string | null>(null);

   private lastSelectedUserModel: UserModel;

   constructor(
      private userService: UsersService,
      private actionTriggerService: ActionTriggerService,
      protected errorMessageService: ErrorMessageService,
      protected nDTService: NDTService,
      protected confirmationDialogService: ConfirmationDialogService,
      protected systemPreferenceService: CurrentSystemPreferencesService,
      protected currentUserService: CurrentUserService
   ) {
      super(errorMessageService, nDTService, confirmationDialogService, systemPreferenceService, currentUserService);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<string | null>' is not assignable... Remove this comment to see the full error message
      this.toolTip = this._toolTip.asObservable();
      this._toolTip.next("");
      if (!this.availablePhoneNumbers) {
         this.availablePhoneNumbers = new Array<string>();
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onClick() {
      if (this.availablePhoneNumbers.length > 1) {
         const actionTriggerModel = new ActionTriggerModel();
         actionTriggerModel.ExpressActionID = ExpressActions.RmVoip_Outbound_Call;
         this.addPayloads(actionTriggerModel);
         this.actionTriggerService.triggerActionWithModel(actionTriggerModel);
      } else if (this.availablePhoneNumbers.length === 1) {
         const outboundCallRequestModel = new OutboundCallRequestModel();
         outboundCallRequestModel.PhoneNumber = this.availablePhoneNumbers[0];
         outboundCallRequestModel.EntityKeyID = this.entityID;
         outboundCallRequestModel.EntityType = this.entityType;
         this.emitConfirmationDialogForPostingOutboundCall(outboundCallRequestModel);
      } else {
         this.errorMessageService.triggerErrorMessage("Phone number could not be found.");
      }
   }

   resolveSelectionChanged(userID: number) {
      this.availablePhoneNumbers = new Array<string>();
      if (!this.users) {
         this.getUsers(userID);
      } else {
         this.getTooltipAndDisplayStatus(userID);
      }
   }

   private getTooltipAndDisplayStatus(userID: number) {
      // since <Unassigned> is not an actual user we don't want to show the 'Click to Call' icon
      // @ts-ignore ts-migrate(2322) FIXME: Type 'UserModel | undefined' is not assignable to ... Remove this comment to see the full error message
      const user: UserModel = this.users.find((u) => u.UserID === userID && u.UserID !== 0);
      this.lastSelectedUserModel = user;
      this.entityType = EntityType.User;

      if (user) {
         this.entityID = user.UserID;
         const phoneNumberModel = user.PhoneNumbers
            ? user.PhoneNumbers.filter((pn) => pn.IsPrimary)
               ? user.PhoneNumbers.filter((pn) => pn.IsPrimary)[0]
               : null
            : null;

         let tooltip: string = "";

         if (user.IsPhoneSystemUser && phoneNumberModel && phoneNumberModel.PhoneNumber.length > 0) {
            this.resolveClickToCallDisplay();
            tooltip = "Call " + user.Name;
            this.availablePhoneNumbers.push(user.PhoneSystemExtension);
            this.availablePhoneNumbers.push(
               ClickToCallBaseService.formatPhoneNumber(phoneNumberModel.StrippedPhoneNumber)
            );
         } else if (user.IsPhoneSystemUser) {
            this.resolveClickToCallDisplay();
            tooltip = "Call " + user.Name + " on extension - " + user.PhoneSystemExtension;
            this.availablePhoneNumbers.push(user.PhoneSystemExtension);
         } else if (phoneNumberModel && phoneNumberModel.PhoneNumber.length > 0) {
            this.resolveClickToCallDisplay();
            tooltip =
               "Call " +
               user.Name +
               " phone on " +
               ClickToCallBaseService.formatPhoneNumber(phoneNumberModel.StrippedPhoneNumber);
            this.availablePhoneNumbers.push(
               ClickToCallBaseService.formatPhoneNumber(phoneNumberModel.StrippedPhoneNumber)
            );
         }
         this._toolTip.next(tooltip);
      }
   }

   private getUsers(userID: number) {
      this.userService
         .getSearchCollection(null, [UserEmbedOptions.PhoneNumbers])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((users) => {
            this.users = users;
            this.getTooltipAndDisplayStatus(userID);
         });
   }

   private addPayloads(actionTriggerModel: ActionTriggerModel) {
      actionTriggerModel.Payloads.push(
         new ActionTriggerPayloadModel(ExpressPayloadFields.ValueSource, this.availablePhoneNumbers)
      );
      actionTriggerModel.Payloads.push(
         new ActionTriggerPayloadModel(ExpressPayloadFields.Value, this.lastSelectedUserModel)
      );
      actionTriggerModel.Payloads.push(new ActionTriggerPayloadModel(ExpressPayloadFields.EntityType, EntityType.User));
   }
}
