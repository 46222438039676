import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Params, QueryParamsHandling, Router } from "@angular/router";
import { GlobalsService } from "@lcs/core/globals.service";
import { mapFromJson } from "@lcs/utils/map-utils";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { ExpressReplacementTags } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-replacement-tags.enum";
import { UserPreferences } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-preferences.enum";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";
import { UserPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-preference.model";
import { BehaviorSubject, filter, first, Observable, Subject, take, takeUntil } from "rxjs";

import { ActionInformationService } from "../action-context/action-information.service";
import { ActionTriggerPayloadModel } from "../action-trigger/action-trigger-payload.model";
import { ActionTriggerModel } from "../action-trigger/action-trigger.model";
import { globalVariables } from "../core/globals";
import { FilterPayloadService } from "../filters/filter-payload.service";
import { CurrentUserPreferencesService, OpenListDetailsInNewTabAreaKey, UserPreferenceOpenListDetailsInNewTabMap } from "../session/current-user-preferences.service";
import { ActionMappingModel } from "./action-mapping.model";
import { AreaActionMappingModel } from "./area-action-mapping.model";
import { RouteInformationModel } from "./route-information.model";
import { SkeletonType } from "./skeletons/skeleton-type.enum";
import { SkeletonService } from "./skeletons/skeleton.service";

/* eslint-disable prefer-const */
interface AreaIdAction {
   area: string;
   id: number | null;
   action: string;
}

export enum OpenInNewTabResult {
   No = 0,
   Yes = 1,
   Blocked = 2,
}

/**
 * 'keepOpen' means the source will be kept open,
 * 'close' means the source will be closed,
 * 'auto' means the default behavior will be used
 */
export type OpenInNewTabSourceAction = "keepOpen" | "close" | "auto";
export interface NewTabOpenedEvent {
   url: string;
   windowName: string;
   windowRef: Window;
   sourceAction?: OpenInNewTabSourceAction;
}

@Injectable({
   providedIn: "root",
})
export class RoutingService implements OnDestroy {
   /**
    * Payload Key that if specified and set to true or false will override any default logic
    * for determining whether full page action will launch in a new tab or not.
    */
   static readonly OpenInNewTabPayloadKey: string = "OpenInNewTab";

   /**
    * Payload Key that if specified allows overriding the default behavior of whether
    * the source component (example: slide-up) from which the new tab was launched is
    * closed or kept open.
    */
   static readonly OpenInNewTabSourceActionPayloadKey: string = "OpenInNewTabSourceAction";

   routeAction: string = "";

   routeArea: string = "";

   routeIDIsPresent: boolean;

   /**
    * Current Route Information
    */
   routeInformation: BehaviorSubject<RouteInformationModel | null> = new BehaviorSubject(null);

   currentParams: Params;

   queryParams: Params | null;

   allowRoutingChanged: Observable<void>;

   get allowRouting(): boolean {
      return this._allowRouting;
   }

   private _allowRoutingChanged = new Subject<void>();

   private _allowRouting: boolean = false;

   private userPreferenceOpenListDetailsInNewTabMap: UserPreferenceOpenListDetailsInNewTabMap;

   /** Set of lowercase actions that should open in a new tab if launched full page in a new area */
   private readonly actionsShouldOpenInNewTabSet: Set<string> = new Set<string>([
      "details",
      "add",
      "run",
      "runlettertemplatebulk",
      "broadcast",
   ]);

   newTabBlocked$: Observable<void>;
   newTabOpened$: Observable<NewTabOpenedEvent>;
   private newTabBlocked: Subject<void> = new Subject<void>();
   private newTabOpened: Subject<NewTabOpenedEvent> = new Subject<NewTabOpenedEvent>();

   private windowRef: Window | null;
   private unsubscribe = new Subject<void>();

   /**
    * Builds a string to be used as the name of the browser tab/window based
    * on the arguments related to the route and query params. This is also
    * used by the ActionRouterService to build the target when opening a new tab.
    */
   static buildWindowName(
      area: string | undefined,
      action: string | undefined,
      id?: number | null,
      queryParams?: Params | null
   ): string {
      if (!area) {
         return "";
      }
      action = action ? action : "X";
      let windowName: string = id == null ? `_RMX_${area}_${action}` : `_RMX_${area}_${id}_${action}`;
      windowName = windowName.toUpperCase();
      if (queryParams) {
         windowName = `${windowName}_${RoutingService.stringHashQueryParams(queryParams)}`;
      }
      return windowName;
   }

   /**
    * Compute a string hash code based on queryParams.
    *
    * Used when building window names to create unique names for routes with different queryParams
    */
   static stringHashQueryParams(queryParams: Params) {
      const queryParamsString: string = RoutingService.convertQueryParamsToQueryString(queryParams);
      const queryParamsHashString: string = GlobalsService.hashCodeString(queryParamsString);
      return queryParamsHashString;
   }

   /**
    * Converts queryParams into a URL query string that can be appended to
    * a route URL.
    */
   static convertQueryParamsToQueryString(queryParams: Params): string {
      try {
         const urlSearchParams: URLSearchParams = new URLSearchParams(queryParams);
         const queryParamsString: string = urlSearchParams.toString();
         return queryParamsString;
      } catch (error) {
         console.error(`RMX: Failed to convert queryParams to string. queryParams=`, queryParams);
         return "";
      }
   }

   constructor(
      private actionInformationService: ActionInformationService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private skeletonService: SkeletonService,
      private currentUserPreferencesService: CurrentUserPreferencesService,
      @Inject(DOCUMENT) private readonly document: Document
   ) {
      this.windowRef = this.document.defaultView;
      this.allowRoutingChanged = this._allowRoutingChanged.asObservable();
      this.newTabBlocked$ = this.newTabBlocked.asObservable();
      this.newTabOpened$ = this.newTabOpened.asObservable();

      this.currentUserPreferencesService.cachePopulated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((cachePopulated: boolean) => {
            if (cachePopulated) {
               const expressOpenListDetailsInNewTabUserPreferenceModel$: Observable<UserPreferenceModel> =
                  this.currentUserPreferencesService.getUserPreference(UserPreferences.ExpressOpenListDetailsInNewTab);
               expressOpenListDetailsInNewTabUserPreferenceModel$
                  .pipe(take(1))
                  .subscribe((upm: UserPreferenceModel) => {
                     this.userPreferenceOpenListDetailsInNewTabMap = mapFromJson<
                        OpenListDetailsInNewTabAreaKey,
                        boolean
                     >(upm?.Value ?? "");
                  });
            } else {
               this.userPreferenceOpenListDetailsInNewTabMap =
                  CurrentUserPreferencesService.defaultUserPreferenceOpenListDetailsInNewTabMap;
            }
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setAllowRouting(allowRouting: boolean) {
      if (this.allowRouting !== allowRouting) {
         this._allowRouting = allowRouting;
         this._allowRoutingChanged.next();
      }
   }

   routeToAction(
      action: ExpressActions,
      id?: number | null,
      queryParams?: Params | null,
      overrideOpenInNewTab: boolean | null = null,
      sourceAction: OpenInNewTabSourceAction = "auto"
   ): OpenInNewTabResult {
      const actionModel: ExpressActionModel | null =
         this.actionInformationService.getExpressActionInformationForExpressAction(action);
      if (!actionModel) {
         throw new Error("Unable to process route - unknown action");
      }
      if (id && actionModel.IsSingleEntityAction) {
         const route: Array<string | number> = [actionModel.Area, id, actionModel.ActionRoute];
         return this.routerNavigate(route, queryParams, overrideOpenInNewTab, sourceAction);
      } else {
         if (actionModel.IsSingleEntityAction && !actionModel.IsDefaultSingleEntityAction) {
            throw new Error("Missing ID for Single Action Entity");
         } else {
            const route = [actionModel.Area];
            if (actionModel.ActionRoute) {
               route.push(actionModel.ActionRoute);
            }
            return this.routerNavigate(route, queryParams, overrideOpenInNewTab, sourceAction);
         }
      }
   }

   routerNavigate(
      route: Array<string | number>,
      queryParams?: Params | null,
      overrideOpenInNewTab: boolean | null = null,
      sourceAction: OpenInNewTabSourceAction = "auto"
   ): OpenInNewTabResult {
      let currentAreaIdAction: AreaIdAction = this.getAreaIdActionFromUrl(this.router.url);
      const openInNewTabResult: OpenInNewTabResult = this.isAutoOpenInNewTab(
         this.routeArea ? this.routeArea : currentAreaIdAction.area,
         this.routeAction ? this.routeAction : currentAreaIdAction.action,
         route,
         queryParams ?? {},
         overrideOpenInNewTab,
         sourceAction
      );

      if (openInNewTabResult === OpenInNewTabResult.No) {
         this.router.navigate(route, { queryParams: queryParams });
      }

      return openInNewTabResult;
   }

   routeChanged(params: Params, queryParams: Params | null = null) {
      if (this.allowRouting === true) {
         this.handleRouteChanged(params, queryParams);
      } else {
         this.allowRoutingChanged
            .pipe(
               filter(() => this.allowRouting),
               first(),
               takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
               this.handleRouteChanged(params, queryParams);
            });
      }
   }

   resolveAction(currentRouteInformation: RouteInformationModel) {
      if (!this.routeArea) {
         return;
      }
      const areaMap: AreaActionMappingModel | null = this.actionInformationService.getAreaActionMappingForArea(
         this.routeArea
      );
      if (areaMap) {
         let routeAction = this.routeAction;
         if (!routeAction) {
            if (this.routeIDIsPresent) {
               routeAction = areaMap.DefaultSingleEntityAction;
            }
            if (!routeAction) {
               routeAction = areaMap.DefaultAction;
            }
         }
         this.routeAction = routeAction ?? "";
         const actionMap: ActionMappingModel = areaMap.Actions[this.routeAction.toLowerCase()];
         if (actionMap) {
            currentRouteInformation.ExpressAction = actionMap.Action;
            currentRouteInformation.ActionMap = actionMap;
         } else {
            this.redirectTo404Page();
         }
      } else {
         this.redirectTo404Page();
      }
   }

   updateRoutingInformation(currentRoute: RouteInformationModel) {
      let routeHasChanged = false;
      const previousRoute = this.routeInformation.value;

      if (
         previousRoute == null ||
         currentRoute == null ||
         previousRoute.ExpressAction == null ||
         currentRoute.ExpressAction == null ||
         previousRoute.ExpressAction.ExpressActionID !== currentRoute.ExpressAction.ExpressActionID
      ) {
         routeHasChanged = true;
      } else if (previousRoute.EntityID !== currentRoute.EntityID) {
         routeHasChanged = true;
      } else if (
         currentRoute.QueryParams != null &&
         previousRoute.QueryParams != null &&
         (Object.keys(currentRoute.QueryParams).length !== 0 || Object.keys(previousRoute.QueryParams).length !== 0) &&
         currentRoute.QueryParams !== previousRoute.QueryParams
      ) {
         routeHasChanged = true;
      }
      if (routeHasChanged) {
         // Update the WindowName each time the route changes
         this.updateWindowName(
            RoutingService.buildWindowName(
               currentRoute.ExpressAction?.Area,
               currentRoute.ExpressAction?.ActionRoute,
               currentRoute.EntityID,
               currentRoute.QueryParams
            )
         );
         this.routeInformation.next(currentRoute);
      } else {
         this.skeletonService.skeletonType.next(SkeletonType.None);
      }
   }

   /**
    * Sets the name of the current window
    */
   updateWindowName(windowName: string) {
      if (this.windowRef) {
         this.windowRef.name = windowName;
      }
   }

   resolveRoute(actionTriggerModel: ActionTriggerModel): Array<string | number> {
      const id = this.resolveID(actionTriggerModel);

      if (!actionTriggerModel.Action) {
         throw new Error("Unable to resolve route - unknown action");
      }

      const routerArray: Array<string | number> = [`/${actionTriggerModel.Action.Area}`];
      if (id != null) {
         routerArray.push(id);
      }

      if (actionTriggerModel.Action.ActionRoute && actionTriggerModel.Action.ActionRoute.length > 0) {
         routerArray.push(actionTriggerModel.Action.ActionRoute);
      }

      return routerArray;
   }

   resolveTriggerPayloadValues(actionTrigger: ActionTriggerModel) {
      if (actionTrigger.Payloads && actionTrigger.Payloads.length > 0) {
         actionTrigger.Payloads = this.resolvePayloadValues(actionTrigger.Payloads);
      }
   }

   resolvePayloadValues(payloads: Array<ActionTriggerPayloadModel>): Array<ActionTriggerPayloadModel> {
      const resolvedPayloads = payloads.map((payload: ActionTriggerPayloadModel) => {
         if (
            [ExpressPayloadFields.Filters, ExpressPayloadFields.QuickFilters].some((p) => p === payload.PayloadField) &&
            typeof payload.Value !== "string"
         ) {
            payload.Value = FilterPayloadService.buildFilterQueryString(payload.Value);
         } else if (payload.PayloadKey === "ShowRMUCRegistrationLink") {
            //does nothing if ShowRMUCRegistrationLink is in payload
         } else {
            payload.Value = this.replaceRoutingTagsInUnknownType(payload.Value);
         }
         return payload;
      });
      return resolvedPayloads;
   }

   resolveID(actionBarTrigger: ActionTriggerModel): number | null {
      let id: string | unknown | null = null;
      for (const payload of actionBarTrigger.Payloads) {
         if (payload.PayloadField) {
            let payloadField = payload.PayloadField;
            if (isNaN(+payloadField)) {
               payloadField = ExpressPayloadFields[payloadField.toString()];
            }
            if ([ExpressPayloadFields.ID].some((f) => f === payloadField)) {
               id = this.replaceRoutingTagsInUnknownType(payload.Value);
            }
         }
      }
      if (typeof id === "string" || typeof id === "number") {
         return +id;
      }
      return null;
   }

   resolveQueryParams(actionBarTrigger: ActionTriggerModel): Params {
      const queryParams: Params = {};
      for (const payload of actionBarTrigger.Payloads?.filter(
         (p: ActionTriggerPayloadModel) =>
            p.PayloadKey !== RoutingService.OpenInNewTabPayloadKey &&
            p.PayloadKey !== RoutingService.OpenInNewTabSourceActionPayloadKey
      )) {
         if (payload.PayloadKey) {
            if (typeof payload.Value === "object") {
               queryParams[payload.PayloadKey] = window.btoa(JSON.stringify(payload.Value));
            } else {
               queryParams[payload.PayloadKey] = payload.Value;
            }
         } else {
            let payloadField = payload.PayloadField;
            if (isNaN(+payloadField)) {
               payloadField = ExpressPayloadFields[payloadField.toString()];
            }
            if ([ExpressPayloadFields.ID].some((p) => p === payloadField)) {
               continue;
            }
            queryParams[ExpressPayloadFields[payloadField]] = payload.Value;
         }
      }
      if (actionBarTrigger.Favorite) {
         queryParams["Favorite"] = actionBarTrigger.Favorite;
      }
      return queryParams;
   }

   updateQueryParams(queryParams: Params, queryParamsHandling: QueryParamsHandling = "merge") {
      this.router.navigate([], {
         relativeTo: this.activatedRoute,
         queryParams: queryParams,
         queryParamsHandling: queryParamsHandling,
      });
   }

   getCurrentRouteSnapshot(): ActivatedRouteSnapshot {
      return this.activatedRoute.snapshot;
   }

   public redirectToLoginPageWithReturnURL(returnURL: string) {
      this.router.navigate(["/login"], {
         queryParams: { returnUrl: returnURL },
      });
      return false;
   }

   public redirectToLoginPage(excludeReturnUrl?: boolean) {
      let returnUrl = "";
      if (!excludeReturnUrl) {
         returnUrl = this.getReturnUrl();
      }
      this.router.navigate(["/login"], {
         queryParams: { returnUrl: returnUrl },
      });
      return false;
   }

   public reloadDefaultPage() {
      if (this.windowRef) {
         const rootUrl = this.windowRef.location.protocol + "//" + this.windowRef.location.host;
         console.log(rootUrl);
         this.windowRef.location.assign(rootUrl);
      } else {
         console.warn(`RMX: reloadDefaultPage() failed. window unavailable.`);
      }
   }

   public redirectToDefaultPage(returnURL?: string) {
      if (returnURL && returnURL !== "/login") {
         this.router.navigateByUrl(returnURL);
      } else {
         this.router.navigate([""]);
      }
   }

   public redirectTo404Page(returnURL?: string) {
      if (returnURL && returnURL !== "/login") {
         this.router.navigateByUrl(returnURL);
      } else {
         this.router.navigate(["404"]);
      }
   }

   public getReturnUrl(): string {
      let returnURL = this.router.routerState.snapshot.root.queryParamMap.get("returnUrl");
      if (!returnURL) {
         returnURL = this.router.routerState.snapshot.url;
      }
      if (returnURL.indexOf("/login") === 0) {
         return "";
      }
      return returnURL;
   }

   private replaceRoutingTagsInUnknownType(s: unknown): string | unknown {
      if (typeof s === "string") {
         return this.replaceRoutingTagsInString(s);
      }
      return s;
   }

   private replaceRoutingTagsInString(s: string): string {
      if (!this.routeInformation.value) {
         throw new Error("");
      }
      if (this.routeInformation.value.EntityID) {
         s = s.replace(
            `{${ExpressReplacementTags[ExpressReplacementTags.ID]}}`,
            this.routeInformation.value.EntityID.toString()
         );
      }
      if (this.routeInformation.value.ExpressAction && this.routeInformation.value.ExpressAction.EntityType) {
         s = s.replace(
            `{${ExpressReplacementTags[ExpressReplacementTags.EntityType]}}`,
            this.routeInformation.value.ExpressAction.EntityType.toString()
         );
      }
      return s;
   }

   private handleRouteChanged(params: Params, queryParams: Params | null) {
      this.currentParams = params;
      this.queryParams = queryParams;
      let actionChanged = false;
      const currentRouteInformation = new RouteInformationModel();

      if (this.routeArea !== params["area"]) {
         this.routeArea = params["area"] ?? "";
         actionChanged = true;
      }

      let actionFromRoute = "";

      if (params["action"]) {
         actionFromRoute = params["action"];
      }
      this.routeIDIsPresent = false;
      let entityID: number | null = null;
      if (params["id"]) {
         if (isNaN(+params["id"])) {
            actionFromRoute = params["id"];
         } else {
            this.routeIDIsPresent = true;
            entityID = +params["id"];
         }
      }

      if (this.routeAction !== actionFromRoute) {
         this.routeAction = actionFromRoute;
         actionChanged = true;
      }

      if (actionChanged) {
         this.resolveAction(currentRouteInformation);
      } else {
         if (this.routeInformation.value) {
            currentRouteInformation.ExpressAction = this.routeInformation.value.ExpressAction;
            currentRouteInformation.ActionMap = this.routeInformation.value.ActionMap;
         }
      }

      if (!this.routeIDIsPresent) {
         currentRouteInformation.EntityID = null;
      } else {
         currentRouteInformation.EntityID = entityID;
      }

      currentRouteInformation.QueryParams = this.queryParams;
      this.updateRoutingInformation(currentRouteInformation);
   }

   /**
    * This method determines whether a new full page action should automatically be loaded into
    * a new tab based on user preferences and/or an override parameter, whether or not it is
    * a mobile browser, the current route and the next route.
    *
    * NOTE: This method is ONLY called when routing actions via an action trigger. It is not
    *       triggered for links where the user can decide whether they want to open them in
    *       a new tab/window by using the standard browser mechanism of ctrl+click (meta+click on macOS)
    *       or via the right-click context menu.
    *
    * @param currentArea
    *   Current area string.  Examples: tenants, prospects, etc.
    *
    * @param currentAction
    *   Current action string.  Examples: list, details, add, run
    *
    * @param nextRoute
    *   Array of items for next route to be opened possibly in a new tab
    *
    *   Does not include any query parameters which are passed via queryParams argument
    * @param queryParams
    *   Contains things like filters.  Needed to enable next/prev item navigation
    *   in new tab based on filters at time item is opened
    *
    * @param overrideOpenInNewTab
    *   if specified (e.g. true or false) then it overrides all other logic
    *
    *   if not specified (e.g. null) then it has no effect
    *
    *   Primarily used allow ctrl+click/meta+click override to be passed via payload on row itemClick
    *   to force opening item in a new tab
    *
    * @returns
    *   OpenInNewTabResult.Yes - if route was opened in a new tab
    *
    *   OpenInNewTabResult.Blocked - if route was attempted to be opened in a new tab, but was blocked
    *
    *   OpenInNewTabResult.No - if route should be opened in the same tab
    */
   private isAutoOpenInNewTab(
      currentArea: string,
      currentAction: string,
      nextRoute: Array<string | number>,
      queryParams: Params,
      overrideOpenInNewTab: boolean | null,
      sourceAction: OpenInNewTabSourceAction = "auto"
   ): OpenInNewTabResult {
      let openInNewTabResult: OpenInNewTabResult = OpenInNewTabResult.No;
      if (globalVariables.isMobile) {
         return openInNewTabResult;
      }
      if (!this.windowRef) {
         console.warn(`RMX: isAutoOpenInNewTab() returned ${openInNewTabResult}. window object is unavailable.`);
         return openInNewTabResult;
      }
      try {
         const nextRouteUrl: string = nextRoute?.join("/") ?? ""; // ?? for safety;
         currentArea = currentArea?.toLowerCase() ?? ""; // ?? for safety
         currentAction = currentAction?.toLowerCase() ?? ""; // ?? for safety

         if (nextRouteUrl) {
            const nextAreaIdAction: AreaIdAction = this.getAreaIdActionFromUrl(nextRouteUrl);

            if (nextAreaIdAction.area.length > 0) {
               let userPrefIsOpenInNewTab: boolean = false;
               let notControlledByUserPref: boolean = false;

               if (this.userPreferenceOpenListDetailsInNewTabMap?.get) {
                  if (
                     this.userPreferenceOpenListDetailsInNewTabMap.has(currentArea as OpenListDetailsInNewTabAreaKey)
                  ) {
                     userPrefIsOpenInNewTab =
                        this.userPreferenceOpenListDetailsInNewTabMap.get(
                           currentArea as OpenListDetailsInNewTabAreaKey
                        ) ?? false;
                  } else {
                     notControlledByUserPref = true;
                  }
               }

               if (
                  this.shouldOpenInNewTab(
                     overrideOpenInNewTab,
                     globalVariables.isMobile,
                     currentArea,
                     currentAction,
                     nextAreaIdAction.area,
                     nextAreaIdAction.action,
                     queryParams,
                     userPrefIsOpenInNewTab,
                     notControlledByUserPref
                  )
               ) {
                  const queryParamString: string = RoutingService.convertQueryParamsToQueryString(queryParams);

                  // We set the targetWindowName based on the route so that if the user opens an item in a new tab
                  // then returns to the list and opens the same item again we just open/focus on the already
                  // opened tab which is much faster and avoids reloading the application and data to display
                  // the new tab. It also minimizes having multiple duplicate tabs open.
                  // NOTE: you can have 2 pages open to the same entity if they have different queryParams
                  //       like filters which affect the next/prev entity navigation.
                  const targetWindowName: string = RoutingService.buildWindowName(
                     nextAreaIdAction.area,
                     nextAreaIdAction.action,
                     nextAreaIdAction.id,
                     queryParams
                  );

                  const openUrl: string = `/#${nextRouteUrl}${queryParamString ? `?${queryParamString}` : ""}`;

                  const newWindowRef: Window | null = this.windowRef.open(openUrl, targetWindowName);
                  if (!newWindowRef) {
                     console.warn(`RMX: Unable to open url="${openUrl}" in new tab.`); // console warning left intentionally
                     openInNewTabResult = OpenInNewTabResult.Blocked;
                     this.newTabBlocked.next();
                  } else {
                     openInNewTabResult = OpenInNewTabResult.Yes;
                     this.newTabOpened.next({
                        url: openUrl,
                        windowName: targetWindowName,
                        windowRef: newWindowRef,
                        sourceAction: sourceAction,
                     });
                  }
               }
            }
         }
      } catch (error: unknown) {
         // in the event of an error, catch and log to console and just return false to avoid
         // causing things to break in the event of an error. They will just open in same tab.
         console.error(`isAutoOpenInNewTab(...) failed -`, error);
      }
      return openInNewTabResult;
   }

   /**
    * Parse url string and return an object containing area, id and action properties,
    * which default to "", null, "" respectively if not found
    */
   private getAreaIdActionFromUrl(url: string): AreaIdAction {
      let matchArray: RegExpMatchArray | null = url.toLowerCase().match(/^\/?(\w+)(?:\/(\d+))?(?:\/(\w+)?)?/i);
      if (matchArray && matchArray.length >= 4) {
         const area: string = matchArray[1];
         const id: number | null = matchArray[2] != null ? +matchArray[2] : null;
         const action: string = matchArray[3];
         return { area, id, action };
      } else {
         return { area: "", id: null, action: "" };
      }
   }

   /**
    * Method encapsulates the parameters and logic that determines if action
    * should open in a new tab or not.
    *
    * @returns
    *  true - if should open in a new tab
    *
    *  false - if should open in the same tab
    */
   private shouldOpenInNewTab(
      overrideOpenInNewTab: boolean | null,
      isMobile: boolean,
      currentArea: string,
      currentAction: string,
      nextArea: string,
      nextAction: string,
      nextQueryParams: Params,
      userPrefIsOpenInNewTab: boolean,
      notControlledByUserPref: boolean
   ): boolean {
      if (overrideOpenInNewTab != null) {
         // if explicitly set to true or false, then return overriding all other logic
         return overrideOpenInNewTab;
      }

      if (nextQueryParams && nextQueryParams.OpenInNewTab != null) {
         return nextQueryParams.OpenInNewTab;
      }

      const reIDKey: RegExp = /^\w*id$/i; // Case insensitive match strings like: id, AccountID, EntityID, etc.
      const reFilterIdEq: RegExp = /\b\w*id,eq,\d+\b/i; // Case insensitive match filter expression strings like: AccountID,eq,6385
      const reFilterOrQuickFilterKey: RegExp = /Filters|QuickFilters/i; // Case Insensitive

      let nextActionIsListFilteredToSpecificID: boolean = false;

      if (nextAction && nextAction.toLowerCase() === "list") {
         /**
          * Do the queryParams contain ID parameter.
          *
          * Ex. occurs for tenant row action "Show All Estimates" => {EntityType: 1, EntityID: 63855}
          */
         const hasIDParam: boolean = Object.keys(nextQueryParams).find((k): boolean => reIDKey.test(k)) ? true : false;

         /**
          * Do the queryParams contain a filter or quickFilter for a specific ID.
          *
          * Ex. occurs for tenant row actions like
          *   "View Screenings" => { QuickFilters: "AccountID,eq,6385" }
          *   "Show All Invoices" => { Filters: "AccountID,eq,6385" }
          *
          */
         const hasIDEqFilterOrQuickFilter: boolean = Object.entries(nextQueryParams).find(
            (kvEntry: [string, any]): boolean =>
               reFilterOrQuickFilterKey.test(kvEntry[0]) && reFilterIdEq.test(kvEntry[1])
         )
            ? true
            : false;

         nextActionIsListFilteredToSpecificID = hasIDParam || hasIDEqFilterOrQuickFilter;
      }

      const shouldOpenInNewTab: boolean =
         !isMobile &&
         currentArea !== "" && // prevents trying to load a new tab on app startup if there is no target url and the user has a default specified in personal prefs
         (this.actionsShouldOpenInNewTabSet.has(nextAction) || nextActionIsListFilteredToSpecificID) &&
         ((currentAction.toLowerCase() === "list" && (userPrefIsOpenInNewTab || notControlledByUserPref)) ||
            nextArea.toLowerCase() !== currentArea.toLowerCase());
      return shouldOpenInNewTab;
   }
}
