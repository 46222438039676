<lcs-single-line-multi-select
   [disabled]="disabled"
   [(ngModel)]="value"
   [inlineLabel]="inlineLabel"
   [hideLabel]="hideLabel"
   [valueSource]="valueSource"
   [name]="name"
   (panelClose)="onPanelClosed()"
   (selectionChange)="onSelectionChanged($event)"
></lcs-single-line-multi-select>
