import { FilterValueType } from "../../filters/filter-value.types";
import { DateRangeModel } from "../../inputs/date-range-picker/date-range.model";
import { NumberRangeModel } from "../../inputs/number-range-input/number-range.model";
import { EntityLinkModel } from "./entity-link-selector-filter/entity-link-selector/entity-link.model";

export type FilterInputValueType = FilterValueType | DateRangeModel | NumberRangeModel | EntityLinkModel | number;

export const isFilterInputValueDateOrString = (value: FilterInputValueType): value is Date | string => {
   return typeof value === "string" || value instanceof Date;
};

export const isFilterInputValueDateRange = (value: FilterInputValueType): value is DateRangeModel => {
   return value instanceof DateRangeModel;
};

export const isFilterInputValueNumberRange = (value: FilterInputValueType): value is NumberRangeModel => {
   return value instanceof NumberRangeModel;
};

export const isFilteInputValueEntityLink = (value: FilterInputValueType): value is EntityLinkModel => {
   return value instanceof EntityLinkModel;
};
