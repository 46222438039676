////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ReportUserControlFrame {
   AsOfDate = 1,
   DateRange = 2,
   BatchPropsOrUnits = 3,
   Basis = 4,
   PropOwnerIDs = 5,
   PropertyIDs = 6,
   OwnerIDs = 7,
   IsSummary = 8,
   CustomerStatus = 9,
   ZeroAmountExclusions = 10,
   CombineReceivableTypes = 11,
   GLAccounts = 12,
   CheckboxFrame = 13,
   ReconcileSelector = 14,
   ChargesToInclude = 15,
   CustomerValuesToIncludeText = 16,
   DelinquentDaysToInclude = 17,
   HistTypeIDs = 18,
   HistCategoryIDs = 19,
   Regarding = 20,
   UserIDs = 21,
   ProspectStatus = 22,
   Comment = 23,
   CustIDs = 24,
   DepositIDs = 25,
   StatusCP = 26,
   CustomersToInclude = 27,
   IncludeProspects = 28,
   UnitIDs = 29,
   Amenities = 30,
   DateRestrictionOn = 31,
   BatchPropsExclude = 32,
   BankBalanceSummary = 33,
   TextBoxFrame = 34,
   CurrencyBoxFrame = 35,
   OwnerStatementExclusions = 36,
   SearchDescription = 37,
   AuditType = 38,
   CheckedDateRange = 39,
   ComboBoxFrame = 40,
   EpayBatchesToInclude = 41,
   IssueListsToInclude = 42,
   PostingPeriod = 43,
   ChargeTypeOrder = 44,
   CCReconcileSelector = 45,
   LeasingAgentIDs = 46,
   AccountTypesToInclude = 47,
   HistNotesFilter = 48,
   OpenClosedIssues = 49,
   IssueLinks = 50,
   HideAccUUTColumns = 51,
   OwnerStatementShowDetails = 52,
   CreditsToInclude = 53,
   MRProration = 54,
   ReportMethod = 55,
   DayOfPeriod = 56,
   HiddenPassThrough = 57,
   ProspectDateRangesToUse = 58,
   BillsToInclude = 59,
   Vendor = 60,
   BudgetType = 61,
   Deposits = 62,
   Banks = 63,
   ValuesToInclude = 64,
   UtilIDs = 65,
   PageBreak = 66,
   BeforePosting = 67,
   MeterIDs = 68,
   SelectConsumptionAndCharge = 69,
   TransactionsToInclude = 70,
   StartingLabel = 71,
   LabelType = 72,
   AddressType = 73,
   VendorIDs = 74,
   AssetTypeIDs = 75,
   AssetManufacturerIDs = 76,
   AssetUnassignedProps = 77,
   AssetUnassignedVendors = 78,
   AssetProfileIncludeSections = 79,
   AssetIDsToInclude = 80,
   YearRange = 81,
   PropUnit = 82,
   TenantStatementInclusions = 83,
   TenantStatementUtilityOptions = 84,
   DateSection = 85,
   ChartMapping = 86,
   InformationToInclude = 87,
   SalesCustsInclude = 88,
   ImageSizeSelector = 89,
   CAMChargeType = 90,
   SelectCustomerLease = 91,
   EXCLUDE1099OPTIONS = 92,
   SecurityDepositTypesToInclude = 93,
   VendorTransToInclude = 94,
   AmountType = 95,
   ConsolidateProperties = 96,
   VendorNameSource = 97,
   PayeeAccountTypesToInclude = 98,
   BatchOwnersExclude = 99,
   CustomerProfileSort = 100,
   CustomerProfileShowOnReport = 101,
   BasisToInclude = 102,
   BankAccount = 105,
   Year = 106,
   CRPIncome = 107,
   DataSource = 108,
   SignatureDate = 109,
   SortOptions = 110,
   PropInfoSource = 111,
   ServiceOrderShowPriceCost = 112,
   ClearedOnly = 113,
   CheckDepositTransToInclude = 114,
   OwnerStatus = 115,
   LoanAddressToUse = 116,
   PostCardOverflowOption = 117,
   ColumnsToInclude = 118,
   LenderAddressToInclude = 119,
   EagleRockRentRollOptionalColumns = 120,
   DateRestrictionOnChecked = 121,
   CAMDivisionMethod = 122,
   ProjectsToInclude = 123,
   ProjectStatus = 124,
   OwnerAvailableCashOptionalColumns = 125,
   PictureQualitySelector = 126,
   PlacementSectionsToInclude = 127,
   MagnumReconcileSelector = 128,
   NumberOfRecords = 129,
   CustLinkCustomerUnit = 130,
   BirthdaysToInclude = 131,
   SBPDetailedCashFlowExclusions = 132,
   DepositsAndJournals = 133,
   FilteredReportGLAccounts = 134,
   JobID = 135,
   JobIDs = 136,
   OwnerProspectStatus = 137,
   PropMultiUnit = 138,
   SignatureFilePath = 139,
   CityAndState = 140,
   ApprovedStatus = 141,
   BillTerms = 142,
   BillPaymentMethods = 143,
   RentEscalationWithFutureOnlyOption = 144,
   InventoryItemIDs = 145,
   HidePartsLaborBilledColumns = 146,
   StateIDs = 147,
   PayableAccounts = 148,
   BatchPropsOrCustomers = 149,
   UnitTypesToInclude = 150,
   AssociatedPhoneNumber = 151,
   DaysOfWeek = 152,
   NDTCallsToInclude = 153,
   NumericBoxFrame = 154,
   Extensions = 155,
   CAMEscrowStatement = 156,
   NDTBarGroup = 157,
   NumberOfCallers = 158,
   NDTStartEndHour = 159,
   BoxScoreLeaseSigned = 160,
   ContactType = 161,
   OwnerProspectBoxScoreConversions = 162,
   DateRangeWithModes = 163,
   ForecastModel = 164,
   BudgetYears = 165,
   PeriodRange = 166,
   NotesOptions = 167,
   IncludeExpectedMoveInMoveOutDates = 168,
   IncludeBudgetComments = 169,
   BatchPropsOrJobs = 170,
   ExceedingAPercentOfBudgetedAmount = 172,
   BatchJobs = 173,
   ViolationsToInclude = 174,
   ViolationDateRestrictionOn = 175,
   ViolationNotesOption = 176,
   AsOfDateOrPeriod = 178,
   DateOrPeriodRange = 179,
   DateRestrictionOnWithBillPayments = 180,
   ReportPeriodType = 181,
   IncludeJobBudgetComments = 182,
   UserDefinedFieldIDs = 183,
   CheckboxAutoBindFrame = 184,
   PrioritiesToInclude = 185,
   AssetStatusesToInclude = 186,
   ProjectID = 187,
   CategoriesToInclude = 188,
   StatusesToInclude = 189,
   ProfitLossIncomePercentage = 190,
   IncludeGraph = 191,
   ProspectStageIDs = 192,
   AssociatedCategories1099 = 193,
   PreparerInformation = 194,
   ColorTypeChooser = 195,
   RentEscalations = 196,
   OwnerNameSource = 197,
   ItemsClassificationsToInclude = 198,
   ProspectsToInclude = 199,
   PollIDs = 200,
   PollResponseIDs = 201,
   AmountSelections = 202,
   ChecklistItems = 203,
   IncludePastDays = 204,
   OwnerActivityExclusions = 205,
   OwnerActivityShowDetailed = 206,
   LeadSourcesToInclude = 207,
   ArrivalDeparturesToInclude = 208,
   OwnerBasicStatementExclusions = 209,
   PeriodOptions = 210,
   PropertySelectionBy = 211,
   ReservationRequestStatuses = 213,
   StatementMethod = 214,
   None = -1,
}
