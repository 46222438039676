import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { OverlayPanelsModule } from "@lcs/overlay-panel/overlay-panels.module";

import { SelectorOverlayPanelComponent } from "./selector-overlay-panel.component";

@NgModule({
   imports: [CommonModule, OverlayPanelsModule, ValidationModule],
   declarations: [SelectorOverlayPanelComponent],
   exports: [SelectorOverlayPanelComponent],
})
export class SelectorOverlayPanelModule {}
