import { Component, Input } from "@angular/core";
import { EstimateDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-detail-view.model";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";

@Component({
   selector: "owa-estimate-detail-list",
   templateUrl: "./detail-list.component.html",
})
export class EstimateDetailListComponent {
   estimateDetailViewItems: Array<EstimateDetailViewModel>;

   _estimateItems: EstimateViewModel;

   @Input() totalCount: number;

   @Input() set estimateDetailItems(estimates: EstimateViewModel) {
      this._estimateItems = estimates;
      if (estimates != null) {
         this.estimateDetailViewItems = estimates.EstimateDetails;
      } else {
         this.estimateDetailViewItems = new Array<EstimateDetailViewModel>();
         this.estimateDetailViewItems.push(new EstimateDetailViewModel());
      }
      this.estimateDetailViewItems.forEach((detail) => {
         let markUpValue,
            taxValue: number = 0;
         if (detail.IsMarkUpPercentage) {
            markUpValue = detail.Rate * (detail.MarkUp / 100);
         } else {
            markUpValue = detail.MarkUp;
         }
         detail.Price = detail.Rate + markUpValue;

         if (this._estimateItems && this._estimateItems.IsTaxable ) {
            taxValue = detail.Price * (this._estimateItems.TaxRate / 100);
            detail.Price += taxValue;
         }

         detail.TotalValue = detail.Price * detail.Quantity;
      });
   }
   get estimateDetailItems(): EstimateViewModel {
      return this._estimateItems;
   }

   estimateDetailsColumns: string[] = ["Property", "Unit", "Item", "Description", "Quantity", "Price", "Total"];

   constructor() {}
}
