import { DecimalPipe } from "@angular/common";
import {
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnInit,
   Output,
   ViewChild,
} from "@angular/core";
import { NgControl, UntypedFormControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { RmNumberPipe } from "@lcs/pipes/rm-number-pipe";
import { MaskitoOptions } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

@Component({
   selector: "lcs-numeric-input",
   templateUrl: "numeric-input.component.html",
   providers: [DecimalPipe],
})
export class NumericInputComponent extends ValueAccessorBase<number | null | ""> implements OnInit {
   @ViewChild("input", { static: true }) input: ElementRef;

   @Input() placeholder: string = "";

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsValidate") validation: ValidationModel;

   @Input() set formatString(val: string) {
      if (val) {
         this._formatString = val;
         this.createMask();
      }
   }

   @Input() isCVV: boolean = false;

   @Input() isShowErrorIcon: boolean = true;

   @Input() integerLimit: number = 15;

   @Input() allowNegative: boolean = false;

   @Input() showSeparator: boolean = true;

   @Input() tooltipOverride: string;

   @Input() returnMouseEventOnFocusEvent: boolean = false;

   @Input() hasCounters: boolean = false;

   @Input() counterIncrement: number = 1;

   @Input() rightAlignInput: boolean = false;

   @Output() blurEvent = new EventEmitter<any>();

   @Output() enterKeyEvent = new EventEmitter<any>();

   @Output() focusEvent = new EventEmitter<any>();

   @Output() valueChange = new EventEmitter<string>();

   @Output() mouseEventOnFocus = new EventEmitter<MouseEvent>();

   get formatString(): string {
      return this._formatString;
   }

   inputFocused: boolean;

   control: UntypedFormControl;

   mask: MaskitoOptions;

   formattedValue: string = "";

   private rmNumberPipe = new RmNumberPipe();

   private mouseEvent: MouseEvent;

   private _formatString: string = "";

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);

      this.registerOnValueWritten((value) => {
         this.formatValue();
      });
   }

   ngOnInit() {
      this.createMask();
      return super.ngOnInit();
   }

   onClick(event: MouseEvent) {
      this.mouseEvent = event;
   }

   onFocus(event: FocusEvent) {
      this.input.nativeElement.select();
      this.inputFocused = true;
      if (this.returnMouseEventOnFocusEvent) {
         this.mouseEventOnFocus.emit(this.mouseEvent);
      } else {
         this.focusEvent.emit(event);
      }
   }

   onBlur(event: FocusEvent) {
      if (!this.isCVV) {
         this.formatInput();
      }

      this.inputFocused = false;
      this.blurEvent.emit(event);
      this.propagateTouched();
   }

   onEnterKeyDown(event: Event) {
      if (!this.isCVV) {
         this.formatInput();
      }
   }

   onEnterKey(event: any) {
      this.enterKeyEvent.emit(event as KeyboardEvent);
   }

   onValueChanged(value: any): void {
      // Ensure when valueChange is emitted that the value of the underlying unformatted form value matches the
      // value of the formatted emitted value
      // NOTE: it also seems like the unformatted value, not the formatted value, should be emitted
      //       which would be consistent with the values emitted by onIncrease and onDecrease, and also
      //       by the reactive form control valueChanges event however until there is sufficient time
      //       for regression testing this should not be changed.
      if (!this.isCVV) {
         if (typeof value === "string") {
            const unformattedValue: string = value.replace(/[^0-9-.]/g, "");
            this.value = unformattedValue === "" ? null : +unformattedValue;
         } else {
            this.value = value;
         }
         this.valueChange.emit(value);
      } else {
         this.value = value;
         this.valueChange.emit(value);
      }
   }

   onIncrease() {
      if (this.innerValue !== null) {
         const changedValue: number = +this.innerValue + this.counterIncrement;
         this.value = this.innerValue = changedValue;
         this.formatValue();
         this.propagateChanged();
         this.valueChange.emit(this.innerValue.toString());
      }
   }

   onDecrease() {
      if (this.innerValue !== null) {
         let changedValue: number = +this.innerValue - this.counterIncrement;
         if (!this.allowNegative && changedValue < 0) {
            changedValue = 0;
         }
         this.value = this.innerValue = changedValue;
         this.formatValue();
         this.propagateChanged();
         this.valueChange.emit(this.innerValue.toString());
      }
   }

   private formatValue() {
      if (this.value == null || this.value === "" || isNaN(+this.value)) {
         this.formattedValue = "";
         return;
      }
      if (this.formatString && this.formatString.length > 0) {
         this.formattedValue = this.rmNumberPipe.transform(this.value, this.formatString);
      } else {
         this.formattedValue = this.value.toString();
      }
      return;
   }

   private formatInput() {
      let tempValue: string = this.formattedValue;
      if (tempValue && tempValue.indexOf(",") >= 0) {
         tempValue = tempValue.replace(/,/g, "");
      }
      if (tempValue == null || tempValue.length === 0 || isNaN(+tempValue)) {
         this.formattedValue = "";
         if (this.value !== "") {
            // if value is empty string, do not set to null which would create a change triggering an unnecessary required if modified validation error
            // when for example tabbing over a blank numeric UDF field that is required if modified.
            this.value = null;
         }
         return;
      }

      if (this.value !== tempValue) {
         this.value = +tempValue;
      }

      if (this.formatString && this.formatString.length > 0) {
         this.formattedValue = this.rmNumberPipe.transform(+this.value, this.formatString);
      }
   }

   private createMask() {
      let decimalLimit = 0;
      let integerLimit = this.integerLimit;
      let hasLeadingZeros = false;
      let decimalZeroPaddingEnabled = false;

      if (this.isCVV) {
         integerLimit = 4;
         hasLeadingZeros = true;
      }

      if (hasLeadingZeros) {
         this.mask = {
            mask: new Array(integerLimit).fill(/\d/),
         };
      } else {
         if (this.formatString && this.formatString.length > 0) {
            const sections = this.formatString.split(".");
            if (sections.length === 2) {
               const decimalSections = sections[1].split("-");
               if (decimalSections.length === 2) {
                  decimalLimit = +decimalSections[1];
                  if (decimalLimit > 0) {
                     decimalZeroPaddingEnabled = true;
                  }
               }
            }
         }

         this.mask = maskitoNumberOptionsGenerator({
            prefix: "",
            postfix: "",
            decimalSeparator: ".",
            decimalZeroPadding: decimalZeroPaddingEnabled,
            minusSign: "-",
            max: integerLimit,
            thousandSeparator: this.showSeparator ? "," : "",
            precision: decimalLimit,
         });
      }
   }
}
