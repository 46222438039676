import { SelectorItemModel } from "@lcs/selectors/selector-item.model";

export class SelectionChangeModel {
   checkedItems: Array<SelectorItemModel>;
   items: Array<SelectorItemModel>;
   selectAll: boolean;
   constructor() {
      this.checkedItems = new Array<SelectorItemModel>();
      this.items = new Array<SelectorItemModel>();
   }
}
