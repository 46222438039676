<div
   class="selector-container"
   (window:resize)="onResize()"
>
   <div [class.inline-selector]="inlineLabel">
      <label
         class="single-line-multi-select-label"
         *ngIf="!hideLabel && valueSource?.Label"
      >
         {{ valueSource?.Label }}
      </label>
      <lcs-loading-overlay
         [show]="$any(isLoading | async)"
         [diameter]="30"
      >
         <div
            #singleLineMultiSelectorWrapper
            [attr.id]="path"
            class="single-line-multi-selector-wrapper select"
            [class.selector-disabled]="disabled"
            [class.selector-error]="
               control?.errors && !disabled && !control?.valid && (control?.touched || control?.dirty)
            "
            [class.open]="showOverlayPanel"
            (focus)="gainFocus()"
            (blur)="lostFocus()"
            [attr.tabindex]="disabled ? null : 0"
         >
            <div class="selector-content">
               <div
                  class="selector-content-label"
                  *ngIf="!selectorInputTemplate"
                  title={{titleValue}}
               >
                  {{ displayValue }}
               </div>
               <ng-container *ngTemplateOutlet="selectorInputTemplate; context: { $implicit: this }"></ng-container>
               <div
                  class="selector-icons"
                  [hidden]="disabled"
               >
                  <lcs-icon
                     [hidden]="control && !control?.valid && (control?.touched || control?.dirty)"
                     [icon]="showOverlayPanel ? openIcon : closedIcon"
                     (click)="onIconClicked()"
                  ></lcs-icon>
                  <i
                     [hidden]="control?.valid || (!control?.touched && !control?.dirty)"
                     class="material-icons validation-error-icon"
                  >error_outline</i>
               </div>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
   <lcs-multi-selector-overlay-panel
      [show]="showOverlayPanel"
      [items]="items"
      [selectedItemCount]="selectedItemCount"
      [isAllSelected]="isAllSelected"
      [isShowLabel]="isShowLabel"
      [label]="footerLabelAdditional"
      (visibilityChanged)="onVisibilityChanged($event)"
      [control]="control"
      [displayName]="displayName"
      [selectorOptionTemplate]="selectorOptionTemplate"
      [isLoading]="$any(isLoading | async)"
      [parentElement]="singleLineMultiSelectorWrapper"
      [innerElementTags]="['div']"
      [innerElementSelector]="'.single-line-multi-selector-wrapper'"
      [disabled]="disabled"
      [disableAllItems]="disableAllItems"
      [alignToRight]="alignOverlayPanelToRight"
      [staticOverlay]="staticOverlay"
      (selectionChange)="onSelectionChange($event)"
      [overlayPanelStaticWidth]="overlayPanelStaticWidth"
      [overlayPanelStaticMinWidth]="overlayPanelStaticMinWidth"
      [afterItemsPortal]="afterItemsPortal"
      [hideSelectAll]="hideSelectAll"
      (afterItemsPortalAttached)="onAfterItemsPortalAttached($event)"
   >
   </lcs-multi-selector-overlay-panel>
</div>