import { Component, HostBinding } from "@angular/core";

@Component({
   selector: "lcs-form-section-header",
   templateUrl: "form-section-header.component.html",
})
export class FormSectionHeaderComponent {
   @HostBinding("class.flex-full") flexClass: boolean = true;

   @HostBinding("class.flex-space-between") justifyContentClass: boolean = true;
}
