import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/scrolling";
import { formatDate } from "@angular/common";
import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   HostBinding,
   Input,
   NgZone,
   OnDestroy,
   OnInit,
   Output,
   Renderer2,
   ViewChild,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ConstantsService } from "@lcs/core/constants.service";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { DatesService } from "@lcs/utils/dates.service";
import { ResponsiveScreenSize } from "@lcs/viewport/responsive-screen-sizes.enum";
import { WindowService } from "@lcs/viewport/window.service";
import { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_SPACE, KEY_UP } from "keycode-js";
import { Calendar, CalendarTypeView } from "primeng/calendar";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { debounce, filter, fromEvent, map, Subject, Subscription, takeUntil, tap } from "rxjs";

import { DateParts } from "./date-parts.interface";

@Component({
   selector: "lcs-date-picker",
   templateUrl: "date-picker.component.html",
})
export class DatePickerComponent extends ValueAccessorBase<Date> implements OnInit, AfterViewInit, OnDestroy {
   @Input() set dateFormat(val: string) {
      this._dateFormat = val;
      if (!val) {
         throw new Error("Must provide a date format to this control.");
      }
   }

   get dateFormat(): string {
      return this._dateFormat;
   }

   @Input() placeholder: string = "";

   @Input() showTooltip: boolean;

   @Input() standalone: boolean = true;

   @Output() inputValueChange = new EventEmitter<Date>();

   @Output() inputFocus: Subject<void> = new EventEmitter<void>();

   @Output() inputBlur: Subject<void> = new EventEmitter<void>();

   @ViewChild("calendarPicker", { static: true }) calendarPicker: Calendar;

   @HostBinding("class.disabled") get disabledClass(): boolean {
      return this.disabled;
   }

   @Input() mobileOpen: boolean = false;

   @Input() view: CalendarTypeView = "date";

   @Input() modelRef: NgControl;

   @Input() tooltipOverride: string;

   dateInputElement: HTMLInputElement;

   expressDataTypes = ExpressDataTypes;

   zIndex: number;

   get overlayVisible(): boolean {
      return this.calendarPicker.overlayVisible ?? false;
   }

   // this prevents keyboard input firing on blur when last input was from using arrow keys
   private lastInputKeyboard: boolean = true;

   private scrollSubscription: Subscription;

   private unsubscribe = new Subject<void>();

   private _dateFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private renderer2: Renderer2,
      private ngZone: NgZone,
      private scrollDispatcher: ScrollDispatcher,
      private elementRef: ElementRef,
      private windowService: WindowService
   ) {
      super(changeDetectorRef, ngControl);

      this.zIndex = ConstantsService.FullMenuZIndex;
      this.registerOnValueWritten(() => {
         if (typeof this.innerValue === "string") {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            this.innerValue = this.innerValue ? new Date(this.innerValue) : null;
         }
      });
   }

   ngOnInit(): RequiredSuperCallFlag {
      if (!this.placeholder || this.placeholder.trim() === "") {
         this.placeholder = this.dateFormat;
      }
      if (!this.modelRef) {
         this.modelRef = this.ngControl;
      }
      return super.ngOnInit();
   }

   ngAfterViewInit() {
      this.calendarPicker.onButtonClick = () => {
         if (
            this.windowService.responsiveScreenWidthSubject.value !== ResponsiveScreenSize.Large ||
            this.windowService.responsiveScreenHeightSubject.value !== ResponsiveScreenSize.Large
         ) {
            this.mobileOpen = true;
            this.calendarPicker.showOverlay();
         } else {
            if (this.calendarPicker.overlayVisible) {
               this.closeDatePicker();
            } else {
               this.calendarPicker.inputfieldViewChild?.nativeElement.focus();
               this.calendarPicker.showOverlay();
            }
         }
      };

      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.ngControl.control.markAsPristine();
      setTimeout(() => {
         this.dateInputElement = this.calendarPicker.inputfieldViewChild?.nativeElement;

         this.ngZone.runOutsideAngular(() => {
            if (this.dateInputElement) {
               fromEvent(this.dateInputElement, "click")
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe(() => {
                     this.ngZone.run(() => {
                        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
                        this.setInputSelection(this.dateInputElement.selectionStart);
                     });
                  });
               fromEvent(this.dateInputElement, "keydown")
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((event: KeyboardEvent) => {
                     this.ngZone.run(() => {
                        this.dateInputElementKeyDown(event);
                     });
                  });
            }

            fromEvent(this.dateInputElement, "input")
               .pipe(
                  map((event: KeyboardEvent) => (<HTMLInputElement>event.target).value),
                  tap((inputValue) => {
                     this.inputValueChange.emit(new Date(inputValue));
                     this.lastInputKeyboard = true;
                  }),
                  debounce(() => this.inputBlur),
                  filter(() => this.lastInputKeyboard),
                  map((inputValue) => DatesService.getDateParts(inputValue)),
                  takeUntil(this.unsubscribe)
               )
               .subscribe((dateParts: DateParts) => {
                  this.ngZone.run(() => {
                     // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
                     let date: Date = null;
                     if (dateParts.masked) {
                        date = new Date(+dateParts.year, +dateParts.month - 1, +dateParts.day);

                        // catch invalid day values (e.g., Feb 31st)
                        if (date.getMonth() + 1 !== +dateParts.month) {
                           // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
                           date = null;
                           dateParts.masked = "";
                        }
                     }
                     // we have to call propagateChanged() here manually
                     // in order to force an update when the user types something invalid
                     // and we overwrite it with new Date()
                     this.innerValue = date;
                     this.propagateChanged();
                  });
               });
         });
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   dateInputElementKeyDown(event: KeyboardEvent) {
      if (
         event.keyCode === KEY_DOWN ||
         event.keyCode === KEY_UP ||
         event.keyCode === KEY_LEFT ||
         event.keyCode === KEY_RIGHT ||
         event.keyCode === KEY_SPACE
      ) {
         if (event.keyCode === KEY_DOWN || event.keyCode === KEY_UP) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
            this.onArrowKeyIncrementPress(this.dateInputElement.selectionStart, event.keyCode);
         } else if (event.keyCode === KEY_LEFT || event.keyCode === KEY_RIGHT) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
            this.onArrowKeyNavigationPress(this.dateInputElement.selectionStart, event.keyCode);
         } else if (event.keyCode === KEY_SPACE || event.code === "Space" || event.key === " ") {
            this.toggleCalendarOverlay();
         }
         event.preventDefault();
         this.changeDetectorRef.markForCheck();
      }
   }

   setInputSelection(position: number) {
      const value = this.dateInputElement.value;
      if (value == null || value.length === 0 || this.dateFormat == null || this.dateFormat.length === 0) {
         return;
      }
      const formatChar = this.getDateFormatChar(position);

      const previousSlash = value.lastIndexOf("/", position);
      const nextSlash = value.indexOf("/", position);

      if (previousSlash < 0 && nextSlash < 0) {
         return;
      }
      let newStart = previousSlash;
      let newEnd = nextSlash;

      if (formatChar === "m") {
         // month
         newStart = 0;
         newEnd = nextSlash;
      } else if (formatChar === "y") {
         // year
         newStart = previousSlash + 1;
         newEnd = value.length;
      } else if (formatChar === "d") {
         // day
         newStart = previousSlash + 1;
         newEnd = nextSlash;
      }

      if (newStart !== this.dateInputElement.selectionStart || newEnd !== this.dateInputElement.selectionEnd) {
         this.dateInputElement.setSelectionRange(newStart, newEnd);
      }
   }

   onArrowKeyIncrementPress(position: number, keyCode: number) {
      this.lastInputKeyboard = false;
      const value = this.dateInputElement.value;
      if (value == null || value.length === 0 || this.dateFormat == null || this.dateFormat.length === 0) {
         return;
      }

      const formatChar = this.getDateFormatChar(position);

      const dateValues = value.split("/");
      // backup date provides a full date when partial date formats are used ex. mm/dd
      // use current value or today's date
      const backupDate = this.value != null ? this.value : new Date();
      let month = dateValues.length > 0 ? +dateValues[0] : backupDate.getMonth();
      let day = dateValues.length > 1 ? +dateValues[1] : backupDate.getDate();
      let year = dateValues.length > 2 ? +dateValues[2] : backupDate.getFullYear();
      if (formatChar === "m") {
         if (keyCode === KEY_UP) {
            ++month;
         } else if (keyCode === KEY_DOWN) {
            --month;
         }
      } else if (formatChar === "y") {
         if (keyCode === KEY_UP) {
            ++year;
         } else if (keyCode === KEY_DOWN) {
            --year;
         }
      } else if (formatChar === "d") {
         if (keyCode === KEY_UP) {
            ++day;
         } else if (keyCode === KEY_DOWN) {
            --day;
         }
      }

      if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
         if (formatChar !== "d") {
            const maxDayOfMonth = new Date(year, month, 0);
            if (day > maxDayOfMonth.getDate()) {
               day = maxDayOfMonth.getDate();
            }
         }
         let originalDate: Date;
         if (this.value == null) {
            originalDate = new Date();
         } else {
            originalDate = this.value;
         }
         const newDate: Date = new Date(year, month - 1, day);
         if (this.dateFormat.toLowerCase().indexOf("y") === -1) {
            // if year is absent in the date format, don't allow year to change
            newDate.setFullYear(originalDate.getFullYear());
         }
         // explicitly set input text b/c setting this.value automatically moves cursor to end of input
         const formattedDate = formatDate(newDate, this.dateFormat, GlobalsService.locale);
         this.renderer2.setProperty(this.dateInputElement, "value", formattedDate);
         this.value = newDate;
         setTimeout(() => {
            this.setInputSelection(position);
         });
      }
   }

   onArrowKeyNavigationPress(position: number, keyCode: number) {
      const value = this.dateInputElement.value;
      if (value == null || value.length === 0 || this.dateFormat == null || this.dateFormat.length === 0) {
         return;
      }

      const formatChar = this.getDateFormatChar(position);

      const dateValues = value.split("/");

      if (formatChar === "m") {
         if (keyCode === KEY_RIGHT) {
            return this.setInputSelection(dateValues[0].length + 2);
         }
      } else if (formatChar === "y") {
         if (keyCode === KEY_LEFT) {
            return this.setInputSelection(dateValues[0].length + 2);
         }
      } else if (formatChar === "d") {
         if (keyCode === KEY_LEFT) {
            return this.setInputSelection(0);
         } else if (keyCode === KEY_RIGHT) {
            return this.setInputSelection(dateValues[0].length + 2 + dateValues[1].length + 2);
         }
      }
   }

   onDateSelect(event: Date) {
      this.value = event;
      this.propagateTouched();
      this.closeDatePicker();
   }

   /**
    * Checks if the calendar overlay is off the left side of the screen, and if so sets the left
    * boundary to match the date-picker host element.  Not sure if this is still needed with
    * current version of p-calendar 13.3.1+, but left for now.
    */
   setLeft(): boolean {
      if (this.calendarPicker.overlayVisible) {
         if (this.calendarPicker.overlay && this.calendarPicker.overlay.getBoundingClientRect().left < 0) {
            this.renderer2.setStyle(
               this.calendarPicker.overlay,
               "left",
               this.elementRef.nativeElement.getBoundingClientRect().left + "px"
            );
            return true;
         }
      }
      return false;
   }

   onBlur() {
      this.inputBlur.next();
      this.propagateTouched();
   }

   onResize() {
      if (this.calendarPicker.overlayVisible && !this.setLeft()) {
         this.calendarPicker.alignOverlay();
      }
   }

   onFocus() {
      this.inputFocus.next();
      let clientHeight: number;
      let originalTop: number;
      let datePickerTop: number;
      let windowTop: number;
      let windowHeight: number;

      const ancestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);
      for (let i = 0; i < ancestors.length; i++) {
         // Scroll event won't fire if height is set to a %
         const scrollableNativeElement = ancestors[i].getElementRef().nativeElement.children[0];
         if (scrollableNativeElement.firstElementChild?.tagName !== "RMX-VIRTUAL-TABLE") {
            this.renderer2.setStyle(scrollableNativeElement, "height", "auto");
         }
      }

      this.scrollSubscription = this.scrollDispatcher
         .ancestorScrolled(this.elementRef, 0)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((data: CdkScrollable) => {
            if (this.calendarPicker.overlay) {
               if (!originalTop) {
                  originalTop = parseInt(this.calendarPicker.overlay.style.top);
               }

               datePickerTop = this.elementRef.nativeElement.getBoundingClientRect().top;
               windowTop = data.getElementRef().nativeElement.getBoundingClientRect().top;
               windowHeight = data.getElementRef().nativeElement.getBoundingClientRect().height;
               clientHeight =
                  this.calendarPicker.overlay.clientHeight > 0
                     ? this.calendarPicker.overlay.clientHeight
                     : clientHeight;

               if (
                  originalTop >= datePickerTop ||
                  windowTop + windowHeight - datePickerTop - this.elementRef.nativeElement.clientHeight >= clientHeight
               ) {
                  this.renderer2.setStyle(
                     this.calendarPicker.overlay,
                     "top",
                     datePickerTop + this.elementRef.nativeElement.clientHeight + "px"
                  );
               } else {
                  this.renderer2.setStyle(
                     this.calendarPicker.overlay,
                     "top",
                     Math.max(datePickerTop - clientHeight, 0) + "px"
                  );
               }
               if (
                  this.isOutOfWindow(datePickerTop, this.elementRef.nativeElement.clientHeight, windowTop, windowHeight)
               ) {
                  this.closeDatePicker();
               }
            }
         });
   }

   getMaskedDate(value: Date) {
      if (!value) {
         return "";
      }

      const year = value.getFullYear().toString();
      let month = (value.getMonth() + 1).toString();
      let day = value.getDate().toString();

      if (month.length === 1) {
         month = "0" + month;
      }
      if (day.length === 1) {
         day = "0" + day;
      }

      return month + "/" + day + "/" + year;
   }

   onEnter(event: Event) {
      if (this.calendarPicker.overlayVisible) {
         (event as KeyboardEvent).preventDefault();
         this.toggleCalendarOverlay();
      } else {
         this.dateInputElement.blur();
      }
      this.dateInputElement.focus();
   }

   private toggleCalendarOverlay() {
      // TODO: (rwassum:2019-09-17) This code can be replaced by this.calendarPicker.toggle() once
      //       we updated to ngprime8.0.3 or higher.  We're currently on 8.0.2
      if (!this.calendarPicker.inline) {
         if (!this.calendarPicker.overlayVisible) {
            this.calendarPicker.showOverlay();
            this.dateInputElement.focus();
         } else {
            this.calendarPicker.hideOverlay();
         }
      }
   }

   private isOutOfWindow(
      datePickerTop: number,
      datePickerHeight: number,
      windowTop: number,
      windowHeight: number
   ): boolean {
      return (
         datePickerTop + datePickerHeight < windowTop || datePickerTop + datePickerHeight > windowTop + windowHeight
      );
   }

   private clearSubscription() {
      if (this.scrollSubscription) {
         this.scrollSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.scrollSubscription = null;
      }
   }

   private closeDatePicker() {
      this.mobileOpen = false;
      this.ngZone.run(() => {
         this.calendarPicker.overlayVisible = false;
         // have to set the calendar overlaypanel display to none because it closes slowly
         if (this.calendarPicker.overlay) {
            this.renderer2.setStyle(this.calendarPicker.overlay, "display", "none");
         }
         this.clearSubscription();
      });
   }

   private getDateFormatChar(position): string {
      let formatChar: string;
      if (position >= this.dateFormat.length) {
         formatChar = this.dateFormat[this.dateFormat.length - 1];
      } else if (position < 0) {
         formatChar = this.dateFormat[0];
      } else if (this.dateFormat[position] === "/" && position > 0) {
         formatChar = this.dateFormat[position - 1];
      } else {
         formatChar = this.dateFormat[position];
      }
      formatChar = formatChar.toLowerCase();

      return formatChar;
   }
}
