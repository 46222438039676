import { HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Params } from "@angular/router";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { map, Observable, Subject } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class LocationService implements OnDestroy {
   private unsubscribe = new Subject<void>();
   private endpoint = "authentication";

   constructor(private apiService: ApiService) {}
   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   getLocationCollection(
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService
         .directGet(
            this.getLocationCollectionUrl(
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption[] | undefined' is n... Remove this comment to see the full error message
               filters,
               embeds,
               orderingOptions,
               fields,
               pageSize,
               pageNumber,
               additionalParameters
            )
         )
         .pipe(map((response: HttpResponse<any>) => response.body));
   }

   getLocationCollectionUrl(
      filters?: FilterOption[],
      embeds?: string[],
      orderingOptions?: OrderingOption[],
      fields?: string[],
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/GetLocationFromRequest";
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (filters !== undefined && filters !== null && filters.length > 0) {
         additionalParameters.push("filters=" + filters.map((f) => f.toApiString()).join(";"));
      }
      if (embeds !== undefined && embeds !== null && embeds.length > 0) {
         additionalParameters.push("embeds=" + embeds.join(","));
      }
      if (orderingOptions !== undefined && orderingOptions !== null && orderingOptions.length > 0) {
         additionalParameters.push("orderingOptions=" + orderingOptions.map((f) => f.toApiString()).join(","));
      }
      if (fields !== undefined && fields != null && fields.length > 0) {
         additionalParameters.push("fields=" + fields.join(","));
      }
      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }
      return this.apiService.getUrl(
         endpoint,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   toLowerCaseParams(params: Params): Params {
      const queryParam: Array<Params> = new Array<Params>();
      // eslint-disable-next-line guard-for-in
      for (const key in params) {
         queryParam[key.toLowerCase()] = params[key];
      }
      return queryParam;
   }
}
