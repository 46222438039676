import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";

import { ExpressControlDataSourceFilterModel } from "./express-control-data-source-filter.model";
import { FilterFieldInformationModel } from "./filter-field-information.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class QuickFilterDefinitionModel {
   CheckboxFilterValue: string;
   ControlType: ExpressLayoutControlTypes;
   DataSourceFilters: Array<ExpressControlDataSourceFilterModel>;
   DataType: ExpressDataTypes;
   EntityType: EntityType;
   EnumerationType: string;
   FilterField: FilterFieldInformationModel;
   FilterFieldInformationModel: FilterFieldInformationModel;
   FilterOperation: FilterOperations;
   FilterType: number;
   hasDateRangeOtherOptions: boolean;
   Hide: boolean;
   InvertedFilter: boolean;
   InvertNullValueFilterOperation: boolean;
   Label: string;
   OrderingOptions: Array<string>;
   QuickFilterMemoryField: string;
   StaticValues: any;
   Value: string;
   Values: Array<string>;
   ValuesToExclude: Array<string>;
   ValuesToInclude: Array<string>;
}
