import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { ColorPickerModule } from "@lcs/color-picker/color-picker.module";
import { EntityMultiSelectorModule } from "@lcs/entity-multi-selector/entity-multi-selector.module";
import { EntitySelectorModule } from "@lcs/entity-selector/entity-selector.module";
import { EnumMultiSelectorModule } from "@lcs/enum-multi-selector/enum-multi-selector.module";
import { InputWithOptionsModule } from "@lcs/input-with-options/input-with-options.module";
import { SelectModule } from "@lcs/select/select.module";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";
import { TooltipModule } from "primeng/tooltip";

import { AssignedToUserSelectorModule } from "./assigned-to-user-selector/assigned-to-user-selector.module";
import { BankSelectorModule } from "./bank-selector/bank-selector.module";
import { CalculatorModule } from "./calculator-input/calculator.module";
import { ClickToCallModule } from "./click-to-call/click-to-call.module";
import { ComingSoonModule } from "./coming-soon/coming-soon.module";
import { DatePickerModule } from "./date-picker/date-picker.module";
import { DateRangePickerModule } from "./date-range-picker/date-range-picker.module";
import { DateTimePickerModule } from "./date-time-picker/date-time-picker.module";
import { EnumerationSelectorDirective } from "./enumeration-selector.directive";
import { InputComponent } from "./input.component";
import { NumberRangeInputModule } from "./number-range-input/number-range-input.module";
import { NumericInputModule } from "./numeric-input/numeric-input.module";
import { OwnerEstimateDatatablePropertySelectorModule } from "./owner-estimate-datatable-property-selector/owner-estimate-datatable-property-selector.module";
import { PhoneNumberComponent } from "./phone-number/phone-number.component";
import { SecureAnnualIncomeInputModule } from "./secure-input/secure-annual-income-input.module";
import { SecureSsnInputModule } from "./secure-input/secure-ssn-input.module";
import { SliderModule } from "./slider/slider.module";
import { StaticFieldModule } from "./static-field/static-field.module";
import { TenantsAndProspectsSelectorModule } from "./tenants-and-prospects-selector/tenants-and-prospects-selector.module";
import { TextAreaModule } from "./text-area/text-area.module";
import { TextBoxModule } from "./text-box/text-box.module";
import { UDFEncryptedTextModule } from "./udf-encrypted-text/udf-encrypted-text.module";
import { ValidationModule } from "./validation/validation.module";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ValidationModule,
      TextBoxModule,
      CalculatorModule,
      CheckboxModule,
      DatePickerModule,
      DateTimePickerModule,
      DateRangePickerModule,
      NumberRangeInputModule,
      NumericInputModule,
      SliderModule,
      ComingSoonModule,
      TooltipModule,
      TextAreaModule,
      EntitySelectorModule,
      EntityMultiSelectorModule,
      EnumMultiSelectorModule,
      SelectModule,
      InputWithOptionsModule,
      TenantsAndProspectsSelectorModule,
      SingleLineMultiSelectModule,
      BankSelectorModule,
      AssignedToUserSelectorModule,
      OwnerEstimateDatatablePropertySelectorModule,
      SecureSsnInputModule,
      StaticFieldModule,
      SecureAnnualIncomeInputModule,
      ClickToCallModule,
      UDFEncryptedTextModule,
      ColorPickerModule,
      //ViolationsCodeSelectorModule,
   ],
   declarations: [InputComponent, PhoneNumberComponent, EnumerationSelectorDirective],
   exports: [InputComponent, PhoneNumberComponent, EnumerationSelectorDirective],
})
export class InputsModule {}
