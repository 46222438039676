////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum UserPreferences {
   NotSet = 0,
   DefaultCOMPort = 1,
   CheckScanningType = 2,
   DefaultScanManagerScanner = 3,
   DefaultViewType = 4,
   ShowInactiveChargeTypes = 5,
   SystemPreferencesWindowHeight = 6,
   RegisterQuickSearchType = 7,
   EmailFormatDefault = 8,
   EmailPrefillLetterSubject = 9,
   UseOSDefaultExternalImageViewer = 10,
   SearchRegisterQuickSearchType = 11,
   HideDashboardOnStartup = 12,
   DashboardPropertyFilter = 13,
   DashboardMultiplePropertySelection = 14,
   ScreeningLetterTemplateID = 15,
   ScreeningUpgradeEmailReport = 16,
   ScreeningUpgradeFaxReport = 17,
   NDTShowIncomingPopups = 18,
   NDTFocusIncomingCalls = 19,
   NDTShowHuntGroupPopups = 20,
   NDTHideHuntGroupPopupsAfterAnswer = 21,
   NDTShowPresenceListing = 22,
   NDTShowExtensionsInPhoneExtensionList = 23,
   NDTOverrideSystemPref = 24,
   CommandLaunchResultsSelector0 = 25,
   CommandLaunchResultsSelector1 = 26,
   CommandLaunchResultsSelector2 = 27,
   CommandLaunchResultsSelector3 = 28,
   CommandLaunchResultsSelector4 = 29,
   CommandLaunchResultsSelector5 = 30,
   CommandLaunchResultsSelector6 = 31,
   CommandLaunchResultsSelector7 = 32,
   CustomerDisplayCurrent = 33,
   CustomerDisplayFuture = 34,
   CustomerDisplayPast = 35,
   CustomerDisplayExclude = 36,
   ProspectDisplayProspects = 37,
   ProspectDisplayCustomers = 38,
   ProspectDisplayLost = 39,
   OwnerDisplayInactive = 40,
   VendorDisplayInactive = 41,
   OwnerProspectDisplayOwnerProspects = 42,
   OwnerProspectDisplayOwners = 43,
   OwnerProspectDisplayLost = 44,
   CalendarTimeScale = 45,
   CalendarFirstDayOfWeek = 46,
   CalendarStartTime = 47,
   CalendarEndTime = 48,
   CalendarMonthShowEndTime = 49,
   CalendarWeekShowEndTime = 50,
   CalendarMonthShowTimeAsClock = 51,
   CalendarWeekShowTimeAsClock = 52,
   CalendarCompressWeekend = 53,
   CalendarMonthPrintLandscape = 54,
   CalendarWeekPrintLandscape = 55,
   CalendarDayPrintLandscape = 56,
   CalendarWorkWeek = 57,
   ServiceManagerDefaultListOnStart = 58,
   ServiceManagerDefaultListNotify = 59,
   ServiceManagerDefaultListRefreshMinutes = 60,
   CalendarIntegrationMethod = 61,
   CalendarIntegrationAltEmail = 62,
   TextMessageNotification = 63,
   APITestValue = 64,
   OnlineListingsImageSliderValue = 65,
   ImageSliderValue = 66,
   NDTPlaceCallShortCutKey = 67,
   ShowInactiveGLAccounts = 68,
   IsDisableReminderPopup = 69,
   TenantListboxFormatOverrideDefault = 70,
   TenantListboxFormatLastNameFirstName = 71,
   TenantListboxFormatIncludeAccountNumber = 72,
   TenantListboxFormatIncludePropertyName = 73,
   TenantListboxFormatIncludeUnitName = 74,
   TenantListboxFormatIncludeTenantProspectStatus = 75,
   DefaultStateSelections = 76,
   UseLocalPrintersByDefault = 77,
   ExpressThemeColor = 78,
   ExpressFontSize = 79,
   ExpressHomePage = 80,
   PrintBillWorksheets = 81,
   PrintCheckWorksheets = 82,
   ImageView = 83,
   SELCOLOR = 84,
   CalendarFollowUpTime = 85,
   ShowConfirmReconcile = 86,
   SMTPFromName = 87,
   SMTPFromEmail = 88,
   OverrideSystemPreferencesSMTPAuth = 89,
   SMTPUsername = 90,
   SMTPPassword = 91,
   ExpressOpenListDetailsInNewTab = 92,
   ExpressShowRMUCBanner = 93,
}
