import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { IconModule } from "@lcs/icon/icon.module";
import { LinkModule } from "@lcs/link/link.module";

import { ActionLinkModule } from "../action-link/action-link.module";
import { FormSectionHeaderModule } from "../forms/form-section-header/form-section-header.module";
import { InformationOverlayModule } from "../information-overlay/information-overlay.module";
import { ValidationModule } from "../inputs/validation/validation.module";
import { OverlayPanelsModule } from "../overlay-panel/overlay-panels.module";
import { PipesModule } from "../pipes/pipes.module";
import { ProgressButtonExtModule } from "../progress-button/progress-button-ext/progress-button-ext.module";
import { CollapsedFooterButtonComponent } from "./collapsed-footer-buttons.component";
import { FooterButtonRaisedPipe } from "./footer-button-raised.pipe";
import { FooterComponent } from "./footer.component";
import { FooterDirective } from "./footer.directive";

@NgModule({
   imports: [
      CommonModule,
      OverlayPanelsModule,
      PipesModule,
      ValidationModule,
      ProgressButtonExtModule,
      OverlayPanelsModule,
      FormSectionHeaderModule,
      InformationOverlayModule,
      MatMenuModule,
      IconModule,
      LinkModule,
      ActionLinkModule,
   ],
   declarations: [FooterComponent, CollapsedFooterButtonComponent, FooterButtonRaisedPipe, FooterDirective],
   exports: [FooterComponent, FooterDirective],
})
export class FooterModule {}
