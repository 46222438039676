import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject, take } from "rxjs";

import { ConstantsService } from "../core/constants.service";
import { EntityEventResult } from "../entity-events/entity-event-result.enum";
import { warnInDevMode } from "../utils/logging";
import { FooterComponent } from "./footer.component";

@Injectable({
   providedIn: "root",
})
export class FooterRegistrationService implements OnDestroy {
   currentFooter: Observable<FooterComponent | undefined>;

   private footers: Map<string, FooterComponent>;

   private footerIDStack: Array<string>;

   private _currentFooter = new BehaviorSubject<FooterComponent | undefined>(undefined);

   private unsubscribe = new Subject<void>();

   constructor() {
      this.currentFooter = this._currentFooter.asObservable();
      this.footers = new Map<string, FooterComponent>();
      this.footerIDStack = [];
   }

   ngOnDestroy(): void {
      if (this.footers) {
         this.footers.clear();
         this.footerIDStack = [];
      }
      this.unsubscribe.next();
   }

   register(footerID: string, footer: FooterComponent) {
      this.footers.set(footerID, footer);
      this.footerIDStack.push(footerID);
      this._currentFooter.next(this.getCurrentFooter());
   }

   deregister(footerID: string) {
      this.footers.delete(footerID);
      const index = this.footerIDStack.findIndex((id) => id === footerID);
      const isCurrentFooter = this.footerIDStack.length > 0 && index === this.footerIDStack.length - 1;
      if (index !== ConstantsService.ArrayIndexNotFound) {
         this.footerIDStack.splice(index, 1);
         if (isCurrentFooter) {
            this._currentFooter.next(this.getCurrentFooter());
         }
      }
   }

   /**
    * Hides/Displays all registered footers.
    * @param isFooterHidden true if the footers should be hidden
    */
   toggleAllFooters(isFooterHidden: boolean): void {
      this.forEachFooter((footer) => {
         footer.hideFooter(isFooterHidden);
      });
   }

   /**
    * Disables/Enables all buttons with the save entity event across all registered footers.
    * @param isDisabled true if the buttons should be disabled.
    */
   disableAllSaveButtons(isDisabled: boolean): void {
      this.forEachFooter((footer) => {
         footer.disableSave(isDisabled, true);
      });
   }

   /**
    * Disables/Enables all buttons with the cancel entity event across all registered footers.
    * @param isDisabled true if the buttons should be disabled.
    */
   disableAllCancelButtons(isDisabled: boolean): void {
      this.forEachFooter((footer) => {
         footer.disableCancel(isDisabled, true);
      });
   }

   /**
    * Disables/Enables all buttons across all registered footers.
    * @param isDisabled true if the buttons should be disabled.
    */
   disableAllButtons(isDisabled: boolean): void {
      this.forEachFooter((footer) => {
         footer.disableAll(isDisabled, true);
      });
   }

   /**
    * Waits for an update on the status display of any footer button before invoking the callback method.
    * @param footerID The ID of the footer to wait on.
    * @param callback The method to invoke a single time when the button status changes.
    */
   waitOnFooterButtonStatusDelay(footerID: string, callback: (result: EntityEventResult) => void) {
      let footer = this.footers.get(footerID);
      if (footer) {
         footer?.buttonStatusChange.pipe(take(1)).subscribe((result) => {
            callback(result);
         });
      }
   }

   private forEachFooter(callback: (footer: FooterComponent) => void): void {
      if (this.footers) {
         for (const footer of Array.from(this.footers.values())) {
            callback(footer);
         }
      }
   }

   private getCurrentFooter(): FooterComponent | undefined {
      let currentFooter: FooterComponent | undefined;
      if (this.footerIDStack && this.footerIDStack.length > 0) {
         const currentFooterID = this.footerIDStack[this.footerIDStack.length - 1];
         if (this.footers.has(currentFooterID)) {
            currentFooter = this.footers.get(currentFooterID);
         } else {
            warnInDevMode(`Warning: Footer ${currentFooterID} is no longer registered.`);
         }
      }
      return currentFooter;
   }
}
