import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReportParameterValueModel } from "@lcs/reports/report-parameters/models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "@lcs/reports/report-parameters/models/report-report-parameter.viewmodel";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { coerceBoolean } from "projects/libraries/lcs/src/lib/utils/boolean-coercion";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

@Component({
   selector: "owa-transactions-to-include-report-parameter",
   templateUrl: "transactions-to-include-report-parameter.component.html",
})
export class TransactionsToIncludeReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   transactionsToIncludeReportParameter: ReportReportParameterViewModel;

   showDepositBreakdownReportParameter: ReportReportParameterViewModel;

   showCheckBreakdownReportParameter: ReportReportParameterViewModel;

   vendorsReportParameter: ReportReportParameterViewModel;

   showJournalsReportParameter: ReportReportParameterViewModel;

   transactionsToIncludeValue: ReportParameterValueModel;

   showDepositBreakdownValue: ReportParameterValueModel;

   showCheckBreakdownValue: ReportParameterValueModel;

   vendorAccnoValue: ReportParameterValueModel;

   showJournalValue: ReportParameterValueModel;

   vendorEntityType = EntityType.Vendor;

   anyVendorItem: SelectorItemModel;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.transactionsToIncludeReportParameter.IsAsk = isAsk;
      this.showDepositBreakdownReportParameter.IsAsk = isAsk;
      this.showCheckBreakdownReportParameter.IsAsk = isAsk;
      this.vendorsReportParameter.IsAsk = isAsk;
      this.showJournalsReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(ReportParameter.TRANSTOINCLUDE, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.SHOWDEPOSITBREAKDOWN, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.SHOWLEDGERBREAKDOWN, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.VENDORSACCNO, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.ShowJournal, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.transactionsToIncludeReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.TRANSTOINCLUDE
      );
      this.transactionsToIncludeReportParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.showDepositBreakdownReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWDEPOSITBREAKDOWN
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.showCheckBreakdownReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWLEDGERBREAKDOWN
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.vendorsReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.VENDORSACCNO
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.showJournalsReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ShowJournal
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.transactionsToIncludeValue = this.reportParametersService.reportParameterValues.get(
         ReportParameter.TRANSTOINCLUDE
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.showDepositBreakdownValue = this.reportParametersService.reportParameterValues.get(
         ReportParameter.SHOWDEPOSITBREAKDOWN
      );
      this.showDepositBreakdownValue.value = coerceBoolean(this.showDepositBreakdownValue.value);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.showCheckBreakdownValue = this.reportParametersService.reportParameterValues.get(
         ReportParameter.SHOWLEDGERBREAKDOWN
      );
      this.showCheckBreakdownValue.value = coerceBoolean(this.showCheckBreakdownValue.value);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.vendorAccnoValue = this.reportParametersService.reportParameterValues.get(ReportParameter.VENDORSACCNO);
      this.anyVendorItem = new SelectorItemModel(0, "Any Vendor");

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.showJournalValue = this.reportParametersService.reportParameterValues.get(ReportParameter.ShowJournal);
      this.showJournalValue.value = coerceBoolean(this.showJournalValue.value);

      this.reportParametersService.reportParameterUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((update) => {
         if (update.reportParameter === ReportParameter.TRANSTOINCLUDE) {
            this.showJournalValue.value = +update.value === 0;
            this.reportParametersService.updateReportParameterValue(this.showJournalValue);

            if (+update.value !== 2) {
               this.showDepositBreakdownValue.value = false;
               this.reportParametersService.updateReportParameterValue(this.showDepositBreakdownValue);
            }

            if (+update.value !== 1) {
               this.showCheckBreakdownValue.value = false;
               this.reportParametersService.updateReportParameterValue(this.showCheckBreakdownValue);
            }
         }
      });
   }
}
