import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { LocationComponent } from "./rm-location.component";
import { LocationService } from "./rm-location.service";


@NgModule({
   imports: [
     CommonModule,
     FormsModule,
   ],
   declarations: [ LocationComponent,
     ],
   exports: [
      LocationComponent,
   ],
   providers: [
      LocationService,
   ],
})
export class LocationModule {}