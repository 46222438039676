import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ControlTypeAdapterService } from "@lcs/datatable/control-type-adapter.service";
import { InputComponent } from "@lcs/inputs/input.component";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";

import { FilterInputValueType } from "../datatable-filter-values.types";
import { DataTableFiltersService } from "../datatable-filters.service";
import { UserDefinedValueFilterDefinitionModel } from "./user-defined-value-filter-definition.model";

@Component({
   selector: "lcs-datatable-user-defined-value-filter",
   templateUrl: "./datatable-user-defined-value-filter.component.html",
})
export class DatatableUserDefinedValueFilterComponent {
   @ViewChild("filterInput", { static: true }) filterInput: InputComponent;

   @Input() set filterDefinition(definition: UserDefinedValueFilterDefinitionModel) {
      this.loaded = false;
      this._filterDefinition = definition;
      if (
         [FilterOperations.In, FilterOperations.NotIn, FilterOperations.Between].some(
            (operation) => operation === definition.filterOption.Operation
         )
      ) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType[] | null' is not assignable ... Remove this comment to see the full error message
         this.filterInputValue = definition.filterOption.Values;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType | null' is not assignable to... Remove this comment to see the full error message
         this.filterInputValue = definition.filterOption.Value;
      }
      this.buildOperationSet();
      this.onFilterOperationChanged(this.filterDefinition.filterOption.Operation);
   }

   get filterDefinition(): UserDefinedValueFilterDefinitionModel {
      return this._filterDefinition;
   }

   @Input() set filterOperations(operations: Array<FilterOperationModel>) {
      this._filterOperations = operations;
      this.buildOperationSet();
   }

   get filterOperations(): Array<FilterOperationModel> {
      return this._filterOperations;
   }

   @Input() showRemoveIcon: boolean;

   @Output() removeClicked = new EventEmitter<void>();

   @Output() valueChange = new EventEmitter<any>();

   trimmedOperations: Array<FilterOperationModel>;

   controlType: ExpressLayoutControlTypes;

   subControlType: ExpressLayoutControlTypes;

   filterInputValue: FilterInputValueType | Array<FilterInputValueType>;

   disabled = false;

   loaded = false;

   private _filterDefinition: UserDefinedValueFilterDefinitionModel;

   private _filterOperations: Array<FilterOperationModel>;

   constructor(private dataTableFiltersService: DataTableFiltersService) {}

   onFilterOperationChanged(operation: FilterOperations) {
      const oldOperation = this.filterDefinition.filterOption.Operation;
      this.filterDefinition.filterOption.Operation = operation;

      this.controlType = ControlTypeAdapterService.convertUdfTypeToControlType(
         this.filterDefinition.fieldType,
         this.filterDefinition.valueSource.EmbeddedSet.ComboList,
         this.filterDefinition.valueSource.EmbeddedSet.Delimeter,
         false,
         operation
      );
      if (this.controlType === ExpressLayoutControlTypes.NumberRangeInput) {
         this.subControlType = ExpressLayoutControlTypes.NumericTextBox;
      } else {
         this.subControlType = ExpressLayoutControlTypes.NotSet;
      }

      if (this.controlType === ExpressLayoutControlTypes.NotSet) {
         this.disabled = true;
      } else {
         this.disabled = false;
      }

      if (this.controlType === ExpressLayoutControlTypes.SingleLineMultiSelector) {
         this.filterDefinition.valueSource.MultipleValueDelimiter = ",";
      }

      if (this.loaded) {
         this.filterInputValue = this.dataTableFiltersService.convertFilterValue(
            this.filterInputValue,
            oldOperation,
            operation,
            this.controlType
         );
         this.setFilterValues();
      }

      this.loaded = true;

      this.valueChange.emit(this.filterInputValue);
   }

   onRemoveClicked() {
      this.removeClicked.emit();
   }

   onValueChange(filterInputValue) {
      this.filterInputValue = filterInputValue;
      this.setFilterValues();
      this.valueChange.emit(filterInputValue);
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.filterDefinition.filterOption.DisplayValue =
         this.dataTableFiltersService.getDisplayLabelFromSelectionChange(selectionChange);
   }

   private setFilterValues() {
      this.filterDefinition.filterOption.Values = this.dataTableFiltersService.buildAPIFilterValue(
         this.filterInputValue,
         this.controlType
      );
      if (this.filterInput && this.filterInput.selectedItem) {
         this.filterDefinition.filterOption.DisplayValue = this.filterInput.selectedItem.displayValue;
      } else {
         this.filterDefinition.filterOption.DisplayValue = this.dataTableFiltersService.buildDisplayValue(
            this.filterDefinition.filterOption.Values,
            this.controlType
         );
      }
   }

   private buildOperationSet() {
      if (!this._filterOperations) {
         return;
      }
      const operations = this.dataTableFiltersService.getFilterOperationsForUDFType(
         this._filterOperations,
         this._filterDefinition.fieldType
      );
      this.trimmedOperations = operations;
   }
}
