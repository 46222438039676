<owa-dashboard-tile
   [title]="'Estimates'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'estimates'"
>
   <lcs-table [dataSource]="$any(observableEstimates | async)">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="estimateTileColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: estimateTileColumns"
      ></tr>
      <ng-container
         lcsColumnDef="EstimateNumber"
         [width]="5"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Estimate #</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/estimates', row.EstimateID]"
            >{{ row.EstimateID }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="CreateDate"
         [width]="6"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Date</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/estimates', row.EstimateID]"
            >{{
               row.CreateDate | date: "MM/dd/yyyy"
            }}</a>
         </td>
      </ng-container>

      <ng-container
         lcsColumnDef="Reference"
         [width]="10"
         [priority]="3"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Reference</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/estimates', row.EstimateID]"
            >{{
               row.Reference
            }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="TotalValue"
         [width]="6"
         [priority]="4"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Amount</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="numeric has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/estimates', row.EstimateID]"
            >{{
               row.TotalValue | currency: "USD":"symbol":"1.2-2"
            }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="NewRow"
         [width]="1"
         [priority]="5"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div *ngIf="row.IsNew">
               <span class="notification-icon"></span>
            </div>
         </td>
      </ng-container>
   </lcs-table>

   <ng-template #noEstimates>
      <div class="no-result">No Estimates found</div>
   </ng-template>
</owa-dashboard-tile>