import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";

import { InputComponent } from "../../inputs/input.component";

@Component({
   selector: "lcs-property-group-selector",
   templateUrl: "property-group-selector.component.html",
})
export class PropertyGroupSelectorComponent {
   @Input() set initialValue(val: any) {
      this.value = val;
   }

   @Input() set initialOperation(val: any) {
      this.userSelectedFilterOperation = val;
   }

   @Input() set initialValueString(val: any) {
      this.valueString = val;
   }

   @Input() set userSelectedFilterOperation(val: FilterOperations) {
      if (val === FilterOperations.In || val === FilterOperations.NotIn) {
         if (!Array.isArray(this.value)) {
            this.value = [this.value];
         }
      } else if (val === FilterOperations.EqualTo || val === FilterOperations.NotEqualTo) {
         if (Array.isArray(this.value)) {
            this.value = this.value[0];
            if (this.selectorItemModels.length > 0) {
               this.selectorItemModels = [this.selectorItemModels[0]];
               this.valueString = this.selectorItemModels[0].displayValue;
               this.propertiesChange.emit(this.getPropertiesFromSelectorItemModels());
            }
         }
      }
      this._userSelectedFilterOption = val;
   }

   get userSelectedFilterOperation(): FilterOperations {
      return this._userSelectedFilterOption;
   }

   get operation(): FilterOperations {
      if (this.userSelectedFilterOperation === FilterOperations.EqualTo) {
         return FilterOperations.In;
      }
      if (this.userSelectedFilterOperation === FilterOperations.NotEqualTo) {
         return FilterOperations.NotIn;
      }
      return this.userSelectedFilterOperation ?? FilterOperations.In;
   }

   @Input() standalone: boolean = false;

   @Output() propertiesChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

   @ViewChild("selector") selector: InputComponent;

   get controlType(): ExpressLayoutControlTypes {
      if (
         this.userSelectedFilterOperation === FilterOperations.In ||
         this.userSelectedFilterOperation === FilterOperations.NotIn
      ) {
         return ExpressLayoutControlTypes.EntityMultiSelector;
      }
      return ExpressLayoutControlTypes.EntitySelector;
   }

   value: any;

   entityType: EntityType = EntityType.PropertyGroup;

   valueString: string = "";

   additionalDataFields: Array<string> = ["Properties"];

   private selectorItemModels: Array<SelectorItemModel> = [];

   get displayValue(): string {
      return this.valueString;
   }

   private _userSelectedFilterOption: FilterOperations;

   onValueChange(propertyGroupIds) {
      if (this.controlType === ExpressLayoutControlTypes.EntityMultiSelector) {
         this.value = propertyGroupIds;
         this.propertiesChange.emit(this.getPropertiesFromSelectorItemModels());
      } else {
         this.value = [propertyGroupIds];
         this.valueString = this.selector.selectedItem.displayValue;
         this.selectorItemModels = [this.selector.selectedItem];
         this.propertiesChange.emit(this.getPropertiesFromSelectorItemModels());
      }
   }

   onSelectionChange(propertyGroupSelectionChanged) {
      this.selectorItemModels = propertyGroupSelectionChanged.checkedItems;
      if (propertyGroupSelectionChanged.checkedItems.length < 3) {
         this.valueString = propertyGroupSelectionChanged.checkedItems
            .map((checkedItem) => checkedItem.displayValue)
            .join(", ");
      } else if (propertyGroupSelectionChanged.checkedItems.length === propertyGroupSelectionChanged.items.length) {
         this.valueString = "All selected";
      } else {
         this.valueString = "List of " + propertyGroupSelectionChanged.checkedItems.length;
      }
   }

   private getPropertiesFromSelectorItemModels(): Array<number> {
      const propertyIds = [];
      this.selectorItemModels.forEach((item) => {
         const propertyModels = item.additionalData.get("Properties");
         if (propertyModels) {
            propertyModels.forEach((model) => {
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
               if (!propertyIds.includes(model.PropertyID)) {
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                  propertyIds.push(model.PropertyID);
               }
            });
         }
      });

      return propertyIds;
   }
}
