/**
 * Class used as the return type for an otherwise void function to
 * indicate that derived classes MUST call the super class method as
 * the last statement to execute in the overridden method.
 *
 * Example:
 *
 * return super.overriddenMethodName();
 */
export class RequiredSuperCallFlag {}
