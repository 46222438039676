<div id="MainContent">
</div>

<div
   id='popup-sign-main'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
>
   <div
      class='popup-sign-validation-message'
      style='display:none'
   >Signature cannot be empty.</div>
   <div
      class='popup-initial-validation-message'
      style='display:none'
   >Initial cannot be empty.</div>
   <div id='signatureDisableMessage'> </div>
   <div
      id='previous-signature-pane'
      style='display:none'
   >
      Sign using <div
         id='previous-signature'
         style='font-family: Dancing Script,cursive;'
      ></div>
      <br />
      <button id='enter-new-signature'>Enter a New Signature</button>
   </div>
   <div id='previous-initial-pane'>
      Initial using <div
         id='previous-initial'
         style='font-family: Dancing Script,cursive;'
      ></div>
      <br />
      <button id='enter-new-initial'>Enter a New Initial</button>
   </div>
   <div id='default-signature-pane'>
      Signature to use: <br />
      <input
         id='signature-input'
         autofocus
         maxlength='80'
         name='signature-input'
         type='text'
         value=''
      >
   </div>
   <div id='default-initial-pane'>
      Initials to use:
      <input
         id='initial-input'
         autofocus
         maxlength='3'
         name='initial-input'
         type='text'
         value=''
      >
   </div>
   <div class="mobile-app-icon-bar">
      <button id='btnPrevSign'>
         <div><i class='fas fa-arrow-left'></i></div>
         <div>Prev</div>
      </button>
      <button id='btnSign'>
         <div><i class='fas fa-check'></i></div>
         <div>Sign</div>
      </button>
      <button
         id='btnOk'
         style="display: none"
      >
         <div><i class='fas fa-check'></i></div>
         <div>OK</div>
      </button>
      <button id='btnSignNext'>
         <div><i class='fas fa-arrow-right'></i></div>
         <div>Sign & Next</div>
      </button>
   </div>
   <button
      class="close-button"
      data-close
      aria-label="Close modal"
      type="button"
   >
      <span aria-hidden="true">&times;</span>
   </button>
</div>

<div
   class="tiny reveal input-popup"
   id="popup-input-main"
   data-reveal
   data-close-on-click="false"
>
   <div
      class="popup-input-validation-message"
      style="display: none"
   >value cannot be empty.</div>
   <div class="default-input-pane">
      <div id="inputLabel"> </div>
      <div id="inputContainer"> </div>
   </div>
   <div class="dialog-info-pane row">
      <div
         id="input-position"
         class="large-6 medium-6"
      > </div>
      <div class="large-6 medium-6">
         <div id="requiredLabel"> </div>
      </div>
   </div>

   <div class="mobile-app-icon-bar">
      <button id='btnPrevious'>
         <div><i class='fas fa-arrow-left'></i></div>
         <div>Prev</div>
      </button>
      <button id='btnSave'>
         <div><i class='fas fa-check'></i></div>
         <div>OK</div>
      </button>
      <button
         id='btnStartSigning'
         class="startSigningButton"
      >
         <div><i class='fas fa-edit'></i></div>
         <div>Start Signing</div>
      </button>
      <button id='btnNext'>
         <div><i class='fas fa-arrow-right'></i></div>
         <div>Next</div>
      </button>
   </div>
   <button
      class="close-button"
      data-close
      aria-label="Close modal"
      type="button"
   >
      <span aria-hidden="true">&times;</span>
   </button>
</div>

<div
   id='sign-invalidation-confirm'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
>
   <div
      class="columns"
      style='float:left; margin:12px 12px 30px 0; padding-right: 0.4rem; line-height: 1.75em;'
   >
      Modifying the field will cause all completed signatures and initials on the page to be removed from the document
      and require all signees to sign the document and initial the page again. <br /> <br /> Are you sure you want to
      modify the field?
   </div>
   <div
      class="mobile-app-icon-bar row"
      style="padding-bottom: 1em"
   >
      <div class="large-6 medium-6 small-6 columns align-bottom left">
         <button
            id="btnConfirm"
            class="login-button"
         >Yes</button>
      </div>
      <div class="large-6 medium-6 small-6 columns align-bottom right">
         <button
            id="btnCancel"
            class="login-button"
         >Cancel</button>
      </div>
      <!-- <button id='btnConfirm'>
         <div><i class='fas fa-check'></i></div>
         <div>Yes</div>
      </button>
      <button id='btnCancel'>
         <div><i class='fas fa-ban'></i></div>
         <div>Cancel</div>
      </button> -->
   </div>
</div>
<div
   ID='popup-completed-main'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
>
   <div>
      You have completed all required signatures for this document.<br /><br />
      An email will be sent to you when the document has been completed by all parties and will be available in your
      online portal.<br /><br />
      Thanks!<br />
      <i>{{signableDocumentsSettings.PropertyName}}</i>
   </div>
   <div class="mobile-app-icon-bar">
      <button id='btnFinish'>
         <div>
            <i class='fas fa-check'></i>
         </div>
         <div>OK</div>
      </button>
   </div>
</div>

<div
   id='popup-completed-items'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
   style="max-width: 500px;"
>
   <div style="padding: inherit; margin-bottom: -5px; line-height: 1.75em;">
      All required fields, initials, and signatures have been completed.
   </div>
   <div style=" padding: inherit; padding-top: 0.5px; line-height: 1.75em;">
      Do you want to submit the finished document?
   </div>
   <div
      id="finishDocBtn"
      class="row"
      style="padding-bottom: 1em"
   >
      <br /><br />
      <div class="large-6 medium-6 small-6 columns align-bottom left">
         <button
            id="btnFinishYes"
            class=" login-button"
         >Yes
         </button>
      </div>
      <div class="large-6 medium-6 small-6 columns align-bottom right">
         <button
            id="btnFinishNo"
            class="login-button"
         >No
         </button>
      </div>
   </div>
</div>
<div
   ID='text-overflow'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
>
   <div>
      The text entered cannot be fully displayed. Please reduce the text to fit the field.
   </div>
   <div class="mobile-app-icon-bar">
      <button id='btnOk'>
         <div><i class='fas fa-check'></i></div>
         <div>OK</div>
      </button>
   </div>
</div>
<div
   ID='timeout-error'
   class="tiny reveal input-popup"
   data-reveal
   data-close-on-click="false"
>
   <button
      class="close-button"
      data-close
      aria-label="Close modal"
      type="button"
   >
      <span aria-hidden="true">&times;</span>
   </button>
   <div>
      There was an issue with your request. Click on reload to refresh the page.
   </div>
   <div class="mobile-app-icon-bar">
      <button id='btnReload'>
         <div><i class='fas fa-refresh'></i></div>
         <div>Reload</div>
      </button>
   </div>
</div>