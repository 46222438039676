import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { FilterHelper } from "projects/libraries/owa-gateway-sdk/src/lib/core/filter-helper";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { ExpressSystemSettingField } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-system-setting-field.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { QuickFilterType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/quick-filter-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-types.model";
import { FilterFieldInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field-information.model";
import { QuickFilterDefinitionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/quick-filter-definition.model";

import { errorInDevMode, warnInDevMode } from "../utils/logging";
import { FilterDataTypes } from "./filter-data-types.enum";
import { FilterValueType } from "./filter-value.types";

/* eslint-disable guard-for-in */
export class QuickFilterModel {
   Label: string;

   EntityType: EntityType;

   ControlType: ExpressLayoutControlTypes;

   DataType: ExpressDataTypes;

   EnumerationType: string;

   InvertedFilter: boolean;

   InvertNullValueFilterOperation: boolean;

   CheckboxFilterValue: string;

   Hide: Boolean;

   hasDateRangeOtherOptions: boolean;

   ValuesToInclude: string[];

   ValuesToExclude: string[];

   FilterType: QuickFilterType; // This is actually a hack to make GetOptions work in the quick filter control. Will be fixed by RMX-3331

   DataSourceFilters: Array<FilterOption>;

   // #region Filter Option Fields
   FilterName: string;

   FilterOperation: FilterOperations;

   SystemSettingFieldID: ExpressSystemSettingField;

   SystemSettingID: number;

   StaticValues: Map<string, string>;

   AddBlankItem: boolean = false;

   BlankItemOverride: SelectorItemModel | null = null;

   OrderAlphabetically: boolean = false;

   OrderingOptions: Array<string>;

   set Value(value: FilterValueType | null) {
      if (value === undefined) {
         errorInDevMode(`Invalid quick filter value! {undefined} not allowed.`);
         return;
      }

      if (value == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         this._values = null;
      } else {
         const convertedValue = FilterHelper.verifyAndConvertValueForDataType(value, this.DataType);
         this._values = [convertedValue];
      }
   }

   get Value(): FilterValueType | null {
      if (!this._values) {
         return null;
      }

      return this._values[0];
   }

   set Values(values: FilterValueType[] | null) {
      if (!this.validFilterValueArray(values)) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         this._values = null;
         return;
      }

      if (values == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         this._values = null;
      } else {
         this._values = values.map((value) => FilterHelper.verifyAndConvertValueForDataType(value, this.DataType));
      }
   }

   get Values(): FilterValueType[] | null {
      return this._values;
   }

   get hasValue() {
      return this.Value != null;
   }

   get hasValues() {
      return this._values.length > 0;
   }

   private _values: FilterValueType[];
   // #endregion

   constructor(dataType: ExpressDataTypes) {
      this.DataType = dataType;
      this.Values = null;
   }

   static initializeEmptyFilterDefinitionModel(
      layoutControlType?: ExpressLayoutControlTypes,
      filterDataType?: FilterDataTypes
   ): QuickFilterDefinitionModel {
      const filterDefinition = new QuickFilterDefinitionModel();
      filterDefinition.FilterFieldInformationModel = new FilterFieldInformationModel();
      filterDefinition.FilterFieldInformationModel.DataType = new DataTypes();

      if (layoutControlType) {
         filterDefinition.ControlType = layoutControlType;
      }

      if (filterDataType) {
         filterDefinition.FilterFieldInformationModel.DataType.Name = FilterDataTypes[filterDataType];
      }

      return filterDefinition;
   }

   static fromTransferModel(quickFilterDefinition: QuickFilterDefinitionModel): QuickFilterModel {
      const filterDataType = this.convertQuickFilterDefinitionDataTypeToFilterDataTypes(quickFilterDefinition);
      let dataType = quickFilterDefinition.DataType;

      if (!dataType) {
         dataType = FilterHelper.convertFilterDataTypeToExpressDataType(filterDataType);
      }

      const quickFilter = new QuickFilterModel(dataType);

      quickFilter.Label = quickFilterDefinition.Label;
      quickFilter.ControlType = quickFilterDefinition.ControlType;
      quickFilter.FilterOperation = quickFilterDefinition.FilterOperation;
      quickFilter.FilterType = quickFilterDefinition.FilterType;
      quickFilter.EntityType = quickFilterDefinition.EntityType;
      quickFilter.EnumerationType = quickFilterDefinition.EnumerationType;
      quickFilter.FilterName = quickFilterDefinition.FilterFieldInformationModel.FilterDefinition;
      quickFilter.SystemSettingFieldID = ExpressSystemSettingField[quickFilterDefinition.QuickFilterMemoryField];
      quickFilter.Hide = quickFilterDefinition.Hide;
      quickFilter.hasDateRangeOtherOptions = quickFilterDefinition.hasDateRangeOtherOptions;
      quickFilter.OrderingOptions = quickFilterDefinition.OrderingOptions;

      if (quickFilterDefinition.ValuesToInclude) {
         quickFilter.ValuesToInclude = [...quickFilterDefinition.ValuesToInclude];
      }

      if (quickFilterDefinition.ValuesToExclude) {
         quickFilter.ValuesToExclude = [...quickFilterDefinition.ValuesToExclude];
      }

      if (quickFilterDefinition.StaticValues) {
         quickFilter.StaticValues = new Map<string, string>();
         const staticValues = quickFilterDefinition.StaticValues as { [key: string]: string };
         for (const key in staticValues) {
            quickFilter.StaticValues.set(key, staticValues[key]);
         }
      }

      quickFilter.InvertedFilter = quickFilterDefinition.InvertedFilter;
      quickFilter.InvertNullValueFilterOperation = quickFilterDefinition.InvertNullValueFilterOperation;
      quickFilter.CheckboxFilterValue = quickFilterDefinition.CheckboxFilterValue;

      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOption[] | null' is not assignable to ... Remove this comment to see the full error message
      quickFilter.DataSourceFilters = quickFilterDefinition.DataSourceFilters
         ? quickFilterDefinition.DataSourceFilters.map(FilterOption.FromExpressControlDataSourceFilterModel)
         : null;

      let values = quickFilterDefinition.Values;
      if (quickFilterDefinition.Value && !(quickFilterDefinition.Values && quickFilterDefinition.Values.length)) {
         values = [quickFilterDefinition.Value];
      }

      if (values && values.length) {
         if (quickFilter) {
            quickFilter.Values = FilterHelper.convertFilterValuesByDataType(values, dataType);
         }
      } else {
         quickFilter.Values = null;
      }

      return quickFilter;
   }

   static convertQuickFilterDefinitionDataTypeToFilterDataTypes(
      quickFilterDefinition: QuickFilterDefinitionModel
   ): FilterDataTypes {
      if (
         !(
            quickFilterDefinition.FilterFieldInformationModel &&
            quickFilterDefinition.FilterFieldInformationModel.DataType &&
            quickFilterDefinition.FilterFieldInformationModel.DataType.Name
         )
      ) {
         warnInDevMode(`${this.name} - FilterDataType not set for quickFilterDefinition`, quickFilterDefinition);
         return FilterDataTypes.String;
      }

      return FilterHelper.convertFilterFieldDataTypeToFilterDataTypes(
         quickFilterDefinition.FilterFieldInformationModel.DataType.Name
      );
   }

   /**
    * Checks if value array is valid filter array. Checks if values in array are valid filter values.
    * @param values The filter values to check.
    *
    * @returns true or false
    * @example
    *
    * [""]
    * // => true
    *
    * null
    * // => true
    *
    * undefined
    * // => false
    *
    * []
    * // => false
    *
    * [null]
    * // => false
    *
    * [undefined]
    * // => false
    *
    */
   private validFilterValueArray(values: Array<FilterValueType> | null | undefined): boolean {
      const errorMessage = "Invalid quick filter values!";

      if (values === undefined) {
         errorInDevMode(`${errorMessage} { undefined } not allowed.`);
         return false;
      }

      if (values) {
         if (values.length === 0) {
            errorInDevMode(`${errorMessage} { [] } not allowed.`);
            return false;
         }

         for (let i = 0; i < values.length; i++) {
            const val = values[i];
            if (val == null) {
               errorInDevMode(`${errorMessage} { [${val}] } not allowed.`);
               return false;
            }
         }
      }

      return true;
   }
}
