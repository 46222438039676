////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ReportFieldDataType {
   Long = 1,
   Decimal = 2,
   Date = 3,
   Bool = 4,
   Int = 5,
   String = 6,
   None = -1,
}
