import { Injectable } from "@angular/core";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";

@Injectable()
export class ReportParameterDependencyService {
   private dependentParameters: Map<number, Array<number>>;
   constructor() {
      this.dependentParameters = new Map([
         [
            Report.ReceiptsBreakdown,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.ChargedBreakdown,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.RentRoll,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.EagleRockRentRoll,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.DemattheisMTDPaymentSummary,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.GulfChargeBreakdown,
            [
               ReportParameter.ChargeTypeOrder,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.EndDate,
               ReportParameter.StartDate,
            ],
         ],
         [
            Report.OccupancyList,
            [ReportParameter.NonAssetORIUnitsToInclude, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [
            Report.UnitExpenseDetail,
            [ReportParameter.UNITIDS, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [
            Report.VendExpense,
            [ReportParameter.UNITIDS, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [
            Report.UtilHistoricalConsumption,
            [
               ReportParameter.NonAssetORIUnitsToInclude,
               ReportParameter.CUSTIDS,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
            ],
         ],
         [
            Report.HorizonLandUnitGeneralLedger,
            [ReportParameter.UNITIDS, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [
            Report.PaymentCoupon,
            [
               ReportParameter.CUSTIDS,
               ReportParameter.PropertyIDs,
               ReportParameter.SHOWINACTIVEPROPS,
               ReportParameter.POSTINGPERIOD,
            ],
         ],
         [
            Report.LoanHistory,
            [ReportParameter.CUSTIDS, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [
            Report.JHStatement,
            [ReportParameter.CUSTIDS, ReportParameter.PropertyIDs, ReportParameter.SHOWINACTIVEPROPS],
         ],
         [Report.ePayReportTenant, [ReportParameter.EPAYBATCHES, ReportParameter.AsOfDate]],
         [Report.ePayBreakdown, [ReportParameter.EPAYBATCHES, ReportParameter.AsOfDate]],
         [
            Report.DepositBreakdown,
            [ReportParameter.DEPOSITS, ReportParameter.StartDate, ReportParameter.EndDate, ReportParameter.BANKS],
         ],
         [Report.DepositDetail, [ReportParameter.DepositID, ReportParameter.StartDate, ReportParameter.EndDate]],
         [Report.Owner1099Breakdown, [ReportParameter.OWNERIDS, ReportParameter.AsOfDate]],
         [Report.OwnerBankBalance, [ReportParameter.OWNERIDS, ReportParameter.AsOfDate]],
         [Report.OwnerPayables, [ReportParameter.OWNERIDS, ReportParameter.AsOfDate]],
         [
            Report.OwnerAgedPayables,
            [
               ReportParameter.OWNERIDS,
               ReportParameter.AsOfDate,
               ReportParameter.BATCHOWNERSEXCLUDEEMPTY,
               ReportParameter.OwnersExcludeEmpty,
            ],
         ],
         [Report.OwnerBalanceSheet, [ReportParameter.OWNERIDS, ReportParameter.AsOfDate]],
         [Report.KramerConsolidatedBalanceSheet, [ReportParameter.OWNERIDS, ReportParameter.AsOfDate]],
         [
            Report.HistNotes,
            [ReportParameter.CustomerStatus, ReportParameter.HISTNOTESFILTER, ReportParameter.IncludeOwnerProspects],
         ],
         [Report.UtilConsumption, [ReportParameter.StartDate, ReportParameter.EndDate, ReportParameter.BEFOREPOSTING]],
         [Report.CommercialRentRoll, [ReportParameter.SHOWRENTESCALATION, ReportParameter.IncludePastEscalations]],
         [
            Report.UtilCustomerConsumption,
            [ReportParameter.StartDate, ReportParameter.EndDate, ReportParameter.BEFOREPOSTING],
         ],
         [
            Report.UtilReconciliation,
            [ReportParameter.StartDate, ReportParameter.EndDate, ReportParameter.BEFOREPOSTING],
         ],
         [Report.AssetListing, [ReportParameter.VendorIDs, ReportParameter.AssetUnassignedVendors]],
         [Report.AssetProfile, [ReportParameter.VendorIDs, ReportParameter.AssetUnassignedVendors]],
         [Report.AssetMaintSchedule, [ReportParameter.VendorIDs, ReportParameter.AssetUnassignedVendors]],
         [Report.AssetWarranty, [ReportParameter.VendorIDs, ReportParameter.AssetUnassignedVendors]],
         [Report.Owner1096, [ReportParameter.OWNERIDS, ReportParameter.YEAR1099]],
         [Report.Owner1099, [ReportParameter.OWNERIDS, ReportParameter.YEAR1099]],
         [Report.Owner1099Breakdown, [ReportParameter.OWNERIDS, ReportParameter.YEAR1099]],
         [Report.CheckDepositList, [ReportParameter.BatchProps, ReportParameter.BATCHBANKS]],
         [Report.LakeshoreCheckDepositList, [ReportParameter.BatchProps, ReportParameter.BATCHBANKS]],
         [Report.CreditCardTransactionListing, [ReportParameter.BatchProps, ReportParameter.BATCHCREDITCARDS]],
         [Report.ProjectStatus, [ReportParameter.PROJECTS, ReportParameter.PROJECTTYPE]],
         [Report.LakeshoreDepositBreakdown, [ReportParameter.DepositsAndJournals, ReportParameter.IncludeJournals]],
         [Report.BalanceSheetUnit, [ReportParameter.PropertyID, ReportParameter.UNITID]],
         [Report.ProfitLossUnitDetail, [ReportParameter.PropertyID, ReportParameter.UNITID]],
         [
            Report.MinnCRP,
            [
               ReportParameter.CRPPhone,
               ReportParameter.CRPOverridePhone,
               ReportParameter.CRPOverridePhoneChecked,
               ReportParameter.CRPPreparedByAgent,
               ReportParameter.CRPPreparerName,
               ReportParameter.CRPPreparerInformationOverridePhoneChecked,
               ReportParameter.CRPPreparerDefaultPhone,
               ReportParameter.CRPPreparerInformationOverridePhone,
            ],
         ],
         [
            Report.MinnCRP2018,
            [ReportParameter.CRPPhone, ReportParameter.CRPOverridePhone, ReportParameter.CRPOverridePhoneChecked],
         ],
         [Report.GeneralLedgerUnit, [ReportParameter.PropertyID, ReportParameter.UNITID]],
         [Report.Owner1099CopyB, [ReportParameter.BATCHOWNERSEXCLUDEEMPTY, ReportParameter.OwnersExcludeEmpty]],
         [
            Report.AgedPayables,
            [
               ReportParameter.PROPSEXCLUDEEMPTY,
               ReportParameter.BATCHPROPSEXCLUDEEMPTY,
               ReportParameter.BANKBALANCESELECTEDONLY,
               ReportParameter.BALANCESELECTEDONLY,
            ],
         ],
         [Report.TenantViolationsListing, [ReportParameter.ViolationNotesOption, ReportParameter.NUMBERNOTES]],
         [
            Report.FixedAssetSchedule,
            [ReportParameter.AssetUnassignedVendors, ReportParameter.VendorIDs, ReportParameter.IncludeInactiveVendors],
         ],
         [
            Report.MobileAgedPayables,
            [
               ReportParameter.BATCHPROPSEXCLUDEEMPTY,
               ReportParameter.PROPSEXCLUDEEMPTY,
               ReportParameter.BANKBALANCESELECTEDONLY,
               ReportParameter.BALANCESELECTEDONLY,
            ],
         ],
         [Report.JobProfitLoss, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobProfitLossComparison, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobGeneralLedger, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobBalanceSheet, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobBudget, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobCashFlow, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobBalanceSheetComp, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.JobBudgetComparison, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.EagleRockJobCostingOverview, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.SalsaJobGeneralLedger, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.FiscalYearBudget, [ReportParameter.BUDGETTYPE, ReportParameter.AmountBasis]],
         // [Report.THGJobBudgetAndUserDefinedFields, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         // [Report.EagleRockPropertyCapitalProjectOverview, [ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs]],
         [Report.OwnerActivity, [ReportParameter.OAHideMgtFeeBreakdown, ReportParameter.OAMgtFees]],
      ]);
   }
   getDependencies(reportID: number): Array<number> {
      if (this.dependentParameters.has(reportID)) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number[] | undefined' is not assignable to t... Remove this comment to see the full error message
         return this.dependentParameters.get(reportID);
      } else {
         return [];
      }
   }
}
