export class PasswordResetModel {
   Token: string;
   LocationID: number;
   WebUserID: number;
   Username: string;
   Newpassword: string;
   ConfirmPassword: string;
   Currentpassword: string;
   IsForceReset: boolean;
}
