<owa-dashboard-tile
   [title]="'Inspection Reports'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'inspectionreports'"
>
   <lcs-table [dataSource]="$any(observableInspections | async)">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="inspectionColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: inspectionColumns"
      ></tr>
      <ng-container
         lcsColumnDef="InspectionDate"
         [width]="8"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Inspection Date </th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >{{ row.InspectionDate | date: "MM/dd/yyyy" }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="Property"
         [width]="12"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Property</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >{{ row.Property }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="Unit"
         [width]="6"
         [priority]="3"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Unit</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="index"
         >{{ row.Unit }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="Report"
         [width]="2"
         [priority]="3"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="index"
         >
            <div *ngIf="row.InspectionStatus === 'Closed'">
               <a
                  href="{{ row.InspectionURL }}"
                  target="_blank"
               >
                  <i class="material-icons">link</i>
               </a>
            </div>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="NewRow"
         [width]="1"
         [priority]="4"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div *ngIf="row.IsNew">
               <span class="notification-icon"></span>
            </div>
         </td>
      </ng-container>
   </lcs-table>

   <ng-template #noInspections>
      <div class="no-result">No Inspection Reports found</div>
   </ng-template>
</owa-dashboard-tile>