import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReportParameterRendererComponentBase } from "@lcs/reports/report-parameters/report-parameter-override-component.base";
import { Subject } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-bank-account-report-parameter",
   templateUrl: "bank-account-report-parameter.component.html",
})
export class BankAccountReportParameterComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() name: string;

   @Input() displayName: string;

   @Input() parameter: ReportReportParameterViewModel;

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeReportParameterValue();
      if (!this.displayName && this.parameter) {
         this.displayName = ReportParameterRendererComponentBase.getDisplayNameFromReportReportParameter(
            this.parameter.toReportReportParameterModel()
         );
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onBankSelectorValueChange(value: number) {
      this.reportParameterValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );
   }
}
