<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || hasAsk"
   >
      {{ displayName }}
   </label>
</div>
<lcs-checkbox-report-parameter
   *ngFor="let parameter of reportReportParameters"
   [parameter]="parameter"
   [hasAsk]="hasAsk"
></lcs-checkbox-report-parameter>