import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { EnumerationInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/enumeration-information.model";
import { EnumerationInformationService as ApiEnumerationInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/services/enumeration-information.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, map, Observable, of, Subject, takeUntil } from "rxjs";

/* eslint-disable import/no-deprecated */
@Injectable({
   providedIn: "root",
})
export class EnumerationInformationService implements SessionCacheProvider, OnDestroy {
   cacheKey = "EnumerationInformationService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   private enumerationCache: Map<string, Array<EnumerationInformationModel>>;

   private unsubscribe = new Subject<void>();

   constructor(
      private apiEnumerationInformationService: ApiEnumerationInformationService,
      private cacheMonitorService: CacheMonitorService,
      private sessionStatusService: SessionStatusService
   ) {
      this.enumerationCache = new Map<string, Array<EnumerationInformationModel>>();
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache();
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   clearCache() {
      this.enumerationCache.clear();
      this.cachePopulated.next(false);
   }

   populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      this.cachePopulated.next(true);
   }

   public getEnumerationInformation(
      name: string,
      forceRefresh: boolean = false
   ): Observable<Array<EnumerationInformationModel>> {
      if (this.enumerationCache.has(name) && !forceRefresh) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<EnumerationInformationModel[] | u... Remove this comment to see the full error message
         return of(this.enumerationCache.get(name));
      }

      return this.apiEnumerationInformationService.getEnumerationInformationCollection(name).pipe(
         map((response) => {
            const enumerationInformation = response;
            this.enumerationCache.set(name, response);
            return enumerationInformation;
         })
      );
   }

   public getEnumerationInformationForValue(name: string, value?: number): Observable<EnumerationInformationModel> {
      let result: Observable<EnumerationInformationModel>;
      if (value) {
         result = this.getEnumerationInformation(name).pipe(
            map((r) => {
               return r.filter((v) => v.Value === value)[0];
            })
         );
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'result' is used before being assigned.
      return result;
   }

   public getEnumerationInformationForValues(
      name: string,
      values?: Array<number>
   ): Observable<Array<EnumerationInformationModel>> {
      let result: Observable<Array<EnumerationInformationModel>>;
      if (values && values.length > 0) {
         result = this.getEnumerationInformation(name).pipe(
            map((r) => {
               return r.filter((e) => values.some((v) => v === e.Value));
            })
         );
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'result' is used before being assigned.
      return result;
   }

   public getEnumerationInformationForValueFromCache(name: string, value?: number): EnumerationInformationModel {
      if (value !== null) {
         if (this.enumerationCache.has(name)) {
            // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            const matchingResults = this.enumerationCache.get(name).filter((v) => v.Value === value);
            if (matchingResults.length > 0) {
               return matchingResults[0];
            }
         }
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Enumeration... Remove this comment to see the full error message
      return null;
   }
}
