<owa-layout [headerTitle]="'Bills'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{filter.Label}} {{filter.shortOperationLabel()}}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{filter.DisplayValue}}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="billFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>
      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{results | async}} of {{totalResults | async}} Bills
      </div>
      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(observableBills | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>
               <ng-container
                  lcsColumnDef="Payee"
                  [width]="10"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Payee</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{row.Payee}}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="PropertyName"
                  [width]="15"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{row.PropertyName}}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="TransactionDate"
                  [width]="6"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Bill Date </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{
                        row.TransactionDate | date: "MM/dd/yyyy"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="DueDate"
                  [width]="6"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Due Date </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{
                        row.DueDate | date: "MM/dd/yyyy"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="IsFullyAllocated"
                  [width]="4"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Paid</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="boolean has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{
                        row.IsFullyAllocated ? "Yes" : "No"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="CheckNumber"
                  [width]="6"
                  [priority]="6"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Check Number</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <div
                        class="checkNumberIcon"
                        id="checkNumberIcon_{{ row.ID }}"
                     >
                        <div
                           matTooltip="{{ row.CheckNumber }}"
                           matTooltipPosition="above"
                           matTooltipClass="email-tooltip"
                        >
                           <i class="info-icon material-icons">error_outline</i>
                        </div>
                     </div>
                     <a
                        owaCheckNumberIcon
                        [iconElementID]="row.ID"
                        class="drilldown-link checkNumber"
                        [routerLink]="['/bills', row.ID]"
                     >{{ row.CheckNumber }}
                     </a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Amount"
                  [width]="4"
                  [priority]="8"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Amount</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="numeric has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/bills', row.ID]"
                     >{{
                        row.Amount | currency: "USD":"symbol":"1.2-2"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="ServiceManagerIssueID"
                  [width]="4"
                  [priority]="8"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                     [style.display]="anyServiceIssues ? '' : 'none'"
                  >Issue#</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="numeric has-drilldown"
                     [style.display]="anyServiceIssues ? '' : 'none'"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/serviceissues', row.ServiceManagerIssue.ServiceManagerIssueID]"
                        *ngIf="showLink(row.ServiceManagerIssue)"
                     >
                        <div class="link">{{
                           row.ServiceManagerIssue?.ServiceManagerIssueID
                           }}</div>
                     </a>
                  </td>
               </ng-container>
            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any((observableBills | async)?.length) < $any((totalResults | async))"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>