import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ExpressFooterButtonModel } from "./express-footer-button.model";

@Component({
   selector: "lcs-collapsed-footer-buttons",
   templateUrl: "collapsed-footer-buttons.component.html",
})
export class CollapsedFooterButtonComponent {
   @Input() set footerButtons(val: Array<ExpressFooterButtonModel>) {
      if (val) {
         this._footerButtons = val;
      }
   }

   get footerButtons(): Array<ExpressFooterButtonModel> {
      return this._footerButtons;
   }
   @Input() maxNumberOfButtons: number = 3;
   @Output() buttonClick = new EventEmitter<ExpressFooterButtonModel>();
   private _footerButtons: Array<ExpressFooterButtonModel>;
   constructor() {}
   onButtonClick(button: ExpressFooterButtonModel) {
      if (!button.disabled) {
         this.buttonClick.emit(button);
      }
   }
}
