////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum UserDefinedFieldRelatedObjectTypes {
   NotSet = 0,
   Tenant = 1,
   Prospect = 2,
   Property = 3,
   Unit = 4,
   Vendor = 6,
   Owner = 7,
   Contact = 8,
   User = 10,
   Lease = 11,
   ServiceManagerIssue = 22,
   Loan = 23,
   CommercialLeaseRenewal = 29,
   NonCommercialLeaseRenewal = 30,
   InventoryItem = 36,
   Asset = 47,
   ServiceManagerRecurringIssue = 99,
   Job = 120,
   OwnerProspects = 121,
   System = 192,
}
