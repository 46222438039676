import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   NgZone,
   OnDestroy,
   Optional,
   Output,
   TemplateRef,
   ViewChild,
} from "@angular/core";
import { ControlContainer, NgControl, UntypedFormControl } from "@angular/forms";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { MobileFocusOverlayService } from "@lcs/mobile-focus-overlay/mobile-focus-overlay.service";
import { MouseEvents } from "@lcs/overlay-panel/mouse-events.enum";
import { OverlayPanelComponent } from "@lcs/overlay-panel/overlay-panel.component";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { KEY_DOWN, KEY_ESCAPE, KEY_TAB, KEY_UP } from "keycode-js";
import { fromEvent, map, Subject, takeUntil } from "rxjs";
import * as mouse from "mouse-event";

import { ValueComparerHelper } from "../helpers/value-comparer.helper";

@Component({
   selector: "lcs-select",
   templateUrl: "./select.component.html",
   styleUrls: ["select.component.scss"],
})
export class SelectComponent extends ValueAccessorBase<any> implements AfterViewInit, OnDestroy {
   static noItemsFoundMessage = "No Results Found";

   @Input() alignOverlayPanelToRight = false;

   @Input() additionalMessage: string;

   @Input() errorMessage: string | null;

   @Input() closedIcon = "expand_more";

   @Input() openIcon = "expand_less";

   @Input() optionalIcon = "";

   @Input() isSearching = true;

   @Input() selectorInputTemplate: TemplateRef<any>;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() parentOverlayPanelRef: OverlayPanelComponent;

   @Input() valueComparer: (selectedItemValue: any, value: any) => boolean;

   @Input() placeholder: string = "";

   @Input() allowMobileFocusMode?: boolean = true;

   @Input() isLabelDropdown = false;

   @Input() tooltipOverride: string;

   @Input() includeDescInDisplay: boolean;

   @Input() set startOpen(value: boolean) {
      if (value) {
         this.showOverlay();
      }
   }
   @Input() isEntitySearchable = false;

   @Input() showIcon = true;

   @Input() selectFirstItemOnFocusLost = false;

   @Input() defocused = false;

   @Input() isLabelSelector = false;

   @Input() onlyEmitSelectionOnUserInput = false;

   @Output() searchTextChanged = new EventEmitter<string>();

   @Output() selectionChanged = new EventEmitter<number>();

   @Output() selectedSelectorItemChanged = new EventEmitter<SelectorItemModel>();

   @ViewChild("userInputWrapper", { static: true }) userInputWrapper: ElementRef;
   @ViewChild("inputEl") inputEl: ElementRef;

   set selectorItems(value: Array<SelectorItemModel>) {
      this._selectorItems = value;

      this.itemSet = new SelectorItemSetModel();
      this.itemSet.items = this._selectorItems;

      if (!this.inputFocused) {
         this.updateDisplayValue(this.value);
      } else if (this.selectFirstItemOnFocusLost && this.showOverlayPanel) {
         this.setSelectedIndex(0);
      } else {
         this.resetSelectedIndex();
      }

      this.changeDetectorRef.markForCheck();
   }

   get selectorItems(): Array<SelectorItemModel> {
      return this._selectorItems;
   }

   overlayPanelStaticWidth: number;

   displayValue: string;

   inputFocused: boolean = false;

   selectedOverlayItemIndex: number | null;

   showOverlayPanel: boolean;

   hideOverlayOnBlur: boolean = true;

   control: UntypedFormControl;

   itemSet: SelectorItemSetModel;

   selectFirstItemOnSearch: boolean = false;

   focused = new Subject<void>();

   overrideInitialValue = false;

   get selectedItem(): SelectorItemModel {
      return this._selectedItem;
   }

   private hasSetInitialValue = false;

   private _selectorItems: Array<SelectorItemModel>;

   private _selectedItem: SelectorItemModel;

   private selectedOverlayItem: SelectorItemModel | null;

   private unsubscribe = new Subject<void>();

   constructor(
      private ngZone: NgZone,
      @Optional() private formRegistrationService: FormRegistrationService,
      private mobileFocusOverlayService: MobileFocusOverlayService,
      private elRef: ElementRef,
      @Optional() private controlContainer: ControlContainer,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.valueComparer = ValueComparerHelper.simpleComparer;

      this.registerOnValueWritten((value) => {
         this.updateDisplayValue(value);
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      if (this.formRegistrationService) {
         this.formRegistrationService.removeControl(this.control);
      }
   }

   ngAfterViewInit() {
      setTimeout(() => {
         if (this.controlContainer && this.name) {
            this.control = this.controlContainer.control?.get(this.name) as UntypedFormControl;
         }

         if (!this.control && this.ngControl.control) {
            this.control = this.ngControl.control as UntypedFormControl;
         }

         if (this.control && this.formRegistrationService) {
            this.formRegistrationService.addControl(this.name, this.control, this.displayName, this.path);
            if (!this.displayValue && !this.overrideInitialValue) {
               this.updateDisplayValue(this.control.value);
            }
         }
      });

      const inputElement = this.userInputWrapper.nativeElement.querySelector("input");

      this.ngZone.runOutsideAngular(() => {
         fromEvent<KeyboardEvent>(inputElement, "keydown")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
               this.ngZone.run(() => {
                  if (
                     this.isLabelDropdown &&
                     !(
                        event.keyCode === KEY_TAB ||
                        event.key === "ArrowDown" ||
                        event.keyCode === KEY_DOWN ||
                        event.key === "ArrowUp" ||
                        event.keyCode === KEY_UP
                     )
                  ) {
                     event.preventDefault();
                  } else if (
                     event.key === "ArrowDown" ||
                     event.keyCode === KEY_DOWN ||
                     event.key === "ArrowUp" ||
                     event.keyCode === KEY_UP
                  ) {
                     if (!this.showOverlayPanel) {
                        this.showOverlay();
                     } else {
                        if (event.key === "ArrowUp" || event.keyCode === KEY_UP) {
                           if (this.selectedOverlayItemIndex != null && this.selectedOverlayItemIndex > 0) {
                              this.setSelectedIndex(this.selectedOverlayItemIndex - 1);
                           } else {
                              this.setSelectedIndex(0);
                           }
                        } else {
                           if (this.selectedOverlayItemIndex == null) {
                              this.setSelectedIndex(0);
                           } else {
                              if (this.selectedOverlayItemIndex < this.selectorItems.length - 1) {
                                 this.setSelectedIndex(this.selectedOverlayItemIndex + 1);
                              }
                           }
                        }
                     }
                     event.preventDefault();
                     event.stopPropagation();
                  } else if (event.key === "Tab" || event.keyCode === KEY_TAB) {
                     this.selectCurrentItem();
                     this.hideOverlay();
                  } else if (event.key === "Escape" || event.keyCode === KEY_ESCAPE) {
                     this.hideOverlay();
                     this.clearOverlaySelectedItem();
                     this.selectCurrentItem();
                  }
               });
            });

         fromEvent(inputElement, "mousedown")
            .pipe(
               map((event) => mouse.buttons(event)),
               takeUntil(this.unsubscribe)
            )
            .subscribe((button) => {
               if (button === MouseEvents.middleClick || button === MouseEvents.rightClick) {
                  return;
               }

               this.showOverlay();
            });
      });

      this.mobileFocusOverlayService.mobileFocusModeAllowed.subscribe((isAllowed) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isAllowed) {
               if (this.inputFocused || this.showOverlayPanel) {
                  this.tryRequestMobileFocusMode();
               } else {
                  this.mobileFocusOverlayService.cancelMobileFocusModeRequest();
               }
            } else if (this.mobileFocusOverlayService.inMobileFocusMode()) {
               this.mobileFocusOverlayService.endMobileFocusMode();
            }
         }
      });

      this.mobileFocusOverlayService.mobileFocusModeSet.subscribe((isSet) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isSet) {
               this.hideOverlayOnBlur = false;
               this.hideOverlay();
               setTimeout(() => {
                  this.showOverlay();
               });
            } else {
               this.hideOverlayOnBlur = true;
               this.hideOverlay();
               this.refreshDisplayValue();
            }
         }
      });
      this.calculateOverlay();
   }

   onResize() {
      this.calculateOverlay();
   }

   inputClosed(overlayVisible) {
      this.overrideInitialValue = false;
      if (overlayVisible === false) {
         setTimeout(() => {
            if (this.inputFocused === false) {
               this.searchTextChanged.emit("");
               if (this.selectedItem === null) {
                  this.selectCurrentItem();
               }
            }
         });
      }
   }
   /**
    * Use this method to set the focus in the input element of the selector component
    */
   selectFocus() {
      if (this.inputEl) {
         this.inputEl.nativeElement.focus();
      }
   }

   onFocusin() {
      if (this.defocused) {
         return;
      }
      this.inputFocused = true;
      this.focused.next();
      this.resetSelectedIndex();
      const input = this.userInputWrapper.nativeElement.querySelectorAll("input");
      if (input.length > 0) {
         input[0].select();
      }

      this.tryRequestMobileFocusMode();
   }

   onFocusout() {
      this.inputFocused = false;
      if (!this.mobileFocusOverlayService.inMobileFocusMode()) {
         this.refreshDisplayValue();
      }
      if (this.selectFirstItemOnFocusLost) {
         this.selectCurrentItem();
      }
   }

   onEnter(event: Event) {
      if (this.showOverlayPanel) {
         event.preventDefault();
         this.selectCurrentItem();
         event.stopPropagation();
         this.toggleOverlay();
         this.clearOverlaySelectedItem();
      }
   }

   onIconMousedown(event) {
      if (!this.inputFocused) {
         const focusable = this.userInputWrapper.nativeElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
         );
         if (focusable.length > 0) {
            focusable[0].focus();
         }
      }

      const input = this.userInputWrapper.nativeElement.querySelectorAll("input");
      if (input?.length > 0) {
         input[0].select();
      }

      this.toggleOverlay();
      event.stopPropagation();
      event.preventDefault();

      this.tryRequestMobileFocusMode();
   }

   onSearchTextChanged(searchText: string) {
      this.overrideInitialValue = false;
      this.displayValue = searchText;
      this.showOverlay();
      this.searchTextChanged.emit(searchText);

      if ((this.selectFirstItemOnSearch || this.selectFirstItemOnFocusLost) && this._selectorItems) {
         this.setSelectedIndex(0);
      }
   }

   selectItem(selectedItem: SelectorItemModel, fromUserClick = false, dirtyForm: boolean = true) {
      if (this.overrideInitialValue && this.hasSetInitialValue) {
         return;
      }
      this.hasSetInitialValue = true;
      this._selectedItem = selectedItem;
      this.updateDisplayValue(selectedItem.value);
      this.searchTextChanged.emit("");
      this.selectionChanged.emit(selectedItem.value);
      if (this.onlyEmitSelectionOnUserInput) {
         if (fromUserClick) {
            this.selectedSelectorItemChanged.emit(selectedItem);
         }
      } else {
         this.selectedSelectorItemChanged.emit(selectedItem);
      }
      if (!this.valueComparer(selectedItem.value, this.value)) {
         if (dirtyForm) {
            this.value = selectedItem.value;
         } else {
            this.innerValue = selectedItem.value;
         }
         if (fromUserClick) {
            this.clearOverlaySelectedItem();
            // clicking an item triggers the blur event on the input, but
            // that's before the value has changed. we call propagateTouched
            // again here so that the new value that was just set
            // gets emitted properly with updateOn: 'blur'
            this.propagateTouched();
         }
      }
   }

   refreshDisplayValue(forceRefresh?: boolean) {
      this.updateDisplayValue(this.value, forceRefresh);
   }

   open() {
      this.showOverlay();
   }

   private calculateOverlay(): void {
      if (this.userInputWrapper) {
         this.overlayPanelStaticWidth = Math.ceil(this.userInputWrapper.nativeElement.getBoundingClientRect().width);
      }
   }

   private updateDisplayValue(value: any, forceRefresh?: boolean) {
      let displayValue = "";
      if (forceRefresh === null || forceRefresh === undefined) {
         forceRefresh = false;
      }
      if (this.selectedItem && this.valueComparer(this.selectedItem.value, value)) {
         if (forceRefresh) {
            const selectedItem = this.selectorItems.find((i) => this.valueComparer(i.value, value));
            if (selectedItem) {
               this._selectedItem = selectedItem;
            }
         }
         displayValue = this.getDisplayName();
      } else if (this.selectorItems) {
         const selectedItem = this.selectorItems.find((i) => this.valueComparer(i.value, value));
         if (selectedItem) {
            this._selectedItem = selectedItem;
            displayValue = this.getDisplayName();
         }
      }
      this.displayValue = displayValue;
      this.changeDetectorRef.markForCheck();
   }

   private getDisplayName() {
      let displayValue = "";
      if (this.includeDescInDisplay) {
         displayValue = `${this.selectedItem.displayValue}`;
         if (this.selectedItem.additionalInfoValue) {
            displayValue += ` - ${this.selectedItem.additionalInfoValue}`;
         }
      } else {
         displayValue = this.selectedItem.displayValue ?? "";
      }
      return displayValue;
   }

   private selectCurrentItem() {
      if (this.selectedOverlayItem != null) {
         this.selectItem(this.selectedOverlayItem, true);
      } else {
         this.updateDisplayValue(this.value);
      }
   }

   private toggleOverlay() {
      if (this.showOverlayPanel) {
         this.hideOverlay();
      } else {
         this.showOverlay();
      }
   }

   private hideOverlay() {
      this.showOverlayPanel = false;
   }

   private showOverlay() {
      this.showOverlayPanel = true;
      this.resetSelectedIndex();
   }

   private setSelectedIndex(index: number) {
      if (this._selectorItems.length > 0 && index < this._selectorItems.length) {
         this.selectedOverlayItemIndex = index;
         this.selectedOverlayItem = this._selectorItems[index];
      } else {
         this.clearOverlaySelectedItem();
      }
   }

   private resetSelectedIndex() {
      if (!this._selectorItems || this._selectorItems.length === 0) {
         this.clearOverlaySelectedItem();
         return;
      }

      let matchingItem: SelectorItemModel | null | undefined = null;
      if (this.displayValue !== "") {
         // if displayValue is blank, then the user either deleted the current value, or it was never set
         // so we do not want to try matching against the current value.
         // If displayValue is not blank we first try to match on value incase multiple entries have the same displayValue but
         // different actual values so we select the correct one.
         matchingItem = this._selectorItems.find((i) => i.value === this.value);
      }
      if (!matchingItem) {
         // if we didn't find a match on value, we filter based on displayValue entered and select 1st by default
         const itemsWithSameDisplayValue = this._selectorItems.filter((i) =>
            // The simple comparer should be used here since we are only comparing display values, not the actual value
            ValueComparerHelper.simpleComparer(i.displayValue, this.displayValue)
         );
         if (itemsWithSameDisplayValue.length >= 1 && this.displayValue) {
            matchingItem = itemsWithSameDisplayValue[0];
         }
      }

      const index: number = matchingItem != null ? this._selectorItems.indexOf(matchingItem) : -1;
      if (index >= 0) {
         this.setSelectedIndex(index);
      }
   }

   private clearOverlaySelectedItem() {
      this.selectedOverlayItemIndex = null;
      this.selectedOverlayItem = null;
   }

   private tryRequestMobileFocusMode() {
      if (this.allowMobileFocusMode) {
         this.mobileFocusOverlayService.requestMobileFocusMode(this.elRef);
         if (this.mobileFocusOverlayService.isMobileFocusModeActive) {
            this.showOverlay();
         }
      }
   }
}
