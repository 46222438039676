import { LeasePeriod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/lease-period.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ChargeTypeOrderReportParameterModel {
   AsOfDate?: Date | null ;
   EndDate: Date;
   IsAllPropertySelected: boolean;
   IsRunByOwner: boolean;
   LeasePeriod: LeasePeriod;
   MetaTag?: string;
   OwnerIds: Array<number>;
   PropertyIds: Array<number>;
   Report: Report;
   ShowInactiveProperties: boolean;
   StartDate: Date;
}
