import { Directive, OnDestroy } from "@angular/core";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { Subject, takeUntil } from "rxjs";

import { SelectComponent } from "../components/select.component";
import { SelectorItemSearchHelper } from "../helpers/selector-item-search.helper";

@Directive()
export abstract class StaticSetSelectorDirectiveBase implements OnDestroy {
   set itemSet(val: Array<SelectorItemModel>) {
      this._itemSet = val;
      this.searchItems();
   }

   get itemSet(): Array<SelectorItemModel> {
      return this._itemSet;
   }

   private searchText: string = "";

   private _itemSet: Array<SelectorItemModel>;

   protected unsubscribe = new Subject<void>();

   constructor(protected selectComponent: SelectComponent) {
      this.selectComponent.searchTextChanged.pipe(takeUntil(this.unsubscribe)).subscribe((searchText) => {
         this.searchText = searchText ?? "";
         this.searchItems();
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private searchItems() {
      this.selectComponent.isSearching = true;

      const items = SelectorItemSearchHelper.search(this._itemSet, this.searchText);

      if (!items || items.length === 0) {
         this.selectComponent.errorMessage = SelectComponent.noItemsFoundMessage;
      } else {
         this.selectComponent.errorMessage = null;
      }

      this.selectComponent.selectorItems = items;
      this.selectComponent.isSearching = false;
   }
}
