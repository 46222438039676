import { Component, Input, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";

@Component({
   selector: "lcs-year-range-report-parameter",
   templateUrl: "year-range-report-parameter.component.html",
})
export class YearRangeReportParameterComponent implements OnInit, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this.reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   reportReportParameters: Array<ReportReportParameterViewModel>;

   startYearParameter: ReportReportParameterViewModel;

   endYearParameter: ReportReportParameterViewModel;

   hasDateRange = false;

   ngOnInit() {
      this.hasDateRange = this.reportReportParameters.some(
         (p) => p.ReportParameterID === ReportParameter.StartDate || p.ReportParameterID === ReportParameter.EndDate
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.startYearParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.STARTYEAR
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.endYearParameter = this.reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.ENDYEAR);
   }
}
