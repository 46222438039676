import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DialogRegistrationService } from "@lcs/dialog/services/dialog-registration.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { TableComponent } from "@lcs/table/table.component";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";
import { Subject, takeUntil } from "rxjs";

import { OWASessionModel } from "../session/models/owa-session.model";
import { OWASessionService } from "../session/owa-session.service";
import { LinkedAccountManagementService } from "./linked-account-management.service";

@Component({
   selector: "owa-linked-account-management",
   templateUrl: "./linked-account-management.component.html",
})
export class LinkedAccountManagementComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   columns: string[] = ["Name", "Nickname", "AccountID", "Default"];

   booleanType: any[] = [true, false];

   defaultOwnerID: number;

   availableAccounts: Array<OWAWebUserAccountModel>;

   saveButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   owaSessionInfo: OWASessionModel;

   accountTypeEnum = AccountType;

   private unsubscribe = new Subject<void>();

   constructor(
      private linkedAccountManagementService: LinkedAccountManagementService,
      private dialogRegistrationService: DialogRegistrationService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService
   ) {
      this.availableAccounts = new Array<OWAWebUserAccountModel>();
   }

   ngOnInit() {
      this.getData();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   getData() {
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((owaSessionInfo) => {
         if (owaSessionInfo) {
            this.availableAccounts = cloneDeep(owaSessionInfo.AvailableAccounts);
            this.availableAccounts = orderBy(this.availableAccounts, "WebUserAccountID");
            this.availableAccounts.forEach((element) => {
               if (element.Nickname === "") {
                  element.Nickname =
                     element.AccountType == AccountType.Owner
                        ? element.Owner.OwnerID + " - " + element.Owner.Name
                        : element.OwnerProspect.OwnerProspectID + " - " + element.OwnerProspect.Name;
               }
            });
            const defaultAccount = this.availableAccounts.find((f) => f.IsDefault);
            if (this.availableAccounts.find((f) => f.IsDefault) != null) {
               this.defaultOwnerID =
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  defaultAccount.AccountType == AccountType.Owner
                     ? this.availableAccounts.filter((f) => f.IsDefault)[0].Owner.OwnerID
                     : this.availableAccounts.filter((f) => f.IsDefault)[0].OwnerProspect.OwnerProspectID;
            } else {
               this.defaultOwnerID =
                  this.availableAccounts[0].AccountType == AccountType.Owner
                     ? this.availableAccounts[0].Owner.OwnerID
                     : this.availableAccounts[0].OwnerProspect.OwnerProspectID;
               this.availableAccounts[0].IsDefault = true;
            }
         }
      });
   }

   updateDefault(row: any, event) {
      this.defaultOwnerID = event;
      row.IsDefault = true;
      const nonDefaults =
         row.AccountType == AccountType.Owner
            ? this.availableAccounts.filter((f) => f.Owner.OwnerID !== this.defaultOwnerID)
            : this.availableAccounts.filter((f) => f.OwnerProspect.OwnerProspectID !== this.defaultOwnerID);
      if (nonDefaults != null) {
         for (const account of nonDefaults) {
            account.IsDefault = false;
         }
      }
   }

   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.saveButtonStatus = LcsProgressButtonStatus.InProgress;
         this.linkedAccountManagementService.saveAccounts(this.availableAccounts).subscribe(
            (res) => {
               this.saveButtonStatus = LcsProgressButtonStatus.Success;
               this.availableAccounts = res.body;
               this.dialogRegistrationService.closeAllModals();
               this.owaSessionService.clearCache(true);
               this.owaSessionService.populateCache();
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
               this.saveButtonStatus = LcsProgressButtonStatus.Error;
            }
         );
      } else {
         this.saveButtonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }
}
