import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class Vendor1099Service {
   static readonly Get1099YearsResource = "Get1099Years";
   static readonly Vendor1099CopyBYearsResource = "Vendor1099CopyBYears";
   static readonly endpoint = "Vendor1099View";
   constructor(private apiService: ApiService) {}
   getGet1099YearsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         Vendor1099Service.endpoint + "/Get1099Years",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGet1099YearsCollection(additionalParameters?: Array<string>): Observable<Array<number>> {
      return this.apiService.getCollection(this.getGet1099YearsCollectionUrl(additionalParameters));
   }

   getVendor1099CopyBYearsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         Vendor1099Service.endpoint + "/Vendor1099CopyBYears",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor1099CopyBYearsCollection(additionalParameters?: Array<string>): Observable<Array<number>> {
      return this.apiService.getCollection(this.getVendor1099CopyBYearsCollectionUrl(additionalParameters));
   }
}
