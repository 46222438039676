import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable, isDevMode, OnDestroy } from "@angular/core";
import { GuardsCheckEnd, Router } from "@angular/router";
import { SessionCheckResult } from "@lcs/authentication/session-check-result.enum";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PostableGetTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/postable-get-transfer.model";
import { RegisterSearchPostBodyTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/register-search-post-body-transfer.model";
import { RegisterSearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/register-search-post-body.model";
import { SearchPostBodyTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body-transfer.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { ServiceManagerIssueSearchPostTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/service-manager-issue-search-post-transfer.model";
import { ServiceManagerIssueSearchPostModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/service-manager-issue-search-post.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { catchError, ConnectableObservable, debounceTime, defer, filter, map, Observable, of, publish, Subject, takeUntil, throwError } from "rxjs";

/* eslint-disable prefer-arrow/prefer-arrow-functions */
@Injectable({
   providedIn: "root",
})
export class ApiService implements OnDestroy {
   private delayedObservableQueue: Array<ConnectableObservable<any>> = new Array<ConnectableObservable<any>>();

   private downloadUrl: string = "Download";

   private responseQueued: Subject<void> = new Subject();

   private unsubscribe = new Subject<void>();

   constructor(
      private httpClient: HttpClient,
      private sessionCheckService: SessionCheckService,
      private apiRequestHelperService: ApiRequestHelperService,
      private router: Router
   ) {
      this.sessionCheckService.sessionCheckResult
         .pipe(
            filter(() => this.delayedObservableQueue.length > 0),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (result) => {
               if (result === SessionCheckResult.Successful || result === SessionCheckResult.Changed) {
                  this.processDelayedObservableQueue();
               } else {
                  this.delayedObservableQueue = new Array<ConnectableObservable<any>>();
               }
            },
            (error) => {
               console.error(error);
            }
         );

      this.router.events
         .pipe(
            filter((event) => event instanceof GuardsCheckEnd && this.delayedObservableQueue.length > 0),
            takeUntil(this.unsubscribe)
         )
         .subscribe(() => {
            this.delayedObservableQueue = new Array<ConnectableObservable<any>>();
         });

      this.responseQueued.pipe(debounceTime(100), takeUntil(this.unsubscribe)).subscribe(() => {
         this.sessionCheckService.checkSessionAsync(false);
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   buildGetSingleParameterArray(
      id?: number | null,
      embeds?: EntityEmbedOption | Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField>,
      additionalParameters?: Array<string>
   ): Array<string> {
      return this.buildParameterArray(
         id,
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters
      );
   }

   buildGetCollectionParameterArray(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null,
      getOptions?: Array<string | EntityGetOption> | null
   ): Array<string> {
      let filterOptions: Array<FilterOption> | null = null;
      if (filterExpression) {
         filterOptions = filterExpression.toFilterOptionsArray();
         filterOptions = filterOptions.filter((f) => f.Values && f.Values.length > 0);
      }
      return this.buildParameterArray(
         null,
         null,
         null,
         filterOptions,
         null,
         null,
         embeds,
         orderingOptions,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   getSearchUrl(
      endpoint: string,
      id?: number | null,
      itemID?: number | null,
      filters?: Array<FilterOption | string> | null,
      filterExpression?: FilterExpression | null,
      quickSearchFields?: Array<string> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string | OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ) {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }

      if (filterExpression) {
         const expressionString = filterExpression.toApiString();
         if (expressionString) {
            additionalParameters.push(`filterExpression=${expressionString}`);
         }
      }

      return this.getUrl(
         endpoint,
         id,
         itemID,
         filters,
         null,
         quickSearchFields,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   buildSearchCollectionParameterArrayFromGetParameters(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null
   ) {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }

      if (filterExpression !== undefined && filterExpression !== null) {
         try {
            const expressionString = filterExpression.toApiString();
            if (expressionString) {
               additionalParameters.push(`filterExpression=${expressionString}`);
            }
         } catch (error) {
            if (isDevMode()) {
               console.error(
                  `ApiService -> buildSearchCollectionParameterArrayFromGetParameters -> filterExpression.toApiString() Failed. Error=${error} filterExpression=`,
                  filterExpression
               );
            }
            throw error;
         }
      }
      return this.buildGetCollectionParameterArray(
         null,
         embeds,
         orderingOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   buildQuickSearchCollectionParameterArrayFromGetParameters(
      filterFields: Array<FilterField> | null,
      searchText?: string | null,
      embeds?: Array<string | EntityEmbedOption>,
      orderingOptions?: Array<string>,
      fields?: Array<string | EntityField>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Array<string> {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (filterFields !== null && filterFields.length > 0) {
         additionalParameters.push(
            "filterFields=" +
               filterFields
                  .map(function(filterField) {
                     return filterField.FilterName;
                  })
                  .join(",")
         );
      }
      if (searchText != null && searchText.length > 0) {
         searchText = encodeURIComponent(searchText);
         additionalParameters.push(`searchText="${searchText}"`);
      }
      return this.buildGetCollectionParameterArray(
         null,
         embeds,
         orderingOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   buildPostParameterArray(
      saveOptions?: Array<string | EntitySaveOption>,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EntityEmbedOption>,
      additionalParameters?: Array<string>
   ): Array<string> {
      return this.buildParameterArray(
         null,
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         saveOptions,
         fields,
         null,
         null,
         null,
         additionalParameters
      );
   }

   buildDeleteSingleParameterArray(id?: number, additionalParameters?: Array<string>): Array<string> {
      return this.buildParameterArray(
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   buildDeleteCollectionParameterArray(
      ids?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Array<string> {
      return this.buildParameterArray(
         null,
         null,
         ids,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   directGet(endpoint: string, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      const headers = this.apiRequestHelperService.buildInitialHeaders();
      return this.httpClient.get(this.apiRequestHelperService.getUrl(endpoint, additionalParameters), {
         headers,
         withCredentials: true,
         observe: "response",
      });
   }

   getWithContentType(
      endpoint: string,
      contentType: string,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<string>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint, additionalParameters);

         let headers = this.apiRequestHelperService.buildInitialHeaders();
         headers = headers.delete(ApiRequestHelperService.contentTypeHeaderName);
         headers = headers.set(ApiRequestHelperService.contentTypeHeaderName, contentType);

         return this.httpClient.get(url, {
            headers,
            withCredentials: true,
            responseType: "text",
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<string>>;

      this.addDelayedObservable(request, `get: ${endpoint}`);
      return request;
   }

   getWithContentTypePost(
      endpoint: string,
      contentType: string,
      responseType: string,
      body: any,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<string>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint, additionalParameters);

         let headers = this.apiRequestHelperService.buildInitialHeaders();
         headers = headers.delete(ApiRequestHelperService.contentTypeHeaderName);
         headers = headers.set(ApiRequestHelperService.contentTypeHeaderName, contentType);

         return this.httpClient.post(url, body, {
            headers,
            withCredentials: true,
            responseType: responseType as "json",
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<string>>;

      this.addDelayedObservable(request, `get: ${endpoint}`);
      return request;
   }

   get(endpoint: string, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
         const headers = this.apiRequestHelperService.buildInitialHeaders();
         return this.httpClient.get<any>(url, {
            headers,
            withCredentials: true,
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<any>>;

      this.addDelayedObservable(request, `get: ${endpoint}`);
      return request;
   }

   getSingle(
      endpoint: string,
      id?: number,
      embeds?: Array<string | EntityEmbedOption>,
      fields?: Array<string | EntityField>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      additionalParameters = this.buildGetSingleParameterArray(id, embeds, fields, additionalParameters);

      return this.get(endpoint, additionalParameters).pipe(
         map(
            (response) => response.body,
            catchError((err: HttpErrorResponse) => {
               if (err.status === 404) {
                  return of(null);
               } else {
                  return throwError(err);
               }
            })
         )
      );
   }

   getSingleSubItem(
      endpoint: string,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption>,
      fields?: Array<string | EntityField>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push(`itemid=${itemID}`);
      additionalParameters = this.buildGetSingleParameterArray(null, embeds, fields, additionalParameters);

      return this.get(endpoint, additionalParameters).pipe(map((response) => response.body));
   }

   getCollection(
      endpoint: string,
      filterExpression?: FilterExpression,
      embeds?: Array<string | EntityEmbedOption>,
      orderingOptions?: Array<string>,
      fields?: Array<string | EntityField>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<any>> {
      return this.getCollectionResponse(
         endpoint,
         filterExpression,
         embeds,
         orderingOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      ).pipe(
         map((response) => {
            if (response.status === HttpStatusCode.NoContent) {
               return [];
            }
            return response.body;
         })
      );
   }

   getCollectionResponse(
      endpoint: string,
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>,
      getOptions?: Array<string | EntityGetOption>
   ): Observable<HttpResponse<any>> {
      additionalParameters = this.buildGetCollectionParameterArray(
         filterExpression,
         embeds,
         orderingOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters,
         getOptions
      );
      return this.get(endpoint, additionalParameters);
   }

   postCollectionResponse(
      endpoint: string,
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>,
      getOptions?: Array<string | EntityGetOption>
   ): Observable<HttpResponse<any>> {
      const postableGetModel: PostableGetModel = new PostableGetModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type '(string | EntityEmbedOption)[] | null | unde... Remove this comment to see the full error message
      postableGetModel.embeds = embeds;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[] | null | undefined' is not assignab... Remove this comment to see the full error message
      postableGetModel.orderingOptions = orderingOptions;
      // @ts-ignore ts-migrate(2322) FIXME: Type '(string | EntityField)[] | null | undefined'... Remove this comment to see the full error message
      postableGetModel.fields = fields;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      postableGetModel.pageSize = pageSize;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      postableGetModel.pageNumber = pageNumber;
      // @ts-ignore ts-migrate(2322) FIXME: Type '(string | EntityGetOption)[] | undefined' is... Remove this comment to see the full error message
      postableGetModel.getOptions = getOptions;
      // @ts-ignore ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      postableGetModel.filters = filterExpression.toFilterOptionsArray();
      return this.post(endpoint, new PostableGetTransferModel(postableGetModel, false), additionalParameters);
   }

   getSearchResponse(
      endpoint: string,
      filterExpression?: FilterExpression,
      embeds?: Array<string | EntityEmbedOption>,
      orderingOptions?: Array<string>,
      fields?: Array<string | EntityField>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      additionalParameters = this.buildSearchCollectionParameterArrayFromGetParameters(
         filterExpression,
         embeds,
         orderingOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      );
      return this.get(endpoint, additionalParameters);
   }

   post(endpoint: string, body?: Object | null, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      if (body instanceof ServiceManagerIssueSearchPostModel) {
         body = new ServiceManagerIssueSearchPostTransferModel(body);
      } else if (body instanceof RegisterSearchPostBodyModel) {
         body = new RegisterSearchPostBodyTransferModel(body);
      } else if (body instanceof SearchPostBodyModel) {
         body = new SearchPostBodyTransferModel(body);
      } else if (body instanceof PostableGetModel) {
         body = new PostableGetTransferModel(body);
      }
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
         const headers = this.apiRequestHelperService.buildInitialHeaders();
         return this.httpClient.post<any>(url, JSON.stringify(body), {
            headers,
            withCredentials: true,
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<any>>;

      this.addDelayedObservable(request, `post: ${endpoint}`);
      return request;
   }

   postSingle(
      endpoint: string,
      body: any,
      saveOptions?: Array<string | EntitySaveOption>,
      fields?: Array<string | EntityField>,
      additionalParameters?: Array<string>,
      embeds?: Array<string | EntityEmbedOption>
   ): Observable<any> {
      return this.postCollection(endpoint, [body], saveOptions, fields, embeds, additionalParameters).pipe(
         map((results) => {
            if (!results || results.length === 0) {
               return null;
            }
            return results[0];
         })
      );
   }

   postCollection(
      endpoint: string,
      body?: Array<any> | null,
      saveOptions?: Array<string | EntitySaveOption>,
      fields?: Array<string | EntityField>,
      embeds?: Array<string | EntityEmbedOption>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      additionalParameters = this.buildPostParameterArray(saveOptions, fields, embeds, additionalParameters);

      return this.post(endpoint, body, additionalParameters).pipe(map((response) => response.body));
   }

   postFormData(endpoint: string, formData: FormData): Observable<HttpResponse<any>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint);
         let headers = this.apiRequestHelperService.buildInitialHeaders();
         headers = headers.delete(ApiRequestHelperService.contentTypeHeaderName);
         return this.httpClient.post(url, formData, {
            headers,
            withCredentials: true,
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<any>>;

      this.addDelayedObservable(request, `postformdata: ${endpoint}`);
      return request;
   }

   getFile(endpoint: string, contentType: string = "application/pdf"): Observable<HttpResponse<any>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint);
         let headers = this.apiRequestHelperService.buildInitialHeaders();
         headers = headers.delete(ApiRequestHelperService.contentTypeHeaderName);
         headers = headers.set(ApiRequestHelperService.contentTypeHeaderName, contentType);
         return this.httpClient.get(url, {
            headers,
            withCredentials: true,
            observe: "response",
            responseType: "blob",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<any>>;

      this.addDelayedObservable(request, `getfile: ${endpoint}`);
      return request;
   }

   delete(endpoint: string, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      const request = defer(() => {
         const url = this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
         const headers = this.apiRequestHelperService.buildInitialHeaders();
         return this.httpClient.delete(url, {
            headers,
            withCredentials: true,
            observe: "response",
         });
      }).pipe(publish()) as ConnectableObservable<HttpResponse<any>>;

      this.addDelayedObservable(request, `delete: ${endpoint}`);
      return request;
   }

   deleteSingle(endpoint: string, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.delete(endpoint, additionalParameters);
   }

   deleteCollection(
      endpoint: string,
      ids?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      additionalParameters = this.buildDeleteCollectionParameterArray(ids, additionalParameters);

      return this.delete(endpoint, additionalParameters);
   }

   getDownloadUrl(token: string) {
      return this.getUrl(`${this.downloadUrl}/${token}`);
   }

   getUrl(
      endpoint: string,
      id?: number | null,
      itemID?: number | null,
      filters?: Array<FilterOption | string> | null,
      filterExpression?: FilterExpression | null,
      quickSearchFields?: Array<string> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string | OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>,
      isSearch?: boolean
   ) {
      if (isSearch) {
         return this.getSearchUrl(
            endpoint,
            id,
            itemID,
            filters,
            filterExpression,
            quickSearchFields,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         );
      }

      additionalParameters = this.buildParameterArray(
         id,
         itemID,
         null,
         filters,
         filterExpression,
         quickSearchFields,
         embeds,
         orderingOptions,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );

      return this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
   }

   deleteUrl(
      endpoint: string,
      id: number | null | string,
      ids?: Array<number> | null,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ) {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (id !== null) {
         additionalParameters.push("id=" + id);
      }
      if (ids !== undefined && ids !== null && ids.length > 0) {
         additionalParameters.push("ids=" + ids.join(","));
      }
      if (deleteOptions !== undefined && deleteOptions !== null && deleteOptions.length > 0) {
         additionalParameters.push("deleteOptions=" + deleteOptions.join(";"));
      }

      return this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
   }

   postUrl(
      endpoint: string,
      id?: number | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ) {
      additionalParameters = this.buildParameterArray(
         id,
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         saveOptions,
         fields,
         null,
         null,
         null,
         additionalParameters
      );

      return this.apiRequestHelperService.getUrl(endpoint, additionalParameters);
   }

   // TODO: eventually this shouldn't take in one | many and only take many
   // there are other spots with the or parameter
   buildParameterArray(
      id?: number | null,
      itemID?: number | null,
      ids?: Array<number> | null,
      filters?: Array<FilterOption | string> | null,
      filterExpression?: FilterExpression | null,
      quickSearchFields?: Array<string> | null,
      embeds?: EntityEmbedOption | Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<string | OrderingOption> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null
   ): Array<string> {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }

      if (id != null) {
         additionalParameters.push("id=" + id);
      }
      if (itemID != null) {
         additionalParameters.push("itemID=" + itemID);
      }
      if (ids && ids.length > 0) {
         additionalParameters.push("ids=" + ids.join(","));
      }
      if (filters) {
         const filterString = FilterOption.toApiString(filters);
         if (filterString && filterString.length > 0) {
            additionalParameters.push("filters=" + filterString);
         }
      }
      if (filterExpression) {
         const expressionString = filterExpression.toApiString();
         if (expressionString && expressionString.length > 0) {
            additionalParameters.push(`filterExpression=${expressionString}`);
         }
      }
      if (quickSearchFields && quickSearchFields.length > 0) {
         additionalParameters.push("filterFields=" + quickSearchFields.join(","));
      }

      if (embeds) {
         const embedsString = EntityEmbedOption.toApiString(embeds);

         if (embedsString && embedsString.length > 0) {
            additionalParameters.push("embeds=" + embedsString);
         }
      }

      if (orderingOptions && orderingOptions.length > 0) {
         const orderingOptionString = OrderingOption.toApiString(orderingOptions);
         if (orderingOptionString && orderingOptionString.length > 0) {
            additionalParameters.push("orderingOptions=" + orderingOptionString);
         }
      }

      if (saveOptions && saveOptions.length > 0) {
         const saveOptionString = EntitySaveOption.toApiString(saveOptions);
         if (saveOptionString && saveOptionString.length > 0) {
            additionalParameters.push("saveOptions=" + saveOptionString);
         }
      }

      if (getOptions) {
         const getOptionString = EntityGetOption.toApiString(getOptions);
         if (getOptionString && getOptionString.length > 0) {
            additionalParameters.push("getOptions=" + getOptionString);
         }
      }

      if (fields) {
         const fieldString = EntityField.toApiString(fields);
         if (fieldString && fieldString.length > 0) {
            additionalParameters.push("fields=" + fieldString);
         }
      }

      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }

      return additionalParameters;
   }

   private addDelayedObservable(delayedObservable: ConnectableObservable<any>, additionalInfo: string) {
      delayedObservable["rmxAdditionalInfo"] = additionalInfo;
      this.delayedObservableQueue.push(delayedObservable);
      this.responseQueued.next();
   }

   private processDelayedObservableQueue() {
      for (const waitingApiRequest of this.delayedObservableQueue) {
         waitingApiRequest.connect();
      }
      this.delayedObservableQueue = new Array<ConnectableObservable<any>>();
   }
}
