<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ parameter?.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="percent-container ">
   <lcs-text-box
      class="input-percent"
      [(ngModel)]="reportParameterValueModel.value"
      [lcsValidate]="validation"
      (blurEvent)="update()"
      [name]="name"
      [inputType]="'number'"
      [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-text-box>
   <div>
      %
   </div>
</div>