import { WorkflowSteps } from "../enumerations/workflowsteps.enum";

export class MergeUserModel {
   TargetWebUserID: number;
   DesiredEmailAddress: string;
   WebUserId: number;
   Message: string;
   WorkflowSteps: WorkflowSteps;
   LocationID: number;
   Password: string;
   AccountDisplayID: number;
   ConvertByDate: Date;
   CurrentWebUserID: number;
  }