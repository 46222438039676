import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "@lcs/pipes/pipes.module";

import { SidebarLayoutSelectorComponent } from "./sidebar-layout-selector.component";
import { SidebarLayoutComponent } from "./sidebar-layout.component";

@NgModule({
   imports: [CommonModule, PipesModule, ScrollingModule],
   declarations: [SidebarLayoutComponent, SidebarLayoutSelectorComponent],
   exports: [SidebarLayoutComponent, SidebarLayoutSelectorComponent],
})
export class SidebarLayoutModule {}
