import { SelectorItemActionModel } from "./selector-item-action.model";

export class SelectorItemModel {
   value: any;

   displayValue: string = "";

   additionalInfoValue: string;

   isChecked: boolean;

   additionalData: Map<string, any>;

   additionalActions: Array<SelectorItemActionModel>;

   label: string;

   isHidden: boolean;

   isItemGroupWithLabel: boolean;

   isMutuallyExclusiveAll: boolean;

   isIncludedInAll: boolean = false;

   isDisabled: boolean;

   hasFlyoutAction: boolean;

   alternateStyleClass: string;

   tooltip: string = "";

   hasDivider: boolean;

   public constructor(value?: any, displayValue: string = "") {
      this.value = value;
      this.displayValue = displayValue;
      this.additionalData = new Map<string, any>();
      this.additionalActions = new Array<SelectorItemActionModel>();
   }
}
