import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LinkComponent } from "./link.component";
import { RouterModule } from "@angular/router";

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
   ],
   declarations: [
      LinkComponent,
   ],
   exports: [
      LinkComponent,
   ],
})
export class LinkModule { }
