import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl, NgControl, NgModel, ValidationErrors, Validator, Validators } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import isDate from "lodash/isDate";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";

import { ControlContainerViewProvider } from "../control-container-view-providers";
import { ToggleableInputBehavior } from "../toggleable-input/toggleable-input-behavior.enum";
import { ValidationHelper } from "../validation/validation-helper";

/* eslint-disable brace-style */
/* eslint-disable brace-style */
@Component({
   selector: "lcs-date-time-picker",
   templateUrl: "date-time-picker.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class DateTimePickerComponent
   extends ValueAccessorBase<Date>
   implements OnChanges, OnInit, Validator, OnDestroy
{
   @Input() toggleable: boolean;

   @Input() dateValidation: ValidationModel;

   @Input() timeValidation: ValidationModel;

   @Input() standalone: boolean;

   @Input() customValidatorData: any;

   @Input() behavior: ToggleableInputBehavior;

   @Input() defaultValue: string;

   @Input() set checked(checked: boolean) {
      if (!!checked !== this.checked) {
         this._checked = !!checked;
         this.applyToggleableBehavior();
      }
   }

   get checked() {
      return this._checked;
   }

   @Output() checkedChange = new EventEmitter<boolean>();

   @ViewChild("datePicker", { static: true }) datePicker: NgModel;

   @ViewChild("timePicker", { static: true }) timePicker: NgModel;

   dateDisplayName: string;

   dateName: string;

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
   datePart: Date = null;

   disableDateParts: boolean;

   timeDisplayName: string;

   timeName: string;

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
   timePart: Date = null;

   private existingDateValidation: (control: AbstractControl, customValidatorData: any) => ValidationErrors | null;

   private existingTimeValidation: (control: AbstractControl, customValidatorData: any) => ValidationErrors | null;

   private unsubscribe = new Subject<void>();

   private _checked: boolean;
   private dateRequiredByDefault: boolean;
   private timeRequiredByDefault: boolean;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
      this.innerValue = null;

      this.registerOnValueWritten((value: Date) => {
         if (value && !isDate(value)) {
            const newDate = new Date(value);
            if (isNaN(newDate.getTime())) {
               console.warn(`Received invalid date string: ${value}`);
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
               this.innerValue = null;
            } else {
               this.innerValue = newDate;
            }
         } else {
            this.innerValue = value; // value is either null or a Date object
         }
         if (this.innerValue) {
            this.datePart = new Date(this.innerValue);
            this.timePart = new Date(this.innerValue);
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
            this.datePart = null;
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
            this.timePart = null;
         }
      });

      this.registerOnChange((value: Date) => {
         if (!this.dateTimeValuesAreEqual(value, this.currentDateTimeFromParts())) {
            if (value) {
               this.datePart = new Date(value);
               this.timePart = new Date(value);
            } else {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
               this.datePart = null;
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
               this.timePart = null;
            }
         }
      });
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.disabled) {
         this.applyToggleableBehavior();
      }
   }

   ngOnInit(): RequiredSuperCallFlag {
      this.dateName = this.name + "Date";
      this.timeName = this.name + "Time";

      if (this.displayName.endsWith("Date")) {
         this.dateDisplayName = this.displayName;
         this.timeDisplayName = this.displayName.replace("Date", "Time");
      } else {
         this.dateDisplayName = this.displayName + " Date";
         this.timeDisplayName = this.displayName + " Time";
      }

      if (this.toggleable) {
         this.behavior = ToggleableInputBehavior.Disable;
      }

      if (this.defaultValue) {
         setTimeout(() => {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            this.value = this.defaultValue ? new Date(this.defaultValue) : null;
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            this.datePart = this.defaultValue ? new Date(this.defaultValue) : null;
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            this.timePart = this.defaultValue ? new Date(this.defaultValue) : null;
         });
      }

      this.applyToggleableBehavior();

      setTimeout(() => {
         this.setupValidation();
      });

      this.datePicker.control.statusChanges
         .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
         .subscribe((status) => {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.ngControl.control.updateValueAndValidity();
         });
      this.timePicker.control.statusChanges
         .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
         .subscribe((status) => {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.ngControl.control.updateValueAndValidity();
         });
      return super.ngOnInit();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   validate(control: AbstractControl): ValidationErrors | null {
      const dateErrors: ValidationErrors | null = this.datePicker.errors;
      const timeErrors: ValidationErrors | null = this.timePicker.errors;

      if (!(dateErrors || timeErrors)) {
         return null;
      }

      const errors: ValidationErrors = {};

      if (dateErrors) {
         errors[this.dateDisplayName] = dateErrors;
      }

      if (timeErrors) {
         errors[this.timeDisplayName] = timeErrors;
      }

      return errors;
   }

   setupValidation() {
      if (!this.dateValidation) {
         this.dateValidationSetup(this.checked);
      } else {
         this.dateRequiredByDefault = this.dateValidation.required;
         this.existingDateValidation = this.dateValidation.customValidator;
         this.dateValidation.customValidator = this.defaultDateValidation.bind(this);
      }

      if (!this.timeValidation) {
         this.timeValidationSetup(this.checked);
      } else {
         this.timeRequiredByDefault = this.timeValidation.required;
         this.existingTimeValidation = this.timeValidation.customValidator;
         this.timeValidation.customValidator = this.defaultTimeValidation.bind(this);
      }

      // Bind validation for the date-time-picker form control to its validate method
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.ngControl.control.setValidators(Validators.compose([this.ngControl.validator, this.validate.bind(this)]));
   }

   updateDatePart(value: Date) {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
      this.datePart = value ? value : null; // normalize undefined to null
      if (!this.datePart) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
         this.timePart = null; // if DatePart is cleared also clear timePart
         this.timePicker.control.updateValueAndValidity(); // force view update
      }
      this.updateValue();
   }

   updateTimePart(value: Date) {
      const defaultDate = new Date(this.defaultValue);
      if (
         value &&
         this.timePart &&
         (value.getHours() !== this.timePart.getHours() || value.getMinutes() !== this.timePart.getMinutes())
      ) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
         this.timePart = value ? value : null; // normalize undefined to null
         this.updateValue();
      } else if (
         value &&
         value.getHours() === defaultDate.getHours() &&
         value.getMinutes() === defaultDate.getMinutes()
      ) {
         this.timePicker.control.markAsPristine();
      }
   }

   applyToggleableBehavior() {
      if (this.toggleable) {
         if (this.behavior === ToggleableInputBehavior.Disable) {
            this.disableDateParts = !this.checked;
         } else if (this.behavior === ToggleableInputBehavior.Clear) {
            if (!this.checked && this.value !== null) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
               this.value = null;
            }
         }
      }
   }

   onDateInputValueChange(value: Date) {
      this.setChecked(!!value);
   }

   onTimeInputValueChange(value: any) {
      this.setChecked(!!value);
   }

   onCheckedChange(checked: boolean) {
      this._checked = checked;
      this.applyToggleableBehavior();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
      this.value = checked ? this.getNewDateTimeTruncatedToMinutes() : null;
      this.checkedChange.emit(checked);
   }

   private dateTimeValuesAreEqual(d1: Date | null, d2: Date | null): boolean {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
      const areEqual: boolean = d1 === d2 || (d1 && d2 && d1.getTime() === d2.getTime());
      return areEqual;
   }

   private setChecked(checked: boolean) {
      if (this.toggleable && this.behavior !== ToggleableInputBehavior.Disable) {
         if (checked && !this.checked) {
            this.checked = true;
            this.onCheckedChange(this.checked);
         }
      }
   }

   /**
    * Returns a new Date object based on optional date truncated to minutes.
    * date defaults to now if null or undefined
    */
   private getNewDateTimeTruncatedToMinutes(date?: Date): Date {
      if (!date) {
         date = new Date();
      }
      const dateRoundedToMinutes = new Date(
         date.getFullYear(),
         date.getMonth(),
         date.getDate(),
         date.getHours(),
         date.getMinutes()
      );
      return dateRoundedToMinutes;
   }

   /**
    * Get new date object or null based on current datePart and timePart
    */
   private currentDateTimeFromParts(): Date | null {
      let currentDateFromParts: Date | null = null;

      if (this.datePart) {
         if (this.timePart) {
            currentDateFromParts = new Date(
               this.datePart.getFullYear(),
               this.datePart.getMonth(),
               this.datePart.getDate(),
               this.timePart.getHours(),
               this.timePart.getMinutes()
            );
         } else {
            currentDateFromParts = new Date(
               this.datePart.getFullYear(),
               this.datePart.getMonth(),
               this.datePart.getDate()
            );
         }
      } else {
         currentDateFromParts = null;
      }
      return currentDateFromParts;
   }

   private updateValue(): void {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
      this.value = this.currentDateTimeFromParts();
      this.setChecked(!!this.value);
   }

   private defaultDateValidation(control: AbstractControl, customValidatorData: any): ValidationErrors | null {
      const dateValue = control.value;
      const timeValue = this.timePart;
      let errors: ValidationErrors | null = null;

      if (!dateValue) {
         if (this.checked) {
            errors = { validationMessages: [`is required if checked`] };
         } else if (timeValue && !this.dateRequiredByDefault) {
            errors = { validationMessages: [`is required with time`] };
         }
      }

      const timeIsRequired = !!(!!dateValue || this.checked);
      this.updateTimeValidationRequired(timeIsRequired);

      if (this.existingDateValidation) {
         const otherErrors: ValidationErrors | null = this.existingDateValidation(control, customValidatorData);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
         const allErrors: ValidationErrors | null = ValidationHelper.mergeErrorMessages([errors, otherErrors]);
         return allErrors;
      }

      return errors;
   }

   private defaultTimeValidation(control: AbstractControl, customValidatorData: any): ValidationErrors | null {
      const timeValue = control.value;
      const dateValue = this.datePart;
      let errors: ValidationErrors | null = null;

      if (!timeValue) {
         if (this.checked) {
            errors = { validationMessages: [`is required if checked`] };
         } else if (this.timeRequiredByDefault) {
            errors = { required: true };
         } else if (dateValue) {
            errors = { validationMessages: [`is required with date`] };
         }
      }

      const dateIsRequired = !!(!!timeValue || this.checked);
      this.updateDateValidationRequired(dateIsRequired);

      if (this.existingTimeValidation) {
         const otherErrors: ValidationErrors | null = this.existingTimeValidation(control, customValidatorData);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
         const allErrors: ValidationErrors | null = ValidationHelper.mergeErrorMessages([errors, otherErrors]);
         return allErrors;
      }

      return errors;
   }

   private updateDateValidationRequired(dateIsRequired: boolean) {
      if (this.dateValidation) {
         const dateControl = this.datePicker.control;

         if (this.dateValidation.required !== dateIsRequired) {
            this.dateValidation.required = dateIsRequired;
            if (dateControl) {
               // Promise.resolve(() => dateControl.updateValueAndValidity());
               setTimeout(() => dateControl.updateValueAndValidity());
            }
         }
      }
   }

   private updateTimeValidationRequired(timeIsRequired: boolean) {
      if (this.timeValidation) {
         const timeControl = this.timePicker.control;
         if (this.timeValidation.required !== timeIsRequired) {
            this.timeValidation.required = timeIsRequired;
            if (timeControl) {
               // Promise.resolve(() => timeControl.updateValueAndValidity());
               setTimeout(() => timeControl.updateValueAndValidity());
            }
         }
      }
   }

   private dateValidationSetup(checked?: boolean) {
      const dateValidation = new ValidationModel();
      dateValidation.dataType = ExpressDataTypes.DateTime;
      dateValidation.customValidator = this.defaultDateValidation.bind(this);
      dateValidation.required = !!checked;
      this.dateValidation = dateValidation;
   }

   private timeValidationSetup(checked: boolean) {
      const timeValidation = new ValidationModel();
      timeValidation.dataType = ExpressDataTypes.Numeric;
      timeValidation.customValidator = this.defaultTimeValidation.bind(this);
      timeValidation.required = !!checked;
      this.timeValidation = timeValidation;
   }
}
