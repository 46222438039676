<lcs-select
   lcsEndpointSelector
   *ngIf="selectorConfigured"
   [endpoint]="endpoint"
   [endpointIsSearch]="true"
   [searchFields]="searchFields"
   [addBlankItem]="addBlankItem"
   [orderingOptions]="orderingOptions"
   [filters]="filters"
   [displayValueSourcePath]="displayValueSourcePath"
   [valueSourcePath]="valueSourcePath"
   [additionalInfoSourcePath]="additionalInfoSourcePath"
   [valuesToExclude]="accountTypesToExclude"
   [ngModel]="value"
   (ngModelChange)="onSelectedChange($event)"
   [ngModelOptions]="{ standalone: true }"
   [name]="name"
   [displayName]="displayName"
   [disabled]="disabled"
   (touched)="onTouched()"
>
</lcs-select>