<lcs-sidebar-layout>
   <ng-template #left>
      <lcs-sidebar-layout-selector
         [items]="[pdfs, null]"
         [displayPropertyPath]="'Name'"
         (selectedUniqueIdentifierChange)="showPdf($event)"
      >
      </lcs-sidebar-layout-selector>
   </ng-template>
   <ng-template #center>
      <div class="pdf-viewer-wrapper">
         <owa-multi-pdf-viewer-display
            [selectedPdf]="selectedPdf"
            [selectedPdfs]="selectedPdfs"
            [errorHeader]="errorHeader"
            [errorInformation]="errorInformation"
            [errorIcon]="errorIcon"
            [errorContentTemplate]="errorContentTemplate"
         >
         </owa-multi-pdf-viewer-display>
      </div>
   </ng-template>
   <ng-template
      *ngIf="rightTemplate"
      #right
   >
      <ng-container *ngTemplateOutlet="rightTemplate"></ng-container>
   </ng-template>
</lcs-sidebar-layout>