import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { PhoneNumberTypeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/phone-number-type.delete-options";
import { PropertyDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/property.delete-options";
import { RollbackManagementFeePostingDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/rollback-management-fee-posting.delete-options";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { AccountingCloseEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/accounting-close.embed-options";
import { AddressTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address-type.embed-options";
import { AddressEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address.embed-options";
import { AllocationOrderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/allocation-order.embed-options";
import { ApplicationSettingsEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/application-settings.embed-options";
import { ApplicationTemplateEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/application-template.embed-options";
import { ARAutomationScheduleEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/arautomation-schedule.embed-options";
import { AssetLocationHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/asset-location-history.embed-options";
import { AssetEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/asset.embed-options";
import { AssociationSettingsEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/association-settings.embed-options";
import { BankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/bank.embed-options";
import { BeginningBalanceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/beginning-balance.embed-options";
import { BoardMemberTermEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/board-member-term.embed-options";
import { CalculatedSecurityDepositInterestEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/calculated-security-deposit-interest.embed-options";
import { ChargeTypeBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge-type-bank.embed-options";
import { ChargeTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge-type.embed-options";
import { ColorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/color.embed-options";
import { CommitteeMemberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/committee-member.embed-options";
import { CommunityEventEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/community-event.embed-options";
import { DefaultChargeSettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/default-charge-setting.embed-options";
import { EpaySettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/epay-setting.embed-options";
import { FileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/file.embed-options";
import { FloorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/floor.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { HistoryNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-note.embed-options";
import { HistorySystemNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-system-note.embed-options";
import { HistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history.embed-options";
import { HolidayEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/holiday.embed-options";
import { ImageTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/image-type.embed-options";
import { ImageEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/image.embed-options";
import { LateFeeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/late-fee.embed-options";
import { LeaseTermPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease-term-property.embed-options";
import { MakeReadyTemplatePropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/make-ready-template-property.embed-options";
import { ManagementFeeHistoryChargesEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/management-fee-history-charges.embed-options";
import { ManagementFeeHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/management-fee-history.embed-options";
import { ManagementFeeSetupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/management-fee-setup.embed-options";
import { MarketingSetupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/marketing-setup.embed-options";
import { MarketingValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/marketing-value.embed-options";
import { OwnerCheckSetupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-check-setup.embed-options";
import { OwnerContractEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-contract.embed-options";
import { OwnerEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner.embed-options";
import { PetTypePropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/pet-type-property.embed-options";
import { PetTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/pet-type.embed-options";
import { PhoneNumberTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number-type.embed-options";
import { PhoneNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number.embed-options";
import { PollEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/poll.embed-options";
import { PropertyAmenityEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-amenity.embed-options";
import { PropertyBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-bank.embed-options";
import { PropertyFileFolderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-file-folder.embed-options";
import { PropertyFileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-file.embed-options";
import { PropertyFloorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-floor.embed-options";
import { PropertyGroupDetailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-group-detail.embed-options";
import { PropertyGroupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-group.embed-options";
import { PropertyManagementFeeSetupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-management-fee-setup.embed-options";
import { PropertyRentChargeTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property-rent-charge-type.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { RateDiscountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rate-discount.embed-options";
import { RateScheduleEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rate-schedule.embed-options";
import { RateSettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rate-setting.embed-options";
import { RateTaxEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rate-tax.embed-options";
import { RecurringChargeSummaryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/recurring-charge-summary.embed-options";
import { RecurringChargeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/recurring-charge.embed-options";
import { ReservableAmenityEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/reservable-amenity.embed-options";
import { RMVoIPCallHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rmvo-ipcall-history.embed-options";
import { ScreeningCredentialsEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/screening-credentials.embed-options";
import { ScreeningSettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/screening-setting.embed-options";
import { SecurityDepositInterestEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/security-deposit-interest.embed-options";
import { TextNumberPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/text-number-property.embed-options";
import { TextNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/text-number.embed-options";
import { UnitTypePropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit-type-property.embed-options";
import { UnitTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit-type.embed-options";
import { UnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-property.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { UtilityPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/utility-property.embed-options";
import { ViolationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/violation.embed-options";
import { BeginningBalanceGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/beginning-balance.get-options";
import { HistoryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/history.get-options";
import { OwnerContractGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/owner-contract.get-options";
import { RecurringChargeSummaryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/recurring-charge-summary.get-options";
import { UnitGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/unit.get-options";
import { BeginningBalanceSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/beginning-balance.save-options";
import { BoardMemberTermSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/board-member-term.save-options";
import { CommitteeMemberSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/committee-member.save-options";
import { MergePropertySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/merge-property.save-options";
import { MigrateUnitSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/migrate-unit.save-options";
import { NonRecurringCRESaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/non-recurring-cre.save-options";
import { PostManagementFeeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/post-management-fee.save-options";
import { PostSecurityDepositInterestSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/post-security-deposit-interest.save-options";
import { PropertySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/property.save-options";
import { RMVoIPCallHistorySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/rmvo-ipcall-history.save-options";
import { RollbackManagementFeePostingSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/rollback-management-fee-posting.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { ScreeningReport } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/screening-report.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountingCloseSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-close-summary.model";
import { AccountingCloseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-close.model";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { AddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address.model";
import { AllocationOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/allocation-order.model";
import { ApplicationSettingsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/application-settings.model";
import { ApplicationTemplateModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/application-template.model";
import { ARAutomationScheduleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/arautomation-schedule.model";
import { AssetLocationHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/asset-location-history.model";
import { AssetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/asset.model";
import { AssociationSettingsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/association-settings.model";
import { BankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bank.model";
import { BatchEmailEntitiesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entities.model";
import { BatchEmailEntitySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entity-summary.model";
import { BeginningBalanceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/beginning-balance.model";
import { BoardMemberEmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/board-member-email.model";
import { BoardMemberTermModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/board-member-term.model";
import { CalculateManagementFeeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculate-management-fee.model";
import { CalculateManagementFundSweepSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculate-management-fund-sweep-summary.model";
import { CalculateManagementFundSweepModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculate-management-fund-sweep.model";
import { CalculateSecurityDepositInterestByPropertiesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculate-security-deposit-interest-by-properties.model";
import { CalculatedManagementFeesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculated-management-fees.model";
import { CalculatedSecurityDepositInterestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculated-security-deposit-interest.model";
import { ChargeTypeBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type-bank.model";
import { ChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type.model";
import { ColorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/color.model";
import { CommitteeMemberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/committee-member.model";
import { CommunityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/community-event.model";
import { CrossPropertyContractModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/cross-property-contract.model";
import { DefaultChargeSettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/default-charge-setting.model";
import { EPayConvenienceFeeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-convenience-fee-summary.model";
import { EpaySettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-setting.model";
import { FileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file.model";
import { FloorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/floor.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { HistoryNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-note.model";
import { HistorySystemNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-system-note.model";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { HolidayModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/holiday.model";
import { ImageTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/image-type.model";
import { ImageModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/image.model";
import { LateFeeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/late-fee.model";
import { LeaseTermPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease-term-property.model";
import { MakeReadyTemplatePropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/make-ready-template-property.model";
import { ManagementFeeBreakdownFeeRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fee-breakdown-fee-request.model";
import { ManagementFeeHistoryChargesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fee-history-charges.model";
import { ManagementFeeHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fee-history.model";
import { ManagementFeeSetupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fee-setup.model";
import { ManagementFundSweepSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fund-sweep-summary.model";
import { ManagementFundSweepModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/management-fund-sweep.model";
import { MarketingDataModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/marketing-data.model";
import { MarketingSetupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/marketing-setup.model";
import { MarketingValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/marketing-value.model";
import { MergePropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/merge-property.model";
import { MigrateUnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/migrate-unit.model";
import { NonRecurringCREChargeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/non-recurring-crecharge-summary.model";
import { NonRecurringCRERequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/non-recurring-crerequest.model";
import { OccupancySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/occupancy-summary.model";
import { OwnerCheckSetupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-check-setup.model";
import { OwnerContractModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contract.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { PetTypePropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pet-type-property.model";
import { PetTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pet-type.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { PollModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/poll.model";
import { PostManagementFeeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/post-management-fee-summary.model";
import { PostManagementFeeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/post-management-fee.model";
import { PostNonRecurringCREChargesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/post-non-recurring-crecharges.model";
import { PostSecurityDepositInterestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/post-security-deposit-interest.model";
import { PostingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/posting.model";
import { PropertiesAffectedByPropertyDeleteSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/properties-affected-by-property-delete-summary.model";
import { PropertyAmenityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-amenity.model";
import { PropertyAvailabilityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-availability.model";
import { PropertyBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-bank.model";
import { PropertyBanksSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-banks-summary.model";
import { PropertyFileFolderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-file-folder.model";
import { PropertyFileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-file.model";
import { PropertyFloorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-floor.model";
import { PropertyGroupDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-group-detail.model";
import { PropertyGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-group.model";
import { PropertyManagementFeePostSummaryViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-management-fee-post-summary-view.model";
import { PropertyManagementFeeSetupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-management-fee-setup.model";
import { PropertyOwnersModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-owners.model";
import { PropertyRentChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-rent-charge-type.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { RateDiscountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rate-discount.model";
import { RateScheduleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rate-schedule.model";
import { RateSettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rate-setting.model";
import { RateTaxModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rate-tax.model";
import { RecurringChargeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/recurring-charge-summary.model";
import { RecurringChargeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/recurring-charge.model";
import { ReservableAmenityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/reservable-amenity.model";
import { RMVoIPCallHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rmvo-ipcall-history.model";
import { ScreeningCredentialsAuthenticationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/screening-credentials-authentication.model";
import { ScreeningCredentialsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/screening-credentials.model";
import { ScreeningSettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/screening-setting.model";
import { ScriptBuilderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/script-builder.model";
import { SecurityDepositInterestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/security-deposit-interest.model";
import { SelectListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/select-list-item.model";
import { TextNumberPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/text-number-property.model";
import { TextNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/text-number.model";
import { UnitTypePropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit-type-property.model";
import { UnitTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit-type.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-property.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { UtilityPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/utility-property.model";
import { ViolationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/violation.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class PropertiesService {
   static readonly AccountingCloseResource = "AccountingClose";
   static readonly ActiveAccountingCloseResource = "ActiveAccountingClose";
   static readonly AddressesResource = "Addresses";
   static readonly AddressTypesResource = "AddressTypes";
   static readonly AllocationOrdersResource = "AllocationOrders";
   static readonly ApplicationSettingsResource = "ApplicationSettings";
   static readonly ApplicationTemplatesResource = "ApplicationTemplates";
   static readonly ARAutomationScheduleResource = "ARAutomationSchedule";
   static readonly AssetLocationHistoryResource = "AssetLocationHistory";
   static readonly AssetsResource = "Assets";
   static readonly AssignedUserResource = "AssignedUser";
   static readonly AssociationSettingsResource = "AssociationSettings";
   static readonly AssociationSettingsGetRTFValuesResource = "AssociationSettingsGetRTFValues";
   static readonly AvailabilityResource = "Availability";
   static readonly BanksResource = "Banks";
   static readonly BatchEmailPropertiesResource = "BatchEmailProperties";
   static readonly BatchEmailPropertiesFromUnitsResource = "BatchEmailPropertiesFromUnits";
   static readonly BeginningBalancesResource = "BeginningBalances";
   static readonly BillingAddressResource = "BillingAddress";
   static readonly BoardMembersResource = "BoardMembers";
   static readonly BoardMemberTermsResource = "BoardMemberTerms";
   static readonly CalculateCapAmountResource = "CalculateCapAmount";
   static readonly CalculateIndividualSecurityDepositInterestsResource = "CalculateIndividualSecurityDepositInterests";
   static readonly CalculateManagementFeesResource = "CalculateManagementFees";
   static readonly CalculateManagementFundSweepResource = "CalculateManagementFundSweep";
   static readonly CalculateSecurityDepositInterestsResource = "CalculateSecurityDepositInterests";
   static readonly ChargeTypeBanksResource = "ChargeTypeBanks";
   static readonly ColorResource = "Color";
   static readonly CommitteeMembersResource = "CommitteeMembers";
   static readonly CommunityEventsResource = "CommunityEvents";
   static readonly ConvenienceFeeSummaryResource = "ConvenienceFeeSummary";
   static readonly CreateUserResource = "CreateUser";
   static readonly CurrentAssetLocationResource = "CurrentAssetLocation";
   static readonly CurrentOwnersResource = "CurrentOwners";
   static readonly DefaultBankResource = "DefaultBank";
   static readonly DefaultBankNameResource = "DefaultBankName";
   static readonly DefaultChargeSettingsResource = "DefaultChargeSettings";
   static readonly DefaultInspectorResource = "DefaultInspector";
   static readonly EmailForBoardMembersResource = "EmailForBoardMembers";
   static readonly EpaySettingsResource = "EpaySettings";
   static readonly FetchNonRecurringCREChargesResource = "FetchNonRecurringCRECharges";
   static readonly FloorsResource = "Floors";
   static readonly GetAllPropertiesResource = "GetAllProperties";
   static readonly GetApplyNowLinkResource = "GetApplyNowLink";
   static readonly GetByPostResource = "GetByPost";
   static readonly HasPostingAutomationsResource = "HasPostingAutomations";
   static readonly HistoryResource = "History";
   static readonly HistoryNotesResource = "HistoryNotes";
   static readonly HistorySystemNotesResource = "HistorySystemNotes";
   static readonly HolidaysResource = "Holidays";
   static readonly ImagesResource = "Images";
   static readonly ImageTypesResource = "ImageTypes";
   static readonly LateFeesResource = "LateFees";
   static readonly LateFeeSetupResource = "LateFeeSetup";
   static readonly LeaseTermPropertiesResource = "LeaseTermProperties";
   static readonly LinkUnitTypesResource = "LinkUnitTypes";
   static readonly LogoResource = "Logo";
   static readonly LogoFileResource = "LogoFile";
   static readonly MakeReadyTemplatePropertiesResource = "MakeReadyTemplateProperties";
   static readonly ManagementFeeBreakdownFeeResource = "ManagementFeeBreakdownFee";
   static readonly ManagementFeeHistoryResource = "ManagementFeeHistory";
   static readonly ManagementFeeHistoryChargesResource = "ManagementFeeHistoryCharges";
   static readonly ManagementFeeSetupResource = "ManagementFeeSetup";
   static readonly ManagementFundSweepResource = "ManagementFundSweep";
   static readonly MarketingDataResource = "MarketingData";
   static readonly MarketingSetupResource = "MarketingSetup";
   static readonly MarketingValuesResource = "MarketingValues";
   static readonly MergePropertiesResource = "MergeProperties";
   static readonly MigrateUnitsResource = "MigrateUnits";
   static readonly NonRecurringCREChargesResource = "NonRecurringCRECharges";
   static readonly OccupancySummaryResource = "OccupancySummary";
   static readonly OnlineListingsResource = "OnlineListings";
   static readonly OwnerCheckSetupResource = "OwnerCheckSetup";
   static readonly OwnerContractsResource = "OwnerContracts";
   static readonly OwnersResource = "Owners";
   static readonly OwnershipsResource = "Ownerships";
   static readonly PetTypePropertiesResource = "PetTypeProperties";
   static readonly PetTypesResource = "PetTypes";
   static readonly PhoneNumbersResource = "PhoneNumbers";
   static readonly PhoneNumberTypesResource = "PhoneNumberTypes";
   static readonly PollsResource = "Polls";
   static readonly PostManagementFeesResource = "PostManagementFees";
   static readonly PostManagementFeesAndGetTransactionsResource = "PostManagementFeesAndGetTransactions";
   static readonly PostSecurityDepositInterestResource = "PostSecurityDepositInterest";
   static readonly PrimaryAddressResource = "PrimaryAddress";
   static readonly PrimaryOwnerResource = "PrimaryOwner";
   static readonly PrimaryPhoneNumberResource = "PrimaryPhoneNumber";
   static readonly PropertiesAffectedByDeleteResource = "PropertiesAffectedByDelete";
   static readonly PropertiesWithListItemsResource = "PropertiesWithListItems";
   static readonly PropertyAmenitiesResource = "PropertyAmenities";
   static readonly PropertyBanksResource = "PropertyBanks";
   static readonly PropertyBanksExtResource = "PropertyBanksExt";
   static readonly PropertyContractsResource = "PropertyContracts";
   static readonly PropertyFileFoldersResource = "PropertyFileFolders";
   static readonly PropertyFilesResource = "PropertyFiles";
   static readonly PropertyFloorsResource = "PropertyFloors";
   static readonly PropertyGroupResource = "PropertyGroup";
   static readonly PropertyGroupDetailsResource = "PropertyGroupDetails";
   static readonly PropertyGroupsResource = "PropertyGroups";
   static readonly PropertyManagementFeeSetupsResource = "PropertyManagementFeeSetups";
   static readonly PropertyOwnersResource = "PropertyOwners";
   static readonly PropertyRentChargeTypesResource = "PropertyRentChargeTypes";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RateDiscountsResource = "RateDiscounts";
   static readonly RateSchedulesResource = "RateSchedules";
   static readonly RateSettingsResource = "RateSettings";
   static readonly RateTaxesResource = "RateTaxes";
   static readonly RecurringChargesResource = "RecurringCharges";
   static readonly RecurringChargeSummariesResource = "RecurringChargeSummaries";
   static readonly RentChargeTypeItemsResource = "RentChargeTypeItems";
   static readonly RentChargeTypesResource = "RentChargeTypes";
   static readonly ReservableAmenitiesResource = "ReservableAmenities";
   static readonly RMVoIPCallHistoryResource = "RMVoIPCallHistory";
   static readonly RollbackLastManagementFeePostingResource = "RollbackLastManagementFeePosting";
   static readonly SavePropertyFileFoldersSortOrderResource = "SavePropertyFileFoldersSortOrder";
   static readonly SaveRecurringChargeSortOrdersResource = "SaveRecurringChargeSortOrders";
   static readonly ScreeningCredentialsResource = "ScreeningCredentials";
   static readonly ScreeningReportTypesForUserResource = "ScreeningReportTypesForUser";
   static readonly ScreeningSettingResource = "ScreeningSetting";
   static readonly ScreeningSettingsResource = "ScreeningSettings";
   static readonly SearchResource = "Search";
   static readonly SecurityDepositInterestResource = "SecurityDepositInterest";
   static readonly ServiceManagerAssignedUserResource = "ServiceManagerAssignedUser";
   static readonly sortPropertyFilesAlphabeticallyResource = "sortPropertyFilesAlphabetically";
   static readonly TenantsForBoardMembersResource = "TenantsForBoardMembers";
   static readonly TestConnectionForScreeningsResource = "TestConnectionForScreenings";
   static readonly TestScriptResource = "TestScript";
   static readonly TextNumberPropertiesResource = "TextNumberProperties";
   static readonly TextNumbersResource = "TextNumbers";
   static readonly UnitsResource = "Units";
   static readonly UnitSquareFootageResource = "UnitSquareFootage";
   static readonly UnitTypePropertiesResource = "UnitTypeProperties";
   static readonly UnitTypesResource = "UnitTypes";
   static readonly UnLinkUnitTypesResource = "UnLinkUnitTypes";
   static readonly UpdateContactAndCreateTWAResource = "UpdateContactAndCreateTWA";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadImageResource = "UploadImage";
   static readonly UploadLogoFileResource = "UploadLogoFile";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly UserPropertiesResource = "UserProperties";
   static readonly UsersResource = "Users";
   static readonly UtilityPropertiesResource = "UtilityProperties";
   static readonly ValidateBoardMemberResource = "ValidateBoardMember";
   static readonly ViolationsResource = "Violations";
   static readonly endpoint = "PropertiesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | PropertySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: PropertyModel | null,
      saveOptions?: Array<string | PropertySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | PropertySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<PropertyModel>,
      saveOptions?: Array<string | PropertySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | PropertyDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(PropertiesService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | PropertyDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(PropertiesService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAccountingCloseUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AccountingClose",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAccountingClose(
      id: number,
      itemID: number,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AccountingCloseModel> {
      return this.apiService.getSingle(
         this.getAccountingCloseUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAccountingCloseCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AccountingClose",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAccountingCloseCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountingCloseModel>> {
      return this.apiService.getCollection(
         this.getAccountingCloseCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAccountingCloseUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AccountingClose",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAccountingClose(
      id: number,
      item?: AccountingCloseModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AccountingCloseModel> {
      return this.apiService.postSingle(
         this.postAccountingCloseUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAccountingCloseCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AccountingClose",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAccountingCloseCollection(
      id: number,
      items: Array<AccountingCloseModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountingCloseModel>> {
      return this.apiService.postCollection(
         this.postAccountingCloseCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteAccountingCloseUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/AccountingClose",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAccountingClose(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAccountingCloseUrl(id, ids, deleteOptions, additionalParameters));
   }

   getActiveAccountingCloseCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ActiveAccountingClose",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getActiveAccountingCloseCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountingCloseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountingCloseSummaryModel>> {
      return this.apiService.getCollection(
         this.getActiveAccountingCloseCollectionUrl(id, filters, embeds, fields, additionalParameters)
      );
   }

   getAddressesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Addresses",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddresses(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(
         this.getAddressesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAddressesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Addresses",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAddressesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Addresses",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAllAddressesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddresses(
      id: number,
      item?: AddressModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.postSingle(
         this.postAddressesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAddressesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddressesCollection(
      id: number,
      items: Array<AddressModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.postCollection(
         this.postAddressesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteAddressesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Addresses",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAddresses(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getAddressTypesUrl(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddressTypes(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.getSingle(this.getAddressTypesUrl(itemID, embeds, additionalParameters));
   }

   getAddressTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AddressTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAddressTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAllAddressTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypes(
      item?: AddressTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.postSingle(this.postAddressTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   postAllAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAllAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   deleteAddressTypesUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/AddressTypes",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteAddressTypes(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressTypesUrl(ids, additionalParameters));
   }

   getAllocationOrdersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AllocationOrders",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllocationOrders(
      id: number,
      itemID: number,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AllocationOrderModel> {
      return this.apiService.getSingle(
         this.getAllocationOrdersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAllocationOrdersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AllocationOrders",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllocationOrdersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AllocationOrderModel>> {
      return this.apiService.getCollection(
         this.getAllocationOrdersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAllocationOrdersCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AllocationOrders",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAllocationOrdersCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AllocationOrderModel>> {
      return this.apiService.getCollection(
         this.getAllAllocationOrdersCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAllocationOrdersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AllocationOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllocationOrders(
      id: number,
      item?: AllocationOrderModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AllocationOrderModel> {
      return this.apiService.postSingle(
         this.postAllocationOrdersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAllocationOrdersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AllocationOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllocationOrdersCollection(
      id: number,
      items: Array<AllocationOrderModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AllocationOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AllocationOrderModel>> {
      return this.apiService.postCollection(
         this.postAllocationOrdersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   getApplicationSettingsUrl(
      id: number,
      embeds?: Array<string | ApplicationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ApplicationSettings",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getApplicationSettings(
      id: number,
      embeds?: Array<string | ApplicationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ApplicationSettingsModel> {
      return this.apiService.getSingle(this.getApplicationSettingsUrl(id, embeds, fields, additionalParameters));
   }

   postApplicationSettingsUrl(
      id: number,
      embeds?: Array<string | ApplicationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ApplicationSettings",
         id,
         null,
         embeds,
         fields,
         additionalParameters
      );
   }

   postApplicationSettings(
      id: number,
      model?: ApplicationSettingsModel | null,
      embeds?: Array<string | ApplicationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ApplicationSettingsModel> {
      return this.apiService
         .post(this.postApplicationSettingsUrl(id, embeds, fields, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getApplicationTemplatesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ApplicationTemplateEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ApplicationTemplates",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getApplicationTemplates(
      id: number,
      itemID: number,
      embeds?: Array<string | ApplicationTemplateEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ApplicationTemplateModel> {
      return this.apiService.getSingle(
         this.getApplicationTemplatesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getApplicationTemplatesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ApplicationTemplateEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ApplicationTemplates",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getApplicationTemplatesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ApplicationTemplateEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ApplicationTemplateModel>> {
      return this.apiService.getCollection(
         this.getApplicationTemplatesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getARAutomationScheduleUrl(
      id: number,
      embeds?: Array<string | ARAutomationScheduleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ARAutomationSchedule",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getARAutomationSchedule(
      id: number,
      embeds?: Array<string | ARAutomationScheduleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ARAutomationScheduleModel> {
      return this.apiService.getSingle(
         this.getARAutomationScheduleUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAssetLocationHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AssetLocationHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssetLocationHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AssetLocationHistoryModel> {
      return this.apiService.getSingle(
         this.getAssetLocationHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAssetLocationHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AssetLocationHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAssetLocationHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AssetLocationHistoryModel>> {
      return this.apiService.getCollection(
         this.getAssetLocationHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAssetsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Assets",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAssetsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AssetModel>> {
      return this.apiService.getCollection(
         this.getAssetsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAssignedUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AssignedUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssignedUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getAssignedUserUrl(id, embeds, additionalParameters));
   }

   getAssociationSettingsUrl(
      id: number,
      embeds?: Array<string | AssociationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/AssociationSettings",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssociationSettings(
      id: number,
      embeds?: Array<string | AssociationSettingsEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AssociationSettingsModel> {
      return this.apiService.getSingle(
         this.getAssociationSettingsUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postAssociationSettingsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AssociationSettings",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postAssociationSettings(
      id: number,
      model?: AssociationSettingsModel | null,
      additionalParameters?: Array<string>
   ): Observable<AssociationSettingsModel> {
      return this.apiService
         .post(this.postAssociationSettingsUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postAssociationSettingsGetRTFValuesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/AssociationSettingsGetRTFValues",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postAssociationSettingsGetRTFValues(
      associationSettingsModel?: AssociationSettingsModel | null,
      additionalParameters?: Array<string>
   ): Observable<AssociationSettingsModel> {
      return this.apiService
         .post(this.postAssociationSettingsGetRTFValuesUrl(additionalParameters), associationSettingsModel)
         .pipe(map((response) => response.body));
   }

   postAvailabilityUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Availability",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postAvailability(
      id: number,
      model?: PropertyAvailabilityModel | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService
         .post(this.postAvailabilityUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Banks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | BankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<BankModel> {
      return this.apiService.getSingle(this.getBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Banks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BankModel>> {
      return this.apiService.getCollection(
         this.getBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postBatchEmailPropertiesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BatchEmailProperties",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailProperties(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailPropertiesUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailPropertiesFromUnitsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BatchEmailPropertiesFromUnits",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailPropertiesFromUnits(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailPropertiesFromUnitsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   getBeginningBalancesCollectionUrl(
      id: number,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      getOptions?: Array<string | BeginningBalanceGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BeginningBalances",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBeginningBalancesCollection(
      id: number,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      getOptions?: Array<string | BeginningBalanceGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BeginningBalanceModel>> {
      return this.apiService.getCollection(
         this.getBeginningBalancesCollectionUrl(id, embeds, getOptions, additionalParameters)
      );
   }

   postBeginningBalancesUrl(
      id: number,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      saveOptions?: Array<string | BeginningBalanceSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BeginningBalances",
         id,
         saveOptions,
         embeds,
         null,
         additionalParameters
      );
   }

   postBeginningBalances(
      id: number,
      item?: BeginningBalanceModel | null,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      saveOptions?: Array<string | BeginningBalanceSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BeginningBalanceModel> {
      return this.apiService.postSingle(
         this.postBeginningBalancesUrl(id, embeds, saveOptions, additionalParameters),
         item
      );
   }

   postBeginningBalancesCollectionUrl(
      id: number,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      saveOptions?: Array<string | BeginningBalanceSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BeginningBalances",
         id,
         saveOptions,
         embeds,
         null,
         additionalParameters
      );
   }

   postBeginningBalancesCollection(
      id: number,
      models?: Array<BeginningBalanceModel> | null,
      embeds?: Array<string | BeginningBalanceEmbedOptions> | null,
      saveOptions?: Array<string | BeginningBalanceSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BeginningBalanceModel>> {
      return this.apiService.postCollection(
         this.postBeginningBalancesCollectionUrl(id, embeds, saveOptions, additionalParameters),
         models
      );
   }

   getBillingAddressUrl(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BillingAddress",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBillingAddress(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(this.getBillingAddressUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getBoardMembersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BoardMembers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBoardMembers(
      id: number,
      itemID: number,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<BoardMemberTermModel> {
      return this.apiService.getSingle(
         this.getBoardMembersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getBoardMembersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BoardMembers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBoardMembersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BoardMemberTermModel>> {
      return this.apiService.getCollection(
         this.getBoardMembersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getBoardMemberTermsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BoardMemberTerms",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBoardMemberTerms(
      id: number,
      itemID: number,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<BoardMemberTermModel> {
      return this.apiService.getSingle(this.getBoardMemberTermsUrl(id, itemID, embeds, fields, additionalParameters));
   }

   getBoardMemberTermsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/BoardMemberTerms",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBoardMemberTermsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BoardMemberTermModel>> {
      return this.apiService.getCollection(
         this.getBoardMemberTermsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postBoardMemberTermsUrl(
      id: number,
      saveOptions?: Array<string | BoardMemberTermSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BoardMemberTerms",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBoardMemberTerms(
      id: number,
      item?: BoardMemberTermModel | null,
      saveOptions?: Array<string | BoardMemberTermSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BoardMemberTermModel> {
      return this.apiService.postSingle(
         this.postBoardMemberTermsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postBoardMemberTermsCollectionUrl(
      id: number,
      saveOptions?: Array<string | BoardMemberTermSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/BoardMemberTerms",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBoardMemberTermsCollection(
      id: number,
      items: Array<BoardMemberTermModel>,
      saveOptions?: Array<string | BoardMemberTermSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BoardMemberTermEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BoardMemberTermModel>> {
      return this.apiService.postCollection(
         this.postBoardMemberTermsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteBoardMemberTermsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/BoardMemberTerms",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteBoardMemberTerms(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteBoardMemberTermsUrl(id, ids, additionalParameters));
   }

   getCalculateCapAmountUrl(
      accountNumber: number,
      leaseID: number,
      capped?: number | null,
      charge?: number | null,
      chargeID?: number | null,
      asOfDate?: Date | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountNumber=" + accountNumber);
      additionalParameters.push("leaseID=" + leaseID);
      if (capped !== undefined && capped !== null) {
         additionalParameters.push("capped=" + capped);
      }
      if (charge !== undefined && charge !== null) {
         additionalParameters.push("charge=" + charge);
      }
      if (chargeID !== undefined && chargeID !== null) {
         additionalParameters.push("chargeID=" + chargeID);
      }
      if (asOfDate !== undefined && asOfDate !== null) {
         additionalParameters.push("asOfDate=" + asOfDate);
      }
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CalculateCapAmount",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCalculateCapAmount(
      accountNumber: number,
      leaseID: number,
      capped?: number | null,
      charge?: number | null,
      chargeID?: number | null,
      asOfDate?: Date | null,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService.getSingle(
         this.getCalculateCapAmountUrl(accountNumber, leaseID, capped, charge, chargeID, asOfDate, additionalParameters)
      );
   }

   postCalculateIndividualSecurityDepositInterestsUrl(
      embeds?: Array<string | CalculatedSecurityDepositInterestEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CalculateIndividualSecurityDepositInterests",
         null,
         null,
         embeds,
         null,
         additionalParameters
      );
   }

   postCalculateIndividualSecurityDepositInterests(
      item?: PostSecurityDepositInterestModel | null,
      embeds?: Array<string | CalculatedSecurityDepositInterestEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CalculatedSecurityDepositInterestModel>> {
      return this.apiService
         .post(this.postCalculateIndividualSecurityDepositInterestsUrl(embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   postCalculateManagementFeesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CalculateManagementFees",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCalculateManagementFees(
      model?: CalculateManagementFeeModel | null,
      additionalParameters?: Array<string>
   ): Observable<CalculatedManagementFeesModel> {
      return this.apiService
         .post(this.postCalculateManagementFeesUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postCalculateManagementFundSweepUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CalculateManagementFundSweep",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCalculateManagementFundSweep(
      sweep?: CalculateManagementFundSweepModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CalculateManagementFundSweepSummaryModel>> {
      return this.apiService
         .post(this.postCalculateManagementFundSweepUrl(additionalParameters), sweep)
         .pipe(map((response) => response.body));
   }

   postCalculateSecurityDepositInterestsUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CalculatedSecurityDepositInterestEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (filters !== undefined && filters !== null && filters.length > 0) {
         additionalParameters.push("filters=" + filters);
      }
      if (orderingOptions !== undefined && orderingOptions !== null && orderingOptions.length > 0) {
         additionalParameters.push("orderingOptions=" + orderingOptions);
      }
      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CalculateSecurityDepositInterests",
         null,
         null,
         embeds,
         null,
         additionalParameters
      );
   }

   postCalculateSecurityDepositInterests(
      item?: CalculateSecurityDepositInterestByPropertiesModel | null,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CalculatedSecurityDepositInterestEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CalculatedSecurityDepositInterestModel>> {
      return this.apiService
         .post(
            this.postCalculateSecurityDepositInterestsUrl(
               filters,
               embeds,
               orderingOptions,
               pageSize,
               pageNumber,
               additionalParameters
            ),
            item
         )
         .pipe(map((response) => response.body));
   }

   getChargeTypeBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ChargeTypeBanks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChargeTypeBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeBankModel> {
      return this.apiService.getSingle(
         this.getChargeTypeBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChargeTypeBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ChargeTypeBanks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChargeTypeBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeBankModel>> {
      return this.apiService.getCollection(
         this.getChargeTypeBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChargeTypeBanksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ChargeTypeBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChargeTypeBanks(
      id: number,
      item?: ChargeTypeBankModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeBankModel> {
      return this.apiService.postSingle(
         this.postChargeTypeBanksUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postChargeTypeBanksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ChargeTypeBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChargeTypeBanksCollection(
      id: number,
      items: Array<ChargeTypeBankModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeBankModel>> {
      return this.apiService.postCollection(
         this.postChargeTypeBanksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteChargeTypeBanksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ChargeTypeBanks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteChargeTypeBanks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChargeTypeBanksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getColorUrl(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Color",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getColor(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ColorModel> {
      return this.apiService.getSingle(this.getColorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCommitteeMembersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CommitteeMembers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCommitteeMembers(
      id: number,
      itemID: number,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CommitteeMemberModel> {
      return this.apiService.getSingle(
         this.getCommitteeMembersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCommitteeMembersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CommitteeMembers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCommitteeMembersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CommitteeMemberModel>> {
      return this.apiService.getCollection(
         this.getCommitteeMembersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCommitteeMembersUrl(
      id: number,
      saveOptions?: Array<string | CommitteeMemberSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CommitteeMembers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCommitteeMembers(
      id: number,
      item?: CommitteeMemberModel | null,
      saveOptions?: Array<string | CommitteeMemberSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CommitteeMemberModel> {
      return this.apiService.postSingle(
         this.postCommitteeMembersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCommitteeMembersCollectionUrl(
      id: number,
      saveOptions?: Array<string | CommitteeMemberSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CommitteeMembers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCommitteeMembersCollection(
      id: number,
      items: Array<CommitteeMemberModel>,
      saveOptions?: Array<string | CommitteeMemberSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommitteeMemberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CommitteeMemberModel>> {
      return this.apiService.postCollection(
         this.postCommitteeMembersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCommitteeMembersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/CommitteeMembers",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCommitteeMembers(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCommitteeMembersUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCommunityEventsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CommunityEvents",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCommunityEvents(
      id: number,
      itemID: number,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CommunityEventModel> {
      return this.apiService.getSingle(
         this.getCommunityEventsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCommunityEventsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CommunityEvents",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCommunityEventsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CommunityEventModel>> {
      return this.apiService.getCollection(
         this.getCommunityEventsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCommunityEventsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CommunityEvents",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCommunityEvents(
      id: number,
      item?: CommunityEventModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CommunityEventModel> {
      return this.apiService.postSingle(
         this.postCommunityEventsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCommunityEventsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/CommunityEvents",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCommunityEventsCollection(
      id: number,
      items: Array<CommunityEventModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CommunityEventEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CommunityEventModel>> {
      return this.apiService.postCollection(
         this.postCommunityEventsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCommunityEventsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/CommunityEvents",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCommunityEvents(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCommunityEventsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getConvenienceFeeSummaryUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ConvenienceFeeSummary",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getConvenienceFeeSummary(
      id: number,
      additionalParameters?: Array<string>
   ): Observable<EPayConvenienceFeeSummaryModel> {
      return this.apiService.getSingle(this.getConvenienceFeeSummaryUrl(id, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCurrentAssetLocationUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CurrentAssetLocation",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCurrentAssetLocation(
      id: number,
      itemID: number,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AssetLocationHistoryModel> {
      return this.apiService.getSingle(
         this.getCurrentAssetLocationUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCurrentAssetLocationCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CurrentAssetLocation",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCurrentAssetLocationCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AssetLocationHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AssetLocationHistoryModel>> {
      return this.apiService.getCollection(
         this.getCurrentAssetLocationCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCurrentOwnersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CurrentOwners",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCurrentOwners(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.getSingle(
         this.getCurrentOwnersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCurrentOwnersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/CurrentOwners",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCurrentOwnersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(
         this.getCurrentOwnersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getDefaultBankUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/DefaultBank",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultBank(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getDefaultBankUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getDefaultBankNameUrl(bankId: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("bankId=" + bankId);
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/DefaultBankName",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultBankName(bankId: number, additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getDefaultBankNameUrl(bankId, additionalParameters));
   }

   getDefaultChargeSettingsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/DefaultChargeSettings",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultChargeSettings(
      id: number,
      itemID: number,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<DefaultChargeSettingModel> {
      return this.apiService.getSingle(
         this.getDefaultChargeSettingsUrl(id, itemID, embeds, fields, additionalParameters)
      );
   }

   getDefaultChargeSettingsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/DefaultChargeSettings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getDefaultChargeSettingsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DefaultChargeSettingModel>> {
      return this.apiService.getCollection(
         this.getDefaultChargeSettingsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postDefaultChargeSettingsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/DefaultChargeSettings",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postDefaultChargeSettings(
      id: number,
      item?: DefaultChargeSettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<DefaultChargeSettingModel> {
      return this.apiService.postSingle(this.postDefaultChargeSettingsUrl(id, saveOptions, additionalParameters), item);
   }

   postDefaultChargeSettingsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/DefaultChargeSettings",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postDefaultChargeSettingsCollection(
      id: number,
      details?: Array<DefaultChargeSettingModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DefaultChargeSettingModel>> {
      return this.apiService.postCollection(
         this.postDefaultChargeSettingsCollectionUrl(id, saveOptions, additionalParameters),
         details
      );
   }

   getDefaultInspectorUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/DefaultInspector",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultInspector(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(
         this.getDefaultInspectorUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postEmailForBoardMembersUrl(isNewBoardMember?: boolean | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (isNewBoardMember !== undefined && isNewBoardMember !== null) {
         additionalParameters.push("isNewBoardMember=" + isNewBoardMember);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/EmailForBoardMembers",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postEmailForBoardMembers(
      isNewBoardMember: boolean,
      boardMemberEmailModel?: BoardMemberEmailModel | null,
      additionalParameters?: Array<string>
   ): Observable<BoardMemberEmailModel> {
      return this.apiService
         .post(this.postEmailForBoardMembersUrl(isNewBoardMember, additionalParameters), boardMemberEmailModel)
         .pipe(map((response) => response.body));
   }

   getEpaySettingsUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpaySettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/EpaySettings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEpaySettings(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpaySettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<EpaySettingModel> {
      return this.apiService.getSingle(
         this.getEpaySettingsUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postEpaySettingsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/EpaySettings",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postEpaySettings(
      id: number,
      epaySettings?: EpaySettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<EpaySettingModel> {
      return this.apiService
         .post(this.postEpaySettingsUrl(id, saveOptions, fields, additionalParameters), epaySettings)
         .pipe(map((response) => response.body));
   }

   deleteEpaySettingsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/EpaySettings",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteEpaySettings(id: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteEpaySettingsUrl(id, additionalParameters));
   }

   postFetchNonRecurringCREChargesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/FetchNonRecurringCRECharges",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postFetchNonRecurringCRECharges(
      requestModel?: NonRecurringCRERequestModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<NonRecurringCREChargeSummaryModel>> {
      return this.apiService
         .post(this.postFetchNonRecurringCREChargesUrl(additionalParameters), requestModel)
         .pipe(map((response) => response.body));
   }

   getFloorsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | FloorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Floors",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getFloors(
      id: number,
      itemID: number,
      embeds?: Array<string | FloorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FloorModel> {
      return this.apiService.getSingle(this.getFloorsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getFloorsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FloorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Floors",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getFloorsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FloorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<FloorModel>> {
      return this.apiService.getCollection(
         this.getFloorsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getGetAllPropertiesCollectionUrl(
      filters?: Array<FilterOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/GetAllProperties",
         null,
         null,
         filters,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetAllPropertiesCollection(
      filters?: Array<FilterOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(this.getGetAllPropertiesCollectionUrl(filters, additionalParameters));
   }

   getGetApplyNowLinkUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/GetApplyNowLink",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetApplyNowLink(id: number, additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getGetApplyNowLinkUrl(id, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getHasPostingAutomationsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HasPostingAutomations",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHasPostingAutomations(additionalParameters?: Array<string>): Observable<Boolean> {
      return this.apiService.getSingle(this.getHasPostingAutomationsUrl(additionalParameters));
   }

   getHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/History",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/History",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getHistoryNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.getSingle(this.getHistoryNotesUrl(id, itemID, embeds, additionalParameters));
   }

   getHistoryNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryNotesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotes(
      item?: HistoryNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryNotesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryNotesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/HistoryNotes",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteHistoryNotes(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryNotesUrl(id, ids, additionalParameters));
   }

   getHistorySystemNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.getSingle(
         this.getHistorySystemNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistorySystemNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getHistorySystemNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistorySystemNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistorySystemNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistorySystemNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistorySystemNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotes(
      id: number,
      item?: HistorySystemNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.postSingle(
         this.postHistorySystemNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistorySystemNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotesCollection(
      id: number,
      items: Array<HistorySystemNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.postCollection(
         this.postHistorySystemNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistorySystemNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/HistorySystemNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistorySystemNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteHistorySystemNotesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getHolidaysUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Holidays",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHolidays(
      id: number,
      itemID: number,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HolidayModel> {
      return this.apiService.getSingle(
         this.getHolidaysUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHolidaysCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Holidays",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHolidaysCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HolidayModel>> {
      return this.apiService.getCollection(
         this.getHolidaysCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHolidaysUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Holidays",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHolidays(
      id: number,
      item?: HolidayModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HolidayModel> {
      return this.apiService.postSingle(
         this.postHolidaysUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHolidaysCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Holidays",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHolidaysCollection(
      id: number,
      items: Array<HolidayModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HolidayEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HolidayModel>> {
      return this.apiService.postCollection(
         this.postHolidaysCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHolidaysUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Holidays",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHolidays(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHolidaysUrl(id, ids, deleteOptions, additionalParameters));
   }

   getImagesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ImageEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Images",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getImages(
      id: number,
      itemID: number,
      embeds?: Array<string | ImageEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ImageModel> {
      return this.apiService.getSingle(this.getImagesUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getImagesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Images",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getImagesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageModel>> {
      return this.apiService.getCollection(
         this.getImagesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllImagesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Images",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllImagesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageModel>> {
      return this.apiService.getCollection(
         this.getAllImagesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postImagesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Images",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postImages(
      id: number,
      item?: ImageModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ImageModel> {
      return this.apiService.postSingle(
         this.postImagesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postImagesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Images",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postImagesCollection(
      id: number,
      items: Array<ImageModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ImageEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageModel>> {
      return this.apiService.postCollection(
         this.postImagesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteImagesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Images",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteImages(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteImagesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getImageTypesUrl(
      itemID: number,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getImageTypes(
      itemID: number,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ImageTypeModel> {
      return this.apiService.getSingle(this.getImageTypesUrl(itemID, embeds, additionalParameters));
   }

   getImageTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ImageTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getImageTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageTypeModel>> {
      return this.apiService.getCollection(
         this.getImageTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllImageTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllImageTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ImageTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageTypeModel>> {
      return this.apiService.getCollection(
         this.getAllImageTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postImageTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postImageTypes(
      item?: ImageTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ImageTypeModel> {
      return this.apiService.postSingle(this.postImageTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postImageTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postImageTypesCollection(
      imageTypes?: Array<ImageTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageTypeModel>> {
      return this.apiService.postCollection(
         this.postImageTypesCollectionUrl(saveOptions, fields, additionalParameters),
         imageTypes
      );
   }

   postAllImageTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllImageTypesCollection(
      imageTypes?: Array<ImageTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ImageTypeModel>> {
      return this.apiService.postCollection(
         this.postAllImageTypesCollectionUrl(saveOptions, fields, additionalParameters),
         imageTypes
      );
   }

   deleteImageTypesUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ImageTypes",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteImageTypes(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteImageTypesUrl(ids, additionalParameters));
   }

   getLateFeesUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/LateFees",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLateFees(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService.getSingle(
         this.getLateFeesUrl(id, filters, embeds, orderingOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   postLateFeesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LateFees",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLateFees(
      id: number,
      lateFee?: LateFeeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService
         .post(this.postLateFeesUrl(id, saveOptions, fields, additionalParameters), lateFee)
         .pipe(map((response) => response.body));
   }

   getLateFeeSetupUrl(
      id: number,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/LateFeeSetup",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLateFeeSetup(
      id: number,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService.getSingle(this.getLateFeeSetupUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postLateFeeSetupUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LateFeeSetup",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLateFeeSetup(
      id: number,
      item?: LateFeeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService
         .post(this.postLateFeeSetupUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteLateFeeSetupUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/LateFeeSetup",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLateFeeSetup(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLateFeeSetupUrl(id, itemID, deleteOptions, additionalParameters));
   }

   getLeaseTermPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseTermPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/LeaseTermProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLeaseTermPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseTermPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseTermPropertyModel>> {
      return this.apiService.getCollection(
         this.getLeaseTermPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLeaseTermPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LeaseTermProperties",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLeaseTermProperties(
      id: number,
      item?: LeaseTermPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseTermPropertyModel> {
      return this.apiService.postSingle(
         this.postLeaseTermPropertiesUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postLeaseTermPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LeaseTermProperties",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLeaseTermPropertiesCollection(
      id: number,
      leaseTermEntityModels?: Array<LeaseTermPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseTermPropertyModel>> {
      return this.apiService.postCollection(
         this.postLeaseTermPropertiesCollectionUrl(id, saveOptions, fields, additionalParameters),
         leaseTermEntityModels
      );
   }

   deleteLeaseTermPropertiesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/LeaseTermProperties",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteLeaseTermProperties(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLeaseTermPropertiesUrl(id, ids, additionalParameters));
   }

   postLinkUnitTypesUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LinkUnitTypes",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkUnitTypes(
      id: number,
      unitTypeIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postLinkUnitTypesUrl(id, additionalParameters), unitTypeIDs);
   }

   getLogoUrl(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Logo",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLogo(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService.getSingle(this.getLogoUrl(id, embeds, additionalParameters));
   }

   postLogoUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(PropertiesService.endpoint + "/Logo", id, null, null, null, additionalParameters);
   }

   postLogo(id: number, logoFile?: FileModel | null, additionalParameters?: Array<string>): Observable<FileModel> {
      return this.apiService
         .post(this.postLogoUrl(id, additionalParameters), logoFile)
         .pipe(map((response) => response.body));
   }

   getLogoFileUrl(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/LogoFile",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLogoFile(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService.getSingle(this.getLogoFileUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postLogoFileUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/LogoFile",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLogoFile(id: number, logoFile?: FileModel | null, additionalParameters?: Array<string>): Observable<FileModel> {
      return this.apiService
         .post(this.postLogoFileUrl(id, additionalParameters), logoFile)
         .pipe(map((response) => response.body));
   }

   getMakeReadyTemplatePropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MakeReadyTemplateProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMakeReadyTemplateProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MakeReadyTemplatePropertyModel> {
      return this.apiService.getSingle(
         this.getMakeReadyTemplatePropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMakeReadyTemplatePropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MakeReadyTemplateProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMakeReadyTemplatePropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MakeReadyTemplatePropertyModel>> {
      return this.apiService.getCollection(
         this.getMakeReadyTemplatePropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMakeReadyTemplatePropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MakeReadyTemplateProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMakeReadyTemplateProperties(
      id: number,
      item?: MakeReadyTemplatePropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MakeReadyTemplatePropertyModel> {
      return this.apiService.postSingle(
         this.postMakeReadyTemplatePropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postMakeReadyTemplatePropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MakeReadyTemplateProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMakeReadyTemplatePropertiesCollection(
      id: number,
      items: Array<MakeReadyTemplatePropertyModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyTemplatePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MakeReadyTemplatePropertyModel>> {
      return this.apiService.postCollection(
         this.postMakeReadyTemplatePropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteMakeReadyTemplatePropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/MakeReadyTemplateProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteMakeReadyTemplateProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteMakeReadyTemplatePropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   postManagementFeeBreakdownFeeUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ManagementFeeBreakdownFee",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postManagementFeeBreakdownFee(
      id: number,
      model?: ManagementFeeBreakdownFeeRequestModel | null,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService
         .post(this.postManagementFeeBreakdownFeeUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getManagementFeeHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ManagementFeeHistoryModel> {
      return this.apiService.getSingle(
         this.getManagementFeeHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getManagementFeeHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ManagementFeeHistoryModel>> {
      return this.apiService.getCollection(
         this.getManagementFeeHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getManagementFeeHistoryChargesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeHistoryChargesEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeHistoryCharges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeHistoryCharges(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeHistoryChargesEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ManagementFeeHistoryChargesModel> {
      return this.apiService.getSingle(
         this.getManagementFeeHistoryChargesUrl(id, itemID, embeds, additionalParameters)
      );
   }

   getManagementFeeHistoryChargesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeHistoryChargesEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeHistoryCharges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeHistoryChargesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeHistoryChargesEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ManagementFeeHistoryChargesModel>> {
      return this.apiService.getCollection(
         this.getManagementFeeHistoryChargesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getManagementFeeSetupUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeSetup",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeSetup(
      id: number,
      itemID: number,
      embeds?: Array<string | ManagementFeeSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ManagementFeeSetupModel> {
      return this.apiService.getSingle(
         this.getManagementFeeSetupUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getManagementFeeSetupCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ManagementFeeSetup",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getManagementFeeSetupCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ManagementFeeSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ManagementFeeSetupModel>> {
      return this.apiService.getCollection(
         this.getManagementFeeSetupCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postManagementFundSweepUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ManagementFundSweep",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postManagementFundSweep(
      sweep?: ManagementFundSweepModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ManagementFundSweepSummaryModel>> {
      return this.apiService
         .post(this.postManagementFundSweepUrl(additionalParameters), sweep)
         .pipe(map((response) => response.body));
   }

   getMarketingDataUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MarketingData",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMarketingData(id: number, additionalParameters?: Array<string>): Observable<MarketingDataModel> {
      return this.apiService.getSingle(this.getMarketingDataUrl(id, additionalParameters));
   }

   getMarketingSetupUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MarketingSetup",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMarketingSetup(
      id: number,
      itemID: number,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MarketingSetupModel> {
      return this.apiService.getSingle(
         this.getMarketingSetupUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMarketingSetupCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MarketingSetup",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMarketingSetupCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MarketingSetupModel>> {
      return this.apiService.getCollection(
         this.getMarketingSetupCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMarketingSetupUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MarketingSetup",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMarketingSetup(
      id: number,
      item?: MarketingSetupModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MarketingSetupModel> {
      return this.apiService.postSingle(
         this.postMarketingSetupUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postMarketingSetupCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MarketingSetup",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMarketingSetupCollection(
      id: number,
      items: Array<MarketingSetupModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MarketingSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MarketingSetupModel>> {
      return this.apiService.postCollection(
         this.postMarketingSetupCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteMarketingSetupUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/MarketingSetup",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteMarketingSetup(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteMarketingSetupUrl(id, ids, deleteOptions, additionalParameters));
   }

   getMarketingValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MarketingValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MarketingValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMarketingValues(
      id: number,
      itemID: number,
      embeds?: Array<string | MarketingValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MarketingValueModel> {
      return this.apiService.getSingle(
         this.getMarketingValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMarketingValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MarketingValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/MarketingValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMarketingValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MarketingValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MarketingValueModel>> {
      return this.apiService.getCollection(
         this.getMarketingValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMergePropertiesUrl(
      saveOptions?: Array<string | MergePropertySaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MergeProperties",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postMergeProperties(
      item?: MergePropertyModel | null,
      saveOptions?: Array<string | MergePropertySaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService
         .post(this.postMergePropertiesUrl(saveOptions, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   postMigrateUnitsUrl(
      saveOptions?: Array<string | MigrateUnitSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/MigrateUnits",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postMigrateUnits(
      migrateUnit?: MigrateUnitModel | null,
      saveOptions?: Array<string | MigrateUnitSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService
         .post(this.postMigrateUnitsUrl(saveOptions, additionalParameters), migrateUnit)
         .pipe(map((response) => response.body));
   }

   postNonRecurringCREChargesUrl(
      saveOptions?: Array<string | NonRecurringCRESaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/NonRecurringCRECharges",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postNonRecurringCRECharges(
      postModel?: PostNonRecurringCREChargesModel | null,
      saveOptions?: Array<string | NonRecurringCRESaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PostingModel> {
      return this.apiService
         .post(this.postNonRecurringCREChargesUrl(saveOptions, additionalParameters), postModel)
         .pipe(map((response) => response.body));
   }

   getOccupancySummaryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OccupancySummary",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOccupancySummaryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OccupancySummaryModel>> {
      return this.apiService.getCollection(
         this.getOccupancySummaryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOccupancySummaryCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OccupancySummary",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOccupancySummaryCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OccupancySummaryModel>> {
      return this.apiService.getCollection(
         this.getAllOccupancySummaryCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOnlineListingsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OnlineListings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOnlineListingsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MarketingDataModel>> {
      return this.apiService.getCollection(
         this.getOnlineListingsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOnlineListingsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OnlineListings",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOnlineListingsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MarketingDataModel>> {
      return this.apiService.getCollection(
         this.getAllOnlineListingsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerCheckSetupUrl(
      id: number,
      embeds?: Array<string | OwnerCheckSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OwnerCheckSetup",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerCheckSetup(
      id: number,
      embeds?: Array<string | OwnerCheckSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerCheckSetupModel> {
      return this.apiService.getSingle(
         this.getOwnerCheckSetupUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postOwnerCheckSetupUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerCheckSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/OwnerCheckSetup",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnerCheckSetup(
      id: number,
      item?: OwnerCheckSetupModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerCheckSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerCheckSetupModel> {
      return this.apiService
         .post(this.postOwnerCheckSetupUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteOwnerCheckSetupUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/OwnerCheckSetup",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteOwnerCheckSetup(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteOwnerCheckSetupUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getOwnerContractsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OwnerContracts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerContracts(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.getSingle(this.getOwnerContractsUrl(id, itemID, embeds, additionalParameters));
   }

   getOwnerContractsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/OwnerContracts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerContractsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getOwnerContractsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnersCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Owners",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnersCollection(id: number, additionalParameters?: Array<string>): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(this.getOwnersCollectionUrl(id, additionalParameters));
   }

   getOwnershipsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Ownerships",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerships(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.getSingle(
         this.getOwnershipsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnershipsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Ownerships",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnershipsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getOwnershipsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOwnershipsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Ownerships",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOwnershipsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getAllOwnershipsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postOwnershipsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Ownerships",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnerships(
      id: number,
      item?: OwnerContractModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.postSingle(
         this.postOwnershipsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postOwnershipsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Ownerships",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnershipsCollection(
      id: number,
      items: Array<OwnerContractModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.postCollection(
         this.postOwnershipsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteOwnershipsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Ownerships",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteOwnerships(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteOwnershipsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPetTypePropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PetTypeProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPetTypeProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PetTypePropertyModel> {
      return this.apiService.getSingle(
         this.getPetTypePropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPetTypePropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PetTypeProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPetTypePropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PetTypePropertyModel>> {
      return this.apiService.getCollection(
         this.getPetTypePropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPetTypePropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PetTypeProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPetTypeProperties(
      id: number,
      item?: PetTypePropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PetTypePropertyModel> {
      return this.apiService.postSingle(
         this.postPetTypePropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPetTypePropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PetTypeProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPetTypePropertiesCollection(
      id: number,
      items: Array<PetTypePropertyModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PetTypePropertyModel>> {
      return this.apiService.postCollection(
         this.postPetTypePropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePetTypePropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PetTypeProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePetTypeProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deletePetTypePropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getPetTypesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PetTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PetTypes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPetTypes(
      id: number,
      itemID: number,
      embeds?: Array<string | PetTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PetTypeModel> {
      return this.apiService.getSingle(
         this.getPetTypesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPetTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PetTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPetTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PetTypeModel>> {
      return this.apiService.getCollection(
         this.getPetTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getPhoneNumbersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumbersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PhoneNumbers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPhoneNumbers(
      id: number,
      item?: PhoneNumberModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.postSingle(
         this.postPhoneNumbersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPhoneNumbersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PhoneNumbers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPhoneNumbersCollection(
      id: number,
      items: Array<PhoneNumberModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumbersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePhoneNumbersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PhoneNumbers",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumbers(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumbersUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPhoneNumberTypesUrl(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypes(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.getSingle(this.getPhoneNumberTypesUrl(itemID, embeds, additionalParameters));
   }

   getPhoneNumberTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumberTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPhoneNumberTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPhoneNumberTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getAllPhoneNumberTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumberTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypes(
      item?: PhoneNumberTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.postSingle(this.postPhoneNumberTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   postAllPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postAllPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   deletePhoneNumberTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PhoneNumberTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumberTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumberTypesUrl(ids, deleteOptions, additionalParameters));
   }

   getPollsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PollEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Polls",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPolls(
      id: number,
      itemID: number,
      embeds?: Array<string | PollEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PollModel> {
      return this.apiService.getSingle(this.getPollsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getPollsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Polls",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPollsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PollModel>> {
      return this.apiService.getCollection(
         this.getPollsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPollsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Polls",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPolls(
      id: number,
      item?: PollModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PollModel> {
      return this.apiService.postSingle(this.postPollsUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postPollsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Polls",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPollsCollection(
      id: number,
      items: Array<PollModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PollEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PollModel>> {
      return this.apiService.postCollection(
         this.postPollsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePollsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Polls",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePolls(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePollsUrl(id, ids, deleteOptions, additionalParameters));
   }

   postPostManagementFeesUrl(
      saveOptions?: Array<string | PostManagementFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PostManagementFees",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPostManagementFees(
      postManagementFees?: PostManagementFeeModel | null,
      saveOptions?: Array<string | PostManagementFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PostManagementFeeSummaryModel> {
      return this.apiService
         .post(this.postPostManagementFeesUrl(saveOptions, additionalParameters), postManagementFees)
         .pipe(map((response) => response.body));
   }

   postPostManagementFeesAndGetTransactionsUrl(
      saveOptions?: Array<string | PostManagementFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PostManagementFeesAndGetTransactions",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPostManagementFeesAndGetTransactions(
      postManagementFees?: PostManagementFeeModel | null,
      saveOptions?: Array<string | PostManagementFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyManagementFeePostSummaryViewModel> {
      return this.apiService
         .post(this.postPostManagementFeesAndGetTransactionsUrl(saveOptions, additionalParameters), postManagementFees)
         .pipe(map((response) => response.body));
   }

   postPostSecurityDepositInterestUrl(
      saveOptions?: Array<string | PostSecurityDepositInterestSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PostSecurityDepositInterest",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPostSecurityDepositInterest(
      item?: PostSecurityDepositInterestModel | null,
      saveOptions?: Array<string | PostSecurityDepositInterestSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PostingModel> {
      return this.apiService
         .post(this.postPostSecurityDepositInterestUrl(saveOptions, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getPrimaryAddressUrl(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PrimaryAddress",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrimaryAddress(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(this.getPrimaryAddressUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPrimaryOwnerUrl(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PrimaryOwner",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrimaryOwner(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.getSingle(this.getPrimaryOwnerUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPrimaryPhoneNumberUrl(
      id: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PrimaryPhoneNumber",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrimaryPhoneNumber(
      id: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getPrimaryPhoneNumberUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertiesAffectedByDeleteUrl(propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertiesAffectedByDelete",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertiesAffectedByDelete(
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<PropertiesAffectedByPropertyDeleteSummaryModel> {
      return this.apiService.getSingle(this.getPropertiesAffectedByDeleteUrl(propertyID, additionalParameters));
   }

   getPropertiesWithListItemsCollectionUrl(
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertiesWithListItems",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertiesWithListItemsCollection(
      embeds?: Array<string | PropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(this.getPropertiesWithListItemsCollectionUrl(embeds, additionalParameters));
   }

   getPropertyAmenitiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyAmenities",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyAmenities(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyAmenityModel> {
      return this.apiService.getSingle(
         this.getPropertyAmenitiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyAmenitiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyAmenities",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyAmenitiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyAmenityModel>> {
      return this.apiService.getCollection(
         this.getPropertyAmenitiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyAmenitiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyAmenities",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyAmenities(
      id: number,
      item?: PropertyAmenityModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyAmenityModel> {
      return this.apiService.postSingle(
         this.postPropertyAmenitiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyAmenitiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyAmenities",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyAmenitiesCollection(
      id: number,
      items: Array<PropertyAmenityModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyAmenityModel>> {
      return this.apiService.postCollection(
         this.postPropertyAmenitiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyAmenitiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyAmenities",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyAmenities(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deletePropertyAmenitiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getPropertyBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyBanks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyBankModel> {
      return this.apiService.getSingle(
         this.getPropertyBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyBanks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyBankModel>> {
      return this.apiService.getCollection(
         this.getPropertyBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyBanksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyBanks(
      id: number,
      item?: PropertyBankModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyBankModel> {
      return this.apiService.postSingle(
         this.postPropertyBanksUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyBanksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyBanksCollection(
      id: number,
      items: Array<PropertyBankModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyBankModel>> {
      return this.apiService.postCollection(
         this.postPropertyBanksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyBanksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyBanks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyBanks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePropertyBanksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPropertyBanksExtUrl(propertyId: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyId=" + propertyId);
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyBanksExt",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyBanksExt(
      propertyId: number,
      additionalParameters?: Array<string>
   ): Observable<PropertyBanksSummaryModel> {
      return this.apiService.getSingle(this.getPropertyBanksExtUrl(propertyId, additionalParameters));
   }

   getPropertyContractsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyContracts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyContracts(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CrossPropertyContractModel> {
      return this.apiService.getSingle(this.getPropertyContractsUrl(id, itemID, embeds, additionalParameters));
   }

   getPropertyContractsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyContracts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyContractsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CrossPropertyContractModel>> {
      return this.apiService.getCollection(
         this.getPropertyContractsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyContractsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyContracts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPropertyContracts(
      id: number,
      item?: CrossPropertyContractModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CrossPropertyContractModel> {
      return this.apiService.postSingle(
         this.postPropertyContractsUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postPropertyContractsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyContracts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPropertyContractsCollection(
      id: number,
      propertyContracts?: Array<CrossPropertyContractModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CrossPropertyContractModel>> {
      return this.apiService.postCollection(
         this.postPropertyContractsCollectionUrl(id, saveOptions, fields, additionalParameters),
         propertyContracts
      );
   }

   getPropertyFileFoldersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFileFolders",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyFileFolders(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFileFolderModel> {
      return this.apiService.getSingle(
         this.getPropertyFileFoldersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyFileFoldersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFileFolders",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyFileFoldersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFileFolderModel>> {
      return this.apiService.getCollection(
         this.getPropertyFileFoldersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyFileFoldersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFileFolders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFileFolders(
      id: number,
      item?: PropertyFileFolderModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFileFolderModel> {
      return this.apiService.postSingle(
         this.postPropertyFileFoldersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyFileFoldersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFileFolders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFileFoldersCollection(
      id: number,
      items: Array<PropertyFileFolderModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileFolderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFileFolderModel>> {
      return this.apiService.postCollection(
         this.postPropertyFileFoldersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyFileFoldersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyFileFolders",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyFileFolders(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deletePropertyFileFoldersUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getPropertyFilesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFiles",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyFiles(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFileModel> {
      return this.apiService.getSingle(
         this.getPropertyFilesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyFilesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFiles",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyFilesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFileModel>> {
      return this.apiService.getCollection(
         this.getPropertyFilesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyFilesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFiles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFiles(
      id: number,
      item?: PropertyFileModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFileModel> {
      return this.apiService.postSingle(
         this.postPropertyFilesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyFilesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFiles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFilesCollection(
      id: number,
      items: Array<PropertyFileModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFileModel>> {
      return this.apiService.postCollection(
         this.postPropertyFilesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyFilesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyFiles",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyFiles(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePropertyFilesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPropertyFloorsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFloors",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyFloors(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFloorModel> {
      return this.apiService.getSingle(
         this.getPropertyFloorsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyFloorsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyFloors",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyFloorsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFloorModel>> {
      return this.apiService.getCollection(
         this.getPropertyFloorsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyFloorsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFloors",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFloors(
      id: number,
      item?: PropertyFloorModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyFloorModel> {
      return this.apiService.postSingle(
         this.postPropertyFloorsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyFloorsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyFloors",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyFloorsCollection(
      id: number,
      items: Array<PropertyFloorModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyFloorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyFloorModel>> {
      return this.apiService.postCollection(
         this.postPropertyFloorsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyFloorsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyFloors",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyFloors(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePropertyFloorsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPropertyGroupUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroup",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroup(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyGroupModel> {
      return this.apiService.getSingle(this.getPropertyGroupUrl(id, itemID, embeds, additionalParameters));
   }

   getPropertyGroupCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroup",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroupCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyGroupModel>> {
      return this.apiService.getCollection(
         this.getPropertyGroupCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPropertyGroupCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroup",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPropertyGroupCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyGroupModel>> {
      return this.apiService.getCollection(
         this.getAllPropertyGroupCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPropertyGroupDetailsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroupDetails",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroupDetails(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyGroupDetailModel> {
      return this.apiService.getSingle(
         this.getPropertyGroupDetailsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyGroupDetailsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroupDetails",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroupDetailsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyGroupDetailModel>> {
      return this.apiService.getCollection(
         this.getPropertyGroupDetailsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPropertyGroupsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroups",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroups(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyGroupModel> {
      return this.apiService.getSingle(
         this.getPropertyGroupsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyGroupsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroups",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyGroupsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyGroupModel>> {
      return this.apiService.getCollection(
         this.getPropertyGroupsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPropertyGroupsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyGroups",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPropertyGroupsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyGroupModel>> {
      return this.apiService.getCollection(
         this.getAllPropertyGroupsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPropertyManagementFeeSetupsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyManagementFeeSetups",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyManagementFeeSetups(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyManagementFeeSetupModel> {
      return this.apiService.getSingle(
         this.getPropertyManagementFeeSetupsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyManagementFeeSetupsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyManagementFeeSetups",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyManagementFeeSetupsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyManagementFeeSetupModel>> {
      return this.apiService.getCollection(
         this.getPropertyManagementFeeSetupsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyManagementFeeSetupsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyManagementFeeSetups",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyManagementFeeSetups(
      id: number,
      item?: PropertyManagementFeeSetupModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyManagementFeeSetupModel> {
      return this.apiService.postSingle(
         this.postPropertyManagementFeeSetupsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyManagementFeeSetupsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyManagementFeeSetups",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyManagementFeeSetupsCollection(
      id: number,
      items: Array<PropertyManagementFeeSetupModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyManagementFeeSetupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyManagementFeeSetupModel>> {
      return this.apiService.postCollection(
         this.postPropertyManagementFeeSetupsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyManagementFeeSetupsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyManagementFeeSetups",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyManagementFeeSetups(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deletePropertyManagementFeeSetupsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   postPropertyOwnersUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyOwners",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPropertyOwners(
      propertyOwners?: PropertyOwnersModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postPropertyOwnersUrl(additionalParameters), propertyOwners);
   }

   deletePropertyOwnersUrl(ownerContractIDs?: Array<number> | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (ownerContractIDs !== undefined && ownerContractIDs !== null && ownerContractIDs.length > 0) {
         additionalParameters.push("ownerContractIDs=" + ownerContractIDs);
      }
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyOwners",
         null,
         null,
         null,
         additionalParameters
      );
   }

   deletePropertyOwners(
      ownerContractIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePropertyOwnersUrl(ownerContractIDs, additionalParameters));
   }

   getPropertyRentChargeTypesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyRentChargeTypes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertyRentChargeTypes(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyRentChargeTypeModel> {
      return this.apiService.getSingle(
         this.getPropertyRentChargeTypesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertyRentChargeTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/PropertyRentChargeTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertyRentChargeTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyRentChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getPropertyRentChargeTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPropertyRentChargeTypesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyRentChargeTypes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyRentChargeTypes(
      id: number,
      item?: PropertyRentChargeTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyRentChargeTypeModel> {
      return this.apiService.postSingle(
         this.postPropertyRentChargeTypesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPropertyRentChargeTypesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/PropertyRentChargeTypes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPropertyRentChargeTypesCollection(
      id: number,
      items: Array<PropertyRentChargeTypeModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PropertyRentChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyRentChargeTypeModel>> {
      return this.apiService.postCollection(
         this.postPropertyRentChargeTypesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePropertyRentChargeTypesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/PropertyRentChargeTypes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePropertyRentChargeTypes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deletePropertyRentChargeTypesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRateDiscountsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateDiscounts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRateDiscounts(
      id: number,
      itemID: number,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RateDiscountModel> {
      return this.apiService.getSingle(
         this.getRateDiscountsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRateDiscountsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateDiscounts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRateDiscountsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateDiscountModel>> {
      return this.apiService.getCollection(
         this.getRateDiscountsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRateDiscountsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateDiscounts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateDiscounts(
      id: number,
      item?: RateDiscountModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RateDiscountModel> {
      return this.apiService.postSingle(
         this.postRateDiscountsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRateDiscountsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateDiscounts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateDiscountsCollection(
      id: number,
      items: Array<RateDiscountModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateDiscountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateDiscountModel>> {
      return this.apiService.postCollection(
         this.postRateDiscountsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRateDiscountsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RateDiscounts",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRateDiscounts(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRateDiscountsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRateSchedulesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateSchedules",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRateSchedules(
      id: number,
      itemID: number,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RateScheduleModel> {
      return this.apiService.getSingle(
         this.getRateSchedulesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRateSchedulesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateSchedules",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRateSchedulesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateScheduleModel>> {
      return this.apiService.getCollection(
         this.getRateSchedulesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRateSchedulesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateSchedules",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateSchedules(
      id: number,
      item?: RateScheduleModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RateScheduleModel> {
      return this.apiService.postSingle(
         this.postRateSchedulesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRateSchedulesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateSchedules",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateSchedulesCollection(
      id: number,
      items: Array<RateScheduleModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateScheduleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateScheduleModel>> {
      return this.apiService.postCollection(
         this.postRateSchedulesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRateSchedulesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RateSchedules",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRateSchedules(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRateSchedulesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRateSettingsUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateSettings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRateSettings(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<RateSettingModel> {
      return this.apiService.getSingle(
         this.getRateSettingsUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRateSettingsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateSettings",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateSettings(
      id: number,
      item?: RateSettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateSettingModel>> {
      return this.apiService
         .post(this.postRateSettingsUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteRateSettingsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RateSettings",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRateSettings(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRateSettingsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRateTaxesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateTaxes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRateTaxes(
      id: number,
      itemID: number,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RateTaxModel> {
      return this.apiService.getSingle(
         this.getRateTaxesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRateTaxesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RateTaxes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRateTaxesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateTaxModel>> {
      return this.apiService.getCollection(
         this.getRateTaxesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRateTaxesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateTaxes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateTaxes(
      id: number,
      item?: RateTaxModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RateTaxModel> {
      return this.apiService.postSingle(
         this.postRateTaxesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRateTaxesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RateTaxes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRateTaxesCollection(
      id: number,
      items: Array<RateTaxModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RateTaxEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RateTaxModel>> {
      return this.apiService.postCollection(
         this.postRateTaxesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRateTaxesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RateTaxes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRateTaxes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRateTaxesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRecurringChargesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RecurringCharges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRecurringCharges(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeModel> {
      return this.apiService.getSingle(
         this.getRecurringChargesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRecurringChargesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RecurringCharges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeModel>> {
      return this.apiService.getCollection(
         this.getRecurringChargesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRecurringChargesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RecurringCharges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRecurringCharges(
      id: number,
      item?: RecurringChargeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeModel> {
      return this.apiService.postSingle(
         this.postRecurringChargesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRecurringChargesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RecurringCharges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRecurringChargesCollection(
      id: number,
      items: Array<RecurringChargeModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeModel>> {
      return this.apiService.postCollection(
         this.postRecurringChargesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRecurringChargesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RecurringCharges",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRecurringCharges(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRecurringChargesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRecurringChargeSummariesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RecurringChargeSummaries",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargeSummaries(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeSummaryModel> {
      return this.apiService.getSingle(
         this.getRecurringChargeSummariesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRecurringChargeSummariesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RecurringChargeSummaries",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargeSummariesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeSummaryModel>> {
      return this.apiService.getCollection(
         this.getRecurringChargeSummariesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRentChargeTypeItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RentChargeTypeItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRentChargeTypeItems(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(
         this.getRentChargeTypeItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRentChargeTypeItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RentChargeTypeItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRentChargeTypeItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getRentChargeTypeItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRentChargeTypesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RentChargeTypes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRentChargeTypes(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SelectListItemModel> {
      return this.apiService.getSingle(this.getRentChargeTypesUrl(id, itemID, embeds, additionalParameters));
   }

   getRentChargeTypesCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RentChargeTypes",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRentChargeTypesCollection(
      id: number,
      additionalParameters?: Array<string>
   ): Observable<Array<SelectListItemModel>> {
      return this.apiService.getCollection(this.getRentChargeTypesCollectionUrl(id, additionalParameters));
   }

   postRentChargeTypesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RentChargeTypes",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRentChargeTypes(
      id: number,
      item?: SelectListItemModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<SelectListItemModel> {
      return this.apiService.postSingle(
         this.postRentChargeTypesUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postRentChargeTypesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RentChargeTypes",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRentChargeTypesCollection(
      id: number,
      chargeTypes?: Array<SelectListItemModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SelectListItemModel>> {
      return this.apiService.postCollection(
         this.postRentChargeTypesCollectionUrl(id, saveOptions, fields, additionalParameters),
         chargeTypes
      );
   }

   getReservableAmenitiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ReservableAmenities",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReservableAmenities(
      id: number,
      itemID: number,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ReservableAmenityModel> {
      return this.apiService.getSingle(
         this.getReservableAmenitiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getReservableAmenitiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ReservableAmenities",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getReservableAmenitiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReservableAmenityModel>> {
      return this.apiService.getCollection(
         this.getReservableAmenitiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postReservableAmenitiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ReservableAmenities",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postReservableAmenities(
      id: number,
      item?: ReservableAmenityModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ReservableAmenityModel> {
      return this.apiService.postSingle(
         this.postReservableAmenitiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postReservableAmenitiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ReservableAmenities",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postReservableAmenitiesCollection(
      id: number,
      items: Array<ReservableAmenityModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReservableAmenityEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReservableAmenityModel>> {
      return this.apiService.postCollection(
         this.postReservableAmenitiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteReservableAmenitiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ReservableAmenities",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteReservableAmenities(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteReservableAmenitiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getRMVoIPCallHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RMVoIPCallHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.getSingle(
         this.getRMVoIPCallHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRMVoIPCallHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RMVoIPCallHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getRMVoIPCallHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllRMVoIPCallHistoryCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/RMVoIPCallHistory",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllRMVoIPCallHistoryCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getAllRMVoIPCallHistoryCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRMVoIPCallHistoryUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistory(
      id: number,
      item?: RMVoIPCallHistoryModel | null,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.postSingle(
         this.postRMVoIPCallHistoryUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRMVoIPCallHistoryCollectionUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistoryCollection(
      id: number,
      items: Array<RMVoIPCallHistoryModel>,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.postCollection(
         this.postRMVoIPCallHistoryCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRollbackLastManagementFeePostingUrl(
      id: number,
      saveOptions?: Array<string | RollbackManagementFeePostingSaveOptions> | null,
      deleteOptions?: Array<string | RollbackManagementFeePostingDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (saveOptions !== undefined && saveOptions !== null && saveOptions.length > 0) {
         additionalParameters.push("saveOptions=" + saveOptions);
      }
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/RollbackLastManagementFeePosting",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRollbackLastManagementFeePosting(
      id: number,
      saveOptions?: Array<string | RollbackManagementFeePostingSaveOptions> | null,
      deleteOptions?: Array<string | RollbackManagementFeePostingDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteRollbackLastManagementFeePostingUrl(id, saveOptions, deleteOptions, additionalParameters)
      );
   }

   postSavePropertyFileFoldersSortOrderUrl(
      isFileDragged?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (isFileDragged !== undefined && isFileDragged !== null) {
         additionalParameters.push("isFileDragged=" + isFileDragged);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/SavePropertyFileFoldersSortOrder",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSavePropertyFileFoldersSortOrder(
      isFileDragged: boolean,
      propertyfolders?: Array<PropertyFileFolderModel> | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.postCollection(
         this.postSavePropertyFileFoldersSortOrderUrl(isFileDragged, additionalParameters),
         propertyfolders
      );
   }

   postSaveRecurringChargeSortOrdersUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/SaveRecurringChargeSortOrders",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSaveRecurringChargeSortOrders(
      id: number,
      recurringChargeSummaries?: Array<RecurringChargeSummaryModel> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(
         this.postSaveRecurringChargeSortOrdersUrl(id, additionalParameters),
         recurringChargeSummaries
      );
   }

   getScreeningCredentialsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ScreeningCredentialsEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ScreeningCredentials",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreeningCredentials(
      id: number,
      itemID: number,
      embeds?: Array<string | ScreeningCredentialsEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningCredentialsModel> {
      return this.apiService.getSingle(this.getScreeningCredentialsUrl(id, itemID, embeds, additionalParameters));
   }

   getScreeningCredentialsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningCredentialsEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ScreeningCredentials",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getScreeningCredentialsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningCredentialsEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ScreeningCredentialsModel>> {
      return this.apiService.getCollection(
         this.getScreeningCredentialsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postScreeningCredentialsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ScreeningCredentials",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postScreeningCredentials(
      id: number,
      screeningCredential?: ScreeningCredentialsModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningCredentialsModel> {
      return this.apiService
         .post(this.postScreeningCredentialsUrl(id, saveOptions, additionalParameters), screeningCredential)
         .pipe(map((response) => response.body));
   }

   deleteScreeningCredentialsUrl(id: number, itemID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ScreeningCredentials",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteScreeningCredentials(
      id: number,
      itemID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteScreeningCredentialsUrl(id, itemID, additionalParameters));
   }

   getScreeningReportTypesForUserCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ScreeningReportTypesForUser",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreeningReportTypesForUserCollection(
      id: number,
      additionalParameters?: Array<string>
   ): Observable<Array<ScreeningReport>> {
      return this.apiService.getCollection(this.getScreeningReportTypesForUserCollectionUrl(id, additionalParameters));
   }

   getScreeningSettingUrl(
      id: number,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ScreeningSetting",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreeningSetting(
      id: number,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningSettingModel> {
      return this.apiService.getSingle(
         this.getScreeningSettingUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postScreeningSettingUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ScreeningSetting",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postScreeningSetting(
      id: number,
      item?: ScreeningSettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningSettingModel> {
      return this.apiService
         .post(this.postScreeningSettingUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteScreeningSettingUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ScreeningSetting",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteScreeningSetting(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteScreeningSettingUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getScreeningSettingsUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ScreeningSettings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getScreeningSettings(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningSettingModel> {
      return this.apiService.getSingle(
         this.getScreeningSettingsUrl(id, filters, embeds, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   postScreeningSettingsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ScreeningSettings",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postScreeningSettings(
      id: number,
      screeningSetting?: ScreeningSettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningSettingModel> {
      return this.apiService
         .post(this.postScreeningSettingsUrl(id, saveOptions, additionalParameters), screeningSetting)
         .pipe(map((response) => response.body));
   }

   deleteScreeningSettingsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/ScreeningSettings",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteScreeningSettings(id: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteScreeningSettingsUrl(id, additionalParameters));
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getSecurityDepositInterestUrl(
      id: number,
      embeds?: Array<string | SecurityDepositInterestEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/SecurityDepositInterest",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSecurityDepositInterest(
      id: number,
      embeds?: Array<string | SecurityDepositInterestEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SecurityDepositInterestModel> {
      return this.apiService.getSingle(
         this.getSecurityDepositInterestUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postSecurityDepositInterestUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SecurityDepositInterestEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/SecurityDepositInterest",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSecurityDepositInterest(
      id: number,
      item?: SecurityDepositInterestModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SecurityDepositInterestEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SecurityDepositInterestModel> {
      return this.apiService
         .post(this.postSecurityDepositInterestUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteSecurityDepositInterestUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/SecurityDepositInterest",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteSecurityDepositInterest(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteSecurityDepositInterestUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerAssignedUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/ServiceManagerAssignedUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerAssignedUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(
         this.getServiceManagerAssignedUserUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postsortPropertyFilesAlphabeticallyUrl(propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/sortPropertyFilesAlphabetically",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postsortPropertyFilesAlphabetically(
      propertyID: number,
      propertyFileIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.postCollection(
         this.postsortPropertyFilesAlphabeticallyUrl(propertyID, additionalParameters),
         propertyFileIDs
      );
   }

   getTenantsForBoardMembersCollectionUrl(propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/TenantsForBoardMembers",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantsForBoardMembersCollection(
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<number>> {
      return this.apiService.getCollection(
         this.getTenantsForBoardMembersCollectionUrl(propertyID, additionalParameters)
      );
   }

   postTestConnectionForScreeningsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/TestConnectionForScreenings",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestConnectionForScreenings(
      id: number,
      screeningCredentials?: ScreeningCredentialsAuthenticationModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postTestConnectionForScreeningsUrl(id, additionalParameters), screeningCredentials)
         .pipe(map((response) => response.body));
   }

   postTestScriptUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/TestScript",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestScript(
      id: number,
      model?: ScriptBuilderModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postTestScriptUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getTextNumberPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/TextNumberProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTextNumberProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TextNumberPropertyModel> {
      return this.apiService.getSingle(
         this.getTextNumberPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTextNumberPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/TextNumberProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTextNumberPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TextNumberPropertyModel>> {
      return this.apiService.getCollection(
         this.getTextNumberPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTextNumberPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/TextNumberProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTextNumberProperties(
      id: number,
      item?: TextNumberPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TextNumberPropertyModel> {
      return this.apiService.postSingle(
         this.postTextNumberPropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postTextNumberPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/TextNumberProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTextNumberPropertiesCollection(
      id: number,
      items: Array<TextNumberPropertyModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TextNumberPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TextNumberPropertyModel>> {
      return this.apiService.postCollection(
         this.postTextNumberPropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteTextNumberPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/TextNumberProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTextNumberProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteTextNumberPropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getTextNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TextNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/TextNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTextNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | TextNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TextNumberModel> {
      return this.apiService.getSingle(
         this.getTextNumbersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTextNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TextNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/TextNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTextNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TextNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TextNumberModel>> {
      return this.apiService.getCollection(
         this.getTextNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Units",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(this.getUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Units",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUnitsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Units",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUnits(
      id: number,
      item?: UnitModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.postSingle(this.postUnitsUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postUnitsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/Units",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUnitsCollection(
      id: number,
      items: Array<UnitModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.postCollection(
         this.postUnitsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUnitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/Units",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUnits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnitsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUnitSquareFootageUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UnitSquareFootage",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnitSquareFootage(id: number, additionalParameters?: Array<string>): Observable<number> {
      return this.apiService.getSingle(this.getUnitSquareFootageUrl(id, additionalParameters));
   }

   getUnitTypePropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UnitTypeProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnitTypeProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitTypePropertyModel> {
      return this.apiService.getSingle(
         this.getUnitTypePropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUnitTypePropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UnitTypeProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitTypePropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitTypePropertyModel>> {
      return this.apiService.getCollection(
         this.getUnitTypePropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUnitTypePropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UnitTypeProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUnitTypeProperties(
      id: number,
      item?: UnitTypePropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitTypePropertyModel> {
      return this.apiService.postSingle(
         this.postUnitTypePropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUnitTypePropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UnitTypeProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUnitTypePropertiesCollection(
      id: number,
      items: Array<UnitTypePropertyModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UnitTypePropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitTypePropertyModel>> {
      return this.apiService.postCollection(
         this.postUnitTypePropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUnitTypePropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/UnitTypeProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUnitTypeProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUnitTypePropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUnitTypesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UnitTypes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnitTypes(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitTypeModel> {
      return this.apiService.getSingle(this.getUnitTypesUrl(id, itemID, embeds, additionalParameters));
   }

   getUnitTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UnitTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitTypeModel>> {
      return this.apiService.getCollection(
         this.getUnitTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUnLinkUnitTypesUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UnLinkUnitTypes",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postUnLinkUnitTypes(
      id: number,
      unitTypeIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postUnLinkUnitTypesUrl(id, additionalParameters), unitTypeIDs);
   }

   postUpdateContactAndCreateTWAUrl(
      contactID: number,
      contactEmail?: string | null,
      accountID?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("contactID=" + contactID);
      if (contactEmail !== undefined && contactEmail !== null) {
         additionalParameters.push("contactEmail=" + contactEmail);
      }
      if (accountID !== undefined && accountID !== null) {
         additionalParameters.push("accountID=" + accountID);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UpdateContactAndCreateTWA",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postUpdateContactAndCreateTWA(
      contactID: number,
      contactEmail?: string | null,
      accountID?: number | null,
      prompts?: BoardMemberEmailModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postUpdateContactAndCreateTWAUrl(contactID, contactEmail, accountID, additionalParameters), prompts)
         .pipe(map((response) => response.body));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUserDefinedFieldsUrl(saveOptions, fields, additionalParameters), item);
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUserPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyModel> {
      return this.apiService.getSingle(
         this.getUserPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UserProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyModel>> {
      return this.apiService.getCollection(
         this.getUserPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserProperties(
      id: number,
      item?: UserPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyModel> {
      return this.apiService.postSingle(
         this.postUserPropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/UserProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPropertiesCollection(
      id: number,
      items: Array<UserPropertyModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyModel>> {
      return this.apiService.postCollection(
         this.postUserPropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         PropertiesService.endpoint + "/UserProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserPropertiesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUsersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Users",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsers(
      id: number,
      itemID: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUsersUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getUsersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Users",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUsersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUtilityPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UtilityPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/UtilityProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUtilityPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UtilityPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UtilityPropertyModel>> {
      return this.apiService.getCollection(
         this.getUtilityPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postValidateBoardMemberUrl(
      IsNew?: boolean | null,
      isNewEmail?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (IsNew !== undefined && IsNew !== null) {
         additionalParameters.push("IsNew=" + IsNew);
      }
      if (isNewEmail !== undefined && isNewEmail !== null) {
         additionalParameters.push("isNewEmail=" + isNewEmail);
      }
      return this.apiService.postUrl(
         PropertiesService.endpoint + "/ValidateBoardMember",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postValidateBoardMember(
      IsNew: boolean,
      isNewEmail?: boolean | null,
      selectedboardMemberEmailModel?: BoardMemberEmailModel | null,
      additionalParameters?: Array<string>
   ): Observable<BoardMemberEmailModel> {
      return this.apiService
         .post(this.postValidateBoardMemberUrl(IsNew, isNewEmail, additionalParameters), selectedboardMemberEmailModel)
         .pipe(map((response) => response.body));
   }

   getViolationsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Violations",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getViolations(
      id: number,
      itemID: number,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ViolationModel> {
      return this.apiService.getSingle(
         this.getViolationsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getViolationsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         PropertiesService.endpoint + "/Violations",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getViolationsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ViolationModel>> {
      return this.apiService.getCollection(
         this.getViolationsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
}
