import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OwnerCoverPageModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-cover-page.model";

@Component({
   selector: "lcs-owner-cover-page-information",
   templateUrl: "owner-cover-page-information.component.html",
   styleUrls: ["owner-cover-page-information.component.scss"],
})
export class OwnerCoverPageInformationComponent {
   @Input() ownerCoverPageInformation: OwnerCoverPageModel;

   @Output() ownerCoverPageInformationChanged = new EventEmitter<OwnerCoverPageModel>();

   onSubjectChanged(subject: string) {
      this.ownerCoverPageInformation.CoverPageSubject = subject;
      this.ownerCoverPageInformationChanged.emit(this.ownerCoverPageInformation);
   }

   onMessageChanged(message: string) {
      this.ownerCoverPageInformation.CoverPageMessage = message;
      this.ownerCoverPageInformationChanged.emit(this.ownerCoverPageInformation);
   }
}
