import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InformationSplashScreenModule } from "@lcs/information-splash-screen/information-splash-screen.module";
import { SidebarLayoutModule } from "@lcs/sidebar-layout/sidebar-layout.module";

import { MultiPdfViewerDisplayComponent } from "./multi-pdf-viewer-display.component";
import { MultiPdfViewerComponent } from "./multi-pdf-viewer.component";
import { PdfViewerComponent } from "./pdf-viewer.component";

@NgModule({
   imports: [CommonModule, SidebarLayoutModule, InformationSplashScreenModule, FormsModule],
   declarations: [PdfViewerComponent, MultiPdfViewerComponent, MultiPdfViewerDisplayComponent],
   exports: [PdfViewerComponent, MultiPdfViewerComponent, MultiPdfViewerDisplayComponent],
})
export class PdfViewerModule {}
