import { ElementRef } from "@angular/core";

export class OverlayPanelRegistrationModel {
   closeWhenNonOverlayClicked: boolean;
   contentElement: ElementRef;
   closeCallback: () => void;
   whiteList: Array<number>;
   showIndependent: boolean;
   isGloballyWhitelist: boolean;
}
