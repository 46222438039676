import { NgModule } from "@angular/core";

import { InputsModule } from "../../inputs/inputs.module";
import { PropertyGroupSelectorComponent } from "./property-group-selector.component";

@NgModule({
   imports: [InputsModule],
   declarations: [PropertyGroupSelectorComponent],
   exports: [PropertyGroupSelectorComponent],
})
export class PropertyGroupSelectorModule {}
