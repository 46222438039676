export class SignableDocumentsSettings {
   PropertyName: string;
   SignatureFont: string;
   GetSignableDocumentURL: string;
   GetPageImageURL: string;
   PostSignatureURL: string;
   PostFieldURL: string;
   PostCompleteDocumentURL: string;
   PostDisplayTextURL: string;
   MergeDocumentPacketsURL: string;
   DocumentWidthInPixels: number;
   PointToPixelFactor: number;
   SignatureBlockTypeID: number;
   InitialBlockTypeID: number;
}
