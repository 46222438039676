import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
   selector: "owa-switch-account-loading",
   templateUrl: "./switch-account-loading.component.html",
   styleUrls: ["./switch-account-loading.component.css"],
})
export class SwitchAccountLoadingComponent implements OnInit, OnDestroy {
   private unsubscribe = new Subject<void>();

   constructor() {}

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
