import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-states-report-parameter",
   templateUrl: "states-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class StatesReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   statesParameter: ReportReportParameterViewModel;

   cityParameter: ReportReportParameterViewModel;

   stateValueModel: ReportParameterValueModel;

   cityValueModel: ReportParameterValueModel;

   valueSource: ValueSourceModel;

   selectedValues: Array<number>;

   selectionChange: SelectionChangeModel;

   isCityDisabled: boolean;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeValidation();
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.statesParameter.IsAsk = isAsk;
      if (this.cityParameter) {
         this.cityParameter.IsAsk = isAsk;
      }

      this.reportParametersService.updateReportParameterAsk(ReportParameter.StateIDs, isAsk);
      if (this.cityParameter) {
         this.reportParametersService.updateReportParameterAsk(ReportParameter.CityWithStateIDs, isAsk);
      }
   }

   selectionChanged() {
      const checkedItems = this.selectionChange.checkedItems.filter((c) => c.value != null && c.value !== "");
      let values = new Array<any>();
      const selectAll = this.selectionChange.selectAll;
      this.stateValueModel.value = "";
      if (!this.statesParameter.ReportParameterValueSource.AllowsMultipleValues) {
         if (checkedItems.length === 1 && checkedItems[0].value != null) {
            this.stateValueModel.value = checkedItems[0].value;
            values = [this.stateValueModel.value];
         }
      } else {
         const allValue = this.statesParameter.ReportParameterValueSource.AllValue;
         if (selectAll && allValue) {
            this.stateValueModel.value = allValue;
            values = [allValue];
         } else if (checkedItems.length > 0) {
            values = checkedItems.map((f) => f.value);
            this.stateValueModel.value = "(" + values.join(",") + ")";
         } else {
            this.stateValueModel.value = "";
         }
      }
      this.stateValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.stateValueModel);

      this.updateCityValue();
   }

   private initializeValidation() {
      this.validation = new ValidationModel();
      this.validation.required = true;
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.statesParameter = this.reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.StateIDs);
      this.statesParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.cityParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.CityWithStateIDs
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.stateValueModel = this.reportParametersService.reportParameterValues.get(
         this.statesParameter.ReportParameterID
      );

      if (this.cityParameter) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
         this.cityValueModel = this.reportParametersService.reportParameterValues.get(
            this.cityParameter.ReportParameterID
         );
      }

      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.AllowsMultipleValues = true;
      valueSource.AllowsSelectAll = true;
      if (this.statesParameter.ReportParameterValueSource) {
         for (const state of this.statesParameter.ReportParameterValueSource.StaticValues) {
            const item = new SelectorItemModel();
            item.displayValue = state.DisplayValue;
            item.value = state.Value;
            valueSource.StaticValues.push(item);
         }
      }
      this.valueSource = valueSource;

      if (this.stateValueModel) {
         let states = [];
         if (this.stateValueModel.value.toLowerCase() === "all") {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
            states = this.valueSource.StaticValues.map((v) => v.value);
         } else {
            states = this.stateValueModel.value
               .split(",")
               .map((v) => v.replace(" ", ""))
               .map((v) => +v);
         }
         this.selectedValues = states;
         this.stateValueModel.rawValues = this.selectedValues;
         this.reportParametersService.updateParameterValue.next(this.stateValueModel);

         this.updateCityValue();
      }
   }

   private updateCityValue() {
      if (this.cityParameter) {
         this.isCityDisabled = this.stateValueModel.rawValues.length !== 1;
         if (this.isCityDisabled) {
            this.cityValueModel.value = "";
            this.reportParametersService.updateParameterValue.next(this.cityValueModel);
         }
      }
   }
}
