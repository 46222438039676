import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { mapToJson } from "@lcs/utils/map-utils";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { UserPreferences } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-preferences.enum";
import { UserPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-preference.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { CurrentService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, filter, map, Observable, of, Subject, switchMap, take, takeUntil } from "rxjs";

export type OpenListDetailsInNewTabAreaKey =
   | "properties"
   | "units"
   | "prospects"
   | "tenants"
   | "vendors"
   | "ownerprospects"
   | "owners";

export type UserPreferenceOpenListDetailsInNewTabMap = Map<OpenListDetailsInNewTabAreaKey, boolean>;

@Injectable({
   providedIn: "root",
})
export class CurrentUserPreferencesService implements SessionCacheProvider, OnDestroy {
   /**
    * Defines the defaults for which lists can be specified by the user via RMX user preferences to open in a new Window (e.g. Tab)
    * The order of these items is fixed and is defined in RMX-6568.
    * NOTE: keys map to areas in URL strings
    */
   static readonly defaultUserPreferenceOpenListDetailsInNewTabMap: UserPreferenceOpenListDetailsInNewTabMap = new Map<
      OpenListDetailsInNewTabAreaKey,
      boolean
   >([
      ["properties", false],
      ["units", false],
      ["prospects", false],
      ["tenants", false],
      ["vendors", false],
      ["ownerprospects", false],
      ["owners", false],
   ]);

   cacheKey = "CurrentUserPreferencesService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   private currentUserPreferencesCache: Map<number, UserPreferenceModel> = new Map<number, UserPreferenceModel>();

   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private currentService: CurrentService,
      private currentUserService: CurrentUserService,
      private sessionStatusService: SessionStatusService,
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   clearCache(sessionExpired: boolean) {
      this.currentUserPreferencesCache.clear();
      this.cachePopulated.next(false);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
      }
   }

   populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      const storedValue = this.localStorageService.getItem(this.cacheKey);
      if (storedValue) {
         const cachedPrefs = JSON.parse(storedValue);
         if (cachedPrefs) {
            cachedPrefs.forEach((pref) => {
               this.currentUserPreferencesCache.set(pref.PreferenceID, pref);
            });
            this.cachePopulated.next(true);
         }
      } else {
         const currentServicePreferencesCollectionUrl = this.currentService.getPreferencesCollectionUrl();
         this.apiService
            .directGet(currentServicePreferencesCollectionUrl)
            .pipe(
               switchMap((response) => {
                  const prefs = response.body;
                  return this.fillCacheWithUserPreferences(prefs);
               }),
               takeUntil(this.unsubscribe)
            )
            .subscribe(
               () => {
                  this.cachePopulated.next(true);
               },
               (_) => {
                  this.cacheMonitorService.reportError(this.cacheKey);
               }
            );
      }
   }

   getUserPreference(userPreference: UserPreferences, forceRefresh: boolean = false): Observable<UserPreferenceModel> {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<UserPreferenceModel | null | unde... Remove this comment to see the full error message
      return this.cachePopulated.pipe(
         filter((populated) => populated),
         switchMap(() => {
            if (this.currentUserPreferencesCache.has(userPreference) && !forceRefresh) {
               return of(this.currentUserPreferencesCache.get(userPreference));
            } else {
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
               this.currentUserPreferencesCache.set(userPreference, null);
               return of(null);
            }
         })
      );
   }

   getUserPreferences(
      userPreferences: UserPreferences[],
      forceRefresh: boolean = false
   ): Observable<Map<number, UserPreferenceModel>> {
      return this.cachePopulated.pipe(
         filter((populated) => populated),
         switchMap(() => {
            const results = new Map<UserPreferences, UserPreferenceModel>();

            for (const userPreference of userPreferences) {
               if (this.currentUserPreferencesCache.has(userPreference) && !forceRefresh) {
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'UserPreferenceModel | undefined'... Remove this comment to see the full error message
                  results.set(userPreference, this.currentUserPreferencesCache.get(userPreference));
               } else {
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                  this.currentUserPreferencesCache.set(userPreference, null);
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'UserPreferenceModel | undefined'... Remove this comment to see the full error message
                  results.set(userPreference, this.currentUserPreferencesCache.get(userPreference));
               }
            }

            return of(results);
         })
      );
   }

   private fillCacheWithMissingDefaults(currentUserID: number) {
      const userPreferenceDefaultValues = new Map<UserPreferences, string>();
      userPreferenceDefaultValues.set(UserPreferences.ExpressHomePage, (+ExpressActions.GettingStarted).toString());
      userPreferenceDefaultValues.set(UserPreferences.ExpressShowRMUCBanner, "show");
      userPreferenceDefaultValues.set(
         UserPreferences.ExpressOpenListDetailsInNewTab,
         mapToJson<OpenListDetailsInNewTabAreaKey, boolean>(
            CurrentUserPreferencesService.defaultUserPreferenceOpenListDetailsInNewTabMap
         )
      );

      userPreferenceDefaultValues.forEach((defaultValue: string, key: UserPreferences) => {
         const cacheValue = this.currentUserPreferencesCache.get(key);
         if (!cacheValue) {
            const userPref = new UserPreferenceModel();
            userPref.Name = UserPreferences[key];
            userPref.PreferenceID = key;
            userPref.UserID = currentUserID;
            userPref.Value = defaultValue;
            this.currentUserPreferencesCache.set(key, userPref);
         }
      });
   }

   private fillCacheWithUserPreferences(prefs: Array<UserPreferenceModel>): Observable<Array<UserPreferenceModel>> {
      return this.currentUserService.currentUser.pipe(
         filter((user) => user !== null),
         take(1),
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'OperatorFunction<UserModel, User... Remove this comment to see the full error message
         map((currentUser: UserModel) => {
            if (!prefs) {
               prefs = new Array<UserPreferenceModel>();
            }
            if (prefs && prefs.length > 0) {
               prefs.forEach((pref) => {
                  this.currentUserPreferencesCache.set(pref.PreferenceID, pref);
               });
            }
            const homePagePref = this.currentUserPreferencesCache.get(UserPreferences.ExpressHomePage);
            if (homePagePref && +homePagePref.Value === ExpressActions.Blank) {
               homePagePref.Value = ExpressActions.GettingStarted.toString();
               this.currentUserPreferencesCache.set(UserPreferences.ExpressHomePage, homePagePref);
            }
            this.fillCacheWithMissingDefaults(currentUser.UserID);
            this.localStorageService.setItem(
               this.cacheKey,
               JSON.stringify(Array.from(this.currentUserPreferencesCache.values()))
            );

            return prefs;
         })
      );
   }

   setCacheWithCustomUserPreference(prefID: number) {
      const bannerPref = this.currentUserPreferencesCache.get(prefID);
      if (bannerPref) {
         bannerPref.Value = "hide";
         this.currentUserPreferencesCache.set(prefID, bannerPref);
      }

      this.localStorageService.setItem(
         this.cacheKey,
         JSON.stringify(Array.from(this.currentUserPreferencesCache.values()))
      );
   }
}
