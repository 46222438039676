<div class="modal-confirmation-content">
   <mat-spinner
      *ngIf="!configuration.icon && configuration.showSpinner"
      [strokeWidth]="5"
      [diameter]="50"
   ></mat-spinner>
   <i
      *ngIf="configuration.icon"
      class="material-icons modal-confirmation-icon"
   >{{ configuration.icon }}</i>
   <ng-container *ngIf="configuration.messages.length === 0">
      <div
         *ngIf="!configuration.isMessageFormattedToNewLine"
         class="modal-confirmation-message"
         [innerHTML]="configuration.message"
      ></div>
      <pre
         *ngIf="configuration.isMessageFormattedToNewLine"
         class="modal-confirmation-message"
         [innerHTML]="configuration.message"
      ></pre>
   </ng-container>
   <ng-container *ngIf="configuration.messages.length > 0">
      <div class="modal-confirmation-message">
         <p
            *ngFor="let message of configuration.messages"
            [innerHTML]="message"
         ></p>
      </div>
   </ng-container>
</div>
