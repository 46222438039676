<lcs-loading-overlay [show]="$any(loading | async)">
   <div class="tab-wrapper">
      <mat-tab-group
         #matTabGroup
         [selectedIndex]="tabIndex"
         (selectedIndexChange)="tabChanged($event)"
      >
         <mat-tab label="{{ propertyReplacementName }}">
            <lcs-single-line-multi-select
               #propertySelector
               [name]="name + '-property'"
               [disabled]="propertyGroupID > 0"
               [displayName]="'Property Selector'"
               [disabledItems]="disabledItems"
               [disableAllItems]="disableAllItems"
               [ngModel]="selectedPropertyIDs"
               [entityValueSourceFilters]="propertyFilters"
               (ngModelChange)="selectedPropertyIDsChange.next($event)"
               (selectionChange)="onPropertySelectionChange($event)"
               [valueSource]="propertyValueSource"
               (allSelected)="onAllSelected($event)"
            ></lcs-single-line-multi-select>
            <div class="additional-controls-wrapper">
               <label
                  *ngIf="propertyGroupValueSource"
                  class="glLedger-report"
               >Group</label>
               <lcs-select
                  lcsLegacySingleSelector
                  *ngIf="propertyGroupValueSource"
                  [name]="name + '-group'"
                  [disabled]="disableAllItems"
                  [displayName]="'Group'"
                  [ngModel]="propertyGroupID"
                  [valueSource]="propertyGroupValueSource"
                  (ngModelChange)="groupChanged($event)"
               >
               </lcs-select>
               <lcs-checkbox
                  *ngIf="!hideInactiveCheckbox"
                  [name]="name + '-inactive-properties-checkbox'"
                  [displayName]="'Show Invactive'"
                  [ngModel]="showInactiveProperties"
                  (ngModelChange)="onShowInactiveProperties($event)"
               >
                  Show Inactive {{ propertyPluralReplacementName }}
               </lcs-checkbox>
            </div>
         </mat-tab>
         <mat-tab
            [disabled]="disableAllItems"
            label="{{ ownerReplacementName }}"
         >
            <lcs-single-line-multi-select
               #ownerSelector
               [name]="name + '-owner'"
               [disabled]="ownerGroupID > 0"
               [displayName]="'Owner Selector'"
               [ngModel]="selectedOwnerIDs"
               (ngModelChange)="selectedOwnerIDsChange.next($event)"
               (selectionChange)="onOwnerSelectionChange($event)"
               [valueSource]="ownerValueSource"
               [ngClass]="{'owner-selector': hideOwnerGroupSelector}"
            ></lcs-single-line-multi-select>
            <div
               *ngIf="!hideOwnerGroupSelector"
               class="additional-controls-wrapper"
            >
               <label
                  *ngIf="ownerGroupValueSource"
                  class="glLedger-report"
               >Group</label>
               <lcs-select
                  lcsLegacySingleSelector
                  *ngIf="ownerGroupValueSource"
                  [name]="name + '-group'"
                  [displayName]="'Group'"
                  [ngModel]="ownerGroupID"
                  [valueSource]="ownerGroupValueSource"
                  (ngModelChange)="groupChanged($event)"
               >
               </lcs-select>
               <lcs-checkbox
                  *ngIf="!hideInactiveCheckbox"
                  [name]="name + '-inactive-ownership-properties-checkbox'"
                  [displayName]="'Show Inactive'"
                  [ngModel]="showInactiveProperties"
                  (ngModelChange)="onShowInactiveProperties($event)"
               >
                  Show Inactive {{ propertyPluralReplacementName }}
               </lcs-checkbox>
            </div>
         </mat-tab>
      </mat-tab-group>
   </div>
</lcs-loading-overlay>