import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { AccountMultiSelectorModule } from "@lcs/account-multi-selector/account-multi-selector.module";
import { AccountSelectorModule } from "@lcs/account-selector/account-selector.module";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { EntityMultiSelectorModule } from "@lcs/entity-multi-selector/entity-multi-selector.module";
import { EntitySelectorModule } from "@lcs/entity-selector/entity-selector.module";
import { EnumMultiSelectorModule } from "@lcs/enum-multi-selector/enum-multi-selector.module";
import { FooterModule } from "@lcs/footer/footer.module";
import { IconModule } from "@lcs/icon/icon.module";
import { LinkModule } from "@lcs/link/link.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";
import { SelectorOverlayPanelModule } from "@lcs/selector-overlay-panel/selector-overlay-panel.module";
import { DragulaService } from "ng2-dragula";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";

import { ClickToCallModule } from "../inputs/click-to-call/click-to-call.module";
import { DatePickerModule } from "../inputs/date-picker/date-picker.module";
import { DateRangePickerModule } from "../inputs/date-range-picker/date-range-picker.module";
import { InputsModule } from "../inputs/inputs.module";
import { SecureSsnInputModule } from "../inputs/secure-input/secure-ssn-input.module";
import { UDFEncryptedTextModule } from "../inputs/udf-encrypted-text/udf-encrypted-text.module";
import { ValidationModule } from "../inputs/validation/validation.module";
import { OverlayPanelsModule } from "../overlay-panel/overlay-panels.module";
import { PipesModule } from "../pipes/pipes.module";
import { ProgressButtonExtModule } from "../progress-button/progress-button-ext/progress-button-ext.module";
import { DataTableFilterFormComponent } from "./datatable-filters/datatable-filter-form.component";
import { DataTableFilterInputComponent } from "./datatable-filters/datatable-filter-input.component";
import { DataTableFiltersComponent } from "./datatable-filters/datatable-filters.component";
import { EntityLinkSelectorFilterModule } from "./datatable-filters/entity-link-selector-filter/entity-link-selector-filter.module";
import { FilterOperationSelectorModule } from "./datatable-filters/filter-operation-selector/filter-operation-selector.module";
import { DatatableUserDefinedValueFilterSectionComponent } from "./datatable-filters/user-defined-value-filters/datatable-user-defined-value-filter-section.component";
import { DatatableUserDefinedValueFilterComponent } from "./datatable-filters/user-defined-value-filters/datatable-user-defined-value-filter.component";
import { PropertyGroupSelectorModule } from "./property-group-selector/property-group-selector.module";
import { PropertyOwnerSelectorModule } from "./property-owner-selector/property-owner-selector.module";
import { PropertyOwnershipSelectorModule } from "./property-ownership-selector/property-ownership-selector.module";

@NgModule({
   imports: [
      CommonModule,
      ScrollingModule,
      FormsModule,
      LoadingOverlayModule,
      OverlayPanelsModule,
      TableModule,
      PaginatorModule,
      CheckboxModule,
      IconModule,
      LinkModule,
      InputsModule,
      PipesModule,
      DateRangePickerModule,
      DatePickerModule,
      MatSelectModule,
      TooltipModule,
      FilterOperationSelectorModule,
      FooterModule,
      EntitySelectorModule,
      EntityMultiSelectorModule,
      EnumMultiSelectorModule,
      SelectorOverlayPanelModule,
      AccountSelectorModule,
      AccountMultiSelectorModule,
      PropertyOwnerSelectorModule,
      EntityLinkSelectorFilterModule,
      PropertyGroupSelectorModule,
      PropertyOwnershipSelectorModule,
      SelectModule,
      ValidationModule,
      SecureSsnInputModule,
      ClickToCallModule,
      LcsProgressButtonModule,
      UDFEncryptedTextModule,
      ProgressButtonExtModule,
   ],
   declarations: [
      DataTableFiltersComponent,
      DataTableFilterFormComponent,
      DataTableFilterInputComponent,
      DatatableUserDefinedValueFilterComponent,
      DatatableUserDefinedValueFilterSectionComponent,
   ],
   providers: [DragulaService],
   exports: [DataTableFilterFormComponent, DataTableFilterInputComponent, DataTableFiltersComponent],
})
export class DataTableModule {}
