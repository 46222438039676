import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";

export class PostableGetTransferModel {
   Embeds: string | null = null;

   Filters: string | null = null;

   Fields: string | null = null;

   OrderingOptions: string | null = null;

   GetOptions: string | null = null;

   PageNumber: number | null = null;

   PageSize: number | null = null;

   constructor(postModel: PostableGetModel | null, uriEncode = true) {
      if (postModel) {
         this.Embeds = EntityEmbedOption.toApiString(postModel.embeds);
         this.Fields = EntityField.toApiString(postModel.fields);
         this.OrderingOptions = OrderingOption.toApiString(postModel.orderingOptions);
         this.GetOptions = EntityGetOption.toApiString(postModel.getOptions);
         this.PageNumber = postModel.pageNumber;
         this.PageSize = postModel.pageSize;
         this.Filters = FilterOption.toApiString(postModel.filters, uriEncode);
      }
   }
}
