import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { ReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-report-parameter.model";

export class ReportParameterRendererComponentBase {
   controlName: string;
   parameter: ReportReportParameterComponentModel;
   parameterComponents: Array<ReportReportParameterComponentModel>;
   hasAsk: boolean;
   isRelative: boolean;

   /**
    * Returns PromptText || DisplayName || PropertyName || defaultValue
    */
   static getDisplayNameFromReportReportParameter(
      reportReportParameter: ReportReportParameterModel,
      defaultValue: string = "Parameter"
   ): string {
      let displayName: string;
      if (!reportReportParameter) {
         displayName = defaultValue;
      } else {
         displayName =
            reportReportParameter.PromptText ||
            reportReportParameter.DisplayName ||
            reportReportParameter.PropertyName ||
            defaultValue;
      }
      return displayName;
   }
}
