import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";

import { ValidationModule } from "../../../inputs/validation/validation.module";
import { ReportParameterAskModule } from "../report-parameter-ask/report-parameter-ask.module";
import { StatementMethodReportParameterComponent } from "./statement-method-report-parameter.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, ReportParameterAskModule, SingleLineMultiSelectModule],
   declarations: [StatementMethodReportParameterComponent],
   exports: [StatementMethodReportParameterComponent],
})
export class StatementMethodReportParameterModule {}
