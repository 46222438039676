<div
   [class.disabled]="disabled"
   class="radio-button-wrapper"
   title="{{tooltip}}"
>
   <div
      [ngStyle]="style"
      [ngClass]="'ui-radiobutton ui-widget'"
      [class]="styleClass"
   >
      <div class="hidden-radio-button">
         <input
            #rb
            type="radio"
            [attr.id]="inputId"
            [attr.name]="name"
            [attr.value]="value"
            [attr.tabindex]="tabindex"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (change)="onChange()"
            [disabled]="disabled"
         />
      </div>
      <div
         *ngIf="!checked"
         (click)="onChange()"
         class="material-icons unchecked"
         [ngClass]="{ 'ui-state-disabled': disabled, 'ui-state-focus': focused }"
      >radio_button_unchecked</div>
      <div
         *ngIf="checked"
         class="material-icons checked"
         [ngClass]="{ 'ui-state-disabled': disabled, 'ui-state-focus': focused }"
      >radio_button_checked</div>
   </div>
   <label
      [class]="labelStyleClass"
      [ngClass]="{ 'ui-radiobutton-label': true, 'ui-label-disabled': disabled, 'ui-label-focus': focused }"
      *ngIf="label"
      [attr.for]="inputId"
   >{{ label }}</label>
</div>