import { Component, OnDestroy, OnInit } from "@angular/core";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { OWADashboardTile } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/owadashboard-tile.enum";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OWADashboardTileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-dashboard-tile.model";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { OWASessionModel } from "../session/models/owa-session.model";
import { OWASessionService } from "../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { MenuService } from "../top-bar/menu/menu.service";
import { UserProfileModalComponent } from "../user-profile/user-profile-modal/user-profile-modal.component";

@Component({
   selector: "owa-dashboard-list",
   templateUrl: "./dashboard-list-view.component.html",
   styleUrls: ["./dashboard.component.css"],
})
export class DashboardListViewComponent implements OnInit, OnDestroy {
   showOpenServiceIssuesOWAPreference: boolean;
   showClosedServiceIssuesOWAPreference: boolean;
   showChecksPreference: boolean;
   showInspectionsPreference: boolean;
   showBills: boolean;
   showManagementFees: boolean;
   loggedInUser: string;
   showNotes: boolean;
   showOwnerDistributions: boolean;
   showOwnerships: boolean;
   showReserve: boolean;
   showOwnershipPercentage: boolean;
   showSecurityDepositHeld: boolean;
   ServiceIssuesSortOrder: Number;
   EstimatesSortOrder: Number;
   ChecksSortOrder: Number;
   InspectionsSortOrder: Number;
   BillsSortOrder: Number;
   ManagementFeesSortOrder: Number;
   NotesSortOrder: Number;
   OwnerDistributionsSortOrder: Number;
   OwnershipsSortOrder: Number;
   MessagesSortOrder: Number;
   allowOwnerToEmailPropertyManager: boolean;
   owaSessionModel: OWASessionModel = new OWASessionModel();
   owaDashboardOrderedTiles: Array<OWADashboardTileModel> = new Array<OWADashboardTileModel>();
   generalMessage: string;
   ownerMessage: string;
   preferencesLoaded: BehaviorSubject<boolean>;
   ownerLoaded: BehaviorSubject<boolean>;
   isOwner: boolean;
   showOpenEstimatesOWAPreference: boolean;
   showClosedEstimatesOWAPreference: boolean;
   webUser: WebUserModel;
   eOwaDashboardTile = OWADashboardTile;
   private unsubscribe = new Subject<void>();

   constructor(
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private owaSessionService: OWASessionService,
      private dialogCreationService: DialogCreationService,
      private menuService: MenuService
   ) {
      this.preferencesLoaded = new BehaviorSubject<boolean>(false);
      this.ownerLoaded = new BehaviorSubject<boolean>(false);
      // this.owaDashboardOrderedTiles = new BehaviorSubject<Array<OWADashboardTileModel>>();
   }

   ngOnInit() {
      this.ownerLoaded.next(false);
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
         if (res != null) {
            this.owaSessionModel = res;
            this.isOwner = this.owaSessionModel.CurrentWebUserAccount.AccountType === AccountType.Owner ? true : false;
            if (this.owaSessionModel != null) {
               this.webUser = this.owaSessionModel.CurrentUser;
               if (!this.webUser.FirstName || !this.webUser.LastName) {
                  this.OpenUserProfileDialog();
               } else {
                  this.loggedInUser = "Welcome, " + this.webUser.FirstName + " " + this.webUser.LastName;
               }
               if (this.owaSessionModel.CurrentWebUserAccount.AccountType === AccountType.Owner) {
                  if (!this.webUser.FirstName || !this.webUser.LastName) {
                     this.loggedInUser = "Welcome, " + this.owaSessionModel.CurrentAccount.Name;
                  }
                  this.ownerMessage = this.owaSessionModel.CurrentAccount.WebMessage;
               } else if (!this.webUser.FirstName || !this.webUser.LastName) {
                  this.loggedInUser = "Welcome, " + this.owaSessionModel.CurrentOwnerProspectAccount.Name;
                  // this.ownerMessage = this.owaSessionModel.CurrentAccount.WebMessage;
               }

               this.generalMessage = this.owaSessionModel.GeneralMessage;
            }
            this.ownerLoaded.next(true);
         }
      });
      this.preferencesLoaded.next(false);

      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               this.menuService
                  .GetDashboardOrderedTiles()
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((tiles) => {
                     this.owaDashboardOrderedTiles = tiles;
                     this.owaDashboardOrderedTiles.forEach((a) => {
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Notes) {
                           this.NotesSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Bills) {
                           this.BillsSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Checks) {
                           this.ChecksSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Estimates) {
                           this.EstimatesSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.InspectionReports) {
                           this.InspectionsSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.ManagementFees) {
                           this.ManagementFeesSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Messages) {
                           this.MessagesSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.Ownership) {
                           this.OwnershipsSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.ServiceIssues) {
                           this.ServiceIssuesSortOrder = a.SortOrder;
                        }
                        if (a.IsEnabled && a.OWADashboardTile == this.eOwaDashboardTile.OwnerDistributions) {
                           this.OwnerDistributionsSortOrder = a.SortOrder;
                        }
                     });
                     this.showOpenServiceIssuesOWAPreference =
                        prefs.find((a) => a.Name === "ShowOpenServiceIssuesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowOpenServiceIssuesOWA").Value === "True"
                              ? true
                              : false
                           : false;

                     this.showClosedServiceIssuesOWAPreference =
                        prefs.find((a) => a.Name === "ShowClosedServiceIssuesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowClosedServiceIssuesOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showChecksPreference =
                        prefs.find((a) => a.Name === "ShowChecksOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowChecksOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showNotes =
                        prefs.find((a) => a.Name === "ViewHistoryNotesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ViewHistoryNotesOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showBills =
                        prefs.find((a) => a.Name === "ShowBillsOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowBillsOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showManagementFees =
                        prefs.find((a) => a.Name === "ShowMgmtFeesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowMgmtFeesOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showOwnerDistributions =
                        prefs.find((a) => a.Name === "ShowOwnerChecksOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowOwnerChecksOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showOwnerships =
                        prefs.find((a) => a.Name === "ShowOwnershipsOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowOwnershipsOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showReserve =
                        prefs.find((a) => a.Name === "ShowReserveOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowReserveOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showOwnershipPercentage =
                        prefs.find((a) => a.Name === "ShowOwnershipPercentOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowOwnershipPercentOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showSecurityDepositHeld =
                        prefs.find((a) => a.Name === "ShowSecurityDepositsOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "ShowSecurityDepositsOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showInspectionsPreference =
                        prefs.find((a) => a.Name === "CanSeeWebInspectionReportOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "CanSeeWebInspectionReportOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.allowOwnerToEmailPropertyManager =
                        prefs.find((a) => a.Name === "AllowOwnerToEmailPropertyManager") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "AllowOwnerToEmailPropertyManager").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showOpenEstimatesOWAPreference =
                        prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.showClosedEstimatesOWAPreference =
                        prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA") != null
                           ? // @ts-ingore
                             // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA").Value === "True"
                              ? true
                              : false
                           : false;
                     this.preferencesLoaded.next(true);
                  });
            }
         }
      );
   }

   OpenUserProfileDialog(): void {
      const dialogConfig = new DialogConfigurationModel();
      dialogConfig.componentInstance = UserProfileModalComponent;
      dialogConfig.title = "User Profile";
      dialogConfig.disableClose = true;
      this.dialogCreationService.open(dialogConfig, this.webUser.WebUserID);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
