<div class="bank-account-report-parameter-wrapper">
   <div class="report-parameter-label">
      <label
         class="parameter-label"
         [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      >
         {{ displayName }}
      </label>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="parameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
   <lcs-bank-selector
      [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      [ngModel]="reportParameterValueModel.value"
      (ngModelChange)="onBankSelectorValueChange($event)"
      [displayName]="displayName"
   ></lcs-bank-selector>
</div>