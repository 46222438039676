import { Injectable } from "@angular/core";
import { IconDefinitionModel } from "@lcs/icon/icon-definition.model";
import { IconService } from "@lcs/icon/icon.service";

@Injectable()
export class LCSIconService implements IconService {
   constructor() {}

   buildIconDefinition(iconName: string): IconDefinitionModel {
      if (iconName.startsWith("fi-") || iconName.startsWith("rmicon-")) {
         const result = new IconDefinitionModel();
         result.iconClass = iconName;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
         result.iconInnerHtml = null;
         return result;
      } else {
         return IconService.buildDefaultIconDefinition(iconName);
      }
   }
}
