////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum HistNoteFilter {
   Property = 1,
   Unit = 2,
   Tenant = 3,
   Owner = 4,
   Vendor = 5,
   Prospect = 6,
   Issue = 7,
   Asset = 8,
   AssetMaintenance = 9,
   Bill = 10,
   RecurringBill = 11,
   Violations = 12,
}
