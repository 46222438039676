import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { AddressRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/address-related-object-types.enum";
import { PhoneNumberRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/phone-number-related-object-types.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { AddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address.model";
import { ContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, switchMap, take, takeUntil } from "rxjs";

import { OWASessionService } from "../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { UpdateAccountInformationService } from "./update-account-information.service";

@Component({
   selector: "owa-update-account-information",
   templateUrl: "./update-account-information.component.html",
   styleUrls: ["./update-account-information.component.css"],
})
export class UpdateAccountInformationComponent implements OnInit, OnDestroy {
   addressModel = new AddressModel();

   addressTypesValueSource: ValueSourceModel;

   canEdit: boolean = false;

   contact = new ContactModel();

   loading: boolean = true;

   owner = new OwnerModel();

   ownerProspect = new OwnerProspectModel();

   public emailValidationModel: ValidationModel;

   phoneNumberModel: PhoneNumberModel = new PhoneNumberModel();

   phoneNumberTypesValueSource: ValueSourceModel;

   selectedAddressType: number;

   selectedAddressValue: string;

   selectedPhoneType: number;

   selectedPhoneValue: string;

   phoneNumbersToDisplay: Array<PhoneNumberModel> = new Array<PhoneNumberModel>();

   updateButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   public allAddresses = new Array<AddressModel>();

   isOwner: boolean = false;

   private allPhoneNumbers = new Array<PhoneNumberModel>();

   private unsubscribe = new Subject<void>();

   constructor(
      private accountInformationService: UpdateAccountInformationService,
      private owaSessionService: OWASessionService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private errorMessageService: ErrorMessageService,
      private currentDialogService: CurrentDialogService
   ) {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               const pref = prefs.find((a) => a.Name === "AllowOwnerToChangePersonalInformationOWA");
               // @ts-ignore ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
               this.canEdit = pref && pref.Value === "True";
            }
         }
      );
   }

   ngOnInit(): void {
      // Create an Email Validation Model for Multiple Emails using this regex expression
      const emailRegexp: RegExp =
         /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/;
      this.emailValidationModel = new ValidationModel();
      this.emailValidationModel.required = true;
      this.emailValidationModel.pattern = emailRegexp;

      this.isOwner =
         this.owaSessionService.currentOWASessionInfo.CurrentWebUserAccount.AccountType == AccountType.Owner
            ? true
            : false;
      if (this.isOwner) {
         this.owaSessionService.OWASessionInfo.pipe(
            switchMap(() => {
               this.loading = true;
               return this.accountInformationService.getOwnerInformation();
            }),
            takeUntil(this.unsubscribe)
         ).subscribe(
            ([owner, addressTypes, phoneNumberTypes]: [OwnerModel, AddressTypeModel[], PhoneNumberTypeModel[]]) => {
               this.loading = false;
               if (owner) {
                  this.owner = owner;
                  this.contact = owner.Contact;

                  this.addressTypesValueSource = new ValueSourceModel();
                  this.addressTypesValueSource.Type = ValueSourceTypes.Static;
                  this.addressTypesValueSource.StaticValues = [];

                  addressTypes.forEach((at: AddressTypeModel) => {
                     const existingAddress = owner.Addresses.find((a) => a.AddressTypeID === at.AddressTypeID);
                     if (!existingAddress) {
                        const blankAddress = new AddressModel();
                        blankAddress.ParentID = this.owner.OwnerID;
                        blankAddress.ParentType = AddressRelatedObjectTypes.Owner;
                        blankAddress.AddressID = -1;
                        blankAddress.AddressTypeID = at.AddressTypeID;
                        blankAddress.IsPrimary = false;
                        blankAddress.IsBilling = false;
                        blankAddress.Address = "";
                        blankAddress.AddressType = at;
                        owner.Addresses.push(blankAddress);
                     }
                     this.addressTypesValueSource.StaticValues.push(new SelectorItemModel(at.AddressTypeID, at.Name));
                  });

                  addressTypes.map((at) => new SelectorItemModel(at.AddressTypeID, at.Name));

                  this.allAddresses = owner.Addresses;
                  this.owner.Addresses = this.allAddresses;
                  this.addressModel = this.allAddresses.find((a) => a.IsPrimary) || this.allAddresses[0];
                  if (this.owner.Addresses.find((ad) => ad.IsPrimary) === null) {
                     this.owner.Addresses[0].IsPrimary = true;
                  }
                  this.selectedAddressType = this.addressModel.AddressTypeID;
                  this.selectedAddressValue = this.addressModel.AddressType.Name;

                  this.phoneNumberTypesValueSource = new ValueSourceModel();
                  this.phoneNumberTypesValueSource.Type = ValueSourceTypes.Static;
                  this.phoneNumberTypesValueSource.StaticValues = [];
                  phoneNumberTypes.forEach((pnt: PhoneNumberTypeModel) => {
                     const existingPhoneNumber = owner.PhoneNumbers.find(
                        (p) => p.PhoneNumberTypeID === pnt.PhoneNumberTypeID
                     );
                     if (!existingPhoneNumber) {
                        const blankPhoneNumber = new PhoneNumberModel();
                        blankPhoneNumber.ParentID = this.contact.ContactID;
                        blankPhoneNumber.ParentType = PhoneNumberRelatedObjectTypes.Contact;
                        blankPhoneNumber.PhoneNumberID = -1;
                        blankPhoneNumber.PhoneNumberTypeID = pnt.PhoneNumberTypeID;
                        blankPhoneNumber.IsPrimary = false;
                        blankPhoneNumber.PhoneNumberType = pnt;
                        owner.PhoneNumbers.push(blankPhoneNumber);
                     }
                     this.phoneNumberTypesValueSource.StaticValues.push(
                        new SelectorItemModel(pnt.PhoneNumberTypeID, pnt.Name)
                     );
                  });
                  this.phoneNumbersToDisplay = owner.PhoneNumbers;
                  this.owner.PhoneNumbers = this.phoneNumbersToDisplay;

                  this.allPhoneNumbers = owner.PhoneNumbers;
                  this.phoneNumberModel = this.allPhoneNumbers.find((p) => p.IsPrimary) || this.allPhoneNumbers[0];
                  const check = this.owner.PhoneNumbers.find((ph) => ph.IsPrimary);
                  if (check === null || check === undefined) {
                     this.owner.PhoneNumbers[0].IsPrimary = true;
                  }
                  this.selectedPhoneType = this.phoneNumberModel.PhoneNumberTypeID;
                  this.selectedPhoneValue = this.phoneNumberModel.PhoneNumberType.Name;
               } else {
                  this.errorMessageService.triggerErrorMessage("Unable to load Owner. Please try again.");
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
      } else {
         this.owaSessionService.OWASessionInfo.pipe(
            switchMap(() => {
               this.loading = true;
               return this.accountInformationService.getOwnerProspectInformation();
            }),
            takeUntil(this.unsubscribe)
         ).subscribe(
            ([ownerProspect, addressTypes, phoneNumberTypes]: [
               OwnerProspectModel,
               AddressTypeModel[],
               PhoneNumberTypeModel[]
            ]) => {
               this.loading = false;
               if (ownerProspect) {
                  this.ownerProspect = ownerProspect;
                  this.contact = ownerProspect.Contact;

                  this.addressTypesValueSource = new ValueSourceModel();
                  this.addressTypesValueSource.Type = ValueSourceTypes.Static;
                  this.addressTypesValueSource.StaticValues = [];

                  addressTypes.forEach((at: AddressTypeModel) => {
                     const existingAddress = ownerProspect.Addresses.find((a) => a.AddressTypeID === at.AddressTypeID);
                     if (!existingAddress) {
                        const blankAddress = new AddressModel();
                        blankAddress.ParentID = this.ownerProspect.OwnerProspectID;
                        blankAddress.ParentType = AddressRelatedObjectTypes.OwnerProspect;
                        blankAddress.AddressID = -1;
                        blankAddress.AddressTypeID = at.AddressTypeID;
                        blankAddress.IsPrimary = false;
                        blankAddress.IsBilling = false;
                        blankAddress.Address = "";
                        blankAddress.AddressType = at;
                        ownerProspect.Addresses.push(blankAddress);
                     }
                     this.addressTypesValueSource.StaticValues.push(new SelectorItemModel(at.AddressTypeID, at.Name));
                  });

                  addressTypes.map((at) => new SelectorItemModel(at.AddressTypeID, at.Name));

                  this.allAddresses = ownerProspect.Addresses;
                  this.ownerProspect.Addresses = this.allAddresses;
                  this.addressModel = this.allAddresses.find((a) => a.IsPrimary) || this.allAddresses[0];
                  if (this.ownerProspect.Addresses.find((ad) => ad.IsPrimary) === null) {
                     this.ownerProspect.Addresses[0].IsPrimary = true;
                  }
                  this.selectedAddressType = this.addressModel.AddressTypeID;

                  this.phoneNumberTypesValueSource = new ValueSourceModel();
                  this.phoneNumberTypesValueSource.Type = ValueSourceTypes.Static;
                  this.phoneNumberTypesValueSource.StaticValues = [];
                  phoneNumberTypes.forEach((pnt: PhoneNumberTypeModel) => {
                     const existingPhoneNumber = ownerProspect.Contact.PhoneNumbers.find(
                        (p) => p.PhoneNumberTypeID === pnt.PhoneNumberTypeID
                     );
                     if (!existingPhoneNumber) {
                        const blankPhoneNumber = new PhoneNumberModel();
                        blankPhoneNumber.ParentID = this.contact.ContactID;
                        blankPhoneNumber.ParentType = PhoneNumberRelatedObjectTypes.Contact;
                        blankPhoneNumber.PhoneNumberID = -1;
                        blankPhoneNumber.PhoneNumberTypeID = pnt.PhoneNumberTypeID;
                        blankPhoneNumber.IsPrimary = false;
                        blankPhoneNumber.PhoneNumberType = pnt;
                        ownerProspect.Contact.PhoneNumbers.push(blankPhoneNumber);
                     }
                     this.phoneNumberTypesValueSource.StaticValues.push(
                        new SelectorItemModel(pnt.PhoneNumberTypeID, pnt.Name)
                     );
                  });
                  this.phoneNumbersToDisplay = ownerProspect.Contact.PhoneNumbers;
                  this.ownerProspect.Contact.PhoneNumbers = this.phoneNumbersToDisplay;

                  this.allPhoneNumbers = ownerProspect.Contact.PhoneNumbers;
                  this.phoneNumberModel = this.allPhoneNumbers.find((p) => p.IsPrimary) || this.allPhoneNumbers[0];
                  const check = this.ownerProspect.Contact.PhoneNumbers.find((ph) => ph.IsPrimary);
                  if (check === null || check === undefined) {
                     this.ownerProspect.Contact.PhoneNumbers[0].IsPrimary = true;
                  }
                  this.selectedPhoneType = this.phoneNumberModel.PhoneNumberTypeID;
               } else {
                  this.errorMessageService.triggerErrorMessage("Unable to load Owner Prospect. Please try again.");
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
      }
   }

   isOwnerProspect(obj: OwnerModel | OwnerProspectModel): obj is OwnerProspectModel {
      return "OwnerProspectID" in obj;
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setPhoneNumberType(phoneNumberType: number) {
      // const owasessioninfo = this.owaSessionService.currentOWASessionInfo;
      if (this.isOwner) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'PhoneNumberModel | undefined' is not assigna... Remove this comment to see the full error message
         this.phoneNumberModel = this.allPhoneNumbers.find((a) => a.PhoneNumberTypeID === phoneNumberType);
         this.selectedPhoneType = phoneNumberType;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.owner.PhoneNumbers.find((a) => a.IsPrimary).IsPrimary = false;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.owner.PhoneNumbers.find((a) => a.PhoneNumberTypeID === phoneNumberType).IsPrimary = true;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'PhoneNumberModel | undefined' is not assigna... Remove this comment to see the full error message
         this.phoneNumberModel = this.allPhoneNumbers.find((a) => a.PhoneNumberTypeID === phoneNumberType);
         this.selectedPhoneType = phoneNumberType;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.ownerProspect.Contact.PhoneNumbers.find((a) => a.IsPrimary).IsPrimary = false;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.ownerProspect.Contact.PhoneNumbers.find((a) => a.PhoneNumberTypeID === phoneNumberType).IsPrimary = true;
      }
   }

   setAddressType(addressType: number) {
      // const owasessioninfo = this.owaSessionService.currentOWASessionInfo;
      if (this.isOwner) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'AddressModel | undefined' is not assignable ... Remove this comment to see the full error message
         this.addressModel = this.allAddresses.find((a) => a.AddressTypeID === addressType);
         this.selectedAddressType = addressType;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.owner.Addresses.find((a) => a.IsPrimary).IsPrimary = false;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.owner.Addresses.find((a) => a.AddressTypeID === addressType).IsPrimary = true;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'AddressModel | undefined' is not assignable ... Remove this comment to see the full error message
         this.addressModel = this.allAddresses.find((a) => a.AddressTypeID === addressType);
         this.selectedAddressType = addressType;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.ownerProspect.Addresses.find((a) => a.IsPrimary).IsPrimary = false;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.ownerProspect.Addresses.find((a) => a.AddressTypeID === addressType).IsPrimary = true;
      }
   }

   onAddressChange(model: AddressModel, address: string): void {
      // the BL only works with \r\n
      model.Address = address.replace(/\r?\n/g, "\r\n");
   }

   onSubmit(form: NgForm) {
      if (this.canEdit) {
         if (form.valid) {
            this.updateButtonStatus = LcsProgressButtonStatus.InProgress;
            if (this.owner.Contact != null || this.ownerProspect.Contact != null) {
               const searchRegExp = /,/gi;
               this.isOwner
                  ? (this.owner.Contact.Email = this.contact.Email.replace(/\s/g, "").replace(searchRegExp, ";"))
                  : (this.ownerProspect.Contact.Email = this.contact.Email.replace(/\s/g, "").replace(
                       searchRegExp,
                       ";"
                    ));
            }
            if (this.owner.Addresses != null || this.ownerProspect.Addresses != null) {
               const address = this.isOwner
                  ? this.owner.Addresses.find((a) => a.IsPrimary)
                  : this.ownerProspect.Addresses.find((a) => a.IsPrimary);
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               if (!address.Address) {
                  this.errorMessageService.triggerErrorMessage(
                     // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                     "Account not Updated. Primary Address (" + address.AddressType.Name + ")is blank"
                  );
                  this.updateButtonStatus = LcsProgressButtonStatus.Error;
                  return;
               }
            }

            if (this.owner.PhoneNumbers != null || this.ownerProspect.Contact.PhoneNumbers != null) {
               const value = this.isOwner
                  ? this.owner.PhoneNumbers.find((a) => a.IsPrimary)
                  : this.ownerProspect.Contact.PhoneNumbers.find((a) => a.IsPrimary);
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               if (!value.PhoneNumber) {
                  this.errorMessageService.triggerErrorMessage(
                     // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                     "Account not Updated. Primary Phone Number (" + value.PhoneNumberType.Name + ")is blank"
                  );
                  this.updateButtonStatus = LcsProgressButtonStatus.Error;
                  return;
               }
            }
            this.isOwner
               ? this.accountInformationService.saveOwnerInformation(this.owner)
               : this.accountInformationService.saveOwnerProspectInformation(this.ownerProspect);
            this.accountInformationService.results.pipe(take(1), takeUntil(this.unsubscribe)).subscribe((res) => {
               if (!res.ok) {
                  this.updateButtonStatus = LcsProgressButtonStatus.Error;
                  this.errorMessageService.triggerHttpErrorMessage(res);
               } else {
                  this.updateButtonStatus = LcsProgressButtonStatus.Success;
               }
            });
         } else {
            this.updateButtonStatus = LcsProgressButtonStatus.Warning;
            markFormGroupAsTouchedAndDirty(form.control);
         }
      } else {
         this.currentDialogService.closeDialog();
      }
   }
}
