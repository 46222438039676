import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class GlobalsService {
   public static locale: string = "en-US";

   public static addActionRoute: string = "add";

   public static upperCaseFirstLetter(text: string) {
      return text.charAt(0).toUpperCase() + text.slice(1);
   }

   public static convertPxToEm(pxValue: number): number {
      const fontSize = window
         .getComputedStyle(document.getElementsByClassName("content-wrapper")[0], null)
         .getPropertyValue("font-size");
      return pxValue / parseFloat(fontSize);
   }

   public static getAllNumerics(value: string): Array<string> | null {
      return value.match(/-?\d*\.?\d+/g);
   }

   public static getAllSpecialCharacters(value: string): Array<string> | null {
      return value.match(/[^\w]|_/g);
   }

   public static getFloatFromString(value: string): number {
      return parseFloat(value.replace(/[^-.\d]+/g, ""));
   }

   public static getElementWidthWithoutPadding(element: any): number {
      let innerWidth = 0;
      const computedStyle = getComputedStyle(element);

      innerWidth = element.clientWidth;
      innerWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

      return GlobalsService.convertPxToEm(innerWidth);
   }

   public static hasValue(value: any) {
      if (value !== null && value !== undefined) {
         return true;
      }
      return false;
   }

   public static decodeBase64Data(base64Data) {
      const partiallyDecodedData = window.atob(base64Data);
      const byteNums = new Array(base64Data.length);
      for (let i = 0; i < byteNums.length; i++) {
         byteNums[i] = partiallyDecodedData.charCodeAt(i);
      }
      return new Uint8Array(byteNums);
   }

   static convertStringToBoolean(value: any): boolean {
      if (value === null || value === undefined) {
         return false;
      }
      const stringValue = value.toString().toLowerCase();
      if (stringValue === "true" || stringValue === "1") {
         return true;
      } else {
         return false;
      }
   }

   public static getSanitizedDateValue(date: Date): number {
      return date.setHours(0, 0, 0, 0);
   }

   /**
    * Compute a 32bit integer hash number for a string based on Java's string hashCode method.
    *
    * NOTE: number returned can be positive, negative or zero
    *
    * See https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
    */
   public static hashCode(str: string | null | undefined): number {
      let hash: number = 0;
      let i: number = 0;
      let chr: number = 0;
      if (!str) {
         return hash;
      }
      for (i = 0; i < str.length; i++) {
         chr = str.charCodeAt(i);
         // eslint-disable-next-line no-bitwise
         hash = (hash << 5) - hash + chr;
         // eslint-disable-next-line no-bitwise
         hash |= 0; // Convert to 32bit integer
      }
      return hash;
   }

   /**
    * Compute a 32bit integer hash number for a string based on Java's string hashCode method.
    *
    * NOTE: if hashCode is negative the absolute value prefixed with an 'N' will be returned
    *
    * See https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
    */
   public static hashCodeString(str: string | null | undefined): string {
      const hashCode: number = GlobalsService.hashCode(str);
      const hashCodeString: string = hashCode >= 0 ? hashCode.toString() : `N${Math.abs(hashCode)}`;
      return hashCodeString;
   }
}
