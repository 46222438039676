import { Injectable } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { ApplicationInfoModel } from "./application-info.model";
import { ApplicationInfoService } from "./application-info.service";
import { StartupStep } from "./startup-step.enum";

@Injectable()
export class StartupService {
   public startupStatus = new BehaviorSubject<StartupStep>(StartupStep.NotStarted);

   public applicationInformation = new BehaviorSubject<ApplicationInfoModel | null>(null);

   private unsubscribe = new Subject<void>();

   constructor(
      private errorMessageService: ErrorMessageService,
      private applicationInfoService: ApplicationInfoService
   ) {}

   public initialize() {
      this.checkAPIHealth();
   }

   private checkAPIHealth() {
      this.startupStatus.next(StartupStep.CheckingAPIHealth);
      this.applicationInfoService
         .GetApplicationInfo()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (response) => {
               this.applicationInformation.next(response);
               this.startupStatus.next(StartupStep.ReadyForAuthentication);
            },
            (err) => {
               this.startupStatus.next(StartupStep.APIHealthCheckFailed);
               this.startupStatus.next(StartupStep.StartupFailed);
               if (this.errorMessageService.errorRequiresSpecialHandling(err)) {
                  this.errorMessageService.triggerHttpErrorMessage(err);
               } else {
                  this.errorMessageService.triggerErrorMessage(
                     "There was an error contacting the server. Please refresh the page.\r\n\r\nIf this problem persists, please contact your administrator.",
                     null,
                     false
                  );
               }
            }
         );
   }
}
