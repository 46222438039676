import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ConstantsService {
   public readonly ZegoPrivacyPage: string = "https://www.gozego.com/privacy-policy/";
   public readonly EstimateSignatureLabel: string = "Enter your name to electronically sign and approve this estimate.";
   public readonly EstimateBannerMultipleApprovalText: string = "There are estimates available for you to review.";
   public readonly EstimateBannerSingleApprovalText: string = "There is an estimate available for you to review.";

   constructor() {}
}
