<div class="toggleable-input-report-parameter-checkbox-section report-parameter-label">
   <lcs-checkbox
      (ngModelChange)="onCheckboxChange($event)"
      [ngModel]="isChecked"
      [name]="checkboxName"
      [displayName]="$any(parameter?.PromptText)"
      [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >{{ parameter?.PromptText }}</lcs-checkbox>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<input
   type="text"
   [disabled]="!isChecked || disabled || (hasAsk === true && parameter.IsAsk === true)"
   [name]="inputName"
   [ngModel]="value"
   (ngModelChange)="updateValue($event)"
/>