<form
   #form="ngForm"
   (ngSubmit)="onApplyFiltersClick(form.control)"
   [hidden]="isFiltersLoading"
   autocomplete="off"
>
   <div
      class="filter-section"
      *ngIf="dataTableFilters && dataTableFilters.length > 0"
   >
      <div class="filter-buttons">
         <lcs-link
            [text]="'Clear All'"
            (clicked)="onClearFiltersClick()"
         ></lcs-link>
         <lcs-link
            [cssClasses]="['filter-collapse-icon']"
            [contentTemplate]="contentTemplate"
            (clicked)="onToggleFilterSectionClick()"
         >
            <ng-template #contentTemplate>
               <i class="material-icons">close</i>
            </ng-template>
         </lcs-link>
      </div>
      <div
         class="filter-list"
         cdkScrollable
      >
         <ng-container *ngIf="!isFiltersLoading">
            <div
               class="filter"
               *ngFor="let filterModel of dataTableFilters"
            >
               <lcs-datatable-filter-input
                  [datatableFilterModel]="filterModel"
                  [entitySearchType]="entitySearchType"
                  [filterOperations]="filterOperations"
                  [filtersCollapsed]="filtersCollapsed"
                  [showInactivePropertiesDefault]="showInactivePropertiesDefault"
                  (filterOptionChange)="filterOptionChanged($event)"
               >
               </lcs-datatable-filter-input>
            </div>
            <lcs-datatable-user-defined-value-filter-section
               *ngIf="includeUserDefinedValueFilters"
               [entityType]="entityType"
               [filterOperations]="filterOperations"
               [initialUserDefinedValuesFilterOptions]="userDefinedValueFilters"
               (filtersChanged)="onUserDefinedValueFiltersChange($event)"
            >
            </lcs-datatable-user-defined-value-filter-section>
         </ng-container>
      </div>
      <div
         lcsFooter
         class="filter-footer-buttons"
      >
         <div class="flex-third flex-end">
            <lcs-progress-button
               [raised]="true"
               [(status)]="applyButtonStatus"
            >
               Apply
            </lcs-progress-button>
         </div>
      </div>
   </div>
   <div
      class="filter-section no-filters"
      *ngIf="!(dataTableFilters && dataTableFilters.length > 0)"
   >
      <div>No Filters Found</div>
   </div>
</form>