import { Injectable } from "@angular/core";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { AccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/account.embed-options";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ExpressAccountTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-account-transfer.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class AccountService {
   static readonly TenantAndProspectUnitsResource = "TenantAndProspectUnits";
   static readonly TenantsAndProspectsResource = "TenantsAndProspects";
   static readonly endpoint = "AccountView";
   constructor(private apiService: ApiService) {}
   getTenantAndProspectUnitsCollectionUrl(accountID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountID=" + accountID);
      return this.apiService.getUrl(
         AccountService.endpoint + "/TenantAndProspectUnits",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantAndProspectUnitsCollection(
      accountID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getTenantAndProspectUnitsCollectionUrl(accountID, additionalParameters)
      );
   }

   getTenantsAndProspectsUrl(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         AccountService.endpoint + "/TenantsAndProspects",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantsAndProspects(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ExpressAccountTransferModel> {
      return this.apiService.getSingle(this.getTenantsAndProspectsUrl(id, embeds, fields, additionalParameters));
   }

   getTenantsAndProspectsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         AccountService.endpoint + "/TenantsAndProspects",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         null,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantsAndProspectsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ExpressAccountTransferModel>> {
      return this.apiService.getCollection(
         this.getTenantsAndProspectsCollectionUrl(filters, embeds, pageSize, pageNumber, additionalParameters)
      );
   }
}
