import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-crp-year-report-parameter",
   templateUrl: "crp-year-report-parameter.component.html",
})
export class CRPYearReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   allValuesLoaded: boolean = false;

   valueSources: Array<ValueSourceModel>;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(private apiService: ApiService, private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeReportParameterValue();
      this.initializeTransactionYearList();
   }

   processCollection(results: Array<string>, valueSourceModel: ValueSourceModel) {
      const sources = new Array<ValueSourceModel>();
      if (results && this.parameter.ReportID === Report.MinnCRP2018) {
         results.filter((year) => {
            if (+year <= 2018) {
               const item = new SelectorItemModel();
               item.displayValue = year;
               item.value = year;
               valueSourceModel.StaticValues.push(item);
            }
         });
         this.parameter.DefaultValue = this.parameter.ReportParameterValueSource.DefaultValue;
      } else if (results && this.parameter.ReportID === Report.MinnCRP) {
         results.filter((year) => {
            if (+year > 2018) {
               const item = new SelectorItemModel();
               item.displayValue = year;
               item.value = year;
               valueSourceModel.StaticValues.push(item);
            }
         });
      }
      this.parameter.DefaultValue = this.parameter.DefaultValue.replace(/\D/g, "");
      if (
         this.parameter.DefaultValue &&
         !valueSourceModel.StaticValues.find((year) => +year.value === +this.parameter.DefaultValue)
      ) {
         const itemvalue = new SelectorItemModel();
         itemvalue.displayValue = this.parameter.DefaultValue;
         itemvalue.value = this.parameter.DefaultValue;
         valueSourceModel.StaticValues.push(itemvalue);
      }
      valueSourceModel.SelectedValues.push(this.parameter.DefaultValue);
      sources.push(valueSourceModel);
      this.valueSources = sources;
      this.allValuesLoaded = true;
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel) {
      const value = selectionChangeModel.checkedItems?.map((f) => f.value.replace(/\D/g, "")).join(",");
      this.reportParameterValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private initializeTransactionYearList() {
      const apiURL = "TransactionInformation/TransactionYearList";
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;
      this.apiService
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         .getCollection(apiURL, null, [], [], [])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => this.processCollection(results, valueSourceModel));
   }

   private initializeReportParameterValue() {
      const reportParameterModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      if (reportParameterModel) {
         reportParameterModel.value = reportParameterModel.value.replace(/\D/g, "");
         this.reportParameterValueModel = reportParameterModel;
      }
   }
}
