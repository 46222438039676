import { Injectable } from "@angular/core";
import { ConfirmationDialogConfigurationModel } from "@lcs/dialog/components/confirmation-dialog-configuration.model";
import { Observable, Subject } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class ConfirmationService {
   public confirmationNeeded: Observable<ConfirmationDialogConfigurationModel>;

   private _confirmationNeeded = new Subject<ConfirmationDialogConfigurationModel>();

   constructor() {
      this.confirmationNeeded = this._confirmationNeeded.asObservable();
   }

   requestConfirmation(configuration: ConfirmationDialogConfigurationModel) {
      this._confirmationNeeded.next(configuration);
   }
}
