import { FilterStage } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-stage.enum";
import { TupleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/tuple.model";

import { DataTypes } from "./data-types.model";
import { FilterOperationModel } from "./filter-operation.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class FilterField {
   DataType: DataTypes;
   DeprecatedFilterNames: Array<TupleModel>;
   FilterDefinition: string;
   FilterName: string;
   FilterStage: FilterStage;
   IsNullable: boolean;
   IsPrimaryKeyField: boolean;
   MappedFilterDefinition: string;
   Operations: Array<FilterOperationModel>;
   PropertyName: string;
   RequiredEmbeds: Array<string>;
   SubQuery: string;
   SubQueryColumn: string;
   UseSubQuery: boolean;
}
