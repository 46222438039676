import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { ExpressActionEntityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action-entity-event.model";
import { Subject } from "rxjs";

import { FooterButtonModel } from "./models/footer-button.model";

@Component({
   selector: "owa-footer",
   templateUrl: "footer.component.html",
})
export class FooterComponent implements OnInit, OnDestroy {
   @Input() closeButtonText = "close";

   @Input() set footerButtons(val: Array<FooterButtonModel>) {
      if (val) {
         this.footerButtonsOveridden = true;
         this._footerButtons = val;
         this.checkVisibility();
      }
   }

   get footerButtons(): Array<FooterButtonModel> {
      return this._footerButtons;
   }

   @Input() set footerTemplate(val: TemplateRef<any>) {
      if (val) {
         this._footerTemplate = val;
         this.checkVisibility();
      }
   }

   get footerTemplate(): TemplateRef<any> {
      return this._footerTemplate;
   }

   @Input() visible: boolean = true;

   @Output() close: EventEmitter<void>;

   @Input() siteFooter: boolean;

   entityEvents = ExpressEntityEvents;

   form: NgForm;

   formId: string;

   showErrors: boolean;

   private _footerButtons: Array<FooterButtonModel>;

   private _footerTemplate: TemplateRef<any>;

   private footerButtonsOveridden;

   private unsubscribe = new Subject<void>();

   private siteFooterIsVisibleClass = "site-footer-visible";

   constructor(private renderer: Renderer2) {
      this.close = new EventEmitter<void>();
   }

   ngOnInit() {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   setActionEntityEvents(actionEntityEvents: ExpressActionEntityEventModel[]) {
      const footerButtons = actionEntityEvents.map((actionEntityEvent: ExpressActionEntityEventModel) => {
         const footerButton = new FooterButtonModel();
         footerButton.label = actionEntityEvent.Label;
         return footerButton;
      });
      if (footerButtons.length === 1) {
         footerButtons[0].isRaised = true;
      }

      this.setFooterButtons(footerButtons);
   }

   setFooterButtons(footerButtons: Array<FooterButtonModel>) {
      if (!this.footerButtonsOveridden) {
         this._footerButtons = footerButtons;
      }
      this.checkVisibility();
   }

   onButtonClick(footerEventButton: FooterButtonModel) {
      if (footerEventButton.onClick) {
         footerEventButton.onClick();
      }
   }

   onShowErrorPanelChange(show: boolean) {
      if (this.showErrors !== show) {
         this.showErrors = show;
      }
   }

   private checkVisibility() {
      this.visible = (this.footerButtons && this.footerButtons.length > 0) || this.footerTemplate != null;
      if (this.siteFooter) {
         if (this.visible) {
            this.renderer.addClass(document.body, this.siteFooterIsVisibleClass);
         } else {
            this.renderer.removeClass(document.body, this.siteFooterIsVisibleClass);
         }
      }
   }
}
