import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { Subject, takeUntil } from "rxjs";

import { RelativeDatesService } from "../../../inputs/date-picker/relative-dates.service";
import { DateRangeModel } from "../../../inputs/date-range-picker/date-range.model";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { RelativeDateValueModel } from "../relative-date-picker/relative-date-value.model";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-checked-date-range-report-parameter",
   templateUrl: "checked-date-range-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
})
export class CheckedDateRangeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }
   @Input() hasAsk: boolean;

   dateRange: DateRangeModel;

   startRelativeDateValue: RelativeDateValueModel;

   endRelativeDateValue: RelativeDateValueModel;

   startDateValue: string = "";

   endDateValue: string = "";

   parameterValueModel: ReportParameterValueModel;

   dateFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   label: string = "Date Range";

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {}

   ngOnInit() {
      this.initializeParameter();
      this.initializeReportParameterValues();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   valueChange(event: DateRangeModel) {
      this.dateRange = event;
      if (event) {
         let startDateValue = "";
         let valueChanged = false;
         if (event.startDate) {
            startDateValue = formatDate(event.startDate, "MM/dd/yyyy", GlobalsService.locale);
         }
         if (this.startDateValue !== startDateValue) {
            this.startDateValue = startDateValue;
            valueChanged = true;
         }
         let endDateValue = "";
         if (event.endDate) {
            endDateValue = formatDate(event.endDate, "MM/dd/yyyy", GlobalsService.locale);
         }
         if (this.endDateValue !== endDateValue) {
            this.endDateValue = endDateValue;
            valueChanged = true;
         }
         if (valueChanged) {
            this.processValueChange();
         }
      }
   }

   onStartDateChanged(value: RelativeDateValueModel) {
      let startDateValue = "";
      if (value) {
         if (this.isRelative && value.relativeDateOption) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            startDateValue = RelativeDatesService.relativeDateEnumMap().get(value.relativeDateOption);
         } else if (value.dateValue) {
            startDateValue = formatDate(value.dateValue, "MM/dd/yyyy", GlobalsService.locale);
         } else {
            startDateValue = "";
         }
      } else {
         startDateValue = "";
      }

      if (this.startDateValue !== startDateValue) {
         this.startDateValue = startDateValue;
         this.processValueChange();
      }
   }

   onEndDateChanged(value: RelativeDateValueModel) {
      let endDateValue = "";
      if (value) {
         if (this.isRelative && value.relativeDateOption) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            endDateValue = RelativeDatesService.relativeDateEnumMap().get(value.relativeDateOption);
         } else if (value.dateValue) {
            endDateValue = formatDate(value.dateValue, "MM/dd/yyyy", GlobalsService.locale);
         } else {
            endDateValue = "";
         }
      } else {
         endDateValue = "";
      }

      if (this.endDateValue !== endDateValue) {
         this.endDateValue = endDateValue;
         this.processValueChange();
      }
   }

   private initializeParameter() {
      if (this.parameter.ReportParameterValueSource && this.parameter.ReportParameterValueSource.FormatString) {
         this.dateFormat = this.parameter.ReportParameterValueSource.FormatString;
      }
   }

   private processValueChange() {
      this.parameterValueModel.value = `${this.startDateValue} - ${this.endDateValue}`;
      this.reportParametersService.updateParameterValue.next(this.parameterValueModel);
   }

   private initializeReportParameterValues() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.parameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );

      if (this.parameterValueModel.value) {
         let delimiter = " ";
         if (this.parameterValueModel.value.indexOf("-") > -1) {
            delimiter = "-";
         }
         const dates: Array<string> = this.parameterValueModel.value.toString().split(delimiter);

         if (dates.length === 2) {
            this.startDateValue = dates[0].trim();
            this.endDateValue = dates[1].trim();
         } else if (dates.length === 1) {
            this.endDateValue = dates[0].trim();
         }
      }
      this.dateRange = new DateRangeModel();

      const startRelativeDateValue = new RelativeDateValueModel();
      if (this.startDateValue) {
         if (this.isRelative && RelativeDatesService.IsRelativeDateValue(this.startDateValue)) {
            startRelativeDateValue.relativeDateOption = RelativeDatesService.getEnumValue(this.startDateValue);
            startRelativeDateValue.dateValue = RelativeDatesService.getRelativeDate(
               startRelativeDateValue.relativeDateOption
            );
         } else {
            this.dateRange.startDate = new Date(this.startDateValue);
            startRelativeDateValue.dateValue = this.dateRange.startDate;
         }
      }

      const endRelativeDateValue = new RelativeDateValueModel();
      if (this.endDateValue) {
         if (this.isRelative && RelativeDatesService.IsRelativeDateValue(this.endDateValue)) {
            endRelativeDateValue.relativeDateOption = RelativeDatesService.getEnumValue(this.endDateValue);
            endRelativeDateValue.dateValue = RelativeDatesService.getRelativeDate(
               endRelativeDateValue.relativeDateOption
            );
         } else {
            this.dateRange.endDate = new Date(this.endDateValue);
            endRelativeDateValue.dateValue = this.dateRange.endDate;
         }
      }

      this.startRelativeDateValue = startRelativeDateValue;
      this.endRelativeDateValue = endRelativeDateValue;

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameterStatus(updatedValue);
         });
   }

   private updateReportParameterStatus(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.parameter.ReportID,
         updatedValue,
         this.parameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
