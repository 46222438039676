<lcs-loading-overlay
   [show]="itemSetIsLoading"
   [configuration]="loadingOverlayConfiguration"
   class="search-loading-overlay"
>
   <div
      class="selector-overlay-panel"
      *ngIf="$any(itemSet?.items?.length) > 0 || itemSetIsLoading || errorMessage || additionalMessage"
      [class.item-set-loading]="itemSetIsLoading"
   >
      <ng-container *ngIf="(!itemSetIsLoading || itemSet?.items?.length) && !errorMessage">
         <ng-container *ngFor="let item of itemSet?.items; let i = index">
            <label
               *ngIf="item.isItemGroupWithLabel"
               class="selector-overlay-panel-section-label"
            >{{
               item.label
               }}</label>
            <div
               *ngIf="!item.isItemGroupWithLabel"
               title="{{item.tooltip}}"
               class="selector-overlay-item"
            >
               <div
                  #commandLaunchItemElementRef
                  (mousedown)="onItemSelected($event, item)"
                  (mouseenter)="onItemHoverIn($event, item, i)"
                  (mouseleave)="onItemHoverOut($event, item, i)"
                  class="selector-overlay-item-display-wrap"
                  [class.selector-overlay-item-selected]="i == selectedItemIndex"
               >
                  <div
                     lcsOverlayPanelItem
                     *ngIf="!selectorOptionTemplate"
                  >
                     <span lcsOverlayPanelItemLabel>{{ item.displayValue }}</span>
                     <span
                        lcsOverlayPanelItemAdditionalInfoLabel
                        *ngIf="item?.additionalInfoValue"
                     >{{
                        item.additionalInfoValue
                        }}</span>
                  </div>
                  <ng-container *ngTemplateOutlet="selectorOptionTemplate; context: { $implicit: item }"></ng-container>
               </div>
               <i
                  *ngFor="let action of item.additionalActions"
                  class="material-icons text-center"
                  (click)="action.click(item)"
               >{{ action.icon }}</i>
            </div>
            <mat-divider *ngIf="item.hasDivider"></mat-divider>
         </ng-container>
      </ng-container>
      <div
         *ngIf="errorMessage"
         class="selector-overlay-error-message "
      >
         <div class="selector-overlay-item-display selector-overlay-additional-message-display">
            {{ errorMessage }}
         </div>
      </div>
      <div
         *ngIf="additionalMessage && !errorMessage"
         class="selector-overlay-error-message "
      >
         <div class="selector-overlay-item-display selector-overlay-additional-message-display">
            {{ additionalMessage }}
         </div>
      </div>
   </div>
</lcs-loading-overlay>