import { LogicalOperators } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/logical-operators.enum";

import { FilterOptionTransferModel } from "./filter-option-transfer.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class FilterExpressionTransferModel {
   FilterOptions: Array<FilterOptionTransferModel>;
   LogicalOperator: LogicalOperators;
   SubExpressions: Array<FilterExpressionTransferModel>;
}
