import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IconModule } from "@lcs/icon/icon.module";
import { SelectorOverlayPanelModule } from "@lcs/selector-overlay-panel/selector-overlay-panel.module";

import { SelectComponent } from "./components/select.component";
import { EndpointSelectorDirective } from "./directives/endpoint-selector.directive";
import { LegacySingleSelectorDirective } from "./directives/legacy-single-selector.directive";
import { StaticSetSelectorDirective } from "./directives/static-set-selector.directive";

@NgModule({
   imports: [CommonModule, FormsModule, SelectorOverlayPanelModule, IconModule],
   declarations: [
      SelectComponent,
      EndpointSelectorDirective,
      LegacySingleSelectorDirective,
      StaticSetSelectorDirective,
   ],
   exports: [SelectComponent, EndpointSelectorDirective, LegacySingleSelectorDirective, StaticSetSelectorDirective],
})
export class SelectModule {}
