import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";

import { ControlContainerViewProvider } from "../control-container-view-providers";
import { ValidationHelper } from "../validation/validation-helper";
import { isNumberRangeModel, NumberRangeModel } from "./number-range.model";

@Component({
   selector: "lcs-number-range-input",
   templateUrl: "number-range-input.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class NumberRangeInputComponent extends ValueAccessorBase<NumberRangeModel> implements OnInit {
   @Input() placeholder: string = "";

   @Input() displayName: string;

   @Input() controlType: ExpressLayoutControlTypes;

   @Input() minValidation: ValidationModel;

   @Input() maxValidation: ValidationModel;

   @Input() customValidatorData: ValidationModel;

   @Input() standalone: boolean;

   @Output() valueChange = new EventEmitter<string>();

   controlTypes = ExpressLayoutControlTypes;
   minRange: number;
   maxRange: number;
   minName: string;
   maxName: string;
   private existingMinValidation;
   private existingMaxValidation;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
      this.registerOnValueWritten((value: NumberRangeModel) => {
         if (value && isNumberRangeModel(value)) {
            this.innerValue = value;
         } else {
            this.innerValue = new NumberRangeModel();
         }
      });
   }
   ngOnInit() {
      if (!this.value) {
         this.innerValue = new NumberRangeModel();
      }
      this.minName = `${this.name}Min`;
      this.maxName = `${this.name}Max`;
      this.setupValidation();
      return super.ngOnInit();
   }

   setupValidation() {
      if (!this.minValidation) {
         const minValidation = new ValidationModel();
         minValidation.dataType = ExpressDataTypes.Numeric;
         minValidation.customValidator = this.defaultMinCustomValidation.bind(this);
         setTimeout(() => (this.minValidation = minValidation));
      } else {
         this.existingMinValidation = this.minValidation.customValidator;
         this.minValidation.customValidator = this.defaultMinCustomValidation.bind(this);
      }
      if (!this.maxValidation) {
         const maxValidation = new ValidationModel();
         maxValidation.dataType = ExpressDataTypes.Numeric;
         maxValidation.customValidator = this.defaultMaxCustomValidation.bind(this);
         setTimeout(() => (this.maxValidation = maxValidation));
      } else {
         this.existingMaxValidation = this.maxValidation.customValidator;
         this.maxValidation.customValidator = this.defaultMaxCustomValidation.bind(this);
      }
   }

   onMinimumChange(value: number) {
      this.value = Object.assign({}, this.innerValue, { min: value } as NumberRangeModel);
   }
   onMaximumChange(value: number) {
      this.value = Object.assign({}, this.innerValue, { max: value } as NumberRangeModel);
   }

   private defaultMaxCustomValidation(control: AbstractControl, customValidatorData: any) {
      const min = this.innerValue ? this.innerValue.min : null;
      const max = control.value;
      let errors;
      if (min && max && min > max) {
         errors = { numberRangeMax: true };
      }

      if (this.existingMaxValidation) {
         const otherErrors = this.existingMaxValidation(control, customValidatorData);
         const allErrors = ValidationHelper.mergeErrorMessages([errors, otherErrors]);
         return allErrors;
      } else {
         return errors;
      }
   }

   private defaultMinCustomValidation(control: AbstractControl, customValidatorData: any) {
      const min = control.value;
      const max = this.innerValue ? this.innerValue.max : null;
      let errors = null;
      if (min && max && min > max) {
         // @ts-ignore ts-migrate(2322) FIXME: Type '{ numberRangeMin: boolean; }' is not assigna... Remove this comment to see the full error message
         errors = { numberRangeMin: true };
      }

      if (this.existingMinValidation) {
         const otherErrors = this.existingMinValidation(control, customValidatorData);
         return ValidationHelper.mergeErrorMessages([errors, otherErrors]);
      } else {
         return errors;
      }
   }
}
