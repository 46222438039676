<owa-layout [headerTitle]="'Service Issues'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>

   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="issueFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>

      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{ results | async }} of {{ totalResults | async }} Service Issues
      </div>

      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(observableIssues | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>

               <ng-container
                  lcsColumnDef="ServiceManagerIssueID"
                  [width]="10"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Issue#</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="numeric has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{
                        row.ServiceManagerIssueID
                        }}</a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="AssignedOpenDate"
                  [width]="8"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Open Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{
                        row.AssignedOpenDate | date: "MM/dd/yyyy"
                        }}</a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="IsClosed"
                  [width]="6"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Closed</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="boolean has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >
                        <div *ngIf="row.IsClosed">
                           <i class="material-icons">check</i>
                        </div>
                     </a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="StatusName"
                  [width]="8"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Status</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{ row.StatusName }}</a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Title"
                  [width]="15"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef="let row"
                  >Title</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{ row.Title }}</a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Description"
                  [width]="20"
                  [priority]="6"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef="let row"
                  >Description</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{ row.Description }}</a>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="PropertyName"
                  [width]="10"
                  [priority]="7"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef="let row"
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        [routerLink]="[row.ServiceManagerIssueID]"
                        class="drilldown-link"
                     >{{ row.PropertyName }}</a>
                  </td>
               </ng-container>
            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any((observableIssues | async))?.length < $any((totalResults | async))"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>