import { Component, Input } from "@angular/core";
import { BillDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-detail-view.model";

@Component({
   selector: "owa-billdetail-list",
   templateUrl: "./billdetails-list.component.html",
})
export class BillDetailsListComponent {
   @Input() totalCount: number; // TODO: use this

   @Input() billDetailItems: Array<BillDetailViewModel>;

   billDetailColumns: string[] = [
      "Property",
      "Unit",
      "Is1099",
      "ExpenseAccount",
      "Comment",
      "Amount",
      "AmountAllocated",
   ];
}
