import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { EntitySelectorDirective } from "./entity-selector.directive";

@NgModule({
   imports: [CommonModule, FormsModule],
   declarations: [EntitySelectorDirective],
   exports: [EntitySelectorDirective],
})
export class EntitySelectorModule {}
