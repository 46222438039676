<lcs-dynamic-selector-report-parameter
   [name]="name + '-labelType'"
   [parameter]="labelTypeSelectionReportParameter"
   [hasAsk]="hasAsk"
></lcs-dynamic-selector-report-parameter>
<lcs-numeric-input-report-parameter
   [name]="name + '-label-across'"
   [validation]="labelAcrossValidation"
   [parameter]="startingLabelReportParameter"
   [hasAsk]="hasAsk"
></lcs-numeric-input-report-parameter>
<lcs-numeric-input-report-parameter
   [name]="name + '-label-down'"
   [validation]="labelDownValidation"
   [parameter]="downOnStartingLabelReportParameter"
   [hasAsk]="hasAsk"
></lcs-numeric-input-report-parameter>
<lcs-dynamic-selector-report-parameter
   *ngIf="allFontValuesLoaded"
   [name]="name + '-font-value'"
   [hasAsk]="hasAsk"
   [parameter]="fontNameReportParameter"
></lcs-dynamic-selector-report-parameter>
<lcs-dynamic-selector-report-parameter
   [name]="name + '-font-size'"
   [hasAsk]="hasAsk"
   [parameter]="fontSizeReportParameter"
></lcs-dynamic-selector-report-parameter>
<div class="starting-label-container">
   <div class="flex-half">
      <lcs-checkbox-report-parameter
         style="align-self: flex-end;"
         [name]="name + '-bold'"
         [displayName]="'Bold'"
         [hasAsk]="hasAsk"
         [parameter]="fontBoldReportParameter"
      ></lcs-checkbox-report-parameter>
   </div>
   <div class="flex-half">
      <lcs-checkbox-report-parameter
         style="align-self: flex-end;"
         [name]="name + '-italic'"
         [displayName]="'Italic'"
         [hasAsk]="hasAsk"
         [parameter]="fontItalReportParameter"
      ></lcs-checkbox-report-parameter>
   </div>
</div>