<div class="selector-container">
   <div class="report-parameter-label">
      <label
         class="parameter-label"
         [class.disabled]="hasAsk === true && parameter.IsAsk === true"
      >
         {{ propertySingleLabel }}
      </label>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="parameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
   <lcs-select
      owaEntitySelector
      [name]="name + '-property-selector'"
      [ngModel]="property"
      (ngModelChange)="propertyChanged($event)"
      [disabled]="hasAsk === true && parameter.IsAsk === true"
      [entityType]="entityType.Property"
      [fields]="propertyFields"
      [filters]="propertyFilters"
      [valueComparer]="propertySelectorComparer"
      [valueSourcePath]="rootPath"
   >
   </lcs-select>
   <lcs-checkbox
      [disabled]="hasAsk === true && parameter.IsAsk === true"
      [name]="name + '-inactive-property-checkbox'"
      [ngModel]="returnInactiveProperties"
      (ngModelChange)="inactivePropertiesChanged($event)"
   >Inactive {{propertyCollectionLabel}}</lcs-checkbox>
   <div class="report-parameter-label">
      <label
         class="parameter-label"
         [class.disabled]="hasAsk === true && parameter.IsAsk === true"
      >
         Select {{ unitCollectionLabel }}
      </label>
   </div>
   <lcs-entity-multi-selector
      [name]="name + '-unit-selector'"
      [disabled]="hasAsk === true && parameter.IsAsk === true"
      [entityType]="entityType.Unit"
      [filters]="unitFilters"
      [hideLabel]="true"
      [(ngModel)]="selectedUnits"
      (ngModelChange)="unitIDsChanged($event)"
   ></lcs-entity-multi-selector>
</div>