import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { InfiniteScrollModule } from "@lcs/infinite-scroll/infinite-scroll.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { PdfViewerModule } from "@lcs/pdf-viewer/pdf-viewer.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";

import { LayoutModule } from "../layout/layout.module";
import { OWATableModule } from "../owa-table/owa-table.module";
import { TileModule } from "../tile/tile.module";
import { ManagementFeesDetailComponent } from "./details/management-fees-detail.component";
import { ManagementFeeDetailContainerComponent } from "./management-fees-detail-container.component";
import { ManagementFeeDetailListViewMoreComponent } from "./management-fees-detail-list/management-fees-detail-list-more.component";
import { ManagementFeeDetailListComponent } from "./management-fees-detail-list/management-fees-detail-list.component";

@NgModule({
   imports: [
      CommonModule,
      DataTableModule,
      FormRegistrationModule,
      FormsModule,
      InfiniteScrollModule,
      LayoutModule,
      LoadingOverlayModule,
      OWATableModule,
      RouterModule,
      LcsSlidePanelModule,
      TileModule,
      PdfViewerModule,
   ],
   declarations: [
      ManagementFeeDetailListComponent,
      ManagementFeeDetailListViewMoreComponent,
      ManagementFeesDetailComponent,
      ManagementFeeDetailContainerComponent,
   ],
   exports: [ManagementFeeDetailListComponent, ManagementFeesDetailComponent],
})
export class ManagementFeesDetailModule {}
