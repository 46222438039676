import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { AccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/account.embed-options";
import { LeaseRenewalSignableDocumentPacketEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease-renewal-signable-document-packet.embed-options";
import { LeaseRenewalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease-renewal.embed-options";
import { OwnerEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner.embed-options";
import { ProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect.embed-options";
import { SignableDocumentPacketEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/signable-document-packet.embed-options";
import { SignableDocumentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/signable-document.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { SignableDocumentPacketGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-file-get-options/signable-document-packet.file-get-options";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/account.model";
import { EditPublishedSignableDocumentsSaveModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/edit-published-signable-documents-save.model";
import { ExpressColumnDefinitionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-column-definition.model";
import { LeaseRenewalSignableDocumentPacketModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease-renewal-signable-document-packet.model";
import { LeaseRenewalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease-renewal.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { ProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect.model";
import { PublishSignableDocumentPacketModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/publish-signable-document-packet.model";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/signable-document-packet-summary.model";
import { SignableDocumentPacketModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/signable-document-packet.model";
import { SignableDocumentSelectionSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/signable-document-selection-summary.model";
import { SignableDocumentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/signable-document.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class SignableDocumentPacketsService {
   static readonly AccountResource = "Account";
   static readonly CompletePacketResource = "CompletePacket";
   static readonly CreateUserResource = "CreateUser";
   static readonly DownloadFileResource = "DownloadFile";
   static readonly DownloadPreviewDocumentResource = "DownloadPreviewDocument";
   static readonly EditPublishedSignableDocumentPacketResource = "EditPublishedSignableDocumentPacket";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetPacketPDFResource = "GetPacketPDF";
   static readonly LeaseRenewalsResource = "LeaseRenewals";
   static readonly LeaseRenewalSignableDocumentPacketResource = "LeaseRenewalSignableDocumentPacket";
   static readonly LeaseRenewalSignableDocumentPacketsResource = "LeaseRenewalSignableDocumentPackets";
   static readonly MergedPacketDocumentsDownloadUrlResource = "MergedPacketDocumentsDownloadUrl";
   static readonly OwnerResource = "Owner";
   static readonly ProspectResource = "Prospect";
   static readonly PublishResource = "Publish";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly SignableDocumentPacketColumnsResource = "SignableDocumentPacketColumns";
   static readonly SignableDocumentPacketsResource = "SignableDocumentPackets";
   static readonly SignableDocumentsResource = "SignableDocuments";
   static readonly SignableDocumentSelectionSummaryModelsByAccountIDResource =
      "SignableDocumentSelectionSummaryModelsByAccountID";
   static readonly TenantResource = "Tenant";
   static readonly VoidPacketResource = "VoidPacket";
   static readonly endpoint = "SignableDocumentPacketsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentPacketModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: SignableDocumentPacketModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentPacketModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<SignableDocumentPacketModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         SignableDocumentPacketsService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(SignableDocumentPacketsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAccountUrl(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Account",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAccount(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AccountModel> {
      return this.apiService.getSingle(this.getAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postCompletePacketUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/CompletePacket",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCompletePacket(id: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postCompletePacketUrl(id, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getDownloadFileUrl(packetKey?: string | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (packetKey !== undefined && packetKey !== null) {
         additionalParameters.push("packetKey=" + packetKey);
      }
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/DownloadFile",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDownloadFile(packetKey?: string | null, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getDownloadFileUrl(packetKey, additionalParameters));
   }

   getDownloadPreviewDocumentUrl(
      entityType: EntityType,
      accountID: number,
      documentID: number,
      fileToken?: string | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("entityType=" + entityType);
      additionalParameters.push("accountID=" + accountID);
      additionalParameters.push("documentID=" + documentID);
      if (fileToken !== undefined && fileToken !== null) {
         additionalParameters.push("fileToken=" + fileToken);
      }
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/DownloadPreviewDocument",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDownloadPreviewDocument(
      entityType: EntityType,
      accountID: number,
      documentID: number,
      fileToken?: string | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(
         this.getDownloadPreviewDocumentUrl(entityType, accountID, documentID, fileToken, additionalParameters)
      );
   }

   postEditPublishedSignableDocumentPacketUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/EditPublishedSignableDocumentPacket",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postEditPublishedSignableDocumentPacket(
      saveModel?: EditPublishedSignableDocumentsSaveModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postEditPublishedSignableDocumentPacketUrl(additionalParameters), saveModel);
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentPacketModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGetPacketPDFUrl(
      id: number,
      getOptions?: Array<string | SignableDocumentPacketGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/GetPacketPDF",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetPacketPDF(
      id: number,
      getOptions?: Array<string | SignableDocumentPacketGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getGetPacketPDFUrl(id, getOptions, additionalParameters));
   }

   getLeaseRenewalsUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/LeaseRenewals",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLeaseRenewals(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalModel> {
      return this.apiService.getSingle(
         this.getLeaseRenewalsUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLeaseRenewalSignableDocumentPacketUrl(
      id: number,
      embeds?: Array<string | LeaseRenewalSignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/LeaseRenewalSignableDocumentPacket",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLeaseRenewalSignableDocumentPacket(
      id: number,
      embeds?: Array<string | LeaseRenewalSignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalSignableDocumentPacketModel> {
      return this.apiService.getSingle(
         this.getLeaseRenewalSignableDocumentPacketUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLeaseRenewalSignableDocumentPacketsUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalSignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/LeaseRenewalSignableDocumentPackets",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLeaseRenewalSignableDocumentPackets(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseRenewalSignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseRenewalSignableDocumentPacketModel> {
      return this.apiService.getSingle(
         this.getLeaseRenewalSignableDocumentPacketsUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getMergedPacketDocumentsDownloadUrlUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/MergedPacketDocumentsDownloadUrl",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMergedPacketDocumentsDownloadUrl(id: number, additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getMergedPacketDocumentsDownloadUrlUrl(id, additionalParameters));
   }

   getOwnerUrl(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Owner",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwner(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.getSingle(this.getOwnerUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getProspectUrl(
      id: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Prospect",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspect(
      id: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(this.getProspectUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postPublishUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/Publish",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPublish(
      item?: PublishSignableDocumentPacketModel | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentPacketModel> {
      return this.apiService
         .post(this.postPublishUrl(additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentPacketModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getSignableDocumentPacketColumnsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocumentPacketColumns",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSignableDocumentPacketColumnsCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<ExpressColumnDefinitionModel>> {
      return this.apiService.getCollection(this.getSignableDocumentPacketColumnsCollectionUrl(additionalParameters));
   }

   getSignableDocumentPacketsCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocumentPackets",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         null,
         null,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSignableDocumentPacketsCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | SignableDocumentPacketEmbedOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentPacketSummaryModel>> {
      return this.apiService.getCollection(
         this.getSignableDocumentPacketsCollectionUrl(
            filterExpression,
            embeds,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSignableDocumentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocuments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSignableDocuments(
      id: number,
      itemID: number,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentModel> {
      return this.apiService.getSingle(
         this.getSignableDocumentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getSignableDocumentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocuments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSignableDocumentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentModel>> {
      return this.apiService.getCollection(
         this.getSignableDocumentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSignableDocumentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocuments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSignableDocuments(
      id: number,
      item?: SignableDocumentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SignableDocumentModel> {
      return this.apiService.postSingle(
         this.postSignableDocumentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postSignableDocumentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocuments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSignableDocumentsCollection(
      id: number,
      items: Array<SignableDocumentModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignableDocumentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentModel>> {
      return this.apiService.postCollection(
         this.postSignableDocumentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteSignableDocumentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocuments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteSignableDocuments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteSignableDocumentsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getSignableDocumentSelectionSummaryModelsByAccountIDCollectionUrl(
      entityType: EntityType,
      accountID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("entityType=" + entityType);
      additionalParameters.push("accountID=" + accountID);
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/SignableDocumentSelectionSummaryModelsByAccountID",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSignableDocumentSelectionSummaryModelsByAccountIDCollection(
      entityType: EntityType,
      accountID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<SignableDocumentSelectionSummaryModel>> {
      return this.apiService.getCollection(
         this.getSignableDocumentSelectionSummaryModelsByAccountIDCollectionUrl(
            entityType,
            accountID,
            additionalParameters
         )
      );
   }

   getTenantUrl(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SignableDocumentPacketsService.endpoint + "/Tenant",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenant(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(this.getTenantUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postVoidPacketUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SignableDocumentPacketsService.endpoint + "/VoidPacket",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postVoidPacket(id: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postVoidPacketUrl(id, additionalParameters));
   }
}
