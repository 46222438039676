import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { SelectorBehavior } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/selector-behavior.enum";
import { Observable, of } from "rxjs";

import { EntityViewInformationServiceBase } from "./entity-view-information-service.base";
import { EntityViewInformationModel } from "./entity-view-information.model";

@Injectable()
export class EntityViewInformationService implements EntityViewInformationServiceBase {
   constructor() {}

   getViewInformation(entityType: EntityType): Observable<EntityViewInformationModel> {
      let result: EntityViewInformationModel;
      switch (entityType) {
         case EntityType.Tenant:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Tenant";
            result.CollectionLabel = "Tenants";
            break;
         case EntityType.Prospect:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Prospect";
            result.CollectionLabel = "Prospects";
            break;
         case EntityType.Property:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Property";
            result.CollectionLabel = "Properties";
            break;
         case EntityType.Owner:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Owner";
            result.CollectionLabel = "Owners";
            break;
         case EntityType.Vendor:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Vendor";
            result.CollectionLabel = "Vendors";
            result.EntityPrimaryKeyPropertyPath = "VendorID";
            result.EntityListItemTemplate = "Name";
            result.EntityDisplayTemplate = "Name";
            result.AdditionalInformationDisplayTemplate = "";
            break;
         case EntityType.ChartAccountsMappings:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Chart Account Mapping";
            result.CollectionLabel = "Chart Account Mappings";
            break;
         case EntityType.Bank:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Bank";
            result.CollectionLabel = "Banks";
            result.EntityType = EntityType.Bank;
            result.EntityPrimaryKeyPropertyPath = "BankID";
            result.EntityListItemTemplate = "{Reference} - {Name}";
            result.EntityDisplayTemplate = "Chart Account: {Reference}";
            result.AdditionalInformationDisplayTemplate = "";
            result.DefaultSelectorBehavior = SelectorBehavior.All;
            break;
         case EntityType.CreditCard:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Credit Card";
            result.CollectionLabel = "Credit Cards";
            result.EntityType = EntityType.CreditCard;
            result.EntityPrimaryKeyPropertyPath = "CreditCardID";
            result.EntityListItemTemplate = "{Reference} - {Name}";
            result.EntityDisplayTemplate = "Chart Account: {Reference}";
            result.AdditionalInformationDisplayTemplate = "";
            result.DefaultSelectorBehavior = SelectorBehavior.All;
            break;
         case EntityType.Amenities:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Amenity";
            result.CollectionLabel = "Amenities";
            break;
         case EntityType.ChargeTypes:
            result = new EntityViewInformationModel();
            result.SingleLabel = "Charge Type";
            result.CollectionLabel = "Charge Types";
            break;
         case EntityType.GLAccount:
            result = new EntityViewInformationModel();
            result.SingleLabel = "GL Account";
            result.CollectionLabel = "GL Accounts";
            break;
         default:
            throw new Error(`No view information defined for ${EntityType[entityType]}`);
      }
      return of(result);
   }
}
