import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { KEY_S } from "keycode-js";
import { BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil, throttleTime } from "rxjs";

import { OverallSubComponentStatuses } from "./overall-sub-component-statuses.enum";
import { SubComponentStatuses } from "./sub-component-statuses.enum";

@Injectable({
   providedIn: "root",
})
export class SubComponentStatusService implements OnDestroy {
   public overallStatus: BehaviorSubject<OverallSubComponentStatuses>;

   private registeredSubComponents = new Map<string, SubComponentStatuses>();

   private registeredCriticalSubComponents = new Map<string, SubComponentStatuses>();

   private registeredSubComponentNames = new Map<string, string>();

   private registeredSubComponentAdditionalInformation = new Map<string, string>();

   private lastSubComponentID: number = 0;

   private checkOverallStatusTrigger = new Subject<void>();

   private unsubscribe = new Subject<void>();

   private logSubComponentStatusesToConsole = false;

   constructor(private ngZone: NgZone) {
      this.overallStatus = new BehaviorSubject<OverallSubComponentStatuses>(OverallSubComponentStatuses.Initializing);

      this.checkOverallStatusTrigger
         .pipe(takeUntil(this.unsubscribe))
         .pipe(debounceTime(100))
         .subscribe(() => {
            this.checkOverallStatus();
         });

      this.ngZone.runOutsideAngular(() => {
         fromEvent(window, "keydown")
            .pipe(throttleTime(100), takeUntil(this.unsubscribe))
            .subscribe((event) => {
               const keyboardEvent: KeyboardEvent = event as KeyboardEvent;
               if (keyboardEvent.keyCode === KEY_S && keyboardEvent.ctrlKey && keyboardEvent.altKey) {
                  this.ngZone.run(() => {
                     this.logSubComponentStatusesToConsole = !this.logSubComponentStatusesToConsole;
                     console.log(
                        `SubComponent Status Console Logging Toggled: ${this.logSubComponentStatusesToConsole}`
                     );
                     this.logSubComponentStatuses();
                  });
               }
            });
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   public registerSubComponent(name: string, isCritical: boolean = false): string {
      this.lastSubComponentID++;
      const subComponentID = this.lastSubComponentID.toString();
      if (isCritical) {
         this.registeredCriticalSubComponents.set(subComponentID, SubComponentStatuses.Initializing);
      } else {
         this.registeredSubComponents.set(subComponentID, SubComponentStatuses.Initializing);
      }
      this.registeredSubComponentNames.set(subComponentID, name);
      this.registeredSubComponentAdditionalInformation.set(subComponentID, "No Info Provided");
      this.checkOverallStatusTrigger.next();
      return subComponentID;
   }

   public updateSubComponentAdditionalInformation(subComponentID: string, additionalInformation: string): void {
      if (this.registeredSubComponentAdditionalInformation.has(subComponentID)) {
         this.registeredSubComponentAdditionalInformation.set(subComponentID, additionalInformation);
      }
   }

   public updateSubComponentStatus(subComponentID: string, subComponentStatus: SubComponentStatuses) {
      if (subComponentID != null) {
         if (this.registeredSubComponents.has(subComponentID)) {
            const currentStatus = this.registeredSubComponents.get(subComponentID);
            if (
               currentStatus === SubComponentStatuses.Initializing &&
               subComponentStatus === SubComponentStatuses.Loading
            ) {
               return;
            }
            this.registeredSubComponents.set(subComponentID, subComponentStatus);
         } else if (this.registeredCriticalSubComponents.has(subComponentID)) {
            this.registeredCriticalSubComponents.set(subComponentID, subComponentStatus);
         } else {
            return;
         }

         if (subComponentStatus === SubComponentStatuses.Initializing) {
            this.checkOverallStatus();
         } else {
            this.checkOverallStatusTrigger.next();
         }
      }
      return;
   }

   public deregisterSubComponent(subComponentID: string) {
      if (subComponentID != null) {
         if (this.registeredSubComponents.has(subComponentID)) {
            this.registeredSubComponents.delete(subComponentID);
         } else if (this.registeredCriticalSubComponents.has(subComponentID)) {
            this.registeredCriticalSubComponents.delete(subComponentID);
         }
         if (this.registeredSubComponentNames.has(subComponentID)) {
            this.registeredSubComponentNames.delete(subComponentID);
         }
         if (this.registeredSubComponentAdditionalInformation.has(subComponentID)) {
            this.registeredSubComponentAdditionalInformation.delete(subComponentID);
         }
      }
      this.checkOverallStatusTrigger.next();
   }

   private checkOverallStatus(): void {
      if (this.registeredSubComponents.size === 0 && this.registeredCriticalSubComponents.size === 0) {
         this.overallStatus.next(OverallSubComponentStatuses.NotSet);
         return;
      }
      let overallStatus = OverallSubComponentStatuses.Ready;

      for (const key of Array.from(this.registeredSubComponents.keys())) {
         const subComponentStatus = this.registeredSubComponents.get(key);
         if (subComponentStatus === SubComponentStatuses.Errored) {
            overallStatus = OverallSubComponentStatuses.Errored;
            break;
         }
         if (subComponentStatus === SubComponentStatuses.NotSet) {
            overallStatus = OverallSubComponentStatuses.NotSet;
            break;
         }
         if (subComponentStatus === SubComponentStatuses.Initializing) {
            overallStatus = OverallSubComponentStatuses.Initializing;
            break;
         }
         if (
            subComponentStatus === SubComponentStatuses.Loading ||
            subComponentStatus === SubComponentStatuses.Saving
         ) {
            overallStatus = OverallSubComponentStatuses.Busy;
         }
      }

      if (this.registeredCriticalSubComponents.size > 0) {
         let criticalStatus = OverallSubComponentStatuses.Ready;
         for (const key of Array.from(this.registeredCriticalSubComponents.keys())) {
            const criticalSubComponentStatus = this.registeredCriticalSubComponents.get(key);
            if (criticalSubComponentStatus === SubComponentStatuses.Errored) {
               criticalStatus = OverallSubComponentStatuses.Errored;
               break;
            }
            if (criticalSubComponentStatus === SubComponentStatuses.NotSet) {
               criticalStatus = OverallSubComponentStatuses.NotSet;
               break;
            }
            if (criticalSubComponentStatus === SubComponentStatuses.Initializing) {
               criticalStatus = OverallSubComponentStatuses.Initializing;
               break;
            }
            if (
               criticalSubComponentStatus === SubComponentStatuses.Loading ||
               criticalSubComponentStatus === SubComponentStatuses.Saving
            ) {
               criticalStatus = OverallSubComponentStatuses.Busy;
            }
         }
         overallStatus = criticalStatus;
      }
      this.overallStatus.next(overallStatus);

      if (this.logSubComponentStatusesToConsole) {
         this.logSubComponentStatuses();
      }
   }

   private logSubComponentStatuses(): void {
      if (!this.registeredSubComponentNames) {
         return;
      }
      let logMessage = `Overall Status: ${OverallSubComponentStatuses[this.overallStatus.value]}`;
      logMessage += "\r\nSubComponent Statuses:";

      this.registeredSubComponentNames.forEach((name, id) => {
         const additionalInfo: string | undefined = this.registeredSubComponentAdditionalInformation.get(id);
         const standardComponent = this.registeredSubComponents.get(id);
         if (standardComponent) {
            logMessage += `\r\n${name} - ${SubComponentStatuses[standardComponent]} - ${additionalInfo}`;
            console.log();
         }
         const criticalComponent = this.registeredCriticalSubComponents.get(id);
         if (criticalComponent) {
            logMessage += `\r\nCritical: ${name} - ${SubComponentStatuses[criticalComponent]} - ${additionalInfo}`;
         }
      });
      console.log(logMessage);
   }
}
