import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoadingOverlayConfigurationModel } from "@lcs/loading-overlay/loading-overlay-configuration.model";

import { LcsProgressButtonStatus } from "./progress-button-status.enum";

@Component({
   selector: "lcs-progress-button",
   templateUrl: "./progress-button.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   host: {
      class: "lcs-progress-button",
   },
})
export class LcsProgressButtonComponent implements OnInit {
   @Input() loadingOverlayConfiguration: LoadingOverlayConfigurationModel;

   @Input() set status(status: LcsProgressButtonStatus) {
      this._status = status;
      this.checkStatus();
   }
   get status() {
      return this._status;
   }

   @Input() disabled: boolean;

   @Input() formId: string;

   @Input() type: string = "submit";

   @Input() raised: boolean;

   @Input() multi: boolean;

   @Input() multiSelectActionButton: boolean;

   @Input() buttonCustomStyle = {};

   @Input() buttonCustomClasses: Array<string>;

   @Output() buttonClick = new EventEmitter<boolean>();

   @Output() statusChange = new EventEmitter<LcsProgressButtonStatus>();

   progressButtonStatus = LcsProgressButtonStatus;

   static readonly StatusChangeDelay = 3000;

   private _status: LcsProgressButtonStatus;

   private disableButtonClick: boolean = false;

   constructor(private changeDetectorRef: ChangeDetectorRef) {}

   ngOnInit() {
      if (!this.loadingOverlayConfiguration) {
         this.loadingOverlayConfiguration = new LoadingOverlayConfigurationModel();
         this.loadingOverlayConfiguration.HasOverlay = false;
         this.loadingOverlayConfiguration.Diameter = 35;
         this.loadingOverlayConfiguration.DarkTheme = true;
      } else {
         if (this.loadingOverlayConfiguration.HasOverlay === undefined) {
            this.loadingOverlayConfiguration.HasOverlay = false;
         }
         if (this.loadingOverlayConfiguration.Diameter === undefined) {
            this.loadingOverlayConfiguration.Diameter = 35;
         }
         if (this.loadingOverlayConfiguration.DarkTheme === undefined) {
            this.loadingOverlayConfiguration.DarkTheme = true;
         }
      }
   }

   checkStatus() {
      if (
         this._status != null &&
         this._status !== LcsProgressButtonStatus.Unset &&
         this._status !== LcsProgressButtonStatus.InProgress
      ) {
         this.disableButtonClick = true;
         setTimeout(() => {
            this._status = LcsProgressButtonStatus.Unset;
            this.statusChange.emit(this._status);
            this.disableButtonClick = false;
            this.changeDetectorRef.markForCheck();
         }, LcsProgressButtonComponent.StatusChangeDelay);
      }
   }

   onButtonClick(): void {
      if (!this.disabled && !this.disableButtonClick) {
         this.buttonClick.emit();
      }
   }
}
