import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideMenuComponent } from "./side-menu.component";
import { MenuService } from "../top-bar/menu/menu.service";
import { RouterModule } from "@angular/router";
import { SideMenuService } from "./side-menu.service";
import { SidebarMenuModule } from "@lcs/sidebar-menu/sidebar-menu.module";

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      SidebarMenuModule,
   ],
   declarations: [
      SideMenuComponent,
   ],
   exports: [
      SideMenuComponent,
   ],
   providers: [
      MenuService,
      SideMenuService,
   ],
})
export class SideMenuModule { }
