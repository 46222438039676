import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class PropertyGroupDetailFields extends EntityField {
   static readonly EntityKeyID: PropertyGroupDetailFields = new PropertyGroupDetailFields("EntityKeyID");

   static readonly GroupDetailsID: PropertyGroupDetailFields = new PropertyGroupDetailFields("GroupDetailsID");

   static readonly GroupID: PropertyGroupDetailFields = new PropertyGroupDetailFields("GroupID");

   static readonly Groups: PropertyGroupDetailFields = new PropertyGroupDetailFields("Groups");

   static readonly MetaTag: PropertyGroupDetailFields = new PropertyGroupDetailFields("MetaTag");

   static readonly Property: PropertyGroupDetailFields = new PropertyGroupDetailFields("Property");

   static readonly PropertyID: PropertyGroupDetailFields = new PropertyGroupDetailFields("PropertyID");
}
