<lcs-loading-overlay [show]="isLoading">

   <ng-container *ngIf="!isReadOnly else readOnlyField">
      <div class="encrypted-text-container">
         <input
            #textBox
            type="text"
            title="{{ tooltipOverride ? tooltipOverride : '' }}"
            autocomplete="off"
            [attr.id]="path"
            [(ngModel)]="displayValue"
            (ngModelChange)="onModelChange($event)"
            [placeholder]="placeholder"
            [disabled]="disabled || isValueHidden"
            (blur)="onBlur($event)"
            (focus)="onFocus($event)"
            (keyup.enter)="onEnterKey($event)"
         />
         <ng-container *ngTemplateOutlet="VisibilityButton"></ng-container>
      </div>
      <lcs-validation-overlay-panel
         [modelRef]="ngControl"
         [parentElement]="textBox"
         [displayName]="displayName"
         [name]="name"
      ></lcs-validation-overlay-panel>
      <i class="material-icons validation-error-icon">error_outline</i>
   </ng-container>

   <ng-template #readOnlyField>
      <div class="encrypted-text-container">
         <div class="read-only-field">
            {{ displayValue }}
         </div>
         <ng-container *ngTemplateOutlet="VisibilityButton"></ng-container>
      </div>
   </ng-template>

</lcs-loading-overlay>

<ng-template #VisibilityButton>
   <ng-container *ngIf="!hideEyeIcon">
      <span
         class="visibility-icon"
         (mousedown)="onVisibilityChanged()"
      >
         <i class="material-icons">{{ isValueHidden ? "visibility" : "visibility_off" }}</i>
      </span>
   </ng-container>
</ng-template>