import { Pipe, PipeTransform } from "@angular/core";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";

import { ObjectMapResolverService } from "./object-map-resolver.service";

@Pipe({
   name: "objectDisplayValue",
   pure: false,
})
export class ObjectDisplayValuePipe implements PipeTransform {
   constructor(private objectMapResolverService: ObjectMapResolverService) {}

   transform(
      obj: any,
      path: string,
      dataType?: ExpressDataTypes,
      enumerationType?: string,
      minFractionDigits?: number,
      maxFractionDigits?: number,
      isUTCDateTime?: boolean,
      conditionalTemplate?: string,
      conditionalPropertyPath?: string
   ): any {
      return this.objectMapResolverService.getDisplayValue(
         obj,
         path,
         dataType,
         enumerationType,
         minFractionDigits,
         maxFractionDigits,
         isUTCDateTime,
         conditionalTemplate,
         conditionalPropertyPath
      );
   }
}
