<lcs-select
   lcsLegacySingleSelector
   [valueSource]="valueSource"
   [(ngModel)]="value"
   [path]="path"
   [ngModelOptions]="{ standalone: true }"
   [alignOverlayPanelToRight]="true"
   [openIcon]="'fi-filter'"
   [closedIcon]="'fi-filter'"
   [allowMobileFocusMode]="false"
>
</lcs-select>