<div class="resend-verification-container">
   <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 270.04 38.37"
   >
      <defs>
         <style>
            .a {
               fill: #464c53;
            }

            .b {
               fill: #b5b5b5;
            }
         </style>
      </defs>
      <g transform="translate(-821 -236)">
         <g transform="translate(862.256 245.942)">
            <path
               class="a"
               d="M31.9,7.7h4.909l2.325,16.277h0L41.589,7.7h5.942l2.454,16.277h0L52.311,7.7h4.651l-4,21.186H46.885L44.3,12.609h0L41.976,28.886h-6.2Z"
               transform="translate(-31.9 -7.7)"
            />
            <path
               class="a"
               d="M52.9,7.7h6.072l6.072,14.469h0L64.914,7.7h4.392V28.886H63.364l-6.2-14.469h0l.129,14.469H52.9V7.7Z"
               transform="translate(-25.771 -7.7)"
            />
            <path
               class="a"
               d="M68.2,7.7H80.86v4H72.98V16.1h7.363v4H72.98v4.78h8.139v4H68.2V7.7Z"
               transform="translate(-21.306 -7.7)"
            />
            <path
               class="a"
               d="M80.3,7.7h8.526a11.377,11.377,0,0,1,2.2.258,5.069,5.069,0,0,1,1.809,1.033,5.8,5.8,0,0,1,1.292,1.679A6.258,6.258,0,0,1,94.51,13a5.847,5.847,0,0,1-.9,3.488,3.849,3.849,0,0,1-2.584,1.809h0a2.082,2.082,0,0,1,.9.517,7.212,7.212,0,0,1,.646,1.033c.129.388.388.9.517,1.421s.388,1.163.517,1.809l1.679,5.813H90.376l-1.033-3.876c-.258-.9-.517-1.679-.646-2.2a7.52,7.52,0,0,0-.646-1.421,1.737,1.737,0,0,0-.9-.775,2.58,2.58,0,0,0-1.163-.258h-.9v8.4H80.3Zm4.78,8.784h2.455a3.772,3.772,0,0,0,1.163-.258,1.165,1.165,0,0,0,.775-.775,2.32,2.32,0,0,0,.388-1.421,2.58,2.58,0,0,0-.258-1.163l-.775-.775a6.006,6.006,0,0,0-1.163-.388c-.388,0-.9-.129-1.292-.129H85.08v4.909Z"
               transform="translate(-17.775 -7.7)"
            />
         </g>
         <g transform="translate(940.929 245.684)">
            <path
               class="b"
               d="M92.8,7.7h2.842l4.263,18.215h0L103.781,7.7H107.4l3.875,18.215h0L115.536,7.7h2.713l-5.555,21.186h-3.359l-3.876-18.6h0l-3.875,18.6H98.1Z"
               transform="translate(-92.8 -7.442)"
            />
            <path
               class="b"
               d="M113.9,7.7h10.076v2.713h-7.493v6.2h6.847v2.713h-6.847V26.3h7.493v2.713H113.9Z"
               transform="translate(-86.642 -7.442)"
            />
            <path
               class="b"
               d="M124.229,7.7h4.78a15.9,15.9,0,0,1,2.584.258,4.5,4.5,0,0,1,1.938.9,4.2,4.2,0,0,1,1.292,1.679A6.886,6.886,0,0,1,135.339,13a5.21,5.21,0,0,1-1.033,3.359,5.738,5.738,0,0,1-2.713,1.679h0a3.664,3.664,0,0,1,1.679.517,5.059,5.059,0,0,1,1.292,1.033,4.467,4.467,0,0,1,.9,1.55,5.355,5.355,0,0,1,.258,2.067,6.217,6.217,0,0,1-.517,2.842,3.926,3.926,0,0,1-1.55,1.679,5.627,5.627,0,0,1-2.2.9,15.9,15.9,0,0,1-2.584.258H124.1V7.7Zm2.584,9.043h1.421a8.529,8.529,0,0,0,1.809-.129,4.58,4.58,0,0,0,1.421-.388,2.644,2.644,0,0,0,1.033-1.033,3.422,3.422,0,0,0,.388-1.809,4.817,4.817,0,0,0-.258-1.55,7.164,7.164,0,0,0-.775-1.033,3.867,3.867,0,0,0-1.033-.517,3.576,3.576,0,0,0-1.163-.129h-2.713v6.588Zm0,9.689h1.938a7.366,7.366,0,0,0,1.679-.129,4.628,4.628,0,0,0,1.421-.517,2.3,2.3,0,0,0,.9-1.033,3.232,3.232,0,0,0,.388-1.679,4.314,4.314,0,0,0-.388-1.938,3.05,3.05,0,0,0-1.033-1.163,4.359,4.359,0,0,0-1.55-.517,7.946,7.946,0,0,0-1.679-.129h-1.679Z"
               transform="translate(-83.666 -7.442)"
            />
            <path
               class="a"
               d="M141.118,7.7h5.813l6.847,21.186H148.61l-1.292-4.521H140.6l-1.421,4.521H134.4Zm2.971,4.392h0l-2.325,8.268h4.521Z"
               transform="translate(-80.66 -7.442)"
            />
            <path
               class="a"
               d="M165.531,28.557a10.941,10.941,0,0,1-2.2.517,18.552,18.552,0,0,1-3.1.258,11.67,11.67,0,0,1-3.617-.517,12.126,12.126,0,0,1-3.359-1.809,9.443,9.443,0,0,1-2.454-3.359,12.109,12.109,0,0,1-.9-5.038,14.5,14.5,0,0,1,.775-4.909,8.073,8.073,0,0,1,2.2-3.488,9.25,9.25,0,0,1,3.23-2.067,10.7,10.7,0,0,1,4.134-.646,19.274,19.274,0,0,1,2.713.258c.9.258,1.679.517,2.584.775l-.388,4.651a9.781,9.781,0,0,0-2.325-1.163,7.315,7.315,0,0,0-2.325-.388,4.424,4.424,0,0,0-2.325.517,6.283,6.283,0,0,0-1.809,1.421,5.953,5.953,0,0,0-1.163,2.2,9.834,9.834,0,0,0-.388,2.713,10.075,10.075,0,0,0,.388,2.842,4.341,4.341,0,0,0,1.292,2.067,5.11,5.11,0,0,0,1.938,1.292,7.554,7.554,0,0,0,2.454.517,14.235,14.235,0,0,0,2.325-.258c.775-.258,1.421-.517,2.067-.775Z"
               transform="translate(-76.136 -7.5)"
            />
            <path
               class="a"
               d="M178.231,28.557a10.941,10.941,0,0,1-2.2.517,18.552,18.552,0,0,1-3.1.258,11.67,11.67,0,0,1-3.617-.517,12.126,12.126,0,0,1-3.359-1.809,9.443,9.443,0,0,1-2.454-3.359,12.109,12.109,0,0,1-.9-5.038,14.5,14.5,0,0,1,.775-4.909,8.074,8.074,0,0,1,2.2-3.488,9.25,9.25,0,0,1,3.23-2.067,10.7,10.7,0,0,1,4.134-.646,19.273,19.273,0,0,1,2.713.258c.9.258,1.679.517,2.584.775l-.388,4.651a9.781,9.781,0,0,0-2.325-1.163,7.315,7.315,0,0,0-2.325-.388,4.424,4.424,0,0,0-2.325.517,6.283,6.283,0,0,0-1.809,1.421,5.953,5.953,0,0,0-1.163,2.2,9.834,9.834,0,0,0-.388,2.713,10.074,10.074,0,0,0,.388,2.842,4.341,4.341,0,0,0,1.292,2.067,5.11,5.11,0,0,0,1.938,1.292,7.554,7.554,0,0,0,2.454.517,14.235,14.235,0,0,0,2.325-.258c.775-.258,1.421-.517,2.067-.775Z"
               transform="translate(-72.43 -7.5)"
            />
            <path
               class="a"
               d="M175.8,7.7h12.66v4h-7.88V16.1h7.363v4H180.58v4.78h8.139v4H175.8Z"
               transform="translate(-68.578 -7.442)"
            />
            <path
               class="a"
               d="M187.646,23.777a8.671,8.671,0,0,0,2.2,1.033,9.18,9.18,0,0,0,2.713.388,3.576,3.576,0,0,0,1.163-.129,1.867,1.867,0,0,0,.9-.258,1.557,1.557,0,0,0,.646-.646,2.433,2.433,0,0,0,.258-1.033,2.139,2.139,0,0,0-.646-1.55,4.468,4.468,0,0,0-1.55-.9c-.646-.258-1.292-.517-2.067-.775a10.3,10.3,0,0,1-2.067-1.033,5.26,5.26,0,0,1-1.55-1.809,6.919,6.919,0,0,1-.646-3.1,6.293,6.293,0,0,1,.775-3.23,4.413,4.413,0,0,1,1.809-1.938,8.256,8.256,0,0,1,2.454-1.033,9.672,9.672,0,0,1,2.713-.258,14.308,14.308,0,0,1,2.454.258c.775.129,1.55.388,2.067.517l-.258,4.392a6.09,6.09,0,0,0-1.938-.775,10.041,10.041,0,0,0-2.067-.258h-1.033a2.434,2.434,0,0,0-1.033.258,1.323,1.323,0,0,0-.775.646,1.467,1.467,0,0,0-.388,1.033,1.59,1.59,0,0,0,.646,1.292,8.6,8.6,0,0,0,1.55.775,9.682,9.682,0,0,0,2.067.646,10.3,10.3,0,0,1,2.067,1.033,5.26,5.26,0,0,1,1.55,1.809,6.918,6.918,0,0,1,.646,3.1,7.739,7.739,0,0,1-.646,3.359,5.047,5.047,0,0,1-1.809,2.2,7.887,7.887,0,0,1-2.584,1.163,11.081,11.081,0,0,1-2.971.258,23.666,23.666,0,0,1-2.713-.258A15.11,15.11,0,0,1,187,28.3Z"
               transform="translate(-65.309 -7.5)"
            />
            <path
               class="a"
               d="M199.346,23.777a8.671,8.671,0,0,0,2.2,1.033,9.18,9.18,0,0,0,2.713.388,3.576,3.576,0,0,0,1.163-.129,1.867,1.867,0,0,0,.9-.258,1.557,1.557,0,0,0,.646-.646,2.433,2.433,0,0,0,.258-1.033,2.139,2.139,0,0,0-.646-1.55,4.467,4.467,0,0,0-1.55-.9c-.646-.258-1.292-.517-2.067-.775a10.3,10.3,0,0,1-2.067-1.033,5.26,5.26,0,0,1-1.55-1.809,6.918,6.918,0,0,1-.646-3.1,6.292,6.292,0,0,1,.775-3.23,4.413,4.413,0,0,1,1.809-1.938,8.255,8.255,0,0,1,2.454-1.033,9.672,9.672,0,0,1,2.713-.258,14.308,14.308,0,0,1,2.454.258c.775.129,1.55.388,2.067.517l-.258,4.392a6.09,6.09,0,0,0-1.938-.775,10.04,10.04,0,0,0-2.067-.258h-1.033a2.434,2.434,0,0,0-1.033.258,1.323,1.323,0,0,0-.775.646,1.467,1.467,0,0,0-.388,1.033,1.59,1.59,0,0,0,.646,1.292,8.6,8.6,0,0,0,1.55.775,9.681,9.681,0,0,0,2.067.646,10.3,10.3,0,0,1,2.067,1.033,5.259,5.259,0,0,1,1.55,1.809,6.918,6.918,0,0,1,.646,3.1,7.738,7.738,0,0,1-.646,3.359,5.047,5.047,0,0,1-1.809,2.2,7.887,7.887,0,0,1-2.584,1.163A11.081,11.081,0,0,1,204,29.2a23.666,23.666,0,0,1-2.713-.258A15.111,15.111,0,0,1,198.7,28.3Z"
               transform="translate(-61.895 -7.5)"
            />
         </g>
         <g transform="translate(821 236)">
            <path
               class="b"
               d="M21.448,28.09h5.813V19.435h3.617L18.089,6,5.3,19.564H8.659V28.09H14.73V19.435h6.718Z"
               transform="translate(1.593 1.746)"
            />
            <path
               class="a"
               d="M1.3,12.013c.388.388,9.689-14.081,25.449-4.263,14.21,8.784,4.651,25.708,5.038,25.966S50.136,14.984,30.758,2.583C16.548-6.59.658,11.625,1.3,12.013Z"
               transform="translate(0.422 -0.004)"
            />
            <path
               class="a"
               d="M33.34,25.832c-.388-.258-9.56,12.66-23.253,4.263C-2.186,22.474,7.245,7.359,6.857,7.1s-15.89,16.277.9,27C20.034,41.98,33.857,26.22,33.34,25.832Z"
               transform="translate(0.036 2.066)"
            />
         </g>
      </g>
   </svg>
   <div class="resend-verification-message-row">
      An account has already been created with this email address, but has not been verified.
   </div>
   <div class="resend-verification-message-row">
      An account finalization email has been sent from "donotreply&#64;rentmanager.com" with subject "Verify your Web
      Account".
   </div>
   <div class="resend-verification-message-row">
      Please follow the link in the email to verify your account. If you have any questions, contact your property
      manager.
   </div>
</div>