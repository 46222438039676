import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

@Component({
   selector: "lcs-validation-error-item",
   changeDetection: ChangeDetectionStrategy.OnPush,
   templateUrl: "validation-error-item.component.html",
})
export class ValidationErrorItemComponent implements OnInit {
   @Input() errors: any;

   @Input() displayName: string;

   ngOnInit() {
      if (!this.displayName) {
         this.displayName = "Value";
      }
   }
}
