import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { PipesModule } from "@lcs/pipes/pipes.module";
import { CalendarModule } from "primeng/calendar";

import { DatePickerComponent } from "./date-picker.component";

@NgModule({
   imports: [FormsModule, CalendarModule, ValidationModule, PipesModule],
   declarations: [DatePickerComponent],
   exports: [DatePickerComponent],
})
export class DatePickerModule {}
