<div class="report-parameter-label">
   <label
      [hidden]="hideLabel"
      class="parameter-label"
   > {{ accountLabel }}</label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<ng-container *ngIf="magnumReconcileParameter">
   <lcs-select
      lcsStaticSetSelector
      #bankSelector
      [name]="name+ '-bank-selector'"
      displayName="GLAccount.Name"
      [ngModelOptions]="{ standalone: true }"
      [items]="bankValues"
      [disabled]="disabled"
      [(ngModel)]="selectedAccountID"
      [lcsValidate]="validation"
      (ngModelChange)="onBankSelected()"
   >
   </lcs-select>
</ng-container>
<ng-container *ngIf="!magnumReconcileParameter">
   <lcs-select
      owaEntitySelector
      #bankSelector
      *ngIf="accountLabel || displayName"
      [name]="name + '-bank-selector'"
      [displayName]="accountLabel"
      [entityType]="accountEntityType"
      [disabled]="disabled"
      [(ngModel)]="selectedAccountID"
      [lcsValidate]="validation"
      (ngModelChange)="onBankSelected()"
   >
   </lcs-select>
</ng-container>
<div class="report-parameter-label">
   <label
      [hidden]="hideLabel"
      class="parameter-label"
   > Reconcile </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<ng-container *ngIf="valueSource">
   <lcs-select
      lcsLegacySingleSelector
      [name]="name"
      [displayName]="'Reconcile'"
      [disabled]="disabled || !selectedAccountID || selectedAccountID == nullFK"
      [valueSource]="valueSource"
      [(ngModel)]="selectedValue"
      [lcsValidate]="validation"
      (ngModelChange)="selectionChanged()"
   >
   </lcs-select>
</ng-container>