import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { ClassConditionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/class-condition.model";
import { ControlTypeConditionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/control-type-condition.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { PropertyBindingConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/property-binding-configuration.model";

import { ValueSourceTypes } from "../enumerations/value-source-types.enum";
import { ClickableIconModel } from "./clickable-icon.model";
import { ExpressColumnDefinitionModel } from "./generated/express-column-definition.model";
import { ValueSourceModel } from "./value-source.model";

export class DataTableColumnModel extends PropertyBindingConfigurationModel {
   AdditionalEmbeds: string;

   AdditionalInformationTemplate: string;

   Checked: boolean;

   ClickableIconModel: ClickableIconModel;

   ColumnName: string | null;

   ConditionalPropertyPath: string;

   ConditionalToolTipPropertyPath: string;

   ConditionalTemplate: string;

   ControlTypePropertyPath: string;

   DrillDownEntityType: EntityType;

   DrillDownIDPropertyPath: string;

   FillEmptySpace: boolean;

   /** Set/Unset by DatatableResponsiveDirective to track if column was assigned to FillEmptySpace automatically */
   AutoFillEmptySpace: boolean;

   FilterValuePropertyPath: string;

   HideCheckboxLabel: boolean;

   IsCalculated: boolean;

   IsDisabled: boolean;

   IsEditable: boolean;

   IsExclusiveValueGroup: boolean;

   IsHidden: boolean = false;

   IsSkipAdd: boolean;

   IsInverted: boolean;

   IsMultiSelect: boolean;

   IsQuickActionsColumn: boolean;

   IsSortable: boolean;

   IsStandalone: boolean;

   Preformatted: boolean;

   ResponsivePriority: number;

   ShowToolTip: boolean = true;

   UseDateRangeControl: boolean;

   ValueSource: ValueSourceModel;

   Width: number;

   WidthPercentage: number;

   UpdateOn: "change" | "blur" | "submit";

   HasIcon: boolean;

   ClassConditions: Array<ClassConditionModel>;

   ControlTypeCondition: Array<ControlTypeConditionModel>;

   AlternateValuePropertyPath: string;

   AlternateValue: string;

   /**
    * Path to property to use as a proxy when sorting by the column.
    * for example in the case of clicking the unit column in Meter Readings Setup
    * actually sorts by the SortOrder not the UnitName.
    *
    * Currently only supported by VirtualTableComponent.
    */
   SortPropertyPath?: string;

   SortPropertyFn?: (item: any) => any;

   ConditionalLinkPropertyPath: string;

   HasCustomLinkAction: boolean = false;

   OriginalControlType: ExpressLayoutControlTypes;

   get WidthStyle(): string {
      let widthStyle: string = "";
      if (this.FillEmptySpace) {
         widthStyle = "100%";
      } else if (this.WidthPercentage) {
         widthStyle = `${this.WidthPercentage}%`;
      } else {
         widthStyle = `${this.Width}em`;
      }
      return widthStyle;
   }

   /** Add for Angular 13 upgrade when p-tables started using flex-box to format. Replaces using WidthStyle */
   get FlexStyle(): string {
      let flexStyle: string = "";
      if (this.FillEmptySpace) {
         if (this.WidthPercentage) {
            flexStyle = `1 0 ${this.WidthPercentage}%`;
         } else {
            flexStyle = `1 0 ${this.Width}em`;
         }
      } else if (this.WidthPercentage) {
         flexStyle = `0 0 ${this.WidthPercentage}%`;
      } else {
         flexStyle = `0 0 ${this.Width}em`;
      }
      return flexStyle;
   }

   constructor() {
      super();
   }

   FromExpressColumnDefinitionModel(expressColumnDefinitionModel: ExpressColumnDefinitionModel) {
      this.ColumnName = expressColumnDefinitionModel.ColumnName;
      this.PropertyPath = expressColumnDefinitionModel.AngularPropertyPath;
      this.DataType = expressColumnDefinitionModel.DataType;
      this.RequiredEmbeds = expressColumnDefinitionModel.RequiredEmbeds;
      this.ControlType = expressColumnDefinitionModel.ControlType;
      this.ControlEnumType = expressColumnDefinitionModel.EnumerationType;
      this.ControlEntityType = expressColumnDefinitionModel.EntityType;
      this.DataSourceFilters = expressColumnDefinitionModel.DataSourceFilters
         ? expressColumnDefinitionModel.DataSourceFilters.map(FilterOption.FromExpressControlDataSourceFilterModel)
         : null;
      this.IsEditable = expressColumnDefinitionModel.IsEditable;
      this.IsCalculated = expressColumnDefinitionModel.IsCalculated;
      this.IsMultiSelect = expressColumnDefinitionModel.IsMultiSelect;
      this.Width = expressColumnDefinitionModel.Width;
      this.WidthPercentage = expressColumnDefinitionModel.WidthPercentage;
      this.ResponsivePriority = expressColumnDefinitionModel.ResponsivePriority;
      this.FillEmptySpace = expressColumnDefinitionModel.FillEmptySpace;
      this.ControlTypePropertyPath = expressColumnDefinitionModel.ControlTypePropertyPath;
      this.AdditionalInformationTemplate = expressColumnDefinitionModel.AdditionalInformationTemplate;
      this.FilterValuePropertyPath = expressColumnDefinitionModel.FilterValuePropertyPath;
      this.MinFractionDigits = expressColumnDefinitionModel.MinFractionDigits;
      this.MaxFractionDigits = expressColumnDefinitionModel.MaxFractionDigits;
      this.IsUTCDateTime = expressColumnDefinitionModel.IsUTCDateTime;
      this.ConditionalPropertyPath = expressColumnDefinitionModel.ConditionalPropertyPath;
      this.ConditionalTemplate = expressColumnDefinitionModel.ConditionalTemplate;
      this.AdditionalEmbeds = expressColumnDefinitionModel.AdditionalEmbeds;
      if (expressColumnDefinitionModel.DrilldownInformation) {
         this.DrillDownEntityType = expressColumnDefinitionModel.DrilldownInformation.EntityType;
         this.DrillDownIDPropertyPath = expressColumnDefinitionModel.DrilldownInformation.EntityIDValuePath;
      }
      this.Validation = expressColumnDefinitionModel.ValidationModel
         ? ValidationModel.FromExpressValidationModel(expressColumnDefinitionModel.ValidationModel)
         : null;
      this.ValueSource = new ValueSourceModel();
      if (expressColumnDefinitionModel.EmbeddedSetPath) {
         this.ValueSource.Type = ValueSourceTypes.EmbeddedSet;
      }
      this.ValueSource.EmbeddedSetValueSourcePath = expressColumnDefinitionModel.EmbeddedSetPath;
      this.ValueSource.EntityValueSourcePath = expressColumnDefinitionModel.EntityValuePath;
      this.ValueSource.IsRequiredValueSourcePath = expressColumnDefinitionModel.IsRequiredPath;
      this.ValueSource.AttachmentValueSourcePath = expressColumnDefinitionModel.AttachmentValueSourcePath;
      this.ValueSource.FileUploadResource = expressColumnDefinitionModel.FileUploadResource;
      this.IsExclusiveValueGroup = expressColumnDefinitionModel.IsExclusiveValueGroup;
   }

   // FromRegisterDataTableColumnModel(expressRegisterColumn: RegisterDataTableColumnModel, showToolTip: boolean = true) {
   //    this.ColumnName = expressRegisterColumn.columnName;
   //    this.DisplayName = expressRegisterColumn.displayLabel;
   //    this.PropertyPath = expressRegisterColumn.propertyPath;
   //    this.DataType = expressRegisterColumn.dataType;
   //    this.IsHidden = expressRegisterColumn.isHidden;
   //    this.ResponsivePriority = expressRegisterColumn.priority;
   //    this.IsSortable = expressRegisterColumn.isSortable;
   //    this.FillEmptySpace = expressRegisterColumn.fillEmptySpace;
   //    this.Width = expressRegisterColumn.width;
   //    this.Icon = expressRegisterColumn.sortIcon;
   //    this.ShowToolTip = showToolTip;
   // }
}
