import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";

import { AccountMultiSelectorComponent } from "./account-multi-selector.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,      
      SingleLineMultiSelectModule,
   ],
   declarations: [
      AccountMultiSelectorComponent
   ],
   exports: [
      AccountMultiSelectorComponent
   ],
})
export class AccountMultiSelectorModule { }
