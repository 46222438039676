<div
   class="checkbox-wrapper"
   [ngStyle]="checkboxWrapperCustomStyle"
   [class.checkbox-disabled]="disabled"
>
   <div
      *ngIf="icon"
      [ngClass]="['icon-button', icon]"
      [class.disabled]="disabled"
      (click)="toggle()"
      [title]="tooltip"
   ></div>
   <p-checkbox
      [hidden]="icon"
      [name]="name"
      [ngModel]="checked"
      (ngModelChange)="checkedChanged($event)"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="disabled"
      [tabindex]="tabIndex"
      [inputId]="inputId"
      [binary]="true"
      [readonly]="readOnly"
      [ngStyle]="pCheckboxCustomStyle"
   ></p-checkbox>
   <label
      [ngStyle]="checkboxLabelCustomStyle"
      class="checkbox-label"
      title="{{tooltip}}"
      [class.label-enabled]="!disabled"
      [attr.for]="inputId"
   >
      <ng-content></ng-content>
   </label>
</div>