////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum OWADashboardTile {
   NotSet = 0,
   Messages = 1,
   Ownership = 2,
   Notes = 3,
   Bills = 4,
   InspectionReports = 5,
   Checks = 6,
   ManagementFees = 7,
   OwnerDistributions = 8,
   ServiceIssues = 9,
   Estimates = 10,
}
