import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { ChangeEmailAddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/change-emailaddress.model";
import { MergeUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/merge-user.model";
import { filter, Observable, Subject, take, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";
import { OwaAuthenticationModel } from "../authentication/models/owa-authentication.model";
import { ChangeEmailAddressService } from "../change-emailaddress/change-emailaddress.service";
import { TopBarService } from "../top-bar/top-bar.service";
import { MergeUsersService } from "./merge-users.service";

@Component({
   selector: "owa-merge-users",
   templateUrl: "./merge-users.component.html",
   styleUrls: ["./merge-users.component.css"],
})
export class MergeUsersComponent implements OnInit, OnDestroy {
   public password: string;
   public cancelButton: boolean;
   public emailValidationModel: ValidationModel;
   public authModel: OwaAuthenticationModel = new OwaAuthenticationModel();

   saveButtonStatus = LcsProgressButtonStatus.Unset;

   cancelButtonStatus = LcsProgressButtonStatus.Unset;

   checkedEmailOption: number;

   public mergeUserModel: MergeUserModel = new MergeUserModel();
   public emailaddress: ChangeEmailAddressModel = new ChangeEmailAddressModel();
   public mergeUsersModel: Observable<MergeUserModel> = new Observable<MergeUserModel>();
   private unsubscribe = new Subject<void>();

   constructor(
      private changeEmailAddressService: ChangeEmailAddressService,
      private mergeUsersService: MergeUsersService,
      private router: Router,
      private route: ActivatedRoute,
      private topbarservice: TopBarService,
      private errorMessageService: ErrorMessageService,
      private sessionCheckService: SessionCheckService,
      private authenticationService: AuthenticationService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit(): void {
      this.changeEmailAddressService.error.next(false);
      this.checkedEmailOption = 1;
      const emailRegexp: RegExp =
         /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
      this.emailValidationModel = new ValidationModel();
      this.emailValidationModel.pattern = emailRegexp;

      this.changeEmailAddressService.changeEmailAddressResults.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
         if (res) {
            this.emailaddress = res;
         }
      });

      const tokenFromURL = this.route.snapshot.queryParams.token;
      if (tokenFromURL !== undefined) {
         this.emailaddress.token = decodeURIComponent(this.route.snapshot.queryParams.token);
      }

      if (this.emailaddress.token != null) {
         this.mergeUsersModel = this.mergeUsersService.getTokenInformation(encodeURIComponent(this.emailaddress.token));
         this.mergeUsersModel.pipe(takeUntil(this.unsubscribe)).subscribe((results) => {
            if (results) {
               this.mergeUserModel = results;
               return results;
            }
         });
      }

      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });
   }

   ngOnDestroy(): void {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
   }

   updateEmailOption(event) {
      this.checkedEmailOption = event;
      if (this.checkedEmailOption === 2) {
         this.emailaddress.NewEmailAddress = "";
         this.emailaddress.ConfirmEmailAddress = "";
      }
   }

   saveClick() {
      this.changeEmailAddressService.error.subscribe((res) => {
         if (!res) {
            this.saveButtonStatus = LcsProgressButtonStatus.InProgress;
         } else {
            this.saveButtonStatus = LcsProgressButtonStatus.Error;
         }
      });
      if (this.checkedEmailOption === 1) {
         this.mergeUserModel.Password = this.password;
         this.mergeUsersService.mergeUsersResult.pipe(take(1), takeUntil(this.unsubscribe)).subscribe(
            () => {
               this.saveButtonStatus = LcsProgressButtonStatus.Success;
            },
            () => {
               this.saveButtonStatus = LcsProgressButtonStatus.Error;
            }
         );
         this.mergeUsersService.mergeUsers(this.mergeUserModel);
         this.authModel.LocationId = this.mergeUserModel.LocationID;
         this.authModel.Username = this.mergeUserModel.DesiredEmailAddress;
         this.authModel.Password = this.mergeUserModel.Password;
         this.authenticationService.logIn(this.authModel);
      } else {
         if (this.emailaddress.WebUserId > 0) {
            if (this.emailaddress.NewEmailAddress === this.mergeUserModel.DesiredEmailAddress) {
               this.errorMessageService.triggerErrorMessage(
                  "Email is already in use. Please enter a new email address."
               );
               this.saveButtonStatus = LcsProgressButtonStatus.Warning;
               return;
            }
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
            this.emailaddress.Message = null;
            this.saveButtonStatus = LcsProgressButtonStatus.InProgress;
            this.changeEmailAddressService.changeEmailAddress(this.emailaddress);
         } else {
            this.emailaddress.AccountDisplayID = this.mergeUserModel.AccountDisplayID;
            this.changeEmailAddressService
               .CreateNewAccount(this.emailaddress)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe(
                  (response) => {
                     if (response) {
                        const signupModel = response;
                        if (signupModel.WorkflowSteps === WorkflowSteps[WorkflowSteps.MergeUsers]) {
                           this.errorMessageService.triggerErrorMessage(
                              "Email is already in use. Please enter a new email address."
                           );
                        } else {
                           this.router.navigateByUrl("/password/verifypending");
                        }
                     }
                  },
                  (_err) => {
                     this.errorMessageService.triggerHttpErrorMessage(_err);
                     this.saveButtonStatus = LcsProgressButtonStatus.Error;
                  }
               );
         }
      }
   }

   cancelClick() {
      this.cancelButtonStatus = LcsProgressButtonStatus.InProgress;
      this.router.navigate(["/login"], {
         queryParams: {
            locations:
               this.mergeUserModel.LocationID != null ? this.mergeUserModel.LocationID : this.emailaddress.locationID,
            locationid:
               this.mergeUserModel.LocationID != null ? this.mergeUserModel.LocationID : this.emailaddress.locationID,
         },
      });
   }
}
