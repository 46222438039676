import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { Observable } from "rxjs";

import { EntityViewInformationModel } from "./entity-view-information.model";

@Injectable()
export abstract class EntityViewInformationServiceBase {
   abstract getViewInformation(entityType: EntityType): Observable<EntityViewInformationModel>;
}
