<div
   class="dashboard-tile-wrapper"
   *ngIf="$any((signableDocumentPackets | async)).length === 1"
>
   <div class="dashboard-tile">
      <div class="signable-documents-list-container">
         <owa-signable-documents-list-item
            *ngFor="let signableDocumentPacket of signableDocumentPackets | async"
            [signableDocumentPacket]="signableDocumentPacket"
         >
         </owa-signable-documents-list-item>
      </div>
   </div>
</div>
<!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
<div
   *ngIf="$any((signableDocumentPackets | async)).length > 1"
   class="signable-documents"
>
   <owa-dashboard-tile
      [title]="'Documents to Sign'"
      [isViewMore]="true"
      [viewMoreComponent]="'signabledocuments'"
   >
      <lcs-table [dataSource]="$any(signableDocumentPackets | async)">
         <tr
            lcs-header-row
            *lcsHeaderRowDef="columns; sticky: false"
         ></tr>
         <tr
            lcs-row
            *lcsRowDef="let row; columns: columns"
         ></tr>
         <ng-container
            lcsColumnDef="PacketName"
            [width]="15"
            [priority]="1"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Name</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
            >{{row.Name}}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="ExpirationDate"
            [width]="8"
            [priority]="2"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Expires</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
            >{{row.ExpirationDate !== '0001-01-01T00:00:00' ? (row?.ExpirationDate | date:
               'MM/dd/yyyy') : ''}}</td>
         </ng-container>
         <ng-container
            lcsColumnDef="Status"
            [width]="12"
            [priority]="3"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Status</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
               class="sign-table-status-container"
            >
               <div class="status-container-item">
                  <i
                     *ngIf="!row.IsViewOnly && row.Status === signableDocumentPacketStatus.Published; else viewIcon"
                     class="material-icons signable-icon"
                  >
                     insert_drive_file
                  </i>
                  <ng-template #viewIcon>
                     <i class="material-icons complete-icon">
                        check_circle_outline
                     </i>
                  </ng-template>
                  {{row.StatusText}}
               </div>
            </td>
         </ng-container>

         <ng-container
            lcsColumnDef="DocumentLink"
            [width]="4"
            [priority]="4"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            ></th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
               class="has-drilldown"
            >
               <div *ngIf="!row.IsViewOnly && row.Status === signableDocumentPacketStatus.Published; else view">
                  <a
                     (click)="signDocument(row.SignableDocumentPacketID)"
                     class="drilldown-link sign"
                  >Sign</a>
               </div>
               <ng-template #view>
                  <a
                     (click)="viewDocument(row.SignableDocumentPacketID)"
                     class="drilldown-link review"
                  >Review</a>
               </ng-template>
            </td>
         </ng-container>

      </lcs-table>
   </owa-dashboard-tile>
</div>