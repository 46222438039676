import { ExpressMenuItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-menu-item.model";

export class SideBarMenuItemConfigurationModel {
   MenuItems: Array<ExpressMenuItemModel>;

   ParentItem: ExpressMenuItemModel;

   IsCurrent: boolean;

   IsSearching: boolean;
}
