import { Component, Input } from "@angular/core";
import { ManagementFeesDetailListViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/management-fee-detail-list-view.model";

@Component({
   selector: "owa-management-fees-detail-list",
   templateUrl: "./management-fees-detail-list.component.html",
})
export class ManagementFeeDetailListComponent {
   @Input() managementFeeDetailListViewItems: Array<ManagementFeesDetailListViewModel>;

   @Input() totalCount: number;
   @Input() MgmtFeeModel: Array<ManagementFeesDetailListViewModel>;
   managementFeeDetailsListColumns: string[] = ["FeeDate", "Unit", "Comment", "Amount", "FeeAmount"];

   constructor() {}
}
