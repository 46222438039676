import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ReportParameterValueStaticSourceItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-static-source-item.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-label-selection-report-parameter",
   templateUrl: "label-selection-report-parameter.component.html",
})
export class LabelSelectionReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      if (values) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  })
            );
      }
   }

   @Input() hasAsk: boolean;

   allFontValuesLoaded: boolean = false;

   labelTypeSelectionReportParameter: ReportReportParameterViewModel;

   fontNameReportParameter: ReportReportParameterViewModel;

   fontSizeReportParameter: ReportReportParameterViewModel;

   fontBoldReportParameter: ReportReportParameterViewModel;

   fontItalReportParameter: ReportReportParameterViewModel;

   startingLabelReportParameter: ReportReportParameterViewModel;

   downOnStartingLabelReportParameter: ReportReportParameterViewModel;

   labelAcrossValidation: ValidationModel;

   labelDownValidation: ValidationModel;

   private unsubscribe = new Subject<void>();

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   constructor(private apiService: ApiService, private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
      this.limitStartingLabelValues("5160");

      const apiURL = "Fonts/FontList";
      this.apiService
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         .getCollection(apiURL, null, [], [], [])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => this.processCollection(results));
   }

   processCollection(results: Array<string>) {
      this.fontNameReportParameter.ReportParameterValueSource.StaticValues = [];
      const fontNames = [];
      if (results) {
         for (const font of results) {
            const item = new ReportParameterValueStaticSourceItemModel();
            item.DisplayValue = font;
            item.Value = font;
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameterValueStaticSource... Remove this comment to see the full error message
            fontNames.push(item);
         }
         this.allFontValuesLoaded = true;
      }
      this.fontNameReportParameter.ReportParameterValueSource.StaticValues = fontNames;
   }

   limitStartingLabelValues(value: string) {
      const labelAcrossValidation = new ValidationModel();
      labelAcrossValidation.dataType = ExpressDataTypes.Numeric;
      const labelDownValidation = new ValidationModel();
      labelDownValidation.dataType = ExpressDataTypes.Numeric;

      switch (value) {
         case "5160":
            labelAcrossValidation.inclusiveRange = [1, 3];
            labelDownValidation.inclusiveRange = [1, 10];
            break;
         case "5161":
            labelAcrossValidation.inclusiveRange = [1, 2];
            labelDownValidation.inclusiveRange = [1, 10];
            break;
         case "5162":
            labelAcrossValidation.inclusiveRange = [1, 2];
            labelDownValidation.inclusiveRange = [1, 7];
            break;
         case "5163":
            labelAcrossValidation.inclusiveRange = [1, 2];
            labelDownValidation.inclusiveRange = [1, 5];
            break;
         case "5164":
            labelAcrossValidation.inclusiveRange = [1, 2];
            labelDownValidation.inclusiveRange = [1, 3];
            break;
         case "5167":
            labelAcrossValidation.inclusiveRange = [1, 4];
            labelDownValidation.inclusiveRange = [1, 20];
            break;
      }
      this.labelAcrossValidation = labelAcrossValidation;
      this.labelDownValidation = labelDownValidation;
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.LABELTYPE) {
         if (this.labelTypeSelectionReportParameter && updatedValue.value) {
            this.limitStartingLabelValues(updatedValue.value);
         }
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.labelTypeSelectionReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.LABELTYPE
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fontNameReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.FONTNAME
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fontSizeReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.FONTSIZE
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fontBoldReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.FONTBOLD
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fontItalReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.FONTITAL
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.startingLabelReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.STARTINGLABEL
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.downOnStartingLabelReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.DownOnStartingLabel
      );
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
