import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";

import { AuthenticationService } from "../authentication/authentication.service";

@Component({
   selector: "owa-switch-account",
   templateUrl: "./switch-account.component.html",
})
export class SwitchAccountComponent {
   @Input() availableAccounts: Array<OWAWebUserAccountModel>;

   @Output() accountSwitched = new EventEmitter<void>();

   accountTypeEnum = AccountType;

   constructor(private authenticationService: AuthenticationService) {}

   switchAccount(webUserAccountId: number) {
      this.authenticationService.switchAccount(webUserAccountId);
      this.accountSwitched.emit();
   }
}
