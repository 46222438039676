import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DialogComponent } from "@lcs/dialog/dialog.component";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { OWASessionService } from "../../session/owa-session.service";

@Component({
   selector: "owa-account-menu",
   templateUrl: "./account-menu.component.html",
   styleUrls: ["./account-menu.component.css"],
})
export class AccountMenuComponent implements OnInit, OnDestroy {
   @ViewChild("switchAccountsDialog") switchAccountsDialog: DialogComponent;
   public currentOwner: OwnerModel;
   public currentOwnerProspect: OwnerProspectModel;
   public currentWebUserAccount: Subject<OWAWebUserAccountModel>;
   public availableAccounts: Array<OWAWebUserAccountModel>;
   isOwner: boolean = false;
   show: boolean;
   showAccountOverlay: boolean;
   private unsubscribe = new Subject<void>();

   constructor(private owaSessionService: OWASessionService) {
      this.currentWebUserAccount = new BehaviorSubject<OWAWebUserAccountModel>(new OWAWebUserAccountModel());
      this.currentOwner = new OwnerModel();
      this.currentOwnerProspect = new OwnerProspectModel();
      this.availableAccounts = new Array<OWAWebUserAccountModel>();
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((owaSessionInfo) => {
         if (owaSessionInfo) {
            if (owaSessionInfo.CurrentWebUserAccount.AccountType == AccountType.Owner) {
               this.isOwner = true;
               this.currentOwner = owaSessionInfo.CurrentAccount;
               this.availableAccounts = owaSessionInfo.AvailableAccounts.filter((account: OWAWebUserAccountModel) => {
                  return account.Owner.OwnerID !== owaSessionInfo.CurrentAccount.OwnerID;
               });
            } else {
               this.currentOwner = new OwnerModel();
               this.isOwner = false;
               this.currentOwnerProspect = owaSessionInfo.CurrentOwnerProspectAccount;
               this.availableAccounts = owaSessionInfo.AvailableAccounts.filter((account: OWAWebUserAccountModel) => {
                  return (
                     account.OwnerProspect.OwnerProspectID !==
                     owaSessionInfo.CurrentOwnerProspectAccount.OwnerProspectID
                  );
               });
            }
            this.currentWebUserAccount.next(owaSessionInfo.CurrentWebUserAccount);
         }
      });
   }

   ngOnInit() {
      const owaSessionInfo = this.owaSessionService.currentOWASessionInfo;
      if (owaSessionInfo) {
         if (owaSessionInfo.CurrentWebUserAccount.AccountType == AccountType.Owner) {
            this.currentOwner = owaSessionInfo.CurrentAccount;
         } else {
            this.currentOwnerProspect = owaSessionInfo.CurrentOwnerProspectAccount;
         }
         this.availableAccounts = owaSessionInfo.AvailableAccounts;
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   openSwitchAccountsDialog() {
      this.switchAccountsDialog.visible = true;
   }
}
