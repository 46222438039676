import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EntitySelectorModule } from "@lcs/entity-selector/entity-selector.module";
import { SelectModule } from "@lcs/select/select.module";

import { TextBoxModule } from "../text-box/text-box.module";
import { ValidationModule } from "../validation/validation.module";
import { BankSelectorComponent } from "./bank-selector.component";

@NgModule({
   imports: [CommonModule, FormsModule, SelectModule, EntitySelectorModule, TextBoxModule, ValidationModule],
   declarations: [BankSelectorComponent],
   exports: [BankSelectorComponent],
})
export class BankSelectorModule {}
