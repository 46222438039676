import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "lcs-report-parameter-ask",
   templateUrl: "report-parameter-ask.component.html",
})
export class ReportParameterAskComponent {
   @Input() isAsk: boolean;

   @Input() name: string;

   @Output() askChanged = new EventEmitter<boolean>();

   onAskChanged(value: boolean) {
      this.askChanged.emit(value);
   }
}
