import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

import { ConstantsService } from "../../../core/constants.service";
import { EmailService } from "../email.service";

export class MiscValidators {
   static notUnset(unsetValue: any): ValidatorFn {
      return (control: AbstractControl) => {
         if (control.value === unsetValue) {
            return { notUnset: true };
         }
         return null;
      };
   }

   static requiredEntity(control: AbstractControl): ValidationErrors | null {
      if (
         control.value == null ||
         control.value.length === 0 ||
         control.value === ConstantsService.NullFK ||
         control.value === ConstantsService.NullFK.toString()
      ) {
         return { required: true };
      }
      return null;
   }

   /**
    * Validate a string of emails separated by a delimiter (string | RegExp) or
    * a single email address if null is passed for the delimiter.
    *
    * delimiter defaults to EmailService.whitespaceAgnosticValidationDelimiter
    */
   static emails(
      control: AbstractControl,
      delimiter: string | RegExp | null = EmailService.whitespaceAgnosticValidationDelimiter
   ): ValidationErrors | null {
      if (control.value) {
         let emails: string[];

         if (delimiter !== null) {
            emails = control.value.split(delimiter);
         } else {
            emails = new Array<string>(control.value);
         }

         const hasInvalidEmails: boolean = emails.some((email: string) => {
            return email.length > 0 && !EmailService.isValidEmailAddress(email);
         });

         return hasInvalidEmails ? { emails: true } : null;
      }

      return null;
   }

   static isStringNullOrEmpty(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
      if (value == null || value?.length === 0 || value.trim().length === 0) {
         return { required: true };
      }
      return null;
   }
}
