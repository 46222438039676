import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";

export class ActionTriggerPayloadModel {
   PayloadField: ExpressPayloadFields;

   PayloadKey: string;

   Value: any;

   /**
    * Payload key used to store the storage key returned from cachePayloads(...)
    * This key and its value should be added to the ActionTriggerPayloads as
    * a proxy for the cached payloads.  This payload will be passed on the url
    * and used to restore the cached payloads.
    */
   static readonly actionTriggerPayloadsCacheKey: string = "atpCacheKey";

   /**
    * Static factory method to create an ActionTriggerModel using an payloadKey and value
    */
   static createPayload(payloadKey: string, value: any): ActionTriggerPayloadModel {
      return new ActionTriggerPayloadModel(null, value, payloadKey);
   }

   /**
    * Static factory function to create an ActionTriggerModel using an ExpressPayloadField and value
    */
   static createPayloadFieldValue(payloadField: ExpressPayloadFields, value: any): ActionTriggerPayloadModel {
      return new ActionTriggerPayloadModel(payloadField, value);
   }

   public constructor(payloadField?: ExpressPayloadFields | null, value?: any, payloadKey?: string) {
      if (payloadField) {
         this.PayloadField = payloadField;
      }
      if (value !== undefined) {
         this.Value = value;
      }
      if (payloadKey) {
         this.PayloadKey = payloadKey;
      }
   }
}
