import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import cloneDeep from "lodash/cloneDeep";
import { warnInDevMode } from "projects/libraries/lcs/src/lib/utils/logging";
import { EntityRequestEndpointInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { ApiServiceHelpers } from "projects/libraries/owa-gateway-sdk/src/lib/core/api-service.helpers";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { UserDefinedFieldFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/user-defined-field.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { combineLatest, of, Subject, switchMap, takeUntil } from "rxjs";

import { DataTableFiltersService } from "../datatable-filters.service";
import { UserDefinedValueFilterDefinitionModel } from "./user-defined-value-filter-definition.model";

@Component({
   selector: "lcs-datatable-user-defined-value-filter-section",
   templateUrl: "./datatable-user-defined-value-filter-section.component.html",
})
export class DatatableUserDefinedValueFilterSectionComponent implements AfterViewInit, OnInit, OnDestroy {
   @Input() entityType: EntityType;

   @Input() filterOperations: Array<FilterOperationModel>;

   @Input() initialUserDefinedValuesFilterOptions: Array<FilterOption>;

   @Output() filtersChanged = new EventEmitter<Array<FilterOption>>();

   @ViewChild("udfSelector") udfSelector: SelectComponent;

   @ViewChild("udvFilterSelector") udvFilterSelector: TemplateRef<any>;

   @ViewChild("udvFilterContainer", { read: ViewContainerRef }) udvFilterContainer: ViewContainerRef;

   userDefinedValueFilterDefinitions = new Array<UserDefinedValueFilterDefinitionModel>();

   allowMultipleFilters: boolean;

   udfSelectorItems: SelectorItemModel[];

   currentUserDefinedFieldID: number;

   currentUserDefinedFieldSelection: SelectorItemModel;

   private unsubscribe = new Subject<void>();

   private fullUdfItemList: SelectorItemModel[];

   constructor(
      private entityRequestEndpointServiceBase: EntityRequestEndpointServiceBase,
      private entityViewInformationServiceBase: EntityViewInformationServiceBase,
      private entityRequestService: EntityRequestService,
      private apiService: ApiService,
      private dataTableFiltersService: DataTableFiltersService,
      private objectMapResolverService: ObjectMapResolverService
   ) {
      this.dataTableFiltersService.filterRemoved.pipe(takeUntil(this.unsubscribe)).subscribe((filterOption) => {
         this.removeFilterOption(filterOption);
      });

      this.dataTableFiltersService.clearAllUDV.pipe(takeUntil(this.unsubscribe)).subscribe((clear) => {
         this.removeUDVFilters(clear);
      });
   }

   ngOnInit() {
      combineLatest([
         this.entityRequestEndpointServiceBase.getEndpointInformation(this.entityType),
         this.entityViewInformationServiceBase.getViewInformation(EntityType.UserDefinedField),
      ])
         .pipe(
            switchMap(
               ([entityEndpointInfo, viewInfo]: [
                  EntityRequestEndpointInformationModel,
                  EntityViewInformationModel
               ]) => {
                  this.allowMultipleFilters = entityEndpointInfo.IsSearchEnabled;
                  const endpoint = this.entityRequestService.buildEndpoint(
                     entityEndpointInfo,
                     "UserDefinedFields",
                     null
                  );
                  const embeds = ApiServiceHelpers.extractEmbeds([
                     viewInfo.EntityPrimaryKeyPropertyPath,
                     viewInfo.EntityDisplayTemplate,
                     viewInfo.AdditionalInformationDisplayTemplate,
                  ]);
                  const filterExpression = new FilterExpression();
                  filterExpression.FilterOptions = [
                     new FilterOption(UserDefinedFieldFields.FieldType, FilterOperations.NotIn, [
                        UserDefinedFieldType.EncryptedText,
                     ]),
                  ];
                  return combineLatest([
                     this.apiService.getCollection(endpoint, filterExpression, embeds),
                     of(viewInfo),
                  ]);
               }
            ),
            switchMap(([results, viewInfo]: [Array<UserDefinedFieldModel>, EntityViewInformationModel]) => {
               const items = new Array<SelectorItemModel>();
               if (results) {
                  results.forEach((result) => {
                     const item = this.objectMapResolverService.buildSelectorItem(
                        result,
                        viewInfo.EntityPrimaryKeyPropertyPath,
                        viewInfo.EntityDisplayTemplate,
                        viewInfo.AdditionalInformationDisplayTemplate,
                        ["UserDefinedFieldID", "Name", "FieldType", "ComboList", "DefaultValue", "IsRequired"]
                     );
                     items.push(item);
                  });
               }
               return of(items);
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((items: SelectorItemModel[]) => {
            this.fullUdfItemList = this.udfSelectorItems = items;
            this.setupInitialFilters();
            this.updateUserDefinedValueSelectorExclusionList();
         });
   }

   ngAfterViewInit() {
      this.createNewFilterSelector();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   setupInitialFilters() {
      this.initialUserDefinedValuesFilterOptions.forEach((filterOption) => {
         const selectorItemModel = this.fullUdfItemList.find(
            (itemModel) => itemModel.value === filterOption.AssociatedFilterOptions[0].Value
         );
         if (selectorItemModel) {
            const udvDefinition = this.buildFilterDefinition(selectorItemModel);
            udvDefinition.filterOption = cloneDeep(filterOption);
            this.userDefinedValueFilterDefinitions.push(udvDefinition);
            this.updateUserDefinedValueSelectorExclusionList();
         }
      });
   }

   onUserDefinedFieldSelected() {
      const userDefinedFieldItem = this.udfSelector.selectedItem;

      this.currentUserDefinedFieldID = userDefinedFieldItem.value;
      this.currentUserDefinedFieldSelection = userDefinedFieldItem;
      if (this.currentUserDefinedFieldSelection && userDefinedFieldItem.value && !this.allowMultipleFilters) {
         this.userDefinedValueFilterDefinitions = [this.buildFilterDefinition(userDefinedFieldItem)];
         this.updateUserDefinedValueSelectorExclusionList();
         this.triggerFiltersChanged();
      }
      this.addField();
      this.createNewFilterSelector();
   }

   createNewFilterSelector() {
      this.udvFilterContainer.clear();
      this.udvFilterContainer.createEmbeddedView(this.udvFilterSelector);
   }

   addField() {
      if (!this.currentUserDefinedFieldSelection || !this.currentUserDefinedFieldSelection.value) {
         return;
      }
      const fieldAlreadyInList = this.userDefinedValueFilterDefinitions.some((definition) => {
         return definition.userDefinedFieldID === this.currentUserDefinedFieldSelection.value.toString();
      });
      if (fieldAlreadyInList) {
         return;
      }
      this.userDefinedValueFilterDefinitions.push(this.buildFilterDefinition(this.currentUserDefinedFieldSelection));
      this.updateUserDefinedValueSelectorExclusionList();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.currentUserDefinedFieldID = null;
      this.triggerFiltersChanged();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'SelectorIte... Remove this comment to see the full error message
      this.currentUserDefinedFieldSelection = null;
   }

   removeField(filterDefinition: UserDefinedValueFilterDefinitionModel) {
      const index = this.userDefinedValueFilterDefinitions.indexOf(filterDefinition);
      if (index > -1) {
         this.userDefinedValueFilterDefinitions.splice(index, 1);
      }
      this.updateUserDefinedValueSelectorExclusionList();
      this.triggerFiltersChanged();
   }

   onValueChange() {
      this.triggerFiltersChanged();
   }

   private buildFilterDefinition(userDefinedFieldItem: SelectorItemModel): UserDefinedValueFilterDefinitionModel {
      const fieldType = userDefinedFieldItem.additionalData.get("FieldType");
      const embeddedSetValueSource = userDefinedFieldItem.additionalData.get("ComboList");
      const defaultValue = userDefinedFieldItem.additionalData.get("DefaultValue");
      const addBlankValue = !userDefinedFieldItem.additionalData.get("IsRequired");

      const filterDefinition = new UserDefinedValueFilterDefinitionModel();

      filterDefinition.userDefinedFieldID = userDefinedFieldItem.value;
      filterDefinition.entityType = this.entityType;
      filterDefinition.label = userDefinedFieldItem.displayValue;
      filterDefinition.fieldType = fieldType;

      const filterField = this.getFieldFromFieldType(fieldType);

      filterDefinition.filterOption = new FilterOption(filterField, null, [], userDefinedFieldItem.displayValue);

      // SelectorItemModel is capable of returning "any" type for propertyValue.
      if (!FilterOption.validValueTypeForFilter(userDefinedFieldItem.value)) {
         warnInDevMode(
            `${this.constructor.name}: Invalid Value Type for Filter Value {${userDefinedFieldItem.value}}`,
            true
         );
      }

      const fieldIDFilterOption = new FilterOption("UserDefinedValues.UserDefinedFieldID", FilterOperations.EqualTo, [
         userDefinedFieldItem.value,
      ]);
      filterDefinition.filterOption.AssociatedFilterOptions = [fieldIDFilterOption];

      filterDefinition.filterOption.Operation = FilterOperations.EqualTo;

      filterDefinition.valueSource = new ValueSourceModel();
      filterDefinition.valueSource.Type = ValueSourceTypes.EmbeddedSet;
      filterDefinition.valueSource.EntityValueSourcePath = "Value";
      filterDefinition.valueSource.EmbeddedSetValueSourcePath = "ComboList";
      if (addBlankValue) {
         filterDefinition.valueSource.AddBlankValue();
      }

      filterDefinition.valueSource.EmbeddedSet = {
         Value: defaultValue,
         ComboList: embeddedSetValueSource,
      };

      return filterDefinition;
   }

   private getFieldFromFieldType(fieldType: UserDefinedFieldType): string {
      let filterField = "";
      switch (fieldType) {
         case UserDefinedFieldType.Date:
            filterField = "UserDefinedValues.DateValue";
            break;
         case UserDefinedFieldType.Numeric:
            filterField = "UserDefinedValues.NumericValue";
            break;
         default:
            filterField = "UserDefinedValues.Value";
            break;
      }
      return filterField;
   }

   // private buildFilterDefinitionFromOption(filterOption: FilterOption): UserDefinedValueFilterDefinitionModel {
   //    // This code has been added here because the old control had something similar
   //    // All the commented lines are pieces that the old control was missing as well
   //    // We need to determine if receiving filters from outside this control is an actual requirement
   //    // And, if so, how to retrieve the data that is not included with those filters

   //    // const fieldType = userDefinedFieldItem.additionalData.get("FieldType");
   //    // const embeddedSetValueSource = userDefinedFieldItem.additionalData.get("ComboList");
   //    // const defaultValue = userDefinedFieldItem.additionalData.get("DefaultValue");
   //    // const addBlankValue = !userDefinedFieldItem.additionalData.get("IsRequired");

   //    const filterDefinition = new UserDefinedValueFilterDefinitionModel();

   //    filterDefinition.userDefinedFieldID = +filterOption.AssociatedFilterOptions[0].Value;
   //    filterDefinition.entityType = this.entityType;
   //    filterDefinition.label = filterOption.DisplayValue;
   //    // filterDefinition.fieldType = fieldType;

   //    filterDefinition.filterOption = filterOption;

   //    filterDefinition.valueSource = new ValueSourceModel();
   //    filterDefinition.valueSource.Type = ValueSourceTypes.EmbeddedSet;
   //    filterDefinition.valueSource.EntityValueSourcePath = "Value";
   //    filterDefinition.valueSource.EmbeddedSetValueSourcePath = "ComboList";
   //    // filterDefinition.valueSource.AddBlankValue = addBlankValue;
   //    filterDefinition.valueSource.EmbeddedSet = {
   //       // Value: defaultValue,
   //       // ComboList: embeddedSetValueSource,
   //    };

   //    return filterDefinition;
   // }

   private updateUserDefinedValueSelectorExclusionList() {
      if (this.fullUdfItemList) {
         const valuesToExclude = this.userDefinedValueFilterDefinitions.map(
            (definition) => definition.filterOption.AssociatedFilterOptions[0].Value
         );
         this.udfSelectorItems = this.fullUdfItemList.filter((item) => valuesToExclude.indexOf(item.value) < 0);
      } else {
         this.udfSelectorItems = [];
      }
   }

   private triggerFiltersChanged() {
      this.filtersChanged.emit(this.userDefinedValueFilterDefinitions.map((definition) => definition.filterOption));
   }

   private removeFilterOption(filterOption: FilterOption) {
      if (
         this.userDefinedValueFilterDefinitions.length > 0 &&
         this.userDefinedValueFilterDefinitions.some(
            (userDefinedValueFilterDefinition) =>
               userDefinedValueFilterDefinition.filterOption.FilterName === filterOption.FilterName
         )
      ) {
         const filterToRemove = this.userDefinedValueFilterDefinitions.find(
            (definition) => definition.userDefinedFieldID === filterOption.AssociatedFilterOptions[0].Value
         );
         if (filterToRemove) {
            this.removeField(filterToRemove);
         }
      }
   }

   private removeUDVFilters(clear: Boolean) {
      if (clear) {
         if (this.userDefinedValueFilterDefinitions.length > 0) {
            this.userDefinedValueFilterDefinitions.slice().forEach((filterDefinition) => {
               this.removeField(filterDefinition);
            });
         }
      }
   }
}
