import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { SystemWebPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/systemwebpreferences.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { LocationsQueryStringValuesService } from "../locations-query-string-values.service";
import { LoginComponent } from "../login/login.component";
import { OwaAuthenticationModel } from "../models/owa-authentication.model";
import { LocationService } from "./rm-location.service";

@Component({
   selector: "owa-rm-location", // style guide requires to have owa as prefix
   templateUrl: "./rm-location.component.html",
   styleUrls: ["./rm-location.component.css"],
})
export class LocationComponent implements OnInit, OnDestroy {
   public defaultLocationID = 1;
   public authModel: OwaAuthenticationModel = new OwaAuthenticationModel();
   public loginComponent: LoginComponent;
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public authlocationId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
   public selectedlocation: number;
   public queryParam: Params;
   public isDataLoadingStatus: BehaviorSubject<LcsProgressButtonStatus>;

   public locationModelList: Array<LocationModel> = new Array<LocationModel>();

   private unsubscribe = new Subject<void>();

   private locationList: Array<string> = new Array<string>();
   private RequireLocationInfoInURLOWAPreference: string = String(false);
   private locationId: Array<string> = new Array<string>();
   constructor(
      private locationService: LocationService,
      private route: ActivatedRoute,
      private systemWebPreferenceService: SystemWebPreferencesService,
      private locationsQueryStringValuesService: LocationsQueryStringValuesService,
      private errorMessageService: ErrorMessageService
   ) {
      this.isDataLoadingStatus = new BehaviorSubject<LcsProgressButtonStatus>(LcsProgressButtonStatus.InProgress);
   }

   ngOnInit() {
      this.buildLocationList();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   buildLocationList() {
      this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         this.queryParam = this.locationService.toLowerCaseParams(queryParams);
         if (this.queryParam["locations"] && this.queryParam["locations"] !== null) {
            this.locationList.push("locations=" + this.queryParam["locations"]);
         }
         if (this.queryParam["locationid"] && this.queryParam["locationid"] !== null) {
            this.locationId = this.queryParam["locationid"].split(",");
         }
      });

      this.locationService
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         .getLocationCollection(null, null, null, null, null, null, this.locationList)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (result) => {
               if (result && result.length > 0) {
                  this.locationModelList = result;
                  this.locationsQueryStringValuesService.LocationsValue = result.map((l) => l.FriendlyName).join(",");
                  this.authModel.IsLocationInfoMissing = false;
                  this.getPreferenceValue();
                  if (result.length === 1) {
                     this.authModel.LocationId = this.locationModelList[0].LocationID;
                     this.authlocationId.next(this.authModel.LocationId);
                     this.authModel.IsShowLocations = false;
                  }
                  if (result.length > 1) {
                     this.authModel.IsShowLocations = true;
                     if (
                        this.locationId.length === 1 &&
                        result.find((a) => a.LocationID.toString() === this.locationId[0])
                     ) {
                        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                        this.selectedlocation = this.authModel.LocationId = parseInt(this.locationId[0]);
                        this.authlocationId.next(this.authModel.LocationId);
                     } else {
                        this.selectedlocation = result.find((a) => a.IsMainLocation)
                           ? // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                             result.find((a) => a.IsMainLocation).LocationID
                           : result[0].LocationID;
                        this.authModel.LocationId = this.selectedlocation;
                        this.authlocationId.next(this.authModel.LocationId);
                     }
                  }
               } else {
                  this.authlocationId.next(1); // default location id ,in case no locations come back from server
                  this.authModel.Message = " No locations are enabled ";
                  this.errorMessage.next(" No locations are enabled ");
               }
               this.isDataLoadingStatus.next(LcsProgressButtonStatus.Unset);
            },
            (error) => {
               this.isDataLoadingStatus.next(LcsProgressButtonStatus.Error);
               this.errorMessageService.triggerErrorMessage("Unable to load locations");
            }
         );
   }

   getPreferenceValue() {
      this.systemWebPreferenceService
         .GetRequireLocationInfoInURLOWA()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((prefernce) => {
            if (prefernce) {
               this.RequireLocationInfoInURLOWAPreference = prefernce.Value;
               if (this.RequireLocationInfoInURLOWAPreference === "True") {
                  if (
                     (this.locationList.length <= 0 && this.locationId.length <= 0) ||
                     this.locationModelList.length <= 0
                  ) {
                     this.authModel.IsLocationInfoMissing = true;
                     this.authModel.Message = "Unexpected Error Occurred. Please contact support.";
                     this.errorMessage.next("Unexpected Error Occurred. Please contact support.");
                  }
               }
            }
         });
   }

   setLocation(location: number) {
      if (location) {
         this.locationModelList.forEach((res) => {
            if (res) {
               if (res.LocationID === location) {
                  this.authModel.LocationId = res.LocationID;
                  this.authlocationId.next(this.authModel.LocationId);
               }
            }
         });
      }
   }
}
