<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   > Associated 1099 Categories
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="tab-wrapper">
   <div class="single-tab-wrapper">
      <div class="flex-space-between">
         <lcs-radio-button
            [(ngModel)]="selectedCategoryGroup"
            (ngModelChange)="onCategoryGroupChange($event)"
            name="NECorMISC"
            [value]="necCategoryID"
            label="Nonemployee Compensation (NEC)"
            [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
         >
         </lcs-radio-button>
         <lcs-radio-button
            [(ngModel)]="selectedCategoryGroup"
            (ngModelChange)="onCategoryGroupChange($event)"
            name="NECorMISC"
            [value]="0"
            label="Other Compensation (MISC)"
            [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
         >
         </lcs-radio-button>
      </div>
      <div class="single-tab-wrapper">
         <lcs-single-line-multi-select
            #selectorcomponent
            [name]="name"
            [displayName]="'Associated 1099 Category'"
            [allSelectedMessage]="'**All 1099 Categories'"
            [valueSource]="valueSource"
            [ngModel]="selectedValues"
            [lcsValidate]="validation"
            [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true) || selectedCategoryGroup > 0"
            (selectionChange)="selectionChange = $event"
            (panelClose)="selectionChanged()"
         ></lcs-single-line-multi-select>
      </div>
   </div>
</div>