<div class="report-parameter-label">

   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && asOfDateReportParameter.IsAsk === true)"
   >
      {{ asOfDateReportParameter?.DisplayName}}
   </label>
   <div class="report-parameter-link-wrapper">
      <lcs-link
         [text]="'Date'"
         (clicked)="isRunByPeriodChanged(false)"
         [ngClass]="{'secondary-parameter-label':isRunByPeriod}"
      ></lcs-link>
      <lcs-link
         [text]="'Period'"
         (clicked)="isRunByPeriodChanged(true)"
         [ngClass]="{'secondary-parameter-label':!isRunByPeriod}"
      ></lcs-link>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="asOfDateReportParameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
</div>
<lcs-relative-date-picker
   *ngIf="!isRunByPeriod"
   [name]="name"
   [displayName]="displayName + ' Date'"
   [disabled]="disabled || (hasAsk === true && asOfDateReportParameter.IsAsk === true)"
   [isRelative]="showRelativeOption"
   [ngModel]="relativeDateValue"
   [validation]="validation"
   (ngModelChange)="updateRelativeDatePicker($event)"
>
</lcs-relative-date-picker>
<div
   *ngIf="isRunByPeriod"
   class="tab-wrapper"
   [class.disabled]="(hasAsk === true && asOfDateReportParameter.IsAsk === true)"
>
   <div class="single-tab-wrapper">
      <div>
         <label>Series</label>
         <lcs-select
            lcsStaticSetSelector
            #seriesSelector
            [name]="'accounting-series-selector-' + uniqueId"
            [disabled]="disabled || (showRelativeOption && relativePeriodSelected) "
            [(ngModel)]="selectedSeriesID"
            [items]="seriesData"
            (ngModelChange)="onAccountingSeriesChange($event)"
         >
         </lcs-select>
      </div>
      <div>
         <label>Year</label>
         <lcs-select
            lcsStaticSetSelector
            #yearSelector
            [name]="'accounting-year-selector-' + uniqueId"
            [displayName]="displayName + ' Year'"
            [disabled]="disabled || (showRelativeOption && relativePeriodSelected) "
            [(ngModel)]="selectedYearID"
            [items]="yearData"
            [lcsValidate]="validation"
            (ngModelChange)="onAccountingYearChange($event)"
         >
         </lcs-select>
      </div>
      <div>
         <label>Period</label>
         <lcs-select
            lcsStaticSetSelector
            #periodSelector
            [name]="'accounting-period-selector-' + uniqueId"
            [displayName]="displayName + ' Period'"
            [disabled]="disabled || (showRelativeOption && relativePeriodSelected) || !periodData "
            [(ngModel)]="selectedPeriodID"
            [items]="periodData"
            [lcsValidate]="validation"
            (ngModelChange)="onAccountingPeriodChange($event)"
         >
         </lcs-select>
      </div>

      <div *ngIf="showRelativeOption">
         <lcs-checkbox
            [ngModel]="relativePeriodSelected"
            [name]="'accounting-relative-period-checkbox-' + uniqueId"
            [disabled]="disabled "
            (ngModelChange)="onRelativePeriodCheckChanged($event)"
         >
            Use Relative Period
         </lcs-checkbox>
         <lcs-select
            lcsStaticSetSelector
            *ngIf="relativePeriodSelected"
            [name]="'accounting-period-options-selector-' + uniqueId"
            [disabled]="disabled "
            [ngModel]="selectedRelativePeriodOptionID"
            [items]="relativePeriodOptionsData"
            [lcsValidate]="validation"
            (ngModelChange)="onRelativePeriodChanged($event)"
         >
         </lcs-select>
      </div>
   </div>
</div>