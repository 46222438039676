export enum DateRangePickerOption {
   PrevDay = 0,
   Today = 1,
   NextDay = 3,
   PrevWeek = 4,
   ThisWeek = 5,
   WeekToDate = 6,
   NextWeek = 7,
   PrevMonth = 8,
   ThisMonth = 9,
   MonthToDate = 10,
   NextMonth = 11,
   PrevYear = 12,
   ThisYear = 13,
   YearToDate = 14,
   NextYear = 15,
}
