import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { ConstantsService } from "@lcs/core/constants.service";
import { IndexedDBService } from "@lcs/indexed-db/indexed-db.service";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { valueOrThrow } from "@lcs/utils/strict-null-utils";
import difference from "lodash/difference";
import { PrivilegeType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege-type.enum";
import { Privilege } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ExpressUserDefinedFieldService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/express-user-defined-field.service";
import { UsersService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/users.service";
import { CurrentService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current.service";
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, switchMap, take, takeUntil } from "rxjs";

import { ApiService } from "../core/api.service";
import { ReportModel } from "../models/generated/report.model";
import { UserPrivilegeModel } from "../models/generated/user-privilege.model";
import { UserModel } from "../models/generated/user.model";
import { CurrentUserModel } from "./current-user.model";
import { SessionStatusService } from "./session-status.service";

@Injectable()
export class CurrentUserService implements SessionCacheProvider, OnDestroy {
   cacheKey = "CurrentUserService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject(false);

   currentUser: BehaviorSubject<CurrentUserModel | null> = new BehaviorSubject(null);

   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private cacheMonitorService: CacheMonitorService,
      private usersService: UsersService,
      private indexedDBService: IndexedDBService,
      private localStorageService: LocalStorageService,
      private sessionStatusService: SessionStatusService,
      private currentService: CurrentService,
      private expressUserDefinedFieldService: ExpressUserDefinedFieldService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean): void {
      this.currentUser.next(null);
      this.cachePopulated.next(false);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
         this.indexedDBService.deleteByKey(IndexedDBService.IDBLocalStorage, this.cacheKey);
      }
   }

   public populateCache(): void {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      this.indexedDBService
         .getByKey<CurrentUserModel>(IndexedDBService.IDBLocalStorage, this.cacheKey)
         .pipe(
            catchError((error) => {
               console.warn(`IDB: Failed to load "${this.cacheKey}" from cache. -- `, error);
               return of(null); // if error attempting to load from cache, load from server
            }),
            switchMap((storedValue: CurrentUserModel | null): Observable<CurrentUserModel> => {
               if (storedValue) {
                  const currentUserModel: CurrentUserModel = storedValue;
                  return of(currentUserModel); // return cached data
               } else {
                  return forkJoin([
                     this.getCurrentUserRequest(),
                     this.getCurrentUserReportIDsRequest(),
                     this.expressUserDefinedFieldService.getGetReadOnlyUserDefinedFieldIDsCollection(),
                  ]).pipe(
                     switchMap(
                        ([currentUserModel, reportIDs, readOnlyUDFIDs]: [
                           CurrentUserModel,
                           number[],
                           Array<number>
                        ]): Observable<CurrentUserModel> => {
                           currentUserModel.ReportIDs = reportIDs;
                           this.processCurrentUser(currentUserModel);
                           currentUserModel.lsManagerReadOnlyUDFIDs = readOnlyUDFIDs;
                           if (currentUserModel) {
                              // cache value returned from the server
                              this.indexedDBService
                                 .updateByKey(IndexedDBService.IDBLocalStorage, currentUserModel, this.cacheKey)
                                 .pipe(takeUntil(this.unsubscribe))
                                 .subscribe({
                                    next: (): void => {
                                       // Also save a hashedValue to localStorage for change detection/synching
                                       this.localStorageService.setItemToValueHashCode(this.cacheKey, currentUserModel);
                                    },
                                 });
                           }
                           return of(currentUserModel); // return data retrieved from server
                        }
                     ),
                     takeUntil(this.unsubscribe)
                  );
               }
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe({
            next: (currentUserModel: CurrentUserModel): void => {
               this.currentUser.next(currentUserModel);
               this.cachePopulated.next(true);
            },
            error: (): void => {
               this.cacheMonitorService.reportError(this.cacheKey);
               this.cachePopulated.next(false);
            },
         });
   }

   hasPrivilege(privilege: Privilege, privilegeType: PrivilegeType | null): boolean {
      if (!this.currentUser.value) {
         return false;
      }
      return this.currentUser.value.UserPrivileges.some((privilegemodel: UserPrivilegeModel) => (
            privilegemodel.PrivilegeID === privilege &&
            ((privilegeType === PrivilegeType.On && privilegemodel.IsOn) ||
               (privilegeType === PrivilegeType.View && privilegemodel.IsView) ||
               (privilegeType === PrivilegeType.Add && privilegemodel.IsAdd) ||
               (privilegeType === PrivilegeType.Edit && privilegemodel.IsEdit) ||
               (privilegeType === PrivilegeType.Delete && privilegemodel.IsDelete))
         ));
   }

   hasProperties(propertyIds: Array<number>): boolean {
      const arrPropIDs = Array.from(valueOrThrow(this.currentUser.value, "Current User").userPropertyIDs.values());
      if (arrPropIDs) {
         if (arrPropIDs.length === 1 && arrPropIDs[0] === ConstantsService.NullFK) {
            return true;
         } else {
            return difference(propertyIds, arrPropIDs).length === 0;
         }
      } else {
         return false;
      }
   }

   hasAccessToAtLeastOneProperty(propertyIds: Array<number>): boolean {
      const arrPropIDs = Array.from(valueOrThrow(this.currentUser.value, "Current User").userPropertyIDs.values());
      if (arrPropIDs) {
         if (arrPropIDs.length === 1 && arrPropIDs[0] === ConstantsService.NullFK) {
            return true;
         } else {
            return !(difference(propertyIds, arrPropIDs).length === propertyIds.length);
         }
      } else {
         return false;
      }
   }

   hasReports(reportIds: Array<number>): boolean {
      const currentUser: CurrentUserModel = valueOrThrow(this.currentUser.value, "Current User");
      if (currentUser.ReportIDs) {
         if (currentUser.ReportIDs.length === 1 && currentUser.ReportIDs[0] === ConstantsService.NullFK) {
            return true;
         } else {
            return difference(reportIds, currentUser.ReportIDs).length === 0;
         }
      } else {
         return false;
      }
   }

   /**
    * Emits a single value based on value of currentUser behavior subject and completes.
    * Effectively a synchronous call.
    */
   getPrivileges(privileges: Array<Privilege>): Observable<Map<Privilege, UserPrivilegeModel>> {
      return this.currentUser.pipe(
         map((currentUser) => {
            const results = new Map<Privilege, UserPrivilegeModel>();
            const userPrivileges = currentUser?.UserPrivileges;

            for (const privilege of privileges) {
               const cachedPrivilege = userPrivileges?.find((p) => p.PrivilegeID === privilege);
               if (cachedPrivilege) {
                  results.set(privilege, cachedPrivilege);
               }
            }

            return results;
         }),
         take(1) // WARNING: removing this take(1) requires updating the code that calls getPrivileges which currently expect it to emit a single value and complete
      );
   }

   private getCurrentUserReportIDsRequest(): Observable<number[]> {
      const fields = ["ReportID"];
      return this.apiService
         .directGet(this.currentService.getEffectiveUserReportsCollectionUrl(null, null, null, fields))
         .pipe(
            map((response) => {
               let reports = new Array<ReportModel>();
               if (response && response.body) {
                  reports = response.body as ReportModel[];
               }
               const reportIDs = reports.map((report) => report.ReportID);

               // These two reports are decorated with IgnoreRunReportPrivilege in the RM12 Solution
               // But there is no way to retrieve that info via the API
               // Jason Weber has already been informed, and the issue will be addressed by his team
               reportIDs.push(Report.Invoice);
               reportIDs.push(Report.Estimate);
               reportIDs.push(Report.PurchaseOrder);
               reportIDs.push(Report.JournalDetailPrint);
               reportIDs.push(Report.MemorizedJournalDetailPrint);
               reportIDs.push(Report.CheckPrint);
               reportIDs.push(Report.RecurringBillWorksheet);
               reportIDs.push(Report.RecurringJournalDetailPrint);
               reportIDs.push(Report.STRReservations);
               reportIDs.push(Report.LoanPayments);
               reportIDs.push(Report.ArchitecturalRequestDetailPrint);
               reportIDs.push(Report.CashPayCard);

               return reportIDs;
            })
         );
   }

   private processCurrentUser(user: CurrentUserModel): void {
      user.userPropertyIDs = new Set<number>();
      if (user.UserProperties) {
         user.userPropertyIDs = new Set<number>(user.UserProperties.map((up) => up.PropertyID));
      }
      user.hasAllProperties = user.userPropertyIDs.has(ConstantsService.NullFK);

      let userBankIDs = new Set<number>();
      if (user.UserBanks) {
         userBankIDs = new Set<number>(user.UserBanks.map((ub) => ub.BankID));
      }
      user.hasAllBanks = userBankIDs.has(ConstantsService.NullFK);
   }

   private getCurrentUserRequest(): Observable<CurrentUserModel> {
      const additionalParams = ["serializeall=true"];
      return this.apiService
         .directGet(this.usersService.getCurrentUserExtUrl(), additionalParams)
         .pipe(map((response) => response.body));
   }

   getSelfAndSubordinatesRecursively(loadedUserList: Array<UserModel>): Array<UserModel> {
      const alreadyAddedList = new Array<UserModel>();
      const currentUserID = this.currentUser.value?.UserID;
      if (currentUserID) {
         const currentUser = loadedUserList.find((f) => f.UserID === currentUserID);
         if (currentUser) {
            alreadyAddedList.push(currentUser);
         }
         this.getSelfAndSubordinatesRecursivelyIncremental(currentUserID, loadedUserList, alreadyAddedList);
      }

      return alreadyAddedList;
   }

   private getSelfAndSubordinatesRecursivelyIncremental(
      rootUserID: number,
      loadedUserList: Array<UserModel>,
      alreadyAddedList: Array<UserModel>
   ) {
      const subordinates = loadedUserList.filter((f) => f.SupervisorID === rootUserID);
      if (subordinates?.length > 0) {
         subordinates.forEach((subordinate) => {
            const user = alreadyAddedList.find((f) => f.UserID === subordinate.UserID);
            if (user) {
               //user was already found - we don't need to add again
               return;
            }
            alreadyAddedList.push(subordinate);
            this.getSelfAndSubordinatesRecursivelyIncremental(subordinate.UserID, loadedUserList, alreadyAddedList);
         });
      }
   }
}
