import { CdkFooterRowDef } from "@angular/cdk/table";
import { Directive, Input } from "@angular/core";

@Directive({
   selector: "[lcsFooterRowDef]",
   providers: [{ provide: CdkFooterRowDef, useExisting: LcsFooterRowDefDirective }],
})
export class LcsFooterRowDefDirective extends CdkFooterRowDef {
   @Input("lcsFooterRowDef") set allColumns(columns: string[]) {
      this._allColumns = columns;
      this.columns = columns;
   }
   get allColumns(): string[] {
      return this._allColumns;
   }

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsFooterRowDefSticky") sticky: boolean;

   private _allColumns: string[];
}
