import { Component, Input } from "@angular/core";
import { ControlContainerViewProvider } from "@lcs/inputs/control-container-view-providers";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { ReportParameterValueModel } from "@lcs/reports/report-parameters/models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "@lcs/reports/report-parameters/models/report-report-parameter.viewmodel";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { ValueComparerHelper } from "@lcs/select/helpers/value-comparer.helper";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { PropertyFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property.fields";
import { UnitFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/unit.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PropertiesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/properties.service";
import { forkJoin, Subject, takeUntil } from "rxjs";

@Component({
   selector: "owa-property-unit-multi-selector-report-parameter",
   templateUrl: "property-unit-multi-selector-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class PropertyUnitMultiSelectorReportParameterComponent {
   @Input() hasAsk: boolean;

   @Input() name: string;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
      this.initializeReportParameterValue();
   }

   entityType = EntityType;

   // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   property: PropertyModel;

   propertyCollectionLabel: string;

   propertySingleLabel: string;

   propertyFields: EntityField[];

   propertyFilters: FilterOption[];

   propertySelectorComparer: (v1: PropertyModel, v2: PropertyModel) => boolean;

   returnInactiveProperties: boolean = false;

   rootPath: string = ObjectMapResolverService.rootPath;

   selectedUnits: number[];

   unitCollectionLabel: string;

   unitFilters: FilterOption[];

   private propertyFilter: FilterOption;

   private reportParameterValueModel: ReportParameterValueModel;

   private unitFilter: FilterOption;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private entityViewInformationService: EntityViewInformationServiceBase,
      private propertiesService: PropertiesService,
      private reportParametersService: ReportParametersService
   ) {
      this.propertyFields = [PropertyFields.Name, PropertyFields.PropertyID];
      this.propertyFilter = new FilterOption(PropertyFields.IsActive, FilterOperations.EqualTo, [true]);
      this.unitFilter = new FilterOption(UnitFields.PropertyID, FilterOperations.EqualTo, null);

      forkJoin([
         this.entityViewInformationService.getViewInformation(EntityType.Property),
         this.entityViewInformationService.getViewInformation(EntityType.Unit),
      ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(([propertyViewInfo, unitViewInfo]: [EntityViewInformationModel, EntityViewInformationModel]) => {
            this.propertySingleLabel = propertyViewInfo.SingleLabel;
            this.propertyCollectionLabel = propertyViewInfo.CollectionLabel;
            this.unitCollectionLabel = unitViewInfo.CollectionLabel;
         });

      this.propertyFilters = [this.propertyFilter];
      const propertyFilterExpression = new FilterExpression();
      propertyFilterExpression.FilterOptions = this.propertyFilters;
      const propertyOrderingOption = new OrderingOption(PropertyFields.Name);

      this.propertiesService
         .getSearchCollection(propertyFilterExpression, null, null, [propertyOrderingOption], this.propertyFields, 1, 0)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((properties: PropertyModel[]) => {
            if (properties && properties.length > 0) {
               const property = properties[0];
               this.property = property;
               this.propertyChanged(this.property);
            }
         });

      this.propertySelectorComparer = (v1: PropertyModel, v2: PropertyModel) => {
         return ValueComparerHelper.comparerNullCheck(v1, v2) && v1.PropertyID === v2.PropertyID;
      };
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   inactivePropertiesChanged(value: boolean) {
      if (value) {
         this.propertyFilters = [];
      } else {
         this.propertyFilter.Values = [true];
         this.propertyFilters = [this.propertyFilter];
      }
   }

   propertyChanged(property: PropertyModel) {
      if (property.PropertyID) {
         const propertyFilter = this.unitFilter;
         propertyFilter.Values = [property.PropertyID];
         this.unitFilters = [propertyFilter];
      } else {
         this.unitFilters = [];
      }
   }

   unitIDsChanged(selectedUnits: number[]) {
      this.reportParameterValueModel.value = "(" + selectedUnits.join(",") + ")";
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );
   }
}
