import { Injectable } from "@angular/core";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { LocationSortOrderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/location-sort-order.embed-options";
import { LocationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/location.embed-options";
import { LocationsPostingAutomationWorkflowEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/locations-posting-automation-workflow.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { LocationSortOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location-sort-order.model";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { LocationsPostingAutomationWorkflowModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/locations-posting-automation-workflow.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class LocationsService {
   static readonly CurrentLocationResource = "CurrentLocation";
   static readonly GetByPostResource = "GetByPost";
   static readonly LocationSortOrderResource = "LocationSortOrder";
   static readonly PostingAutomationWorkflowsResource = "PostingAutomationWorkflows";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly UsersResource = "Users";
   static readonly endpoint = "LocationsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCurrentLocationUrl(
      embeds?: Array<string | LocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/CurrentLocation",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCurrentLocation(
      embeds?: Array<string | LocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationModel> {
      return this.apiService.getSingle(this.getCurrentLocationUrl(embeds, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         LocationsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<LocationModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getLocationSortOrderUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/LocationSortOrder",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLocationSortOrder(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationSortOrderModel> {
      return this.apiService.getSingle(
         this.getLocationSortOrderUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLocationSortOrderCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/LocationSortOrder",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLocationSortOrderCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationSortOrderModel>> {
      return this.apiService.getCollection(
         this.getLocationSortOrderCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllLocationSortOrderCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/LocationSortOrder",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllLocationSortOrderCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationSortOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationSortOrderModel>> {
      return this.apiService.getCollection(
         this.getAllLocationSortOrderCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPostingAutomationWorkflowsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationsPostingAutomationWorkflowEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/PostingAutomationWorkflows",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPostingAutomationWorkflows(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationsPostingAutomationWorkflowEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationsPostingAutomationWorkflowModel> {
      return this.apiService.getSingle(
         this.getPostingAutomationWorkflowsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPostingAutomationWorkflowsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationsPostingAutomationWorkflowEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/PostingAutomationWorkflows",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPostingAutomationWorkflowsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationsPostingAutomationWorkflowEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationsPostingAutomationWorkflowModel>> {
      return this.apiService.getCollection(
         this.getPostingAutomationWorkflowsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         LocationsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         LocationsService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getUsersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/Users",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsers(
      id: number,
      itemID: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUsersUrl(id, itemID, embeds, additionalParameters));
   }

   getUsersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         LocationsService.endpoint + "/Users",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUsersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
}
