import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LinkModule } from "@lcs/link/link.module";
import { TooltipModule } from "primeng/tooltip";

import { SidebarMenuPageComponent } from "./sidebar-menu-page/sidebar-menu-page.component";
import { SidebarMenuComponent } from "./sidebar-menu/sidebar-menu.component";

@NgModule({
   imports: [
      CommonModule,
      LinkModule,
      TooltipModule,
   ],
   declarations: [
      SidebarMenuComponent,
      SidebarMenuPageComponent,
   ],
   exports: [
      SidebarMenuComponent,
   ],
})
export class SidebarMenuModule { }
