import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-management-fees-list-view",
   templateUrl: "./management-fees-list-view.component.html",
})
export class ManagementFeeListViewComponent implements OnInit, OnDestroy {
   showPassThru: boolean;
   isLoaded: BehaviorSubject<boolean>;

   private unsubscribe = new Subject<void>();

   constructor(private systemWebPreferenceSessionService: SystemWebPreferencesSessionService) {
      this.isLoaded = new BehaviorSubject<boolean>(false);
   }

   ngOnInit() {
      this.isLoaded.next(false);
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (result) => {
            if (result) {
               this.showPassThru =
                  result.find((a) => a.Name === "ShowPassthruFeesOWA") != null
                     ? result.find((a) => a.Name === "ShowPassthruFeesOWA")?.Value.toLowerCase() === "true"
                        ? true
                        : false
                     : false;
               this.isLoaded.next(true);
            }
         }
      );
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
