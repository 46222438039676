<lcs-table [dataSource]="estimateDetailViewItems">
   <tr
      lcs-header-row
      *lcsHeaderRowDef="estimateDetailsColumns; sticky: false"
   ></tr>
   <tr
      lcs-row
      *lcsRowDef="let row; columns: estimateDetailsColumns"
   ></tr>
   <ng-container
      lcsColumnDef="Property"
      [width]="15"
      [priority]="1"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Property</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >{{ row.Property?.Name }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Unit"
      [width]="6"
      [priority]="2"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Unit</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >{{ row.Unit?.Name }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Item"
      [width]="15"
      [priority]="3"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Item</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >{{ row.InventoryItem?.Name }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Description"
      [width]="15"
      [priority]="4"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Description</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >{{ row.Description }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Quantity"
      [width]="5"
      [priority]="5"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Quantity</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="numeric"
      >{{ row.Quantity }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Price"
      [width]="5"
      [priority]="6"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Price</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="numeric"
      >{{ row.Price | currency }}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Total"
      [width]="8"
      [priority]="7"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Total</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="numeric"
      >{{ row.TotalValue | currency }}</td>
   </ng-container>
</lcs-table>