import { Injectable } from "@angular/core";
import { ExpressContextHelp } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-context-help.enum";
import { BehaviorSubject, Subject } from "rxjs";

import { ActionBarActionModel } from "./action-bar-action.model";
import { ActionBarConfigurationModel } from "./action-bar-configuration.model";

@Injectable({
   providedIn: "root",
})
export class ActionBarService {
   actionBarHelp: BehaviorSubject<ExpressContextHelp>;

   actionBarModelSubject: BehaviorSubject<ActionBarConfigurationModel>;

   actionBarVisibilitySubject: BehaviorSubject<boolean>;

   deleteButtonVisibilitySubject: BehaviorSubject<boolean>;

   actionBarCustomActions = new BehaviorSubject<Array<ActionBarActionModel> | null>(null);

   actionBarStandardAndCustomActions = new BehaviorSubject<Array<ActionBarActionModel> | null>(null);

   actionBarStandardActions = new Subject<void>();

   disableFavorite: BehaviorSubject<boolean>;

   disableActions: BehaviorSubject<boolean>;

   clearActions = new Subject<void>();

   hideActions: BehaviorSubject<boolean>;

   addTopPadding: BehaviorSubject<boolean>;

   private configuration: ActionBarConfigurationModel;

   constructor() {
      this.actionBarHelp = new BehaviorSubject(ExpressContextHelp.NotSet);
      this.configuration = new ActionBarConfigurationModel();
      this.actionBarModelSubject = new BehaviorSubject(this.configuration);
      this.actionBarVisibilitySubject = new BehaviorSubject(false);
      this.deleteButtonVisibilitySubject = new BehaviorSubject(false);
      this.disableFavorite = new BehaviorSubject(false);
      this.disableActions = new BehaviorSubject(false);
      this.hideActions = new BehaviorSubject(false);
      this.addTopPadding = new BehaviorSubject(false);
   }

   initializeActionBar(configuration: ActionBarConfigurationModel) {
      this.configuration = configuration;
      this.actionBarModelSubject.next(configuration);
      this.actionBarVisibilitySubject.next(true);
      this.deleteButtonVisibilitySubject.next(false);
      this.actionBarHelp.next(configuration.help);
   }

   initializeCustomActions(actions: Array<ActionBarActionModel>) {
      this.actionBarCustomActions.next(actions);
   }

   initializeStandardAndCustomActions(actions: Array<ActionBarActionModel>) {
      this.actionBarStandardAndCustomActions.next(actions);
   }

   initializeStandardActions() {
      this.actionBarStandardActions.next();
   }
}
