import { ImageTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/image-type.model";

export class ImageCarouselModel {
   imageID: number;
   source: string;
   caption: string;
   alt: string;
   topStyle: string;
   leftStyle: string;
   isLoading: boolean = true;
   isInFailedToLoadState: boolean;
   imageType: ImageTypeModel;
}
