import { CdkHeaderRowDef } from "@angular/cdk/table";
import { Directive, Input, OnChanges } from "@angular/core";

@Directive({
   selector: "[lcsHeaderRowDef]",
   providers: [{ provide: CdkHeaderRowDef, useExisting: LcsHeaderRowDefDirective }],
})
export class LcsHeaderRowDefDirective extends CdkHeaderRowDef implements OnChanges {
   @Input("lcsHeaderRowDef") set allColumns(columns: string[]) {
      this._allColumns = columns;
      this.columns = columns;
   }
   get allColumns(): string[] {
      return this._allColumns;
   }

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsHeaderRowDefSticky") sticky: boolean;

   private _allColumns: string[];
}
