import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type.model";
import { ChargeTypesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/charge-types.service";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-cam-charge-type-report-parameter",
   templateUrl: "cam-charge-type-report-parameter.component.html",
})
export class CAMChargeTypeReportParameterComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   chargeTypes = new Array<SelectorItemModel>();

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private chargeeTypesService: ChargeTypesService
   ) {}

   ngOnInit() {
      this.initializeValidation();
      this.initializeReportParameterValue();
      this.initializeChargeTypes();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   onChargeTypeChange(value: number) {
      this.reportParameterValueModel.value = +value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   private initializeValidation() {
      this.validation = new ValidationModel();
      this.validation.required = true;
   }

   private initializeChargeTypes() {
      this.chargeeTypesService
         .getCollection([new FilterOption("IsCam", FilterOperations.EqualTo, [true])])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            this.processCollection(results);
         });
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }

   private processCollection(results: Array<ChargeTypeModel>) {
      const items = new Array<SelectorItemModel>();
      if (results) {
         for (const chargeType of results) {
            const item = new SelectorItemModel();
            item.displayValue = `${chargeType.Name} - ${chargeType.Description}`;
            item.value = chargeType.ChargeTypeID;
            items.push(item);
         }
      }
      this.chargeTypes = items.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      if (items.length > 0 && (!this.reportParameterValueModel.value || +this.reportParameterValueModel.value <= 0)) {
         this.onChargeTypeChange(items[0].value);
      }
   }
}
