import { Injectable, OnDestroy } from "@angular/core";
import { valueOrThrow } from "@lcs/utils/strict-null-utils";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ReportFieldDataType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-field-data-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ReportBatchReportParameterValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-batch-report-parameter-value.model";
import { ReportParameterValueModel as ReportParameterValueModelGenerated } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value.model";
import { BehaviorSubject, debounceTime, Subject, takeUntil } from "rxjs";

import { CurrentLocationService } from "../../session/current-location.service";
import { isAllValueType } from "./all-value.type";
import { ReportParameterControlStatusModel } from "./models/report-parameter-control-status.model";
import { ReportParameterValueModel } from "./models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "./models/report-report-parameter.viewmodel";
import { ReportParameterDependentAskService } from "./report-parameter-dependent-ask.service";

// TODO - this entire service and the report parameters component needs to be replaced with a report container service
@Injectable()
export class ReportParametersService implements OnDestroy {
   static allValue: string = "All";

   updateParameterValue: Subject<ReportParameterValueModel> = new Subject<ReportParameterValueModel>();

   reportParameterUpdated: Subject<ReportParameterValueModel> = new Subject<ReportParameterValueModel>();

   reportParameterStatusChanged: Subject<ReportParameterControlStatusModel> =
      new Subject<ReportParameterControlStatusModel>();

   reportParameterValues = new Map<ReportParameter, ReportParameterValueModel>();

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
   propertyOrOwnerActiveIndex = new BehaviorSubject<number>(undefined);

   private errorMessages = new Map<ReportParameter, string>();

   private reportParameterAsk = new Map<ReportParameter, boolean>();

   private unsubscribe: Subject<void> = new Subject<void>();

   constructor(
      private currentLocationService: CurrentLocationService,
      private reportParameterDependentAskService: ReportParameterDependentAskService
   ) {
      this.updateParameterValue
         .pipe(debounceTime(500), takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.updateReportParameterValue(updatedValue));
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   initialize(reportReportParameters: Array<ReportReportParameterViewModel>): void {
      this.reportParameterValues = new Map<ReportParameter, ReportParameterValueModel>();
      reportReportParameters.forEach((parameter) => {
         let defaultValue;
         if (parameter.ReportParameterValueSource) {
            defaultValue = parameter.ReportParameterValueSource.DefaultValue;
         }
         const reportParameterValueModel = new ReportParameterValueModel(parameter.ReportParameterID, defaultValue);
         this.reportParameterValues.set(parameter.ReportParameterID, reportParameterValueModel);
         this.reportParameterAsk.set(parameter.ReportParameterID, parameter.IsAsk);
      });
      const index = reportReportParameters.findIndex(
         (p) => p.ReportParameterID === ReportParameter.IncludeAvidInvoices
      );
      if (index > -1) {
         if (!this.currentLocationService.currentLocation.value.IsAvidPay) {
            reportReportParameters.splice(index, 1);
         }
      }
   }

   updateReportParameterValue(updatedValue: ReportParameterValueModel) {
      if (this.reportParameterValues.has(updatedValue.reportParameter)) {
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.reportParameterValues.get(updatedValue.reportParameter).value = updatedValue.value;
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.reportParameterValues.get(updatedValue.reportParameter).rawValues = updatedValue.rawValues;
      } else {
         this.reportParameterValues.set(updatedValue.reportParameter, updatedValue);
      }
      this.reportParameterUpdated.next(
         valueOrThrow(
            this.reportParameterValues.get(updatedValue.reportParameter),
            `Failed to obtain value for Report Parameter "${ReportParameter[updatedValue.reportParameter]}"`
         )
      );
   }

   convertReportReportParameterViewModelsToReportParameterValueModels(
      reportReportParameters: Array<ReportReportParameterViewModel>
   ): Array<ReportParameterValueModelGenerated> {
      const reportParameterValues: Array<ReportParameterValueModelGenerated> =
         new Array<ReportParameterValueModelGenerated>();
      if (!reportReportParameters) {
         // guard against null/undefined to be safe until we have better strict null coverage
         return reportParameterValues;
      }

      // if ignoreCustIDsParamIfAllValue is set true, we will not push CUSTIDS parameter onto returned Array.
      // This fixes issue where one or more PropertyIDs were selected, but CUSTIDS was set to "All" which
      // was causing the selected properties to be ignore on the server.  Initially we are only setting
      // this if there is a PropertyIDs report param, but additional conditions could be added if needed.
      const ignoreCustIDsParamIfAllValue: boolean = reportReportParameters.some(
         (m: ReportReportParameterViewModel) => m.ReportParameterID === ReportParameter.PropertyIDs
      );

      this.reportParameterValues.forEach((parameter: ReportParameterValueModel): void => {
         const model: ReportParameterValueModelGenerated = new ReportParameterValueModelGenerated();
         model.Parameter = parameter.reportParameter;
         if (parameter && parameter.value != null) {
            let value = parameter.value;
            const reportReportParameter: ReportReportParameterViewModel | undefined = reportReportParameters.find(
               (p) => p.ReportParameterID === parameter.reportParameter
            );
            if (
               reportReportParameter &&
               !reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
               parameter.value.toString().length === 0
            ) {
               return;
            }
            if (reportReportParameter) {
               const isCollection =
                  reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
                  value.toString().startsWith("(") &&
                  value.toString().endsWith(")");
               if (reportReportParameter.DataType === ReportFieldDataType.String && !isCollection) {
                  value = `"${value}"`;
               }
               if (isCollection) {
                  value = value.toString().replace("(", "").replace(")", "");
               }
            }
            if (
               value != null &&
               value !== "" &&
               !(model.Parameter === ReportParameter.CUSTIDS && ignoreCustIDsParamIfAllValue && isAllValueType(value))
            ) {
               model.Value = value;
               reportParameterValues.push(model);
            }
         }
      });
      return reportParameterValues;
   }

   processReportParameterValues(
      reportReportParameters: Array<ReportReportParameterViewModel>,
      allowEmptyValues: boolean = false
   ): Array<string> {
      const reportParameterValues = new Array<string>();
      this.reportParameterValues.forEach((parameter: ReportParameterValueModel): void => {
         if (parameter && parameter.value !== undefined && parameter.value !== null) {
            let value = parameter.value;
            const reportReportParameter: ReportReportParameterViewModel | undefined = reportReportParameters.find(
               (p: ReportReportParameterViewModel): boolean => p.ReportParameterID === parameter.reportParameter
            );
            // allowEmptyValues MUST EXIST for report batches to accept prompt parameters
            if (allowEmptyValues && parameter.value.toString().length === 0) {
               reportParameterValues.push(parameter.reportParameter + ',""');
               return;
            }
            if (
               reportReportParameter &&
               !reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
               parameter.value.toString().length === 0
            ) {
               return;
            }
            if (reportReportParameter) {
               const isCollection =
                  reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
                  value.toString().startsWith("(") &&
                  value.toString().endsWith(")");
               if (reportReportParameter.DataType === ReportFieldDataType.String && !isCollection) {
                  value = `"${value}"`;
               }
            }
            if (value != null && value !== "") {
               reportParameterValues.push(parameter.reportParameter + "," + value);
            }
         }
      });
      return reportParameterValues;
   }

   processReportBatchReportParameterValues(
      reportReportParameters: Array<ReportReportParameterViewModel>,
      allowEmptyValues: boolean = false
   ): Array<ReportBatchReportParameterValueModel> {
      const reportBatchReportParameterValues = new Array<ReportBatchReportParameterValueModel>();
      this.reportParameterValues.forEach((parameter) => {
         if (parameter && parameter.value !== undefined && parameter.value != null) {
            let value = parameter.value;
            const reportReportParameter = reportReportParameters.find(
               (p) => p.ReportParameterID === parameter.reportParameter
            );
            // allowEmptyValues MUST EXIST for report batches to accept prompt parameters
            if (allowEmptyValues && parameter.value.toString().length === 0) {
               const parameterValue = new ReportBatchReportParameterValueModel();
               parameterValue.Parameter = parameter.reportParameter;
               parameterValue.Value = "";
               reportBatchReportParameterValues.push(parameterValue);
               return;
            }
            if (
               reportReportParameter &&
               !reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
               parameter.value.toString().length === 0
            ) {
               return;
            }
            if (reportReportParameter) {
               const isCollection =
                  reportReportParameter.ReportParameterValueSource.AllowsMultipleValues &&
                  value.toString().startsWith("(") &&
                  value.toString().endsWith(")");
               if (reportReportParameter.DataType === ReportFieldDataType.String && !isCollection) {
                  value = `"${value}"`;
               }
            }
            if (value != null && value !== "") {
               const parameterValue = new ReportBatchReportParameterValueModel();
               parameterValue.Parameter = parameter.reportParameter;
               parameterValue.Value = value;
               reportBatchReportParameterValues.push(parameterValue);
            }
         }
      });
      return reportBatchReportParameterValues;
   }

   getRequiredPromptValues(currentValues: Array<ReportReportParameterViewModel>): Array<string> {
      const requiredPromptValues = new Array<string>();
      if (
         [
            ReportParameter.PropOwnerIDs,
            ReportParameter.PROPOWNERIDSALL,
            ReportParameter.PropertyIDs,
            ReportParameter.PIDSALL,
         ].some((rp) => currentValues.some((v) => v.ReportParameterID === rp && v.IsAsk))
      ) {
         if (!currentValues.some((v) => v.ReportParameterID === ReportParameter.USEOWNERPROPERTIES)) {
            requiredPromptValues.push(`${ReportParameter.USEOWNERPROPERTIES},false`);
         }
         if (!currentValues.some((v) => v.ReportParameterID === ReportParameter.GroupID)) {
            requiredPromptValues.push(`${ReportParameter.GroupID},-1`);
         }
      }
      return requiredPromptValues;
   }

   getRequiredReportBatchPromptValues(
      currentValues: Array<ReportReportParameterViewModel>
   ): Array<ReportBatchReportParameterValueModel> {
      const requiredPromptValues = new Array<ReportBatchReportParameterValueModel>();
      if (
         [
            ReportParameter.PropOwnerIDs,
            ReportParameter.PROPOWNERIDSALL,
            ReportParameter.PropertyIDs,
            ReportParameter.PIDSALL,
         ].some((rp) => currentValues.some((v) => v.ReportParameterID === rp && v.IsAsk))
      ) {
         if (!currentValues.some((v) => v.ReportParameterID === ReportParameter.USEOWNERPROPERTIES)) {
            const useOwnerProperties = new ReportBatchReportParameterValueModel();
            useOwnerProperties.Parameter = ReportParameter.USEOWNERPROPERTIES;
            useOwnerProperties.Value = false.toString();
            requiredPromptValues.push(useOwnerProperties);
         }
         if (!currentValues.some((v) => v.ReportParameterID === ReportParameter.GroupID)) {
            const group = new ReportBatchReportParameterValueModel();
            group.Parameter = ReportParameter.GroupID;
            group.Value = ConstantsService.NullFK.toString();
            requiredPromptValues.push(group);
         }
      }
      return requiredPromptValues;
   }

   updateReportParameterAsk(reportParameterID: ReportParameter, isAsk: boolean) {
      this.reportParameterAsk.set(reportParameterID, isAsk);
      if (this.reportParameterDependentAskService.dependentAskParameters.has(reportParameterID)) {
         this.updateDependentReportParameterAsk(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameter[] | undefined' i... Remove this comment to see the full error message
            this.reportParameterDependentAskService.dependentAskParameters.get(reportParameterID),
            isAsk
         );
      }
   }

   updateDependentReportParameterAsk(dependentParameterIDs: Array<ReportParameter>, isAsk: boolean) {
      for (const parameterID of dependentParameterIDs) {
         if (this.reportParameterAsk.has(parameterID)) {
            this.reportParameterAsk.set(parameterID, isAsk);
         }
      }
   }

   getReportParameterAsk(reportParameterID: ReportParameter): boolean {
      const value = this.reportParameterAsk.get(reportParameterID);
      return value === true;
   }

   reportParameterHasZeroAsDefaultValue(reportParameter: ReportParameter) {
      // the following report parameters actually use zero as the default value
      // the bl incorrectly sets others to zero when they shouldnt be, which breaks validation
      const zeroDefaultReportParameters = [ReportParameter.ISSUELISTSTOINCLUDE, ReportParameter.VENDORSACCNO];

      return zeroDefaultReportParameters.indexOf(reportParameter) > -1;
   }

   getErrorMessages(): Map<ReportParameter, string> {
      return this.errorMessages;
   }

   addErrorMessage(reportParameter: ReportParameter, errorMessage: string) {
      this.errorMessages.set(reportParameter, errorMessage);
   }

   removeErrorMessage(reportParameter: ReportParameter) {
      this.errorMessages.delete(reportParameter);
   }

   clearErrorMessages() {
      this.errorMessages.clear();
   }
}
