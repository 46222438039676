////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressSystemSettingField {
   NotSet = 0,
   EmailLastFromName = 1,
   EmailLastFromAddress = 2,
   EpayReturnLastCheckDate = 3,
   EpayReturnLastUpdateDate = 4,
   EpayPayleaseConvenienceFeeDate = 6,
   EpayPayleaseSurchargeAmount = 7,
   EpayPayleaseCreditCardAmount = 8,
   EpayPayleaseACHAmount = 9,
   EpayDepositReconcileLastRunDate = 10,
   PostLateFeesChargeIDs = 11,
   PostLateFeesIsOverrideSettings = 12,
   WebUsernameConvertByDate = 13,
   LCSRemoteSalesTaxTypeID = 14,
   EstimateStartingNumberUserHasBeenPrompted = 15,
   ExpressUserHasTakenTour = 16,
   HistoryShowAllApptTasks = 17,
   HistoryOpenMaintenance = 18,
   HistoryClosedMaintenance = 19,
   DashboardNote = 20,
   NSFFee = 21,
   UseEpayReturnDate = 22,
   NSFAddHistoryItem = 23,
   ExpressDepositPrintOption = 24,
   ExpressTenantStatusQuickFilter = 25,
   ExpressTenantHistoryUsersQuickFilter = 26,
   ExpressTenantHistoryCategoryQuickFilter = 27,
   ExpressTenantHistoryTypeQuickFilter = 28,
   ExpressTenantHistoryUnitNoteQuickFilter = 29,
   ExpressTenantHistoryHasAttachmentQuickFilter = 30,
   ExpressProspectHistoryUsersQuickFilter = 31,
   ExpressProspectHistoryCategoryQuickFilter = 32,
   ExpressProspectHistoryTypeQuickFilter = 33,
   ExpressProspectHistoryUnitNoteQuickFilter = 34,
   ExpressProspectHistoryHasAttachmentQuickFilter = 35,
   ExpressProspectHistoryAllApptQuickFilter = 36,
   ExpressPropertiesRegisterShowInactiveQuickFilter = 37,
   ExpressUnitRegisterShowInactiveQuickFilter = 38,
   ExpressProspectInterestedPropertiesUnitsShowInterestedOnlyQuickFilter = 39,
   ExpressProspectInterestedPropertiesUnitsShowWaitListQuickFilter = 40,
   ExpressTenantTransactionCollapseInvoicesQuickFilter = 41,
   ExpressProspectTransactionCollapseInvoicesQuickFilter = 42,
   ExpressUnitHistoryUsersQuickFilter = 44,
   ExpressUnitHistoryCategoryQuickFilter = 45,
   ExpressUnitHistoryTypeQuickFilter = 46,
   ExpressUnitHistoryTenantNoteQuickFilter = 47,
   ExpressUnitHistoryHasAttachmentQuickFilter = 48,
   ExpressPropertyHistoryUsersQuickFilter = 49,
   ExpressPropertyHistoryCategoryQuickFilter = 50,
   ExpressPropertyHistoryTypeQuickFilter = 51,
   ExpressPropertyHistoryHasAttachmentQuickFilter = 52,
   ExpressSignableDocumentRegisterStatusQuickFilter = 53,
   ExpressServiceManagerProjectRegisterShowMyProjectsOnlyQuickFilter = 54,
   ExpressServiceManagerProjectRegisterShowCompletedOnlyQuickFilter = 55,
   ExpressApplicationRegisterIncludeAssociatedApplicationsQuickFilter = 56,
   ExpressApplicationRegisterApplicationDateQuickFilter = 57,
   ExpressPurchaseOrderRegisterStatusQuickFilter = 58,
   ExpressEstimatesRegisterStatusQuickFilter = 59,
   ExpressEstimatesRegisterShowActiveOnlyQuickFilter = 60,
   ExpressInvoiceRegisterIsFullyAllocatedQuickFilter = 61,
   ExpressScreeningRegisterScreeningTypeQuickFilter = 62,
   ExpressServiceManagerIssueHistoryUsersQuickFilter = 63,
   ExpressServiceManagerIssueHistoryCategoryQuickFilter = 64,
   ExpressServiceManagerIssueHistoryTypeQuickFilter = 65,
   ExpressServiceManagerIssueHistoryHasAttachmentQuickFilter = 66,
   ExpressOwnerHistoryOwnerProspectNoteQuickFilter = 68,
   ExpressTenantHistoryAccountGroupNoteQuickFilter = 69,
   ExpressOwnerTransactionCollapseInvoicesQuickFilter = 70,
   ExpressBankRegisterBankQuickFilter = 71,
   ExpressProspectRegisterStatusQuickFilter = 72,
   ExpressProspectRegisterLeasingAgentQuickFilter = 73,
   ExpressPayBillsSelectedProperties = 74,
   ExpressPayBillsSelectedPropertyGroup = 75,
   ExpressPayBillsSelectedOwners = 76,
   ExpressPayBillsSelectedOwnerGroup = 77,
   ExpressPayBillsPropOwnerTabIndex = 78,
   ExpressPayBillsShowInactiveProperties = 79,
   ExpressBankRegisterDateQuickFilter = 80,
   ExpressPayBillsConsolidateByVendor = 81,
   ExpressPayBillsPrintChecks = 82,
   ExpressCreditCardRegisterCreditCardQuickFilter = 83,
   ExpressCreditCardRegisterDateQuickFilter = 84,
   ExpressVendorRegisterShowInactiveQuickFilter = 85,
   ExpressCheckRegisterDateQuickFilter = 86,
   ExpressBillRegisterPaidQuickFilter = 87,
   ExpressJournalRegisterDateQuickFilter = 88,
   CurrentTaxYear = 89,
   ExpressVendorCreditRegisterDateQuickFilter = 90,
   ExpressVendorCreditRegisterOpenCreditsQuickFilter = 91,
   ExpressAssetRegisterShowInactiveQuickFilter = 93,
   ExpressAssetHistoryMaintenanceQuickFilter = 94,
   ExpressAssetHistoryUsersQuickFilter = 95,
   ExpressAssetHistoryCategoryQuickFilter = 96,
   ExpressAssetHistoryTypeQuickFilter = 97,
   ExpressAssetHistoryHasAttachmentQuickFilter = 98,
   ExpressChartOfAccountsRegisterShowInactiveQuickFilter = 99,
   ExpressReconciliationAccountType = 100,
   ExpressReconciliationAccount = 101,
   ExpressReconciliationDateFilter = 102,
   ExpressReconciliationLastCompletedOnly = 103,
   ExpressReconciliationChangesRegisterGLAccountQuickFilter = 104,
   ExpressPrintChecksBank = 105,
   ExpressPrintChecksChangeCheckDate = 106,
   ExpressPrintChecksShowMemo = 107,
   ExpressPrintChecksShowProperty = 108,
   ExpressPrintChecksShowUnit = 109,
   ExpressPrintChecksShowAccountNumber = 110,
   ExpressPrintChecksRenumberAllChecks = 111,
   ExpressPrintChecksRenumberUnNumberedChecks = 112,
   ManagementFeePostType = 113,
   PaymentMethodForPostMgtFees = 114,
   ExpressJobRegisterShowInactiveQuickFilter = 115,
   ExpressPrintChecksWithWorksheets = 116,
   ExpressMakeReadyBoardPropertiesQuickFilter = 117,
   ExpressMakeReadyBoardDaysToShowQuickFilter = 118,
   ExpressPostRecurringBillsSelectedProperties = 119,
   ExpressInvoiceRegisterShowOpenOnlyQuickFilter = 120,
   ExpressPostRecurringBillsSelectedPropertyGroup = 121,
   ExpressPostRecurringBillsSelectedOwners = 122,
   ExpressPostRecurringBillsSelectedOwnerGroup = 123,
   ExpressPostRecurringBillsPropOwnerTabIndex = 124,
   ExpressPostRecurringBillsShowInactiveProperties = 125,
   BankReconciliationExcludeReconcileDate = 126,
   CreditCardReconciliationExcludeReconcileDate = 127,
   ExpressPostRecurringJournalsSelectedProperties = 130,
   ExpressPostRecurringJournalsSelectedPropertyGroup = 131,
   ExpressPostRecurringJournalsSelectedOwners = 132,
   ExpressPostRecurringJournalsSelectedOwnerGroup = 133,
   ExpressPostRecurringJournalsPropOwnerTabIndex = 134,
   ExpressPostRecurringJournalsShowInactiveProperties = 135,
   ExpressGPRPostingHistoryProperty = 136,
   ExpressMakeReadyBoardSortQuickFilter = 137,
   ExpressColorEntityTypeQuickFilter = 138,
   ExpressGPRPostingHistoryPostDate = 140,
   ExpressUserDefinedFieldsRegisterEntityType = 143,
   ExpressRmVoipCallHistoryDaysToShowQuickFilter = 144,
   ExpressUnitRegisterShowVacantUnitsOnlyQuickFilter = 145,
   ExpressBatchPaymentsPopulateAvailableTenants = 147,
   ExpressBatchPaymentsTenantStatus = 148,
   ExpressBatchPaymentsDefaultPaymentToFullAmountOwed = 149,
   ExpressTenantPaymentCollapseInvoices = 150,
   ExpressProspectPaymentCollapseInvoices = 151,
   ImportPaymentDepositReportType = 152,
   ExpressUserRegisterShowInactiveQuickFilter = 153,
   ExpressPushPrivilegesOptions = 155,
   ExpressPushPrivilegesPrivileges = 156,
   ExpressPushPrivilegesReports = 157,
   ExpressPushPrivilegesMessagingIDs = 158,
   ExpressOwnerPayHideColumns = 159,
   ExpressChecksRegisterBankFilter = 160,
   ExpressPostingRollbackRegisterDateFilter = 161,
   ExpressViolationCategoryRegisterShowInactiveQuickFilter = 162,
   ExpressViolationCodeGroupDetailsShowInactiveQuickFilter = 163,
   ExpressHistorySearchTypeFilter = 164,
   ExpressTransactionSearchTypeFilter = 165,
   ExpressPostingRollbackRegisterTypeFilter = 166,
   ExpressLetterTemplateRegisterEntityType = 167,
   ExpressPaymentAccountGroupFilter = 168,
   ExpressReservationRegisterPropertyFilter = 169,
   ExpressRecurringChargesSearchTypeFilter = 170,
   ExpressForecastModelShowInactiveFilter = 171,
   ExpressCheckListTemplateShowInactiveFilter = 172,
   ExpressRecurringChargesSearchOptions = 173,
   ExpressRecurringChargesSearchChargeTypes = 174,
   BatchEmailChkCombine = 175,
   BatchEmailChkAddToHistory = 176,
   BatchEmailChkAllOccupants = 177,
   BatchEmailChkAddSignature = 178,
   ExpressTenantAccountGroupTransactionsQuickFilter = 179,
   ExpressLinkedAssetsShowHistoryQuickFilter = 180,
   ExpressInspectionStatusQuickFilter = 181,
   ExpressUDFSearchEntityFilter = 185,
   ExpressUDFSearchNameFilter = 186,
   ExpressViolationCommunicationNeededQuickFilter = 187,
   ExpressViolationClosedQuickFilter = 188,
   AssetWorkflowActiveQuickFilter = 189,
   PostUtilitiesPostZeroCharges = 190,
   ExpressAppointmentRegisterDateQuickFilter = 192,
   ExpressTaskRegisterDateQuickFilter = 194,
   ExpressTaskRegisterHistoryFollowUpQuickFilter = 195,
   ExpressTaskRegisterOpenOnlyQuickFilter = 196,
   PhoneBroadcastNumber = 197,
   ExpressArchitecturalRequestStatusQuickFilter = 199,
   ExpressArchitecturalRequestPropertyQuickFilter = 200,
   ExpressAssociationCommitteeShowInactiveQuickFilter = 201,
   ExpressBatchPaymentsV2DefaultPaymentToFullAmountOwed = 202,
   ExpressBatchPaymentsDefaultMemo = 203,
   ExpressBatchPaymentsIncludePastTenantsInSearch = 204,
   ExpressBatchPaymentsIncludeCurrentTenantsInSearch = 205,
   ExpressBatchPaymentsIncludeFutureTenantsInSearch = 206,
   ExpressBatchPaymentsIncludeProspectsInSearch = 207,
   ExpressBatchPaymentsSelectedPropertyIds = 208,
   ExpressBatchPaymentsSelectedPropertyGroupId = 209,
   ExpressPollsDaysToShowQuickFilter = 210,
   ExpressLoanRegisterShowOpenOnlyQuickFilter = 211,
   ExpressShowAllBoardMembersQuickFilter = 212,
   ShowRentersInsuranceSystemSettings = 213,
   ExpressSavedPropertyFilter = 214,
   ExpressShowAllCommitteeMembersQuickFilter = 215,
   ExpressArchitecturalRequestDetailVoteSummaryFilter = 216,
   ExpressSavedTenantFilter = 217,
   PostManagementFeesHideColumns = 218,
   ExpressCommunityCalendarPropertyFilter = 221,
   ExpressNonRecurringCRECustomerDivisionMethod = 222,
   ExpressNonRecurringCREAmountDivisionMethod = 223,
   ExpressNonRecurringCREProRataShare = 224,
   ExpressNonRecurringCREAdminFees = 225,
   ExpressNonRecurringCREUDFFieldForPercentage = 226,
   ExpressNonRecurringCREUDFPropFieldForPercentage = 227,
   ExpressNonRecurringCREUDFCustFieldForPercentage = 228,
   ExpressNonRecurringCREBudgetGLAccount = 229,
   ExpressNonRecurringCREExpensesFrom = 230,
   ExpressNonRecurringCRESubBaseAmt2 = 231,
   ExpressNonRecurringCREIsCash = 232,
   ExpressNonRecurringCREIncreaseByValue = 233,
   ExpressNonRecurringCREIncreaseByAmount = 234,
   ExpressNonRecurringCREAnnualizedMonthlyAvg = 235,
   ExpressNonRecurringCRESubBaseAmt = 236,
   ExpressNonRecurringCREOptionValue = 237,
   ExpressNonRecurringCREMonthsAgo = 238,
   ExpressGuestCardTemplateTypeQuickFilter = 240,
   ExpressSDIRate = 241,
   ExpressSDIMethod = 242,
   ExpressSDIDisbursement = 243,
   ExpressSDICredit = 244,
   ExpressSDITable = 245,
   ExpressSDIIsOverride = 246,
   AddHistoryOption = 247,
   CrpPreparedByAgentChecked = 248,
   CrpPreparerNameToUse = 249,
   CrpDefaultPhoneToUse = 250,
   CrpOverrideDefaultPhoneChecked = 251,
   CrpOverrideDefaultPhone = 252,
   CrpNameToUse = 253,
   CrpAddressToUse = 254,
   CrpDefaultPhone = 255,
   CrpOverridePhoneChecked = 256,
   CrpOverridePhone = 257,
   CrpNameToUse2019 = 258,
   CrpAddressToUse2019 = 259,
   CrpPhone2019 = 260,
}
