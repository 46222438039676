export type FilterValueType = string | number | boolean | Date;

export function isFilterValueType(value: unknown): value is FilterValueType {
   if (typeof value === "string" || typeof value === "number" || typeof value === "boolean" || value instanceof Date) {
      return true;
   }
   return false;
}

export type FilterValueDateType = string | number | Date;

export function isFilterValueDateType(value: unknown): value is FilterValueDateType {
   if (typeof value === "string" || typeof value === "number" || value instanceof Date) {
      return true;
   }
   return false;
}

export function isFilterValueTypeArray(values: Array<unknown>): values is Array<FilterValueType> {
   return values.every((value) => isFilterValueType(value));
}
