import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ControlTypeAdapterService } from "@lcs/datatable/control-type-adapter.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { AccountSignupUserDefinedFieldViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/account-signup-userdefinedfield-view.model";
import { AccountSignupViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/account-signup-view.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { AccountSignupService } from "projects/libraries/owa-gateway-sdk/src/lib/services/account-signup.service";
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from "rxjs";

import { LocationComponent } from "../authentication/rm-location/rm-location.component";
import { LocationService } from "../authentication/rm-location/rm-location.service";
import { TopBarService } from "../top-bar/top-bar.service";

@Component({
   selector: "owa-account-signup",
   templateUrl: "./account-signup.component.html",
   styleUrls: ["./account-signup.component.css"],
})
export class AccountSignUpComponent implements OnInit, OnDestroy {
   public authenticated: boolean;
   public userName: string;
   public signupModel = new AccountSignupViewModel();
   phoneNumberValidation: ValidationModel;
   taxNumberValidation: ValidationModel;
   shorttaxNumberValidation: ValidationModel;
   PropertyID: number;
   button1Status: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   button2Status: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   @ViewChild(LocationComponent, { static: true }) locationComponent;
   isLoaded: boolean;
   controlType1SelectorValueSource: ValueSourceModel;
   controlType2SelectorValueSource: ValueSourceModel;
   controlType3SelectorValueSource: ValueSourceModel;

   logInButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   public controlType1: ExpressLayoutControlTypes;
   public controlType2: ExpressLayoutControlTypes;
   public controlType3: ExpressLayoutControlTypes;
   public test: string;
   public validationUDF1: ValidationModel;
   public validationUDF2: ValidationModel;
   public validationUDF3: ValidationModel;
   public errorMessageUDF1: string;
   public errorMessageUDF2: string;
   public errorMessageUDF3: string;
   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
   public errorMessage: string = null;
   public phoneNumber: string;
   public phoneNumberModel = new PhoneNumberModel();
   public emailValidationModel: ValidationModel;
   public loginUrl: string;
   public queryParameter: Params;
   public showTextingDisclaimer: boolean;
   public textingCompanyName: string | undefined;
   public textingPrivacyPolicyLink: string | undefined;
   public textingToSPolicyLink: string | undefined;
   showReCaptcha = new Observable<boolean>();
   private _showReCaptcha = new BehaviorSubject<boolean>(false);
   private unsubscribe = new Subject<void>();

   constructor(
      private errorMessageService: ErrorMessageService,
      private router: Router,
      private accountcreationService: AccountSignupService,
      private sessionCheckService: SessionCheckService,
      private topbarservice: TopBarService,
      private cacheMonitorService: CacheMonitorService,
      private activatedRoute: ActivatedRoute,
      private locationService: LocationService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
      this.showReCaptcha = this._showReCaptcha.asObservable();
   }

   ngOnInit() {
      this.validationUDF1 = new ValidationModel();
      this.validationUDF2 = new ValidationModel();
      this.validationUDF3 = new ValidationModel();
      const emailRegexp: RegExp =
         /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
      this.emailValidationModel = new ValidationModel();
      this.emailValidationModel.required = true;
      this.emailValidationModel.pattern = emailRegexp;

      this.isLoaded = false;

      this.locationComponent.authlocationId.pipe(takeUntil(this.unsubscribe)).subscribe((locationid) => {
         if (locationid != null) {
            this.accountcreationService
               .getSignupDetails(locationid)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((response) => {
                  if (response) {
                     this.signupModel = response;
                     this._showReCaptcha.next(true);
                     // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
                     this.signupModel.AccountNumber = null;
                     this.signupModel.LocationId = locationid;
                     this.isLoaded = true;
                     if (response.ProfilePhoneNumbers.length > 0) {
                        this.phoneNumber = response.ProfilePhoneNumbers[0].PhoneNumber;
                     }

                     if (this.signupModel.RequireUserDefinedValue1 === true) {
                        if (
                           this.signupModel.UserDefinedValue1 != null &&
                           this.signupModel.UserDefinedValue1.FieldType != null
                        ) {
                           this.validationUDF1.required = true;
                           this.controlType1 = ControlTypeAdapterService.convertUdfTypeToControlType(
                              this.signupModel.UserDefinedValue1.FieldType
                           );
                           this.test = ExpressLayoutControlTypes[this.controlType1];
                           if (
                              this.signupModel.UserDefinedValue1.FieldType === UserDefinedFieldType.YesNo ||
                              this.signupModel.UserDefinedValue1.FieldType === UserDefinedFieldType.Dropdown
                           ) {
                              this.controlType1 = ExpressLayoutControlTypes.TextBox;
                              this.controlType1SelectorValueSource = this.valueSource(
                                 this.signupModel.UserDefinedValue1
                              );
                           }
                           if (this.signupModel.UserDefinedValue1.FieldType === UserDefinedFieldType.File) {
                              this.validationUDF1.required = false;
                              this.errorMessageUDF1 = "File Not Supported";
                           }
                           if (this.signupModel.UserDefinedValue1.FieldType === UserDefinedFieldType.Image) {
                              this.validationUDF1.required = false;
                              this.errorMessageUDF1 = "Image Not Supported";
                           }
                        }
                     }
                     if (this.signupModel.RequireUserDefinedValue2 === true) {
                        if (this.signupModel.UserDefinedValue2 != null) {
                           this.validationUDF2.required = true;
                           this.controlType2 = ControlTypeAdapterService.convertUdfTypeToControlType(
                              this.signupModel.UserDefinedValue2.FieldType
                           );
                           if (
                              this.signupModel.UserDefinedValue2.FieldType === UserDefinedFieldType.YesNo ||
                              this.signupModel.UserDefinedValue2.FieldType === UserDefinedFieldType.Dropdown
                           ) {
                              this.controlType2 = ExpressLayoutControlTypes.TextBox;
                              this.controlType2SelectorValueSource = this.valueSource(
                                 this.signupModel.UserDefinedValue2
                              );
                           }
                           if (this.signupModel.UserDefinedValue2.FieldType === UserDefinedFieldType.File) {
                              this.validationUDF2.required = false;
                              this.errorMessageUDF2 = "File Not Supported";
                           }
                           if (this.signupModel.UserDefinedValue2.FieldType === UserDefinedFieldType.Image) {
                              this.validationUDF2.required = false;
                              this.errorMessageUDF2 = "Image Not Supported";
                           }
                        }
                     }

                     if (this.signupModel.RequireUserDefinedValue3 === true) {
                        if (this.signupModel.UserDefinedValue3 != null) {
                           this.validationUDF3.required = true;
                           this.controlType3 = ControlTypeAdapterService.convertUdfTypeToControlType(
                              this.signupModel.UserDefinedValue3.FieldType
                           );
                           if (
                              this.signupModel.UserDefinedValue3.FieldType === UserDefinedFieldType.YesNo ||
                              this.signupModel.UserDefinedValue3.FieldType === UserDefinedFieldType.Dropdown
                           ) {
                              this.controlType3 = ExpressLayoutControlTypes.TextBox;
                              this.controlType3SelectorValueSource = this.valueSource(
                                 this.signupModel.UserDefinedValue3
                              );
                           }
                           if (this.signupModel.UserDefinedValue3.FieldType === UserDefinedFieldType.File) {
                              this.validationUDF3.required = false;
                              this.errorMessageUDF3 = "File Not Supported";
                           }
                           if (this.signupModel.UserDefinedValue3.FieldType === UserDefinedFieldType.Image) {
                              this.validationUDF3.required = false;
                              this.errorMessageUDF3 = "Image Not Supported";
                           }
                        }
                     }
                  }
               });
         }
      });

      this.locationComponent.errorMessage
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((message) => (this.errorMessage = message));

      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });

      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         this.loginUrl = "/login";
         if (queryParams != null) {
            this.queryParameter = this.locationService.toLowerCaseParams(queryParams);
            if (
               this.queryParameter["locations"] != null &&
               this.queryParameter["locations"] !== undefined &&
               this.queryParameter["locations"] !== ""
            ) {
               this.loginUrl += "?locations=" + this.queryParameter["locations"];
            }
            if (
               this.queryParameter["locationid"] != null &&
               this.queryParameter["locationid"] !== undefined &&
               this.queryParameter["locationid"] !== ""
            ) {
               if (this.loginUrl.includes("locations")) {
                  this.loginUrl += "&";
               } else {
                  this.loginUrl += "?";
               }
               this.loginUrl += "locationId=" + this.queryParameter["locationid"];
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
      this.topbarservice.topBarIsVisible.next(this.cacheMonitorService.cachesLoadedState);
   }

   valueSource(model: AccountSignupUserDefinedFieldViewModel): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.EmbeddedSet;
      valueSource.EmbeddedSet = { embeddedSet: model.Values };
      valueSource.EmbeddedSetValueSourcePath = "embeddedSet";
      return valueSource;
   }

   signupClick(form: NgForm): void {
      if (form.valid) {
         this.button1Status = LcsProgressButtonStatus.InProgress;
         if (this.signupModel.ProfilePhoneNumbers.length > 0) {
            this.signupModel.ProfilePhoneNumbers[0].PhoneNumber = this.phoneNumber;
         } else {
            this.phoneNumberModel.PhoneNumber = this.phoneNumber;
            this.signupModel.ProfilePhoneNumbers.push(this.phoneNumberModel);
         }
         this.accountcreationService
            .signup(this.signupModel)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (response) => {
                  if (response) {
                     this.signupModel = response;
                     if (this.signupModel.WorkflowSteps === WorkflowSteps[WorkflowSteps.MergeUsers]) {
                        this.router.navigateByUrl("/mergeusers?token=" + encodeURIComponent(this.signupModel.Token));
                     } else if (this.signupModel.WorkflowSteps === WorkflowSteps[WorkflowSteps.ResendVerification]) {
                        this.router.navigateByUrl("/resendverification");
                     } else {
                        this.router.navigateByUrl("/password/verifypending");
                     }
                  }
               },
               (_err) => {
                  this.errorMessageService.triggerHttpErrorMessage(_err);
                  this.button1Status = LcsProgressButtonStatus.Error;
                  grecaptcha.reset();
               }
            );
      } else {
         this.button1Status = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   cancelClick() {
      this.button2Status = LcsProgressButtonStatus.InProgress;
      this.router.navigateByUrl(this.loginUrl);
   }

   onValueChangeUDF1(val: any) {
      this.signupModel.UserDefinedValue1.Value = val;
   }

   onValueChangeUDF2(val: any) {
      this.signupModel.UserDefinedValue2.Value = val;
   }

   onValueChangeUDF3(val: any) {
      this.signupModel.UserDefinedValue3.Value = val;
   }
}
