import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityRequestEndpointInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { forkJoin, map, Observable, of, switchMap } from "rxjs";

import { ApiService } from "../core/api.service";
import { EntityType } from "../enumerations/generated/entity-type.enum";
import { FilterExpression } from "../models/filter-expression.model";

@Injectable({
   providedIn: "root",
})
export class EntityRequestService {
   constructor(
      private apiService: ApiService,
      private entityRequestEndpointService: EntityRequestEndpointServiceBase
   ) {}

   deleteSingle(entityType: EntityType, id: number): Observable<HttpResponse<any>> {
      return this.getRootResource(entityType).pipe(
         switchMap((endpoint) => {
            const additionalParameters = this.apiService.buildGetSingleParameterArray(id);
            return this.apiService.deleteSingle(endpoint, additionalParameters);
         })
      );
   }

   getSingleEntity(
      entityType: EntityType,
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      return this.getRootResource(entityType).pipe(
         switchMap((endpoint) => {
            return this.apiService.getSingle(endpoint, id, embeds, fields, additionalParameters);
         })
      );
   }

   getSingleEntityResource(
      entityType: EntityType | null,
      id: number,
      resourceEntityType?: EntityType,
      embeds?: Array<string>,
      fields?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      return this.getSingleEntityResourceUrl(entityType, id, resourceEntityType).pipe(
         switchMap((endpoint) => {
            return this.apiService.getSingle(endpoint, id, embeds, fields, additionalParameters);
         })
      );
   }

   getSingleEntityResourceUrl(
      entityType: EntityType | null,
      id: number,
      resourceEntityType?: EntityType
   ): Observable<string> {
      let request: Observable<any>;

      if (resourceEntityType) {
         request = this.getRootResource(resourceEntityType);
      } else {
         request = of(null);
      }

      return request.pipe(
         switchMap((resourceEndpoint) => {
            if (resourceEndpoint) {
               return this.getEntityEndpoint(entityType, resourceEndpoint, id);
            } else {
               return this.getEntityEndpoint(entityType, null, id);
            }
         })
      );
   }

   deleteSingleEntityResouce(entityType: EntityType, id: number, resource?: string, itemID?: number, ids?: number[]) {
      return this.getEntityEndpoint(entityType, resource, id).pipe(
         switchMap((endpoint: string) => {
            const additionalParameters = this.apiService.buildParameterArray(null, itemID, ids);
            return this.apiService.delete(endpoint, additionalParameters);
         })
      );
   }

   deleteCollection(
      entityType: EntityType,
      resource: string,
      id: number,
      ids?: Array<number>,
      deleteOptions?: Array<string>
   ) {
      return this.getEntityEndpoint(entityType, resource, id).pipe(
         switchMap((endpoint: string) => {
            const additionalParameters = this.apiService.buildDeleteCollectionParameterArray(ids);
            if (deleteOptions && deleteOptions.length > 0) {
               additionalParameters.push("deleteOptions=" + deleteOptions.join(";"));
            }
            return this.apiService.deleteCollection(endpoint, null, additionalParameters);
         })
      );
   }

   getCollectionUrl(
      entityType: EntityType,
      entityID?: number | null,
      resource?: string | null,
      embeds?: Array<string> | null,
      filterExpression?: FilterExpression | null,
      orderingOptions?: Array<string> | null,
      getOptions?: Array<string> | null,
      fields?: Array<string> | null,
      pageSize?: number,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null
   ): Observable<string> {
      return forkJoin([
         this.getEntityEndpoint(entityType, resource, entityID),
         this.entityRequestEndpointService.getEndpointInformation(entityType),
      ]).pipe(
         switchMap(([endpoint, requestInformation]) => {
            if (!resource && requestInformation && requestInformation.IsSearchEnabled) {
               additionalParameters = this.apiService.buildSearchCollectionParameterArrayFromGetParameters(
                  filterExpression,
                  embeds,
                  orderingOptions,
                  fields,
                  pageSize,
                  pageNumber,
                  additionalParameters
               );
            } else {
               additionalParameters = this.apiService.buildGetCollectionParameterArray(
                  filterExpression,
                  embeds,
                  orderingOptions,
                  fields,
                  pageSize,
                  pageNumber,
                  additionalParameters,
                  getOptions
               );
            }

            const url = this.apiService.getUrl(
               endpoint,
               null,
               null,
               null,
               null,
               null,
               null,
               null,
               null,
               null,
               null,
               null,
               additionalParameters
            );

            return of(url);
         })
      );
   }

   getCollectionResponse(
      entityType: EntityType,
      entityID?: number | null,
      resource?: string | null,
      embeds?: Array<string> | null,
      filterExpression?: FilterExpression | null,
      orderingOptions?: Array<string> | null,
      getOptions?: Array<string> | null,
      fields?: Array<string> | null,
      pageSize?: number,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null
   ): Observable<HttpResponse<any>> {
      return this.getCollectionUrl(
         entityType,
         entityID,
         resource,
         embeds,
         filterExpression,
         orderingOptions,
         getOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      ).pipe(
         switchMap((endpoint: string) => {
            return this.apiService.get(endpoint);
         })
      );
   }

   getCollection(
      entityType: EntityType,
      entityID?: number | null,
      resource?: string | null,
      embeds?: Array<string> | null,
      filterExpression?: FilterExpression | null,
      orderingOptions?: Array<string> | null,
      getOptions?: Array<string> | null,
      fields?: Array<string>,
      pageSize?: number,
      pageNumber?: number | null,
      additionalParameters?: Array<string> | null
   ): Observable<any> {
      return this.getCollectionResponse(
         entityType,
         entityID,
         resource,
         embeds,
         filterExpression,
         orderingOptions,
         getOptions,
         fields,
         pageSize,
         pageNumber,
         additionalParameters
      ).pipe(
         map((response) => {
            return response.body;
         })
      );
   }

   getEntityEndpoint(
      entityType: EntityType | null,
      resource?: string | null,
      entityID?: number | null,
      isQuickSearch: boolean = false
   ): Observable<string> {
      return this.entityRequestEndpointService.getEndpointInformation(entityType).pipe(
         map((endpointInfo) => {
            return this.buildEndpoint(endpointInfo, resource, entityID, isQuickSearch);
         })
      );
   }

   buildEndpoint(
      endpointInformation: EntityRequestEndpointInformationModel,
      resource?: string | null,
      entityID?: number | null,
      isQuickSearch: boolean = false
   ): string {
      let endpoint = endpointInformation.APIUrl;

      if (entityID) {
         endpoint += `/${entityID}`;
      }
      if (resource) {
         endpoint += `/${resource}`;
      }

      let searchEndpoint = "Search";
      if (isQuickSearch) {
         searchEndpoint = "QuickSearch";
      }
      if (endpointInformation && endpointInformation.IsSearchEnabled && !resource && !entityID) {
         endpoint = `${endpointInformation.APIUrl}/${searchEndpoint}`;
      }
      return endpoint;
   }

   getEntityPostEndpoint(
      entityType: EntityType,
      resource?: string | null,
      entityID?: number | null
   ): Observable<string> {
      return this.entityRequestEndpointService.getEndpointInformation(entityType).pipe(
         map((endpointInfo) => {
            return this.buildPostEndpoint(endpointInfo, resource, entityID);
         })
      );
   }

   buildPostEndpoint(
      endpointInformation: EntityRequestEndpointInformationModel,
      resource?: string | null,
      entityID?: number | null
   ): string {
      let endpoint = endpointInformation.APIUrl;

      if (resource) {
         if (entityID) {
            endpoint += `/${entityID}`;
         }
         endpoint += `/${resource}`;
      }
      return endpoint;
   }

   getEndpoint(entityType: EntityType, entity: string, resource: string, entityID: number): Observable<string> {
      return this.entityRequestEndpointService.getEndpointInformation(entityType).pipe(
         map((endpointInformation) => {
            let endpoint = "";
            if (endpointInformation) {
               endpoint += endpointInformation.APIUrl;
            } else {
               endpoint += entity;
            }
            if (entityID) {
               endpoint += `/${entityID}`;
            }
            if (resource) {
               endpoint += `/${resource}`;
            }
            if (endpointInformation && endpointInformation.IsSearchEnabled && !resource && !entityID) {
               endpoint = `${endpointInformation.APIUrl}/Search`;
            }
            return endpoint;
         })
      );
   }

   getRootResource(entityType: EntityType): Observable<string> {
      return this.entityRequestEndpointService.getEndpointInformation(entityType).pipe(
         map((endpointInformation) => {
            return `${endpointInformation.APIUrl}`;
         })
      );
   }

   postCollectionResponse(
      entityType: EntityType,
      values: Array<any>,
      entityID?: number | null,
      resource?: string | null,
      saveOptions?: Array<string>,
      fields?: Array<string> | null,
      embeds?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.getEntityPostEndpoint(entityType, resource, entityID).pipe(
         switchMap((endpoint) => {
            additionalParameters = this.apiService.buildPostParameterArray(
               saveOptions,
               fields,
               embeds,
               additionalParameters
            );

            return this.apiService.post(endpoint, values, additionalParameters);
         })
      );
   }

   postCollection(
      entityType: EntityType,
      values: Array<any>,
      entityID?: number | null,
      resource?: string | null,
      saveOptions?: Array<string>,
      fields?: Array<string> | null,
      embeds?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      return this.postCollectionResponse(
         entityType,
         values,
         entityID,
         resource,
         saveOptions,
         fields,
         embeds,
         additionalParameters
      ).pipe(
         map((response) => {
            return response.body;
         })
      );
   }

   postSingle(
      entityType: EntityType,
      value: any,
      entityID: number,
      resource: string,
      saveOptions?: Array<string>,
      fields?: Array<string>,
      embeds?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<any> {
      return this.postCollection(
         entityType,
         [value],
         entityID,
         resource,
         saveOptions,
         fields,
         embeds,
         additionalParameters
      ).pipe(
         map((results) => {
            if (results.length === 0) {
               return null;
            }
            return results[0];
         })
      );
   }
}
