import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { ManagementFeeViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/management-fee-view.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { ManagementFeesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/management-fees.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { FilterValueType } from "../../../../../libraries/lcs/src/lib/filters/filter-value.types";
import { OWASessionService } from "../../session/owa-session.service";

@Component({
   selector: "owa-management-fees-list",
   templateUrl: "./management-fees-list.component.html",
   providers: [DataTableFiltersService],
})
export class ManagementFeesListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   webUser: WebUserModel;

   columns: string[] = ["FeeDate", "PropertyName", "FeeAmount"];
   @Input() showPassThru: boolean;
   observableManagementFees: Observable<Array<ManagementFeeViewModel>>;
   mgtFeeFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   appliedSideBarFilters: Array<FilterOption>;
   showFilters: boolean = false;

   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   threshold: string = "50%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;
   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _obserableManagementFees = new BehaviorSubject<Array<any>>(null);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setMonth(new Date().getMonth() - 6), "MM/dd/yy", "en-US");

   private unsubscribe = new Subject<void>();
   constructor(
      private managementFeeService: ManagementFeesService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);

      this.observableManagementFees = new Observable<Array<ManagementFeeViewModel>>();
      this.observableManagementFees = this._obserableManagementFees.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.mgtFeeFilters = this.getFilters();
      this.setDefaultDateFilter();
   }

   ngOnInit() {
      if (this.showPassThru) {
         this.columns.push("PassThruAmount");
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
   printWindow() {
      window.print();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.mgtFeeFilters.find((f) => f.FilterOption.FilterName === "FeeDate").FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.mgtFeeFilters.find((f) => f.FilterOption.FilterName === "FeeDate").FilterOption
      );
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.managementFeeService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const fees = response.body;
               return fees;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData =
                  this._obserableManagementFees && this._obserableManagementFees.value
                     ? this._obserableManagementFees.value
                     : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._obserableManagementFees.next(newData);
               this.results.next(this._obserableManagementFees.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   onInfiniteScroll($event): void {
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         this.initialLoadComplete = true;
      }
      const results = this._obserableManagementFees.value ? this._obserableManagementFees.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || results.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true);
      }
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }
   onClearFilters(): void {
      this.mgtFeeFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.mgtFeeFilters = this.resetFilter(this.mgtFeeFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel,
      defaultFilterOperation?: FilterOperations
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (defaultFilterOperation) {
         filter.FilterOption = new FilterOption(filterField, defaultFilterOperation, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   getFilters(): Array<DataTableFilterModel> {
      const feeDateFilter = this.getDataTableFilterModel(
         "FeeDate",
         "Fee Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.EqualTo, FilterOperations.Between],
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         FilterOperations.Between
      );

      const propertyFiltervalueSourceModel = new ValueSourceModel();
      propertyFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      propertyFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      propertyFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "All Ownerships")
      );
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });

      const propertyFilter = this.getDataTableFilterModel(
         "PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         propertyFiltervalueSourceModel
      );

      const filterList = [feeDateFilter, propertyFilter];

      return filterList;
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];

      return filterOps;
   }

   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
