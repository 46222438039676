import { HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";
import { Observable, Subject, takeUntil } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class LinkedAccountManagementService implements OnDestroy {
   readonly endpoint = "authentication/accountmanagement";

   private unsubscribe = new Subject<void>();

   constructor(private apiService: ApiService) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   saveAccounts(webUserAccounts: OWAWebUserAccountModel[]): Observable<HttpResponse<any>> {
      return this.apiService.post(this.endpoint, webUserAccounts).pipe(takeUntil(this.unsubscribe));
   }
}
