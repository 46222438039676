import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-types.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { AccountService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/account.service";
import { Subject } from "rxjs";

@Component({
   selector: "lcs-tenants-and-prospects-selector",
   templateUrl: "tenants-and-prospects-selector.component.html",
})
export class TenantsAndProspectsSelectorComponent extends ValueAccessorBase<number> implements OnInit, OnDestroy {
   @Input() addBlankItem: boolean;

   @Input() hideLabel: boolean;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @ViewChild(SelectComponent) searchableSelector: SelectComponent;

   selectedItem: SelectorItemModel;

   selectorConfigured: boolean;

   endpoint: string;

   valueSourcePath: string;

   displayValueSourcePath: string;

   additionalInfoSourcePath: string;

   additionalDataFields: Array<string>;

   filters: Array<FilterOption>;

   searchFields: Array<FilterField>;

   private unsubscribe = new Subject<void>();

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   ngOnInit() {
      this.configureSelector();
      return super.ngOnInit();
   }

   onValueChange(value: number) {
      this.selectedItem = this.searchableSelector.selectedItem;
      this.value = value;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private configureSelector() {
      this.endpoint = `${AccountService.endpoint}/${AccountService.TenantsAndProspectsResource}`;
      this.displayValueSourcePath = "Name";
      this.valueSourcePath = "AccountID";
      this.additionalInfoSourcePath = "AccountTypeDescription";
      this.additionalDataFields = ["AccountType"];
      this.filters = new Array<FilterOption>();

      const searchFields = new Array<FilterField>();
      const filterField = new FilterField();
      filterField.FilterName = this.displayValueSourcePath;
      filterField.DataType = new DataTypes();
      filterField.DataType.Name = "string";
      searchFields.push(filterField);
      this.searchFields = searchFields;

      this.selectorConfigured = true;
   }
}
