import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { OwnerDistributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-distributions.service";
import { OwnerDistributionViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-distribution-view.model";

@Component({
   selector: "owa-dashboard-owner-distributions",
   templateUrl: "./dashboard-tile-owner-distributions.component.html",
   providers: [OwnerDistributionsService],
})
export class DashboardOwnerDistributionsComponent implements OnInit {
   observableOwnerDistributions: Observable<Array<OwnerDistributionViewModel>>;
   ownerDistributions: Array<OwnerDistributionViewModel>;
   notificationCount: number;
   columns: string[] = ["Property", "Reference", "CheckDate", "Amount", "NewRow"];
   constructor(private ownerDistributionService: OwnerDistributionsService) {
      this.ownerDistributions = new Array<OwnerDistributionViewModel>();
   }

   ngOnInit() {
      this.observableOwnerDistributions = this.ownerDistributionService.getOwnerDistributionsForDashboard();
      this.observableOwnerDistributions.subscribe((res) => {
         this.ownerDistributions = res;
         if (this.ownerDistributions != null && this.ownerDistributions.length > 0) {
            const newDistributions = this.ownerDistributions.filter((i) => i.IsNew);
            this.notificationCount = newDistributions != null ? newDistributions.length : 0;
         }
      });
   }
}
