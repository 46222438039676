////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum PrivilegeType {
   Add = 0,
   View = 1,
   Edit = 2,
   Delete = 3,
   On = 4,
   NotSet = 5,
}
