import { InjectionToken } from "@angular/core";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";

export const FORM_CONTROL_METADATA_SELECTORFILTERS = new InjectionToken<FormControlMetadataSelectorFilters>(
   "Selector Filters Component"
);

export interface FormControlMetadataSelectorFilters {
   setFilters: (T: Array<FilterOption>) => void;
}
