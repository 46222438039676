import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { Observable } from "rxjs";

import { AccountRecoveryModel } from "../models/account-recovery.model";

@Injectable({
   providedIn: "root",
})
export class AccountRecoveryService {
   constructor(private httpClient: HttpClient, private apiRequestHelperService: ApiRequestHelperService) { }

   recover(recoverModel: AccountRecoveryModel, returnURL?: string, preventRedirect?: boolean): Observable<AccountRecoveryModel> {
      return this.httpClient.post<AccountRecoveryModel>(
         this.apiRequestHelperService.url + "accountrecovery/recover",
         JSON.stringify(recoverModel),
         { headers: this.apiRequestHelperService.initialHeaders, withCredentials: true }
      );
   }
}
