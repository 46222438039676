import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
   selector: "[lcsTableNoResultsFoundMessage]",
})
export class LcsTableNoResultsFoundMessageDirective<T extends Object> {
   @Input() set lcsTableNoResultsFoundMessageInput(value: string) {
      this._lcsTableNoResultsFoundMessageInput = value;
      if (this.div) {
         this.setNoResultsFoundDiv(true);
      }
   }
   get lcsTableNoResultsFoundMessageInput(): string {
      return this._lcsTableNoResultsFoundMessageInput;
   }

   @Input() set lcsTableNoResultsFoundMessageShow(value: boolean) {
      this._show = value;
      this.setNoResultsFoundDiv(false);
   }
   get lcsTableNoResultsFoundMessageShow(): boolean {
      return this._show;
   }

   @Input()
   set lcsTableNoResultsFoundMessageDataSource(data: T[]) {
      this.hasData = data?.length > 0;
      this.setNoResultsFoundDiv(false);
   }

   private div;

   private _lcsTableNoResultsFoundMessageInput: string = "No results found.";
   private _show = true;

   private hasData: boolean = false;

   constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

   setNoResultsFoundDiv(deleteAndReadd: boolean) {
      if (this.elementRef.nativeElement && this.div && deleteAndReadd) {
         this.renderer.removeChild(this.elementRef.nativeElement, this.div);
         this.div = null;
      }

      if (!this.div) {
         this.div = this.renderer.createElement("div");
         const divText = this.renderer.createText(this._lcsTableNoResultsFoundMessageInput);
         this.renderer.appendChild(this.div, divText);
         this.renderer.addClass(this.div, "no-results-found-message");
         this.renderer.appendChild(this.elementRef.nativeElement, this.div);
      }

      if (!this._show || this.hasData) {
         this.renderer.setStyle(this.div, "display", "none");
      } else {
         this.renderer.setStyle(this.div, "display", "block");
      }
   }
}
