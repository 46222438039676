<input
   #secureInput
   type="text"
   [attr.id]="name"
   [ngModel]="displayValue"
   (ngModelChange)="onDisplayValueChange(secureInput.value)"
   [placeholder]="placeholder"
   [disabled]="disabled || !hasPrivilege"
   (blur)="onBlur($event)"
   (focus)="onFocus($event)"
   (click)="onClick($event)"
   (keyup.enter)="onEnterKey($event)"
/>
<span
   *ngIf="hasPrivilege && showVisibilityIcon"
   class="material-icons visibility-icon"
   [ngClass]="{ 'visibility-on': visibilityOn }"
   (mousedown)="changeVisibility()"
>visibility</span>
<i class="material-icons validation-error-icon">error_outline</i>
<lcs-validation-overlay-panel
   [parentElement]="secureInput"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>