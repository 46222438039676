import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-vendor-report-parameter",
   templateUrl: "vendor-report-parameter.component.html",
})
export class VendorReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   _reportReportParameters: Array<ReportReportParameterViewModel>;

   includeInactiveVendorsReportParameter: ReportReportParameterViewModel;

   vendorsReportParameter: ReportReportParameterViewModel;

   vendorsFilters: Array<FilterOption>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {
      this.vendorsFilters = new Array<FilterOption>();
      this.vendorsFilters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
   }

   ngOnInit() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.vendorsReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.VendorIDs
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.includeInactiveVendorsReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IncludeInactiveVendors
      );
      if (!this.includeInactiveVendorsReportParameter) {
         const showInactiveVendors = new ReportParameterValueModel(ReportParameter.IncludeInactiveVendors, "false");
         this.reportParametersService.updateReportParameterValue(showInactiveVendors);

         const showInactiveVendorssView = new ReportReportParameterViewModel();
         showInactiveVendorssView.DisplayName = `Inactive Vendors`;
         showInactiveVendorssView.DefaultValue = "false";
         showInactiveVendorssView.ReportParameterID = ReportParameter.IncludeInactiveVendors;
         this.includeInactiveVendorsReportParameter = showInactiveVendorssView;
      }

      const includeInactiveVendors =
         this.includeInactiveVendorsReportParameter &&
         this.includeInactiveVendorsReportParameter.DefaultValue &&
         this.includeInactiveVendorsReportParameter.DefaultValue.toString().toLowerCase() === "true";
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | boolean' is not assigna... Remove this comment to see the full error message
      this.buildVendorFilters(includeInactiveVendors);
      this.initializeReportParameterValues();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.IncludeInactiveVendors) {
         this.buildVendorFilters(updatedValue.value.toString().toLowerCase() === "true");
      }
   }
   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.vendorsReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.vendorsReportParameter.ReportParameterID, isAsk);
   }

   private buildVendorFilters(includeInactiveVendors: boolean) {
      const newFilters = new Array<FilterOption>();
      if (!includeInactiveVendors) {
         newFilters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      }
      this.vendorsFilters = newFilters;
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
