import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { SystemPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/system-preferences.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, Observable, of, Subject, takeUntil } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class CurrentSystemPreferencesService implements SessionCacheProvider, OnDestroy {
   cacheKey = "CurrentSystemPreferencesService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   private currentSystemPreferencesCache: Map<SystemPreference, SystemPreferenceModel | null> = new Map<
      SystemPreference,
      SystemPreferenceModel | null
   >();

   private unsubscribe = new Subject<void>();

   constructor(
      private sessionStatusService: SessionStatusService,
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService,
      private systemPreferencesService: SystemPreferencesService,
      private apiService: ApiService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this, true);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   clearCache(sessionExpired: boolean) {
      this.currentSystemPreferencesCache.clear();

      this.cachePopulated.next(false);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
      }
   }

   populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      const storedValue = this.localStorageService.getItem(this.cacheKey);
      if (storedValue) {
         const cachedPrefs = JSON.parse(storedValue);
         if (cachedPrefs) {
            cachedPrefs.forEach((pref: SystemPreferenceModel) => {
               this.currentSystemPreferencesCache.set(pref.PreferenceID, pref);
            });
            this.cachePopulated.next(true);
         }
      } else {
         const systemPreferencesResponse = this.apiService.directGet(this.systemPreferencesService.getCollectionUrl());

         systemPreferencesResponse.pipe(takeUntil(this.unsubscribe)).subscribe(
            (response) => {
               const prefs = response.body;
               if (prefs && prefs.length > 0) {
                  this.fillCacheWithSystemPreferences(prefs);
               }
               this.cachePopulated.next(true);
            },
            (_) => {
               this.cacheMonitorService.reportError(this.cacheKey);
            }
         );
      }
   }

   fillCacheWithSystemPreferences(prefs: Array<SystemPreferenceModel>) {
      if (prefs && prefs.length > 0) {
         prefs.forEach((pref) => {
            this.currentSystemPreferencesCache.set(pref.PreferenceID, pref);
         });
      }
      this.localStorageService.setItem(
         this.cacheKey,
         JSON.stringify(Array.from(this.currentSystemPreferencesCache.values()))
      );

      return prefs;
   }

   getSystemPreference(
      systemPreference: SystemPreference,
      forceRefresh: boolean = false
   ): Observable<SystemPreferenceModel> {
      if (this.currentSystemPreferencesCache.has(systemPreference) && !forceRefresh) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<SystemPreferenceModel | undefined... Remove this comment to see the full error message
         return of(this.currentSystemPreferencesCache.get(systemPreference));
      }

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.currentSystemPreferencesCache.set(systemPreference, null);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<null>' is not assignable to type ... Remove this comment to see the full error message
      return of(null);
   }

   getSystemPreferences(
      systemPreferences: Array<SystemPreference>,
      forceRefresh: boolean = false
   ): Observable<Map<SystemPreference, SystemPreferenceModel>> {
      const results = new Map<SystemPreference, SystemPreferenceModel>();
      for (const systemPreference of systemPreferences) {
         if (this.currentSystemPreferencesCache.has(systemPreference) && !forceRefresh) {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'SystemPreferenceModel | undefine... Remove this comment to see the full error message
            results.set(systemPreference, this.currentSystemPreferencesCache.get(systemPreference));
         } else {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            results.set(systemPreference, null);
         }
      }
      return of(results);
   }

   getSystemPreferenceCacheValue(systemPreference: SystemPreference): string {
      if (this.currentSystemPreferencesCache.has(systemPreference)) {
         const pref = this.currentSystemPreferencesCache.get(systemPreference);
         if (!pref) {
            return "";
         }
         return pref.Value;
      }

      this.currentSystemPreferencesCache.set(systemPreference, null);
      return "";
   }
}
