<div class="side-bar-menu">
   <div
      *ngIf="hasSearch"
      class="searchable-selector-container"
   >
      <div class="input-wrapper">
         <input
            #inputEl
            type="text"
            [disabled]="disabled"
            [(ngModel)]="searchValue"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="off"
            tabindex="0"
         />
         <i class="material-icons">search</i>
      </div>
   </div>
   <div class="side-bar-menu-scroller">
      <div [hidden]="menuItemsHidden">
         <lcs-side-bar-menu-list
            *ngIf="sideBarSubMenus[0]"
            [sideBarSubMenu]="sideBarSubMenus[0]"
            #sideBarSubMenu0
            [disabled]="disabled"
            [currentEntityID]="currentEntityID"
            [useActionLinks]="useActionLinks"
            [showInformation]="showPaths"
            (parentMenuItemSelected)="clickParentItem($event)"
            (childMenuItemSelected)="setCurrentItem($event)"
            (backSelected)="goBack()"
         ></lcs-side-bar-menu-list>
         <lcs-side-bar-menu-list
            class="side-bar-menu-list-hidden"
            *ngIf="sideBarSubMenus[1]"
            [sideBarSubMenu]="sideBarSubMenus[1]"
            #sideBarSubMenu1
            [disabled]="disabled"
            [currentEntityID]="currentEntityID"
            [useActionLinks]="useActionLinks"
            [showInformation]="showPaths"
            (parentMenuItemSelected)="clickParentItem($event)"
            (childMenuItemSelected)="setCurrentItem($event)"
            (backSelected)="goBack()"
         ></lcs-side-bar-menu-list>
      </div>
   </div>
</div>