<div class="report-parameter-label">
   <label
      [hidden]="hideLabel"
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ $any(parameter?.PromptText?.length) > 0 ? parameter?.PromptText : parameter?.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<ng-container *ngIf="valueSource">
   <ng-container *ngIf="valueSource.AllowsMultipleValues; else singleSelect">
      <lcs-single-line-multi-select
         [name]="name"
         [displayName]="displayName"
         [selectAllItemsOnFilterChange]="selectAllItemsOnFilterChange"
         [entityValueSourceFilters]="entityValueSourceFilters"
         lcsReportParametersSingleLineMultiSelectFiltersDirective
         [parameter]="parameter.ReportParameterID"
         [hideLabel]="!hideLabel"
         [valueSource]="valueSource"
         [ngModel]="selectedValues"
         [lcsValidate]="validation"
         [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
         (selectionChange)="selectionChange = $event"
         (panelClose)="selectionChanged(selectionChange)"
         (processValueItems)="onProcessValueItems($event)"
      ></lcs-single-line-multi-select>
   </ng-container>
   <ng-template #singleSelect>
      <owa-dynamic-selector
         [name]="name"
         [displayName]="displayName"
         [allowsMultipleSelections]="parameter.ReportParameterValueSource.AllowsMultipleValues"
         [allowsSelectAll]="parameter.ReportParameterValueSource.AllowsSelectAll"
         [valueSources]="[valueSource]"
         [validation]="validation"
         [selectedValues]="selectedValues"
         [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
         (selectionChanged)="selectionChanged($event)"
         [entityValueSourceFilters]="entityValueSourceFilters"
         [entityValueSourceFilterExpression]="entityValueSourceFilterExpression"
      ></owa-dynamic-selector>
   </ng-template>
</ng-container>