import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";

import { RMReportHubModule } from "../controls/rm-report-hub/rm-report-hub.module";
import { FooterComponent } from "../footer/footer.component";
import { ReportParameterOverrideModule } from "../report-parameter-override/report-parameter-override.module";
import { ReportHubComponent } from "./report-hub.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      RMReportHubModule,
      FormRegistrationModule,
      ReportParameterOverrideModule,
      LcsProgressButtonModule,
      SelectModule,
   ],
   declarations: [ReportHubComponent, FooterComponent],
   providers: [FormRegistrationService],
})
export class ReportHubModule {}
