export class DateRangeModel {
   static readonly RangeSeparator: string = " - ";

   startDate: Date | null;
   endDate: Date | null;

   constructor(startDate?: Date, endDate?: Date) {
      this.startDate = startDate || null;
      this.endDate = endDate || null;
   }
   toJSON() {
      const toDateString = (date: Date) => {
         return (
            (date.getMonth() + 1).toString() + "/" + date.getDate().toString() + "/" + date.getFullYear().toString()
         );
      };
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
      return toDateString(this.startDate) + DateRangeModel.RangeSeparator + toDateString(this.endDate);
   }

   static FromUdvString(value: string): DateRangeModel {
      const dateRangeModel = new DateRangeModel();

      if (value == null) {
         return dateRangeModel;
      }

      const dates = value.split(DateRangeModel.RangeSeparator);
      if (dates.length >= 1) {
         dateRangeModel.startDate = new Date(dates[0]);
         if (dates.length === 2) {
            dateRangeModel.endDate = new Date(dates[1]);
         }
      }
      return dateRangeModel;
   }
}

export function isDateRangeModel(obj: any): obj is DateRangeModel {
   return "startDate" in obj && "endDate" in obj;
}
