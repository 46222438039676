<ng-container [ngSwitch]="controlType">
   <lcs-select
      lcsEntitySelector
      #selector
      *ngSwitchCase="controlTypes.EntitySelector"
      [parentEntityType]="parentEntityType"
      [entityType]="entityType"
      [entityID]="entityID"
      [resource]="resource"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (selectionChanged)="onValueChange($event)"
      [filters]="filters"
      [addBlankItem]="addBlankItem"
      [lcsValidate]="validation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [valuesToExclude]="valuesToExclude"
      [staticValues]="staticValues"
      [isSubItem]="isSubItem"
      [embeds]="embeds"
      [additionalDataFields]="additionalDataFields"
   >
   </lcs-select>

   <lcs-entity-multi-selector
      #selector
      *ngSwitchCase="controlTypes.EntityMultiSelector"
      [name]="name"
      [displayName]="displayName"
      [entityType]="entityType"
      [embeds]="embeds"
      [additionalDataFields]='additionalDataFields'
      [staticValues]="staticValues"
      [(ngModel)]="value"
      [filters]="filters"
      [disabled]="disabled"
      [ngModelOptions]="{ standalone: standalone }"
      [hideLabel]="hideSelectorLabel"
      (selectionChange)="onSelectionChange($event)"
      (panelClose)="onSelectionPanelClose()"
   ></lcs-entity-multi-selector>

   <lcs-enum-multi-selector
      #selector
      *ngSwitchCase="controlTypes.EnumMultiSelector"
      [name]="name"
      [displayName]="displayName"
      [enumName]="enumName"
      [valuesToExclude]="valuesToExclude"
      [(ngModel)]="value"
      [ngModelOptions]="{ standalone: standalone }"
      [disabled]="disabled"
      (selectionChange)="onSelectionChange($event)"
      (panelClose)="onSelectionPanelClose()"
   ></lcs-enum-multi-selector>

   <lcs-color-picker
      #selector
      class="flex-wrapper"
      *ngSwitchCase="controlTypes.LcsColorPicker"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [displayName]="displayName"
      [showOKButton]="true"
      [showCancelButton]="true"
   ></lcs-color-picker>

   <lcs-select
      lcsEnumerationSelector
      [enumerationName]="enumName"
      #selector
      *ngSwitchCase="controlTypes.EnumSelector"
      [valuesToExclude]="valuesToExclude"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-select>

   <lcs-select
      lcsLegacySingleSelector
      #selector
      *ngSwitchCase="controlTypes.SingleSelector"
      [valueSource]="valueSource"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      [name]="name"
      [lcsValidate]="validation"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      (ngModelChange)="onValueChange($event)"
      (selectionChanged)="onSingleSelectorChange($event)"
   >
   </lcs-select>

   <lcs-checkbox
      *ngSwitchCase="controlTypes.Checkbox"
      [pCheckboxCustomStyle]="checkboxCustomStyle"
      [name]="name"
      [displayName]="displayName"
      [tooltip]="tooltip"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [disabled]="disabled"
      [isInverted]="isInverted"
   >
      <span *ngIf="!hideCheckBoxLabel"> {{additionalInformationValue || displayName}} </span>
   </lcs-checkbox>

   <lcs-date-picker
      *ngSwitchCase="controlTypes.DatePicker || controlTypes.ConfirmDatePicker"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-date-picker>

   <lcs-date-time-picker
      *ngSwitchCase="controlTypes.DateTimePicker"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [dateValidation]="validation"
      [timeValidation]="secondaryValidation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-date-time-picker>

   <lcs-date-range-picker
      *ngSwitchCase="controlTypes.DateRangePicker"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [startValidation]="validation"
      [endValidation]="secondaryValidation"
      [name]="name"
      [disabled]="disabled"
      [displayName]="displayName"
   ></lcs-date-range-picker>

   <lcs-calculator-input
      *ngSwitchCase="controlTypes.CalculatorInput"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [numberFormat]="numberFormat"
      [hideIcon]="hideIcon"
   ></lcs-calculator-input>

   <lcs-numeric-input
      *ngSwitchCase="controlTypes.NumericTextBox"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [disabled]="disabled"
      [lcsValidate]="validation"
      [name]="name"
      [allowNegative]="allowNegative"
      [formatString]="numberFormat"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [showSeparator]="showSeparator"
   ></lcs-numeric-input>

   <lcs-number-range-input
      *ngSwitchCase="controlTypes.NumberRangeInput"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [minValidation]="validation"
      [maxValidation]="secondaryValidation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [controlType]="subControlType"
   ></lcs-number-range-input>

   <lcs-slider
      *ngSwitchCase="controlTypes.Slider"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [displayName]="displayName"
      [disabled]="disabled"
   ></lcs-slider>

   <lcs-text-area
      *ngSwitchCase="controlTypes.TextArea"
      [expandable]="expandable"
      [name]="name"
      [displayName]="displayName || name"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [disabled]="disabled"
   ></lcs-text-area>

   <lcs-input-with-options
      *ngSwitchCase="controlTypes.InputWithOptions"
      [valueSource]="valueSource"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [disabled]="disabled"
      [lcsValidate]="validation"
      [name]="name"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-input-with-options>

   <lcs-phone-number
      *ngSwitchCase="controlTypes.PhoneNumberInput"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      [lcsValidate]="validation"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [disabled]="disabled"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
   ></lcs-phone-number>

   <lcs-coming-soon *ngSwitchCase="controlTypes.ComingSoon"></lcs-coming-soon>

   <lcs-text-box
      *ngSwitchCase="controlTypes.PasswordInput"
      [inputType]="'password'"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [disabled]="disabled"
      [name]="name"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-text-box>

   <lcs-tenants-and-prospects-selector
      #selector
      *ngSwitchCase="controlTypes.TenantsAndProspectsSelector"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [lcsValidate]="validation"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-tenants-and-prospects-selector>

   <lcs-single-line-multi-select
      *ngSwitchCase="controlTypes.SingleLineMultiSelector"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [displayName]="displayName"
      [lcsValidate]="validation"
      [inlineLabel]="inlineLabel"
      [valueSource]="valueSource"
      [disabled]="disabled"
      (selectionChange)="onSelectionChange($event)"
      (panelClose)="onSelectionPanelClose()"
   >
   </lcs-single-line-multi-select>

   <lcs-bank-selector
      #selector
      *ngSwitchCase="controlTypes.BankSelector"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      (selected)="onSelected($event)"
      [filters]="filters"
      [addBlankItem]="addBlankItem"
      [staticValues]="staticValues"
      [lcsValidate]="validation"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
   ></lcs-bank-selector>

   <lcs-udf-encrypted-text
      *ngSwitchCase="controlTypes.UDFEncryptedText"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      [lcsValidate]="validation"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [disabled]="disabled"
      [displayName]="displayName"
      [placeholder]="placeholder"
      [userDefinedData]="customValidatorData"
      [isReadOnly]="false"
   ></lcs-udf-encrypted-text>

   <lcs-text-box
      *ngSwitchDefault
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone, updateOn: updateOn }"
      [lcsValidate]="validation"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [placeholder]="placeholder"
   ></lcs-text-box>

   <div
      *ngSwitchCase="controlTypes.AssignedToUser"
      class="assigned-to-user"
   >
      <lcs-select
         lcsAssignedToUserSelector
         class="assigned-to-user-selector"
         #selector
         [issueEntityType]="parentEntityType"
         [entityID]="entityID"
         [ngModel]="value"
         [ngModelOptions]="{ standalone: standalone }"
         (ngModelChange)="onValueChange($event)"
         [filters]="filters"
         [name]="name"
         [displayName]="displayName"
         [disabled]="disabled"
         [lcsValidate]="validation"
      >
      </lcs-select>
      <lcs-click-to-call
         class="assigned-to-user-click-to-call"
         [dataSource]="selector"
      ></lcs-click-to-call>
   </div>

   <lcs-select
      lcsOwnerEstimateDatatablePropertySelector
      #selector
      *ngSwitchCase="controlTypes.OwnerEstimatePropertySelector"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone }"
      (ngModelChange)="onValueChange($event)"
      [filters]="filters"
      [lcsValidate]="validation"
      [name]="name"
      [displayName]="displayName"
      [disabled]="disabled"
   >
   </lcs-select>

   <lcs-secure-ssn-input
      *ngSwitchCase="controlTypes.SocialSecurityNumber"
      [name]="name"
      [ngModelOptions]="{ standalone: standalone, updateOn: updateOn }"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [hasPrivilege]="true"
   ></lcs-secure-ssn-input>

   <lcs-secure-annual-income-input
      *ngSwitchCase="controlTypes.SecureCurrency"
      name]="name"
      [ngModelOptions]="{ standalone: standalone, updateOn: updateOn }"
      [disabled]="disabled"
      [customValidatorData]="customValidatorData"
      [displayName]="displayName"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      [lcsValidate]="validation"
      [hasPrivilege]="true"
   ></lcs-secure-annual-income-input>

   <lcs-static-field
      *ngSwitchCase="controlTypes.Label"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: standalone, updateOn: updateOn }"
      (ngModelChange)="onValueChange($event)"
      [name]="name"
      [disabled]="disabled"
      [lcsValidate]="validation"
      [customValidatorData]="customValidatorData"
      [label]="displayName"
   ></lcs-static-field>
</ng-container>