import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { ControlContainerViewProvider } from "../../../../../libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "../../../../../libraries/lcs/src/lib/inputs/user-input-component.interface";

@Component({
   selector: "owa-dynamic-selector",
   templateUrl: "dynamic-selector.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class DynamicSelectorComponent implements UserInputComponent {
   @Input() allowsMultipleSelections: boolean = false;

   @Input() allowsSelectAll: boolean;

   @Input() set valueSources(val: Array<ValueSourceModel>) {
      if (val) {
         this._valueSources = val;
         this.initialize();
      }
   }

   get valueSources(): Array<ValueSourceModel> {
      return this._valueSources;
   }

   @Input() hideLabel = false;

   @Input() alignOverlayPanelToRight;

   @Input() staticOverlay: boolean = false;

   @Input() selectorInputTemplate: TemplateRef<any>;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() set entityValueSourceFilters(val: Array<FilterOption>) {
      this._entityValueSourceFilters = val;
      this.buildSelectorFilters();
   }

   get entityValueSourceFilters(): Array<FilterOption> {
      return this._entityValueSourceFilters;
   }

   @Input() set entityValueSourceFilterExpression(val: FilterExpression) {
      this._entityValueSourceFilterExpression = val;
      this.buildSelectorFilters();
   }

   get entityValueSourceFilterExpression(): FilterExpression {
      return this._entityValueSourceFilterExpression;
   }
   @Output() valueSourceSelected = new EventEmitter<number>();

   @Output() selectionChanged = new EventEmitter<SelectionChangeModel>();

   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   public singleSelectorType = ExpressLayoutControlTypes.SingleSelector;

   public expressLayoutControlTypes = ExpressLayoutControlTypes;

   @Input() set selectedValues(val: Array<any>) {
      if (val === undefined) {
         return;
      }
      this._selectedValues = val;
      const changeModel = new SelectionChangeModel();
      changeModel.checkedItems = this.selectedValues.map((v) => new SelectorItemModel(v));
      this.selectionChanged.emit(changeModel);
   }

   get selectedValues(): Array<any> {
      return this._selectedValues;
   }

   @Input() set singleValue(val: any) {
      if (val === undefined) {
         return;
      }
      this.selectedValues = [val];
   }

   get singleValue(): any {
      if (this.selectedValues && this.selectedValues.length > 0) {
         return this.selectedValues[0];
      }
      return null;
   }

   get firstValueSource(): ValueSourceModel {
      if (this._valueSources && this._valueSources.length > 0) {
         return this.valueSources[0];
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValueSource... Remove this comment to see the full error message
      return null;
   }

   selectorFilters: Array<FilterOption>;

   private _selectedValues: Array<any>;

   private _valueSources: Array<ValueSourceModel>;

   private _entityValueSourceFilters: Array<FilterOption>;

   private _entityValueSourceFilterExpression: FilterExpression;

   constructor() {
      this._selectedValues = new Array<any>();
   }

   initialize() {
      if (this.valueSources && this.valueSources.length > 0) {
         if (this._valueSources.filter((source) => source.Type === ValueSourceTypes.Entity).length > 0) {
            this.singleSelectorType = ExpressLayoutControlTypes.SearchableSelector;
         } else {
            this.singleSelectorType = ExpressLayoutControlTypes.SingleSelector;
         }
         if (!this.allowsMultipleSelections) {
            this.singleValue = this.firstValueSource.DefaultValue;
            if (this.firstValueSource.SelectedValues.length > 0) {
               this.singleValue = this.firstValueSource.SelectedValues[0];
            }
         }
      }
      this.buildSelectorFilters();
   }

   propagateValueSourceSelected(index: number) {
      this.valueSourceSelected.emit(index);
   }

   propagateSelectionChanged(selectionChangeModel: SelectionChangeModel) {
      this.selectionChanged.emit(selectionChangeModel);
   }

   private buildSelectorFilters() {
      this.selectorFilters = new Array<FilterOption>();
      if (this.valueSources && this.valueSources.length > 0 && this.valueSources[0].Filters) {
         this.selectorFilters.push(...this.valueSources[0].Filters);
      }

      if (this._entityValueSourceFilters) {
         this.selectorFilters.push(...this._entityValueSourceFilters);
      }
   }
}
