import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class PhoneService {
   private phoneNumberLengthNoAreaCode: number = 7;

   private phoneNumberLengthWithAreaCode: number = 10;

   maskPhoneNumber(value: string): string {
      const strippedValueCharList = value.match(/[^()-]/g);
      if (strippedValueCharList && strippedValueCharList.length && !isNaN(+strippedValueCharList.join(""))) {
         if (strippedValueCharList.length > 1) {
            if (strippedValueCharList[0] === "+" && strippedValueCharList[1] === "1") {
               strippedValueCharList.splice(0, 2);
            }
            if (
               strippedValueCharList[0] === "+" &&
               strippedValueCharList[1] !== "+" &&
               strippedValueCharList[1] !== "-"
            ) {
               strippedValueCharList.splice(0, 1);
            }
         }
         const length = strippedValueCharList.length;
         if (length === this.phoneNumberLengthNoAreaCode) {
            strippedValueCharList.splice(3, 0, "-");
         } else if (length === this.phoneNumberLengthWithAreaCode) {
            strippedValueCharList.splice(0, 0, "(");
            strippedValueCharList.splice(4, 0, ") ");
            strippedValueCharList.splice(8, 0, "-");
         }
         return strippedValueCharList.join("");
      }
      return value;
   }
}
