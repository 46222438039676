import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/overlay";
import {
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   NgZone,
   OnDestroy,
   OnInit,
   Output,
   Renderer2,
   ViewChild,
} from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { DomHandler } from "primeng/dom";
import { fromEvent, map, merge, Subject, Subscription, takeUntil } from "rxjs";

import { WindowService } from "../viewport/window.service";
import { MouseEvents } from "./mouse-events.enum";
import { OverlayPanelOpenDirection } from "./overlay-panel-open-direction.enum";
import { OverlayPanelRegistrationModel } from "./overlay-panel-registration.model";
import { OverlayPanelRegistrationService } from "./overlay-panel-registration.service";
import { OverlayTooltipArrowComponent } from "./overlay-tooltip-arrow/overlay-tooltip-arrow.component";
import { TooltipArrowLocation } from "./tooltip-arrow-location";
import * as mouse from "mouse-event";

interface OverlayPositionVariables {
   borderSpacer: number;
   bottomSpacer: number;
   windowScroll: number;
   innerElementRect: ClientRect | DOMRect;
   overlayRect: ClientRect | DOMRect;
   parentElementHeight: number;
   overlayPanelOriginY: number;
   unrestrictedPanelHeight: number;
   unrestrictedPanelBottom: number;
   panelHeight: number;
   windowBottom: number;
   topSpaceAvailable: number;
   bottomSpaceAvailable: number;
   openDirection: OverlayPanelOpenDirection;
   bottomY?: number;
}

@Component({
   selector: "lcs-overlay-panel",
   templateUrl: "./overlay-panel.component.html",
})
export class OverlayPanelComponent implements OnInit, OnDestroy {
   @Input() alignToRight: boolean;

   @Input() alignToCenter: boolean;

   @Input() rightOverride: number;

   @Input() staticOverlay: boolean;

   @Input() openBesideRight: boolean;

   @Input() openBesideLeft: boolean;

   @Input() openOverTopElement: boolean;

   @Input() hideOnBlur: boolean = true;

   @Input() hideOnOutsideClick: boolean = true;

   @Input() innerElementSelector: string;

   @Input() innerElementTags: Array<string>;

   @Input() parentElement: ElementRef | HTMLElement;

   @Input() showOnFocus: boolean;

   @Input() showOnClick: boolean = true;

   @Input() toggleOnClick: boolean = true;

   @Input() disabled: boolean = false;

   @Input() parentOverlayPanelRef: OverlayPanelComponent;

   @Input() set show(val: boolean) {
      if (this.disabled) {
         val = false;
      }
      if (this._show === val) {
         return;
      }

      if (val) {
         this.createAndShowOverlay();
      } else {
         this.hideOverlay();
      }
   }

   @Input() set top(value: number) {
      if (value > 0) {
         this.calculateTop();
      }
   }

   @Input() topAdjustmentOverride: number;

   @Input() overlayHeader: string;

   @Input() overlayPointer: boolean;

   @Input() overlayPanelStaticWidth: number;

   @Input() overlayPanelStaticMinWidth: number;

   @Input() showIndependent: boolean;

   @Input() allowScrolling: boolean;

   @Input() overlayClasses: string;

   @Input() isGloballyWhiteListOverlay: boolean;

   @Input() hasTooltipArrow: boolean = false;

   @Input() tooltipLocation: TooltipArrowLocation = TooltipArrowLocation.topLeft;

   @Input() hasExpandedSelectorArea: boolean = false;

   @Input() mobileOverlay: boolean = false;

   @Output() showChange = new EventEmitter<boolean>(false);

   @Output() panelRepositioning = new EventEmitter<boolean>();

   @ViewChild("overlayPanel", { static: true }) overlayPanel: ElementRef;

   @ViewChild("overlayPanelContents") overlayPanelContents: ElementRef;

   @ViewChild("overlayPanelContentsWrapper") overlayPanelContentsWrapper: ElementRef;

   overlayPanelDisplay: string;

   overlayPanelLeft: number;

   overlayPanelMinWidth: number;

   overlayPanelMaxWidth: number;

   overlayPanelOpacity: number;

   overlayPanelRight: number;

   overlayPanelZIndex: number;

   overlayPanelPointerEvents: string;

   windowScroll = 0;

   created = false;

   // used to hide the overlay panel until it correctly positioned to avoid the user seeing it jump into position
   isHidden = true;

   isMouseInContent: boolean;

   protected innerElement: Element;

   private activeElementId: string;

   private unsubscribe = new Subject<void>();

   private contentAreaClickSubscription: Subscription;

   private resizeSubscription: Subscription;

   private scrollSubscription: Subscription;

   private repositionedSubscription: Subscription;

   private parentOverlayPanelScrollSubscription: Subscription;

   private _show: boolean = false;

   private contentHeight: number;

   private overlayPanelOpenDirection = OverlayPanelOpenDirection.Unset;

   private overlayPanelWindowGap = 5;

   private maxHeight: number;

   private uniqueID: number;

   private _top = 0;

   private _parentPanelRepositioning: boolean;

   private intersectionObserver: IntersectionObserver;
   constructor(
      private ngZone: NgZone,
      private scrollDispatcher: ScrollDispatcher,
      private windowService: WindowService,
      private renderer: Renderer2,
      private overlayPanelRegistrationService: OverlayPanelRegistrationService,
      private uniqueIDService: UniqueIDService,
      private changeDetectorRef: ChangeDetectorRef
   ) {
      this.uniqueID = this.uniqueIDService.getUniqueID();
      this.intersectionObserver = new IntersectionObserver(() => {
         // for ios zooming, which doesn't fire a window resize or scroll event
         this.setOverlayPanelPosition();
      });
   }

   ngOnInit() {
      if (!this.innerElementTags) {
         this.innerElementTags = ["input", "textarea"];
      }
      this.determineInnerElement();
      this.initializeOpenEvents();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      // appendOverlayToBody may not have been called yet, so we can't call body.removeChild()
      // we use el.parentNode.removeChild(el) instead of el.remove() to support IE
      if (this.overlayPanelContentsWrapper && this.overlayPanelContentsWrapper.nativeElement) {
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         (this.overlayPanelContentsWrapper.nativeElement as HTMLElement).parentNode.removeChild(
            this.overlayPanelContentsWrapper.nativeElement
         );
      }
   }

   appendOverlayToBody() {
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.querySelector("body").appendChild(this.overlayPanelContentsWrapper.nativeElement);
   }

   setOverlayPanelStyles() {
      if (!this._show) {
         return;
      }
      if (this.innerElement) {
         let overlayMinWidth = Math.ceil(this.innerElement.getBoundingClientRect().width);
         if (overlayMinWidth < 100) {
            overlayMinWidth = 100;
         }
         if (this.overlayPanelStaticMinWidth) {
            this.overlayPanelMinWidth = this.overlayPanelStaticMinWidth;
         } else {
            this.overlayPanelMinWidth = overlayMinWidth;
         }
         this.overlayPanelDisplay = "block";
         this.overlayPanelOpacity = 1;
         this.overlayPanelPointerEvents = "none";
         const domHandlerZIndex = ++DomHandler.zindex;
         this.overlayPanelZIndex = ConstantsService.FullMenuZIndex;
         +domHandlerZIndex + 1000; // Added 1000 to ensure overlay panel for selectors on dialogs display on top. They were displaying behind dialog.
      }
   }

   updateOverlayPanelPosition() {
      this.setOverlayPanelPosition();
   }

   setOverlayPanelPosition(show: boolean = false) {
      if (this._parentPanelRepositioning) {
         return;
      }

      this.setOverlayPanelStyles();
      this.panelRepositioning.next(true);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      let left: number = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      let right: number = null;

      if (!this._show) {
         return;
      }
      const innerElementRect = this.innerElement.getBoundingClientRect();

      if (this.rightOverride != null) {
         right = this.rightOverride;
      } else if (this.openBesideRight) {
         left = Math.ceil(document.documentElement.clientWidth - innerElementRect.right);
         if (
            (this.hasTooltipArrow && this.tooltipLocation === TooltipArrowLocation.leftTop) ||
            this.tooltipLocation === TooltipArrowLocation.leftBottom
         ) {
            left += OverlayTooltipArrowComponent.arrowHeight;
         }
      } else if (this.openBesideLeft) {
         right = Math.ceil(document.documentElement.clientWidth - innerElementRect.left);
         if (
            (this.hasTooltipArrow && this.tooltipLocation === TooltipArrowLocation.rightTop) ||
            this.tooltipLocation === TooltipArrowLocation.rightBottom
         ) {
            right += OverlayTooltipArrowComponent.arrowHeight;
         }
      } else if (this.alignToRight) {
         right = Math.ceil(document.documentElement.clientWidth - innerElementRect.right);
         if (this.overlayPointer) {
            right += innerElementRect.height;
         }
      } else if (this.alignToCenter) {
         right = Math.ceil(document.documentElement.clientWidth - innerElementRect.right);
         left = Math.ceil(innerElementRect.left);
         const width = right - left;
         if (width < this.overlayPanelStaticWidth) {
            const widthDifference = (this.overlayPanelStaticWidth - width) / 2;
            left -= widthDifference;
            right -= widthDifference;
         }
      } else {
         const windowScrollX = window.scrollX || document.documentElement.scrollLeft;
         left = Math.ceil(innerElementRect.left);
         left += windowScrollX;
      }

      // This additional setTimeout is required to prevent layout thrashing
      // It decouples reading and updating the layout
      // http://kellegous.com/j/2013/01/26/layout-performance/
      setTimeout(() => {
         if (right != null) {
            this.overlayPanelRight = right;
         }
         if (left != null) {
            this.overlayPanelLeft = left;
         }

         this.overlayPanelOpacity = 1;
         this.overlayPanelPointerEvents = "auto";
         this.calculateTop();
         this.changeDetectorRef.markForCheck();

         this.panelRepositioning.next(false);
         if (show) {
            this.isHidden = false;
         }
      }, 1);
   }

   resetOverlayPanelPosition(hide: boolean) {
      if (hide) {
         this.overlayPanelDisplay = "none";
         this.overlayPanelOpacity = 0;
      }
      this.overlayPanelPointerEvents = "none";
      this.overlayPanelOpenDirection = OverlayPanelOpenDirection.Unset;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.maxHeight = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.contentHeight = null;
   }

   createAndShowOverlay() {
      if (!this.created) {
         this.created = true;
         this.changeDetectorRef.detectChanges();
         setTimeout(() => {
            this.appendOverlayToBody();
            this.showOverlay();
         });
      } else {
         this.showOverlay();
      }
   }

   showOverlay() {
      if (!this.overlayPanelRegistrationService.hasOverlayPanel(this.uniqueID)) {
         const registration = new OverlayPanelRegistrationModel();
         registration.closeWhenNonOverlayClicked = this.hideOnOutsideClick;
         registration.contentElement = this.overlayPanelContentsWrapper;
         registration.closeCallback = this.hideOverlay.bind(this, "closed By Registration Service");
         registration.whiteList = new Array<number>();
         registration.whiteList.push(this.uniqueID);
         registration.isGloballyWhitelist = this.isGloballyWhiteListOverlay;
         if (!this.staticOverlay && this.parentOverlayPanelRef) {
            if (this.overlayPanelRegistrationService.openOverlayPanels) {
               const parentPanel = this.overlayPanelRegistrationService.openOverlayPanels.get(
                  this.parentOverlayPanelRef.uniqueID
               );
               if (parentPanel && parentPanel.whiteList) {
                  parentPanel.whiteList.forEach((id) => {
                     registration.whiteList.push(id);
                  });
               }
            }
         }
         registration.showIndependent = this.showIndependent;
         this.overlayPanelRegistrationService.register(this.uniqueID, registration);
      }

      this._show = true;
      this.showChange.emit(this._show);
      this.intersectionObserver.observe(document.body);

      this.resetOverlayPanelPosition(false);
      this.setOverlayPanelStyles();

      if (!this.staticOverlay) {
         if (!this.scrollSubscription) {
            let scrollObservable = this.scrollDispatcher.ancestorScrolled(this.overlayPanel, 0);

            if (this.parentOverlayPanelRef) {
               scrollObservable = merge(
                  scrollObservable,
                  this.scrollDispatcher.ancestorScrolled(this.parentOverlayPanelRef.overlayPanel, 0)
               );
            }

            this.scrollSubscription = scrollObservable
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((ancestor: CdkScrollable) => {
                  this.calculateTop(ancestor);
                  this.ngZone.run(() => this.closeOverlayIfOutsideContainer(ancestor));
               });
         }

         if (this.parentOverlayPanelRef && !this.repositionedSubscription) {
            this.repositionedSubscription = this.parentOverlayPanelRef.panelRepositioning
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((repositioning) => {
                  if (repositioning === false) {
                     // SetTimeout so it will go in Macro Task behind parent panel positioning in the Macro Task
                     setTimeout(() => {
                        this.setOverlayPanelPosition();
                     });
                  }

                  this._parentPanelRepositioning = repositioning;
               });
         }
      }

      this.afterShow();
   }

   afterShow(): void {
      setTimeout(() => {
         this.setOverlayPanelPosition(true);
         this.initializeResizeEvent();
      }, 1);
   }

   hideOverlay() {
      setTimeout(() => {
         if (this._show) {
            this._show = false;
            this.overlayPanelRegistrationService.overlayPanelClosed(this.uniqueID);
            this.showChange.emit(this._show);
            this.intersectionObserver.unobserve(document.body);
         }
         this.clearCloseEvents();
         this.resetOverlayPanelPosition(true);
         if (this.overlayPanelContentsWrapper) {
            this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "display", "none");
         }
         this.changeDetectorRef.markForCheck();
      });
   }

   calculateTop(ancestorScrolled?: CdkScrollable | void) {
      if (!this._show) {
         return;
      }

      const overlayVars = this.setOverlayVars();

      this.contentHeight = overlayVars.unrestrictedPanelHeight;

      const originOutsideScrollingContainer = this.overlayOriginYOutsideScrollingContainer(
         overlayVars.overlayPanelOriginY,
         ancestorScrolled
      );

      // Best open direction
      if (
         originOutsideScrollingContainer ||
         (overlayVars.unrestrictedPanelBottom > overlayVars.windowBottom &&
            overlayVars.topSpaceAvailable > overlayVars.bottomSpaceAvailable)
      ) {
         overlayVars.openDirection = this.overlayPanelOpenDirection = OverlayPanelOpenDirection.Up;
      } else {
         overlayVars.openDirection = this.overlayPanelOpenDirection = OverlayPanelOpenDirection.Down;
      }

      // Max height
      if (this.overlayPanelOpenDirection === OverlayPanelOpenDirection.Down) {
         this.maxHeight = overlayVars.bottomSpaceAvailable - overlayVars.bottomSpacer;
      } else {
         this.maxHeight = overlayVars.topSpaceAvailable - overlayVars.bottomSpacer;
      }
      overlayVars.panelHeight =
         overlayVars.unrestrictedPanelHeight > this.maxHeight ? this.maxHeight : overlayVars.unrestrictedPanelHeight;

      // only if command launch flyout overlay has only one item in the menu.
      if (overlayVars.unrestrictedPanelHeight > this.maxHeight && this.hasExpandedSelectorArea) {
         overlayVars.panelHeight = overlayVars.unrestrictedPanelHeight;
         this.maxHeight = overlayVars.bottomSpaceAvailable - overlayVars.bottomSpacer;
         overlayVars.openDirection = this.overlayPanelOpenDirection = OverlayPanelOpenDirection.Down;
      }
      // Adjust Panel
      this._top = this.adjustPanelForOpenDirection(overlayVars);
      if (!this.mobileOverlay) {
         if (overlayVars.openDirection === OverlayPanelOpenDirection.Up && overlayVars.bottomY) {
            this.renderer.setStyle(
               this.overlayPanelContentsWrapper.nativeElement,
               "bottom",
               `${overlayVars.bottomY}px`
            );
            this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "top", `unset`);
         } else {
            this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "top", `${this._top}px`);
            this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "bottom", `unset`);
         }
      } else {
         this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "top", `unset`);
         this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "bottom", `0px`);
      }
      if (this.allowScrolling !== false) {
         this.renderer.setStyle(this.overlayPanelContents.nativeElement, "overflowY", "auto");
         this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "maxHeight", `${this.maxHeight}px`);
      } else {
         if (this.contentHeight >= this.maxHeight) {
            this.renderer.setStyle(this.overlayPanelContentsWrapper.nativeElement, "height", `${this.maxHeight}px`);
         } else {
            this.renderer.removeStyle(this.overlayPanelContentsWrapper.nativeElement, "height");
         }
         this.renderer.setStyle(this.overlayPanelContents.nativeElement, "overflowY", "hidden");
         this.renderer.setStyle(this.overlayPanelContents.nativeElement, "height", "100%");
      }

      // Max Width
      if (this.overlayPanelStaticWidth) {
         this.overlayPanelMaxWidth = this.overlayPanelStaticWidth;
      } else if (this.alignToRight) {
         this.overlayPanelMaxWidth =
            document.documentElement.clientWidth - this.overlayPanelRight - this.overlayPanelWindowGap;
      } else {
         this.overlayPanelMaxWidth =
            document.documentElement.clientWidth - overlayVars.overlayRect.left - this.overlayPanelWindowGap;
      }
   }

   private setOverlayVars(): OverlayPositionVariables {
      const borderSpacer = 2;
      const bottomSpacer = 5;

      const documentRect = document.body.getBoundingClientRect() as DOMRect;
      const innerElementRect = this.innerElement.getBoundingClientRect();
      const windowScroll = window.scrollY || document.documentElement.scrollTop;

      const overlayRect = this.overlayPanel.nativeElement.getBoundingClientRect();
      let overlayPanelOriginY = overlayRect.top + windowScroll;

      if (this.top != null) {
         overlayPanelOriginY = this.top;
      }

      const parentElementHeight = innerElementRect.height;
      const unrestrictedPanelHeight = this.overlayPanelContents.nativeElement.getBoundingClientRect().height;
      const unrestrictedPanelBottom = overlayPanelOriginY + unrestrictedPanelHeight;

      const windowBottom = documentRect.height;

      const topSpaceAvailable = overlayPanelOriginY - parentElementHeight + documentRect.top;

      const bottomSpaceAvailable = windowBottom - overlayPanelOriginY;

      const panelHeight = unrestrictedPanelHeight;
      const openDirection = OverlayPanelOpenDirection.Down;

      return {
         borderSpacer,
         bottomSpacer,
         windowScroll,
         innerElementRect,
         parentElementHeight,
         overlayRect,
         overlayPanelOriginY,
         unrestrictedPanelHeight,
         unrestrictedPanelBottom,
         panelHeight,
         windowBottom,
         topSpaceAvailable,
         bottomSpaceAvailable,
         openDirection,
      };
   }

   private overlayOriginYOutsideScrollingContainer(top: number, ancestorScrolled: CdkScrollable | void): boolean {
      const windowScroll = window.scrollY || document.documentElement.scrollTop;

      let ancestor: CdkScrollable;
      if (!ancestorScrolled) {
         ancestor = this.getAncestor();
      } else {
         ancestor = ancestorScrolled;
      }

      if (!ancestor) {
         return false;
      }

      const containerRect = ancestor.getElementRef().nativeElement.getBoundingClientRect();

      if (
         (containerRect.top && top < containerRect.top + windowScroll) ||
         (containerRect.bottom && top > containerRect.bottom + windowScroll)
      ) {
         return true;
      }

      return false;
   }

   private adjustPanelForOpenDirection(overlayPositionVars: OverlayPositionVariables) {
      if (this.top) {
         return this.top;
      }

      let adjustedTop = overlayPositionVars.overlayPanelOriginY;

      if (overlayPositionVars.openDirection === OverlayPanelOpenDirection.Down) {
         if (this.openOverTopElement) {
            adjustedTop = Math.ceil(
               overlayPositionVars.overlayPanelOriginY -
                  overlayPositionVars.parentElementHeight -
                  overlayPositionVars.borderSpacer
            );
         }
      } else if (overlayPositionVars.openDirection === OverlayPanelOpenDirection.Up) {
         if (!this.openOverTopElement) {
            adjustedTop = Math.ceil(
               overlayPositionVars.overlayPanelOriginY -
                  overlayPositionVars.parentElementHeight -
                  overlayPositionVars.panelHeight
            );
            overlayPositionVars.bottomY = Math.ceil(
               overlayPositionVars.windowBottom -
                  (overlayPositionVars.overlayPanelOriginY - overlayPositionVars.parentElementHeight)
            );
         } else {
            adjustedTop = Math.ceil(overlayPositionVars.overlayPanelOriginY - overlayPositionVars.panelHeight);
         }
      } else {
         console.warn("Panel Direction not set for positioning!");
      }

      if (this.topAdjustmentOverride) {
         adjustedTop += this.topAdjustmentOverride;
      }

      return adjustedTop;
   }

   private closeOverlayIfOutsideContainer(ancestorScrolled: CdkScrollable | void): void {
      if (!this.staticOverlay) {
         const windowScroll = window.scrollY || document.documentElement.scrollTop;

         let ancestor: CdkScrollable;
         if (!ancestorScrolled) {
            ancestor = this.getAncestor();
         } else {
            ancestor = ancestorScrolled;
         }

         if (!ancestor) {
            return;
         }

         const containerRect = ancestor.getElementRef().nativeElement.getBoundingClientRect();

         if (this.overlayPanelOpenDirection === OverlayPanelOpenDirection.Down) {
            if (containerRect.top && this._top < containerRect.top + windowScroll) {
               this.hideOverlayAndSetInteractive();
               return;
            }

            if (containerRect.bottom && this._top > containerRect.bottom + windowScroll) {
               this.hideOverlayAndSetInteractive();
               return;
            }
         } else if (this.overlayPanelOpenDirection === OverlayPanelOpenDirection.Up) {
            const height = this.contentHeight < this.maxHeight ? this.contentHeight : this.maxHeight;
            const bottom = this._top + height;

            if (containerRect.top && bottom < containerRect.top + windowScroll) {
               this.hideOverlayAndSetInteractive();
               return;
            }

            if (containerRect.bottom && bottom > containerRect.bottom + windowScroll) {
               this.hideOverlayAndSetInteractive();
               return;
            }
         }
      }
   }

   private hideOverlayAndSetInteractive() {
      this.hideOverlay();
   }

   private determineInnerElement() {
      let innerElement: Element;
      if (this.parentElement instanceof Element) {
         innerElement = this.parentElement;
      } else if (this.parentElement.nativeElement) {
         innerElement = this.parentElement.nativeElement;
      }
      if (
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'innerElement' is used before being assig... Remove this comment to see the full error message
         innerElement &&
         (!innerElement.tagName || this.innerElementTags.indexOf(innerElement.tagName.toLowerCase()) === -1)
      ) {
         if (this.innerElementSelector) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Element | null' is not assignable to type 'E... Remove this comment to see the full error message
            innerElement = innerElement.parentElement.querySelector(this.innerElementSelector);
         } else {
            for (const inputTag of this.innerElementTags) {
               const elementForTag = innerElement.querySelector(inputTag);
               if (elementForTag) {
                  innerElement = elementForTag;
                  break;
               }
            }
         }
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'innerElement' is used before being assig... Remove this comment to see the full error message
      this.innerElement = innerElement;
      if (!this.innerElement) {
         console.warn(
            "Unable to locate inner element for overlay panel. Automatic show and hide events will not work."
         );
      }
   }

   private initializeOpenEvents() {
      if (!this.innerElement) {
         return;
      }
      fromEvent(this.innerElement, "focusin")
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.activeElementId = document.activeElement.id;
            if (this.showOnFocus) {
               this.createAndShowOverlay();
            }
         });
      fromEvent(this.innerElement, "blur")
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            if (document.activeElement.id !== this.activeElementId) {
               if (this.hideOnBlur) {
                  this.hideOverlay();
               }
            } else {
               this.setOverlayPanelPosition();
            }
         });
      fromEvent(this.innerElement, "mousedown")
         .pipe(
            map((event) => mouse.buttons(event)),
            takeUntil(this.unsubscribe)
         )
         .subscribe((button) => {
            if (button === MouseEvents.middleClick || button === MouseEvents.rightClick) {
               return;
            }

            if (this._show && this.toggleOnClick) {
               this.hideOverlay();
            } else if (this.showOnClick) {
               this.overlayPanelZIndex = ConstantsService.FullMenuZIndex;
               +(++DomHandler.zindex);
               this.createAndShowOverlay();
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               event.stopPropagation();
            }
         });
   }

   private initializeResizeEvent() {
      if (!this.resizeSubscription) {
         this.resizeSubscription = this.windowService.resized.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.setOverlayPanelPosition();
         });
      }
   }

   private clearCloseEvents() {
      if (this.contentAreaClickSubscription) {
         this.contentAreaClickSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.contentAreaClickSubscription = null;
      }
      if (this.resizeSubscription) {
         this.resizeSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.resizeSubscription = null;
      }
      if (this.scrollSubscription) {
         this.scrollSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.scrollSubscription = null;
      }
      if (this.parentOverlayPanelScrollSubscription) {
         this.parentOverlayPanelScrollSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.parentOverlayPanelScrollSubscription = null;
      }
      if (this.repositionedSubscription) {
         this.repositionedSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.repositionedSubscription = null;
      }
   }

   private getAncestor(): CdkScrollable {
      const ancestors = this.scrollDispatcher.getAncestorScrollContainers(this.overlayPanel);

      if (ancestors.length === 0) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'CdkScrollab... Remove this comment to see the full error message
         return null;
      }

      return ancestors[0];
   }
}
