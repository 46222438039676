import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-lead-sources-to-include-report-parameter",
   templateUrl: "lead-sources-to-include-report-parameter.component.html",
})
export class LeadSourcesToIncludeReportParameterComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   leadSourcesReportParameter: ReportReportParameterViewModel;

   inactiveLeadSourcesReportParameter: ReportReportParameterViewModel;

   leadSourcesFilters: Array<FilterOption>;

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   constructor(private reportParametersService: ReportParametersService) {
      this.leadSourcesFilters = new Array<FilterOption>();
      this.leadSourcesFilters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
   }

   ngOnInit() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.leadSourcesReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.LeadSourcesToInclude
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.inactiveLeadSourcesReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IncludeInactiveLeadSources
      );

      if (!this.inactiveLeadSourcesReportParameter) {
         const showInactiveLeadSources = new ReportParameterValueModel(
            ReportParameter.IncludeInactiveLeadSources,
            "false"
         );
         this.reportParametersService.updateReportParameterValue(showInactiveLeadSources);
         this.buildInactiveLeadSourceView();
      }

      const includeInactiveLeadSources =
         this.inactiveLeadSourcesReportParameter &&
         this.inactiveLeadSourcesReportParameter.DefaultValue &&
         this.inactiveLeadSourcesReportParameter.DefaultValue.toString().toLowerCase() === "true";

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | boolean' is not assigna... Remove this comment to see the full error message
      this.buildFilters(includeInactiveLeadSources);
      this.initializeSelectorValues();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.leadSourcesReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.leadSourcesReportParameter.ReportParameterID, isAsk);
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.IncludeInactiveLeadSources) {
         this.buildFilters(updatedValue.value.toString().toLowerCase() === "true");
      }
   }

   private initializeSelectorValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }

   private buildFilters(includeInactiveLeadSources: boolean) {
      const filters = new Array<FilterOption>();
      if (!includeInactiveLeadSources) {
         filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      }
      this.leadSourcesFilters = filters;
   }

   private buildInactiveLeadSourceView() {
      const showInactiveLeadSourcesView = new ReportReportParameterViewModel();
      showInactiveLeadSourcesView.DisplayName = `Inactive`;
      showInactiveLeadSourcesView.DefaultValue = "false";
      showInactiveLeadSourcesView.ReportParameterID = ReportParameter.IncludeInactiveLeadSources;
      this.inactiveLeadSourcesReportParameter = showInactiveLeadSourcesView;
   }
}
