import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { SingleLineMultiSelectComponent } from "@lcs/single-line-multi-select/single-line-multi-select.component";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { OwnerGroupDetailFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/owner-group-detail.fields";
import { OwnerGroupFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/owner-group.fields";
import { OwnerFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/owner.fields";
import { PropertyGroupDetailFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property-group-detail.fields";
import { PropertyGroupFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property-group.fields";
import { PropertyFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OwnerGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group.model";
import { PropertyGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-group.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { PropertyGroupsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/property-groups.service";
import { OwnerGroupsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/owner-groups.service";
import { BehaviorSubject, forkJoin, Observable, Subject, take, takeUntil } from "rxjs";

import { ConstantsService } from "../../core/constants.service";
import { FilterInputValueType } from "../datatable-filters/datatable-filter-values.types";

@Component({
   selector: "lcs-property-owner-selector",
   templateUrl: "property-owner-selector.component.html",
})
export class PropertyOwnerSelectorComponent implements OnChanges, AfterViewInit, OnDestroy {
   @Input() set tabIndex(value: number) {
      if (!isNaN(value)) {
         this._tabIndex = value;
         this.tabSelected(this._tabIndex);
      }
   }

   @Input() set showInactiveProperties(value: boolean) {
      this._showInactiveProperties = value;
      if (!this._showInactiveProperties) {
         this.propertyFilters = new Array<FilterOption>(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      } else {
         this.propertyFilters = new Array<FilterOption>();
      }
   }
   get showInactiveProperties(): boolean {
      return this._showInactiveProperties;
   }

   get tabIndex(): number {
      return this._tabIndex;
   }

   get displayValue(): string {
      if (this.tabIndex === 0) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
         return this.propertySelector ? this.propertySelector.displayValue : "";
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
         return this.ownerSelector ? this.ownerSelector.displayValue : "";
      }
   }
   @Input() name: string = "property-owner-selector";

   @Input() ownerGroupID: number;

   @Input() propertyGroupID: number;

   @Input() selectedOwnerIDs: Array<FilterInputValueType> | FilterInputValueType;

   @Input() selectedPropertyIDs: Array<FilterInputValueType> | FilterInputValueType;

   @Input() hideInactiveCheckbox: boolean;

   @Input() hideOwnerGroupSelector: boolean;

   @Input() allCollectionItem: SelectorItemModel;

   @Output() ownerGroupIDChange = new EventEmitter<number>();

   @Output() ownerGroupChange = new EventEmitter<OwnerGroupModel>();

   @Output() propertyGroupIDChange = new EventEmitter<number>();

   @Output() propertyGroupChange = new EventEmitter<PropertyGroupModel>();

   @Output() selectedOwnerIDsChange = new EventEmitter<Array<number>>();

   @Output() selectedPropertyIDsChange = new EventEmitter<Array<number>>();

   @Output() propertySelectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() ownerSelectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() tabChange = new EventEmitter<EntityType>();

   @Output() showInactivePropertiesChange = new EventEmitter<boolean>();

   @Output() allSelected = new EventEmitter<boolean>();

   @ViewChild("matTabGroup", { static: true }) matTabGroup: MatTabGroup;

   @ViewChild("propertySelector", { static: true }) propertySelector: SingleLineMultiSelectComponent;

   @ViewChild("ownerSelector", { static: true }) ownerSelector: SingleLineMultiSelectComponent;

   loading: Observable<boolean>;

   _loading = new BehaviorSubject<boolean>(true);

   ownerGroupValueSource: ValueSourceModel;

   ownerReplacementName: string;

   ownerValueSource: ValueSourceModel;

   propertyGroupValueSource: ValueSourceModel;

   propertyReplacementName: string;

   propertyPluralReplacementName: string;

   propertyValueSource: ValueSourceModel;

   selectedOwnerGroupID: number;

   selectedPropertyGroupID: number;

   propertyFilters: Array<FilterOption>;

   disabledItems: Array<number>;

   disableAllItems: boolean = false;

   private _showInactiveProperties: boolean;

   private ownerGroups: Array<OwnerGroupModel>;

   private propertyGroups: Array<PropertyGroupModel>;

   private tabEntityType: EntityType;

   private unsubscribe = new Subject<void>();

   private _tabIndex: number = 0;

   private ownersSet: boolean = false;

   constructor(
      private propertyGroupsService: PropertyGroupsService,
      private ownerGroupsService: OwnerGroupsService,
      private errorMessageService: ErrorMessageService,
      private entityInformationService: EntityInformationService
   ) {
      this.loading = this._loading.asObservable();
      this.ownerReplacementName = this.entityInformationService.getEntityTypeInformation(EntityType.Owner).SingleLabel;
      const propertyInformation = this.entityInformationService.getEntityTypeInformation(EntityType.Property);
      this.propertyReplacementName = propertyInformation.SingleLabel;
      this.propertyPluralReplacementName = propertyInformation.CollectionLabel;

      this.buildSingleLineMultiSelectorValueSources();
      this.buildGroupsValueSources();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.propertyGroupID && changes.propertyGroupID.previousValue !== changes.propertyGroupID.currentValue) {
         this.selectedPropertyGroupID = this.propertyGroupID;
         if (this.tabEntityType === EntityType.Property) {
            this.updateSelectedValuesForGroupSelected();
         }
      }
      if (changes.ownerGroupID && changes.ownerGroupID.previousValue !== changes.ownerGroupID.currentValue) {
         this.selectedOwnerGroupID = this.propertyGroupID;
         if (this.tabEntityType === EntityType.Owner) {
            this.updateSelectedValuesForGroupSelected();
         }
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   ngAfterViewInit() {
      this.realignInkBar();
   }

   groupChanged(val: number) {
      const selectedGroupID = val;

      if (this.tabEntityType === EntityType.Property && this.propertyGroupID !== selectedGroupID) {
         this.propertyGroupID = selectedGroupID;
         this.propertyGroupIDChange.emit(this.propertyGroupID);
      } else if (this.tabEntityType === EntityType.Owner && this.ownerGroupID !== selectedGroupID) {
         this.ownerGroupID = selectedGroupID;
         this.ownerGroupIDChange.emit(this.ownerGroupID);
      }
      this.updateSelectedValuesForGroupSelected();
   }

   realignInkBar() {
      this.matTabGroup.realignInkBar();
   }

   tabChanged(index: number) {
      this.tabSelected(index);
      this.tabChange.emit(this.tabEntityType);
   }

   updateSelectedValuesForGroupSelected() {
      if (this.propertyGroups && this.ownerGroups) {
         if (this.tabEntityType === EntityType.Property && this.propertyGroupID) {
            this.selectedPropertyGroupID = this.propertyGroupID;
            const selectedPropertyGroup = this.propertyGroups.find(
               (pg) => pg.GroupID.toString() === this.propertyGroupID.toString()
            );
            if (selectedPropertyGroup) {
               this.getFilteredGroupProperties(selectedPropertyGroup.GroupID).subscribe(
                  (filteredGroup: PropertyGroupModel) => {
                     this.selectedPropertyIDs = filteredGroup.Properties.map((p) => p.PropertyID);
                     this.selectedPropertyIDsChange.emit(this.selectedPropertyIDs as Array<number>);
                     this.propertyGroupChange.emit(filteredGroup);
                  }
               );
            }
         } else if (this.tabEntityType === EntityType.Owner && this.ownerGroupID) {
            this.selectedOwnerGroupID = this.ownerGroupID;
            const selectedOwnerGroup = this.ownerGroups.find(
               (pg) => pg.GroupID.toString() === this.ownerGroupID.toString()
            );
            if (selectedOwnerGroup) {
               this.getFilteredGroupOwners(selectedOwnerGroup.GroupID).subscribe((filteredGroup: OwnerGroupModel) => {
                  this.selectedOwnerIDs = filteredGroup.Owners.map((o) => o.OwnerID);
                  this.selectedOwnerIDsChange.emit(this.selectedOwnerIDs as Array<number>);
                  this.ownerGroupChange.emit(filteredGroup);
               });
            }
         }
      }
   }
   onAllSelected(isAllSelected: boolean) {
      this.allSelected.emit(isAllSelected);
   }

   onPropertySelectionChange(selection: SelectionChangeModel) {
      if (selection.selectAll === true) {
         this.selectedPropertyIDsChange.emit([ConstantsService.ZeroFK]);
      }
      this.propertySelectionChange.emit(selection);
   }

   onOwnerSelectionChange(selection: SelectionChangeModel) {
      if (selection.selectAll === true) {
         this.selectedOwnerIDsChange.emit([ConstantsService.ZeroFK]);
      }
      this.ownerSelectionChange.emit(selection);
   }

   onShowInactiveProperties(isChecked: boolean) {
      this.showInactivePropertiesChange.emit(isChecked);
   }

   private getFilteredGroupProperties(groupID) {
      return this.propertyGroupsService
         .get(
            groupID,
            [PropertyGroupFields.Properties.Identifier],
            [PropertyGroupFields.Properties.Identifier + "." + PropertyFields.PropertyID.Identifier]
         )
         .pipe(take(1));
   }

   private getFilteredGroupOwners(groupID) {
      return this.ownerGroupsService
         .get(
            groupID,
            [OwnerGroupFields.Owners.Identifier],
            [OwnerGroupFields.Owners.Identifier + "." + OwnerFields.OwnerID.Identifier]
         )
         .pipe(take(1));
   }

   private buildGroupValueSource(items: Array<PropertyGroupModel | OwnerGroupModel>) {
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;

      let item = new SelectorItemModel();
      item.displayValue = "None";
      item.value = ConstantsService.NullFK;

      valueSourceModel.StaticValues.push(item);
      if (items && items.length > 0) {
         for (const group of items) {
            item = new SelectorItemModel();
            item.displayValue = group.Name;
            item.value = group.GroupID;
            valueSourceModel.StaticValues.push(item);
         }
      }
      return valueSourceModel;
   }

   private buildGroupsValueSources() {
      forkJoin([
         this.propertyGroupsService.getCollection(
            null,
            [PropertyGroupFields.PropertyGroupDetails.Identifier],
            [new OrderingOption(PropertyGroupFields.Name.Identifier)],
            [
               PropertyGroupFields.GroupID.Identifier,
               PropertyGroupFields.Name.Identifier,
               PropertyGroupFields.FilterID.Identifier,
               PropertyGroupFields.PropertyGroupDetails.Identifier +
                  "." +
                  PropertyGroupDetailFields.PropertyID.Identifier,
            ]
         ),
         this.ownerGroupsService.getCollection(
            null,
            [OwnerGroupFields.OwnerGroupDetails.Identifier],
            [new OrderingOption(OwnerGroupFields.Name.Identifier)],
            [
               OwnerGroupFields.GroupID.Identifier,
               OwnerGroupFields.Name.Identifier,
               OwnerGroupFields.FilterID.Identifier,
               OwnerGroupFields.OwnerGroupDetails.Identifier + "." + OwnerGroupDetailFields.OwnerID.Identifier,
            ]
         ),
      ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            ([propertyGroups, ownerGroups]) => {
               this.propertyGroups = propertyGroups;
               this.propertyGroupValueSource = this.buildGroupValueSource(this.propertyGroups);
               this.ownerGroups = ownerGroups;
               this.ownerGroupValueSource = this.buildGroupValueSource(this.ownerGroups);
               this.updateSelectedValuesForGroupSelected();
               this._loading.next(false);
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            }
         );
   }

   private buildSingleLineMultiSelectorValueSources() {
      const ownerValueSource = this.buildBaseValueSource();
      ownerValueSource.OrderingOptions = ["Name"];
      ownerValueSource.EndpointIsSearch = true;
      ownerValueSource.EntityValueSourcePath = "OwnerID";
      ownerValueSource.EntityType = EntityType.Owner;
      this.ownerValueSource = ownerValueSource;

      const propertyValueSource = this.buildBaseValueSource();
      propertyValueSource.OrderingOptions = ["Name"];
      propertyValueSource.EndpointIsSearch = true;
      propertyValueSource.EntityValueSourcePath = "PropertyID";
      propertyValueSource.EntityType = EntityType.Property;
      if (this.hideInactiveCheckbox || !this.showInactiveProperties) {
         propertyValueSource.Filters = new Array<FilterOption>(
            new FilterOption("IsActive", FilterOperations.EqualTo, [true])
         );
      }
      if (this.allCollectionItem) {
         propertyValueSource.StaticValues = [this.allCollectionItem];
      }

      this.propertyValueSource = propertyValueSource;
   }

   private buildBaseValueSource() {
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Entity;
      valueSourceModel.AllowsSelectAll = true;
      valueSourceModel.AllowsMultipleValues = true;
      valueSourceModel.AllValue = ConstantsService.ZeroFK.toString();
      valueSourceModel.DefaultValue = "";
      valueSourceModel.DisplayValueSourcePath = "Name";

      return valueSourceModel;
   }

   private tabSelected(index: number) {
      this._tabIndex = index;
      if (index === 0) {
         this.tabEntityType = EntityType.Property;
      } else if (index === 1) {
         this.tabEntityType = EntityType.Owner;
         if (this.ownersSet === false) {
            this.selectedOwnerIDsChange.emit([ConstantsService.ZeroFK]);
            this.ownersSet = true;
         }
      }
   }
}
