import { Countries } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/countries.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";

import { EpayPaymentType } from "../enumerations/epay-paymentype.enum";
import { EpayInformationModel } from "./generated/epay-information.model";
import { OwnerContributionACHPaymentViewModel } from "./owner-contribution-achpayment-view-model";
import { OwnerContributionCCPaymentViewModel } from "./owner-contribution-ccpayment-view.model";
import { OwnerContributionModel } from "./owner-contribution.model";

export class OwnerContributionPaymentDetailViewModel {
   ContributionType: EpayPaymentType;
   eEpayAccountType: EpayAccountType;
   Items: Array<OwnerContributionModel>;
   Amount: number;
   ConvenienceFee?: number;
   Total: number;
   Comment: string;
   EpayInfo: EpayInformationModel;
   AccountTypeID?: number;
   AccountTypes: Array<EpayAccountType>;
   AccountNumber: string;
   ExpirationMonth?: number;
   ExpirationYear?: number;
   RoutingNumber: string;
   BillingAddressStreet1: string;
   BillingAddressStreet2: string;
   BillingAddressCity: string;
   BillingAddressState: string;
   BillingAddressPostalCode: string;
   EpayCC: OwnerContributionCCPaymentViewModel;
   EpayACH: OwnerContributionACHPaymentViewModel;
   IsDisclaimerRequired: boolean;
   IsDisclaimerChecked: boolean;
   DisclaimerMessage: string;
   BankCountryID: Countries;
   IsDebitCard: boolean;
}
