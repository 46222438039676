<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && jobReportParameter.IsAsk === true)"
   >{{
      jobReportParameter?.DisplayName
      }}</label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="jobReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-dynamic-selector-report-parameter
   [hideLabel]="true"
   [name]="name + '-job'"
   [disabled]="disabled || (hasAsk === true && jobReportParameter.IsAsk === true)"
   [parameter]="jobReportParameter"
   [entityValueSourceFilters]="jobsFilters"
   [selectAllItemsOnFilterChange]="false"
></lcs-dynamic-selector-report-parameter>
<div class="additional-controls-wrapper">
   <lcs-checkbox-report-parameter
      [name]="name + '-include-inactive-job'"
      [disabled]="disabled || (hasAsk === true && jobReportParameter.IsAsk === true)"
      [parameter]="includeInactiveJobsReportParameter"
   ></lcs-checkbox-report-parameter>
</div>