import { Portal } from "@angular/cdk/portal";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, isDevMode, OnChanges, OnDestroy, OnInit, Optional, Output, TemplateRef, ViewChild } from "@angular/core";
import { ControlContainer, NgControl, UntypedFormControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ControlContainerViewProvider } from "@lcs/inputs/control-container-view-providers";
import { UserInputComponent } from "@lcs/inputs/user-input-component.interface";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { MobileFocusOverlayService } from "@lcs/mobile-focus-overlay/mobile-focus-overlay.service";
import { ValueComparerHelper } from "@lcs/select/helpers/value-comparer.helper";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { KEY_DOWN, KEY_UP } from "keycode-js";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import xor from "lodash/xor";
import { SelectItem } from "primeng/api";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { BehaviorSubject, fromEvent, Observable, Subject, takeUntil } from "rxjs";

import { OverlayPortalAttachedModel } from "./models/overlay-portal-attached.model";
import { ValueSourceService } from "./value-source.service";

@Component({
   selector: "lcs-single-line-multi-select",
   templateUrl: "single-line-multi-select.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class SingleLineMultiSelectComponent
   extends ValueAccessorBase<any>
   implements OnDestroy, OnInit, UserInputComponent, AfterViewInit, OnChanges
{
   @Input() alignOverlayPanelToRight = false;

   @Input() allSelectedMessage: string;

   @Input() staticOverlay: boolean = false;

   @Input() isShowLabel: boolean = false;

   @Input() footerLabelAdditional: string;

   @Input() customValidatorData: any;

   @Input() set entityValueSourceFilters(filters: Array<FilterOption> | null) {
      // NOTE: using this._entityValueSourceFilters backing field instead of setting this._valueSource.Filters
      //       directly because there is no guarantee which input will be initialized first.
      //       Values will be synched in ngOnInit()
      this._entityValueSourceFilters = filters ? filters : null;
      if (
         this._entityValueSourceFilters &&
         this._valueSource &&
         this._entityValueSourceFilters !== this._valueSource.Filters
      ) {
         this._valueSource.Filters = this._entityValueSourceFilters;
         this.processValueSource(this._valueSource);
      }
   }

   get entityValueSourceFilters(): Array<FilterOption> | null {
      // in the event that entityValueSourceFilters is never set, but filters are set on values source return those, otherwise null
      return this._entityValueSourceFilters ?? this._valueSource?.Filters ?? null;
   }

   @Input() hideLabel: boolean = false;

   @Input() name: string;

   @Input() selectorInputTemplate: TemplateRef<any>;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() standalone: boolean;

   @Input() validation: ValidationModel;

   @Input() set valueSource(value: ValueSourceModel) {
      if (value) {
         this._valueSource = value;
         this.processValueSource(this._valueSource);
      } else {
         this._isLoading.next(false);
      }
   }

   get valueSource(): ValueSourceModel {
      return this._valueSource;
   }

   @Input() set disabledItems(disabledItems: Array<any>) {
      if (disabledItems && !isEqual(this._disabledItems, disabledItems)) {
         this._disabledItems = disabledItems;
         this.setDisabledItems();
      }
   }

   get disabledItems() {
      return this._disabledItems;
   }

   @Input() overrideIsAllSelected: boolean;

   @Input() inlineLabel: boolean;

   @Input() hideSelectAll: boolean;

   @ViewChild("singleLineMultiSelectorWrapper", { static: true }) singleLineMultiSelectorWrapper: ElementRef;

   @Input() closedIcon = "expand_more";

   @Input() openIcon = "expand_less";

   @Input() afterItemsPortal: Portal<any>;

   @Input() allowMobileFocusMode?: boolean = true;

   @Input() selectAllItemsOnFilterChange: boolean = true;

   @Input() initializedEmpty?: boolean = false;

   @Input() isNotShowLoading?: boolean = false;

   @Input() disableAllItems: boolean;

   @Input() NoOfSelectedItemsToDisplay = 1;

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() panelClose = new EventEmitter<void>();

   @Output() afterItemsPortalAttached = new EventEmitter<OverlayPortalAttachedModel>();

   @Output() processValueItems = new EventEmitter<Array<SelectorItemModel>>();

   @Output() allSelected = new EventEmitter<boolean>();

   isLoading: Observable<boolean>;

   control: UntypedFormControl;

   defaultValue: any;

   showOverlayPanel: boolean = false;

   options: Array<SelectItem>;

   selectedItemCount: number = 0;

   items = new Array<SelectorItemModel>();

   displayValue: string | null;

   titleValue: string[] | string;

   isAllSelected: boolean;

   overlayPanelStaticWidth: number;

   overlayPanelStaticMinWidth: number;

   private _isLoading = new BehaviorSubject<boolean>(true);

   private unsubscribe = new Subject<void>();

   private valueChanged: boolean;

   private _valueSource: ValueSourceModel;

   private _disabledItems: Array<any>;

   private _entityValueSourceFilters: Array<FilterOption> | null = null;

   private originalHideLabel: boolean;

   private inputsAreInitialized: boolean = false;

   constructor(
      private valueSourceService: ValueSourceService,
      private formRegistrationService: FormRegistrationService,
      private errorMessageService: ErrorMessageService,
      private mobileFocusOverlayService: MobileFocusOverlayService,
      private elRef: ElementRef,
      @Optional() private controlContainer: ControlContainer,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.isLoading = this._isLoading.asObservable();

      this.registerOnValueWritten((value: any) => {
         if (this.selectAllItemsOnFilterChange) {
            this.innerValue = value;
            this.selectedItemCount = 0;
            this.setSelectedItems();
            this.setDisplayValue();
            this.setSelectorToBeAllSelected(this.overrideIsAllSelected);
         }
      });
   }

   onIconClicked() {
      this.calculateOverlay();
   }

   onResize() {
      this.calculateOverlay();
   }

   ngOnInit(): RequiredSuperCallFlag {
      this.inputsAreInitialized = true;
      if (this._entityValueSourceFilters && this._entityValueSourceFilters.length > 0) {
         // if _entityValueSourceFilters was set, sync to _valueSource
         this._valueSource.Filters = this._entityValueSourceFilters;
      }

      this.processValueSource(this.valueSource);
      return super.ngOnInit();
   }

   ngAfterViewInit() {
      setTimeout(() => {
         if (this.controlContainer?.control && this.name) {
            this.control = this.controlContainer.control.get(this.name) as UntypedFormControl;
         }

         if (!this.control && this.ngControl.control) {
            this.control = this.ngControl.control as UntypedFormControl;
         }

         if (this.control) {
            this.formRegistrationService.addControl(this.name, this.control, this.displayName, this.path);
         } else if (isDevMode()) {
         }
      });

      fromEvent<KeyboardEvent>(this.singleLineMultiSelectorWrapper.nativeElement, "keydown")
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((event) => {
            if (event.keyCode === KEY_DOWN || event.keyCode === KEY_UP) {
               if (!this.showOverlayPanel) {
                  this.calculateOverlay();
                  this.showOverlayPanel = true;
                  event.preventDefault();
               }
            }
         });

      this.mobileFocusOverlayService.mobileFocusModeAllowed.subscribe((isAllowed) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isAllowed) {
               if (this.showOverlayPanel) {
                  this.tryRequestMobileFocusMode();
               } else {
                  this.mobileFocusOverlayService.cancelMobileFocusModeRequest();
               }
            } else if (this.mobileFocusOverlayService.inMobileFocusMode()) {
               this.mobileFocusOverlayService.endMobileFocusMode();
            }
         }
      });

      this.mobileFocusOverlayService.mobileFocusModeSet.subscribe((isSet) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isSet) {
               this.originalHideLabel = this.hideLabel;
               this.hideLabel = true;
               this.showOverlayPanel = false;
               this.calculateOverlay();
               setTimeout(() => {
                  this.showOverlayPanel = true;
               });
            } else {
               this.hideLabel = this.originalHideLabel;
               this.showOverlayPanel = false;
               this.calculateOverlay();
            }
         }
      });
   }

   ngOnChanges() {
      this.setSelectedItems();
      this.setDisplayValue();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      this.formRegistrationService.removeControl(this.control);
   }

   gainFocus() {
      this.tryRequestMobileFocusMode();
   }

   lostFocus() {
      if (!this.initializedEmpty) {
         this.propagateTouched();
      }
   }

   onVisibilityChanged(show: boolean) {
      this.calculateOverlay();
      this.showOverlayPanel = show;
      if (!show) {
         if (this.valueChanged) {
            this.panelClose.emit();
         }
         this.propagateTouched();
      }
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.valueChanged = true;
      this.selectedItemCount = selectionChange.checkedItems ? selectionChange.checkedItems.length : 0;

      if (
         this.valueSource &&
         this.valueSource.AllValue &&
         this.selectedItemCount === this.items.length &&
         this.valueSource.AllowsSelectAll
      ) {
         selectionChange.selectAll = true;
      } else {
         selectionChange.selectAll = false;
      }

      const selectedValues = selectionChange.checkedItems.map((item) => item.value);

      if (this.valueSource.MultipleValueDelimiter) {
         this.value = selectedValues.join(this.valueSource.MultipleValueDelimiter);
      } else {
         this.value = selectedValues;
      }

      this.setDisplayValue();
      this.allSelected.emit(this.selectedItemCount === this.items.length);
      this.selectionChange.emit(selectionChange);
   }

   setDisabledItems() {
      if (this.disableAllItems) {
         this.items.forEach((item) => {
            item.isDisabled = true;
            item.isChecked = false;
         });
      } else {
         this.items.forEach((item) => {
            if (this.disabledItems.find((di) => +di === +item.value) !== undefined) {
               item.isDisabled = true;
            } else {
               item.isDisabled = false;
            }
         });
      }
   }

   onAfterItemsPortalAttached(event: OverlayPortalAttachedModel) {
      this.afterItemsPortalAttached.emit(event);
   }

   private setDisplayValue() {
      const filteredSelectedItemCount = this.items.filter((f) => f.isChecked && !f.isMutuallyExclusiveAll)?.length;

      if (
         filteredSelectedItemCount > 0 &&
         filteredSelectedItemCount === this.items.filter((f) => !f.isMutuallyExclusiveAll)?.length
      ) {
         if (this.items.length > 0) {
            this.displayValue = this.allSelectedMessage || "All selected";
         }
      } else {
         if (this.selectedItemCount <= this.NoOfSelectedItemsToDisplay) {
            let display = "";
            this.items.filter((i) => i.isChecked)?.forEach((f) => (display += ", " + f.displayValue));
            this.displayValue = display.slice(2, display.length);
         } else {
            this.displayValue = `${this.selectedItemCount} Selected`;
         }
      }

      const titleList = new Array<string>();
      this.items.forEach((i) => {
         if (i.isChecked) {
            const title = i.tooltip ? i.tooltip : i.displayValue;
            titleList.push(" " + title);
         }
      });
      this.titleValue = titleList;
   }

   private isSelectedAll() {
      if (this.valueSource && this.valueSource.AllValue) {
         if (
            this.valueSource.DefaultValue &&
            this.valueSource.DefaultValue.toLowerCase() === this.valueSource.AllValue.toLowerCase() &&
            (!this.innerValue || this.innerValue.length === 0) &&
            this.selectAllItemsOnFilterChange
         ) {
            return true;
         }
         if (
            this.innerValue &&
            this.innerValue.length > 0 &&
            this.innerValue[0].toString().toLowerCase() === this.valueSource.AllValue.toLowerCase()
         ) {
            return true;
         }
      }
      return false;
   }

   private calculateOverlay() {
      if (this.singleLineMultiSelectorWrapper) {
         this.overlayPanelStaticWidth = Math.ceil(
            this.singleLineMultiSelectorWrapper.nativeElement.getBoundingClientRect().width
         );
         this.overlayPanelStaticMinWidth = this.overlayPanelStaticWidth;
      }
   }

   private processValueSource(valueSource: ValueSourceModel) {
      if (!this.inputsAreInitialized) {
         // avoid potential duplicate and unnecessary processing during input initialization
         return;
      }
      if (this.isNotShowLoading) {
         this._isLoading.next(false);
      } else {
         this._isLoading.next(true);
      }
      if (valueSource && valueSource.Type) {
         this.valueSourceService
            .process(valueSource)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
               next: (results) => {
                  this.selectedItemCount = 0;
                  if (!results) {
                     this.items = [];
                     if (this.valueSource.Type !== ValueSourceTypes.Static && this.valueSource.StaticValues) {
                        const staticItems = clone(this.valueSource.StaticValues);
                        this.items.unshift(...staticItems);
                        this.setSelectedItems();
                        this.setSelectorToBeAllSelected(this.overrideIsAllSelected);
                        this.processValueItems.emit(this.items);
                     } else if (
                        this.control &&
                        xor(
                           this.control.value,
                           this.items.map((i) => i.value)
                        ).length > 0
                     ) {
                        this.control.setValue(this.items);
                     }
                  } else {
                     if (this.valueSource.Type !== ValueSourceTypes.Static && this.valueSource.StaticValues) {
                        // if not a static value source but staticValues exist, insert static items at front of items list
                        const staticItems = clone(this.valueSource.StaticValues);
                        results = results.filter((i) => !this.valueSourceService.itemAlreadyInItemSet(i, staticItems));
                        results.unshift(...staticItems);
                        this.items = results;
                     } else {
                        this.items = results;
                     }

                     if (this.control?.value) {
                        if (
                           this.control.value.length === 0 &&
                           this._valueSource.SelectedValues &&
                           this._valueSource.SelectedValues.length !== 0
                        ) {
                           this._valueSource.SelectedValues = this._valueSource.SelectedValues.filter((f) =>
                              this.items.find((iv) => ValueComparerHelper.simpleComparer(iv.value, f))
                           );

                           this.control.setValue(this._valueSource.SelectedValues);
                        } else if (
                           this.control.value.length === 1 &&
                           (this.control.value[0] == null || this.control.value[0].length === 0) &&
                           this.valueSource.DefaultValue != null &&
                           this.valueSource.DefaultValue.length === 0
                        ) {
                           // If DefaultValue is an empty string then control value is initialized to an array
                           // with one item which is an empty string.  This passes required field validation
                           // allowing the form to be submitted with essentially nothing selected.
                           // By setting the value to an empty list vs a list with 1 empty item, we allow
                           // required field validation to fail as expected.
                           this.control.setValue([]);
                        } else {
                           this.setSelectedItems();
                           this.setSelectorToBeAllSelected(this.overrideIsAllSelected);
                        }
                     } else {
                        this.setSelectedItems();
                        this.setSelectorToBeAllSelected(this.overrideIsAllSelected);
                     }

                     if (this.disabledItems) {
                        this.setDisabledItems();
                     }
                     this.processValueItems.emit(this.items);
                  }
                  this.setDisplayValue();
                  this.setSelectorToBeAllSelected(this.overrideIsAllSelected);
               },
               error: (err) => {
                  this.errorMessageService.triggerHttpErrorMessage(err);
               },
               complete: () => {
                  this._isLoading.next(false);
               },
            });
      } else if (isDevMode()) {
         console.warn(
            `DevMode: Selector name="${this.name}" processValueSource(...) aborted, valueSource is null, undefined, or has a missing or invalid Type property. valueSource=`,
            valueSource
         );
      }
   }

   private selectAll() {
      let selectedItemCount = 0;
      this.items = this.items
         .filter((i) => !i.isDisabled)
         .map((item: SelectorItemModel) => {
            item.isChecked = true;
            selectedItemCount++;
            return item;
         });
      this.selectedItemCount = selectedItemCount;
   }

   private setSelectedItems() {
      if (!this.items || this.items.length === 0) {
         return;
      }
      if (this.isSelectedAll() && !this.disableAllItems) {
         this.selectAll();
      } else if (this.items && this.items.length > 0 && this.innerValue) {
         let selectedItemCount = 0;
         let valuesArray = this.innerValue;
         if (this.valueSource && this.valueSource.MultipleValueDelimiter && this.innerValue) {
            valuesArray = this.innerValue.toString().split(this.valueSource.MultipleValueDelimiter);
         }

         this.items.forEach((item) => {
            if (valuesArray.find((iv) => ValueComparerHelper.simpleComparer(iv, item.value)) !== undefined) {
               item.isChecked = true;
               selectedItemCount++;
            } else {
               item.isChecked = false;
            }
         });
         this.selectedItemCount = selectedItemCount;
      }

      const filteredSelectedItemCount = this.items.filter((f) => f.isChecked && !f.isMutuallyExclusiveAll)?.length;

      if (
         filteredSelectedItemCount > 0 &&
         filteredSelectedItemCount === this.items.filter((f) => !f.isMutuallyExclusiveAll)?.length
      ) {
         this.isAllSelected = true;
      } else {
         this.isAllSelected = false;
      }

      this.allSelected.emit(this.isAllSelected);
   }

   private setSelectorToBeAllSelected(boolean) {
      if (boolean) {
         this.isAllSelected = boolean;
         this.displayValue = "All selected";
         this.allSelected.emit(this.isAllSelected);
      }
   }

   private tryRequestMobileFocusMode() {
      if (this.allowMobileFocusMode) {
         this.mobileFocusOverlayService.requestMobileFocusMode(this.elRef);
      }
   }
}
