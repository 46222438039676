import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-balance-due-values-to-include-report-parameter",
   templateUrl: "balance-due-values-to-include-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class BalanceDueValuesToIncludeParameterComponent implements OnInit, UserInputComponent, OnDestroy {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() public set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   isAllSelected: boolean;

   valuesToIncludeParameter: ReportReportParameterViewModel;

   valuesToIncludeAmountParameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      let isAllSelected = true;
      if (this.valuesToIncludeParameter) {
         if (
            this.valuesToIncludeParameter.ReportParameterValueSource &&
            this.valuesToIncludeParameter.ReportParameterValueSource.DefaultValue &&
            this.valuesToIncludeParameter.ReportParameterValueSource.DefaultValue.toString().toLowerCase() !== "all"
         ) {
            isAllSelected = false;
         }
      }
      this.isAllSelected = isAllSelected;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.ValuesToInclude) {
         this.isAllSelected = updatedValue.value === "All";
      }
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.valuesToIncludeParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ValuesToInclude
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.valuesToIncludeAmountParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ValuesToIncludeAmount
      );

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
