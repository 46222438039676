import { HttpResponse } from "@angular/common/http";
import { Directive, Input, OnDestroy, OnInit, Self } from "@angular/core";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import cloneDeep from "lodash/cloneDeep";
import { EnumerationInformationService } from "projects/libraries/lcs/src/lib/utils/enumeration-information.service";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { EnumerationInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/enumeration-information.model";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Observable, of, switchMap, takeUntil } from "rxjs";

import { SelectComponent } from "../components/select.component";
import { StaticSetSelectorDirectiveBase } from "./static-set-selector.directive.base";

@Directive({
   selector: "[lcsLegacySingleSelector]",
})
export class LegacySingleSelectorDirective extends StaticSetSelectorDirectiveBase implements OnInit, OnDestroy {
   /**
    * filters is is used for legacy code to support backward compatibility and only supports 'And'ed filters.
    * Use filterExpression to support more complex filters with subExpressions and 'Or' operators.
    *
    * NOTE: filters ONLY applies to ValueSource of type Entity
    *
    * WARNING: it is invalid to define both filters and a filterExpression and will result in an error
    */
   @Input() set filters(val: Array<FilterOption>) {
      this._filters = val;
      this.itemSet = [];
      this.processValueSource();
   }

   /**
    * filterExpression supports complex filters with subExpressions and both 'And' and 'Or' operators.
    *
    * NOTE: filterExpression ONLY applies to ValueSource of type Entity
    *
    * WARNING: it is invalid to define both filters and a filterExpression and will result in an error.
    */
   @Input() set filterExpression(val: FilterExpression) {
      this._filterExpression = val;
      this.itemSet = [];
      this.processValueSource();
   }

   /**
    * valueSource defines how/where to obtain the items for the selector.
    * Any Filters defined on the valueSource serve as the base set of filters when retrieving
    * items for Entity value sources. Any additional filters or filterExpression will be
    * 'And'ed with the valueSource Filters.
    *
    * NOTE: Any StaticValues on the valueSource will be inserted at the head of the list
    *       followed by any additional items obtained based on the valueSource type.
    */
   @Input() set valueSource(val: ValueSourceModel) {
      if (val) {
         this._valueSource = val;
         this.processValueSource();
      }
   }

   get valueSource(): ValueSourceModel {
      return this._valueSource;
   }

   private _valueSource: ValueSourceModel;

   private _filters: Array<FilterOption>;

   private _filterExpression: FilterExpression;

   private inputsAreInitialized: boolean = false;
   constructor(
      private apiService: ApiService,
      private objectMapResolverService: ObjectMapResolverService,
      private enumerationInformationService: EnumerationInformationService,
      private entityRequestService: EntityRequestService,
      @Self() protected selectComponent: SelectComponent
   ) {
      super(selectComponent);
   }

   ngOnInit(): void {
      this.inputsAreInitialized = true;
      this.processValueSource();
   }

   ngOnDestroy() {
      super.ngOnDestroy();
   }

   private appendNewItemsToItemSet(itemSet: Array<SelectorItemModel>) {
      if (!this.itemSet || this.itemSet.length === 0) {
         // if this.itemSet is null or empty then just assign to new itemSet
         this.itemSet = itemSet;
      } else {
         // add any items from itemSet not already in existing itemSet based on item.value
         // NOTE: assumes itemSet passed in contains items with unique values
         const existingItemValueSet = new Set<SelectorItemModel>(this.itemSet.map((i) => i.value));
         this.itemSet = [...this.itemSet, ...itemSet.filter((i) => !existingItemValueSet.has(i.value))];
      }
   }

   private processValueSource() {
      if (!this.inputsAreInitialized) {
         // prevent potential unnecessary processing of valueSource before all inputs have been initialized
         return;
      }
      if (!this.valueSource) {
         this.selectComponent.errorMessage = "Configuration Incorrect: ValueSource is required";
         return;
      }
      if (!this.valueSource.Type) {
         this.selectComponent.errorMessage = "Configuration Incorrect: ValueSource Type is required";
         return;
      }

      this.itemSet = new Array<SelectorItemModel>();

      if (this.valueSource.Type !== ValueSourceTypes.Static && this.valueSource.StaticValues.length > 0) {
         // If Static values are specified for a non-Static value source, add them first
         // This allows augmenting a non-static value source with additional needed values,
         // without building a full static value source on the server just to add a few special values
         this.processStaticValueSource(this.valueSource);
      }

      if (this.valueSource.Type === ValueSourceTypes.Static) {
         this.processStaticValueSource(this.valueSource);
      } else if (this.valueSource.Type === ValueSourceTypes.EmbeddedSet) {
         this.processEmbeddedSetValueSource(this.valueSource);
      } else if (this.valueSource.Type === ValueSourceTypes.Enumeration) {
         this.processEnumerationValueSource(this.valueSource);
      } else if (this.valueSource.Type === ValueSourceTypes.Entity) {
         this.processEntityValueSource(this.valueSource);
      } else {
         throw new Error("Unable to process Value Source");
      }
   }

   private processStaticValueSource(source: ValueSourceModel) {
      const itemSet = new Array<SelectorItemModel>();
      if (!source.StaticValues || source.StaticValues.length === 0) {
         this.selectComponent.additionalMessage = "No selectable items";
      } else {
         this.selectComponent.additionalMessage = "";
         for (const value of source.StaticValues) {
            const selectorItem = cloneDeep(value);
            selectorItem.isChecked = false;
            if (source.SelectedValues && source.SelectedValues.indexOf(selectorItem.value) !== -1) {
               selectorItem.isChecked = true;
            }
            itemSet.push(selectorItem);
         }
      }
      this.appendNewItemsToItemSet(itemSet);
   }
   private processEmbeddedSetValueSource(source: ValueSourceModel) {
      const itemSet = new Array<SelectorItemModel>();

      const selectedValue = ObjectMapResolverService.getPropertyValue(source.EmbeddedSet, source.EntityValueSourcePath);

      ObjectMapResolverService.getPropertyValue(source.EmbeddedSet, source.EmbeddedSetValueSourcePath)
         .split(source.EmbeddedSetValueSourceDelimiter)
         .map((item) => {
            const selectorItem = new SelectorItemModel();
            selectorItem.displayValue = item;
            selectorItem.value = item;
            selectorItem.isChecked = selectedValue === item;
            itemSet.push(selectorItem);
         });
      this.appendNewItemsToItemSet(itemSet);
   }

   private processEnumerationValueSource(source: ValueSourceModel) {
      const itemSet = new Array<SelectorItemModel>();
      this.enumerationInformationService
         .getEnumerationInformation(source.EnumerationName)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results: EnumerationInformationModel[]) => {
            for (const result of results) {
               if (source.ValuesToExclude.map((val) => val.toString()).indexOf(result.Value.toString()) === -1) {
                  const selectorItem = new SelectorItemModel();
                  selectorItem.value = result.Value;
                  if (!isNaN(+selectorItem.value)) {
                     selectorItem.value = +selectorItem.value;
                  }
                  selectorItem.displayValue = result.Description;
                  selectorItem.isChecked =
                     source.SelectedValues &&
                     source.SelectedValues.some(
                        (v) =>
                           v != null &&
                           selectorItem.value != null &&
                           v.toString().toLowerCase() === selectorItem.value.toString().toLowerCase()
                     );
                  itemSet.push(selectorItem);
               }
            }
            this.appendNewItemsToItemSet(itemSet);
         });
   }

   private processEntityValueSource(source: ValueSourceModel) {
      let itemSet = new Array<SelectorItemModel>();

      // TODO: detect and pass embeds
      let apiURLObservable: Observable<string>;
      if (source.Endpoint) {
         apiURLObservable = of(source.Endpoint);
      } else if (source.EntityType) {
         apiURLObservable = this.entityRequestService.getEntityEndpoint(
            source.EntityType,
            null,
            null,
            source.IsQuickSearchEnabled
         );
      } else {
         this.selectComponent.errorMessage = "This control has not been configured correctly.";
         throw new Error("The endpoint should have been provided on the value source, and was not.");
      }
      apiURLObservable
         .pipe(
            takeUntil(this.unsubscribe),
            switchMap((apiURL) => {
               source.Endpoint = apiURL;
               const filterExpression = new FilterExpression();
               if (this._filters && this._filters.length > 0 && this._filterExpression) {
                  this.selectComponent.errorMessage = "This control has not been configured correctly.";
                  const errorMessage =
                     "It is invalid to define both an array of filters and a filterExpression for the legacy-single-selector-directive.";
                  console.error(errorMessage);
                  throw new Error(errorMessage);
               }
               // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOption[] | undefined' is not assignabl... Remove this comment to see the full error message
               filterExpression.FilterOptions = source.Filters?.slice(); // Add any valueSource filters since they provide the base set of values

               if (this._filterExpression) {
                  filterExpression.SubExpressions.push(this._filterExpression); // Add filterExpression as a SubExpression providing additional filters
               } else if (this._filters) {
                  // Add any additional filters not already in the base set of valueSource filters
                  this._filters.forEach((f) => {
                     if (
                        !source.Filters?.find((sf) => sf.FilterName === f.FilterName && sf.Operation === f.Operation)
                     ) {
                        filterExpression.FilterOptions.push(f);
                     }
                  });
               }

               if (source.EndpointIsSearch) {
                  return this.apiService.getSearchResponse(
                     apiURL,
                     filterExpression,
                     [],
                     source.OrderingOptions,
                     this.objectMapResolverService.processEntityValueSourcePathInformation(source)
                  );
               } else {
                  return this.apiService.getCollection(
                     apiURL,
                     filterExpression,
                     [],
                     source.OrderingOptions,
                     this.objectMapResolverService.processEntityValueSourcePathInformation(source)
                  );
               }
            }),
            switchMap((results) => {
               if ((results as HttpResponse<any>).body) {
                  return of((results as HttpResponse<any>).body);
               } else if ((results as HttpResponse<any>).body === null) {
                  return of(null);
               } else {
                  return of(results);
               }
            })
         )
         .subscribe(
            (results) => {
               itemSet = results ? results.map((result) => this.processEntityItem(result)) : [];
               if (this.valueSource.AdditionalEntityItems && this.valueSource.AdditionalEntityItems.length > 0) {
                  if (itemSet && itemSet.length > 0) {
                     const additionalItems = this.valueSource.AdditionalEntityItems.filter(
                        // eslint-disable-next-line eqeqeq
                        (additional) => !itemSet.some((existing) => existing.value == additional.value)
                     );
                     itemSet = [...additionalItems, ...itemSet];
                  } else {
                     itemSet = this.valueSource.AdditionalEntityItems;
                  }
               }
               this.appendNewItemsToItemSet(itemSet);
            },
            () => {
               this.selectComponent.errorMessage = "Error while retrieving results";
            },
            () => {
               if (!itemSet || itemSet.length === 0) {
                  this.selectComponent.errorMessage = SelectComponent.noItemsFoundMessage;
               } else {
                  this.selectComponent.errorMessage = "";
               }
            }
         );
   }

   private processEntityItem(searchResult: any): SelectorItemModel {
      const item = new SelectorItemModel();
      item.value = ObjectMapResolverService.getPropertyValue(searchResult, this.valueSource.EntityValueSourcePath);
      item.displayValue = this.objectMapResolverService.getDisplayValue(
         searchResult,
         this.valueSource.DisplayValueSourcePath
      );
      item.additionalInfoValue = ObjectMapResolverService.getPropertyValue(
         searchResult,
         this.valueSource.AdditionalInfoSourcePath
      );
      if (this.valueSource.AdditionalDataFields) {
         for (const additionalDataField of this.valueSource.AdditionalDataFields) {
            item.additionalData.set(
               additionalDataField,
               ObjectMapResolverService.getPropertyValue(searchResult, additionalDataField)
            );
         }
      }
      return item;
   }
}
