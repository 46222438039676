import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { BillDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/bill.delete-options";
import { ChargeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/charge.delete-options";
import { CheckDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/check.delete-options";
import { InvoiceDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/invoice.delete-options";
import { OwnerDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/owner.delete-options";
import { PaymentReversalDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/payment-reversal.delete-options";
import { PaymentDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/payment.delete-options";
import { PhoneNumberTypeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/phone-number-type.delete-options";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { AddressTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address-type.embed-options";
import { AddressEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address.embed-options";
import { AppointmentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/appointment.embed-options";
import { BillEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/bill.embed-options";
import { BillableExpenseEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/billable-expense.embed-options";
import { ChargeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge.embed-options";
import { CheckEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check.embed-options";
import { ColorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/color.embed-options";
import { ContactEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/contact.embed-options";
import { EpayInformationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/epay-information.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { HistoryCallEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-call.embed-options";
import { HistoryEmailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-email.embed-options";
import { HistoryNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-note.embed-options";
import { HistorySystemNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-system-note.embed-options";
import { HistoryVisitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-visit.embed-options";
import { HistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history.embed-options";
import { InvoiceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/invoice.embed-options";
import { NachaOwnerBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/nacha-owner-bank.embed-options";
import { OpenPrepayEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/open-prepay.embed-options";
import { OWAFileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owafile.embed-options";
import { OwnerContractEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-contract.embed-options";
import { OwnerGroupDetailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-group-detail.embed-options";
import { OwnerGroupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-group.embed-options";
import { OwnerWebAccessOwnerPreferenceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-web-access-owner-preference.embed-options";
import { OwnerWebAccessReportPrivilegeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner-web-access-report-privilege.embed-options";
import { OwnerEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/owner.embed-options";
import { PayOwnerEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/pay-owner.embed-options";
import { PaymentReversalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/payment-reversal.embed-options";
import { PaymentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/payment.embed-options";
import { PhoneNumberTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number-type.embed-options";
import { PhoneNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number.embed-options";
import { RMVoIPCallHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rmvo-ipcall-history.embed-options";
import { TaskEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { WebUserAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/web-user-account.embed-options";
import { WebUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/web-user.embed-options";
import { GetNachaFileGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-file-get-options/get-nacha-file.file-get-options";
import { HistoryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/history.get-options";
import { OpenPrepayGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/open-prepay.get-options";
import { OpenReceivableGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/open-receivable.get-options";
import { OwnerContractGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/owner-contract.get-options";
import { PaymentGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/payment.get-options";
import { ReportBatchGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-report-get-options/report-batch.report-get-options";
import { BillSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/bill.save-options";
import { ChargeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/charge.save-options";
import { CheckSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check.save-options";
import { ContactSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/contact.save-options";
import { HistoryCallSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-call.save-options";
import { HistoryEmailSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-email.save-options";
import { HistoryVisitSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-visit.save-options";
import { InvoiceSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/invoice.save-options";
import { ManualOwnerPaySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/manual-owner-pay.save-options";
import { OwnerContributionSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/owner-contribution.save-options";
import { OwnershipTransferSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/ownership-transfer.save-options";
import { PayOwnerSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/pay-owner.save-options";
import { PaymentReversalSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/payment-reversal.save-options";
import { PaymentSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/payment.save-options";
import { RMVoIPCallHistorySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/rmvo-ipcall-history.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { WebUserSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/web-user.save-options";
import { BillPayMethod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/bill-pay-method.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { AddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address.model";
import { AppointmentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/appointment.model";
import { BatchEmailEntitiesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entities.model";
import { BatchEmailEntitySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entity-summary.model";
import { BillModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bill.model";
import { BillableExpenseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/billable-expense.model";
import { CalculatePayOwnerCheckModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/calculate-pay-owner-check.model";
import { ChargeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge.model";
import { CheckDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check-detail.model";
import { CheckModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check.model";
import { ColorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/color.model";
import { ContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact.model";
import { CreatePaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/create-payment.model";
import { DepositModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/deposit.model";
import { EpayInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-information.model";
import { FileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { HistoryCallModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-call.model";
import { HistoryEmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-email.model";
import { HistoryNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-note.model";
import { HistorySystemNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-system-note.model";
import { HistoryVisitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-visit.model";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { InvoiceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/invoice.model";
import { ManualOwnerPaySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/manual-owner-pay-summary.model";
import { ManualOwnerPayModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/manual-owner-pay.model";
import { NachaOwnerBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/nacha-owner-bank.model";
import { OpenPrepayModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/open-prepay.model";
import { OpenReceivableModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/open-receivable.model";
import { OWAFileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owafile.model";
import { OwnerCheckSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-check-summary.model";
import { OwnerContractModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contract.model";
import { OwnerContributionItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contribution-item.model";
import { OwnerContributionPaymentDetailSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contribution-payment-detail-summary.model";
import { OwnerContributionRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contribution-request.model";
import { OwnerContributionSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-contribution-summary.model";
import { OwnerDistributionSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-distribution-summary.model";
import { OwnerGroupDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group-detail.model";
import { OwnerGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group.model";
import { OwnerHistoryNotesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-history-notes.model";
import { OwnerWebAccessOwnerPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-web-access-owner-preference.model";
import { OwnerWebAccessReportPrivilegeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-web-access-report-privilege.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { OwnershipTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/ownership-transfer.model";
import { PayOwnerCheckSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pay-owner-check-summary.model";
import { PayOwnerRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pay-owner-request.model";
import { PayOwnerResultModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pay-owner-result.model";
import { PaymentReversalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/payment-reversal.model";
import { PaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/payment.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { ReportParameterValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value.model";
import { RMVoIPCallHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rmvo-ipcall-history.model";
import { RunReportBatchModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/run-report-batch.model";
import { ScriptBuilderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/script-builder.model";
import { TaskModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { WebOwnerReportPrivilegeInfoModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-owner-report-privilege-info.model";
import { WebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user-account.model";
import { WebUserAuthenticationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user-authentication.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class OwnersService {
   static readonly ActivePartnershipsResource = "ActivePartnerships";
   static readonly AddressesResource = "Addresses";
   static readonly AddressesListResource = "AddressesList";
   static readonly AddressTypesResource = "AddressTypes";
   static readonly AppointmentsResource = "Appointments";
   static readonly ApproveBillResource = "ApproveBill";
   static readonly BalanceResource = "Balance";
   static readonly BalanceAccountResource = "BalanceAccount";
   static readonly BatchEmailOwnersResource = "BatchEmailOwners";
   static readonly BatchEmailOwnersFromOwnershipsResource = "BatchEmailOwnersFromOwnerships";
   static readonly BatchEmailOwnersFromPropertiesResource = "BatchEmailOwnersFromProperties";
   static readonly BatchEmailOwnersFromProspectsResource = "BatchEmailOwnersFromProspects";
   static readonly BatchEmailOwnersFromTenantsResource = "BatchEmailOwnersFromTenants";
   static readonly BatchEmailOwnersFromUnitsResource = "BatchEmailOwnersFromUnits";
   static readonly BillableExpensesResource = "BillableExpenses";
   static readonly BillsResource = "Bills";
   static readonly CalculateChecksResource = "CalculateChecks";
   static readonly ChargesResource = "Charges";
   static readonly CheckOwnerContributionsAccountingCloseResource = "CheckOwnerContributionsAccountingClose";
   static readonly ChecksResource = "Checks";
   static readonly ColorResource = "Color";
   static readonly ContactResource = "Contact";
   static readonly ContactPhoneNumbersResource = "ContactPhoneNumbers";
   static readonly ContributionAccountResource = "ContributionAccount";
   static readonly CreateUserResource = "CreateUser";
   static readonly DownloadNachaFileResource = "DownloadNachaFile";
   static readonly DrawAccountResource = "DrawAccount";
   static readonly EpayInformationResource = "EpayInformation";
   static readonly EpayOwnerContributionsResource = "EpayOwnerContributions";
   static readonly FileForOWAResource = "FileForOWA";
   static readonly GenerateNACHAFileResource = "GenerateNACHAFile";
   static readonly GetByPostResource = "GetByPost";
   static readonly HistoryResource = "History";
   static readonly HistoryCallsResource = "HistoryCalls";
   static readonly HistoryEmailsResource = "HistoryEmails";
   static readonly HistoryNotesResource = "HistoryNotes";
   static readonly HistorySystemNotesResource = "HistorySystemNotes";
   static readonly HistoryVisitsResource = "HistoryVisits";
   static readonly HistoryWithUnitHistoryResource = "HistoryWithUnitHistory";
   static readonly InvoicesResource = "Invoices";
   static readonly MakePaymentsResource = "MakePayments";
   static readonly NachaOwnerBankResource = "NachaOwnerBank";
   static readonly OpenPrepaysResource = "OpenPrepays";
   static readonly OpenReceivablesResource = "OpenReceivables";
   static readonly OWAChecksResource = "OWAChecks";
   static readonly OWAFileResource = "OWAFile";
   static readonly OWAFilesResource = "OWAFiles";
   static readonly OwnerBankBalanceResource = "OwnerBankBalance";
   static readonly OwnerChecksResource = "OwnerChecks";
   static readonly OwnerContractsResource = "OwnerContracts";
   static readonly OwnerContributionsResource = "OwnerContributions";
   static readonly OwnerDistributionsResource = "OwnerDistributions";
   static readonly OwnerGroupDetailsResource = "OwnerGroupDetails";
   static readonly OwnerGroupsResource = "OwnerGroups";
   static readonly OwnerHistoryNotesResource = "OwnerHistoryNotes";
   static readonly OwnerPropertySecurityDepositsHeldResource = "OwnerPropertySecurityDepositsHeld";
   static readonly OwnershipsResource = "Ownerships";
   static readonly OwnerWebAccessOwnerPreferenceResource = "OwnerWebAccessOwnerPreference";
   static readonly OwnerWebAccessOwnerPreferencesResource = "OwnerWebAccessOwnerPreferences";
   static readonly OwnerWebAccessReportPrivilegesResource = "OwnerWebAccessReportPrivileges";
   static readonly PaymentMethodsResource = "PaymentMethods";
   static readonly PaymentReversalsResource = "PaymentReversals";
   static readonly PaymentsResource = "Payments";
   static readonly PayOwnersResource = "PayOwners";
   static readonly PayOwnersManuallyResource = "PayOwnersManually";
   static readonly PhoneNumbersResource = "PhoneNumbers";
   static readonly PhoneNumbersListResource = "PhoneNumbersList";
   static readonly PhoneNumberTypesResource = "PhoneNumberTypes";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RMVoIPCallHistoryResource = "RMVoIPCallHistory";
   static readonly RunReportBatchResource = "RunReportBatch";
   static readonly RunReportBatchSeparateResource = "RunReportBatchSeparate";
   static readonly SearchResource = "Search";
   static readonly StatusResource = "Status";
   static readonly TasksResource = "Tasks";
   static readonly TestScriptResource = "TestScript";
   static readonly TransferOwnershipResource = "TransferOwnership";
   static readonly UpdatePasswordResource = "UpdatePassword";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly ValidateWebUserCredentialsResource = "ValidateWebUserCredentials";
   static readonly WebOwnerReportPrivilegeInfoResource = "WebOwnerReportPrivilegeInfo";
   static readonly WebUserAccountsResource = "WebUserAccounts";
   static readonly WebUsersResource = "WebUsers";
   static readonly endpoint = "OwnersView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(OwnersService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   post(
      item?: OwnerModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(OwnersService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   postCollection(
      items: Array<OwnerModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | OwnerDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(OwnersService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | OwnerDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(OwnersService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getActivePartnershipsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/ActivePartnerships",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getActivePartnershipsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(
         this.getActivePartnershipsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAddressesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Addresses",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddresses(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(
         this.getAddressesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAddressesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Addresses",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAddressesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Addresses",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAllAddressesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Addresses",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddresses(
      id: number,
      item?: AddressModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.postSingle(this.postAddressesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postAddressesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Addresses",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressesCollection(
      id: number,
      items: Array<AddressModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.postCollection(
         this.postAddressesCollectionUrl(id, saveOptions, fields, additionalParameters),
         items
      );
   }

   getAddressesListCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/AddressesList",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressesListCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAddressesListCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAddressTypesUrl(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/AddressTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddressTypes(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.getSingle(this.getAddressTypesUrl(itemID, embeds, additionalParameters));
   }

   getAddressTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/AddressTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAddressTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/AddressTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAllAddressTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypes(
      item?: AddressTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.postSingle(this.postAddressTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   postAllAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAllAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   deleteAddressTypesUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(OwnersService.endpoint + "/AddressTypes", null, ids, null, additionalParameters);
   }

   deleteAddressTypes(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressTypesUrl(ids, additionalParameters));
   }

   getAppointmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Appointments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAppointments(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.getSingle(this.getAppointmentsUrl(id, itemID, embeds, additionalParameters));
   }

   getAppointmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Appointments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAppointmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.getCollection(
         this.getAppointmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAppointmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Appointments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAppointments(
      id: number,
      item?: AppointmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.postSingle(this.postAppointmentsUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postAppointmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Appointments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAppointmentsCollection(
      id: number,
      items: Array<AppointmentModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.postCollection(
         this.postAppointmentsCollectionUrl(id, saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteAppointmentsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(OwnersService.endpoint + "/Appointments", id, ids, null, additionalParameters);
   }

   deleteAppointments(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAppointmentsUrl(id, ids, additionalParameters));
   }

   postApproveBillUrl(id: number, billID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("billID=" + billID);
      return this.apiService.postUrl(
         OwnersService.endpoint + "/ApproveBill",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postApproveBill(id: number, billID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postApproveBillUrl(id, billID, additionalParameters));
   }

   getBalanceUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Balance",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBalance(id: number, additionalParameters?: Array<string>): Observable<number> {
      return this.apiService.getSingle(this.getBalanceUrl(id, additionalParameters));
   }

   getBalanceAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/BalanceAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBalanceAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getBalanceAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postBatchEmailOwnersUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwners",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwners(
      model?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postBatchEmailOwnersFromOwnershipsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwnersFromOwnerships",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwnersFromOwnerships(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersFromOwnershipsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailOwnersFromPropertiesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwnersFromProperties",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwnersFromProperties(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersFromPropertiesUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailOwnersFromProspectsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwnersFromProspects",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwnersFromProspects(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersFromProspectsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailOwnersFromTenantsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwnersFromTenants",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwnersFromTenants(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersFromTenantsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailOwnersFromUnitsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/BatchEmailOwnersFromUnits",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailOwnersFromUnits(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailOwnersFromUnitsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   getBillableExpensesCollectionUrl(
      id: number,
      embeds?: Array<string | BillableExpenseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/BillableExpenses",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBillableExpensesCollection(
      id: number,
      embeds?: Array<string | BillableExpenseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillableExpenseModel>> {
      return this.apiService.getCollection(
         this.getBillableExpensesCollectionUrl(id, embeds, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   getBillsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Bills",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBills(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BillModel> {
      return this.apiService.getSingle(this.getBillsUrl(id, itemID, embeds, additionalParameters));
   }

   getBillsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Bills",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBillsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getBillsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllBillsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Bills",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllBillsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getAllBillsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postBillsUrl(
      id: number,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Bills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBills(
      id: number,
      item?: BillModel | null,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BillModel> {
      return this.apiService.postSingle(this.postBillsUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postBillsCollectionUrl(
      id: number,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Bills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBillsCollection(
      id: number,
      bills?: Array<BillModel> | null,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.postCollection(
         this.postBillsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         bills
      );
   }

   deleteBillsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | BillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(OwnersService.endpoint + "/Bills", id, ids, deleteOptions, additionalParameters);
   }

   deleteBills(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | BillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteBillsUrl(id, ids, deleteOptions, additionalParameters));
   }

   postCalculateChecksUrl(
      embeds?: Array<string | PayOwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/CalculateChecks",
         null,
         null,
         embeds,
         null,
         additionalParameters
      );
   }

   postCalculateChecks(
      item?: CalculatePayOwnerCheckModel | null,
      embeds?: Array<string | PayOwnerEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PayOwnerCheckSummaryModel>> {
      return this.apiService
         .post(this.postCalculateChecksUrl(embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getChargesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Charges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCharges(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeModel> {
      return this.apiService.getSingle(
         this.getChargesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChargesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Charges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChargesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.getCollection(
         this.getChargesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllChargesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Charges",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllChargesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.getCollection(
         this.getAllChargesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChargesUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Charges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCharges(
      id: number,
      item?: ChargeModel | null,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeModel> {
      return this.apiService.postSingle(
         this.postChargesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postChargesCollectionUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Charges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChargesCollection(
      id: number,
      items: Array<ChargeModel>,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.postCollection(
         this.postChargesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteChargesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | ChargeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Charges",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCharges(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | ChargeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChargesUrl(id, ids, deleteOptions, additionalParameters));
   }

   postCheckOwnerContributionsAccountingCloseUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/CheckOwnerContributionsAccountingClose",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCheckOwnerContributionsAccountingClose(
      ownerContributionModels?: Array<OwnerContributionItemModel> | null,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService.postCollection(
         this.postCheckOwnerContributionsAccountingCloseUrl(additionalParameters),
         ownerContributionModels
      );
   }

   getChecksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Checks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChecks(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.getSingle(this.getChecksUrl(id, itemID, embeds, additionalParameters));
   }

   getChecksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Checks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChecksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.getCollection(
         this.getChecksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChecksUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Checks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChecks(
      id: number,
      item?: CheckModel | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.postSingle(
         this.postChecksUrl(id, saveOptions, embeds, fields, additionalParameters),
         item
      );
   }

   postChecksCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Checks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChecksCollection(
      id: number,
      checks?: Array<CheckModel> | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.postCollection(
         this.postChecksCollectionUrl(id, saveOptions, embeds, fields, additionalParameters),
         checks
      );
   }

   deleteChecksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Checks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteChecks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChecksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getColorUrl(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Color",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getColor(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ColorModel> {
      return this.apiService.getSingle(this.getColorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getContactUrl(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Contact",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContact(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService.getSingle(this.getContactUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postContactUrl(
      id: number,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Contact",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContact(
      id: number,
      item?: ContactModel | null,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService
         .post(this.postContactUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteContactUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Contact",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContact(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteContactUrl(id, itemID, deleteOptions, additionalParameters));
   }

   getContactPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/ContactPhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContactPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getContactPhoneNumbersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getContactPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/ContactPhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getContactPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getContactPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postContactPhoneNumbersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/ContactPhoneNumbers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactPhoneNumbers(
      id: number,
      item?: PhoneNumberModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.postSingle(
         this.postContactPhoneNumbersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postContactPhoneNumbersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/ContactPhoneNumbers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactPhoneNumbersCollection(
      id: number,
      items: Array<PhoneNumberModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.postCollection(
         this.postContactPhoneNumbersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteContactPhoneNumbersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/ContactPhoneNumbers",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContactPhoneNumbers(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteContactPhoneNumbersUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getContributionAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/ContributionAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContributionAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(
         this.getContributionAccountUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getDownloadNachaFileUrl(fileKey?: string | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (fileKey !== undefined && fileKey !== null) {
         additionalParameters.push("fileKey=" + fileKey);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/DownloadNachaFile",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDownloadNachaFile(fileKey?: string | null, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getDownloadNachaFileUrl(fileKey, additionalParameters));
   }

   getDrawAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/DrawAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDrawAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getDrawAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getEpayInformationUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/EpayInformation",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpayInformation(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.getSingle(
         this.getEpayInformationUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getEpayInformationCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/EpayInformation",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEpayInformationCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getEpayInformationCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllEpayInformationCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/EpayInformation",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllEpayInformationCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getAllEpayInformationCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postEpayInformationUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postEpayInformation(
      id: number,
      item?: EpayInformationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.postSingle(
         this.postEpayInformationUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postEpayInformationCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postEpayInformationCollection(
      id: number,
      items: Array<EpayInformationModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.postCollection(
         this.postEpayInformationCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteEpayInformationUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/EpayInformation",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteEpayInformation(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteEpayInformationUrl(id, ids, deleteOptions, additionalParameters));
   }

   postEpayOwnerContributionsUrl(
      id: number,
      saveOptions?: Array<string | OwnerContributionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/EpayOwnerContributions",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postEpayOwnerContributions(
      id: number,
      contributionModel?: OwnerContributionRequestModel | null,
      saveOptions?: Array<string | OwnerContributionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DepositModel>> {
      return this.apiService
         .post(this.postEpayOwnerContributionsUrl(id, saveOptions, additionalParameters), contributionModel)
         .pipe(map((response) => response.body));
   }

   getFileForOWAUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/FileForOWA",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getFileForOWA(id: number, additionalParameters?: Array<string>): Observable<FileModel> {
      return this.apiService.getSingle(this.getFileForOWAUrl(id, additionalParameters));
   }

   getGenerateNACHAFileUrl(
      id: number,
      itemID: number,
      getOptions?: Array<string | GetNachaFileGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/GenerateNACHAFile",
         id,
         itemID,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGenerateNACHAFile(
      id: number,
      itemID: number,
      getOptions?: Array<string | GetNachaFileGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getGenerateNACHAFileUrl(id, itemID, getOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(OwnersService.endpoint + "/GetByPost", id, null, null, null, additionalParameters);
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/History",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/History",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getHistoryCallsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryCalls",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryCalls(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryCallModel> {
      return this.apiService.getSingle(
         this.getHistoryCallsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCallsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryCalls",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCallsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.getCollection(
         this.getHistoryCallsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryCallsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryCalls",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryCallsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryCallsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryCallsUrl(
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryCalls",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryCalls(
      item?: HistoryCallModel | null,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryCallModel> {
      return this.apiService.postSingle(this.postHistoryCallsUrl(saveOptions, fields, additionalParameters), item);
   }

   postHistoryCallsCollectionUrl(
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryCalls",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryCallsCollection(
      items: Array<HistoryCallModel>,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.postCollection(
         this.postHistoryCallsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   postAllHistoryCallsCollectionUrl(
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryCalls",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllHistoryCallsCollection(
      items: Array<HistoryCallModel>,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryCallsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteHistoryCallsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/HistoryCalls",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryCalls(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryCallsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryEmailsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryEmails",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryEmails(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryEmailModel> {
      return this.apiService.getSingle(
         this.getHistoryEmailsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryEmailsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryEmails",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryEmailsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.getCollection(
         this.getHistoryEmailsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryEmailsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryEmails",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryEmailsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryEmailsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryEmailsUrl(
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryEmails",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryEmails(
      item?: HistoryEmailModel | null,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryEmailModel> {
      return this.apiService.postSingle(this.postHistoryEmailsUrl(saveOptions, fields, additionalParameters), item);
   }

   postHistoryEmailsCollectionUrl(
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryEmails",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryEmailsCollection(
      items: Array<HistoryEmailModel>,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.postCollection(
         this.postHistoryEmailsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   postAllHistoryEmailsCollectionUrl(
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryEmails",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllHistoryEmailsCollection(
      items: Array<HistoryEmailModel>,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryEmailsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteHistoryEmailsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/HistoryEmails",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryEmails(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryEmailsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.getSingle(
         this.getHistoryNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryNotesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotes(
      item?: HistoryNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryNotesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/HistoryNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryNotesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistorySystemNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.getSingle(
         this.getHistorySystemNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistorySystemNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getHistorySystemNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistorySystemNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistorySystemNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistorySystemNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistorySystemNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotes(
      id: number,
      item?: HistorySystemNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.postSingle(
         this.postHistorySystemNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistorySystemNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotesCollection(
      id: number,
      items: Array<HistorySystemNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.postCollection(
         this.postHistorySystemNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistorySystemNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/HistorySystemNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistorySystemNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteHistorySystemNotesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getHistoryVisitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryVisits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryVisits(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryVisitModel> {
      return this.apiService.getSingle(
         this.getHistoryVisitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryVisitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryVisits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryVisitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.getCollection(
         this.getHistoryVisitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryVisitsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryVisits",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryVisitsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryVisitsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryVisitsUrl(
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryVisits",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryVisits(
      item?: HistoryVisitModel | null,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryVisitModel> {
      return this.apiService.postSingle(this.postHistoryVisitsUrl(saveOptions, fields, additionalParameters), item);
   }

   postHistoryVisitsCollectionUrl(
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryVisits",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryVisitsCollection(
      items: Array<HistoryVisitModel>,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.postCollection(
         this.postHistoryVisitsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   postAllHistoryVisitsCollectionUrl(
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/HistoryVisits",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllHistoryVisitsCollection(
      items: Array<HistoryVisitModel>,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryVisitsCollectionUrl(saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteHistoryVisitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/HistoryVisits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryVisits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryVisitsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryWithUnitHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryWithUnitHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryWithUnitHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryWithUnitHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryWithUnitHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/HistoryWithUnitHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryWithUnitHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryWithUnitHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getInvoicesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Invoices",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getInvoices(
      id: number,
      itemID: number,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<InvoiceModel> {
      return this.apiService.getSingle(this.getInvoicesUrl(id, itemID, embeds, additionalParameters));
   }

   getInvoicesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Invoices",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getInvoicesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InvoiceModel>> {
      return this.apiService.getCollection(
         this.getInvoicesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postInvoicesUrl(
      id: number,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Invoices",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postInvoices(
      id: number,
      item?: InvoiceModel | null,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<InvoiceModel> {
      return this.apiService.postSingle(this.postInvoicesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postInvoicesCollectionUrl(
      id: number,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Invoices",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postInvoicesCollection(
      id: number,
      invoices?: Array<InvoiceModel> | null,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InvoiceModel>> {
      return this.apiService.postCollection(
         this.postInvoicesCollectionUrl(id, saveOptions, fields, additionalParameters),
         invoices
      );
   }

   deleteInvoicesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InvoiceDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Invoices",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteInvoices(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InvoiceDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteInvoicesUrl(id, ids, deleteOptions, additionalParameters));
   }

   postMakePaymentsUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/MakePayments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postMakePayments(
      id: number,
      item?: CreatePaymentModel | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CreatePaymentModel> {
      return this.apiService.postSingle(this.postMakePaymentsUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postMakePaymentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/MakePayments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postMakePaymentsCollection(
      id: number,
      payments?: Array<CreatePaymentModel> | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreatePaymentModel>> {
      return this.apiService.postCollection(
         this.postMakePaymentsCollectionUrl(id, saveOptions, fields, additionalParameters),
         payments
      );
   }

   getNachaOwnerBankUrl(
      id: number,
      embeds?: Array<string | NachaOwnerBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/NachaOwnerBank",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getNachaOwnerBank(
      id: number,
      embeds?: Array<string | NachaOwnerBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<NachaOwnerBankModel> {
      return this.apiService.getSingle(this.getNachaOwnerBankUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postNachaOwnerBankUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | NachaOwnerBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/NachaOwnerBank",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postNachaOwnerBank(
      id: number,
      item?: NachaOwnerBankModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | NachaOwnerBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<NachaOwnerBankModel> {
      return this.apiService
         .post(this.postNachaOwnerBankUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteNachaOwnerBankUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/NachaOwnerBank",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteNachaOwnerBank(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteNachaOwnerBankUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getOpenPrepaysCollectionUrl(
      id: number,
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OpenPrepays",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOpenPrepaysCollection(
      id: number,
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenPrepayModel>> {
      return this.apiService.getCollection(
         this.getOpenPrepaysCollectionUrl(id, embeds, getOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   getAllOpenPrepaysCollectionUrl(
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OpenPrepays",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOpenPrepaysCollection(
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenPrepayModel>> {
      return this.apiService.getCollection(
         this.getAllOpenPrepaysCollectionUrl(embeds, getOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   getOpenReceivablesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OpenReceivables",
         id,
         null,
         filters,
         null,
         null,
         null,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOpenReceivablesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenReceivableModel>> {
      return this.apiService.getCollection(
         this.getOpenReceivablesCollectionUrl(
            id,
            filters,
            getOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOpenReceivablesCollectionUrl(
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OpenReceivables",
         null,
         null,
         filters,
         null,
         null,
         null,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOpenReceivablesCollection(
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenReceivableModel>> {
      return this.apiService.getCollection(
         this.getAllOpenReceivablesCollectionUrl(
            filters,
            getOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOWAChecksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OWAChecks",
         id,
         null,
         filters,
         null,
         null,
         null,
         null,
         null,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOWAChecksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckDetailModel>> {
      return this.apiService.getCollection(
         this.getOWAChecksCollectionUrl(id, filters, pageSize, pageNumber, additionalParameters)
      );
   }

   postOWAFileUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(OwnersService.endpoint + "/OWAFile", id, null, null, null, additionalParameters);
   }

   postOWAFile(id: number, item?: OWAFileModel | null, additionalParameters?: Array<string>): Observable<OWAFileModel> {
      return this.apiService
         .post(this.postOWAFileUrl(id, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getOWAFilesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OWAFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OWAFiles",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOWAFiles(
      id: number,
      itemID: number,
      embeds?: Array<string | OWAFileEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OWAFileModel> {
      return this.apiService.getSingle(this.getOWAFilesUrl(id, itemID, embeds, additionalParameters));
   }

   getOWAFilesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OWAFileEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OWAFiles",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOWAFilesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OWAFileEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OWAFileModel>> {
      return this.apiService.getCollection(
         this.getOWAFilesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postOWAFilesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OWAFiles",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOWAFiles(
      id: number,
      item?: OWAFileModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<OWAFileModel> {
      return this.apiService.postSingle(this.postOWAFilesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postOWAFilesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OWAFiles",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOWAFilesCollection(
      id: number,
      owaFileItems?: Array<OWAFileModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OWAFileModel>> {
      return this.apiService.postCollection(
         this.postOWAFilesCollectionUrl(id, saveOptions, fields, additionalParameters),
         owaFileItems
      );
   }

   deleteOWAFilesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(OwnersService.endpoint + "/OWAFiles", id, ids, null, additionalParameters);
   }

   deleteOWAFiles(id: number, ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteOWAFilesUrl(id, ids, additionalParameters));
   }

   getOwnerBankBalanceUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerBankBalance",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerBankBalance(id: number, additionalParameters?: Array<string>): Observable<number> {
      return this.apiService.getSingle(this.getOwnerBankBalanceUrl(id, additionalParameters));
   }

   getOwnerChecksCollectionUrl(
      id: number,
      checkIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (checkIDs !== undefined && checkIDs !== null && checkIDs.length > 0) {
         additionalParameters.push("checkIDs=" + checkIDs);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerChecks",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerChecksCollection(
      id: number,
      checkIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerCheckSummaryModel>> {
      return this.apiService.getCollection(this.getOwnerChecksCollectionUrl(id, checkIDs, additionalParameters));
   }

   getOwnerContractsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerContracts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerContracts(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.getSingle(this.getOwnerContractsUrl(id, itemID, embeds, additionalParameters));
   }

   getOwnerContractsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerContracts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerContractsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getOwnerContractsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postOwnerContractsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerContracts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOwnerContracts(
      id: number,
      item?: OwnerContractModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.postSingle(
         this.postOwnerContractsUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postOwnerContractsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerContracts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOwnerContractsCollection(
      id: number,
      ownerships?: Array<OwnerContractModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.postCollection(
         this.postOwnerContractsCollectionUrl(id, saveOptions, fields, additionalParameters),
         ownerships
      );
   }

   getOwnerContributionsUrl(id: number, endDate?: Date | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (endDate !== undefined && endDate !== null) {
         additionalParameters.push("endDate=" + endDate);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerContributions",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerContributions(
      id: number,
      endDate?: Date | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContributionSummaryModel> {
      return this.apiService.getSingle(this.getOwnerContributionsUrl(id, endDate, additionalParameters));
   }

   postOwnerContributionsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerContributions",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postOwnerContributions(
      model?: OwnerContributionPaymentDetailSummaryModel | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContributionPaymentDetailSummaryModel> {
      return this.apiService
         .post(this.postOwnerContributionsUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getOwnerDistributionsCollectionUrl(
      id: number,
      restrictByContracts?: boolean | null,
      propertyIDs?: Array<number> | null,
      fromDate?: Date | null,
      toDate?: Date | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (restrictByContracts !== undefined && restrictByContracts !== null) {
         additionalParameters.push("restrictByContracts=" + restrictByContracts);
      }
      if (propertyIDs !== undefined && propertyIDs !== null && propertyIDs.length > 0) {
         additionalParameters.push("propertyIDs=" + propertyIDs);
      }
      if (fromDate !== undefined && fromDate !== null) {
         additionalParameters.push("fromDate=" + fromDate);
      }
      if (toDate !== undefined && toDate !== null) {
         additionalParameters.push("toDate=" + toDate);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerDistributions",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerDistributionsCollection(
      id: number,
      restrictByContracts?: boolean | null,
      propertyIDs?: Array<number> | null,
      fromDate?: Date | null,
      toDate?: Date | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerDistributionSummaryModel>> {
      return this.apiService.getCollection(
         this.getOwnerDistributionsCollectionUrl(
            id,
            restrictByContracts,
            propertyIDs,
            fromDate,
            toDate,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerGroupDetailsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerGroupDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerGroupDetails",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerGroupDetails(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerGroupDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerGroupDetailModel> {
      return this.apiService.getSingle(
         this.getOwnerGroupDetailsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnerGroupDetailsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerGroupDetails",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerGroupDetailsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerGroupDetailModel>> {
      return this.apiService.getCollection(
         this.getOwnerGroupDetailsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerGroupsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerGroups",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerGroups(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerGroupModel> {
      return this.apiService.getSingle(
         this.getOwnerGroupsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnerGroupsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerGroups",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerGroupsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerGroupModel>> {
      return this.apiService.getCollection(
         this.getOwnerGroupsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOwnerGroupsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerGroups",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOwnerGroupsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerGroupModel>> {
      return this.apiService.getCollection(
         this.getAllOwnerGroupsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerHistoryNotesCollectionUrl(
      id: number,
      fromDate?: Date | null,
      toDate?: Date | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (fromDate !== undefined && fromDate !== null) {
         additionalParameters.push("fromDate=" + fromDate);
      }
      if (toDate !== undefined && toDate !== null) {
         additionalParameters.push("toDate=" + toDate);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerHistoryNotes",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerHistoryNotesCollection(
      id: number,
      fromDate?: Date | null,
      toDate?: Date | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerHistoryNotesModel>> {
      return this.apiService.getCollection(
         this.getOwnerHistoryNotesCollectionUrl(
            id,
            fromDate,
            toDate,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerPropertySecurityDepositsHeldCollectionUrl(
      id: number,
      asOfDate?: Date | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (asOfDate !== undefined && asOfDate !== null) {
         additionalParameters.push("asOfDate=" + asOfDate);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerPropertySecurityDepositsHeld",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerPropertySecurityDepositsHeldCollection(
      id: number,
      asOfDate?: Date | null,
      additionalParameters?: Array<string>
   ): Observable<Array<number>> {
      return this.apiService.getCollection(
         this.getOwnerPropertySecurityDepositsHeldCollectionUrl(id, asOfDate, additionalParameters)
      );
   }

   getOwnershipsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Ownerships",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerships(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.getSingle(
         this.getOwnershipsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnershipsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Ownerships",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnershipsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getOwnershipsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOwnershipsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Ownerships",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOwnershipsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OwnerContractGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.getCollection(
         this.getAllOwnershipsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postOwnershipsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Ownerships",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnerships(
      id: number,
      item?: OwnerContractModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerContractModel> {
      return this.apiService.postSingle(
         this.postOwnershipsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postOwnershipsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Ownerships",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postOwnershipsCollection(
      id: number,
      items: Array<OwnerContractModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | OwnerContractEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerContractModel>> {
      return this.apiService.postCollection(
         this.postOwnershipsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteOwnershipsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Ownerships",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteOwnerships(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteOwnershipsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getOwnerWebAccessOwnerPreferenceUrl(
      id: number,
      embeds?: Array<string | OwnerWebAccessOwnerPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerWebAccessOwnerPreference",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerWebAccessOwnerPreference(
      id: number,
      embeds?: Array<string | OwnerWebAccessOwnerPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerWebAccessOwnerPreferenceModel> {
      return this.apiService.getSingle(
         this.getOwnerWebAccessOwnerPreferenceUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnerWebAccessOwnerPreferencesUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerWebAccessOwnerPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerWebAccessOwnerPreferences",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerWebAccessOwnerPreferences(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerWebAccessOwnerPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerWebAccessOwnerPreferenceModel> {
      return this.apiService.getSingle(
         this.getOwnerWebAccessOwnerPreferencesUrl(id, filters, embeds, additionalParameters)
      );
   }

   postOwnerWebAccessOwnerPreferencesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerWebAccessOwnerPreferences",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOwnerWebAccessOwnerPreferences(
      id: number,
      item?: OwnerWebAccessOwnerPreferenceModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerWebAccessOwnerPreferenceModel> {
      return this.apiService
         .post(this.postOwnerWebAccessOwnerPreferencesUrl(id, saveOptions, fields, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getOwnerWebAccessReportPrivilegesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerWebAccessReportPrivilegeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerWebAccessReportPrivileges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerWebAccessReportPrivileges(
      id: number,
      itemID: number,
      embeds?: Array<string | OwnerWebAccessReportPrivilegeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerWebAccessReportPrivilegeModel> {
      return this.apiService.getSingle(
         this.getOwnerWebAccessReportPrivilegesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOwnerWebAccessReportPrivilegesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerWebAccessReportPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/OwnerWebAccessReportPrivileges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOwnerWebAccessReportPrivilegesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | OwnerWebAccessReportPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerWebAccessReportPrivilegeModel>> {
      return this.apiService.getCollection(
         this.getOwnerWebAccessReportPrivilegesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postOwnerWebAccessReportPrivilegesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerWebAccessReportPrivileges",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOwnerWebAccessReportPrivileges(
      id: number,
      item?: OwnerWebAccessReportPrivilegeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerWebAccessReportPrivilegeModel> {
      return this.apiService.postSingle(
         this.postOwnerWebAccessReportPrivilegesUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postOwnerWebAccessReportPrivilegesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/OwnerWebAccessReportPrivileges",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postOwnerWebAccessReportPrivilegesCollection(
      id: number,
      items: Array<OwnerWebAccessReportPrivilegeModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerWebAccessReportPrivilegeModel>> {
      return this.apiService.postCollection(
         this.postOwnerWebAccessReportPrivilegesCollectionUrl(id, saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteOwnerWebAccessReportPrivilegesUrl(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/OwnerWebAccessReportPrivileges",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteOwnerWebAccessReportPrivileges(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteOwnerWebAccessReportPrivilegesUrl(id, ids, additionalParameters));
   }

   getPaymentMethodsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PaymentMethods",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentMethodsCollection(additionalParameters?: Array<string>): Observable<Array<BillPayMethod>> {
      return this.apiService.getCollection(this.getPaymentMethodsCollectionUrl(additionalParameters));
   }

   getPaymentReversalsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PaymentReversals",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentReversals(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentReversalModel> {
      return this.apiService.getSingle(this.getPaymentReversalsUrl(id, itemID, embeds, additionalParameters));
   }

   getPaymentReversalsCollectionUrl(
      id: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PaymentReversals",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentReversalsCollection(
      id: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.getCollection(this.getPaymentReversalsCollectionUrl(id, embeds, additionalParameters));
   }

   getAllPaymentReversalsCollectionUrl(
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PaymentReversals",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllPaymentReversalsCollection(
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.getCollection(this.getAllPaymentReversalsCollectionUrl(embeds, additionalParameters));
   }

   postPaymentReversalsUrl(
      id: number,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PaymentReversals",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPaymentReversals(
      id: number,
      item?: PaymentReversalModel | null,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentReversalModel> {
      return this.apiService.postSingle(this.postPaymentReversalsUrl(id, saveOptions, additionalParameters), item);
   }

   postPaymentReversalsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PaymentReversals",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPaymentReversalsCollection(
      id: number,
      models?: Array<PaymentReversalModel> | null,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.postCollection(
         this.postPaymentReversalsCollectionUrl(id, saveOptions, additionalParameters),
         models
      );
   }

   deletePaymentReversalsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/PaymentReversals",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePaymentReversals(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePaymentReversalsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPaymentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Payments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPayments(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentModel> {
      return this.apiService.getSingle(
         this.getPaymentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPaymentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Payments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPaymentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentModel>> {
      return this.apiService.getCollection(
         this.getPaymentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPaymentsUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Payments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPayments(
      id: number,
      item?: PaymentModel | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentModel> {
      return this.apiService.postSingle(this.postPaymentsUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postPaymentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Payments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPaymentsCollection(
      id: number,
      payments?: Array<PaymentModel> | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentModel>> {
      return this.apiService.postCollection(
         this.postPaymentsCollectionUrl(id, saveOptions, fields, additionalParameters),
         payments
      );
   }

   deletePaymentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/Payments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePayments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePaymentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   postPayOwnersUrl(
      saveOptions?: Array<string | PayOwnerSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PayOwners",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPayOwners(
      item?: PayOwnerRequestModel | null,
      saveOptions?: Array<string | PayOwnerSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PayOwnerResultModel> {
      return this.apiService
         .post(this.postPayOwnersUrl(saveOptions, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   postPayOwnersManuallyUrl(
      saveOptions?: Array<string | ManualOwnerPaySaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PayOwnersManually",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPayOwnersManually(
      item?: ManualOwnerPayModel | null,
      saveOptions?: Array<string | ManualOwnerPaySaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ManualOwnerPaySummaryModel> {
      return this.apiService
         .post(this.postPayOwnersManuallyUrl(saveOptions, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(this.getPhoneNumbersUrl(id, itemID, embeds, additionalParameters));
   }

   getPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPhoneNumbersListCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumbersList",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbersListCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumbersListCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPhoneNumberTypesUrl(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypes(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.getSingle(this.getPhoneNumberTypesUrl(itemID, embeds, additionalParameters));
   }

   getPhoneNumberTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumberTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPhoneNumberTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPhoneNumberTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getAllPhoneNumberTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumberTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypes(
      item?: PhoneNumberTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.postSingle(this.postPhoneNumberTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   postAllPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postAllPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   deletePhoneNumberTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/PhoneNumberTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumberTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumberTypesUrl(ids, deleteOptions, additionalParameters));
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRMVoIPCallHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/RMVoIPCallHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.getSingle(
         this.getRMVoIPCallHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRMVoIPCallHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/RMVoIPCallHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getRMVoIPCallHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllRMVoIPCallHistoryCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/RMVoIPCallHistory",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllRMVoIPCallHistoryCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getAllRMVoIPCallHistoryCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRMVoIPCallHistoryUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistory(
      id: number,
      item?: RMVoIPCallHistoryModel | null,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.postSingle(
         this.postRMVoIPCallHistoryUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRMVoIPCallHistoryCollectionUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistoryCollection(
      id: number,
      items: Array<RMVoIPCallHistoryModel>,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.postCollection(
         this.postRMVoIPCallHistoryCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   getRunReportBatchUrl(
      itemID: number,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (parameters !== undefined && parameters !== null && parameters.length > 0) {
         additionalParameters.push("parameters=" + parameters);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/RunReportBatch",
         null,
         itemID,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRunReportBatch(
      itemID: number,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getRunReportBatchUrl(itemID, parameters, getOptions, additionalParameters));
   }

   postRunReportBatchUrl(
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         OwnersService.endpoint + "/RunReportBatch",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReportBatch(
      runReportBatchModel?: RunReportBatchModel | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postRunReportBatchUrl(getOptions, additionalParameters), runReportBatchModel);
   }

   getRunReportBatchSeparateUrl(
      itemID: number,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (parameters !== undefined && parameters !== null && parameters.length > 0) {
         additionalParameters.push("parameters=" + parameters);
      }
      return this.apiService.getUrl(
         OwnersService.endpoint + "/RunReportBatchSeparate",
         null,
         itemID,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRunReportBatchSeparate(
      itemID: number,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(
         this.getRunReportBatchSeparateUrl(itemID, parameters, getOptions, additionalParameters)
      );
   }

   postRunReportBatchSeparateUrl(
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         OwnersService.endpoint + "/RunReportBatchSeparate",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReportBatchSeparate(
      runReportBatchModel?: RunReportBatchModel | null,
      getOptions?: Array<string | ReportBatchGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(
         this.postRunReportBatchSeparateUrl(getOptions, additionalParameters),
         runReportBatchModel
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnerModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | OwnerEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(OwnersService.endpoint + "/Search", null, null, null, null, additionalParameters);
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getStatusUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Status",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getStatus(id: number, additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getStatusUrl(id, additionalParameters));
   }

   getTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Tasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.getSingle(this.getTasksUrl(id, itemID, embeds, additionalParameters));
   }

   getTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/Tasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.getCollection(
         this.getTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTasksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Tasks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postTasks(
      id: number,
      item?: TaskModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.postSingle(this.postTasksUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postTasksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/Tasks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postTasksCollection(
      id: number,
      items: Array<TaskModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.postCollection(
         this.postTasksCollectionUrl(id, saveOptions, fields, additionalParameters),
         items
      );
   }

   deleteTasksUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(OwnersService.endpoint + "/Tasks", id, ids, null, additionalParameters);
   }

   deleteTasks(id: number, ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTasksUrl(id, ids, additionalParameters));
   }

   postTestScriptUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/TestScript",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestScript(
      id: number,
      model?: ScriptBuilderModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postTestScriptUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postTransferOwnershipUrl(
      id: number,
      saveOptions?: Array<string | OwnershipTransferSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/TransferOwnership",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postTransferOwnership(
      id: number,
      model?: OwnershipTransferModel | null,
      saveOptions?: Array<string | OwnershipTransferSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OwnershipTransferModel> {
      return this.apiService
         .post(this.postTransferOwnershipUrl(id, saveOptions, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postUpdatePasswordUrl(
      id: number,
      p?: string | null,
      isRegistration?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (p !== undefined && p !== null) {
         additionalParameters.push("p=" + p);
      }
      if (isRegistration !== undefined && isRegistration !== null) {
         additionalParameters.push("isRegistration=" + isRegistration);
      }
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UpdatePassword",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postUpdatePassword(
      id: number,
      p?: string | null,
      isRegistration?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postUpdatePasswordUrl(id, p, isRegistration, additionalParameters));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUserDefinedFieldsUrl(saveOptions, fields, additionalParameters), item);
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   postValidateWebUserCredentialsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/ValidateWebUserCredentials",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postValidateWebUserCredentials(
      webUserAuthentication?: WebUserAuthenticationModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postValidateWebUserCredentialsUrl(additionalParameters), webUserAuthentication);
   }

   getWebOwnerReportPrivilegeInfoCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/WebOwnerReportPrivilegeInfo",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getWebOwnerReportPrivilegeInfoCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<WebOwnerReportPrivilegeInfoModel>> {
      return this.apiService.getCollection(this.getWebOwnerReportPrivilegeInfoCollectionUrl(additionalParameters));
   }

   getWebUserAccountsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/WebUserAccounts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getWebUserAccounts(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserAccountModel> {
      return this.apiService.getSingle(
         this.getWebUserAccountsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getWebUserAccountsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/WebUserAccounts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getWebUserAccountsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserAccountModel>> {
      return this.apiService.getCollection(
         this.getWebUserAccountsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postWebUserAccountsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/WebUserAccounts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postWebUserAccounts(
      id: number,
      item?: WebUserAccountModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserAccountModel> {
      return this.apiService.postSingle(
         this.postWebUserAccountsUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postWebUserAccountsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/WebUserAccounts",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postWebUserAccountsCollection(
      id: number,
      webUserAccounts?: Array<WebUserAccountModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserAccountModel>> {
      return this.apiService.postCollection(
         this.postWebUserAccountsCollectionUrl(id, saveOptions, fields, additionalParameters),
         webUserAccounts
      );
   }

   deleteWebUserAccountsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         OwnersService.endpoint + "/WebUserAccounts",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteWebUserAccounts(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteWebUserAccountsUrl(id, ids, additionalParameters));
   }

   getWebUsersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         OwnersService.endpoint + "/WebUsers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getWebUsersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserModel>> {
      return this.apiService.getCollection(
         this.getWebUsersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postWebUsersUrl(
      id: number,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/WebUsers",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postWebUsers(
      id: number,
      item?: WebUserModel | null,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserModel> {
      return this.apiService.postSingle(this.postWebUsersUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postWebUsersCollectionUrl(
      id: number,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         OwnersService.endpoint + "/WebUsers",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postWebUsersCollection(
      id: number,
      webUsers?: Array<WebUserModel> | null,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserModel>> {
      return this.apiService.postCollection(
         this.postWebUsersCollectionUrl(id, saveOptions, fields, additionalParameters),
         webUsers
      );
   }
}
