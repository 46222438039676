import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "lcs-click-to-call-view",
   templateUrl: "./click-to-call-view.component.html",
})
export class ClickToCallViewComponent {
   @Input() isDisplayClickToCall: boolean;

   @Input() toolTip: string;

   @Input() extraCSSClass: string;

   @Input() isDisplayIconWithoutPrivs: boolean;

   @Input() primaryColor: boolean;

   @Output() buttonClicked = new EventEmitter<void>();

   @Output() dialogClosed = new EventEmitter<void>();

   constructor() {}

   onClick() {
      this.buttonClicked.next();
   }
}
