////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ReportParameter {
   NotSet = 0,
   AsOfDate = 1,
   AsOfDate2 = 2,
   StartDate = 3,
   StartDate2 = 4,
   EndDate = 5,
   EndDate2 = 6,
   PropertyIDs = 7,
   PIDSALL = 8,
   PropOwnerIDs = 9,
   PROPOWNERIDSALL = 10,
   RUNBYOWNER = 11,
   LMONTH = 12,
   SMONTH = 13,
   LeasingAgentIDs = 14,
   ALTADDRESS = 15,
   AUDITTYPE = 16,
   BANKID = 17,
   BANKS = 18,
   BATCHBANKS = 19,
   BATCHCUSTS = 20,
   BATCHOWNERSEXCLUDEEMPTY = 21,
   BATCHPROPSEXCLUDEEMPTY = 22,
   BatchProps = 23,
   BATCHUNITS = 24,
   BATCHOWNERS = 25,
   BILLSTOINCLUDE = 26,
   BLANKCHECKBOX = 27,
   BUDGETTYPE = 28,
   PROSDATETYPE = 29,
   SHOWALL = 30,
   SHOWPROP = 31,
   GROUP = 32,
   CAMCHARGETYPE = 33,
   Basis = 34,
   CHARGESTOINCLUDE = 35,
   CHARGESTOINCLUDE2 = 36,
   CHARGEORDER = 37,
   CHARTMAPID = 38,
   CLEAREDOPTIONS = 39,
   CLOSEDONLY = 40,
   COLLAPSEPARENTS = 41,
   COMBINEOWNERSHIP = 42,
   CombineReceivableTypes = 43,
   CombineReceivableType = 44,
   COMMENT = 45,
   CONSOLIDATE1096 = 46,
   CONSOLIDATE1099 = 47,
   CUSTOMERVALUESTOINCLUDETEXT = 48,
   CUSTGTEQ = 49,
   CREDSEP = 50,
   CUSTOMER = 51,
   DATASOURCE = 52,
   DATESECTION = 53,
   DATERESTRICTIONON = 54,
   DEPOSITS = 55,
   DETAIL = 56,
   IsSummary = 57,
   EPAYBATCHES = 58,
   EXCLUDE1099 = 59,
   EXCLUDE1099OPTIONS = 60,
   VENDORSINCLUDE = 61,
   EXCLUDEPAY = 62,
   EXCLUDEZEROACTIVITY = 63,
   EXCLUDEZEROBALANCE = 64,
   EXCLUDEZEROS = 65,
   EXCLUDENOREADS = 66,
   EXPONLY = 67,
   DayOfPeriod = 68,
   FONTNAME = 69,
   FONTSIZE = 70,
   FONTBOLD = 71,
   FONTITAL = 72,
   GTEQ = 73,
   INCLUDEINACTIVE = 74,
   INCLUDEINACTIVEGEN = 75,
   INCLUDECUST = 76,
   LABELTYPES = 77,
   LABELTYPE = 78,
   HIDEACC = 79,
   HIDEMAILINGLABEL = 80,
   HIDEOWNERNUMBERS = 81,
   HIDERESERVE = 82,
   HIDESDHELD = 83,
   HIDEUNIT = 84,
   HIDEUNITTYPE = 85,
   HISTCATEGORYIDS = 86,
   HISTNOTESFILTER = 87,
   HISTTYPEIDS = 88,
   LEGALPAPER = 89,
   NUMRECORDS = 90,
   NUMBERNOTES = 91,
   OPENONLY = 92,
   PAGEBREAK = 93,
   PERCENTINCOME = 94,
   PostCardOverflowOption = 95,
   PROPORUNIT = 96,
   REGARDING = 97,
   RM7CMP = 98,
   SPROS = 99,
   SCUST = 100,
   SLOST = 101,
   SLREJ = 102,
   SEARCHDESC = 103,
   SECDEPTYPES = 104,
   SEPRENT = 105,
   SHOWISSUECOST = 106,
   SHOWISSUEPRICE = 107,
   SHOWDILOANS = 108,
   SHOWPERDAYLATE = 109,
   SHOWUTILSTMT = 110,
   SHOWUTILREADS = 111,
   SHOWVENDOR = 112,
   SORTOPTIONS = 113,
   SORTORDERASC = 114,
   CustomerStatus = 115,
   STATUSCP = 116,
   STATUSCPA = 117,
   STATUSCPFA = 118,
   SUMRRMETHOD = 119,
   VENDORSACCNO = 120,
   VendorIDs = 121,
   VENDSHOWCHECKS = 122,
   VENDSHOWBILLS = 123,
   VENDSHOWCREDITS = 124,
   VENDSHOWCARDS = 125,
   YEAR = 126,
   YEAR1099 = 127,
   OWNERID = 128,
   USERDEF = 129,
   AUTOCH = 130,
   TRANH = 131,
   HIST = 132,
   ISSUE = 133,
   OPENREC = 134,
   UNITID = 135,
   UNITIDS = 136,
   USERIDS = 137,
   CUSTLINK = 138,
   UTILIDS = 139,
   METERIDS = 140,
   CUSTIDS = 141,
   CUSTIDSINCLUDE = 142,
   GLACCOUNT = 143,
   GLAccountIDs = 144,
   Amenities = 145,
   STARTINGLABEL = 146,
   POSTINGPERIOD = 147,
   FREQUENCY = 148,
   VALSTOINCLUDETEXT = 149,
   VALSTOINCLUDESQL = 150,
   VALSTOINCLUDEFILTER = 151,
   EXCLUDEUNAPPROVED = 152,
   CREDITS = 153,
   LANDSCAPEMODE = 154,
   SHOWPROSPECTS = 155,
   LEASESIGNED = 156,
   WOMODE = 157,
   SHOWNAMES = 158,
   SUMMARYONLY = 159,
   GRAYSCALE = 160,
   HIDEINACTITEMS = 161,
   COMPAREPREV = 162,
   TRANSFILTER = 163,
   TRANSTOINCLUDE = 164,
   SHOWDEPOSITBREAKDOWN = 165,
   SHOWLEDGERBREAKDOWN = 166,
   SHOWSUMMARY = 167,
   OWNERIDS = 168,
   INCLUDEINACTIVEOWNERS = 169,
   BEFOREPOSTING = 170,
   FROMCHARGE = 171,
   TOCHARGE = 172,
   FROMCONSUMPT = 173,
   TOCONSUMPT = 174,
   MONTHNUMS = 175,
   AGEASOF = 176,
   FROMAGE = 177,
   TOAGE = 178,
   SHOWMISSINGBDAYS = 179,
   VENDORSUBTYPES = 180,
   OwnerStatus = 181,
   ACTIVEOWNERS = 182,
   OPENLOANSONLY = 183,
   CRPINCOME = 184,
   REPIDS = 185,
   AGENTIDS = 186,
   OPENCLOSEDISSUES = 187,
   ISSUECATEGORY = 188,
   ISSUESTATUS = 189,
   ISSUEPRIORITY = 190,
   ISSUEASSIGN = 191,
   ISSUEVENDOR = 192,
   ISSUELINKTYPE = 193,
   ISSUELINK = 194,
   ISSUEFILTER = 195,
   ISSUESEARCH = 196,
   ISSUELISTSTOINCLUDE = 197,
   SERVICEISSUELIST = 198,
   CRPNAME = 199,
   CRPADDRESS = 200,
   SALESCUSTSINCLUDE = 201,
   STATEMENTCUSTSINCLUDE = 202,
   VENDORNAMESOURCE = 203,
   AMOUNTTYPE = 204,
   SIGNATUREDATE = 205,
   SHOWBANKSUMMARY = 206,
   BANKBALANCESELECTEDONLY = 207,
   MR_PRORATION = 208,
   MREFFECTIVEDATE = 209,
   PROJECTS = 210,
   PROJECTTYPE = 211,
   EXPVENDONLY = 212,
   OTHERTRANSACTIONDETAIL = 213,
   SHOWWHOLEDOLLAR = 214,
   JOURNALTYPES = 215,
   SHOWCREDITS = 216,
   INCLUDEINACTIVEUSERS = 217,
   BATCHDEPOSITS = 218,
   ALWAYSSKIPOPTIONS = 219,
   PRDATA = 220,
   STARTYEAR = 221,
   ENDYEAR = 222,
   SHOWDIL = 223,
   ZeroAmountExclusion = 224,
   SERVICEISSUESAVEDLIST = 225,
   SERVICEISSUELISTTITLE = 226,
   SERVICEISSUELISTFILTERSUMMARY = 227,
   SKIPOPTIONS = 500,
   SHOWUSER = 501,
   FILTER = 502,
   ISCHARGECOMPARE = 503,
   SHOWBALANCES = 504,
   NOPAUSE = 505,
   METHOD = 506,
   RUNFULL = 507,
   REPORTID = 508,
   ISCHARGE = 509,
   CHARGETYPE = 510,
   WEEKLY = 511,
   IGNORESTARTDATE = 512,
   IGNOREENDDATE = 513,
   MTD = 514,
   HIDENONOP = 515,
   ADJUSTOWNERSHIP = 516,
   INCLUDECC = 517,
   ALWAYSSPLITPROPS = 518,
   SELECTEDALL = 519,
   SHOWINACTIVEPROPS = 520,
   INCLUDECHILDACCOUNTS = 521,
   HIDECHARTS = 522,
   HIDEPASSTHRU = 523,
   AVAILCASHCOLOPT = 524,
   SHOWRENTESCALATION = 525,
   OWNER1099IDS = 526,
   MONTHONLY = 527,
   USEMOVEINMOVEOUTDATES = 528,
   CAMDIVEXPMETHOD = 529,
   CAMSUBTRACTBASE = 530,
   SIMPLECHARTLIST = 531,
   HIDESDTRANS = 532,
   GroupID = 533,
   Accno = 534,
   BatchPropsOrUnits = 535,
   ReconcileID = 536,
   BinaryFileID = 537,
   DelinquentDaysToInclude = 538,
   DelinquencySortByTotalAmount = 539,
   VPOForce = 540,
   DepositID = 541,
   CustomersToInclude = 542,
   IncludeProspects = 543,
   ProspectStatus = 544,
   USEOWNERPROPERTIES = 545,
   SHOWINACTIVEPROPUSEOWNERPROPERTIES = 546,
   RUNMODE = 547,
   PROSPECT = 548,
   CUST = 549,
   CONSOLNAME = 550,
   CONSOLADDR1 = 551,
   CONSOLADDR2 = 552,
   CONSOLCITY = 553,
   CONSOLSTATE = 554,
   CONSOLZIP = 555,
   CONSOLTAXID = 556,
   VendorInclusion = 557,
   ExcludeFinancialInformation = 558,
   PROPSEXCLUDEEMPTY = 559,
   BALANCESELECTEDONLY = 560,
   AMENITYIDS = 561,
   ShowAccountGroupReportOption = 562,
   ShowAccGroup = 563,
   HIDEUNDEPOSITEDFUNDS = 564,
   ExcludeRenewedLeases = 565,
   CheckedDateRange = 566,
   MonthOfExpiration = 567,
   ServiceIssueIDs = 568,
   ChargeTypeOrder = 569,
   CCReconcileID = 570,
   ActiveAssets = 571,
   ASSETHIST = 572,
   ASSETISSUE = 573,
   AssetTypeIDs = 574,
   AssetUnassignedProps = 575,
   AssetUnassignedVendors = 576,
   ASSETUSERDEF = 577,
   MAINTENANCE = 578,
   AssetManufacturerIDs = 579,
   RECAP = 580,
   INCLUDEHYPERLINKS = 581,
   CLIENTTYPE = 582,
   CLIENTID = 583,
   AccountTypesToInclude = 584,
   CollapseNotes = 585,
   LCSLevelUDF = 586,
   AgeOfLastNote = 587,
   HIDEACCUUTCOLUMNS = 588,
   WORDWRAP = 589,
   HYPERLINKEDIN = 590,
   OwnerStatementShowDetails = 591,
   CreditsToInclude = 592,
   ReportMethod = 593,
   MRProration = 594,
   MRProrationDate = 595,
   SelectedEntityIDsPassThrough = 596,
   ReportWriterReportScriptVariables = 597,
   AGENTID = 598,
   INCOMESUMMARY = 600,
   MODE = 601,
   BATCHCHECKS = 602,
   ALLOCATIONRESTRICTION = 603,
   OWNERSHIPPERCENTAGE = 604,
   UseMarketRentForRent = 605,
   DepositSort = 606,
   IsGrayscale = 607,
   BOOKMARKTEXT = 608,
   ValuesToInclude = 609,
   ValuesToIncludeAmount = 610,
   CurrentReading = 611,
   PreviousReading = 612,
   CurrentReadingDate = 613,
   PreviousReadingDate = 614,
   EXCLUDES = 615,
   AddressType = 616,
   AssetProfileIncludeSections = 617,
   PrivilegesToInclude = 619,
   ReportsToInclude = 620,
   DownOnStartingLabel = 621,
   PropertiesToInclude = 622,
   AssetsIDsToInclude = 623,
   BanksToInclude = 624,
   InspectionID = 625,
   PictureSize = 626,
   ChartMapping = 627,
   CONSOLPHONE = 628,
   CONSOLEMAIL = 629,
   InvoiceIDs = 630,
   PayeeAccountTypesToInclude = 631,
   OwnersExcludeEmpty = 632,
   CRPPERCENT = 633,
   CustomerProfileIncludeAddresses = 634,
   CustomerProfileIncludePhone = 635,
   BasisToInclude = 636,
   DoNotShowInactiveUnits = 637,
   BankAccount = 638,
   LeaseID = 640,
   INCLUDEDEPOSITS = 641,
   SHOWVOIDEDCHECKS = 642,
   ShowJournal = 643,
   CustomerProfileSort = 644,
   ExcludeNoActivityUsers = 645,
   ExcludeOpen = 646,
   AddressToUse = 647,
   ManualName = 648,
   ManualAddress = 649,
   ManualCity = 650,
   ManualState = 651,
   ManualPostalCode = 652,
   ManualPhoneNumber = 653,
   ManualTaxID = 654,
   ShowAllCharges = 657,
   ColumnsToInclude = 658,
   ShowAmortizationGraph = 659,
   Exclude1098 = 660,
   EagleRockRentRollOptionalColumns = 661,
   Loan1098AddressToUse = 662,
   Owner1099UsePayee = 663,
   DateRestrictionOnChecked = 665,
   IsUseProRataShare = 666,
   IsUseAdminFees = 667,
   CAMTenantUDF = 668,
   BATCHCREDITCARDS = 669,
   BATCHBILLS = 670,
   BatchBillID = 671,
   IncludeInactiveVendors = 672,
   OwnerAvailableCashOptionalColumns = 673,
   ExcludeItemsWithNoStatus = 674,
   PictureQuality = 675,
   PlacementSectionsToInclude = 676,
   MagnumReconcileID = 677,
   MagnumAdjustedGLBalance = 678,
   SBPHideMailingLabel = 679,
   SBPHideOwnerNumbers = 680,
   SBPHideReserve = 681,
   SBPHideSDHeld = 682,
   SBPShowVendor = 683,
   SBPHideSDTrans = 684,
   BoutiqueDashboardHideImprovements = 685,
   ShowOnlyNotContactedIn24Hours = 686,
   IsHideVendorIfApplicable = 687,
   HorizonLandRecoveryAccounts = 688,
   IsCombinePerOwner = 689,
   IsUseLastPaidCheckDate = 690,
   IncludeZeroBalanceAccounts = 691,
   BatchPropertiesPerOwner = 692,
   RestrictByOwnerContractDates = 693,
   IsReportDateRangeOverriden = 694,
   HorizonLandCostsAccounts = 695,
   DepositsAndJournals = 696,
   IncludeJournals = 697,
   ExcludeGLAccountIDs = 698,
   FilteredGLAccountIDs = 699,
   GroupProperties = 700,
   JobID = 701,
   JobIDs = 702,
   IncludeInactiveJobs = 703,
   OwnerProspectStatus = 704,
   IncludeOnlyConvertedOwners = 705,
   UseSameAddress = 706,
   IncludeOwnerProspects = 707,
   PropertyCapitalizationRate = 708,
   JobTypeID = 709,
   INCLUDEBATCH = 710,
   PropMultiUnit = 711,
   SignatureFilePath = 712,
   City = 713,
   State = 714,
   GLStartDateOverride = 715,
   BillTermIDs = 716,
   BillPaymentMethodIDs = 717,
   ApprovedStatus = 718,
   RentEscalationWithFutureOnlyOption = 719,
   INVENTORYITEMIDS = 720,
   OnlyCallsAsContact = 721,
   StateIDs = 722,
   HidePartsLaborBilledColumns = 723,
   PayableAccounts = 724,
   BatchCheckID = 725,
   EXCLUDEPREVBAL = 726,
   PayableAccountType = 727,
   PremierProFormaExpenseRate = 728,
   BatchPropsOrCustomers = 729,
   IncludeExpectedMoveOutDates = 730,
   UnitTypesToInclude = 731,
   ExcludeNSFTransactions = 732,
   NDTIncludeInbound = 733,
   NDTIncludeOutbound = 734,
   NDTAssociatedPhoneNumber = 735,
   NDTNumberOfCalls = 736,
   DaysOfWeek = 737,
   Extension = 738,
   NDTIsDuration = 739,
   NDTStartHour = 740,
   NDTEndHour = 741,
   NDTExcludedExtToExt = 742,
   NDTSummaryOnly = 743,
   CAMEscrowStatement = 744,
   NDTNumberOfCallers = 745,
   BoxScoreLeaseSigned = 746,
   ExcludeRejectedApplications = 747,
   ContactType = 748,
   OwnerProspectBoxScoreOwnerConverted = 749,
   OwnerProspectBoxScoreExcludeRejectedOwners = 750,
   CurrentLocation = 751,
   HideCompleteSSNsTaxIDs = 752,
   ForecastStartMonth = 753,
   ForecastDateRangeMode = 754,
   ForecastModel = 755,
   ForecastStartYear = 756,
   ForecastEndYear = 757,
   BudgetYears = 758,
   ForecastQuarter = 759,
   IncludeForecastModelDetail = 760,
   PropertyID = 761,
   PeriodStartDate = 762,
   PeriodEndDate = 763,
   IncludeDates = 764,
   IncludeAvidInvoices = 765,
   IncludeUnassociatedInventory = 766,
   CityWithStateIDs = 767,
   PanAmericanCashFlowDebtAccounts = 768,
   CRPPhone = 769,
   CRPOverridePhoneChecked = 770,
   CRPOverridePhone = 771,
   ExcludePeriodAdjustments = 772,
   IncludeExpiredLeasesWithNoRenewals = 773,
   IncludeBudgetComments = 774,
   BatchPropsOrJobs = 775,
   ExceedingAPercentOfBudgetedAmount = 777,
   BatchJobs = 778,
   ViolationsToInclude = 779,
   ViolationDateRestrictionOn = 780,
   ViolationNotesOption = 781,
   LCSSalesTaxState = 782,
   BatchStates = 783,
   StageDetails = 784,
   AsOfPeriodID = 785,
   StartPeriodID = 786,
   EndPeriodID = 787,
   AsOfPeriodID2 = 788,
   StartPeriodID2 = 789,
   EndPeriodID2 = 790,
   CodeGroupID = 791,
   CodeGroupName = 792,
   PropertyNames = 793,
   RunByPeriod = 794,
   DateRestrictionOnWithBillPayments = 795,
   ExcludeOwners = 796,
   InvoiceID = 797,
   RunByPeriod2 = 798,
   EstimateID = 800,
   ReportPeriodType = 801,
   WeekStart = 802,
   PaymentID = 803,
   MasterPaymentID = 804,
   NonAssetORIUnitsToInclude = 805,
   IncludeJobBudgetComments = 806,
   IgnoreChargeAfterEndDate = 807,
   PurchaseOrderID = 808,
   UserDefinedFieldIDs = 809,
   IncludeBirthdateAndAge = 810,
   ShowSubTotals = 811,
   CoverLetterSummary = 812,
   ShowLegend = 813,
   PrioritiesToInclude = 814,
   StatusesToInclude = 815,
   AssetStatusesToInclude = 820,
   ProjectID = 821,
   CategoriesToInclude = 822,
   MemorizedInvoiceID = 824,
   ProspectStageIDs = 825,
   ProfitLossIncomePercentage = 826,
   IncludeGraph = 827,
   ExcludeZeroRentBalance = 828,
   ConsolidatedContact = 829,
   ConsolidatedFax = 830,
   Associated1099Categories = 831,
   CRPPreparedByAgent = 832,
   CRPPreparerName = 833,
   CRPPreparerDefaultPhone = 834,
   CRPPreparerInformationOverridePhone = 835,
   CRPSignatureDate = 836,
   CRPSignatureFilePath = 837,
   CRPPreparerInformationOverridePhoneChecked = 838,
   IncludePastEscalations = 839,
   BillID = 840,
   OwnerNameSource = 841,
   OwnerUDFName = 842,
   VendorUDFName = 843,
   MarkAsCorrected = 844,
   ItemsClassificationsToInclude = 845,
   CheckForWarnings = 846,
   ExcludeStagesWithoutProspects = 847,
   ProspectsToInclude = 848,
   IncludeStageHistory = 849,
   IncludeInactiveProspectStages = 850,
   PostingID = 851,
   PanAmericanVistaFormatting = 852,
   Reminder = 853,
   RemindersOptions = 854,
   PrintMICR = 855,
   DoNotMICRDepositNum = 856,
   DepositPrintOffset = 857,
   IncludeCustComments = 858,
   ExcludeAccountsWithCorrectClearedBalances = 859,
   ExcludePassThroughs = 860,
   ExcludeOtherSection = 861,
   LeaseOptions = 862,
   CheckIDs = 863,
   IsPrintBlankChecks = 864,
   FirstCheckNumber = 865,
   CheckCount = 866,
   SignatureID = 867,
   ShowMemo = 868,
   ShowPropertyAllocation = 869,
   ShowUnitAllocation = 870,
   ShowAccountNumbers = 871,
   IsPrintWithoutSignature = 872,
   ExcludeProRataShare = 873,
   ExcludeFullExpenseAmount = 874,
   RecurringBillID = 875,
   PollID = 876,
   PollResponseID = 877,
   AmountBasis = 878,
   ShowOpenChecklistItems = 880,
   ShowCompletedChecklistItems = 881,
   IncludePastDays = 882,
   MakeReadyPostIDs = 883,
   EntityTypeID = 884,
   JournalID = 885,
   ReversalDisplayID = 886,
   HideVacantUnits = 887,
   RecurringJournalID = 888,
   LeadSourcesToInclude = 889,
   IncludeInactiveLeadSources = 890,
   OAHIDEMAILINGLABEL = 891,
   OAHIDEPHONENUMBERS = 892,
   OAHIDEVENDOR = 893,
   OAPaymentReceived = 894,
   OAMgtFees = 895,
   MemorizedJournalID = 896,
   ReconcileCheckIDs = 897,
   ReconcileCheckDetailIDs = 898,
   ReconcileCreditCardTransactionDetailIDs = 899,
   ReconcileJournalDetailIDs = 900,
   ReconcileDepositIDs = 901,
   ReconcileDepositDetailIDs = 902,
   ReconcilePaymentIDs = 903,
   ReconcileCreditCardTransactionIDs = 904,
   PostDate = 905,
   BatchPerOwner = 906,
   IsHideColumns = 907,
   ManagementFeePostType = 908,
   ManagementFeePropertyIDs = 909,
   ManagementFeeOwnerIDs = 910,
   IncludeArrivalDepartures = 911,
   OBSHideMailingHeader = 912,
   OBSHidePhoneNumber = 913,
   OBSHideVendors = 914,
   OBSHideBillsOutstanding = 915,
   OAHideMgtFeeBreakdown = 916,
   OAHideDistributionBreakdown = 917,
   ExcludeAfterReconcileDate = 918,
   IncludePollGraph = 919,
   IncludeManagementFee = 920,
   PeriodOptions = 921,
   Name = 922,
   RecurringChargeFrequency = 923,
   IsSearchContains = 924,
   IncludePropertyCharges = 925,
   IncludeUnitCharges = 926,
   IncludeUnitTypeCharges = 927,
   IncludeTenantCharges = 928,
   UtilityMeterID = 929,
   IncomeTypeIsLow = 930,
   ShowVendorIfApplicable = 931,
   HideChartAccounts = 932,
   BatchPropertyIDs = 933,
   HideHeaderFooter = 934,
   PropertySelectionBy = 935,
   ActualOrBudget = 936,
   IncludeTotalPSF = 937,
   DaysBefore = 938,
   DaysAfter = 939,
   AvailableUnitsOnly = 940,
   Length = 941,
   Width = 942,
   Amps = 943,
   PullThroughOnly = 944,
   NumberGuests = 945,
   Bedrooms = 946,
   Bathrooms = 947,
   UseExactMatch = 948,
   ChargeDate = 950,
   ExcludeUnitsWithNoAvailability = 951,
   ShowUnitStatusSummary = 952,
   ExcludeSDFromBankBalances = 953,
   ExcludeTenantBalances = 954,
   ExcludeReserveBalancingEntries = 955,
   ExcludeTenantPrepays = 956,
   IncludeInactiveHistoryCategories = 957,
   HideDistributionBreakdown = 958,
   OBSHideDistributionBreakdown = 959,
   InsuranceExpiration = 960,
   MonthlyYearly = 962,
   ShowIR = 963,
   ChangeSort = 964,
   ExcludeOtherRentableItems = 965,
   IncludeEPayOnly = 966,
   ExcludeEmptyReports = 967,
   ExcludeAllocatedTenantPrepays = 968,
   DisplayHeaderOnlyOnFirstPage = 969,
   ShowVendorChecks = 970,
   ShowOwnerChecks = 971,
   ShowCheckBreakdown = 972,
   IncludePsf = 973,
   IncludeUseable = 974,
   IncludeGrossup = 975,
   IncludeBudgetOnly = 976,
   IncludeAllBankAccounts = 977,
   ReservationRequestStatuses = 980,
   SelectAssetsByLocationProperty = 981,
   StatementMethod = 982,
   IncludeCumulativeBadDebtWriteOffs = 983,
   ArchitecturalRequestId = 984,
}
