import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { RmCurrencyPipe } from "@lcs/pipes/rm-currency-pipe";
import { valueOrThrow } from "@lcs/utils/strict-null-utils";

import { OverlayPanelComponent } from "../../overlay-panel/overlay-panel.component";
import { MidpointRounding } from "../../utils/midpoint-rounding";
import { OperationHistoryModel } from "./operation-history.model";

@Component({
   selector: "lcs-calculator-input",
   templateUrl: "calculator-input.component.html",
})
export class CalculatorInputComponent extends ValueAccessorBase<string | null> {
   @Input() hideIcon: boolean = false;

   @Input() numberFormat: string = "1.0-0";

   @ViewChild("textBox", { static: true }) textBox: ElementRef;

   @ViewChild("overlay", { static: true }) overlay: OverlayPanelComponent;

   @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();

   calculatorHistory: Array<OperationHistoryModel>;

   invalidInputValue: boolean;

   firstHistoryOperand: number;

   inputFocused: boolean;

   overlayPanelVisible = false;

   overlayStyles: {};

   runningTotal: string | null;

   private numberOperatorRegex: RegExp = /([\d.][+*/-])/g;

   private numberRegex: RegExp = /-?\d*\.?\d+/g;

   private operatorRegex: RegExp = /(?!-[\d.])[-+*/]/g;

   private operations = {
      "*": (a: number, b: number): number => {
         return a * b;
      },
      "/": (a: number, b: number): number => {
         return a / b;
      },
      "+": (a: number, b: number): number => {
         return a + b;
      },
      "-": (a: number, b: number): number => {
         return a - b;
      },
   };

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
      this.calculatorHistory = new Array<any>();
      this.runningTotal = "";
   }

   writeValue(value: number | string | null): void {
      const val = this.formatValue(`${value}`, this.numberFormat);
      this.innerValue = value === null ? "" : val;
      this.changeDetectorRef.markForCheck();
   }

   registerOnChange(fn: (_: any) => void): void {
      this.changed.push((value: string) => {
         value = value.trim();
         fn(value ? parseFloat(value) : null);
      });
   }

   validateInput(input: any) {
      this.innerValue = input;
      if (input) {
         //RM12 accepts strings with any amount of whitespace, $ or , in the string so remove all of them
         input = input.toString().replace(/\s+|\$|,/g, "");
         const lastChar = input.substring(input.length - 1);
         if (lastChar === "=") {
            if (lastChar !== input) {
               setTimeout(() => {
                  this.innerValue = input.match(/((?!=).)+/g);
                  this.handleFinished();
                  this.changeDetectorRef.markForCheck();
               });
            } else {
               this.resetValue();
            }
         } else {
            this.invalidInputValue = input.replace(/[ ]/g, "").match(/([^\w+/*.=-]|[a-zA-Z_])/g);
            if (!this.invalidInputValue) {
               this.parseInput(input);
            }
         }
      }
   }

   parseInput(input: string) {
      input = input.replace(/\s+/g, "");
      if (this.overlayPanelVisible) {
         this.parseCalculatorFeed(input);
      } else {
         const operatorMatches = input.match(this.operatorRegex);
         const operandMatches = input.match(this.numberRegex);
         if (operatorMatches && operandMatches) {
            const operator = operatorMatches[0];
            const operand = operandMatches[0];
            if (operand + operator === input) {
               this.resetValue();
               setTimeout(() => {
                  this.initializeOverlayPanel(operator, operand);
                  this.changeDetectorRef.markForCheck();
               });
            }
         }
      }
   }

   initializeOverlayPanel(operator: string, operand: string) {
      this.firstHistoryOperand = parseFloat(operand);
      this.addHistory(false, operator, null);
      this.runningTotal = this.formatValue(this.innerValue, this.numberFormat);
      this.overlayPanelVisible = true;
   }

   addHistory(update: boolean, operator?: string | null, operand?: string | null) {
      if (!(operand || operand === "" || operand === "0" || operator)) {
         throw new Error("Must provide at least one component of an operation for addHistory method.");
      }
      if (this.calculatorHistory.length === 0) {
         // First addition
         const newHistory = new OperationHistoryModel();
         newHistory.operand = operand ? parseFloat(operand) : null;
         newHistory.operator = operator;
         this.calculatorHistory.push(newHistory);
         setTimeout(() => {
            this.overlay.calculateTop();
         });
      } else if ((operand || operand === "") && update) {
         // Change/add the operand
         const history = this.calculatorHistory[this.calculatorHistory.length - 1];
         if (operand === "") {
            history.operand = null;
         } else {
            history.operand = parseFloat(operand);
         }
         history.operator = operator ? operator : history.operator;
      } else if (operator && !update) {
         // Appending new history
         const newHistory = new OperationHistoryModel();
         newHistory.operator = operator;
         this.calculatorHistory.push(newHistory);
         setTimeout(() => {
            this.overlay.calculateTop();
         });
      } else if (update && operator) {
         this.calculatorHistory[this.calculatorHistory.length - 1].operator = operator;
      }
   }

   updateTotal() {
      let total =
         this.firstHistoryOperand || this.firstHistoryOperand === 0
            ? this.firstHistoryOperand
            : parseFloat(this.innerValue ?? "");
      const operators = new Array<string | null | undefined>();
      const operands = new Array<number | null | undefined>();
      this.calculatorHistory.forEach((history) => {
         operators.push(history.operator);
         // @ts-ignore (error TS2345) FIXME: Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
         if (!Number.isNaN(history.operand)) {
            operands.push(history.operand);
         }
      });

      for (let idx = 0; idx < operators.length; ++idx) {
         const operator: string | undefined | null = operators[idx];
         const operand: number | undefined | null = operands[idx];
         if (operand === null) {
            break;
         }
         total = this.operations[valueOrThrow<string>(operator)](total, operand);
      }
      this.runningTotal = this.formatValue(`${total}`, this.numberFormat);
   }

   calculateAndClose() {
      if (!this.invalidInputValue) {
         const lastHistoryItem = this.calculatorHistory[this.calculatorHistory.length - 1];
         if (lastHistoryItem.operator && !(lastHistoryItem.operand || lastHistoryItem.operand === 0)) {
            this.calculatorHistory.splice(this.calculatorHistory.length - 1, 1);
         }
         this.updateTotal();
         this.innerValue = this.runningTotal;
         this.overlayPanelVisible = false;
         this.clearHistory();
      }
   }

   handleFinished() {
      if (this.overlayPanelVisible) {
         this.calculateAndClose();
      } else {
         if (this.innerValue !== "") {
            const value = parseFloat(this.innerValue ? this.innerValue.replace(/\$|,/g, "") : "");
            if (isNaN(value)) {
               this.innerValue = "";
            } else {
               const formatted = this.formatValue(`${value}`, this.numberFormat);
               if (formatted !== this.innerValue) {
                  this.innerValue = formatted;
               }
            }
         }
      }
      this.propagateChanged();
   }

   onEnter(event: Event) {
      if (this.overlayPanelVisible) {
         (event as KeyboardEvent).preventDefault();
      }
      this.handleFinished();
   }

   resetValue() {
      setTimeout(() => {
         this.innerValue = "";
         this.changeDetectorRef.markForCheck();
      });
   }

   clearHistory() {
      this.calculatorHistory.splice(0, this.calculatorHistory.length);
      this.firstHistoryOperand = 0;
   }

   onBlur() {
      this.inputFocused = false;
      this.handleFinished();
      this.clearHistory();
      this.propagateTouched();
      this.inputBlur.emit();
   }

   onFocus() {
      this.inputFocused = true;
      const element = this.textBox.nativeElement;
      const position = element.value.length;
      element.setSelectionRange(0, position);
   }

   private parseCalculatorFeed(input: string) {
      const numberOperatorMatches = input.match(this.numberOperatorRegex);
      if (numberOperatorMatches) {
         const match = numberOperatorMatches[0];
         const operator = match.substring(match.length - 1);
         this.resetValue();
         this.updateTotal();
         this.addHistory(false, operator, null);
      } else {
         const operatorList = input.match(this.operatorRegex);
         if (operatorList) {
            const operator = operatorList[0];

            this.addHistory(true, operator, null);
            this.resetValue();
            return;
         }
         this.addHistory(true, null, input);
      }
   }

   private formatValue(value: string | null, numberFormat: string): string | null {
      const numberValue = parseFloat(value ?? "");
      if (value && !isNaN(numberValue)) {
         return RmCurrencyPipe.rmCurrencyTransform(numberValue, numberFormat, MidpointRounding.ToEven, "");
      } else {
         return null;
      }
   }
}
