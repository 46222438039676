import { EntityEmbedOption } from "../base-options/embed-option";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class UserEmbedOptions extends EntityEmbedOption {
   static readonly Banks: UserEmbedOptions = new UserEmbedOptions("Banks");

   static readonly CreateUser: UserEmbedOptions = new UserEmbedOptions("CreateUser");

   static readonly Supervisor: UserEmbedOptions = new UserEmbedOptions("Supervisor");

   static readonly TaskUsers: UserEmbedOptions = new UserEmbedOptions("TaskUsers");

   static readonly UpdateUser: UserEmbedOptions = new UserEmbedOptions("UpdateUser");

   static readonly UserBanks: UserEmbedOptions = new UserEmbedOptions("UserBanks");

   static readonly UserDefinedValues: UserEmbedOptions = new UserEmbedOptions("UserDefinedValues");

   static readonly UserDefinedValuesAttachment: UserEmbedOptions = new UserEmbedOptions("UserDefinedValues.Attachment");

   static readonly UserLocations: UserEmbedOptions = new UserEmbedOptions("UserLocations");

   static readonly UserPrivileges: UserEmbedOptions = new UserEmbedOptions("UserPrivileges");

   static readonly UserPrivilegesPrivilege: UserEmbedOptions = new UserEmbedOptions("UserPrivileges.Privilege");

   static readonly UserPrivilegesPrivilegePrivilegeCategory: UserEmbedOptions = new UserEmbedOptions(
      "UserPrivileges.Privilege.PrivilegeCategory"
   );

   static readonly CreditCards: UserEmbedOptions = new UserEmbedOptions("CreditCards");

   static readonly UserProperties: UserEmbedOptions = new UserEmbedOptions("UserProperties");

   static readonly UserPropertyGroups: UserEmbedOptions = new UserEmbedOptions("UserPropertyGroups");

   static readonly UserPropertyGroupsPropertyGroup: UserEmbedOptions = new UserEmbedOptions(
      "UserPropertyGroups.PropertyGroup"
   );

   static readonly UserPropertyGroupsPropertyGroupProperties: UserEmbedOptions = new UserEmbedOptions(
      "UserPropertyGroups.PropertyGroup.Properties"
   );

   static readonly UserReports: UserEmbedOptions = new UserEmbedOptions("UserReports");

   static readonly UserReportsReport: UserEmbedOptions = new UserEmbedOptions("UserReports.Report");

   static readonly UserReportsReportCategory: UserEmbedOptions = new UserEmbedOptions("UserReports.ReportCategory");

   static readonly UserReportsReportReportCategory: UserEmbedOptions = new UserEmbedOptions(
      "UserReports.Report.ReportCategory"
   );

   static readonly UserReportsReportReportCategoryChildCategories: UserEmbedOptions = new UserEmbedOptions(
      "UserReports.Report.ReportCategory.ChildCategories"
   );

   static readonly UserRoles: UserEmbedOptions = new UserEmbedOptions("UserRoles");

   static readonly CustomProductUserPreferences: UserEmbedOptions = new UserEmbedOptions(
      "CustomProductUserPreferences"
   );

   static readonly UserRolesRole: UserEmbedOptions = new UserEmbedOptions("UserRoles.Role");

   static readonly UserRolesRoleUserPrivileges: UserEmbedOptions = new UserEmbedOptions(
      "UserRoles.Role.UserPrivileges"
   );

   static readonly UserRolesRoleUserPrivilegesPrivilege: UserEmbedOptions = new UserEmbedOptions(
      "UserRoles.Role.UserPrivileges.Privilege"
   );

   static readonly UserRolesRoleUserPrivilegesPrivilegePrivilegeCategory: UserEmbedOptions = new UserEmbedOptions(
      "UserRoles.Role.UserPrivileges.Privilege.PrivilegeCategory"
   );

   static readonly UserRolesRoleUserReports: UserEmbedOptions = new UserEmbedOptions("UserRoles.Role.UserReports");

   static readonly UserTasks: UserEmbedOptions = new UserEmbedOptions("UserTasks");

   static readonly EffectivePrivileges: UserEmbedOptions = new UserEmbedOptions("EffectivePrivileges");

   static readonly LetterTemplates: UserEmbedOptions = new UserEmbedOptions("LetterTemplates");

   static readonly LetterTemplatesLetterTemplate: UserEmbedOptions = new UserEmbedOptions(
      "LetterTemplates.LetterTemplate"
   );

   static readonly ListItem: UserEmbedOptions = new UserEmbedOptions("ListItem");

   static readonly PhoneNumbers: UserEmbedOptions = new UserEmbedOptions("PhoneNumbers");

   static readonly Properties: UserEmbedOptions = new UserEmbedOptions("Properties");
}
