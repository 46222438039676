import { ScrollingModule } from "@angular/cdk/scrolling";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ContextService } from "@lcs/action-context/context.service";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { DialogModule } from "@lcs/dialog/dialog.module";
import { ErrorMessageModule } from "@lcs/error-message/error-message.module";
import { FooterService } from "@lcs/footer/footer.service";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { IconService } from "@lcs/icon/icon.service";
import { LCSIconService } from "@lcs/icon/lcs-icon.service";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { SessionModule } from "@lcs/session/session.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";
import { CookieModule } from "ngx-cookie";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityRequestEndpointService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint.service";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntitySearchConfigurationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration.service";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.service";

import { AccountInformationDetailModule } from "./account-information-detail/account-information-detail.module";
import { AccountRecoveryModule } from "./account-recovery/account-recovery.module";
import { AccountSignUpModule } from "./account-signup/account-signup.module";
import { AccountUpdatedModule } from "./account-updated/account-updated.module";
import { AppComponent } from "./app.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { BillDetailModule } from "./bill-detail/bill-detail.module";
import { BillListModule } from "./bill-list/bill-list.module";
import { ChangeEmailAddressModule } from "./change-emailaddress/change-emailaddress.module";
import { ChangePasswordModule } from "./change-password/change-password.module";
import { CheckListModule } from "./check-list/check-list.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { EmailModule } from "./email/email.module";
import { EstimateDetailModule } from "./estimate-detail/estimate-detail.module";
import { EstimatesListModule } from "./estimate-list/estimate-list.module";
import { HistoryNotesListModule } from "./history-notes-list/history-notes-list.module";
import { InspectionReportListModule } from "./inspection-report-list/inspection-report-list.module";
import { LayoutModule } from "./layout/layout.module";
import { LinkedAccountManagementModule } from "./linked-account-management/linked-account-management.module";
import { ManagementFeesDetailModule } from "./management-fees-detail/management-fees-detail.module";
import { ManagementFeesListModule } from "./management-fees-list/management-fees-list.module";
import { MergeUsersModule } from "./merge-users/merge-users.module";
import { OWATableModule } from "./owa-table/owa-table.module";
import { OwnerContributionsModule } from "./owner-contributions/owner-contributions.module";
import { OwnerDistributionListModule } from "./owner-distribution-list/owner-distribution-list.module";
import { OwnershipsModule } from "./ownerships-list/ownerships-list.module";
import { PasswordResetModule } from "./password-reset/password-reset.module";
import { PrivilegeGuard } from "./privileges/privilege.guard";
import { ReportHubModule } from "./report-hub/report-hub.module";
import { ReportsListModule } from "./reports-list/reports-list.module";
import { ResendVerificationModule } from "./resend-verification/resend-verification.module";
import { OWARoutes } from "./routing/app.routing";
import { ServiceIssueDetailModule } from "./service-issue-details/service-issue-detail.module";
import { ServiceIssueListModule } from "./service-issue-list/service-issue-list.module";
import { OWASessionCheckService } from "./session/owa-sessioncheck.service";
import { SessionGuard } from "./session/session.guard";
import { SideMenuModule } from "./side-menu/side-menu.module";
import { SignableDocumentsListModule } from "./signable-documents-list/signable-documents-list.module";
import { SignableDocumentsModule } from "./signable-documents/signable-documents.module";
import { TileModule } from "./tile/tile.module";
import { TopBarModule } from "./top-bar/top-bar.module";
import { UpdateAccountInformationModule } from "./update-account-information/update-account-information.module";
import { UserProfileModule } from "./user-profile/user-profile.module";
import { ValidatePasswordFormatModule } from "./validate-password-format/validate-password-format.module";
import { PhoneNumberValidationService } from "./validation/phone-number-validation.service";
import { VerifyEmailAddressModule } from "./verify-emailaddress/verify-emailaddress.module";
import { VerifyPendingModule } from "./verify-pending/verify-pending.module";

@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule,
      CookieModule.forRoot(),
      BrowserAnimationsModule,
      LoadingOverlayModule,
      OWARoutes,
      RouterModule,
      AuthenticationModule,
      TopBarModule,
      SideMenuModule,
      AccountInformationDetailModule,
      FormsModule,
      AccountRecoveryModule,
      SessionModule,
      DialogModule,
      DashboardModule,
      ServiceIssueListModule,
      ServiceIssueDetailModule,
      BillListModule,
      BillDetailModule,
      EstimateDetailModule,
      EstimatesListModule,
      OwnerDistributionListModule,
      OwnershipsModule,
      ManagementFeesListModule,
      ManagementFeesDetailModule,
      ReportsListModule,
      HistoryNotesListModule,
      SignableDocumentsListModule,
      ErrorMessageModule,
      AccountRecoveryModule,
      PasswordResetModule,
      VerifyPendingModule,
      TileModule,
      LayoutModule,
      ReportHubModule,
      ChangeEmailAddressModule,
      MergeUsersModule,
      CheckListModule,
      OWATableModule,
      SignableDocumentsModule,
      AccountUpdatedModule,
      UpdateAccountInformationModule,
      InspectionReportListModule,
      LcsSlidePanelModule,
      OwnerContributionsModule,
      EmailModule,
      LinkedAccountManagementModule,
      AccountSignUpModule,
      VerifyEmailAddressModule,
      ScrollingModule,
      ValidatePasswordFormatModule,
      UserProfileModule,
      ChangePasswordModule,
      ResendVerificationModule,
      //SearchRegisterModule,
   ],
   providers: [
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      SessionGuard,
      PrivilegeGuard,
      FooterService,
      { provide: EntityRequestEndpointServiceBase, useClass: EntityRequestEndpointService },
      { provide: EntitySearchConfigurationServiceBase, useClass: EntitySearchConfigurationService },
      { provide: EntityViewInformationServiceBase, useClass: EntityViewInformationService },
      PhoneNumberValidationService,
      { provide: SessionCheckService, useClass: OWASessionCheckService },
      FormRegistrationService,
      ContextService,
      { provide: IconService, useClass: LCSIconService },
      // This can be removed when Datatable filters are removed
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
