import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-violation-notes-report-parameter",
   templateUrl: "violation-notes-report-parameter.component.html",
})
export class ViolationNotesReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   isChecked: boolean;

   violationNotesReportParameter: ReportReportParameterViewModel;

   numberNotesReportParameter: ReportReportParameterViewModel;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.violationNotesReportParameter.IsAsk = isAsk;
      this.numberNotesReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(
         this.violationNotesReportParameter.ReportParameterID,
         isAsk
      );
      this.reportParametersService.updateReportParameterAsk(this.numberNotesReportParameter.ReportParameterID, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.violationNotesReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ViolationNotesOption
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.numberNotesReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.NUMBERNOTES
      );
      this.numberNotesReportParameter.DisplayName = "";

      this.reportParametersService.reportParameterUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         if (value.reportParameter === ReportParameter.ViolationNotesOption) {
            this.isChecked = GlobalsService.convertStringToBoolean(value.value);
         }
      });
   }
}
