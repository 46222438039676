import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { ChargeTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge-type.embed-options";
import { ChartOfAccountsMappingLinkEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/chart-of-accounts-mapping-link.embed-options";
import { GLAccountTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount-type.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { MicrEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/micr.embed-options";
import { NachaODFIBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/nacha-odfibank.embed-options";
import { PositivePayFormatEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/positive-pay-format.embed-options";
import { UserBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-bank.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { MergeGLAccountSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/merge-glaccount.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountBalanceDisbursalRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/account-balance-disbursal-request.model";
import { AccountBalanceDisbursalSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/account-balance-disbursal-summary.model";
import { ChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type.model";
import { ChartOfAccountsMappingLinkModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/chart-of-accounts-mapping-link.model";
import { GeneralLedgerDataSetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/general-ledger-data-set.model";
import { GLAccountTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount-transfer.model";
import { GLAccountTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount-type.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { MergeGLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/merge-glaccount.model";
import { MicrModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/micr.model";
import { NachaODFIBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/nacha-odfibank.model";
import { PositivePayFormatModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/positive-pay-format.model";
import { UserBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-bank.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class GLAccountsService {
   static readonly AccountInsertResource = "AccountInsert";
   static readonly ActiveBankGLAccountsForPrivilegesResource = "ActiveBankGLAccountsForPrivileges";
   static readonly AllUserBanksResource = "AllUserBanks";
   static readonly AllUserCreditCardsResource = "AllUserCreditCards";
   static readonly BanksWithReconcileResource = "BanksWithReconcile";
   static readonly CalculateAccountBalanceDisbursalResource = "CalculateAccountBalanceDisbursal";
   static readonly CAMReconcileChargeTypeResource = "CAMReconcileChargeType";
   static readonly CAMRecoveryChargeTypeResource = "CAMRecoveryChargeType";
   static readonly ChargeTypesResource = "ChargeTypes";
   static readonly ChartOfAccountsMappingLinksResource = "ChartOfAccountsMappingLinks";
   static readonly ChildGLAccountsResource = "ChildGLAccounts";
   static readonly CreateUserResource = "CreateUser";
   static readonly GeneralLedgerAccountTypeResource = "GeneralLedgerAccountType";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetDataResource = "GetData";
   static readonly GetGLAccountTreeResource = "GetGLAccountTree";
   static readonly InactiveGLAccountResource = "InactiveGLAccount";
   static readonly MergeGLAccountsResource = "MergeGLAccounts";
   static readonly MergeHasBillsOutsideOfDateRangeResource = "MergeHasBillsOutsideOfDateRange";
   static readonly MergeHasChecksOutsideOfDateRangeResource = "MergeHasChecksOutsideOfDateRange";
   static readonly MicrResource = "Micr";
   static readonly NachaODFIBankResource = "NachaODFIBank";
   static readonly NextGLAccountNumberResource = "NextGLAccountNumber";
   static readonly ParentGLAccountResource = "ParentGLAccount";
   static readonly PositivePayFormatResource = "PositivePayFormat";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly SearchWithParentHierarchyResource = "SearchWithParentHierarchy";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UserBanksResource = "UserBanks";
   static readonly UserBanksByUserIDsResource = "UserBanksByUserIDs";
   static readonly endpoint = "GLAccountsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: GLAccountModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<GLAccountModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(GLAccountsService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(GLAccountsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   postAccountInsertUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      embedOptions?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (embedOptions !== undefined && embedOptions !== null && embedOptions.length > 0) {
         additionalParameters.push("embedOptions=" + embedOptions);
      }
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/AccountInsert",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postAccountInsert(
      item?: GLAccountModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      embedOptions?: Array<string | GLAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService
         .post(this.postAccountInsertUrl(saveOptions, embedOptions, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getActiveBankGLAccountsForPrivilegesCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ActiveBankGLAccountsForPrivileges",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getActiveBankGLAccountsForPrivilegesCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getActiveBankGLAccountsForPrivilegesCollectionUrl(additionalParameters)
      );
   }

   getAllUserBanksCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/AllUserBanks",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllUserBanksCollection(additionalParameters?: Array<string>): Observable<Array<UserBankModel>> {
      return this.apiService.getCollection(this.getAllUserBanksCollectionUrl(additionalParameters));
   }

   getAllUserCreditCardsCollectionUrl(
      filters?: Array<FilterOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/AllUserCreditCards",
         null,
         null,
         filters,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllUserCreditCardsCollection(
      filters?: Array<FilterOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(this.getAllUserCreditCardsCollectionUrl(filters, additionalParameters));
   }

   getBanksWithReconcileCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/BanksWithReconcile",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBanksWithReconcileCollection(additionalParameters?: Array<string>): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(this.getBanksWithReconcileCollectionUrl(additionalParameters));
   }

   postCalculateAccountBalanceDisbursalUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/CalculateAccountBalanceDisbursal",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCalculateAccountBalanceDisbursal(
      model?: AccountBalanceDisbursalRequestModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountBalanceDisbursalSummaryModel>> {
      return this.apiService
         .post(this.postCalculateAccountBalanceDisbursalUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getCAMReconcileChargeTypeUrl(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/CAMReconcileChargeType",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCAMReconcileChargeType(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(
         this.getCAMReconcileChargeTypeUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCAMRecoveryChargeTypeUrl(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/CAMRecoveryChargeType",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCAMRecoveryChargeType(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(
         this.getCAMRecoveryChargeTypeUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChargeTypesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChargeTypes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChargeTypes(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(
         this.getChargeTypesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChargeTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChargeTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChargeTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getChargeTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getChartOfAccountsMappingLinksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMappingLinks(
      id: number,
      itemID: number,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingLinkModel> {
      return this.apiService.getSingle(
         this.getChartOfAccountsMappingLinksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChartOfAccountsMappingLinksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMappingLinksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappingLinkModel>> {
      return this.apiService.getCollection(
         this.getChartOfAccountsMappingLinksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getChildGLAccountsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChildGLAccounts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChildGLAccounts(
      id: number,
      itemID: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(
         this.getChildGLAccountsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChildGLAccountsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ChildGLAccounts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChildGLAccountsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getChildGLAccountsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getGeneralLedgerAccountTypeUrl(
      id: number,
      embeds?: Array<string | GLAccountTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/GeneralLedgerAccountType",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGeneralLedgerAccountType(
      id: number,
      embeds?: Array<string | GLAccountTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountTypeModel> {
      return this.apiService.getSingle(
         this.getGeneralLedgerAccountTypeUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postGetDataUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/GetData",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetData(
      parameters?: GeneralLedgerDataSetModel | null,
      additionalParameters?: Array<string>
   ): Observable<GeneralLedgerDataSetModel> {
      return this.apiService
         .post(this.postGetDataUrl(additionalParameters), parameters)
         .pipe(map((response) => response.body));
   }

   getGetGLAccountTreeCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/GetGLAccountTree",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getGetGLAccountTreeCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getGetGLAccountTreeCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getInactiveGLAccountUrl(accountID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountID=" + accountID);
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/InactiveGLAccount",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getInactiveGLAccount(accountID: number, additionalParameters?: Array<string>): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getInactiveGLAccountUrl(accountID, additionalParameters));
   }

   postMergeGLAccountsUrl(
      saveOptions?: Array<string | MergeGLAccountSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/MergeGLAccounts",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postMergeGLAccounts(
      item?: MergeGLAccountModel | null,
      saveOptions?: Array<string | MergeGLAccountSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postMergeGLAccountsUrl(saveOptions, additionalParameters), item);
   }

   getMergeHasBillsOutsideOfDateRangeUrl(
      sourceAccountID: number,
      startDate?: string | null,
      endDate?: string | null,
      vendorID?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("sourceAccountID=" + sourceAccountID);
      if (startDate !== undefined && startDate !== null) {
         additionalParameters.push("startDate=" + startDate);
      }
      if (endDate !== undefined && endDate !== null) {
         additionalParameters.push("endDate=" + endDate);
      }
      if (vendorID !== undefined && vendorID !== null) {
         additionalParameters.push("vendorID=" + vendorID);
      }
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/MergeHasBillsOutsideOfDateRange",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMergeHasBillsOutsideOfDateRange(
      sourceAccountID: number,
      startDate?: string | null,
      endDate?: string | null,
      vendorID?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.getSingle(
         this.getMergeHasBillsOutsideOfDateRangeUrl(sourceAccountID, startDate, endDate, vendorID, additionalParameters)
      );
   }

   getMergeHasChecksOutsideOfDateRangeUrl(
      sourceAccountID: number,
      startDate?: string | null,
      endDate?: string | null,
      vendorID?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("sourceAccountID=" + sourceAccountID);
      if (startDate !== undefined && startDate !== null) {
         additionalParameters.push("startDate=" + startDate);
      }
      if (endDate !== undefined && endDate !== null) {
         additionalParameters.push("endDate=" + endDate);
      }
      if (vendorID !== undefined && vendorID !== null) {
         additionalParameters.push("vendorID=" + vendorID);
      }
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/MergeHasChecksOutsideOfDateRange",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMergeHasChecksOutsideOfDateRange(
      sourceAccountID: number,
      startDate?: string | null,
      endDate?: string | null,
      vendorID?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.getSingle(
         this.getMergeHasChecksOutsideOfDateRangeUrl(
            sourceAccountID,
            startDate,
            endDate,
            vendorID,
            additionalParameters
         )
      );
   }

   getMicrUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MicrEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/Micr",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMicr(
      id: number,
      itemID: number,
      embeds?: Array<string | MicrEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MicrModel> {
      return this.apiService.getSingle(this.getMicrUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getMicrCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/Micr",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMicrCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MicrModel>> {
      return this.apiService.getCollection(
         this.getMicrCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMicrUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/Micr",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMicr(
      id: number,
      item?: MicrModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MicrModel> {
      return this.apiService.postSingle(this.postMicrUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postMicrCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/Micr",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMicrCollection(
      id: number,
      items: Array<MicrModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MicrEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MicrModel>> {
      return this.apiService.postCollection(
         this.postMicrCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   getNachaODFIBankUrl(
      id: number,
      embeds?: Array<string | NachaODFIBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/NachaODFIBank",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getNachaODFIBank(
      id: number,
      embeds?: Array<string | NachaODFIBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<NachaODFIBankModel> {
      return this.apiService.getSingle(this.getNachaODFIBankUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getNextGLAccountNumberUrl(
      glAccountTypeID: number,
      parentGLAccountID?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("glAccountTypeID=" + glAccountTypeID);
      if (parentGLAccountID !== undefined && parentGLAccountID !== null) {
         additionalParameters.push("parentGLAccountID=" + parentGLAccountID);
      }
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/NextGLAccountNumber",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getNextGLAccountNumber(
      glAccountTypeID: number,
      parentGLAccountID?: number | null,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService.getSingle(
         this.getNextGLAccountNumberUrl(glAccountTypeID, parentGLAccountID, additionalParameters)
      );
   }

   getParentGLAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/ParentGLAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getParentGLAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(
         this.getParentGLAccountUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPositivePayFormatUrl(
      id: number,
      embeds?: Array<string | PositivePayFormatEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/PositivePayFormat",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPositivePayFormat(
      id: number,
      embeds?: Array<string | PositivePayFormatEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PositivePayFormatModel> {
      return this.apiService.getSingle(
         this.getPositivePayFormatUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getSearchWithParentHierarchyCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/SearchWithParentHierarchy",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchWithParentHierarchyCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountTransferModel>> {
      return this.apiService.getCollection(
         this.getSearchWithParentHierarchyCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/UserBanks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserBankModel> {
      return this.apiService.getSingle(
         this.getUserBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         GLAccountsService.endpoint + "/UserBanks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserBankModel>> {
      return this.apiService.getCollection(
         this.getUserBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserBanksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/UserBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserBanks(
      id: number,
      item?: UserBankModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserBankModel> {
      return this.apiService.postSingle(
         this.postUserBanksUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserBanksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/UserBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserBanksCollection(
      id: number,
      items: Array<UserBankModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserBankModel>> {
      return this.apiService.postCollection(
         this.postUserBanksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserBanksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         GLAccountsService.endpoint + "/UserBanks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserBanks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserBanksUrl(id, ids, deleteOptions, additionalParameters));
   }

   postUserBanksByUserIDsUrl(
      glAccountID: number,
      userIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("glAccountID=" + glAccountID);
      if (userIDs !== undefined && userIDs !== null && userIDs.length > 0) {
         additionalParameters.push("userIDs=" + userIDs);
      }
      return this.apiService.postUrl(
         GLAccountsService.endpoint + "/UserBanksByUserIDs",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postUserBanksByUserIDs(
      glAccountID: number,
      userIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserBankModel>> {
      return this.apiService
         .post(this.postUserBanksByUserIDsUrl(glAccountID, userIDs, additionalParameters))
         .pipe(map((response) => response.body));
   }
}
