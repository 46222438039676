<div class="owner-cover-page-container">
   <div class="control-wrapper">
      <label class="owner-selector-label">Cover Page Subject</label>
      <lcs-text-box
         [ngModel]="ownerCoverPageInformation?.CoverPageSubject"
         (ngModelChange)="onSubjectChanged($event)"
         name="cover-page-comment"
         displayName="Comment"
      ></lcs-text-box>
   </div>
   <div class="control-wrapper">
      <label class="owner-selector-label">Cover Page Message</label>
      <lcs-text-area
         [expandable]='false'
         [ngModel]="ownerCoverPageInformation?.CoverPageMessage"
         (ngModelChange)="onMessageChanged($event)"
         name="cover-page-message"
         displayName="Message"
      ></lcs-text-area>
   </div>
</div>