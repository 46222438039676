import { Component, Input, OnInit } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

@Component({
   selector: "lcs-validation-error-display-panel",
   templateUrl: "./validation-error-display-panel.component.html",
})
export class ValidationErrorDisplayPanelComponent implements OnInit {
   @Input() public displayName: string;

   @Input() public errors: ValidationErrors;

   ngOnInit() {
      if (!this.displayName) {
         this.displayName = "Value";
      }
   }
}
