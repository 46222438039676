import { EstimateModel } from "./estimate.model";
import { InventoryItemModel } from "./inventory-item.model";
import { PropertyModel } from "./property.model";
import { UnitModel } from "./unit.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class EstimateDetailModel {
   Description: string;
   Estimate: EstimateModel;
   EstimateDetailID: number;
   EstimateID: number;
   InventoryItem: InventoryItemModel;
   InventoryItemID: number;
   IsMarkUpPercentage: boolean;
   IsTaxable: boolean;
   MarkUp: number;
   MetaTag?: string;
   Property: PropertyModel;
   PropertyID: number;
   Quantity: number;
   Rate: number;
   SortOrder: number;
   Unit: UnitModel;
   UnitID: number;
}
