<div class="datatable-filter-label-section">
   <label>{{ filterDefinition.label }}</label>
   <lcs-filter-operation-selector
      [ngModel]="filterDefinition.filterOption.Operation"
      (ngModelChange)="onFilterOperationChanged($event)"
      [filterOperations]="trimmedOperations"
   >
   </lcs-filter-operation-selector>
</div>
<div class="datatable-filter-input-section">
   <lcs-input
      #filterInput
      class="white datatable-filter-input-control"
      [disabled]="disabled"
      [name]="filterDefinition.label"
      [controlType]="controlType"
      [subControlType]="subControlType"
      [value]="filterInputValue"
      [valueSource]="filterDefinition.valueSource"
      (valueChange)="onValueChange($event)"
      [hideSelectorLabel]="true"
      (selectionChange)="onSelectionChange($event)"
   >
   </lcs-input>
   <lcs-icon
      [showIcon]="showRemoveIcon"
      [icon]="'close'"
      [iconTooltip]="'Remove'"
      (click)="onRemoveClicked()"
   ></lcs-icon>
</div>