<div class="individual-report-parameter-option">
   <lcs-date-range-report-parameter
      [name]="name + '-date-range'"
      *ngIf="hasDateRange"
      [reportReportParameters]="reportReportParameters"
      [isRelative]="isRelative"
      [hasAsk]="hasAsk"
   ></lcs-date-range-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="name + '-start-year'"
      [parameter]="startYearParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<lcs-dynamic-selector-report-parameter
   [name]="name + '-end-year'"
   [parameter]="endYearParameter"
   [hasAsk]="hasAsk"
></lcs-dynamic-selector-report-parameter>