export enum RelativeDateOptions {
   Today = 1,
   Tomorrow = 2,
   Yesterday = 3,
   OneWeekAgo = 4,
   OneMonthAgo = 5,
   OneYearAgo = 6,
   LastWeekStart = 7,
   LastMonthStart = 8,
   LastQuarterStart = 9,
   LastYearStart = 10,
   CurrentWeekStart = 11,
   CurrentMonthStart = 12,
   CurrentQuarterStart = 13,
   CurrentYearStart = 14,
   NextWeekStart = 15,
   NextMonthStart = 16,
   NextQuarterStart = 17,
   NextYearStart = 18,
   LastWeekEnd = 19,
   LastMonthEnd = 20,
   LastQuarterEnd = 21,
   LastYearEnd = 22,
   CurrentWeekEnd = 23,
   CurrentMonthEnd = 24,
   CurrentQuarterEnd = 25,
   CurrentYearEnd = 26,
   NextWeekEnd = 27,
   NextMonthEnd = 28,
   NextQuarterEnd = 29,
   NextYearEnd = 30,
}
