import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DragScrollModule } from "projects/owa/src/app/file-carousel/drag-scroll/drag-scroll.module";

import { FileIconModule } from "../file-icon/file-icon.module";
import { LayoutModule } from "../layout/layout.module";
import { FileCarouselComponent } from "./file-carousel.component";

@NgModule({
   imports: [CommonModule, LayoutModule, DragScrollModule, FileIconModule],
   declarations: [FileCarouselComponent],
   exports: [FileCarouselComponent],
})
export class FileCarouselModule {}
