import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";

import { DatePickerModule } from "../date-picker/date-picker.module";
import { TimePickerModule } from "../time-picker/time-picker.module";
import { ValidationModule } from "../validation/validation.module";
import { DateTimePickerComponent } from "./date-time-picker.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, CheckboxModule, DatePickerModule, TimePickerModule],
   declarations: [DateTimePickerComponent],
   exports: [DateTimePickerComponent],
})
export class DateTimePickerModule {}
