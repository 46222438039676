<lcs-datatable-filter-form
   [isFiltersLoading]="isFiltersLoading"
   [filtersCollapsed]="filtersCollapsed"
   [filterOperations]="filterOperations"
   [entityType]="entityType"
   [entitySearchType]="entitySearchType"
   [includeUserDefinedValueFilters]="includeUserDefinedValueFilters"
   [dataTableFilters]="dataTableFilters"
   [userDefinedValueFilters]="userDefinedValueFilters"
   [showInactivePropertiesDefault]="showInactivePropertiesDefault"
   (appliedFilters)="onApplyFilters($event)"
   (clearFilters)="onClearFilters()"
   (toggleFilterSection)="onToggleFilterSection()"
   (userDefinedValueFiltersChange)="onUserDefinedValueFiltersChange($event)"
>
</lcs-datatable-filter-form>