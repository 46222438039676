import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OwnerFields extends EntityField {
   static readonly Addresses: OwnerFields = new OwnerFields("Addresses");

   static readonly Balance: OwnerFields = new OwnerFields("Balance");

   static readonly BalanceAccount: OwnerFields = new OwnerFields("BalanceAccount");

   static readonly BalanceAccountID: OwnerFields = new OwnerFields("BalanceAccountID");

   static readonly BalancingJournalMemo: OwnerFields = new OwnerFields("BalancingJournalMemo");

   static readonly BillableExpenses: OwnerFields = new OwnerFields("BillableExpenses");

   static readonly Charges: OwnerFields = new OwnerFields("Charges");

   static readonly CheckPayeeName: OwnerFields = new OwnerFields("CheckPayeeName");

   static readonly Color: OwnerFields = new OwnerFields("Color");

   static readonly ColorID: OwnerFields = new OwnerFields("ColorID");

   static readonly Comment: OwnerFields = new OwnerFields("Comment");

   static readonly Contact: OwnerFields = new OwnerFields("Contact");

   static readonly ContributionAccount: OwnerFields = new OwnerFields("ContributionAccount");

   static readonly ContributionAccountID: OwnerFields = new OwnerFields("ContributionAccountID");

   static readonly CreateDate: OwnerFields = new OwnerFields("CreateDate");

   static readonly CreateUser: OwnerFields = new OwnerFields("CreateUser");

   static readonly CreateUserID: OwnerFields = new OwnerFields("CreateUserID");

   static readonly DefaultPayMethod: OwnerFields = new OwnerFields("DefaultPayMethod");

   static readonly DisplayName: OwnerFields = new OwnerFields("DisplayName");

   static readonly DrawAccount: OwnerFields = new OwnerFields("DrawAccount");

   static readonly DrawAccountID: OwnerFields = new OwnerFields("DrawAccountID");

   static readonly EcheckEmail: OwnerFields = new OwnerFields("EcheckEmail");

   static readonly EpayInfo: OwnerFields = new OwnerFields("EpayInfo");

   static readonly History: OwnerFields = new OwnerFields("History");

   static readonly HistoryCalls: OwnerFields = new OwnerFields("HistoryCalls");

   static readonly HistoryEmails: OwnerFields = new OwnerFields("HistoryEmails");

   static readonly HistoryNotes: OwnerFields = new OwnerFields("HistoryNotes");

   static readonly HistorySystemNotes: OwnerFields = new OwnerFields("HistorySystemNotes");

   static readonly HistoryVisits: OwnerFields = new OwnerFields("HistoryVisits");

   static readonly HistoryWithUnitHistory: OwnerFields = new OwnerFields("HistoryWithUnitHistory");

   static readonly IsBalanceReserves: OwnerFields = new OwnerFields("IsBalanceReserves");

   static readonly IsOverridingCheckDefault: OwnerFields = new OwnerFields("IsOverridingCheckDefault");

   static readonly IsSeparateChecks: OwnerFields = new OwnerFields("IsSeparateChecks");

   static readonly IsStopPrintChecks: OwnerFields = new OwnerFields("IsStopPrintChecks");

   static readonly LastPayDate: OwnerFields = new OwnerFields("LastPayDate");

   static readonly MetaTag: OwnerFields = new OwnerFields("MetaTag");

   static readonly NachaOwnerBank: OwnerFields = new OwnerFields("NachaOwnerBank");

   static readonly Name: OwnerFields = new OwnerFields("Name");

   static readonly OwnerChecks: OwnerFields = new OwnerFields("OwnerChecks");

   static readonly OwnerContracts: OwnerFields = new OwnerFields("OwnerContracts");

   static readonly OwnerGroupDetails: OwnerFields = new OwnerFields("OwnerGroupDetails");

   static readonly OwnerGroups: OwnerFields = new OwnerFields("OwnerGroups");

   static readonly OwnerID: OwnerFields = new OwnerFields("OwnerID");

   static readonly OwnerReportBatchID: OwnerFields = new OwnerFields("OwnerReportBatchID");

   static readonly Ownerships: OwnerFields = new OwnerFields("Ownerships");

   static readonly OwnerWebAccessOwnerPreference: OwnerFields = new OwnerFields("OwnerWebAccessOwnerPreference");

   static readonly OwnerWebAccessOwnerPreferences: OwnerFields = new OwnerFields("OwnerWebAccessOwnerPreferences");

   static readonly OwnerWebAccessReportPrivileges: OwnerFields = new OwnerFields("OwnerWebAccessReportPrivileges");

   static readonly Payments: OwnerFields = new OwnerFields("Payments");

   static readonly PhoneNumbers: OwnerFields = new OwnerFields("PhoneNumbers");

   static readonly RMVoIPCallHistory: OwnerFields = new OwnerFields("RMVoIPCallHistory");

   static readonly StatementComment: OwnerFields = new OwnerFields("StatementComment");

   static readonly Status: OwnerFields = new OwnerFields("Status");

   static readonly TaxID: OwnerFields = new OwnerFields("TaxID");

   static readonly UpdateDate: OwnerFields = new OwnerFields("UpdateDate");

   static readonly UpdateUser: OwnerFields = new OwnerFields("UpdateUser");

   static readonly UpdateUserID: OwnerFields = new OwnerFields("UpdateUserID");

   static readonly UserDefinedValues: OwnerFields = new OwnerFields("UserDefinedValues");

   static readonly WebMessage: OwnerFields = new OwnerFields("WebMessage");

   static readonly WebUserAccounts: OwnerFields = new OwnerFields("WebUserAccounts");

   static readonly WebUsers: OwnerFields = new OwnerFields("WebUsers");
}
