////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum LeasePeriod {
   NotSet = 0,
   Monthly = 1,
   Weekly = 2,
   Daily = 3,
   Yearly = 4,
}
