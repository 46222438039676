<owa-layout [headerTitle]="'Ownerships'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{filter.Label}} {{filter.shortOperationLabel()}}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{filter.DisplayValue}}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>

   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="ownershipFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>

      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{results | async}} of {{totalResults | async}} Ownerships
      </div>

      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(observableOwnerships | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>

               <ng-container
                  lcsColumnDef="Ended"
                  [width]="1.5"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  ></th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="boolean has-drilldown"
                  >
                     <div
                        *ngIf="!row.IsActiveContract"
                        matTooltip="Ended on {{row.ContractToDate | date: 'MM/dd/yyyy'}}"
                        matTooltipPosition="above"
                        matTooltipClass="end-tooltip"
                     >
                        <i class=" contract-end material-icons">error_outline</i>
                     </div>
                  </td>
               </ng-container>



               <ng-container
                  lcsColumnDef="PropertyName"
                  [width]="15"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                  >{{row.PropertyName}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="OwnershipPercentage"
                  [width]="6"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Ownership</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="numeric"
                  >{{row.OwnershipPercentage}}%</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="ReserveAmount"
                  [width]="6"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Reserve</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="numeric"
                  >{{row.ReserveAmount | currency:'USD':'symbol':'1.2-2'}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="SecurityDepositHeld"
                  [width]="8"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Security Deposit</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                     class="numeric"
                  >{{row.SecurityDepositHeld | currency:'USD':'symbol':'1.2-2'}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Manager"
                  [width]="8"
                  [priority]="6"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Manager</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                  >{{row.Manager}}</td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Email"
                  [width]="2"
                  [priority]="7"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  ></th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index=index"
                  >
                     <div
                        class="link"
                        *ngIf="allowOwnerToEmailPropertyManager && row.Email !== ''"
                        (click)="email(row.Email,row.PropertyID)"
                        matTooltip="Email property manager"
                        matTooltipPosition="above"
                        matTooltipClass="email-tooltip"
                     >
                        <i class="material-icons">mail_outline</i>
                     </div>
                  </td>
               </ng-container>

            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="((!(isDataLoading | async)) && ($any((observableOwnerships | async))?.length < $any((totalResults | async)))) "
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>

   </div>
</owa-layout>