import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { slidePanelAnimationIdentifier } from "@lcs/slide-panel/slide-panel.animation";
import { SystemWebPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-web-preference.enum";

import { AccountInformationDetailComponent } from "../account-information-detail/account-information-detail.component";
import { AccountRecoveryComponent } from "../account-recovery/account-recovery.component";
import { AccountSignUpComponent } from "../account-signup/account-signup.component";
import { AccountUpdatedComponent } from "../account-updated/account-updated.component";
import { LoginComponent } from "../authentication/login/login.component";
import { BillDetailComponent } from "../bill-detail/bill-detail.component";
import { BillDetailListViewMoreComponent } from "../bill-detail/billdetail-list/billdetails-list-view-more.component";
import { BillDetailContainerComponent } from "../bill-detail/details/bill-detail-container.component";
import { BillListComponent } from "../bill-list/bill-list.component";
import { ChangeEmailAddressViewComponent } from "../change-emailaddress-view/change-emailaddress-view.component";
import { ChangeEmailAddressComponent } from "../change-emailaddress/change-emailaddress.component";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { CheckListComponent } from "../check-list/check-list.component";
import { DashboardListViewComponent } from "../dashboard/dashboard-list-view.component";
import { EstimateDetailListViewMoreComponent } from "../estimate-detail/detail-list/detail-list-view-more.component";
import { EstimateDetailContainerComponent } from "../estimate-detail/details/estimate-detail-container.component";
import { EstimateDetailComponent } from "../estimate-detail/estimate-detail.component";
import { EstimatePrintMoreComponent } from "../estimate-detail/estimate-print/estimate-print-view-more.component";
import { EstimateHistoryListViewMoreComponent } from "../estimate-detail/history-list/estimate-history-list-view-more.component";
import { EstimateListContainerComponent } from "../estimate-list/estimate-list-container.component";
import { HistoryNotesListComponent } from "../history-notes-list/containers/history-notes-list.component";
import { InspectionReportListComponent } from "../inspection-report-list/inspection-report-list.component";
import { ManagementFeesDetailComponent } from "../management-fees-detail/details/management-fees-detail.component";
import { ManagementFeeListViewComponent } from "../management-fees-list/containers/management-fees-list-view-component";
import { MergeUsersComponent } from "../merge-users/merge-users.component";
import { OwnerContributionsListComponent } from "../owner-contributions/owner-contributions-list.component";
import { OwnerDistributionListComponent } from "../owner-distribution-list/containers/owner-distribution-list.component";
import { OwnershipListViewComponent } from "../ownerships-list/containers/ownerships-list-view.component";
import { PasswordResetForcedComponent } from "../password-reset/password-reset-forced/password-reset-forced.component";
import { PasswordResetComponent } from "../password-reset/password-reset.component";
import { PrivilegeGuard } from "../privileges/privilege.guard";
import { ReportHubComponent } from "../report-hub/report-hub.component";
import { ReportsListComponent } from "../reports-list/containers/reports-list.component";
import { ResendVerificationComponent } from "../resend-verification/resend-verification.component";
import { ChecklistListViewMoreComponent as IssueChecklistListViewMoreComponent } from "../service-issue-details/checklist-list/checklist-list-view-more.component";
import { ServiceIssueDetailContainerComponent } from "../service-issue-details/details/service-issue-detail-container.component";
import { IssueHistoryViewMoreComponent } from "../service-issue-details/history-list/issue-history-view-more.component";
import { ServiceIssueDetailComponent } from "../service-issue-details/service-issue-detail.component";
import { IssueWorkOrdersViewMoreComponent } from "../service-issue-details/workorder-list/issue-work-order-view-more.component";
import { ServiceIssueListComponent } from "../service-issue-list/service-issue-list.component";
import { SessionGuard } from "../session/session.guard";
import { SignableDocumentsListComponent } from "../signable-documents-list/containers/signable-documents-list.component";
import { SignableDocumentsSignComponent } from "../signable-documents/signable-documents-sign/signable-documents-sign.component";
import { SignableDocumentsViewComponent } from "../signable-documents/signable-documents-view/signable-documents-view.component";
import { SwitchAccountLoadingComponent } from "../top-bar/switch-account-loading/switch-account-loading.component";
import { UpdateAccountInformationComponent } from "../update-account-information/update-account-information.component";
import { UserProfileComponent } from "../user-profile/user-profile.component";
import { VerifyEmailAddressComponent } from "../verify-emailaddress/verify-emailaddress.component";
import { VerifyPendingComponent } from "../verify-pending/verify-pending.component";

const appRoutes: Routes = [
   {
      path: "",
      redirectTo: "/dashboard",
      pathMatch: "full",
   },
   {
      path: "login",
      children: [
         { path: "", component: LoginComponent, data: { title: "Login" } },
         {
            path: "verifyemailaddress",
            component: VerifyEmailAddressComponent,
            data: { title: "Verify EmailAddress" },
         },
         {
            path: "createaccount",
            component: VerifyEmailAddressComponent,
            data: { title: "Verify EmailAddress" },
         },
      ],
   },
   {
      path: "logout",
      component: LoginComponent,
      data: { title: "Logout" },
   },
   {
      path: "dashboard",
      component: DashboardListViewComponent,
      data: { title: "Dashboard" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "serviceissues",
      children: [
         {
            path: "",
            component: ServiceIssueListComponent,
            data: {
               title: "Service Issues",
               requiredPrivileges: [
                  SystemWebPreference.ShowOpenServiceIssuesOWA,
                  SystemWebPreference.ShowClosedServiceIssuesOWA,
               ],
            },
            canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
         },
         {
            path: ":id",
            component: ServiceIssueDetailComponent,
            data: {
               title: "Service Issue Details",
               requiredPrivileges: [
                  SystemWebPreference.ShowOpenServiceIssuesOWA,
                  SystemWebPreference.ShowClosedServiceIssuesOWA,
               ],
            },
            children: [
               {
                  path: "",
                  pathMatch: "full",
                  component: ServiceIssueDetailContainerComponent,
                  data: {
                     requiredPrivileges: [
                        SystemWebPreference.ShowOpenServiceIssuesOWA,
                        SystemWebPreference.ShowClosedServiceIssuesOWA,
                     ],
                  },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
               {
                  path: "checklist",
                  component: IssueChecklistListViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                     requiredPrivileges: [
                        SystemWebPreference.ShowOpenServiceIssuesOWA,
                        SystemWebPreference.ShowClosedServiceIssuesOWA,
                     ],
                  },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
               {
                  path: "history",
                  component: IssueHistoryViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                     requiredPrivileges: [
                        SystemWebPreference.ShowOpenServiceIssuesOWA,
                        SystemWebPreference.ShowClosedServiceIssuesOWA,
                     ],
                  },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
               {
                  path: "workorders",
                  component: IssueWorkOrdersViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                     requiredPrivileges: [SystemWebPreference.ShowWorkOrdersOWA],
                  },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
            ],
         },
      ],
      data: {
         title: "Service Issues",
         requiredPrivileges: [
            SystemWebPreference.ShowOpenServiceIssuesOWA,
            SystemWebPreference.ShowClosedServiceIssuesOWA,
         ],
      },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "serviceissuedetail/id",
      component: ServiceIssueDetailComponent,
      data: {
         title: "Service Issue Detail",
         requiredPrivileges: [
            SystemWebPreference.ShowOpenServiceIssuesOWA,
            SystemWebPreference.ShowClosedServiceIssuesOWA,
         ],
      },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "recovery",
      component: AccountRecoveryComponent,
      data: { title: "Account Recovery" },
   },
   {
      path: "signup",
      component: AccountSignUpComponent,
      data: { title: "Account Signup" },
   },
   {
      path: "switchaccountloading",
      component: SwitchAccountLoadingComponent,
      data: { title: "Switch Account Loading" },
   },
   {
      path: "bills",
      children: [
         {
            path: "",
            component: BillListComponent,
            data: { title: "Bills", requiredPrivileges: [SystemWebPreference.ShowBillsOWA] },
            canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
         },
         {
            path: ":id",
            component: BillDetailComponent,
            data: { title: "Bill Detail", requiredPrivileges: [SystemWebPreference.ShowBillsOWA] },
            children: [
               {
                  path: "",
                  pathMatch: "full",
                  component: BillDetailContainerComponent,
                  data: { requiredPrivileges: [SystemWebPreference.ShowBillsOWA] },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
               {
                  path: "billdetails",
                  component: BillDetailListViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                     requiredPrivileges: [SystemWebPreference.ShowBillsOWA],
                  },
                  canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
               },
            ],
         },
      ],
      data: { title: "Bills", requiredPrivileges: [SystemWebPreference.ShowBillsOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "billdetail/id",
      component: BillDetailComponent,
      data: { title: "Bill Detail", requiredPrivileges: [SystemWebPreference.ShowBillsOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "checks",
      component: CheckListComponent,
      data: { title: "Checks", requiredPrivileges: [SystemWebPreference.ShowChecksOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "distributions",
      component: OwnerDistributionListComponent,
      data: { title: "Owner Distributions", requiredPrivileges: [SystemWebPreference.ShowOwnerChecksOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "ownerships",
      component: OwnershipListViewComponent,
      data: { title: "Ownerships", requiredPrivileges: [SystemWebPreference.ShowOwnershipsOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "managementfees",
      component: ManagementFeeListViewComponent,
      data: { title: "Management Fees", requiredPrivileges: [SystemWebPreference.ShowMgmtFeesOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "managementfeedetails/:id",
      component: ManagementFeesDetailComponent,
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "reports",
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
      data: { requiredPrivileges: [SystemWebPreference.ViewReportsOWA] },
      children: [
         {
            path: "",
            component: ReportsListComponent,
            data: { title: "Reports", requiredPrivileges: [SystemWebPreference.ViewReportsOWA] },
            canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
         },
         {
            path: ":id/run",
            component: ReportHubComponent,
            canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
            data: { requiredPrivileges: [SystemWebPreference.ViewReportsOWA] },
         },
      ],
   },
   {
      path: "ownercontributions",
      component: OwnerContributionsListComponent,
      data: { title: "Make Contributions", requiredPrivileges: [SystemWebPreference.ViewContributionsOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "historynotes",
      component: HistoryNotesListComponent,
      data: { title: "History / Notes", requiredPrivileges: [SystemWebPreference.ViewHistoryNotesOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "signabledocuments",
      component: SignableDocumentsListComponent,
      data: { title: "Documents to Sign" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "signabledocuments/:id/sign",
      component: SignableDocumentsSignComponent,
      data: { title: "Sign Document" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "signabledocuments/:id/view",
      component: SignableDocumentsViewComponent,
      data: { title: "View Document" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "password/reset",
      component: PasswordResetComponent,
      data: { title: "Password Reset" },
   },
   {
      path: "password/reset/forced",
      component: PasswordResetForcedComponent,
      data: { title: "Password Reset" },
   },
   {
      path: "password/verifypending",
      component: VerifyPendingComponent,
      data: { title: "Verify Pending" },
   },
   {
      path: "resendverification",
      component: ResendVerificationComponent,
      data: { title: "Resend Verification" },
   },
   {
      path: "account",
      component: AccountInformationDetailComponent,
      data: { title: "Account Information" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "changeemailaddress",
      component: ChangeEmailAddressViewComponent,
      data: { title: "Change EmailAddress" },
   },
   {
      path: "changeemailaddressLogin",
      component: ChangeEmailAddressComponent,
      data: { title: "Change EmailAddress Login" },
   },
   {
      path: "mergeusers",
      component: MergeUsersComponent,
      data: { title: "Merge Users" },
   },

   {
      path: "mergeusers/accounthasbeenupdated",
      component: AccountUpdatedComponent,
      data: { title: "Account Has been Updated" },
   },
   {
      path: "changepassword",
      component: ChangePasswordComponent,
      data: { title: "Change Password" },
   },
   {
      path: "updateaccountinformation",
      component: UpdateAccountInformationComponent,
      data: { title: "Update Account Information" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "inspectionreports",
      component: InspectionReportListComponent,
      data: { title: "Inspection Reports", requiredPrivileges: [SystemWebPreference.CanSeeWebInspectionReportOWA] },
      canActivate: [SessionGuard.canActivate, PrivilegeGuard.canActivate],
   },
   {
      path: "estimates",
      children: [
         {
            path: "",
            component: EstimateListContainerComponent,
            data: { title: "Estimates" },
            canActivate: [SessionGuard.canActivate],
         },
         {
            path: ":id",
            component: EstimateDetailComponent,
            data: {
               title: "Estimate Details",
            },
            children: [
               {
                  path: "",
                  pathMatch: "full",
                  component: EstimateDetailContainerComponent,
                  canActivate: [SessionGuard.canActivate],
               },
               {
                  path: "estimatedetails",
                  component: EstimateDetailListViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                  },
                  canActivate: [SessionGuard.canActivate],
               },
               {
                  path: "estimatehistorylist",
                  component: EstimateHistoryListViewMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                  },
                  canActivate: [SessionGuard.canActivate],
               },
               {
                  path: "estimateprint",
                  component: EstimatePrintMoreComponent,
                  data: {
                     routeAnimation: slidePanelAnimationIdentifier,
                  },
                  canActivate: [SessionGuard.canActivate],
               },
            ],
         },
      ],
      data: { title: "Estimates" },
      canActivate: [SessionGuard.canActivate],
   },
   {
      path: "userprofile",
      component: UserProfileComponent,
      data: { title: "User Profile" },
      canActivate: [SessionGuard.canActivate],
   },
];

export const OWARoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
