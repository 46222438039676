import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SideMenuService {
   public sideMenuIsVisible: BehaviorSubject<boolean>;

   constructor() {
      this.sideMenuIsVisible = new BehaviorSubject(false);
   }
}
