import { Platform } from "@angular/cdk/platform";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { SubComponentStatusService } from "@lcs/action-context/sub-component-status.service";
import { EntityEventResult } from "@lcs/entity-events/entity-event-result.enum";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { DownloaderService } from "@lcs/files/downloader.service";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { ReportReportParameterViewModel } from "@lcs/reports/report-parameters/models/report-report-parameter.viewmodel";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { SeparatedReportResponse } from "@lcs/reports/separated-report-response.interface";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ResponsiveScreenSize } from "@lcs/viewport/responsive-screen-sizes.enum";
import { WindowService } from "@lcs/viewport/window.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ExportType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/export-type.enum";
import { ReportFieldDataType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-field-data-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { APIReportDownloadModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/apireport-download.model";
import { ExpressMenuItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-menu-item.model";
import { ReportPayloadModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-payload.model";
import { ReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-report-parameter.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { ReportsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/reports.service";
import { Observable, Subject, takeUntil } from "rxjs";

import { MultiPdfViewerModel } from "../pdf-viewer/models/multi-pdf-viewer.model";

@Component({
   selector: "owa-rm-report-hub",
   templateUrl: "./rm-report-hub.component.html",
   providers: [FormRegistrationService, SubComponentStatusService],
})
export class RMReportHubComponent implements OnInit, OnDestroy {
   @Input() set reportID(val: Report) {
      if (val != this._reportID) {
         this._reportID = val;
         this.loadReport();
      }
   }

   @Input() overriddenReportParameterRenderer: TemplateRef<any>;

   @Input() isOWAReport: Boolean;

   @Input() set exportType(val) {
      this._exportType = val;
      this.exportTypeChanged();
   }

   get exportType(): ExportType {
      return this._exportType;
   }

   @Output() reportExportTypesChanged = new EventEmitter<ValueSourceModel>();
   @Output() reRunReportClicked = new EventEmitter();

   report: ReportModel;

   reportParameterString: string;

   reportParameterValues: Array<string>;

   downloadUrl: string;

   isParametersCollapsed: boolean;

   isFileDownload: boolean;

   reportFieldDataType = ReportFieldDataType;

   entityEventResult: EntityEventResult;

   reportMenuHidden: boolean;

   isPdfViewerVisible: boolean;

   reportReportParameters: Array<ReportReportParameterViewModel> = new Array<ReportReportParameterViewModel>();

   reportParameter = new Array<ReportReportParameterModel>();

   reportOutputFormats: Array<ValueSourceModel>;

   menuTitle: string = "Reports";

   menuItems: Array<ExpressMenuItemModel>;

   pdfs: Array<MultiPdfViewerModel>;

   isSeparated: boolean = false;

   errorHeader: string = "Whoops! Your Report Session Has Expired";

   errorInformation: string = "Please re-generate the report in order to view it again";

   errorIcon: string = "rmicon-reports";

   private unsubscribe = new Subject<void>();

   private _reportID: Report;

   private reportParameterDefaultOverrides = new Map<ReportParameter, string>();

   private _exportType: ExportType = ExportType.PDF;

   constructor(
      private apiService: ApiService,
      private reportsService: ReportsService,
      private errorMessageService: ErrorMessageService,
      private reportParametersService: ReportParametersService,
      private apiRequestHelperService: ApiRequestHelperService,
      private downloaderService: DownloaderService,
      private platform: Platform,
      private windowService: WindowService
   ) {}

   ngOnInit() {
      if (this.windowService.responsiveScreenWidth === ResponsiveScreenSize.Small) {
         this.reportMenuHidden = true;
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   fetchData(reportPayloads: Array<ReportPayloadModel>) {
      this.fetchSystemReportData();
   }

   fetchSystemReportData() {
      this.reportsService
         .get(this._reportID, ["ReportParameters", "ReportParameters.ReportParameterValueSource"])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (result) => {
               this.report = result;
               this.applyReportParameterDefaultOverrides(result.ReportParameters);
               if (this.isOWAReport) {
                  if (
                     this.report.ReportID === Report.ProfitLossRecapMonth ||
                     this.report.ReportID === Report.CashFlowRecap
                  ) {
                     result.ReportParameters = result.ReportParameters.filter(
                        (rp) => rp.ReportParameterID !== ReportParameter.LEGALPAPER
                     );
                  }
               }
               const viewParameters: Array<ReportReportParameterViewModel> =
                  new Array<ReportReportParameterViewModel>();
               for (const param of result.ReportParameters) {
                  const viewParam = new ReportReportParameterViewModel();
                  viewParam.fromReportReportParameterModel(param);
                  viewParameters.push(viewParam);
               }
               this.reportReportParameters = viewParameters;
               this.setReportOutputFormats();
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            }
         );
   }

   processReportParameterDefaults(defaultDefinitionString: string) {
      this.reportParameterDefaultOverrides = new Map<ReportParameter, string>();
      const reportParameterDefaults = defaultDefinitionString.split("+");
      for (const reportParameterDefault of reportParameterDefaults) {
         const defaultParts = reportParameterDefault.split(":");
         this.reportParameterDefaultOverrides.set(ReportParameter[defaultParts[0]], defaultParts[1]);
      }
   }

   applyReportParameterDefaultOverrides(reportReportParameters: Array<ReportReportParameterModel>) {
      if (this.reportParameterDefaultOverrides.size === 0) {
         return;
      }
      for (const parameter of reportReportParameters) {
         if (this.reportParameterDefaultOverrides.has(parameter.ReportParameterID)) {
            parameter.ReportParameterValueSource.DefaultValue =
               this.reportParameterDefaultOverrides.get(parameter.ReportParameterID) ?? "";
         }
      }
   }

   processReportParameterValues(): void {
      this.reportParameterValues = this.reportParametersService.processReportParameterValues(
         this.reportReportParameters
      );

      this.reportReportParameters.forEach((reportReportParameterViewModel) => {
         const reportReportParameter = reportReportParameterViewModel.toReportReportParameterModel();
         this.reportParameter.push(reportReportParameter);
      });

      this.reportParameterString = "";

      const getOptions = this.getReportOutputFormatGetOption();

      if (getOptions.length > 0) {
         this.reportParameterString += "?getoptions=" + getOptions.join(",");
      }
   }

   getReportOutputFormatGetOption(): string[] {
      let exportName = ExportType[this.exportType];
      if (this.exportType === ExportType.Excel) {
         exportName = "XLS";
      }
      return [`Return${exportName}Url`];
   }

   setReportOutputFormats() {
      this.reportOutputFormats = new Array<ValueSourceModel>();
      const formatValueSource = new ValueSourceModel();
      formatValueSource.Type = ValueSourceTypes.Static;

      const formats = Array<SelectorItemModel>();
      const pdfFormat = new SelectorItemModel();
      pdfFormat.value = ExportType[ExportType.PDF];
      pdfFormat.displayValue = ExportType[ExportType.PDF];
      formats.push(pdfFormat);

      formatValueSource.DefaultToFirstValueOnReload = true;
      formatValueSource.SelectedValues.push(pdfFormat.value);

      if (!this.report.CantExportCSV) {
         const csvFormat = new SelectorItemModel();
         csvFormat.value = ExportType[ExportType.CSV];
         csvFormat.displayValue = ExportType[ExportType.CSV];
         formats.push(csvFormat);
      }

      if (!this.report.CantExportHtml) {
         const htmlFormat = new SelectorItemModel();
         htmlFormat.value = ExportType[ExportType.HTML];
         htmlFormat.displayValue = ExportType[ExportType.HTML];
         formats.push(htmlFormat);
      }

      if (!this.report.CantExportText) {
         const textFormat = new SelectorItemModel();
         textFormat.value = ExportType[ExportType.Text];
         textFormat.displayValue = ExportType[ExportType.Text];
         formats.push(textFormat);
      }

      if (!this.report.CantExportExcel) {
         const excelFormat = new SelectorItemModel();
         excelFormat.value = "XLS";
         excelFormat.displayValue = ExportType[ExportType.Excel];
         formats.push(excelFormat);
      }

      formats.forEach((value) => formatValueSource.StaticValues.push(value));

      this.reportOutputFormats.push(formatValueSource);

      this.reportExportTypesChanged.emit(formatValueSource);
   }

   generateReport(): Observable<void> {
      const reportGenerated = new Subject<void>();

      // ignore any changes while the report is running
      const isFileDownload: boolean = this.isFileDownload;

      // use timeout to allow report parameters to finish any value updates
      setTimeout(() => {
         this.processReportParameterValues();
         let url: string;
         if (isFileDownload || this.isOWAReport) {
            url = "/reportsview/" + this._reportID + "/RunReport" + this.reportParameterString;
         } else {
            // we use /RunReportSeparate when exporting via PDF, even if the report isn't separable
            // we determine if the report was actually separated by inspecting the API's response
            url = "/reportsview/" + this._reportID + "/RunReportSeparate" + this.reportParameterString;
         }

         this.apiService
            .postCollection(url, this.reportParameterValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (result: SeparatedReportResponse | string) => {
                  if (typeof result === "string") {
                     if (this.isOWAReport && !this.isFileDownload) {
                        this.isSeparated = false;
                        this.downloadUrl = this.convertAndSanitizeReportDownloadUrl(result);
                        this.isPdfViewerVisible = true;
                     } else {
                        const downloadUrl = this.convertAndSanitizeReportDownloadUrl(result);
                        this.downloaderService.downloadFileFromURL(downloadUrl);
                     }
                  } else {
                     if (result.URLs.length === 1) {
                        this.isSeparated = false;
                        this.downloadUrl = this.convertAndSanitizeReportDownloadUrl(result.URLs[0].FileLinks[0].URL);
                     } else {
                        this.isSeparated = true;
                        this.pdfs = result.URLs.map((reportDownload: APIReportDownloadModel) => {
                           const model = new MultiPdfViewerModel();
                           model.Name = reportDownload.Description;
                           model.Token = reportDownload.FileLinks[0].Token;
                           model.DownloadUrl = this.convertAndSanitizeReportDownloadUrl(
                              reportDownload.FileLinks[0].URL
                           );
                           return model;
                        });
                     }
                     this.isPdfViewerVisible = true;
                  }
                  reportGenerated.next();
                  reportGenerated.complete();
               },
               (error) => {
                  this.errorMessageService.triggerHttpErrorMessage(error);
                  reportGenerated.error(null);
                  reportGenerated.complete();
               }
            );
      }, 1000);

      return reportGenerated;
   }

   reRunReport() {
      this.generateReport();
   }

   reRunClicked() {
      this.reRunReportClicked.emit(true);
      this.isPdfViewerVisible = false;
   }
   exportTypeChanged() {
      if (
         this._exportType === ExportType.PDF ||
         this.platform.IOS ||
         (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ) {
         this.isFileDownload = false;
      } else {
         this.isFileDownload = true;
      }
   }

   onMenuItemSelected(menuItem: ExpressMenuItemModel) {
      if (menuItem && menuItem.EntityID !== this._reportID) {
         // this.routingService.routeToAction(ExpressActions.Report_Run, menuItem.EntityID);
      }
   }

   private loadReport() {
      this.isParametersCollapsed = false;
      this.isPdfViewerVisible = false;
      this.fetchData([]);
   }

   private convertAndSanitizeReportDownloadUrl(url: string) {
      url = url + `&report=${this._reportID}`;
      return this.apiRequestHelperService.sanitizeURL(url);
   }
}
