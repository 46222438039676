////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum EntityType {
   NotSet = 0,
   Tenant = 1,
   Prospect = 2,
   Property = 3,
   Unit = 4,
   UnitType = 5,
   Vendor = 6,
   Owner = 7,
   Contact = 8,
   LoanContact = 9,
   User = 10,
   Lease = 11,
   Estimate = 20,
   PO = 21,
   ServiceManagerIssue = 22,
   Loan = 23,
   Journal = 24,
   Invoice = 25,
   Utility = 26,
   Screening = 27,
   Bill = 28,
   CommercialLeaseRenewal = 29,
   NonCommercialLeaseRenewal = 30,
   Bank = 31,
   BankImport = 32,
   GPRPosting = 33,
   Reconcile = 34,
   ManualOwnerPay = 35,
   InventoryItem = 36,
   CreditCardTransaction = 38,
   POWorkFlow = 39,
   MasterPayments = 40,
   ReportBatch = 41,
   MemorizedReport = 42,
   MemorizedEstimate = 43,
   AssetManufacturer = 44,
   CommissionRates = 45,
   SalesBonus = 46,
   Asset = 47,
   ListManagerPriorities = 48,
   ListManagerCategories = 49,
   ListManagerStatuses = 50,
   ReportWriterManager = 51,
   MeterTypes = 52,
   AccountGroups = 53,
   ManagementFeeTemplates = 54,
   OwnerCheckSetups = 55,
   HistNote = 56,
   LeaseRenewal = 57,
   LeadSource = 58,
   ChartAccount = 59,
   Note = 60,
   Letter = 61,
   LetterTemplateType = 62,
   Amenities = 63,
   HistoryCategories = 64,
   Color = 65,
   Subsidies = 66,
   UnitStatusType = 67,
   ImageTypes = 68,
   AddWizard = 69,
   AddWizardType = 70,
   ChargeTypes = 71,
   CustomTab = 72,
   CustomTabType = 73,
   SavedList = 74,
   ListManager = 75,
   GLAccount = 76,
   GLAccountType = 77,
   HistoryTypes = 78,
   Floor = 79,
   Terms = 80,
   Report = 81,
   CustomReport = 82,
   ReportParameter = 83,
   ServiceManagerProject = 84,
   ServiceManagerProjectTemplate = 85,
   UserRole = 86,
   Inspection = 87,
   InspectionTemplate = 88,
   AssetType = 89,
   AssetStatus = 90,
   PhoneBroadcast = 91,
   ChartAccountsMappings = 92,
   Task = 93,
   Appointment = 94,
   Location = 96,
   DocumentPacket = 97,
   Vendor1099Category = 98,
   ServiceManagerRecurringIssue = 99,
   UserDefinedField = 101,
   EntityTypesName = 102,
   RecurringServiceManagerIssue = 103,
   LWASettings = 104,
   PropertyGroup = 105,
   BirdsEyeViewMap = 106,
   SignableDocument = 107,
   LoanDetail = 108,
   MUFileFormats = 109,
   BirdsEyeViewOverlay = 110,
   Check = 111,
   ContactType = 112,
   PositivePayFormat = 113,
   TWASettings = 114,
   OWASettings = 115,
   WebUser = 116,
   ApplicationTemplate = 117,
   Deposit = 118,
   MemorizedServiceManagerIssue = 119,
   Job = 120,
   OwnerProspects = 121,
   VendorCredit = 125,
   eChecks = 126,
   RecurringBill = 127,
   JobType = 128,
   EPayBatch = 129,
   Privilege = 130,
   AddressType = 131,
   SecurityDepositType = 132,
   Filter = 133,
   PDFTemplate = 134,
   AutomatedNotification = 135,
   ARAutomationSchedule = 136,
   Application = 137,
   MemorizedInvoice = 138,
   MemorizedEstimateDetail = 139,
   EpaySetting = 140,
   UserPreference = 141,
   ExpressLayout = 142,
   UserDefinedValue = 143,
   Address = 144,
   Transaction = 145,
   Charge = 146,
   Credit = 147,
   Payment = 148,
   RecurringCharge = 149,
   WebUserAccount = 150,
   ExpressAction = 151,
   MemorizedTasks = 152,
   PhoneNumber = 153,
   EstimateDetail = 154,
   InvoiceDetail = 155,
   ExpressDashboard = 156,
   MarketRent = 157,
   ProspectContactAddress = 158,
   TenantContactAddress = 159,
   ProspectWaitingList = 160,
   UnitTypeProperty = 161,
   Pet = 162,
   UnitStatus = 163,
   Occupancy = 164,
   PreferredAmenity = 165,
   PreferredUnitUserDefinedValue = 166,
   RecurringChargeSummary = 167,
   LetterTemplate = 168,
   ServiceManagerIssueItem = 169,
   ServiceManagerIssueHistory = 170,
   PropertyFee = 171,
   PreferredFloor = 172,
   PreferredUnitType = 173,
   TenantSubsidy = 174,
   PrimaryOwner = 178,
   EpayReturn = 179,
   RecurringJournal = 180,
   SignableDocumentPacket = 181,
   TaxType = 182,
   CreditCard = 183,
   PhoneNumberType = 184,
   SystemPreference = 185,
   OpenReceivable = 186,
   UnitAvailabilityProfile = 187,
   File = 188,
   MakeReadyTemplateItem = 189,
   ServiceManagerIssueTemplate = 190,
   MemorizedServiceManagerIssueItem = 191,
   System = 192,
   Violations = 193,
   WebChatQueue = 194,
   WebChatConversation = 195,
   EpayDepositReconciliation = 196,
   ProspectApplication = 197,
   PetType = 198,
   PetTypeProperty = 199,
   SubsidyPayment = 200,
   TextMessage = 201,
   ChargeEscalation = 202,
   DepreciationSchedule = 203,
   ChargeEscalationDetail = 204,
   LeaseTerm = 205,
   AccountingPeriodSeries = 206,
   AccountingPeriodYear = 207,
   AccountingPeriod = 208,
   ApplicationSubApplicantField = 209,
   ApplicationSubApplicantSetup = 210,
   ProspectSubApplicantDetail = 211,
   JobBudget = 212,
   RMVoIPCallHistory = 213,
   OwnerProspectLeadSource = 214,
   ServiceManagerIssueLock = 215,
   Posting = 216,
   PostingMessage = 217,
   PostingEntity = 218,
   MeterTypeStandard = 219,
   MeterTypeStandardCalculation = 220,
   MeterTypeStandardVariable = 221,
   ServiceManagerIssueCategories = 222,
   ServiceManagerIssueStatuses = 223,
   ServiceManagerIssuePriorities = 224,
   ReportWriterReport = 225,
   ReportWriterReportUser = 226,
   ReportWriterReportRole = 227,
   ReportWriterReportParameter = 228,
   AccountGroupPayment = 229,
   MakeReadyTemplate = 230,
   MakeReadyTemplateProperties = 231,
   IncomingText = 232,
   OutgoingText = 233,
   MMSImage = 234,
   BillDetail = 235,
   NDTBroadcastBatch = 236,
   PropertyBank = 237,
   PropertyRentChargeType = 238,
   UserProperty = 239,
   EpayTrans = 240,
   MakeReadyPosts = 241,
   MakeReadyPostItems = 242,
   MakeReadyActions = 243,
   MakeReadyTemplates = 244,
   InspectionType = 245,
   InspectionStatus = 246,
   DocumentPacketUser = 247,
   DocumentPacketRole = 248,
   DocumentPacketProperty = 249,
   DocumentPacketDocument = 250,
   ChartAccountsMappingLink = 251,
   ChartAccountsMappedAccount = 252,
   TextNumber = 253,
   TextNumberProperty = 254,
   CommercialRecoverableExpense = 255,
   UnitLicenseHistory = 256,
   Account = 257,
   ServiceManagerIssueLinkedTenant = 258,
   ServiceManagerMemorizedIssueLinkedTenant = 259,
   ServiceManagerRecurringIssueLinkedTenant = 260,
   ServiceManagerMemorizedIssueLinkedUnit = 261,
   ServiceManagerRecurringIssueLinkedUnit = 262,
   ServiceManagerMemorizedIssueLinkedProperty = 263,
   ServiceManagerRecurringIssueLinkedProperty = 264,
   ServiceManagerMemorizedIssueLinkedProspect = 265,
   ServiceManagerRecurringIssueLinkedProspect = 266,
   CheckScanner = 267,
   CheckScanBatch = 268,
   CheckScanPayment = 269,
   CheckScanPaymentChargeAllocation = 270,
   CheckScanPaymentChargePreallocation = 271,
   OwnerContract = 272,
   MemorizedInvoiceDetail = 273,
   PurchaseOrderDetail = 274,
   PropertyAvailabilityProfile = 275,
   UnitAvailabilityProfileApiField = 276,
   PropertyAvailabilityProfileApiField = 277,
   InventoryItemIncrease = 278,
   InventoryItemDecrease = 279,
   InventoryItemHistory = 280,
   PurchaseOrderAssignHistory = 281,
   NachaODFIBank = 282,
   PurchaseOrderWorkFlow = 283,
   PurchaseOrderWorkFlowUser = 284,
   Vendors1099 = 285,
   SystemSetting = 286,
   ManagementFeeHistory = 287,
   ManagementFeeHistoryTransaction = 288,
   ProspectFloor = 289,
   PropertyFloor = 290,
   AssetWorkflow = 291,
   CheckDetail = 292,
   ServiceManagerSavedList = 293,
   VendorProperty = 294,
   WebChatConversationItem = 295,
   ReportWriterReportColumn = 296,
   ReportWriterReportColumnFilter = 297,
   HistoryAttachment = 299,
   EntityLogo = 300,
   LoanTransaction = 301,
   OwnerCheckHistory = 302,
   ServiceManagerIssueChecklistItems = 303,
   OwnerGroup = 304,
   PropertyFileFolder = 305,
   EpayPayment = 306,
   EpayAccountGroupPayment = 307,
   AmenityPricing = 308,
   ReportSortOption = 309,
   AssetLocationHistory = 310,
   Marketing = 311,
   MeterReading = 312,
   OwnershipTransfer = 313,
   SharedReportBatch = 314,
   ProspectStage = 315,
   ProspectStageHistory = 316,
   ProspectLostReason = 317,
   NDTBroadcastBatchDetail = 318,
   NDTTextBroadcast = 319,
   NDTPhoneBroadcast = 320,
   AssetTitleStatus = 321,
   RetailSale = 322,
   ChargeTypeSetup = 323,
   CommercialRecoverableExpenseSetup = 324,
   WebUserAccountAutomaticPaymentSetting = 325,
   HistoryCall = 326,
   HistoryVisit = 327,
   HistoryEmail = 328,
   FollowUpTask = 329,
   ServiceManagerIssueLinkedProspect = 330,
   ServiceManagerIssueLinkedUnit = 331,
   ServiceManagerIssueLinkedProperty = 332,
   HistorySystemNote = 333,
   GeneratedLeaseRenewal = 334,
   GeneratedRecurringCharge = 335,
   AssetImages = 337,
   Signature = 338,
   ManagementFeeSetupModel = 339,
   ViolationCodeGroupEntity = 340,
   ViolationCodeGroup = 341,
   ViolationCode = 342,
   ViolationCodeGroupStage = 343,
   ViolationCodeStage = 344,
   ViolationStage = 345,
   ViolationStageAction = 346,
   HistoryViolationNote = 347,
   RecurringBillDetail = 348,
   Poll = 349,
   PollQuestion = 350,
   PollResponse = 352,
   BoardMemberTerm = 353,
   HistoryViolationStageRevertNote = 354,
   PollAnswer = 355,
   ViolationCategory = 356,
   PayBills = 357,
   BankRegister = 358,
   LateFeeSchedule = 359,
   LoanLateFeeSchedule = 360,
   LoanSchedule = 361,
   GPRSchedule = 362,
   ManagementFeeSchedule = 363,
   RecurringChargesSchedule = 364,
   RecurringEpaySchedule = 365,
   UtilitiesSchedule = 366,
   CreditCardReconciliation = 367,
   AvidPay = 368,
   NACHA = 369,
   AvidPayPayment = 370,
   NachaOwnerBank = 371,
   MemorizedJournal = 372,
   MemorizedJournalDetail = 373,
   CreditCardRegister = 374,
   AssetWorkflowState = 375,
   AssetWorkflowNextState = 376,
   AutomatedNotificationTypeGroup = 377,
   AutomatedNotificationSetup = 378,
   VendorCreditRegister = 379,
   ReservableAmenity = 380,
   AutomatedNotificationGuestCardTemplate = 381,
   GuestCardTemplate = 382,
   AutomatedNotificationProspectStage = 383,
   AutomatedNotificationSetupRecipient = 384,
   ManagementFeeSetupChargeType = 385,
   ReconciliationChange = 387,
   AssetStatusHistory = 388,
   CommunityEvent = 389,
   ARAutomationScheduleDetail = 390,
   Budget = 391,
   AmenityReservationRequest = 392,
   Role = 393,
   ReconcileJournal = 394,
   Micr = 395,
   MicrSetUp = 396,
   UserSignature = 397,
   ProspectUserDefinedFieldLink = 398,
   OwnerProspectUserDefinedFieldLink = 399,
   PostRecurringBill = 400,
   ArchitecturalRequest = 401,
   AmenityReservationRequestStatus = 402,
   ReservableAmenityAvailabilitySchedule = 404,
   ReservableAmenityAvailability = 405,
   ReservableAmenityUnavailabilitySchedule = 406,
   ConsumerPriceIndex = 407,
   MemorizedComment = 408,
   Audit = 409,
   GPRPropertyPostingHistory = 410,
   Reservation = 411,
   AvidInvoice = 413,
   SentEmailFolder = 414,
   SentEmail = 415,
   SentEmailAttachment = 416,
   Committee = 417,
   CommitteeMember = 418,
   PropertyManagementFeeSetup = 419,
   BatchPayment = 420,
   UnitDefaultSecurityDeposits = 421,
   AllocationOrder = 422,
   ImportTemplate = 423,
   Vendor1099Adjustment = 424,
   OwnerGroupDetail = 425,
   ChargeTypeBank = 426,
   OwnerGroupFilter = 427,
   AccountGroupDetail = 428,
   Holiday = 429,
   GPRAutomationSchedule = 430,
   PropertyGroupFilter = 431,
   ForecastModel = 432,
   ForecastModelChargeType = 433,
   PropertyGroupFilterDetail = 434,
   ForecastModelGLAccountTypePercentage = 435,
   ForecastModelGLAccountPercentage = 436,
   TenantBill = 437,
   MarketingField = 439,
   UtilityMeterReadingHistory = 440,
   UtilityMeter = 441,
   VirtualPostOfficeJob = 442,
   LoanOtherCharge = 443,
   LoanAutomationSchedule = 444,
   OwnerGroupFilterDetail = 445,
   LateFee = 446,
   JournalDetail = 447,
   PositivePayFormatDetail = 448,
   UserTokens = 449,
   SignablePDFTemplate = 450,
   EmailSentItemRecipient = 451,
   ProspectApplicationMajorSection = 452,
   WebChatQueueAction = 453,
   WebChatQueueCustomReply = 454,
   ProspectApplicationMinorSection = 455,
   WebChatQueueCustomQuestionLink = 456,
   Reminder = 457,
   TaskUser = 458,
   BirdsEyeViewMapProperty = 459,
   Allocation = 460,
   BirdsEyeViewMapUser = 461,
   UtilityReadingsFileFormatField = 462,
   UtilityReadingsFileFormat = 463,
   Vehicle = 464,
   BirdsEyeViewHoverScriptLineItem = 465,
   BirdsEyeViewMapUserSetting = 466,
   BirdsEyeViewMapShape = 467,
   BirdsEyeViewMapShapeCoordinate = 468,
   SystemWebPreference = 469,
   RevenuePricing = 470,
   RevenueRenewal = 471,
   UserPropertyGroup = 472,
   WebPageCustomization = 473,
   Images = 474,
   EPayBank = 475,
   ReportBatchReport = 477,
   ReportBatchReportSavedReportParameterValue = 478,
   Area = 479,
   AreaItem = 480,
   ApplicationDetail = 481,
   DepositDetail = 482,
   UserBank = 483,
   LetterTemplateFilter = 484,
   CheckScannerUser = 485,
   ServiceManagerTechTime = 486,
   ServiceManagerIssueLayout = 488,
   ServiceManagerIssueLayoutSection = 489,
   ServiceManagerIssueLayoutUserDefinedField = 490,
   ServiceManagerIssueLayoutUser = 491,
   DefaultChargeSettingExcludedChargeType = 492,
   ApplicationSettings = 493,
   ApplicationFee = 494,
   WebhookDetail = 495,
   DefaultChargeSetupPet = 496,
   CheckListTemplate = 497,
   CheckInRegister = 498,
   AreaItemStatus = 499,
   CustomForm = 500,
   AccountStatement = 501,
   ApplicationDesign = 502,
   ApplicationField = 503,
   ApplicationFieldLeadSource = 504,
   DefaultChargeSetting = 505,
   DefaultChargeSetup = 506,
   ModifyRecurringCharges = 507,
   LetterTemplateRole = 508,
   EpayChargeType = 509,
   EpayInformation = 510,
   UnitAmenity = 511,
   ManagementFeeAdjustment = 512,
   UtilityProperty = 513,
   GeneratedLeaseRenewalDocumentPacket = 514,
   MeterTypePlusCalculation = 515,
   MeterTypePlus = 516,
   MeterTypePlusRate = 517,
   MeterTypePlusRateVariable = 518,
   MeterTypePlusStatementField = 519,
   RMResidentPushNotificationsToken = 520,
   Rate = 521,
   RateFee = 522,
   RateSetting = 523,
   RateSchedule = 524,
   RateDiscount = 525,
   SiteGroup = 526,
   RateDetail = 527,
   RenterInsurancePolicy = 528,
   RenterInsurancePolicyAdditionalInsured = 529,
   RateAdditionalFee = 530,
   ModifyMarketRent = 532,
   WebMessageBoardPost = 534,
   ServiceManagerIssueLayoutLinkedField = 535,
   RateTax = 536,
   HistoryCategoryType = 537,
   PropertyFile = 538,
   SignableDocumentSignatureBlockGroup = 540,
   UtilityMeterReading = 541,
   ScreeningDetail = 542,
   ScreeningResponse = 543,
   ScreeningResponseFactor = 544,
   SecurityDepositInterest = 545,
   AreaPredefinedItem = 546,
   AreaPredefinedItemStatus = 547,
   TenantProspect = 549,
   InspectionArea = 550,
   InspectionAreaItem = 551,
   InspectionAreaItemAttachment = 552,
   RentersInsurancePolicyType = 553,
   PropertyAmenity = 554,
   LeaseTrackTransaction = 555,
   ReservationLeadSource = 556,
   ReservationTransaction = 557,
   ShortTermReservation = 558,
   ShortTermRentalPaymentPolicy = 559,
   ShortTermRentalPaymentPolicyRule = 560,
   PaymentBatch = 561,
   PaymentBatchAllocation = 563,
   PaymentBatchPreallocation = 564,
   UnitProhibitedRVType = 565,
   LetterTemplateFolder = 566,
   PlaidItem = 567,
   PlaidItemAccount = 568,
   Floorplan = 569,
   EmailChain = 570,
   EmailSentItem = 571,
   SiteType = 572,
   CommunityEventType = 573,
   VendorContact = 574,
   InspectionVideoFileStatus = 577,
   InspectionVideoFile = 578,
   ArchitecturalRequestForm = 579,
   GoogleAccount = 580,
   ArchitecturalRequestFormSection = 581,
   ArchitecturalRequestFormField = 582,
   ArchitecturalRequestDetail = 583,
   PublishedTWAReport = 584,
   TWAReportTemplate = 585,
   TWAReportPropertySettings = 586,
   TenantStatementAutomation = 589,
   AmenityReservationFee = 590,
   Unassociated = -1,
}
