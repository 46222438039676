import { Directive, Host, Input, OnChanges, OnDestroy, OnInit, Self, SimpleChanges } from "@angular/core";
import { EntitySelectorDirective } from "@lcs/entity-selector/entity-selector.directive";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FORM_CONTROL_METADATA_ENTITY_ID, FormControlMetadataEntityID } from "@lcs/forms/form-control-metadata/form-control-metadata-entity-id.interface";
import { FORM_CONTROL_METADATA_SELECTORFILTERS, FormControlMetadataSelectorFilters } from "@lcs/forms/form-control-metadata/form-control-metadata-selector-filters.interface";
import { SelectComponent } from "@lcs/select/components/select.component";
import { EndpointSelectorDirectiveBase } from "@lcs/select/directives/endpoint-selector.directive.base";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntitySearchConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration.model";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { UserFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/user.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { SelectorBehavior } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/selector-behavior.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { PropertiesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/properties.service";
import { ServiceManagerMemorizedIssuesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/service-manager-memorized-issues.service";
import { ServiceManagerRecurringIssuesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/service-manager-recurring-issues.service";
import { ServiceManagerIssuesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/service-manager-issues.service";
import { forkJoin, of, take, takeUntil } from "rxjs";

import { ContextService } from "../../action-context/context.service";
import { ConstantsService } from "../../core/constants.service";
import { EntityChangeService } from "../../entity-events/entity-change.service";
import { ObjectMapResolverService } from "../../pipes/object-map-resolver.service";

/* eslint-disable brace-style */
@Directive({
   selector: "[lcsAssignedToUserSelector]",
   providers: [
      {
         provide: FORM_CONTROL_METADATA_ENTITY_ID,
         useExisting: EntitySelectorDirective,
      },
      {
         provide: FORM_CONTROL_METADATA_SELECTORFILTERS,
         useExisting: EntitySelectorDirective,
      },
   ],
})
export class AssignedToUserSelectorDirective
   extends EndpointSelectorDirectiveBase
   implements OnInit, OnDestroy, OnChanges, FormControlMetadataEntityID, FormControlMetadataSelectorFilters
{
   @Input() entityID: number;

   @Input() issueEntityType: EntityType;

   @Input() isServiceIssueCheckList: boolean = false;

   @Input() filters: Array<FilterOption>;

   private filtersFromMetadata: Array<FilterOption>;

   private userInformation: EntityViewInformationModel;

   private userSearchConfiguration: EntitySearchConfigurationModel;

   constructor(
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private serviceManagerIssuesService: ServiceManagerIssuesService,
      private serviceManagerRecurringIssuesService: ServiceManagerRecurringIssuesService,
      private serviceManagerMemorizedIssuesService: ServiceManagerMemorizedIssuesService,
      private entityRequestsService: EntityRequestService,
      private contextService: ContextService,
      private entityChangeService: EntityChangeService,
      private propertiesService: PropertiesService,
      protected errorMessageService: ErrorMessageService,
      protected objectMapResolverService: ObjectMapResolverService,
      protected valueSourceService: ValueSourceService,
      protected apiService: ApiService,
      @Host() @Self() protected selectComponent: SelectComponent
   ) {
      super(errorMessageService, objectMapResolverService, valueSourceService, apiService, selectComponent);

      this.entityChangeService.entityChange.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
         if (event.entityType === this.issueEntityType && event.entityID === this.selectComponent.value) {
            this.refreshSelectedItemSubject.next(true);
         }
      });
   }

   ngOnInit(): void {
      super.ngOnInit();
   }

   ngOnDestroy(): void {
      super.ngOnDestroy();
   }

   ngOnChanges(changes: SimpleChanges): void {
      const filtersChanged = changes.filters && changes.filters.currentValue !== changes.filters.previousValue;
      const filterExpressionChanged =
         changes.filterExpression && changes.filterExpression.currentValue !== changes.filterExpression.previousValue;
      if (filtersChanged || filterExpressionChanged) {
         if (filtersChanged) {
            this.setFilters(changes.filters.currentValue);
         } else {
            this.setEndpointSelectorBaseValues();
         }
      }

      if (
         (changes.issueEntityType && changes.issueEntityType.currentValue !== changes.issueEntityType.previousValue) ||
         (changes.parentEntityType &&
            changes.parentEntityType.currentValue !== changes.parentEntityType.previousValue) ||
         (changes.entityID && changes.entityID.currentValue !== changes.entityID.previousValue)
      ) {
         this.setEndpointSelectorBaseValues();
      }
   }

   setEntityID(entityID: number) {
      this.entityID = entityID;
      this.setEndpointSelectorBaseValues();
   }

   setFilters(filters: Array<FilterOption>) {
      this.filtersFromMetadata = filters;
      this.setEndpointSelectorBaseValues();
   }

   private getEndpoint() {
      if (!this.entityID) {
         this.entityID = this.contextService.parentEntityID
            ? this.contextService.parentEntityID
            : ConstantsService.NullFK;
      }
      switch (this.issueEntityType) {
         case EntityType.ServiceManagerIssue:
            return this.serviceManagerIssuesService.getUsersThatCanBeAssignedToServiceIssueCollectionUrl(this.entityID);
         case EntityType.ServiceManagerRecurringIssue:
            return this.serviceManagerRecurringIssuesService.getUsersThatCanBeAssignedToRecurringServiceIssueCollectionUrl(
               this.entityID
            );
         case EntityType.MemorizedServiceManagerIssue:
            return this.serviceManagerMemorizedIssuesService.getUsersThatCanBeAssignedToMemorizedServiceIssueCollectionUrl(
               this.entityID
            );
         case EntityType.Property:
            if (
               this.filters.find(
                  (filterOption: FilterOption) => filterOption.FilterName === UserFields.IsInspector.Identifier
               )
            ) {
               this._staticValues = [new SelectorItemModel(-1, "<Unassigned>")];
            } else {
               this._staticValues = [
                  new SelectorItemModel(-1, "<Unassigned>"),
                  new SelectorItemModel(0, "<System Default>"),
               ];
            }
            return this.propertiesService.getUsersCollectionUrl(this.entityID);
      }
   }

   private getUserInformation() {
      if (this.userInformation) {
         return of(this.userInformation);
      } else {
         return this.entityViewInformationService.getViewInformation(EntityType.User);
      }
   }

   private getUserSearchConfiguration() {
      if (this.userSearchConfiguration) {
         return of(this.userSearchConfiguration);
      } else {
         return this.entitySearchConfigurationService.getSearchConfiguration(EntityType.User);
      }
   }

   private setEndpointSelectorBaseValues() {
      forkJoin([this.getUserInformation(), this.getUserSearchConfiguration()])
         .pipe(take(1))
         .subscribe(
            ([viewInformation, searchConfiguration]: [EntityViewInformationModel, EntitySearchConfigurationModel]) => {
               this._displayValueSourcePath = viewInformation.EntityDisplayTemplate;
               if (!this.isServiceIssueCheckList) {
                  this.entityRequestsService
                     .getEntityEndpoint(EntityType.User)
                     .pipe(take(1))
                     .subscribe((endpoint) => {
                        this._itemEndpoint = endpoint;
                     });
               }
               this._endpoint = this.getEndpoint() ?? "";
               if (viewInformation.DefaultSelectorBehavior === SelectorBehavior.All) {
                  this._loadAllItems = true;
               }
               this._fields = [UserFields.UserID, UserFields.Name, UserFields.Username];
               this._orderingOptions = [searchConfiguration.DefaultOrderingOption.Name];
               this._searchFields = searchConfiguration.DefaultSearchFields;
               this._additionalInfoSourcePath = UserFields.Username;
               this._primaryKeySourcePath = viewInformation.EntityPrimaryKeyPropertyPath;
               if (this.isServiceIssueCheckList) {
                  this._valueSourcePath = ObjectMapResolverService.rootPath;
                  this._valueComparer = (v1: UserModel, v2: UserModel) => {
                     return v1.UserID === v2.UserID;
                  };
               } else {
                  this._valueSourcePath = viewInformation.EntityPrimaryKeyPropertyPath;
               }

               let filters: Array<FilterOption> | null = [];
               if (this.filtersFromMetadata) {
                  filters = FilterOption.Merge(filters, this.filtersFromMetadata);
               }
               if (this.filters) {
                  filters = FilterOption.Merge(filters ?? new Array<FilterOption>(), this.filters);
               }

               this._filters = filters ?? new Array<FilterOption>();
            }
         );
   }
}
