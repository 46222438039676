import { ReportParameterValueModel } from "./report-parameter-value.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class RunReportModel {
   CustomFormID: number;
   MetaTag?: string;
   Parameters: Array<ReportParameterValueModel>;
}
