import { Platform } from "@angular/cdk/platform";
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subject } from "rxjs";

@Component({
   selector: "lcs-pdf-viewer",
   templateUrl: "pdf-viewer.component.html",
})
export class PdfViewerComponent implements OnInit, OnDestroy {
   safeUrl: SafeResourceUrl;
   mobilePlatform: boolean;

   @Input() set downloadUrl(value: string) {
      this._downloadUrl = value;
      this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this._downloadUrl);
   }

   get downloadUrl() {
      return this._downloadUrl;
   }

   @Input() errorHeader: string = "Error";

   @Input() errorInformation: string = "An error has occurred";

   @Input() errorIcon: string;

   @Input() errorContentTemplate: TemplateRef<any>;

   @Input() iframeCustomStyle = {};

   @ViewChild("pdfAnchor") pdfAnchor: ElementRef;

   private _downloadUrl: string;

   private unsubscribe = new Subject<void>();

   constructor(private domSanitizer: DomSanitizer, private platform: Platform, private renderer: Renderer2) {}

   ngOnInit() {
      this.initializeComponent();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private initializeComponent() {
      this.determinePlatform();

      setTimeout(() => {
         if (this.mobilePlatform && this.pdfAnchor) {
            this.initializeDocumentLinkProperties();
         }
      });
   }

   private initializeDocumentLinkProperties() {
      const anchor = this.pdfAnchor.nativeElement;

      if (!this.platform.IOS) {
         this.renderer.setProperty(anchor, "download", "");
      } else {
         this.renderer.setProperty(anchor, "target", "_blank");
         this.renderer.setProperty(anchor, "rel", "noopener");
      }
   }

   private determinePlatform() {
      if (this.platform.IOS || this.platform.ANDROID) {
         this.mobilePlatform = true;
      } else {
         this.mobilePlatform = false;
      }
   }
}
