export enum AuthenticationStep {
   NotStarted = 0,
   LoggingOut = 1,
   Authenticating = 2,
   ReauthenticationNeeded = 3,
   AuthenticationSuccessful = 4,
   CheckingSession = 5,
   SessionCheckSuccessful = 6,
   LoadingCachesSuccessful = 7,
   AuthenticationComplete = 8,
   AuthenticationFailed = 9,
}
