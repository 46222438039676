/* eslint-disable prefer-const */
import { Injectable, NgZone, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { ResponsiveScreenSizeBreak } from "@lcs/viewport/responsive-screen-size-break.enum";
import { BehaviorSubject, distinctUntilChanged, fromEvent, map, Subject, takeUntil, takeWhile } from "rxjs";

import { ImmediateAuditor } from "./immediate-auditor.extension";
import { ResponsiveScreenSize } from "./responsive-screen-sizes.enum";

@Injectable({
   providedIn: "root",
})
export class WindowService implements OnDestroy {
   static immediateAuditTime = 300;

   responsiveScreenWidth: ResponsiveScreenSize;
   responsiveScreenHeight: ResponsiveScreenSize;
   height = new BehaviorSubject<number>(window.innerHeight);
   width = new BehaviorSubject<number>(window.innerWidth);
   responsiveScreenWidthSubject: BehaviorSubject<ResponsiveScreenSize>;
   responsiveScreenHeightSubject: BehaviorSubject<ResponsiveScreenSize>;
   resized = new Subject<void>();

   scrolled = new Subject<void>();

   get scrollbarWidth(): number {
      if (this._scrollbarWidth === undefined) {
         this._scrollbarWidth = this.measureScrollbarWidth();
      }
      return this._scrollbarWidth;
   }

   private unsubscribe = new Subject<void>();
   private mainContentElementScrollTop = 0;
   private windowScrollTop = 0;

   private componentScrollTops: Map<string, number> = new Map<string, number>();
   private lastComponentID = 0;

   private _scrollbarWidth: number;

   constructor(private ngZone: NgZone, private rendererFactory: RendererFactory2) {
      this.responsiveScreenWidthSubject = new BehaviorSubject<ResponsiveScreenSize>(this.responsiveScreenWidth);
      this.responsiveScreenHeightSubject = new BehaviorSubject<ResponsiveScreenSize>(this.responsiveScreenHeight);
      this.ngZone.runOutsideAngular(() => {
         const resizeEvent = fromEvent(window, "resize").pipe(takeUntil(this.unsubscribe));
         new ImmediateAuditor(resizeEvent, WindowService.immediateAuditTime).observable
            .pipe(
               map(() => [window.innerWidth, window.innerHeight]),
               distinctUntilChanged((oldValue, newValue) => {
                  if (oldValue[0] !== newValue[0]) {
                     return false;
                  }
                  if (oldValue[1] !== newValue[1]) {
                     return false;
                  }
                  return true;
               })
            )
            .subscribe(() => {
               this.ngZone.run(() => {
                  this.updateWindowDimensions();
                  this.resized.next();
               });
            });
      });
      this.updateWindowDimensions();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   measureScrollbarWidth(): number {
      const renderer2: Renderer2 = this.rendererFactory.createRenderer(null, null);
      const div: HTMLElement = renderer2.createElement("div");
      renderer2.setStyle(div, "width", "100px");
      renderer2.setStyle(div, "overflow", "scroll");
      renderer2.setStyle(div, "visibility", "hidden");
      renderer2.appendChild(document.body, div);
      const scrollbarWidth = div.offsetWidth - div.clientWidth;
      renderer2.removeChild(document.body, div);

      return scrollbarWidth;
   }

   public setResponsiveScreenWidth(width: number) {
      if (width > ResponsiveScreenSizeBreak.MediumWidth) {
         this.responsiveScreenWidth = ResponsiveScreenSize.Large;
      } else if (width <= ResponsiveScreenSizeBreak.SmallWidth) {
         this.responsiveScreenWidth = ResponsiveScreenSize.Small;
      } else {
         this.responsiveScreenWidth = ResponsiveScreenSize.Medium;
      }
      if (this.responsiveScreenWidthSubject.getValue() !== this.responsiveScreenWidth) {
         this.responsiveScreenWidthSubject.next(this.responsiveScreenWidth);
      }
   }

   public setResponsiveScreenHeight(height: number) {
      if (height > ResponsiveScreenSizeBreak.MediumHeight) {
         this.responsiveScreenHeight = ResponsiveScreenSize.Large;
      } else if (height <= ResponsiveScreenSizeBreak.SmallHeight) {
         this.responsiveScreenHeight = ResponsiveScreenSize.Small;
      } else {
         this.responsiveScreenHeight = ResponsiveScreenSize.Medium;
      }
      if (this.responsiveScreenHeightSubject.getValue() !== this.responsiveScreenHeight) {
         this.responsiveScreenHeightSubject.next(this.responsiveScreenHeight);
      }
   }

   public initializeScrollEvents(mainContentElement: Element) {
      this.ngZone.runOutsideAngular(() => {
         const contentScrollEvent = fromEvent(mainContentElement, "scroll");
         new ImmediateAuditor(contentScrollEvent, WindowService.immediateAuditTime).observable
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
               this.ngZone.run(() => {
                  if (mainContentElement.scrollTop !== this.mainContentElementScrollTop) {
                     this.mainContentElementScrollTop = mainContentElement.scrollTop;
                     this.scrolled.next();
                  }
               });
            });

         const windowScrollEvent = fromEvent(window, "scroll");
         new ImmediateAuditor(windowScrollEvent, WindowService.immediateAuditTime).observable
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
               this.ngZone.run(() => {
                  if (document.documentElement.scrollTop !== this.windowScrollTop) {
                     this.windowScrollTop = document.documentElement.scrollTop;
                     this.scrolled.next();
                  }
               });
            });
      });
   }

   public registerScrollableComponent(contentElement: Element): string {
      if (contentElement) {
         this.lastComponentID++;
         const componentID = this.lastComponentID.toString();
         this.componentScrollTops.set(componentID, contentElement.scrollTop);

         this.ngZone.runOutsideAngular(() => {
            const contentScrollEvent = fromEvent(contentElement, "scroll").pipe(
               takeWhile(() => this.componentScrollTops.has(componentID)),
               takeUntil(this.unsubscribe)
            );
            new ImmediateAuditor(contentScrollEvent, 300).observable.subscribe(() => {
               this.ngZone.run(() => {
                  if (contentElement.scrollTop !== this.componentScrollTops.get(componentID)) {
                     this.componentScrollTops.set(componentID, contentElement.scrollTop);
                     this.scrolled.next();
                  }
               });
            });
         });
         return componentID;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
         return null;
      }
   }

   public deregisterScrollableComponent(scrollableComponentID: string) {
      if (scrollableComponentID != null && this.componentScrollTops.has(scrollableComponentID)) {
         this.componentScrollTops.delete(scrollableComponentID);
      }
   }

   private updateWindowDimensions() {
      const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;
      this.width.next(viewportWidth);
      this.height.next(viewportHeight);
      this.setResponsiveScreenWidth(viewportWidth);
      this.setResponsiveScreenHeight(viewportHeight);
   }

   static isElementCompletelyInViewport<T extends HTMLElement>(element: T): boolean {
      const elementRect: DOMRect = element.getBoundingClientRect();
      const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;
      return (
         elementRect.top >= 0 &&
         elementRect.left >= 0 &&
         elementRect.bottom <= viewportHeight &&
         elementRect.right <= viewportWidth
      );
   }

   static isElementPartiallyInViewport<T extends HTMLElement>(element: T): boolean {
      const elementRect: DOMRect = element.getBoundingClientRect();
      const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;
      return (
         (elementRect.top >= 0 &&
            elementRect.top <= viewportHeight &&
            elementRect.left >= 0 &&
            elementRect.left <= viewportWidth) ||
         (elementRect.bottom > 0 &&
            elementRect.bottom < viewportHeight &&
            elementRect.right > 0 &&
            elementRect.right < viewportWidth)
      );
   }

   static isElementInVerticalViewInContainer<T extends HTMLElement>(
      element: T,
      container: T,
      partial: boolean
   ): boolean {
      let result: boolean = false;
      //Get container properties
      let cTop = container.scrollTop;
      let cBottom = cTop + container.clientHeight;

      //Get element properties
      let eTop = element.offsetTop;
      let eBottom = eTop + element.clientHeight;

      //Check if in view
      let isTotal = eTop >= cTop && eBottom <= cBottom;
      let isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

      //Return outcome
      result = isTotal || isPartial;
      return result;
   }

   /**
    * This static method scrolls an element into view within a container without scrolling the entire page.
    *
    * For example if you want to ensure the selected item in a list is visible within the list container
    * without scrolling the entire page to make the item visible in the viewport.
    *
    * @param element - current element
    * @param container - container element
    * @param topOffset - pixel offset from top of container to scrollable region (use if scrollable container has a fixed header)
    * @param bottomOffset - pixel offset from bottom of container to scrollable region (use if scrollable container has a fixed footer)
    */
   static ensureElementInVerticalViewInContainer<T extends HTMLElement>(
      element: T,
      container: T,
      topOffset: number = 0,
      bottomOffset: number = 0
   ): void {
      //Determine container top and bottom
      let cTop = container.scrollTop;
      let cBottom = cTop + container.clientHeight;

      //Determine element top and bottom
      let eTop = element.offsetTop;
      let eBottom = eTop + element.clientHeight;

      //Check if out of view
      if (eTop < cTop + topOffset) {
         container.scrollTop -= cTop + topOffset - eTop;
      } else if (eBottom > cBottom - bottomOffset) {
         container.scrollTop += eBottom - (cBottom - bottomOffset);
      }
   }
}
