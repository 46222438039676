import { Pipe, PipeTransform } from "@angular/core";

import { ObjectMapResolverService } from "./object-map-resolver.service";

@Pipe({
   name: "objectMapResolver",
   pure: false,
})
export class ObjectMapResolverPipe implements PipeTransform {
   constructor() {}

   transform(obj: any, path: string): any {
      return ObjectMapResolverService.getPropertyValue(obj, path);
   }
}
