import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable } from "rxjs";

import { OwnerDistributionViewModel } from "../models/owner-distribution-view.model";
import { OwnerDistributionModel } from "../models/owner-distribution.model";

@Injectable({ providedIn: "root" })
export class OwnerDistributionsService {
   readonly endpoint = "OwnerDistributions";

   constructor(private apiService: ApiService) {}

   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/Get";
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   getCollection(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<OwnerDistributionModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCollectionResponse(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getCollectionResponse(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnerDistributionsForDashboard(): Observable<Array<OwnerDistributionViewModel>> {
      const endpoint = this.endpoint + "/GetOwnerDistributionsForDashboard";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }
}
