<label *ngIf="!isRelative">
   {{ label }}
</label>
<lcs-date-range-picker
   *ngIf="!isRelative"
   [name]="name"
   [displayName]="label"
   [disabled]="disabled"
   [dateStartFormat]="dateStartFormat"
   [dateEndFormat]="dateEndFormat"
   [standalone]="true"
   [(ngModel)]="dateRange"
   (ngModelChange)="valueChange($event)"
></lcs-date-range-picker>
<div
   class="memorized-date-range-report-parameter-start"
   *ngIf="isRelative"
>
   <lcs-date-report-parameter
      [isRelative]="isRelative"
      [name]="name + '-start-date'"
      [disabled]="disabled"
      [parameter]="startDateParameter"
      [validation]="validation"
      [displayName]="displayName + ' - Start'"
      [standalone]="true"
      [hasAsk]="hasAsk"
   ></lcs-date-report-parameter>
</div>
<div
   class="memorized-date-range-report-parameter-end"
   *ngIf="isRelative"
>
   <lcs-date-report-parameter
      [isRelative]="isRelative"
      [name]="name + '-end-date'"
      [disabled]="disabled"
      [parameter]="endDateParameter"
      [validation]="validation"
      [displayName]="displayName + ' - End'"
      [standalone]="true"
      [hasAsk]="hasAsk"
   ></lcs-date-report-parameter>
</div>