<lcs-single-line-multi-select
   #selector
   [ngStyle]="singleSelectStyleOverride"
   [valueSource]="valueSource"
   [inlineLabel]="inlineLabel"
   [hideLabel]="hideLabel"
   [ngModel]="value"
   [disabled]="disabled"
   [disabledItems]="disabledItems"
   (ngModelChange)="onChange($event)"
   [ngModelOptions]="{ standalone: true }"
   [NoOfSelectedItemsToDisplay]="NoOfSelectedItemsToDisplay"
   [name]="name"
   [displayName]="displayName"
   (selectionChange)="onSelectionChange($event)"
   (panelClose)="onPanelClose()"
   (allSelected)="onAllSelected($event)"
>
</lcs-single-line-multi-select>