export class FooterConfigurationModel {
   HideWhenPristine: boolean;

   MarkFormAsPristineOnSuccess: boolean;

   AllowAutoButtonDisableStateChanging: boolean;

   ToggleOnConfigurationChange: boolean;

   constructor(
      hideWhenPristine: boolean = false,
      markFormAsPristineOnSuccess: boolean = false,
      allowAutoButtonDisableStateChanging: boolean = true,
      toggleOnConfigurationChange: boolean = true
   ) {
      this.HideWhenPristine = hideWhenPristine;

      this.AllowAutoButtonDisableStateChanging = allowAutoButtonDisableStateChanging;

      this.MarkFormAsPristineOnSuccess = markFormAsPristineOnSuccess;

      this.ToggleOnConfigurationChange = toggleOnConfigurationChange;
   }

   static get AlwaysDisplayConfiguration(): FooterConfigurationModel {
      return new FooterConfigurationModel(false, false, true, true);
   }

   static get AutomaticHideConfiguration(): FooterConfigurationModel {
      return new FooterConfigurationModel(true, true, true, true);
   }

   static get ManualConfiguration(): FooterConfigurationModel {
      return new FooterConfigurationModel(false, false, false, false);
   }
}
