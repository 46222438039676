import { SelectorItemModel } from "@lcs/selectors/selector-item.model";

export class StateSelectorItemHelper {
   public static buildStateOptions(isUnitedStates: boolean): Array<SelectorItemModel> {
      const states = new Array<SelectorItemModel>();

      if (isUnitedStates) {
         states.push(new SelectorItemModel("AL", "Alabama"));
         states.push(new SelectorItemModel("AK", "Alaska"));
         states.push(new SelectorItemModel("AZ", "Arizona"));
         states.push(new SelectorItemModel("AR", "Arkansas"));
         states.push(new SelectorItemModel("CA", "California"));
         states.push(new SelectorItemModel("CO", "Colorado"));
         states.push(new SelectorItemModel("CT", "Connecticut"));
         states.push(new SelectorItemModel("DE", "Delaware"));
         states.push(new SelectorItemModel("FL", "Florida"));
         states.push(new SelectorItemModel("GA", "Georgia"));
         states.push(new SelectorItemModel("HI", "Hawaii"));
         states.push(new SelectorItemModel("ID", "Idaho"));
         states.push(new SelectorItemModel("IL", "Illinois"));
         states.push(new SelectorItemModel("IN", "Indiana"));
         states.push(new SelectorItemModel("IA", "Iowa"));
         states.push(new SelectorItemModel("KS", "Kansas"));
         states.push(new SelectorItemModel("KY", "Kentucky"));
         states.push(new SelectorItemModel("LA", "Louisiana"));
         states.push(new SelectorItemModel("ME", "Maine"));
         states.push(new SelectorItemModel("MD", "Maryland"));
         states.push(new SelectorItemModel("MA", "Massachusetts"));
         states.push(new SelectorItemModel("MI", "Michigan"));
         states.push(new SelectorItemModel("MN", "Minnesota"));
         states.push(new SelectorItemModel("MS", "Mississippi"));
         states.push(new SelectorItemModel("MO", "Missouri"));
         states.push(new SelectorItemModel("MT", "Montana"));
         states.push(new SelectorItemModel("NE", "Nebraska"));
         states.push(new SelectorItemModel("NV", "Nevada"));
         states.push(new SelectorItemModel("NH", "New Hampshire"));
         states.push(new SelectorItemModel("NJ", "New Jersey"));
         states.push(new SelectorItemModel("NM", "New Mexico"));
         states.push(new SelectorItemModel("NY", "New York"));
         states.push(new SelectorItemModel("NC", "North Carolina"));
         states.push(new SelectorItemModel("ND", "North Dakota"));
         states.push(new SelectorItemModel("OH", "Ohio"));
         states.push(new SelectorItemModel("OK", "Oklahoma"));
         states.push(new SelectorItemModel("OR", "Oregon"));
         states.push(new SelectorItemModel("PA", "Pennsylvania"));
         states.push(new SelectorItemModel("RI", "Rhode Island"));
         states.push(new SelectorItemModel("SC", "South Carolina"));
         states.push(new SelectorItemModel("SD", "South Dakota"));
         states.push(new SelectorItemModel("TN", "Tennessee"));
         states.push(new SelectorItemModel("TX", "Texas"));
         states.push(new SelectorItemModel("UT", "Utah"));
         states.push(new SelectorItemModel("VT", "Vermont"));
         states.push(new SelectorItemModel("VA", "Virginia"));
         states.push(new SelectorItemModel("WA", "Washington"));
         states.push(new SelectorItemModel("WV", "West Virginia"));
         states.push(new SelectorItemModel("WI", "Wisconsin"));
         states.push(new SelectorItemModel("WY", "Wyoming"));
      } else {
         states.push(new SelectorItemModel("AB", "Alberta"));
         states.push(new SelectorItemModel("BC", "British Columbia"));
         states.push(new SelectorItemModel("MB", "Manitoba"));
         states.push(new SelectorItemModel("NB", "New Brunswick"));
         states.push(new SelectorItemModel("NL", "Newfoundland and Labrador"));
         states.push(new SelectorItemModel("NT", "Northwest Territories"));
         states.push(new SelectorItemModel("NS", "Nova Scotia"));
         states.push(new SelectorItemModel("NU", "Nunavut"));
         states.push(new SelectorItemModel("ON", "Ontario"));
         states.push(new SelectorItemModel("PE", "Prince Edward Island"));
         states.push(new SelectorItemModel("QC", "Quebec"));
         states.push(new SelectorItemModel("SK", "Saskatchewan"));
         states.push(new SelectorItemModel("YT", "Yukon"));
      }

      return states;
   }
}
