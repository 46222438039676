<div class="layout-wrapper">
   <div class="tile-header-wrapper">
   <div class="header-message">
      {{headerTitle}}
   </div>
      <div
         *ngIf="showIcon"
         class="header-message"
      >
         <lcs-link [contentTemplate]="contentTemplate">
            <ng-template #contentTemplate>
               <i
                  class="material-icons"
                  (click)="onTitleIconClicked()"
               >{{ titleIcon }}</i>
            </ng-template>
         </lcs-link>
      </div>
   </div>
   <div class="content-wrapper {{backgroundColorClass}}">
      <ng-content></ng-content>
   </div>
  
</div> 