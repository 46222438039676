import { ExpressRegisterColumnModel } from "../generated/express-register-column.model";
import { RegisterSearchPostBodyModel } from "./register-search-post-body.model";
import { SearchPostBodyTransferModel } from "./search-post-body-transfer.model";

export class RegisterSearchPostBodyTransferModel extends SearchPostBodyTransferModel {
   Columns: Array<ExpressRegisterColumnModel>;

   constructor(postModel: RegisterSearchPostBodyModel | null) {
      super(postModel);
      if (postModel) {
         this.Columns = postModel.columns;
      }
   }
}
