import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { OwnerContractFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/owner-contract.fields";
import { OwnerFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/owner.fields";
import { PropertyFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { OwnerStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/owner-status.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OwnerGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { OwnerGroupsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/owner-groups.service";
import { Subject, takeUntil } from "rxjs";

import { coerceBoolean } from "../../../utils/boolean-coercion";
import { AllValueType, isAllValueType } from "../all-value.type";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-owners-to-include-report-parameter",
   templateUrl: "owners-to-include-report-parameter.component.html",
})
export class OwnersToIncludeReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() disabled: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() hasAsk: boolean;

   @Input() report: ReportModel;

   @Output() selectionChanged = new EventEmitter<SelectionChangeModel>();

   ownerIdsReportParameter: ReportReportParameterViewModel;

   includeInactiveOwnerReportParameter: ReportReportParameterViewModel;

   showInactivePropertiesReportParameter: ReportReportParameterViewModel;

   groupReportParameter: ReportReportParameterViewModel;

   groupValueSources: Array<ValueSourceModel>;

   propertiesReplacementName: string;

   includeInactiveOwners: boolean = false;

   ownerFilters: Array<FilterOption> = new Array<FilterOption>();

   selectedGroupID: number | "";

   isLoadingGroups = false;

   askParameter: ReportReportParameterViewModel = new ReportReportParameterViewModel();

   disableOwnerMultiSelectors: boolean = false;

   showInactiveOwnerOption: boolean = true;

   private unsubscribe = new Subject<void>();

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private ownerGroups = new Array<OwnerGroupModel>();

   private selectedOwnersIds: Array<number | AllValueType> = new Array<number>();

   constructor(
      private reportParametersService: ReportParametersService,
      private ownerGroupsService: OwnerGroupsService,
      private errorMessageService: ErrorMessageService,
      private entityViewInformationService: EntityViewInformationServiceBase
   ) {
      this.groupValueSources = new Array<ValueSourceModel>();
      this.entityViewInformationService
         .getViewInformation(EntityType.Property)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((viewInformation) => {
            if (viewInformation) {
               this.propertiesReplacementName = viewInformation.CollectionLabel;
            }
         });
   }

   ngOnInit() {
      this.initializeParameters();
      this.loadOwnerGroups();
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === ReportParameter.OWNERIDS) {
               this.selectedOwnersIds = updatedValue.rawValues;
            } else if (updatedValue.reportParameter === ReportParameter.INCLUDEINACTIVEOWNERS) {
               this.inactiveOwnerChecked(updatedValue.value);
            }
         });
   }

   loadOwnerGroups() {
      this.isLoadingGroups = true;
      this.ownerGroupsService
         .getCollection(null, ["Owners"])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (ownerGroups: OwnerGroupModel[]) => {
               if (ownerGroups) {
                  this.ownerGroups = ownerGroups;
                  this.toggleGroup();
                  if (this.selectedGroupID) {
                     this.updateForGroupSelected(this.selectedGroupID);
                  }
               }
               this.isLoadingGroups = false;
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            }
         );
   }

   toggleGroup() {
      const groupItems: Array<OwnerGroupModel> = this.ownerGroups;
      const selectedGroupID: number | "" = this.selectedGroupID;

      this.updateReportParameter(this.selectedOwnersIds);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValueSource... Remove this comment to see the full error message
      this.groupValueSources = null;
      const selectedValueSources = new Array<ValueSourceModel>();
      if (groupItems.length > 0) {
         const valueSourceModel = new ValueSourceModel();
         valueSourceModel.Type = ValueSourceTypes.Static;
         let item = new SelectorItemModel();
         item.displayValue = "None";
         item.value = "";
         if (selectedGroupID == null || selectedGroupID === "") {
            item.isChecked = true;
         }
         valueSourceModel.StaticValues.push(item);
         for (const group of groupItems) {
            item = new SelectorItemModel();
            item.displayValue = group.Name;
            item.value = group.GroupID;
            if (
               item.value &&
               selectedGroupID &&
               item.value.toString().toLowerCase() === selectedGroupID.toString().toLowerCase()
            ) {
               item.isChecked = true;
            }
            valueSourceModel.StaticValues.push(item);
         }
         selectedValueSources.push(valueSourceModel);
         this.groupValueSources = selectedValueSources;
      }
      this.selectedGroupID = selectedGroupID;
   }

   updateGroup() {
      this.toggleGroup();

      if (!this.ownerGroups || this.ownerGroups.length === 0) {
         this.updateForGroupSelected(0);
      } else if (this.selectedGroupID) {
         this.updateForGroupSelected(this.selectedGroupID);
      }
   }

   /**
    * Coerce value to boolean then set ownerFilters accordingly
    */
   inactiveOwnerChecked(value: any) {
      this.includeInactiveOwners = coerceBoolean(value);

      const newFilters = new Array<FilterOption>();
      if (!this.includeInactiveOwners) {
         newFilters.push(
            new FilterOption(OwnerFields.Status.Identifier, FilterOperations.EqualTo, [OwnerStatus.Current])
         );
      }
      if (
         this.report &&
         this.report.PropertyTypes &&
         this.report.PropertyTypes.length &&
         this.report.PropertyTypes.length > 0
      ) {
         newFilters.push(
            new FilterOption(
               `${OwnerFields.Ownerships.Identifier}.${OwnerContractFields.Property.Identifier}.${PropertyFields.PropertyType.Identifier}`,
               FilterOperations.In,
               this.report.PropertyTypes
            )
         );
      }
      this.ownerFilters = newFilters;
   }

   groupSelected(selection: SelectionChangeModel) {
      if (this.selectedGroupID === selection.checkedItems[0].value) {
         return;
      }
      this.isLoadingGroups = true;
      this.updateForGroupSelected(selection.checkedItems[0].value);
      setTimeout(() => {
         this.isLoadingGroups = false;
      });
   }

   updateForGroupSelected(selectedGroupID: number | "") {
      if (!(parseInt(selectedGroupID?.toString()) > 0)) {
         // selectedGroupID is not a positive integer
         this.selectedGroupID = "";
         this.disableOwnerMultiSelectors = false;
         const noGroup = new ReportParameterValueModel(ReportParameter.GroupID, 0, [0]);
         this.reportParametersService.updateReportParameterValue(noGroup);
      } else if (typeof selectedGroupID === "number") {
         if (this.ownerGroups.length > 0) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'OwnerGroupModel | undefined' is not assignab... Remove this comment to see the full error message
            const selectedOwnerGroup: OwnerGroupModel = this.ownerGroups.find((g) => g.GroupID === selectedGroupID);
            if (selectedOwnerGroup) {
               this.disableOwnerMultiSelectors = true;
               this.selectedGroupID = selectedOwnerGroup.GroupID;
               const ownerIDs = selectedOwnerGroup.Owners.map((p) => p.OwnerID);
               this.updateReportParameter(ownerIDs);
            }
         }
         const ownerGroupParameterValueModel = new ReportParameterValueModel(ReportParameter.GroupID, selectedGroupID, [
            selectedGroupID,
         ]);
         this.reportParametersService.updateReportParameterValue(ownerGroupParameterValueModel);
      }
   }

   askChanged(isAsk: boolean) {
      this.askParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.askParameter.ReportParameterID, isAsk);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private updateReportParameter(ownerIds: Array<number | AllValueType> | null) {
      if (ownerIds) {
         let values: string = "";
         if (ownerIds.length > 0) {
            if (isAllValueType(ownerIds[0])) {
               values = ownerIds[0];
            } else {
               values = `(${ownerIds.join(",")})`;
            }
         }
         const valueModel = new ReportParameterValueModel(ReportParameter.OWNERIDS, values, ownerIds);
         this.reportParametersService.updateReportParameterValue(valueModel);
      }
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.ownerIdsReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.OWNERIDS
      );
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const selectedValues = this.reportParametersService.reportParameterValues.get(ReportParameter.OWNERIDS).value;
      if (selectedValues && selectedValues.length > 0) {
         this.selectedOwnersIds = selectedValues.split(",");
      }

      const hideInactiveOwnerReports = [Report.OwnerBalanceSheet, Report.OwnerProfitLoss, Report.Owner1099Breakdown];
      if (hideInactiveOwnerReports.find((x) => x === this.ownerIdsReportParameter.ReportID)) {
         this.showInactiveOwnerOption = false;
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.includeInactiveOwnerReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.INCLUDEINACTIVEOWNERS
      );

      if (!this.includeInactiveOwnerReportParameter) {
         const includeInactiveValueModel = new ReportParameterValueModel(
            ReportParameter.INCLUDEINACTIVEOWNERS,
            "false"
         );
         this.reportParametersService.updateReportParameterValue(includeInactiveValueModel);

         const includeInactiveReportParameter = new ReportReportParameterViewModel();
         includeInactiveReportParameter.PromptText = `Inactive Owners`;
         includeInactiveReportParameter.DefaultValue = "false";
         includeInactiveReportParameter.ReportParameterID = ReportParameter.INCLUDEINACTIVEOWNERS;
         this.includeInactiveOwnerReportParameter = includeInactiveReportParameter;
      }

      this.inactiveOwnerChecked(this.includeInactiveOwnerReportParameter.DefaultValue);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.showInactivePropertiesReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWINACTIVEPROPS
      );

      if (!this.showInactivePropertiesReportParameter) {
         const includeInactiveValueModel = new ReportParameterValueModel(ReportParameter.SHOWINACTIVEPROPS, "false");
         this.reportParametersService.updateReportParameterValue(includeInactiveValueModel);

         const includeInactiveReportParameter = new ReportReportParameterViewModel();
         includeInactiveReportParameter.PromptText = `Inactive ${this.propertiesReplacementName}`;
         includeInactiveReportParameter.DefaultValue = "false";
         includeInactiveReportParameter.ReportParameterID = ReportParameter.SHOWINACTIVEPROPS;
         this.showInactivePropertiesReportParameter = includeInactiveReportParameter;
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.groupReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.GroupID
      );
      if (this.groupReportParameter?.DefaultValue) {
         const grpDefaultValue: string = this.groupReportParameter.ReportParameterValueSource.DefaultValue;
         const defaultGroupID: number | "" = parseInt(grpDefaultValue) > 0 ? parseInt(grpDefaultValue) : "";
         this.selectedGroupID = defaultGroupID;
      }
      this.toggleGroup();

      if (this.ownerIdsReportParameter) {
         this.askParameter = this.ownerIdsReportParameter;
      }
   }
}
