<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      *ngIf="loanAddressToUseParameter"
      [name]="name + '-address-to-user'"
      [disabled]="disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="loanAddressToUseParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-checkbox-report-parameter
      *ngIf="consolidate1096PropertyParameter"
      [name]="name + '-loan-1096-consolidate-property'"
      [disabled]="disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="consolidate1096PropertyParameter"
      [hasAsk]="hasAsk"
   ></lcs-checkbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-checkbox-report-parameter
      *ngIf="consolidate1099PropertyParameter"
      [name]="name + '-loan-1099-consolidate-property'"
      [disabled]="disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="consolidate1099PropertyParameter"
      [hasAsk]="hasAsk"
   ></lcs-checkbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="nameParameter"
      [name]="name + '-name'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="nameParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="streetParameter"
      [name]="name + '-street'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="streetParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="cityParameter"
      [name]="name + '-city'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="cityParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="stateParameter"
      [name]="name + '-state'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="stateParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="zipParameter"
      [name]="name + '-zip'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="zipParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="contactParameter && !contactParameter.IsHidden"
      [name]="name + '-contact'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="contactParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="phoneParameter && !phoneParameter.IsHidden"
      [name]="name + '-phone'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="phoneParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="faxParameter && !faxParameter.IsHidden"
      [name]="name + '-fax'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="faxParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="taxIDParameter"
      [name]="name + '-taxid'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="taxIDParameter"
   ></lcs-textbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-textbox-report-parameter
      *ngIf="emailParameter && !emailParameter.IsHidden"
      [name]="name + '-email'"
      [disabled]="!manualOverride || disabled || (hasAsk === true && primaryParameter.IsAsk === true)"
      [parameter]="emailParameter"
   ></lcs-textbox-report-parameter>
</div>