import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

import { slidePanelAnimationIdentifier, slidePanelAnimations } from "./slide-panel.animation";

@Component({
   selector: "lcs-slide-panel-outlet",
   templateUrl: "slide-panel-outlet.component.html",
   animations: [slidePanelAnimations.slidePanelOutletAnimation],
})
export class LcsSlidePanelOutletComponent {
   prepareRoute(outlet: RouterOutlet) {
      if (outlet.isActivated) {
         // routes that don't have an 'animation' property defined are assumed to be base pages (i.e., not slide panels)
         const path = outlet.activatedRoute.snapshot.pathFromRoot
            .map((route) => route.url.map((segment) => segment.toString()))
            .join("/");
         return (outlet && outlet.activatedRouteData && outlet.activatedRouteData["routeAnimation"]) ===
            slidePanelAnimationIdentifier
            ? `${slidePanelAnimationIdentifier}_${path}`
            : "base";
      } else {
         return null;
      }
   }
}
