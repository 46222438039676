import { Injectable } from "@angular/core";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { concatMap, distinctUntilChanged, Observable, Subject, windowTime } from "rxjs";

import { ActionBarActionModel } from "../action-bar/action-bar-action.model";
import { OpenInNewTabSourceAction, RoutingService } from "../action-router/routing.service";
import { DatatableQuickActionModel } from "../datatable/datatable-quick-action.model";
import { ActionTriggerPayloadModel } from "./action-trigger-payload.model";
import { ActionTriggerModel } from "./action-trigger.model";

@Injectable({
   providedIn: "root",
})
export class ActionTriggerService {
   actionTrigger: Observable<ActionTriggerModel>;

   reloadActionTrigger = new Subject<void>();

   private _actionTrigger = new Subject<ActionTriggerModel | null>();

   constructor() {
      this._actionTrigger.next(null);
      this.actionTrigger = this._actionTrigger.pipe(
         windowTime(500),
         concatMap((action) =>
            action.pipe(
               distinctUntilChanged(
                  (a1: ActionTriggerModel, a2: ActionTriggerModel) => a1.ExpressActionID === a2.ExpressActionID
               )
            )
         )
      );
   }

   triggerAction(action: ExpressActions, openInNewTab: boolean = false) {
      const actionTrigger = this.buildActionTriggerFromAction(action, [], openInNewTab);
      this.triggerActionWithModel(actionTrigger);
   }

   triggerActionWithModel(action: ActionTriggerModel) {
      this._actionTrigger.next(action);
   }

   triggerActionBarAction(actionBarAction: ActionBarActionModel) {
      const actionTrigger = this.buildActionTriggerFromActionBarAction(actionBarAction);
      this.triggerActionWithModel(actionTrigger);
   }

   triggerDataTableAction(action: DatatableQuickActionModel) {
      const actionTrigger = new ActionTriggerModel();
      actionTrigger.ExpressActionID = action.ExpressAction;
      actionTrigger.Payloads = action.Payloads;
      actionTrigger.ViewMode = action.ViewMode;
      actionTrigger.ValueCallBack = action.ValueCallBack;
      this.triggerActionWithModel(actionTrigger);
   }

   buildActionTriggerFromAction(
      expressAction: ExpressActions,
      payloads?: Array<ActionTriggerPayloadModel>,
      openInNewTab: boolean = false,
      openInNewTabSourceAction?: OpenInNewTabSourceAction
   ): ActionTriggerModel {
      const actionTrigger = new ActionTriggerModel();
      actionTrigger.ExpressActionID = expressAction;

      if (payloads) {
         actionTrigger.Payloads = payloads;
      }

      if (openInNewTab) {
         actionTrigger.addPayload(RoutingService.OpenInNewTabPayloadKey, true);
      }

      if (openInNewTabSourceAction) {
         actionTrigger.addPayload(RoutingService.OpenInNewTabSourceActionPayloadKey, openInNewTabSourceAction);
      }

      return actionTrigger;
   }

   buildActionTriggerFromActionBarAction(expressActionBarAction: ActionBarActionModel): ActionTriggerModel {
      const actionTrigger = new ActionTriggerModel();
      actionTrigger.Action = expressActionBarAction.Action;
      actionTrigger.ViewMode = expressActionBarAction.ViewMode;
      actionTrigger.ExpressActionID = expressActionBarAction.ExpressActionID;
      actionTrigger.Payloads = new Array<ActionTriggerPayloadModel>();

      if (expressActionBarAction.Payloads) {
         for (const payload of expressActionBarAction.Payloads) {
            const triggerPayload = new ActionTriggerPayloadModel();
            triggerPayload.PayloadField = payload.PayloadField;
            triggerPayload.PayloadKey = payload.PayloadKey;
            triggerPayload.Value = payload.Value;
            actionTrigger.Payloads.push(triggerPayload);
         }
      }

      if (expressActionBarAction.ActionTriggerPayloads) {
         for (const payload of expressActionBarAction.ActionTriggerPayloads) {
            const triggerPayload = new ActionTriggerPayloadModel();
            triggerPayload.PayloadField = payload.PayloadField;
            triggerPayload.Value = payload.Value;
            triggerPayload.PayloadKey = payload.PayloadKey;
            actionTrigger.Payloads.push(triggerPayload);
         }
      }

      return actionTrigger;
   }

   reloadAction() {
      this.reloadActionTrigger.next();
   }
}
