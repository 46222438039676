<div>
   <div
      *ngIf="isDisplayClickToCall"
      (click)="onClick()"
      class="click-to-call"
   >
      <i
         [title]="toolTip"
         class="material-icons {{extraCSSClass}}"
      >call</i>
   </div>
   <div
      class="call-icon-wrapper"
      *ngIf="isDisplayIconWithoutPrivs && !isDisplayClickToCall"
      [ngClass]="{'primary-icon': primaryColor}"
   >
      <i class="material-icons">call</i>
   </div>
</div>