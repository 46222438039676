<p-dialog
   #Dialog
   [(visible)]="visible"
   appendTo="body"
   [style]="inlineStyle"
   [modal]="modal"
   [draggable]="draggable"
   [resizable]="resizable"
   [showHeader]="showHeader"
   [dismissableMask]="canClose && closeOnOutsideClick"
   [closable]="canClose"
   [closeOnEscape]="false"
   [blockScroll]="true"
   [styleClass]="styleClass"
   [autoZIndex]="true"
   [baseZIndex]="baseZIndex"
   [focusOnShow]="false"
   onclick="void(0);"
>
   <div
      #dialogContainer
      class="modal-container modal-heading-mobile"
   >
      <div
         class="modal-header"
         *ngIf="title || helpUrl || canClose"
      >
         <div class="modal-heading">{{ title }}</div>
         <div class="modal-heading-buttons">
            <div *ngIf="headingButtonsTemplate"><ng-container
                  [ngTemplateOutlet]="headingButtonsTemplate"></ng-container></div>
            <div
               *ngIf="helpUrl"
               [pTooltip]="isIOS ? '' : 'Help'"
               tooltipPosition="left"
               tooltipEvent="hover"
            >
               <lcs-link
                  [cssClasses]="['help-icon']"
                  [contentTemplate]="contentTemplate1"
                  [href]="helpUrl"
               >
                  <ng-template #contentTemplate1>
                     <i class="material-icons">help_outline</i>
                  </ng-template>
               </lcs-link>
            </div>
            <lcs-link
               *ngIf="canClose"
               [cssClasses]="['close-icon']"
               [contentTemplate]="contentTemplate2"
               (clicked)="closeDialog()"
            >
               <ng-template #contentTemplate2>
                  <i class="material-icons">close</i>
               </ng-template>
            </lcs-link>
         </div>
      </div>
      <form
         class="dialog-content"
         [class.overflow-y-hidden]="overflowYHidden"
         cdkScrollable
         autocomplete="off"
         onsubmit="return false;"
      >
         <ng-template #contentPlaceholder></ng-template>
      </form>
      <ng-template #footerPlaceholder></ng-template>
   </div>
</p-dialog>