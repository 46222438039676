import { Injectable } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ActionTriggerModel } from "@lcs/action-trigger/action-trigger.model";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";
import { ExpressFavoritesPayloadModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-favorites-payload.model";

import { ActionRoute } from "./action-route.interface";
import { RoutingService } from "./routing.service";

@Injectable({ providedIn: "root" })
export class ActionRoutingService {
   constructor(private routingService: RoutingService) {}

   // change spots that use this and or buildURL in the html
   static buildURLExplicit(
      expressAction: ExpressActionModel | null,
      id: number | null = null,
      additionalData: string = ""
   ): Array<string> {
      let url = "/";
      let queryString = "";

      if (!expressAction || !expressAction.Area) {
         return [""];
      }

      url += expressAction.Area;

      if (expressAction.IsSingleEntityAction && id) {
         url += "/" + id;
      }

      if (expressAction.ActionRoute && expressAction.ActionRoute.length > 0) {
         url += "/" + expressAction.ActionRoute;
      }

      if (additionalData) {
         const additionalDataArray = additionalData.split(";");
         for (let i = 0; i < additionalDataArray.length; i++) {
            if (queryString === "") {
               queryString += `?${additionalDataArray[i]}`;
            } else {
               queryString += `&${additionalDataArray[i]}`;
            }
         }

         url += queryString;
      }
      return [url];
   }

   buildQueryParamsFromPayloads(payloads: Array<ActionTriggerPayloadModel>): {} | null {
      let queryParams: object = {};
      this.routingService.resolvePayloadValues(payloads).forEach((payload: ActionTriggerPayloadModel) => {
         queryParams[ExpressPayloadFields[payload.PayloadField].toString()] = payload.Value;
      });

      if (Object.keys(queryParams).length === 0) {
         return null;
      }

      return queryParams;
   }

   buildURL(node: ActionRoute): Array<string> {
      // change spots that use this and or buildURL in the html
      return ActionRoutingService.buildURLExplicit(node.Action, node.EntityID, node.AdditionalData);
   }

   buildFavoritePayloadQueryParams(payloads: Array<ExpressFavoritesPayloadModel>): any {
      if (!payloads || payloads.length === 0) {
         return;
      }

      const queryParams = {};
      payloads.forEach((payload) => {
         const expressPayload = ExpressPayloadFields[payload.ExpressFavoritesFieldID];
         queryParams[expressPayload.toString()] = payload.ExpressFavoritesValue;
      });
      return queryParams;
   }

   buildURLFromActionTriggerModel(actionTrigger: ActionTriggerModel): Array<string> {
      let id: number | null = null;
      let idPayload = actionTrigger.Payloads.find((p) => p.PayloadField === ExpressPayloadFields.ID);
      if (!idPayload) {
         idPayload = actionTrigger.Payloads.find((p) => p.PayloadField === ExpressPayloadFields.EntityID);
      }

      if (idPayload) {
         id = idPayload.Value;
      }

      return ActionRoutingService.buildURLExplicit(actionTrigger.Action, id);
   }
}
