import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";

/**
 * Recursively mark control and all descendant UntypedFormGroup, UntypedFormArray and FormControls as
 * both touched and dirty.
 *
 * Note: If you only need to mark touched you can use the built-in markAllAsTouched() AbstractControl method
 */
export function markFormGroupAsTouchedAndDirty(control: AbstractControl): void {
   control.markAsTouched();
   control.markAsDirty();
   if (control instanceof UntypedFormGroup) {
      const children: { [key: string]: AbstractControl } = (control as UntypedFormGroup).controls;
      if (children) {
         Object.keys(children).forEach((key: string) => {
            markFormGroupAsTouchedAndDirty(children[key]);
         });
      }
   } else if (control instanceof UntypedFormArray) {
      const children: Array<AbstractControl> = (control as UntypedFormArray).controls;
      if (children) {
         children.forEach((c) => markFormGroupAsTouchedAndDirty(c));
      }
   }
}

/**
 * Wrapper for AbstractControl.get(...) that returns the AbstractControl if found, otherwise throws an error
 * indicating the path that was not found. Optional contextMessage if provided will be appended to thrown Error message.
 *
 * Error message includes path by default.
 *
 * Error thrown if:
 * - control arg is null or undefined
 * - failed to find descendant control at given path
 *
 * Originally added to support strictNullChecks, use in cases where it is expected that the get should always succeed
 * to avoid unnecessarily making a variable accept null or undefined when it should never be and if it is it
 * should be an error.
 */
export function getControlOrThrow(
   control: AbstractControl | null | undefined,
   path: string | (string | number)[],
   contextMessage: string = ""
): AbstractControl {
   if (control == null) {
      throw new Error(
         `Unexpected null or undefined control value! Cannot search for path: ${JSON.stringify(path)} ${contextMessage}`
      );
   }

   const childControl: AbstractControl | null = control.get(path);
   if (!childControl) {
      throw new Error(
         `Unexpected null value! Failed to find descendant control given path: ${JSON.stringify(
            path
         )} ${contextMessage}`
      );
   }

   return childControl;
}
