import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";
import { TableModule } from "@lcs/table/table.module";

import { HistoryNotesListModule } from "../history-notes-list/history-notes-list.module";
import { LayoutModule } from "../layout/layout.module";
import { TileModule } from "../tile/tile.module";
import { ChecklistListViewMoreComponent } from "./checklist-list/checklist-list-view-more.component";
import { ChecklistListComponent } from "./checklist-list/checklist-list.component";
import { ServiceIssueDetailContainerComponent } from "./details/service-issue-detail-container.component";
import { ServiceIssueDetailsComponent } from "./details/service-issue-details.component";
import { IssueEstimateListComponent } from "./estimate-list/issue-estimate-list.component";
import { IssueHistoryViewMoreComponent } from "./history-list/issue-history-view-more.component";
import { ServiceIssueDetailComponent } from "./service-issue-detail.component";
import { TenantDetailComponent } from "./tenant-details/tenant-detail.component";
import { VendorDetailComponent } from "./vendor-details/vendor-detail.component";
import { IssueWorkOrdersViewMoreComponent } from "./workorder-list/issue-work-order-view-more.component";
import { WorkOrderListComponent } from "./workorder-list/workorder-list.component";

@NgModule({
   imports: [
      CommonModule,
      TileModule,
      LayoutModule,
      TableModule,
      LcsSlidePanelModule,
      LoadingOverlayModule,
      LcsProgressButtonModule,
      HistoryNotesListModule,
   ],
   declarations: [
      ServiceIssueDetailComponent,
      ServiceIssueDetailContainerComponent,
      VendorDetailComponent,
      TenantDetailComponent,
      ChecklistListComponent,
      WorkOrderListComponent,
      ServiceIssueDetailsComponent,
      ChecklistListViewMoreComponent,
      IssueHistoryViewMoreComponent,
      IssueWorkOrdersViewMoreComponent,
      IssueEstimateListComponent,
   ],
   exports: [ServiceIssueDetailComponent],
})
export class ServiceIssueDetailModule {}
