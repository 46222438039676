<owa-dashboard-tile
   [title]="'Service Issues'"
   [notificationCount]="notificationCount"
   [contentClass]="'content-padding'"
   [isViewMore]="true"
   [viewMoreComponent]="'serviceissues'"
>
   <div
      *ngIf="observableIssues != null; else noIssues"
      class=tile-inner-wrapper
   >
      <div
         class='issue-item'
         *ngFor="let issue of observableIssues | async"
      >
         <div class="issue-date">{{issue.AssignedOpenDate | date: 'MMM d, y'}}
            <span
               *ngIf="issue.IsNew"
               class="notification-icon"
            ></span>
         </div>
         <div
            class='issue-title'
            (click)="showDetails(issue.ServiceManagerIssueID)"
         >{{issue.Title}}</div>
         <div class="issue-description">{{issue.Description}}</div>
      </div>
   </div>
   <ng-template #noIssues>
         <div class='no-result'>No Service Issues found</div>
   </ng-template>
   </owa-dashboard-tile>