<div class="routing-wrapper">
   <table class="routing-detail">
      <tr>
         <td>
            At the bottom of a check, you will see three groups of numbers. The first group is your routing number, the
            second is your account number and the third is your check number.

            <br />
            <br />
            Note: If you notice extra zeroes before or after your account number, please include them as part of your
            account number.

         </td>
      </tr>
      <tr>
         <td>
            <img
               class="routingImage"
               src="/content/images/check.png"
               (load)="onImageLoaded()"
            />
         </td>
      </tr>
   </table>
</div>

<div lcsFooter>
   <lcs-progress-button
      [raised]="true"
      (buttonClick)="onOkClick()"
   >OK</lcs-progress-button>
</div>