import { Component, Input, OnDestroy } from "@angular/core";
import { SelectComponent } from "@lcs/select/components/select.component";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { Observable, Subject, takeUntil } from "rxjs";

import { ContextService } from "../../action-context/context.service";
import { ConstantsService } from "../../core/constants.service";
import { ClickToCallService } from "./click-to-call.service";

@Component({
   selector: "lcs-click-to-call",
   templateUrl: "./click-to-call.component.html",
   providers: [ClickToCallService],
})
export class ClickToCallComponent implements OnDestroy {
   @Input() set dataSource(input: any) {
      if (input) {
         if (typeof input === "string") {
            this.clickToCallService.availablePhoneNumbers = new Array<string>();
            this.clickToCallService.availablePhoneNumbers.push(input);
            this.clickToCallService.resolveClickToCallDisplay();
            this.clickToCallService.entityType = this.contextService.parentEntityType;
            // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
            this.clickToCallService.entityID = this.contextService.parentEntityID;
         } else if (input instanceof SelectComponent) {
            input?.selectionChanged.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
               this.clickToCallService.resolveSelectionChanged(val);
            });
         } else if ("RMVoIPCallHistoryID" in input) {
            this.clickToCallService.availablePhoneNumbers = new Array<string>();
            this.clickToCallService.availablePhoneNumbers.push(input.Call);
            this.clickToCallService.entityID = input.ParentID;
            if (input.LinkType === "Tenant") {
               this.clickToCallService.entityType = EntityType.Tenant;
            } else if (input.LinkType === "Prospect") {
               this.clickToCallService.entityType = EntityType.Prospect;
            } else if (input.LinkType === "Owner") {
               this.clickToCallService.entityType = EntityType.Owner;
            } else if (input.LinkType === "OwnerProspect") {
               this.clickToCallService.entityType = EntityType.OwnerProspects;
            } else if (input.LinkType === "Property") {
               this.clickToCallService.entityType = EntityType.Property;
            } else if (input.LinkType === "Vendor") {
               this.clickToCallService.entityType = EntityType.Vendor;
            } else {
               this.clickToCallService.entityType = EntityType.NotSet;
               this.clickToCallService.entityID = ConstantsService.NullFK;
            }
            this.clickToCallService.resolveClickToCallDisplay();
         }
      }
   }

   @Input() extraCSSClass: string;

   @Input() isDisplayIconWithoutPrivs: boolean;

   @Input() primaryColor: boolean;

   isDisplayClickToCall: Observable<boolean>;

   toolTip: Observable<string>;

   private unsubscribe = new Subject<void>();

   constructor(private clickToCallService: ClickToCallService, private contextService: ContextService) {
      this.isDisplayClickToCall = this.clickToCallService.isDisplayClickToCall;
      this.toolTip = this.clickToCallService.toolTip;
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onClick() {
      this.clickToCallService.onClick();
   }
}
