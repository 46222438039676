import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { EmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/email.model";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { OwnershipViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/ownership-view.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { OwnershipsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/ownerships.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { EmailConfigurationModel } from "../../email/email-configuration.model";
import { EmailComponent } from "../../email/email.component";
import { OWASessionService } from "../../session/owa-session.service";

@Component({
   selector: "owa-ownerships-list",
   templateUrl: "./ownerships-list.component.html",
   providers: [DataTableFiltersService],
})
export class OwnershipsListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   @Input() showReserve: boolean;
   @Input() showSecurityDepositHeld: boolean;
   @Input() showOwnershipPercentage: boolean;
   @Input() allowOwnerToEmailPropertyManager: boolean;

   columns: string[] = ["PropertyName", "Manager", "Ended", "Email"];

   observableOwnerships: Observable<Array<OwnershipViewModel>>;

   ownershipFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   appliedSideBarFilters: Array<FilterOption>;
   showFilters: boolean = false;

   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   threshold: string = "30%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;

   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _observableOwnerships = new BehaviorSubject<Array<any>>(null);
   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);
   private unsubscribe = new Subject<void>();

   constructor(
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService,
      private ownershipService: OwnershipsService,
      private dialogCreationService: DialogCreationService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.observableOwnerships = this._observableOwnerships.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.ownershipFilters = this.getFilters();
   }

   ngOnInit() {
      if (this.showReserve) {
         this.columns.push("ReserveAmount");
      }
      if (this.showOwnershipPercentage) {
         this.columns.push("OwnershipPercentage");
      }
      if (this.showSecurityDepositHeld) {
         this.columns.push("SecurityDepositHeld");
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   email(email: string, propertyID: number) {
      const emailConfigurationModel = new EmailConfigurationModel();
      const emailModel = new EmailModel();
      emailModel.To = email;
      emailConfigurationModel.Email = emailModel;
      emailModel.PropertyID = propertyID;

      const dialogConfigurationModel = new DialogConfigurationModel();
      dialogConfigurationModel.componentInstance = EmailComponent;
      dialogConfigurationModel.title = "Compose Email";
      this.dialogCreationService.open(dialogConfigurationModel, emailConfigurationModel);
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.ownershipService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));

               const ownerships = response.body;
               return ownerships;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData =
                  this._observableOwnerships && this._observableOwnerships.value
                     ? this._observableOwnerships.value
                     : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._observableOwnerships.next(newData);
               this.results.next(this._observableOwnerships.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll($event): void {
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         this.initialLoadComplete = true;
      }
      const data = this._observableOwnerships.value ? this._observableOwnerships.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || data.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";
      const filterOps = [eq];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.ownershipFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const propertyFiltervalueSourceModel = new ValueSourceModel();
      propertyFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      propertyFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel("", "All Properties"));
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });
      const propertyFilter = this.getDataTableFilterModel(
         "PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         propertyFiltervalueSourceModel
      );
      const filterList = [propertyFilter];
      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.ownershipFilters = this.resetFilter(this.ownershipFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(allFilters: DataTableFilterModel[], filterToReset: FilterOption, defaultValue: any = null) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = filter.ValueSource.DefaultValue;
            }
            return {
               ...filter,
               FilterOption: new FilterOption(
                  filter.FilterName,
                  FilterOperations.EqualTo,
                  newValue,
                  filterToReset.Label
               ),
            };
         }
         return filter;
      });
   }
}
