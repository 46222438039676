import { NgModule } from "@angular/core";

import { EntityLinkSelectorFilterComponent } from "./entity-link-selector-filter.component";
import { EntityLinkSelectorModule } from "./entity-link-selector/entity-link-selector.module";

@NgModule({
   imports: [EntityLinkSelectorModule],
   declarations: [EntityLinkSelectorFilterComponent],
   exports: [EntityLinkSelectorFilterComponent],
})
export class EntityLinkSelectorFilterModule {}
