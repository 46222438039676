<lcs-select
   *ngIf="hasBankPermission"
   lcsEntitySelector
   [entityType]="entityType"
   [ngModel]="value"
   [ngModelOptions]="{ standalone: true }"
   [name]="name"
   [displayName]="displayName+' Selector'"
   [filters]="filters"
   [disabled]="disabled"
   [addBlankItem]="addBlankItem"
   [staticValues]="staticValues"
   (ngModelChange)="onValueChange($event)"
   [tooltipOverride]="tooltip"
>
</lcs-select>
<lcs-text-box
   *ngIf="!hasBankPermission"
   class="text-box"
   [disabled]="true"
   [ngModelOptions]="{ standalone: true }"
   [ngModel]="defaultBankName"
></lcs-text-box>