import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { SystemWebPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-web-preference.enum";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PrivilegeInformationService implements OnDestroy {
   private unsubscribe = new Subject<void>();

   private endpoint = "PrivilegeInformation";
   private validatePrivilegeResource = "/ValidatePrivilege";

   constructor(private apiService: ApiService) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   public validatePrivilege(systemWebPreference: SystemWebPreference): Observable<boolean> {
      const endpoint = this.endpoint + this.validatePrivilegeResource;
      const additionalParameters = new Array<string>();
      additionalParameters.push("preference=" + SystemWebPreference[systemWebPreference]);
      return this.apiService.getSingle(
         this.apiService.getUrl(
            endpoint,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            additionalParameters
         )
      );
   }
}
