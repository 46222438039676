<div
   #AccountMenuContainer
   class="account-menu-container"
>
   <div style="display: flex;">
      <div
         class="account-name-label"
         [class.hoverable]="availableAccounts.length > 0"
         #switchAccountsMenuItem
      >
         <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
         <span *ngIf="$any((currentWebUserAccount | async)).Nickname != ''; else noNickName">
            {{ $any((currentWebUserAccount | async)).Nickname }}
         </span>
         <ng-template #noNickName>
            <span *ngIf="isOwner"> {{ currentOwner.OwnerID }} - {{ currentOwner.Name }} </span>
            <span *ngIf="!isOwner"> {{ currentOwnerProspect.OwnerProspectID }} - {{ currentOwnerProspect.Name }} </span>
         </ng-template>
         <i
            class="material-icons"
            *ngIf="availableAccounts.length > 0"
         >expand_more</i>
      </div>
   </div>
   <div
      #accountCogMenuItem
      class="account-menu-cog"
   >
      <ul>
         <li>
            <i class="material-icons">settings</i>
         </li>
      </ul>
   </div>
</div>
<lcs-overlay-panel
   #accountCogOverlay
   [(show)]="show"
   [parentElement]="accountCogMenuItem"
   [toggleOnClick]="true"
   [showOnClick]="true"
   [alignToRight]="true"
   [hideOnOutsideClick]="true"
   [hideOnBlur]="true"
>
   <owa-account-context-menu (menuItemClick)="show = false"></owa-account-context-menu>
</lcs-overlay-panel>

<lcs-overlay-panel
   *ngIf="availableAccounts.length > 0"
   #switchAccountsOverlay
   [(show)]="showAccountOverlay"
   [parentElement]="switchAccountsMenuItem"
   [toggleOnClick]="true"
   [showOnClick]="true"
   [alignToRight]="true"
   [hideOnOutsideClick]="true"
>
   <owa-switch-account
      [availableAccounts]="availableAccounts"
      (accountSwitched)="showAccountOverlay=false"
   ></owa-switch-account>
</lcs-overlay-panel>