import { ComponentPortal } from "@angular/cdk/portal";
import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject } from "rxjs";

@Directive({
   selector: "[lcsReportParametersSingleLineMultiSelectFiltersDirective]",
})
export class ReportParametersSingleLineMultiSelectFiltersDirective implements OnChanges, OnDestroy {
   @Input() parameter: ReportParameter;

   @Input() includeInactiveProspectStages: boolean;

   @Output() inactiveFilterValue = new EventEmitter<boolean>();

   private unsubscribe = new Subject<void>();

   private afterItemsPortal: ComponentPortal<any>;

   constructor() {}

   ngOnDestroy() {
      if (this.afterItemsPortal) {
         if (this.afterItemsPortal.isAttached) {
            this.afterItemsPortal.detach();
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ComponentPo... Remove this comment to see the full error message
         this.afterItemsPortal = null;
      }
      this.unsubscribe.next();
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.parameter) {
         this.processParameterChange();
      }
   }

   processParameterChange() {
      if (this.afterItemsPortal) {
         if (this.afterItemsPortal.isAttached) {
            this.afterItemsPortal.detach();
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ComponentPo... Remove this comment to see the full error message
         this.afterItemsPortal = null;
      }
   }
}
