import { Injectable, isDevMode, OnDestroy } from "@angular/core";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { Observable, Subject, takeUntil } from "rxjs";

import { SessionCacheProvider } from "./session-cache-provider.interface";

@Injectable({
   providedIn: "root",
})
export class CacheMonitorService implements OnDestroy {
   public get clearCaches(): Observable<void> {
      return this._clearCaches.asObservable();
   }

   public get loadCaches(): Observable<void> {
      return this._loadCaches.asObservable();
   }

   public get cachesLoaded(): Observable<void> {
      return this._cachesLoaded.asObservable();
   }

   public get cachesLoadedState(): boolean {
      return this._cachesLoadedState;
   }

   public get cacheLoadErrored(): Observable<void> {
      return this._cacheLoadErrored.asObservable();
   }

   public serviceCacheStates = new Map<string, boolean>();

   private unsubscribe = new Subject<void>();

   private cacheDisabled = false;

   private _clearCaches = new Subject<void>();

   private _loadCaches = new Subject<void>();

   private _cachesLoaded = new Subject<void>();

   private _cacheLoadErrored = new Subject<void>();

   private _cachesLoadedState = false;

   /** Maintain registered sessionCacheProviders */
   private sessionCacheProviderMap: Map<string, SessionCacheProvider> = new Map<string, SessionCacheProvider>();

   /**
    * Set of sessionCacheProviders which will not be autoSynched.  This is to support providers
    * may implement their own synching mechanism and therefore do not need auto-synched by
    * the CacheMonitorService.
    */
   private sessionCacheProviderDoNotAutoSync: Set<string> = new Set<string>();

   constructor(private localStorageService: LocalStorageService) {
      this.localStorageService.storageChanged$
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((storageEvent: StorageEvent) => {
            if (
               storageEvent.key &&
               this.sessionCacheProviderMap.has(storageEvent.key) &&
               !this.sessionCacheProviderDoNotAutoSync.has(storageEvent.key) &&
               storageEvent.newValue != null
            ) {
               if (isDevMode()) {
                  console.log(
                     `DevMode: localStorageService.storageChanged$ key="${storageEvent.key}" sessionCacheProvider.populateCache()`
                  );
               }
               const sessionCacheProvider = this.sessionCacheProviderMap.get(storageEvent.key);
               this.setServiceCacheState(storageEvent.key, false);
               sessionCacheProvider?.populateCache();
            }
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   clear() {
      this._cachesLoadedState = false;
      for (const stateKey of Array.from(this.serviceCacheStates.keys())) {
         this.serviceCacheStates.set(stateKey, false);
      }
      this._clearCaches.next();
      this.checkAllCachesLoaded();
   }

   load() {
      this._loadCaches.next();
      this.checkAllCachesLoaded();
   }

   setServiceCacheState(className: string, populated: boolean) {
      this.serviceCacheStates.set(className, populated);
      this.checkAllCachesLoaded();
   }

   reportError(className: string) {
      console.error(`Cache failed to load: ${className}`);
      this._cacheLoadErrored.next();
   }

   /** Registers SessionCacheProviders for autoSync (unless doNotAutoSync is specified).
    *  Also subscribes to their cachePopulated observables to auto-update the serviceCacheState.
    */
   registerSessionCacheProvider(sessionCacheProvider: SessionCacheProvider, doNotAutoSync: boolean = false) {
      this.sessionCacheProviderMap.set(sessionCacheProvider.cacheKey, sessionCacheProvider);
      sessionCacheProvider.cachePopulated.pipe(takeUntil(this.unsubscribe)).subscribe((cachePopulated: boolean) => {
         this.setServiceCacheState(sessionCacheProvider.cacheKey, cachePopulated);
      });
      if (doNotAutoSync) {
         this.sessionCacheProviderDoNotAutoSync.add(sessionCacheProvider.cacheKey);
      }
   }

   private checkAllCachesLoaded() {
      let allServiceCachesLoaded = true;

      for (const value of Array.from(this.serviceCacheStates.values())) {
         if (!value) {
            allServiceCachesLoaded = false;
            break;
         }
      }
      const cachesLoaded = this.cacheDisabled || allServiceCachesLoaded;

      if (cachesLoaded !== this._cachesLoadedState) {
         this._cachesLoadedState = cachesLoaded;
      }

      if (cachesLoaded) {
         this._cachesLoaded.next();
      }
   }
}
