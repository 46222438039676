import { ReportParameterComponents } from "../report-parameter-components.enum";
import { ReportReportParameterViewModel } from "./report-report-parameter.viewmodel";

export class ReportReportParameterComponentModel {
   ReportParameterComponent: ReportParameterComponents;
   ReportReportParameter: ReportReportParameterViewModel;
   GroupedReportReportParameters: Array<ReportReportParameterViewModel>;
   Order: number;
   RowSpan: number = 1;

   constructor() {
      this.GroupedReportReportParameters = new Array<ReportReportParameterViewModel>();
   }
}
