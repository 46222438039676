import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable, Subject } from "rxjs";

import { VendorModel } from "../models/generated/vendor.model";

@Injectable({ providedIn: "root" })
export class VendorsService implements OnDestroy {
   private unsubscribe = new Subject<void>();
   private endpoint = "vendors";

   constructor(private apiService: ApiService) {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }
   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/Get";
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }
   getCollection(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
   getUrl(
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters
      );
   }
   get(
      id?: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
