import { DatePipe, formatDate } from "@angular/common";
import { Injectable, Optional } from "@angular/core";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import isEqual from "lodash/isEqual";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { EntityRequestEndpointInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntitySearchConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration.model";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { ApiServiceHelpers } from "projects/libraries/owa-gateway-sdk/src/lib/core/api-service.helpers";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterHelper } from "projects/libraries/owa-gateway-sdk/src/lib/core/filter-helper";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { EnumerationInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/enumeration-information.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { AccountService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/account.service";
import { catchError, forkJoin, map, Observable, of, Subject, switchMap } from "rxjs";

import { ConstantsService } from "../../core/constants.service";
import { FilterValueType, isFilterValueType, isFilterValueTypeArray } from "../../filters/filter-value.types";
import { QuickFilterModel } from "../../filters/quick-filter.model";
import { DateRangeModel } from "../../inputs/date-range-picker/date-range.model";
import { isNumberRangeModel, NumberRangeModel } from "../../inputs/number-range-input/number-range.model";
import { ObjectMapResolverService } from "../../pipes/object-map-resolver.service";
import { EnumerationInformationService } from "../../utils/enumeration-information.service";
import { errorInDevMode } from "../../utils/logging";
import { PropertyGroupPropertyFilterModel } from "../property-group-property-selector/property-group-property-filter.model";
import { FilterInputValueType, isFilteInputValueEntityLink, isFilterInputValueDateOrString, isFilterInputValueDateRange } from "./datatable-filter-values.types";
import { DataTableFilterModel } from "./datatable-filter.model";

@Injectable({
   providedIn: "root",
})
export class DataTableFiltersService {
   filterRemoved = new Subject<FilterOption>();

   clearAllUDV = new Subject<Boolean>();

   constructor(
      private apiService: ApiService,
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private enumerationInformationService: EnumerationInformationService,
      @Optional() private entityInformationService: EntityInformationService,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private objectMapResolverService: ObjectMapResolverService
   ) {}

   getInvalidFilters(filters: Array<FilterOption>, dataTableFilters: Array<DataTableFilterModel>): Map<string, string> {
      const invalidFilters = new Map<string, string>();
      filters.forEach((f) => {
         const foundFilter = dataTableFilters.find(
            (dt) => f.FilterName.toLowerCase() === dt.FilterOption.FilterName.toLowerCase()
         );
         const duplicateFilters = filters.filter((x) => x.FilterName.toLowerCase() === f.FilterName.toLowerCase());
         if (duplicateFilters && duplicateFilters.length > 1) {
            invalidFilters.set(f.FilterName, "duplicate found for");
         } else if (!foundFilter) {
            invalidFilters.set(f.FilterName, "could not find");
         } else if (f.Operation == null) {
            invalidFilters.set(f.FilterName, "invalid operation for");
         }
      });
      return invalidFilters;
   }

   getInvalidQuickFilters(
      filters: Array<FilterOption>,
      dataTableQuickFilters: Array<QuickFilterModel>
   ): Map<string, string> {
      const invalidFilters = new Map<string, string>();

      filters.forEach((q) => {
         const foundFilter = dataTableQuickFilters.find((dt) => {
            return q.FilterName.toLowerCase() === dt.FilterName.toLowerCase();
         });
         const duplicateFilters = filters.filter((x) => x.FilterName.toLowerCase() === q.FilterName.toLowerCase());
         if (duplicateFilters && duplicateFilters.length > 1) {
            invalidFilters.set(q.FilterName, "duplicate found for");
         } else if (!foundFilter) {
            invalidFilters.set(q.FilterName, "could not find");
         } else {
            if (foundFilter.ControlType === ExpressLayoutControlTypes.DateRangePicker) {
               if (
                  ![
                     FilterOperations.LessThan,
                     FilterOperations.LessThanOrEqualTo,
                     FilterOperations.LessThanOrNull,
                     FilterOperations.LessThanOrEqualToOrNull,
                     FilterOperations.GreaterThan,
                     FilterOperations.GreaterThanOrEqualTo,
                     FilterOperations.GreaterThanOrNull,
                     FilterOperations.GreaterThanOrEqualToOrNull,
                     FilterOperations.NotEqualTo,
                     FilterOperations.EqualTo,
                     FilterOperations.In,
                     FilterOperations.NotIn,
                     FilterOperations.HasValue,
                     FilterOperations.Between,
                  ].some((filterOperation) => filterOperation === foundFilter.FilterOperation)
               ) {
                  invalidFilters.set(q.FilterName, "invalid operation for");
               }
            } else if (q.Operation !== foundFilter.FilterOperation) {
               invalidFilters.set(q.FilterName, "invalid operation for");
            }
         }
      });
      return invalidFilters;
   }

   getValuesFromType(values: Array<any>, controlType: ExpressLayoutControlTypes): any {
      return values.map((v) => this.getValueFromType(v, controlType));
   }

   getValueFromType(value: any, controlType: ExpressLayoutControlTypes): any {
      if (controlType === ExpressLayoutControlTypes.Checkbox) {
         return value.toString().toLowerCase() === "true";
      }
      return value;
   }

   // many filters require custom handling depending on the filter operation and value
   getQuickFilterExpression(entityType: EntityType, filters: Array<FilterOption>): Array<FilterOption> {
      if (filters) {
         const removeFilters = [];
         filters.forEach((filter, index) => {
            if (entityType === EntityType.Screening) {
               if (filter.FilterName.toLowerCase() === "type" && filter.Value && +filter.Value === EntityType.NotSet) {
                  // <All Types>
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                  removeFilters.push(index);
               }
            }
         });
         if (removeFilters.length > 0) {
            filters = filters.filter(function (f, index) {
               return !removeFilters.some((r) => r === index);
            });
         }
      }
      return filters;
   }

   buildAPIFilterValue(
      filterInputValue: FilterInputValueType | Array<FilterInputValueType>,
      controlType: ExpressLayoutControlTypes,
      dataType: ExpressDataTypes = ExpressDataTypes.String
   ): Array<FilterValueType> {
      if (filterInputValue == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      if (!this.filterInputControlTypeRequiresConversion(controlType)) {
         if (Array.isArray(filterInputValue)) {
            return filterInputValue.map((inputValue) =>
               FilterHelper.verifyAndConvertValueForDataType(inputValue as FilterValueType, dataType)
            );
         } else {
            return [FilterHelper.verifyAndConvertValueForDataType(filterInputValue as FilterValueType, dataType)];
         }
      }

      let filterValues: Array<FilterValueType> | null = null;

      if (Array.isArray(filterInputValue)) {
         switch (controlType) {
            case ExpressLayoutControlTypes.EnumMultiSelector:
            case ExpressLayoutControlTypes.EntityMultiSelector:
            case ExpressLayoutControlTypes.AccountMultiSelector:
               filterValues = this.convertFilterInputValuesForSelector(filterInputValue);
               break;
            default:
               errorInDevMode("Unhandled FilterInputValue Conversion Detected!");
         }
      } else {
         switch (controlType) {
            case ExpressLayoutControlTypes.DateRangePicker:
               filterValues = this.buildDateRangeFilterValues(filterInputValue);
               break;
            case ExpressLayoutControlTypes.DatePicker:
               filterValues = this.buildDateFilterValues(filterInputValue);
               break;
            case ExpressLayoutControlTypes.EnumMultiSelector:
            case ExpressLayoutControlTypes.EntityMultiSelector:
            case ExpressLayoutControlTypes.AccountMultiSelector:
               filterValues = this.convertFilterInputValueForSelector(filterInputValue);
               break;
            case ExpressLayoutControlTypes.PhoneNumberInput:
               filterValues = this.convertFilterInputValueForPhoneNumber(filterInputValue);
               break;
            case ExpressLayoutControlTypes.NumberRangeInput:
               filterValues = this.buildNumberRangeFilterValues(filterInputValue);
               break;
            case ExpressLayoutControlTypes.EntityLinkSelector:
               filterValues = this.convertFilterInputValueForEntityLinkSelector(filterInputValue);
               break;
            default:
               errorInDevMode("Unhandled FilterInputValue Conversion Detected!");
         }
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterValueType[] | null' is not assignable ... Remove this comment to see the full error message
      return filterValues;
   }

   buildDisplayValue(values: Array<FilterValueType> | null, controlType: ExpressLayoutControlTypes): string {
      let result = "";
      if (!values || values.length === 0) {
         return result;
      }
      if (controlType === ExpressLayoutControlTypes.DateRangePicker) {
         result = values.map((v) => formatDate(v.toString(), "MM/dd/yy", "en-US")).join(" - ");
      } else if (controlType === ExpressLayoutControlTypes.DatePicker) {
         result = formatDate(values[0].toString(), "MM/dd/yy", "en-US");
      } else if (controlType === ExpressLayoutControlTypes.PhoneNumberInput) {
         let phoneNbr = values[0].toString();
         phoneNbr = phoneNbr.replace("(", "");
         phoneNbr = phoneNbr.replace(")", "-");
         values = [phoneNbr];
      } else if (controlType === ExpressLayoutControlTypes.NumberRangeInput) {
         result = values.join(" - ");
      }
      return result;
   }

   convertFilterValue(
      currentValue: FilterInputValueType | Array<FilterInputValueType>,
      oldOperation: FilterOperations,
      newOperation: FilterOperations,
      filterControlType: ExpressLayoutControlTypes
   ): FilterInputValueType | Array<FilterInputValueType> {
      if (oldOperation === null) {
         return currentValue;
      }

      if (newOperation === null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterInput... Remove this comment to see the full error message
         return null;
      } else {
         const collectionOperations = [FilterOperations.In, FilterOperations.NotIn, FilterOperations.Between];

         const oldOperationIsCollection = collectionOperations.indexOf(oldOperation) > -1;
         const newOperationIsCollection = collectionOperations.indexOf(newOperation) > -1;

         if (oldOperationIsCollection && !newOperationIsCollection) {
            if (currentValue instanceof DateRangeModel) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Filt... Remove this comment to see the full error message
               currentValue = currentValue.startDate;
            } else if (currentValue instanceof NumberRangeModel) {
               currentValue = currentValue.min;
            } else {
               if (Array.isArray(currentValue) && currentValue.length > 0) {
                  currentValue = currentValue[0];
               }
            }
         } else if (newOperationIsCollection && !oldOperationIsCollection) {
            if (newOperation === FilterOperations.Between) {
               if (filterControlType === ExpressLayoutControlTypes.DateRangePicker) {
                  const value = new DateRangeModel();
                  if (currentValue instanceof Date) {
                     value.startDate = currentValue;
                  }
                  currentValue = value;
               } else if (filterControlType === ExpressLayoutControlTypes.NumberRangeInput) {
                  const value = new NumberRangeModel();
                  if (typeof currentValue === "number") {
                     value.min = currentValue;
                  }
                  currentValue = value;
               }
            } else {
               currentValue = [currentValue as FilterValueType];
            }
         } else if (filterControlType === ExpressLayoutControlTypes.DatePicker) {
            if (currentValue instanceof DateRangeModel) {
               if (
                  [
                     FilterOperations.LessThan,
                     FilterOperations.LessThanOrEqualTo,
                     FilterOperations.LessThanOrEqualToOrNull,
                     FilterOperations.LessThanOrNull,
                  ].some((f) => f === newOperation)
               ) {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Filt... Remove this comment to see the full error message
                  currentValue = currentValue.endDate;
               } else {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Filt... Remove this comment to see the full error message
                  currentValue = currentValue.startDate;
               }
            }
         }
      }
      return currentValue;
   }

   getDisplayLabelFromSelectionChange(selectionChange: SelectionChangeModel): string {
      let result = "";
      if (selectionChange) {
         // this.datatableFilterModel.FilterOption.Values = selectionChange.checkedItems.map(i => i.value);
         if (selectionChange.selectAll) {
            result = "all";
         } else {
            const selectedItems = selectionChange.checkedItems;
            if (selectedItems.length > 2) {
               result = `List of ${selectedItems.length}`;
            } else {
               result = selectionChange.checkedItems.map((i) => i.displayValue).join(", ");
            }
         }
      }
      return result;
   }

   getFilterOperationsForUDFType(
      availableOperations: Array<FilterOperationModel>,
      udfType: UserDefinedFieldType
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): Array<FilterOperationModel> {
      if (!availableOperations || availableOperations.length === 0) {
         return [];
      }
      switch (udfType) {
         case UserDefinedFieldType.Dropdown:
         case UserDefinedFieldType.YesNo:
            return availableOperations.filter((f) =>
               [FilterOperations.NotEqualTo, FilterOperations.EqualTo].some((fo) => fo === f.BackingEnumeration)
            );
         case UserDefinedFieldType.Text:
         case UserDefinedFieldType.File:
         case UserDefinedFieldType.Image:
            return availableOperations.filter((f) =>
               [
                  FilterOperations.NotEqualTo,
                  FilterOperations.EqualTo,
                  FilterOperations.Contains,
                  FilterOperations.StartsWith,
                  FilterOperations.EndsWith,
               ].some((fo) => fo === f.BackingEnumeration)
            );
         case UserDefinedFieldType.Date:
            return availableOperations.filter((f) =>
               [
                  FilterOperations.LessThan,
                  FilterOperations.LessThanOrEqualTo,
                  FilterOperations.GreaterThan,
                  FilterOperations.GreaterThanOrEqualTo,
                  FilterOperations.NotEqualTo,
                  FilterOperations.EqualTo,
                  FilterOperations.Between,
               ].some((fo) => fo === f.BackingEnumeration)
            );
         case UserDefinedFieldType.Numeric:
            return availableOperations.filter((f) =>
               [
                  FilterOperations.LessThan,
                  FilterOperations.LessThanOrEqualTo,
                  FilterOperations.GreaterThan,
                  FilterOperations.GreaterThanOrEqualTo,
                  FilterOperations.NotEqualTo,
                  FilterOperations.EqualTo,
                  FilterOperations.Between,
                  FilterOperations.Contains,
               ].some((fo) => fo === f.BackingEnumeration)
            );
      }
   }

   buildBoolSingleSelector(): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.StaticValues = [
         new SelectorItemModel(ConstantsService.unselected, "Don't Filter"),
         new SelectorItemModel(true, "True"),
         new SelectorItemModel(false, "False"),
      ];
      return valueSource;
   }

   getFilterDisplayValues(filters: Array<FilterOption>, dtFilters: Array<DataTableFilterModel>, entityID: number) {
      const displayValueObservables = new Array<Observable<any>>();
      dtFilters.forEach((dtFilter, dtFilterIndex) => {
         const filterIndex = filters.findIndex((f) => f.FilterName.toLowerCase() === dtFilter.FilterName.toLowerCase());
         if (filterIndex > -1) {
            dtFilters[dtFilterIndex].FilterOption.Operation = filters[filterIndex].Operation;
            dtFilters[dtFilterIndex].FilterOption.Values = this.getValuesFromType(
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterValueType[] | null' is not... Remove this comment to see the full error message
               filters[filterIndex].Values,
               dtFilter.ControlType
            );
            // copy dt filter because it contains correctly cased filter names
            filters[filterIndex] = dtFilter.FilterOption.clone();
            if (
               [FilterOperations.In, FilterOperations.NotIn].some(
                  (ct) => ct === dtFilters[dtFilterIndex].FilterOption.Operation
               )
            ) {
               if (
                  [ExpressLayoutControlTypes.EntitySelector, ExpressLayoutControlTypes.EntityMultiSelector].some(
                     (ct) => ct === dtFilters[dtFilterIndex].ControlType
                  )
               ) {
                  displayValueObservables.push(
                     this.getEntityMultiSelectorDisplayValue(
                        filterIndex,
                        dtFilterIndex,
                        dtFilters[dtFilterIndex],
                        entityID
                     )
                  );
               } else if (
                  [ExpressLayoutControlTypes.EnumSelector, ExpressLayoutControlTypes.EnumMultiSelector].some(
                     (ct) => ct === dtFilters[dtFilterIndex].ControlType
                  )
               ) {
                  displayValueObservables.push(
                     this.getEnumMultiSelectorDisplayValue(filterIndex, dtFilterIndex, dtFilters[dtFilterIndex])
                  );
               }
            } else if (
               [
                  ExpressLayoutControlTypes.EntitySelector,
                  ExpressLayoutControlTypes.ColorPicker,
                  ExpressLayoutControlTypes.TenantsAndProspectsSelector,
                  ExpressLayoutControlTypes.AccountSelector,
               ].some((ct) => ct === dtFilters[dtFilterIndex].ControlType)
            ) {
               displayValueObservables.push(
                  this.getEntitySelectorDisplayValue(filterIndex, dtFilterIndex, dtFilters[dtFilterIndex], entityID)
               );
            } else if (
               [ExpressLayoutControlTypes.DatePicker, ExpressLayoutControlTypes.DateRangePicker].some(
                  (ct) => ct === dtFilters[dtFilterIndex].ControlType
               )
            ) {
               // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
               const displayValue = dtFilter.FilterOption.Values.map((v) => {
                  if (typeof v === "boolean") {
                     throw new Error("Boolean values cannot be used with date filters");
                  }
                  if (v instanceof PropertyGroupPropertyFilterModel) {
                     throw new Error("PropertyGroupPropertyFilterModel values cannot be used with date filters");
                  }
                  // if (v instanceof RegisterDataTableSortModel) {
                  //    throw new Error("RegisterDataTableSortModel values cannot be used with date filters");
                  // }
                  return new DatePipe("en-US").transform(v, "MM/dd/yy");
               }).join(" - ");
               filters[filterIndex].DisplayValue = displayValue;
               dtFilters[dtFilterIndex].FilterOption.DisplayValue = displayValue;
            } else if (dtFilters[dtFilterIndex].ControlType === ExpressLayoutControlTypes.EnumSelector) {
               displayValueObservables.push(
                  this.getEnumSelectorDisplayValue(filterIndex, dtFilterIndex, dtFilters[dtFilterIndex])
               );
            } else {
               filters[filterIndex].DisplayValue = "";
               dtFilters[dtFilterIndex].FilterOption.DisplayValue = "";
            }
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterOpera... Remove this comment to see the full error message
            dtFilters[dtFilterIndex].FilterOption.Operation = null;

            dtFilters[dtFilterIndex].FilterOption.Values = null;
         }
      });

      if (displayValueObservables.length > 0) {
         return forkJoin(displayValueObservables).pipe(
            switchMap((values: Array<{ filterIndex: number; dataTableFilterIndex: number; value: string }>) => {
               values.forEach((value) => {
                  if (value) {
                     filters[value.filterIndex].DisplayValue = value.value;
                     dtFilters[value.dataTableFilterIndex].FilterOption.DisplayValue = value.value;
                  }
               });
               return of({ filters: filters, dtFilters: dtFilters });
            })
         );
      }
      return of({ filters: filters, dtFilters: dtFilters });
   }

   private buildDateFilterValues(filterInputValue: FilterInputValueType): Array<Date> {
      if (filterInputValue == null || filterInputValue === "") {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         return null;
      }

      if (!isFilterInputValueDateOrString(filterInputValue)) {
         errorInDevMode("Invalid input value type for date filter", filterInputValue);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         return null;
      }

      const filterValues = new Array<Date>();
      if (filterInputValue instanceof Date) {
         filterValues.push(new Date(filterInputValue));
      } else {
         filterValues.push(new Date(filterInputValue));
      }

      return filterValues;
   }

   private buildDateRangeFilterValues(dateRange: FilterInputValueType): Array<Date> {
      if (dateRange == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         return null;
      }

      if (!isFilterInputValueDateRange(dateRange)) {
         errorInDevMode("Invalid input value type for date range filter", dateRange);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         return null;
      }

      if (!dateRange.startDate && !dateRange.endDate) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         return null;
      }

      const filterValues = new Array<Date>();
      if (dateRange.startDate) {
         filterValues.push(new Date(dateRange.startDate));
      }
      if (dateRange.endDate) {
         filterValues.push(new Date(dateRange.endDate));
      }

      return filterValues;
   }

   private buildNumberRangeFilterValues(numberRange: FilterInputValueType): Array<number> {
      if (numberRange == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number[]'.
         return null;
      }

      if (!isNumberRangeModel(numberRange)) {
         errorInDevMode("Invalid input value type for number range filter", numberRange);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number[]'.
         return null;
      }

      const filterValues = new Array<number>();
      if (numberRange) {
         if (numberRange.min !== null && numberRange.min !== undefined) {
            filterValues.push(numberRange.min);
         }
         if (numberRange.max !== null && numberRange.max !== undefined) {
            filterValues.push(numberRange.max);
         }
      }
      return filterValues;
   }

   private convertFilterInputValueForSelector(filterInputValue: FilterInputValueType): Array<FilterValueType> {
      if (!filterInputValue) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      if (!isFilterValueType(filterInputValue)) {
         errorInDevMode("Invalid input value type for selector filter input", filterInputValue);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      return [filterInputValue];
   }

   private convertFilterInputValuesForSelector(filterInputValues: Array<FilterInputValueType>): Array<FilterValueType> {
      if (!filterInputValues) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      if (!isFilterValueTypeArray(filterInputValues)) {
         errorInDevMode("Invalid input value type array for selector filter input", filterInputValues);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      return [...filterInputValues];
   }

   private convertFilterInputValueForPhoneNumber(filterInputValue: FilterInputValueType): Array<FilterValueType> {
      if (filterInputValue == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      if (!(typeof filterInputValue === "string")) {
         errorInDevMode("Invalid input value type for selector filter input", filterInputValue);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      let phoneNbr = filterInputValue;
      phoneNbr = phoneNbr.replace("(", "");
      phoneNbr = phoneNbr.replace(")", "-");

      return [phoneNbr];
   }

   private convertFilterInputValueForEntityLinkSelector(
      filterInputValue: FilterInputValueType
   ): Array<FilterValueType> {
      if (filterInputValue == null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      if (!isFilteInputValueEntityLink(filterInputValue)) {
         errorInDevMode("Invalid input value type for selector filter input", filterInputValue);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
         return null;
      }

      return [filterInputValue.entityID];
   }

   /**
    * Determines whether or not a FilterInputValue from the specified LayoutControlType would need additional handling prior to
    * being assigned to a variable of FilterValueType
    * @param layoutControlType the ExpressLayoutControlType in question
    *
    * @returns true/false
    */
   private filterInputControlTypeRequiresConversion(layoutControlType: ExpressLayoutControlTypes): boolean {
      switch (layoutControlType) {
         case ExpressLayoutControlTypes.DatePicker:
         case ExpressLayoutControlTypes.DateRangePicker:
         case ExpressLayoutControlTypes.PhoneNumberInput:
         case ExpressLayoutControlTypes.EntityMultiSelector:
         case ExpressLayoutControlTypes.EnumMultiSelector:
         case ExpressLayoutControlTypes.AccountMultiSelector:
         case ExpressLayoutControlTypes.NumberRangeInput:
         case ExpressLayoutControlTypes.EntityLinkSelector:
            return true;
      }

      return false;
   }

   private getEnumMultiSelectorDisplayValue(
      filterIndex: number,
      dataTableFilterIndex: number,
      dtFilter: DataTableFilterModel
   ): Observable<{ filterIndex: number; dataTableFilterIndex: number; value: string }> {
      const retValue = { filterIndex: filterIndex, dataTableFilterIndex: dataTableFilterIndex, value: "" };
      if (!dtFilter.FilterOption.Values || dtFilter.FilterOption.Values.length === 0) {
         return of(retValue);
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<{ filterIndex: number; dataTableF... Remove this comment to see the full error message
      return this.enumerationInformationService
         .getEnumerationInformationForValues(
            dtFilter.EnumerationType,
            dtFilter.FilterOption.Values.map((v) => +v)
         )
         .pipe(
            map((results: EnumerationInformationModel[]) => {
               let displayValue = "";
               if (results.length > 2) {
                  displayValue = `List of ${results.length}`;
               } else {
                  displayValue = results.map((r) => r.Description).join(", ");
               }
               retValue.value = displayValue;
               return retValue;
            }),
            catchError(() => {
               return of(null);
            })
         );
   }

   private getEntityMultiSelectorDisplayValue(
      filterIndex: number,
      dataTableFilterIndex: number,
      dtFilter: DataTableFilterModel,
      entityID: number
   ): Observable<{ filterIndex: number; dataTableFilterIndex: number; value: string }> {
      const retValue = { filterIndex: filterIndex, dataTableFilterIndex: dataTableFilterIndex, value: "" };
      if (
         dtFilter.EntityType == null ||
         dtFilter.EntityType === EntityType.NotSet ||
         dtFilter.EntityType === EntityType.Unassociated ||
         dtFilter.FilterOption.Value == null
      ) {
         return of(retValue);
      }
      const entityTypeInfo = this.entityInformationService.getEntityTypeInformation(dtFilter.EntityType);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<{ filterIndex: number; dataTableF... Remove this comment to see the full error message
      return forkJoin([
         this.entityViewInformationService.getViewInformation(dtFilter.EntityType),
         this.entityRequestEndpointService.getEndpointInformation(dtFilter.EntityType),
         this.entitySearchConfigurationService.getSearchConfiguration(dtFilter.EntityType),
      ]).pipe(
         switchMap(([viewInfo, requestInfo, searchConfiguration]) => {
            const valueSource = this.buildEntitySelectorValueSource(
               viewInfo,
               requestInfo,
               searchConfiguration,
               dtFilter,
               entityID
            );
            if (dtFilter.FilterOption.Values && dtFilter.FilterOption.Values.length > 0) {
               const apiUrl = this.resolveApiUrl(valueSource);
               if (!apiUrl) {
                  return of(retValue);
               }
               const apiURL = entityTypeInfo.ApiUrlString;
               const filterExpression = new FilterExpression();
               filterExpression.FilterOptions.push(
                  new FilterOption(dtFilter.PropertyName, dtFilter.FilterOption.Operation, dtFilter.FilterOption.Values)
               );
               return this.apiService
                  .getCollection(
                     apiURL,
                     filterExpression,
                     [],
                     [],
                     this.objectMapResolverService.processEntityValueSourcePathInformation(valueSource)
                  )
                  .pipe(
                     switchMap((results) => {
                        let displayValue = "";
                        if (results.length > 2) {
                           displayValue = `List of ${results.length}`;
                        } else {
                           displayValue = results
                              .map((r) =>
                                 this.objectMapResolverService.processEntitySourceDisplayValueResults(valueSource, r)
                              )
                              .join(", ");
                        }
                        retValue.value = displayValue;
                        return of(retValue);
                     }),
                     catchError(() => {
                        return of(null);
                     })
                  );
            }
            return of(retValue);
         })
      );
   }

   private buildEntitySelectorValueSource(
      entityInformation: EntityViewInformationModel,
      entityRequestEndpointInformationModel: EntityRequestEndpointInformationModel,
      entitySearchConfiguration: EntitySearchConfigurationModel,
      dtFilter: DataTableFilterModel,
      entityID: number
   ): ValueSourceModel {
      if (
         dtFilter.EntityType === null ||
         dtFilter.EntityType === undefined ||
         dtFilter.EntityType === EntityType.NotSet ||
         dtFilter.EntityType === EntityType.Unassociated
      ) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'ValueS... Remove this comment to see the full error message
         return;
      }
      const valueSource = new ValueSourceModel();
      valueSource.EndpointIsSearch = entityRequestEndpointInformationModel.IsSearchEnabled;
      valueSource.IsQuickSearchEnabled = entityRequestEndpointInformationModel.IsSearchEnabled;
      valueSource.Endpoint = this.entityInformationService.buildEntityEndpoint(
         dtFilter.EntityType,
         "",
         entityID,
         false
      );
      if (valueSource.IsQuickSearchEnabled) {
         valueSource.QuickSearchEndpoint = this.entityInformationService.buildEntityEndpoint(
            dtFilter.EntityType,
            "",
            entityID,
            true
         );
      }
      // TODO: Extract fields from templates
      valueSource.Embeds = ApiServiceHelpers.extractEmbeds([
         entityInformation.EntityPrimaryKeyPropertyPath,
         entityInformation.EntityDisplayTemplate,
         entityInformation.AdditionalInformationDisplayTemplate,
      ]);
      valueSource.Label = entityInformation.SingleLabel;

      valueSource.EntityValueSourcePath = entityInformation.EntityPrimaryKeyPropertyPath;
      valueSource.DisplayValueSourcePath = entityInformation.EntityListItemTemplate;
      valueSource.StaticValues = entityInformation.AdditionalSelectorItems;
      valueSource.AdditionalInfoSourcePath = entityInformation.AdditionalInformationDisplayTemplate;
      valueSource.SearchFields = entitySearchConfiguration.DefaultSearchFields;
      return valueSource;
   }

   private getEntitySelectorDisplayValue(
      filterIndex: number,
      dataTableFilterIndex: number,
      dtFilter: DataTableFilterModel,
      entityID: number
   ): Observable<{ filterIndex: number; dataTableFilterIndex: number; value: string }> {
      const retValue = { filterIndex: filterIndex, dataTableFilterIndex: dataTableFilterIndex, value: "" };
      if (
         dtFilter.ControlType !== ExpressLayoutControlTypes.TenantsAndProspectsSelector &&
         (dtFilter.EntityType == null ||
            dtFilter.EntityType === EntityType.NotSet ||
            dtFilter.EntityType === EntityType.Unassociated ||
            dtFilter.FilterOption.Value == null)
      ) {
         return of(retValue);
      }
      return forkJoin([
         this.entityViewInformationService.getViewInformation(dtFilter.EntityType),
         this.entityRequestEndpointService.getEndpointInformation(dtFilter.EntityType),
         this.entitySearchConfigurationService.getSearchConfiguration(dtFilter.EntityType),
      ]).pipe(
         switchMap(([viewInfo, requestInfo, searchConfiguration]) => {
            let valueSource = new ValueSourceModel();
            if (dtFilter.ControlType === ExpressLayoutControlTypes.TenantsAndProspectsSelector) {
               valueSource = this.buildTenantProspectSelectorValueSource(dtFilter);
            } else {
               valueSource = this.buildEntitySelectorValueSource(
                  viewInfo,
                  requestInfo,
                  searchConfiguration,
                  dtFilter,
                  entityID
               );
            }
            if (valueSource.StaticValues && valueSource.StaticValues.length > 0) {
               const defaultValue = valueSource.StaticValues[0].value;
               const defaultDisplayValue = valueSource.StaticValues[0].displayValue;
               if (isEqual(defaultValue, dtFilter.FilterOption.Value)) {
                  retValue[filterIndex] = defaultDisplayValue;
                  return of(retValue);
               }
            }
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            if (valueSource.AllValue && valueSource.AllValue === dtFilter.FilterOption.Value.toString()) {
               let allValue = "";
               if (viewInfo) {
                  allValue = viewInfo.CollectionLabel;
               }
               retValue.value = `All ${allValue}`.trim();
               return of(retValue);
            }
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            if (dtFilter.FilterOption.Value.toString() !== "-1") {
               let apiUrl = this.resolveApiUrl(valueSource);
               if (!apiUrl) {
                  return of(retValue);
               }
               let observable: Observable<any>;
               if (valueSource.IsSubItem) {
                  observable = this.apiService
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     .getSingleSubItem(apiUrl, +dtFilter.FilterOption.Value, valueSource.Embeds)
                     .pipe(
                        catchError(() => {
                           return of(null);
                        })
                     );
               } else {
                  if (valueSource.ItemEndpoint) {
                     apiUrl = valueSource.ItemEndpoint;
                  }
                  // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                  observable = this.apiService.getSingle(apiUrl, +dtFilter.FilterOption.Value, valueSource.Embeds).pipe(
                     catchError(() => {
                        return of(null);
                     })
                  );
               }
               return observable.pipe(
                  switchMap((result) => {
                     if (result) {
                        retValue.value = this.objectMapResolverService.processEntitySourceDisplayValueResults(
                           valueSource,
                           result
                        );
                        return of(retValue);
                     } else {
                        return of(retValue);
                     }
                  })
               );
            }
            return of(retValue);
         })
      );
   }

   private buildTenantProspectSelectorValueSource(dtFilter: DataTableFilterModel): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Entity;
      valueSource.Endpoint = `${AccountService.endpoint}/${AccountService.TenantsAndProspectsResource}`;
      valueSource.DisplayValueSourcePath = "Name";
      valueSource.EntityValueSourcePath = "AccountID";
      valueSource.Filters = new Array<FilterOption>();
      valueSource.Filters.push(dtFilter.FilterOption);
      const searchFields = new Array<FilterField>();
      const filterField = new FilterField();
      filterField.FilterName = valueSource.DisplayValueSourcePath;
      searchFields.push(filterField);
      valueSource.SearchFields = searchFields;
      return valueSource;
   }

   private resolveApiUrl(valueSource: ValueSourceModel): string {
      let apiUrl = "";
      if (valueSource.Endpoint) {
         apiUrl = valueSource.Endpoint;
      } else {
         apiUrl = this.entityInformationService.buildEntityEndpoint(
            valueSource.EntityType,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            valueSource.IsQuickSearchEnabled
         );
      }
      return apiUrl;
   }

   private getEnumSelectorDisplayValue(
      filterIndex: number,
      dataTableFilterIndex: number,
      dtFilter: DataTableFilterModel
   ): Observable<{ filterIndex: number; dataTableFilterIndex: number; value: string }> {
      const retValue = { filterIndex: filterIndex, dataTableFilterIndex: dataTableFilterIndex, value: "" };
      if (!dtFilter.FilterOption.Value) {
         return of(retValue);
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<{ filterIndex: number; dataTableF... Remove this comment to see the full error message
      return this.enumerationInformationService
         .getEnumerationInformationForValue(dtFilter.EnumerationType, +dtFilter.FilterOption.Value)
         .pipe(
            map((result: EnumerationInformationModel) => {
               let displayValue = "";
               if (result) {
                  displayValue = result.Description;
               }
               retValue.value = displayValue;
               return retValue;
            }),
            catchError(() => {
               return of(null);
            })
         );
   }
}
