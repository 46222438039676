import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { map, Observable } from "rxjs";

import { EstimateDetailService } from "../estimate-detail.service";

@Component({
   selector: "owa-estimate-history-list-view-more",
   templateUrl: "./estimate-history-list-view-more.component.html",
})
export class EstimateHistoryListViewMoreComponent {
   historyItems: Observable<HistoryModel[]>;

   loading: Observable<boolean>;

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      estimateDetailService: EstimateDetailService
   ) {
      this.historyItems = estimateDetailService.estimate.pipe(map((estimates) => estimates.History));
      this.loading = estimateDetailService.loading;
   }

   onCloseClick(): void {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
   }
}
