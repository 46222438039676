<div class="page">
   <div
      class="page-header"
      #pageHeader
   >
      <owa-top-bar></owa-top-bar>
   </div>
   <div
      cdkScrollable
      class="page-content"
   >
      <owa-side-menu></owa-side-menu>
      <lcs-loading-overlay>
         <div class="site-content">
            <router-outlet></router-outlet>
         </div>
      </lcs-loading-overlay>
   </div>
   <div class="CopyrightFooter">
      Powered by Rent Manager © {{currentYear}}
   </div>
</div>