<div class="account-context-menu-container">
   <ul class="account-context-menu">
      <li>
         <div class="account-context-menu-item owner-menu-info">
            <div
               *ngIf="isOwner"
               class="owner-name"
            >{{ currentOwner.Name }}</div>
            <div
               *ngIf="!isOwner"
               class="owner-name"
            >{{ currentOwnerProspect.Name }}</div>
            <div class="owner-email">{{ currentUser.UserName }}</div>
         </div>
      </li>
      <li>
         <lcs-link
            [cssClasses]="menuItemClasses"
            (clicked)="openUserProfilePage()"
            text="User Profile"
            id="ChangePasswordEmailAddress"
         >
         </lcs-link>
      </li>
      <li>
         <lcs-link
            [cssClasses]="menuItemClasses"
            (clicked)="openAccountInformationDialog()"
            [text]="personalInfoLabel"
            id="PersonalInformation"
         >
         </lcs-link>
      </li>
      <li *ngIf="availableAccounts != null && availableAccounts.length > 1">
         <lcs-link
            [cssClasses]="menuItemClasses"
            (clicked)="openLinkedAccountManagementDialog()"
            text="Linked Account Management"
            id="LinkedAccountManagement"
         ></lcs-link>
      </li>
      <li>
         <a
            id="SignOut"
            [ngClass]="menuItemClasses"
            (click)="logOut()"
         ><i class="material-icons sign-out">exit_to_app</i> Sign Out</a>
      </li>
   </ul>
</div>