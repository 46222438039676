import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LinkModule } from "@lcs/link/link.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { TableModule } from "primeng/table";

import { ActionLinkModule } from "../action-link/action-link.module";
import { InputsModule } from "../inputs/inputs.module";
import { OverlayPanelsModule } from "../overlay-panel/overlay-panels.module";
import { SideBarMenuListComponent } from "./side-bar-menu-list.component";
import { SideBarMenuComponent } from "./side-bar-menu.component";

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      LinkModule,
      ActionLinkModule,
      InputsModule,
      LoadingOverlayModule,
      TableModule,
      OverlayPanelsModule,
   ],
   declarations: [SideBarMenuComponent, SideBarMenuListComponent],
   exports: [SideBarMenuComponent],
})
export class SideBarMenuModule {}
