import { Component } from "@angular/core";
import { DialogContentComponent } from "@lcs/dialog/dialog-content-component.interface";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { WindowService } from "@lcs/viewport/window.service";

@Component({
   selector: "owa-routing-number-info",
   templateUrl: "./routing-number-info.component.html",
   styleUrls: ["./routing-number-info.component.css"],
})
export class RoutingNumberInfoComponent implements DialogContentComponent {
   public configuration = new DialogConfigurationModel();

   constructor(private currentDialogService: CurrentDialogService, private windowService: WindowService) {}

   onOkClick(): void {
      this.currentDialogService.closeDialog();
   }

   onImageLoaded() {
      this.windowService.resized.next();
   }
}
