<owa-layout
   [headerTitle]="'Reports'"
   [backgroundColorClass]="'detail-layout'"
>
   <div class="reports-list-search-container">
      <label>Search</label>
      <input
         type="text"
         [(ngModel)]="searchText"
         placeholder="search for reports here"
         name="reports-list-search"
      />
   </div>
   <div class="flex-wrapper">
      <div
         *ngFor="let menuItem of reportsList"
         class="flex-fifth report-flex"
      >
         <a routerLink="/{{menuItem.Route}}">
            <div
               class="reports-list-item"
               [pTooltip]="menuItem.Tooltip"
               [showDelay]="250"
            >
               {{menuItem.Name}}
            </div>
         </a>
      </div>
   </div>
</owa-layout>