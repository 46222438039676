<div class="report-parameter-label">
   <lcs-checkbox
      [name]="name"
      [displayName]="parameter?.PromptText || parameter?.DisplayName || displayName"
      (ngModelChange)="valueChanged($event)"
      [ngModel]="checked"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ $any(parameter.PromptText?.length) > 0 ? parameter.PromptText : parameter.DisplayName }}
   </lcs-checkbox>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>