import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { UIDService } from "@lcs/unique-ids/uid.service";

import { RadioButtonRegistry } from "./radio-button-registry";

@Component({
   selector: "lcs-radio-button",
   templateUrl: "radio-button.component.html",
   styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent extends ValueAccessorBase<any> implements OnDestroy {
   public uniqueId: string = `radioButton-${UIDService.getUID()}`;

   @ViewChild("rb", { static: true }) element: ElementRef;

   @Input() inputId: string;

   @Input() label: string;

   @Input() labelStyleClass: string;

   @Input() style: any;

   @Input() styleClass: string;

   @Input() tabindex: number;

   @Input() disabled: boolean;

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("value") thisValue: any; // renamed to avoid conflict with ValueAccessorBase

   @Input() tooltip: string = "";

   checked: boolean;

   focused: boolean;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      private radioButtonRegistry: RadioButtonRegistry,
      private renderer2: Renderer2,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.radioButtonRegistry.add(ngControl, this);
      if (!this.inputId) {
         if (this.path && this.thisValue) {
            this.inputId = this.path + this.thisValue;
         } else {
            this.inputId = this.uniqueId;
         }
      }
      this.registerOnValueWritten((value: any) => {
         this.checked = value === this.thisValue;
         this.innerValue = this.checked;
         this.renderer2.setProperty(this.element.nativeElement, "checked", this.checked);
      });
      this.registerOnChange(() => {
         this.radioButtonRegistry.select(this);
      });
   }

   ngOnDestroy() {
      this.radioButtonRegistry.remove(this);
   }

   fireUncheck(value: any) {
      this.writeValue(value);
   }

   onFocus() {
      this.focused = true;
   }

   onBlur() {
      this.focused = false;
      this.propagateTouched();
   }

   onChange() {
      if (!this.disabled) {
         this.value = this.thisValue;
         this.checked = true;
      }
   }
}
