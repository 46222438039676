import { Component } from "@angular/core";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";

@Component({
   selector: "lcs-error-dialog-footer",
   templateUrl: "error-dialog-footer.component.html",
})
export class ErrorDialogFooterComponent {
   constructor(private currentDialogService: CurrentDialogService) {}

   onOkClick(): void {
      this.currentDialogService.closeDialog();
   }
}
