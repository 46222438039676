import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { fromEvent, Subject, takeUntil, throttleTime } from "rxjs";

import { ScrollableElementDimensions } from "./scrollable-element-dimensions.interface";

@Directive({
   selector: "[lcsInfiniteScroll]",
   host: { class: "lcs-infinite-scroll" },
})
export class InfiniteScrollDirective implements AfterViewInit, OnInit, OnDestroy {
   @Input()
   set lcsInfiniteScrollThreshold(threshold: number | string) {
      this._threshold = threshold;
      if (typeof threshold === "string") {
         this.threshold = parseInt(threshold);
         this.thresholdIsPercentage = threshold.trim().endsWith("%");
      } else if (typeof threshold === "number") {
         this.threshold = threshold;
         this.thresholdIsPercentage = false;
      }
   }
   get lcsInfiniteScrollThreshold(): number | string {
      return this._threshold;
   }

   @Output() infiniteScroll = new EventEmitter<ScrollableElementDimensions>();

   private _threshold: number | string;

   private threshold: number;

   private thresholdIsPercentage: boolean;

   private unsubscribe = new Subject<void>();

   constructor(private elementRef: ElementRef) {}

   ngOnInit(): void {
      fromEvent(this.elementRef.nativeElement, "scroll")
         .pipe(throttleTime(100, undefined, { trailing: true }), takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.checkPosition(this.elementRef.nativeElement);
         });
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.infiniteScroll.emit({
            clientHeight: this.elementRef.nativeElement.clientHeight,
            scrollHeight: this.elementRef.nativeElement.scrollHeight,
         });
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   checkPosition(element: HTMLElement): void {
      const distanceToBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
      let thresholdPassed: boolean;
      if (this.thresholdIsPercentage) {
         const percentageScrolled = Math.round(
            (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100
         );

         // Percentage math can be tricky due to the exact timing and calculation of positions.
         // We want to trigger loading if the user is within 10% of the bottom of the screen, to make sure the user won't end up with a register that won't load more items
         const percentageBuffer = 10;

         thresholdPassed = percentageScrolled + percentageBuffer >= this.threshold;
      } else {
         thresholdPassed = distanceToBottom <= this.threshold;
      }

      if (thresholdPassed) {
         this.infiniteScroll.emit({
            clientHeight: element.clientHeight,
            scrollHeight: element.scrollHeight,
         });
      }
   }
}
