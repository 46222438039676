import { EstimateDetailModel } from "./generated/estimate-detail.model";
import { PropertyModel } from "./generated/property.model";
import { UnitModel } from "./generated/unit.model";

export class EstimateDetailViewModel extends EstimateDetailModel {
   Property: PropertyModel;
   Unit: UnitModel;
   Price: number;
   TotalValue: number;
}
