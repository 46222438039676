export enum DateRangePickerOtherOption {
   OtherOptions = 0,
   LastQuarter = 1,
   ThisQuarter = 2,
   NextQuarter = 3,
   LastFiscalYear = 4,
   ThisFiscalYear = 5,
   NextFiscalYear = 6,
   FiscalYearToDate = 7,
   Previous12Months = 8,
   Next12Months = 9,
   SinceGLStartDate = 10,
}
