import { ChangeDetectorRef, Component } from "@angular/core";
import { NgControl } from "@angular/forms";

import { SecureInputBase } from "./secure-input-base";

@Component({
   selector: "lcs-secure-annual-income-input",
   templateUrl: "./secure-input-view.html",
})
export class SecureAnnualIncomeInputComponent extends SecureInputBase {
   constructor(protected changeDetectorRef: ChangeDetectorRef, public control: NgControl) {
      super(changeDetectorRef, control);
      this.defaultMaskValue = "XXXXXXXXXX";
   }
}
