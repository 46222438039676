import { NgModule } from "@angular/core";

import { FormRegistrationBaseService } from "./form-registration.base.service";
import { FormRegistrationDirective } from "./form-registration.directive";
import { FormRegistrationService } from "./form-registration.service";

@NgModule({
   declarations: [FormRegistrationDirective],
   exports: [FormRegistrationDirective],
   providers: [FormRegistrationService, FormRegistrationBaseService],
})
export class FormRegistrationModule {}
