import { Component, Input } from "@angular/core";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";

import { FileCarouselComponent } from "../../file-carousel/file-carousel.component";
import { FileCarouselConfigurationModel } from "../../file-carousel/models/file-carousel-configuration.model";
import { FileCarouselModel } from "../../file-carousel/models/file-carousel.model";

@Component({
   selector: "owa-estimate-history-list",
   templateUrl: "./estimate-history-list.component.html",
})
export class EstimateHistoryListComponent {
   @Input() totalCount: number; // TODO: use this

   @Input() historyItems: Array<HistoryModel>;

   historyColumns: string[] = ["Date", "Note", "File"];
   images: Map<number, Array<FileCarouselModel>>;

   constructor(private dialogCreationService: DialogCreationService) {}

   openCarousel(historyID: number, note: string) {
      if (
         this.historyItems != null &&
         this.historyItems.length > 0 &&
         (this.images == null || this.images.size === 0)
      ) {
         const historyWithFile = this.historyItems.filter(
            (historyItem) => historyItem.Attachments !== undefined && historyItem.Attachments.length > 0
         );
         if (historyWithFile.length > 0) {
            const imageMap = new Map<number, Array<FileCarouselModel>>();
            const imageExtensions = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tif", ".tiff"];
            historyWithFile.forEach(function(historyItem) {
               const imageList = new Array<FileCarouselModel>();
               historyItem.Attachments.forEach(function(file) {
                  const image = new FileCarouselModel();
                  image.isImage = imageExtensions.some(function(element) {
                     return element === file.Extension.toLowerCase();
                  });
                  image.source = image.fileLink = file.DownloadURL;
                  image.extension = file.Extension;
                  image.alt = "No preview available";
                  image.name = file.Name;
                  imageList.push(image);
               });
               imageMap.set(historyItem.HistoryID, imageList);
            });
            this.images = imageMap;
         }
      }
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = FileCarouselComponent;
      dialogConfigModel.disableClose = false;
      dialogConfigModel.title = note;

      const fileConfigModel = new FileCarouselConfigurationModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FileCarouselModel[] | undefined' is not assi... Remove this comment to see the full error message
      fileConfigModel.FileSource = this.images.get(historyID);
      dialogConfigModel.styleClass = fileConfigModel.FileSource.every(function(value, index) {
         return value.isImage;
      })
         ? "image-carousel"
         : "file-carousel";
      this.dialogCreationService.open(dialogConfigModel, fileConfigModel);
   }
}
