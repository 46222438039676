import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { Countries } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/countries.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";
import { OwnerContributionPaymentDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-paymentdetail-view.model";
import { OwnerContributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-contributions.service";
import { Subject, takeUntil } from "rxjs";

import { CompletedComponent } from "../Completed/completed.component";
import { ConstantsService } from "./../../../../../libraries/owa-gateway-sdk/src/lib/services/constants.service";

@Component({
   selector: "owa-epay",
   templateUrl: "./epay.component.html",
   styleUrls: ["./epay.component.css"],
})
export class EpayComponent implements OnInit {
   buttonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   public unsubscribe: Subject<void> = new Subject<void>();
   public accountNumber: string;
   public accountType: string = "epay";
   public cardType: string = "";
   public typeLabel: string;
   public configuration: OwnerContributionPaymentDetailViewModel;
   public ownerContributionPaymentModel = new OwnerContributionPaymentDetailViewModel();
   public unitedStates: boolean;
   paymentMethod: string;
   isShowExpirationMonth: boolean = false;
   showDateYear: string;
   checked: boolean;

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(refresh: b... Remove this comment to see the full error message
   valueCallBack: (refresh: boolean) => void = null;

   constructor(
      private ownerContributionService: OwnerContributionsService,
      private dialogueService: CurrentDialogService,
      private dialogCreationService: DialogCreationService,
      private errorMessageService: ErrorMessageService,
      public constantsService: ConstantsService
   ) {}

   ngOnInit() {
      this.ownerContributionPaymentModel = this.configuration;
      this.unitedStates = this.ownerContributionPaymentModel.BankCountryID === Countries.UnitedStates ? true : false;
      this.paymentMethod = EpayAccountType[this.ownerContributionPaymentModel.EpayInfo.PaymentMethod];
      this.accountNumber = "x".repeat(12) + this.ownerContributionPaymentModel.EpayInfo.AccountNumberLastFourDigits;
      this.checked = this.ownerContributionPaymentModel.IsDisclaimerChecked;

      if (
         this.ownerContributionPaymentModel.EpayInfo.PaymentMethod !== EpayAccountType.Checking &&
         this.ownerContributionPaymentModel.EpayInfo.PaymentMethod !== EpayAccountType.BusinessChecking &&
         this.ownerContributionPaymentModel.EpayInfo.PaymentMethod !== EpayAccountType.GenericACH &&
         this.ownerContributionPaymentModel.EpayInfo.PaymentMethod !== EpayAccountType.Savings
      ) {
         this.accountType = "CC";
         this.isShowExpirationMonth = true;
         this.typeLabel = "Card ";
         this.showDateYear =
            this.ownerContributionPaymentModel.EpayInfo.ExpirationMonth +
            "/" +
            this.ownerContributionPaymentModel.EpayInfo.ExpirationYear;
         if (this.ownerContributionPaymentModel.EpayInfo.IsDebitCard) {
            this.cardType = " Debit Card";
         } else {
            this.cardType = " Credit Card";
         }
      } else {
         this.accountType = "ACH";
         this.typeLabel = "Account ";
      }
   }
   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.buttonStatus = LcsProgressButtonStatus.InProgress;
         this.ownerContributionService
            .SaveContributions(this.ownerContributionPaymentModel)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (result) => {
                  if (result) {
                     this.dialogueService.closeDialog();
                     const config = new DialogConfigurationModel();
                     config.componentInstance = CompletedComponent;
                     if (this.accountType === "ACH") {
                        config.title = "Payment Submitted";
                     } else {
                        config.title = "Payment Success";
                     }
                     this.ownerContributionService.PaymentType.next(this.accountType);
                     this.dialogCreationService.open(config, null, null, null, null, null, (v) => {
                        this.valueCallBack(v);
                     });
                  }
               },
               (_err) => {
                  this.errorMessageService.triggerHttpErrorMessage(_err);
                  this.buttonStatus = LcsProgressButtonStatus.Error;
               }
            );
      } else {
         this.buttonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   allSelected(value: boolean) {
      this.ownerContributionPaymentModel.IsDisclaimerChecked = value;
      this.checked = value;
   }
}
