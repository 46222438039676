import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { WebUserService } from "projects/libraries/owa-gateway-sdk/src/lib/services/web-user.service";
import { Subject, takeUntil } from "rxjs";

import { ChangeEmailAddressComponent } from "../change-emailaddress/change-emailaddress.component";
import { ChangeEmailAddressService } from "../change-emailaddress/change-emailaddress.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { OWASessionService } from "../session/owa-session.service";

@Component({
   selector: "owa-user-profile",
   templateUrl: "./user-profile.component.html",
   styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
   webUser = new WebUserModel();
   phoneNumber: string;
   errorMessage: string;
   userName: string;
   phoneNumberModel = new PhoneNumberModel();
   saveButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   @Output() menuItemClick = new EventEmitter<void>();
   private unsubscribe = new Subject<void>();
   constructor(
      private changeEmailAddressService: ChangeEmailAddressService,
      private dialogCreationService: DialogCreationService,
      private webUserService: WebUserService,
      private owaSessionService: OWASessionService,
      private errorMessageService: ErrorMessageService
   ) {}

   ngOnInit(): void {
      this.webUserService
         .getUserProfile(this.owaSessionService.OWASessionInfo.value.CurrentUser.WebUserID)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (res) => {
               if (res) {
                  this.webUser = res;
                  if (res.PhoneNumbers.length > 0) {
                     this.phoneNumber = res.PhoneNumbers[0].PhoneNumber;
                  }
               }
            },
            (error) => {
               this.errorMessage = this.errorMessageService.processHttpError(error).Message;
            }
         );
      this.owaSessionService.OWASessionInfo.subscribe((sessionInfo) => {
         if (sessionInfo) {
            this.userName = sessionInfo.CurrentUser.UserName;
         }
      });
   }

   saveUserProfile(form: NgForm) {
      if (form.valid) {
         this.saveButtonStatus = LcsProgressButtonStatus.InProgress;
         if (this.webUser.PhoneNumbers.length > 0) {
            this.webUser.PhoneNumbers[0].PhoneNumber = this.phoneNumber;
         } else {
            this.phoneNumberModel.PhoneNumber = this.phoneNumber;
            this.webUser.PhoneNumbers.push(this.phoneNumberModel);
         }
         this.webUser.FirstName = this.webUser.FirstName.trim();
         this.webUser.LastName = this.webUser.LastName.trim();
         if (!this.webUser.FirstName || !this.webUser.LastName) {
            this.saveButtonStatus = LcsProgressButtonStatus.Error;
         } else {
            this.webUserService
               .saveUserProfile(this.webUser)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe(
                  (res) => {
                     this.saveButtonStatus = LcsProgressButtonStatus.Success;
                     this.owaSessionService.clearCache(true);
                     this.owaSessionService.populateCache();
                  },
                  (error) => {
                     this.saveButtonStatus = LcsProgressButtonStatus.Error;
                     this.errorMessageService.triggerHttpErrorMessage(error);
                  }
               );
         }
      } else {
         this.saveButtonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   openChangeEmailAddressDialog() {
      this.menuItemClick.emit();
      this.changeEmailAddressService
         .GetChangeEmailAddressModelInfo(
            WorkflowSteps[WorkflowSteps.Unset],
            this.webUser.WebUserID,
            this.owaSessionService.OWASessionInfo.value.LocationID
         )
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((result) => {
            if (result) {
               const config = new DialogConfigurationModel();
               config.componentInstance = ChangeEmailAddressComponent;
               config.title = "Change Username";
               this.dialogCreationService.open(config, result);
            }
         });
   }

   openChangePasswordDialog() {
      this.menuItemClick.emit();
      const config = new DialogConfigurationModel();
      config.componentInstance = ChangePasswordComponent;
      config.title = "Change Password";
      this.dialogCreationService.open(config, null);
   }
}
