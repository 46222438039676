<div class="report-parameter-label">
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="forecastDateRangeModeParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'date-range-mode'"
      [disabled]="disabled || (hasAsk === true && forecastDateRangeModeParameter.IsAsk === true)"
      [parameter]="forecastDateRangeModeParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'forecast-start-year'"
      [disabled]="disabled || (hasAsk === true && forecastStartYearParameter.IsAsk === true)"
      [parameter]="forecastStartYearParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      *ngIf="selectedDateRangeMode === dateRangeModes.Monthly"
      [name]="'forecast-start-month'"
      [disabled]="disabled || (hasAsk === true && forecastStartMonthParameter.IsAsk === true)"
      [parameter]="forecastStartMonthParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      *ngIf="selectedDateRangeMode === dateRangeModes.Quarterly"
      [name]="'forecast-start-quarter'"
      [disabled]="disabled || (hasAsk === true && forecastQuarterParameter.IsAsk === true)"
      [parameter]="forecastQuarterParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      *ngIf="selectedDateRangeMode === dateRangeModes.Yearly"
      [name]="'forecast-end-year'"
      [disabled]="disabled || (hasAsk === true && forecastEndYearParameter.IsAsk === true)"
      [parameter]="forecastEndYearParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>