import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   Output,
   ViewChild,
} from "@angular/core";
import { ControlContainer, NgControl, UntypedFormControl } from "@angular/forms";
import { FormRegistrationService } from "@lcs/forms/form-registration/form-registration.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { MobileFocusOverlayService } from "@lcs/mobile-focus-overlay/mobile-focus-overlay.service";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { ValueComparerHelper } from "@lcs/select/helpers/value-comparer.helper";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { KEY_DOWN, KEY_ESCAPE, KEY_RETURN, KEY_TAB, KEY_UP } from "keycode-js";
import cloneDeep from "lodash/cloneDeep";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { fromEvent, Subject, takeUntil, takeWhile } from "rxjs";

@Component({
   selector: "lcs-input-with-options",
   templateUrl: "input-with-options.component.html",
})
export class InputWithOptionsComponent extends ValueAccessorBase<string> implements OnDestroy, AfterViewInit {
   @Input() public set valueSource(val: ValueSourceModel) {
      if (val) {
         this._valueSource = val;
         this.processValueSource();
      }
   }

   public get valueSource(): ValueSourceModel {
      return this._valueSource;
   }

   @Input() arrowNavigable: boolean = true;

   @Input() alignOverlayPanelToRight = false;

   @Input() staticOverlay: boolean = false;

   @Input() allowMobileFocusMode: boolean = true;

   @Output() valueChanged = new EventEmitter<SelectorItemModel | string>();

   @ViewChild("inputWithOptionsWrapper", { static: true }) inputWithOptionsWrapper: ElementRef;

   control: UntypedFormControl;

   dropdownOptions: Array<SelectorItemModel>;

   itemSet: SelectorItemSetModel;

   selectedItemIndex: number = -1;

   showOverlayPanel: boolean = false;

   hideOverlayOnBlur: boolean = true;

   private overlayPanelShownFromIconClicked: boolean;

   private unsubscribe = new Subject<void>();

   private _valueSource: ValueSourceModel;

   private valueComparer: (selectedItemValue: any, value: any) => boolean;

   constructor(
      private controlContainer: ControlContainer,
      private formRegistrationService: FormRegistrationService,
      private mobileFocusOverlayService: MobileFocusOverlayService,
      private elRef: ElementRef,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.valueComparer = ValueComparerHelper.simpleComparer;
   }

   ngAfterViewInit() {
      setTimeout(() => {
         if (this.controlContainer && this.name) {
            this.control = this.controlContainer.control?.get(this.name) as UntypedFormControl;
            this.formRegistrationService.addControl(this.name, this.control, this.displayName, this.path);
         }
      });

      fromEvent<KeyboardEvent>(this.inputWithOptionsWrapper.nativeElement, "keydown")
         .pipe(
            takeWhile(() => this.arrowNavigable),
            takeUntil(this.unsubscribe)
         )
         .subscribe((event) => {
            if (
               event.key === "ArrowUp" ||
               event.keyCode === KEY_DOWN ||
               event.key === "ArrowDown" ||
               event.keyCode === KEY_UP
            ) {
               if (!this.showOverlayPanel) {
                  this.showOverlay();
                  event.preventDefault();
               } else {
                  if (event.key === "ArrowDown" || event.keyCode === KEY_DOWN) {
                     if (this.selectedItemIndex === null) {
                        this.selectedItemIndex = 0;
                     } else if (this.selectedItemIndex < this.itemSet.items.length - 1) {
                        this.selectedItemIndex++;
                     }
                     event.preventDefault();
                  } else if (event.key === "ArrowUp" || event.keyCode === KEY_UP) {
                     if (this.selectedItemIndex !== null && this.selectedItemIndex > 0) {
                        this.selectedItemIndex--;
                     }
                     event.preventDefault();
                  }
               }
            } else if (event.key === "Tab" || event.keyCode === KEY_TAB) {
               if (this.selectedItemIndex >= 0) {
                  this.onItemSelected(this.itemSet.items[this.selectedItemIndex]);
               }
               this.hideOverlay();
            } else if (event.key === "Enter" || event.keyCode === KEY_RETURN) {
               if (this.showOverlayPanel) {
                  event.preventDefault(); // if drop down is open prevent from submitting form when enter is pressed to select an option
               }
               if (this.selectedItemIndex >= 0) {
                  this.onItemSelected(this.itemSet.items[this.selectedItemIndex]);
               }
               this.hideOverlay();
            } else if (event.key === "Escape" || event.keyCode === KEY_ESCAPE) {
               this.hideOverlay();
            }
         });

      this.mobileFocusOverlayService.mobileFocusModeAllowed.subscribe((isAllowed) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isAllowed) {
               if (this.showOverlayPanel) {
                  this.tryRequestMobileFocusMode();
               } else {
                  this.mobileFocusOverlayService.cancelMobileFocusModeRequest();
               }
            } else if (this.mobileFocusOverlayService.inMobileFocusMode()) {
               this.mobileFocusOverlayService.endMobileFocusMode();
            }
         }
      });

      this.mobileFocusOverlayService.mobileFocusModeSet.subscribe((isSet) => {
         if (this.mobileFocusOverlayService.isCurrentlyRequestedMobileFocusElement(this.elRef)) {
            if (isSet) {
               this.hideOverlayOnBlur = false;
               this.hideOverlay();
               setTimeout(() => {
                  this.showOverlay();
               });
            } else {
               this.hideOverlayOnBlur = true;
               this.hideOverlay();
            }
         }
      });
   }

   ngOnDestroy() {
      this.formRegistrationService.removeControl(this.control);
      this.unsubscribe.next();
   }

   onIconClicked() {
      if (this.showOverlayPanel || this.overlayPanelShownFromIconClicked) {
         this.overlayPanelShownFromIconClicked = false;
         this.hideOverlay();
      } else {
         this.showOverlay();
      }

      this.tryRequestMobileFocusMode();
   }

   onIconMousedown() {
      this.overlayPanelShownFromIconClicked = true;
      if (!this.showOverlayPanel && this.overlayPanelShownFromIconClicked) {
         this.overlayPanelShownFromIconClicked = false;
      }
   }

   /**
    * Sync selected item to current value if possible
    */
   updateSelectedItemIndexBasedOnValue(value: string) {
      const idx =
         this.itemSet && this.itemSet.items
            ? this.itemSet.items.findIndex((i) => this.valueComparer(i.displayValue, value))
            : -1;
      this.selectedItemIndex = idx;
   }

   onValueChange(value: string) {
      if (value || value === "") {
         this.updateSelectedItemIndexBasedOnValue(value);
         if (this.selectedItemIndex >= 0) {
            this.onItemSelected(this.itemSet.items[this.selectedItemIndex]);
         } else {
            this.value = value;
         }
      }
      this.propagateTouched();
      this.valueChanged.emit(value);
   }

   onItemSelected(item: SelectorItemModel) {
      if (item) {
         this.value = item.value;
         this.valueChanged.emit(item);
      }
   }

   processStaticValueSource(source: ValueSourceModel): SelectorItemSetModel {
      const itemSet = new SelectorItemSetModel();
      itemSet.identifier = "static";
      for (const value of source.StaticValues) {
         const selectorItem = cloneDeep(value);
         if (source.SelectedValues.indexOf(selectorItem.value) !== -1) {
            selectorItem.isChecked = true;
         }
         itemSet.items.push(selectorItem);
      }
      return itemSet;
   }

   processEmbeddedSetValueSource(source: ValueSourceModel): SelectorItemSetModel {
      const itemSet = new SelectorItemSetModel();
      itemSet.identifier = "embeddedType";

      const selectedValue = ObjectMapResolverService.getPropertyValue(source.EmbeddedSet, source.EntityValueSourcePath);

      let embeddedValues;
      if (source.EmbeddedSet && !source.EmbeddedSetValueSourcePath) {
         embeddedValues = source.EmbeddedSet;
      } else {
         embeddedValues = ObjectMapResolverService.getPropertyValue(
            source.EmbeddedSet,
            source.EmbeddedSetValueSourcePath
         );
      }
      embeddedValues.split(source.EmbeddedSetValueSourceDelimiter).map((item) => {
         const selectorItem = new SelectorItemModel();
         selectorItem.displayValue = item;
         selectorItem.value = item;
         selectorItem.isChecked = selectedValue === item;
         itemSet.items.push(selectorItem);
      });
      return itemSet;
   }

   selectAllText(event: FocusEvent) {
      const target = <HTMLInputElement>event.target;
      if (!(target.selectionStart && target.selectionEnd && target.selectionEnd > target.selectionStart)) {
         target.select();
      }
      this.updateSelectedItemIndexBasedOnValue(target.value);

      this.tryRequestMobileFocusMode();
   }

   private hideOverlay() {
      this.showOverlayPanel = false;
   }

   private showOverlay() {
      this.updateSelectedItemIndexBasedOnValue(this.value);
      this.showOverlayPanel = true;
   }

   private processValueSource() {
      if (this.valueSource) {
         this.itemSet = new SelectorItemSetModel();

         if (this.valueSource.Type === ValueSourceTypes.Static) {
            this.itemSet = this.processStaticValueSource(this.valueSource);
         } else if (this.valueSource.Type === ValueSourceTypes.EmbeddedSet) {
            this.itemSet = this.processEmbeddedSetValueSource(this.valueSource);
         } else {
            console.warn("Unable to process Value Source");
         }
      }
   }

   private tryRequestMobileFocusMode() {
      if (this.allowMobileFocusMode) {
         this.mobileFocusOverlayService.requestMobileFocusMode(this.elRef);
      }
   }
}
