import { DatePipe } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { ControlContainerViewProvider } from "@lcs/inputs/control-container-view-providers";
import { UserInputComponent } from "@lcs/inputs/user-input-component.interface";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ReportParameterValueModel } from "@lcs/reports/report-parameters/models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "@lcs/reports/report-parameters/models/report-report-parameter.viewmodel";
import { ReportParameterFiltersService } from "@lcs/reports/report-parameters/report-parameter-filters.service";
import { ReportParameterControlStatusService } from "@lcs/reports/report-parameters/report-parameters-control-status.service";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityRequestEndpointInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { ReconciliationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/reconciliation.model";
import { ReportParameterValueEntitySourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-entity-source.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { GLAccountsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/glaccounts.service";
import { ReconciliationsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/reconciliations.service";
import { filter, forkJoin, Subject, takeUntil } from "rxjs";

@Component({
   selector: "owa-reconciliations-report-parameter",
   templateUrl: "reconciliations-report-parameter.component.html",
   providers: [ReportParameterFiltersService, ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class ReconciliationsReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() hideLabel: boolean;

   @Input() entityValueSourceFilters = new Array<FilterOption>();

   @Input() parameter: ReportReportParameterViewModel;

   @Input() hasAsk: boolean;

   @ViewChild("bankSelector") bankSelector: SelectComponent;

   reportParameterValueModel: ReportParameterValueModel;

   valueSource: ValueSourceModel;

   magnumReconcileParameter: boolean;

   bankValues: Array<SelectorItemModel>;

   selectionChange: SelectionChangeModel;

   selectedValue: any;

   selectedAccountID: number;

   accountLabel: string;

   accountEntityType: EntityType;

   nullFK = ConstantsService.NullFK;

   private valueInitialized = false;

   private unsubscribe = new Subject<void>();

   private entityRequestInformation: EntityRequestEndpointInformationModel;

   private entityViewInformation: EntityViewInformationModel;

   private entityValueSource: ReportParameterValueEntitySourceModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterFilterService: ReportParameterFiltersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService,
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private reconciliationsExtensionsService: ReconciliationsService,
      private glAccountExtentionService: GLAccountsService
   ) {}

   ngOnInit() {
      if (this.parameter.ReportParameterID === ReportParameter.MagnumReconcileID) {
         this.magnumReconcileParameter = true;
         this.initializeBankList();
      }
      this.initializeReportParameterValue();

      if (parseInt(this.parameter.ReportParameterValueSource.DefaultValue) > 0) {
         this.selectedValue = parseInt(this.parameter.ReportParameterValueSource.DefaultValue);
         this.selectionChanged();
      } else {
         this.initializeAccountSelector();
         this.initializeValueSource();
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   initializeBankList() {
      const selectorItems = new Array<SelectorItemModel>();
      this.glAccountExtentionService
         .getBanksWithReconcileCollection()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            if (results) {
               results.sort((a: GLAccountModel, b: GLAccountModel) => +a.Reference - +b.Reference);
               for (const bank of results) {
                  const item = new SelectorItemModel();
                  item.displayValue = bank.Reference + " " + bank.Name;
                  item.value = bank.GLAccountID;
                  selectorItems.push(item);
               }
               this.bankValues = selectorItems;
               if (results.length > 0) {
                  this.selectedAccountID = results[0].GLAccountID;
               }
               this.getReconciliations();
            }
         });
   }

   onBankSelected() {
      const selectedItem = this.bankSelector.selectedItem;
      if (!selectedItem) {
         if (this.selectedAccountID) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
            this.selectedAccountID = null;
            this.selectedValue = "";
            this.getReconciliations();
         }
      } else {
         this.selectedAccountID = selectedItem.value;
         this.getReconciliations();
      }
   }

   selectionChanged() {
      this.reportParameterValueModel.value = "";
      if (!this.parameter.ReportParameterValueSource.AllowsMultipleValues) {
         if (this.selectedValue) {
            this.reportParameterValueModel.value = this.selectedValue;
         }
      } else {
         if (this.selectedValue) {
            this.reportParameterValueModel.value = `(${this.selectedValue})`;
         } else {
            this.reportParameterValueModel.value = "";
         }
      }
      this.reportParameterValueModel.rawValues = [this.reportParameterValueModel.value];
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeAccountSelector() {
      if (this.parameter.ReportID === Report.ReconcileReport || this.parameter.ReportID === Report.MagnumBankRec) {
         this.accountEntityType = EntityType.Bank;
         this.accountLabel = "Bank";
      } else if (this.parameter.ReportID === Report.CreditCardReconcileReport) {
         this.accountEntityType = EntityType.CreditCard;
         this.accountLabel = "Credit Card";
      }
   }

   private getReconciliations() {
      if (this.selectedAccountID) {
         this.reconciliationsExtensionsService
            .getReconciliationsCollection(this.selectedAccountID)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reconciliations) => {
               this.buildValueSource(reconciliations);
            });
      } else {
         this.buildValueSource(new Array<ReconciliationModel>());
      }
   }

   private initializeValueSource() {
      if (!this.validation) {
         this.validation = new ValidationModel();
      }

      this.validation.required = this.parameter.ReportParameterValueSource.IsRequired;
      this.validation.dataType = ExpressDataTypes.Key;

      if (this.parameter.ReportParameterValueSource.EntitySources.length !== 1) {
         throw new Error("Unable to build Reconcile Report Parameter - incorrect source configuration.");
      }
      this.entityValueSource = this.parameter.ReportParameterValueSource.EntitySources[0];

      forkJoin([
         this.entityRequestEndpointService.getEndpointInformation(this.entityValueSource.EntityType),
         this.entityViewInformationService.getViewInformation(this.entityValueSource.EntityType),
      ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(([entityRequestInformation, entityViewInformation]) => {
            this.entityRequestInformation = entityRequestInformation;
            this.entityViewInformation = entityViewInformation;

            this.getReconciliations();
         });
   }

   private buildValueSource(reconciliations: Array<ReconciliationModel>) {
      const valueSourceModel = new ValueSourceModel();
      if (this.entityRequestInformation) {
         valueSourceModel.EndpointIsSearch = this.entityRequestInformation.IsSearchEnabled;
      }
      valueSourceModel.Type = ValueSourceTypes.Static;
      valueSourceModel.EntityType = this.entityValueSource.EntityType;
      valueSourceModel.AllowsSelectAll = this.parameter.ReportParameterValueSource.AllowsSelectAll;
      valueSourceModel.AllValue = this.parameter.ReportParameterValueSource.AllValue;
      valueSourceModel.AllValueLabel = `All ${this.entityViewInformation.CollectionLabel}`;
      valueSourceModel.AllowsMultipleValues = this.parameter.ReportParameterValueSource.AllowsMultipleValues;

      valueSourceModel.DisplayValueSourcePath = this.entityValueSource.DisplayNameProperty;
      valueSourceModel.EntityValueSourcePath = this.entityValueSource.ValueProperty;
      this.selectedValue = null;

      if (this.parameter.ReportParameterValueSource.AddBlankValue) {
         valueSourceModel.AddBlankValue();
      }
      if (reconciliations && reconciliations.length > 0) {
         const datePipe = new DatePipe("en-US");
         valueSourceModel.StaticValues = reconciliations.map(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            (r) => new SelectorItemModel(r.ReconciliationID, datePipe.transform(r.ReconciliationDate, "MM/dd/yy"))
         );
      } else {
         valueSourceModel.StaticValues = new Array<SelectorItemModel>();
      }
      this.valueSource = valueSourceModel;
      if (this.valueSource.StaticValues.length > 0) {
         this.selectedValue = this.valueSource.StaticValues[0].value;
      }
      this.selectionChanged();
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === this.parameter.ReportParameterID) {
               this.reportParameterValuesUpdated(updatedValue);
            }
            this.updateReportParameterValueFilters(updatedValue);
         });
      this.reportParametersService.reportParameterStatusChanged
         .pipe(
            filter((updatedValue) => updatedValue.reportParameter === this.parameter.ReportParameterID),
            takeUntil(this.unsubscribe)
         )
         .subscribe((status) => {
            this.disabled = status.disabled;
         });
   }

   private reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      let valueChanged = false;
      let updatedRawValues = updatedValue.rawValues;
      if (updatedRawValues == null) {
         updatedRawValues = new Array<any>();
      }
      if (updatedRawValues.length === 1 && updatedRawValues[0] !== this.selectedValue) {
         valueChanged = true;
      }
      if (!valueChanged) {
         return;
      }
      this.selectedValue = updatedRawValues[0];
   }

   private updateReportParameterValueFilters(updatedValue: ReportParameterValueModel) {
      const entityValueSourceFilters = this.reportParameterFilterService.getUpdatedEntityValueSourceFilters(
         this.parameter.ReportID,
         this.reportParameterValueModel.reportParameter,
         updatedValue,
         this.reportParametersService.reportParameterValues
      );
      if (entityValueSourceFilters) {
         this.entityValueSourceFilters = entityValueSourceFilters;
      }
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.parameter.ReportID,
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }

      // clear values
      const clearValues = this.reportParameterControlStatusService.ClearValues(
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (clearValues) {
         if (this.hasAsk && !this.valueInitialized) {
            this.valueInitialized = true;
            return;
         }
         this.selectedValue = null;
      }
   }
}
