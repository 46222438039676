import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { ValidatePasswordFormatModule } from "../validate-password-format/validate-password-format.module";
import { PasswordResetForcedComponent } from "./password-reset-forced/password-reset-forced.component";
import { PasswordResetComponent } from "./password-reset.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      LcsProgressButtonModule,
      TextBoxModule,
      ValidationModule,
      ValidatePasswordFormatModule,
   ],
   declarations: [PasswordResetComponent, PasswordResetForcedComponent],
})
export class PasswordResetModule {}
