<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ parameter?.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-single-line-multi-select
   [name]="name"
   [valueSource]="valueSource"
   [ngModel]="selectedValues"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   (selectionChange)="selectionChange = $event"
   (panelClose)="selectionChanged()"
></lcs-single-line-multi-select>