import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { SlideToggleComponent } from "./slide-toggle.component";

@NgModule({
   imports: [CommonModule, FormsModule, MatSlideToggleModule],
   declarations: [SlideToggleComponent],
   exports: [SlideToggleComponent],
})
export class SlideToggleModule {}
