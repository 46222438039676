import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/account-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ExpressAccountTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-account-transfer.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { ExpressAccountsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/express-accounts.service";
import { map, Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-account-multi-selector",
   templateUrl: "account-multi-selector.component.html",
})
export class AccountMultiSelectorComponent extends ValueAccessorBase<any> implements OnDestroy {
   @Input() disabled: boolean;

   @Input() set accountTypes(values: Array<AccountType>) {
      this._accountTypes = values;

      if (this._accountTypes && this._accountTypes.length > 0) {
         if (this.accountTypes && this.accountTypes.length > 0) {
            this.filters.push(new FilterOption("AccountType", FilterOperations.In, this.accountTypes));
         }
      }
      this.getAccountData();
   }

   @Input() set accountTypesToExclude(values: Array<string>) {
      this._accountTypesToExcluded = values;

      if (this._accountTypesToExcluded && this._accountTypesToExcluded.length > 0) {
         if (this.accountTypesToExclude && this.accountTypesToExclude.length > 0) {
            this.filters.push(new FilterOption("AccountType", FilterOperations.NotIn, this._accountTypesToExcluded));
         }
      }
      this.getAccountData();
   }

   @Input() filters: Array<FilterOption> = [];

   @Input() hideLabel: boolean;

   @Input() inlineLabel: boolean;

   @Input() label: string = "Accounts";

   @Output() panelClose = new EventEmitter<void>();

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   selectorItems: Array<SelectorItemModel>;

   valueSource: ValueSourceModel;

   private unsubscribe = new Subject<void>();

   private _accountTypes: Array<AccountType>;

   private _accountTypesToExcluded: Array<string>;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private expressAccountsService: ExpressAccountsService
   ) {
      super(changeDetectorRef, ngControl);

      this.buildValueSource();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onPanelClosed() {
      this.panelClose.emit();
   }

   onSelectionChanged(selectionChange: SelectionChangeModel) {
      this.selectionChange.emit(selectionChange);
   }

   private buildValueSource() {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.AllowsSelectAll = true;
      valueSource.SelectedValues = this.innerValue;
      valueSource.Label = this.label;
      this.valueSource = valueSource;
   }

   private getAccountData() {
      if (!this._accountTypes && !this._accountTypesToExcluded) {
         return;
      }

      const filterExpression = new FilterExpression();
      filterExpression.FilterOptions = this.filters;

      const orderingOption = new OrderingOption("Name");

      this.expressAccountsService
         .getAccountsWithAccountTypeNameCollection(filterExpression, null, [orderingOption], null, null, null, true)
         .pipe(
            map((accounts: Array<ExpressAccountTransferModel>) => {
               return accounts.map((account) => {
                  const selectorItem = new SelectorItemModel(account.AccountID, account.Name);
                  selectorItem.additionalInfoValue = account.AccountTypeDescription;
                  return selectorItem;
               });
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((selectorItemModels: Array<SelectorItemModel>) => {
            this.selectorItems = selectorItemModels;
            const valueSource = Object.assign({}, this.valueSource);
            valueSource.StaticValues = this.selectorItems;
            this.valueSource = valueSource;
         });
   }
}
