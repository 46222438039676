import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { EstimateStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eestimatestatus.enum";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { Subject } from "rxjs";

import { EstimateDetailPreferences } from "../estimate-detail-preferences.interface";
import { EstimateDetailService } from "../estimate-detail.service";
import { EstimateDetailRejectDialogComponent } from "../reject/estimate-detail-reject.component";
import { EstimateDetailSignatureDialogComponent } from "../signature/estimate-detail-signature.component";

@Component({
   selector: "owa-estimate-details",
   templateUrl: "estimate-details.component.html",
   styleUrls: ["./estimate-details.component.css"],
})
export class EstimateDetailsComponent implements OnInit, OnDestroy {
   public estimateStatus: EstimateStatus;
   public unsubscribe: Subject<void> = new Subject<void>();

   @Input() loading: boolean;

   @Input() estimate: EstimateViewModel;

   @Input() preferences: EstimateDetailPreferences;

   isApproved: boolean = false;

   approveButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   cancelButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   errorMessageService: any;

   get ShowHistory(): boolean {
      return this.preferences.showHistory && this.estimate && this.estimate.History.length > 0;
   }

   get ShowApproval(): boolean {
      return this.preferences.allowApproval && this.estimate && this.estimate.CanApprove;
   }

   get ShowApprovedRejected(): boolean {
      return (
         this.estimate &&
         (this.estimate.Status === EstimateStatus.Approved ||
            this.estimate.Status === EstimateStatus.Rejected ||
            this.estimate.IsExpired)
      );
   }

   get ShowServiceManagerIssue(): boolean {
      return this.estimate && this.estimate.ServiceManagerIssue != null;
   }

   get StatusName(): string {
      if (this.estimate) {
         if (this.estimate.IsExpired) {
            return "Expired";
         }
         return EstimateStatus[this.estimate.Status];
      }
      return "";
   }

   constructor(
      private dialogCreationService: DialogCreationService,
      private estimateService: EstimateService,
      private estimateDetailService: EstimateDetailService,
      private router: Router,
      private activateRoute: ActivatedRoute
   ) {}

   openEstimateDetailsMore(): void {
      this.router.navigate(["./estimatedetails"], {
         relativeTo: this.activateRoute,
      });
   }

   openEstimateHistoryListMore(): void {
      this.router.navigate(["./estimatehistorylist"], { relativeTo: this.activateRoute });
   }

   approveClick(): void {
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = EstimateDetailSignatureDialogComponent;
      dialogConfigModel.title = "Approve Estimate";
      dialogConfigModel.disableClose = true;
      dialogConfigModel.styleClass = "estimate-detail-signature";
      this.dialogCreationService.open(dialogConfigModel, this.estimate.EstimateID);
   }

   rejectClick(): void {
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = EstimateDetailRejectDialogComponent;
      dialogConfigModel.title = "Reject Estimate";
      dialogConfigModel.disableClose = true;
      dialogConfigModel.styleClass = "estimate-detail-reject";
      this.dialogCreationService.open(dialogConfigModel, this.estimate.EstimateID);
   }

   cancelClick(): void {}

   ngOnInit() {
      this.estimateService.approved.subscribe((isApproved) => {
         this.estimateService.get(this.estimate.EstimateID).subscribe(
            (estimate: EstimateViewModel) => {
               this.approveButtonStatus = LcsProgressButtonStatus.Success;
               this.isApproved = true;
               this.estimateDetailService.estimateSubject.next(estimate);
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
