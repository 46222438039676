import { Injectable } from "@angular/core";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { AccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/account.embed-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { AccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/account.model";
import { ExpressAccountTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-account-transfer.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ExpressAccountsService {
   static readonly AccountsWithAccountTypeNameResource = "AccountsWithAccountTypeName";
   static readonly SearchResource = "Search";
   static readonly endpoint = "ExpressAccounts";
   constructor(private apiService: ApiService) {}
   getAccountsWithAccountTypeNameUrl(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ExpressAccountsService.endpoint + "/AccountsWithAccountTypeName",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAccountsWithAccountTypeName(
      id: number,
      embeds?: Array<string | AccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ExpressAccountTransferModel> {
      return this.apiService.getSingle(
         this.getAccountsWithAccountTypeNameUrl(id, embeds, fields, additionalParameters)
      );
   }

   getAccountsWithAccountTypeNameCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      ignorePaging?: boolean | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (ignorePaging !== undefined && ignorePaging !== null) {
         additionalParameters.push("ignorePaging=" + ignorePaging);
      }
      return this.apiService.getUrl(
         ExpressAccountsService.endpoint + "/AccountsWithAccountTypeName",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAccountsWithAccountTypeNameCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      ignorePaging?: boolean | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ExpressAccountTransferModel>> {
      return this.apiService.getCollection(
         this.getAccountsWithAccountTypeNameCollectionUrl(
            filterExpression,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            ignorePaging,
            additionalParameters
         )
      );
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      ignorePaging?: boolean | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (ignorePaging !== undefined && ignorePaging !== null) {
         additionalParameters.push("ignorePaging=" + ignorePaging);
      }
      return this.apiService.getUrl(
         ExpressAccountsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      ignorePaging?: boolean | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            ignorePaging,
            additionalParameters
         )
      );
   }
}
