import { formatCurrency, getLocaleCurrencySymbol, getLocaleNumberSymbol, NumberSymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { MidpointRounding } from "projects/libraries/lcs/src/lib/utils/midpoint-rounding";
import { roundCurrency } from "projects/libraries/lcs/src/lib/utils/rounding";

@Pipe({
   name: "rmCurrency",
})
export class RmCurrencyPipe implements PipeTransform {
   transform(value: number, options: string = "1.2-2", rounding: MidpointRounding = MidpointRounding.ToEven): any {
      return RmCurrencyPipe.rmCurrencyTransform(value, options, rounding);
   }

   static rmCurrencyTransform(
      value: number,
      options: string = "1.2-2",
      rounding: MidpointRounding = MidpointRounding.ToEven,
      groupSeperator: string = ","
   ): any {
      if (value != null) {
         const roundedCurrency = roundCurrency(value, parseInt(options.substr(options.lastIndexOf("-") + 1)), rounding);
         const currencySymbol = getLocaleCurrencySymbol(GlobalsService.locale);
         // @ts-ignore
         const formattedCurrency = formatCurrency(roundedCurrency, GlobalsService.locale, currencySymbol, "", options);
         let cleanFormat = formattedCurrency;
         if (formattedCurrency !== null && formattedCurrency !== "") {
            const groupSeparator = getLocaleNumberSymbol(GlobalsService.locale, NumberSymbol.Group);
            const decimalSeparator = getLocaleNumberSymbol(GlobalsService.locale, NumberSymbol.Decimal);
            const decimalSeparatorRegex = new RegExp("\\" + decimalSeparator, "g");
            const groupSeparatorRegex = new RegExp("\\" + groupSeparator, "g");
            cleanFormat = formattedCurrency
               // @ts-ignore
               .replace(currencySymbol, "")
               .replace(groupSeparatorRegex, groupSeperator)
               .replace(decimalSeparatorRegex, ".");
         }
         return cleanFormat;
      }
   }
}
