export enum FilterDataTypes {
   String,
   Int,
   Key,
   PrimaryKey,
   Decimal,
   Bool,
   DateTime,
   Object,
}
