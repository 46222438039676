import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { BillDetailPreferences } from "projects/owa/src/app/bill-detail/bill-detail-preferences.interface";
import { SystemWebPreferencesSessionService } from "projects/owa/src/app/session/systemwebpreference-session.service";
import { catchError, map, Observable, of, shareReplay } from "rxjs";

import { BillViewModel } from "../models/bill-view.model";
import { SystemWebPreferenceModel } from "../models/system-web-preference.model";

@Injectable({
   providedIn: "root",
})
export class BillsService {
   readonly endpoint = "Bills";
   preferences: Observable<BillDetailPreferences>;
   valueString: string;

   constructor(
      private apiService: ApiService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private errorMessageService: ErrorMessageService
   ) {
      this.preferences = this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            return of({
               // TODO: are these default values correct?
               showBills: false,
               showBillAttachments: false,
               showServiceIssues: false,
            });
         }),
         map((preferences: SystemWebPreferenceModel[]) => ({
            showBills:
               preferences != null && preferences.filter((f) => f.Name === "ShowBillsOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowBillsOWA")[0].Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : false,
            showBillAttachments:
               preferences != null && preferences.filter((f) => f.Name === "ShowBillAttachmentsOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowBillAttachmentsOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,
            showClosedIssues:
               preferences != null && preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,
            showOpenIssues:
               preferences != null && preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,
            hasCategoriesPermission:
               preferences.filter((f) => f.Name === "ServiceIssueCategoriesToDisplayOWA").length !== 0
                  ? (this.valueString = preferences.filter(
                       (f) => f.Name === "ServiceIssueCategoriesToDisplayOWA"
                    )[0].Value)
                  : (this.valueString = ""),
         })),
         shareReplay(1) // share the results among all subscribers (avoid duplicate API calls)
      );
   }
   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/Get";
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (id !== undefined && id !== null) {
         additionalParameters.push("id=" + id);
      }
      if (filters !== undefined && filters !== null && filters.length > 0) {
         additionalParameters.push("filters=" + filters.map((f) => f.toApiString()).join(";"));
      }
      if (embeds !== undefined && embeds !== null && embeds.length > 0) {
         additionalParameters.push("embeds=" + embeds.join(","));
      }
      if (orderingOptions !== undefined && orderingOptions !== null && orderingOptions.length > 0) {
         additionalParameters.push("orderingOptions=" + orderingOptions.map((o) => o.toApiString()).join(","));
      }
      if (fields !== undefined && fields !== null && fields.length > 0) {
         additionalParameters.push("fields=" + fields.join(","));
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions.join(";"));
      }
      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }
      return this.apiService.getUrl(
         endpoint,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   getCollection(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<BillViewModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
   getCollectionResponse(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getCollectionResponse(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
   getUrl(
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (id !== undefined && id !== null) {
         additionalParameters.push("id=" + id);
      }
      if (embeds !== undefined && embeds !== null && embeds.length > 0) {
         additionalParameters.push("embeds=" + embeds.join(","));
      }
      if (fields !== undefined && fields !== null && fields.length > 0) {
         additionalParameters.push("fields=" + fields.join(","));
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions.join(";"));
      }
      return this.apiService.getUrl(
         endpoint,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   get(
      id?: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<BillViewModel> {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getBillsForDashboard(id?: number): Observable<Array<BillViewModel>> {
      const endpoint = this.endpoint + "/GetBillsForDashboard";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }

   ApproveBillByOwner(id: number): Observable<any> {
      const endpoint = "Owners/ApproveBill?id=" + id;
      return this.apiService.post(this.apiService.getUrl(endpoint));
   }
}
