import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StartupService } from "@lcs/application-status/startup.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";

import { CurrentLocationService } from "./current-location.service";
import { ExpressReportDefinitionsService } from "./express-report-definitions.service";

@NgModule({
   imports: [CommonModule],
   declarations: [],
   providers: [
      CurrentLocationService,
      CurrentUserService,
      CacheMonitorService,
      StartupService,
      ExpressReportDefinitionsService,
   ],
   exports: [],
})
export class SessionModule {}
