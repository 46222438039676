<div class="vendor-detail-wrapper">
   <div class="flex-grid vendor-detail">
      <div class="flex-two-thirds">
         <div class="grid-container">
            <div class="title">Name</div>
            <div class="tile-value">{{vendor?.Name}}</div>
            <div class="title">Email</div>
            <div class="tile-value">{{vendor?.Contact?.Email}}</div>
            <div class="title">Payee</div>
            <div class="tile-value">{{vendor?.Payee}}</div>
            <div class="title">Primary</div>
            <div
               class="address tile-value"
               *ngFor="let address of vendor?.Addresses"
            >
               <span *ngIf="address.IsPrimary">{{address.Address}}</span>
            </div>
         </div>
      </div>
      <div class=flex-third>
         <div class="grid-container">
            <div
               *ngFor="let phone of vendor?.PhoneNumbers"
               class="grid-container phoneNumberGrid"
            >
               <div class="title">{{phone.PhoneNumberType?.Name}}</div>
               <div class="tile-value">{{phone.PhoneNumber}}</div>
            </div>
         </div>
      </div>
   </div>
</div>

<div lcsFooter>
   <lcs-progress-button
      [raised]="true"
      (buttonClick)="onOkClick()"
   >OK</lcs-progress-button>
</div>