export class ChangeEmailAddressModel {
   NewEmailAddress: string;
   ConfirmEmailAddress: string;
   WebUserId: number;
   UserName: string;
   Message: string;
   WorkflowSteps: string;
   UserNameIsVerified: boolean;
   HasVerifiedEmailAddress: boolean;
   ConvertByDate: Date;
   EmailAddress: string;
   Password: string;
   IsPastConvertByDate: string;
   token: string;
   locationID: number;
   AccountDisplayID: number;
}
