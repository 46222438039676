<div id="signatureBox">
   <div *ngIf="rejectMessage != ''" class="customLabelText">{{ rejectMessage }}</div>
   <div class="commentLabelText">Comments</div>
   <textarea
      type="text"
      class="commentText"
      id="commentInput"
      maxlength="255"
      [(ngModel)]="approvalModel.Comments"
   ></textarea>
   <div class="buttonContainer flex">
      <div class="flex-half">
         <lcs-progress-button
            [(status)]="progressButtonStatus"
            [raised]="true"
            (buttonClick)="rejectClick()"
            class="rejectButton"
         >
            OK
         </lcs-progress-button>
      </div>
      <div class="flex-half">
         <lcs-progress-button
            [raised]="true"
            (buttonClick)="cancelClick()"
            class="cancelButton"
            [disabled]="isProcessing"
         >
            Cancel
         </lcs-progress-button>
      </div>
   </div>
</div>