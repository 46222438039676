<owa-layout [headerTitle]="'Owner Contributions'">
   <div class="form-element-container">
      <div class="contribution-dropdown form-element">
         <label>Contribution Type</label>
         <lcs-select
            lcsLegacySingleSelector
            [valueSource]="contributionTypes"
            [ngModel]="paymentTypeSelection"
            (ngModelChange)="setContributionType($event)"
            name="ContributionType"
         >
         </lcs-select>
      </div>
   </div>
   <div class="table-wrapper">
      <lcs-table [dataSource]="$any(contributions | async)">
         <tr
            lcs-header-row
            *lcsHeaderRowDef="columns; sticky: false"
         ></tr>
         <tr
            lcs-row
            *lcsRowDef="let row; columns: columns"
         ></tr>
         <ng-container
            lcsColumnDef="Partners"
            [width]="10"
            [priority]="1"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Partners</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >{{ row.Partners }}</td>
         </ng-container>
         <ng-container
            lcsColumnDef="Property"
            [width]="10"
            [priority]="1"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Property</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >{{ row.PropertyName }}</td>
         </ng-container>
         <div *ngIf="showBalance">
            <ng-container
               lcsColumnDef="BankBalance"
               [width]="10"
               [priority]="2"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Bank Balance </th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >{{
                  row.BankBalance | currency: "USD":"symbol":"1.2-2"
                  }}</td>
            </ng-container>
         </div>
         <div *ngIf="showSecurityDeposit">
            <ng-container
               lcsColumnDef="SecurityDepositHeldTotal"
               [width]="10"
               [priority]="3"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Security Deposits </th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >{{ row.SecurityDepositHeldTotal }}</td>
            </ng-container>
         </div>
         <div *ngIf="showPercentageOwnerShip">
            <ng-container
               lcsColumnDef="PercentageOfOwnership"
               [width]="10"
               [priority]="5"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Ownership%</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >{{ row.PercentageOfOwnership }}</td>
            </ng-container>
         </div>
         <div *ngIf="showReserve">
            <ng-container
               lcsColumnDef="Reserve"
               [width]="10"
               [priority]="5"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Reserve</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >{{ row.Reserve }}</td>
            </ng-container>
         </div>
         <ng-container
            lcsColumnDef="ContributionAmount"
            [width]="10"
            [priority]="5"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Contribution Amount</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >
               <input
                  [(ngModel)]="row.ContributuionAmount"
                  type="number"
                  value="$0.0"
                  id="contributionAmount"
                  min="0.00"
                  step="1"
               />
            </td>
         </ng-container>
      </lcs-table>

      <div
         lcsFooter
         class="flex align-right contributions-button"
      >
         <lcs-progress-button
            [(status)]="disableButton"
            (buttonClick)="onClickContinue()"
            [raised]="true"
         >Continue</lcs-progress-button>
      </div>
   </div>
</owa-layout>