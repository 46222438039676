import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MobileFocusOverlayComponent } from "./mobile-focus-overlay.component";

@NgModule({
   imports: [CommonModule],
   declarations: [MobileFocusOverlayComponent],
   exports: [MobileFocusOverlayComponent],
})
export class MobileFocusOverlayModule {}
