import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { InventoryItemDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/inventory-item.delete-options";
import { ChargeTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge-type.embed-options";
import { EpaySettingChargeTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/epay-setting-charge-type.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { InventoryItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/inventory-item.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { RecurringChargeTypesForRentRollGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/recurring-charge-types-for-rent-roll.get-options";
import { ChargeTypeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/charge-type.save-options";
import { MergeChargeTypeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/merge-charge-type.save-options";
import { LeasePeriod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/lease-period.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ChargeTypeOrderReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type-order-report-parameter.model";
import { ChargeTypeOrderSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type-order-summary.model";
import { ChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type.model";
import { EpaySettingChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-setting-charge-type.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { InventoryItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/inventory-item.model";
import { LocationGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location-group.model";
import { MergeChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/merge-charge-type.model";
import { RentChargeTypeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rent-charge-type-summary.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ChargeTypesService {
   static readonly AllRentChargeTypesForAllPropertiesResource = "AllRentChargeTypesForAllProperties";
   static readonly ChargeTypeFilterByReportParametersResource = "ChargeTypeFilterByReportParameters";
   static readonly ChargeTypeOrderSummaryResource = "ChargeTypeOrderSummary";
   static readonly CreateUserResource = "CreateUser";
   static readonly EpaySettingsResource = "EpaySettings";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetFromAllLocationsResource = "GetFromAllLocations";
   static readonly GLAccountResource = "GLAccount";
   static readonly InventoryItemsResource = "InventoryItems";
   static readonly MergeChargeTypesResource = "MergeChargeTypes";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RecurringChargeTypesForRentRollResource = "RecurringChargeTypesForRentRoll";
   static readonly RentRollRecurringChargeTypesResource = "RentRollRecurringChargeTypes";
   static readonly SearchResource = "Search";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly endpoint = "ChargeTypesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | ChargeTypeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ChargeTypeModel | null,
      saveOptions?: Array<string | ChargeTypeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | ChargeTypeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ChargeTypeModel>,
      saveOptions?: Array<string | ChargeTypeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(ChargeTypesService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(ChargeTypesService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAllRentChargeTypesForAllPropertiesCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/AllRentChargeTypesForAllProperties",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllRentChargeTypesForAllPropertiesCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<RentChargeTypeSummaryModel>> {
      return this.apiService.getCollection(
         this.getAllRentChargeTypesForAllPropertiesCollectionUrl(additionalParameters)
      );
   }

   postChargeTypeFilterByReportParametersUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/ChargeTypeFilterByReportParameters",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChargeTypeFilterByReportParameters(
      model?: ChargeTypeOrderReportParameterModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService
         .post(this.postChargeTypeFilterByReportParametersUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getChargeTypeOrderSummaryCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("ids=" + ids);
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/ChargeTypeOrderSummary",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChargeTypeOrderSummaryCollection(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeOrderSummaryModel>> {
      return this.apiService.getCollection(this.getChargeTypeOrderSummaryCollectionUrl(ids, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getEpaySettingsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EpaySettingChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/EpaySettings",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpaySettings(
      id: number,
      itemID: number,
      embeds?: Array<string | EpaySettingChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<EpaySettingChargeTypeModel> {
      return this.apiService.getSingle(
         this.getEpaySettingsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getEpaySettingsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpaySettingChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/EpaySettings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEpaySettingsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpaySettingChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpaySettingChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getEpaySettingsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGetFromAllLocationsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (LocationIDs !== undefined && LocationIDs !== null && LocationIDs.length > 0) {
         additionalParameters.push("LocationIDs=" + LocationIDs);
      }
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/GetFromAllLocations",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getGetFromAllLocationsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationGroupModel>> {
      return this.apiService.getCollection(
         this.getGetFromAllLocationsCollectionUrl(
            filters,
            embeds,
            fields,
            pageSize,
            pageNumber,
            LocationIDs,
            additionalParameters
         )
      );
   }

   getGLAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/GLAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGLAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getGLAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getInventoryItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/InventoryItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getInventoryItems(
      id: number,
      itemID: number,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<InventoryItemModel> {
      return this.apiService.getSingle(
         this.getInventoryItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getInventoryItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/InventoryItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getInventoryItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InventoryItemModel>> {
      return this.apiService.getCollection(
         this.getInventoryItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postInventoryItemsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/InventoryItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postInventoryItems(
      id: number,
      item?: InventoryItemModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<InventoryItemModel> {
      return this.apiService.postSingle(
         this.postInventoryItemsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postInventoryItemsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/InventoryItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postInventoryItemsCollection(
      id: number,
      items: Array<InventoryItemModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InventoryItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InventoryItemModel>> {
      return this.apiService.postCollection(
         this.postInventoryItemsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteInventoryItemsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InventoryItemDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ChargeTypesService.endpoint + "/InventoryItems",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteInventoryItems(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InventoryItemDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteInventoryItemsUrl(id, ids, deleteOptions, additionalParameters));
   }

   postMergeChargeTypesUrl(
      saveOptions?: Array<string | MergeChargeTypeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/MergeChargeTypes",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postMergeChargeTypes(
      item?: MergeChargeTypeModel | null,
      saveOptions?: Array<string | MergeChargeTypeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postMergeChargeTypesUrl(saveOptions, additionalParameters), item);
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRecurringChargeTypesForRentRollCollectionUrl(
      propertyIDs?: Array<number> | null,
      postDate?: Date | null | null,
      leasePeriod?: LeasePeriod | null,
      allowNullMoveInDate?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (propertyIDs !== undefined && propertyIDs !== null && propertyIDs.length > 0) {
         additionalParameters.push("propertyIDs=" + propertyIDs);
      }
      if (postDate !== undefined && postDate !== null) {
         additionalParameters.push("postDate=" + postDate);
      }
      if (leasePeriod !== undefined && leasePeriod !== null) {
         additionalParameters.push("leasePeriod=" + leasePeriod);
      }
      if (allowNullMoveInDate !== undefined && allowNullMoveInDate !== null) {
         additionalParameters.push("allowNullMoveInDate=" + allowNullMoveInDate);
      }
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/RecurringChargeTypesForRentRoll",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargeTypesForRentRollCollection(
      propertyIDs?: Array<number> | null,
      postDate?: Date | null | null,
      leasePeriod?: LeasePeriod | null,
      allowNullMoveInDate?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getRecurringChargeTypesForRentRollCollectionUrl(
            propertyIDs,
            postDate,
            leasePeriod,
            allowNullMoveInDate,
            additionalParameters
         )
      );
   }

   getRentRollRecurringChargeTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | RecurringChargeTypesForRentRollGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/RentRollRecurringChargeTypes",
         null,
         null,
         filters,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRentRollRecurringChargeTypesCollection(
      filters?: Array<FilterOption> | null,
      getOptions?: Array<string | RecurringChargeTypesForRentRollGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getRentRollRecurringChargeTypesCollectionUrl(filters, getOptions, additionalParameters)
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeTypeModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ChargeTypeEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChargeTypesService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeTypeModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChargeTypesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
