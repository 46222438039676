////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum SystemWebPreference {
   NotSet = 0,
   ResidentWebAccessPurchased = 1,
   ResidentWebAccessAvailable = 2,
   OwnerWebAccessPurchased = 3,
   OwnerWebAccessAvailable = 4,
   ServiceManagerWebAccessEnabled = 5,
   ServiceManagerWebAccessPurchased = 6,
   ServiceManagerWebAccessAvailable = 7,
   MobileWebAccessEnabled = 8,
   MobileWebAccessPurchased = 9,
   MobileWebAccessAvailable = 10,
   UnitAvailabilityWebAccessEnabled = 11,
   AvidxChangeID = 12,
   IsAvidPayEnabled = 13,
   CashPayAccountID = 14,
   IsCashPayEnabled = 15,
   RequireLocationInfoInURLOWA = 16,
   AutomaticallyDepositEpayPayments = 17,
   CombineSameDayEpayDeposits = 18,
   MinPasswordLengthWA = 19,
   MaxPasswordLengthWA = 20,
   MinPasswordNumsWA = 21,
   MinPasswordSpecialCharsWA = 22,
   LoginPageMessageRWA = 23,
   FailedLoginAttemptsUntilLockoutRWA = 24,
   LockoutTimeRWA = 25,
   DaysActiveAfterMoveoutRWA = 26,
   MessageToShowAllUsersRWA = 27,
   AllowCustomersToEmailPropertyManagerRWA = 28,
   UseMgmtCoNameAsPropertyNameTWA = 29,
   OverrideContactInfoTWA = 30,
   OverrideContactNameTWA = 31,
   OverrideContactEmailTWA = 32,
   LeaseHistoryItemCategory = 33,
   UseCustomLandlordLabel = 34,
   CustomLandlordLabel = 35,
   PublishedDocumentValidDays = 36,
   RequireLocationInfoInURLTWA = 37,
   UseTempTitleRWA = 38,
   UseCustomTitleRWA = 39,
   CustomerTitleRWA = 40,
   OverrideAssociationTitle = 41,
   UseCustomTitleAssociation = 42,
   AssociationTitleRWA = 43,
   CustomTitleServiceIssuePlural = 44,
   CustomTitleServiceIssueSingular = 45,
   ShowCompleteTransactionHistoryRWA = 46,
   ShowServiceIssuesRWA = 47,
   ExcludeServiceIssuesFromAssociations = 48,
   UseCustomTitleServiceIssue = 49,
   ShowLeasesRWA = 50,
   ExcludeLeasesFromAssociations = 51,
   EnableViewingMeterUtilitiesRWA = 52,
   ExcludeMeteredUtilitiesFromAssociations = 53,
   ShowHistoryNotesRWA = 54,
   ShowCompletedAppsRWA = 55,
   ShowDocumentsToSignRWA = 56,
   ShowPayleaseStatementsRWA = 57,
   ShowFilesRWA = 58,
   ShowOpenChargesRWA = 59,
   AllowMakingPaymentsRWA = 60,
   ShowMessageBoardRWA = 61,
   ShowViolationsRWA = 62,
   AllowCustomersToCreateAccountsRWA = 63,
   VerifyWithPhoneNumberRWA = 64,
   VerifyWithSSNRWA = 65,
   UseLastFourDigitsOnSSNVerifyRWA = 66,
   VerifyBirthDateRWA = 67,
   VerifyEmailAddressRWA = 68,
   VerifyUDF1RWA = 69,
   UDF1RWA = 70,
   VerifyUDF2RWA = 71,
   UDF2RWA = 72,
   VerifyUDF3RWA = 73,
   UDF3RWA = 74,
   AllowCustomerToChangePersonalInfoRWA = 75,
   SelectedHistoryNoteCategoriesRWA = 76,
   ShowPropertyNotesRWA = 77,
   ShowUnitNotesRWA = 78,
   CustFinancialViewOptionRWA = 79,
   ShowBalanceDueRWA = 80,
   AllowPaymentsOverBalanceDueRWA = 81,
   DoNotAllowPartialPaymentsRWA = 82,
   NoEpayMessageRWA = 83,
   RequireEpayDisclaimerRWA = 84,
   EpayDisclaimerMessageRWA = 85,
   OptInForCreditReportingRWA = 86,
   EnableTimeZoneOffsetRWA = 87,
   TimeZoneOffsetRWA = 88,
   UseDaylightSavingsTimeRWA = 89,
   AllowCustsToSeeAmountDueOnAccountGroupsRWA = 90,
   EnableMessageBoardStateRWA = 91,
   AllowCustsToCreateServiceIssuesRWA = 92,
   ShowAllServiceIssueDetailsRWA = 93,
   UsePreDefinedServiceIssueTitlesRWA = 94,
   PreDefinedServiceIssueTitlesRWA = 95,
   AllowCustomServiceIssueTitlesRWA = 96,
   SelectedServiceIssueCategoriesRWA = 97,
   UseDefaultHeaderImageRWA = 98,
   CustomHeaderImageRWA = 99,
   EnableAutoPayConfigurationTWA = 100,
   AutoPayDatesAllowedTWA = 101,
   EnableWebChatTWA = 102,
   SpecifiedQueueTWA = 103,
   LoginPageMessageLWA = 104,
   MessageToShowAllUsersLWA = 105,
   ProspectApplicationsOnlineApplicationsTWA = 106,
   ProspectApplicationsDisclaimerTextTWA = 107,
   ProspectApplicationsEnableScreeningsTWA = 108,
   ProspectApplicationsAccountCreationDefaultPropertyTWA = 109,
   ProspectApplicationsScreeningReportTypeTWA = 110,
   ProspectApplicationsApplyNowPageTextTWA = 111,
   ProspectApplicationsConsentToScreeningTextTWA = 112,
   ProspectApplicationsESignCheckboxTextTWA = 113,
   ProspectReserveUnitUponOAPSubmitTWA = 114,
   OverrideContactInfoLWA = 115,
   OverrideContactNameLWA = 116,
   OverrideContactEmailLWA = 117,
   CanSeeWebInspectionReportRWA = 118,
   GeneralMessageOWA = 119,
   FailedLoginAttemptsUntilLockoutOWA = 120,
   LockoutTimeOWA = 121,
   DaysActiveAfterContractEndOWA = 122,
   OverrideDefaultHeaderImageOWA = 123,
   HeaderImagePathOWA = 124,
   UseMgmtCoNameAsPropertyNameOWA = 125,
   OverrideContactInfoOWA = 126,
   OverrideContactNameOWA = 127,
   OverrideContactEmailOWA = 128,
   UseCustomCompanyRepresentativeLabel = 129,
   CustomCompanyRepresentativeLabel = 130,
   PublishedDocumentValidDaysOWA = 131,
   AllowOwnerToEmailPropertyManager = 132,
   ViewReportsOWA = 133,
   ViewContributionsOWA = 134,
   ViewPasswordChangesOWA = 135,
   ViewHistoryNotesOWA = 136,
   ShowOwnershipBalanceOWA = 137,
   ShowOwnershipPercentOWA = 138,
   ShowSecurityDepositsOWA = 139,
   ShowReserveOWA = 140,
   ShowOwnershipsOWA = 141,
   ShowChecksOWA = 142,
   ShowOwnerChecksOWA = 143,
   ShowMgmtFeesOWA = 144,
   ShowBillsOWA = 145,
   ShowBillAttachmentsOWA = 146,
   ShowPassthruFeesOWA = 147,
   ReportPrivsOWA = 148,
   RestrictbyOwnerContractOWA = 149,
   AllowOwnerToCreateAccountsOWA = 150,
   VerifyWithPhoneNumberOWA = 151,
   VerifyWithTaxIDOWA = 152,
   VerifyWithTaxIDLastFourDigitsOWA = 153,
   VerifyWithEmailAddressOWA = 154,
   VerifyWithUDF1OWA = 156,
   UDF1OWA = 157,
   VerifyWithUDF2OWA = 158,
   UDF2OWA = 159,
   VerifyWithUDF3OWA = 160,
   UDF3OWA = 161,
   AllowOwnerToChangePersonalInformationOWA = 162,
   SelectedHistoryNoteCategoriesOWA = 163,
   EmailWhenNewNotesAddedOWA = 164,
   EmailWhenNewPublishFilesOWA = 165,
   ShowPropertyNotesOWA = 166,
   ShowUnitNotesOWA = 167,
   ShowCustNotesOWA = 168,
   RequireEpayDisclaimerOWA = 169,
   EpayDisclaimerOWA = 170,
   ExcludeVendorInformationFromServiceIssuesOWA = 171,
   ExcludeTenantInformationFromServiceIssuesOWA = 172,
   ShowOpenServiceIssuesOWA = 173,
   ShowClosedServiceIssuesOWA = 174,
   ShowWorkOrdersOWA = 175,
   SelectedServiceIssueNotesCategoriesOWA = 176,
   ServiceIssueCategoriesToDisplayOWA = 177,
   CanSeeWebInspectionReportOWA = 178,
   EnableWebChatOWA = 179,
   SpecifiedQueueOWA = 180,
   UnitAvailabilityPagingOption = 181,
   ShowSorting = 182,
   UnitAvailabilityLocationSelection = 183,
   UnitAvailabilityTimedScrollTemplate = 184,
   UnitAvailabilityTimedScrollUDFValue = 185,
   ApplicationFeeOAP = 186,
   ApplicationFeeDefaultChargeTypeOAP = 187,
   ConvenienceFeeDefaultChargeTypeOAP = 188,
   ACHConvenienceFeeOAP = 189,
   CreditCardConvenienceFeeOAP = 190,
   RequireEpayDisclaimerOAP = 191,
   EpayDisclaimerOAP = 192,
   LimitCountOAP = 193,
   EmailSendAsHtmlOAP = 194,
   EpayEnabledOAP = 195,
   DefaultPropertyOAP = 196,
   HavePayleaseManageConvenienceFeesOAP = 197,
   DefaultApplicationTemplateOAP = 198,
   ApplyNowPropertySelectionMethodOAP = 199,
   ApplyNowPropertySelectionValueOAP = 200,
   APITestValue = 201,
   EnableSiteManagerApplyNow = 202,
   EnableWebChatWTS = 203,
   SpecifiedQueueWTS = 204,
   DoNotRequirePaymentOAP = 205,
   PayLaterMessageOAP = 206,
   FeePropertyIDOAP = 207,
   OverrideSystemDefaultPaymentTypeOAP = 208,
   ACHOAP = 209,
   DinersClubOAP = 210,
   VisaMasterCardOAP = 211,
   AmexOAP = 212,
   DiscoverOAP = 213,
   CashPayOAP = 214,
   ApplyNowUnitSelectionMethod = 215,
   ApplyNowUnitSubSelectionMethod = 216,
   ApplyNowEnableEpay = 217,
   ApplyNowUnitSelectionValue = 218,
   ApplyNowUseUnitTypeDesc = 219,
   ApplyNowDefaultProperty = 220,
   ApplyNowLimitCount = 221,
   ApplyNowRequireSignaturePriorCompletingApplication = 222,
   ApplyNowHavePayleaseManageConvenienceFees = 223,
   ApplyNowEpayDisclaimer = 225,
   ApplyNowRequireEpayDisclaimer = 226,
   AlwaysDisplayIssuesCreatedRWA = 227,
   DisplayIssueCreatedCategoriesRWA = 228,
   CustomMessageRWA = 229,
   IncludeIssueHistoryNotesCategoriesRWA = 230,
   RemovePetsRWA = 231,
   SelectedIssueHistoryNotesCategoriesRWA = 232,
   DisplayIssueCreatedCategoriesOWA = 233,
   IncludeIssueHistoryNotesCategoriesOWA = 234,
   NotificationEmailOAP = 235,
   EpayUsePropertyEmailOAP = 236,
   EmailApplicantOnSubmissionOAP = 237,
   EmailBccOAP = 238,
   EmailFromAddressOAP = 239,
   EmailSubjectOAP = 240,
   EmailMessageOAP = 241,
   EmailUserForNewServiceIssueTicketsRWA = 242,
   NewServiceIssueUserSubjectRWA = 243,
   NewServiceIssueUserMessageRWA = 244,
   EmailCustForNewServiceTicketsRWA = 245,
   NewServiceIssueFromEmailRWA = 246,
   NewServiceIssueCustSubjectRWA = 247,
   NewServiceIssueCustMessageRWA = 248,
   IncludeUnitTypesFromUALocations = 249,
   IncludeOpenEstimatesOWA = 250,
   IncludeClosedEstimatesOWA = 251,
   IncludeHistoryEstimatesOWA = 252,
   IncludeHistoryCategoriesEstimatesOWA = 253,
   SendAwaitingApprovalNoticeEstimatesOWA = 254,
   EnableApprovalRejectionEstimatesOWA = 255,
   ApprovalMessageEstimatesOWA = 256,
   ApprovalConfirmationMessageEstimateOWA = 257,
   RejectionMessageEstimatesOWA = 258,
   ExcludeFromProspectRWA = 259,
   RequirePaymentOfRWA = 260,
   DeclineRenewalMessageRWA = 261,
   CompleteRenewalWhenPublishedDocumentCompletedRWA = 262,
   IncludeIssuesForAllTenantsInAccountGroupRWA = 263,
   EmailHistoryCategoryRWA = 264,
   EmailHistoryCategoryOWA = 265,
   RequireExpectedMoveOutDateWhenOfferDeclinedRWA = 267,
   SetNoticeDateWhenMoveOutDateEnteredRWA = 268,
   IsDebitCardPayEnabled = 278,
   ShowArchitecturalRequests = 279,
   PublishedDocumentNoteForOWA = 281,
   IsLinkApplicationsToExistingProspectsTWA = 282,
   IsDisplayWarningApplicationWithoutCoAppsTWA = 283,
   WarningApplicationWithoutCoAppsTWA = 284,
   VerificationEmailSubjectTWA = 285,
   VerificationEmailBody1TWA = 286,
   VerificationEmailBody2TWA = 287,
   TWADashboardTileOrder = 288,
   TWAThemeColor = 289,
   RMResidentIsPaymentDueNotificationEnabled = 290,
   RMResidentPaymentDueNotificationSendDay = 291,
   RMResidentPaymentDueNotificationText = 292,
   TWACustomHeaderPropertyUDFImage = 293,
   TWACustomHeaderImageAlignment = 294,
   TWACustomHeaderBackgroundColor = 295,
   TWAPageHeaderColor = 296,
   TWATileHeaderColor = 297,
   TWAButtonColor = 298,
   TWAAccentColor = 299,
   TWAHeaderUseImageFile = 300,
   TWAHeaderUsePropertyUDF = 301,
   AllowTenantToPurchaseRentersInsuranceRWA = 302,
   OWACustomHeaderImageAlignment = 304,
   OWACustomHeaderBackgroundColor = 305,
   OWAToolbarColor = 306,
   OWAButtonsAndAccentColor = 307,
   OWAThemeColor = 308,
   RequireLocationInfoInURLStrOnlineBooking = 309,
   STROnlineBookingRequireVehicleMakeModel = 310,
   STROnlineBookingRequireVehicleLicensePlate = 311,
   STROnlineBookingRequireVehicleYear = 312,
   STROnlineBookingAllowAdditionalVehicles = 313,
   STROnlineBookingAllowLeadSources = 314,
   STROnlineBookingEPayOverrideSysPrefDefaultPayment = 316,
   STROnlineBookingACH = 317,
   STROnlineBookingDiscover = 318,
   STROnlineBookingVisaMastercard = 319,
   STROnlineBookingAmericanExpress = 320,
   STROnlineBookingDebitCards = 321,
   STROnlineBookingZegoManageConvenienceFees = 322,
   STROnlineBookingCCFee = 323,
   STROnlineBookingCCFeeAmount = 324,
   STROnlineBookingCCFeePercent = 325,
   STROnlineBookingACHFee = 326,
   STROnlineBookingACHFeeAmount = 327,
   STROnlineBookingACHFeePercent = 328,
   STROnlineBookingEPayRequireDisclaimer = 329,
   STROnlineBookingEPayDisclaimer = 330,
   STROnlineBookingCampgroundConvenienceFeeChargeType = 331,
   STROnlineBookingDebitCardFee = 332,
   STROnlineBookingDebitCardFeeAmount = 333,
   STROnlineBookingDebitCardFeePercent = 334,
   ExcludeSubsidyChargeTypesRWA = 335,
   STROnlineBookingUseProspect = 338,
   STROnlineBookingFutureGuestBookReservationUseSpecificDate = 339,
   STROnlineBookingFutureGuestBookReservationDays = 340,
   STROnlineBookingFutureGuestBookReservationSpecificDate = 341,
   STROnlineBookingBookReservationSameDay = 342,
   STROnlineBookingBookReservationDays = 343,
   STROnlineBookingAllowMaximumNights = 344,
   STROnlineBookingMaximumNights = 345,
   STROnlineBookingMaximumNightsExceededMessage = 346,
   STROnlineBookingMaximumNightsDisplayApplyNow = 347,
   STROnlineBookingSuccessMessage = 348,
   ShowCommunityDirectory = 349,
   STROnlineBookingDefaultPaymentPolicy = 350,
   ShowLiveReports = 351,
   LiveReportsMenuTitle = 352,
   LiveReportsTitle = 353,
   LiveReportsCustomMessage = 354,
   ShowReservationsRWA = 999,
   ShowCommunityCalendar = 1000,
}
