import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { StartupStep } from "@lcs/application-status/startup-step.enum";
import { StartupService } from "@lcs/application-status/startup.service";
import { SessionCheckResult } from "@lcs/authentication/session-check-result.enum";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { AuthenticationStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-status.service";
import { AuthenticationStep } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-step.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ExpressSessionStatusModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-session-status.model";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { catchError, defer, filter, first, fromEvent, map, Observable, of, Subject, Subscription, switchMap, takeUntil } from "rxjs";

@Injectable()
export class OWASessionCheckService implements OnDestroy, SessionCheckService {
   checkSessionOnFocus: boolean = true;

   get sessionCheckResult(): Observable<SessionCheckResult> {
      return this._sessionCheckResult.asObservable();
   }

   private contactingServerSubscription: Subscription;

   private _sessionCheckResult = new Subject<SessionCheckResult>();

   private lastSessionCheckTime: Date;

   private lastSessionCheckResult: SessionCheckResult;

   private sessionTimeOutDate: Date;

   private sessionExpirationDate: Date;

   private sessionStatusObjectLifetimeInSeconds = 15;

   private unsubscribe = new Subject<void>();

   constructor(
      private httpClient: HttpClient,
      private sessionStatusService: SessionStatusService,
      private cacheMonitorService: CacheMonitorService,
      private startupService: StartupService,
      private apiRequestHelperService: ApiRequestHelperService,
      private authenticationStatusService: AuthenticationStatusService,
      private errorMessageService: ErrorMessageService
   ) {
      fromEvent(window, "focus")
         .pipe(
            filter(() => {
               const startupComplete = this.startupService.startupStatus.value === StartupStep.ReadyForAuthentication;
               return startupComplete && this.checkSessionOnFocus;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(() => {
            this.forceCheckSessionAsync(false);
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   forceCheckSession(forRouting: boolean): Observable<SessionCheckResult> {
      return defer(() => {
         setTimeout(() => {
            this.forceCheckSessionAsync(forRouting);
         });
         return this.sessionCheckResult;
      });
   }

   checkSession(forRouting?: boolean): Observable<SessionCheckResult> {
      return defer(() => {
         setTimeout(() => {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
            this.checkSessionAsync(forRouting);
         });
         return this.sessionCheckResult;
      });
   }

   forceCheckSessionAsync(forRouting: boolean): void {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
      this.lastSessionCheckTime = null;
      this.checkSessionAsync(forRouting);
   }

   checkSessionAsync(forRouting: boolean): void {
      if (this.lastSessionCheckResult && !forRouting) {
         let timeSinceRetrievalInSeconds = this.sessionStatusObjectLifetimeInSeconds + 1;
         if (this.lastSessionCheckTime) {
            timeSinceRetrievalInSeconds = (Date.now() - this.lastSessionCheckTime.getTime()) / 1000;
         }

         let timeLeftUntilTimeout = 0;
         let timeLeftUntilExpiration = 0;
         if (this.sessionTimeOutDate) {
            timeLeftUntilTimeout = new Date(this.sessionTimeOutDate).getTime() - Date.now();
         }
         if (this.sessionExpirationDate) {
            timeLeftUntilExpiration = new Date(this.sessionExpirationDate).getTime() - Date.now();
         }

         if (
            timeLeftUntilTimeout > 0 &&
            timeLeftUntilExpiration > 0 &&
            timeSinceRetrievalInSeconds < this.sessionStatusObjectLifetimeInSeconds
         ) {
            this._sessionCheckResult.next(this.lastSessionCheckResult);
            return;
         }
      }

      if (this.contactingServerSubscription) {
         return;
      }

      this.contactingServerSubscription = this.contactServer(forRouting)
         .pipe(
            takeUntil(this.unsubscribe),
            switchMap((result: SessionCheckResult) => {
               if (result === SessionCheckResult.Successful) {
                  return this.checkForApplicationLoaded(result);
               } else {
                  return of(result);
               }
            })
         )
         .subscribe((sessionCheckResult) => {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
            this.contactingServerSubscription = null;
            if (sessionCheckResult === SessionCheckResult.Successful) {
               this._sessionCheckResult.next(SessionCheckResult.Successful);
            } else {
               this.sessionStatusService.clearSession();
               this._sessionCheckResult.next(SessionCheckResult.Failed);
            }
         });
   }

   private contactServer(forRouting: boolean): Observable<SessionCheckResult> {
      return this.startupService.startupStatus.pipe(
         filter((status) => {
            return status === StartupStep.ReadyForAuthentication;
         }),
         switchMap(() => {
            return this.getSessionStatus();
         }),
         catchError((error: HttpErrorResponse) => {
            this.sessionStatusService.clearSession();
            if (error.status === HttpStatusCode.Unauthorized) {
               this.handleSessionStatusError(error, forRouting);
            } else {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
            return of(null);
         }),
         map((sessionStatus: ExpressSessionStatusModel) => {
            return this.processSessionStatusResult(sessionStatus);
         })
      );
   }

   private getSessionStatus(): Observable<ExpressSessionStatusModel> {
      const headers = this.apiRequestHelperService.buildInitialHeaders();
      return this.httpClient.get<ExpressSessionStatusModel>(
         this.apiRequestHelperService.getUrl(`${SessionStatusService.sessionStatusEndpoint}/SessionStatus`),
         {
            headers: headers,
            withCredentials: true,
         }
      );
   }

   private handleSessionStatusError(error: HttpErrorResponse, forRouting: boolean) {
      if (!forRouting) {
         this.authenticationStatusService.updateAuthenticationStatus(AuthenticationStep.ReauthenticationNeeded);
      }
   }

   private checkForApplicationLoaded(checkResult: SessionCheckResult): Observable<SessionCheckResult> {
      this.cacheMonitorService.load();
      if (this.cacheMonitorService.cachesLoadedState) {
         return of(checkResult);
      } else {
         return this.cacheMonitorService.cachesLoaded.pipe(
            first(),
            map(() => {
               return SessionCheckResult.Successful;
            })
         );
      }
   }

   private processSessionStatusResult(sessionStatus: ExpressSessionStatusModel): SessionCheckResult {
      let result = SessionCheckResult.NotSet;
      if (!sessionStatus) {
         result = SessionCheckResult.Failed;
      } else if (
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         sessionStatus.BuildTimeStamp.toString() != this.startupService.applicationInformation.value.BuildTimestamp
      ) {
         console.error("New Application Version Detected.");
         this.sessionStatusService.applicationBuildDetected.next();
         result = SessionCheckResult.Failed;
      } else {
         this.lastSessionCheckTime = new Date();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
         this.sessionTimeOutDate = sessionStatus.TimeOutDate;
         this.sessionExpirationDate = sessionStatus.ExpirationDate;
         this.sessionStatusService.updateSession(sessionStatus);
         result = SessionCheckResult.Successful;
      }
      this.lastSessionCheckResult = result;
      return result;
   }
}
