import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-values-to-include-report-parameter",
   templateUrl: "values-to-include-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class ValuesToIncludeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   chargeAmountValidation: ValidationModel;

   consumptionValidation: ValidationModel;

   parameterLabel: string = "Values to Include";

   consumptionLabel: string = "Consumption (Usage) :";

   chargesLabel: string = "Charges ($ Amounts) :";

   fromConsumption: ReportReportParameterViewModel;

   toConsumption: ReportReportParameterViewModel;

   fromCharges: ReportReportParameterViewModel;

   toCharges: ReportReportParameterViewModel;

   fromChargeValueModel: ReportParameterValueModel;

   toChargeValueModel: ReportParameterValueModel;

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {
      this.initializeValidation();
   }

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {}

   toChargeChanged(value: number) {
      this.toChargeValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.toChargeValueModel);
   }

   fromChargeChanged(value: number) {
      this.fromChargeValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.fromChargeValueModel);
   }

   private initializeValidation() {
      const amount = new ValidationModel();
      amount.dataType = ExpressDataTypes.Numeric;
      amount.maxFractionalDigits = 2;
      amount.max = 9999999.99;
      const consumption = new ValidationModel();
      amount.dataType = ExpressDataTypes.Numeric;
      amount.maxFractionalDigits = 0;
      amount.max = 9999999;
      this.consumptionValidation = consumption;
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fromConsumption = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.FROMCONSUMPT
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.toConsumption = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.TOCONSUMPT);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.fromCharges = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.FROMCHARGE);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.toCharges = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.TOCHARGE);
      if (this.fromCharges) {
         this.fromChargeValueModel = new ReportParameterValueModel(this.fromCharges.ReportParameterID, 0.0);
      }
      if (this.toCharges) {
         this.toChargeValueModel = new ReportParameterValueModel(this.toCharges.ReportParameterID, 9999999.99);
      }
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
