import { Component, OnInit } from "@angular/core";

@Component({
   selector: "owa-account-information-detail",
   templateUrl: "./account-information-detail.component.html",
   styleUrls: ["./account-information-detail.component.css"],
})
export class AccountInformationDetailComponent implements OnInit {
   constructor() {}

   ngOnInit() {}
}
