import { Injectable } from "@angular/core";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

@Injectable({ providedIn: "root" })
export class ReportParameterDependentAskService {
   dependentAskParameters: Map<ReportParameter, Array<ReportParameter>>;

   constructor() {
      this.initialize();
   }

   private initialize() {
      this.dependentAskParameters = new Map<ReportParameter, Array<ReportParameter>>();
      this.dependentAskParameters.set(ReportParameter.PropOwnerIDs, [
         ReportParameter.GroupID,
         ReportParameter.RUNBYOWNER,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.USEOWNERPROPERTIES,
         ReportParameter.INCLUDEINACTIVEOWNERS,
      ]);
      this.dependentAskParameters.set(ReportParameter.PropertyIDs, [
         ReportParameter.GroupID,
         ReportParameter.RUNBYOWNER,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.USEOWNERPROPERTIES,
         ReportParameter.INCLUDEINACTIVEOWNERS,
         ReportParameter.UNITID,
      ]);
      this.dependentAskParameters.set(ReportParameter.USERIDS, [ReportParameter.INCLUDEINACTIVEUSERS]);
      this.dependentAskParameters.set(ReportParameter.OWNERIDS, [
         ReportParameter.INCLUDEINACTIVEOWNERS,
         ReportParameter.GroupID,
         ReportParameter.SHOWINACTIVEPROPS,
      ]);
      this.dependentAskParameters.set(ReportParameter.CAMDIVEXPMETHOD, [
         ReportParameter.IsUseProRataShare,
         ReportParameter.IsUseAdminFees,
         ReportParameter.CAMTenantUDF,
      ]);
      this.dependentAskParameters.set(ReportParameter.CONSOLIDATE1096, [
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLADDR2,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLTAXID,
         ReportParameter.CONSOLZIP,
      ]);
      this.dependentAskParameters.set(ReportParameter.CONSOLIDATE1099, [
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLADDR2,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLTAXID,
         ReportParameter.CONSOLZIP,
      ]);
      this.dependentAskParameters.set(ReportParameter.Loan1098AddressToUse, [
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLADDR2,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLTAXID,
         ReportParameter.CONSOLZIP,
      ]);
      this.dependentAskParameters.set(ReportParameter.UNITID, [
         ReportParameter.PropertyID,
         ReportParameter.SHOWINACTIVEPROPS,
      ]);
      this.dependentAskParameters.set(ReportParameter.CHARTMAPID, [ReportParameter.GLAccountIDs]);
      this.dependentAskParameters.set(ReportParameter.GLAccountIDs, [ReportParameter.CHARTMAPID]);
      this.dependentAskParameters.set(ReportParameter.OwnersExcludeEmpty, [ReportParameter.BATCHOWNERSEXCLUDEEMPTY]);
      this.dependentAskParameters.set(ReportParameter.BATCHOWNERSEXCLUDEEMPTY, [ReportParameter.OwnersExcludeEmpty]);
      this.dependentAskParameters.set(ReportParameter.JobID, [ReportParameter.IncludeInactiveJobs]);
      this.dependentAskParameters.set(ReportParameter.ViolationNotesOption, [ReportParameter.NUMBERNOTES]);
      this.dependentAskParameters.set(ReportParameter.JobIDs, [ReportParameter.IncludeInactiveJobs]);
   }
}
