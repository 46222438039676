import { Component, EventEmitter, Output } from "@angular/core";

@Component({
   selector: "lcs-slide-panel",
   templateUrl: "slide-panel.component.html",
   host: { class: "lcs-slide-panel" },
})
export class LcsSlidePanelComponent {
   @Output() close = new EventEmitter<void>();

   onCloseClick() {
      this.close.emit();
   }
}
