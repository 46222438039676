////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum TextMessagingReportPeriod {
   NotSet = 0,
   Daily = 1,
   Monthly = 2,
   Weekly = 3,
}
