import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ComingSoonComponent } from "./coming-soon.component";

@NgModule({
   imports: [CommonModule, RouterModule],
   declarations: [ComingSoonComponent],
   exports: [ComingSoonComponent],
})
export class ComingSoonModule { }
