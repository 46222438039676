import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { PropertyDetailsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-details.model";
import { PropertiesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/properties.service";
import { Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-bank-selector",
   templateUrl: "bank-selector.component.html",
})
export class BankSelectorComponent extends ValueAccessorBase<number> implements OnDestroy {
   @Input() set bankID(value: number | PropertyDetailsModel) {
      if (value instanceof PropertyDetailsModel) {
         this.getDefaultBankName(value.DefaultBankID);
      } else {
         this.getDefaultBankName(value);
      }
   }

   @Input() addBlankItem: boolean;

   @Input() filters: Array<FilterOption>;

   @Input() staticValues: Array<SelectorItemModel>;

   @Input() tooltip: string;

   @Output() selected = new EventEmitter<SelectorItemModel>();

   @ViewChild(SelectComponent, { static: true }) entitySelector: SelectComponent;

   selectedItem: SelectorItemModel;

   entityType: EntityType = EntityType.Bank;

   hasBankPermission: boolean = true;

   defaultBankName: string;

   private unsubscribe = new Subject<void>();

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private propertiesService: PropertiesService
   ) {
      super(changeDetectorRef, ngControl);
   }

   getDefaultBankName(bankID: number) {
      if (bankID) {
         this.propertiesService
            .getDefaultBankName(bankID)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
               if (value) {
                  this.defaultBankName = value;
                  this.hasBankPermission = false;
               }
            });
      }
   }
   onValueChange(value: number) {
      if (this.entitySelector) {
         this.selectedItem = this.entitySelector.selectedItem;
      }

      this.selected.next(this.selectedItem);
      this.value = value;
      this.getDefaultBankName(value);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
