import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Observable } from "rxjs";

import { WebChatOptions } from "../models/web-chat-options.model";

@Injectable({
   providedIn: "root",
})
export class WebChatService {
   readonly endpoint = "WebChat";
   constructor(private apiService: ApiService) {}

   getWebChatOptionsUrl(): string {
      const endpoint = this.endpoint + "/GetWebChatOptions";
      return this.apiService.getUrl(endpoint);
   }

   getWebChatOptions(): Observable<WebChatOptions> {
      return this.apiService.getSingle(this.getWebChatOptionsUrl());
   }

   getUrl(): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(endpoint);
   }

   get(): Observable<WebChatOptions> {
      return this.apiService.getSingle(this.getUrl());
   }
}
