import { SelectorItemModel } from "./selector-item.model";

export class SelectorItemSetModel {
   name: string;
   identifier: string;

   set items(value: Array<SelectorItemModel>) {
      if (value) {
         this._items = value;
      } else {
         this._items = new Array<SelectorItemModel>();
      }
   }

   get items(): Array<SelectorItemModel> {
      return this._items;
   }
   valuePropertyName: string;
   displayNamePropertyName: string;
   defaultValue: string;
   label: string;
   private _items: Array<SelectorItemModel>;

   constructor() {
      this.items = new Array<SelectorItemModel>();
   }
}
