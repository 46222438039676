<lcs-loading-overlay [show]="$any(isLoading | async)">
   <owa-layout [headerTitle]="'Management Fee Details'">

      <div class="flex-grid">
         <owa-tile
            title="Posting Summary: {{managementFeeDetails.PropertyName}} ({{managementFeeDetails.FeeDate | date: 'MM/dd/yyyy'}})"
            class="flex-full management-fee-summary-tile"
         >
            <div>
               <div class="charges-table">
                  <div
                     *ngIf="managementFeeDetails.ChargesBreakdown.length > 0"
                     class="flex-grid"
                  >
                     <div class="flex-third">
                        <div class="grid-container">
                           <div class="tile-value table-header">Fee Amounts</div>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="managementFeeDetails.ChargesBreakdown.length > 0">
                     <table>
                        <tr>
                           <th class="table-column left-aligned">Charge</th>
                           <th class="table-column numeric-column-header">Payment Amount</th>
                           <th class="table-column numeric-column-header">Fee Amount</th>
                        </tr>
                        <tr *ngFor="let charge of managementFeeDetails.ChargesBreakdown">
                           <td
                              *ngIf="!charge.IsPassThru"
                              class="table-column top-aligned"
                           >{{charge.ChargeType}} ({{charge.Percentage}}%)</td>
                           <td
                              *ngIf="!charge.IsPassThru"
                              class="numeric-column"
                           >
                              {{charge.TotalAmount | currency: "USD":"symbol":"1.2-2" }}</td>
                           <td
                              *ngIf="!charge.IsPassThru"
                              class="numeric-column"
                           >
                              {{charge.FeeAmount | currency: "USD":"symbol":"1.2-2" }}</td>
                        </tr>
                        <div class="total-line"></div>
                        <tr>
                           <td></td>
                           <td class="numeric-column">
                              {{managementFeeDetails.TotalAmount | currency: "USD":"symbol":"1.2-2"}}</td>
                           <td class="numeric-column">
                              {{managementFeeDetails.FeeTotal | currency: "USD":"symbol":"1.2-2"}}</td>
                        </tr>
                     </table>
                  </div>
               </div>

               <div class="charges-table">
                  <div
                     class="flex-grid"
                     *ngIf="otherFees.length > 0"
                  >
                     <div class="flex-third">
                        <div class="grid-container">
                           <div class="tile-value other-fees-title">Other Fees</div>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="otherFees.length > 0">
                     <table>
                        <tr>
                           <th class="fee-type">Fee Type</th>
                           <th class="numeric-column-header">Fee Amount</th>
                        </tr>
                        <tr *ngFor="let fee of otherFees">
                           <td
                              *ngIf="fee.FeeType !== 'Per Unit Fee' && fee.FeeType !== 'Per Occupied Unit Fee'"
                              class="top-aligned"
                           >{{fee.FeeType}}</td>
                           <td
                              *ngIf="fee.FeeType === 'Per Unit Fee' || fee.FeeType === 'Per Occupied Unit Fee'"
                              class="top-aligned"
                           >{{fee.FeeType}} ({{fee.Rate | currency: "USD":"symbol":"1.2-2"}} per unit)</td>
                           <td class="numeric-column">
                              {{fee.FeeAmount | currency: "USD":"symbol":"1.2-2" }}</td>
                        </tr>
                        <div class="total-line-other-fees"></div>
                        <tr>
                           <td></td>
                           <td class="numeric-column">
                              {{otherFeeTotal | currency: "USD":"symbol":"1.2-2"}}</td>
                        </tr>
                     </table>
                  </div>
               </div>
               <div style="clear:both"></div>
            </div>
         </owa-tile>

      </div>
      <div *ngFor="let mgmtFeeDetailList of mgmtFeeArrayData">
         <owa-tile
            *ngIf="!mgmtFeeDetailList[0].IsPassThru"
            title="{{mgmtFeeDetailList[0].ChargeTypeShort}} - {{mgmtFeeDetailList[0].ChargeType}} ({{mgmtFeeDetailList[0].Percentage}}%)"
            class="flex-full full-width"
         >
            <owa-management-fees-detail-list [managementFeeDetailListViewItems]="mgmtFeeDetailList">
            </owa-management-fees-detail-list>
         </owa-tile>
      </div>

   </owa-layout>
</lcs-loading-overlay>