import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OwnerGroupFields extends EntityField {
   static readonly ConcurrencyID: OwnerGroupFields = new OwnerGroupFields("ConcurrencyID");

   static readonly CreateDate: OwnerGroupFields = new OwnerGroupFields("CreateDate");

   static readonly FilterID: OwnerGroupFields = new OwnerGroupFields("FilterID");

   static readonly GroupID: OwnerGroupFields = new OwnerGroupFields("GroupID");

   static readonly MetaTag: OwnerGroupFields = new OwnerGroupFields("MetaTag");

   static readonly Name: OwnerGroupFields = new OwnerGroupFields("Name");

   static readonly OwnerGroupDetails: OwnerGroupFields = new OwnerGroupFields("OwnerGroupDetails");

   static readonly OwnerGroupFilter: OwnerGroupFields = new OwnerGroupFields("OwnerGroupFilter");

   static readonly OwnerGroupFilterID: OwnerGroupFields = new OwnerGroupFields("OwnerGroupFilterID");

   static readonly OwnerGroupFilters: OwnerGroupFields = new OwnerGroupFields("OwnerGroupFilters");

   static readonly OwnerGroupID: OwnerGroupFields = new OwnerGroupFields("OwnerGroupID");

   static readonly Owners: OwnerGroupFields = new OwnerGroupFields("Owners");

   static readonly UpdateDate: OwnerGroupFields = new OwnerGroupFields("UpdateDate");

   static readonly UserID: OwnerGroupFields = new OwnerGroupFields("UserID");
}
