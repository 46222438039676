import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { CheckListItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check-list-item.embed-options";
import { MemorizedUserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/memorized-user-defined-value.embed-options";
import { MemorizedWorkOrderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/memorized-work-order.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { ProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect.embed-options";
import { ServiceManagerCategoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-category.embed-options";
import { ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-memorized-issue-linked-property.embed-options";
import { ServiceManagerMemorizedIssueLinkedProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-memorized-issue-linked-prospect.embed-options";
import { ServiceManagerMemorizedIssueLinkedTenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-memorized-issue-linked-tenant.embed-options";
import { ServiceManagerMemorizedIssueLinkedUnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-memorized-issue-linked-unit.embed-options";
import { ServiceManagerMemorizedIssueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-memorized-issue.embed-options";
import { ServiceManagerPriorityEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-priority.embed-options";
import { ServiceManagerProjectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-project.embed-options";
import { ServiceManagerStatusEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-status.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { UnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { VendorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor.embed-options";
import { UnitGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/unit.get-options";
import { CheckListItemSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check-list-item.save-options";
import { ServiceManagerIssueWorkOrderSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/service-manager-issue-work-order.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { CheckListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check-list-item.model";
import { MemorizedUserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/memorized-user-defined-value.model";
import { MemorizedWorkOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/memorized-work-order.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { ProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect.model";
import { ServiceManagerCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-category.model";
import { ServiceManagerMemorizedIssueLinkedPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-memorized-issue-linked-property.model";
import { ServiceManagerMemorizedIssueLinkedProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-memorized-issue-linked-prospect.model";
import { ServiceManagerMemorizedIssueLinkedTenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-memorized-issue-linked-tenant.model";
import { ServiceManagerMemorizedIssueLinkedUnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-memorized-issue-linked-unit.model";
import { ServiceManagerMemorizedIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-memorized-issue.model";
import { ServiceManagerPriorityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-priority.model";
import { ServiceManagerProjectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-project.model";
import { ServiceManagerStatusModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-status.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ServiceManagerMemorizedIssuesService {
   static readonly AssignedToUserResource = "AssignedToUser";
   static readonly AvailableChildLinksForProspectResource = "AvailableChildLinksForProspect";
   static readonly AvailableChildLinksForTenantResource = "AvailableChildLinksForTenant";
   static readonly AvailableChildLinksForUnitResource = "AvailableChildLinksForUnit";
   static readonly CategoryResource = "Category";
   static readonly CheckListItemsResource = "CheckListItems";
   static readonly CreateUserResource = "CreateUser";
   static readonly GetByPostResource = "GetByPost";
   static readonly MemorizedUserDefinedValuesResource = "MemorizedUserDefinedValues";
   static readonly MemorizedWorkOrdersResource = "MemorizedWorkOrders";
   static readonly PayeeAccountResource = "PayeeAccount";
   static readonly PriorityResource = "Priority";
   static readonly ProjectResource = "Project";
   static readonly PropertiesResource = "Properties";
   static readonly ProspectsResource = "Prospects";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly ServiceManagerMemorizedIssueLinkedPropertiesResource =
      "ServiceManagerMemorizedIssueLinkedProperties";
   static readonly ServiceManagerMemorizedIssueLinkedProspectsResource = "ServiceManagerMemorizedIssueLinkedProspects";
   static readonly ServiceManagerMemorizedIssueLinkedTenantsResource = "ServiceManagerMemorizedIssueLinkedTenants";
   static readonly ServiceManagerMemorizedIssueLinkedUnitsResource = "ServiceManagerMemorizedIssueLinkedUnits";
   static readonly StatusResource = "Status";
   static readonly TenantsResource = "Tenants";
   static readonly UnitsResource = "Units";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadMemorizedUserDefinedValueAttachmentResource = "UploadMemorizedUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UsersThatCanBeAssignedToMemorizedServiceIssueResource =
      "UsersThatCanBeAssignedToMemorizedServiceIssue";
   static readonly endpoint = "ServiceManagerMemorizedIssuesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ServiceManagerMemorizedIssueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ServiceManagerMemorizedIssueModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint,
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAssignedToUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/AssignedToUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssignedToUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getAssignedToUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getAvailableChildLinksForProspectUrl(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/AvailableChildLinksForProspect",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForProspect(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedProspectModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForProspectUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForTenantUrl(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/AvailableChildLinksForTenant",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForTenant(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedTenantModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForTenantUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForUnitUrl(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/AvailableChildLinksForUnit",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForUnit(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedUnitModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForUnitUrl(id, embeds, additionalParameters));
   }

   getCategoryUrl(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Category",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCategory(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerCategoryModel> {
      return this.apiService.getSingle(this.getCategoryUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCheckListItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CheckListItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCheckListItems(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.getSingle(
         this.getCheckListItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCheckListItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CheckListItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCheckListItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.getCollection(
         this.getCheckListItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCheckListItemsUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItems(
      id: number,
      item?: CheckListItemModel | null,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.postSingle(
         this.postCheckListItemsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCheckListItemsCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItemsCollection(
      id: number,
      items: Array<CheckListItemModel>,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.postCollection(
         this.postCheckListItemsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCheckListItemsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CheckListItems",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCheckListItems(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCheckListItemsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getMemorizedUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedUserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMemorizedUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MemorizedUserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getMemorizedUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMemorizedUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedUserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMemorizedUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedUserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getMemorizedUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllMemorizedUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedUserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllMemorizedUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedUserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllMemorizedUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMemorizedUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedUserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMemorizedUserDefinedValues(
      id: number,
      item?: MemorizedUserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MemorizedUserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postMemorizedUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postMemorizedUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedUserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMemorizedUserDefinedValuesCollection(
      id: number,
      udvs?: Array<MemorizedUserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedUserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedUserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postMemorizedUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         udvs
      );
   }

   getMemorizedWorkOrdersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMemorizedWorkOrders(
      id: number,
      itemID: number,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MemorizedWorkOrderModel> {
      return this.apiService.getSingle(
         this.getMemorizedWorkOrdersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMemorizedWorkOrdersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMemorizedWorkOrdersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedWorkOrderModel>> {
      return this.apiService.getCollection(
         this.getMemorizedWorkOrdersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllMemorizedWorkOrdersCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllMemorizedWorkOrdersCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedWorkOrderModel>> {
      return this.apiService.getCollection(
         this.getAllMemorizedWorkOrdersCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMemorizedWorkOrdersUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMemorizedWorkOrders(
      id: number,
      item?: MemorizedWorkOrderModel | null,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MemorizedWorkOrderModel> {
      return this.apiService.postSingle(
         this.postMemorizedWorkOrdersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postMemorizedWorkOrdersCollectionUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMemorizedWorkOrdersCollection(
      id: number,
      items: Array<MemorizedWorkOrderModel>,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MemorizedWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MemorizedWorkOrderModel>> {
      return this.apiService.postCollection(
         this.postMemorizedWorkOrdersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteMemorizedWorkOrdersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/MemorizedWorkOrders",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteMemorizedWorkOrders(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteMemorizedWorkOrdersUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getPayeeAccountUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/PayeeAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPayeeAccount(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getPayeeAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPriorityUrl(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Priority",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPriority(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerPriorityModel> {
      return this.apiService.getSingle(this.getPriorityUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getProjectUrl(
      id: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Project",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProject(
      id: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerProjectModel> {
      return this.apiService.getSingle(this.getProjectUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Properties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Properties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Prospects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(
         this.getProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Prospects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ProspectModel>> {
      return this.apiService.getCollection(
         this.getProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getServiceManagerMemorizedIssueLinkedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedPropertyModel> {
      return this.apiService.getSingle(
         this.getServiceManagerMemorizedIssueLinkedPropertiesUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerMemorizedIssueLinkedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedPropertyModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerMemorizedIssueLinkedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerMemorizedIssueLinkedPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedProperties(
      id: number,
      item?: ServiceManagerMemorizedIssueLinkedPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedPropertyModel> {
      return this.apiService.postSingle(
         this.postServiceManagerMemorizedIssueLinkedPropertiesUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         item
      );
   }

   postServiceManagerMemorizedIssueLinkedPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedPropertiesCollection(
      id: number,
      propertiesToLink?: Array<ServiceManagerMemorizedIssueLinkedPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedPropertyModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerMemorizedIssueLinkedPropertiesCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         propertiesToLink
      );
   }

   deleteServiceManagerMemorizedIssueLinkedPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerMemorizedIssueLinkedProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerMemorizedIssueLinkedPropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerMemorizedIssueLinkedProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProspects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedProspectModel> {
      return this.apiService.getSingle(
         this.getServiceManagerMemorizedIssueLinkedProspectsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerMemorizedIssueLinkedProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProspects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedProspectModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerMemorizedIssueLinkedProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerMemorizedIssueLinkedProspectsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedProspects(
      id: number,
      item?: ServiceManagerMemorizedIssueLinkedProspectModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedProspectModel> {
      return this.apiService.postSingle(
         this.postServiceManagerMemorizedIssueLinkedProspectsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerMemorizedIssueLinkedProspectsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedProspectsCollection(
      id: number,
      prospectsToLink?: Array<ServiceManagerMemorizedIssueLinkedProspectModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedProspectModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerMemorizedIssueLinkedProspectsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         prospectsToLink
      );
   }

   deleteServiceManagerMemorizedIssueLinkedProspectsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedProspects",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerMemorizedIssueLinkedProspects(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerMemorizedIssueLinkedProspectsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerMemorizedIssueLinkedTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedTenantModel> {
      return this.apiService.getSingle(
         this.getServiceManagerMemorizedIssueLinkedTenantsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerMemorizedIssueLinkedTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedTenantModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerMemorizedIssueLinkedTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerMemorizedIssueLinkedTenantsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedTenants(
      id: number,
      item?: ServiceManagerMemorizedIssueLinkedTenantModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedTenantModel> {
      return this.apiService.postSingle(
         this.postServiceManagerMemorizedIssueLinkedTenantsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerMemorizedIssueLinkedTenantsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedTenantsCollection(
      id: number,
      tenantsToLink?: Array<ServiceManagerMemorizedIssueLinkedTenantModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedTenantModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerMemorizedIssueLinkedTenantsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         tenantsToLink
      );
   }

   deleteServiceManagerMemorizedIssueLinkedTenantsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedTenants",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerMemorizedIssueLinkedTenants(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerMemorizedIssueLinkedTenantsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerMemorizedIssueLinkedUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedUnits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedUnitModel> {
      return this.apiService.getSingle(
         this.getServiceManagerMemorizedIssueLinkedUnitsUrl(
            id,
            itemID,
            embeds,
            fields,
            getOptions,
            additionalParameters
         )
      );
   }

   getServiceManagerMemorizedIssueLinkedUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedUnits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerMemorizedIssueLinkedUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedUnitModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerMemorizedIssueLinkedUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerMemorizedIssueLinkedUnitsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedUnits(
      id: number,
      item?: ServiceManagerMemorizedIssueLinkedUnitModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerMemorizedIssueLinkedUnitModel> {
      return this.apiService.postSingle(
         this.postServiceManagerMemorizedIssueLinkedUnitsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerMemorizedIssueLinkedUnitsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerMemorizedIssueLinkedUnitsCollection(
      id: number,
      unitsToLink?: Array<ServiceManagerMemorizedIssueLinkedUnitModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerMemorizedIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerMemorizedIssueLinkedUnitModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerMemorizedIssueLinkedUnitsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         unitsToLink
      );
   }

   deleteServiceManagerMemorizedIssueLinkedUnitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/ServiceManagerMemorizedIssueLinkedUnits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerMemorizedIssueLinkedUnits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerMemorizedIssueLinkedUnitsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getStatusUrl(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Status",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getStatus(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerStatusModel> {
      return this.apiService.getSingle(this.getStatusUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Tenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(
         this.getTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Tenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Units",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(this.getUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/Units",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUsersThatCanBeAssignedToMemorizedServiceIssueCollectionUrl(
      memorizedIssueID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("memorizedIssueID=" + memorizedIssueID);
      return this.apiService.getUrl(
         ServiceManagerMemorizedIssuesService.endpoint + "/UsersThatCanBeAssignedToMemorizedServiceIssue",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsersThatCanBeAssignedToMemorizedServiceIssueCollection(
      memorizedIssueID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersThatCanBeAssignedToMemorizedServiceIssueCollectionUrl(memorizedIssueID, additionalParameters)
      );
   }
}
