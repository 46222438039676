<div
   *ngIf="$any(isLoaded | async)"
   class="owner-contribution-section"
>
   <owa-owner-contributions
      [showBalance]='showBalance'
      [showSecurityDeposit]='showSecurityDeposit'
      [showPercentageOwnerShip]='showPercentageOwnerShip'
      [showReserve]='showReserve'
   >

   </owa-owner-contributions>
</div>