import { Injectable } from "@angular/core";
import { NgControl } from "@angular/forms";

import { RadioButtonComponent } from "./radio-button.component";

@Injectable({
   providedIn: "root",
})
export class RadioButtonRegistry {
   private accessors: [NgControl, RadioButtonComponent][] = new Array<[NgControl, RadioButtonComponent]>();

   add(control: NgControl, accessor: RadioButtonComponent) {
      this.accessors.push([control, accessor]);
   }

   remove(accessor: RadioButtonComponent) {
      for (let i = this.accessors.length - 1; i >= 0; --i) {
         if (this.accessors[i][1] === accessor) {
            this.accessors.splice(i, 1);
            return;
         }
      }
   }

   select(accessor: RadioButtonComponent) {
      this.accessors.forEach((c) => {
         if (this._isSameGroup(c, accessor) && c[1] !== accessor) {
            c[1].fireUncheck(accessor.thisValue);
         }
      });
   }

   private _isSameGroup(controlPair: [NgControl, RadioButtonComponent], accessor: RadioButtonComponent): boolean {
      if (!controlPair[0].control) {
         return false;
      }
      return (
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         controlPair[0].control.parent === accessor.ngControl.control.parent && controlPair[1].name === accessor.name
      );
   }
}
