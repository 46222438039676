import { AbstractControl, ValidatorFn } from "@angular/forms";

export class RoutingNumberValidator {
   public static getValidator(): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }

         const routingNumber: string = control.value.toString();
         const validationErrors = { routingNumber: true };

         if (!routingNumber) {
            return validationErrors;
         }

         if (isNaN(parseInt(routingNumber)) || routingNumber.length !== 9) {
            return validationErrors;
         }

         let n = 0;
         for (let i = 0; i < routingNumber.length; i += 3) {
            n +=
               parseInt(routingNumber.charAt(i), 10) * 3 +
               parseInt(routingNumber.charAt(i + 1), 10) * 7 +
               parseInt(routingNumber.charAt(i + 2), 10);
         }

         if (n !== 0 && n % 10 === 0) {
            return null;
         } else {
            return validationErrors;
         }
      };
   }
}
