<div class="error-messages-container">
   <ul class="error-message-list">
      <ng-container *ngFor="let control of controls">
         <lcs-validation-error-item
            *ngIf="control.FormControl.errors"
            [errors]="control.FormControl.errors"
            [displayName]="control.DisplayName"
            (click)="onErrorClick(control)"
         ></lcs-validation-error-item>
      </ng-container>
   </ul>
</div>