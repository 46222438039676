<div class="collapse-footer-buttons">
   <lcs-progress-button-ext
      [ngClass]="{'collapse-footer-button':footerButtons.length > maxNumberOfButtons }"
      [isRaisedButton]="footerButtons[footerButtons.length - 2].isRaised"
      [disabled]="footerButtons[footerButtons.length - 2].disabled"
      (buttonClick)="onButtonClick(footerButtons[footerButtons.length - 2])"
      [(entityEventResult)]="footerButtons[footerButtons.length - 2].entityEventResult"
      [text]="footerButtons[footerButtons.length - 2].label"
   >
   </lcs-progress-button-ext>
   <button
      [ngClass]="{'collapse-footer-button':footerButtons.length > maxNumberOfButtons }"
      mat-button
      type="button"
      [matMenuTriggerFor]="menu"
   > <i class="material-icons collapse-button">keyboard_arrow_up</i></button>
   <mat-menu #menu="matMenu">
      <div *ngFor="let expressFooterButton of footerButtons">
         <label>
            <lcs-link
               [text]="expressFooterButton.label"
               (click)="onButtonClick(expressFooterButton)"
               [disabled]="expressFooterButton.disabled"
               class="footer-button-menu-items"
            >
            </lcs-link>
         </label>
      </div>
   </mat-menu>
</div>