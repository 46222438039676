<div class="calculator-input">
   <input
      #textBox
      type="text"
      [class.icon-visible]="!hideIcon"
      [attr.id]="path"
      [ngModel]="value"
      (ngModelChange)="validateInput($event)"
      [ngModelOptions]="{ standalone: true }"
      (focus)="onFocus()"
      [disabled]="disabled"
      (blur)="onBlur()"
      (keydown.enter)="onEnter($event)"
   />
   <span
      *ngIf="!hideIcon && (!ngControl.invalid || inputFocused)"
      class="rmicon-calculator"
      [attr.disabled]="disabled"
      [class.disabled]="disabled"
   ></span>
   <i class="material-icons validation-error-icon">error_outline</i>
</div>
<lcs-validation-overlay-panel
   [parentElement]="textBox"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>

<lcs-overlay-panel
   #overlay
   [(show)]="overlayPanelVisible"
   [parentElement]="textBox"
   [innerElementSelector]="'input'"
   [toggleOnClick]="false"
   [showOnFocus]="false"
   [showOnClick]="false"
   [hideOnBlur]="false"
>
   <div class="calculator-input-history-total">
      <div class="calculator-input-history-content">
         <div class="flex-half first-history-container">
            <span class="calculator-history-operand">{{ firstHistoryOperand }}</span>
         </div>
         <div
            class="calculator-overlay-item"
            *ngFor="let history of calculatorHistory"
         >
            <span
               class="calculator-history-operator"
               *ngIf="history.operator !== null"
            >{{ history.operator }}</span>
            <span
               class="calculator-history-operand"
               *ngIf="history.operand !== null"
            >{{ history.operand }}</span>
         </div>
      </div>
      <div
         class="total-container"
         *ngIf="runningTotal"
      >
         <span class="total-label total-item">Total:</span>
         <span class="total-amount total-item">{{ runningTotal }}</span>
      </div>
   </div>
</lcs-overlay-panel>