<lcs-overlay-panel
   #overlayPanel
   [(show)]="show"
   [parentElement]="parentElement"
   [innerElementTags]="innerElementTags"
   [innerElementSelector]="innerElementSelector"
   [alignToRight]="alignToRight"
   [staticOverlay]="staticOverlay"
   [disabled]="disabled"
   [toggleOnClick]="toggleOnClick"
   [showOnFocus]="false"
   [hideOnBlur]="false"
   [hideOnOutsideClick]="true"
   [overlayPanelStaticWidth]="overlayPanelStaticWidth"
   [overlayPanelStaticMinWidth]="overlayPanelStaticMinWidth"
   [allowScrolling]="false"
>
   <lcs-loading-overlay [show]="isLoading">
      <div
         class="multi-selector-overlay-wrapper"
         *ngIf="show"
      >
         <lcs-validation-error-display-panel
            *ngIf="control?.invalid && (control?.touched || control?.dirty) && !isLoading"
            [displayName]="displayName"
            [errors]="$any(control?.errors)"
         ></lcs-validation-error-display-panel>
         <div class="multi-selector-search-header flex-wrapper">
            <lcs-checkbox
               *ngIf="!hideSelectAll"
               [name]="'select-all-checkbox'"
               [displayName]="'Select All'"
               [ngModel]="isAllSelected"
               (ngModelChange)="allSelected($event)"
               [disabled]="disabled || disableAllItems"
               (mousedown)="clickInOptions()"
            ></lcs-checkbox>
            <lcs-text-box
               #searchBox
               class="multi-selector-overlay-panel-search"
               [disabled]="isLoading"
               [(ngModel)]="searchValue"
               [ngModelOptions]="{ standalone: true }"
               [placeholder]="'Search'"
               [name]="'search-textbox'"
               [displayName]="'Search'"
               (blurEvent)="onBlur()"
               (focusEvent)="onFocus()"
               (window:resize)="onResize()"
            ></lcs-text-box>
         </div>
         <cdk-virtual-scroll-viewport
            class="multiselector-wrapper"
            itemSize="22"
            minBufferPx="88"
            maxBufferPx="220"
            (mousedown)="clickInOptions()"
         >

            <lcs-checkbox
               *cdkVirtualFor="let item of filteredItems; trackBy: trackByFn"
               [ngModel]="item.isChecked"
               [disabled]="disabled || item.isDisabled"
               (ngModelChange)="item.isChecked = $event; onItemSelected(item)"
               [displayName]="item.displayValue || displayName"
            >
               <div
                  class="selector-overlay-item"
                  title="{{ item.displayValue }}"
               >
                  <div lcsOverlayPanelItem>
                     <span
                        lcsOverlayPanelItemLabel
                        [class.disabled]="item.isDisabled"
                        [attr.class]="item.alternateStyleClass"
                     >
                        {{ item.displayValue }}
                     </span>
                     <span
                        lcsOverlayPanelItemAdditionalInfoLabel
                        [class.disabled]="item.isDisabled"
                        *ngIf="item?.additionalInfoValue"
                     >
                        {{ item.additionalInfoValue }}
                     </span>
                  </div>
               </div>
            </lcs-checkbox>
         </cdk-virtual-scroll-viewport>
         <ng-container
            [cdkPortalOutlet]="afterItemsPortal"
            (attached)="onPortalAttached($event)"
         ></ng-container>
         <div class="multi-selector-search-footer flex-space-between">
            <div class="flex-space-between flex-center">
               <label class="selected-item-count">{{ selectedItemCount }}</label>
               <label> selected</label>
            </div>
            <a (click)="clear()">Clear</a>
         </div>
         <div
            class="multi-selector-search-footer flex-space-between"
            *ngIf="isShowLabel"
         >
            <label>
               {{label}}
            </label>
         </div>
      </div>
   </lcs-loading-overlay>
</lcs-overlay-panel>