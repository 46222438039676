import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";

import { MenuService } from "../../top-bar/menu/menu.service";
import { MenuItemModel } from "../../top-bar/menu/models/menu-item.model";

@Component({
   selector: "owa-reports-list",
   templateUrl: "./reports-list.component.html",
   styleUrls: ["./reports-list.component.css"],
})
export class ReportsListComponent implements OnInit, OnDestroy {
   get searchText(): string {
      return this._searchText;
   }

   set searchText(val: string) {
      if (this._searchText !== val) {
         this._searchText = val;
         this.searchTextChange.next(this._searchText);
      }
   }
   reportsList: Array<MenuItemModel> = new Array<MenuItemModel>();

   private reportsMenu: Array<MenuItemModel> = new Array<MenuItemModel>();
   private _searchText: string;
   private searchTextChange: EventEmitter<string> = new EventEmitter<string>();
   private unsubscribe = new Subject<void>();

   constructor(private menuService: MenuService) {}

   ngOnInit() {
      this.menuService.currentMenu.forEach((mi) => {
         if (mi.Name === "Reports") {
            this.reportsMenu = mi.Children;
            this.reportsList = this.reportsMenu;
         }
      });

      this.searchTextChange
         .pipe(
            debounceTime(500),
            distinctUntilChanged((emittedValue) => {
               return emittedValue === this._searchText;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((searchText) => {
            this.reportsList = this.reportsMenu.filter(
               (i) => i.Name.toUpperCase().indexOf(searchText.toUpperCase()) > -1
            );
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
