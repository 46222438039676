<div
   #overlayPanel
   [hidden]="isHidden"
>
   <div
      cdkScrollable
      *ngIf="created"
      #overlayPanelContentsWrapper
      class="overlay-panel-contents-wrapper"
      [style.left.px]="overlayPanelLeft"
      [style.right.px]="overlayPanelRight"
      [style.opacity]="overlayPanelOpacity"
      [style.z-index]="overlayPanelZIndex"
      [style.display]="overlayPanelDisplay"
      [style.pointerEvents]="overlayPanelPointerEvents"
      [ngStyle]="{'overflow-y': hasTooltipArrow ? 'visible' : 'auto'}"
      [ngClass]="overlayClasses"
      (mouseenter)="isMouseInContent = true"
      (mouseleave)="isMouseInContent = false"
   >
      <label
         class="overlay-panel-header"
         *ngIf="overlayHeader"
      >{{overlayHeader}}</label>
      <div
         #overlayPanelContents
         class="overlay-panel-contents"
         [class.overlay-panel-contents-align-to-center]="alignToCenter"
         [style.min-width.px]="overlayPanelMinWidth"
         [style.max-width.px]="overlayPanelMaxWidth"
      >
         <ng-content></ng-content>
      </div>

      <lcs-overlay-tooltip-arrow
         *ngIf="hasTooltipArrow"
         [tooltipLocation]="tooltipLocation"
      >
      </lcs-overlay-tooltip-arrow>

      <div
         *ngIf="hasExpandedSelectorArea"
         class="invisible-selector-panel"
      >

      </div>

   </div>
</div>