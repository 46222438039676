import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

export class ReportParameterControlStatusModel {
   reportParameter: ReportParameter;
   disabled: boolean;
   rawValues: Array<any>;
   constructor(reportParameter: ReportParameter) {
      this.reportParameter = reportParameter;
   }
}
