import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import isDate from "lodash/isDate";

export class TimeValidator {
   public static validateTime(validation: ValidationModel): ValidatorFn {
      // @ts-ignore ts-migrate(2322) FIXME: Type '(control: AbstractControl) => ValidationErro... Remove this comment to see the full error message
      return (control: AbstractControl) => {
         let date: Date;
         let isValueInRange = false;
         const validationErrors: ValidationErrors = {};
         let failedValidation = false;

         if (!control.value) {
            return null;
         }

         if (control.value && !isDate(control.value)) {
            let hours = control.value.substr(0, 2);
            const minutes = control.value.substr(3, 2);
            const period = control.value.substr(6, 2);
            if (period === "PM" && +hours < 12) {
               hours = (+hours + 12).toString();
            } else if (period === "AM" && +hours === 12) {
               hours = (+hours - 12).toString();
            }
            date = new Date(0, 0, 0, +hours, +minutes, 0, 0);
         } else {
            date = new Date(control.value);
         }

         if (!isDate(date) || isNaN(date.getTime())) {
            validationErrors["time"] = true;
            return validationErrors;
         }

         const time = date.getTime();

         //  Min
         if (validation.minDate) {
            if (!this.isValidDate(validation.minDate, "Min Value")) {
               return;
            }
            const minTime = this.getSanitizedTime(validation.minDate);
            if (time < minTime) {
               validationErrors["minDate"] = validation.minDate;
               failedValidation = true;
            }
         }

         //  Max
         if (validation.maxDate) {
            if (!this.isValidDate(validation.maxDate, "Max Value")) {
               return;
            }
            const maxTime = this.getSanitizedTime(validation.maxDate);
            if (time > maxTime) {
               validationErrors["maxDate"] = validation.maxDate;
            }
         }

         const hasInclusiveRange = validation.inclusiveRange && validation.inclusiveRange.length === 2;
         const hasExclusiveRange = validation.exclusiveRange && validation.exclusiveRange.length === 2;

         // Inclusive range
         if (hasInclusiveRange) {
            if (this.isValidTimeRange(time, validation.inclusiveRange, false)) {
               isValueInRange = true;
            }
         }

         // Exclusive range
         if (hasExclusiveRange) {
            isValueInRange = false;
            validation.exclusiveRange.forEach((exclusiveRange) => {
               if (this.isValidTimeRange(time, exclusiveRange, true)) {
                  isValueInRange = true;
               }
            });
         }

         let range;
         if (!isValueInRange && hasInclusiveRange) {
            range = `[${validation.inclusiveRange[0]}, ${validation.inclusiveRange[1]}]`;
            failedValidation = true;
         }
         if (!isValueInRange && hasExclusiveRange) {
            range = `(${validation.exclusiveRange[0]}, ${validation.exclusiveRange[1]})`;
            failedValidation = true;
         }

         if (!isValueInRange) {
            validationErrors["requiredRanges"] = `${range}`;
         }

         // Whitelist
         if (validation.whitelist && validation.whitelist.length > 0) {
            if (!this.timeInList(time, validation.whitelist)) {
               validationErrors["whiteList"] = validation.whitelist;
               failedValidation = true;
            }
         }

         if (!failedValidation) {
            return null;
         } else {
            return validationErrors;
         }
      };
   }

   private static isValidTimeRange(time: number, range: Array<Date>, isExclusive: boolean): boolean {
      if (!isDate(range[0] || !isDate(range[1]))) {
         console.warn("range value is not a date!");
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'boolea... Remove this comment to see the full error message
         return;
      }

      const startTime = new Date(0, 0, 0, range[0].getHours(), range[0].getMinutes(), 0, 0).getTime();
      const stopTime = new Date(0, 0, 0, range[1].getHours(), range[1].getMinutes(), 0, 0).getTime();

      if (isExclusive) {
         return this.isValidRangeExclusive(time, startTime, stopTime);
      } else {
         return this.isValidRangeInclusive(time, startTime, stopTime);
      }
   }

   private static timeInList(time: number, dateTimeList: Array<Date>): boolean {
      const valueInList =
         dateTimeList.filter(function (validationValue) {
            if (!isDate(validationValue)) {
               console.warn("list item value is not a date!");
               return;
            }
            const validationTime = new Date(
               0,
               0,
               0,
               validationValue.getHours(),
               validationValue.getMinutes(),
               0,
               0
            ).getTime();
            return validationTime === time;
         }).length > 0;
      return valueInList;
   }

   private static isValidRangeInclusive(time: number, startTime: number, stopTime: number) {
      if (startTime > stopTime) {
         return time >= startTime || time <= stopTime;
      } else {
         return time >= startTime && time <= stopTime;
      }
   }

   private static isValidRangeExclusive(time: number, startTime: number, stopTime: number) {
      if (startTime > stopTime) {
         return time > startTime || time < stopTime;
      } else {
         return time > startTime && time < stopTime;
      }
   }

   private static isValidDate(value: any, item?: string): boolean {
      if (!isDate(value)) {
         console.warn(`validation ${item} value is not a date!`);
         return false;
      }
      return true;
   }

   private static getSanitizedTime(date: Date): number {
      return new Date(0, 0, 0, date.getHours(), date.getMinutes(), 0, 0).getTime();
   }
}
