export function subStringAllowingInvalidStartIndexAndLength(value: string, startIndex: number, length: number): string {
   if (value == null) {
      return "";
   }
   if (startIndex < 0) {
      startIndex = 0;
   }
   if (startIndex >= value.length) {
      return "";
   }
   if (length <= 0) {
      return "";
   }

   if (length >= value.length - startIndex) {
      return value.substr(startIndex);
   } else {
      return value.substr(startIndex, length);
   }
}

export function subStringAllowingInvalidStartIndex(value: string, startIndex: number): string {
   if (value == null) {
      return "";
   }
   if (startIndex < 0) {
      startIndex = 0;
   }
   if (startIndex >= value.length) {
      return "";
   }
   return value.substr(startIndex);
}

export function replaceCharAt(value: string, index: number, replacementValue: string) {
   if (value == null) {
      return "";
   }
   if (replacementValue == null) {
      replacementValue = "";
   }
   if (index < 0) {
      index = 0;
   }
   if (index >= value.length) {
      return "";
   }
   return value.substr(0, index) + replacementValue + value.substr(index + replacementValue.length);
}

/**
 * Returns true if the value is base64 encoded, otherwise returns false.
 * @param value
 */
export function isBase64(value: unknown): boolean {
   if (typeof value !== "string") {
      return false;
   }
   const trimmedStr = value.trim();
   if (trimmedStr.length % 4 !== 0 || /[^A-Z0-9+\/=]/i.test(trimmedStr)) {
      // base64 strings are always a multiple of 4 in length and can only contain characters A-Z, a-z, 0-9, +, /, and =
      return false;
   }
   try {
      return window.btoa(window.atob(value)) === trimmedStr;
   } catch (err) {
      return false;
   }
}

/**
 * Returns the value if it is not base64 encoded, otherwise returns the base64 decoded value.
 * @param value
 */
export function base64DecodedIfNecessary(value: unknown): unknown {
   if (typeof value !== "string") {
      return value;
   }
   const trimmedStr = value.trim();
   if (trimmedStr.length % 4 !== 0 || /[^A-Z0-9+\/=]/i.test(trimmedStr)) {
      // base64 strings are always a multiple of 4 in length and can only contain characters A-Z, a-z, 0-9, +, /, and =
      return value;
   }
   try {
      const decodedValue = JSON.parse(window.atob(value));
      return decodedValue;
   } catch (err) {
      return value;
   }
}
