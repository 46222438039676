<owa-dashboard-tile
   [title]="'Messages'"
   [contentClass]="'content-padding'"
>
   <div
      class="message-item"
      *ngIf="generalMessage !== ''"
   >
      <div innerHTML="{{generalMessage}}"></div>
   </div>
   <div
      class="message-item"
      *ngIf="ownerMessage !== ''"
   >
      <div innerHTML="{{ownerMessage}}"></div>
   </div>
</owa-dashboard-tile>