import { Directive, Input, OnDestroy, OnInit, Self } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";

import { EndpointSelectorDirectiveBase } from "./endpoint-selector.directive.base";

@Directive({
   selector: "[lcsEndpointSelector]",
})
export class EndpointSelectorDirective extends EndpointSelectorDirectiveBase implements OnDestroy, OnInit {
   /** Url to retrieve data from. */
   @Input() set endpoint(val: string) {
      this._endpoint = val;
   }

   /** Whether or not the dataset should be retrieved from a subresource. */
   @Input() set isSubItem(val: boolean) {
      this._isSubItem = val;
   }

   /** Whether or not the request can use the search syntax. */
   @Input() set endpointIsSearch(val: boolean) {
      this._endpointIsSearch = val;
   }

   /** Whether or not the request can use the quicksearch syntax. */
   @Input() set endpointIsQuickSearch(val: boolean) {
      this._endpointIsQuickSearch = val;
   }

   /** Whether or not to load all items in the set before the selector is focused. */
   @Input() set eagerLoadItems(val: boolean) {
      this._eagerLoadItems = val;
      this.setEagerLoadItems();
   }

   /** Whether or not to load all items in the set when the selector is focused. */
   @Input() set loadItemsOnFocus(val: boolean) {
      this._loadItemsOnFocus = val;
   }

   /** Whether or not to load all items in the set. */
   @Input() set loadAllItems(val: boolean) {
      this._loadAllItems = val;
   }

   /** Whether or not to add a blank item to the set. Allows selectors to be "unset" in a consistent manner. */
   @Input() set addBlankItem(val: boolean) {
      this._addBlankItem = val;
   }

   /** Defines values to exclude from the result set. Expects a list of the item values, not the items themselves. */
   @Input() set valuesToExclude(val: Array<string | number | boolean>) {
      this._valuesToExclude = val;
   }

   /** The path the ObjectMapResolver will use to locate the item value when processing the dataset. */
   @Input() set valueSourcePath(val: string) {
      this._valueSourcePath = val;
   }

   /** The path the ObjectMapResolver will use to locate the item displayValue when processing the dataset. */
   @Input() set displayValueSourcePath(val: string) {
      this._displayValueSourcePath = val;
   }

   /** The path the ObjectMapResolver will use to locate the item additionalInfo when processing the dataset. */
   @Input() set additionalInfoSourcePath(val: string) {
      this._additionalInfoSourcePath = val;
   }

   /** A set of additional fields to add to the request in addition to the fields used to construct the SelectorItemModels. */
   @Input() set additionalDataFields(val: Array<string>) {
      this._additionalDataFields = val;
   }

   /** Defines additional items for the result set. They are prepended to data returned from the endpoint. */
   @Input() set staticValues(val: Array<SelectorItemModel>) {
      this._staticValues = val;
   }

   /** Defines filters to apply to the endpoint request. */
   @Input() set filters(val: Array<FilterOption>) {
      this._filters = val;
   }

   /** Defines fields to use when building search expressions. */
   @Input() set searchFields(val: Array<FilterField>) {
      this._searchFields = val;
   }

   /** A set of additional embeds to add to the request in addition to the embeds used to construct the SelectorItemModels. */
   @Input() set embeds(val: Array<string>) {
      this._embeds = val;
   }

   /** The set of orderingOptions to use on the endpoint request. */
   @Input() set orderingOptions(val: Array<string>) {
      this._orderingOptions = val;
   }

   /** A set of fields to use on the endpoint request. Needs to be removed/replaced with the additionalDataFields input. */
   @Input() set fields(val: Array<string | EntityField>) {
      this._fields = val;
   }

   /** The number of items to request from the endpoint. Defaults to 10. Overridden by eagerLoadItems. */
   @Input() set maximumItemsToRetrieve(val: number) {
      this._maximumItemsToRetrieve = val;
   }

   /** A custom value compararer to use for complex datasets.*/
   @Input() set valueComparer(val: (selectedItemValue: any, value: any) => boolean) {
      this._valueComparer = val;
   }

   /** A flag that determines if the force refresh function can run.  Defaults to true.*/
   @Input() set allowForceRefresh(val: boolean) {
      this._allowForceRefresh = val;
   }

   constructor(
      protected errorMessageService: ErrorMessageService,
      protected objectMapResolverService: ObjectMapResolverService,
      protected valueSourceService: ValueSourceService,
      protected apiService: ApiService,
      @Self() protected selectComponent: SelectComponent
   ) {
      super(errorMessageService, objectMapResolverService, valueSourceService, apiService, selectComponent);
   }

   ngOnInit(): void {
      super.ngOnInit();
   }

   ngOnDestroy() {
      super.ngOnDestroy();
   }
}
