////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum WeeklyCount {
   first = 1,
   second = 2,
   third = 3,
   fourth = 4,
   last = -1,
}
