<lcs-property-owner-selector
   #propertyOwnerSelector
   [(selectedOwnerIDs)]="selectedOwnerIDs"
   [selectedPropertyIDs]="selectedPropertyIDs"
   [(ownerGroupID)]="ownerGroupID"
   [propertyGroupID]="propertyGroupID"
   [showInactiveProperties]="showInactiveProperties"
   [tabIndex]="tabIndex"
   [hideInactiveCheckbox]="hideInactiveCheckbox"
   [hideOwnerGroupSelector]="hideOwnerGroupSelector"
   [allCollectionItem]="allCollectionItem"
   (propertySelectionChange)="onPropertySelectionChange($event)"
   (ownerSelectionChange)="onOwnerSelectionChange($event)"
   (showInactivePropertiesChange)="onShowInactivePropertiesChange($event)"
   (propertyGroupChange)="onPropertyGroupChange($event)"
   (ownerGroupChange)="onOwnerGroupChange($event)"
   (tabChange)="onTabChange($event)"
   (allSelected)="onAllSelected($event)"
></lcs-property-owner-selector>