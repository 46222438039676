import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextAreaModule } from "@lcs/inputs/text-area/text-area.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { RadioButtonModule } from "@lcs/radio-button/radio-button.module";

import { OWATableModule } from "../owa-table/owa-table.module";
import { LinkedAccountManagementComponent } from "./linked-account-management.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      LcsProgressButtonModule,
      TextBoxModule,
      TextAreaModule,
      ValidationModule,
      OWATableModule,
      RadioButtonModule,
   ],
   declarations: [LinkedAccountManagementComponent],
   exports: [LinkedAccountManagementComponent],
})
export class LinkedAccountManagementModule {}
