<owa-dashboard-tile
   [title]="'Ownership'"
   [isViewMore]="$any(isViewMore | async)"
   [viewMoreComponent]="'ownerships'"
>
   <div *ngIf="(isLoading | async) != true">

      <div
         *ngIf="ownershipDashboardModel.Ownerships.length === 1 || ownershipDashboardModel.Ownerships.length === 0"
         class="scoreboard-wrapper single-ownership"
      >

         <div class=scoreboard-wrapper>
            <div
               *ngIf="ownershipDashboardModel.ShowOwnerships"
               class="scoreboard-item flex-full"
            >
               <span>Ownership</span>
               <div
                  class="ownership"
                  id="Ownership"
               >
                  <span
                     *ngIf="!ownershipDashboardModel.Ownerships[0].IsActiveContract"
                     matTooltip="Ended on {{ownershipDashboardModel.Ownerships[0].ContractToDate | date: 'MM/dd/yyyy'}}"
                     matTooltipPosition="above"
                     matTooltipClass="end-tooltip"
                  >
                     <i class=" contract-end material-icons">error_outline</i>
                  </span>
                  <span
                     class="item-color"
                     *ngIf="ownershipDashboardModel.ShowOwnershipPercentage"
                  >
                     {{ownershipDashboardModel.Ownerships[0].OwnershipPercentage}}% -
                  </span>
                  <span class="item-color">{{ownershipDashboardModel.Ownerships[0].PropertyName}}
                  </span>
                  <span
                     class="link"
                     *ngIf="allowOwnerToEmailPropertyManager && ownershipDashboardModel.Ownerships[0].Email !== ''"
                     (click)="email(ownershipDashboardModel.Ownerships[0].Email,ownershipDashboardModel.Ownerships[0].PropertyID)"
               
                     matTooltip="Email property manager"
                     matTooltipPosition="above"
                     matTooltipClass="email-tooltip"
                  >
                     <i class="material-icons">mail_outline</i>
                  </span>
               </div>
            </div>

            <div
               *ngIf="ownershipDashboardModel.Ownerships.length === 1 && ownershipDashboardModel.ShowReserve"
               class="scoreboard-item flex-half"
               id="ReserveAmount"
            >
               <span>Reserve Amount</span>
               <span class="item-color">{{ownershipDashboardModel.Ownerships[0].ReserveAmount | currency:'USD':'symbol':'1.2-2'}}
               </span>
            </div>

            <div
               *ngIf="ownershipDashboardModel.Ownerships.length === 1 && ownershipDashboardModel.ShowSecurityDeposits"
               class="scoreboard-item flex-half"
               id="SecurityDepositHeld"
            >
               <span>Security Deposit Held</span>
               <span class="item-color">{{ownershipDashboardModel.Ownerships[0].SecurityDepositHeld | currency:'USD':'symbol':'1.2-2'}}
               </span>
            </div>

            <div
               id="LastManagementFee"
               class="scoreboard-item flex-half"
            >
               <span>Last Management Fee</span>
               <span class="item-color">{{(ownershipDashboardModel?.LastManagementFee | date: 'MM/dd/yyyy') !== (defaultDate | date: 'MM/dd/yyyy')
                  ? (ownershipDashboardModel?.LastManagementFee | date: 'MM/dd/yyyy') : ''}}</span>
            </div>

            <div
               id="LastDistribution"
               class="scoreboard-item flex-half"
            >
               <span>Last Distribution</span>
               <span class="item-color">{{(ownershipDashboardModel?.LastDistribution | date: 'MM/dd/yyyy') !== (defaultDate | date: 'MM/dd/yyyy')
                  ? (ownershipDashboardModel?.LastDistribution | date: 'MM/dd/yyyy') : ''}}</span>
            </div>

            <div
               *ngIf="ownershipDashboardModel.ShowBalance"
               class="scoreboard-item flex-half"
               id="Balance"
            >
               <span>Balance</span>
               <span class="item-bold">{{ownershipDashboardModel.Balance | currency:'USD':'symbol':'1.2-2'}}</span>
            </div>
         </div>
      </div>

      <div *ngIf="ownershipDashboardModel.Ownerships.length > 1">

         <div class="scoreboard-wrapper">
            <div
               id="LastManagementFee"
               class="scoreboard-item flex-third"
            >
               <span>Last Management Fee</span>
               <span class="item-color">{{(ownershipDashboardModel?.LastManagementFee | date: 'MM/dd/yyyy') !== (defaultDate | date: 'MM/dd/yyyy')
                  ? (ownershipDashboardModel?.LastManagementFee | date: 'MM/dd/yyyy') : ''}}</span>
            </div>

            <div
               id="LastDistribution"
               class="scoreboard-item flex-third"
            >
               <span>Last Distribution</span>
               <span class="item-color">{{(ownershipDashboardModel?.LastDistribution | date: 'MM/dd/yyyy') !== (defaultDate | date: 'MM/dd/yyyy')
                  ? (ownershipDashboardModel?.LastDistribution | date: 'MM/dd/yyyy') : ''}}</span>
            </div>

            <div
               *ngIf="ownershipDashboardModel.ShowBalance"
               class="scoreboard-item flex-third"
               id="Balance"
            >
               <span>Balance</span>
               <span class="item-bold">{{ownershipDashboardModel.Balance | currency:'USD':'symbol':'1.2-2'}}</span>
            </div>
         </div>


         <lcs-table
            [dataSource]="ownershipDashboardModel.Ownerships"
            id="OwnershipsTable"
         >
            <tr
               lcs-header-row
               *lcsHeaderRowDef="columns; sticky: false"
            ></tr>
            <tr
               lcs-row
               *lcsRowDef="let row; columns: columns"
            ></tr>

            <ng-container
               lcsColumnDef="Ended"
               [width]="1"
               [priority]="1"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               ></th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
               >
                  <div
                     *ngIf="!row.IsActiveContract"
                     matTooltip="Ended on {{row.ContractToDate | date: 'MM/dd/yyyy'}}"
                     matTooltipPosition="above"
                     matTooltipClass="end-tooltip"
                  >
                     <i class=" contract-end material-icons">error_outline</i>
                  </div>
               </td>
            </ng-container>

            <ng-container
               lcsColumnDef="OwnershipPercentage"
               [width]="6"
               [priority]="2"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Ownership</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
                  class="numeric"
               >{{row.OwnershipPercentage}}%</td>
            </ng-container>

            <ng-container
               lcsColumnDef="PropertyName"
               [width]="15"
               [priority]="3"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Property</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
               >{{row.PropertyName}}</td>
            </ng-container>

            <ng-container
               lcsColumnDef="ReserveAmount"
               [width]="6"
               [priority]="5"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Reserve</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
                  class="numeric"
               >{{row.ReserveAmount | currency:'USD':'symbol':'1.2-2'}}</td>
            </ng-container>

            <ng-container
               lcsColumnDef="SecurityDepositHeld"
               [width]="7"
               [priority]="6"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Security Dep.</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
                  class="numeric"
               >{{row.SecurityDepositHeld | currency:'USD':'symbol':'1.2-2'}}</td>
            </ng-container>

            <ng-container
               lcsColumnDef="Email"
               [width]="3"
               [priority]="4"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               ></th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
               >
                  <div
                     class="link email"
                     *ngIf="allowOwnerToEmailPropertyManager && row.Email !== ''"
                     (click)="email(row.Email, row.PropertyID)"
                     matTooltip="Email property manager"
                     matTooltipPosition="above"
                     matTooltipClass="email-tooltip"
                  >
                     <i class="material-icons">mail_outline</i>
                  </div>
               </td>
            </ng-container>

         </lcs-table>
      </div>
   </div>

</owa-dashboard-tile>