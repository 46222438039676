import { OwnerProspectStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/owner-prospect-status.enum";

import { AddressModel } from "./generated/address.model";
import { ContactModel } from "./generated/contact.model";
import { HistoryCallModel } from "./generated/history-call.model";
import { HistoryEmailModel } from "./generated/history-email.model";
import { HistoryNoteModel } from "./generated/history-note.model";
import { HistorySystemNoteModel } from "./generated/history-system-note.model";
import { HistoryVisitModel } from "./generated/history-visit.model";
import { OwnerProspectOwnershipModel } from "./generated/owner-prospect-ownership.model";
import { PhoneNumberModel } from "./generated/phone-number.model";
import { UserDefinedValueModel } from "./generated/user-defined-value.model";

export class OwnerProspectModel {
   Addresses: Array<AddressModel>;
   ApplicationDate: Date;
   ColorID: number;
   Contact: ContactModel;
   Comment: string;
   ConcurrencyID: number;
   CreateDate: Date;
   CreateUserID: number;
   DisplayName: string;
   Email: string;
   HistoryCalls: Array<HistoryCallModel>;
   HistoryEmails: Array<HistoryEmailModel>;
   HistoryNotes: Array<HistoryNoteModel>;
   HistorySystemNotes: Array<HistorySystemNoteModel>;
   HistoryVisits: Array<HistoryVisitModel>;
   InterestLevel: number;
   LastStatusChangedDate: Date;
   MetaTag?: string;
   Name: string;
   OwnerID: number;
   OwnerProspectID: number;
   OwnerProspectLeadSourceID: number;
   OwnerProspectStatus: OwnerProspectStatus;
   Ownerships: Array<OwnerProspectOwnershipModel>;
   PhoneNumbers: Array<PhoneNumberModel>;
   SalesRepresentativeID: number;
   UpdateDate: Date;
   UpdateUserID: number;
   UserDefinedValues: Array<UserDefinedValueModel>;
   WebMessage: string;
}
