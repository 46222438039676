import { Countries } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/countries.enum";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";

import { EpayPaymentType } from "../enumerations/epay-paymentype.enum";
import { AccountModel } from "./generated/account.model";
import { EpayInformationModel } from "./generated/epay-information.model";
import { EpaySettingChargeTypeModel } from "./generated/epay-setting-charge-type.model";
import { EpayTransactionInformationModel } from "./generated/epay-transaction-information.model";
import { PaymentModel } from "./generated/payment.model";
import { OwnerContributionModel } from "./owner-contribution.model";

export class OwnerContributionCCPaymentViewModel {
   AccountTypeID?: number;
   Firstname: string;
   LastName: string;
   BankCountryID: Countries;
   AccountNumber: string;
   Cvv2: string;
   IsCvv2Verified: boolean;
   ExpirationMonth?: number;
   ExpirationYear?: number;
   BillingAddressStreet1: string;
   BillingAddressStreet2: string;
   BillingAddressCity: string;
   BillingAddressState: string;
   BillingAddressPostalCode: string;
   BillingAddressCountry: string;
   Amount: number;
   Comment: string;
   Items: Array<OwnerContributionModel>;
   ConvenienceFee: number;
   ConvenienceFeeNumber?: number;
   Total: number;
   PostingDate: Date;
   Memo: string;
   EpayTransactionItem: EpayTransactionInformationModel;
   EpayInfo: EpayInformationModel;
   EntityType: EntityType;
   customer: AccountModel;
   HasEPayAccount: boolean;
   StopChecks: boolean;
   SECType: EpaySettingChargeTypeModel;
   BatchID: Int16Array;
   BalanceDetail: PaymentModel;
   PaymentType: EpayPaymentType;
   AccountTypes: Array<EpayAccountType>;
   EpayResponse: string;
   IsDisclaimerRequired: boolean;
   IsDisclaimerChecked: boolean;
   DisclaimerMessage: string;
   IsDebitCard: boolean;
}
