import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { EntitySelectorModule } from "@lcs/entity-selector/entity-selector.module";
import { IconModule } from "@lcs/icon/icon.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { SelectModule } from "@lcs/select/select.module";
import { InputsModule } from "projects/libraries/lcs/src/lib/inputs/inputs.module";

import { FormRegistrationModule } from "../../../forms/form-registration/form-registration.module";
import { TextBoxModule } from "../../../inputs/text-box/text-box.module";
import { ValidationModule } from "../../../inputs/validation/validation.module";
import { OverlayPanelsModule } from "../../../overlay-panel/overlay-panels.module";
import { ReportParameterAskModule } from "../report-parameter-ask/report-parameter-ask.module";
import { ChartAccountsToIncludeOverlayPanelComponent } from "./chart-accounts-to-include-overlay-panel.component";
import { ChartAccountsToIncludeReportParameterComponent } from "./chart-accounts-to-include-report-parameter.component";
import { ChartAccountsToIncludeSelectorComponent } from "./chart-accounts-to-include-selector.component";

@NgModule({
   imports: [
      CommonModule,
      FormRegistrationModule,
      FormsModule,
      SelectModule,
      TextBoxModule,
      ValidationModule,
      CheckboxModule,
      MatIconModule,
      OverlayPanelsModule,
      MatDividerModule,
      IconModule,
      ValidationModule,
      TextBoxModule,
      LoadingOverlayModule,
      ScrollingModule,
      ReportParameterAskModule,
      InputsModule,
      EntitySelectorModule,
   ],
   declarations: [
      ChartAccountsToIncludeOverlayPanelComponent,
      ChartAccountsToIncludeReportParameterComponent,
      ChartAccountsToIncludeSelectorComponent,
   ],
   exports: [ChartAccountsToIncludeReportParameterComponent, ChartAccountsToIncludeSelectorComponent],
})
export class ChartAccountsToIncludeReportParameterModule {}
