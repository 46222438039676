export class ManagementFeesDetailViewModel {
   ManagementFeeID: number;
   PropertyID: number;
   PropertyName: string;
   ChargeTypeName: string;
   AccountName: string;
   TotalAmount: number;
   FeePercentage: number;
   TotalFeeAmount: number;
   TotalPassThruAmount: number;
   TotalPassThruFeeAmount: number;
   FeeDate: string;
   FromDate: string;
   FeeTotal: number;
   ChargesBreakdown: any;
   ChargesDetails: any;
   OtherFees: any;
}
