import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";

import { ServiceIssuePreferences } from "../service-issue-preferences.interface";
import { TenantConfigurationModel } from "../tenant-details/tenant-configuration.model";
import { TenantDetailComponent } from "../tenant-details/tenant-detail.component";
import { VendorConfigurationModel } from "../vendor-details/vendor-configuration.model";
import { VendorDetailComponent } from "../vendor-details/vendor-detail.component";

@Component({
   selector: "owa-service-issue-details",
   templateUrl: "./service-issue-details.component.html",
})
export class ServiceIssueDetailsComponent {
   @Input() loading: boolean;

   @Input() issue: ServiceManagerIssueModel;

   @Input() preferences: ServiceIssuePreferences;

   readonly defaultDate: Date = new Date("0001-01-01T00:00:00");

   constructor(
      private activatedRoute: ActivatedRoute,
      private dialogCreationService: DialogCreationService,
      private router: Router
   ) {}

   showVendorDialog() {
      const vendorConfigurationModel = new VendorConfigurationModel();
      vendorConfigurationModel.vendor = this.issue.PayeeAccount;

      const dialogConfigurationModel = new DialogConfigurationModel();
      dialogConfigurationModel.componentInstance = VendorDetailComponent;
      dialogConfigurationModel.title = "Vendor Info";
      this.dialogCreationService.open(dialogConfigurationModel, vendorConfigurationModel);
   }

   showTenantDialog(id: number) {
      const tenantConfigurationModel = new TenantConfigurationModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'TenantModel | undefined' is not assignable t... Remove this comment to see the full error message
      tenantConfigurationModel.tenant = this.issue.Tenants.find((tenant) => tenant.TenantID === id);

      const dialogConfigurationModel = new DialogConfigurationModel();
      dialogConfigurationModel.componentInstance = TenantDetailComponent;
      dialogConfigurationModel.title = "Tenant Info";
      this.dialogCreationService.open(dialogConfigurationModel, tenantConfigurationModel);
   }

   openChecklistViewMore(): void {
      this.router.navigate(["./checklist"], { relativeTo: this.activatedRoute });
   }

   openWorkOrderViewMore(): void {
      this.router.navigate(["./workorders"], { relativeTo: this.activatedRoute });
   }

   openHistoryViewMore(): void {
      this.router.navigate(["./history"], { relativeTo: this.activatedRoute });
   }
}
