import { AppointmentModel } from "./appointment.model";
import { CheckListItemModel } from "./check-list-item.model";
import { FileModel } from "./file.model";
import { HistoryNoteModel } from "./history-note.model";
import { HistoryModel } from "./history.model";
import { JobModel } from "./job.model";
import { MakeReadyPostItemModel } from "./make-ready-post-item.model";
import { PropertyModel } from "./property.model";
import { ProspectModel } from "./prospect.model";
import { ServiceManagerCategoryModel } from "./service-manager-category.model";
import { ServiceManagerIssueLinkedPropertyModel } from "./service-manager-issue-linked-property.model";
import { ServiceManagerIssueLinkedProspectModel } from "./service-manager-issue-linked-prospect.model";
import { ServiceManagerIssueLinkedTenantModel } from "./service-manager-issue-linked-tenant.model";
import { ServiceManagerIssueLinkedUnitModel } from "./service-manager-issue-linked-unit.model";
import { ServiceManagerIssueLockModel } from "./service-manager-issue-lock.model";
import { ServiceManagerIssueWorkOrderModel } from "./service-manager-issue-work-order.model";
import { ServiceManagerPriorityModel } from "./service-manager-priority.model";
import { ServiceManagerProjectModel } from "./service-manager-project.model";
import { ServiceManagerStatusModel } from "./service-manager-status.model";
import { ServiceManagerTechTimeModel } from "./service-manager-tech-time.model";
import { TaskModel } from "./task.model";
import { TenantModel } from "./tenant.model";
import { UnitModel } from "./unit.model";
import { UserDefinedValueModel } from "./user-defined-value.model";
import { UserListItemModel } from "./user-list-item.model";
import { UserModel } from "./user.model";
import { VendorModel } from "./vendor.model";
import { WebUserModel } from "./web-user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ServiceManagerIssueModel {
   Age: string;
   Appointments: Array<AppointmentModel>;
   AssignedCloseDate?: Date | null ;
   AssignedOpenDate?: Date | null ;
   AssignedToUser: UserListItemModel;
   AssignedToUserID: number;
   Category: ServiceManagerCategoryModel;
   CategoryID: number;
   CheckListItems: Array<CheckListItemModel>;
   CloseDate?: Date | null ;
   CloseUser: UserModel;
   CloseUserID: number;
   CreateDate: Date;
   CreateUser: UserModel;
   CreateUserID: number;
   CreateWebUser: WebUserModel;
   CreateWebUserID: number;
   CurrentProjectSequence: number;
   CustomerDescription: string;
   Description: string;
   DueDate?: Date | null ;
   HasPets: boolean;
   History: Array<HistoryModel>;
   HistoryNotes: Array<HistoryNoteModel>;
   Hours: number;
   IsAllowedToEnter: boolean;
   IsClosed: boolean;
   IsCustomerInitiated: boolean;
   IsRead: boolean;
   IsSignatureOnFile: boolean;
   IsSigned: boolean;
   Job: JobModel;
   JobID: number;
   LinkedProperties: Array<PropertyModel>;
   LinkedProspects: Array<ProspectModel>;
   LinkedTenants: Array<TenantModel>;
   LinkedUnits: Array<UnitModel>;
   MakeReadyPostItem: MakeReadyPostItemModel;
   MakeReadyPostItems: Array<MakeReadyPostItemModel>;
   MetaTag?: string;
   NoteText: string;
   PayeeAccount: VendorModel;
   PayeeAccountID: number;
   Priority: ServiceManagerPriorityModel;
   PriorityID: number;
   Project: ServiceManagerProjectModel;
   ProjectID: number;
   ProjectSequence: number;
   Properties: Array<PropertyModel>;
   Prospects: Array<ProspectModel>;
   RelatedIssues: Array<ServiceManagerIssueModel>;
   Resolution: string;
   ScheduledDate?: Date | null ;
   ServiceManagerIssueID: number;
   ServiceManagerIssueLinkedProperties: Array<ServiceManagerIssueLinkedPropertyModel>;
   ServiceManagerIssueLinkedProspects: Array<ServiceManagerIssueLinkedProspectModel>;
   ServiceManagerIssueLinkedTenants: Array<ServiceManagerIssueLinkedTenantModel>;
   ServiceManagerIssueLinkedUnits: Array<ServiceManagerIssueLinkedUnitModel>;
   ServiceManagerIssueLock: ServiceManagerIssueLockModel;
   SignatureFile: FileModel;
   SignatureFileID: number;
   Status: ServiceManagerStatusModel;
   StatusID: number;
   Tasks: Array<TaskModel>;
   TechTimes: Array<ServiceManagerTechTimeModel>;
   Tenants: Array<TenantModel>;
   Title: string;
   Units: Array<UnitModel>;
   UpdateDate: Date;
   UpdateUser: UserModel;
   UpdateUserID: number;
   UserDefinedValues: Array<UserDefinedValueModel>;
   Vendor: VendorModel;
   VendorID?: number | null ;
   WorkOrders: Array<ServiceManagerIssueWorkOrderModel>;
}
