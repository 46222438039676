import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { PdfViewerModule } from "@lcs/pdf-viewer/pdf-viewer.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";
import { TableModule } from "@lcs/table/table.module";

import { LayoutModule } from "../layout/layout.module";
import { TileModule } from "../tile/tile.module";
import { EstimateDetailListViewMoreComponent } from "./detail-list/detail-list-view-more.component";
import { EstimateDetailListComponent } from "./detail-list/detail-list.component";
import { EstimateDetailContainerComponent } from "./details/estimate-detail-container.component";
import { EstimateDetailsComponent } from "./details/estimate-details.component";
import { EstimateDetailComponent } from "./estimate-detail.component";
import { EstimatePrintMoreComponent } from "./estimate-print/estimate-print-view-more.component";
import { EstimatePrintComponent } from "./estimate-print/estimate-print.component";
import { EstimateHistoryListViewMoreComponent } from "./history-list/estimate-history-list-view-more.component";
import { EstimateHistoryListComponent } from "./history-list/estimate-history-list.component";
import { EstimateDetailRejectDialogComponent } from "./reject/estimate-detail-reject.component";
import { ServiceIssueListComponent } from "./serviceissue-list/estimate-serviceissue-list.component";
import { ConfirmationSafeHtmlPipe } from "./signature/confirmation-html-pipe";
import { EstimateDetailSignatureDialogComponent } from "./signature/estimate-detail-signature.component";
import { EstimateDetailSignatureService } from "./signature/estimate-detail-signature.service";

@NgModule({
   imports: [
      CommonModule,
      LayoutModule,
      TileModule,
      TableModule,
      TextBoxModule,
      LcsSlidePanelModule,
      LoadingOverlayModule,
      LcsProgressButtonModule,
      FormsModule,
      PdfViewerModule,
   ],
   declarations: [
      EstimateDetailComponent,
      EstimateDetailContainerComponent,
      EstimateDetailsComponent,
      EstimateDetailSignatureDialogComponent,
      EstimateDetailListComponent,
      EstimateDetailListViewMoreComponent,
      EstimateHistoryListComponent,
      EstimateHistoryListViewMoreComponent,
      ServiceIssueListComponent,
      EstimateDetailRejectDialogComponent,
      ConfirmationSafeHtmlPipe,
      EstimatePrintComponent,
      EstimatePrintMoreComponent,
   ],
   providers: [EstimateDetailSignatureService],
   exports: [EstimateDetailComponent],
})
export class EstimateDetailModule {}
