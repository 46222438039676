import { Component, OnInit } from "@angular/core";
import { BillViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-view.model";
import { BillsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/bills.service";
import { Observable } from "rxjs";

@Component({
   selector: "owa-dashboard-bills",
   templateUrl: "./dashboard-tile-bills.component.html",
   providers: [BillsService],
})
export class DashboardBillsComponent implements OnInit {
   observableBills: Observable<Array<BillViewModel>>;
   public bills: Array<BillViewModel> = new Array<BillViewModel>();
   public newBills: Array<BillViewModel> = new Array<BillViewModel>();
   notificationCount: number;
   public isNew: boolean;
   billTileColumns: string[] = ["TransactionDate", "Amount", "Payee", "NewRow"];
   constructor(private billService: BillsService) {}

   ngOnInit() {
      this.observableBills = this.billService.getBillsForDashboard();
      this.observableBills.subscribe((res) => {
         this.bills = res;
         if (this.bills != null && this.bills.length > 0) {
            const newBills = this.bills.filter((bill) => bill.IsNew);
            this.notificationCount = newBills != null ? newBills.length : 0;
         }
      });
   }
}
