import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceManagerIssueWorkOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-work-order.model";

@Component({
   selector: "owa-issue-workorder-list",
   templateUrl: "./workorder-list.component.html",
})
export class WorkOrderListComponent {
   @Input() loading: boolean; // TODO: use this

   @Input() set showBills(showBills: boolean) {
      this._showBills = showBills;
      if (showBills) {
         this.workOrderColumns = [...this.defaultWorkOrderColumns, "Bill"];
      } else {
         this.workOrderColumns = this.defaultWorkOrderColumns;
      }
   }
   get showBills(): boolean {
      return this._showBills;
   }

   @Input() totalCount: number; // TODO: use this

   @Input() workOrderItems: Array<ServiceManagerIssueWorkOrderModel>;

   workOrderColumns: string[] = [];

   private _showBills: boolean;

   private defaultWorkOrderColumns = ["Property", "Unit", "Name", "Quantity", "Price", "Total"];

   constructor(private router: Router) {
      this.workOrderColumns = this.defaultWorkOrderColumns;
   }

   showBill(id: number) {
      if (id && id > 0) {
         this.router.navigate(["/bills", id]);
      }
   }
}
