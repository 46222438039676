import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class UserDefinedFieldFields extends EntityField {
   static readonly ComboList: UserDefinedFieldFields = new UserDefinedFieldFields("ComboList");

   static readonly CreateDate: UserDefinedFieldFields = new UserDefinedFieldFields("CreateDate");

   static readonly CreateUser: UserDefinedFieldFields = new UserDefinedFieldFields("CreateUser");

   static readonly CreateUserID: UserDefinedFieldFields = new UserDefinedFieldFields("CreateUserID");

   static readonly DefaultAttachment: UserDefinedFieldFields = new UserDefinedFieldFields("DefaultAttachment");

   static readonly DefaultAttachmentID: UserDefinedFieldFields = new UserDefinedFieldFields("DefaultAttachmentID");

   static readonly DefaultValue: UserDefinedFieldFields = new UserDefinedFieldFields("DefaultValue");

   static readonly FieldType: UserDefinedFieldFields = new UserDefinedFieldFields("FieldType");

   static readonly IsRequired: UserDefinedFieldFields = new UserDefinedFieldFields("IsRequired");

   static readonly MetaTag: UserDefinedFieldFields = new UserDefinedFieldFields("MetaTag");

   static readonly Name: UserDefinedFieldFields = new UserDefinedFieldFields("Name");

   static readonly OwnerProspectUserDefinedFieldLink: UserDefinedFieldFields = new UserDefinedFieldFields(
      "OwnerProspectUserDefinedFieldLink"
   );

   static readonly ParentType: UserDefinedFieldFields = new UserDefinedFieldFields("ParentType");

   static readonly PrecisionValue: UserDefinedFieldFields = new UserDefinedFieldFields("PrecisionValue");

   static readonly ProspectUserDefinedFieldLink: UserDefinedFieldFields = new UserDefinedFieldFields(
      "ProspectUserDefinedFieldLink"
   );

   static readonly SortOrder: UserDefinedFieldFields = new UserDefinedFieldFields("SortOrder");

   static readonly UpdateDate: UserDefinedFieldFields = new UserDefinedFieldFields("UpdateDate");

   static readonly UpdateUser: UserDefinedFieldFields = new UserDefinedFieldFields("UpdateUser");

   static readonly UpdateUserID: UserDefinedFieldFields = new UserDefinedFieldFields("UpdateUserID");

   static readonly UserDefinedFieldID: UserDefinedFieldFields = new UserDefinedFieldFields("UserDefinedFieldID");
}
