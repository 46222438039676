import { Injectable } from "@angular/core";
import { Params, QueryParamsHandling, Router } from "@angular/router";

@Injectable({
   providedIn: "root",
})
export class LocationsQueryStringValuesService {
   LocationsValue: string;

   LocationIDValue: number;

   constructor(private router: Router) {}

   redirectWithLocationInformation(mergeQueryString = false): any {
      let queryParamsHandling: QueryParamsHandling = "";
      if (mergeQueryString) {
         queryParamsHandling = "merge";
      }

      const params: Params = {};

      if (this.LocationsValue != null && this.LocationsValue !== undefined && this.LocationsValue !== "") {
         params["locations"] = this.LocationsValue;
      } else if (this.LocationIDValue != null) {
         params["locations"] = this.LocationIDValue.toString();
      }

      this.router.navigate(["/login"], {
         queryParams: params,
         queryParamsHandling: queryParamsHandling,
      });
   }
}
