import { Component } from "@angular/core";
import { BillViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-view.model";
import { Observable } from "rxjs";

import { BillDetailPreferences } from "../bill-detail-preferences.interface";
import { BillDetailService } from "../bill-detail.service";

@Component({
   selector: "owa-bill-detail-container",
   templateUrl: "bill-detail-container.component.html",
})
export class BillDetailContainerComponent {
   loading = new Observable<boolean>();

   bill: Observable<BillViewModel>;

   preferences: Observable<BillDetailPreferences>;

   constructor(billDetailService: BillDetailService) {
      this.loading = billDetailService.loading;
      this.bill = billDetailService.bill;
      this.preferences = billDetailService.preferences;
   }
}
