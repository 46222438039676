import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";

import { SearchPostBodyModel } from "./search-post-body.model";

export class ServiceManagerIssueSearchPostModel extends SearchPostBodyModel {
   LinkedEntityID?: number;
   LinkedEntityType?: EntityType;
   OwnerIDs: Array<number>;
   PropertyIDs: Array<number>;
}
