import { ComponentType } from "@angular/cdk/portal";
import { TemplateRef, ViewContainerRef } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";

export class DialogConfigurationModel {
   viewContainerRef?: ViewContainerRef;
   componentInstance: ComponentType<unknown> | null;
   disableClose?: boolean;
   width?: number;
   widthUnit: string = "px";
   height?: number;
   heightUnit: string = "px";
   title?: string;
   payloads?: Array<ActionTriggerPayloadModel>;
   styleClass: string;
   closeButtonInFooter: boolean;
   closeButtonText: string;
   closeDialogOnOutsideClick?: boolean;
   expressAction: ExpressActions;
   closeCallbackOverride: () => void | null;
   headingButtonsTemplate?: TemplateRef<any>;
   overflowYHidden: boolean;
}
