import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignableDocumentsSignComponent } from "./signable-documents-sign/signable-documents-sign.component";
import { SignableDocumentsViewComponent } from "./signable-documents-view/signable-documents-view.component";
import { RouterModule } from "@angular/router";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
     SignableDocumentsSignComponent,
     SignableDocumentsViewComponent,
   ],
})
export class SignableDocumentsModule { }
