import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { ExpressViewWidths } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-view-widths.enum";

import { ValidationModel } from "../../../../lcs/src/lib/inputs/validation/validation.model";
import { ExpressPayloadFields } from "../enumerations/generated/express-payload-fields.enum";
import { HistoryType } from "../enumerations/generated/history-type.enum";
import { FilterOption } from "./filter-option.model";
import { ExpressControlConditionModel } from "./generated/express-control-condition.model";
import { ExpressPropertyBindingConfigurationModel } from "./generated/express-property-binding-configuration.model";
import { SelectorItemTransferModelConverter } from "./selector-item-model.converter";

export class PropertyBindingConfigurationModel {
   PropertyPath: string;
   ValuePath: string;
   RequiredEmbeds: string;
   DisplayName: string;
   AlternateDisplayName: string;
   ControlType: ExpressLayoutControlTypes;
   ControlEntityType: EntityType;
   ControlEnumType: string;
   ControlResource: string;
   ControlParentEntityType: EntityType;
   ControlEndpointOverride: string;
   LargeRowNumber: number;
   ExpressViewWidth: ExpressViewWidths;
   LargeColumnPosition: number;
   MediumRowNumber: number;
   MediumColumnWidth: number;
   MediumColumnPosition: number;
   SmallSortOrder: number;
   Class: string;
   IsReadOnly: boolean;
   ItemIDPropertyPath: string;
   IsNullable: boolean;
   DefaultValue: string | number;
   AdditionalSelectorItems: Array<any>;
   // NumberOfSelectedItemsToDisplay is nullable because there is no undefined in C#, so it instead defaults to 0, which is a valid value for this property and therefore cannot be considered to be a non-entry
   NumberOfSelectedItemsToDisplay: number | null;
   EntityKeyIdPropertyPath: string;
   EntityDisplayPropertyPath: string;

   HyperlinkPropertyPath: string;

   DataType: ExpressDataTypes;
   Format: string;
   MinFractionDigits: number;
   MaxFractionDigits: number;
   IsUTCDateTime: boolean;
   IsSubItem: boolean;
   Validation: ValidationModel | null;

   Action: ExpressActions;
   ActionButtonText: string;
   IsQuickAction: boolean;
   ActionButtonIcon: string;
   IsDisplayButtonAsIcon: boolean;
   ActionPayloads: ActionTriggerPayloadModel[];

   DataSourceFilters: Array<FilterOption> | null;
   ControlConditions: Array<ExpressControlConditionModel>;

   SystemPreferenceID: number;

   UseAlternateDisplayName: boolean;
   Disabled: boolean;
   ConfirmationPropertyPath: string;
   ConfirmationFieldIdentifier: string;
   Icon: string;
   FormIdentifier: string;
   ValuesToExclude: Array<string>;
   ActionDisabled: boolean;

   historyType: HistoryType;

   OrderingOptions: Array<string>;

   constructor() {
      this.ControlType = ExpressLayoutControlTypes.TextBox;
      this.Validation = new ValidationModel();
   }

   public static FromExpressPropertyBindingConfigurationModel(
      expressFieldDefinition: ExpressPropertyBindingConfigurationModel
   ): PropertyBindingConfigurationModel {
      const fieldDefinition = new PropertyBindingConfigurationModel();
      fieldDefinition.Action = expressFieldDefinition.Action;
      if (fieldDefinition.Action) {
         fieldDefinition.ActionPayloads =
            PropertyBindingConfigurationModel.PropertyBindingConfigurationActionPayloadModels(fieldDefinition.Action);
      }
      // the following should be slightly refactored when more buttons begin to use isDisplayButtonAsIcon
      // instead a map should be created an used to map ExpressActions to a material-icons (like 'add')
      fieldDefinition.ActionButtonText = expressFieldDefinition.IsDisplayButtonAsIcon
         ? "add"
         : expressFieldDefinition.ActionButtonText;

      fieldDefinition.ActionButtonIcon = expressFieldDefinition.ActionButtonIcon;

      fieldDefinition.IsDisplayButtonAsIcon = expressFieldDefinition.IsDisplayButtonAsIcon;
      fieldDefinition.ControlEntityType = expressFieldDefinition.ControlEntityType;
      fieldDefinition.ControlEnumType = expressFieldDefinition.ControlEnumType;
      fieldDefinition.ControlType = expressFieldDefinition.ControlType;
      fieldDefinition.DataType = expressFieldDefinition.DataType;
      fieldDefinition.DisplayName = expressFieldDefinition.DisplayName;
      fieldDefinition.AlternateDisplayName = expressFieldDefinition.AlternateDisplayName;
      fieldDefinition.ExpressViewWidth = expressFieldDefinition.ExpressViewWidth;
      fieldDefinition.Format = expressFieldDefinition.Format;
      fieldDefinition.IsNullable = expressFieldDefinition.IsNullable;
      fieldDefinition.LargeColumnPosition = expressFieldDefinition.LargeColumnPosition;
      fieldDefinition.LargeRowNumber = expressFieldDefinition.LargeRowNumber;
      fieldDefinition.MaxFractionDigits = expressFieldDefinition.MaxFractionDigits;
      fieldDefinition.MediumColumnPosition = expressFieldDefinition.MediumColumnPosition;
      fieldDefinition.MediumColumnWidth = expressFieldDefinition.MediumColumnWidth;
      fieldDefinition.MediumRowNumber = expressFieldDefinition.MediumRowNumber;
      fieldDefinition.MinFractionDigits = expressFieldDefinition.MinFractionDigits;
      fieldDefinition.IsUTCDateTime = expressFieldDefinition.IsUTCDateTime;
      fieldDefinition.IsSubItem = expressFieldDefinition.IsSubItem;
      fieldDefinition.PropertyPath = expressFieldDefinition.PropertyPath;
      fieldDefinition.SmallSortOrder = expressFieldDefinition.SmallSortOrder;
      fieldDefinition.Validation = expressFieldDefinition.Validation
         ? ValidationModel.FromExpressValidationModel(expressFieldDefinition.Validation)
         : null;
      fieldDefinition.ControlResource = expressFieldDefinition.ControlResource;
      fieldDefinition.ControlParentEntityType = expressFieldDefinition.ControlParentEntityType;
      fieldDefinition.ItemIDPropertyPath = expressFieldDefinition.ItemIDPropertyPath;
      fieldDefinition.ControlConditions = expressFieldDefinition.ControlConditions;

      fieldDefinition.DataSourceFilters = expressFieldDefinition.DataSourceFilters
         ? expressFieldDefinition.DataSourceFilters.map(FilterOption.FromExpressControlDataSourceFilterModel)
         : null;
      fieldDefinition.ConfirmationPropertyPath = expressFieldDefinition.ConfirmationPropertyPath;
      fieldDefinition.ConfirmationFieldIdentifier = expressFieldDefinition.ConfirmationFieldIdentifier;
      fieldDefinition.Icon = expressFieldDefinition.Icon;
      fieldDefinition.FormIdentifier = expressFieldDefinition.FormIdentifier;
      fieldDefinition.ValuesToExclude = expressFieldDefinition.ValuesToExclude;
      fieldDefinition.DefaultValue = expressFieldDefinition.DefaultValue;
      fieldDefinition.NumberOfSelectedItemsToDisplay = expressFieldDefinition.NumberOfSelectedItemsToDisplay ?? null;
      fieldDefinition.AdditionalSelectorItems = expressFieldDefinition.AdditionalSelectorItems?.map((item) =>
         SelectorItemTransferModelConverter.convert(item)
      );
      fieldDefinition.OrderingOptions = expressFieldDefinition.OrderingOptions;
      return fieldDefinition;
   }

   public static PropertyBindingConfigurationActionPayloadModels(action: ExpressActions): ActionTriggerPayloadModel[] {
      const payloads: ActionTriggerPayloadModel[] = new Array<ActionTriggerPayloadModel>();
      switch (action) {
         case ExpressActions.History_AddNote:
            const historyAddNote = new ActionTriggerPayloadModel();
            historyAddNote.PayloadField = ExpressPayloadFields.HistoryType;
            historyAddNote.Value = HistoryType.Note;
            payloads.push(historyAddNote);

            const historyAddNoteResource = new ActionTriggerPayloadModel();
            historyAddNoteResource.PayloadField = ExpressPayloadFields.Resource;
            historyAddNoteResource.Value = payloads.push(historyAddNoteResource);
            break;
         case ExpressActions.History_AddCall:
            const historyAddCall = new ActionTriggerPayloadModel();
            historyAddCall.PayloadField = ExpressPayloadFields.HistoryType;
            historyAddCall.Value = HistoryType.Call;
            payloads.push(historyAddCall);

            const historyAddCallResource = new ActionTriggerPayloadModel();
            historyAddCallResource.PayloadField = ExpressPayloadFields.Resource;
            historyAddCallResource.Value = payloads.push(historyAddCallResource);
            break;
         case ExpressActions.History_AddEmail:
            const historyAddEmail = new ActionTriggerPayloadModel();
            historyAddEmail.PayloadField = ExpressPayloadFields.HistoryType;
            historyAddEmail.Value = HistoryType.Email;
            payloads.push(historyAddEmail);

            const historyAddEmailResource = new ActionTriggerPayloadModel();
            historyAddEmailResource.PayloadField = ExpressPayloadFields.Resource;
            historyAddEmailResource.Value = payloads.push(historyAddEmailResource);
            break;
         case ExpressActions.History_AddVisit:
            const historyAddVisit = new ActionTriggerPayloadModel();
            historyAddVisit.PayloadField = ExpressPayloadFields.HistoryType;
            historyAddVisit.Value = HistoryType.Visit;
            payloads.push(historyAddVisit);

            const historyAddVisitResource = new ActionTriggerPayloadModel();
            historyAddVisitResource.PayloadField = ExpressPayloadFields.Resource;
            historyAddVisitResource.Value = payloads.push(historyAddVisitResource);
            break;
      }
      return payloads;
   }
}
