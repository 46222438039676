import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";

import { ValidationModule } from "../validation/validation.module";
import { UDFEncryptedTextComponent } from "./udf-encrypted-text.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, LoadingOverlayModule],
   declarations: [UDFEncryptedTextComponent],
   exports: [UDFEncryptedTextComponent],
})
export class UDFEncryptedTextModule {}
