<div class="selector-container">
   <div>
      <div class="report-parameter-label">
         <label
            class="parameter-label"
            [class.disabled]="disabled || (hasAsk === true && chartAccountsReportParameter.IsAsk === true)"
         >
            {{ displayName }}
         </label>
         <lcs-report-parameter-ask
            *ngIf="hasAsk"
            [isAsk]="chartAccountsReportParameter.IsAsk"
            (askChanged)="askChanged($event)"
            [name]="name"
         ></lcs-report-parameter-ask>
      </div>
      <ng-container>
         <lcs-chart-accounts-to-include-selector
            [name]="name + 'chart-account-selector'"
            [displayName]="displayName"
            [entityValueSourceFilters]="entityValueSourceFilters"
            [ngModel]="selectedItems"
            [showMapping]="showMapping"
            [chartMapParameterValue]="chartMapParameterValue"
            [lcsValidate]="validation"
            [disabled]="disabled || (hasAsk === true && chartAccountsReportParameter.IsAsk === true)"
            (selectionChange)="selectionChanged($event)"
         ></lcs-chart-accounts-to-include-selector>
      </ng-container>
   </div>
</div>