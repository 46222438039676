import { Component, Input, OnInit } from "@angular/core";
import { FileType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/file-type.enum";

@Component({
   selector: "owa-file-icon",
   templateUrl: "./file-icon.component.html",
   styleUrls: ["./file-icon.component.css"],
})
export class FileIconComponent implements OnInit {
   @Input() extension: string;

   @Input() caption: string;

   icon: string;

   constructor() {}

   ngOnInit(): void {
      this.icon = "fa ";
      switch (this.extension) {
         case FileType.DOC.valueOf():
         case FileType.DOCX.valueOf():
            this.icon += "fa-file-word";
            break;
         case FileType.PDF.valueOf():
            this.icon += "fa-file-pdf";
            break;
         case FileType.PPTX.valueOf():
            this.icon += "fa-file-powerpoint";
            break;
         case FileType.XLSX.valueOf():
         case FileType.XLS.valueOf():
         case FileType.CSV.valueOf():
            this.icon += "fa-file-excel";
            break;
         case FileType.ZIP.valueOf():
            this.icon += "fa-file-archive";
            break;
         default:
            this.icon += "fa-file";
            break;
      }
   }
}
