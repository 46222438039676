import { Directive, ElementRef, HostBinding, Input, isDevMode, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { fromEvent, Observable, Subject, takeUntil } from "rxjs";

import { FormRegistrationModel } from "./form-registration.model";
import { FormRegistrationService } from "./form-registration.service";

@Directive({
   // eslint-disable-next-line @angular-eslint/directive-selector
   selector: "form:not([ngNoForm]),ngForm,[ngForm]",
})
export class FormRegistrationDirective implements OnInit, OnDestroy {
   @HostBinding("id") formId: string;
   @HostBinding("autocomplete") autoComplete: string;
   @Input() enableDeactivationDialogs: boolean = false;
   @Input() customDeactivationMessage: string = "";

   private _enableBeforeUnloadNotification: boolean = false;
   public get enableBeforeUnloadNotification(): boolean {
      return this._enableBeforeUnloadNotification;
   }
   @Input()
   public set enableBeforeUnloadNotification(value: boolean) {
      this._enableBeforeUnloadNotification = value;
      if (value) {
         if (isDevMode()) {
            console.log(
               `DevMode: set enableBeforeUnloadNotification ~ formId=${this.formId} dirty=${this.form.dirty} subscribe value=${value}`
            );
         }
         this.beforeUnload$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event: any) => this.beforeUnloadNotification(event));
      } else {
         if (isDevMode()) {
            console.log(
               `DevMode: set enableBeforeUnloadNotification ~ formId=${this.formId} dirty=${this.form.dirty} unsubscribe value=${value}`
            );
         }
         this.unsubscribe.next();
      }
   }

   private beforeUnload$: Observable<any>;

   private unsubscribe: Subject<void> = new Subject<void>();

   constructor(
      private elementRef: ElementRef,
      private form: ControlContainer,
      private formRegistrationService: FormRegistrationService,
      private uniqueIdService: UniqueIDService
   ) {
      this.autoComplete = "off";
      this.formId = `form${this.uniqueIdService.getUniqueID()}`;
      this.beforeUnload$ = fromEvent(window, "beforeunload");
   }

   ngOnInit(): void {
      const formRegistration = new FormRegistrationModel();
      formRegistration.formId = this.formId;
      formRegistration.form = this.form as NgForm;
      formRegistration.element = this.elementRef.nativeElement as HTMLFormElement;
      formRegistration.enableDeactivationDialogs = this.enableDeactivationDialogs;
      formRegistration.customDeactivationMessage = this.customDeactivationMessage;
      this.formRegistrationService.registerForm(formRegistration);
   }

   ngOnDestroy(): void {
      this.formRegistrationService.deregisterForm(this.formId);
      if (this.enableBeforeUnloadNotification) {
         this.unsubscribe.next();
         this.unsubscribe.complete();
      }
   }

   private beforeUnloadNotification(event: any): boolean {
      if (this.enableBeforeUnloadNotification && this.form.dirty) {
         event.preventDefault();
         event.returnValue = false;
      }
      return event.returnValue;
   }
}
