import { Component, Input, OnInit } from "@angular/core";
import { ContextService } from "@lcs/action-context/context.service";
import { ExpressFooterButtonModel } from "@lcs/footer/express-footer-button.model";
import { ExpressContextHelp } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-context-help.enum";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";

import { DialogContentComponent } from "../dialog-content-component.interface";
import { CurrentDialogService } from "../services/current-dialog.service";
import { ConfirmationDialogConfigurationModel } from "./confirmation-dialog-configuration.model";

@Component({
   selector: "lcs-confirmation-dialog",
   templateUrl: "confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent implements OnInit, DialogContentComponent {
   @Input() configuration: ConfirmationDialogConfigurationModel;

   public childComponentType?: any;

   public expressAction: ExpressActionModel;

   public contextHelp = ExpressContextHelp;

   public onClose: Function;

   public onShow: Function;

   constructor(private currentDialogService: CurrentDialogService, private contextService: ContextService) {}

   ngOnInit() {
      if (this.configuration) {
         const footerButtons = new Array<ExpressFooterButtonModel>();

         if (this.configuration.showAcceptButton) {
            const acceptButton = new ExpressFooterButtonModel();
            acceptButton.label = this.configuration.acceptButtonText;
            acceptButton.isRaised = true;
            acceptButton.onClick = this.acceptOption.bind(this);
            footerButtons.push(acceptButton);
         }

         if (this.configuration.showRejectButton) {
            const rejectButton = new ExpressFooterButtonModel();
            rejectButton.label = this.configuration.rejectButtonText;
            rejectButton.isRaised = false;
            rejectButton.onClick = this.rejectOption.bind(this);
            footerButtons.push(rejectButton);
         }

         if (this.configuration.showOtherButton) {
            const otherButton = new ExpressFooterButtonModel();
            otherButton.label = this.configuration.otherButtonText;
            otherButton.isRaised = this.configuration.raiseOtherButton;
            otherButton.onClick = this.otherOption.bind(this);
            footerButtons.push(otherButton);
         }

         if (this.configuration.showCancelButton) {
            const cancelButton = new ExpressFooterButtonModel();
            cancelButton.label = this.configuration.cancelButtonText;
            cancelButton.isRaised = false;
            cancelButton.onClick = this.cancelOption.bind(this);
            footerButtons.push(cancelButton);
         }

         this.contextService.footerButtons.next(footerButtons);
      }
   }

   acceptOption() {
      if (this.configuration.accept) {
         this.configuration.accept();
      }
      this.currentDialogService.closeDialog();
   }

   rejectOption() {
      if (this.configuration.reject) {
         this.configuration.reject();
      }
      this.currentDialogService.closeDialog();
   }

   cancelOption() {
      if (this.configuration.cancel) {
         this.configuration.cancel();
      }
      this.currentDialogService.closeDialog();
   }

   otherOption() {
      if (this.configuration.other) {
         this.configuration.other();
      }
      this.currentDialogService.closeDialog();
   }
}
