import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { ChangeEmailAddressViewComponent } from "../change-emailaddress-view/change-emailaddress-view.component";
import { ChangeEmailAddressComponent } from "./change-emailaddress.component";

@NgModule({
   declarations: [ChangeEmailAddressComponent, ChangeEmailAddressViewComponent],
   imports: [CommonModule, FormsModule, LcsProgressButtonModule, TextBoxModule],
   exports: [ChangeEmailAddressComponent],
})
export class ChangeEmailAddressModule {}
