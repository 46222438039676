<div class="report-parameter-label">
   <lcs-checkbox-report-parameter
      [name]="'violation-notes'"
      [displayName]="'Violation Notes'"
      [disabled]="disabled || (hasAsk === true && violationNotesReportParameter.IsAsk === true)"
      [parameter]="violationNotesReportParameter"
   ></lcs-checkbox-report-parameter>

   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="violationNotesReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-numeric-input-report-parameter
   [name]="'number-notes'"
   [displayName]="'Number of Violations'"
   [disabled]="disabled || !isChecked || (hasAsk === true && numberNotesReportParameter.IsAsk === true)"
   [parameter]="numberNotesReportParameter"
></lcs-numeric-input-report-parameter>