export class EntityGetOption {
   Identifier: string;

   constructor(identifer: string) {
      this.Identifier = identifer;
   }

   toString(): string {
      return this.Identifier;
   }

   static toApiString(getOptions?: Array<string | EntityGetOption> | null): string | null {
      if (getOptions == null) {
         return null;
      }

      if (!(getOptions instanceof Array)) {
         getOptions = [getOptions];
      }

      return getOptions.join(";");
   }
}
