import { Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { SystemWebPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-web-preference.enum";
import { PrivilegeInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/services/privilegeinformation.service";
import { forkJoin, map, Observable, of, Subject, take } from "rxjs";

@Injectable()
export class PrivilegeGuard implements OnDestroy {
   private unsubscribe = new Subject<void>();
   constructor(private privilegeInformationService: PrivilegeInformationService) {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   static canActivate: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(PrivilegeGuard).canActivateRoute(_route, state);
   };

   static canActivateChild: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(PrivilegeGuard).canActivateChildRoute(_route, state);
   };

   canActivateRoute(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const preferencesToCheck = _route.data["requiredPrivileges"] as Array<SystemWebPreference>;

      if (!preferencesToCheck) {
         console.error("Route configured to check preference, but no preference provided. Check app.routing.");
         return of(false);
      }
      const observableBatch = new Array<Observable<boolean>>();
      preferencesToCheck.forEach((pref) => {
         observableBatch.push(this.privilegeInformationService.validatePrivilege(pref));
      });
      return forkJoin(observableBatch).pipe(map((privValidation) => privValidation.some((v) => v)));
   }

   private canActivateChildRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.canActivateRoute(route, state).pipe(take(1));
   }
}
