import { CdkColumnDef, CdkHeaderCell } from "@angular/cdk/table";
import { Directive, ElementRef, HostBinding } from "@angular/core";

import { LcsColumnDefDirective } from "./column-def.directive";

@Directive({
   // eslint-disable-next-line @angular-eslint/directive-selector
   selector: "lcs-header-cell, th[lcs-header-cell]",
})
export class LcsHeaderCellDirective extends CdkHeaderCell {
   @HostBinding("class.lcs-header-cell") cellClass: boolean = true;

   @HostBinding("attr.role") role: string = "columnheader";

   @HostBinding("style.width") widthStyle: string | null;

   constructor(columnDef: CdkColumnDef, elementRef: ElementRef<HTMLElement>) {
      super(columnDef, elementRef);
      elementRef.nativeElement.classList.add(`lcs-column-${columnDef.cssClassFriendlyName}`);
      this.widthStyle = (columnDef as LcsColumnDefDirective).widthStyle;
      this.setHidden((columnDef as LcsColumnDefDirective).hidden);
   }

   setHidden(hidden: boolean): void {
      this.role = hidden ? "rowheader" : "columnheader";
   }
}
