import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ObjectDisplayValuePipe } from "./object-display-value.pipe";
import { ObjectMapResolverPipe } from "./object-map-resolver.pipe";
import { ObjectMapResolverService } from "./object-map-resolver.service";
import { OrderByService } from "./order-by.service";
import { PhoneNumberPipe } from "./phone-number.pipe";
import { PureObjectDisplayValuePipe } from "./pure-object-display-value.pipe";
import { PureObjectMapResolverPipe } from "./pure-object-map-resolver.pipe";
import { RmCurrencyPipe } from "./rm-currency-pipe";
import { RmNumberPipe } from "./rm-number-pipe";
import { SatisfiesConditionService } from "./satisfies-condition.service";
import { UserDefinedValueDisplayValuePipe } from "./user-defined-value-display-value.pipe";
import { UserDefinedValueDisplayValueService } from "./user-defined-value-display-value.service";
import { ValueFormatterPipe } from "./value-formatter.pipe";

@NgModule({
   imports: [CommonModule],
   declarations: [
      ObjectMapResolverPipe,
      ObjectDisplayValuePipe,
      ValueFormatterPipe,
      PhoneNumberPipe,
      PureObjectDisplayValuePipe,
      PureObjectMapResolverPipe,
      UserDefinedValueDisplayValuePipe,
      RmCurrencyPipe,
      RmNumberPipe,
   ],
   providers: [
      SatisfiesConditionService,
      OrderByService,
      ObjectMapResolverService,
      UserDefinedValueDisplayValueService,
   ],
   exports: [
      ObjectMapResolverPipe,
      ObjectDisplayValuePipe,
      ValueFormatterPipe,
      PhoneNumberPipe,
      PureObjectDisplayValuePipe,
      PureObjectMapResolverPipe,
      UserDefinedValueDisplayValuePipe,
      RmCurrencyPipe,
      RmNumberPipe,
   ],
})
export class PipesModule {}
