<owa-dashboard-tile
   [title]="'Checks'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'checks'"
>
   <lcs-table [dataSource]="$any(observChecks | async)">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="checkColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: checkColumns"
      ></tr>
      <ng-container
         lcsColumnDef="TransactionDate"
         [width]="8"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Check Date </th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >{{ row.TransactionDate | date: "MM/dd/yyyy" }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="Payee"
         [width]="15"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Payee</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >{{ row.Payee }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="Amount"
         [width]="10"
         [priority]="3"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Amount</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="numeric"
         >{{
            row.Amount | currency: "USD":"symbol":"1.2-2"
            }}</td>
      </ng-container>
      <ng-container
         lcsColumnDef="NewRow"
         [width]="1"
         [priority]="4"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div *ngIf="row.IsNew">
               <span class="notification-icon"></span>
            </div>
         </td>
      </ng-container>
   </lcs-table>

   <ng-template #noChecks>
      <div class="no-result">No Checks found</div>
   </ng-template>
</owa-dashboard-tile>