import { Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Params, Router, RouterStateSnapshot } from "@angular/router";
import { SessionCheckResult } from "@lcs/authentication/session-check-result.enum";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { map, Subject, take, tap } from "rxjs";

@Injectable()
export class SessionGuard implements OnDestroy {
   private unsubscribe = new Subject<void>();
   constructor(private router: Router, private sessionCheckService: SessionCheckService) {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   static canActivate: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(SessionGuard).canActivateRoute(_route, state);
   };

   static canActivateChild: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(SessionGuard).canActivateChildRoute(_route, state);
   };

   canActivateRoute(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.sessionCheckService.checkSession(true).pipe(
         map((result) => {
            return result !== SessionCheckResult.Failed;
         }),
         tap((result) => {
            if (result === false) {
               // can't get queryParams from _route for https://entid.owa.rentmanager.com?locationid=1&locations=1
               const queryParameters: Params = { returnUrl: state.url };
               if (window.location.search !== "") {
                  const paramsArray = window.location.search.substring(1).split("&");
                  paramsArray.forEach((s) => {
                     const paramParts = s.split("=");
                     if (paramParts.length === 2) {
                        queryParameters[paramParts[0]] = paramParts[1];
                     }
                  });
               }

               this.router.navigate(["/login"], { queryParams: queryParameters });
            }
         })
      );
   }

   private canActivateChildRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.canActivateRoute(route, state).pipe(take(1));
   }
}
