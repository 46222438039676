import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

import { UniqueIdentifier } from "../core/unique-identifier.interface";

@Component({
   selector: "lcs-sidebar-layout-selector",
   templateUrl: "sidebar-layout-selector.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLayoutSelectorComponent {
   @Input() disableUnselected: boolean;

   @Input() displayPropertyPath: string = "displayValue";

   @Input() set items([values, index]: [Array<UniqueIdentifier>, number | null]) {
      if (values) {
         this._items = values;
         this._index = index;
         if (this._items && this._items.length > 0 && !this.selectedUniqueIdentifier) {
            this.selectedUniqueIdentifier = index ? index : this._items[0].UniqueIdentifier;
         }
      }
   }

   @Input() itemTemplate: TemplateRef<any>;

   @Input() newItemModel: any;

   @Input() selectedUniqueIdentifier: number;

   @Output() selectedUniqueIdentifierChange = new EventEmitter<number>();

   get items(): [Array<UniqueIdentifier>, number | null] {
      return [this._items, this._index];
   }

   private _items: Array<UniqueIdentifier>;

   private _index: number | null;

   onItemClick(uniqueIdentifier: number) {
      if (uniqueIdentifier !== this.selectedUniqueIdentifier) {
         this.selectedUniqueIdentifier = uniqueIdentifier;
         this.selectedUniqueIdentifierChange.emit(this.selectedUniqueIdentifier);
      }
   }
}
