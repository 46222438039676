import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { InputsModule } from "@lcs/inputs/inputs.module";
import { TextAreaModule } from "@lcs/inputs/text-area/text-area.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { OwaInputsModule } from "../inputs/inputs.module";
import { LayoutModule } from "../layout/layout.module";
import { UserProfileModalComponent } from "./user-profile-modal/user-profile-modal.component";
import { UserProfileComponent } from "./user-profile.component";

@NgModule({
   declarations: [UserProfileModalComponent, UserProfileComponent],
   imports: [
      CommonModule,
      FormRegistrationModule,
      FormsModule,
      InputsModule,
      LayoutModule,
      LcsProgressButtonModule,
      LoadingOverlayModule,
      TextAreaModule,
      TextBoxModule,
      RouterModule,
      ReactiveFormsModule,
      OwaInputsModule,
   ],

   exports: [UserProfileModalComponent, UserProfileComponent],
})
export class UserProfileModule {}
