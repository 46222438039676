import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class ApiRequestHelperService {
   static epayAuthTokenHeaderName = "";
   static httpString: string = "http://";
   static httpsString: string = "https://";
   static totalResultsHeaderName = "X-Total-Results";
   static contentTypeHeaderName = "Content-Type";

   url = "localhost:8185/";
   isHttps: boolean = true;
   initialHeaders: HttpHeaders;

   constructor() {
      let headers = new HttpHeaders();
      headers = headers.set(ApiRequestHelperService.contentTypeHeaderName, "application/json");
      this.initialHeaders = headers;
      this.determineURL();
   }

   determineURL() {
      if (window.location.host.startsWith("localhost")) {
         let portNumber = +window.location.host.substring(window.location.host.indexOf(":") + 1);
         portNumber++;
         this.url = `${ApiRequestHelperService.httpString}localhost:${portNumber}/`;
         this.isHttps = false;
      } else {
         const host = window.location.host;
         let apiHost = host.replace(".owa.", ".owaapi.");
         apiHost = apiHost.replace(".owanew.", ".owaapi.");
         this.url = `${ApiRequestHelperService.httpsString}${apiHost}/`;
         this.isHttps = true;
      }
   }

   sanitizeURL(input: string) {
      let httpPartToReplace = ApiRequestHelperService.httpsString;
      let newHttpPart = ApiRequestHelperService.httpString;
      if (this.isHttps) {
         httpPartToReplace = ApiRequestHelperService.httpString;
         newHttpPart = ApiRequestHelperService.httpsString;
      }
      if (input.startsWith(newHttpPart)) {
         return input;
      }
      if (input.startsWith(httpPartToReplace)) {
         return newHttpPart + input.substring(httpPartToReplace.length);
      } else {
         return newHttpPart + input;
      }
   }

   getUrl(endpoint: string, additionalParameters?: Array<string>): string {
      if (!endpoint.startsWith(this.url, 0)) {
         endpoint = this.url + endpoint;
      }
      let queryString = "";
      if (additionalParameters && additionalParameters.length > 0) {
         if (endpoint.indexOf("?") === -1) {
            queryString += "?";
         } else {
            queryString += "&";
         }
         queryString += additionalParameters.join("&");
      }
      return endpoint + queryString;
   }

   buildInitialHeaders(): HttpHeaders {
      let headers = new HttpHeaders();
      for (const headerKey of this.initialHeaders.keys()) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
         headers = headers.set(headerKey, this.initialHeaders.get(headerKey));
      }
      return headers;
   }
}
