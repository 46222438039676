import { Injectable } from "@angular/core";

import { ObjectMapResolverService } from "./object-map-resolver.service";

@Injectable()
export class SatisfiesConditionService {
   constructor() {}

   satisfiesCondition(items: Array<any>, fieldName: string, operator: string, fieldValue: any): Array<any> {
      return ObjectMapResolverService.filterBySatisfiesCondition(items, fieldName, operator, fieldValue);
   }
}
