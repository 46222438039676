import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { SessionCheckResult } from "@lcs/authentication/session-check-result.enum";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { SystemWebPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/systemwebpreferences.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { filter, first, Subject, takeUntil } from "rxjs";

import { TopBarService } from "../../top-bar/top-bar.service";
import { AuthenticationService } from "../authentication.service";
import { OwaAuthenticationModel } from "../models/owa-authentication.model";
import { LocationComponent } from "../rm-location/rm-location.component";
import { LocationService } from "../rm-location/rm-location.service";

@Component({
   selector: "owa-login",
   templateUrl: "./login.component.html",
   styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
   public authModel: OwaAuthenticationModel = new OwaAuthenticationModel();
   public selectedlocation: number;
   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
   public errorMessage: string = null;
   @ViewChild(LocationComponent, { static: true }) locationComponent: LocationComponent;
   public sourceStep: string;
   infoMessage: string;
   loginButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   preferenceValue: boolean;
   success: boolean;
   public signupUrl: string;
   public accountRecoveryUrl: string;
   public queryParam: Params;
   private unsubscribe = new Subject<void>();

   constructor(
      private authenticationService: AuthenticationService,
      private errorMessageService: ErrorMessageService,
      private sessionStatusService: SessionStatusService,
      private activatedRoute: ActivatedRoute,
      private systemWebPreferenceService: SystemWebPreferencesService,
      private locationService: LocationService,
      private topbarservice: TopBarService,
      private cacheMonitorService: CacheMonitorService,
      private sessionCheckService: SessionCheckService
   ) {}

   ngOnInit() {
      setTimeout(() => {
         if (this.sessionStatusService.currentSessionStatus) {
            this.authenticationService.redirect();
         }
      });
      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         if (queryParams["sourceStep"] && !isNaN(+queryParams["sourceStep"])) {
            if (queryParams["sourceStep"] === "ResetPassword") {
               this.infoMessage =
                  "Your password has been successfully changed. Please login with your new credentials.";
            }
         }

         if (queryParams["returnUrl"]) {
            this.authenticationService.returnURL = queryParams["returnUrl"];
         }
      });
      this.locationComponent.isDataLoadingStatus.subscribe((status) => {
         if (status === LcsProgressButtonStatus.InProgress) {
            this.loginButtonStatus = LcsProgressButtonStatus.InProgress;
         } else if (status === LcsProgressButtonStatus.Error) {
            this.loginButtonStatus = LcsProgressButtonStatus.Error;
         } else {
            this.loginButtonStatus = LcsProgressButtonStatus.Unset;
         }
      });
      this.authenticationService.loginResults.pipe(takeUntil(this.unsubscribe)).subscribe((loginSuccessful) => {
         if (loginSuccessful != null) {
            if (!loginSuccessful.ok) {
               this.loginButtonStatus = LcsProgressButtonStatus.Error;
               this.errorMessageService.triggerHttpErrorMessage(loginSuccessful);
            } else {
               this.loginButtonStatus = LcsProgressButtonStatus.Success;
            }
         }
      });
      this.locationComponent.errorMessage
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((message) => (this.errorMessage = message));
      this.locationComponent.authlocationId
         .pipe(
            filter((location) => location != null),
            takeUntil(this.unsubscribe)
         )
         .subscribe((locationid) => {
            this.authModel.LocationId = locationid;
            this.systemWebPreferenceService
               .GetAccountCreationSystemWebPreference(locationid)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((pref) => {
                  this.preferenceValue = false;
                  if (pref != null) {
                     this.preferenceValue = pref.Value === "True" ? true : false;
                  }
               });

            this.systemWebPreferenceService
               .GetThemeColorSystemWebPreference(locationid)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((prefs) => {
                  this.authenticationService.setTheme(prefs);
               });

            this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
               this.signupUrl = "/#/signup";
               this.accountRecoveryUrl = "/#/recovery";
               if (queryParams != null) {
                  this.queryParam = this.locationService.toLowerCaseParams(queryParams);
                  if (
                     this.queryParam["locations"] != null &&
                     this.queryParam["locations"] !== undefined &&
                     this.queryParam["locations"] !== ""
                  ) {
                     this.signupUrl += "?locations=" + this.queryParam["locations"];
                     this.accountRecoveryUrl += "?locations=" + this.queryParam["locations"];
                  }
               }
               if (this.signupUrl.includes("locations")) {
                  this.signupUrl += "&";
                  this.accountRecoveryUrl += "&";
               } else {
                  this.signupUrl += "?";
                  this.accountRecoveryUrl += "?";
               }
               this.signupUrl += "locationId=" + this.authModel.LocationId;
               this.accountRecoveryUrl += "locationId=" + this.authModel.LocationId;
            });
         });

      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });
      this.sessionCheckService.sessionCheckResult
         .pipe(
            filter((result) => result === SessionCheckResult.Successful || result === SessionCheckResult.Changed),
            first(),
            takeUntil(this.unsubscribe)
         )
         .subscribe((result) => {
            if (result === SessionCheckResult.Successful || result === SessionCheckResult.Changed) {
               this.authenticationService.redirect();
            }
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
      this.topbarservice.topBarIsVisible.next(this.cacheMonitorService.cachesLoadedState);
   }

   onSubmit() {
      this.loginButtonStatus = LcsProgressButtonStatus.InProgress;
      this.authenticationService.logIn(this.authModel);
   }

   signup() {
      // this.router.navigateByUrl("/signup");
   }
}
