import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
   selector: "owa-estimate-print-view-more",
   templateUrl: "estimate-print-view-more.component.html",
})
export class EstimatePrintMoreComponent  {

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router) { }

   onCloseClick(): void {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
   }
}