import { Injectable } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { Subject } from "rxjs";

import { EntityEventResult } from "./entity-event-result.enum";
import { EntityEventResultModel } from "./entity-event-result.model";
import { EntityEventStateModel } from "./entity-event-state.model";
import { TriggerActionEventModel } from "./trigger-action-event.model";

@Injectable({
   providedIn: "root",
})
export class EntityEventService {
   entityEvents: Subject<ExpressEntityEvents>;
   triggerActionEvent: Subject<TriggerActionEventModel>;
   entityEventResult: Subject<EntityEventResultModel>;
   entityEventState: Subject<EntityEventStateModel>;

   constructor() {
      this.entityEvents = new Subject<ExpressEntityEvents>();
      this.triggerActionEvent = new Subject<TriggerActionEventModel>();
      this.entityEventResult = new Subject<EntityEventResultModel>();
      this.entityEventState = new Subject<EntityEventStateModel>();
   }

   public buildAndEmitEntityEventResult(
      expressEntityEvent: ExpressEntityEvents | null,
      entityEventResult: EntityEventResult,
      triggerAction?: ExpressActions,
      triggerActionPayloads?: Array<ActionTriggerPayloadModel>
   ): void {
      this.entityEventResult.next(
         new EntityEventResultModel(expressEntityEvent, entityEventResult, triggerAction, triggerActionPayloads)
      );
   }
}
