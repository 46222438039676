import { Component, Input, OnInit } from "@angular/core";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { EmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/email.model";
import { OwnershipDashboardModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/ownership-dashboard.model";
import { OwnershipsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/ownerships.service";
import { BehaviorSubject, Observable } from "rxjs";

import { EmailConfigurationModel } from "../../email/email-configuration.model";
import { EmailComponent } from "../../email/email.component";

@Component({
   selector: "owa-dashboard-ownerships",
   templateUrl: "./dashboard-tile-ownerships.component.html",
   providers: [OwnershipsService],
})
export class DashboardOwnershipComponent implements OnInit {
   @Input() showReserve: boolean;
   @Input() showSecurityDepositHeld: boolean;
   @Input() showOwnershipPercentage: boolean;
   @Input() allowOwnerToEmailPropertyManager: boolean;

   observableOwnershipDashboardModel: Observable<OwnershipDashboardModel>;
   ownershipDashboardModel: OwnershipDashboardModel;
   defaultDate: Date = new Date("0001-01-01T00:00:00");
   columns: string[] = ["PropertyName", "Ended", "Email"];
   isLoading = new Observable<boolean>();
   isViewMore: BehaviorSubject<boolean>;
   private _isLoading = new BehaviorSubject<boolean>(false);
   constructor(private ownershipService: OwnershipsService, private dialogCreationService: DialogCreationService) {
      this.isLoading = this._isLoading.asObservable();
      this.isViewMore = new BehaviorSubject<boolean>(false);
   }

   ngOnInit() {
      if (this.showReserve) {
         this.columns.push("ReserveAmount");
      }
      if (this.showOwnershipPercentage) {
         this.columns.push("OwnershipPercentage");
      }
      if (this.showSecurityDepositHeld) {
         this.columns.push("SecurityDepositHeld");
      }
      this.getDashboardData();
   }

   getDashboardData() {
      this._isLoading.next(true);
      this.observableOwnershipDashboardModel = this.ownershipService.getOwnershipsForDashboard();
      this.observableOwnershipDashboardModel.subscribe((res) => {
         this.ownershipDashboardModel = res;
         if (this.ownershipDashboardModel.Ownerships.length > 1) {
            this.isViewMore.next(true);
         } else {
            this.isViewMore.next(false);
         }
         this._isLoading.next(false);
      });
   }

   email(email: string, propertyID: number) {
      const emailConfigurationModel = new EmailConfigurationModel();
      const emailModel = new EmailModel();
      emailModel.To = email;
      emailConfigurationModel.Email = emailModel;
      emailModel.PropertyID = propertyID;

      const dialogConfigurationModel = new DialogConfigurationModel();
      dialogConfigurationModel.componentInstance = EmailComponent;
      dialogConfigurationModel.title = "Compose Email";
      this.dialogCreationService.open(dialogConfigurationModel, emailConfigurationModel);
   }
}
