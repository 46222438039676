import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { BillViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-view.model";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { BillsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/bills.service";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { VendorsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/vendors.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { BillDetailPreferences } from "../bill-detail/bill-detail-preferences.interface";
import { OWASessionService } from "../session/owa-session.service";

@Component({
   selector: "owa-bill-list",
   templateUrl: "./bill-list.component.html",
   styleUrls: ["./bill-list.component.css"],
   providers: [DataTableFiltersService],
})
export class BillListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   webUser: WebUserModel;

   columns: string[] = [
      "TransactionDate",
      "Amount",
      "DueDate",
      "Payee",
      "IsFullyAllocated",
      "PropertyName",
      "CheckNumber",
      "ServiceManagerIssueID",
   ];
   billFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   observableBills: Observable<Array<BillViewModel>>;

   appliedSideBarFilters: Array<FilterOption>;

   showFilters: boolean = false;
   vendors: Array<VendorModel>;
   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   threshold: string = "50%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;
   anyServiceIssues: boolean = false;

   private categoryID: number[];
   private hasCategory: boolean;
   private valueString: string;

   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _observableBills = new BehaviorSubject<Array<any>>(null);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);
   private unsubscribe = new Subject<void>();
   private preferences: BillDetailPreferences;

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setFullYear(new Date().getFullYear() - 1), "MM/dd/yy", "en-US");

   constructor(
      private billService: BillsService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService,
      private vendorService: VendorsService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.isInfiniteScrollLoading = this._isInfiniteScrollLoading.asObservable();
      this.observableBills = this._observableBills.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.vendors = new Array<VendorModel>();
      this.billFilters = this.getFilters();
      billService.preferences.subscribe((value) => {
         this.preferences = value;
      });
      this.setDefaultDateFilter();
   }

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.billFilters.find((f) => f.FilterOption.FilterName === "TransactionDate").FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.billFilters.find((f) => f.FilterOption.FilterName === "TransactionDate").FilterOption
      );
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.billService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               this.anyServiceIssues = false;
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const billList = response.body;
               const newBills = [];
               billList.forEach((element) => {
                  if (
                     element != null &&
                     element.ServiceManagerIssue != null &&
                     this.showLink(element.ServiceManagerIssue)
                  ) {
                     this.anyServiceIssues = true;
                  }
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                  newBills.push(element);
               });
               return newBills;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData = this._observableBills && this._observableBills.value ? this._observableBills.value : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._observableBills.next(newData);
               this.results.next(this._observableBills.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll($event): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      const results = this._observableBills.value ? this._observableBills.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || results.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true, refreshData);
      }
   }
   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }
   onClearFilters(): void {
      this.billFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.billFilters = this.resetFilter(this.billFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel,
      defaultFilterOperation?: FilterOperations
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.DataType = dataType;
      filter.ControlType = controlType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (defaultFilterOperation) {
         filter.FilterOption = new FilterOption(filterField, defaultFilterOperation, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   showLink(serviceManagerIssue: ServiceManagerIssueModel): boolean {
      let showLink = false;
      this.valueString = this.preferences.hasCategoriesPermission;
      this.categoryID = this.valueString.split(",").map((categoryID) => {
         return Number(categoryID);
      });

      if (serviceManagerIssue != null) {
         if (this.valueString == "-1") {
            this.hasCategory = true;
         } else {
            for (var i of this.categoryID) {
               if (i === serviceManagerIssue.CategoryID) {
                  this.hasCategory = true;
                  break;
               } else {
                  this.hasCategory = false;
               }
            }
         }

         if (this.preferences.showClosedIssues && this.preferences.showOpenIssues && this.hasCategory) {
            showLink = true;
         } else if (this.preferences.showClosedIssues && serviceManagerIssue.IsClosed && this.hasCategory) {
            showLink = true;
         } else if (this.preferences.showOpenIssues && !serviceManagerIssue.IsClosed && this.hasCategory) {
            showLink = true;
         } else {
            showLink = false;
         }
      }
      return showLink;
   }

   getFilters(): Array<DataTableFilterModel> {
      const billDateFilter = this.getDataTableFilterModel(
         "TransactionDate",
         "Bill Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between, FilterOperations.EqualTo],
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         FilterOperations.Between
      );

      const dueDateFilter = this.getDataTableFilterModel(
         "DueDate",
         "Due Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between, FilterOperations.EqualTo],
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         FilterOperations.Between
      );

      const isPaidFiltervalueSourceModel = new ValueSourceModel();
      isPaidFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      isPaidFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      isPaidFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "Paid & Unpaid")
      );
      isPaidFiltervalueSourceModel.StaticValues.push(new SelectorItemModel("true", "Paid"));
      isPaidFiltervalueSourceModel.StaticValues.push(new SelectorItemModel("false", "Unpaid"));

      const isPaidFilter = this.getDataTableFilterModel(
         "IsFullyAllocated",
         "Paid/Unpaid?",
         ExpressDataTypes.Boolean,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         isPaidFiltervalueSourceModel
      );

      const propertyFiltervalueSourceModel = new ValueSourceModel();
      propertyFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      propertyFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      propertyFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "All Ownerships")
      );
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });

      const propertyFilter = this.getDataTableFilterModel(
         "BillDetails.PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         propertyFiltervalueSourceModel
      );

      const vendorFilterValueSourceModel = new ValueSourceModel();
      vendorFilterValueSourceModel.Type = ValueSourceTypes.Static;
      vendorFilterValueSourceModel.StaticValues = new Array<SelectorItemModel>();
      vendorFilterValueSourceModel.StaticValues.push(new SelectorItemModel(ConstantsService.unselected, "All Vendors"));

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      const observableVendors = this.vendorService.getCollection(
         undefined,
         [
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new FilterOption("IsActive", FilterOperations.EqualTo, [true], null, null, null, ExpressDataTypes.Boolean),
         ],
         undefined,
         undefined,
         ["VendorID", "Name"]
      );

      const vendorFilter = this.getDataTableFilterModel(
         "AccountID",
         "Vendor",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         vendorFilterValueSourceModel
      );

      observableVendors.subscribe((res) => {
         this.vendors = res;
         vendorFilter.ValueSource = new ValueSourceModel();
         vendorFilter.ValueSource.Type = ValueSourceTypes.Static;
         vendorFilter.ValueSource.StaticValues = new Array<SelectorItemModel>();
         vendorFilter.ValueSource.StaticValues.push(new SelectorItemModel(ConstantsService.unselected, "All Vendors"));
         this.vendors.forEach((v) => {
            vendorFilter.ValueSource.StaticValues.push(new SelectorItemModel(v.VendorID, v.Name));
         });
      });

      const filterList = [billDateFilter, dueDateFilter, isPaidFilter, propertyFilter, vendorFilter];

      return filterList;
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];

      return filterOps;
   }

   // TODO: this function could be put into a service for all datatables to use,
   // or if there's ever some kind of generic owa-table it could go there.
   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
