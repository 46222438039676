import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, forkJoin, Subject, takeUntil } from "rxjs";

@Injectable()
export class SidebarMenuService implements OnDestroy {
   show: Subject<boolean> = new Subject<boolean>();

   showPage: Subject<any> = new Subject<any>();

   width: BehaviorSubject<number> = new BehaviorSubject<number>(20);

   units: BehaviorSubject<string> = new BehaviorSubject<string>("em");

   widthStyle: BehaviorSubject<string>;

   scrollBarOffset: BehaviorSubject<number> = new BehaviorSubject<number>(2);

   scrollerWidth: Subject<number>;

   scrollerWidthStyle: BehaviorSubject<string>;

   left: BehaviorSubject<number> = new BehaviorSubject<number>(-20);

   leftStyle: BehaviorSubject<string>;

   transitionStyle: BehaviorSubject<string>;

   transitionDuration: BehaviorSubject<number> = new BehaviorSubject<number>(200);

   rootIdentifier: any = "sidebar-root-menu-page";

   private unsubscribe = new Subject<void>();

   constructor() {
      this.scrollerWidth = new Subject<number>();
      this.scrollerWidthStyle = new BehaviorSubject<string>("");
      this.widthStyle = new BehaviorSubject<string>("");
      this.transitionStyle = new BehaviorSubject<string>("");
      this.leftStyle = new BehaviorSubject<string>("");

      forkJoin([this.width, this.units])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.recalculateWidthStyle();
         });
      this.recalculateWidthStyle();

      forkJoin([this.width, this.scrollBarOffset, this.units])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            this.recalculateScrollerWidth();
         });
      this.recalculateScrollerWidth();

      this.transitionDuration.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.recalculateTransitionStyle();
      });
      this.recalculateTransitionStyle();

      this.left.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.recalculateLeftStyle();
      });
      this.recalculateLeftStyle();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private recalculateScrollerWidth() {
      this.scrollerWidth.next(this.width.value + this.scrollBarOffset.value);
      this.scrollerWidthStyle.next(this.width.value + this.scrollBarOffset.value + this.units.value);
   }

   private recalculateWidthStyle() {
      this.widthStyle.next(this.width.value + this.units.value);
   }

   private recalculateLeftStyle() {
      this.leftStyle.next(this.left.value + this.units.value);
   }

   private recalculateTransitionStyle() {
      this.transitionStyle.next(`all ${this.transitionDuration.value / 1000}s ease-in-out`);
   }
}
