import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

export class UserDefinedValueFilterDefinitionModel {
   userDefinedFieldID: number;

   entityType: EntityType;

   label: string;

   filterOption: FilterOption;

   fieldType: UserDefinedFieldType;

   valueSource: ValueSourceModel;
}
