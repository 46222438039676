import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ReportParameterRendererComponentBase } from "@lcs/reports/report-parameters/report-parameter-override-component.base";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ReportParameterValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-source.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { filter, Subject, takeUntil } from "rxjs";

import { isAllValueType } from "../all-value.type";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterFiltersService } from "../report-parameter-filters.service";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";
import { SelectorReportParameterService } from "./selector-report-parameter.service";

@Component({
   selector: "lcs-dynamic-selector-report-parameter",
   templateUrl: "selector-report-parameter.component.html",
   providers: [ReportParameterFiltersService, ReportParameterControlStatusService, SelectorReportParameterService],
   viewProviders: [ControlContainerViewProvider],
})
export class SelectorReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() hideLabel: boolean;

   @Input() entityValueSourceFilters = new Array<FilterOption>();

   @Input() entityValueSourceFilterExpression: FilterExpression;

   @Input() parameter: ReportReportParameterViewModel;

   @Input() selectedValues: Array<any>;

   @Input() hasAsk: boolean;

   @Input() selectAllItemsOnFilterChange: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   valueSource: ValueSourceModel;

   selectionChange: SelectionChangeModel;

   private valueInitialized = false;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterFilterService: ReportParameterFiltersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService,
      private selectorReportParameterService: SelectorReportParameterService
   ) {
      this.selectedValues = new Array<any>();
   }

   ngOnInit(): void {
      if (
         this.selectorReportParameterService.isEnumReportParameterValueSource(this.parameter.ReportParameterValueSource)
      ) {
         this.parameter.ReportParameterValueSource.DefaultValue =
            this.selectorReportParameterService.getValidDefaultValueForReportParameterValueSourceWithEnumValueSource(
               this.parameter.ReportParameterValueSource
            );
      }

      const reportParameterValueSource: ReportParameterValueSourceModel = this.parameter.ReportParameterValueSource;
      this.initializeReportParameterValue();

      this.initializeValidation(reportParameterValueSource.IsRequired);

      if (this.selectorReportParameterService.isEntityReportParameterValueSource(reportParameterValueSource)) {
         this.adjustReportParameterValuesSourceDefaultValueIfNecessary(reportParameterValueSource);
         this.initializeEntityValueSourceFiltersOrFilterExpression();
      }

      if (this.reportParameterValueModel.value?.length) {
         // if reportParameterValueModel has value set, override reportParameterValueSource.DefaultValue
         reportParameterValueSource.DefaultValue = this.reportParameterValueModel.value;
      }

      this.valueSource =
         this.selectorReportParameterService.createValueSourceFromReportParameterValueSource(
            reportParameterValueSource
         );

      this.selectedValues = this.valueSource.SelectedValues;

      this.updateReportParameterValueWithParenWrappedDefaultIfNecessary(
         this.valueSource.DefaultValue,
         this.valueSource.AllowsMultipleValues
      );

      if (!this.displayName && this.parameter) {
         this.displayName = ReportParameterRendererComponentBase.getDisplayNameFromReportReportParameter(
            this.parameter.toReportReportParameterModel()
         );
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel): void {
      const checkedItems = selectionChangeModel.checkedItems.filter((c) => c.value != null && c.value !== "");
      let values = new Array<any>();
      const selectAll: boolean = selectionChangeModel.selectAll;
      this.reportParameterValueModel.value = "";
      if (!this.parameter.ReportParameterValueSource.AllowsMultipleValues) {
         if (checkedItems.length === 1 && checkedItems[0].value != null) {
            this.reportParameterValueModel.value = checkedItems[0].value;
            values = [this.reportParameterValueModel.value];
         }
      } else {
         const allValue: string = this.parameter.ReportParameterValueSource.AllValue;
         if (selectAll && allValue) {
            this.reportParameterValueModel.value = allValue;
            values = [allValue];
         } else if (checkedItems.length > 0) {
            values = checkedItems.map((f) => f.value);
            this.reportParameterValueModel.value = "(" + values.join(",") + ")";
         } else {
            this.reportParameterValueModel.value = "";
         }
      }
      this.reportParameterValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean): void {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   onProcessValueItems(items: Array<SelectorItemModel>): void {
      const selectedValues = items.filter((i) => i.isChecked).map((i) => i.value);
      const isAll =
         selectedValues.length > 0 &&
         items.length === selectedValues.length &&
         this.parameter.ReportParameterValueSource.AllowsSelectAll;

      const selectionChange = new SelectionChangeModel();
      selectionChange.selectAll = isAll;
      selectionChange.items = items;
      selectionChange.checkedItems = items.filter((i) => i.isChecked);
      this.selectionChanged(selectionChange);
      this.selectedValues = this.reportParameterValueModel.rawValues;
   }

   /** Initialize validation property */
   private initializeValidation(isRequired: boolean): void {
      if (isRequired) {
         if (this.validation) {
            this.validation.required = true;
         } else {
            const validation = new ValidationModel();
            validation.required = true;
            validation.dataType = ExpressDataTypes.Key;
            this.validation = validation;
         }
      }
   }

   private initializeEntityValueSourceFiltersOrFilterExpression(): void {
      // if neither entityValueSourceFilterExpression nor entityValueSourceFilters are set via inputs
      // then we init via reportParameterFilterService.
      // NOTE: We do NOT set the valueSourceModel.Filters for entitySources they are filtered only
      //       via the entityValueSourceFilterExpression or entityValueSourceFilters properties
      if (
         !this.entityValueSourceFilterExpression &&
         !(this.entityValueSourceFilters && this.entityValueSourceFilters.length > 0)
      ) {
         this.entityValueSourceFilterExpression =
            this.reportParameterFilterService.getInitialEntityValueSourceFilterExpression(
               this.parameter.ReportID,
               this.reportParameterValueModel.reportParameter,
               this.reportParametersService.reportParameterValues
            );
         if (!this.entityValueSourceFilterExpression) {
            const entityValueSourceFilters = this.reportParameterFilterService.getInitialEntityValueSourceFilters(
               this.parameter.ReportID,
               this.reportParameterValueModel.reportParameter,
               this.reportParametersService.reportParameterValues
            );
            if (entityValueSourceFilters) {
               this.entityValueSourceFilters = entityValueSourceFilters;
            }
         }
      }
   }

   /**
    * This compensates for the BL claiming that the default value is zero for many report parameters that are required
    * we need to reset it to null to make required validation work any reportParameter that actually
    * has a default value of zero needs to be left alone
    */
   private adjustReportParameterValuesSourceDefaultValueIfNecessary(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): void {
      if (
         reportParameterValueSource.DefaultValue === "0" &&
         !this.reportParametersService.reportParameterHasZeroAsDefaultValue(this.parameter.ReportParameterID)
      ) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
         reportParameterValueSource.DefaultValue = null;
      }
   }

   private initializeReportParameterValue(): void {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === this.parameter.ReportParameterID) {
               this.reportParameterValuesUpdated(updatedValue);
            }
            this.updateReportParameterValueFilters(updatedValue);
         });

      this.reportParametersService.reportParameterStatusChanged
         .pipe(
            filter((updatedValue) => updatedValue.reportParameter === this.parameter.ReportParameterID),
            takeUntil(this.unsubscribe)
         )
         .subscribe((status) => {
            this.disabled = status.disabled;
         });
   }

   private updateReportParameterValueWithParenWrappedDefaultIfNecessary(
      defaultValue: string | null,
      allowsMultipleValues: boolean
   ): void {
      if (typeof defaultValue === "string" && allowsMultipleValues && !isAllValueType(defaultValue)) {
         if (!defaultValue.includes("(")) {
            this.reportParameterValueModel.value = "(" + defaultValue + ")";
            this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
         }
      }
   }

   private reportParameterValuesUpdated(updatedValue: ReportParameterValueModel): void {
      let valueChanged = false;
      let updatedRawValues = updatedValue.rawValues;
      if (updatedRawValues == null) {
         updatedRawValues = new Array<any>();
      }
      if (updatedRawValues.length !== this.selectedValues.length && updatedRawValues.length !== 0) {
         valueChanged = true;
      } else {
         for (const value of updatedRawValues) {
            if (this.selectedValues.indexOf(value) === ConstantsService.ArrayIndexNotFound) {
               valueChanged = true;
               break;
            }
         }
      }
      if (!valueChanged) {
         return;
      }
      this.selectedValues = updatedRawValues;
   }

   private updateReportParameterValueFilters(updatedValue: ReportParameterValueModel): void {
      const entityValueSourceFilterExpression =
         this.reportParameterFilterService.getUpdatedEntityValueSourceFilterExpression(
            this.parameter.ReportID,
            this.reportParameterValueModel.reportParameter,
            updatedValue,
            this.reportParametersService.reportParameterValues
         );

      if (entityValueSourceFilterExpression) {
         this.entityValueSourceFilterExpression = entityValueSourceFilterExpression;
      }

      if (!this.entityValueSourceFilterExpression) {
         const entityValueSourceFilters = this.reportParameterFilterService.getUpdatedEntityValueSourceFilters(
            this.parameter.ReportID,
            this.reportParameterValueModel.reportParameter,
            updatedValue,
            this.reportParametersService.reportParameterValues
         );
         if (entityValueSourceFilters) {
            this.entityValueSourceFilters = entityValueSourceFilters;
         }
      }

      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.parameter.ReportID,
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }

      // clear values
      const clearValues = this.reportParameterControlStatusService.ClearValues(
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (clearValues) {
         if (this.hasAsk && !this.valueInitialized) {
            this.valueInitialized = true;
            return;
         }
         this.selectedValues = [];
      }
   }
}
