import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { AccountingPeriodYearEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/accounting-period-year.embed-options";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountingPeriodSeriesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period-series.model";
import { AccountingPeriodYearModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period-year.model";
import { AccountingPeriodModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period.model";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { AccountingPeriodSeriesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/accounting-period-series.service";
import { AccountingPeriodYearsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/accounting-period-years.service";
import { debounceTime, Subject, takeUntil } from "rxjs";

import { RelativeDatesService } from "../../../inputs/date-picker/relative-dates.service";
import { ReportParameterComponents } from "../../../reports/report-parameters/report-parameter-components.enum";
import { CurrentSystemPreferencesService } from "../../../session/current-system-preferences.service";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { RelativeDateValueModel } from "../relative-date-picker/relative-date-value.model";
import { ReportParametersService } from "../report-parameters.service";
import { RelativePeriodOptions } from "./relative-period-options.enum";
import { RelativePeriodsService } from "./relative-periods.service";

@Component({
   selector: "lcs-date-and-accounting-period-report-parameter",
   templateUrl: "date-and-accounting-period-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class DateAndAccountingPeriodReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() showRelativeOption: boolean;

   @Input() hasAsk: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this.reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() dateReportParameterType: ReportParameterComponents;

   @Input() asOfDateReportParameterId: ReportParameter;

   @Input() accountingPeriodReportParameterId: ReportParameter;

   @Input() runByPeriodReportParameterId: ReportParameter;

   @ViewChild("yearSelector") yearSelector: SelectComponent;

   @ViewChild("periodSelector") periodSelector: SelectComponent;

   asOfDateReportParameter: ReportReportParameterViewModel;

   relativeDateValue: RelativeDateValueModel;

   asOfDateReportParameterValueModel: ReportParameterValueModel;

   disableRelativeDates: boolean;

   isRunByPeriod: boolean;

   selectedSeriesID: number;

   seriesData: SelectorItemModel[];

   yearData: SelectorItemModel[];

   selectedYearID: number;

   selectedPeriodID: number;

   periodData: SelectorItemModel[];

   relativePeriodOptionsData: SelectorItemModel[];

   selectedRelativePeriodOptionID: RelativePeriodOptions;

   relativePeriodSelected: boolean;

   uniqueId: number;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private updateParameterValue = new Subject<void>();

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private currentSystemPreferenceService: CurrentSystemPreferencesService,
      private accountingPeriodSeriesService: AccountingPeriodSeriesService,
      private accountingPeriodYearsService: AccountingPeriodYearsService,
      private uniqueIDService: UniqueIDService
   ) {
      this.uniqueId = this.uniqueIDService.getUniqueID();
   }

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
      this.updateParameterValue.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(() => {
         if (this.relativeDateValue) {
            if (this.showRelativeOption && this.relativeDateValue.relativeDateOption) {
               this.asOfDateReportParameterValueModel.value = RelativeDatesService.relativeDateEnumMap().get(
                  this.relativeDateValue.relativeDateOption
               );
            } else if (this.relativeDateValue.dateValue) {
               this.asOfDateReportParameterValueModel.value = formatDate(
                  this.relativeDateValue.dateValue,
                  "MM/dd/yyyy",
                  GlobalsService.locale
               );
            } else {
               this.asOfDateReportParameterValueModel.value = "";
            }
         } else {
            this.asOfDateReportParameterValueModel.value = "";
         }
         this.reportParametersService.updateReportParameterValue(this.asOfDateReportParameterValueModel);

         if (this.showRelativeOption && this.relativePeriodSelected) {
            const enumMap = RelativePeriodsService.relativePeriodEnumMap();
            const parameterValue =
               this.selectedRelativePeriodOptionID + ":" + enumMap.get(this.selectedRelativePeriodOptionID);
            this.reportParametersService.updateReportParameterValue(
               new ReportParameterValueModel(this.accountingPeriodReportParameterId, parameterValue)
            );
         } else {
            this.reportParametersService.updateReportParameterValue(
               new ReportParameterValueModel(this.accountingPeriodReportParameterId, this.selectedPeriodID)
            );
         }
         this.reportParametersService.updateReportParameterValue(
            new ReportParameterValueModel(this.runByPeriodReportParameterId, this.isRunByPeriod)
         );
      });
      this.fillPeriodOptionsData();
      this.currentSystemPreferenceService
         .getSystemPreferences([
            SystemPreference.AccountingPeriodEnable,
            SystemPreference.AccountingPeriodDefaultForReports,
         ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((pref: Map<SystemPreference, SystemPreferenceModel>) => {
            if (
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               GlobalsService.convertStringToBoolean(pref.get(SystemPreference.AccountingPeriodEnable).Value) &&
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               GlobalsService.convertStringToBoolean(pref.get(SystemPreference.AccountingPeriodDefaultForReports).Value)
            ) {
               this.isRunByPeriod = true;
            }
         });
      this.loadSeriesData();

      if (this.validation) {
         this.validation.required = true;
      } else {
         const validation = new ValidationModel();
         validation.required = true;
         validation.dataType = ExpressDataTypes.Key;
         this.validation = validation;
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   isRunByPeriodChanged(runByPeriod) {
      this.isRunByPeriod = runByPeriod;
      this.updateParameterValue.next();
   }

   updateRelativeDatePicker(value: RelativeDateValueModel) {
      this.relativeDateValue = value;
      this.updateParameterValue.next();
   }

   askChanged(isAsk: boolean) {
      this.asOfDateReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.asOfDateReportParameterId, isAsk);
      this.reportParametersService.updateReportParameterAsk(this.accountingPeriodReportParameterId, isAsk);
      this.reportParametersService.updateReportParameterAsk(this.runByPeriodReportParameterId, isAsk);
   }

   onAccountingSeriesChange(seriesId: number) {
      this.selectedSeriesID = seriesId;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedYearID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedPeriodID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'SelectorIte... Remove this comment to see the full error message
      this.periodData = null;
      this.setYearData(false);
   }

   onAccountingYearChange(yearId: number) {
      this.selectedYearID = yearId;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedPeriodID = null;
      this.setPeriodData(this.yearSelector.selectedItem.additionalData.get("AccountingPeriods"), false);
   }

   onAccountingPeriodChange(periodId: number) {
      this.selectedPeriodID = periodId;
      if (
         this.accountingPeriodReportParameterId === ReportParameter.StartPeriodID ||
         this.accountingPeriodReportParameterId === ReportParameter.StartPeriodID2
      ) {
         this.relativeDateValue.dateValue = this.periodSelector.selectedItem.additionalData.get("StartDate");
      } else {
         this.relativeDateValue.dateValue = this.periodSelector.selectedItem.additionalData.get("EndDate");
      }

      this.updateParameterValue.next();
   }

   onRelativePeriodChanged(optionId: number) {
      this.selectedRelativePeriodOptionID = optionId;
      this.updateParameterValue.next();
   }

   onRelativePeriodCheckChanged(isCheck: boolean) {
      this.relativePeriodSelected = isCheck;
      this.updateParameterValue.next();
   }

   private initializeReportParameterValues() {
      const runByPeriodParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.runByPeriodReportParameterId
      );
      if (runByPeriodParameterValueModel) {
         this.isRunByPeriod = GlobalsService.convertStringToBoolean(runByPeriodParameterValueModel.value);
      }

      const relativeDateValue = new RelativeDateValueModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.asOfDateReportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.asOfDateReportParameterId
      );

      if (
         this.showRelativeOption &&
         RelativeDatesService.IsRelativeDateValue(this.asOfDateReportParameterValueModel.value)
      ) {
         relativeDateValue.relativeDateOption = RelativeDatesService.getEnumValue(
            this.asOfDateReportParameterValueModel.value
         );
         relativeDateValue.dateValue = RelativeDatesService.getRelativeDate(relativeDateValue.relativeDateOption);
      } else {
         const dateValue = new Date(this.asOfDateReportParameterValueModel.value);
         if (!isNaN(dateValue.getTime())) {
            relativeDateValue.dateValue = dateValue;
         }
      }
      this.relativeDateValue = relativeDateValue;

      const periodReportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.accountingPeriodReportParameterId
      );

      if (periodReportParameterValueModel) {
         if (
            this.showRelativeOption &&
            RelativePeriodsService.IsRelativePeriodValue(periodReportParameterValueModel.value)
         ) {
            this.relativePeriodSelected = true;

            this.selectedRelativePeriodOptionID = RelativePeriodsService.getEnumValue(
               periodReportParameterValueModel.value
            );
         } else {
            this.relativePeriodSelected = false;
            const filterOptions = new Array<FilterOption>();
            filterOptions.push(
               new FilterOption(
                  "AccountingPeriods.AccountingPeriodId",
                  FilterOperations.EqualTo,
                  periodReportParameterValueModel.value
               )
            );
            this.accountingPeriodYearsService
               .getCollection(filterOptions, [
                  AccountingPeriodYearEmbedOptions.AccountingPeriodSeries,
                  AccountingPeriodYearEmbedOptions.AccountingPeriods,
               ])
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((accountingPeriodYearModels: AccountingPeriodYearModel[]) => {
                  if (accountingPeriodYearModels && accountingPeriodYearModels.length === 1) {
                     this.selectedSeriesID = accountingPeriodYearModels[0].AccountingPeriodSeriesID;
                     this.selectedYearID = accountingPeriodYearModels[0].AccountingPeriodYearID;
                     this.selectedPeriodID = periodReportParameterValueModel.value;
                     this.setYearData(false);
                  }
               });
         }
      }
   }

   private initializeParameters() {
      if (this.dateReportParameterType === ReportParameterComponents.AsOfDateWithAccountingPeriod2) {
         this.asOfDateReportParameterId = ReportParameter.AsOfDate2;
         this.accountingPeriodReportParameterId = ReportParameter.AsOfPeriodID2;
         this.runByPeriodReportParameterId = ReportParameter.RunByPeriod2;
      } else if (this.dateReportParameterType === ReportParameterComponents.AsOfDateWithAccountingPeriod) {
         this.asOfDateReportParameterId = ReportParameter.AsOfDate;
         this.accountingPeriodReportParameterId = ReportParameter.AsOfPeriodID;
         this.runByPeriodReportParameterId = ReportParameter.RunByPeriod;
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.asOfDateReportParameter = this.reportReportParameters.find(
         (x) => x.ReportParameterID === this.asOfDateReportParameterId
      );
   }

   private loadSeriesData() {
      this.accountingPeriodSeriesService
         .getCollection()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((seriesModels: AccountingPeriodSeriesModel[]) => {
            this.seriesData = seriesModels.map(
               (model) => new SelectorItemModel(model.AccountingPeriodSeriesID, model.Name)
            );
            const selectedSeries = this.selectedSeriesID
               ? seriesModels.find((x) => x.AccountingPeriodSeriesID === this.selectedSeriesID)
               : seriesModels.find((x) => x.IsDefault);
            if (selectedSeries) {
               this.selectedSeriesID = selectedSeries.AccountingPeriodSeriesID;
               this.setYearData(true);
            }
         });
   }

   private setYearData(selectDefaultYear: boolean) {
      const filterOption = new Array<FilterOption>();
      filterOption.push(
         new FilterOption("AccountingPeriodSeriesID", FilterOperations.EqualTo, [this.selectedSeriesID])
      );
      this.accountingPeriodYearsService
         .getCollection(filterOption, [AccountingPeriodYearEmbedOptions.AccountingPeriods])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((yearsModels: AccountingPeriodYearModel[]) => {
            this.yearData = yearsModels.map((model) => {
               const yearModel = new SelectorItemModel(model.AccountingPeriodYearID, model.Name);
               yearModel.additionalData.set("AccountingPeriods", model.AccountingPeriods);
               yearModel.additionalData.set("StartDate", new Date(model.StartDate));
               yearModel.additionalData.set("EndDate", new Date(model.EndDate));
               return yearModel;
            });

            let selectedYearModel: AccountingPeriodYearModel;
            if (yearsModels.find((x) => x.AccountingPeriodYearID === this.selectedYearID)) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'AccountingPeriodYearModel | undefined' is no... Remove this comment to see the full error message
               selectedYearModel = yearsModels.find((x) => x.AccountingPeriodYearID === this.selectedYearID);
            } else if (selectDefaultYear) {
               const now = new Date();
               now.setHours(0, 0, 0, 0);
               // @ts-ignore ts-migrate(2322) FIXME: Type 'AccountingPeriodYearModel | undefined' is no... Remove this comment to see the full error message
               selectedYearModel = yearsModels.find((x) => {
                  const startDate = new Date(x.StartDate);
                  const endDate = new Date(x.EndDate);
                  return startDate.getTime() <= now.getTime() && now.getTime() <= endDate.getTime();
               });
            }

            // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedYearModel' is used before being ... Remove this comment to see the full error message
            if (selectedYearModel) {
               this.selectedYearID = selectedYearModel.AccountingPeriodYearID;
               this.setPeriodData(selectedYearModel.AccountingPeriods, true);
            }
         });
   }

   private setPeriodData(accountingPeriodModels: AccountingPeriodModel[], selectDefaultPeriod: boolean) {
      this.periodData = accountingPeriodModels.map((model: AccountingPeriodModel) => {
         const selectorModel = new SelectorItemModel(model.AccountingPeriodID, model.Name);
         selectorModel.additionalData.set("StartDate", new Date(model.StartDate));
         selectorModel.additionalData.set("EndDate", new Date(model.EndDate));
         return selectorModel;
      });

      let selectedPeriodModel: AccountingPeriodModel;
      if (accountingPeriodModels.find((x) => x.AccountingPeriodID === this.selectedPeriodID)) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'AccountingPeriodModel | undefined' is not as... Remove this comment to see the full error message
         selectedPeriodModel = accountingPeriodModels.find((x) => x.AccountingPeriodID === this.selectedPeriodID);
      } else if (selectDefaultPeriod) {
         const now = new Date();
         now.setHours(0, 0, 0, 0);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'AccountingPeriodModel | undefined' is not as... Remove this comment to see the full error message
         selectedPeriodModel = accountingPeriodModels.find((x) => {
            const startDate = new Date(x.StartDate);
            const endDate = new Date(x.EndDate);
            return startDate.getTime() <= now.getTime() && now.getTime() <= endDate.getTime();
         });
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedPeriodModel' is used before bein... Remove this comment to see the full error message
      if (selectedPeriodModel) {
         this.selectedPeriodID = selectedPeriodModel.AccountingPeriodID;
         if (
            this.accountingPeriodReportParameterId === ReportParameter.StartPeriodID ||
            this.accountingPeriodReportParameterId === ReportParameter.StartPeriodID2
         ) {
            this.relativeDateValue.dateValue = new Date(selectedPeriodModel.StartDate);
         } else {
            this.relativeDateValue.dateValue = new Date(selectedPeriodModel.EndDate);
         }
         this.updateParameterValue.next();
      }
   }

   private fillPeriodOptionsData() {
      const source = new Array<SelectorItemModel>();
      const enumMap = RelativePeriodsService.relativePeriodEnumMap();
      const mappedKeys = Array.from(enumMap.keys());
      mappedKeys.forEach((key) => {
         const selectorItem = new SelectorItemModel();
         selectorItem.value = key;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
         selectorItem.displayValue = enumMap.get(key);
         source.push(selectorItem);
      });
      this.relativePeriodOptionsData = source;
   }
}
