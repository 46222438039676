import { CdkColumnDef, CdkFooterCell } from "@angular/cdk/table";
import { Directive, ElementRef, HostBinding } from "@angular/core";

@Directive({
   // eslint-disable-next-line @angular-eslint/directive-selector
   selector: "lcs-footer-cell, td[lcs-footer-cell]",
})
export class LcsFooterCellDirective extends CdkFooterCell {
   @HostBinding("class.lcs-footer-cell") cellClass: boolean = true;

   @HostBinding("attr.role") role: string = "gridcell";

   constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
      super(columnDef, elementRef);
      elementRef.nativeElement.classList.add(`lcs-column-${columnDef.cssClassFriendlyName}`);
   }
}
