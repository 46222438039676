import { DateRangePickerOption } from "@lcs/inputs/date-range-picker/date-range-picker-options.enum";
import { DateRangeModel } from "@lcs/inputs/date-range-picker/date-range.model";
import { DatesService } from "@lcs/utils/dates.service";

import { RelativeDateOptions } from "./relative-date-options.enum";

export class RelativeDatesService {
   static relativeDateValueMap = new Map<string, RelativeDateOptions>([
      ["Today", RelativeDateOptions.Today],
      ["Tomorrow", RelativeDateOptions.Tomorrow],
      ["Yesterday", RelativeDateOptions.Yesterday],
      ["One Week Ago", RelativeDateOptions.OneWeekAgo],
      ["One Month Ago", RelativeDateOptions.OneMonthAgo],
      ["One Year Ago", RelativeDateOptions.OneYearAgo],
      ["Last Week Start", RelativeDateOptions.LastWeekStart],
      ["Last Month Start", RelativeDateOptions.LastMonthStart],
      ["Last Quarter Start", RelativeDateOptions.LastQuarterStart],
      ["Last Year Start", RelativeDateOptions.LastYearStart],
      ["Current Week Start", RelativeDateOptions.CurrentWeekStart],
      ["Current Month Start", RelativeDateOptions.CurrentMonthStart],
      ["Current Quarter Start", RelativeDateOptions.CurrentQuarterStart],
      ["Current Year Start", RelativeDateOptions.CurrentYearStart],
      ["Next Week Start", RelativeDateOptions.NextWeekStart],
      ["Next Month Start", RelativeDateOptions.NextMonthStart],
      ["Next Quarter Start", RelativeDateOptions.NextQuarterStart],
      ["Next Year Start", RelativeDateOptions.NextYearStart],
      ["Last Week End", RelativeDateOptions.LastWeekEnd],
      ["Last Month End", RelativeDateOptions.LastMonthEnd],
      ["Last Quarter End", RelativeDateOptions.LastQuarterEnd],
      ["Last Year End", RelativeDateOptions.LastYearEnd],
      ["Current Week End", RelativeDateOptions.CurrentWeekEnd],
      ["Current Month End", RelativeDateOptions.CurrentMonthEnd],
      ["Current Quarter End", RelativeDateOptions.CurrentQuarterEnd],
      ["Current Year End", RelativeDateOptions.CurrentYearEnd],
      ["Next Week End", RelativeDateOptions.NextWeekEnd],
      ["Next Month End", RelativeDateOptions.NextMonthEnd],
      ["Next Quarter End", RelativeDateOptions.NextQuarterEnd],
      ["Next Year End", RelativeDateOptions.NextYearEnd],
   ]);

   static relativeDateEnumMap(): Map<RelativeDateOptions, string> {
      const relativeDateValueEnumMap = new Map<RelativeDateOptions, string>();
      for (const key of Array.from(RelativeDatesService.relativeDateValueMap.keys())) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativeDateOptions | undefined'... Remove this comment to see the full error message
         relativeDateValueEnumMap.set(RelativeDatesService.relativeDateValueMap.get(key), key);
      }
      return relativeDateValueEnumMap;
   }

   static IsRelativeDateValue(relativeDateValue: string): boolean {
      return RelativeDatesService.relativeDateValueMap.has(relativeDateValue);
   }

   static getRelativeDate(relativeDate: RelativeDateOptions): Date {
      let date = new Date();
      const dayInMonth = date.getDate();
      const dayInWeek = date.getDay();
      let month = date.getMonth();
      let year = date.getFullYear();
      switch (relativeDate) {
         case RelativeDateOptions.Today:
            return date;

         case RelativeDateOptions.Tomorrow:
            date.setDate(dayInMonth + 1);
            return date;

         case RelativeDateOptions.Yesterday:
            date.setDate(dayInMonth - 1);
            return date;

         case RelativeDateOptions.OneWeekAgo:
            date.setDate(dayInMonth - 7);
            return date;

         case RelativeDateOptions.OneMonthAgo:
            date.setMonth(month - 1);
            return date;

         case RelativeDateOptions.OneYearAgo:
            date.setFullYear(year - 1);
            return date;

         case RelativeDateOptions.LastWeekStart:
            date.setDate(dayInMonth - 7 - dayInWeek);
            return date;

         case RelativeDateOptions.LastMonthStart:
            date.setMonth(month - 1);
            date.setDate(1);
            return date;

         case RelativeDateOptions.LastQuarterStart:
            month -= 3;
            if (month < 0) {
               month += 12;
               year--;
            }
            return DatesService.getBeginningOfQuarter(DatesService.getQuarterFromMonth(month), year);

         case RelativeDateOptions.LastYearStart:
            return new Date(year - 1, 0, 1);

         case RelativeDateOptions.CurrentWeekStart:
            date.setDate(dayInMonth - dayInWeek);
            return date;

         case RelativeDateOptions.CurrentMonthStart:
            return new Date(year, month, 1);

         case RelativeDateOptions.CurrentQuarterStart:
            return DatesService.getBeginningOfQuarter(DatesService.getQuarterFromMonth(month), year);

         case RelativeDateOptions.CurrentYearStart:
            return new Date(year, 0, 1);

         case RelativeDateOptions.NextWeekStart:
            date.setDate(dayInMonth + 7 - dayInWeek);
            return date;

         case RelativeDateOptions.NextMonthStart:
            date.setMonth(month + 1);
            date.setDate(1);
            return date;

         case RelativeDateOptions.NextQuarterStart:
            month += 3;
            if (month > 11) {
               month -= 12;
               year++;
            }
            return DatesService.getBeginningOfQuarter(
               DatesService.getQuarterFromMonth(month),
               month > 11 ? year + 1 : year
            );

         case RelativeDateOptions.NextYearStart:
            return new Date(year + 1, 0, 1);

         case RelativeDateOptions.LastWeekEnd:
            date.setDate(dayInMonth - 7);
            date.setDate(date.getDate() + (7 - dayInWeek) - 1);
            return date;

         case RelativeDateOptions.LastMonthEnd:
            date.setMonth(month);
            date.setDate(0);
            return date;

         case RelativeDateOptions.LastQuarterEnd:
            date = DatesService.getBeginningOfQuarter(DatesService.getQuarterFromMonth(month), year);
            date.setDate(0);
            return date;

         case RelativeDateOptions.LastYearEnd:
            return new Date(year - 1, 12, 0);

         case RelativeDateOptions.CurrentWeekEnd:
            date.setDate(dayInMonth + (7 - dayInWeek) - 1);
            return date;

         case RelativeDateOptions.CurrentMonthEnd:
            return new Date(year, month + 1, 0);

         case RelativeDateOptions.CurrentQuarterEnd:
            month += 3;
            if (month > 11) {
               month -= 12;
               year++;
            }
            date = DatesService.getBeginningOfQuarter(DatesService.getQuarterFromMonth(month), year);
            date.setDate(0);
            return date;

         case RelativeDateOptions.CurrentYearEnd:
            return new Date(year, 12, 0);

         case RelativeDateOptions.NextWeekEnd:
            date.setDate(dayInMonth + 7);
            date.setDate(date.getDate() + (7 - dayInWeek) - 1);
            return date;

         case RelativeDateOptions.NextMonthEnd:
            date.setMonth(month + 2);
            date.setDate(0);
            return date;

         case RelativeDateOptions.NextQuarterEnd:
            month += 6;
            if (month > 11) {
               month -= 12;
               year++;
            }
            date = DatesService.getBeginningOfQuarter(
               DatesService.getQuarterFromMonth(month),
               month > 11 ? year + 1 : year
            );
            date.setDate(0);
            return date;

         case RelativeDateOptions.NextYearEnd:
            return new Date(year + 1, 12, 0);
      }
   }

   static getRelativeDateRange(
      dateRangePickerOption: DateRangePickerOption,
      startDate?: Date,
      endDate?: Date
   ): DateRangeModel {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
      const selStartDate: Date = startDate;
      let tempDateStartDate = new Date();
      if (selStartDate) {
         tempDateStartDate = new Date(selStartDate.valueOf());
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
      const selEndDate: Date = endDate;
      let tempDateEndDate = new Date();
      if (selEndDate) {
         tempDateEndDate = new Date(selEndDate.valueOf());
      }

      const dateRangeModel = new DateRangeModel();

      switch (dateRangePickerOption) {
         case DateRangePickerOption.PrevDay:
            tempDateStartDate.setDate(tempDateStartDate.getDate() - 1);
            tempDateEndDate.setDate(tempDateEndDate.getDate() - 1);
            dateRangeModel.startDate = new Date(tempDateStartDate.valueOf());
            dateRangeModel.endDate = new Date(tempDateEndDate.valueOf());
            break;
         case DateRangePickerOption.Today:
            dateRangeModel.endDate = new Date();
            dateRangeModel.startDate = new Date();
            break;
         case DateRangePickerOption.NextDay:
            tempDateStartDate.setDate(tempDateStartDate.getDate() + 1);
            tempDateEndDate.setDate(tempDateEndDate.getDate() + 1);
            dateRangeModel.startDate = new Date(tempDateStartDate.valueOf());
            dateRangeModel.endDate = new Date(tempDateEndDate.valueOf());
            break;
         case DateRangePickerOption.PrevWeek:
            const lwLastSunday = new Date(tempDateStartDate.valueOf());
            lwLastSunday.setDate(lwLastSunday.getDate() - 7);
            lwLastSunday.setDate(lwLastSunday.getDate() - ((0 + lwLastSunday.getDay()) % 7));
            dateRangeModel.startDate = lwLastSunday;

            const lwNextSaturday = new Date(lwLastSunday.valueOf());
            lwNextSaturday.setDate(lwLastSunday.getDate() + 6);
            dateRangeModel.endDate = lwNextSaturday;
            break;
         case DateRangePickerOption.ThisWeek:
            const twPrevSunday = new Date();
            twPrevSunday.setDate(twPrevSunday.getDate() - ((0 + twPrevSunday.getDay()) % 7));
            dateRangeModel.startDate = twPrevSunday;

            const twNextSaturday = new Date(twPrevSunday.valueOf());
            twNextSaturday.setDate(twNextSaturday.getDate() + 6);
            dateRangeModel.endDate = twNextSaturday;
            break;
         case DateRangePickerOption.WeekToDate:
            const wtdPrevSunday = new Date();
            wtdPrevSunday.setDate(wtdPrevSunday.getDate() - ((0 + wtdPrevSunday.getDay()) % 7));
            dateRangeModel.startDate = wtdPrevSunday;
            dateRangeModel.endDate = new Date();
            break;
         case DateRangePickerOption.NextWeek:
            const nwLastSunday = new Date(tempDateStartDate.valueOf());
            nwLastSunday.setDate(nwLastSunday.getDate() + 7);
            nwLastSunday.setDate(nwLastSunday.getDate() - ((0 + nwLastSunday.getDay()) % 7));
            dateRangeModel.startDate = nwLastSunday;

            const nwNextSaturday = new Date(nwLastSunday.valueOf());
            nwNextSaturday.setDate(nwNextSaturday.getDate() + 6);
            dateRangeModel.endDate = nwNextSaturday;
            break;
         case DateRangePickerOption.PrevMonth:
            const pvStartDate = new Date(tempDateStartDate.valueOf());
            pvStartDate.setMonth(pvStartDate.getMonth() - 1);
            const pmFirstDay = new Date(pvStartDate.getFullYear(), pvStartDate.getMonth(), 1);
            const pmLastDay = new Date(pvStartDate.getFullYear(), pvStartDate.getMonth() + 1, 0);
            dateRangeModel.startDate = pmFirstDay;
            dateRangeModel.endDate = pmLastDay;
            break;
         case DateRangePickerOption.ThisMonth:
            const tmStartDate = new Date();
            const tmFirstDay = new Date(tmStartDate.getFullYear(), tmStartDate.getMonth(), 1);
            const tmLastDay = new Date(tmStartDate.getFullYear(), tmStartDate.getMonth() + 1, 0);
            dateRangeModel.startDate = tmFirstDay;
            dateRangeModel.endDate = tmLastDay;
            break;
         case DateRangePickerOption.MonthToDate:
            const mtdStartDate = new Date();
            const mtdFirstDay = new Date(mtdStartDate.getFullYear(), mtdStartDate.getMonth(), 1);
            dateRangeModel.startDate = mtdFirstDay;
            dateRangeModel.endDate = new Date();
            break;
         case DateRangePickerOption.NextMonth:
            const nmStartDate = new Date(tempDateStartDate.valueOf());
            nmStartDate.setMonth(nmStartDate.getMonth() + 1);
            const nmFirstDay = new Date(nmStartDate.getFullYear(), nmStartDate.getMonth(), 1);
            const mnLastDay = new Date(nmStartDate.getFullYear(), nmStartDate.getMonth() + 1, 0);
            dateRangeModel.startDate = nmFirstDay;
            dateRangeModel.endDate = mnLastDay;
            break;
         case DateRangePickerOption.PrevYear:
            const pyStartDate = new Date(new Date(tempDateStartDate.valueOf()).getFullYear() - 1, 0, 1);
            const pyLastofYear = new Date(pyStartDate.getFullYear(), pyStartDate.getMonth() + 12, 0);
            dateRangeModel.startDate = pyStartDate;
            dateRangeModel.endDate = pyLastofYear;
            break;
         case DateRangePickerOption.ThisYear:
            const tyStartDate = new Date(new Date().getFullYear(), 0, 1);
            const tyLastofYear = new Date(tyStartDate.getFullYear(), tyStartDate.getMonth() + 12, 0);
            dateRangeModel.startDate = tyStartDate;
            dateRangeModel.endDate = tyLastofYear;
            break;
         case DateRangePickerOption.YearToDate:
            const ytdStartDate = new Date(new Date().getFullYear(), 0, 1);
            dateRangeModel.startDate = ytdStartDate;
            dateRangeModel.endDate = new Date();
            return dateRangeModel;
         case DateRangePickerOption.NextYear:
            const nyStartDate = new Date(new Date(tempDateStartDate.valueOf()).getFullYear() + 1, 0, 1);
            const nyLastofYear = new Date(nyStartDate.getFullYear(), nyStartDate.getMonth() + 12, 0);
            dateRangeModel.startDate = nyStartDate;
            dateRangeModel.endDate = nyLastofYear;
            break;
         default:
            dateRangeModel.endDate = new Date();
            dateRangeModel.startDate = new Date();
            break;
      }
      DatesService.setTimeToBeginningOfDay(dateRangeModel.startDate);
      DatesService.setTimeToEndOfDay(dateRangeModel.endDate);
      return dateRangeModel;
   }

   static getMonthOfDateRange(date: Date): DateRangeModel {
      const monthOfDate = new Date(date.valueOf());

      const firstDay = new Date(monthOfDate.getFullYear(), monthOfDate.getMonth(), 1);
      const lastDay = new Date(monthOfDate.getFullYear(), monthOfDate.getMonth() + 1, 0);

      return new DateRangeModel(firstDay, lastDay);
   }

   static getEnumValue(stringValue: string): RelativeDateOptions {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'RelativeDateOptions | undefined' is not assi... Remove this comment to see the full error message
      return RelativeDatesService.relativeDateValueMap.get(stringValue);
   }
}
