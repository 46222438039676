////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum CamAutoDivisionMethodCustomer {
   NotSet = 0,
   PercentPropertySquareFootage = 1,
   PercentPropertyRentedSquareFootage = 2,
   PercentOccupiedOfPropertyOccupancy = 3,
   EqualAmongTenants = 4,
   EqualAmongUnits = 5,
   UDFField = 6,
   CRESetup = 7,
}
