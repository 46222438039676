import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { LayoutModule } from "../layout/layout.module";
import { FileIconComponent } from "./file-icon.component";

@NgModule({
   imports: [CommonModule, LayoutModule],
   declarations: [FileIconComponent],
   exports: [FileIconComponent],
})
export class FileIconModule { }
