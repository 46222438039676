<lcs-link
   [anchorTemplate]="anchorTemplate"
   [contentTemplate]="contentTemplate"
   [cssClasses]="cssClasses"
   [disabled]="disabled"
   [linkMode]="linkMode"
   [routerLink]="$any(routerLink)"
   [text]="text"
   [title]="title"
   [target]="target"
   (clicked)="triggerAction()"
   [queryParams]="queryParams"
></lcs-link>