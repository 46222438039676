import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExpressMenuItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-menu-item.model";

import { ActionRoutingService } from "../action-router/action-routing.service";
import { SideBarMenuItemConfigurationModel } from "./side-bar-menu-item-configuration.model";

@Component({
   selector: "lcs-side-bar-menu-list",
   templateUrl: "side-bar-menu-list.component.html",
})
export class SideBarMenuListComponent {
   @Input() sideBarSubMenu: SideBarMenuItemConfigurationModel;

   @Input() useActionLinks: boolean;

   @Input() currentEntityID: number;

   @Input() showInformation: boolean;

   @Input() disabled: boolean;

   @Output() parentMenuItemSelected = new EventEmitter<ExpressMenuItemModel>();

   @Output() childMenuItemSelected = new EventEmitter<ExpressMenuItemModel>();

   @Output() backSelected = new EventEmitter();

   buildURL;

   constructor(private actionRoutingService: ActionRoutingService) {
      this.buildURL = this.actionRoutingService.buildURL;
   }
}
