import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

import { SideMenuService } from "../../side-menu/side-menu.service";
import { MenuService } from "./menu.service";
import { MenuItemModel } from "./models/menu-item.model";

@Component({
   selector: "owa-menu",
   templateUrl: "./menu.component.html",
   styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit, OnDestroy {
   @ViewChild("Hamburgler", { static: true }) hamburgerMenu: ElementRef;

   public activeMenuClass = "active-menu-item";
   public menuItems: Array<MenuItemModel>;
   private unsubscribe = new Subject<void>();

   constructor(
      private menuService: MenuService,
      private sideMenuService: SideMenuService,
      private renderer: Renderer2
   ) {
      this.sideMenuService.sideMenuIsVisible.pipe(takeUntil(this.unsubscribe)).subscribe((isSideMenuVisible) => {
         if (this.hamburgerMenu) {
            if (isSideMenuVisible) {
               this.renderer.addClass(this.hamburgerMenu.nativeElement, this.activeMenuClass);
            } else {
               this.renderer.removeClass(this.hamburgerMenu.nativeElement, this.activeMenuClass);
            }
         }
      });

      this.menuService.currentMenuItemsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((currentMenu) => {
         this.menuItems = currentMenu;
         if (this.menuItems) {
            this.menuItems.forEach((f) => (f.ID = f.Name.replace(/ /g, "") + "TopBarMenu"));
         }
      });
   }

   ngOnInit() {
      this.menuItems = this.menuService.currentMenu;
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   toggleHamburger() {
      this.sideMenuService.sideMenuIsVisible.next(!this.sideMenuService.sideMenuIsVisible.value);
   }
}
