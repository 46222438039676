import { Component, Input } from "@angular/core";
import { CheckListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check-list-item.model";

@Component({
   selector: "owa-issue-checklist-list",
   templateUrl: "./checklist-list.component.html",
})
export class ChecklistListComponent {
   @Input() checkListItems: Array<CheckListItemModel>;

   @Input() totalCount: number; // TODO: use this

   checkListColumns: string[] = ["IsClosed", "Description", "ClosedDate", "ClosedByUser"];
}
