<div class="report-parameter-label">
   <label>
      {{ label }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-date-range-picker
   *ngIf="!isRelative"
   [name]="name"
   [displayName]="displayName"
   [disabled]="disabled"
   [dateStartFormat]="dateFormat"
   [dateEndFormat]="dateFormat"
   [(ngModel)]="dateRange"
   (ngModelChange)="valueChange($event)"
></lcs-date-range-picker>
<div
   class="memorized-date-range-report-parameter-start"
   *ngIf="isRelative"
>
   <lcs-relative-date-picker
      [name]="name + '-start-date'"
      [displayName]="displayName"
      [label]="label + ' - Start'"
      [isRelative]="isRelative"
      [ngModel]="startRelativeDateValue"
      (ngModelChange)="onStartDateChanged($event)"
   >
   </lcs-relative-date-picker>
</div>
<div
   class="memorized-date-range-report-parameter-end"
   *ngIf="isRelative"
>
   <lcs-relative-date-picker
      [name]="name + '-end-date'"
      [displayName]="displayName"
      [label]="label + ' - End'"
      [isRelative]="isRelative"
      [ngModel]="endRelativeDateValue"
      (ngModelChange)="onEndDateChanged($event)"
   >
   </lcs-relative-date-picker>
</div>