<div class="dashboard-message" *ngIf="canApprove && hasEstimatesToView">
   <div class="dashboard-message-value">
      {{ message }}
   </div>
   <div class="dashboard-message-link-container">
      <a [routerLink]="getURL()">
         <div class="dashboard-message-link">
            <div *ngIf="estimateList.length == 1; else elseBlock"> View Estimate </div>
            <ng-template #elseBlock>View Estimates</ng-template>
         </div>
      </a>
   </div>
</div>
