import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { InspectionReportsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/inspection-reports.service";
import { InspectionReportViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/inspection-report-view.model";

@Component({
   selector: "owa-dashboard-inspection-report",
   templateUrl: "./dashboard-tile-inspection-report.component.html",
   providers: [InspectionReportsService],
})
export class DashboardInspectionReportComponent implements OnInit {
   public observableInspections: Observable<Array<InspectionReportViewModel>>;
   public inspections: Array<InspectionReportViewModel> = new Array<InspectionReportViewModel>();
   public notificationCount: number;
   public router: Router;
   public isNew: boolean;
   public inspectionColumns: string[] = ["InspectionDate", "Property", "Unit", "Report", "NewRow"];
   constructor(private inspectionReportsService: InspectionReportsService) {}

   ngOnInit() {
      this.observableInspections = this.inspectionReportsService.getInspectionReportsForDashboard();
      this.observableInspections.subscribe((result) => {
         this.inspections = result;
         if (this.inspections != null && this.inspections.length > 0) {
            const newInspections = this.inspections.filter((i) => i.IsNew);
            this.notificationCount = newInspections != null ? newInspections.length : 0;
         }
      });
   }
}
