<owa-layout [headerTitle]="'Inspection Reports'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="inspectionFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>

      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{ results | async }} of {{ totalResults | async }} inspection reports
      </div>

      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(inspections | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>
               <ng-container
                  lcsColumnDef="InspectionDate"
                  [width]="10"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Inspection Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{
                     row.InspectionDate | date: "MM/dd/yyyy"
                     }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Scheduled"
                  [width]="10"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Scheduled</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.ScheduledDate | date: "MM/dd/yyyy" }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Type"
                  [width]="10"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Type </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.InspectionType }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Description"
                  [width]="10"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Description </th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.Description }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Property"
                  [width]="10"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.Property }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Unit"
                  [width]="10"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Unit</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{ row.Unit }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Completed"
                  [width]="10"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Completed</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >{{
                     row.CompletionDate | date: "MM/dd/yyyy"
                     }}</td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Report"
                  [width]="10"
                  [priority]="5"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Report</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="attachment"
                  >
                     <div *ngIf="row.InspectionStatus === 'Closed'">
                        <a
                           href="{{ row.InspectionURL }}"
                           target="_blank"
                        >
                           <i class="material-icons">link</i>
                        </a>
                     </div>
                  </td>
               </ng-container>
            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any((inspections | async))?.length < $any((totalResults | async))"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>