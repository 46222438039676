<!-- <div class="label-text-title flex-full">Information</div> -->
<div
   class=" label-text flex-full"
   *ngIf="paymentType == 'ACH'"
>
   Owner contribution payment has been submitted.
</div>
<div
   class="label-text flex-full"
   *ngIf="paymentType != 'ACH'"
>
   Owner contribution payment has been successfully processed.
</div>
<div class="flex-half">
   <input
      type="submit"
      value="OK"
      id="ok"
      (click)="onClickOk()"
   />
</div>