import { TemplatePortal } from "@angular/cdk/portal";
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ReportParameterRendererComponentBase } from "@lcs/reports/report-parameters/report-parameter-override-component.base";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { OverlayPortalAttachedModel } from "@lcs/single-line-multi-select/models/overlay-portal-attached.model";
import cloneDeep from "lodash/cloneDeep";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ProspectStageModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect-stage.model";
import { ReportParameterValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-source.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { ProspectStagesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/prospect-stages.service";
import { Subject, takeUntil } from "rxjs";

import { OverlayPanelComponent } from "../../../overlay-panel/overlay-panel.component";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterFiltersService } from "../report-parameter-filters.service";
import { ReportParametersService } from "../report-parameters.service";
import { SelectorReportParameterService } from "../selector/selector-report-parameter.service";

@Component({
   selector: "lcs-prospect-stage-id-report-parameter",
   templateUrl: "prospect-stage-id-report-parameter.component.html",
   providers: [ReportParameterFiltersService, SelectorReportParameterService],
})
export class ProspectStageIDReportParameterComponent implements OnInit, OnDestroy, AfterViewInit {
   @ViewChild("inactiveStagesCheckbox") inactiveStagesCheckbox: TemplateRef<any>;
   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean = false;

   entityValueSourceFilters = new Array<FilterOption>();

   selectedValues: Array<any>;

   @Input() validation: ValidationModel;

   selectionChange: SelectionChangeModel;

   reportParameterValueModel: ReportParameterValueModel;

   allValuesLoaded: boolean = false;

   includeInactiveProspectStages: boolean = false;

   valueSource: ValueSourceModel;

   prospectStages = new Array<ProspectStageModel>();

   allProspectStages = new Array<SelectorItemModel>();

   activeProspectStages = new Array<SelectorItemModel>();

   portal: TemplatePortal<any>;

   parentOverlayPanelRef: OverlayPanelComponent;

   isAllSelected: boolean;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private prospectStagesService: ProspectStagesService,
      private selectorReportParameterService: SelectorReportParameterService,
      private viewContainerRef: ViewContainerRef,
      private changeDetectorRef: ChangeDetectorRef
   ) {
      this.getData();
   }

   ngOnInit() {
      this.initializeReportParameterValue();

      if (!this.displayName && this.parameter) {
         this.displayName = ReportParameterRendererComponentBase.getDisplayNameFromReportReportParameter(
            this.parameter.toReportReportParameterModel()
         );
      }
   }

   ngAfterViewInit() {
      if (this.inactiveStagesCheckbox) {
         this.portal = new TemplatePortal(this.inactiveStagesCheckbox, this.viewContainerRef);
         this.changeDetectorRef.detectChanges();
      }
   }

   getData() {
      this.prospectStagesService
         .getCollection()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((prospectStages) => {
            this.prospectStages = prospectStages;
            this.buildValueSource();
         });
   }

   onPortalAttached(portalAttached: OverlayPortalAttachedModel) {
      if (portalAttached && portalAttached.parentOverlayRef) {
         this.parentOverlayPanelRef = portalAttached.parentOverlayRef as OverlayPanelComponent;
      }
   }
   buildValueSource() {
      const reportParameterValueSource: ReportParameterValueSourceModel = this.parameter.ReportParameterValueSource;
      this.initializeValidation(reportParameterValueSource.IsRequired);
      if (this.reportParameterValueModel.value?.length) {
         // if reportParameterValueModel has value set, override reportParameterValueSource.DefaultValue
         reportParameterValueSource.DefaultValue = this.reportParameterValueModel.value;
      }

      let selectorValueSource = new ValueSourceModel();

      selectorValueSource =
         this.selectorReportParameterService.createValueSourceFromReportParameterValueSource(
            reportParameterValueSource
         );
      selectorValueSource.Type = ValueSourceTypes.Static;
      selectorValueSource.Type = ValueSourceTypes.Static;
      selectorValueSource.StaticValues = new Array<SelectorItemModel>();
      this.prospectStages.forEach((prospectStage) => {
         if (prospectStage.IsActive) {
            this.activeProspectStages.push(new SelectorItemModel(prospectStage.ProspectStageID, prospectStage.Name));
         }
         this.allProspectStages.push(new SelectorItemModel(prospectStage.ProspectStageID, prospectStage.Name));
      });
      if (this.hasAsk) {
         this.checkForInactiveStages();
      }
      this.includeInactiveProspectStages
         ? (selectorValueSource.StaticValues = this.allProspectStages)
         : (selectorValueSource.StaticValues = this.activeProspectStages);
      this.valueSource = selectorValueSource;
      this.selectedValues = selectorValueSource.SelectedValues;
   }

   checkForInactiveStages() {
      this.selectedValues.map((x) => {
         if (!this.activeProspectStages.find((y) => y.value === parseInt(x))) {
            this.includeInactiveProspectStages = true;
         }
      });
   }
   onInactiveFilterValue(value) {
      const valueSource = cloneDeep(this.valueSource);
      if (value) {
         valueSource.StaticValues = this.allProspectStages;
         const selectedValues = new Array<number>();
         this.selectedValues.map((x) => {
            if (this.allProspectStages.some((y) => y.value === x)) {
               selectedValues.push(x);
            }
         });
         this.selectedValues = selectedValues;
      } else {
         valueSource.StaticValues = this.activeProspectStages;
         const selectedValues = new Array<number>();
         this.selectedValues.map((x) => {
            if (this.activeProspectStages.some((y) => y.value === x)) {
               selectedValues.push(x);
            }
         });
         this.selectedValues = selectedValues;
      }
      this.includeInactiveProspectStages = value;
      this.valueSource = cloneDeep(valueSource);
      this.updateReportParameterValues();
   }

   onProcessValueItems(items: Array<SelectorItemModel>): void {
      const selectedValues = items.filter((i) => i.isChecked).map((i) => i.value);
      const isAll =
         selectedValues.length > 0 &&
         items.length === selectedValues.length &&
         this.parameter.ReportParameterValueSource.AllowsSelectAll;

      const selectionChange = new SelectionChangeModel();
      selectionChange.selectAll = isAll;
      selectionChange.items = items;
      selectionChange.checkedItems = items.filter((i) => i.isChecked);
      this.selectedValues = this.reportParameterValueModel.rawValues;
   }

   selectionChanged() {
      const checkedItems = this.selectionChange.checkedItems.filter((c) => c.value != null && c.value !== "");
      this.updateReportParameterValues(checkedItems.map((i) => i.value));
   }

   updateReportParameterValues(selectedValues?: Array<number>) {
      this.reportParameterValueModel.value = "";
      let values = new Array<any>();
      const allValue = this.parameter.ReportParameterValueSource.AllValue;
      if (this.isAllSelected && allValue) {
         if (this.includeInactiveProspectStages) {
            this.reportParameterValueModel.value = "(" + this.allProspectStages.map((x) => x.value).join(",") + ")";
         } else {
            this.reportParameterValueModel.value = "(" + this.activeProspectStages.map((x) => x.value).join(",") + ")";
         }
         values = [this.reportParameterValueModel.value];
      } else if (this.selectedValues && this.selectedValues.length > 0) {
         this.reportParameterValueModel.value = "(" + this.selectedValues.join(",") + ")";
         values = this.selectedValues;
      } else if (!selectedValues) {
         if (this.includeInactiveProspectStages) {
            this.reportParameterValueModel.value = "(" + this.allProspectStages.map((x) => x.value).join(",") + ")";
         } else {
            this.reportParameterValueModel.value = "(" + this.activeProspectStages.map((x) => x.value).join(",") + ")";
         }
         values = [this.reportParameterValueModel.value];
      } else {
         this.reportParameterValueModel.value = "";
      }
      this.reportParameterValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   allSelected(allSelected: boolean) {
      this.isAllSelected = allSelected;
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   ngOnDestroy() {
      if (this.portal) {
         if (this.portal.isAttached) {
            this.portal.detach();
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'TemplatePor... Remove this comment to see the full error message
         this.portal = null;
      }
      this.unsubscribe.next();
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      if (this.reportParameterValueModel.value) {
         const values = this.reportParameterValueModel.value.toString().split(",");
         this.selectedValues = values;
      }
   }

   private initializeValidation(isRequired: boolean): void {
      if (isRequired) {
         if (this.validation) {
            this.validation.required = true;
         } else {
            const validation = new ValidationModel();
            validation.required = true;
            validation.dataType = ExpressDataTypes.Key;
            validation.validateOnlyIfDirty = true;
            this.validation = validation;
         }
      }
   }
}
