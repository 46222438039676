import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { InputsModule } from "@lcs/inputs/inputs.module";
import { TextAreaModule } from "@lcs/inputs/text-area/text-area.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";

import { OwaInputsModule } from "../inputs/inputs.module";
import { LayoutModule } from "../layout/layout.module";
import { UpdateAccountInformationComponent } from "./update-account-information.component";

@NgModule({
   declarations: [UpdateAccountInformationComponent],
   imports: [
      CommonModule,
      FormsModule,
      InputsModule,
      LayoutModule,
      LcsProgressButtonModule,
      LoadingOverlayModule,
      SelectModule,
      TextAreaModule,
      TextBoxModule,
      MatTooltipModule,
      OwaInputsModule,
      ValidationModule,
   ],
   exports: [UpdateAccountInformationComponent],
})
export class UpdateAccountInformationModule {}
