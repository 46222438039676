import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ClearedOnly } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/cleared-only.enum";
import { Subject } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";
import { SelectorReportParameterService } from "../selector/selector-report-parameter.service";

@Component({
   selector: "lcs-cleared-options",
   templateUrl: "./cleared-options.component.html",
   providers: [SelectorReportParameterService],
})
export class ClearedOptionsComponent implements OnInit, OnDestroy {
   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   @Input() name: string;

   @Input() hasAsk: boolean;

   value: ClearedOnly = ClearedOnly.None;

   disabled: boolean = false;

   itemSet: SelectorItemSetModel;

   reportParameterValueModel: ReportParameterValueModel;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit(): void {
      this.initializeReportParameterValue();
      this.buildItemSet();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   valueChanged(clickedItem: SelectorItemModel) {
      this.value = clickedItem.isChecked ? +clickedItem.value : ClearedOnly.None;
      this.itemSet.items.forEach((item) => {
         item.isDisabled = item.value !== clickedItem.value && this.value !== ClearedOnly.None;
      });
      this.reportParameterValueModel.value = this.value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   private buildItemSet(): void {
      this.itemSet = new SelectorItemSetModel();
      const clearedSelectorItem = new SelectorItemModel();
      clearedSelectorItem.value = ClearedOnly.Cleared;
      clearedSelectorItem.displayValue = "Only include transactions that have been cleared";
      clearedSelectorItem.isChecked = this.value === clearedSelectorItem.value;
      clearedSelectorItem.isDisabled = !(clearedSelectorItem.isChecked || this.value === ClearedOnly.None);
      this.itemSet.items.push(clearedSelectorItem);

      const openSelectorItem = new SelectorItemModel();
      openSelectorItem.value = ClearedOnly.Open;
      openSelectorItem.displayValue = "Only include transactions that have not cleared";
      openSelectorItem.isChecked = this.value === openSelectorItem.value;
      openSelectorItem.isDisabled = !(openSelectorItem.isChecked || this.value === ClearedOnly.None);
      this.itemSet.items.push(openSelectorItem);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );

      if (this.reportParameterValueModel.value) {
         this.value = +this.reportParameterValueModel.value;
      }
   }
}
