import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class EmailService {
   emailContents: BehaviorSubject<string> = new BehaviorSubject("");

   /**
    * RegExp that splits on commas, semicolon and newline chars (see Note), ignoring surrounding whitespace and
    * collapsing multiple adjacent empty items.
    *
    * Useful for validating email addresses separated by commas, semicolons, carriage returns, line feed chars (see Note)
    * or any combo thereof optionally surrounded by whitespace.
    *
    * NOTE: if the input is a form input of 'input type=text', the browser will remove any line break chars replacing them with a space
    *       so in those cases, only a semicolon will actually work as a delimiter. Using line break chars would require a textarea input.
    */
   static readonly whitespaceAgnosticValidationDelimiter: RegExp = /(?:\s*[,;\r\n]\s*)+/gm;

   /**
    * Regular expression copied directly from LCS.Core.Extensions.StringExtensions.IsValidEmail is used to determine the validity of input email address string.
    *
    * This should mitigate the risk of inconsistent validity criteria between the client and server.
    *
    * NOTE: This regex is not entirely RFC compliant, but it is what is used on the server and should be sufficient for our purposes.
    */
   static readonly validEmailAddressRegexp: RegExp =
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

   /**
    * This email validation regexp was originally used by the email validation directive, but it does not match the
    * RM12 email validation regexp used by the server or the regex used in other places in RMX.
    * It is left commented out here for reference, but is not recommended for use at this time to avoid potential
    * inconsistencies with RM12.
    */
   // static readonly validEmailAddressRegexp_DEPRECATED: RegExp =
   //    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/;

   /**
    * This regexp is is identical to EmailService.validEmailAddressRegexp except it allows for multiple email addresses
    * separated by semicolons or commas and optional whitespace.
    */
   static readonly multipleValidEmailAddressRegExp: RegExp =
      /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*[;,]\s*|\s*$))*$/;

   /**
    * @remarks
    * Uses EmailService.validEmailRegexp to determine the validity of input email address string.
    */
   static isValidEmailAddress(emailAddress: string): boolean {
      const trimmedEmailAddress: string = emailAddress.trim();
      const isValidEmailAddress: boolean = EmailService.validEmailAddressRegexp.test(trimmedEmailAddress);
      return isValidEmailAddress;
   }

   /**
    * @param emailAddresses A string of email addresses separated by semicolons or commas. Whitespace can optionally surround email addresses.
    *
    * Removes (case insensitive) duplicate and empty email addresses and collapses whitespace.
    *
    * Maintains both order and casing of the input string.
    *
    * Use null for emailSeparator if you only want to allow a single email address so it will not split string at all.
    *
    * @returns A string of all distinct items from the input string, separated by outputDelimiter which defaults to "; "
    */
   static removeDuplicatesAndCollapseWhitespace(
      emailAddressesString: string,
      outputDelimiter: string = "; ",
      emailSeparator: string | RegExp | null = EmailService.whitespaceAgnosticValidationDelimiter
   ): string {
      let emailArray: Array<string>;
      if (emailSeparator !== null) {
         emailArray = emailAddressesString.split(emailSeparator);
      } else {
         emailArray = new Array<string>(emailAddressesString);
      }
      const emailSet = new Set<string>();
      const uniqueEmails = new Array<string>();
      emailArray.forEach((email: string) => {
         if (email != null && email.length > 0 && !emailSet.has(email.toLowerCase())) {
            emailSet.add(email.toLowerCase());
            uniqueEmails.push(email);
         }
      });

      const trimmedUniqueEmails: string = uniqueEmails.join(outputDelimiter).trim();

      return trimmedUniqueEmails;
   }

   updateEmailContents(emailContent: string) {
      this.emailContents.next(emailContent);
   }
}
