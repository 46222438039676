import { Pipe, PipeTransform } from "@angular/core";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";

import { ExpressFooterButtonModel } from "./express-footer-button.model";

@Pipe({
   name: "lcsIsFooterButtonRaised",
})
export class FooterButtonRaisedPipe implements PipeTransform {
   transform(footerButton: ExpressFooterButtonModel): boolean {
      return FooterButtonRaisedPipe.isFooterButtonRaised(footerButton);
   }

   static isFooterButtonRaised(footerButton: ExpressFooterButtonModel): boolean {
      if (footerButton.expressActionEntityEvent) {
         return footerButton.expressActionEntityEvent.ExpressEntityEvent !== ExpressEntityEvents.Cancel;
      } else {
         return footerButton.isRaised;
      }
   }
}
