<div
   *ngIf="visible"
   lcsFooter
   [ngClass]="additionalFooterStyling"
   [class.animate-footer]="animateFooter"
   [lcsFooterIsEmpty]="(!footerButtons || footerButtons.length === 0) && !footerTemplate"
>
   <div class="information">
      <lcs-information-overlay
         *ngIf="createAndUpdateUser"
         [informationTemplate]="informationTemplate"
         [alignToRight]="false"
      >
         <ng-template #informationTemplate>
            <div *ngIf="createAndUpdateUser">
               <div class="form-section">
                  <lcs-form-section-header>
                     <h4>Information</h4>
                  </lcs-form-section-header>
                  <div class="form-section">
                     <div class="form-element flex-full">
                        <div>Created: {{ createAndUpdateUser.CreateDate | date: "M/dd/yyyy h:mm:ss a" }}</div>
                     </div>
                     <div class="form-element flex-full">
                        <div>Created By: {{ createAndUpdateUser.CreateUsername }}</div>
                     </div>
                     <div class="form-element flex-full">
                        <div>Updated: {{ createAndUpdateUser.UpdateDate | date: "M/dd/yyyy h:mm:ss a" }}</div>
                     </div>
                     <div class="form-element flex-full">
                        <div>Updated By: {{ createAndUpdateUser.UpdateUsername }}</div>
                     </div>
                  </div>
               </div>
            </div>
         </ng-template>
      </lcs-information-overlay>
      <lcs-information-overlay
         *ngIf="additionalInfo"
         class="additional-info-icon"
         [informationTemplate]="informationTemplate"
         [alignToRight]="false"
      >
         <ng-template #informationTemplate>
            <div class="additional-info-icon">{{additionalInfo}}
            </div>
         </ng-template>
      </lcs-information-overlay>
      <div
         *ngIf="linkedInfo"
         class="linked-info"
      >
         <lcs-action-link
            *ngIf="linkedInfo.ActionTrigger"
            [contentTemplate]="contentTemplate"
            [actionTrigger]="$any(linkedInfo.ActionTrigger)"
         >
            <ng-template #contentTemplate>
               <label title={{linkedInfo?.Title}}>{{linkedInfo.Text}}</label>
            </ng-template>
         </lcs-action-link>
         <div *ngIf="!linkedInfo?.ActionTrigger">
            <label title={{linkedInfo?.Title}}>{{linkedInfo.Text}}</label>
         </div>
      </div>
      <ng-container *ngTemplateOutlet="informationTemplate; context: { $implicit: this }"></ng-container>
   </div>
   <div
      class="footer-buttons"
      [ngStyle]="footerButtonsAdditionalStyling"
   >
      <ng-container *ngIf="touchedInvalidControls.length > 0">
         <div
            class="footer-errors"
            #errorMessage
         >
            <div
               *ngIf="!form?.errors"
               class="footer-errors-label"
               (click)="showErrors = !showErrors"
            >
               <i class="material-icons">error_outline</i>
            </div>
            <lcs-overlay-panel
               [show]="showErrors"
               [parentElement]="errorMessage"
               [toggleOnClick]="false"
               [showOnClick]="false"
               [showOnFocus]="false"
               [hideOnBlur]="false"
               [alignToRight]="true"
               [overlayPointer]="true"
               [hideOnOutsideClick]="true"
               [staticOverlay]="true"
               [overlayClasses]="'validation-overlay'"
               (showChange)="onShowErrorPanelChange($event)"
            >
               <div class="validation-overlay-pointer-wrapper">
                  <div
                     class="footer-validation-summary-wrapper"
                     (click)="showErrors = false"
                  >
                     <lcs-validation-summary
                        [controls]="touchedInvalidControls"
                        (errorClick)="onErrorClick($event)"
                     ></lcs-validation-summary>
                  </div>
               </div>
            </lcs-overlay-panel>
         </div>
      </ng-container>
      <div
         class="footer-errors"
         #manualErrorMessage
      >
         <div
            class="footer-errors-label"
            *ngIf="form?.errors"
            (click)="showManualErrors = !showManualErrors"
         >
            <i class="material-icons">error_outline</i>
         </div>
         <lcs-overlay-panel
            [show]="showManualErrors"
            [parentElement]="manualErrorMessage"
            [toggleOnClick]="false"
            [showOnClick]="false"
            [showOnFocus]="false"
            [hideOnBlur]="false"
            [alignToRight]="true"
            [overlayPointer]="true"
            [hideOnOutsideClick]="true"
            [staticOverlay]="true"
            [overlayClasses]="'validation-overlay'"
            (showChange)="onShowManualErrorPanelChange($event)"
         >
            <div class="validation-overlay-pointer-wrapper">
               <div
                  class="footer-validation-summary-wrapper"
                  (click)="showManualErrors = false"
               >
                  <div class="error-messages-container">
                     <div class="error-messages-list">
                        <div class="error-message">{{ form && form.errors && form.errors["formError"] ?
                           form.errors["formError"] : '' }}</div>
                     </div>
                  </div>
               </div>
            </div>
         </lcs-overlay-panel>
      </div>

      <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: this }"></ng-container>

      <lcs-progress-button-ext
         #progressButton
         [ngClass]="{'full-screen':footerButtons.length > maxNumberOfButtons}"
         class="{{progressButtonAdditionalStyling}}"
         *ngFor="let expressFooterButton of footerButtons"
         [isRaisedButton]="expressFooterButton | lcsIsFooterButtonRaised"
         [isCancelEvent]="expressFooterButton.expressActionEntityEvent?.ExpressEntityEvent === entityEvents.Cancel"
         [type]="
            expressFooterButton.expressActionEntityEvent?.ExpressEntityEvent === entityEvents.Save ? 'submit' : 'button'
         "
         [formId]="
         $any(expressFooterButton.expressActionEntityEvent?.ExpressEntityEvent === entityEvents.Save ? formId : null)
         "
         [disabled]="expressFooterButton.disabled"
         (buttonClick)="onButtonClick(expressFooterButton)"
         (buttonDestroyed)="onButtonDestroyed(progressButton)"
         (entityEventResultChange)="onButtonStatusChange($event)"
         [(entityEventResult)]="expressFooterButton.entityEventResult"
         [text]="expressFooterButton.label"
      ></lcs-progress-button-ext>
      <lcs-collapsed-footer-buttons
         *ngIf="footerButtons.length > maxNumberOfButtons"
         [footerButtons]="footerButtons"
         [maxNumberOfButtons]="maxNumberOfButtons"
         (buttonClick)="onButtonClick($event)"
      ></lcs-collapsed-footer-buttons>
   </div>