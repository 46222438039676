import { CDK_DRAG_CONFIG } from "@angular/cdk/drag-drop";
import { CdkRowDef, CdkTable } from "@angular/cdk/table";
import {
   AfterContentInit,
   AfterViewInit,
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   ContentChildren,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   Output,
   QueryList,
   TrackByFunction,
   ViewChild,
} from "@angular/core";
import { WindowService } from "@lcs/viewport/window.service";
import { startWith, Subject, takeUntil } from "rxjs";

import { LcsColumnDefDirective } from "./cdk-overrides/column-def.directive";
import { LcsFooterRowDefDirective } from "./cdk-overrides/footer-row-def.directive";
import { LcsHeaderRowDefDirective } from "./cdk-overrides/header-row-def.directive";
import { LcsRowDefDirective } from "./cdk-overrides/row-def.directive";

export enum ResponsiveColumnCollapseMethod {
   PriorityOrder,
   RenderingOrder,
}
const DragConfig = {
   dragStartThreshold: 0,
   pointerDirectionChangeThreshold: 5,
   zIndex: 1000000,
};
@Component({
   selector: "lcs-table",
   templateUrl: "./table.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class TableComponent<T extends Object> implements AfterContentInit, AfterViewInit, OnDestroy {
   private _dataSource: T[];
   @Input() set dataSource(data: T[]) {
      this._dataSource = data;
      this.toggleExpansionOnAllRows();
   }
   get dataSource() {
      return this._dataSource;
   }

   @Input() trackBy: TrackByFunction<T>;

   @Input() responsiveColumnCollapseMethod: ResponsiveColumnCollapseMethod;

   @Input() responsiveItemHeaderStyle: Object;

   @Output() visibleColumnsChange = new EventEmitter<string[]>();

   @ContentChildren(LcsHeaderRowDefDirective, { descendants: true }) headerRows: QueryList<LcsHeaderRowDefDirective>;

   @ContentChildren(LcsRowDefDirective, { descendants: true }) rows: QueryList<LcsRowDefDirective<T>>;

   @ContentChildren(LcsFooterRowDefDirective, { descendants: true }) footerRows: QueryList<LcsFooterRowDefDirective>;

   @ContentChildren(LcsColumnDefDirective, { descendants: true }) columns: QueryList<LcsColumnDefDirective>;

   @ViewChild(CdkTable, { static: true }) table: CdkTable<T>;

   @ViewChild("table", { read: ElementRef, static: true }) tableEl: ElementRef;

   @ViewChild(LcsRowDefDirective, { static: true }) responsiveRow: LcsRowDefDirective<T>;

   hasHiddenColumns: boolean = false;

   hiddenColumns: LcsColumnDefDirective[] = [];

   responsiveRowColumnNames = ["hiddenColumnPlaceHolder", "hiddenColumns"];

   rowsExpanded = new Map<T, boolean>();

   hiddenColumnSpan: number;

   hiddenPlaceHolderSpan: number;

   allRowsExpanded: boolean = false;

   private latestHeaderRows = new Array<LcsHeaderRowDefDirective>();

   private unsubscribe = new Subject<void>();

   constructor(
      private changeDetectorRef: ChangeDetectorRef,
      public elementRef: ElementRef,
      public windowService: WindowService
   ) {}

   ngAfterContentInit(): void {
      let latestColumns: LcsColumnDefDirective[] = [];
      let latestFooterRows: LcsFooterRowDefDirective[] = [];
      let latestRows: LcsRowDefDirective<T>[] = [];

      this.columns.changes.pipe(startWith(null), takeUntil(this.unsubscribe)).subscribe(() => {
         latestColumns.forEach((column) => this.table.removeColumnDef(column));
         latestColumns = this.columns.toArray();
         latestColumns.forEach((column) => this.table.addColumnDef(column));

         this.checkColumnWidths();
      });
      this.headerRows.changes.pipe(startWith(null), takeUntil(this.unsubscribe)).subscribe(() => {
         // we use setTimeout here because if we don't, the cdk table briefly renders
         // two copies of header and footer rows.
         setTimeout(() => {
            const newHeaderRows = this.headerRows.toArray();
            let headerRowsChanged = false;
            if (newHeaderRows.length !== this.latestHeaderRows.length) {
               headerRowsChanged = true;
            } else {
               for (let i = 0; i < newHeaderRows.length; i++) {
                  if (newHeaderRows[i] !== this.latestHeaderRows[i]) {
                     headerRowsChanged = true;
                     break;
                  }
               }
            }

            if (!headerRowsChanged) {
               return;
            }

            this.latestHeaderRows.forEach((headerRow) => this.table.removeHeaderRowDef(headerRow));
            this.latestHeaderRows = this.headerRows.toArray();
            this.latestHeaderRows.forEach((headerRow) => this.table.addHeaderRowDef(headerRow));
            this.changeDetectorRef.markForCheck();
         });
      });
      this.footerRows.changes.pipe(startWith(null), takeUntil(this.unsubscribe)).subscribe(() => {
         setTimeout(() => {
            latestFooterRows.forEach((footerRow) => this.table.removeFooterRowDef(footerRow));
            latestFooterRows = this.footerRows.toArray();
            latestFooterRows.forEach((footerRow) => this.table.addFooterRowDef(footerRow));
            this.changeDetectorRef.markForCheck();
         });
      });
      this.rows.changes.pipe(startWith(null), takeUntil(this.unsubscribe)).subscribe(() => {
         latestRows.forEach((row) => this.table.removeRowDef(row as any as CdkRowDef<T>));
         this.table.removeRowDef(this.responsiveRow);
         latestRows = this.rows.toArray();
         latestRows.forEach((row) => this.table.addRowDef(row));
         if (this.responsiveRow) {
            this.table.addRowDef(this.responsiveRow);
         }
         this.changeDetectorRef.markForCheck();
      });
   }

   ngAfterViewInit(): void {
      this.table.addRowDef(this.responsiveRow);
      this.windowService.resized.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.checkColumnWidths();
      });
      setTimeout(() => {
         this.checkColumnWidths();
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   addColumnDef(columnDef: LcsColumnDefDirective): void {
      this.table.addColumnDef(columnDef);
   }

   removeColumnDef(columnDef: LcsColumnDefDirective): void {
      this.table.removeColumnDef(columnDef);
   }

   renderRows(): void {
      if (this.table.dataSource) {
         this.table.renderRows();
      }
   }

   checkColumnWidths(): void {
      const el: HTMLTableElement = this.elementRef.nativeElement;
      const fontSize = parseInt(getComputedStyle(el).fontSize);

      // everything converted to em's from px's using fontsize
      const width: number = el.getBoundingClientRect().width / fontSize;
      const tablePaddingLeft = parseInt(getComputedStyle(el).paddingLeft) / fontSize;
      const tablePaddingRight = parseInt(getComputedStyle(el).paddingRight) / fontSize;
      const tableWidth = width - tablePaddingLeft - tablePaddingRight;

      const defaultRow = this.rows.toArray().find((row) => !row.when);
      const columns = this.columns.toArray().filter((column) => defaultRow?.allColumns.indexOf(column.name) !== -1);
      const columnNamesInRenderingOrder = columns.map((col) => col.name);

      this.sortColumns(columns, columnNamesInRenderingOrder);

      const visibleColumnNames: string[] = [];
      let hiddenColumnSpan = 0;
      this.hiddenColumns.length = 0;

      let runningWidth: number = 0;
      this.hasHiddenColumns = false;
      for (let i = 0; i < columns.length; i++) {
         const col = columns[i];
         col.overrideToFill = false;
         if (col.width !== undefined) {
            runningWidth += +col.width;
         }
         if (runningWidth > tableWidth) {
            this.hasHiddenColumns = true;
            this.hiddenColumns.push(col);
            col.hidden = true;
         } else {
            if ((this.hasHiddenColumns || runningWidth > tableWidth - 3) && i < columns.length) {
               this.hasHiddenColumns = true;
               this.hiddenColumns.push(col);
               col.hidden = true;
            } else {
               visibleColumnNames.push(col.name);
               if (!col.skipForHiddenRows) {
                  hiddenColumnSpan++;
               }
               col.hidden = false;
            }
         }
      }

      if (this.hasHiddenColumns) {
         visibleColumnNames.unshift("responsive-toggle");
         columnNamesInRenderingOrder.unshift("responsive-toggle");
         this.setAllRowsExpandedFlag();

         this.hiddenColumnSpan = hiddenColumnSpan;
         this.hiddenPlaceHolderSpan = visibleColumnNames.length - hiddenColumnSpan;

         //On smaller sized devices, if there are no columns that are filling remaining space, it causes all visible columns to stretch. This makes it so the responsible toggle column is wider than it need sto be and doesnt give room for collapsed columns to show its data correctly.
         //So the solution is to make the last visible column fill remaining space so the rest of the visible columns are true to their sizes.
         if (!columns.find((f) => !f.hidden && f.fillRemainingSpace)) {
            for (let i = columnNamesInRenderingOrder.length - 1; i >= 0; i--) {
               const visibleLastCol = columns.find((f) => f.name === columnNamesInRenderingOrder[i] && !f.hidden);
               if (visibleLastCol) {
                  visibleLastCol.overrideToFill = true;
                  this.hiddenColumnSpan = visibleColumnNames.length - 1;
                  this.hiddenPlaceHolderSpan = 1;
                  break;
               }
            }
         }
      }

      const newColumns = columnNamesInRenderingOrder.filter((col) => visibleColumnNames.indexOf(col) !== -1);
      this.headerRows.forEach((row: LcsHeaderRowDefDirective) => (row.columns = newColumns));
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      defaultRow.columns = newColumns;

      this.visibleColumnsChange.emit(visibleColumnNames);
      this.changeDetectorRef.markForCheck();
   }

   toggleRowExpanded(row: T) {
      if (this.rowsExpanded.has(row)) {
         this.rowsExpanded.delete(row);
      } else {
         this.rowsExpanded.set(row, true);
      }
      this.setAllRowsExpandedFlag();
      this.renderRows();
   }

   rowIsExpanded = (_: number, row: T) => {
      return (this.hasHiddenColumns && this.rowsExpanded.get(row)) || false;
   };

   resetExpansionToggle() {
      this.allRowsExpanded = !this.allRowsExpanded;
      this.toggleExpansionOnAllRows();
   }

   private toggleExpansionOnAllRows() {
      if (!this._dataSource || this._dataSource.length <= 0 || !this.table.dataSource) {
         return;
      }
      if (this.allRowsExpanded) {
         this._dataSource.forEach((row) => {
            this.rowsExpanded.set(row, true);
         });
      } else {
         this.rowsExpanded = new Map<T, boolean>();
      }
      this.renderRows();
   }

   private setAllRowsExpandedFlag() {
      if (!this.hasHiddenColumns || !this.rowsExpanded) {
         this.allRowsExpanded = false;
         return;
      }

      if (this._dataSource.length === 0 || this.rowsExpanded.size < this._dataSource.length) {
         this.allRowsExpanded = false;
      } else {
         this.allRowsExpanded = true;
      }
   }

   private sortColumns(columns: LcsColumnDefDirective[], orderedNames: string[]): void {
      if (this.responsiveColumnCollapseMethod === ResponsiveColumnCollapseMethod.RenderingOrder) {
         for (let i = 0; i < orderedNames.length; i++) {
            const col = columns.find((c) => c.name === orderedNames[i]);
            if (col && col.priority >= 0) {
               col.priority = i + 1;
            }
         }
      }
      columns.sort((a, b) => a.priority - b.priority);
   }
}
