import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";

import { SecureSsnInputComponent } from "./secure-ssn-input.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule],
   declarations: [SecureSsnInputComponent],
   exports: [SecureSsnInputComponent],
})
export class SecureSsnInputModule {}
