<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && vendorNameAndUDFNameReportParameter!.IsAsk === true)"
   >
      Vendor Name Source
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="vendorNameAndUDFNameReportParameter!.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="tab-wrapper">
   <div class="single-tab-wrapper">
      <div class="individual-report-parameter-option vendor-name-report-wrapper">
         <lcs-dynamic-selector-report-parameter
            *ngIf="vendorNameAndUDFNameReportParameter"
            [name]="'vendor-name'"
            [hideLabel]="true"
            [disabled]="disabled || (hasAsk === true && vendorNameAndUDFNameReportParameter?.IsAsk === true)"
            [parameter]="vendorNameAndUDFNameReportParameter"
         ></lcs-dynamic-selector-report-parameter>
      </div>
      <div class="individual-report-parameter-option">
         <label
            class="parameter-label"
            [class.disabled]="disabled || (hasAsk === true && vendorNameAndUDFNameReportParameter?.IsAsk === true) || (+vendorNameValue?.value !== 4) "
         >UDF Name</label>
         <lcs-select
            *ngIf="udfNameReportParameter"
            lcsStaticSetSelector
            [items]="vendorUDFItems"
            [ngModel]="vendorUDFID"
            (ngModelChange)="onVendorUDFIDChange($event)"
            [disabled]="disabled || (hasAsk === true && vendorNameAndUDFNameReportParameter?.IsAsk === true) || (+vendorNameValue?.value !== 4) "
         ></lcs-select>
      </div>
   </div>
</div>