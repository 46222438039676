export {};

declare global {
   interface Date {
      toJSONLocal(): string;
   }

   interface Window {
      MSStream: any;
   }

   interface String {
      toBoolean(): boolean;
   }
}

Date.prototype.toJSONLocal = function() {
   function addZ(n) {
      return (n < 10 ? "0" : "") + n;
   }
   return this.getFullYear() + "-" + addZ(this.getMonth() + 1) + "-" + addZ(this.getDate());
};

Date.prototype.toJSON = function() {
   const correctedDate = new Date(this);
   correctedDate.setHours(this.getHours() - this.getTimezoneOffset() / 60);
   correctedDate.setMinutes(this.getMinutes() - (this.getTimezoneOffset() % 60));
   return correctedDate.toISOString().replace("Z", "");
};

String.prototype.toBoolean = function() {
   return true.toString() === this.toString().toLowerCase();
};

export const globalVariables = {
   isIOS: new RegExp(/iPad|iPhone|iPod/).test(navigator.userAgent) && !window.MSStream,
   isAndroid: new RegExp(/Android/).test(navigator.userAgent) && !window.MSStream,
   isMobile: new RegExp(/iPad|iPhone|iPod|Android/).test(navigator.userAgent) && !window.MSStream,
   topBarHeight: 60,
   contextBarHeight: 60,
};
