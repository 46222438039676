export class EntitySaveOption {
   Identifier: string;

   constructor(identifer: string) {
      this.Identifier = identifer;
   }

   toString(): string {
      return this.Identifier;
   }

   static toApiString(saveOptions?: Array<string | EntitySaveOption> | null): string | null {
      if (saveOptions == null) {
         return null;
      }

      if (!(saveOptions instanceof Array)) {
         saveOptions = [saveOptions];
      }

      return saveOptions.join(";");
   }
}
