<div [ngClass]="hasCounters ? 'flex-start' : ''">
   <div [ngClass]="hasCounters ? 'counter-wrapper'  : ''">
      <button
         *ngIf="hasCounters"
         type="button"
         (click)="onDecrease()"
         class="material-icons"
         [disabled]="disabled"
      ><i class="material-icons-outlined">
            remove
         </i></button>
      <input
         #input
         type="tel"
         class="no-spin"
         [ngClass]="[hasCounters ? 'numeric-input inline-element' : '']"
         [ngStyle]="{ 'text-align': rightAlignInput ? 'right' : 'left' }"
         placeholder="{{ placeholder }}"
         title="{{ tooltipOverride ? tooltipOverride : formattedValue }}"
         [attr.id]="path"
         [(ngModel)]="formattedValue"
         (ngModelChange)="onValueChanged($event)"
         [ngModelOptions]="{ standalone: true }"
         [maskito]="mask"
         [disabled]="disabled"
         (mousedown)="onClick($event)"
         (blur)="onBlur($event)"
         (focus)="onFocus($event)"
         (keydown.enter)="onEnterKeyDown($event)"
         (keyup.enter)="onEnterKey($event)"
      />
      <button
         *ngIf="hasCounters"
         type="button"
         (click)="onIncrease()"
         class="material-icons"
         [disabled]="disabled"
      ><i class="material-icons-outlined">
            add
         </i></button>
   </div>
</div>
<i
   *ngIf="isShowErrorIcon"
   class="material-icons validation-error-icon"
>error_outline</i>
<lcs-validation-overlay-panel
   [modelRef]="ngControl"
   [parentElement]="input"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>