import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BillDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bill-detail.model";
import { map, Observable } from "rxjs";

import { BillDetailService } from "../bill-detail.service";

@Component({
   selector: "owa-billdetails-list-view-more",
   templateUrl: "./billdetails-list-view-more.component.html",
})
export class BillDetailListViewMoreComponent {
   billDetailItems: Observable<BillDetailModel[]>;

   loading: Observable<boolean>;

   constructor(private activatedRoute: ActivatedRoute, private router: Router, billDetailService: BillDetailService) {
      this.billDetailItems = billDetailService.bill.pipe(map((bill) => bill.BillDetails));
      this.loading = billDetailService.loading;
   }

   onCloseClick(): void {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
   }
}
