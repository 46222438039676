<lcs-select
   lcsEndpointSelector
   *ngIf="selectorConfigured"
   [addBlankItem]="addBlankItem"
   [endpoint]="endpoint"
   [displayValueSourcePath]="displayValueSourcePath"
   [valueSourcePath]="valueSourcePath"
   [additionalInfoSourcePath]="additionalInfoSourcePath"
   [additionalDataFields]="additionalDataFields"
   [filters]="filters"
   [searchFields]="searchFields"
   [ngModel]="value"
   (ngModelChange)="onValueChange($event)"
   [name]="name"
   [displayName]="displayName"
   [selectorOptionTemplate]="selectorOptionTemplate"
>
</lcs-select>