import { Component, Input, OnInit } from "@angular/core";
import { ReportParameterRendererComponentBase } from "@lcs/reports/report-parameters/report-parameter-override-component.base";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ReportParameterValueStaticSourceItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-static-source-item.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { Vendor1099Service } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/vendor1099.service";
import { Observable, take } from "rxjs";

import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";

@Component({
   templateUrl: "year-1099-report-parameter.component.html",
   selector: "lcs-year-1099-report-parameter",
})
export class Year1099ReportParameterComponent implements OnInit {
   @Input() parameter: ReportReportParameterViewModel;

   @Input() disabled: boolean;

   @Input() name: string;

   @Input() displayName: string;

   @Input() hasAsk: boolean;

   @Input() report: ReportModel;

   dataLoading: boolean = true;

   constructor(private vendor1099Service: Vendor1099Service) {}

   ngOnInit() {
      let request = new Observable<any>();
      if (this.report && this.report.ReportID === Report.Vendor1099CopyB) {
         request = this.vendor1099Service.getVendor1099CopyBYearsCollection();
      } else {
         request = this.vendor1099Service.getGet1099YearsCollection();
      }

      request.pipe(take(1)).subscribe((years) => {
         this.parameter.ReportParameterValueSource.StaticValues = years.map((year) => {
            const staticValue = new ReportParameterValueStaticSourceItemModel();
            staticValue.DisplayValue = year.toString();
            staticValue.Value = year.toString();
            return staticValue;
         });
         this.dataLoading = false;
      });

      if (!this.displayName && this.parameter) {
         this.displayName = ReportParameterRendererComponentBase.getDisplayNameFromReportReportParameter(
            this.parameter.toReportReportParameterModel()
         );
      }
   }
}
