import { Directive, Host, Input, OnDestroy, OnInit, Self } from "@angular/core";
import { EntitySearchService } from "@lcs/entity-search/entity-search.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { StaticSetSelectorDirectiveBase } from "@lcs/select/directives/static-set-selector.directive.base";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { takeUntil } from "rxjs";

@Directive({
   selector: "[lcsStaticSetSelector]",
   providers: [EntitySearchService],
})
export class StaticSetSelectorDirective extends StaticSetSelectorDirectiveBase implements OnDestroy, OnInit {
   /** List of items to display in the item overlay. */
   @Input() set items(val: Array<SelectorItemModel>) {
      this.itemSet = val;
   }

   @Input() isEntitySearchDisabled: boolean;

   @Input() entityType: EntityType = EntityType.NotSet;

   // @HostListener("mousedown", ["$event.target"]) onClick(element) {
   //    // if (element.classList.contains("search-icon")) {
   //    //    this.entitySearchService.openStaticSearchRegister(this.entityType, this.items);
   //    // }
   // }

   private isEntitySearchable: boolean;

   private searchableEntities: Array<EntityType> = [EntityType.Property, EntityType.Unit, EntityType.InventoryItem];

   get items(): Array<SelectorItemModel> {
      return this.itemSet;
   }

   constructor(
      @Self() protected selectComponent: SelectComponent,
      @Host() @Self() private entitySearchService: EntitySearchService
   ) {
      super(selectComponent);
   }

   ngOnInit(): void {
      if (this.isEntitySearchDisabled) {
         this.isEntitySearchable = false;
      } else {
         this.isEntitySearchable = this.searchableEntities.some((se) => se === this.entityType);
      }
      this.selectComponent.isEntitySearchable = this.isEntitySearchable;
      if (this.isEntitySearchable) {
         this.entitySearchService.selectorItem
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((item: SelectorItemModel | null) => {
               if (item != null) {
                  //adding this check since search register component sends rowValue
                  if (item instanceof SelectorItemModel) {
                     this.selectComponent.value = item.value;
                     this.selectComponent.selectItem(item);
                  } else {
                     Object.keys(item).forEach((key) => {
                        if (key.includes("ID")) {
                           const selectedItem = this.itemSet.find((i) => i.value === item[key]);
                           if (selectedItem) {
                              this.selectComponent.value = selectedItem.value;
                              this.selectComponent.selectItem(selectedItem);
                           }
                        }
                     });
                  }
               }
            });
      }
      this.selectComponent.selectFirstItemOnSearch = true;
   }

   // ngAfterViewInit() {
   //    const inputElement = this.selectComponent.userInputWrapper.nativeElement.querySelector("input");
   //    this.ngZone.runOutsideAngular(() => {
   //       fromEvent<KeyboardEvent>(inputElement, "keydown")
   //          .pipe(takeUntil(this.unsubscribe))
   //          .subscribe((event) => {
   //             this.ngZone.run(() => {
   //                if (event.key === this.keyCode && this.selectComponent.isEntitySearchable) {
   //                   event.preventDefault();
   //                   this.entitySearchService.openStaticSearchRegister(this.entityType, this.items);
   //                }
   //             });
   //          });
   //    });
   // }

   ngOnDestroy() {
      super.ngOnDestroy();
   }
}
