/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable, isDevMode, OnDestroy } from "@angular/core";
import { AbstractControl, FormControlStatus, NgForm, UntypedFormGroup } from "@angular/forms";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import {
   BehaviorSubject,
   debounceTime,
   distinctUntilChanged,
   filter,
   map,
   merge,
   Observable,
   Subject,
   switchMap,
   takeUntil,
} from "rxjs";

import { RootFormDirective } from "../root-form-directive.type";
import { FormControlRegistrationModel } from "./form-control-registration.model";
import { FormRegistrationBaseService } from "./form-registration.base.service";
import { FormRegistrationModel } from "./form-registration.model";
import { convertFormStatusToEnum, FormStatuses } from "./form-statuses.enum";

@Injectable()
export class FormRegistrationService implements OnDestroy {
   allErrors: Observable<FormControlRegistrationModel[]>;

   focusFormControl: Observable<string>;

   get form(): RootFormDirective {
      return this.formSubject.value;
   }

   formId: string;

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   formStatusChange = new BehaviorSubject<FormStatuses>(null);

   // @ts-ignore ts-migrate(2322) FIXME: Type 'BehaviorSubject<null>' is not assignable to ... Remove this comment to see the full error message
   formSubject: BehaviorSubject<RootFormDirective> = new BehaviorSubject(null);

   private controlStatusChanges = new Subject<void>();

   private controls = new Array<FormControlRegistrationModel>();

   private controlRemoved = new Subject<AbstractControl>();

   private formElement: HTMLFormElement;

   private unsubscribe = new Subject<void>();

   private controlToFocusElementIdMap: Map<AbstractControl, string> = new Map<AbstractControl, string>();

   constructor(private formRegistrationBaseService: FormRegistrationBaseService) {
      this.allErrors = this.formSubject.pipe(
         filter((form) => form !== null),
         switchMap((form: NgForm) => {
            return merge(
               // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
               form.valueChanges.pipe(debounceTime(100), distinctUntilChanged()),
               this.controlStatusChanges.pipe(debounceTime(100)),
               this.formStatusChange.pipe(distinctUntilChanged()),
               form.ngSubmit
            ).pipe(
               map(() => {
                  return this.controls.filter((controlModel: FormControlRegistrationModel) => {
                     return controlModel.FormControl.errors;
                  });
               })
            );
         }),
         takeUntil(this.unsubscribe)
      );

      this.formSubject
         .pipe(
            filter((form: RootFormDirective): boolean => form !== null),
            switchMap((form: RootFormDirective) => {
               return form.form.statusChanges;
            }),
            distinctUntilChanged(),
            map((status: FormControlStatus): FormStatuses => convertFormStatusToEnum(status)),
            filter((status: FormStatuses): boolean => status !== this.formStatusChange.value),
            takeUntil(this.unsubscribe)
         )
         .subscribe((status: FormStatuses) => {
            this.formStatusChange.next(status);
         });
   }

   markFormAsTouchedAndDirty(): void {
      markFormGroupAsTouchedAndDirty(this.form.control);
   }

   updateFormValueAndValidity(): void {
      if (this.form) {
         this.form.form.updateValueAndValidity();
      }
   }

   markFormAsPristine(): void {
      if (this.form) {
         this.markFormGroupAsPristine(this.form.control);
      }
      this.formRegistrationBaseService.markFormGroupAsPristine(this.formId);
   }

   markFormGroupAsPristine(control: AbstractControl): void {
      control.markAsPristine();
      const children = (control as UntypedFormGroup).controls;
      if (children) {
         Object.keys(children).forEach((key: string) => {
            this.markFormGroupAsPristine(children[key]);
         });
      }
   }

   markAllFormsAsPristine() {
      if (this.form) {
         this.markFormGroupAsPristine(this.form.control);
      }
      this.formRegistrationBaseService.markAllFormsAsPristine();
   }

   markFormToDisableDeactivationDialogs() {
      const form = this.formRegistrationBaseService.forms.find((form) => form.formId === this.formId);
      if (form) {
         form.enableDeactivationDialogs = false;
      }
   }

   markFormToEnableDeactivationDialogs() {
      const form = this.formRegistrationBaseService.forms.find((form) => form.formId === this.formId);
      if (form) {
         form.enableDeactivationDialogs = true;
      }
   }

   registerForm(form: FormRegistrationModel): void {
      this.formRegistrationBaseService.forms.push(form);
      this.updateCurrentFormRegistration();
   }

   deregisterForm(formId: string): void {
      this.formRegistrationBaseService.forms = this.formRegistrationBaseService.forms.filter(
         (form) => form.formId !== formId
      );
      this.updateCurrentFormRegistration();
   }

   addControl(name: string, control: AbstractControl, displayName: string, focusElementId?: string): void {
      if (control) {
         const index = this.controls.findIndex(
            (controlModel: FormControlRegistrationModel) => controlModel.FormControl === control
         );
         if (index === -1) {
            this.controls.push(new FormControlRegistrationModel(name, control, displayName));
            this.wireupTouchHack(control);
            if (focusElementId) {
               if (this.controlToFocusElementIdMap.has(control)) {
                  if (this.controlToFocusElementIdMap.get(control) !== focusElementId && isDevMode()) {
                     console.warn(
                        `Remapping control to focusElementId="${focusElementId}". Previously mapped to "${this.controlToFocusElementIdMap.get(
                           control
                        )}" control=`,
                        control
                     );
                  }
               }
               this.controlToFocusElementIdMap.set(control, focusElementId);
            }

            // NOTE: explicitly declaring this with a type prevents a tslint deprecated error
            //       caused due to a signature confusion error if left untyped
            const touchedHackObservable: Observable<void> = <Observable<void>>control["touchedHack"];

            // This merge results in any touch event for any added control causing the FormRegistrationService
            // controlStatusChanges event to emit. This is required to get the validation error overlay to
            // display properly in cases related to touched status
            merge(control.statusChanges, touchedHackObservable)
               .pipe(takeUntil(this.getControlRemovedSubject(control)), takeUntil(this.unsubscribe))
               .subscribe(() => this.controlStatusChanges.next());
         }
      } else {
         if (isDevMode()) {
            console.warn(`addControl FAILED -> name=${name} displayName=${displayName} control==null`);
         }
      }
   }

   removeControl(control: AbstractControl): void {
      const index = this.controls.findIndex(
         (controlModel: FormControlRegistrationModel) => controlModel.FormControl === control
      );
      if (index > -1) {
         this.controls = [...this.controls.slice(0, index), ...this.controls.slice(index + 1)];
         this.controlRemoved.next(control);
      }
   }

   focusOnControl(control: FormControlRegistrationModel): void {
      if (!control) {
         if (isDevMode()) {
            console.warn(`focusOnControl(): Invalid null argument "control". Aborting.`);
         }
         return;
      }

      const cssFocusableSelector: string = `button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [href]:not([disabled]), [tabindex]:not([disabled])`;

      const focusElementId = this.controlToFocusElementIdMap.has(control.FormControl)
         ? this.controlToFocusElementIdMap.get(control.FormControl)
         : control.Name;

      const focusElement: HTMLElement = this.formElement.querySelector(`#${focusElementId}`) as HTMLElement;

      if (focusElement) {
         if (focusElement.matches(cssFocusableSelector)) {
            focusElement.focus();
         } else {
            const focusableElement: HTMLElement | null = focusElement.querySelector(cssFocusableSelector);
            if (focusableElement) {
               focusableElement.focus();
            } else {
               if (isDevMode()) {
                  console.warn(`Cannot focus on or find focusable element within control "${control.Name}"`);
               }
            }
         }
      } else {
         if (isDevMode()) {
            console.warn(`Cannot find control "${control.Name}" focusElementId=${focusElementId}`);
         }
      }
   }

   enableForm() {
      this.form.form.enable();
   }

   disableForm() {
      this.form.form.disable();
   }

   /*
disableFormWithoutEmittingEvents() is needed as disableForm() method has already been used in lots of components.
If we make changes to disableForm() we will need to make sure no controls in all the components that uses disableForm() are listening to the change event
So instead of back tracking all the components disableFormWithoutEmittingEvents() has been added to make sure control disable without emitting events.
*/
   disableFormWithoutEmittingEvents() {
      this.form.form.disable({ emitEvent: false });
   }

   enableFormWithoutEmittingEvents() {
      this.form.form.enable({ emitEvent: false });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   getInvalidControls(): Array<FormControlRegistrationModel> {
      return this.controls.filter((c) => c.FormControl?.invalid);
   }

   private updateCurrentFormRegistration() {
      if (this.formRegistrationBaseService.forms.length === 0) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLFormEle... Remove this comment to see the full error message
         this.formElement = null;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
         this.formId = null;
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         this.formSubject.next(null);
      } else {
         const lastForm = this.formRegistrationBaseService.forms[this.formRegistrationBaseService.forms.length - 1];
         if (lastForm.form !== this.formSubject.value) {
            this.formElement = lastForm.element;
            this.formId = lastForm.formId;
            this.formSubject.next(lastForm.form);
         }
      }
   }

   private getControlRemovedSubject(control: AbstractControl): Observable<AbstractControl> {
      return this.controlRemoved.pipe(
         filter((ctrl: AbstractControl) => ctrl === control),
         takeUntil(this.unsubscribe)
      );
   }

   // This hack is here because there is no other way to subscribe to form control touched events
   // see https://github.com/angular/angular/issues/10887
   private wireupTouchHack(control: AbstractControl) {
      control["touchedHack"] = new Subject<void>();
      control["_touched"] = false;
      Object.defineProperty(control, "touched", {
         set: function (value: boolean) {
            this._touched = value;
            if (value) {
               control["touchedHack"].next();
            }
         },
         get: function (): boolean {
            return this._touched;
         },
      });
   }
}
