import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OWAWebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-web-user-account.model";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";

export class OWASessionModel {
   public CurrentUser: WebUserModel;
   public CurrentWebUserAccount: OWAWebUserAccountModel;
   public CurrentAccount: OwnerModel;
   public CurrentOwnerProspectAccount: OwnerProspectModel;
   public AvailableAccounts: Array<OWAWebUserAccountModel>;
   public SiteHeaderImageURL: string;
   public GeneralMessage: string;
   public SystemwebprefernceInfo: Array<SystemWebPreferenceModel>;
   public LocationID: number;
}
