<div class="modal-header-border"></div>
<form class="change-password-form">
   <div class="flex-full field-controls">
      <label>Current Password *</label>
      <lcs-text-box
         inputType="password"
         [(ngModel)]="passwordResetModel.Currentpassword"
         name="current-password"
         displayName="Current Password"
         [required]="true"
      ></lcs-text-box>
   </div>
   <div class="flex-full field-controls">
      <label>New Password *</label>
      <span class="password-icon-placement">
         <owa-validate-password-format [passwordToValidate]="passwordResetModel.Newpassword">
         </owa-validate-password-format>
      </span>
      <lcs-text-box
         inputType="password"
         [(ngModel)]="passwordResetModel.Newpassword"
         name="newPasswordName"
         displayName="New Password"
         [required]="true"
      ></lcs-text-box>
   </div>
   <div class="flex-full field-controls">
      <label>Confirm New Password *</label>
      <lcs-text-box
         inputType="password"
         [(ngModel)]="passwordResetModel.ConfirmPassword"
         name="confirmPasswordName"
         displayName="Confirm New Password"
         [required]="true"
      ></lcs-text-box>
   </div>
   <div class="dialog-button-container field-controls">
      <lcs-progress-button
         [(status)]="progressButtonStatus"
         [raised]="true"
         (buttonClick)="changePassword()"
         class="change-password-buttons"
      >
         Change Password
      </lcs-progress-button>
   </div>
</form>