////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressPayloadFields {
   ID = 1,
   EntityType = 2,
   IsExpressReport = 3,
   ReportParameters = 4,
   ServiceManagerSavedListID = 5,
   Value = 6,
   AsOfDate = 7,
   Configuration = 8,
   EntityID = 9,
   File = 10,
   ToDate = 11,
   ChargeType = 12,
   EntitySet = 13,
   ValueSource = 14,
   FilterID = 15,
   SubDirective = 16,
   Filters = 17,
   Resource = 18,
   HistoryType = 19,
   QuickFilters = 20,
   ContactID = 21,
   ImmediateRun = 23,
   SearchText = 24,
   SavedFilterID = 25,
   EmailAutomatically = 27,
   EmailSubject = 28,
   EmailBody = 29,
}
