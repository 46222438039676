import {
   AfterViewInit,
   Component,
   EventEmitter,
   Input,
   OnDestroy,
   Output,
   TemplateRef,
   ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { OverlayPanelComponent } from "@lcs/overlay-panel/overlay-panel.component";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { debounceTime, Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-selector-overlay-panel",
   templateUrl: "./selector-overlay-panel.component.html",
})
export class SelectorOverlayPanelComponent implements OnDestroy, AfterViewInit {
   @Input() alignToRight: boolean;

   @Input() classes: string[];

   @Input() control: UntypedFormControl;

   @Input() disabled: boolean;

   @Input() displayName: string = "Value";

   @Input() errorMessage: string;

   @Input() additionalMessage: string;

   @Input() innerElementSelector: string;

   @Input() innerElementTags: Array<string>;

   @Input() set items(items: Array<SelectorItemModel>) {
      const itemSet = new SelectorItemSetModel();
      itemSet.items = items;
      this._itemSet = itemSet;
   }

   @Input() set itemSet(itemSet: SelectorItemSetModel) {
      this._itemSet = itemSet;
      this.updateOverlayPanelPosition();
   }

   @Input() itemSetIsLoading: boolean = false;

   @Input() parentElement: HTMLDivElement | HTMLLIElement;

   @Input() selectable = true;

   @Input() set selectedItemIndex(val: number | null) {
      if (
         val != null &&
         this.itemSet &&
         this.itemSet.items &&
         this._selectedItemIndex !== val &&
         val >= 0 &&
         val < this.itemSet.items.length
      ) {
         this._selectedItemIndex = val;
         this.scrollSelectedItemIntoView();
      } else {
         this._selectedItemIndex = null;
      }
   }

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() set show(val: boolean) {
      if (val !== this._show) {
         this._show = val;
         this.showChange.emit(this._show);
         this.scrollSelectedItemIntoView();
      }
   }

   @Input() staticOverlay: boolean;

   @Input() toggleOnClick: boolean = true;

   @Input() hideOnBlur: boolean = true;

   @Input() parentOverlayPanelRef: OverlayPanelComponent;

   @Input() overlayPanelStaticWidth: number;

   @ViewChild("overlayPanelComponentRef", { static: true }) overlayPanelComponentRef: OverlayPanelComponent;

   @Output() showChange = new EventEmitter<boolean>(false);

   @Output() selected = new EventEmitter<SelectorItemModel>(true);

   get show(): boolean {
      return this._show;
   }

   get selectedItemIndex(): number | null {
      return this._selectedItemIndex;
   }

   get itemSet(): SelectorItemSetModel {
      return this._itemSet;
   }

   private _show: boolean = false;

   private _selectedItemIndex: number | null;

   private _itemSet: SelectorItemSetModel;

   private unsubscribe = new Subject<void>();

   private selectedIndexChanged = new Subject<null>();

   constructor() {
      this.selectedIndexChanged
         .pipe(debounceTime(200), takeUntil(this.unsubscribe))
         .subscribe(() => this.selected.emit(this.itemSet.items[this.selectedItemIndex ?? 0]));
   }

   ngAfterViewInit() {
      setTimeout(() => {
         const selectedItemElement: HTMLElement | null = document.querySelector("div.selector-overlay-item-selected");
         if (selectedItemElement) {
            selectedItemElement.scrollIntoView();
         }
      }, 300);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onItemSelected(item: SelectorItemModel) {
      this.selected.emit(item);
      this.show = false;
   }

   onSelectedItemIndexChange(index: number) {
      if (
         index != null &&
         this.itemSet &&
         this.itemSet.items &&
         this._selectedItemIndex !== index &&
         index >= 0 &&
         index < this.itemSet.items.length
      ) {
         this._selectedItemIndex = index;
         this.selectedIndexChanged.next(null);
      } else {
         this._selectedItemIndex = null;
      }
   }

   updateOverlayPanelPosition() {
      this.overlayPanelComponentRef.updateOverlayPanelPosition();
   }

   private scrollSelectedItemIntoView() {
      setTimeout(() => {
         if (this.show) {
            const selectedItemElement: HTMLElement | null = document.querySelector(
               "div.selector-overlay-item-selected"
            );
            if (selectedItemElement) {
               selectedItemElement.scrollIntoView();
            }
         }
      });
   }
}
