import { PropertyType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/property-type.enum";
import { ScreeningMinimumDecision } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/screening-minimum-decision.enum";
import { StatementMethod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/statement-method.enum";

import { AddressModel } from "./address.model";
import { BankModel } from "./bank.model";
import { ChargeTypeBankModel } from "./charge-type-bank.model";
import { HistoryModel } from "./history.model";
import { ImageModel } from "./image.model";
import { LateFeeModel } from "./late-fee.model";
import { OwnerContractModel } from "./owner-contract.model";
import { OwnerModel } from "./owner.model";
import { PhoneNumberModel } from "./phone-number.model";
import { RecurringChargeModel } from "./recurring-charge.model";
import { ReservableAmenityModel } from "./reservable-amenity.model";
import { ScreeningFieldMappingModel } from "./screening-field-mapping.model";
import { SelectListItemModel } from "./select-list-item.model";
import { TextNumberPropertyModel } from "./text-number-property.model";
import { TextNumberModel } from "./text-number.model";
import { UnitModel } from "./unit.model";
import { UserDefinedValueModel } from "./user-defined-value.model";
import { UserModel } from "./user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class PropertyDetailsModel {
   Addresses: Array<AddressModel>;
   AmenityIDs: Array<number>;
   ARAutomationScheduleID: number;
   ARAutomationUseSystemPreference: boolean;
   Banks: Array<BankModel>;
   BillingName1: string;
   BillingName2: string;
   ChargeTypeBanks: Array<ChargeTypeBankModel>;
   ColorID?: number | null ;
   Comment: string;
   DefaultBankID: number;
   DefaultInspectorID?: number | null ;
   DefaultPhoneNumberTypeID: number;
   Email: string;
   FiscalYearEndDate?: Date | null ;
   FiscalYearStartDate?: Date | null ;
   FixedAmount: number;
   HistoryNotes: Array<HistoryModel>;
   Images: Array<ImageModel>;
   IsActive: boolean;
   IsAmRent: boolean;
   IsDaylightSavingsTime: boolean;
   IsEpayEnabled: boolean;
   IsEquifax: boolean;
   IsExperian: boolean;
   IsFixed: boolean;
   IsFixedRentOnly: boolean;
   IsLateChargeEnabled: boolean;
   IsLockboxEnabled: boolean;
   IsOptOutCreditReporting: boolean;
   IsOverrideAssignedUserID: boolean;
   IsOverrideFiscalYear: boolean;
   IsOverrideSystemPreference: boolean;
   IsOverwriteTimeZone: boolean;
   IsOwnerPayPerUnit: boolean;
   IsPercent: boolean;
   IsPercentFullChargeAmount: boolean;
   IsPercentRentOnly: boolean;
   IsPerDay: boolean;
   IsPerDayExcludeHolidays: boolean;
   IsPerDayExcludeWeekends: boolean;
   IsPerDayGrace: boolean;
   IsPerDayGraceExcludeHolidays: boolean;
   IsPerDayGraceExcludeWeekends: boolean;
   IsPerDayGraceNonRetroactive: boolean;
   IsPerDayOneMonth: boolean;
   IsPerDayUseInvoiceDueDate: boolean;
   IsPropertyLevelOnlineListings: boolean;
   IsRequestDecisionReport: boolean;
   IsSubtractSecurityDeposits: boolean;
   IsTimeZoneOffsetEnabled: boolean;
   IsTrackRentersInsurance: boolean;
   IsTransunion: boolean;
   IsUseMasterPolicy: boolean;
   LateFeeSetup: LateFeeModel;
   LateMinimumBalance: number;
   LogoFileID: number;
   ManagerName: string;
   MinimumDecision: ScreeningMinimumDecision;
   Name: string;
   OwnerCheckSetupID: number;
   OwnerCheckSetupName: string;
   Ownerships: Array<OwnerContractModel>;
   PercentAmount: number;
   PerDayAmount: number;
   PerDayGraceCount: number;
   PerDayGraceLimitCount: number;
   PerDayLateFeeLimitAmount: number;
   PhoneNumbers: Array<PhoneNumberModel>;
   PostingDay: number;
   PrimaryOwner: OwnerModel;
   PrimaryOwnerID: number;
   PropertyBankIDs: Array<number>;
   PropertyID: number;
   PropertyType: PropertyType;
   PublishedTWAReportsIds: Array<number>;
   RecurringCharges: Array<RecurringChargeModel>;
   RentChargeType: Array<SelectListItemModel>;
   RentChargeTypeIDs: Array<number>;
   ReservableAmenities: Array<ReservableAmenityModel>;
   ScreeningFieldMappings: Array<ScreeningFieldMappingModel>;
   ServiceManagerAssignedUserID?: number | null ;
   ShortName: string;
   SquareFootage: number;
   StatementMethod: StatementMethod;
   TaxID: string;
   textNumberIDs: Array<number>;
   textNumberPropertyIDs: Array<number>;
   textNumbers: Array<TextNumberModel>;
   textNumbersProperties: Array<TextNumberPropertyModel>;
   TimeZoneOffset: number;
   TWAReportTemplateIds: Array<number>;
   Units: Array<UnitModel>;
   UnitTypeID: number;
   UnitTypePropertyIDs: Array<number>;
   UserDefinedValues: Array<UserDefinedValueModel>;
   UserIDs: Array<UserModel>;
   UserPropertyIDs: Array<number>;
}
