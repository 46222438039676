<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && usersReportParameter.IsAsk === true)"
   >
      {{ usersReportParameter.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="usersReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-multi-select
   [name]="name"
   [displayName]="$any(usersReportParameter?.DisplayName)"
   [disabled]="disabled || (hasAsk === true && usersReportParameter.IsAsk === true) || !showOtherUsers"
   [valueSources]="valueSources"
   [ngModel]="selectedValues"
   [allowsSelectAll]="showOtherUsers"
   [entityValueSourceFilters]="appliedValueSourceFilters"
   (selectionChanged)="onSelectionChanged($event)"
></lcs-multi-select>
<lcs-checkbox-report-parameter
   *ngIf="!loading && showOtherUsers"
   [name]="name + '-includeInactiveUsers'"
   [displayName]="showInactiveUsersReportParameter.DisplayName"
   [disabled]="disabled || (hasAsk === true && usersReportParameter.IsAsk === true)"
   [parameter]="showInactiveUsersReportParameter"
>
   {{ showInactiveUsersReportParameter.DisplayName }}
</lcs-checkbox-report-parameter>