import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";

export class EntityLinkModel {
   entityType: EntityType;

   // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   get entityID(): number {
      if (!isNaN(this.value)) {
         return this.value;
      }

      if (!this.value) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
         return;
      }

      switch (this.entityType) {
         case EntityType.Tenant:
            return this.value.TenantID;
         case EntityType.Prospect:
            return this.value.ProspectID;
         case EntityType.Unit:
            return this.value.UnitID;
         case EntityType.Property:
            return this.value.PropertyID;
         case EntityType.Owner:
            return this.value.OwnerID;
         case EntityType.Vendor:
            return this.value.VendorID;
         case EntityType.ServiceManagerIssue:
            return this.value.ServiceManagerIssueID;
         case EntityType.OwnerProspects:
            return this.value.OwnerProspectID;
         default:
            console.error(`${this.entityType.toString()} is not a valid entity type`);
      }
   }

   // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   get displayName(): string {
      if (!isNaN(this.value)) {
         return this.value;
      }
      switch (this.entityType) {
         case EntityType.Tenant:
            return this.value.Name;
         case EntityType.Prospect:
            return this.value.Name;
         case EntityType.Unit:
            return this.value.Name;
         case EntityType.Property:
            return this.value.Name;
         case EntityType.Owner:
            return this.value.DisplayName;
         case EntityType.Vendor:
            return this.value.Name;
         case EntityType.ServiceManagerIssue:
            return this.value.Title;
         case EntityType.OwnerProspects:
            return this.value.Name;
         default:
            console.error(`${this.entityType.toString()} is not a valid entity type`);
      }
   }

   value: number | any;

   constructor(entityType: EntityType, value: number | any) {
      this.entityType = entityType;
      this.value = value;
   }
}
