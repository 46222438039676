import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

import { OverlayPanelComponent } from "../overlay-panel/overlay-panel.component";

@Component({
   selector: "lcs-information-overlay",
   templateUrl: "information-overlay.component.html",
})
export class InformationOverlayComponent {
   @Input() overlayPanelStaticWidth: number = 320;

   @Input() alignToRight: boolean;

   @Input() informationTemplate: TemplateRef<any>;

   @ViewChild(OverlayPanelComponent, { static: true }) overlay: OverlayPanelComponent;

   show: boolean = false;

   toggleOverlay() {
      this.show = !this.show;
      if (this.show) {
         this.overlay.showOverlay();
      } else {
         this.overlay.hideOverlay();
      }
   }

   onFocusOut() {
      if (this.show) {
         this.show = false;
      }
   }
}
