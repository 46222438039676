import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { Observable, Subject } from "rxjs";

import { AccountSignupViewModel } from "../models/account-signup-view.model";

@Injectable({
   providedIn: "root",
})
export class AccountSignupService {
   unsubscribe: Subject<void> = new Subject<void>();
   constructor(private httpClient: HttpClient, private apiRequestHelperService: ApiRequestHelperService) {}

   getSignupDetails(locationID: number): Observable<AccountSignupViewModel> {
      return this.httpClient.get<AccountSignupViewModel>(
         this.apiRequestHelperService.url + "/accountrecovery/SignupDetails?locationId=" + locationID,
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }

   signup(signupViewModel: AccountSignupViewModel): Observable<AccountSignupViewModel> {
      return this.httpClient.post<AccountSignupViewModel>(
         this.apiRequestHelperService.url + "/accountrecovery/Signup",
         JSON.stringify(signupViewModel),
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }
}
