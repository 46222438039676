import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LcsSlidePanelContentDirective } from "./slide-panel-content.directive";
import { LcsSlidePanelOutletComponent } from "./slide-panel-outlet.component";
import { LcsSlidePanelTitleDirective } from "./slide-panel-title.directive";
import { LcsSlidePanelComponent } from "./slide-panel.component";

@NgModule({
   imports: [CommonModule, PortalModule, RouterModule],
   declarations: [
      LcsSlidePanelContentDirective,
      LcsSlidePanelTitleDirective,
      LcsSlidePanelComponent,
      LcsSlidePanelOutletComponent,
   ],
   exports: [
      LcsSlidePanelContentDirective,
      LcsSlidePanelTitleDirective,
      LcsSlidePanelComponent,
      LcsSlidePanelOutletComponent,
   ],
})
export class LcsSlidePanelModule {}
