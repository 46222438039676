import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { FormControlRegistrationModel } from "../../../forms/form-registration/form-control-registration.model";

@Component({
   selector: "lcs-validation-summary",
   changeDetection: ChangeDetectionStrategy.OnPush,
   templateUrl: "validation-summary.component.html",
})
export class ValidationSummaryComponent {
   @Input() controls: FormControlRegistrationModel[];

   @Output() errorClick = new EventEmitter<FormControlRegistrationModel>();

   onErrorClick(control: FormControlRegistrationModel) {
      this.errorClick.emit(control);
   }
}
