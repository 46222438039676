<div #overlay class="sidebar-menu-overlay" [hidden]="!show">
   <div
      class="sidebar-menu"
      [style.width]="widthStyle | async"
      [style.left]="leftStyle | async"
      [style.transition]="transitionStyle | async"
   >
      <div class="sidebar-menu-scroller" [style.width]="scrollerWidthStyle | async">
         <lcs-sidebar-menu-page [menuItems]="menuItems"> </lcs-sidebar-menu-page>
      </div>
   </div>
</div>
