import { CdkScrollable } from "@angular/cdk/overlay";
import {
   AfterViewInit,
   ChangeDetectionStrategy,
   Component,
   ContentChild,
   Input,
   TemplateRef,
   ViewChild,
} from "@angular/core";

@Component({
   selector: "lcs-sidebar-layout",
   templateUrl: "sidebar-layout.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLayoutComponent implements AfterViewInit {
   @Input() leftEditable: boolean;

   @Input() leftHeader: string;

   @Input() centerHeader: string;

   @Input() rightEditable: boolean;

   @Input() rightHeader: string;

   @Input() isLeftCollapsed: boolean = false;

   @Input() overrideWidth: boolean = false;

   @Input() overrideHeight: boolean = false;

   @ContentChild("leftHeaderContent") leftHeaderContent: TemplateRef<any>;

   @ContentChild("left") left: TemplateRef<any>;

   @ContentChild("centerHeaderContent") centerHeaderContent: TemplateRef<any>;

   @ContentChild("center") center: TemplateRef<any>;

   @ContentChild("rightHeaderContent") rightHeaderContent: TemplateRef<any>;

   @ContentChild("right") right: TemplateRef<any>;

   @ViewChild("centerScrollable", { read: CdkScrollable, static: true }) cdkScrollable: CdkScrollable;

   constructor() {}

   ngAfterViewInit() {}
}
