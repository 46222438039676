import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { DialogContentComponent } from "@lcs/dialog/dialog-content-component.interface";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { EstimateStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eestimatestatus.enum";
import { EstimateApprovalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-approval.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { Subject } from "rxjs";

import { OWASessionService } from "../../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-estimate-detail-reject-dialog",
   templateUrl: "estimate-detail-reject.component.html",
   styleUrls: ["../signature/estimate-detail-signature.component.css"],
})
export class EstimateDetailRejectDialogComponent implements AfterViewInit, DialogContentComponent, OnDestroy {
   unsubscribe: Subject<void> = new Subject<void>();
   configuration: any;
   approvalModel: EstimateApprovalModel;
   progressButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   rejectMessage: string;
   isProcessing: boolean = false;

   constructor(
      private dialog: CurrentDialogService,
      private errorMessageService: ErrorMessageService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private owaSessionService: OWASessionService,
      private estimateService: EstimateService
   ) {
      this.approvalModel = new EstimateApprovalModel();
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.subscribe((pref) => {
         this.rejectMessage =
            pref.filter((f) => f.Name === "RejectionMessageEstimatesOWA").length !== 0
               ? pref.filter((f) => f.Name === "RejectionMessageEstimatesOWA")[0].Value
               : "";
      });
   }

   rejectClick(): void {
      this.progressButtonStatus = LcsProgressButtonStatus.InProgress;
      this.isProcessing = true;
      this.approvalModel.Signature = this.owaSessionService.OWASessionInfo.value.CurrentUser.EmailAddress;
      this.approvalModel.Status = EstimateStatus.Rejected;
      this.approvalModel.EstimateID = this.configuration;
      this.estimateService.ApproveRejectEstimate(this.approvalModel).subscribe(
         (am) => {
            this.progressButtonStatus = LcsProgressButtonStatus.Success;
            this.estimateService.approved.emit(false);
            setTimeout(() => this.dialog.closeDialog(), 1000);
         },
         (error) => {
            this.progressButtonStatus = LcsProgressButtonStatus.Error;
            this.errorMessageService.triggerHttpErrorMessage(error);
            this.isProcessing = false;
         }
      );
   }

   cancelClick() {
      this.dialog.closeDialog();
   }

   ngAfterViewInit() {
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      setTimeout(() => document.getElementById("commentInput").focus());
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
