export class ValueComparerHelper {
   static comparerNullCheck(v1: any, v2: any) {
      if ((v1 === null && v2 !== null) || (v1 !== null && v2 === null)) {
         return false;
      }
      return true;
   }

   static simpleComparer(v1: any, v2: any) {
      if (v1 == null && v2 == null) {
         return true;
      } else if (v1 == null || v2 == null) {
         return false;
      } else {
         return v1.toString().toLowerCase() === v2.toString().toLowerCase();
      }
   }
}
