import { Component } from "@angular/core";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";
import { Observable } from "rxjs";

import { ServiceIssueDetailService } from "../service-issue-detail.service";
import { ServiceIssuePreferences } from "../service-issue-preferences.interface";

@Component({
   selector: "owa-service-issue-detail-container",
   templateUrl: "service-issue-detail-container.component.html",
})
export class ServiceIssueDetailContainerComponent {
   loading = new Observable<boolean>();

   issue: Observable<ServiceManagerIssueModel>;

   preferences: Observable<ServiceIssuePreferences>;

   constructor(serviceIssueDetailService: ServiceIssueDetailService) {
      this.loading = serviceIssueDetailService.loading;
      this.issue = serviceIssueDetailService.issue;
      this.preferences = serviceIssueDetailService.preferences;
   }
}
