import { Params } from "@angular/router";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";

export class FilterPayloadService {
   static parseFiltersFromParams(params: Params, payloadField: ExpressPayloadFields): Array<FilterOption> {
      if (!params) {
         return [];
      }
      const filterKey = Object.keys(params).find(
         (key) => key.toLowerCase() === ExpressPayloadFields[payloadField].toLowerCase()
      );
      if (!filterKey) {
         return [];
      }
      const filters = this.parseFilterPayloads(params[filterKey]);
      if (payloadField === 20) {
         filters.forEach((filter) => {
            if (filter.FilterName === "ShowInactive" && filter.Operation === 16) {
               filter.FilterName = "CurrentUnitStatus";
            } else if (filter.FilterName === "ShowInactive") {
               filter.FilterName = "IsActive";
            }
            if (filter.FilterName === "MyProjectsOnly") {
               filter.FilterName = "AuthorUserID";
            }
            if (filter.FilterName === "ShowCompleted") {
               filter.FilterName = "IsClosed";
            }
         });
      }
      return filters;
   }

   static parseFilterPayloads(queryString: string): Array<FilterOption> {
      const filters = new Array<FilterOption>();
      if (!queryString) {
         return filters;
      }
      const filterValues = queryString.split(";");
      filterValues.forEach((filterString) => {
         const values = filterString.split(",");
         if (values.length > 2) {
            const name = values[0];
            const operation = this.getOperationFromString(values[1]);

            let filterOptionValues: string[];
            if ([FilterOperations.In, FilterOperations.NotIn, FilterOperations.Between].some((o) => o === operation)) {
               filterOptionValues = this.parseParenthesisValues(values);
            } else {
               let filterOptionValue = decodeURIComponent(values[2]);
               if (filterOptionValue.includes(`"`)) {
                  filterOptionValue = filterOptionValue.replace(/"/g, "");
               }
               filterOptionValues = [filterOptionValue];
            }
            filters.push(new FilterOption(name, operation, filterOptionValues));
         }
      });
      return filters;
   }

   static buildFilterQueryString(filters: Array<FilterOption>): string {
      let filterString = "";
      if (filters && filters.length > 0) {
         filterString = filters
            .map((filter) => {
               if (filter.FilterName.startsWith("UserDefinedValues")) {
                  return filter.toApiString(false);
               }

               let field;
               if (filter.FilterName.endsWith("Property.PropertyID")) {
                  field = filter.Label.replace(/\s/g, "");
               } else {
                  field = filter.FilterName;
               }
               let operation;
               if (filter.FilterName.endsWith("Property.PropertyID")) {
                  operation = this.getStringFromOperation(filter.FilterOperationToDisplay);
               } else {
                  operation = this.getStringFromOperation(filter.Operation);
               }
               if (!operation) {
                  return;
               }
               let value = "";
               if (
                  filter.FilterName.endsWith("Property.PropertyID") &&
                  [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filter.FilterOperationToDisplay)
               ) {
                  value = `(${filter.AdditionalData.map((v) => v.toString()).join(",")})`;
               } else if (filter.FilterName.endsWith("Property.PropertyID")) {
                  value = filter.AdditionalData.toString();
               } else if (
                  [FilterOperations.In, FilterOperations.NotIn, FilterOperations.Between].some(
                     (o) => o === filter.Operation
                  )
               ) {
                  // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                  value = `(${filter.Values.map((v) => v.toString()).join(",")})`;
               } else {
                  // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                  value = filter.Value.toString();
               }
               return `${field},${operation},${value}`;
            })
            .join(";");
      }
      return filterString;
   }

   static getOperationFromString(operation: string): FilterOperations {
      switch (operation.toLowerCase()) {
         case "lt":
            return FilterOperations.LessThan;
         case "ltn":
            return FilterOperations.LessThanOrNull;
         case "le":
            return FilterOperations.LessThanOrEqualTo;
         case "len":
            return FilterOperations.LessThanOrEqualToOrNull;
         case "gt":
            return FilterOperations.GreaterThan;
         case "gtn":
            return FilterOperations.GreaterThanOrNull;
         case "ge":
            return FilterOperations.GreaterThanOrEqualTo;
         case "gen":
            return FilterOperations.GreaterThanOrEqualToOrNull;
         case "ne":
            return FilterOperations.NotEqualTo;
         case "eq":
            return FilterOperations.EqualTo;
         case "in":
            return FilterOperations.In;
         case "ni":
            return FilterOperations.NotIn;
         case "bt":
            return FilterOperations.Between;
         case "ct":
            return FilterOperations.Contains;
         case "sw":
            return FilterOperations.StartsWith;
         case "ew":
            return FilterOperations.EndsWith;
         case "hv":
            return FilterOperations.HasValue;
         default:
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterOpera... Remove this comment to see the full error message
            return null;
      }
   }

   static getStringFromOperation(operation: FilterOperations): string {
      switch (operation) {
         case FilterOperations.LessThan:
            return "lt";
         case FilterOperations.LessThanOrNull:
            return "ltn";
         case FilterOperations.LessThanOrEqualTo:
            return "le";
         case FilterOperations.LessThanOrEqualToOrNull:
            return "len";
         case FilterOperations.GreaterThan:
            return "gt";
         case FilterOperations.GreaterThanOrNull:
            return "gtn";
         case FilterOperations.GreaterThanOrEqualTo:
            return "ge";
         case FilterOperations.GreaterThanOrEqualToOrNull:
            return "gen";
         case FilterOperations.NotEqualTo:
            return "ne";
         case FilterOperations.EqualTo:
            return "eq";
         case FilterOperations.In:
            return "in";
         case FilterOperations.NotIn:
            return "ni";
         case FilterOperations.Between:
            return "bt";
         case FilterOperations.Contains:
            return "ct";
         case FilterOperations.StartsWith:
            return "sw";
         case FilterOperations.EndsWith:
            return "ew";
         case FilterOperations.HasValue:
            return "hv";
         default:
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
            return null;
      }
   }

   // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   private static parseParenthesisValues(values: string[]): string[] {
      const list = values.slice(2);
      if (list && list.length > 0) {
         list[0] = list[0].replace("(", "");
         list[list.length - 1] = list[list.length - 1]
            .split("")
            .reverse()
            .join("")
            .replace(")", "")
            .split("")
            .reverse()
            .join("");
         return list;
      }
   }
}
