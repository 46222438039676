import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SidebarMenuItemModel } from "@lcs/sidebar-menu/models/sidebar-menu-item.model";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { OWADashboardTileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owa-dashboard-tile.model";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";

import { MenuItemModel } from "./models/menu-item.model";

@Injectable()
export class MenuService implements SessionCacheProvider, OnDestroy {
   public get currentMenu(): Array<MenuItemModel> {
      return this.currentMenuItemsSubject.value;
   }
   cacheKey = "menuItems";
   menuLoaded = new Subject<void>();
   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject(false);

   public currentMenuItemsSubject: BehaviorSubject<Array<MenuItemModel>> = new BehaviorSubject<Array<MenuItemModel>>(
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      null
   );
   public sidebarMenuItemsSubject: BehaviorSubject<Array<SidebarMenuItemModel>> = new BehaviorSubject<
      Array<SidebarMenuItemModel>
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   >(null);
   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private errorMessageService: ErrorMessageService,
      private cacheMonitorService: CacheMonitorService
   ) {
      this.cacheMonitorService.setServiceCacheState(this.cacheKey, false);
      this.cachePopulated.next(false);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         if (!this.cachePopulated.value) {
            this.populateCache();
         }
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.clearCache(true));
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.currentMenuItemsSubject.next(null);
      this.cachePopulated.next(false);
      this.cacheMonitorService.setServiceCacheState(this.cacheKey, false);
      if (sessionExpired) {
         localStorage.removeItem(this.cacheKey);
      }
   }

   public populateCache() {
      const storedValue = localStorage.getItem(this.cacheKey);
      if (storedValue) {
         const menuItems: Array<MenuItemModel> = JSON.parse(storedValue);
         this.currentMenuItemsSubject.next(menuItems);
         this.sidebarMenuItemsSubject.next(this.buildSidebarMenuItems(menuItems));
         this.cachePopulated.next(true);
         this.cacheMonitorService.setServiceCacheState(this.cacheKey, true);
      } else {
         this.apiService
            .directGet(this.apiService.getUrl("Menu"))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (menuItems) => {
                  if (menuItems) {
                     const jsonResult = menuItems.body;
                     localStorage.setItem(this.cacheKey, JSON.stringify(jsonResult));
                     this.currentMenuItemsSubject.next(jsonResult);
                     this.sidebarMenuItemsSubject.next(this.buildSidebarMenuItems(jsonResult));
                     this.cachePopulated.next(true);
                     this.cacheMonitorService.setServiceCacheState(this.cacheKey, true);
                  } else {
                     this.errorMessageService.triggerErrorMessage("Unable to review menu items.");
                  }
                  this.menuLoaded.next();
               },
               (_) => {
                  this.cacheMonitorService.reportError(this.cacheKey);
               }
            );
      }
   }

   buildSidebarMenuItems(menuItems: Array<MenuItemModel>): Array<SidebarMenuItemModel> {
      const sidebarMenuItems = new Array<SidebarMenuItemModel>();
      menuItems.forEach((mi) => {
         sidebarMenuItems.push(this.buildSidebarMenuItem(mi));
      });
      return sidebarMenuItems;
   }

   buildSidebarMenuItem(menuItem: MenuItemModel): SidebarMenuItemModel {
      const sidebarMenuItem = new SidebarMenuItemModel();
      sidebarMenuItem.label = menuItem.Name;
      sidebarMenuItem.routerLink = menuItem.Route;
      sidebarMenuItem.uniqueIdentifier = menuItem.MenuItemID;
      sidebarMenuItem.children = new Array<SidebarMenuItemModel>();
      sidebarMenuItem.tooltip = menuItem.Tooltip;
      if (menuItem.Children && menuItem.Children.length > 0) {
         menuItem.Children.forEach((mi) => {
            sidebarMenuItem.children.push(this.buildSidebarMenuItem(mi));
         });
      }
      return sidebarMenuItem;
   }

   GetDashboardOrderedTiles(): Observable<Array<OWADashboardTileModel>> {
      const endpoint = "Menu/GetOWADashboardTiles";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }
}
