import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { Subject, takeUntil } from "rxjs";

import { ActionBarService } from "../../../../../libraries/lcs/src/lib/action-bar/action-bar.service";
import { EntityEventService } from "../../../../../libraries/lcs/src/lib/entity-events/entity-event.service";

@Component({
   selector: "owa-collapsible-context-bar",
   templateUrl: "collapsible-context-bar.component.html",
})
export class CollapsibleContextBarComponent implements AfterViewInit, OnDestroy {
   @Input() isCollapsed: boolean;

   @Input() title: string;

   @Input() subTitle: string | null;

   @Input() hideSubTitle: boolean;

   @Input() menuTriggerVisible: boolean;

   @Input() isListViewToggleVisible: boolean;

   @Input() listViewToggleTitle: string;

   @Input() showRefresh: boolean;

   @Input() disableRefresh: boolean;

   @Input() extendContextBarOverActionBar: boolean = false;

   @Output() contextBarClicked = new EventEmitter<void>();

   @Output() showMenuClicked = new EventEmitter<boolean>();

   @Output() toggleClicked = new EventEmitter<void>();

   addActionBarWidth: boolean = false;

   private unsubscribe = new Subject<void>();

   constructor(private entityEventService: EntityEventService, private actionBarService: ActionBarService) {}

   ngAfterViewInit() {
      if (this.extendContextBarOverActionBar) {
         this.actionBarService.actionBarVisibilitySubject.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
            this.actionBarService.addTopPadding.next(val);
            this.addActionBarWidth = val;
         });
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      if (this.extendContextBarOverActionBar) {
         this.actionBarService.addTopPadding.next(false);
      }
   }

   listViewToggleBtnClicked(event) {
      event.stopPropagation();
      this.toggleClicked.emit();
   }

   onRefresh() {
      this.entityEventService.entityEvents.next(ExpressEntityEvents.Refresh);
   }
}
