import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconModule } from "@lcs/icon/icon.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { ProgressButtonExtComponent } from "./progress-button-ext.component";

@NgModule({
   imports: [CommonModule, LcsProgressButtonModule, IconModule],
   declarations: [ProgressButtonExtComponent],
   exports: [ProgressButtonExtComponent],
})
export class ProgressButtonExtModule {}
