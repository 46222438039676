import { FormControlStatus } from "@angular/forms";

/**
 * This enum was created pre-angular 13 and corresponds to the new FormControlStatus type
 * which is a union of the possible form status string values:
 * 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'
 */
export enum FormStatuses {
   Valid = "VALID",
   Disabled = "DISABLED",
   Invalid = "INVALID",
   Pending = "PENDING",
}

export function convertFormStatusToEnum(formControlStatus: FormControlStatus | null): FormStatuses {
   let status: FormStatuses;
   if (formControlStatus === FormStatuses.Disabled) {
      status = FormStatuses.Disabled;
   } else if (formControlStatus === FormStatuses.Invalid) {
      status = FormStatuses.Invalid;
   } else if (formControlStatus === FormStatuses.Pending) {
      status = FormStatuses.Pending;
   } else {
      status = FormStatuses.Valid;
   }
   return status;
}
