import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { RequiredSuperCallFlag } from "@lcs/component-interfaces/required-super-call.flag";
import { ValueAccessorBase } from "projects/libraries/lcs/src/lib/inputs-framework/value-accessor-base";

import { SubComponentStatusService } from "./sub-component-status.service";
import { SubComponentStatuses } from "./sub-component-statuses.enum";

@Component({
   selector: "lcs-value-accessor-sub-component-base",
   template: "",
})
export abstract class ValueAccessorSubComponentBase<T> extends ValueAccessorBase<T> implements OnDestroy {
   protected subComponentID: string;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      protected subComponentStatusService: SubComponentStatusService,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.registerSubComponent(this.constructor.name);
   }

   ngOnDestroy(): RequiredSuperCallFlag {
      this.deregisterSubComponent();
      return new RequiredSuperCallFlag();
   }

   protected deregisterSubComponent() {
      if (this.subComponentStatusService) {
         this.subComponentStatusService.deregisterSubComponent(this.subComponentID);
      }
   }

   protected updateSubComponentStatus(subComponentStatus: SubComponentStatuses) {
      if (this.subComponentStatusService) {
         this.subComponentStatusService.updateSubComponentStatus(this.subComponentID, subComponentStatus);
      }
   }

   private registerSubComponent(name: string) {
      if (this.subComponentStatusService) {
         this.subComponentID = this.subComponentStatusService.registerSubComponent(name);
      }
   }
}
