import { Pipe, PipeTransform } from "@angular/core";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";

import { ObjectMapResolverService } from "./object-map-resolver.service";

@Pipe({
   name: "valueFormatter",
})
export class ValueFormatterPipe implements PipeTransform {
   constructor(private objectMapResolverService: ObjectMapResolverService) {}

   transform(value: any, format: ExpressDataTypes): any {
      return this.objectMapResolverService.formatValueTemplate(value, format);
   }
}
