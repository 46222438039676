/* eslint-disable no-underscore-dangle */
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ExpressSessionStatusModel } from "../models/generated/express-session-status.model";

@Injectable({
   providedIn: "root",
})
export class SessionStatusService {
   static sessionStatusEndpoint = "SessionInformation";

   get currentSessionStatus(): ExpressSessionStatusModel | null {
      return this._currentSessionStatus;
   }

   applicationBuildDetected = new Subject<void>();

   applicationTimeoutDetected = new Subject<void>();

   private _currentSessionStatus: ExpressSessionStatusModel | null = null;

   constructor() {}

   updateSession(sessionStatus: ExpressSessionStatusModel) {
      this._currentSessionStatus = sessionStatus;
   }

   clearSession() {
      this._currentSessionStatus = null;
   }
}
