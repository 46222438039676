import { CDK_ROW_TEMPLATE, CdkRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
   selector: "lcs-row, tr[lcs-row]",
   template: CDK_ROW_TEMPLATE,
   changeDetection: ChangeDetectionStrategy.OnPush,
   exportAs: "lcsRow",
   providers: [{ provide: CdkRow, useExisting: LcsRowComponent }],
})
export class LcsRowComponent extends CdkRow {
   @HostBinding("class.lcs-row") class: boolean = true;

   @HostBinding("attr.role") role: string = "row";
}
