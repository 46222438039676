import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, map, mapTo, merge, Observable, of, shareReplay, Subject, switchMap } from "rxjs";

import { EstimateService } from "../../../../libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { EstimateDetailPreferences } from "./estimate-detail-preferences.interface";

@Injectable()
export class EstimateDetailService implements OnDestroy {
   loading = new Observable<boolean>();

   estimate: Observable<EstimateViewModel>;

   estimatePropertyName: string = "";

   preferences: Observable<EstimateDetailPreferences>;

   showPrintIcon = new BehaviorSubject<boolean>(true);

   estimateSubject = new Subject<EstimateViewModel>();

   unsubscribe: Subject<void> = new Subject<void>();

   private _loading = new Subject<boolean>();

   constructor(
      private estimateService: EstimateService,
      private errorMessageService: ErrorMessageService,
      private route: ActivatedRoute,
      private router: Router,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      // this.loading = this._loading.pipe(mapTo(false));
      const estimateID = this.route.paramMap.pipe(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
         map((paramMap: ParamMap) => parseInt(paramMap.get("id"))),
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         filter((id: number) => id && !isNaN(id)),
         distinctUntilChanged()
      );

      this.preferences = this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            return of({
               showDetails: false,
               showHistory: false,
               allowApproval: false,
               showClosedServiceIssues: false,
               showOpenServiceIssues: false,
            });
         }),
         map((preferences: SystemWebPreferenceModel[]) => ({
            showDetails: true,
            showHistory:
               preferences.filter((f) => f.Name === "IncludeHistoryEstimatesOWA").length !== 0 &&
               preferences.filter((f) => f.Name === "IncludeHistoryEstimatesOWA")[0].Value.toLocaleLowerCase() ===
                  "true"
                  ? true
                  : false,
            allowApproval:
               preferences.filter((f) => f.Name === "EnableApprovalRejectionEstimatesOWA").length !== 0 &&
               preferences
                  .filter((f) => f.Name === "EnableApprovalRejectionEstimatesOWA")[0]
                  .Value.toLocaleLowerCase() === "true"
                  ? true
                  : false,
            showClosedServiceIssues:
               preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA").length !== 0 &&
               preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA")[0].Value.toLocaleLowerCase() ===
                  "true"
                  ? true
                  : false,
            showOpenServiceIssues:
               preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA").length !== 0 &&
               preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA")[0].Value.toLocaleLowerCase() === "true"
                  ? true
                  : false,
         })),
         shareReplay(1)
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<EstimateViewModel | null>' is not... Remove this comment to see the full error message
      this.estimate = estimateID.pipe(
         switchMap((id: number) => {
            this._loading.next(true);
            this.estimateService.get(id).subscribe((e) => this.estimateSubject.next(e));
            return this.estimateSubject.asObservable();
         }),
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            this._loading.next(false);
            this.router.navigate(["/estimates"]);
            return of(null);
         }),
         shareReplay(1)
      );

      this.loading = merge(this._loading, combineLatest([this.preferences, this.estimateSubject]).pipe(mapTo(false)));
   }

   getEstimateSubject() {
      return this.estimateSubject.asObservable();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
