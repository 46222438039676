import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { globalVariables } from "@lcs/core/globals";
import { Observable, Subject, takeUntil } from "rxjs";

import { SidebarMenuItemModel } from "../models/sidebar-menu-item.model";
import { SidebarMenuPageState } from "../models/sidebar-menu-page-state.enum";
import { SidebarMenuService } from "../sidebar-menu.service";

@Component({
   selector: "lcs-sidebar-menu-page",
   templateUrl: "./sidebar-menu-page.component.html",
})
export class SidebarMenuPageComponent implements OnInit, OnDestroy {
   @Input() parentMenuItem: SidebarMenuItemModel;

   @Input() grandParentMenuItem: SidebarMenuItemModel;

   @Input() set menuItems(val: Array<SidebarMenuItemModel>) {
      this._menuItems = val;
      if (val) {
         this.menuItemsWithChildren = val.filter((v) => v.children && v.children.length > 0);
         this._menuItems.forEach((f) => (f.id = f.label.replace(/ /g, "")));
      }
   }

   get menuItems(): Array<SidebarMenuItemModel> {
      return this._menuItems;
   }

   menuItemsWithChildren = new Array<SidebarMenuItemModel>();

   overflowY = "hidden";

   left = "0";

   transitionStyle: Observable<string>;

   isIOS = globalVariables.isIOS;

   private unsubscribe = new Subject<void>();

   private _menuItems = new Array<SidebarMenuItemModel>();

   constructor(private sidebarMenuService: SidebarMenuService) {
      this.transitionStyle = this.sidebarMenuService.transitionStyle;

      this.sidebarMenuService.showPage.pipe(takeUntil(this.unsubscribe)).subscribe((uniqueIdentifier) => {
         if (
            (this.parentMenuItem && this.parentMenuItem.uniqueIdentifier === uniqueIdentifier) ||
            (this.parentMenuItem === undefined && uniqueIdentifier === this.sidebarMenuService.rootIdentifier)
         ) {
            this.show();
         } else if (
            (this.grandParentMenuItem && this.grandParentMenuItem.uniqueIdentifier === uniqueIdentifier) ||
            uniqueIdentifier === this.sidebarMenuService.rootIdentifier
         ) {
            this.hide(SidebarMenuPageState.hideRight);
         } else if (
            this.parentMenuItem &&
            this.parentMenuItem.children.some((child) => child.uniqueIdentifier === uniqueIdentifier)
         ) {
            this.hide(SidebarMenuPageState.hideLeft);
         } else if (this.parentMenuItem === undefined && uniqueIdentifier !== uniqueIdentifier) {
            this.hide(SidebarMenuPageState.hideLeft);
         }
      });
   }

   ngOnInit() {
      if (this.parentMenuItem) {
         this.hide(SidebarMenuPageState.hideRight);
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   goBack() {
      if (this.grandParentMenuItem) {
         this.sidebarMenuService.showPage.next(this.grandParentMenuItem.uniqueIdentifier);
      } else {
         this.sidebarMenuService.showPage.next(this.sidebarMenuService.rootIdentifier);
      }
      this.hide(SidebarMenuPageState.hideRight);
   }

   onMenuItemClick(menuItem: SidebarMenuItemModel) {
      if (menuItem.children && menuItem.children.length > 0) {
         this.hide(SidebarMenuPageState.hideLeft);
         this.sidebarMenuService.showPage.next(menuItem.uniqueIdentifier);
      } else if (
         menuItem.clickEvent ||
         (menuItem.routerLink && menuItem.routerLink.length > 0 && menuItem.routerLink[0].length > 0)
      ) {
         this.sidebarMenuService.show.next(false);
      }
   }

   private show() {
      // This prevents the scroll bar from showing during transition
      setTimeout(() => {
         this.overflowY = "auto";
      }, this.sidebarMenuService.transitionDuration.value);

      this.left = "0";
   }

   private hide(state: SidebarMenuPageState) {
      if (state === SidebarMenuPageState.hideRight) {
         this.left = "100%";
      } else {
         this.left = "this.sidebarMenuService.widthStyle.value";
      }
      this.overflowY = "hidden";
   }
}
