import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { ScrollableElementDimensions } from "@lcs/infinite-scroll/scrollable-element-dimensions.interface";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { IssuesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/serviceissues.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { FilterValueType } from "../../../../libraries/lcs/src/lib/filters/filter-value.types";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { ServiceIssueListItemModel } from "./models/service-issue-list-item.model";

@Component({
   selector: "owa-service-issue-list",
   templateUrl: "./service-issue-list.component.html",
   styleUrls: ["./service-issue-list.component.css"],
   providers: [DataTableFiltersService],
})
export class ServiceIssueListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   columns: string[] = [
      "ServiceManagerIssueID",
      "AssignedOpenDate",
      "IsClosed",
      "StatusName",
      "Title",
      "Description",
      "PropertyName",
   ];

   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   observableIssues: Observable<Array<ServiceIssueListItemModel>>;

   issueFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   appliedSideBarFilters: Array<FilterOption>;
   showOpen: boolean = false;
   showClosed: boolean = false;

   showFilters: boolean = false;

   threshold: string = "50%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;

   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _observableIssues = new BehaviorSubject<Array<any>>(null);
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(false);
   private unsubscribe = new Subject<void>();

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setFullYear(new Date().getFullYear() - 1), "MM/dd/yy", "en-US");

   constructor(
      private issueService: IssuesService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private errorMessageService: ErrorMessageService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.isInfiniteScrollLoading = this._isInfiniteScrollLoading.asObservable();
      this.observableIssues = this._observableIssues.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.issueFilters = this.getFilters();
      this.setDefaultDateFilter();
   }

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.issueFilters.find((f) => f.FilterOption.FilterName === "AssignedOpenDate").FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.issueFilters.find((f) => f.FilterOption.FilterName === "AssignedOpenDate").FilterOption
      );
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.issueService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const issuesList = response.body;
               const newIssues = [];
               issuesList.forEach((element) => {
                  const item = new ServiceIssueListItemModel(element);
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ServiceIssueListItemModel' is no... Remove this comment to see the full error message
                  newIssues.push(item);
               });
               return newIssues;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData = this._observableIssues && this._observableIssues.value ? this._observableIssues.value : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._observableIssues.next(newData);
               this.results.next(this._observableIssues.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll(event: ScrollableElementDimensions): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil(event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      const data = this._observableIssues.value ? this._observableIssues.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || data.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true, refreshData);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.issueFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const filterList: any[] = Array<DataTableFilterModel>();
      const openDateFilter = this.getDataTableFilterModel(
         "AssignedOpenDate",
         "Open Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between]
      );

      filterList.push(openDateFilter);

      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs) {
               this.showOpen =
                  prefs.find((a) => a.Name === "ShowOpenServiceIssuesOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       prefs.find((a) => a.Name === "ShowOpenServiceIssuesOWA").Value.toLocaleLowerCase() === "true"
                        ? true
                        : false
                     : false;
               this.showClosed =
                  prefs.find((a) => a.Name === "ShowClosedServiceIssuesOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       prefs.find((a) => a.Name === "ShowClosedServiceIssuesOWA").Value.toLocaleLowerCase() === "true"
                        ? true
                        : false
                     : false;

               const isClosedFiltervalueSourceModel = new ValueSourceModel();
               isClosedFiltervalueSourceModel.Type = ValueSourceTypes.Static;

               isClosedFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();

               if (this.showOpen && this.showClosed) {
                  isClosedFiltervalueSourceModel.StaticValues.push(
                     new SelectorItemModel(ConstantsService.unselected, "Open & Closed")
                  );
               }
               if (this.showClosed) {
                  isClosedFiltervalueSourceModel.StaticValues.push(new SelectorItemModel("true", "Closed"));
               }
               if (this.showOpen) {
                  isClosedFiltervalueSourceModel.StaticValues.push(new SelectorItemModel("false", "Open"));
               }

               const isClosedFilter = this.getDataTableFilterModel(
                  "IsClosed",
                  "Closed",
                  ExpressDataTypes.Boolean,
                  ExpressLayoutControlTypes.SingleSelector,
                  [FilterOperations.EqualTo],
                  isClosedFiltervalueSourceModel
               );
               filterList.push(isClosedFilter);
            }
         }
      );

      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.issueFilters = this.resetFilter(this.issueFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (dataType === ExpressDataTypes.Date) {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.Between, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
