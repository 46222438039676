<div
   class="reports-hub-container"
   [hidden]="isParametersCollapsed"
   [class.is-pdf-viewer-visible]="isPdfViewerVisible"
   cdkScrollable
>
   <owa-collapsible-context-bar
      class="rerun-report"
      [title]="menuTitle"
      [subTitle]="$any(report?.Description)"
      [listViewToggleTitle]="'Rerun Report'"
      [isListViewToggleVisible]="isPdfViewerVisible"
      [isCollapsed]="reportMenuHidden"
      (contextBarClicked)="reportMenuHidden = isPdfViewerVisible ? true : !reportMenuHidden"
      (toggleClicked)="reRunClicked()"
   ></owa-collapsible-context-bar>
   <lcs-side-bar-menu
      *ngIf="menuItems && report && report.ReportID"
      [hidden]="reportMenuHidden || isPdfViewerVisible"
      [reportID]="report.ReportID"
      [items]="menuItems"
      [currentEntityID]="report.ReportID"
      [hasSearch]="true"
      [useActionLinks]="true"
      (menuItemSelected)="onMenuItemSelected($event)"
   ></lcs-side-bar-menu>
   <div class="reports-hub-content">
      <owa-report-parameters
         *ngIf="reportReportParameters"
         [hidden]="isPdfViewerVisible"
         [parameters]="reportReportParameters"
         [overriddenReportParameterRenderer]="overriddenReportParameterRenderer"
      ></owa-report-parameters>
      <ng-container *ngIf="isPdfViewerVisible">
         <owa-pdf-viewer
            *ngIf="!isSeparated"
            [downloadUrl]="downloadUrl"
            [errorHeader]="errorHeader"
            [errorInformation]="errorInformation"
            [errorIcon]="errorIcon"
            [errorContentTemplate]="contentTemplate"
         >
            <ng-template #contentTemplate>
               <input
                  type="button"
                  value="Rerun Report"
                  (click)="reRunReport()"
               />
            </ng-template>
         </owa-pdf-viewer>
         <owa-multi-pdf-viewer
            *ngIf="isSeparated"
            [pdfs]="pdfs"
            [errorHeader]="errorHeader"
            [errorInformation]="errorInformation"
            [errorIcon]="errorIcon"
            [errorContentTemplate]="contentTemplate"
         >
            <ng-template #contentTemplate>
               <input
                  type="button"
                  value="Rerun Report"
                  (click)="reRunReport()"
               />
            </ng-template>
         </owa-multi-pdf-viewer>
      </ng-container>
   </div>
</div>