import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { TextMessagingReportPeriod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/text-messaging-report-period.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-report-period-type-report-parameter",
   templateUrl: "report-period-type-report-parameter.component.html",
})
export class ReportPeriodTypeReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   reportPeriodTypeParameter: ReportReportParameterViewModel;

   weekStartParameter: ReportReportParameterViewModel;

   weekStartDisabled: boolean;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.reportPeriodTypeParameter.IsAsk = isAsk;
      this.weekStartParameter.IsAsk = isAsk;

      this.reportParametersService.updateReportParameterAsk(ReportParameter.ReportPeriodType, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.WeekStart, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.reportPeriodTypeParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ReportPeriodType
      );
      this.reportPeriodTypeParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.weekStartParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.WeekStart
      );
      if (this.reportPeriodTypeParameter) {
         this.setWeekStartDisabled(this.reportPeriodTypeParameter.DefaultValue);
      }
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === ReportParameter.ReportPeriodType) {
               this.setWeekStartDisabled(updatedValue.value);
            }
         });
   }

   private setWeekStartDisabled(value: any) {
      this.weekStartDisabled =
         +value === TextMessagingReportPeriod.Daily || +value === TextMessagingReportPeriod.Monthly;
   }
}
