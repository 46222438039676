import { Component, OnDestroy, ViewChild } from "@angular/core";
import { SidebarMenuItemModel } from "@lcs/sidebar-menu/models/sidebar-menu-item.model";
import { SidebarMenuComponent } from "@lcs/sidebar-menu/sidebar-menu/sidebar-menu.component";
import { Observable, Subject } from "rxjs";

import { MenuService } from "../top-bar/menu/menu.service";
import { SideMenuService } from "./side-menu.service";

@Component({
   selector: "owa-side-menu",
   templateUrl: "./side-menu.component.html",
   styleUrls: ["./side-menu.component.css"],
})
export class SideMenuComponent implements OnDestroy {
   @ViewChild("sidebarMenu", { static: true }) sidebarMenuComponent: SidebarMenuComponent;
   public sideMenuIsVisible: Observable<boolean>;
   public menuItems: Observable<Array<SidebarMenuItemModel>>;
   private unsubscribe = new Subject<void>();

   constructor(private menuService: MenuService, private sideMenuService: SideMenuService) {
      this.sideMenuIsVisible = this.sideMenuService.sideMenuIsVisible;
      this.menuItems = this.menuService.sidebarMenuItemsSubject;
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   showChanged(event) {
      if (event !== this.sideMenuService.sideMenuIsVisible.value) {
         this.sideMenuService.sideMenuIsVisible.next(event);
      }
   }
}
