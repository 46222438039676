<owa-dashboard-tile
   [title]="'Owner Distributions'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'distributions'"
>
   <div *ngIf="observableOwnerDistributions != null; else noDistributions">
      <lcs-table [dataSource]="$any(observableOwnerDistributions | async)">
         <tr
            lcs-header-row
            *lcsHeaderRowDef="columns; sticky: false"
         ></tr>
         <tr
            lcs-row
            *lcsRowDef="let row; columns: columns"
         ></tr>

         <ng-container
            lcsColumnDef="Property"
            [width]="18"
            [priority]="1"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Property</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
            >{{row.Property}}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="Reference"
            [width]="6"
            [priority]="2"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Reference</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
               class="numeric"
            >{{row.Reference}}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="CheckDate"
            [width]="7"
            [priority]="3"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Check Date</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
            >{{row.CheckDate | date: 'MM/dd/yyyy'}}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="Amount"
            [width]="6"
            [priority]=4
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Amount</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
               class="numeric"
            >{{row.Amount | currency:'USD':'symbol':'1.2-2'}}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="NewRow"
            [width]="1"
            [priority]="5"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            ></th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index=index"
            >
               <div *ngIf="row.IsNew">
                  <span class="notification-icon"></span>
               </div>
            </td>
         </ng-container>
      </lcs-table>
   </div>
   <ng-template #noDistributions>
      <div class='no-result'>No Owner Distributions found</div>
   </ng-template>
</owa-dashboard-tile>