import { EntityEmbedOption } from "../base-options/embed-option";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class AccountingPeriodYearEmbedOptions extends EntityEmbedOption {
   static readonly AccountingPeriods: AccountingPeriodYearEmbedOptions = new AccountingPeriodYearEmbedOptions(
      "AccountingPeriods"
   );

   static readonly AccountingPeriodSeries: AccountingPeriodYearEmbedOptions = new AccountingPeriodYearEmbedOptions(
      "AccountingPeriodSeries"
   );

   static readonly CreateUser: AccountingPeriodYearEmbedOptions = new AccountingPeriodYearEmbedOptions("CreateUser");

   static readonly ListItem: AccountingPeriodYearEmbedOptions = new AccountingPeriodYearEmbedOptions("ListItem");

   static readonly UpdateUser: AccountingPeriodYearEmbedOptions = new AccountingPeriodYearEmbedOptions("UpdateUser");
}
