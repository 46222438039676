import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { DefaultChargeSettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/default-charge-setting.embed-options";
import { EntityTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/entity-type.embed-options";
import { SystemPreferenceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/system-preference.embed-options";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { SystemPreferencesCategory } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preferences-category.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DefaultChargeSettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/default-charge-setting.model";
import { EmailSettingsCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/email-settings-category.model";
import { EntityTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/entity-type.model";
import { EpayConvenienceFeesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-convenience-fees.model";
import { EpayCredentialsAuthenticationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-credentials-authentication.model";
import { LocationGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location-group.model";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { VirtualPostOfficeGeneralCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/virtual-post-office-general-category.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class SystemPreferencesService {
   static readonly ClearOrSetARAutomationSchedulesOnPropertiesResource = "ClearOrSetARAutomationSchedulesOnProperties";
   static readonly DefaultChargeSettingsResource = "DefaultChargeSettings";
   static readonly EntityTypeResource = "EntityType";
   static readonly EpayConvenienceFeesResource = "EpayConvenienceFees";
   static readonly GetAvidXchangePasswordResource = "GetAvidXchangePassword";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetFromAllLocationsResource = "GetFromAllLocations";
   static readonly HasDefaultSecurityDepositAssociatedWithChargeTypeResource =
      "HasDefaultSecurityDepositAssociatedWithChargeType";
   static readonly isEpayPassphraseSetResource = "isEpayPassphraseSet";
   static readonly PushNewEpayPasswordToLocationsResource = "PushNewEpayPasswordToLocations";
   static readonly SaveSystemPreferencesResource = "SaveSystemPreferences";
   static readonly SetNewEpayPasswordForLocationResource = "SetNewEpayPasswordForLocation";
   static readonly ShowRentersInsuranceSystemSettingsResource = "ShowRentersInsuranceSystemSettings";
   static readonly SystemPreferenceCategoryResource = "SystemPreferenceCategory";
   static readonly TestEmailSettingsCredentialsResource = "TestEmailSettingsCredentials";
   static readonly TestVPOCredentialsResource = "TestVPOCredentials";
   static readonly endpoint = "SystemPreferencesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: SystemPreference,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: SystemPreference,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SystemPreferenceModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SystemPreferenceModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: SystemPreferenceModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SystemPreferenceModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<SystemPreferenceModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SystemPreferenceModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: SystemPreference,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         SystemPreferencesService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: SystemPreference,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(SystemPreferencesService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   postClearOrSetARAutomationSchedulesOnPropertiesUrl(
      isClearAction?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (isClearAction !== undefined && isClearAction !== null) {
         additionalParameters.push("isClearAction=" + isClearAction);
      }
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/ClearOrSetARAutomationSchedulesOnProperties",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postClearOrSetARAutomationSchedulesOnProperties(
      isClearAction?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postClearOrSetARAutomationSchedulesOnPropertiesUrl(isClearAction, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getDefaultChargeSettingsUrl(
      itemID: number,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/DefaultChargeSettings",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultChargeSettings(
      itemID: number,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<DefaultChargeSettingModel> {
      return this.apiService.getSingle(this.getDefaultChargeSettingsUrl(itemID, embeds, fields, additionalParameters));
   }

   getDefaultChargeSettingsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/DefaultChargeSettings",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getDefaultChargeSettingsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | DefaultChargeSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DefaultChargeSettingModel>> {
      return this.apiService.getCollection(
         this.getDefaultChargeSettingsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postDefaultChargeSettingsUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/DefaultChargeSettings",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postDefaultChargeSettings(
      item?: DefaultChargeSettingModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<DefaultChargeSettingModel> {
      return this.apiService.postSingle(this.postDefaultChargeSettingsUrl(saveOptions, additionalParameters), item);
   }

   postDefaultChargeSettingsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/DefaultChargeSettings",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postDefaultChargeSettingsCollection(
      details?: Array<DefaultChargeSettingModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DefaultChargeSettingModel>> {
      return this.apiService.postCollection(
         this.postDefaultChargeSettingsCollectionUrl(saveOptions, additionalParameters),
         details
      );
   }

   postAllDefaultChargeSettingsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/DefaultChargeSettings",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postAllDefaultChargeSettingsCollection(
      details?: Array<DefaultChargeSettingModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DefaultChargeSettingModel>> {
      return this.apiService.postCollection(
         this.postAllDefaultChargeSettingsCollectionUrl(saveOptions, additionalParameters),
         details
      );
   }

   getEntityTypeCollectionUrl(
      filters?: Array<FilterOption> | null,
      embedOptions?: Array<string | EntityTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (embedOptions !== undefined && embedOptions !== null && embedOptions.length > 0) {
         additionalParameters.push("embedOptions=" + embedOptions);
      }
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/EntityType",
         null,
         null,
         filters,
         null,
         null,
         null,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEntityTypeCollection(
      filters?: Array<FilterOption> | null,
      embedOptions?: Array<string | EntityTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EntityTypeModel>> {
      return this.apiService.getCollection(
         this.getEntityTypeCollectionUrl(
            filters,
            embedOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getEpayConvenienceFeesUrl(updateDB?: boolean | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (updateDB !== undefined && updateDB !== null) {
         additionalParameters.push("updateDB=" + updateDB);
      }
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/EpayConvenienceFees",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpayConvenienceFees(
      updateDB?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<EpayConvenienceFeesModel> {
      return this.apiService.getSingle(this.getEpayConvenienceFeesUrl(updateDB, additionalParameters));
   }

   getGetAvidXchangePasswordUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/GetAvidXchangePassword",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetAvidXchangePassword(additionalParameters?: Array<string>): Observable<string> {
      return this.apiService.getSingle(this.getGetAvidXchangePasswordUrl(additionalParameters));
   }

   postGetByPostUrl(
      id: SystemPreference,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: SystemPreference,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<SystemPreferenceModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGetFromAllLocationsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (LocationIDs !== undefined && LocationIDs !== null && LocationIDs.length > 0) {
         additionalParameters.push("LocationIDs=" + LocationIDs);
      }
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/GetFromAllLocations",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getGetFromAllLocationsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SystemPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationGroupModel>> {
      return this.apiService.getCollection(
         this.getGetFromAllLocationsCollectionUrl(
            filters,
            embeds,
            fields,
            pageSize,
            pageNumber,
            LocationIDs,
            additionalParameters
         )
      );
   }

   getHasDefaultSecurityDepositAssociatedWithChargeTypeUrl(
      chargeTypeID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("chargeTypeID=" + chargeTypeID);
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/HasDefaultSecurityDepositAssociatedWithChargeType",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHasDefaultSecurityDepositAssociatedWithChargeType(
      chargeTypeID: number,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.getSingle(
         this.getHasDefaultSecurityDepositAssociatedWithChargeTypeUrl(chargeTypeID, additionalParameters)
      );
   }

   getisEpayPassphraseSetUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/isEpayPassphraseSet",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getisEpayPassphraseSet(additionalParameters?: Array<string>): Observable<Boolean> {
      return this.apiService.getSingle(this.getisEpayPassphraseSetUrl(additionalParameters));
   }

   postPushNewEpayPasswordToLocationsUrl(
      locations?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (locations !== undefined && locations !== null && locations.length > 0) {
         additionalParameters.push("locations=" + locations);
      }
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/PushNewEpayPasswordToLocations",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPushNewEpayPasswordToLocations(
      locations?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postPushNewEpayPasswordToLocationsUrl(locations, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postSaveSystemPreferencesUrl(
      category?: SystemPreferencesCategory | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (category !== undefined && category !== null) {
         additionalParameters.push("category=" + category);
      }
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/SaveSystemPreferences",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSaveSystemPreferences(
      category?: SystemPreferencesCategory | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postSaveSystemPreferencesUrl(category, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postSetNewEpayPasswordForLocationUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/SetNewEpayPasswordForLocation",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSetNewEpayPasswordForLocation(
      authenticationModel?: EpayCredentialsAuthenticationModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postSetNewEpayPasswordForLocationUrl(additionalParameters), authenticationModel)
         .pipe(map((response) => response.body));
   }

   getShowRentersInsuranceSystemSettingsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/ShowRentersInsuranceSystemSettings",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getShowRentersInsuranceSystemSettings(additionalParameters?: Array<string>): Observable<Boolean> {
      return this.apiService.getSingle(this.getShowRentersInsuranceSystemSettingsUrl(additionalParameters));
   }

   getSystemPreferenceCategoryUrl(
      category?: SystemPreferencesCategory | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (category !== undefined && category !== null) {
         additionalParameters.push("category=" + category);
      }
      return this.apiService.getUrl(
         SystemPreferencesService.endpoint + "/SystemPreferenceCategory",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSystemPreferenceCategory(
      category?: SystemPreferencesCategory | null,
      additionalParameters?: Array<string>
   ): Observable<any> {
      return this.apiService.getSingle(this.getSystemPreferenceCategoryUrl(category, additionalParameters));
   }

   postTestEmailSettingsCredentialsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/TestEmailSettingsCredentials",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestEmailSettingsCredentials(
      model?: EmailSettingsCategoryModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postTestEmailSettingsCredentialsUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postTestVPOCredentialsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         SystemPreferencesService.endpoint + "/TestVPOCredentials",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestVPOCredentials(
      authenticationModel?: VirtualPostOfficeGeneralCategoryModel | null,
      additionalParameters?: Array<string>
   ): Observable<VirtualPostOfficeGeneralCategoryModel> {
      return this.apiService
         .post(this.postTestVPOCredentialsUrl(additionalParameters), authenticationModel)
         .pipe(map((response) => response.body));
   }
}
