import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";

@Directive({
   selector: "[owaCheckNumberIcon]",
})
export class CheckNumberIconDirective implements AfterViewInit {
   @Input() iconElementID: string;

   private get el(): HTMLElement {
      return this.elementRef.nativeElement;
   }

   constructor(private readonly elementRef: ElementRef, private renderer: Renderer2) { }

   @HostListener("window:resize")
   onWindowResize() {
      this.ngAfterViewInit();
   }

   public ngAfterViewInit(): void {
      setTimeout(() => {
         // @ts-ignore FIXME: Object is possibly 'undefined'.
         const icon = this.el.ownerDocument.querySelector("#checkNumberIcon_" + this.iconElementID);
         if (icon) {
            if (this.el.scrollWidth <= this.el.clientWidth) {
               this.renderer.setStyle(icon, "visibility", "hidden");
            } else {
               this.renderer.setStyle(icon, "visibility", "visible");
            }
         }
      }, 0);
   }
}
