<ng-container [ngSwitch]="inputType">
   <ng-container *ngSwitchCase="'themeEdit'">
      <button
         #colorPickerInput
         class="caret-hidden"
         [type]="'button'"
         autocomplete="off"
         [style.width]="width"
         [style.height]="height"
         [disabled]="disabled"
         [cpSaveClickOutside]="false"
         [cpOKButton]="showOKButton"
         [cpCancelButton]="showCancelButton"
         [cpPosition]="'left'"
         [cpAlphaChannel]="displayAlphaChannel ? 'enabled' : 'disabled'"
         [colorPicker]="value"
         [cpOKButtonClass]="'mat-raised-button primary'"
         [cpDialogDisplay]="cpDialogDisplay"
         [(cpToggle)]="cpToggle"
         (colorPickerSelect)="onColorConfirmed($event)"
      >
         <ng-container
            *ngIf="icon"
            [ngTemplateOutlet]="icon"
         ></ng-container>
      </button>
   </ng-container>
   <ng-container *ngSwitchDefault>
      <input
         #colorPickerInput
         class="caret-hidden"
         [type]="inputType"
         autocomplete="off"
         [style.background]="value"
         [style.width]="width"
         [style.height]="height"
         [disabled]="disabled"
         [cpSaveClickOutside]="false"
         [cpOKButton]="showOKButton"
         [cpCancelButton]="showCancelButton"
         [cpPosition]="'auto'"
         [cpAlphaChannel]="displayAlphaChannel ? 'enabled' : 'disabled'"
         [(colorPicker)]="value"
         [cpOKButtonClass]="'mat-button mat-raised-button primary'"
         [cpDialogDisplay]="cpDialogDisplay"
         [(cpToggle)]="cpToggle"
      />
   </ng-container>
</ng-container>