import { UserPreferences } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-preferences.enum";

import { UserModel } from "./user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class UserPreferenceModel {
   MetaTag?: string;
   Name: string;
   PreferenceID: UserPreferences;
   User: UserModel;
   UserID: number;
   UserPreferenceID: number;
   Value: string;
}
