<div
   *ngIf="$any(isLoaded | async)"
   class="ownerships-section"
>
   <owa-ownerships-list
      [showReserve]='showReserve'
      [showOwnershipPercentage]='showOwnershipPercentage'
      [showSecurityDepositHeld]='showSecurityDepositHeld'
      [allowOwnerToEmailPropertyManager]='allowOwnerToEmailPropertyManager'
   >
   </owa-ownerships-list>
</div>