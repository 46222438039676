import { Injectable, OnDestroy } from "@angular/core";
import { ConfirmationDialogConfigurationModel } from "@lcs/dialog/components/confirmation-dialog-configuration.model";
import { ConfirmationService } from "@lcs/dialog/services/confirmation.service";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { Observable, Subject } from "rxjs";

import { EntityEventResult } from "./entity-event-result.enum";
import { EntityEventService } from "./entity-event.service";

@Injectable({
   providedIn: "root",
})
export class EntityDeleteService implements OnDestroy {
   deleting: Observable<boolean>;

   private _deleting = new Subject<boolean>();

   private unsubscribe = new Subject<void>();

   constructor(
      private entityInformationService: EntityInformationService,
      private entityEventService: EntityEventService,
      private confirmationService: ConfirmationService
   ) {
      this.deleting = this._deleting.asObservable();
   }

   setDeletingStatus(isDeleting: boolean): void {
      this._deleting.next(isDeleting);
   }

   confirmEntityDelete(entityType: EntityType | null, confirmationMessageOverride?: string) {
      switch (entityType) {
         case EntityType.MakeReadyPosts: {
            // make-ready-post-delete.service will handle the confirmation dialog
            this.entityEventService.entityEvents.next(ExpressEntityEvents.Delete);
            break;
         }
         default: {
            const confirmationConfiguration = this.constructConfirmationDialog(entityType, confirmationMessageOverride);
            confirmationConfiguration.accept = () => {
               this.entityEventService.entityEvents.next(ExpressEntityEvents.Delete);
            };
            confirmationConfiguration.reject = () => {
               this.entityEventService.buildAndEmitEntityEventResult(
                  ExpressEntityEvents.Delete,
                  EntityEventResult.Unset
               );
            };
            this.confirmationService.requestConfirmation(confirmationConfiguration);
         }
      }
   }

   confirmRowActionDelete(entityType: EntityType, confirmationMessageOverride?: string | null): Observable<boolean> {
      const confirmationConfiguration = this.constructConfirmationDialog(
         entityType,
         confirmationMessageOverride ?? undefined
      );
      const userInput = new Subject<boolean>();
      confirmationConfiguration.accept = () => {
         userInput.next(true);
      };
      confirmationConfiguration.reject = () => {
         userInput.next(false);
      };
      this.confirmationService.requestConfirmation(confirmationConfiguration);
      return userInput.asObservable();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private constructConfirmationDialog(entityType: EntityType | null, confirmationMessageOverride?: string) {
      const confirmationConfiguration = new ConfirmationDialogConfigurationModel();
      confirmationConfiguration.title = "Confirm Delete";
      if (confirmationMessageOverride) {
         confirmationConfiguration.message = confirmationMessageOverride;
      } else {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'EntityType | null' is not assign... Remove this comment to see the full error message
         const entityName = this.entityInformationService.getEntityTypeInformation(entityType).SingleLabel;
         confirmationConfiguration.message = `Are you sure you want to delete this ${entityName}?`;
      }
      confirmationConfiguration.showAcceptButton = true;
      confirmationConfiguration.acceptButtonText = "Yes";
      confirmationConfiguration.showRejectButton = true;
      confirmationConfiguration.rejectButtonText = "No";

      return confirmationConfiguration;
   }
}
