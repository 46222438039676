import { formatDate } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

import { RelativeDatesService } from "../../../inputs/date-picker/relative-dates.service";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { RelativeDateValueModel } from "../relative-date-picker/relative-date-value.model";
import { ReportParameterComponents } from "../report-parameter-components.enum";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-date-report-parameter",
   templateUrl: "date-report-parameter.component.html",
})
export class DateReportParameterComponent implements OnInit, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean = false;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() dateReportParameterType: ReportParameterComponents = ReportParameterComponents.Date;

   @Input() hasAsk: boolean;

   @Input() isUseMinDateForBlankValue: boolean | undefined = false;

   relativeDateValue: RelativeDateValueModel;

   reportParameterValueModel: ReportParameterValueModel;

   disableRelativeDates: boolean;

   private _parameter: ReportReportParameterViewModel;

   private _reportReportParameters: ReportReportParameterViewModel[];

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValue();
   }

   initializeParameters() {
      if (!this.parameter) {
         if (this.dateReportParameterType === ReportParameterComponents.Date) {
            this.parameter = this._reportReportParameters.filter(
               (p) => p.ReportParameterID === ReportParameter.AsOfDate
            )[0];
         } else {
            this.parameter = this._reportReportParameters.filter(
               (p) => p.ReportParameterID === ReportParameter.AsOfDate2
            )[0];
         }
      }
   }

   update(value: RelativeDateValueModel) {
      if (value) {
         if (this.isRelative && value.relativeDateOption) {
            this.reportParameterValueModel.value = RelativeDatesService.relativeDateEnumMap().get(
               value.relativeDateOption
            );
         } else if (value.dateValue) {
            this.reportParameterValueModel.value = formatDate(value.dateValue, "MM/dd/yyyy", GlobalsService.locale);
         } else {
            if (this.isUseMinDateForBlankValue) {
               this.reportParameterValueModel.value = new Date("0001-01-01T00:00:00Z").toDateString();
            } else {
               this.reportParameterValueModel.value = "";
            }
         }
      } else {
         this.reportParameterValueModel.value = "";
      }
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
      this.relativeDateValue = value;
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      const relativeDateValue = new RelativeDateValueModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );

      if (this.isRelative && RelativeDatesService.IsRelativeDateValue(this.reportParameterValueModel.value)) {
         relativeDateValue.relativeDateOption = RelativeDatesService.getEnumValue(this.reportParameterValueModel.value);
         relativeDateValue.dateValue = RelativeDatesService.getRelativeDate(relativeDateValue.relativeDateOption);
      } else {
         const dateValue = new Date(this.reportParameterValueModel.value);
         if (!isNaN(dateValue.getTime())) {
            relativeDateValue.dateValue = dateValue;
         }
      }
      this.relativeDateValue = relativeDateValue;
   }
}
