import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { LocationModule } from "../authentication/rm-location/rm-location.module";
import { AccountRecoveryComponent } from "./account-recovery.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LocationModule,
    LcsProgressButtonModule,
  ],
  declarations: [AccountRecoveryComponent],
})
export class AccountRecoveryModule { }
