import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, TemplateRef, ViewChildren } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ChartAccountsToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/chart-accounts-to-include/chart-accounts-to-include-report-parameter.component";
import { CombinedReportParameterModel } from "@lcs/reports/report-parameters/models/combined-report-parameter.model";
import { ReportReportParameterGroupModel } from "@lcs/reports/report-parameters/models/report-parameter-group.model";
import { ReportReportParameterComponentModel } from "@lcs/reports/report-parameters/models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "@lcs/reports/report-parameters/models/report-report-parameter.viewmodel";
import { ReportParameterComponents } from "@lcs/reports/report-parameters/report-parameter-components.enum";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { CurrentSystemPreferencesService } from "@lcs/session/current-system-preferences.service";
import sortBy from "lodash/sortBy";
import { ReportFieldDataType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-field-data-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ReportUserControlFrame } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-user-control-frame.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { OwnerCoverPageModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-cover-page.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { Subject, takeUntil } from "rxjs";

@Component({
   selector: "owa-report-parameters",
   templateUrl: "report-parameters.component.html",
})
export class ReportParametersComponent implements OnDestroy {
   @ViewChildren("tiles") set tiles(tiles: QueryList<ElementRef>) {
      if (tiles) {
         this._tiles = tiles;
         setTimeout(() => {
            this.setParameterTileHeights();
         });
      }
   }

   @Input() set parameters(value: Array<ReportReportParameterViewModel>) {
      if (value) {
         this.reportReportParameterGroups = new Array<ReportReportParameterGroupModel>();
         this.reportParameters = value.map(function (rp) {
            return rp.ReportParameterID;
         });
         this.notSupportedReportParametersArray = new Array<ReportParameter>();
         this.reportParametersService.initialize(value);
         this.groupReportParameters(value);
      }
   }

   @Input() set isMemorizedReport(value: boolean) {
      this.isRelative = value;
      this.hasAsk = value;
   }

   @Input() set isReportBatch(value: boolean) {
      this.isRelative = value;
      this.hasAsk = value;
   }

   @Input() isRelative: boolean = false;

   @Input() overriddenReportParameterRenderer: TemplateRef<any>;

   @Input() tileRowHeights = new Map<number, string>();

   @Input() ownerCoverPageInfo: OwnerCoverPageModel;

   @Input() report: ReportModel;

   @Output() notSupportedReportParameters: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

   @Output() ownerCoverPageInfoChanged = new EventEmitter<OwnerCoverPageModel>();

   lastGroupOrder = 99;

   maxReportParametersPerSection = 4;

   reportReportParameterGroups: Array<ReportReportParameterGroupModel>;

   reportFieldDataType = ReportFieldDataType;

   name: string;

   hasAsk: boolean;

   notSupportedReportParametersArray: Array<ReportParameter>;

   private baseHeight: number;

   private reportParameters = new Array<ReportParameter>();

   private componentRowSpans: Map<ReportParameterComponents, number>;

   private parameterRowSpans: Map<ReportParameter, number>;

   private _tiles: QueryList<ElementRef>;

   private isAccountingPeriodEnabled: boolean = false;

   private isJobCostingEnabled: boolean = false;

   private jobRelatedReportParameters: Array<ReportParameter> = [
      ReportParameter.JobID,
      ReportParameter.JobIDs,
      ReportParameter.IncludeInactiveJobs,
      ReportParameter.JobTypeID,
      ReportParameter.BatchPropsOrJobs,
      ReportParameter.BatchJobs,
      ReportParameter.IncludeJobBudgetComments,
   ];

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private currentSystemPreferencesService: CurrentSystemPreferencesService
   ) {
      this.currentSystemPreferencesService
         .getSystemPreferences([SystemPreference.AccountingPeriodEnable, SystemPreference.EnableJobCosting])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((pref) => {
            if (pref) {
               this.isAccountingPeriodEnabled =
                  GlobalsService.convertStringToBoolean(pref.get(SystemPreference.AccountingPeriodEnable)?.Value) ??
                  false;
               this.isJobCostingEnabled =
                  GlobalsService.convertStringToBoolean(pref.get(SystemPreference.EnableJobCosting)?.Value) ?? false;
            }
         });

      this.buildRowSpans();
   }

   onOwnerCoverPageInfoChanged(ownerCoverPageInfo: OwnerCoverPageModel) {
      this.ownerCoverPageInfoChanged.emit(ownerCoverPageInfo);
   }
   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   private setParameterTileHeights() {
      this._tiles.forEach((tile, index) => {
         let height = 0;
         for (const element of tile.nativeElement.children) {
            height += element.offsetHeight;
         }
         if (!this.baseHeight) {
            this.baseHeight = height / tile.nativeElement.children.length;
         }
         this.tileRowHeights.set(index, `span ${Math.max(Math.round(height / this.baseHeight), 1)}`);
      });
   }

   private getAllCombinedReportParameters(hasAccountingPeriodParameter: boolean): CombinedReportParameterModel[] {
      // order matters with combined parameters containing the same report parameters - add higher overriding components first
      const combinedParameters = new Array<CombinedReportParameterModel>();

      const balanceDueValuesToInclude = new CombinedReportParameterModel(
         ReportParameterComponents.BalanceDueValuesToInclude
      );
      balanceDueValuesToInclude.reportParameters = [
         ReportParameter.ValuesToInclude,
         ReportParameter.ValuesToIncludeAmount,
      ];
      combinedParameters.push(balanceDueValuesToInclude);

      const camDivExpMethod = new CombinedReportParameterModel(ReportParameterComponents.CAMDivExpMethod);
      camDivExpMethod.reportParameters = [
         ReportParameter.CAMDIVEXPMETHOD,
         ReportParameter.IsUseProRataShare,
         ReportParameter.IsUseAdminFees,
         ReportParameter.CAMTenantUDF,
      ];
      combinedParameters.push(camDivExpMethod);

      const labelSelection = new CombinedReportParameterModel(ReportParameterComponents.LabelSelection);
      labelSelection.reportParameters = [
         ReportParameter.LABELTYPE,
         ReportParameter.FONTNAME,
         ReportParameter.FONTSIZE,
         ReportParameter.FONTBOLD,
         ReportParameter.FONTITAL,
         ReportParameter.STARTINGLABEL,
         ReportParameter.DownOnStartingLabel,
      ];
      combinedParameters.push(labelSelection);

      const yearRange = new CombinedReportParameterModel(ReportParameterComponents.YearRange);
      yearRange.reportParameters = [
         ReportParameter.STARTYEAR,
         ReportParameter.ENDYEAR,
         ReportParameter.StartDate,
         ReportParameter.EndDate,
      ];
      combinedParameters.push(yearRange);

      const crpPreparerParameters = new CombinedReportParameterModel(ReportParameterComponents.CrpPreparerParameters);
      crpPreparerParameters.reportParameters = [
         ReportParameter.CRPSignatureDate,
         ReportParameter.CRPPreparedByAgent,
         ReportParameter.CRPPreparerName,
         ReportParameter.CRPPreparerDefaultPhone,
         ReportParameter.CRPPreparerInformationOverridePhoneChecked,
         ReportParameter.CRPPreparerInformationOverridePhone,
      ];
      combinedParameters.push(crpPreparerParameters);

      const crpOwnerParameters = new CombinedReportParameterModel(ReportParameterComponents.CrpOwnerParameters);
      crpOwnerParameters.reportParameters = [
         ReportParameter.CRPNAME,
         ReportParameter.CRPADDRESS,
         ReportParameter.CRPPhone,
         ReportParameter.CRPOverridePhoneChecked,
         ReportParameter.CRPOverridePhone,
      ];
      combinedParameters.push(crpOwnerParameters);

      const dateRange = new CombinedReportParameterModel(ReportParameterComponents.DateRange);
      dateRange.reportParameters = [ReportParameter.StartDate, ReportParameter.EndDate];
      if (hasAccountingPeriodParameter) {
         if (this.isAccountingPeriodEnabled) {
            dateRange.reportParameterComponent = ReportParameterComponents.DateRangeWithAccountingPeriod;
         }
         dateRange.reportParameters.push(ReportParameter.StartPeriodID);
         dateRange.reportParameters.push(ReportParameter.EndPeriodID);
         dateRange.reportParameters.push(ReportParameter.RunByPeriod);
      }
      combinedParameters.push(dateRange);

      const dateRange2 = new CombinedReportParameterModel(ReportParameterComponents.DateRange2);
      dateRange2.reportParameters = [ReportParameter.StartDate2, ReportParameter.EndDate2];
      if (hasAccountingPeriodParameter) {
         if (this.isAccountingPeriodEnabled) {
            dateRange2.reportParameterComponent = ReportParameterComponents.DateRangeWithAccountingPeriod2;
         }
         dateRange2.reportParameters.push(ReportParameter.StartPeriodID2);
         dateRange2.reportParameters.push(ReportParameter.EndPeriodID2);
         dateRange2.reportParameters.push(ReportParameter.RunByPeriod2);
      }
      combinedParameters.push(dateRange2);

      if (hasAccountingPeriodParameter) {
         const asOfDate = new CombinedReportParameterModel(
            this.isAccountingPeriodEnabled
               ? ReportParameterComponents.AsOfDateWithAccountingPeriod
               : ReportParameterComponents.Date
         );
         asOfDate.reportParameters = [
            ReportParameter.AsOfDate,
            ReportParameter.AsOfPeriodID,
            ReportParameter.RunByPeriod,
         ];
         combinedParameters.push(asOfDate);

         const asOfDate2 = new CombinedReportParameterModel(
            this.isAccountingPeriodEnabled
               ? ReportParameterComponents.AsOfDateWithAccountingPeriod2
               : ReportParameterComponents.Date2
         );
         asOfDate2.reportParameters = [
            ReportParameter.AsOfDate2,
            ReportParameter.AsOfPeriodID2,
            ReportParameter.RunByPeriod2,
         ];
         combinedParameters.push(asOfDate2);
      }

      const chartAccountsToInclude = new CombinedReportParameterModel(ReportParameterComponents.ChartAccountsToInclude);
      chartAccountsToInclude.reportParameters = [ReportParameter.GLAccountIDs, ReportParameter.CHARTMAPID];
      chartAccountsToInclude.displayName = ChartAccountsToIncludeReportParameterComponent.defaultDisplayName;
      combinedParameters.push(chartAccountsToInclude);

      const consolidateAddressVendor1096 = new CombinedReportParameterModel(
         ReportParameterComponents.ConsolidateAddressVendor1096
      );
      consolidateAddressVendor1096.reportParameters = [
         ReportParameter.CONSOLIDATE1096,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLADDR2,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLZIP,
         ReportParameter.CONSOLTAXID,
         ReportParameter.ConsolidatedFax,
         ReportParameter.ConsolidatedContact,
      ];
      combinedParameters.push(consolidateAddressVendor1096);

      const consolidateAddressVendor1099 = new CombinedReportParameterModel(
         ReportParameterComponents.ConsolidateAddressVendor1099
      );
      consolidateAddressVendor1099.reportParameters = [
         ReportParameter.CONSOLIDATE1099,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLADDR2,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLZIP,
         ReportParameter.CONSOLTAXID,
      ];
      combinedParameters.push(consolidateAddressVendor1099);

      const consolidateAddressLoan1096 = new CombinedReportParameterModel(
         ReportParameterComponents.ConsolidateAddressLoan
      );
      consolidateAddressLoan1096.reportParameters = [
         ReportParameter.Loan1098AddressToUse,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLZIP,
         ReportParameter.CONSOLTAXID,
         ReportParameter.ConsolidatedFax,
         ReportParameter.ConsolidatedContact,
      ];
      combinedParameters.push(consolidateAddressLoan1096);

      const consolidateAddressLoan1098 = new CombinedReportParameterModel(
         ReportParameterComponents.ConsolidateAddressLoan
      );
      consolidateAddressLoan1098.reportParameters = [
         ReportParameter.Loan1098AddressToUse,
         ReportParameter.CONSOLPHONE,
         ReportParameter.CONSOLEMAIL,
         ReportParameter.CONSOLNAME,
         ReportParameter.CONSOLADDR1,
         ReportParameter.CONSOLCITY,
         ReportParameter.CONSOLSTATE,
         ReportParameter.CONSOLZIP,
         ReportParameter.CONSOLTAXID,
      ];
      combinedParameters.push(consolidateAddressLoan1098);

      const propertiesOwnersAllUseOwnerProperties = new CombinedReportParameterModel(
         ReportParameterComponents.PropertiesAndOwnersToInclude
      );
      propertiesOwnersAllUseOwnerProperties.reportParameters = Array.prototype.concat(
         ReportParameter.PropOwnerIDs,
         ReportParameter.RUNBYOWNER,
         ReportParameter.INCLUDEINACTIVEOWNERS,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID,
         ReportParameter.USEOWNERPROPERTIES,
         ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES
      );
      combinedParameters.push(propertiesOwnersAllUseOwnerProperties);

      const propertiesOwnersAll = new CombinedReportParameterModel(
         ReportParameterComponents.PropertiesAndOwnersToInclude
      );
      propertiesOwnersAll.reportParameters = Array.prototype.concat(
         ReportParameter.PropOwnerIDs,
         ReportParameter.RUNBYOWNER,
         ReportParameter.INCLUDEINACTIVEOWNERS,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID
      );
      combinedParameters.push(propertiesOwnersAll);

      const propertiesOwnersInactiveProps = new CombinedReportParameterModel(
         ReportParameterComponents.PropertiesAndOwnersToInclude
      );
      propertiesOwnersInactiveProps.reportParameters = Array.prototype.concat(
         ReportParameter.PropOwnerIDs,
         ReportParameter.RUNBYOWNER,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID
      );
      combinedParameters.push(propertiesOwnersInactiveProps);

      const propertiesOwners = new CombinedReportParameterModel(ReportParameterComponents.PropertiesAndOwnersToInclude);
      propertiesOwners.reportParameters = Array.prototype.concat(ReportParameter.PropOwnerIDs);
      combinedParameters.push(propertiesOwners);

      const selectUnit = new CombinedReportParameterModel(ReportParameterComponents.SelectUnit);
      selectUnit.reportParameters = [
         ReportParameter.UNITID,
         ReportParameter.PropertyID,
         ReportParameter.SHOWINACTIVEPROPS,
      ];
      combinedParameters.push(selectUnit);

      const propertiesUseOwnerProperties = new CombinedReportParameterModel(
         ReportParameterComponents.PropertiesAndOwnersToInclude
      );
      propertiesUseOwnerProperties.reportParameters = Array.prototype.concat(
         ReportParameter.PropertyIDs,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID,
         ReportParameter.USEOWNERPROPERTIES,
         ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES
      );
      combinedParameters.push(propertiesUseOwnerProperties);

      const properties = new CombinedReportParameterModel(ReportParameterComponents.PropertiesAndOwnersToInclude);
      properties.reportParameters = Array.prototype.concat(
         ReportParameter.PropertyIDs,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID
      );
      combinedParameters.push(properties);

      const HistoryCategoriesToInclude = new CombinedReportParameterModel(
         ReportParameterComponents.HistoryCategoriesToInclude
      );
      HistoryCategoriesToInclude.reportParameters = Array.prototype.concat(
         ReportParameter.HISTCATEGORYIDS,
         ReportParameter.IncludeInactiveHistoryCategories
      );
      combinedParameters.push(HistoryCategoriesToInclude);

      const usersToInclude = new CombinedReportParameterModel(ReportParameterComponents.UsersToInclude);
      usersToInclude.reportParameters = Array.prototype.concat(
         ReportParameter.USERIDS,
         ReportParameter.INCLUDEINACTIVEUSERS
      );
      combinedParameters.push(usersToInclude);

      const vendors = new CombinedReportParameterModel(ReportParameterComponents.VendorReportParameter);
      vendors.reportParameters = Array.prototype.concat(
         ReportParameter.VendorIDs,
         ReportParameter.IncludeInactiveVendors
      );
      combinedParameters.push(vendors);

      const owners = new CombinedReportParameterModel(ReportParameterComponents.OwnersToInclude);
      owners.reportParameters = Array.prototype.concat(
         ReportParameter.OWNERIDS,
         ReportParameter.INCLUDEINACTIVEOWNERS,
         ReportParameter.SHOWINACTIVEPROPS,
         ReportParameter.GroupID
      );
      combinedParameters.push(owners);

      const ownerExclusions = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      ownerExclusions.reportParameters = Array.prototype.concat(
         ReportParameter.HIDEMAILINGLABEL,
         ReportParameter.HIDEOWNERNUMBERS,
         ReportParameter.HIDERESERVE,
         ReportParameter.HIDESDHELD,
         ReportParameter.HIDEPASSTHRU,
         ReportParameter.HIDEUNDEPOSITEDFUNDS,
         ReportParameter.IsHideVendorIfApplicable,
         ReportParameter.HideDistributionBreakdown
      );
      combinedParameters.push(ownerExclusions);

      const ownerExclusionsHiddenPassThru = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      ownerExclusionsHiddenPassThru.reportParameters = Array.prototype.concat(
         ReportParameter.HIDEMAILINGLABEL,
         ReportParameter.HIDEOWNERNUMBERS,
         ReportParameter.HIDERESERVE,
         ReportParameter.HIDESDHELD,
         ReportParameter.HIDEUNDEPOSITEDFUNDS,
         ReportParameter.IsHideVendorIfApplicable
      );
      combinedParameters.push(ownerExclusionsHiddenPassThru);

      const tenantUtilities = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      tenantUtilities.reportParameters = Array.prototype.concat(
         ReportParameter.SHOWDILOANS,
         ReportParameter.SHOWPERDAYLATE
      );
      combinedParameters.push(tenantUtilities);

      const tenantInclusions = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      tenantInclusions.reportParameters = Array.prototype.concat(
         ReportParameter.SHOWUTILSTMT,
         ReportParameter.SHOWUTILREADS
      );
      combinedParameters.push(tenantInclusions);

      const showOnReportCustProfile = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      showOnReportCustProfile.reportParameters = Array.prototype.concat(
         ReportParameter.USERDEF,
         ReportParameter.AUTOCH,
         ReportParameter.TRANH,
         ReportParameter.HIST,
         ReportParameter.ISSUE,
         ReportParameter.OPENREC,
         ReportParameter.CustomerProfileIncludeAddresses,
         ReportParameter.CustomerProfileIncludePhone,
         ReportParameter.IncludeBirthdateAndAge
      );
      combinedParameters.push(showOnReportCustProfile);

      const showOnReport = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      showOnReport.reportParameters = Array.prototype.concat(
         ReportParameter.USERDEF,
         ReportParameter.AUTOCH,
         ReportParameter.TRANH,
         ReportParameter.HIST,
         ReportParameter.ISSUE,
         ReportParameter.OPENREC,
         ReportParameter.CustomerProfileIncludeAddresses,
         ReportParameter.CustomerProfileIncludePhone
      );
      combinedParameters.push(showOnReport);

      const showOnReportProspectProfile = new CombinedReportParameterModel(
         ReportParameterComponents.ShowOnReportParameter
      );
      showOnReportProspectProfile.reportParameters = Array.prototype.concat(
         ReportParameter.USERDEF,
         ReportParameter.TRANH,
         ReportParameter.HIST,
         ReportParameter.OPENREC,
         ReportParameter.CustomerProfileIncludeAddresses,
         ReportParameter.CustomerProfileIncludePhone,
         ReportParameter.IncludeBirthdateAndAge,
         ReportParameter.IncludeStageHistory
      );
      combinedParameters.push(showOnReportProspectProfile);

      if (this.isJobCostingEnabled) {
         const jobs = new CombinedReportParameterModel(ReportParameterComponents.JobReportParameter);
         jobs.reportParameters = Array.prototype.concat(ReportParameter.JobIDs, ReportParameter.IncludeInactiveJobs);
         combinedParameters.push(jobs);

         const job = new CombinedReportParameterModel(ReportParameterComponents.JobReportParameter);
         job.reportParameters = Array.prototype.concat(ReportParameter.JobID, ReportParameter.IncludeInactiveJobs);
         combinedParameters.push(job);
      }

      const vendorTransGroup = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      vendorTransGroup.reportParameters = Array.prototype.concat(
         ReportParameter.VENDSHOWCHECKS,
         ReportParameter.VENDSHOWBILLS,
         ReportParameter.VENDSHOWCREDITS,
         ReportParameter.VENDSHOWCARDS
      );
      combinedParameters.push(vendorTransGroup);

      const exclusionOptionsGroup = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      exclusionOptionsGroup.reportParameters = Array.prototype.concat(
         ReportParameter.HIDEMAILINGLABEL,
         ReportParameter.HIDEOWNERNUMBERS,
         ReportParameter.HIDEPASSTHRU,
         ReportParameter.IsHideVendorIfApplicable,
         ReportParameter.HIDEUNDEPOSITEDFUNDS
      );
      combinedParameters.push(exclusionOptionsGroup);

      const valuesToIncludeGroup = new CombinedReportParameterModel(ReportParameterComponents.ValuesToIncludeParameter);
      valuesToIncludeGroup.reportParameters = Array.prototype.concat(
         ReportParameter.FROMCHARGE,
         ReportParameter.TOCHARGE,
         ReportParameter.FROMCONSUMPT,
         ReportParameter.TOCONSUMPT
      );
      combinedParameters.push(valuesToIncludeGroup);

      const birthdaysToIncludeGroup = new CombinedReportParameterModel(
         ReportParameterComponents.BirthdaysToIncludeParameter
      );
      birthdaysToIncludeGroup.reportParameters = Array.prototype.concat(
         ReportParameter.MONTHNUMS,
         ReportParameter.AGEASOF,
         ReportParameter.FROMAGE,
         ReportParameter.TOAGE,
         ReportParameter.SHOWMISSINGBDAYS
      );
      combinedParameters.push(birthdaysToIncludeGroup);

      const violationNotes = new CombinedReportParameterModel(ReportParameterComponents.ViolationNotes);
      violationNotes.reportParameters = Array.prototype.concat(
         ReportParameter.ViolationNotesOption,
         ReportParameter.NUMBERNOTES
      );
      combinedParameters.push(violationNotes);

      const payableAccounts = new CombinedReportParameterModel(ReportParameterComponents.PayableAccounts);
      payableAccounts.reportParameters = Array.prototype.concat(
         ReportParameter.PayableAccountType,
         ReportParameter.PayableAccounts
      );
      combinedParameters.push(payableAccounts);

      const periodDateRange = new CombinedReportParameterModel(ReportParameterComponents.PeriodRange);
      periodDateRange.reportParameters = Array.prototype.concat(
         ReportParameter.PeriodStartDate,
         ReportParameter.PeriodEndDate
      );
      combinedParameters.push(periodDateRange);

      const dateRangeWithModes = new CombinedReportParameterModel(ReportParameterComponents.DateRangeWithModes);
      dateRangeWithModes.reportParameters = Array.prototype.concat(
         ReportParameter.ForecastDateRangeMode,
         ReportParameter.ForecastStartYear,
         ReportParameter.ForecastEndYear,
         ReportParameter.ForecastStartMonth,
         ReportParameter.ForecastQuarter
      );
      combinedParameters.push(dateRangeWithModes);

      const forecastModel = new CombinedReportParameterModel(ReportParameterComponents.ForecastModel);
      forecastModel.reportParameters = Array.prototype.concat(
         ReportParameter.ForecastModel,
         ReportParameter.IncludeForecastModelDetail
      );
      combinedParameters.push(forecastModel);

      const selectLease = new CombinedReportParameterModel(ReportParameterComponents.SelectLease);
      selectLease.reportParameters = Array.prototype.concat(ReportParameter.CUSTOMER, ReportParameter.LeaseID);
      combinedParameters.push(selectLease);

      const reportPeriodType = new CombinedReportParameterModel(ReportParameterComponents.ReportPeriodType);
      reportPeriodType.reportParameters = Array.prototype.concat(
         ReportParameter.ReportPeriodType,
         ReportParameter.WeekStart
      );
      combinedParameters.push(reportPeriodType);

      const statesWithCity = new CombinedReportParameterModel(ReportParameterComponents.States);
      statesWithCity.reportParameters = Array.prototype.concat(
         ReportParameter.StateIDs,
         ReportParameter.CityWithStateIDs
      );
      combinedParameters.push(statesWithCity);

      const states = new CombinedReportParameterModel(ReportParameterComponents.States);
      states.reportParameters = Array.prototype.concat(ReportParameter.StateIDs);
      combinedParameters.push(states);

      const marketRent = new CombinedReportParameterModel(ReportParameterComponents.MarketRent);
      marketRent.reportParameters = Array.prototype.concat(
         ReportParameter.MRProration,
         ReportParameter.MRProrationDate
      );
      combinedParameters.push(marketRent);

      const transactionsToInclude = new CombinedReportParameterModel(ReportParameterComponents.TransactionsToInclude);
      transactionsToInclude.reportParameters = Array.prototype.concat(
         ReportParameter.TRANSTOINCLUDE,
         ReportParameter.SHOWDEPOSITBREAKDOWN,
         ReportParameter.SHOWLEDGERBREAKDOWN,
         ReportParameter.VENDORSACCNO,
         ReportParameter.ShowJournal
      );
      combinedParameters.push(transactionsToInclude);

      const leadSources = new CombinedReportParameterModel(
         ReportParameterComponents.LeadSourcesToIncludeReportParameter
      );
      leadSources.reportParameters = Array.prototype.concat(
         ReportParameter.LeadSourcesToInclude,
         ReportParameter.IncludeInactiveLeadSources
      );
      combinedParameters.push(leadSources);

      const rentEscalations = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      rentEscalations.reportParameters = Array.prototype.concat(
         ReportParameter.SHOWRENTESCALATION,
         ReportParameter.IncludePastEscalations
      );
      combinedParameters.push(rentEscalations);

      const excludeEmptyReports = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      excludeEmptyReports.displayName = "Batch";
      excludeEmptyReports.reportParameters = Array.prototype.concat(
         ReportParameter.PROPSEXCLUDEEMPTY,
         ReportParameter.BATCHPROPSEXCLUDEEMPTY
      );
      combinedParameters.push(excludeEmptyReports);

      const ownerExcludeEmptyReports = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      ownerExcludeEmptyReports.displayName = "Batch";
      ownerExcludeEmptyReports.reportParameters = Array.prototype.concat(
         ReportParameter.OwnersExcludeEmpty,
         ReportParameter.BATCHOWNERSEXCLUDEEMPTY
      );
      combinedParameters.push(ownerExcludeEmptyReports);

      const bankBalanceSummaryReports = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      bankBalanceSummaryReports.displayName = "Bank Balance Summary";
      bankBalanceSummaryReports.reportParameters = Array.prototype.concat(
         ReportParameter.BANKBALANCESELECTEDONLY,
         ReportParameter.BALANCESELECTEDONLY
      );
      combinedParameters.push(bankBalanceSummaryReports);
      const includeCalls = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      includeCalls.reportParameters = Array.prototype.concat(
         ReportParameter.NDTIncludeInbound,
         ReportParameter.NDTIncludeOutbound
      );
      combinedParameters.push(includeCalls);

      const basicOwnerStatementExclusions = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      basicOwnerStatementExclusions.displayName = "Exclusion Options";
      basicOwnerStatementExclusions.reportParameters = Array.prototype.concat(
         ReportParameter.OBSHideMailingHeader,
         ReportParameter.OBSHidePhoneNumber,
         ReportParameter.OBSHideVendors,
         ReportParameter.OBSHideBillsOutstanding
      );
      combinedParameters.push(basicOwnerStatementExclusions);

      const ownerActivityExclusions = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      ownerActivityExclusions.displayName = "Exclusion Options";
      ownerActivityExclusions.reportParameters = Array.prototype.concat(
         ReportParameter.OAHIDEMAILINGLABEL,
         ReportParameter.OAHIDEPHONENUMBERS,
         ReportParameter.OAHIDEVENDOR,
         ReportParameter.OAHideMgtFeeBreakdown,
         ReportParameter.OAHideDistributionBreakdown
      );
      combinedParameters.push(ownerActivityExclusions);

      const ownerActivityShowDetailed = new CombinedReportParameterModel(ReportParameterComponents.CheckboxGroup);
      ownerActivityShowDetailed.displayName = "Show Detailed";
      ownerActivityShowDetailed.reportParameters = Array.prototype.concat(
         ReportParameter.OAPaymentReceived,
         ReportParameter.OAMgtFees
      );
      combinedParameters.push(ownerActivityShowDetailed);

      const vendorNameAndUDFName = new CombinedReportParameterModel(ReportParameterComponents.VendorNameAndUDFName);
      vendorNameAndUDFName.displayName = "Vendor Name Source";
      vendorNameAndUDFName.reportParameters = Array.prototype.concat(
         ReportParameter.VENDORNAMESOURCE,
         ReportParameter.VendorUDFName
      );
      combinedParameters.push(vendorNameAndUDFName);

      const amountSelection = new CombinedReportParameterModel(ReportParameterComponents.AmountSelectionPSF);
      amountSelection.displayName = "Amount Selection";
      amountSelection.reportParameters = [ReportParameter.AmountBasis, ReportParameter.IncludeTotalPSF];
      combinedParameters.push(amountSelection);

      const ownerNameAndUDFName = new CombinedReportParameterModel(ReportParameterComponents.OwnerNameAndUDFName);
      ownerNameAndUDFName.displayName = "Owner Name Source";
      ownerNameAndUDFName.reportParameters = Array.prototype.concat(
         ReportParameter.OwnerNameSource,
         ReportParameter.OwnerUDFName
      );
      combinedParameters.push(ownerNameAndUDFName);

      return combinedParameters;
   }

   private buildRowSpans() {
      this.componentRowSpans = new Map<ReportParameterComponents, number>();
      this.componentRowSpans.set(ReportParameterComponents.PropertiesAndOwnersToInclude, 4);
      this.componentRowSpans.set(ReportParameterComponents.UsersToInclude, 4);
      this.componentRowSpans.set(ReportParameterComponents.LabelSelection, 4);
      this.componentRowSpans.set(ReportParameterComponents.ChartAccountsToInclude, 1);
      this.componentRowSpans.set(ReportParameterComponents.YearRange, 4);
      this.componentRowSpans.set(ReportParameterComponents.CreditsToInclude, 4);
      this.componentRowSpans.set(ReportParameterComponents.ConsolidateAddressVendor1096, 4);
      this.componentRowSpans.set(ReportParameterComponents.ConsolidateAddressVendor1099, 4);
      this.componentRowSpans.set(ReportParameterComponents.ConsolidateAddressLoan, 4);
      this.componentRowSpans.set(ReportParameterComponents.DateRange, 2);
      this.componentRowSpans.set(ReportParameterComponents.DateRange2, 2);
      this.componentRowSpans.set(ReportParameterComponents.CheckboxGroup, 4);
      this.componentRowSpans.set(ReportParameterComponents.CrpPreparerParameters, 4);
      this.componentRowSpans.set(ReportParameterComponents.CrpOwnerParameters, 4);

      this.parameterRowSpans = new Map<ReportParameter, number>();
      this.parameterRowSpans.set(ReportParameter.CUSTOMERVALUESTOINCLUDETEXT, 2);
      this.parameterRowSpans.set(ReportParameter.DelinquentDaysToInclude, 2);
      this.parameterRowSpans.set(ReportParameter.GLAccountIDs, 4);
      this.parameterRowSpans.set(ReportParameter.ChargeTypeOrder, 4);
      this.parameterRowSpans.set(ReportParameter.USERIDS, 4);
      this.parameterRowSpans.set(ReportParameter.PropertyIDs, 4);
      this.parameterRowSpans.set(ReportParameter.DateRestrictionOnChecked, 4);
      this.parameterRowSpans.set(ReportParameter.BatchPropsOrUnits, 1);
      this.parameterRowSpans.set(ReportParameter.ProspectStatus, 4);
      this.parameterRowSpans.set(ReportParameter.CustomerStatus, 4);
      this.parameterRowSpans.set(ReportParameter.OwnerStatementShowDetails, 4);
      this.parameterRowSpans.set(ReportParameter.CLEAREDOPTIONS, 4);
      this.parameterRowSpans.set(ReportParameter.HISTNOTESFILTER, 4);
      this.parameterRowSpans.set(ReportParameter.HISTTYPEIDS, 4);
      this.parameterRowSpans.set(ReportParameter.ViolationNotesOption, 2);
   }

   private groupReportParameters(parameters: Array<ReportReportParameterViewModel>) {
      let order = 0;
      const groups = new Array<ReportReportParameterGroupModel>();
      const includeAccountingPeriodReportParameter = this.hasAccountingPeriodReportParameter(parameters);
      const allCombinedReportParameters = this.getAllCombinedReportParameters(includeAccountingPeriodReportParameter);
      let combinedReportParameters = new Array<CombinedReportParameterModel>();
      let availableParameters = [...parameters];
      for (const reportParameter of sortBy(parameters, "IsHidden")) {
         const combinedReportParameter = allCombinedReportParameters.find(
            (p) =>
               p.reportParameters.some((a) => a === reportParameter.ReportParameterID) &&
               p.reportParameters.every((rp) => this.reportParameters.some((g) => g === rp))
         );

         if (combinedReportParameter) {
            combinedReportParameters.push(combinedReportParameter);
         }
      }
      if (combinedReportParameters.length > 0) {
         const combinedParameters = combinedReportParameters
            .map((p) => p.reportParameters)
            .reduce(function (a, b) {
               return a.concat(b);
            });
         availableParameters = parameters.filter((p) => !combinedParameters.some((c) => c === p.ReportParameterID));

         // distinct all combined report parameters must be done for report batch run - report parameter component and defining report parameters both must match to be a duplicate because report components are shared
         //    with differing numbers of backing report parameters
         const distinctCombinedReportParameters = new Array<CombinedReportParameterModel>();
         combinedReportParameters.forEach((c) => {
            if (
               !distinctCombinedReportParameters.some(
                  (d) =>
                     d.reportParameterComponent === c.reportParameterComponent &&
                     d.reportParameters.every((drp) => c.reportParameters.some((crp) => crp === drp))
               )
            ) {
               distinctCombinedReportParameters.push(c);
            }
         });
         combinedReportParameters = distinctCombinedReportParameters;

         for (const combined of combinedReportParameters) {
            const combinedGroup = new ReportReportParameterGroupModel();
            combinedGroup.displayName = combined.displayName;
            combinedGroup.Order = order;
            if (
               !combinedGroup.ReportReportParameterComponents ||
               combinedGroup.ReportReportParameterComponents.length === 0
            ) {
               combinedGroup.ReportReportParameterComponents = new Array<ReportReportParameterComponentModel>();
            }
            const viewModels = parameters.filter((p) =>
               combined.reportParameters.some((c) => c === p.ReportParameterID)
            );
            const reportReportParameterComponent = new ReportReportParameterComponentModel();
            reportReportParameterComponent.ReportParameterComponent = combined.reportParameterComponent;
            reportReportParameterComponent.GroupedReportReportParameters = viewModels;

            const span = this.componentRowSpans.get(reportReportParameterComponent.ReportParameterComponent);
            if (span) {
               reportReportParameterComponent.RowSpan = span;
            }
            combinedGroup.ReportParameterComponent = combined.reportParameterComponent;
            combinedGroup.ReportReportParameterComponents.push(reportReportParameterComponent);

            combinedGroup.isCombinedReportParameter = true;
            groups.push(combinedGroup);
            order++;
         }
      }

      for (const reportParameter of sortBy(availableParameters, "IsHidden")) {
         const reportReportParameterComponent = this.resolveReportParameterComponent(reportParameter);
         const reportReportParameterGroup = new ReportReportParameterGroupModel();
         reportReportParameterGroup.ReportParameterComponent = reportReportParameterComponent.ReportParameterComponent;
         reportReportParameterGroup.Order = order;
         if (reportParameter.IsHidden || this.hideJobReportParameter(reportParameter)) {
            continue;
         }
         if (
            !reportReportParameterGroup.ReportReportParameterComponents ||
            reportReportParameterGroup.ReportReportParameterComponents.length === 0
         ) {
            reportReportParameterGroup.ReportReportParameterComponents =
               new Array<ReportReportParameterComponentModel>();
         }
         reportReportParameterGroup.ReportReportParameterComponents.push(reportReportParameterComponent);
         groups.push(reportReportParameterGroup);
         order++;
      }

      if (this.notSupportedReportParametersArray.length < 1) {
         this.notSupportedReportParameters.emit([]);
      }

      groups.forEach((reportParameterGroup) => {
         const groupOrder = this.getMiscellaneousGroupParameterOrder(
            reportParameterGroup.Order,
            reportParameterGroup.ReportParameterComponent
         );
         reportParameterGroup.Order = groupOrder;
      });

      const orderedGroups = new Array<ReportReportParameterGroupModel>();
      let orderedGroup = new ReportReportParameterGroupModel();
      orderedGroup.ReportReportParameterComponents = new Array<ReportReportParameterComponentModel>();
      orderedGroup.Order = 0;
      orderedGroups.push(orderedGroup);
      let currentRowSpan = 0;
      order = 1;
      for (const group of sortBy(groups, "Order")) {
         if (group.RowSpan + currentRowSpan > 4) {
            orderedGroup = new ReportReportParameterGroupModel();
            orderedGroup.displayName = group.displayName;
            orderedGroup.ReportReportParameterComponents = new Array<ReportReportParameterComponentModel>();
            orderedGroup.Order = order;
            orderedGroups.push(orderedGroup);
            currentRowSpan = 0;
            order++;
         }

         currentRowSpan += group.RowSpan;
         orderedGroup.ReportReportParameterComponents.push(...group.ReportReportParameterComponents);
      }
      if (orderedGroups.length > 0) {
         this.reportReportParameterGroups = orderedGroups;
      }
   }

   private hideJobReportParameter(reportParameter: ReportReportParameterViewModel): boolean {
      return !this.isJobCostingEnabled && this.jobRelatedReportParameters.includes(reportParameter.ReportParameterID);
   }

   private hasAccountingPeriodReportParameter(parameters: ReportReportParameterViewModel[]): boolean {
      if (
         parameters.find((x) => x.ReportParameterID === ReportParameter.StartPeriodID) ||
         parameters.find((x) => x.ReportParameterID === ReportParameter.StartPeriodID2) ||
         parameters.find((x) => x.ReportParameterID === ReportParameter.AsOfPeriodID) ||
         parameters.find((x) => x.ReportParameterID === ReportParameter.AsOfPeriodID2)
      ) {
         return true;
      }
      return false;
   }

   private resolveReportParameterComponent(
      parameter: ReportReportParameterViewModel
   ): ReportReportParameterComponentModel {
      const reportReportParameterComponent = new ReportReportParameterComponentModel();
      reportReportParameterComponent.ReportReportParameter = parameter;

      const reportParameterID = parameter.ReportParameterID;
      const dataType = parameter.DataType;
      const reportID = parameter.ReportID;
      let isNotSupported = false;

      if (
         reportParameterID === ReportParameter.CUSTOMERVALUESTOINCLUDETEXT ||
         reportParameterID === ReportParameter.DelinquentDaysToInclude
      ) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.ToggleableInput;
      } else if (reportParameterID === ReportParameter.YEAR) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.CRPYear;
      } else if (reportParameterID === ReportParameter.AddressType) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.AddressType;
      } else if (
         reportParameterID === ReportParameter.CreditsToInclude ||
         (reportParameterID === ReportParameter.CHARGESTOINCLUDE &&
            (reportID === Report.AgedReceivables ||
               reportID === Report.AgedReceivablesWithNotes ||
               reportID === Report.ZemanAgedReceivablesWithNotes))
      ) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.CreditsToInclude;
      } else if (reportParameterID === ReportParameter.SORTOPTIONS) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.SortOptions;
      } else if (reportParameterID === ReportParameter.ChargeTypeOrder) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.ChargeTypeOrder;
      } else if (reportParameterID === ReportParameter.USERIDS) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.UsersToInclude;
      } else if (reportParameterID === ReportParameter.JobID || reportParameterID === ReportParameter.JobIDs) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.JobReportParameter;
      } else if (reportParameterID === ReportParameter.IncludeExpectedMoveOutDates) {
         reportReportParameterComponent.ReportParameterComponent =
            ReportParameterComponents.IncludeExpectedMoveDatesReportParameter;
      } else if (reportParameterID === ReportParameter.PropertyIDs) {
         reportReportParameterComponent.ReportParameterComponent =
            ReportParameterComponents.PropertiesAndOwnersToInclude;
      } else if (reportParameterID === ReportParameter.OWNERIDS) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.OwnersToInclude;
      } else if (reportParameterID === ReportParameter.VendorIDs) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.VendorReportParameter;
      } else if (reportParameterID === ReportParameter.CAMEscrowStatement) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.CAMEscrow;
      } else if (reportParameterID === ReportParameter.GLAccountIDs) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.ChartAccountsToInclude;
      } else if (reportParameterID === ReportParameter.HorizonLandRecoveryAccounts) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.AccountsWrapper;
      } else if (reportParameterID === ReportParameter.HorizonLandCostsAccounts) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.AccountsWrapper;
      } else if (reportParameterID === ReportParameter.PanAmericanCashFlowDebtAccounts) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.AccountsWrapper;
      } else if (reportParameterID === ReportParameter.PropertyCapitalizationRate) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.PercentReportParameter;
      } else if (
         reportParameterID === ReportParameter.ReconcileID ||
         reportParameterID === ReportParameter.CCReconcileID ||
         reportParameterID === ReportParameter.MagnumReconcileID
      ) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Reconciliations;
      } else if (reportParameterID === ReportParameter.BankAccount) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.BankAccountSelector;
      } else if (reportParameterID === ReportParameter.BudgetYears) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.BudgetYears;
      } else if (reportParameterID === ReportParameter.CAMCHARGETYPE) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.CAMChargeTypes;
      } else if (reportParameterID === ReportParameter.PropMultiUnit) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.PropertyUnitMultiSelector;
      } else if (reportParameterID === ReportParameter.BANKS) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.BankCCAccountsParameter;
      } else if (reportParameterID === ReportParameter.Associated1099Categories) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Associated1099Categories;
      } else if (reportParameterID === ReportParameter.LeadSourcesToInclude) {
         reportReportParameterComponent.ReportParameterComponent =
            ReportParameterComponents.LeadSourcesToIncludeReportParameter;
      } else if (reportParameterID === ReportParameter.ProspectStageIDs) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.ProspectStageID;
      } else if (reportParameterID === ReportParameter.StatementMethod) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.StatementMethod;
      } else if (reportParameterID === ReportParameter.CLEAREDOPTIONS) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.ClearedOptions;
      } else if (this.parameterHasSource(parameter)) {
         if (parameter.ReportParameterValueSource.AllowsMultipleValues) {
            reportReportParameterComponent.RowSpan = 1;
         }
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Selector;
      } else if (dataType === ReportFieldDataType.Bool) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Checkbox;
      } else if (parameter.UserControlFrame === ReportUserControlFrame.CheckedDateRange) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.CheckedDateRange;
      } else if (dataType === ReportFieldDataType.Date) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Date;
         if (reportParameterID === ReportParameter.SIGNATUREDATE) {
            reportReportParameterComponent.ReportReportParameter.IsUseMinDateForBlankDateValue = true;
         }
      } else if (parameter.UserControlFrame === ReportUserControlFrame.Comment) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Textarea;
         reportReportParameterComponent.RowSpan = 2;
      } else if (dataType === ReportFieldDataType.String || parameter.IsHidden) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Textbox;
      } else if (
         [ReportFieldDataType.Int, ReportFieldDataType.Long, ReportFieldDataType.Decimal].some((d) => d === dataType)
      ) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.NumericInput;
      } else if (reportParameterID === ReportParameter.PropertySelectionBy) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.PropertySelectionBy;
      } else {
         isNotSupported = true;
      }

      if (isNotSupported) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Textbox;
         reportReportParameterComponent.ReportReportParameter.DisplayName += " * Unsupported *";
         this.notSupportedReportParametersArray.push(
            <ReportParameter>reportReportParameterComponent.ReportReportParameter.ReportParameterID
         );
         this.notSupportedReportParameters.emit(this.notSupportedReportParametersArray);
      }
      if (
         reportParameterID === ReportParameter.LEASESIGNED ||
         reportParameterID === ReportParameter.BoxScoreLeaseSigned
      ) {
         reportReportParameterComponent.ReportReportParameter.DisplayName =
            "Include submitted applications & leases signed";
      }
      if (reportParameterID === ReportParameter.PictureSize || reportParameterID === ReportParameter.PictureQuality) {
         reportReportParameterComponent.ReportReportParameter.DisplayName =
            reportReportParameterComponent.ReportReportParameter.DisplayName.replace(/([A-Z])/g, " $1").trim();
      }
      if (reportParameterID === ReportParameter.YEAR1099) {
         reportReportParameterComponent.ReportParameterComponent = ReportParameterComponents.Year1099;
      }

      const parameterRowSpan = this.parameterRowSpans.get(reportParameterID);
      if (parameterRowSpan) {
         reportReportParameterComponent.RowSpan = parameterRowSpan;
      }

      return reportReportParameterComponent;
   }

   private parameterHasSource(parameter: ReportReportParameterViewModel): boolean {
      if (
         parameter &&
         parameter.ReportParameterValueSource &&
         (parameter.ReportParameterValueSource.EntitySources.length > 0 ||
            parameter.ReportParameterValueSource.EnumSources.length > 0 ||
            parameter.ReportParameterValueSource.StaticValues.length > 0)
      ) {
         return true;
      }
      return false;
   }

   private getMiscellaneousGroupParameterOrder(currentOrder: number, componentType: ReportParameterComponents): number {
      if (
         componentType === ReportParameterComponents.PropertiesAndOwnersToInclude ||
         componentType === ReportParameterComponents.OwnersToInclude
      ) {
         return 10 + currentOrder;
      } else if (componentType === ReportParameterComponents.ChartAccountsToInclude) {
         return 20 + currentOrder;
      } else if (
         [
            ReportParameterComponents.Date,
            ReportParameterComponents.Date2,
            ReportParameterComponents.AsOfDateWithAccountingPeriod,
            ReportParameterComponents.AsOfDateWithAccountingPeriod2,
         ].some((x) => x === componentType)
      ) {
         return 20 + currentOrder;
      } else if (
         componentType === ReportParameterComponents.DateRange ||
         componentType === ReportParameterComponents.DateRangeWithAccountingPeriod
      ) {
         return 30 + currentOrder;
      } else if (
         componentType === ReportParameterComponents.DateRange2 ||
         componentType === ReportParameterComponents.DateRangeWithAccountingPeriod2
      ) {
         return 31 + currentOrder;
      } else if (componentType === ReportParameterComponents.Selector) {
         return 100 + currentOrder;
      } else if (componentType === ReportParameterComponents.ProspectStageID) {
         return 100 + currentOrder;
      } else if (componentType === ReportParameterComponents.LabelSelection) {
         return 200 + currentOrder;
      } else if (componentType === ReportParameterComponents.Textbox) {
         return 300 + currentOrder;
      } else if (componentType === ReportParameterComponents.Textarea) {
         return 400 + currentOrder;
      } else if (
         componentType === ReportParameterComponents.Checkbox ||
         componentType === ReportParameterComponents.ToggleableInput
      ) {
         return 500 + currentOrder;
      } else {
         return 1000 + currentOrder;
      }
   }
}
