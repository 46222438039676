import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ConfirmationDialogService } from "@lcs/dialog/components/confirmation-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption, FilterOptionKeyValuePair } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";

import { FormRegistrationService } from "../../forms/form-registration/form-registration.service";
import { DataTableFilterModel } from "./datatable-filter.model";

@Component({
   selector: "lcs-datatable-filter-form",
   templateUrl: "./datatable-filter-form.component.html",
})
export class DataTableFilterFormComponent {
   @Input() isFiltersLoading: boolean;

   @Input() includeUserDefinedValueFilters: boolean;

   @Input() entityType: EntityType;

   @Input() entitySearchType: EntityType;

   @Input() dataTableFilters: Array<DataTableFilterModel>;

   @Input() userDefinedValueFilters: Array<FilterOption>;

   @Input() filtersCollapsed: boolean;

   @Input() filterOperations: Array<FilterOperationModel>;

   @Input() showInactivePropertiesDefault: boolean;

   @Output() appliedFilters = new EventEmitter<Array<FilterOption>>();

   @Output() clearFilters = new EventEmitter<void>();

   @Output() toggleFilterSection = new EventEmitter<void>();

   @Output() userDefinedValueFiltersChange = new EventEmitter<Array<FilterOption>>();

   changedFilterOptions = new Map<string, FilterOption>();

   applyButtonStatus = LcsProgressButtonStatus.Unset;

   constructor(
      private formRegistrationService: FormRegistrationService,
      private confirmationDialogService: ConfirmationDialogService,
      private errorMessageService: ErrorMessageService
   ) {}

   filterOptionChanged(filter: FilterOptionKeyValuePair) {
      if (!filter.filterOption) {
         this.changedFilterOptions.delete(filter.filterName);
      } else {
         this.changedFilterOptions.set(filter.filterName, filter.filterOption);
      }
   }

   onApplyFiltersClick(form: UntypedFormGroup) {
      if (form.valid) {
         this.appliedFilters.emit(Array.from(this.changedFilterOptions.values()));
         this.applyButtonStatus = LcsProgressButtonStatus.InProgress;
      } else {
         try {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            this.confirmationDialogService.openAlert(`Please correct invalid filter(s)`, null, null);
            this.formRegistrationService.markFormAsTouchedAndDirty();
            this.applyButtonStatus = LcsProgressButtonStatus.Error;
         } catch (e) {
            // show message for OWA, dialog service does not work in OWA
            this.errorMessageService.triggerErrorMessage("Please correct invalid filter(s)");
         }
      }
      this.applyButtonStatus = LcsProgressButtonStatus.Success;
   }

   onClearFiltersClick() {
      this.clearFilters.emit();
   }

   onToggleFilterSectionClick() {
      this.toggleFilterSection.emit();
   }

   onUserDefinedValueFiltersChange(udfOptions: Array<FilterOption>) {
      this.userDefinedValueFiltersChange.emit(udfOptions);
   }
}
