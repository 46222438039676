<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>
   <div class="email-wrapper">
      <div class="email-content">
         <div>
            <label>From</label>
            <input
               [(ngModel)]="emailModel.From"
               name="From"
               type="text"
               value=""
               id="from"
               [disabled]=true
            >
         </div>
         <div>
            <label>Subject *</label>
            <lcs-text-box
               [(ngModel)]="emailModel.Subject"
               [name]="'Subject'"
               displayName="Subject"
               [lcsValidate]="requiredValidation"
            >
            </lcs-text-box>
         </div>
         <div>
            <label>Body *</label>
            <lcs-text-area
               [expandable]='false'
               class="email"
               [(ngModel)]="emailModel.Body"
               name="Body"
               displayName="Body"
               [lcsValidate]="requiredValidation"
            >
            </lcs-text-area>
         </div>
      </div>

      <div lcsFooter>
         <lcs-progress-button
            [raised]="true"
            [(status)]="sendButtonStatus"
         >Send</lcs-progress-button>
      </div>
   </div>
</form>