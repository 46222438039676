import { formatNumber, getLocaleNumberSymbol, NumberSymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { MidpointRounding } from "projects/libraries/lcs/src/lib/utils/midpoint-rounding";
import { roundCurrency } from "projects/libraries/lcs/src/lib/utils/rounding";

@Pipe({
   name: "rmNumber",
})
export class RmNumberPipe implements PipeTransform {
   transform(
      value: number,
      options: string = "0.0-2",
      rounding: MidpointRounding = MidpointRounding.AwayFromZero
   ): any {
      return RmNumberPipe.rmNumberTransform(value, options, rounding);
   }

   static rmNumberTransform(
      value: number,
      options: string = "0.0-2",
      rounding: MidpointRounding = MidpointRounding.AwayFromZero
   ): any {
      const roundNumber = roundCurrency(value, parseInt(options.substr(options.lastIndexOf("-") + 1)), rounding);
      const formattedNumber = formatNumber(roundNumber, GlobalsService.locale, options);
      let cleanFormat = formattedNumber;
      if (formattedNumber !== null && formattedNumber !== "") {
         const groupSeparator = getLocaleNumberSymbol(GlobalsService.locale, NumberSymbol.Group);
         const decimalSeparator = getLocaleNumberSymbol(GlobalsService.locale, NumberSymbol.Decimal);
         const decimalSeparatorRegex = new RegExp("\\" + decimalSeparator, "g");
         const groupSeparatorRegex = new RegExp("\\" + groupSeparator, "g");
         cleanFormat = formattedNumber.replace(groupSeparatorRegex, ",").replace(decimalSeparatorRegex, ".");
      }
      return cleanFormat;
   }
}
