import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { ChartOfAccountsMappedAccountDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/chart-of-accounts-mapped-account.delete-options";
import { ChartOfAccountsMappedAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/chart-of-accounts-mapped-account.embed-options";
import { ChartOfAccountsMappingLinkEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/chart-of-accounts-mapping-link.embed-options";
import { ChartOfAccountsMappingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/chart-of-accounts-mapping.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { ChartOfAccountsMappedAccountSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/chart-of-accounts-mapped-account.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ChartOfAccountsMappedAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/chart-of-accounts-mapped-account.model";
import { ChartOfAccountsMappingLinkModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/chart-of-accounts-mapping-link.model";
import { ChartOfAccountsMappingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/chart-of-accounts-mapping.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { GLMapTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glmap-transfer.model";
import { MappedGlAccountLinkModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/mapped-gl-account-link.model";
import { MappedGlAccountSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/mapped-gl-account-summary.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";
import { SearchPostBodyModel } from "../../models/post-body-models/search-post-body.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ChartOfAccountsMappedAccountsService {
   static readonly ChartOfAccountsAlreadyMappedGlAccountsResource = "ChartOfAccountsAlreadyMappedGlAccounts";
   static readonly ChartOfAccountsMappedAccountsResource = "ChartOfAccountsMappedAccounts";
   static readonly ChartOfAccountsMappedAccountsSortOrderResource = "ChartOfAccountsMappedAccountsSortOrder";
   static readonly ChartOfAccountsMappedGlAccountLinkModelResource = "ChartOfAccountsMappedGlAccountLinkModel";
   static readonly ChartOfAccountsMappingResource = "ChartOfAccountsMapping";
   static readonly ChartOfAccountsMappingLinksResource = "ChartOfAccountsMappingLinks";
   static readonly ChartOfAccountsMappingLinksCollectionByMapResource = "ChartOfAccountsMappingLinksCollectionByMap";
   static readonly ChartOfAccountsMapWithDefaultMappingsResource = "ChartOfAccountsMapWithDefaultMappings";
   static readonly ChartOfAccountsMapWithDuplicateMappingsResource = "ChartOfAccountsMapWithDuplicateMappings";
   static readonly CreateUserResource = "CreateUser";
   static readonly GetByPostResource = "GetByPost";
   static readonly ParentChartOfAccountsMappedAccountResource = "ParentChartOfAccountsMappedAccount";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly SearchResource = "Search";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly endpoint = "ChartOfAccountsMappedAccountsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappedAccountModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappedAccountModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | ChartOfAccountsMappedAccountSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ChartOfAccountsMappedAccountModel | null,
      saveOptions?: Array<string | ChartOfAccountsMappedAccountSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappedAccountModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | ChartOfAccountsMappedAccountSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ChartOfAccountsMappedAccountModel>,
      saveOptions?: Array<string | ChartOfAccountsMappedAccountSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappedAccountModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | ChartOfAccountsMappedAccountDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | ChartOfAccountsMappedAccountDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ChartOfAccountsMappedAccountsService.endpoint,
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getChartOfAccountsAlreadyMappedGlAccountsCollectionUrl(
      mappingID: number,
      linkedAccountIDs?: Array<number> | null,
      chartAccountID?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("mappingID=" + mappingID);
      if (linkedAccountIDs !== undefined && linkedAccountIDs !== null && linkedAccountIDs.length > 0) {
         additionalParameters.push("linkedAccountIDs=" + linkedAccountIDs);
      }
      if (chartAccountID !== undefined && chartAccountID !== null) {
         additionalParameters.push("chartAccountID=" + chartAccountID);
      }
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsAlreadyMappedGlAccounts",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsAlreadyMappedGlAccountsCollection(
      mappingID: number,
      linkedAccountIDs?: Array<number> | null,
      chartAccountID?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<GLAccountModel>> {
      return this.apiService.getCollection(
         this.getChartOfAccountsAlreadyMappedGlAccountsCollectionUrl(
            mappingID,
            linkedAccountIDs,
            chartAccountID,
            additionalParameters
         )
      );
   }

   getChartOfAccountsMappedAccountsCollectionUrl(
      filters?: Array<FilterOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappedAccounts",
         null,
         null,
         filters,
         null,
         null,
         null,
         orderingOptions,
         null,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMappedAccountsCollection(
      filters?: Array<FilterOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MappedGlAccountSummaryModel>> {
      return this.apiService.getCollection(
         this.getChartOfAccountsMappedAccountsCollectionUrl(
            filters,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChartOfAccountsMappedAccountsSortOrderUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappedAccountsSortOrder",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChartOfAccountsMappedAccountsSortOrder(
      items: Array<ChartOfAccountsMappedAccountModel>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(
         this.postChartOfAccountsMappedAccountsSortOrderUrl(additionalParameters),
         items
      );
   }

   postChartOfAccountsMappedGlAccountLinkModelUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappedGlAccountLinkModel",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChartOfAccountsMappedGlAccountLinkModel(
      item?: MappedGlAccountLinkModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postChartOfAccountsMappedGlAccountLinkModelUrl(additionalParameters), item);
   }

   getChartOfAccountsMappingUrl(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMapping",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMapping(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappingEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingModel> {
      return this.apiService.getSingle(
         this.getChartOfAccountsMappingUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChartOfAccountsMappingLinksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMappingLinks(
      id: number,
      itemID: number,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingLinkModel> {
      return this.apiService.getSingle(
         this.getChartOfAccountsMappingLinksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChartOfAccountsMappingLinksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChartOfAccountsMappingLinksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappingLinkModel>> {
      return this.apiService.getCollection(
         this.getChartOfAccountsMappingLinksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChartOfAccountsMappingLinksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChartOfAccountsMappingLinks(
      id: number,
      item?: ChartOfAccountsMappingLinkModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingLinkModel> {
      return this.apiService.postSingle(
         this.postChartOfAccountsMappingLinksUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postChartOfAccountsMappingLinksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChartOfAccountsMappingLinksCollection(
      id: number,
      items: Array<ChartOfAccountsMappingLinkModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChartOfAccountsMappingLinkEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappingLinkModel>> {
      return this.apiService.postCollection(
         this.postChartOfAccountsMappingLinksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteChartOfAccountsMappingLinksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteChartOfAccountsMappingLinks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteChartOfAccountsMappingLinksUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   postChartOfAccountsMappingLinksCollectionByMapUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinksCollectionByMap",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChartOfAccountsMappingLinksCollectionByMap(
      items: Array<ChartOfAccountsMappingLinkModel>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(
         this.postChartOfAccountsMappingLinksCollectionByMapUrl(additionalParameters),
         items
      );
   }

   deleteChartOfAccountsMappingLinksCollectionByMapUrl(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMappingLinksCollectionByMap",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteChartOfAccountsMappingLinksCollectionByMap(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteChartOfAccountsMappingLinksCollectionByMapUrl(ids, additionalParameters)
      );
   }

   postChartOfAccountsMapWithDefaultMappingsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMapWithDefaultMappings",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChartOfAccountsMapWithDefaultMappings(
      item?: GLMapTransferModel | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingModel> {
      return this.apiService
         .post(this.postChartOfAccountsMapWithDefaultMappingsUrl(additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   postChartOfAccountsMapWithDuplicateMappingsUrl(
      chartOfAccountsMappingID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("chartOfAccountsMappingID=" + chartOfAccountsMappingID);
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ChartOfAccountsMapWithDuplicateMappings",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChartOfAccountsMapWithDuplicateMappings(
      item: GLMapTransferModel,
      chartOfAccountsMappingID: number,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappingModel> {
      return this.apiService
         .post(
            this.postChartOfAccountsMapWithDuplicateMappingsUrl(chartOfAccountsMappingID, additionalParameters),
            item
         )
         .pipe(map((response) => response.body));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappedAccountModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getParentChartOfAccountsMappedAccountUrl(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/ParentChartOfAccountsMappedAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getParentChartOfAccountsMappedAccount(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappedAccountModel> {
      return this.apiService.getSingle(
         this.getParentChartOfAccountsMappedAccountUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappedAccountModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ChartOfAccountsMappedAccountModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ChartOfAccountsMappedAccountEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappedAccountModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChartOfAccountsMappedAccountModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ChartOfAccountsMappedAccountsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
