import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable } from "rxjs";

import { OwnershipDashboardModel } from "../models/ownership-dashboard.model";

@Injectable({ providedIn: "root" })
export class OwnershipsService {
   readonly endpoint = "Ownerships";

   constructor(private apiService: ApiService) {}

   getUrl(
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters
      );
   }

   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/Get";
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   getCollectionResponse(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getCollectionResponse(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getOwnershipsForDashboard(): Observable<OwnershipDashboardModel> {
      const endpoint = this.endpoint + "/GetOwnershipsForDashboard";
      return this.apiService.getSingle(this.apiService.getUrl(endpoint));
   }
}
