import { Injectable, OnDestroy } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EnumerationInformationService } from "projects/libraries/lcs/src/lib/utils/enumeration-information.service";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { LogicalOperators } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/logical-operators.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { map, Observable, of, Subject, switchMap, takeUntil } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class ValueSourceService implements OnDestroy {
   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private entityRequestService: EntityRequestService,
      private enumerationInformationService: EnumerationInformationService,
      private objectMapResolverService: ObjectMapResolverService
   ) {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   process(source: ValueSourceModel): Observable<Array<SelectorItemModel>> {
      switch (source.Type) {
         case ValueSourceTypes.Static:
            return this.processStaticValueSource(source);
         case ValueSourceTypes.Enumeration:
            return this.processEnumerationValueSource(source);
         case ValueSourceTypes.Entity:
            return this.processEntityValueSource(source);
         case ValueSourceTypes.EmbeddedSet:
            return of(this.processEmbeddedSetValueSource(source));
         default:
            throw new Error(`Unknown value source type: ${source.Type}`);
      }
   }

   itemAlreadyInItemSet(itemToCheck: SelectorItemModel, items: Array<SelectorItemModel>): boolean {
      if (!items || items.length === 0) {
         return false;
      }
      let valuesToCheck = [itemToCheck.value];

      // Zero is included here because of the ISSUELISTSTOINCLUDE report parameter - probably needs to be fixed to be NullFK as well, or else this may break other things
      const nullValues = [null, ConstantsService.NullFK, ConstantsService.NullFK.toString(), 0, "0"];

      if (itemToCheck.value == null || nullValues.indexOf(itemToCheck.value) > -1) {
         valuesToCheck = nullValues;
      }

      const itemWithMatchingValue = items.find((i) => valuesToCheck.indexOf(i.value) > -1);

      return itemWithMatchingValue != null;
   }

   private processStaticValueSource(source: ValueSourceModel): Observable<Array<SelectorItemModel>> {
      const items = new Array<SelectorItemModel>();
      for (const value of source.StaticValues) {
         const selectorItem = new SelectorItemModel();
         selectorItem.displayValue = value.displayValue;
         selectorItem.tooltip = value.tooltip;
         selectorItem.value = value.value;
         selectorItem.additionalInfoValue = value.additionalInfoValue;
         selectorItem.isMutuallyExclusiveAll = value.isMutuallyExclusiveAll;
         selectorItem.isDisabled = value.isDisabled;
         selectorItem.alternateStyleClass = value.alternateStyleClass;
         selectorItem.isIncludedInAll = value.isIncludedInAll;
         if (source.SelectedValues && source.SelectedValues.length > 0) {
            selectorItem.isChecked =
               value.isChecked ||
               source.SelectedValues.some(
                  (selValue) =>
                     selValue &&
                     selectorItem.value &&
                     selValue.toString().toLowerCase() === selectorItem.value.toString().toLowerCase()
               );
         }
         items.push(selectorItem);
      }
      return of(items);
   }

   private processEnumerationValueSource(source: ValueSourceModel): Observable<Array<SelectorItemModel>> {
      const items = new Array<SelectorItemModel>();
      return this.enumerationInformationService.getEnumerationInformation(source.EnumerationName).pipe(
         map((results) => {
            if (source.OrderingOptions && source.OrderingOptions.length === 1) {
               const sortBy = source.OrderingOptions[0];
               results.sort((a, b) => {
                  if (a[sortBy] && b[sortBy]) {
                     return a[sortBy] > b[sortBy] ? 1 : -1;
                  } else {
                     return -1;
                  }
               });
            }
            for (const result of results) {
               if (
                  source.ValuesToExclude &&
                  source.ValuesToExclude.some(
                     (value) => value.toString().toLowerCase() === result.Value.toString().toLowerCase()
                  )
               ) {
                  continue;
               }
               if (
                  source.ValuesToInclude &&
                  source.ValuesToInclude.length > 0 &&
                  !source.ValuesToInclude.some(
                     (value) => value.toString().toLowerCase() === result.Value.toString().toLowerCase()
                  )
               ) {
                  continue;
               }
               const selectorItem = new SelectorItemModel();
               selectorItem.value = result.Value;
               selectorItem.displayValue = result.Description ? result.Description : result.Name;
               if (source.SelectedValues && source.SelectedValues.length > 0) {
                  selectorItem.isChecked = source.SelectedValues.some(
                     (value) =>
                        value != null &&
                        selectorItem.value != null &&
                        value.toString().toLowerCase() === selectorItem.value.toString().toLowerCase()
                  );
               }
               items.push(selectorItem);
            }
            return items;
         }),
         takeUntil(this.unsubscribe)
      );
   }

   private processEntityValueSource(source: ValueSourceModel): Observable<Array<SelectorItemModel>> {
      const filterExpression = new FilterExpression();
      if (source.Filters) {
         filterExpression.FilterOptions = source.Filters;
      }

      return this.getEntityUrl(source).pipe(
         switchMap((apiUrl) => {
            let additionalParameters = new Array<string>();

            if (source.EndpointIsSearch) {
               let newFilterExpression = new FilterExpression();
               if (source.HasUserSelectedItems && source.SelectedValues && source.SelectedValues.length > 0) {
                  newFilterExpression.SubExpressions = [filterExpression];
                  newFilterExpression.LogicalOperator = LogicalOperators.Or;
                  newFilterExpression.FilterOptions?.push(
                     new FilterOption(source.EntityValueSourcePath, FilterOperations.In, source.SelectedValues)
                  );
               } else {
                  newFilterExpression = filterExpression;
               }
               additionalParameters = this.apiService.buildSearchCollectionParameterArrayFromGetParameters(
                  newFilterExpression,
                  source.Embeds,
                  source.OrderingOptions,
                  this.objectMapResolverService.processEntityValueSourcePathInformation(source)
               );
            } else {
               additionalParameters = this.apiService.buildGetCollectionParameterArray(
                  filterExpression,
                  source.Embeds,
                  source.OrderingOptions,
                  this.objectMapResolverService.processEntityValueSourcePathInformation(source)
               );
            }
            return this.apiService.get(apiUrl, additionalParameters);
         }),
         map((results) => {
            return this.processEntitySourceResults(source, results.body);
         })
      );
   }

   private getEntityUrl(source: ValueSourceModel): Observable<string> {
      if (source.Endpoint) {
         return of(source.Endpoint);
      } else {
         return this.entityRequestService.getEntityEndpoint(source.EntityType, null, null, source.IsQuickSearchEnabled);
      }
   }

   private processEntitySourceResults(source: ValueSourceModel, results: Array<any>): Array<SelectorItemModel> {
      const items = new Array<SelectorItemModel>();
      if (results && results.length > 0) {
         results.forEach((result) => {
            const selectorItem = new SelectorItemModel();
            if (result[source.EntityValueSourcePath] != null) {
               selectorItem.value = result[source.EntityValueSourcePath];
            }
            selectorItem.displayValue = this.objectMapResolverService.processEntitySourceDisplayValueResults(
               source,
               result
            );
            selectorItem.additionalInfoValue = this.objectMapResolverService.getDisplayValue(
               result,
               source.AdditionalInfoSourcePath
            );
            if (source.AdditionalDataFields) {
               for (const additionalDataField of source.AdditionalDataFields) {
                  selectorItem.additionalData.set(
                     additionalDataField,
                     ObjectMapResolverService.getPropertyValue(result, additionalDataField)
                  );
               }
            }

            if (source.SelectedValues && source.SelectedValues.length > 0) {
               selectorItem.isChecked = source.SelectedValues.some(
                  (value) =>
                     value &&
                     selectorItem.value &&
                     value.toString().toLowerCase() === selectorItem.value.toString().toLowerCase()
               );
            }
            items.push(selectorItem);
         });
      }
      return items;
   }
   private processEmbeddedSetValueSource(source: ValueSourceModel): Array<SelectorItemModel> {
      const items = new Array<SelectorItemModel>();

      const selectedValue = ObjectMapResolverService.getPropertyValue(source.EmbeddedSet, source.EntityValueSourcePath);

      let embeddedValues;
      if (source.EmbeddedSet && !source.EmbeddedSetValueSourcePath) {
         embeddedValues = source.EmbeddedSet;
      } else {
         embeddedValues = ObjectMapResolverService.getPropertyValue(
            source.EmbeddedSet,
            source.EmbeddedSetValueSourcePath
         );
      }
      embeddedValues.split(source.EmbeddedSetValueSourceDelimiter).map((item) => {
         const selectorItem = new SelectorItemModel();
         selectorItem.displayValue = item;
         selectorItem.value = item;
         selectorItem.isChecked = selectedValue === item;
         items.push(selectorItem);
      });
      return items;
   }
}
