<ng-container *ngIf="anchorTemplate; else default">
   <ng-container *ngTemplateOutlet="anchorTemplate; context: { $implicit: data, additionalData: additionalData }">
   </ng-container>
</ng-container>

<ng-template #default>
   <a
      #actionLink
      class="action-link"
      [attr.title]="title"
      *ngIf="routerLink && !href"
      [ngClass]="cssClasses"
      [routerLink]="routerLink"
      [queryParams]="queryParams"
      [class.button]="linkMode === linkModes.Button"
      [class.disabled]="disabled"
      [target]="target"
      (click)="emitClicked($event)"
      tabindex="0"
   >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
   </a>

   <a
      #actionLink
      class="action-link"
      [attr.title]="title"
      *ngIf="!routerLink && !href"
      [ngClass]="cssClasses"
      [class.button]="linkMode === linkModes.Button"
      [class.disabled]="disabled"
      [target]="target"
      (click)="emitClicked($event)"
      tabindex="0"
   >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
   </a>

   <a
      #actionLink
      class="action-link"
      [attr.title]="title"
      *ngIf="!routerLink && href"
      [ngClass]="cssClasses"
      [target]="target"
      [href]="href"
      [class.button]="linkMode === linkModes.Button"
      [class.disabled]="disabled"
      (click)="emitClicked($event)"
      tabindex="0"
   >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
   </a>

   <ng-template #linkContent>
      <ng-container *ngIf="!contentTemplate">
         {{ text }}
      </ng-container>
      <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: data }">
      </ng-container>
   </ng-template>
</ng-template>