import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { Observable } from "rxjs";

import { EntitySearchConfigurationModel } from "./entity-search-configuration.model";

@Injectable()
export abstract class EntitySearchConfigurationServiceBase {
   abstract getSearchConfiguration(entityType: EntityType): Observable<EntitySearchConfigurationModel>;
}
