<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="name + '-properties'"
      [parameter]="propertyParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="name + '-units'"
      [parameter]="unitParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<lcs-checkbox-report-parameter
   *ngIf="showInactivePropertiesParameter"
   [name]="name + '-showInactiveProperties'"
   [parameter]="showInactivePropertiesParameter"
>
   {{ showInactivePropertiesParameter.DisplayName }}
</lcs-checkbox-report-parameter>