import { Pipe, PipeTransform } from "@angular/core";
import { parse, format, isValidNumber } from "libphonenumber-js";
import { PhoneNumberPipeOptionsModel } from "./phonenumber-pipe-options.model";

@Pipe({
   name: "phonenumber",
})
export class PhoneNumberPipe implements PipeTransform {
   transform(value: string, options?: PhoneNumberPipeOptionsModel): any {
      if (!options) {
         options = new PhoneNumberPipeOptionsModel();
      }

      if (!value || !isValidNumber(value, options.CountryCode)) {
         return value;
      }

      const parsedNumber = parse(value, options.CountryCode);
      const formatted = format(parsedNumber, options.NumberFormat);
      return formatted;
   }
}
