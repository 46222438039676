<div
   class="entity-link-selector-wrapper"
   [formGroup]="group"
>
   <div
      [class.display-horizontal]="displayHorizontal"
      [class.add-hidden]="isAddHidden"
      [class.display-vertical]="!displayHorizontal"
   >
      <label *ngIf="entityTypeSelectorLabel">{{entityTypeSelectorLabel}}</label>
      <lcs-select
         lcsLegacySingleSelector
         class="entity-type-selector"
         [formControlName]="entityLinkEntityTypeName"
         [displayName]="'Entity Type'"
         [valueSource]="entityLinkValueSource"
      >
      </lcs-select>
      <div *ngIf="entityType > 0">
         <label *ngIf="entitySelectorLabel">{{entitySelectorLabel}}</label>
         <lcs-select
            #entityLinkValue
            lcsEntitySelector
            class="entity-selector"
            [formControlName]="entityLinkValueName"
            [displayName]="'Entity'"
            [entityType]="entityType"
            [valueComparer]="entityLinkValueComparer"
            [filters]="entityFilters"
         >
         </lcs-select>
      </div>
   </div>
   <lcs-link
      *ngIf="!isAddHidden"
      class="lcs-link-selector flex-shrink-none align-items-center"
      title="Add Link"
      [contentTemplate]="contentTemplate2"
      (clicked)="onLinkEntityClicked()"
   >
      <ng-template #contentTemplate2>
         <i class="material-icons text-center">add</i>
      </ng-template>
   </lcs-link>
</div>