<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>
   <div>
      <label>NOTE: Do not refresh this page or you will be redirected to start over.</label>
   </div>
   <div class="flex">
      <div class="flex flex-half">
         <h5 class="form-header">Account Information</h5>
         <!-- <div class="form-element flex-full">
            <div>
               <label>Account Type</label>
            </div>
            <lcs-select
               lcsLegacySingleSelector
               [valueSource]="accountTypeValueSource"
               [ngModel]="selectedAccountType"
               (ngModelChange)="onAccountTypeChange($event)"
               [name]="accountTypeControlName"
               displayName="Account Type"
            ></lcs-select>
         </div> -->
         <div
            class=" flex flex-half"
            *ngIf="unitedStates; else canada"
            style="align-items:baseline;"
         >
            <div class="form-element flex-half">
               <label>Account Number *</label>
               <lcs-text-box
                  [(ngModel)]="ownerContributionPaymentModel.EpayACH.AccountNumber"
                  name="AccountNumber"
                  displayName="Account Number"
                  [lcsValidate]="accountNumberValidation"
                  [required]="true"
               ></lcs-text-box>
            </div>
            <div class="form-element flex-half">
               <label>Routing Number *
                  <i
                     (click)="showRoutingNumberInfo()"
                     class="routing-link material-icons"
                     style="vertical-align: bottom;margin-top:-1px"
                  >info</i>
               </label>
               <lcs-text-box
                  [(ngModel)]="ownerContributionPaymentModel.EpayACH.RoutingNumber"
                  name="RoutingNumber"
                  displayName="Routing Number"
                  [lcsValidate]="routingNumberValidation"
                  [required]="true"
               > </lcs-text-box>
            </div>
         </div>
         <ng-template #canada>
            <div class="flex flex-half">
               <div class="form-element flex-half">
                  <label>Institution Number *</label>
                  <lcs-text-box
                     [(ngModel)]="ownerContributionPaymentModel.EpayACH.InstitutionNumber"
                     name="InstitutionNumber"
                     displayName="Institution Number"
                     [required]="true"
                  ></lcs-text-box>
               </div>
               <div class="form-element flex-half">
                  <label>Transit Number *</label>
                  <lcs-text-box
                     [(ngModel)]="ownerContributionPaymentModel.EpayACH.TransitNumber"
                     name="TransitNumber"
                     displayName="Transit Number"
                     [required]="true"
                  ></lcs-text-box>
               </div>
            </div>
            <div class="form-element flex-half">
               <label>Account Number *</label>
               <lcs-text-box
                  [(ngModel)]="ownerContributionPaymentModel.EpayACH.AccountNumber"
                  name="AccountNumber"
                  displayName="Account Number"
                  [required]="true"
               ></lcs-text-box>
            </div>
         </ng-template>
         <h5 class="form-header">Payment Information</h5>
         <!-- TODO: styles for this block -->
         <div class="div-text flex flex-full ">
            <div class=" flex-half ">Amount</div>
            <div class="label-value flex-half">{{ ownerContributionPaymentModel.EpayACH.Amount | currency }}</div>
         </div>
         <div class="div-text flex flex-full ">
            <div class="flex-half">Convenience Fee</div>
            <div class="label-value flex-half">{{
               (ownerContributionPaymentModel.EpayACH.ConvenienceFee === null
               ? 0
               : ownerContributionPaymentModel.EpayACH.ConvenienceFee) | currency
               }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class="flex-half">Total</div>
            <div class="label-value flex-half">{{
               (ownerContributionPaymentModel.EpayACH.Total != 0
               ? ownerContributionPaymentModel.EpayACH.Total
               : ownerContributionPaymentModel.Total) | currency
               }}</div>
         </div>
      </div>
      <div class="flex flex-half">
         <h5 class="form-header flex-space-between">
            Billing Address
         </h5>
         <div class="form-element flex-half">
            <label>First Name *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.Firstname"
               name="firstName"
               displayName="First Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Last Name *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.LastName"
               name="lastName"
               displayName="Last Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Street 1 *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.BillingAddressStreet1"
               name="Street1"
               displayName="Street"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-half">
            <label>Street 2</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.BillingAddressStreet2"
               name="Street2"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-third">
            <label>City *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.BillingAddressCity"
               name="City"
               displayName="City"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="form-element flex-third">
            <label>State *</label>
            <lcs-select
               lcsStaticSetSelector
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.BillingAddressState"
               name="State"
               displayName="State"
               [items]="stateOptions"
               [required]="true"
            ></lcs-select>
         </div>
         <div class="form-element flex-third">
            <label>Postal Code *</label>
            <lcs-text-box
               [(ngModel)]="ownerContributionPaymentModel.EpayACH.BillingAddressPostalCode"
               name="PostalCode"
               displayName="Postal Code"
               [required]="true"
            ></lcs-text-box>
         </div>
      </div>
   </div>
   <div>
      <div *ngIf="ownerContributionPaymentModel.EpayACH.IsDisclaimerRequired">
         <lcs-checkbox
            [name]="'ownerContributionPaymentModel.EpayACH.IsDisclaimerMessage'"
            [ngModel]="checked"
            (ngModelChange)="allSelected($event)"
         >{{ ownerContributionPaymentModel.EpayACH.DisclaimerMessage }}</lcs-checkbox>
      </div>
      <div lcsFooter>
         <lcs-progress-button
            class="contribution-button"
            [(status)]="buttonStatus"
            [raised]="true"
         >Make Contribution</lcs-progress-button>
         <a
            class="zego-link"
            target="_blank"
            [href]="constantsService.ZegoPrivacyPage"
         > Zego Privacy Policy </a>
      </div>
   </div>
</form>