import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LinkModule } from "@lcs/link/link.module";

import { PipesModule } from "../pipes/pipes.module";
import { SortableListComponent } from "./sortable-list.component";

@NgModule({
   imports: [CommonModule, FormsModule, DragDropModule, PipesModule, LinkModule],
   declarations: [SortableListComponent],
   exports: [SortableListComponent],
})
export class SortableListModule {}
