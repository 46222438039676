import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";

import { ValidationHelper } from "../validation-helper";
import { DateValidator } from "./date.validator";
import { MiscValidators } from "./misc-validators";
import { NumberValidator } from "./number.validator";
import { PasswordValidator } from "./password.validator";
import { StandardPhoneNumberValidator } from "./standard-phone-number.validator";
import { TimeValidator } from "./time.validator";

@Directive({
   selector: "[lcsValidate]",
   providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidationDirective), multi: true }],
})
export class ValidationDirective implements OnChanges, Validator {
   private static numericDataTypes: ExpressDataTypes[] = [
      ExpressDataTypes.Numeric,
      ExpressDataTypes.Currency,
      ExpressDataTypes.Count,
   ];

   @Input() customValidatorData: any;

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsValidate") validation: ValidationModel;

   private onChange: () => void;

   private validator: (control: AbstractControl, customValidatorData: any) => ValidationErrors;

   validate(control: AbstractControl): ValidationErrors | null {
      if (this.validation && (!this.validation.validateOnlyIfDirty || control.dirty)) {
         let errors: ValidationErrors | null = null;
         if (this.validator) {
            errors = this.validator(control, this.customValidatorData);
         }
         if (this.validation.customValidator) {
            const customValidationErrors = this.validation.customValidator(control, this.customValidatorData);
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
            errors = ValidationHelper.mergeErrorMessages([errors, customValidationErrors]);
         }

         return errors;
      }
      return null;
   }

   registerOnValidatorChange(fn: () => void): void {
      this.onChange = fn;
   }

   ngOnChanges(changes: SimpleChanges) {
      if ("validation" in changes || "customValidatorData" in changes) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidatorFn | null' is not assignable to typ... Remove this comment to see the full error message
         this.validator = Validators.compose(ValidationDirective.getValidators(this.validation));
         if (this.onChange) {
            this.onChange();
         }
      }
   }

   /**
    * Enhanced version of built-in pattern validator that takes optional patternValidationMessage for
    * a more user friendly validation message.
    *
    * @param pattern
    * @param patternValidationMessage
    */
   static pattern(pattern: string | RegExp, patternValidationMessage: string = "format is invalid"): ValidatorFn {
      if (!pattern) {
         return Validators.nullValidator;
      }
      let regex: RegExp;
      let regexStr: string;
      if (typeof pattern === "string") {
         regexStr = "";

         if (pattern.charAt(0) !== "^") {
            regexStr += "^";
         }

         regexStr += pattern;

         if (pattern.charAt(pattern.length - 1) !== "$") {
            regexStr += "$";
         }

         regex = new RegExp(regexStr);
      } else {
         regexStr = pattern.toString();
         regex = pattern;
      }
      return (control: AbstractControl): ValidationErrors | null => {
         if (ValidationHelper.isNullOrEmpty(control.value)) {
            return null; // don't validate empty values to allow optional controls
         }
         const value: string = control.value;
         return regex.test(value)
            ? null
            : {
                 pattern: {
                    requiredPattern: regexStr,
                    actualValue: value,
                    patternValidationMessage: patternValidationMessage,
                 },
              };
      };
   }

   static requiredIfDirty(control: AbstractControl): ValidationErrors | null {
      if (control.dirty && ValidationHelper.isNullOrEmpty(control.value)) {
         return { requiredIfDirty: { errorMessage: "is required once modified" } };
      }
      return null;
   }

   static getValidators(validation: ValidationModel): Array<ValidatorFn> {
      if (!validation || validation.bypassValidation) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValidatorFn... Remove this comment to see the full error message
         return null;
      }

      let validators = new Array<ValidatorFn>();

      if (validation.required) {
         if (validation.dataType === ExpressDataTypes.Key) {
            validators.push(MiscValidators.requiredEntity);
         } else {
            validators.push(Validators.required);
         }
      }

      if (validation.requiredTrue) {
         validators.push(Validators.requiredTrue);
      }

      if (validation.requiredIfDirty) {
         validators.push(ValidationDirective.requiredIfDirty);
      }

      if (this.numericDataTypes.indexOf(validation.dataType) > -1) {
         validators = validators.concat(NumberValidator.getValidators(validation));
      } else {
         if (typeof validation.min === "number") {
            validators.push(Validators.min(validation.min));
         }
         if (typeof validation.max === "number") {
            validators.push(Validators.max(validation.max));
         }
         if (typeof validation.minLength === "number") {
            validators.push(Validators.minLength(validation.minLength));
         }
         if (typeof validation.maxLength === "number") {
            validators.push(Validators.maxLength(validation.maxLength));
         }
         if (validation.email) {
            validators.push(Validators.email);
         }
      }

      if (validation.pattern) {
         validators.push(ValidationDirective.pattern(validation.pattern, validation.patternValidationMessage));
      }
      if (validation.notUnset && validation.unsetValue !== undefined) {
         validators.push(MiscValidators.notUnset(validation.unsetValue));
      }

      switch (validation.dataType) {
         case ExpressDataTypes.Time:
            validators = validators.concat(TimeValidator.validateTime(validation));
            break;
         case ExpressDataTypes.PhoneNumber:
            validators = validators.concat(StandardPhoneNumberValidator.validatePhoneNumber());
            break;
         case ExpressDataTypes.Password:
            validators = validators.concat(PasswordValidator.validatePassword(validation));
            break;
         case ExpressDataTypes.Date:
         case ExpressDataTypes.DateTime:
            validators = validators.concat(DateValidator.getValidators(validation));
            break;
      }

      return validators;
   }
}
