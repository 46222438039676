////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum SignableDocumentPacketStatus {
   NotSet = 0,
   Published = 1,
   PendingAcceptance = 2,
   Completed = 3,
   Voided = 4,
   Expired = 5,
   Null = -1,
}
