import { ExpressReports } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-reports.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";

import { ReportReportParameterModel } from "./report-report-parameter.model";
import { ReportModel } from "./report.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ExpressReportModel {
   ExpressReport: ExpressReports;
   Report: Report;
   ReportModel: ReportModel;
   ReportParameters: Array<ReportReportParameterModel>;
}
