import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { ValidationModule } from "../validation/validation.module";
import { TimePickerComponent } from "./time-picker.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule],
   declarations: [TimePickerComponent],
   exports: [TimePickerComponent],
})
export class TimePickerModule {}
