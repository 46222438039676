import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { ReportCategoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report-category.embed-options";
import { ReportReportParameterEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report-report-parameter.embed-options";
import { ReportSortOptionEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report-sort-option.embed-options";
import { ReportEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report.embed-options";
import { ReportGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-report-get-options/report.report-get-options";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DataReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-report.model";
import { ReportCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-category.model";
import { ReportParameterValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value.model";
import { ReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-report-parameter.model";
import { ReportSortOptionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-sort-option.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { RunReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/run-report.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ReportsService {
   static readonly CheckForMinnCrpUdfsResource = "CheckForMinnCrpUdfs";
   static readonly DataReportResource = "DataReport";
   static readonly DownloadFileResource = "DownloadFile";
   static readonly GetByPostResource = "GetByPost";
   static readonly OwnerReportsResource = "OwnerReports";
   static readonly ParametersResource = "Parameters";
   static readonly ReportCategoryResource = "ReportCategory";
   static readonly ReportParametersResource = "ReportParameters";
   static readonly ReportSortOptionsResource = "ReportSortOptions";
   static readonly RunReportResource = "RunReport";
   static readonly RunReportSeparateResource = "RunReportSeparate";
   static readonly RunReportSeparateWithRestrictedParametersResource = "RunReportSeparateWithRestrictedParameters";
   static readonly endpoint = "ReportsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: Report,
      embeds?: Array<string | ReportEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: Report,
      embeds?: Array<string | ReportEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ReportModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCheckForMinnCrpUdfsUrl(
      reportID?: Report | null,
      dataSourceType?: EntityType | null,
      year?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (reportID !== undefined && reportID !== null) {
         additionalParameters.push("reportID=" + reportID);
      }
      if (dataSourceType !== undefined && dataSourceType !== null) {
         additionalParameters.push("dataSourceType=" + dataSourceType);
      }
      if (year !== undefined && year !== null) {
         additionalParameters.push("year=" + year);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/CheckForMinnCrpUdfs",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCheckForMinnCrpUdfs(
      reportID?: Report | null,
      dataSourceType?: EntityType | null,
      year?: number | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postCheckForMinnCrpUdfsUrl(reportID, dataSourceType, year, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postDataReportUrl(
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/DataReport",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postDataReport(
      data?: DataReportModel | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postDataReportUrl(getOptions, additionalParameters), data);
   }

   getDownloadFileUrl(reportKey?: string | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (reportKey !== undefined && reportKey !== null) {
         additionalParameters.push("reportKey=" + reportKey);
      }
      return this.apiService.getUrl(
         ReportsService.endpoint + "/DownloadFile",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDownloadFile(reportKey?: string | null, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getDownloadFileUrl(reportKey, additionalParameters));
   }

   postGetByPostUrl(id: Report, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: Report,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ReportModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getOwnerReportsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/OwnerReports",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerReportsCollection(additionalParameters?: Array<string>): Observable<Array<Report>> {
      return this.apiService.getCollection(this.getOwnerReportsCollectionUrl(additionalParameters));
   }

   getParametersUrl(
      id: Report,
      itemID: ReportParameter,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/Parameters",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getParameters(
      id: Report,
      itemID: ReportParameter,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ReportReportParameterModel> {
      return this.apiService.getSingle(this.getParametersUrl(id, itemID, embeds, additionalParameters));
   }

   getParametersCollectionUrl(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/Parameters",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getParametersCollection(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportReportParameterModel>> {
      return this.apiService.getCollection(
         this.getParametersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getReportCategoryUrl(
      id: Report,
      embeds?: Array<string | ReportCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/ReportCategory",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReportCategory(
      id: Report,
      embeds?: Array<string | ReportCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ReportCategoryModel> {
      return this.apiService.getSingle(this.getReportCategoryUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getReportParametersUrl(
      id: Report,
      itemID: ReportParameter,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/ReportParameters",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReportParameters(
      id: Report,
      itemID: ReportParameter,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ReportReportParameterModel> {
      return this.apiService.getSingle(
         this.getReportParametersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getReportParametersCollectionUrl(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/ReportParameters",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getReportParametersCollection(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportReportParameterEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportReportParameterModel>> {
      return this.apiService.getCollection(
         this.getReportParametersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getReportSortOptionsCollectionUrl(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportSortOptionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReportsService.endpoint + "/ReportSortOptions",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getReportSortOptionsCollection(
      id: Report,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportSortOptionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportSortOptionModel>> {
      return this.apiService.getCollection(
         this.getReportSortOptionsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRunReportUrl(
      id: Report,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (parameters !== undefined && parameters !== null && parameters.length > 0) {
         additionalParameters.push("parameters=" + parameters);
      }
      return this.apiService.getUrl(
         ReportsService.endpoint + "/RunReport",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRunReport(
      id: Report,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getRunReportUrl(id, parameters, getOptions, additionalParameters));
   }

   postRunReportUrl(
      id: Report,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/RunReport",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReport(
      id: Report,
      runReportModel?: RunReportModel | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postRunReportUrl(id, getOptions, additionalParameters), runReportModel);
   }

   getRunReportSeparateUrl(
      id: Report,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (parameters !== undefined && parameters !== null && parameters.length > 0) {
         additionalParameters.push("parameters=" + parameters);
      }
      return this.apiService.getUrl(
         ReportsService.endpoint + "/RunReportSeparate",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRunReportSeparate(
      id: Report,
      parameters?: Array<ReportParameterValueModel> | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getRunReportSeparateUrl(id, parameters, getOptions, additionalParameters));
   }

   postRunReportSeparateUrl(
      id: Report,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/RunReportSeparate",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReportSeparate(
      id: Report,
      runReportModel?: RunReportModel | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postRunReportSeparateUrl(id, getOptions, additionalParameters), runReportModel);
   }

   postRunReportSeparateWithRestrictedParametersUrl(
      id: Report,
      getOptions?: Array<string | ReportGetOptions> | null,
      ignoreCanRunReportPrivs?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      if (ignoreCanRunReportPrivs !== undefined && ignoreCanRunReportPrivs !== null) {
         additionalParameters.push("ignoreCanRunReportPrivs=" + ignoreCanRunReportPrivs);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/RunReportSeparateWithRestrictedParameters",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReportSeparateWithRestrictedParameters(
      id: Report,
      runReportModel?: RunReportModel | null,
      getOptions?: Array<string | ReportGetOptions> | null,
      ignoreCanRunReportPrivs?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(
         this.postRunReportSeparateWithRestrictedParametersUrl(
            id,
            getOptions,
            ignoreCanRunReportPrivs,
            additionalParameters
         ),
         runReportModel
      );
   }
}
