import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { OwnerHistoryNotesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-history-notes.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { HistoryService } from "projects/libraries/owa-gateway-sdk/src/lib/services/history.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { FileCarouselComponent } from "../../file-carousel/file-carousel.component";
import { FileCarouselConfigurationModel } from "../../file-carousel/models/file-carousel-configuration.model";
import { FileCarouselModel } from "../../file-carousel/models/file-carousel.model";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-history-notes-list",
   templateUrl: "./history-notes-list.component.html",
   styleUrls: ["./history-notes-list.component.css"],
   providers: [DataTableFiltersService],
})
export class HistoryNotesListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   columns: string[] = ["HistoryDate", "Note", "File"];

   observableHistory: Observable<Array<OwnerHistoryNotesModel>>;
   images: Map<number, Array<FileCarouselModel>>;

   historyFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   appliedSideBarFilters: Array<FilterOption>;
   showFilters: boolean = false;
   hasPinnedNotes: boolean;
   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   threshold: string = "30%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;
   hasThemePinnedNotes: boolean;
   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _observableHistory = new BehaviorSubject<Array<any>>(null);
   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);
   private unsubscribe = new Subject<void>();

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setMonth(new Date().getMonth() - 6), "MM/dd/yy", "en-US");

   constructor(
      private historyService: HistoryService,
      private errorMessageService: ErrorMessageService,
      private dialogCreationService: DialogCreationService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.observableHistory = this._observableHistory.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.historyFilters = this.getFilters();
      this.images = new Map<number, Array<FileCarouselModel>>();
      this.setDefaultDateFilter();
   }

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.historyFilters.find((f) => f.FilterOption.FilterName === "HistoryDate").FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.historyFilters.find((f) => f.FilterOption.FilterName === "HistoryDate").FilterOption
      );
   }

   openCarousel(historyID: number, note: string) {
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = FileCarouselComponent;
      dialogConfigModel.disableClose = false;
      dialogConfigModel.title = note;

      const fileConfigModel = new FileCarouselConfigurationModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FileCarouselModel[] | undefined' is not assi... Remove this comment to see the full error message
      fileConfigModel.FileSource = this.images.get(historyID);
      dialogConfigModel.styleClass = fileConfigModel.FileSource.every(function (value, index) {
         return value.isImage;
      })
         ? "image-carousel"
         : "file-carousel";
      this.dialogCreationService.open(dialogConfigModel, fileConfigModel);
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.historyService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const historyList = response.body;
               const historyWithFile = historyList.filter(
                  (historyItem) =>
                     historyItem.HasFile && historyItem.Files !== undefined && historyItem.Files.length > 0
               );
               if (historyWithFile.length > 0) {
                  const imageMap = this.images;
                  const imageExtensions = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tif", ".tiff"];
                  historyWithFile.forEach(function (historyItem) {
                     const imageList = new Array<FileCarouselModel>();
                     historyItem.Files.forEach(function (file) {
                        const image = new FileCarouselModel();
                        file.IsImage = image.isImage = imageExtensions.some(function (element) {
                           return element === file.Extension.toLowerCase();
                        });
                        image.source = image.fileLink = file.DownloadURL;
                        image.extension = file.Extension;
                        image.fileLink = file.DownloadURL;
                        image.alt = "No preview available";
                        image.name = file.Name;
                        imageList.push(image);
                     });
                     imageMap.set(historyItem.HistoryID, imageList);
                  });
               }
               return historyList;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData =
                  this._observableHistory && this._observableHistory.value ? this._observableHistory.value : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._observableHistory.next(newData);
               this.results.next(this._observableHistory.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
               if (results.find((r) => r.IsPinned)) {
                  this.hasPinnedNotes = true;
                  this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
                     takeUntil(this.unsubscribe)
                  ).subscribe((prefs) => {
                     if (prefs != null) {
                        var themePref = prefs.find((p) => p.Name === "OWAToolbarColor");
                        if (themePref != null && themePref.Value != "#F28228") {
                           this.hasThemePinnedNotes = true;
                        }
                     }
                  });
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll($event): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      const data = this._observableHistory.value ? this._observableHistory.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || data.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true, refreshData);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.historyFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const historyDateFilter = this.getDataTableFilterModel(
         "HistoryDate",
         "Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between]
      );
      const filterList = [historyDateFilter];
      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.historyFilters = this.resetFilter(this.historyFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (dataType === ExpressDataTypes.Date) {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.Between, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
