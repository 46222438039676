import { ApplicationFieldInputFieldTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/application-field-input-field-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";

export class ControlTypeAdapterService {
   public static convertApplicationInputFieldTypeToControlType(
      type: ApplicationFieldInputFieldTypes
   ): ExpressLayoutControlTypes {
      if (type === ApplicationFieldInputFieldTypes.Dropdown) {
         return ExpressLayoutControlTypes.SingleSelector;
      } else if (type === ApplicationFieldInputFieldTypes.YesNo) {
         return ExpressLayoutControlTypes.Checkbox;
      } else if (type === ApplicationFieldInputFieldTypes.Date) {
         return ExpressLayoutControlTypes.DatePicker;
      } else if (type === ApplicationFieldInputFieldTypes.Numeric) {
         return ExpressLayoutControlTypes.NumericTextBox;
      } else if (type === ApplicationFieldInputFieldTypes.File) {
         return ExpressLayoutControlTypes.FileUpload;
      } else if (type === ApplicationFieldInputFieldTypes.Image) {
         return ExpressLayoutControlTypes.ImageUpload;
      } else if (type === ApplicationFieldInputFieldTypes.SSN) {
         return ExpressLayoutControlTypes.SocialSecurityNumber;
      } else if (type === ApplicationFieldInputFieldTypes.PhoneNumber) {
         return ExpressLayoutControlTypes.PhoneNumberInput;
      } else if (
         type === ApplicationFieldInputFieldTypes.Property ||
         type === ApplicationFieldInputFieldTypes.UnitUnitType
      ) {
         return ExpressLayoutControlTypes.EntitySelector;
      }
      return ExpressLayoutControlTypes.TextBox;
   }

   public static convertUdfTypeToControlType(
      udfType: UserDefinedFieldType,
      comboList?: string,
      delimiter: string = "|",
      useDateRangeControl: boolean = false,
      filterOperations?: FilterOperations
   ): ExpressLayoutControlTypes {
      if (udfType === UserDefinedFieldType.Dropdown && comboList) {
         if (comboList.charAt(0) === delimiter) {
            return ExpressLayoutControlTypes.InputWithOptions;
         } else {
            return ExpressLayoutControlTypes.SingleSelector;
         }
      } else if (udfType === UserDefinedFieldType.YesNo) {
         return ExpressLayoutControlTypes.SingleSelector;
      } else if (udfType === UserDefinedFieldType.Date) {
         if (useDateRangeControl || filterOperations === FilterOperations.Between) {
            return ExpressLayoutControlTypes.DateRangePicker;
         }
         return ExpressLayoutControlTypes.DatePicker;
      } else if (udfType === UserDefinedFieldType.Numeric) {
         if (filterOperations === FilterOperations.Between) {
            return ExpressLayoutControlTypes.NumberRangeInput;
         }
         return ExpressLayoutControlTypes.NumericTextBox;
      } else if (udfType === UserDefinedFieldType.File) {
         return ExpressLayoutControlTypes.FileUpload;
      } else if (udfType === UserDefinedFieldType.Image) {
         return ExpressLayoutControlTypes.ImageUpload;
      } else if (udfType === UserDefinedFieldType.Checkedlist) {
         return ExpressLayoutControlTypes.SingleLineMultiSelector;
      } else if (udfType === UserDefinedFieldType.EncryptedText) {
         return ExpressLayoutControlTypes.UDFEncryptedText;
      }
      return ExpressLayoutControlTypes.TextBox;
   }

   public static convertFilterControlTypeToFilterInputControlType(
      filterFieldControlType: ExpressLayoutControlTypes,
      filterOperation?: FilterOperations
   ): ExpressLayoutControlTypes {
      if (filterOperation == null) {
         return ExpressLayoutControlTypes.NotSet;
      }
      if (filterFieldControlType === ExpressLayoutControlTypes.ColorPicker) {
         return ExpressLayoutControlTypes.ColorPicker;
      }
      if (filterOperation === FilterOperations.HasValue) {
         return ExpressLayoutControlTypes.Checkbox;
      }

      if (filterFieldControlType === ExpressLayoutControlTypes.ConfirmDatePicker) {
         filterFieldControlType = ExpressLayoutControlTypes.DatePicker;
      }

      const singleValueOperations: Array<FilterOperations> = [
         FilterOperations.LessThan,
         FilterOperations.LessThanOrNull,
         FilterOperations.LessThanOrEqualTo,
         FilterOperations.LessThanOrEqualToOrNull,
         FilterOperations.GreaterThan,
         FilterOperations.GreaterThanOrNull,
         FilterOperations.GreaterThanOrEqualTo,
         FilterOperations.GreaterThanOrEqualToOrNull,
         FilterOperations.EqualTo,
         FilterOperations.NotEqualTo,
         FilterOperations.Contains,
      ];

      // Control Types that don't map directly to a filter
      if (
         (filterFieldControlType === ExpressLayoutControlTypes.DatePicker ||
            filterFieldControlType === ExpressLayoutControlTypes.DateRangePicker ||
            filterFieldControlType === ExpressLayoutControlTypes.DateTimePicker) &&
         filterOperation === FilterOperations.Between
      ) {
         return ExpressLayoutControlTypes.DateRangePicker;
      } else if (
         filterFieldControlType === ExpressLayoutControlTypes.NumberRangeInput ||
         filterFieldControlType === ExpressLayoutControlTypes.NumericTextBox ||
         filterFieldControlType === ExpressLayoutControlTypes.CalculatorInput ||
         filterFieldControlType === ExpressLayoutControlTypes.Slider
      ) {
         if (filterOperation === FilterOperations.Between) {
            return ExpressLayoutControlTypes.NumberRangeInput;
         }
      }

      if (
         filterFieldControlType === ExpressLayoutControlTypes.Label ||
         filterFieldControlType === ExpressLayoutControlTypes.TextBox ||
         filterFieldControlType === ExpressLayoutControlTypes.TextArea ||
         filterFieldControlType === ExpressLayoutControlTypes.ContactEmailInput ||
         filterFieldControlType === ExpressLayoutControlTypes.ContactNameInput
      ) {
         return ExpressLayoutControlTypes.TextBox;
      }

      if (
         filterFieldControlType === ExpressLayoutControlTypes.NumericTextBox &&
         [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filterOperation)
      ) {
         return ExpressLayoutControlTypes.NumericTextBox;
      }
      if (
         filterFieldControlType === ExpressLayoutControlTypes.EntitySelector &&
         [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filterOperation)
      ) {
         return ExpressLayoutControlTypes.EntityMultiSelector;
      }
      if (
         filterFieldControlType === ExpressLayoutControlTypes.EnumSelector &&
         [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filterOperation)
      ) {
         return ExpressLayoutControlTypes.EnumMultiSelector;
      }
      if (
         filterFieldControlType === ExpressLayoutControlTypes.AccountSelector &&
         [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filterOperation)
      ) {
         return ExpressLayoutControlTypes.AccountMultiSelector;
      }

      if (singleValueOperations.some((o) => o === filterOperation) && filterFieldControlType) {
         return filterFieldControlType;
      }

      if (
         filterFieldControlType === ExpressLayoutControlTypes.Checkbox ||
         (filterFieldControlType === ExpressLayoutControlTypes.SingleLineMultiSelector && [
            FilterOperations.In,
            FilterOperations.NotIn,
         ])
      ) {
         return filterFieldControlType;
      }

      if (
         (filterFieldControlType === ExpressLayoutControlTypes.PropertyOwnershipSelector ||
            filterFieldControlType === ExpressLayoutControlTypes.PropertyGroupSelector) &&
         [FilterOperations.In, FilterOperations.NotIn].some((f) => f === filterOperation)
      ) {
         return filterFieldControlType;
      }

      if (
         filterFieldControlType === ExpressLayoutControlTypes.BankSelector &&
         [FilterOperations.In, FilterOperations.NotIn].some((f) => f === filterOperation)
      ) {
         return ExpressLayoutControlTypes.EntityMultiSelector;
      }
      if (
         filterFieldControlType === ExpressLayoutControlTypes.PropertyGroupPropertySelector &&
         [FilterOperations.In, FilterOperations.NotIn].some((o) => o === filterOperation)
      ) {
         return ExpressLayoutControlTypes.PropertyGroupPropertySelector;
      }

      return ExpressLayoutControlTypes.NotSet;
   }

   public static convertDataTypeToControlType(dataType: string, name?: string): ExpressLayoutControlTypes {
      if (dataType === "DateTime") {
         return ExpressLayoutControlTypes.DatePicker;
      } else if (dataType === "Boolean") {
         return ExpressLayoutControlTypes.Checkbox;
      } else if (dataType === "Int32" || dataType === "Decimal" || dataType === "Single") {
         return ExpressLayoutControlTypes.NumericTextBox;
      } else if (name != null && name.toLowerCase().includes("ssn")) {
         return ExpressLayoutControlTypes.SocialSecurityNumber;
      }
      return ExpressLayoutControlTypes.TextBox;
   }
}
