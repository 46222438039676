import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OwnerGroupDetailFields extends EntityField {
   static readonly MetaTag: OwnerGroupDetailFields = new OwnerGroupDetailFields("MetaTag");

   static readonly Owner: OwnerGroupDetailFields = new OwnerGroupDetailFields("Owner");

   static readonly OwnerGroupDetailID: OwnerGroupDetailFields = new OwnerGroupDetailFields("OwnerGroupDetailID");

   static readonly OwnerGroupID: OwnerGroupDetailFields = new OwnerGroupDetailFields("OwnerGroupID");

   static readonly OwnerGroups: OwnerGroupDetailFields = new OwnerGroupDetailFields("OwnerGroups");

   static readonly OwnerID: OwnerGroupDetailFields = new OwnerGroupDetailFields("OwnerID");
}
