<div
   [class.disabled]="disabled"
   class="static-field-wrapper"
   [ngStyle]="wrapperStyleOverride"
>
   <span
      *ngIf="label"
      class="static-field-label"
      [attr.title]="labelTooltip"
      [ngStyle]="labelStyleOverride"
   >{{label}}</span>
   <span
      class="static-field-value"
      [attr.title]="tooltipOverride ? tooltipOverride : customPipeTransform ?
      customPipeTransform.transform(value, customPipeTransformArgs) :
      (value | valueFormatter:formatDataType)"
      [ngStyle]="valueStyleOverride"
   >{{ customPipeTransform ?
      customPipeTransform.transform(value, customPipeTransformArgs) :
      (value | valueFormatter:formatDataType) }}</span>
</div>