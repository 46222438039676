import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { UserRoleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-role.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { UsersService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/users.service";
import { CurrentUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.model";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { filter, Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-users-to-include-report-parameter",
   templateUrl: "users-to-include-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class UsersToIncludeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() standalone: boolean;

   @Input() validation: ValidationModel;

   @Input() hasAsk: boolean;

   usersReportParameter: ReportReportParameterViewModel;

   showInactiveUsersReportParameter: ReportReportParameterViewModel;

   appliedValueSourceFilters: Array<FilterOption>;

   includeInactiveUsers: boolean = false;

   loading: boolean = true;

   usersValueModel: ReportParameterValueModel;

   selectedValues: Array<any> = new Array<any>();

   showOtherUsers: boolean = true;

   valueSources: Array<ValueSourceModel>;

   private defaultFilters: FilterOption[];

   private isSalesReport: boolean = false;

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(
      private currentUserService: CurrentUserService,
      private reportParametersService: ReportParametersService,
      private usersService: UsersService,
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase
   ) {}

   ngOnInit() {
      this.usersReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.USERIDS
      ) as ReportReportParameterViewModel;
      this.showInactiveUsersReportParameter = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.INCLUDEINACTIVEUSERS
      ) as ReportReportParameterViewModel;
      if (!this.showInactiveUsersReportParameter) {
         const showInactiveUsers = new ReportParameterValueModel(ReportParameter.INCLUDEINACTIVEUSERS, "false");
         this.reportParametersService.updateReportParameterValue(showInactiveUsers);
         const showInactiveUsersView = new ReportReportParameterViewModel();
         showInactiveUsersView.DisplayName = `Inactive Users`;
         showInactiveUsersView.DefaultValue = "false";
         showInactiveUsersView.ReportParameterID = ReportParameter.INCLUDEINACTIVEUSERS;
         this.showInactiveUsersReportParameter = showInactiveUsersView;
      }
      this.usersValueModel = this.reportParametersService.reportParameterValues.get(
         ReportParameter.USERIDS
      ) as ReportParameterValueModel;
      this.includeInactiveUsers =
         this.showInactiveUsersReportParameter != null &&
         this.showInactiveUsersReportParameter.DefaultValue != null &&
         this.showInactiveUsersReportParameter.DefaultValue.toString().toLowerCase() === "true";
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            if (updatedValue.reportParameter === this.usersReportParameter.ReportParameterID) {
               this.reportParameterValuesUpdated(updatedValue);
            } else if (updatedValue.reportParameter === this.showInactiveUsersReportParameter.ReportParameterID) {
               this.onIncludeInactiveUsersChanged(updatedValue.value);
            }
         });

      this.reportParametersService.reportParameterStatusChanged
         .pipe(
            filter((updatedValue) => updatedValue.reportParameter === this.usersReportParameter.ReportParameterID),
            takeUntil(this.unsubscribe)
         )
         .subscribe((status) => {
            this.disabled = status.disabled;
         });

      const salesReports = new Array<Report>(
         Report.CommissionRep,
         Report.SalesGPComparison,
         Report.MonthlyGPSummary,
         Report.MonthlyCommissionSummary,
         Report.SummaryAnalysisComparison,
         Report.EstimateSalesRepresentativeReview
      );
      this.isSalesReport = salesReports.indexOf(this.usersReportParameter.ReportID) > -1;
      if (this.isSalesReport) {
         const currentUser = this.currentUserService.currentUser.value as CurrentUserModel;
         this.usersService
            .getRolesCollection(currentUser.UserID, null, ["Role"])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (roles: Array<UserRoleModel>) => {
                  this.showOtherUsers = roles.some((role) => role.Name.indexOf("Sales Manager") > -1);
                  this.initializeValueSource(this.showOtherUsers, currentUser);
                  if (!this.showOtherUsers) {
                     this.usersValueModel.value = `(${currentUser.UserID})`;
                     this.usersValueModel.rawValues = [this.usersValueModel.value];
                  }
                  this.loading = false;
               },
               () => {
                  this.loading = false;
               }
            );
      } else {
         this.initializeValueSource(true);
         this.loading = false;
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   onSelectionChanged(selectionChangeModel: SelectionChangeModel) {
      const checkedItems = selectionChangeModel.checkedItems;
      let values = new Array<any>();
      const selectAll = selectionChangeModel.selectAll;
      this.usersValueModel.value = "";

      if (!this.usersReportParameter.ReportParameterValueSource.AllowsMultipleValues) {
         if (checkedItems.length === 1 && checkedItems[0].value) {
            this.usersValueModel.value = checkedItems[0].value.toString();
         }
      } else {
         if (selectAll) {
            this.usersValueModel.value = "All";
         } else if (checkedItems.length > 0) {
            values = checkedItems.map((f) => f.value);
            this.usersValueModel.value = "(" + values.join(",") + ")";
         } else {
            this.usersValueModel.value = "";
         }
      }
      this.usersValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.usersValueModel);
   }

   onIncludeInactiveUsersChanged(includeInactiveUsers: boolean) {
      this.appliedValueSourceFilters = this.buildUserFilters(includeInactiveUsers);
   }

   askChanged(isAsk: boolean) {
      this.usersReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.usersReportParameter.ReportParameterID, isAsk);
   }

   private initializeValueSource(showOtherUsers: boolean, currentUser?: UserModel) {
      const valueSources = new Array<ValueSourceModel>();
      if (showOtherUsers && this.usersReportParameter) {
         for (const entityValueSource of this.usersReportParameter.ReportParameterValueSource.EntitySources) {
            const valueSource = new ValueSourceModel();
            valueSource.Type = ValueSourceTypes.Entity;
            valueSource.EndpointIsSearch = true;
            valueSource.EntityType = entityValueSource.EntityType;
            if (entityValueSource.ModelSource !== EntityType[entityValueSource.EntityType].toString()) {
               valueSource.Endpoint = entityValueSource.ModelSource;
            }
            valueSource.DisplayValueSourcePath = entityValueSource.DisplayNameProperty;
            valueSource.EntityValueSourcePath = entityValueSource.ValueProperty;
            if (this.usersReportParameter.ReportParameterValueSource.DefaultValue !== "0") {
               valueSource.DefaultValue = this.usersReportParameter.ReportParameterValueSource.DefaultValue;
            }
            if (this.usersReportParameter.ReportParameterValueSource.AllowsSelectAll) {
               valueSource.AllValue = "all";
            }
            this.entitySearchConfigurationService
               .getSearchConfiguration(valueSource.EntityType)
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((searchConfiguration) => {
                  this.defaultFilters = searchConfiguration.GlobalFilters.map(
                     FilterOption.FromExpressControlDataSourceFilterModel
                  );
                  valueSource.OrderingOptions = [searchConfiguration.DefaultOrderingOption.Name];
                  valueSource.Filters = this.buildUserFilters(this.includeInactiveUsers);
                  this.appliedValueSourceFilters = valueSource.Filters;
                  let selectedValues = new Array<any>();
                  if (this.usersValueModel.value) {
                     selectedValues = this.usersValueModel.value.split(",").map((v) => v.replace(" ", ""));
                  }
                  valueSource.SelectedValues = selectedValues;
                  valueSource.StaticValues = [new SelectorItemModel(0, "<Unassigned>")];
                  valueSources.push(valueSource);
                  this.valueSources = valueSources;
               });
         }
      } else {
         const valueSource = new ValueSourceModel();
         valueSource.Type = ValueSourceTypes.Static;
         valueSource.StaticValues = [new SelectorItemModel(currentUser?.UserID, currentUser?.Name)];
         valueSource.DefaultValue = currentUser?.UserID?.toString();
         valueSource.SelectedValues = [currentUser?.UserID];
         valueSources.push(valueSource);
         this.valueSources = valueSources;
      }
   }

   private buildUserFilters(includeInactiveUsers: boolean): Array<FilterOption> {
      const filters = this.defaultFilters.slice();
      if (this.isSalesReport) {
         filters.push(new FilterOption("IsSalesRepresentative", FilterOperations.EqualTo, [true]));
      } else if (this.usersReportParameter.ReportID === Report.HistNotesCust) {
         filters.push(new FilterOption("UserID", FilterOperations.GreaterThan, [0]));
      }
      if (!includeInactiveUsers) {
         filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      } else if (includeInactiveUsers) {
         const index = filters.findIndex((f) => f.FilterName === "IsActive");
         if (index > -1) {
            filters.splice(index, 1);
         }
      }
      return filters;
   }

   private reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      let valueChanged = false;

      let updatedRawValues = updatedValue.rawValues;
      if (updatedRawValues == null) {
         updatedRawValues = new Array<any>();
      }

      if (updatedRawValues.length !== this.selectedValues.length) {
         valueChanged = true;
      } else {
         for (const value of updatedRawValues) {
            // if one of the updatedRawValues is not in the list of selectedItems then value changed
            if (this.selectedValues.indexOf(value) === ConstantsService.ArrayIndexNotFound) {
               valueChanged = true;
               break;
            }
         }
      }

      if (!valueChanged) {
         return;
      }

      this.selectedValues = updatedRawValues;
   }
}
