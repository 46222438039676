import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { RelativeDateOptions } from "../../../inputs/date-picker/relative-date-options.enum";
import { RelativeDatesService } from "../../../inputs/date-picker/relative-dates.service";
import { RelativeDateValueModel } from "./relative-date-value.model";

/* eslint-disable brace-style */
@Component({
   selector: "lcs-relative-date-picker",
   templateUrl: "relative-date-picker.component.html",
})
export class RelativeDatePickerComponent
   extends ValueAccessorBase<RelativeDateValueModel>
   implements OnInit, OnDestroy, UserInputComponent
{
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean;

   @Input() hideLabel = false;

   @Input() label: string;

   useRelativeDate = false;

   dateValue: Date;

   selectedRelativeDateValue: RelativeDateOptions;

   relativeDatesValueSource: ValueSourceModel;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
      this.registerOnValueWritten((value) => {
         if (value) {
            this.dateValue = value.dateValue;
            if (value.relativeDateOption) {
               this.useRelativeDate = true;
               this.selectedRelativeDateValue = value.relativeDateOption;
            }
         }
      });
   }

   ngOnInit() {
      if (this.isRelative) {
         this.initializeRelativeDatesValueSource();
      }
      return super.ngOnInit();
   }

   ngOnDestroy(): void {}
   onDateEntered(date: Date) {
      this.dateValue = date;
      this.updateValue();
   }

   onRelativeOptionSelected(value: RelativeDateOptions) {
      if (value !== this.selectedRelativeDateValue) {
         this.selectedRelativeDateValue = value;
         this.updateValue();
      }
   }

   onUseRelativeDateChecked() {
      this.updateValue();
   }

   private updateValue() {
      if (this.isRelative) {
         this.relativeDatesValueSource.StaticValues.map((item) => {
            if (item.value === this.selectedRelativeDateValue) {
               item.isChecked = true;
            } else {
               item.isChecked = false;
            }
         });
      }

      const value = new RelativeDateValueModel();
      value.dateValue = this.dateValue;
      if (this.useRelativeDate) {
         value.relativeDateOption = this.selectedRelativeDateValue;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'RelativeDat... Remove this comment to see the full error message
         value.relativeDateOption = null;
      }
      this.value = value;
      this.propagateChanged();
   }

   private initializeRelativeDatesValueSource() {
      const source = new ValueSourceModel();
      source.Type = ValueSourceTypes.Static;
      source.StaticValues = new Array<SelectorItemModel>();

      const enumMap = RelativeDatesService.relativeDateEnumMap();
      const mappedKeys = Array.from(enumMap.keys());
      mappedKeys.forEach((key) => {
         const selectorItem = new SelectorItemModel();
         selectorItem.value = key;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
         selectorItem.displayValue = enumMap.get(key);
         selectorItem.isChecked = this.selectedRelativeDateValue === selectorItem.value;
         if (selectorItem.isChecked) {
            source.SelectedValues.push(selectorItem);
         }
         source.StaticValues.push(selectorItem);
      });
      this.relativeDatesValueSource = source;
   }
}
