import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { Observable } from "rxjs";

import { ManagementFeesDetailViewModel } from "../models/management-fee-detail-view.model";
import { ManagementFeeViewModel } from "../models/management-fee-view.model";

@Injectable({
   providedIn: "root",
})
export class ManagementFeesService {
   readonly endpoint = "ManagementFeeHistory";

   constructor(private apiService: ApiService) {}

   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint + "/Get";
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (id !== undefined && id !== null) {
         additionalParameters.push("id=" + id);
      }
      if (filters !== undefined && filters !== null && filters.length > 0) {
         additionalParameters.push("filters=" + filters.map((f) => f.toApiString()).join(";"));
      }
      if (embeds !== undefined && embeds !== null && embeds.length > 0) {
         additionalParameters.push("embeds=" + embeds.join(","));
      }
      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }
      return this.apiService.getUrl(
         endpoint,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }
   getCollection(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<ManagementFeeViewModel>> {
      return this.apiService.getCollection(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
         this.getCollectionUrl(id, filters, embeds, pageSize, pageNumber, additionalParameters)
      );
   }
   getCollectionResponse(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
         this.getCollectionUrl(id, filters, embeds, pageSize, pageNumber, additionalParameters)
      );
   }
   getFeesForDashboard(id?: number): Observable<Array<ManagementFeeViewModel>> {
      const endpoint = this.endpoint + "/GetFeesForDashboard";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }

   getFeesForDetailPage(id: number): Observable<ManagementFeesDetailViewModel> {
      const endpoint = this.endpoint + "/GetManagementFeeDetails";
      return this.apiService.getSingle(this.apiService.getUrl(endpoint, id));
   }
}
