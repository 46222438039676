import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { SkeletonType } from "./skeleton-type.enum";

@Injectable({
   providedIn: "root",
})
export class SkeletonService {
   public skeletonType: BehaviorSubject<SkeletonType>;

   public ignoreSubcomponentStatus: Observable<boolean>;

   public showHideSkeleton: Observable<boolean>;

   private _ignoreSubcomponentStatus = new BehaviorSubject<boolean>(false);

   private _showHideSkeleton = new BehaviorSubject<boolean>(false);

   constructor() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'BehaviorSubject<null>' is not assignable to ... Remove this comment to see the full error message
      this.skeletonType = new BehaviorSubject(null);
      this.ignoreSubcomponentStatus = this._ignoreSubcomponentStatus.asObservable();
      this.showHideSkeleton = this._showHideSkeleton.asObservable();
   }

   setIgnoreSubComponentStatusForSkeleton(value: boolean) {
      this._ignoreSubcomponentStatus.next(value);
   }

   showSkeleton(show: boolean) {
      this._showHideSkeleton.next(show);
   }
}
