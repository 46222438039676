<div class="account-context-menu-container">
   <ul class="account-context-menu">
      <ng-container>
         <li *ngFor="let webUserAccount of availableAccounts">
            <button
               class="account-context-menu-item"
               (click)="switchAccount(webUserAccount.WebUserAccountID)"
            >
               <div *ngIf="webUserAccount.Nickname != ''; else noNickName">
                  {{ webUserAccount.Nickname }}
               </div>
               <ng-template #noNickName>
                  <div *ngIf="webUserAccount.AccountType === accountTypeEnum.OwnerProspect; else ownerAccount">
                     {{ webUserAccount.OwnerProspect.OwnerProspectID }} - {{ webUserAccount.OwnerProspect.Name }}
                  </div>
                  <ng-template #ownerAccount>
                     <div>
                        {{ webUserAccount.Owner.OwnerID }} - {{ webUserAccount.Owner.Name }}
                     </div>
                  </ng-template>
               </ng-template>
            </button>
         </li>
      </ng-container>
   </ul>
</div>