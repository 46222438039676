import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-cam-escrow-report-parameter",
   templateUrl: "cam-escrow-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class CamEscrowReportParameterComponent implements OnInit, UserInputComponent, OnDestroy {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   checked: boolean = false;

   detailChecked: boolean = false;

   detailDisabled: boolean = true;

   generateLabel: string = "Generate CAM Escrow Statement";

   detailLabel: string = "Show detailed CAM";

   private _parameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {
      this.reportParametersService.reportParameterStatusChanged
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((status) => {
            if (this._parameter && status.reportParameter === this._parameter.ReportParameterID) {
               this.disabled = status.disabled;
            }
         });
   }

   ngOnInit() {
      this.initializeReportParameterValue();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   generateCheckboxValueChanged(checked: boolean) {
      if (this.checked === checked) {
         return;
      }
      if (checked) {
         this.detailDisabled = false;
      } else {
         this.detailDisabled = true;
         this.detailChecked = false;
      }
      this.checked = checked;
      this.buildParameterValue();
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   detailCheckboxValueChanged(checked: boolean) {
      if (this.detailChecked === checked) {
         return;
      }
      this.detailChecked = checked;
      this.buildParameterValue();
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private buildParameterValue() {
      if (this.detailChecked && this.checked) {
         this.reportParameterValueModel.value = 2;
      } else if (this.checked && !this.detailChecked) {
         this.reportParameterValueModel.value = 1;
      } else {
         this.reportParameterValueModel.value = 0;
      }
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      this.checked = GlobalsService.convertStringToBoolean(this.reportParameterValueModel.value);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameters(updatedValue);
         });
   }

   private updateReportParameters(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this._parameter.ReportID,
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
