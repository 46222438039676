import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FooterModule } from "@lcs/footer/footer.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { OverlayPanelsModule } from "@lcs/overlay-panel/overlay-panels.module";
import { PipesModule } from "@lcs/pipes/pipes.module";
import { ProgressButtonExtModule } from "@lcs/progress-button/progress-button-ext/progress-button-ext.module";
import { TableModule as LcsTableModule } from "@lcs/table/table.module";

@NgModule({
   imports: [
      LcsTableModule,
      CommonModule,
      CdkTableModule,
      DataTableModule,
      ProgressButtonExtModule,
      OverlayPanelsModule,
      ValidationModule,
      PipesModule,
   ],
   declarations: [],
   exports: [
      LcsTableModule,
      FooterModule,
      DataTableModule,
      ProgressButtonExtModule,
      OverlayPanelsModule,
      ValidationModule,
      PipesModule,
   ],
})
export class OWATableModule {}
