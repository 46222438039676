<div class="toggleable-input-report-parameter-checkbox-section report-parameter-label">
   Include Expected Move In / Move Out Dates
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-checkbox
   [disabled]="disabled"
   [ngModel]="isTenantChecked"
   (ngModelChange)="onTenantChecked($event)"
   [name]="tenantCheckboxName"
   [displayName]="tenantCheckboxLabel"
>{{ tenantCheckboxLabel }}</lcs-checkbox>
<lcs-checkbox
   [disabled]="disabled"
   [ngModel]="isProspectChecked"
   (ngModelChange)="onProspectChecked($event)"
   [name]="prospectCheckboxName"
   [displayName]="prospectCheckboxLabel"
>{{ prospectCheckboxLabel }}</lcs-checkbox>