<div
   class="loading-overlay-wrapper"
   [ngStyle]="loadingOverlayWrapperCustomStyle"
>
   <ng-content></ng-content>
   <div
      *ngIf="show"
      class="loading-overlay-container"
      [ngStyle]="loadingOverlayContainerCustomStyle"
   >
      <mat-spinner
         *ngIf="showSpinner || textWithSpinner"
         [strokeWidth]="5"
         [ngClass]="{ dark: configuration?.DarkTheme === true, light: configuration?.DarkTheme === false }"
         [diameter]="diameter"
      ></mat-spinner>
      <div
         *ngIf="!configuration || configuration?.HasOverlay"
         class="loading-overlay"
         [class.dark]="configuration?.DarkTheme"
         [class.light]="!configuration?.DarkTheme"
         [class.transparent]="showSpinner"
      ></div>
      <div
         *ngIf="!showSpinner || textWithSpinner"
         class="loading-text"
         [style.padding-left]="'0.5em'"
      >{{loadingText}}...</div>
   </div>
</div>