<div class="slider-control">
   <mat-slider
      [attr.id]="path"
      [disabled]="disabled"
      [max]="configuration?.max"
      [min]="configuration?.min"
      [step]="configuration?.step"
      [tickInterval]="tickInterval"
      [invert]="configuration?.invert"
      [thumbLabel]="configuration?.thumbLabel"
      [vertical]="configuration?.vertical"
      [ngModel]="sliderValue"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="valueChanged($event)"
   ></mat-slider>
   <label *ngIf="configuration?.showValueLabel">{{ sliderValue }}</label>
</div>