import { Component, Input } from "@angular/core";

import { TooltipArrowLocation } from "../tooltip-arrow-location";

@Component({
   selector: "lcs-overlay-tooltip-arrow",
   templateUrl: "./overlay-tooltip-arrow.component.html",
   styleUrls: ["./overlay-tooltip-arrow.component.scss"],
})
export class OverlayTooltipArrowComponent {
   @Input() set tooltipLocation(value: TooltipArrowLocation) {
      this._tooltipLocation = value;
      this.applyStyles(value);
   }
   get tooltipLocation(): TooltipArrowLocation {
      return this._tooltipLocation;
   }

   tooltipLocationEnum = TooltipArrowLocation;

   styles;

   // There is no easy way to set CSS variables dynamically from a ts file
   // If this changes, change the arrow-height CSS variable too
   static arrowHeight = 14;

   private _tooltipLocation: TooltipArrowLocation;

   // Implement other locations (bottomLeft, leftTop, etc.) as needed
   private applyStyles(location: TooltipArrowLocation) {
      switch (location) {
         case TooltipArrowLocation.topLeft: {
            this.styles = {
               left: "10%",
               top: "0px",
            };
            break;
         }
         case TooltipArrowLocation.topRight: {
            this.styles = {
               left: "80%",
               top: "0px",
            };
            break;
         }
         case TooltipArrowLocation.rightTop: {
            this.styles = {
               transform: "rotate(90deg)",
               right: "-18px",
               top: "20%",
            };
            break;
         }
         case TooltipArrowLocation.rightBottom: {
            this.styles = {
               transform: "rotate(90deg)",
               right: "-18px",
               top: "80%",
            };
            break;
         }
      }
   }
}
