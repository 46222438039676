<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ $any(parameter?.PromptText?.length) > 0 ? parameter.PromptText : parameter.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-numeric-input
   [name]="name"
   [displayName]="parameter?.DisplayName || displayName"
   [formatString]="formatString"
   [showSeparator]="showSeparator"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   [(ngModel)]="reportParameterValueModel.value"
   [lcsValidate]="validation"
></lcs-numeric-input>