import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceManagerIssueWorkOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-work-order.model";
import { map, Observable } from "rxjs";

import { ServiceIssueDetailService } from "../service-issue-detail.service";
import { ServiceIssuePreferences } from "../service-issue-preferences.interface";

@Component({
   selector: "owa-issue-work-order-view-more",
   templateUrl: "issue-work-order-view-more.component.html",
})
export class IssueWorkOrdersViewMoreComponent {
   loading: Observable<boolean>;

   preferences: Observable<ServiceIssuePreferences>;

   workOrders: Observable<ServiceManagerIssueWorkOrderModel[]>;

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      serviceIssueDetailService: ServiceIssueDetailService
   ) {
      this.workOrders = serviceIssueDetailService.issue.pipe(map((issue) => issue.WorkOrders));
      this.loading = serviceIssueDetailService.loading;
      this.preferences = serviceIssueDetailService.preferences;
   }

   onCloseClick(): void {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
   }
}
