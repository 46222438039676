import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from "@angular/core";
import { LegacyTooltipPosition as TooltipPosition } from "@angular/material/legacy-tooltip";
import { fromEvent, Observable, Subscription } from "rxjs";

@Component({
   selector: "owa-validate-password-format",
   templateUrl: "./validate-password-format.component.html",
   styleUrls: ["./validate-password-format.component.css"],
})
export class ValidatePasswordFormatComponent implements OnInit, OnDestroy, OnChanges {
   @Input() public passwordToValidate: string;
   @Output() passwordStrength = new EventEmitter<boolean>();
   icon: string = "help";
   iconClass: string = "help-icon";
   tooltipClass: string = "help-tooltip";
   tooltipMessage: string =
      "Passwords must include:\n\n • At least 8 characters \n\n • At least 1 numeric character\n\n • At least 1 uppercase alphabetic character\n\n • At least 1 lowercase alphabetic character";
   position: TooltipPosition = "below";
   resizeObservable$: Observable<Event>;
   resizeSubscription$: Subscription;
   constructor() {
      this.setTooltipPosition(window.innerWidth);
   }
   ngOnInit(): void {
      this.resizeObservable$ = fromEvent(window, "resize");
      this.resizeSubscription$ = this.resizeObservable$.subscribe((evt: any) => {
         this.setTooltipPosition(evt.target.innerWidth);
      });
   }

   ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
      const password: string = changes.passwordToValidate.currentValue;
      if (password) {
         if (!this.validatePassword(password)) {
            this.icon = "error";
            this.iconClass = "error-icon";
            this.tooltipClass = "error-tooltip";
         } else {
            this.icon = "check_circle";
            this.iconClass = "check-icon";
            this.tooltipClass = "check-tooltip";
         }
      } else {
         this.icon = "help";
         this.iconClass = "help-icon";
         this.tooltipClass = "help-tooltip";
      }
   }

   validatePassword(password: string) {
      let isValid: boolean = true;
      if (!/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}$/.test(password)) {
         isValid = false;
      }
      return isValid;
   }

   setTooltipPosition(width): void {
      if (width > 900) {
         this.position = "right";
      } else {
         this.position = "above";
      }
   }

   ngOnDestroy(): void {
      this.resizeSubscription$.unsubscribe();
   }
}
