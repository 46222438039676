import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { ToggleableInputComponent } from "./toggleable-input.component";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      CheckboxModule,
   ],
   declarations: [
      ToggleableInputComponent,
   ],
   exports: [
      ToggleableInputComponent,
   ],
})
export class ToggleableInputModule { }
