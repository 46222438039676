import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Observable } from "rxjs";

import { SignableDocumentStatusModel } from "../models/signable-document-status.model";
import { SignableDocumentsSettings } from "../models/signable-documents-settings.model";

@Injectable({ providedIn: "root" })
export class SignableDocumentsService {
   readonly endpoint = "SignableDocuments";
   constructor(private apiService: ApiService) {}

   getSignableDocumentsSettingsUrl(): string {
      const endpoint = this.endpoint + "/SignableDocumentsSettings";
      return this.apiService.getUrl(endpoint);
   }

   getSignableDocumentsSettings(): Observable<SignableDocumentsSettings> {
      return this.apiService.getSingle(this.getSignableDocumentsSettingsUrl());
   }

   getUrl(): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(endpoint);
   }

   get(): Observable<SignableDocumentStatusModel> {
      return this.apiService.getSingle(this.getUrl());
   }
}
