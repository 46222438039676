import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-market-rent-report-parameter",
   templateUrl: "market-rent-report-parameter.component.html",
})
export class MarketRentReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   prorateReportParameter: ReportReportParameterViewModel;

   effectiveDateReportParameter: ReportReportParameterViewModel;

   prorateValue: ReportParameterValueModel;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.prorateReportParameter.IsAsk = isAsk;
      this.effectiveDateReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(ReportParameter.MRProration, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.MRProrationDate, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.prorateReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.MRProration
      );
      this.prorateReportParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.effectiveDateReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.MRProrationDate
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.prorateValue = this.reportParametersService.reportParameterValues.get(ReportParameter.MRProration);
   }
}
