import { ActionForCurrentUserModel } from "./action-for-current-user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ActionsForCurrentUserModel {
   AreaActionMappings: any;
   ExpressActions: Array<ActionForCurrentUserModel>;
}
