import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EnumMultiSelectorComponent } from "./enum-multi-selector.component";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";
import { FormsModule } from "@angular/forms";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      SingleLineMultiSelectModule,
   ],
   declarations: [
      EnumMultiSelectorComponent,
   ],
   exports: [
      EnumMultiSelectorComponent,
   ],
})
export class EnumMultiSelectorModule { }
