export class ConfirmationDialogConfigurationModel {
   title: string;
   message: string;
   messages: Array<string>;
   icon: string = "help";
   showSpinner: boolean = false;
   showAcceptButton: boolean = true;
   showRejectButton: boolean = false;
   showOtherButton: boolean = false;
   showCancelButton: boolean = false;
   accept: Function;
   reject: Function;
   other: Function;
   cancel: Function;
   acceptButtonText: string = "OK";
   rejectButtonText: string = "Reject";
   otherButtonText: string = "";
   cancelButtonText: string = "Cancel";
   isMessageFormattedToNewLine: boolean = false;
   disableClose: boolean = true;
   raiseOtherButton: boolean = true;

   constructor() {
      this.messages = new Array<string>();
   }
}
