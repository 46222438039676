import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { FileAttachmentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/file-attachment.embed-options";
import { FileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/file.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { ReconciliationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/reconciliation.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { ReconciliationGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/reconciliation.get-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { BankTransactionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bank-transaction.model";
import { FileAttachmentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file-attachment.model";
import { FileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { ReconciliationChangesSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/reconciliation-changes-summary.model";
import { ReconciliationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/reconciliation.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ReconciliationsService {
   static readonly AttachmentsResource = "Attachments";
   static readonly CreateUserResource = "CreateUser";
   static readonly FileAttachmentsResource = "FileAttachments";
   static readonly GetByPostResource = "GetByPost";
   static readonly GLAccountResource = "GLAccount";
   static readonly GLAccountHasReconcileChangesResource = "GLAccountHasReconcileChanges";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly ReconcileJournalResource = "ReconcileJournal";
   static readonly ReconciliationChangesSummaryModelResource = "ReconciliationChangesSummaryModel";
   static readonly ReconciliationsResource = "Reconciliations";
   static readonly ReportFileResource = "ReportFile";
   static readonly SearchResource = "Search";
   static readonly TransactionsResource = "Transactions";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly endpoint = "ReconciliationsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReconciliationModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ReconciliationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ReconciliationModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReconciliationModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(ReconciliationsService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(ReconciliationsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAttachmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Attachments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAttachments(
      id: number,
      itemID: number,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileAttachmentModel> {
      return this.apiService.getSingle(
         this.getAttachmentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAttachmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Attachments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAttachmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<FileAttachmentModel>> {
      return this.apiService.getCollection(
         this.getAttachmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAttachmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/Attachments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAttachments(
      id: number,
      item?: FileAttachmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<FileAttachmentModel> {
      return this.apiService.postSingle(
         this.postAttachmentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAttachmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/Attachments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAttachmentsCollection(
      id: number,
      attachments?: Array<FileAttachmentModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<FileAttachmentModel>> {
      return this.apiService.postCollection(
         this.postAttachmentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         attachments
      );
   }

   deleteAttachmentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ReconciliationsService.endpoint + "/Attachments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAttachments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAttachmentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getFileAttachmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/FileAttachments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getFileAttachments(
      id: number,
      itemID: number,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileAttachmentModel> {
      return this.apiService.getSingle(
         this.getFileAttachmentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getFileAttachmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/FileAttachments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getFileAttachmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<FileAttachmentModel>> {
      return this.apiService.getCollection(
         this.getFileAttachmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postFileAttachmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/FileAttachments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postFileAttachments(
      id: number,
      item?: FileAttachmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<FileAttachmentModel> {
      return this.apiService.postSingle(
         this.postFileAttachmentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postFileAttachmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/FileAttachments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postFileAttachmentsCollection(
      id: number,
      items: Array<FileAttachmentModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | FileAttachmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<FileAttachmentModel>> {
      return this.apiService.postCollection(
         this.postFileAttachmentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteFileAttachmentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ReconciliationsService.endpoint + "/FileAttachments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteFileAttachments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteFileAttachmentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGLAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/GLAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGLAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getGLAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getGLAccountHasReconcileChangesUrl(glAccountID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("glAccountID=" + glAccountID);
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/GLAccountHasReconcileChanges",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGLAccountHasReconcileChanges(glAccountID: number, additionalParameters?: Array<string>): Observable<Boolean> {
      return this.apiService.getSingle(this.getGLAccountHasReconcileChangesUrl(glAccountID, additionalParameters));
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReconciliationModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   deleteReconcileJournalUrl(reconcileID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("reconcileID=" + reconcileID);
      return this.apiService.deleteUrl(
         ReconciliationsService.endpoint + "/ReconcileJournal",
         null,
         null,
         null,
         additionalParameters
      );
   }

   deleteReconcileJournal(reconcileID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteReconcileJournalUrl(reconcileID, additionalParameters));
   }

   postReconciliationChangesSummaryModelUrl(accountID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountID=" + accountID);
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/ReconciliationChangesSummaryModel",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postReconciliationChangesSummaryModel(
      accountID: number,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationChangesSummaryModel> {
      return this.apiService
         .post(this.postReconciliationChangesSummaryModelUrl(accountID, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getReconciliationsCollectionUrl(accountID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountID=" + accountID);
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Reconciliations",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReconciliationsCollection(
      accountID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<ReconciliationModel>> {
      return this.apiService.getCollection(this.getReconciliationsCollectionUrl(accountID, additionalParameters));
   }

   deleteReconciliationsUrl(
      reconcileID: number,
      ignoreHardClose?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("reconcileID=" + reconcileID);
      if (ignoreHardClose !== undefined && ignoreHardClose !== null) {
         additionalParameters.push("ignoreHardClose=" + ignoreHardClose);
      }
      return this.apiService.deleteUrl(
         ReconciliationsService.endpoint + "/Reconciliations",
         null,
         null,
         null,
         additionalParameters
      );
   }

   deleteReconciliations(
      reconcileID: number,
      ignoreHardClose?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteReconciliationsUrl(reconcileID, ignoreHardClose, additionalParameters)
      );
   }

   getReportFileUrl(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/ReportFile",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReportFile(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService.getSingle(this.getReportFileUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ReconciliationEmbedOptions> | null,
      getOptions?: Array<string | ReconciliationGetOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReconciliationModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ReconciliationsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      id: number,
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<ReconciliationModel> {
      return this.apiService
         .post(this.postSearchUrl(id, additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getTransactionsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/Transactions",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTransactionsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BankTransactionModel>> {
      return this.apiService.getCollection(
         this.getTransactionsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ReconciliationsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }
}
