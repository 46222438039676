<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && prorateReportParameter.IsAsk === true)"
   >
      Market Rent
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="prorateReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'prorate-market-rent'"
      [disabled]="disabled || (hasAsk === true && prorateReportParameter.IsAsk === true)"
      [parameter]="prorateReportParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<lcs-date-report-parameter
   [name]="'effective-date-market-rent'"
   [parameter]="effectiveDateReportParameter"
   [disabled]="disabled || (hasAsk === true && prorateReportParameter.IsAsk === true) || (prorateValue && prorateValue.value === 'Prorate Market Rent')"
></lcs-date-report-parameter>