import { Directive, Host, Input, OnChanges, OnDestroy, OnInit, Self, SimpleChanges } from "@angular/core";
import { EntitySelectorDirective } from "@lcs/entity-selector/entity-selector.directive";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FORM_CONTROL_METADATA_SELECTORFILTERS, FormControlMetadataSelectorFilters } from "@lcs/forms/form-control-metadata/form-control-metadata-selector-filters.interface";
import { SelectComponent } from "@lcs/select/components/select.component";
import { EndpointSelectorDirectiveBase } from "@lcs/select/directives/endpoint-selector.directive.base";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntitySearchConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration.model";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { PropertyFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { EstimateDetailService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/estimate-detail.service";
import { forkJoin, of, takeUntil } from "rxjs";

import { RoutingService } from "../../action-router/routing.service";
import { ConstantsService } from "../../core/constants.service";
import { ObjectMapResolverService } from "../../pipes/object-map-resolver.service";

/* eslint-disable brace-style */
@Directive({
   selector: "[lcsOwnerEstimateDatatablePropertySelector]",
   providers: [
      {
         provide: FORM_CONTROL_METADATA_SELECTORFILTERS,
         useExisting: EntitySelectorDirective,
      },
   ],
})
export class OwnerEstimateDatatablePropertySelectorDirective
   extends EndpointSelectorDirectiveBase
   implements OnInit, OnDestroy, OnChanges, FormControlMetadataSelectorFilters
{
   @Input() filters: Array<FilterOption>;

   private propertyInformation: EntityViewInformationModel;

   private propertySearchConfiguration: EntitySearchConfigurationModel;

   private ownerID: number = ConstantsService.NullFK;

   constructor(
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private estimateDetailService: EstimateDetailService,
      private entityRequestsService: EntityRequestService,
      protected errorMessageService: ErrorMessageService,
      protected objectMapResolverService: ObjectMapResolverService,
      protected valueSourceService: ValueSourceService,
      protected apiService: ApiService,
      private routingService: RoutingService,
      @Host() @Self() protected selectComponent: SelectComponent
   ) {
      super(errorMessageService, objectMapResolverService, valueSourceService, apiService, selectComponent);
   }

   ngOnInit(): void {
      super.ngOnInit();
   }

   ngOnDestroy(): void {
      super.ngOnDestroy();
   }

   ngOnChanges(changes: SimpleChanges): void {
      const filtersChanged = changes.filters && changes.filters.currentValue !== changes.filters.previousValue;
      const filterExpressionChanged =
         changes.filterExpression && changes.filterExpression.currentValue !== changes.filterExpression.previousValue;
      if (filtersChanged || filterExpressionChanged) {
         if (filtersChanged) {
            this.setFilters(changes.filters.currentValue);
         }
      }
   }

   setFilters(filters: Array<FilterOption>) {
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (+filters[0].Value === -1) {
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         if (this.routingService.queryParams["EntityID"] && this.routingService.queryParams["EntityType"]) {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.ownerID = +this.routingService.queryParams["EntityID"];
         }
      } else {
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         this.ownerID = +filters[0].Value;
      }
      this.setEndpointSelectorBaseValues();
   }

   private getEndpoint() {
      return this.estimateDetailService.getOwnerEstimateDetailItemPropertiesCollectionUrl(this.ownerID);
   }

   private getPropertyInformation() {
      if (this.propertyInformation) {
         return of(this.propertyInformation);
      } else {
         return this.entityViewInformationService.getViewInformation(EntityType.Property);
      }
   }

   private getPropertySearchConfiguration() {
      if (this.propertySearchConfiguration) {
         return of(this.propertySearchConfiguration);
      } else {
         return this.entitySearchConfigurationService.getSearchConfiguration(EntityType.Property);
      }
   }

   private getPropertyRequest() {
      return this.entityRequestsService.getEntityEndpoint(EntityType.Property);
   }

   private setEndpointSelectorBaseValues() {
      forkJoin([this.getPropertyInformation(), this.getPropertySearchConfiguration(), this.getPropertyRequest()])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            ([viewInformation, searchConfiguration, propertyEndpoint]: [
               EntityViewInformationModel,
               EntitySearchConfigurationModel,
               string
            ]) => {
               this._displayValueSourcePath = PropertyFields.ShortName.Identifier;
               this._endpoint = this.getEndpoint();
               this._loadAllItems = true;
               this._additionalInfoSourcePath = PropertyFields.Name;
               this._searchFields = searchConfiguration.DefaultSearchFields;
               this._primaryKeySourcePath = viewInformation.EntityPrimaryKeyPropertyPath;
               this._itemEndpoint = propertyEndpoint;
               this._valueSourcePath = viewInformation.EntityPrimaryKeyPropertyPath;
            }
         );
   }
}
