import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { ReportParameterDependencyService } from "@lcs/reports/report-parameters/report-parameter-dependency.service";
import { ReportParametersService } from "@lcs/reports/report-parameters/report-parameters.service";
import { ExportType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/export-type.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { RMReportHubComponent as LCSReportHubComponent } from "../controls/rm-report-hub/rm-report-hub.component";

@Component({
   selector: "owa-report-hub",
   templateUrl: "./report-hub.component.html",
   providers: [ReportParametersService, ReportParameterDependencyService],
})
export class ReportHubComponent implements OnDestroy {
   @ViewChild("reportHub", { static: true }) reportHub: LCSReportHubComponent;

   reportID: Report;

   runReportButtonStatus: LcsProgressButtonStatus;

   isFileDownload: boolean;

   selectedExportTypeValue: string = ExportType[ExportType.PDF];

   selectedExportType: ExportType = ExportType.PDF;

   exportTypesForReport: ValueSourceModel;

   exportTypesToExclude: Array<ExportType>;

   private unsubscribe = new Subject<void>();

   constructor(private route: ActivatedRoute) {
      this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
         if (params.hasOwnProperty("id")) {
            this.reportID = +params["id"];
         }
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   generateReport() {
      this.runReportButtonStatus = LcsProgressButtonStatus.InProgress;
      this.reportHub.generateReport().subscribe({
         next: () => {
            this.runReportButtonStatus = LcsProgressButtonStatus.Success;
         },
         error: () => {
            this.runReportButtonStatus = LcsProgressButtonStatus.Error;
         },
      });
   }

   reportFormatSelectionChanged(format: string) {
      let selectedReportValue = format;
      if (selectedReportValue === "XLS") {
         selectedReportValue = "Excel";
      }

      this.selectedExportType = +ExportType[selectedReportValue];
   }

   reportExportTypesChanged(exportTypesForCurrentReport: ValueSourceModel) {
      this.exportTypesForReport = exportTypesForCurrentReport;
   }
}
