import { Injectable, isDevMode } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";

export type StorageType = "localStorage" | "sessionStorage";

export interface StorageItemStatistics {
   key: string;
   totalLength: number;
   keyLength: number;
   valueLength: number;
}
export interface StorageTotalStatistics {
   keyCount: number;
   totalLength: number;
   minKeyLength: number;
   maxKeyLength: number;
   totalKeyLength: number;
   minValueLength: number;
   maxValueLength: number;
   totalValueLength: number;
}

export interface StorageStatistics {
   storageItemStatistics: Array<StorageItemStatistics>;
   storageTotalStatistics: StorageTotalStatistics;
}

@Injectable({
   providedIn: "root",
})
export abstract class StorageService implements Storage {
   static readonly localStorageType: StorageType = "localStorage";
   static readonly sessionStorageType: StorageType = "sessionStorage";

   static readonly defaultTempKeysRegExp: RegExp = new RegExp(/^N?\d+$/); // Starts with optional "N" followed by one or more digits. Corresponds to hashCodeString(...)

   protected get storage() {
      return this._storage;
   }

   protected get storageType(): StorageType {
      return this._storageType;
   }

   private _storage: Storage;
   private _storageType: StorageType;
   private errorCount: number = 0;

   /**
    * Feature detect whether storageType is both supported and available
    *
    * See: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    */
   static storageAvailable(storageType: StorageType): boolean {
      let storage: Storage | null = null;
      try {
         storage = window[storageType];
         const x = "__storage_test__";
         storage.setItem(x, x);
         storage.removeItem(x);
         return true;
      } catch (error: any) {
         console.warn(`${storageType} is not available. Error:`, error);
         const storageAvailable =
            error instanceof DOMException &&
            // everything except Firefox
            (error.code === 22 ||
               // Firefox
               error.code === 1014 ||
               // test name field too, because code might not be present
               // everything except Firefox
               error.name === "QuotaExceededError" ||
               // Firefox
               error.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage?.length !== 0;
         return storageAvailable;
      }
   }

   constructor(storageType: StorageType) {
      this._storageType = storageType;
      if (StorageService.storageAvailable(storageType)) {
         this._storage = storageType === "localStorage" ? window.localStorage : window.sessionStorage;
      } else {
         throw new Error(`${storageType} is not available.`);
      }
   }

   /** Returns an integer representing the number of data items stored in the Storage object. */
   get length(): number {
      return this.storage.length;
   }

   /** When passed a number n, this method will return the name of the nth key in the storage. */
   key(index: number): string | null {
      return this.storage.key(index);
   }

   /** When passed a key name, will return that key's value. */
   getItem(key: string): string | null {
      return this.storage.getItem(key);
   }

   /** When passed a key name and value, will add that key to the storage, or update that key's value if it already exists. */
   setItem(key: string, value: string): void {
      try {
         this.storage.setItem(key, value);
         if (isDevMode()) {
            const storageStatistics: StorageStatistics = this.storageStatistics();
            console.log(`DevMode: StorageTotalStatistics: `, storageStatistics.storageTotalStatistics);
            console.log(`DevMode: StorageItemStatistics: `, storageStatistics.storageItemStatistics);
         }
      } catch (error: any) {
         this.errorCount++;
         console.error(
            `ErrorCount: ${this.errorCount} - setItem failed for key="${key}" value.length=${value?.length} value=`,
            value,
            `\nerror:`,
            error,
            "\n"
         );
         if (isDevMode()) {
            const storageStatistics: StorageStatistics = this.storageStatistics();
            console.log(`DevMode: StorageTotalStatistics: `, storageStatistics.storageTotalStatistics);
            console.log(`DevMode: StorageItemStatistics: `, storageStatistics.storageItemStatistics);
         }
      }
   }

   /** When passed a key name and value, will add that key and the hashCode of its value to storage,
    *  or update the key's hashCodeValue value if it already exists.
    *
    *  Not part of Web Storage API
    *
    *  Initially added to allow auto synching of IDBLocalStorage items by saving/updating a hash
    *  of the IDBLocalStorage values with the same key, the cache monitoring service can listen
    *  to the storageEvent and populate the caches across tabs when values are updated.
    */
   setItemToValueHashCode(key: string, valueToBeHashed: any): void {
      try {
         const valueHashCodeString: string = GlobalsService.hashCodeString(JSON.stringify(valueToBeHashed));
         this.setItem(key, valueHashCodeString);
      } catch (error: any) {
         this.errorCount++;
         console.error(
            `ErrorCount: ${this.errorCount} - setItemToValueHashCode failed for key="${key}" valueToBeHashed=`,
            valueToBeHashed,
            `\nerror:`,
            error,
            "\n"
         );
         if (isDevMode()) {
            const storageStatistics: StorageStatistics = this.storageStatistics();
            console.log(`DevMode: StorageTotalStatistics: `, storageStatistics.storageTotalStatistics);
            console.log(`DevMode: StorageItemStatistics: `, storageStatistics.storageItemStatistics);
         }
      }
   }

   /** When passed a key name, will remove that key from the storage. */
   removeItem(key: string): void {
      this.storage.removeItem(key);
   }

   /** When invoked, will empty all keys out of the storage. */
   clear(): void {
      this.storage.clear();
   }

   /** Clears temporary storage based on keys that match tempKeysRegExp.
    *
    *  tempKeysRegExp defaults to StorageService.defaultTempKeysRegExp.
    */
   clearTempStorage(tempKeysRegExp: RegExp = StorageService.defaultTempKeysRegExp): void {
      if (tempKeysRegExp === undefined) {
         tempKeysRegExp = StorageService.defaultTempKeysRegExp;
      }
      this.keys()
         .filter((key: string) => tempKeysRegExp.test(key))
         ?.forEach((key) => {
            this.storage.removeItem(key);
         });
   }

   /** Returns array of storage keys. Not part of Web Storage API */
   keys(): string[] {
      const keys: Array<string> = new Array<string>();
      for (let i: number = 0; i < this.length; i++) {
         const key: string | null = this.storage.key(i);
         if (key != null) {
            keys.push(key);
         }
      }
      return keys;
   }

   /** Returns an array of StorageItemStatistics. Not part of Web Storage API */
   storageItemStatistics(): Array<StorageItemStatistics> {
      const itemStatistics: Array<StorageItemStatistics> = this.keys().map((k: string) => {
         const v = this.getItem(k) ?? "";
         return { key: k, totalLength: k.length + v.length, keyLength: k.length, valueLength: v.length };
      });
      return itemStatistics;
   }

   /** Returns StorageTotalStatistics. Not part of Web Storage API */
   storageTotalStatistics(storageItemStatistics?: Array<StorageItemStatistics>): StorageTotalStatistics {
      if (!storageItemStatistics) {
         storageItemStatistics = this.storageItemStatistics();
      }

      const storageTotalStatistics: StorageTotalStatistics = {
         keyCount: 0,
         totalLength: 0,
         minKeyLength: 0,
         maxKeyLength: 0,
         totalKeyLength: 0,
         minValueLength: 0,
         maxValueLength: 0,
         totalValueLength: 0,
      };

      storageItemStatistics.forEach((sis: StorageItemStatistics) => {
         storageTotalStatistics.keyCount++;
         storageTotalStatistics.minKeyLength =
            storageTotalStatistics.minKeyLength === 0
               ? sis.keyLength
               : Math.min(storageTotalStatistics.minKeyLength, sis.keyLength);
         storageTotalStatistics.maxKeyLength = Math.max(storageTotalStatistics.maxKeyLength, sis.keyLength);
         storageTotalStatistics.minValueLength =
            storageTotalStatistics.minValueLength === 0
               ? sis.valueLength
               : Math.min(storageTotalStatistics.minValueLength, sis.valueLength);
         storageTotalStatistics.maxValueLength = Math.max(storageTotalStatistics.maxValueLength, sis.valueLength);
         storageTotalStatistics.totalKeyLength = storageTotalStatistics.totalKeyLength + sis.keyLength;
         storageTotalStatistics.totalValueLength = storageTotalStatistics.totalValueLength + sis.valueLength;
         storageTotalStatistics.totalLength = storageTotalStatistics.totalLength + sis.keyLength + sis.valueLength;
      });

      return storageTotalStatistics;
   }

   /** Returns StorageStatistics. Useful for determining how much storage is being used. Not part of Web Storage API */
   storageStatistics(storageItemStatistics?: Array<StorageItemStatistics>): StorageStatistics {
      if (!storageItemStatistics) {
         storageItemStatistics = this.storageItemStatistics();
      }

      const storageStatistics: StorageStatistics = {
         storageItemStatistics: storageItemStatistics,
         storageTotalStatistics: this.storageTotalStatistics(storageItemStatistics),
      };

      return storageStatistics;
   }
}
