import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";

export class SearchPostBodyModel {
   embeds: Array<string | EntityEmbedOption> | null;

   fields: Array<string | EntityField> | null;

   filterExpression: FilterExpression | null;

   orderingOptions: Array<string | OrderingOption> | null;

   getOptions: Array<string | EntityGetOption> | null;

   pageNumber: number;

   pageSize: number;
}
