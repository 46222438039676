import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   ViewChild,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { Subject, takeUntil } from "rxjs";

import { globalVariables } from "../../core/globals";

@Component({
   selector: "lcs-secure-input-base",
   template: "",
})
export class SecureInputBase extends ValueAccessorBase<string> implements OnDestroy, OnInit, AfterViewInit {
   @Input() defaultMaskValue: string;

   @Input() set hasPrivilege(value: boolean) {
      this._hasPrivilege = value;
      this.showVisibilityIcon = this.getShowVisibilityIcon();
   }

   get hasPrivilege(): boolean {
      return this._hasPrivilege ?? false;
   }

   @Input() placeholder: string;

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsValidate") validation: ValidationModel;

   @Output() blurEvent = new EventEmitter<FocusEvent>();

   @Output() clickEvent = new EventEmitter<MouseEvent>();

   @Output() enterKeyEvent = new EventEmitter<KeyboardEvent>();

   @Output() focusEvent = new EventEmitter<FocusEvent>();

   @ViewChild("secureInput", { static: true }) secureInput: ElementRef;

   /**
    * Native input element that displays the masked value when maskEnabled=true
    * and also receives the input entered by the user and displays the
    * unmasked value when maskEnabled=false
    */
   secureInputElement: HTMLInputElement;

   showVisibilityIcon: boolean;

   maskEnabled: boolean = true;

   unmaskedValue: string;

   displayValue: string;

   visibilityOn: boolean = false;

   private _hasPrivilege: boolean | null | undefined;

   private cachedMaskValue: string;

   private originalValue: string;

   private originalUnmaskedValue: string;

   private selectionStart: number | null | undefined;

   private selectionEnd: number | null | undefined;

   private unsubscribe = new Subject<void>();

   private justFocused: boolean = false;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public control: NgControl) {
      super(changeDetectorRef, control);
      this.defaultMaskValue = "XXXXXXXXXX";
      this.cachedMaskValue = this.defaultMaskValue;
      this.displayValue = this.defaultMaskValue;
   }

   ngOnInit() {
      if (this.placeholder == null) {
         this.placeholder = this.getDefaultPlaceholder();
      }

      this.unmaskedValue = this.value;
      this.showVisibilityIcon = this.getShowVisibilityIcon();
      this.updateDisplayValue();

      this.control.statusChanges?.pipe(takeUntil(this.unsubscribe)).subscribe((status: string) => {
         this.unmaskedValue = this.value;
         if (this.control.invalid) {
            this.maskEnabled = false;
         }
         this.showVisibilityIcon = this.getShowVisibilityIcon();
         this.updateDisplayValue();
      });
      return super.ngOnInit();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   ngAfterViewInit(): void {
      this.secureInputElement = this.secureInput.nativeElement;
      this.updateDisplayValue();
   }

   onFocus(event: FocusEvent): void {
      this.justFocused = true;
      if (this.maskEnabled) {
         this.setVisibilityOn(true, false);
      }
      this.focusEvent.emit(event);
   }

   onClick(event: MouseEvent): void {
      if (this.justFocused) {
         if (globalVariables.isMobile) {
            this.saveSelection();
         }
         setTimeout(() => {
            this.justFocused = false;
         });
      }
      this.clickEvent.emit(event);
   }

   onDisplayValueChange(value: string): void {
      this.justFocused = false;
      if (value != null) {
         this.unmaskedValue = value;
         this.updateMaskValue(value);
         this.displayValue = value;
         this.value = value; // updates component value triggering validation
         this.maskEnabled = value === this.cachedMaskValue;
         this.visibilityOn = !this.maskEnabled;
         this.showVisibilityIcon = this.getShowVisibilityIcon();
      }
   }

   onBlur(event: FocusEvent): void {
      this.justFocused = false;
      if (!this.control.invalid) {
         if (globalVariables.isMobile) {
            this.saveSelection();
         } else {
            this.clearSavedSelection();
         }
         if (this.unmaskedValue !== this.originalUnmaskedValue) {
            this.propagateTouched();
         } else if (!this.hasPrivilege) {
            // NOTE: if user does not have privilege to view then originalValue is either null or the masked value
            this.unmaskedValue = this.originalValue;
            this.displayValue = this.originalValue;
         }
         this.setVisibilityOn(false);
      }
      this.blurEvent.emit(event);
   }

   onEnterKey(event: Event): void {
      this.enterKeyEvent.emit(event as KeyboardEvent);
   }

   changeVisibility(): void {
      this.setVisibilityOn(!this.visibilityOn, true, true);
   }

   protected getDefaultPlaceholder(): string {
      return "";
   }

   protected generateMaskValue(unmaskedValue: string): string {
      return this.defaultMaskValue;
   }

   protected getVisibleMaskValue(maskedValue: string): string {
      return "";
   }

   private setVisibilityOn(value: boolean, setFocus: boolean = false, fromChangeVisibility: boolean = false): void {
      this.visibilityOn = value;

      this.maskEnabled = !this.visibilityOn;

      if (this.maskEnabled) {
         if (this.value !== this.unmaskedValue) {
            if (this.displayValue !== this.originalValue) {
               this.onDisplayValueChange(this.unmaskedValue);
            }
         }
      } else {
         if (typeof this.originalValue === "undefined" && typeof this.value !== "undefined") {
            this.originalValue = this.value;
         }

         if (!this.hasPrivilege && this.unmaskedValue === this.originalValue) {
            this.unmaskedValue = this.getVisibleMaskValue(this.cachedMaskValue);
         } else {
            this.unmaskedValue = this.value;
         }

         if (typeof this.originalUnmaskedValue === "undefined" && typeof this.unmaskedValue !== "undefined") {
            this.originalUnmaskedValue = this.unmaskedValue;
         }
      }

      this.updateDisplayValue(setFocus, fromChangeVisibility);
   }

   private updateMaskValue(value: string): void {
      this.cachedMaskValue = this.generateMaskValue(value);
   }

   private getMaskedValue(value: string): string {
      this.updateMaskValue(value);
      return this.cachedMaskValue;
   }

   private getShowVisibilityIcon(): boolean {
      return this.hasPrivilege && !this.control.invalid;
   }

   private clearSavedSelection(): void {
      this.selectionStart = null;
      this.selectionEnd = null;
   }

   private saveSelection(): void {
      if (!this.secureInputElement) {
         return;
      }
      this.selectionStart = this.secureInputElement.selectionStart;
      this.selectionEnd = this.secureInputElement.selectionEnd;
   }

   private textIsSelected(): boolean {
      if (!this.secureInputElement) {
         return false;
      }
      return (
         this.secureInputElement.selectionStart != null &&
         this.secureInputElement.selectionEnd != null &&
         this.secureInputElement.selectionEnd - this.secureInputElement.selectionStart > 0
      );
   }

   private hasSavedSelection(): boolean {
      return this.secureInputElement && this.selectionStart != null && this.selectionEnd != null;
   }

   private restoreSelection(): void {
      if (!this.hasSavedSelection()) {
         return;
      }
      setTimeout(() => {
         if (this.selectionStart != null && this.selectionEnd != null) {
            this.secureInputElement.setSelectionRange(this.selectionStart, this.selectionEnd);
         }
      });
   }

   private selectEntireInput(inputElement: HTMLInputElement): void {
      setTimeout(() => {
         inputElement.setSelectionRange(0, inputElement.value.length);
      });
   }

   private putCursorAtEndOfInput(inputElement: HTMLInputElement): void {
      setTimeout(() => {
         inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
      });
   }

   private updateDisplayValue(setFocus: boolean = false, fromChangeVisibility: boolean = false): void {
      setTimeout(() => {
         if (this.justFocused && !globalVariables.isMobile) {
            this.saveSelection();
         }
         if (this.maskEnabled) {
            const maskedValue = this.getMaskedValue(this.unmaskedValue);
            this.displayValue = maskedValue;
         } else {
            this.displayValue = this.unmaskedValue;
            if (setFocus) {
               this.secureInputElement.focus();
               if (fromChangeVisibility) {
                  setTimeout(() => {
                     this.justFocused = false;
                  });
               }
            }
         }

         if (this.justFocused) {
            if (globalVariables.isMobile) {
               this.restoreSelection();
            } else {
               if (this.textIsSelected()) {
                  if (fromChangeVisibility) {
                     this.putCursorAtEndOfInput(this.secureInputElement);
                  } else {
                     this.selectEntireInput(this.secureInputElement);
                  }
               } else {
                  if (this.hasSavedSelection()) {
                     this.restoreSelection();
                  } else {
                     this.putCursorAtEndOfInput(this.secureInputElement);
                  }
               }
            }
         }
      });
   }
}
