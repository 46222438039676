import { Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { ValueComparerHelper } from "@lcs/select/helpers/value-comparer.helper";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { TenantStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/tenant-status.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { TenantsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/tenants.service";
import { BehaviorSubject, filter, forkJoin, map, Observable, of, Subject, take, takeUntil } from "rxjs";

import { ContextService } from "../../../../action-context/context.service";
import { ObjectMapResolverService } from "../../../../pipes/object-map-resolver.service";
import { EntityLinkEntityTypes } from "./entity-link-entity-types.enum";
import { EntityLinkFormGroup } from "./entity-link-form-group";

@Injectable()
export class EntityLinkSelectorService implements OnDestroy {
   entityFilter = new Observable<Array<FilterOption>>();

   entityTypesToInclude: Array<string>;

   entityLinkValueComparerObservable = new Observable<any>();

   entityLinkValueSource = new Observable<ValueSourceModel>();

   entityLinkEntityTypes: Map<EntityLinkEntityTypes, EntityType>;

   group: UntypedFormGroup;

   includeStatusFilters: boolean;

   private entityInformationMap = new Map<EntityType, string>();

   private unsubscribe = new Subject<void>();

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _entityFilters = new BehaviorSubject<Array<FilterOption>>(null);

   private _entityLinkValueComparerObservable = new BehaviorSubject<any>(null);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _entityLinkValueSource = new BehaviorSubject<ValueSourceModel>(null);

   constructor(
      private entityInformationService: EntityInformationService,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private tenantsService: TenantsService,
      private contextService: ContextService
   ) {
      this.entityFilter = this._entityFilters.asObservable();
      this.entityLinkValueComparerObservable = this._entityLinkValueComparerObservable.asObservable();
      this.entityLinkValueSource = this._entityLinkValueSource.asObservable();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   getEntityLink(): UntypedFormGroup {
      return this.group;
   }

   getEntityLinkEntityType(): AbstractControl {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'AbstractControl | null' is not assignable to... Remove this comment to see the full error message
      return this.group.get(EntityLinkFormGroup.entityLinkEntityTypeName);
   }

   getEntityLinkValue(): AbstractControl {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'AbstractControl | null' is not assignable to... Remove this comment to see the full error message
      return this.group.get(EntityLinkFormGroup.entityLinkValueName);
   }

   getEntityType(): AbstractControl {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'AbstractControl | null' is not assignable to... Remove this comment to see the full error message
      return this.group.get(EntityLinkFormGroup.entityTypeName);
   }

   initialize(includeStatusFilters: boolean, group: UntypedFormGroup) {
      this.group = group;
      this.includeStatusFilters = includeStatusFilters;

      this.initEntityLinkEntityTypes(this.includeStatusFilters);
      this.initEntityTypeValueSource();

      const entityTypeValue = this.getEntityType().value;
      const entityLinkEntityTypeValue = this.getEntityLinkEntityType().value;

      if (!entityLinkEntityTypeValue && entityTypeValue) {
         this.updateEntityLinkEntityType(entityTypeValue);
      } else if (!entityLinkEntityTypeValue && !entityTypeValue) {
         if (this.entityTypesToInclude) {
            if (this.contextService.context.value?.Action.ExpressActionID === ExpressActions.Bill_List) {
               const index = this.entityTypesToInclude.findIndex((entity) => +entity === EntityType.Vendor);
               this.updateEntityLinkEntityType(+this.entityTypesToInclude[index]);
            } else {
               this.updateEntityLinkEntityType(+this.entityTypesToInclude[0]);
            }
         } else {
            this.updateEntityLinkEntityType(this.entityLinkEntityTypes.values().next().value);
         }
      }

      this.updateEntityTypeValueComparer(this.getEntityLinkEntityType().value);
      this.updateFilters(entityLinkEntityTypeValue);
   }

   updateEntityTypeValueComparer(entityLinkEntityType: EntityLinkEntityTypes) {
      const entityType = this.entityLinkEntityTypes.get(entityLinkEntityType);

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'EntityType | undefined' is not a... Remove this comment to see the full error message
      this.getEntityTypePrimaryKeyPropertyPath(entityType)
         .pipe(
            // @ts-ignore ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
            filter((v) => v !== null),
            take(1)
         )
         // @ts-ignore ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
         .subscribe((entityPrimaryKeyPropertyPath: string) => {
            const entityTypeValueComparer = (v1: any, v2: any) => {
               const value1PrimaryKey = ObjectMapResolverService.getPropertyValue(v1, entityPrimaryKeyPropertyPath);
               const value2PrimaryKey = ObjectMapResolverService.getPropertyValue(v2, entityPrimaryKeyPropertyPath);
               return ValueComparerHelper.comparerNullCheck(v1, v2) && value1PrimaryKey === value2PrimaryKey;
            };

            this._entityLinkValueComparerObservable.next(entityTypeValueComparer);
         });
   }

   updateFilters(entityLinkEntityType: EntityLinkEntityTypes) {
      const entityLinkEntityTypeControl = this.getEntityLinkEntityType();
      if (
         this.includeStatusFilters &&
         this.getEntityType().value === EntityType.Tenant &&
         this.getEntityLink().value > 0
      ) {
         this.getTenantEntityType()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((tenantLinkType) => {
               if (tenantLinkType !== entityLinkEntityType) {
                  entityLinkEntityTypeControl.setValue(tenantLinkType, { emitEvent: false });
               }
               this.setEntityFilters(entityLinkEntityType);
            });
      } else {
         this.setEntityFilters(entityLinkEntityType);
      }
      this.updateEntityType(entityLinkEntityTypeControl.value);
   }

   private getEntityTypePrimaryKeyPropertyPath(entityType: EntityType) {
      if (!this._entityLinkValueSource.value.StaticValues) {
         return of(null);
      }

      if (this.entityInformationMap.size > 0) {
         return of(this.entityInformationMap.get(entityType));
      }

      const entityInformationRequests: Observable<EntityViewInformationModel>[] = [];
      for (const selectorItem of this._entityLinkValueSource.value.StaticValues) {
         const selectorEntityType = this.entityLinkEntityTypes.get(selectorItem.value);
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'EntityType | undefined' is not a... Remove this comment to see the full error message
         entityInformationRequests.push(this.entityViewInformationService.getViewInformation(selectorEntityType));
      }

      return forkJoin(entityInformationRequests).pipe(
         map((entityViewInformationModels: EntityViewInformationModel[]) => {
            for (const entityViewInformationModel of entityViewInformationModels) {
               this.entityInformationMap.set(
                  entityViewInformationModel.EntityType,
                  entityViewInformationModel.EntityPrimaryKeyPropertyPath
               );
            }

            return this.entityInformationMap.get(entityType);
         })
      );
   }

   private updateEntityLinkEntityType(entityType: EntityType) {
      const entityLinkEntityTypes = Array.from(this.entityLinkEntityTypes.keys());

      for (const entityLinkEntityType of entityLinkEntityTypes) {
         if (entityType === this.entityLinkEntityTypes.get(entityLinkEntityType)) {
            this.getEntityLinkEntityType().setValue(entityLinkEntityType);
            return;
         }
      }
   }

   private getTenantEntityType(): Observable<EntityLinkEntityTypes> {
      return this.tenantsService.get(this.getEntityLink().value).pipe(
         map((t) => {
            if (t.Status === TenantStatus.Current) {
               return EntityLinkEntityTypes.CurrentTenant;
            } else {
               return EntityLinkEntityTypes.PastOrFutureTenant;
            }
         })
      );
   }

   private initEntityLinkEntityTypes(includeStatusFilters: boolean) {
      this.entityLinkEntityTypes = new Map<EntityLinkEntityTypes, EntityType>();
      if (includeStatusFilters) {
         this.entityLinkEntityTypes.set(EntityLinkEntityTypes.CurrentTenant, EntityType.Tenant);
         this.entityLinkEntityTypes.set(EntityLinkEntityTypes.PastOrFutureTenant, EntityType.Tenant);
      } else {
         this.entityLinkEntityTypes.set(EntityLinkEntityTypes.AllTenant, EntityType.Tenant);
      }
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.Prospect, EntityType.Prospect);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.Unit, EntityType.Unit);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.Property, EntityType.Property);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.Vendor, EntityType.Vendor);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.Owner, EntityType.Owner);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.ServiceManagerIssue, EntityType.ServiceManagerIssue);
      this.entityLinkEntityTypes.set(EntityLinkEntityTypes.OwnerProspect, EntityType.OwnerProspects);
   }

   private initEntityTypeValueSource() {
      if (this.entityTypesToInclude && this.entityTypesToInclude.length > 0) {
         this.initSetValueSource();
      } else {
         this.initStandardValueSource();
      }
   }

   private initSetValueSource() {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      if (this.entityTypesToInclude && this.entityTypesToInclude.length > 0) {
         this.entityTypesToInclude.forEach((entityType) => {
            this.entityLinkEntityTypes.forEach((value, key) => {
               if (value === +entityType) {
                  const item = new SelectorItemModel(
                     key,
                     value === EntityType.ServiceManagerIssue
                        ? "Issue"
                        : this.entityInformationService.getEntityTypeInformation(value).SingleLabel
                  );
                  valueSource.StaticValues.push(item);
               }
            });
         });
      }
      this._entityLinkValueSource.next(valueSource);
   }

   private initStandardValueSource() {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      const tenant = this.entityInformationService.getEntityTypeInformation(EntityType.Tenant).SingleLabel;

      if (this.includeStatusFilters === true) {
         const currentTenant = new SelectorItemModel(EntityLinkEntityTypes.CurrentTenant, `Current ${tenant}`);
         valueSource.StaticValues.push(currentTenant);

         const pastOrFutureTenant = new SelectorItemModel(
            EntityLinkEntityTypes.PastOrFutureTenant,
            `Past or Future ${tenant}`
         );
         valueSource.StaticValues.push(pastOrFutureTenant);
      } else {
         const allTenant = new SelectorItemModel(EntityLinkEntityTypes.AllTenant, tenant);
         valueSource.StaticValues.push(allTenant);
      }

      const prospectItem = new SelectorItemModel(
         EntityLinkEntityTypes.Prospect,
         this.entityInformationService.getEntityTypeInformation(EntityType.Prospect).SingleLabel
      );
      valueSource.StaticValues.push(prospectItem);

      const unitItem = new SelectorItemModel(
         EntityLinkEntityTypes.Unit,
         this.entityInformationService.getEntityTypeInformation(EntityType.Unit).SingleLabel
      );
      valueSource.StaticValues.push(unitItem);

      const propertyItem = new SelectorItemModel(
         EntityLinkEntityTypes.Property,
         this.entityInformationService.getEntityTypeInformation(EntityType.Property).SingleLabel
      );
      valueSource.StaticValues.push(propertyItem);

      this._entityLinkValueSource.next(valueSource);
   }

   private setEntityFilters(entityLinkEntityType: EntityLinkEntityTypes) {
      let filters = new Array<FilterOption>();
      switch (entityLinkEntityType) {
         case EntityLinkEntityTypes.CurrentTenant:
            filters = [new FilterOption("Status", FilterOperations.EqualTo, ["Current"])];
            break;
         case EntityLinkEntityTypes.PastOrFutureTenant:
            filters = [new FilterOption("Status", FilterOperations.In, ["Past", "Future"])];
            break;
         case EntityLinkEntityTypes.Prospect:
            filters = [new FilterOption("ProspectStatus", FilterOperations.In, ["Prospect", "LostRejected", "Lost"])];
            break;
      }
      this._entityFilters.next(filters);
   }

   private updateEntityType(entityLinkEntityType: EntityLinkEntityTypes) {
      this.getEntityType().setValue(this.entityLinkEntityTypes.get(entityLinkEntityType));
   }
}
