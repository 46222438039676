import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NDTCredentialsAuthenticationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/ndtcredentials-authentication.model";
import { OutboundCallRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/outbound-call-request.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class NDTService {
   static readonly DownloadCallRecordingResource = "DownloadCallRecording";
   static readonly LicensesResource = "Licenses";
   static readonly OutboundCallResource = "OutboundCall";
   static readonly TestConnectionResource = "TestConnection";
   static readonly endpoint = "NDTView";
   constructor(private apiService: ApiService) {}
   getDownloadCallRecordingUrl(rmVoIPCallHistoryID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("rmVoIPCallHistoryID=" + rmVoIPCallHistoryID);
      return this.apiService.getUrl(
         NDTService.endpoint + "/DownloadCallRecording",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDownloadCallRecording(
      rmVoIPCallHistoryID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getSingle(this.getDownloadCallRecordingUrl(rmVoIPCallHistoryID, additionalParameters));
   }

   getLicensesCollectionUrl(
      username?: string | null,
      password?: string | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (username !== undefined && username !== null) {
         additionalParameters.push("username=" + username);
      }
      if (password !== undefined && password !== null) {
         additionalParameters.push("password=" + password);
      }
      return this.apiService.getUrl(
         NDTService.endpoint + "/Licenses",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLicensesCollection(
      username?: string | null,
      password?: string | null,
      additionalParameters?: Array<string>
   ): Observable<Array<string>> {
      return this.apiService.getCollection(this.getLicensesCollectionUrl(username, password, additionalParameters));
   }

   postOutboundCallUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         NDTService.endpoint + "/OutboundCall",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postOutboundCall(
      outboundCallModel?: OutboundCallRequestModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postOutboundCallUrl(additionalParameters), outboundCallModel);
   }

   postTestConnectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         NDTService.endpoint + "/TestConnection",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestConnection(
      authenticationRequestModel?: NDTCredentialsAuthenticationModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postTestConnectionUrl(additionalParameters), authenticationRequestModel)
         .pipe(map((response) => response.body));
   }
}
