import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { IconModule } from "@lcs/icon/icon.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { OverlayPanelsModule } from "@lcs/overlay-panel/overlay-panels.module";

import { SingleLineMultiSelectComponent } from "./single-line-multi-select.component";
import { MultiSelectorOverlayPanelComponent } from "./views/multi-selector-overlay-panel/multi-selector-overlay-panel.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      PortalModule,
      LoadingOverlayModule,
      IconModule,
      OverlayPanelsModule,
      ValidationModule,
      CheckboxModule,
      TextBoxModule,
      ScrollingModule,
   ],
   declarations: [SingleLineMultiSelectComponent, MultiSelectorOverlayPanelComponent],
   exports: [SingleLineMultiSelectComponent, MultiSelectorOverlayPanelComponent],
})
export class SingleLineMultiSelectModule {}
