import { Injectable } from "@angular/core";
import { ExpressColumnDefinitionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-column-definition.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class EstimateDetailService {
   static readonly EstimateDetailColumnsResource = "EstimateDetailColumns";
   static readonly OwnerEstimateDetailItemPropertiesResource = "OwnerEstimateDetailItemProperties";
   static readonly endpoint = "EstimateDetailView";
   constructor(private apiService: ApiService) {}
   getEstimateDetailColumnsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         EstimateDetailService.endpoint + "/EstimateDetailColumns",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEstimateDetailColumnsCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<ExpressColumnDefinitionModel>> {
      return this.apiService.getCollection(this.getEstimateDetailColumnsCollectionUrl(additionalParameters));
   }

   getOwnerEstimateDetailItemPropertiesCollectionUrl(ownerID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("ownerID=" + ownerID);
      return this.apiService.getUrl(
         EstimateDetailService.endpoint + "/OwnerEstimateDetailItemProperties",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOwnerEstimateDetailItemPropertiesCollection(
      ownerID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getOwnerEstimateDetailItemPropertiesCollectionUrl(ownerID, additionalParameters)
      );
   }
}
