import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { DateRangeModel } from "../../../inputs/date-range-picker/date-range.model";
import { ReportParameterComponents } from "../../../reports/report-parameters/report-parameter-components.enum";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-period-range-report-parameter",
   templateUrl: "period-range-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
})
export class PeriodRangeReportParameterComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() set reportReportParameters(values: Array<ReportReportParameterViewModel>) {
      this._reportReportParameters = values;
   }

   @Input() dateRangeReportParameterType: ReportParameterComponents = ReportParameterComponents.DateRange;

   @Input() hasAsk: boolean;

   dateRange: DateRangeModel;

   startDateParameter: ReportReportParameterViewModel;

   startDateValueModel: ReportParameterValueModel;

   endDateParameter: ReportReportParameterViewModel;

   endDateValueModel: ReportParameterValueModel;

   dateStartFormat: string = "mm/yy";

   dateEndFormat: string = "mm/yy";

   label: string = "Period Range";

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   initializeParameters() {
      this.startDateParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === ReportParameter.PeriodStartDate
      )[0];
      this.endDateParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === ReportParameter.PeriodEndDate
      )[0];
      if (
         this.startDateParameter.ReportParameterValueSource &&
         this.startDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateStartFormat = this.startDateParameter.ReportParameterValueSource.FormatString;
      }
      if (
         this.endDateParameter.ReportParameterValueSource &&
         this.endDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateEndFormat = this.endDateParameter.ReportParameterValueSource.FormatString;
      }
   }

   valueChange(event: DateRangeModel) {
      this.dateRange = event;
      if (event) {
         let startDateValue = "";
         if (event.startDate && !isNaN(event.startDate.getTime())) {
            startDateValue = formatDate(event.startDate, "MM/yyyy", GlobalsService.locale);
            if (this.startDateValueModel.value !== startDateValue) {
               this.startDateValueModel.value = startDateValue;
               this.reportParametersService.updateParameterValue.next(this.startDateValueModel);
            }
         }

         let endDateValue = "";
         if (event.endDate && !isNaN(event.endDate.getTime())) {
            event.endDate.setHours(23, 59, 59);
            endDateValue = formatDate(event.endDate, "MM/yyyy", GlobalsService.locale);
            if (this.endDateValueModel.value !== endDateValue) {
               this.endDateValueModel.value = endDateValue;
               this.reportParametersService.updateParameterValue.next(this.endDateValueModel);
            }
         }
      }
   }

   private initializeReportParameterValues() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.startDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.startDateParameter.ReportParameterID
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.endDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.endDateParameter.ReportParameterID
      );
      this.startDateValueModel.value = new Date();
      this.endDateValueModel.value = new Date();
      this.updateReportParameterStatus(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameterValueModel | unde... Remove this comment to see the full error message
         this.reportParametersService.reportParameterValues.get(ReportParameter.BEFOREPOSTING)
      );
      this.dateRange = new DateRangeModel();
      this.dateRange.startDate = new Date(this.startDateValueModel.value);
      this.dateRange.endDate = new Date(this.endDateValueModel.value);
      this.valueChange(this.dateRange);

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameterStatus(updatedValue);
         });
   }

   private updateReportParameterStatus(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.startDateParameter.ReportID,
         updatedValue,
         this.startDateValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
