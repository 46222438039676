<lcs-entity-link-selector-view
   [displayHorizontal]="displayHorizontal"
   [entityFilters]="$any(entityFilterObservable | async)"
   [entityLinkValueComparer]="entityLinkValueComparerObservable | async"
   [entityLinkValueSource]="$any(entityLinkValueSourceObservable | async)"
   [entityType]="entityType"
   [group]="group"
   [isAddHidden]="isAddHidden"
   (linkEntityClicked)="onLinkEntityClicked()"
   [entityTypeSelectorLabel]="entityTypeSelectorLabel"
   [entitySelectorLabel]="entitySelectorLabel"
>
</lcs-entity-link-selector-view>