import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LinkModule } from "@lcs/link/link.module";

import { CollapsibleContextBarComponent } from "./collapsible-context-bar.component";

@NgModule({
   imports: [CommonModule, LinkModule],
   declarations: [CollapsibleContextBarComponent],
   exports: [CollapsibleContextBarComponent],
})
export class CollapsibleContextBarModule {}
