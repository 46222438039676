import { CDK_ROW_TEMPLATE, CdkFooterRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
   selector: "lcs-footer-row, tr[lcs-footer-row]",
   template: CDK_ROW_TEMPLATE,
   changeDetection: ChangeDetectionStrategy.OnPush,
   exportAs: "lcsFooterRow",
   providers: [{ provide: CdkFooterRow, useExisting: LcsFooterRowComponent }],
})
export class LcsFooterRowComponent extends CdkFooterRow {
   @HostBinding("class.lcs-footer-row") class: boolean = true;

   @HostBinding("attr.role") role: string = "row";
}
