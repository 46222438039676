<lcs-table [dataSource]="workOrderItems">
   <tr
      lcs-header-row
      *lcsHeaderRowDef="workOrderColumns; sticky: false"
   ></tr>
   <tr
      lcs-row
      *lcsRowDef="let row; columns: workOrderColumns"
   ></tr>
   <ng-container
      lcsColumnDef="Property"
      [width]="20"
      [priority]="1"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Property</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
      >{{row.Property?.Name}}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Unit"
      [width]="10"
      [priority]="2"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Unit</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
      >{{row.Unit?.Name}}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Name"
      [width]="15"
      [priority]="3"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Name</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
      >{{row.InventoryItem?.Name}}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Quantity"
      [width]="6"
      [priority]="4"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Quantity</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
         class="numeric"
      >{{row.Quantity}}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Price"
      [width]="6"
      [priority]="5"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Price</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
         class="numeric"
      >{{row.Price | currency:'USD':'symbol':'1.2-2'}}</td>
   </ng-container>
   <ng-container
      lcsColumnDef="Total"
      [width]="6"
      [priority]="6"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Total</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index=index"
         class="numeric"
      >{{row.Price * row.Quantity | currency:'USD':'symbol':'1.2-2'}}</td>
   </ng-container>
   <ng-container *ngIf="showBills">
      <ng-container
         lcsColumnDef="Bill"
         [width]="10"
         [priority]="7"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Bill</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index=index"
            class="attachment link"
            (click)="showBill(row.OwnerBill !== undefined && row.HasOwnerBillLink ? row.OwnerBill?.ID : (row.VendorBill !== undefined && row.HasVendorBillLink ? row.VendorBill?.ID : ''))"
         >
            {{row.OwnerBill !== undefined && row.HasOwnerBillLink ? (row.OwnerBill?.Reference == "" ? (row.OwnerBill?.TransactionDate | date: "MM/dd/yyyy") : row.OwnerBill?.Reference) : (row.VendorBill !== undefined  && row.HasVendorBillLink ? (row.VendorBill?.Reference == "" ? (row.VendorBill?.TransactionDate | date: "MM/dd/yyyy") : row.VendorBill?.Reference) : '')}}
         </td>
      </ng-container>
   </ng-container>
</lcs-table>