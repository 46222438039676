import cloneDeep from "lodash/cloneDeep";
import { ReportFieldDataType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-field-data-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ReportUserControlFrame } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-user-control-frame.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { MemorizedReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/memorized-report-report-parameter.model";
import { ReportBatchReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-batch-report-report-parameter.model";
import { ReportParameterValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-source.model";
import { ReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter.model";
import { ReportReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-report-parameter.model";
import { ReportWriterReportParameterSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-writer-report-parameter-summary.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";

import { ReportReportParameterType } from "../report-report-parameter-type.enum";

export class ReportReportParameterViewModel {
   ReportReportParameterType: ReportReportParameterType;
   ColNumber: number;
   ColSpan: number;
   DataType: ReportFieldDataType;
   DefaultValue: string;
   DisplayName: string;
   IsHidden: boolean;
   MemorizedReportID: number;
   PromptText: string;
   PropertyName: string;
   Report: ReportModel;
   ReportID: Report;
   ReportParameter: ReportParameterModel;
   ReportParameterID: ReportParameter;
   ReportParameterValueSource: ReportParameterValueSourceModel;
   RowNumber: number;
   RowSpan: number;
   SortOrder: number;
   ToolTip: string;
   UserControlFrame: ReportUserControlFrame;
   IsAsk: boolean;
   IsUseMinDateForBlankDateValue: boolean;

   public fromReportReportParameterModel(model: ReportReportParameterModel) {
      this.ReportReportParameterType = ReportReportParameterType.Report;
      this.ColNumber = model.ColNumber;
      this.ColSpan = model.ColSpan;
      this.DefaultValue = model.DefaultValue;
      this.DisplayName = model.DisplayName;
      this.IsHidden = model.IsHidden;
      this.PromptText = model.PromptText;
      this.PropertyName = model.PropertyName;
      this.Report = model.Report;
      this.ReportID = model.ReportID;
      this.ReportParameter = cloneDeep(model.ReportParameter);
      this.ReportParameterID = model.ReportParameterID;
      this.ReportParameterValueSource = cloneDeep(model.ReportParameterValueSource);
      this.DataType = this.ReportParameterValueSource.DataType;
      this.RowNumber = model.RowNumber;
      this.RowSpan = model.RowSpan;
      this.SortOrder = model.SortOrder;
      this.ToolTip = model.ToolTip;
      this.UserControlFrame = model.UserControlFrame;
   }

   public fromMemorizedReportReportParameterModel(model: MemorizedReportReportParameterModel) {
      this.ReportReportParameterType = ReportReportParameterType.MemorizedReport;
      this.ColNumber = model.ColNumber;
      this.ColSpan = model.ColSpan;
      this.DefaultValue = model.DefaultValue;
      this.DisplayName = model.DisplayName;
      this.IsHidden = model.IsHidden;
      this.PromptText = model.PromptText;
      this.PropertyName = model.PropertyName;
      this.MemorizedReportID = model.MemorizedReportID;
      this.ReportParameter = cloneDeep(model.ReportParameter);
      this.ReportParameterID = model.ReportParameterID;
      this.ReportParameterValueSource = cloneDeep(model.ReportParameterValueSource);
      this.DataType = this.ReportParameterValueSource.DataType;
      this.RowNumber = model.RowNumber;
      this.RowSpan = model.RowSpan;
      this.SortOrder = model.SortOrder;
      this.ToolTip = model.ToolTip;
      this.UserControlFrame = model.UserControlFrame;
   }

   public fromReportBatchReportParameterModel(model: ReportBatchReportReportParameterModel) {
      this.ReportReportParameterType = ReportReportParameterType.ReportBatchReport;
      this.ColNumber = model.ColNumber;
      this.ColSpan = model.ColSpan;
      this.DefaultValue = model.DefaultValue;
      this.DisplayName = model.DisplayName;
      this.IsHidden = model.IsHidden;
      this.PromptText = model.PromptText;
      this.PropertyName = model.PropertyName;
      this.ReportID = model.ReportID;
      this.ReportParameter = cloneDeep(model.ReportParameter);
      this.ReportParameterID = model.ReportParameterID;
      this.ReportParameterValueSource = cloneDeep(model.ReportParameterValueSource);
      this.DataType = this.ReportParameterValueSource.DataType;
      this.RowNumber = model.RowNumber;
      this.RowSpan = model.RowSpan;
      this.SortOrder = model.SortOrder;
      this.ToolTip = model.ToolTip;
      this.UserControlFrame = model.UserControlFrame;
   }

   public toReportReportParameterModel(): ReportReportParameterModel {
      const model = new ReportReportParameterModel();
      model.ColNumber = this.ColNumber;
      model.ColSpan = this.ColSpan;
      model.DataType = this.DataType;
      model.DefaultValue = this.DefaultValue;
      model.DisplayName = this.DisplayName;
      model.IsHidden = this.IsHidden;
      model.PromptText = this.PromptText;
      model.PropertyName = this.PropertyName;
      model.Report = this.Report;
      model.ReportID = this.ReportID;
      model.ReportParameter = cloneDeep(this.ReportParameter);
      model.ReportParameterID = this.ReportParameterID;
      model.ReportParameterValueSource = cloneDeep(this.ReportParameterValueSource);
      model.RowNumber = this.RowNumber;
      model.RowSpan = this.RowSpan;
      model.SortOrder = this.SortOrder;
      model.ToolTip = this.ToolTip;
      model.UserControlFrame = this.UserControlFrame;

      return model;
   }

   public toMemorizedReportReportParameterModel(): MemorizedReportReportParameterModel {
      const model = new MemorizedReportReportParameterModel();
      model.ColNumber = this.ColNumber;
      model.ColSpan = this.ColSpan;
      model.DataType = this.DataType;
      model.DefaultValue = this.DefaultValue;
      model.DisplayName = this.DisplayName;
      model.IsHidden = this.IsHidden;
      model.PromptText = this.PromptText;
      model.PropertyName = this.PropertyName;
      model.MemorizedReportID = this.MemorizedReportID;
      model.ReportParameter = cloneDeep(this.ReportParameter);
      model.ReportParameterID = this.ReportParameterID;
      model.ReportParameterValueSource = cloneDeep(this.ReportParameterValueSource);
      model.RowNumber = this.RowNumber;
      model.RowSpan = this.RowSpan;
      model.SortOrder = this.SortOrder;
      model.ToolTip = this.ToolTip;
      model.UserControlFrame = this.UserControlFrame;

      return model;
   }

   fromReportWriterReportParameterSummaryModel(model: ReportWriterReportParameterSummaryModel) {
      this.ReportReportParameterType = ReportReportParameterType.Report;
      this.ColNumber = model.ColNumber;
      this.ColSpan = model.ColSpan;
      this.DefaultValue = model.DefaultValue;
      this.DisplayName = model.DisplayName;
      this.IsHidden = model.IsHidden;
      this.PromptText = model.PromptText;
      this.PropertyName = model.PropertyName;
      this.ReportID = Report.ReportWriter;
      this.ReportParameterID = model.ReportParameterID;
      this.ReportParameterValueSource = cloneDeep(model.ReportParameterValueSource);
      this.DataType = this.ReportParameterValueSource.DataType;
      this.RowNumber = model.RowNumber;
      this.RowSpan = model.RowSpan;
      this.SortOrder = model.SortOrder;
      this.ToolTip = model.ToolTip;
   }
}
