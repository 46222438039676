////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum AddressRelatedObjectTypes {
   NotSet = 0,
   Tenant = 1,
   Prospect = 2,
   Property = 3,
   Unit = 4,
   Vendor = 6,
   Owner = 7,
   Contact = 8,
   LoanContact = 9,
   Utility = 26,
   AssetManufacturer = 44,
   OwnerProspect = 121,
   AddressType = 131,
}
