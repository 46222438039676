import { CdkPortalOutletAttachedRef, Portal } from "@angular/cdk/portal";
import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   TemplateRef,
   TrackByFunction,
   ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TextBoxComponent } from "@lcs/inputs/text-box/text-box.component";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { OverlayPortalAttachedModel } from "@lcs/single-line-multi-select/models/overlay-portal-attached.model";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-multi-selector-overlay-panel",
   templateUrl: "./multi-selector-overlay-panel.component.html",
})
export class MultiSelectorOverlayPanelComponent implements OnInit, OnDestroy {
   @ViewChild("overlayPanel") overlayPanel: ElementRef;

   @ViewChild("searchBox") searchBox: TextBoxComponent;

   @Input() alignToRight: boolean;

   @Input() control: UntypedFormControl;

   @Input() disabled: boolean;

   @Input() isShowLabel: boolean;

   @Input() displayName: string = "Value";

   @Input() innerElementSelector: string;

   @Input() innerElementTags: Array<string>;

   @Input() overlayPanelStaticWidth: number;

   @Input() overlayPanelStaticMinWidth: number;

   @Input() label: string;

   @Input() set items(value: Array<SelectorItemModel>) {
      if (value) {
         this._items = value;
         this.hasMutuallyExclusiveAll = value.some((v) => v.isMutuallyExclusiveAll);
         this.updateUI();
      }
   }

   get items(): Array<SelectorItemModel> {
      return this._items;
   }

   @Input() isLoading: boolean = false;

   @Input() parentElement: HTMLDivElement;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() set show(val: boolean) {
      if (val !== this._show) {
         this._show = val;
         this.visibilityChanged.emit(this._show);
      }
   }

   @Input() staticOverlay: boolean;

   @Input() toggleOnClick: boolean = true;

   @Input() isAllSelected: boolean;

   @Input() selectedItemCount: number = 0;

   @Input() afterItemsPortal: Portal<any>;

   @Input() hideSelectAll: boolean;

   @Input() disableAllItems: boolean;

   @Output() afterItemsPortalAttached = new EventEmitter<OverlayPortalAttachedModel>();

   @Output() visibilityChanged = new EventEmitter<boolean>(false);

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   get show(): boolean {
      return this._show;
   }

   filteredItems: SelectorItemModel[];

   get searchValue(): string {
      return this._searchValue;
   }

   set searchValue(val: string) {
      if (this._searchValue !== val) {
         this._searchValue = val;
         this.searchValueChange.next(this._searchValue);
      }
   }

   private _items = new Array<SelectorItemModel>();

   private _show: boolean = false;

   private unsubscribe = new Subject<void>();

   private _searchValue: string;

   private searchValueChange: EventEmitter<string> = new EventEmitter<string>();

   private hasMutuallyExclusiveAll: boolean;

   private preventBlur: boolean = false;

   private keyboardOpening: boolean = false;

   trackByFn: TrackByFunction<SelectorItemModel> = (_: number, item: SelectorItemModel) => item.value;

   ngOnInit() {
      this.searchValueChange
         .pipe(
            debounceTime(500),
            distinctUntilChanged((emittedValue) => {
               return emittedValue === this._searchValue;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((searchText) => {
            this.filterItems(searchText);
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   clickInOptions() {
      this.preventBlur = true;
      setTimeout(() => {
         this.preventBlur = false;
      });
   }

   onResize() {
      this.preventBlur = false;
      if (!this.keyboardOpening) {
         this.searchBox.inputEl.nativeElement.blur();
         this.keyboardOpening = true;
      } else {
         this.keyboardOpening = false;
      }
   }

   onFocus() {
      if (!this.preventBlur) {
         this.keyboardOpening = true;
      }
   }

   onBlur() {
      if (this.preventBlur) {
         this.searchBox.inputEl.nativeElement.focus();
      }
   }

   onItemSelected(item: SelectorItemModel) {
      if (this.hasMutuallyExclusiveAll) {
         this.updateMutuallyExclusiveAll(item);
      }
      this.updateUI();
      this.selectedItemsChanged();
   }

   onPortalAttached(attached: CdkPortalOutletAttachedRef) {
      const overlayPortalAttached = new OverlayPortalAttachedModel();
      overlayPortalAttached.portalOutletAttachedRef = attached;
      overlayPortalAttached.parentOverlayRef = this.overlayPanel;
      this.afterItemsPortalAttached.emit(overlayPortalAttached);
   }

   updateMutuallyExclusiveAll(item: SelectorItemModel) {
      if (item.isMutuallyExclusiveAll && item.isChecked) {
         this.items.forEach((i) => {
            if (!i.isMutuallyExclusiveAll && (i.isIncludedInAll || i.value >= 0)) {
               i.isChecked = false;
            }
         });
      } else if (!item.isMutuallyExclusiveAll && item.isChecked && (item.isIncludedInAll || item.value >= 0)) {
         this.items.forEach((i) => {
            if (i.isMutuallyExclusiveAll) {
               i.isChecked = false;
            }
         });
      }
   }

   allSelected(isSelected: boolean) {
      this.items.forEach((item) => {
         if (!item.isHidden && !item.isDisabled) {
            item.isChecked = isSelected;
         }
         if (isSelected && item.isMutuallyExclusiveAll) {
            item.isChecked = false;
         }
      });

      this.updateUI();
      this.selectedItemsChanged();
   }

   clear() {
      this.items.forEach((item) => {
         if (!item.isHidden && !item.isDisabled) {
            item.isChecked = false;
         }
      });
      this.updateUI();
      this.selectedItemsChanged();
   }

   private filterItems(searchText: string) {
      if (!searchText || searchText.length === 0) {
         this.items.forEach((i) => (i.isHidden = false));
      } else {
         this.items.forEach((i) => {
            i.isHidden =
               (!i.displayValue || i.displayValue.toLowerCase().indexOf(searchText.toLowerCase()) < 0) &&
               (!i.additionalInfoValue || i.additionalInfoValue.toLowerCase().indexOf(searchText.toLowerCase()) < 0);
         });
      }
      this.updateUI();
   }

   private updateUI() {
      this.selectedItemCount = this.items.filter((i) => i.isChecked).length;
      this.isAllSelected =
         this.items.length > 0 && !this.items.some((i) => !i.isHidden && !i.isChecked && !i.isMutuallyExclusiveAll);
      this.filteredItems = this.items.filter((item) => !item.isHidden);
   }

   private selectedItemsChanged() {
      const selection = new SelectionChangeModel();
      this.items.filter((i) => i.isChecked)?.forEach((f) => selection.checkedItems.push(f));
      selection.items = this.items;
      selection.selectAll = !this.items.some((i) => !i.isChecked);
      this.selectionChange.emit(selection);
   }
}
