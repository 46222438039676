import { Injectable } from "@angular/core";
import { ConfirmationDialogConfigurationModel } from "@lcs/dialog/components/confirmation-dialog-configuration.model";
import { ConfirmationDialogService } from "@lcs/dialog/components/confirmation-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { CurrentSystemPreferencesService } from "@lcs/session/current-system-preferences.service";
import { OutboundCallRequestModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/outbound-call-request.model";
import { NDTService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/ndt.service";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ClickToCallBaseService {
   isDisplayClickToCall: Observable<boolean>;

   private _isDisplayClickToCall = new BehaviorSubject<boolean>(false);

   constructor(
      protected errorMessageService: ErrorMessageService,
      protected nDTService: NDTService,
      protected confirmationDialogService: ConfirmationDialogService,
      protected systemPreferenceService: CurrentSystemPreferencesService,
      protected currentUserService: CurrentUserService
   ) {
      this.isDisplayClickToCall = this._isDisplayClickToCall.asObservable();
   }

   emitConfirmationDialogForPostingOutboundCall(outboundCallRequestModel: OutboundCallRequestModel) {
      if (outboundCallRequestModel.PhoneNumber) {
         const formattedPhoneNumber: string = ClickToCallBaseService.formatPhoneNumber(
            outboundCallRequestModel.PhoneNumber
         );

         const dialog = new ConfirmationDialogConfigurationModel();
         dialog.title = "Place Call";
         dialog.message = "Are you sure you want to call: " + formattedPhoneNumber + "?";
         dialog.icon = "info";
         dialog.showCancelButton = true;
         dialog.acceptButtonText = "Yes";
         dialog.cancelButtonText = "No";
         dialog.accept = () => {
            this.nDTService.postOutboundCall(outboundCallRequestModel);
         };
         this.confirmationDialogService.openConfirmation(dialog);
      } else {
         this.errorMessageService.triggerErrorMessage("A call could not be made at this time.");
      }
   }

   resolveClickToCallDisplay() {
      // this.systemPreferenceService
      //    .getSystemPreference(SystemPreference.NDTConnectOnStartup)
      //    .pipe(take(1))
      //    .subscribe((systemPreferenceModel) => {
      //       this._isDisplayClickToCall.next(
      //          // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      //          this.currentUserService.currentUser.value.IsPhoneSystemUser &&
      //             this.currentUserService.hasPrivilege(Privilege.ndtplacecall, PrivilegeType.On) &&
      //             SystemPreferencesRequestService.coerceBoolean(systemPreferenceModel)
      //       );
      //    });
   }

   static formatPhoneNumber(phoneNumberString): string {
      const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
         return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
   }
}
