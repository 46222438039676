import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyPendingComponent } from './verify-pending.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [VerifyPendingComponent]
})
export class VerifyPendingModule { }
