import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { FormRegistrationModule } from "../../../forms/form-registration/form-registration.module";
import { SlideToggleModule } from "../../../inputs/slide-toggle/slide-toggle.module";
import { ValidationModule } from "../../../inputs/validation/validation.module";
import { ReportParameterAskComponent } from "./report-parameter-ask.component";

@NgModule({
   imports: [CommonModule, FormRegistrationModule, FormsModule, ValidationModule, SlideToggleModule],
   declarations: [ReportParameterAskComponent],
   exports: [ReportParameterAskComponent],
})
export class ReportParameterAskModule {}
