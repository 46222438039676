import {
   AfterViewInit,
   Component,
   ContentChildren,
   ElementRef,
   EventEmitter,
   Input,
   NgZone,
   OnDestroy,
   Output,
   QueryList,
} from "@angular/core";
import { fromEvent, Subject, takeUntil } from "rxjs";

import { ImmediateAuditor } from "../../../../../libraries/lcs/src/lib/viewport/immediate-auditor.extension";
import { WindowService } from "../../../../../libraries/lcs/src/lib/viewport/window.service";

@Component({
   selector: "owa-drag-scroll",
   templateUrl: "drag-scroll.component.html",
})
export class DragScrollComponent implements AfterViewInit, OnDestroy {
   @Input() disabled: boolean = false;

   @Input() dragDisabled: boolean = false;

   @Input() snap: boolean;

   @Input() snapOffset: number = 2;

   @Input() xDisabled: boolean = false;

   @Input() yDisabled: boolean = false;

   @Output() reachesLeftBound = new EventEmitter<boolean>();

   @Output() reachesRightBound = new EventEmitter<boolean>();

   @ContentChildren("dragScrollItem", { descendants: true, read: ElementRef })
   contentChildren: QueryList<ElementRef>;

   currentIndex: number = 0;

   downX: number = 0;

   downY: number = 0;

   isAnimating: boolean = false;

   isPressed: boolean = false;

   isScrolling: boolean = false;

   prevChildrenLength = 0;

   scrollReachesRightEnd = false;

   scrollReachesLeftEnd = true;

   scrollTimer: number = -1;

   scrollToId: number;

   inner: any;

   scrollPanelBarX: any;

   scrollPanelBarY: any;

   isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

   private children: ElementRef[] = new Array<ElementRef>();

   private scrollAuditTime: number = 100;

   private unsubscribe = new Subject<void>();

   constructor(private ngZone: NgZone, private windowService: WindowService, private elementRef: ElementRef) {
      this.windowService.resized.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.checkNavStatus();
      });
   }

   ngAfterViewInit() {
      this.findScrollpanel();
      this.ngZone.runOutsideAngular(() => {
         new ImmediateAuditor(fromEvent(this.inner, "scroll"), this.scrollAuditTime).observable
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
               this.onScroll();
            });

         fromEvent(document, "mousemove")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event: MouseEvent) => {
               if (this.isPressed && !this.disabled) {
                  // Drag X
                  if (!this.xDisabled && !this.dragDisabled) {
                     this.inner.scrollLeft = this.inner.scrollLeft - event.clientX + this.downX;
                     this.downX = event.clientX;
                  }

                  // Drag Y
                  if (!this.yDisabled && !this.dragDisabled) {
                     this.inner.scrollTop = this.inner.scrollTop - event.clientY + this.downY;
                     this.downY = event.clientY;
                  }
               }
            });

         fromEvent(document, "touchmove")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event: TouchEvent) => {
               if (this.isPressed && !this.disabled) {
                  if (event.touches && event.touches.length > 0) {
                     const touch = event.touches[0];
                     // Drag X
                     if (!this.xDisabled && !this.dragDisabled) {
                        this.inner.scrollLeft = this.inner.scrollLeft - touch.clientX + this.downX;
                        this.downX = touch.clientX;
                     }
                     // Drag Y
                     if (!this.yDisabled && !this.dragDisabled) {
                        this.inner.scrollTop = this.inner.scrollTop - touch.clientY + this.downY;
                        this.downY = touch.clientY;
                     }
                  }
               }
            });

         fromEvent(document, "touchend")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
               if (this.isPressed) {
                  this.ngZone.run(() => {
                     this.isPressed = false;
                  });
                  if (this.snap) {
                     this.locateCurrentIndex(true);
                  } else {
                     this.locateCurrentIndex();
                  }
               }
            });

         fromEvent(document, "mouseup")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
               if (this.isPressed) {
                  this.ngZone.run(() => {
                     this.isPressed = false;
                  });
                  if (this.snap) {
                     this.locateCurrentIndex(true);
                  } else {
                     this.locateCurrentIndex();
                  }
               }
            });
      });

      this.contentChildren.changes.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.updateChildren();
      });
      this.updateChildren();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onMousedown(event: MouseEvent) {
      if (event.srcElement === this.scrollPanelBarX || event.srcElement === this.scrollPanelBarY) {
         return;
      }
      this.isPressed = true;
      this.downX = event.clientX;
      this.downY = event.clientY;
      cancelAnimationFrame(this.scrollToId);
   }

   onTouchStart(event: TouchEvent) {
      if (event.srcElement === this.scrollPanelBarX || event.srcElement === this.scrollPanelBarY) {
         return;
      }
      if (!event.touches || event.touches.length === 0) {
         return;
      }
      const touch = event.touches[0];
      this.isPressed = true;
      this.downX = touch.clientX;
      this.downY = touch.clientY;
      cancelAnimationFrame(this.scrollToId);
   }

   onDragstart(event) {
      event.preventDefault();
   }

   onScroll() {
      if (this.isAnimating) {
         return;
      }
      this.checkNavStatus();
      if (!this.isPressed && this.snap) {
         this.isScrolling = true;
         clearTimeout(this.scrollTimer);
         this.scrollTimer = window.setTimeout(() => {
            this.isScrolling = false;
            this.locateCurrentIndex(true);
         }, 500);
      } else {
         this.locateCurrentIndex();
      }
   }

   moveLeft() {
      if (this.currentIndex !== 0) {
         this.currentIndex--;
         cancelAnimationFrame(this.scrollToId);
         this.scrollTo(this.inner, this.getOffsetLeft(this.currentIndex));
      } else {
         // if the first child is our current item but we're not scrolled all the way left, scroll all the way left
         cancelAnimationFrame(this.scrollToId);
         this.scrollTo(this.inner, 0);
      }
   }

   moveRight() {
      if (!this.scrollReachesRightEnd && this.children.length > this.currentIndex + 1) {
         this.currentIndex++;
         cancelAnimationFrame(this.scrollToId);
         this.scrollTo(this.inner, this.getOffsetLeft(this.currentIndex));
      }
   }

   moveTo(index: number) {
      if (index >= 0 && index !== this.currentIndex && this.children.length > index + 1) {
         this.currentIndex = index;
         cancelAnimationFrame(this.scrollToId);
         this.scrollTo(this.inner, this.getOffsetLeft(this.currentIndex));
      }
   }

   checkNavStatus() {
      let reachesLeftBound = false;
      let reachesRightBound = false;
      const el = this.inner;
      if (this.children.length <= 1 || el.scrollWidth <= el.clientWidth) {
         // only one element, or elements do not take up the full width
         reachesLeftBound = true;
         reachesRightBound = true;
      } else if (el.scrollLeft + el.offsetWidth >= el.scrollWidth) {
         // reached right end
         reachesRightBound = true;
      } else if (el.scrollLeft === 0 && el.scrollWidth > el.clientWidth) {
         // reached left end
         reachesLeftBound = true;
      }

      this.ngZone.run(() => {
         this.scrollReachesLeftEnd = reachesLeftBound;
         this.scrollReachesRightEnd = reachesRightBound;
         this.reachesLeftBound.emit(reachesLeftBound);
         this.reachesRightBound.emit(reachesRightBound);
      });
   }

   private scrollTo(element: Element, to: number) {
      this.ngZone.runOutsideAngular(() => {
         this.isAnimating = true;
         const start = element.scrollLeft;
         const delta = to - start - this.snapOffset - this.children[0].nativeElement.offsetLeft; // account for left-padding on the container
         const duration: number = 300;
         const increment = 20;
         this.scrollToId = requestAnimationFrame(() =>
            this.animateScroll(element, start, delta, duration, increment, 0)
         );
      });
   }

   private animateScroll(
      element: Element,
      start: number,
      delta: number,
      duration: number,
      increment: number,
      currentTime: number
   ) {
      currentTime += increment;
      element.scrollLeft = this.easeInOutQuad(currentTime, start, delta, duration);
      if (currentTime < duration) {
         this.scrollToId = requestAnimationFrame(() =>
            this.animateScroll(element, start, delta, duration, increment, currentTime)
         );
      } else {
         // allow last step of animation to complete
         setTimeout(() => {
            this.isAnimating = false;
         }, increment + this.scrollAuditTime + 50);
         this.ngZone.run(() => {
            this.checkNavStatus();
         });
      }
   }

   private easeInOutQuad(currentTime: number, start: number, delta: number, duration: number) {
      currentTime /= duration / 2;
      if (currentTime < 1) {
         return (delta / 2) * currentTime * currentTime + start;
      }
      currentTime--;
      return (-delta / 2) * (currentTime * (currentTime - 2) - 1) + start;
   }

   private locateCurrentIndex(snap?: boolean) {
      const el = this.inner;
      let childrenWidth = 0;
      for (let i = 0; i < this.children.length; i++) {
         if (i === this.children.length - 1) {
            this.currentIndex = this.children.length;
            this.checkNavStatus();
            break;
         }

         const child = this.children[i].nativeElement;
         childrenWidth += child.offsetWidth;
         if (el.scrollLeft < child.offsetWidth + child.offsetLeft) {
            if (this.currentIndex !== i) {
               this.currentIndex = i;
               if (snap) {
                  this.scrollTo(el, childrenWidth);
                  this.checkNavStatus();
               }
            }
            break;
         }
      }
   }

   private getOffsetLeft(index: number) {
      const offsetLeft = (this.children[index].nativeElement as HTMLElement).offsetLeft - this.snapOffset;
      return offsetLeft < 0 ? 0 : offsetLeft;
   }

   private updateChildren() {
      setTimeout(() => {
         this.children = this.contentChildren.toArray();
         this.checkNavStatus();
      });
   }

   private findScrollpanel() {
      this.inner = this.elementRef.nativeElement.querySelector(".p-scrollpanel-content");
      this.scrollPanelBarX = this.elementRef.nativeElement.querySelector(".p-scrollpanel-bar-x");
      this.scrollPanelBarY = this.elementRef.nativeElement.querySelector(".p-scrollpanel-bar-y");
   }
}
