import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { KEY_C } from "keycode-js";
import { fromEvent, Subject, takeUntil } from "rxjs";

import { DialogComponent } from "../dialog.component";

@Injectable({
   providedIn: "root",
})
export class DialogRegistrationService implements OnDestroy {
   currentDialog: DialogComponent;

   private dialogs: Map<string, DialogComponent>;

   private unsubscribe = new Subject<void>();

   constructor(private ngZone: NgZone) {
      this.dialogs = new Map<string, DialogComponent>();

      this.ngZone.runOutsideAngular(() => {
         fromEvent(window, "keydown")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
               const keyboardEvent = event as KeyboardEvent;
               if (keyboardEvent.keyCode === KEY_C && keyboardEvent.ctrlKey && keyboardEvent.altKey) {
                  this.ngZone.run(() => this.closeAllModals());
               }
            });
      });
   }

   public register(dialogID: string, dialog: DialogComponent) {
      this.dialogs.set(dialogID, dialog);
   }

   public deregister(dialogID: string) {
      this.dialogs.delete(dialogID);
   }

   public isDialogTypeOpen(componentType: any): boolean {
      return (
         Array.from(this.dialogs.entries())
            .map((entry) => entry[1])
            .filter((d) => d.childComponentType && d.childComponentType === componentType).length > 0
      );
   }

   public closeAllModals() {
      if (this.dialogs) {
         for (const entry of Array.from(this.dialogs.entries())) {
            const dialog = entry[1];
            dialog.closeDialog();
         }
         this.dialogs.clear();
      }
   }

   ngOnDestroy(): void {
      this.closeAllModals();
      this.unsubscribe.next();
   }
}
