<ng-container *ngIf="customHeaderTemplate">
   <div class="sortable-list-header">
      <ng-container *ngTemplateOutlet="customHeaderTemplate"> </ng-container>
   </div>
</ng-container>
<div
   cdkDropList
   cdkDragLockAxis="y"
   (cdkDropListDropped)="onItemSorted($event)"
   class="sortable-list"
   [ngStyle]="sortableListCustomStyle"
>
   <div
      class="sortable-list-item"
      [ngStyle]="sortableListItemCustomStyle"
      *ngFor="let item of items"
      cdkDrag
   >
      <ng-container *ngIf="customTemplate; else defaultTemplate">
         <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: item }"> </ng-container>
      </ng-container>
      <ng-template #defaultTemplate>
         <div class="item-wrapper">
            <div class="item-label-wrapper">
               <i class="text-center material-icons reorder drag-handle">reorder</i>
               <div class="user-select-none">
                  {{ item.itemObject | objectDisplayValue: item.displayProperty }}
               </div>
            </div>
            <div class="vertical-align-icon-wrapper">
               <lcs-link
                  [contentTemplate]="contentTemplate"
                  (clicked)="moveItemTop(item)"
               >
                  <ng-template #contentTemplate>
                     <i class="text-center material-icons vertical_align_top">vertical_align_top</i>
                  </ng-template>
               </lcs-link>
               <lcs-link
                  [contentTemplate]="contentTemplate1"
                  (clicked)="moveItemBottom(item)"
               >
                  <ng-template #contentTemplate1>
                     <i class="text-center material-icons vertical_align_bottom drag-handle">vertical_align_bottom</i>
                  </ng-template>
               </lcs-link>
            </div>
         </div>
      </ng-template>
   </div>
</div>