import { formatDate } from "@angular/common";
import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class DateHelperService implements OnDestroy {
   private unsubscribe = new Subject<void>();

   constructor() {}

   static getEndofDay(date: Date = new Date()): string {
      date.setHours(23, 59, 59, 999);
      return formatDate(date, "MM/dd/yy H:mm:ss", "en-US");
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }
}
