import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class PropertyGroupFields extends EntityField {
   static readonly ConcurrencyID: PropertyGroupFields = new PropertyGroupFields("ConcurrencyID");

   static readonly CreateDate: PropertyGroupFields = new PropertyGroupFields("CreateDate");

   static readonly FilterID: PropertyGroupFields = new PropertyGroupFields("FilterID");

   static readonly GroupID: PropertyGroupFields = new PropertyGroupFields("GroupID");

   static readonly MetaTag: PropertyGroupFields = new PropertyGroupFields("MetaTag");

   static readonly Name: PropertyGroupFields = new PropertyGroupFields("Name");

   static readonly Properties: PropertyGroupFields = new PropertyGroupFields("Properties");

   static readonly PropertyGroupDetails: PropertyGroupFields = new PropertyGroupFields("PropertyGroupDetails");

   static readonly PropertyGroupFilter: PropertyGroupFields = new PropertyGroupFields("PropertyGroupFilter");

   static readonly PropertyGroupFilterID: PropertyGroupFields = new PropertyGroupFields("PropertyGroupFilterID");

   static readonly PropertyGroupFilters: PropertyGroupFields = new PropertyGroupFields("PropertyGroupFilters");

   static readonly PropertyGroupID: PropertyGroupFields = new PropertyGroupFields("PropertyGroupID");

   static readonly UpdateDate: PropertyGroupFields = new PropertyGroupFields("UpdateDate");

   static readonly UserID: PropertyGroupFields = new PropertyGroupFields("UserID");
}
