export enum ReportParameterComponents {
   NotSet = -1,
   Hidden = 0,
   Textbox = 1,
   Checkbox = 2,
   Date = 3,
   Selector = 4,
   ToggleableInput = 5,
   BalanceDueValuesToInclude = 6,
   CRPYear = 7,
   AddressType = 8,
   CreditsToInclude = 9,
   SortOptions = 10,
   CAMDivExpMethod = 11,
   LabelSelection = 12,
   Textarea = 13,
   ChartAccountsToInclude = 14,
   DateRange = 15,
   DateRange2 = 16,
   YearRange = 17,
   ConsolidateAddressVendor1096 = 18,
   ConsolidateAddressVendor1099 = 19,
   ConsolidateAddressLoan = 20,
   ChargeTypeOrder = 21,
   PropertiesAndOwnersToInclude = 22,
   SelectUnit = 23,
   UsersToInclude = 24,
   Year1099 = 25,
   JobReportParameter = 26,
   IncludeExpectedMoveDatesReportParameter = 27,
   CheckedDateRange = 28,
   Reconciliations = 29,
   VendorReportParameter = 30,
   CheckboxGroup = 31,
   BankAccountSelector = 32,
   CAMEscrow = 33,
   AccountsWrapper = 34,
   PercentReportParameter = 35,
   ViolationNotes = 37,
   PayableAccounts = 38,
   DateRangeWithModes = 39,
   ForecastModel = 40,
   BudgetYears = 41,
   SelectLease = 42,
   CAMChargeTypes = 43,
   ReportPeriodType = 44,
   States = 45,
   PeriodRange = 46,
   MarketRent = 47,
   TransactionsToInclude = 48,
   PropertyUnitMultiSelector = 49,
   OwnersToInclude = 50,
   ValuesToIncludeParameter = 51,
   BirthdaysToIncludeParameter = 52,
   ShowOnReportParameter = 53,
   NumericInput = 54,
   BankCCAccountsParameter = 56,
   Date2 = 57,
   DateRangeWithAccountingPeriod = 58,
   DateRangeWithAccountingPeriod2 = 59,
   AsOfDateWithAccountingPeriod = 60,
   AsOfDateWithAccountingPeriod2 = 61,
   Associated1099Categories = 62,
   LeadSourcesToIncludeReportParameter = 63,
   ProspectStageID = 64,
   VendorNameAndUDFName = 65,
   AmountSelectionPSF = 66,
   HistoryCategoriesToInclude = 67,
   OwnerNameAndUDFName = 68,
   CrpPreparerParameters = 69,
   CrpOwnerParameters = 70,
   StatementMethod = 71,
   PropertySelectionBy = 72,
   ClearedOptions = 73,
}
