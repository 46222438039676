import { Injectable } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { BillViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/bill-view.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { BillsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/bills.service";
import { catchError, combineLatest, distinctUntilChanged, filter, first, map, mapTo, merge, Observable, of, shareReplay, Subject, switchMap } from "rxjs";

import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { BillDetailPreferences } from "./bill-detail-preferences.interface";

@Injectable()
export class BillDetailService {
   loading = new Observable<boolean>();

   bill: Observable<BillViewModel>;

   preferences: Observable<BillDetailPreferences>;
   private _loading = new Subject<boolean>();
   categoryID: number[];
   valueString: string;
   constructor(
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private billsService: BillsService,
      private route: ActivatedRoute,
      private errorMessageService: ErrorMessageService,
      private router: Router
   ) {
      const billID = this.route.paramMap.pipe(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
         map((paramMap: ParamMap) => parseInt(paramMap.get("id"))),
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         filter((id: number) => id && !isNaN(id)), // ignore invalid urls for now. TODO: throw an error? re-route to the list view?
         distinctUntilChanged() // ignore duplicates
      );

      this.preferences = this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            return of({
               // TODO: are these default values correct?
               showBills: false,
               showBillAttachments: false,
               showServiceIssues: false,
            });
         }),
         first(),
         map((preferences: SystemWebPreferenceModel[]) => ({
            showBills:
               preferences.filter((f) => f.Name === "ShowBillsOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowBillsOWA")[0].Value.toLocaleLowerCase() === "false"
                     ? false
                     : true
                  : false,
            showBillAttachments:
               preferences.filter((f) => f.Name === "ShowBillAttachmentsOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowBillAttachmentsOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,
            showClosedIssues:
               preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowClosedServiceIssuesOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,
            showOpenIssues:
               preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA").length !== 0
                  ? preferences.filter((f) => f.Name === "ShowOpenServiceIssuesOWA")[0].Value.toLocaleLowerCase() ===
                    "false"
                     ? false
                     : true
                  : false,

            hasCategoriesPermission:
               preferences.filter((f) => f.Name === "ServiceIssueCategoriesToDisplayOWA").length !== 0
                  ? (this.valueString = preferences.filter(
                       (f) => f.Name === "ServiceIssueCategoriesToDisplayOWA"
                    )[0].Value)
                  : (this.valueString = ""),
         })),
         shareReplay(1) // share the results among all subscribers (avoid duplicate API calls)
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<BillViewModel | null>' is not ass... Remove this comment to see the full error message
      this.bill = billID.pipe(
         switchMap((id: number) => {
            this._loading.next(true);
            return this.billsService.get(id);
         }),
         catchError((error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            this._loading.next(false);
            this.router.navigate(["/bills"]);
            return of(null);
         }),
         shareReplay(1) // share the results among all subscribers (avoid duplicate API calls)
      );

      this.loading = merge(this._loading, combineLatest(this.preferences, this.bill).pipe(mapTo(false)));
   }
}
