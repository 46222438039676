import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingOverlayComponent } from "./loading-overlay.component";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";

@NgModule({
   imports: [
      CommonModule,
      MatProgressSpinnerModule,
   ],
   declarations: [
      LoadingOverlayComponent,
   ],
   exports: [
      LoadingOverlayComponent,
   ],
})
export class LoadingOverlayModule { }
