import { animate, animateChild, AnimationTriggerMetadata, group, query, state, style, transition, trigger } from "@angular/animations";

export const slidePanelTiming: string = "300ms cubic-bezier(0, 0, 0.2, 1)";
export const slidePanelAnimationIdentifier = "slidePanel";
//RMX-4089 Lines 6-25, until Strict Null Checks is officially turned on, you need to use the ? operator prior to .length to avoid possible null/undefined ref exception. Please remove these once it is time to turn the flag on.
export function slidePanelToSlidePanel(from: string, to: string): boolean {
   return (
      from?.length > 0 &&
      to?.length > 0 &&
      from.startsWith(slidePanelAnimationIdentifier) &&
      to.startsWith(slidePanelAnimationIdentifier)
   );
}
export function voidToSlidePanel(from: string, to: string): boolean {
   return (!from || from === "void") && to?.length > 0 && to.startsWith(slidePanelAnimationIdentifier);
}
export function slidePanelToVoid(from: string, to: string): boolean {
   return (!to || to === "void") && from?.length > 0 && from.startsWith(slidePanelAnimationIdentifier);
}
export function baseToSlidePanel(from: string, to: string): boolean {
   return from === "base" && to?.length > 0 && to.startsWith(slidePanelAnimationIdentifier);
}
export function slidePanelToBase(from: string, to: string): boolean {
   return from?.length > 0 && from.startsWith(slidePanelAnimationIdentifier) && to === "base";
}

const slidePanelStateChanges = {
   slidePanelToSlidePanel: slidePanelToSlidePanel,
   voidToSlidePanel: voidToSlidePanel,
   slidePanelToVoid: slidePanelToVoid,
   baseToSlidePanel: baseToSlidePanel,
   slidePanelToBase: slidePanelToBase,
};

const switchSlidePanelsSteps = [
   // apply position:absolute to both the entering and leaving component to avoid a scrollbar
   query(":enter, :leave", style({ position: "absolute" }), { optional: true }),

   // move the new slide panel to the bottom of the screen
   query(":enter", style({ transform: "translateY(100%)" })),

   // apply translateY(0) to the old slide panel so that it can be animated to translateY(100%)
   query(":leave", [animateChild(), style({ transform: "translateY(0)" })], { optional: true }),

   // animate the leave and enter animations at the same time
   group([
      // animate the old slide panel to the bottom of the screen
      query(":leave", animate(slidePanelTiming, style({ transform: "translateY(100%)" })), { optional: true }),

      // animate the new slide panel to the top of the screen
      query(":enter", animate(slidePanelTiming, style({ transform: "translateY(0)" }))),
   ]),
   query(":enter", animateChild()),
];

const switchSlidePanelRoutesSteps = [
   // apply position:fixed to both the entering and leaving component to avoid a scrollbar on the scrollable container
   // we use width: 100% to prevent the animated elements' contents from forcing the element to overflow the viewport
   query(":enter, :leave", style({ position: "fixed", height: "100%", width: "100%" })),

   // move the new slide panel to the bottom of the screen
   query(":enter", style({ transform: "translateY(100%)" })),

   // apply translateY(0) to the old slide panel so that it can be animated to translateY(100%)
   query(":leave", [animateChild(), style({ transform: "translateY(0)" })]),

   // animate the leave and enter animations at the same time
   group([
      // animate the old slide panel to the bottom of the screen
      query(":leave", animate(slidePanelTiming, style({ transform: "translateY(100%)" }))),

      // animate the new slide panel to the top of the screen
      query(":enter", animate(slidePanelTiming, style({ transform: "translateY(0)" }))),
   ]),
   query(":enter", animateChild()),
];

export const slidePanelAnimations = {
   slidePanelBaseAnimation: trigger("slidePanelAnimation", [
      transition(slidePanelStateChanges.slidePanelToSlidePanel, switchSlidePanelsSteps),
      transition(slidePanelStateChanges.voidToSlidePanel, [
         query(":enter", [
            // apply styles to both the entering and leaving component
            // move the new slide panel to the bottom of the screen
            style({ position: "absolute", transform: "translateY(100%)" }),

            // animate the new slide panel to the top of the screen
            animate(slidePanelTiming, style({ transform: "translateY(0)" })),

            // allow the slide panel to animate if it has its own animations to run
            animateChild(),
         ]),
      ]),
      transition(slidePanelStateChanges.slidePanelToVoid, [
         query(":leave", [
            // allow the detail to animate if it has its own animations to run
            animateChild(),

            // position the slide panel at the top of the screen
            style({ position: "absolute", transform: "translateY(0)" }),

            // animate the slide panel to the bottom of the screen
            animate(slidePanelTiming, style({ transform: "translateY(100%)" })),
         ]),
      ]),
   ]),
   slidePanelOutletAnimation: trigger("routeTransition", [
      // we use width: 100% to prevent the animated elements' contents from forcing the element to overflow the viewport
      transition(slidePanelStateChanges.slidePanelToSlidePanel, switchSlidePanelRoutesSteps),
      transition(slidePanelStateChanges.slidePanelToBase, [
         query(
            ":leave",
            style({ position: "fixed", height: "100%", width: "100%", "z-index": 1000, transform: "translateY(0) " })
         ),
         query(":enter", style({ position: "absolute", top: 0, width: "100%" })),
         query(":leave", animate(slidePanelTiming, style({ transform: "translateY(100%)" }))),
      ]),
      transition(slidePanelStateChanges.baseToSlidePanel, [
         query(
            ":enter",
            style({ position: "fixed", height: "100%", width: "100%", "z-index": 1000, transform: "translateY(100%)" })
         ),
         query(":leave", style({ opacity: 1, width: "100%" })),
         query(":enter", animate(slidePanelTiming, style({ transform: "translateY(0)" }))),
      ]),
   ]),
};

export const slidePanelContainerAnimation: AnimationTriggerMetadata = trigger("slidePanelContainer", [
   state("void, exit", style({ transform: "translateY(100%)" })),
   state("enter", style({ transform: "none" })),
   transition("* => enter", animate(slidePanelTiming, style({ transform: "translateY(0)" }))),
   transition("* => void, * => exit", animate(slidePanelTiming, style({ transform: "translateY(100%)" }))),
]);
