import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { SignableDocumentPacketStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/signable-document-packet-status.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { SignableDocumentPacketSummariesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packet-summaries.service";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
   selector: "owa-dashboard-signable-documents",
   templateUrl: "./dashboard-tile-signable-documents.component.html",
})
export class DashboardSignableDocumentsComponent implements OnInit {
   isLoading = new Observable<boolean>();
   signableDocumentPackets: Observable<Array<SignableDocumentPacketSummaryModel>>;
   columns: string[] = ["PacketName", "ExpirationDate", "Status", "DocumentLink"];
   signableDocumentPacketStatus = SignableDocumentPacketStatus;
   private _isLoading = new BehaviorSubject<boolean>(true);
   private _signableDocumentPackets = new BehaviorSubject<Array<SignableDocumentPacketSummaryModel>>(
      new Array<SignableDocumentPacketSummaryModel>()
   );

   constructor(
      private signableDocumentPacketSummariesService: SignableDocumentPacketSummariesService,
      private router: Router
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.signableDocumentPackets = this._signableDocumentPackets.asObservable();
   }

   ngOnInit() {
      this.getData();
   }

   getData() {
      this._isLoading.next(true);
      const filterOptions = new Array<FilterOption>();
      filterOptions.push(
         new FilterOption("Status", FilterOperations.In, [
            SignableDocumentPacketStatus.Published,
            SignableDocumentPacketStatus.PendingAcceptance,
            SignableDocumentPacketStatus.Completed,
         ])
      );

      this.signableDocumentPacketSummariesService
         .getCollection(filterOptions)
         .subscribe((signableDocumentPacketSummaries) => {
            if (signableDocumentPacketSummaries) {
               this._signableDocumentPackets.next(signableDocumentPacketSummaries.slice(0, 5));
               this._isLoading.next(false);
            }
         });
   }

   signDocument(id: number) {
      const route = "/signabledocuments/" + id + "/sign";
      this.router.navigate([route]);
   }

   viewDocument(id: number) {
      const route = "/signabledocuments/" + id + "/view";
      this.router.navigate([route]);
   }
}
