import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { ControlContainer, NgControl, UntypedFormControl } from "@angular/forms";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { KEY_DOWN, KEY_UP } from "keycode-js";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { ChartOfAccountsMappingsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/chart-of-accounts-mappings.service";
import { ChartOfAccountsMappedAccountsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/chart-of-accounts-mapped-accounts.service";
import { BehaviorSubject, fromEvent, Observable, Subject, takeUntil } from "rxjs";

import { FormRegistrationService } from "../../../forms/form-registration/form-registration.service";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportParametersService } from "../report-parameters.service";

/* eslint-disable brace-style */
@Component({
   selector: "lcs-chart-accounts-to-include-selector",
   templateUrl: "chart-accounts-to-include-selector.component.html",
   viewProviders: [ControlContainerViewProvider],
   providers: [ValueSourceService],
})
export class ChartAccountsToIncludeSelectorComponent
   extends ValueAccessorBase<Array<any>>
   implements OnInit, OnDestroy, AfterViewInit, UserInputComponent
{
   @Input() alignOverlayPanelToRight = false;

   @Input() staticOverlay: boolean = false;

   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() showMapping: boolean = true;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() entityValueSourceFilters: Array<FilterOption>;

   @Input() chartMapParameterValue: ReportParameterValueModel;

   @ViewChild("chartAccountsSelectorWrapper", { static: true }) chartAccountsSelectorWrapperElement: ElementRef;

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() panelClose = new EventEmitter<void>();

   glAccountSelectorsConfigured = new BehaviorSubject<boolean>(false);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   glAccountSelectorEndpoint = new BehaviorSubject<string>(null);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   glAccountSelectorEndpointIsSearch = new BehaviorSubject<boolean>(null);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   glAccountSelectorFilters = new BehaviorSubject<Array<FilterOption>>(null);

   isAllSelected: Observable<boolean>;

   fromGlAccountID: Observable<any>;

   toGlAccountID: Observable<any>;

   overlayPanelStaticWidth: number;

   overlayPanelStaticMinWidth: number;

   glAccountMapValueSource: ValueSourceModel;

   items = new Array<SelectorItemModel>();

   isLoading: boolean = true;

   mapSelected: boolean;

   showOverlayPanel: boolean = false;

   control: UntypedFormControl;

   selectedItemCount: number = 0;

   selectedItemCountMessage: string;

   closedIcon = "expand_more";

   openIcon = "expand_less";

   glAccountMapID: number;

   glAccountTypeID: any;

   private unsubscribe = new Subject<void>();

   private valueChanged: boolean;

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isAllSelected = new BehaviorSubject<boolean>(null);

   private _fromGlAccountID = new BehaviorSubject<any>(null);

   private _toGlAccountID = new BehaviorSubject<any>(null);

   constructor(
      private glAccountMapsService: ChartOfAccountsMappingsService,
      private errorMessageService: ErrorMessageService,
      private reportParametersService: ReportParametersService,
      private chartOfAccountsMappedAccountsService: ChartOfAccountsMappedAccountsService,
      private formRegistrationService: FormRegistrationService,
      @Optional() private controlContainer: ControlContainer,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);
      this.isAllSelected = this._isAllSelected.asObservable();
      this.fromGlAccountID = this._fromGlAccountID.asObservable();
      this.toGlAccountID = this._toGlAccountID.asObservable();
      this.registerOnValueWritten((value: Array<any>) => {
         this.innerValue = value;
         this.selectedItemCount = 0;
         this.setSelectedItems();
         this.setSelectedItemCountMessage();
      });
   }

   ngOnInit() {
      this.initializeValueSources();
      return super.ngOnInit();
   }

   ngAfterViewInit() {
      setTimeout(() => {
         if (this.controlContainer && this.name) {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.control = this.controlContainer.control.get(this.name) as UntypedFormControl;
            this.formRegistrationService.addControl(this.name, this.control, this.displayName);
         }
      });
      fromEvent<KeyboardEvent>(this.chartAccountsSelectorWrapperElement.nativeElement, "keydown")
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((event) => {
            if (event.keyCode === KEY_DOWN || event.keyCode === KEY_UP) {
               if (!this.showOverlayPanel) {
                  this.calculateOverlay();
                  this.showOverlayPanel = true;
                  event.preventDefault();
               }
            }
         });
   }

   initializeValueSources() {
      this.configureGLAccountSelectors();
      this.buildMapValueSource();
      this.getGlAccountsToInclude(this.entityValueSourceFilters);
   }

   getGlAccountsToInclude(filters?: FilterOption[]): void {
      this.isLoading = true;
      this.chartOfAccountsMappedAccountsService
         .getChartOfAccountsMappedAccountsCollection(filters, [new OrderingOption("SortOrder")])
         .subscribe({
            next: (results) => {
               this.selectedItemCount = 0;
               this.setItems(results);
            },
            error: (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            },
         });
   }

   configureGLAccountSelectors(filters?: Array<FilterOption>) {
      if (this.mapSelected) {
         this.glAccountSelectorEndpoint.next(
            this.chartOfAccountsMappedAccountsService.getChartOfAccountsMappedAccountsCollectionUrl()
         );
         this.glAccountSelectorEndpointIsSearch.next(false);
      } else {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         this.glAccountSelectorEndpoint.next(null);
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         this.glAccountSelectorEndpointIsSearch.next(null);
      }
      // TODO: determine if we need these - part of RMX-2500
      // valueSource.EagerLoadItems = true;
      // valueSource.OrderingOptions = ["SortOrder"];
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption[] | undefined' is n... Remove this comment to see the full error message
      this.glAccountSelectorFilters.next(filters);
      this.glAccountSelectorsConfigured.next(true);
      this._fromGlAccountID.next("");
      this._toGlAccountID.next("");
   }

   buildMapValueSource() {
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;
      this.glAccountMapsService
         .getCollection()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (results) => {
               if (results) {
                  if (results) {
                     let item = new SelectorItemModel();
                     item.displayValue = "None";
                     item.value = 0;
                     valueSourceModel.StaticValues.push(item);
                     valueSourceModel.SelectedValues.push(item);
                     for (const glAccountMap of results) {
                        item = new SelectorItemModel();
                        item.displayValue = glAccountMap.Name;
                        item.value = glAccountMap.ChartOfAccountsMappingID;
                        valueSourceModel.StaticValues.push(item);
                     }
                     this.glAccountMapValueSource = valueSourceModel;
                     this.glAccountMapID =
                        this.chartMapParameterValue && this.chartMapParameterValue.value
                           ? +this.chartMapParameterValue.value
                           : 0;
                  }
               }
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            }
         );
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      this.formRegistrationService.removeControl(this.control);
   }

   onIconClicked() {
      this.calculateOverlay();
   }

   onResize() {
      this.calculateOverlay();
   }

   lostFocus() {
      this.propagateTouched();
   }

   onVisibilityChanged(show: boolean) {
      this.calculateOverlay();
      this.showOverlayPanel = show;
      if (!show) {
         if (this.valueChanged) {
            this.panelClose.emit();
         }
         this.propagateTouched();
      }
   }

   changeFromAccount(value: any) {
      this._fromGlAccountID.next(value);
      this.selectGlAccountRange();
   }

   changeToAccount(value: any) {
      this._toGlAccountID.next(value);
      this.selectGlAccountRange();
   }

   changeMapSelection(value: number) {
      this.glAccountMapID = value;
      if (value === 0) {
         this.mapSelected = false;
      } else {
         this.mapSelected = true;
      }
      this.selectGLAccountMap();
   }

   changeGroupSelection(item: SelectorItemModel) {
      this.glAccountTypeID = item.value;
      if (item.value) {
         this.selectGLAccountType();
      }
   }

   selectGlAccountRange() {
      let fromItemIndex = null;
      let toItemIndex = null;
      const checkedItems = new Array<SelectorItemModel>();
      if (this._fromGlAccountID.value && this._fromGlAccountID.value !== ConstantsService.NullFK) {
         const fromItem = this.items.filter((i) => i.value === this._fromGlAccountID.value);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
         fromItemIndex = this.items.indexOf(fromItem[0]);
      }
      if (this._toGlAccountID.value && this._toGlAccountID.value !== ConstantsService.NullFK) {
         const toItem = this.items.filter((i) => i.value === this._toGlAccountID.value);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
         toItemIndex = this.items.indexOf(toItem[0]);
      }
      if (fromItemIndex !== null && toItemIndex !== null && fromItemIndex <= toItemIndex) {
         for (let i = 0; i < this.items.length; i++) {
            if (i >= fromItemIndex && i <= toItemIndex) {
               this.items[i].isChecked = true;
               checkedItems.push(this.items[i]);
            } else {
               this.items[i].isChecked = false;
            }
         }
         this.glAccountTypeID = null;
         const selectionChange = new SelectionChangeModel();
         selectionChange.items = this.items;
         selectionChange.checkedItems = checkedItems;
         this.onSelectionChange(selectionChange);
      }
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.valueChanged = true;
      this.selectedItemCount = selectionChange.checkedItems ? selectionChange.checkedItems.length : 0;

      if (this.selectedItemCount === this.items.length) {
         this._isAllSelected.next(true);
         selectionChange.selectAll = true;
         this.value = ["all"];
      } else {
         this._isAllSelected.next(false);
         selectionChange.selectAll = false;
         this.value = selectionChange.checkedItems.map((item) => item.value);
      }

      this.setSelectedItemCountMessage();
      this.selectionChange.emit(selectionChange);
   }

   selectGLAccountMap() {
      const filters = new Array<FilterOption>();
      if (this.mapSelected) {
         filters.push(
            new FilterOption("ChartOfAccountsMappingLinks.ChartOfAccountsMappingID", FilterOperations.EqualTo, [
               this.glAccountMapID,
            ])
         );
         const selectionChange = new SelectionChangeModel();
         selectionChange.checkedItems = this.items;
         selectionChange.items = this.items;
         selectionChange.selectAll = true;
         this.onSelectionChange(selectionChange);
         const group = new SelectorItemModel();
         group.value = "";
         this.changeGroupSelection(group);
      }
      this.entityValueSourceFilters = filters;
      this.getGlAccountsToInclude(filters);
      this.configureGLAccountSelectors(filters);
      this.chartMapParameterValue.value = this.glAccountMapID;
      this.reportParametersService.updateParameterValue.next(this.chartMapParameterValue);
   }

   selectGLAccountType() {
      if (this.selectedItemCount !== this.items.length) {
         const checkedItems = this.items.filter((item) => item.isChecked);
         const matchedItems = this.items.filter(
            (item) => item.additionalData.get("GLAccountType") === this.glAccountTypeID && !item.isChecked
         );
         matchedItems.forEach((mi) => {
            mi.isChecked = true;
            checkedItems.push(mi);
         });
         const selectionChange = new SelectionChangeModel();
         selectionChange.items = this.items;
         selectionChange.checkedItems = checkedItems;
         this.onSelectionChange(selectionChange);
      }
   }

   setItems(results: any) {
      const items = new Array<SelectorItemModel>();
      for (const glAccount of results) {
         const item = new SelectorItemModel();
         item.displayValue = glAccount.Reference + " " + glAccount.Name;
         item.value = glAccount.GLAccountID;
         item.additionalData = new Map<string, number>();
         item.additionalData.set("GLAccountType", glAccount.GLAccountType);
         item.additionalData.set("AssetLiabilityEquity", glAccount.AssetLiabilityEquity);
         items.push(item);
      }
      this.items = items;
      this.isLoading = false;
      this.setSelectedItems();
      this.setSelectedItemCountMessage();
   }

   private setSelectedItems() {
      if (!this.items || this.items.length === 0) {
         return;
      }

      if (this.isSelectedAll()) {
         this.selectAll();
      } else if (this.items && this.items.length > 0 && this.innerValue) {
         let selectedItemCount = 0;
         this.items.forEach((item) => {
            if (this.innerValue.find((iv) => +iv === +item.value) !== undefined) {
               item.isChecked = true;
               selectedItemCount++;
            } else {
               item.isChecked = false;
            }
         });
         this.selectedItemCount = selectedItemCount;
         if (this.selectedItemCount === this.items.length) {
            this._isAllSelected.next(true);
         } else {
            this._isAllSelected.next(false);
         }
      }
   }

   private calculateOverlay() {
      if (this.chartAccountsSelectorWrapperElement) {
         this.overlayPanelStaticWidth = Math.ceil(
            this.chartAccountsSelectorWrapperElement.nativeElement.getBoundingClientRect().width
         );
         this.overlayPanelStaticMinWidth = this.overlayPanelStaticWidth;
      }
   }

   private setSelectedItemCountMessage() {
      if (this.items.length === 0 || this.selectedItemCount === 0) {
         this.selectedItemCountMessage = "";
      } else if (this.selectedItemCount === this.items.length) {
         if (this.items.length > 0) {
            this.selectedItemCountMessage = "All selected";
         }
      } else {
         this.selectedItemCountMessage = `${this.selectedItemCount} Selected`;
      }
   }

   private isSelectedAll() {
      if (this.innerValue && this.innerValue.length > 0 && this.innerValue[0].toString().toLowerCase() === "all") {
         return true;
      }
      return false;
   }

   private selectAll() {
      let selectedItemCount = 0;
      this.items = this.items.map((item: SelectorItemModel) => {
         item.isChecked = true;
         selectedItemCount++;
         return item;
      });
      this._isAllSelected.next(true);
      this.selectedItemCount = selectedItemCount;
   }
}
