import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Observable, Subject, takeUntil } from "rxjs";

import { EntityLinkEntityTypes } from "./entity-link-entity-types.enum";
import { EntityLinkSelectorService } from "./entity-link-selector.service";
import { EntityLinkModel } from "./entity-link.model";

@Component({
   selector: "lcs-entity-link-selector",
   templateUrl: "entity-link-selector.component.html",
   providers: [EntityLinkSelectorService],
})
export class EntityLinkSelectorComponent implements OnInit, OnDestroy {
   @Input() displayHorizontal: boolean;

   @Input() entityTypesToInclude: Array<string>;

   @Input() entityTypeSelectorLabel: string;

   @Input() entitySelectorLabel: string;

   @Input() group: UntypedFormGroup;

   @Input() includeStatusFilters: boolean;

   @Input() isAddHidden: boolean;

   @Output() linkEntitySelected = new EventEmitter<EntityLinkModel>();

   @Output() emitEntityTypeChange = new EventEmitter<void>();

   entityFilterObservable = new Observable<Array<FilterOption>>();

   entityLinkValueComparerObservable = new Observable<any>();

   entityLinkValueSourceObservable = new Observable<ValueSourceModel>();

   entityType: EntityType;

   private unsubscribe = new Subject<void>();

   constructor(private entityLinkSelectorService: EntityLinkSelectorService) {
      this.entityFilterObservable = this.entityLinkSelectorService.entityFilter;
      this.entityLinkValueComparerObservable = this.entityLinkSelectorService.entityLinkValueComparerObservable;
      this.entityLinkValueSourceObservable = this.entityLinkSelectorService.entityLinkValueSource;
   }

   ngOnInit() {
      this.entityLinkSelectorService.entityTypesToInclude = this.entityTypesToInclude;
      this.entityLinkSelectorService.initialize(this.includeStatusFilters, this.group);
      this.formValueChangeListeners();

      this.entityType = this.entityLinkSelectorService.getEntityType().value;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   onLinkEntityClicked() {
      if (this.entityLinkSelectorService.getEntityLinkValue().value !== null) {
         this.emitEntityLinkModel();
      }
   }

   propogateEntityTypeChange() {
      this.emitEntityTypeChange.emit();
   }

   private emitEntityLinkModel() {
      const entityLinkModel = new EntityLinkModel(
         this.entityLinkSelectorService.getEntityType().value,
         this.entityLinkSelectorService.getEntityLinkValue().value
      );
      this.linkEntitySelected.emit(entityLinkModel);
   }

   private formValueChangeListeners() {
      this.entityLinkSelectorService
         .getEntityLinkEntityType()
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe((value: EntityLinkEntityTypes) => {
            const entityLinkValueControl = this.entityLinkSelectorService.getEntityLinkValue();
            if (entityLinkValueControl.value !== null) {
               entityLinkValueControl.setValue(null);
            }
            this.entityLinkSelectorService.updateFilters(value);
            this.entityLinkSelectorService.updateEntityTypeValueComparer(value);

            if (this.isAddHidden) {
               this.emitEntityLinkModel();
            }
         });

      this.entityLinkSelectorService
         .getEntityLinkValue()
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe(() => {
            if (this.isAddHidden) {
               this.emitEntityLinkModel();
            }
         });

      this.entityLinkSelectorService
         .getEntityType()
         .valueChanges.pipe(takeUntil(this.unsubscribe))
         .subscribe((value: EntityType) => {
            this.entityType = value;
            this.propogateEntityTypeChange();
         });
   }
}
