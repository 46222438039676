import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";

import { ServiceIssueListItemModel } from "../../service-issue-list/models/service-issue-list-item.model";

@Component({
   selector: "owa-serviceissue-list",
   templateUrl: "./serviceissue-list.component.html",
})
export class ServiceIssueListComponent {
   serviceIssueColumns: string[] = [
      "ServiceManagerIssueID",
      "OpenDate",
      "Closed",
      "StatusName",
      "Title",
      "Description",
      "PropertyName",
   ];

   @Input() set serviceIssueItem(issue: ServiceManagerIssueModel) {
      this._serviceIssueItem = issue;
      if (issue != null) {
         this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
         this.serviceIssueListItems.push(new ServiceIssueListItemModel(this.serviceIssueItem));
      } else {
         this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
      }
   }
   get serviceIssueItem(): ServiceManagerIssueModel {
      return this._serviceIssueItem;
   }

   @Input() set showClosedIssues(showClosedIssues: boolean) {
      this._showClosedIssues = showClosedIssues;
   }
   get showClosedIssues(): boolean {
      return this._showClosedIssues;
   }

   get showLink(): boolean {
      this._valueString = this._hasCategoriesPermission;
      this._categoryID = this._valueString.split(",").map((categoryID) => {
         return Number(categoryID);
      });
      if (this._valueString == "-1") {
         this._hasCategory = true;
      } else {
         for (var i of this._categoryID) {
            if (i === this._serviceIssueItem.CategoryID) {
               this._hasCategory = true;
               break;
            } else {
               this._hasCategory = false;
            }
         }
      }

      if (this.showClosedIssues && this.showOpenIssues && this._hasCategory) {
         this._showLink = true;
      } else if (this.showClosedIssues && this._serviceIssueItem.IsClosed && this._hasCategory) {
         this._showLink = true;
      } else if (this.showOpenIssues && !this._serviceIssueItem.IsClosed && this._hasCategory) {
         this._showLink = true;
      } else {
         this._showLink = false;
      }
      return this._showLink;
   }

   @Input() set showOpenIssues(showOpenIssues: boolean) {
      this._showOpenIssues = showOpenIssues;
   }
   get showOpenIssues(): boolean {
      return this._showOpenIssues;
   }
   @Input() set hasCategoriesPermission(hasCategoriesPermission: string) {
      this._hasCategoriesPermission = hasCategoriesPermission;
   }
   get hasCategoriesPermission(): string {
      return this._hasCategoriesPermission;
   }
   serviceIssueListItems: ServiceIssueListItemModel[] = new Array<ServiceIssueListItemModel>();

   private _serviceIssueItem: ServiceManagerIssueModel;
   private _showClosedIssues: boolean;
   private _showOpenIssues: boolean;
   private _showLink: boolean;
   private _hasCategoriesPermission: string;
   private _hasCategory: boolean;
   private _categoryID: number[];
   private _valueString: string;
   constructor(private router: Router) {
      this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
      this.serviceIssueListItems.push(new ServiceIssueListItemModel(this.serviceIssueItem));
   }

   showServiceIssueDetails(id: number) {
      if (id > 0) {
         this.router.navigate(["/serviceissues", id]);
      } else {
         return;
      }
   }
}
