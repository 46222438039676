import { ExpressActionEntityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action-entity-event.model";

import { EntityEventResult } from "../entity-events/entity-event-result.enum";

export class ExpressFooterButtonModel {
   set deleteConfirmationOverride(value: string) {
      this._deleteConfirmationOverride = value;
   }

   get deleteConfirmationOverride(): string {
      return this._deleteConfirmationOverride;
   }

   disabled: boolean;

   entityEventResult: EntityEventResult;

   expressActionEntityEvent: ExpressActionEntityEventModel;

   isRaised: boolean = true;

   label: string;

   onClick: Function;

   overridden: boolean;

   hidden: boolean;

   private _deleteConfirmationOverride: string;
}
