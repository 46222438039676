<div class="modal-header-border"></div>
<form class="change-emailaddress-form">
   <div *ngIf="$any(isLoaded | async)">
      <label>Current {{name | titlecase}}</label>
      <div class="emailaddress-radiobutton">{{changeEmailAddressModel.UserName}}</div>
      <div *ngIf="changeEmailAddressModel.UserNameIsVerified ; else notVerified"></div>
      <ng-template #notVerified>
         <div class="bold emailaddress">Your username has not been updated.</div>
         <div *ngIf="changeEmailAddressModel.IsPastConvertByDate">
            <div class="emailaddress-radiobutton">You have untill
               {{changeEmailAddressModel.ConvertByDate |date}}
               to update your
               username to a valid email address</div>
         </div>
         <div *ngIf="displayVerifiedEmailShortcut">
            <div class="emailaddress-radiobutton">
               <input
                  [(ngModel)]="checked"
                  type="radio"
                  name="EmailChoice"
                  checked="checked"
                  [checked]="checked"
                  value="changeEmailAddressModel.EmailAddress"
               >Use {{changeEmailAddressModel.EmailAddress}} <br />
            </div>
            <div class="emailaddress-radiobutton">
               <input
                  [(ngModel)]="checked"
                  type="radio"
                  name="EmailChoice"
                  [checked]="!checked"
               >Use another email address
            </div>
         </div>
      </ng-template>
      <div
         *ngIf="!checked || !displayVerifiedEmailShortcut || !sourceStep"
         class="emailaddress"
      >
         <div class="field-controls">
            <label> New Email Address *</label>
            <lcs-text-box
               [(ngModel)]="changeEmailAddressModel.NewEmailAddress"
               name="New Email Address"
               displayName="Email Address"
               placeholder="someone@company.com"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="field-controls">
            <label>Confirm Email Address *</label>
            <lcs-text-box
               [(ngModel)]="changeEmailAddressModel.ConfirmEmailAddress"
               name="Confirm Email Address"
               displayName="Confirm Email Address"
               placeholder="someone@company.com"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div *ngIf="!sourceStep">
            <label>Confirm Current Password *</label>
            <lcs-text-box
               [(ngModel)]="changeEmailAddressModel.Password"
               inputType="password"
               name="Confirm Email Address"
               displayName="Password"
               [required]="true"
            ></lcs-text-box>
         </div>
      </div>
      <p class="emailaddress">You will be logged out of this session and a verification email will
         be
         sent to the address provided.</p>
      <div class="emailaddress-buttons">
         <div
            class="flex-half dialog-button-container"
            *ngIf="changeEmailAddressModel.WorkflowSteps == workflowStep && changeEmailAddressModel.IsPastConvertByDate"
         >
            <lcs-progress-button
               [raised]="true"
               (buttonClick)="skipclick()"
               [(status)]="progressButtonStatusSkip"
            >
               Skip
            </lcs-progress-button>
         </div>
         <span class="flex-half dialog-button-container">
            <lcs-progress-button
               [raised]="true"
               (buttonClick)="onClick()"
               [(status)]="progressButtonStatus"
               class="button-wrapper field-controls"
            >
               {{displayVerifiedEmailShortcut === true ? 'Update' : 'Send Verification Email'}}
            </lcs-progress-button>
         </span>
      </div>
   </div>
</form>