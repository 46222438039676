import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { LeasePeriod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/lease-period.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ChargeTypeOrderReportParameterModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type-order-report-parameter.model";
import { ChargeTypesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/charge-types.service";
import { debounceTime, Subject, takeUntil } from "rxjs";

import { RelativeDatesService } from "../../../inputs/date-picker/relative-dates.service";
import { SortableListItemModel } from "../../../sortable-list/sortable-list-item.model";
import { DatesService } from "../../../utils/dates.service";
import { isAllValueType } from "../all-value.type";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-charge-type-order-report-parameter",
   templateUrl: "charge-type-order-report-parameter.component.html",
})
export class ChargeTypeOrderReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() parameter: ReportReportParameterViewModel;

   @Input() hasAsk: boolean;

   chargeTypeItems: Array<SortableListItemModel>;

   isLoading = true;

   private reportParameterValueModel;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private chargeTypesService: ChargeTypesService,
      private errorMessageService: ErrorMessageService
   ) {
      this.chargeTypeItems = new Array<SortableListItemModel>();
   }

   ngOnInit(): void {
      this.reportParametersService.reportParameterUpdated
         .pipe(debounceTime(200), takeUntil(this.unsubscribe))
         .subscribe((reportParameter) => {
            if (
               reportParameter.reportParameter === ReportParameter.PropOwnerIDs ||
               reportParameter.reportParameter === ReportParameter.PropertyIDs ||
               reportParameter.reportParameter === ReportParameter.SHOWINACTIVEPROPS ||
               reportParameter.reportParameter === ReportParameter.AsOfDate ||
               reportParameter.reportParameter === ReportParameter.StartDate ||
               reportParameter.reportParameter === ReportParameter.EndDate ||
               reportParameter.reportParameter === ReportParameter.POSTINGPERIOD ||
               reportParameter.reportParameter === ReportParameter.OWNERIDS
            ) {
               const model = new ChargeTypeOrderReportParameterModel();
               model.Report = this.parameter.ReportID;
               model.PropertyIds = new Array<number>();
               model.OwnerIds = new Array<number>();
               model.IsAllPropertySelected = false;
               model.IsRunByOwner = false;

               const asOfDateReportParameter = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.AsOfDate
               );
               const leasePeriodReportParameter = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.POSTINGPERIOD
               );
               const startDateReportParameter = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.StartDate
               );
               const endDateReportParameter = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.EndDate
               );
               const showInactiveProperty = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.SHOWINACTIVEPROPS
               );

               if (showInactiveProperty && showInactiveProperty.value.toString().toLowerCase() === "false") {
                  model.ShowInactiveProperties = false;
               } else {
                  model.ShowInactiveProperties = true;
               }

               let runByOwner = false;
               const runByOwnerReportParameter = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.RUNBYOWNER
               );
               if (runByOwnerReportParameter && runByOwnerReportParameter.value != null) {
                  runByOwner = runByOwnerReportParameter.value.toString().toLowerCase() === "true";
               }
               model.IsRunByOwner = runByOwner;
               const propOwnerIDs = this.reportParametersService.reportParameterValues.get(
                  ReportParameter.PropOwnerIDs
               );
               if (propOwnerIDs?.rawValues) {
                  if (isAllValueType(propOwnerIDs.rawValues.toString().toLowerCase())) {
                     model.IsAllPropertySelected = true;
                  } else {
                     if (runByOwner) {
                        model.OwnerIds = propOwnerIDs.rawValues;
                     } else {
                        model.PropertyIds = propOwnerIDs.rawValues;
                     }
                  }
               }
               model.AsOfDate = asOfDateReportParameter
                  ? this.getDateValue(asOfDateReportParameter)
                  : new Date(DatesService.nullDate);
               model.LeasePeriod = leasePeriodReportParameter ? leasePeriodReportParameter.value : LeasePeriod.NotSet;
               model.StartDate = startDateReportParameter
                  ? this.getDateValue(startDateReportParameter)
                  : formatDate(new Date(), "MM/dd/yyyy", GlobalsService.locale);
               model.EndDate = endDateReportParameter
                  ? this.getDateValue(endDateReportParameter)
                  : formatDate(new Date(), "MM/dd/yyyy", GlobalsService.locale);

               if (!model.StartDate || !model.EndDate) {
                  this.chargeTypeItems = new Array<SortableListItemModel>();
                  this.valueChanged();
               } else {
                  this.chargeTypesService
                     .postChargeTypeFilterByReportParameters(model)
                     .pipe(takeUntil(this.unsubscribe))
                     .subscribe(
                        (chargeTypes) => {
                           if (chargeTypes && chargeTypes.length > 0) {
                              const sortableListItems = new Array<SortableListItemModel>();
                              chargeTypes.forEach((chargeType) => {
                                 sortableListItems.push(
                                    new SortableListItemModel("{Name} - {Description}", chargeType)
                                 );
                              });
                              this.chargeTypeItems = sortableListItems;

                              this.valueChanged();
                           } else {
                              this.chargeTypeItems = new Array<SortableListItemModel>();
                              this.valueChanged();
                           }
                        },
                        (error) => {
                           this.errorMessageService.triggerHttpErrorMessage(error);
                        }
                     );
               }
            }
         });
      this.isLoading = false;
      this.setReportParameterValueModel();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   setReportParameterValueModel() {
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this.parameter.ReportParameterID
      );
   }

   onItemsSorted(items: Array<SortableListItemModel>) {
      this.chargeTypeItems = items;
      this.valueChanged();
   }

   valueChanged() {
      const values = this.chargeTypeItems.map((i) => i.itemObject);
      let value = "";
      if (values.length > 0) {
         value = `(${values.map((f) => f.ChargeTypeID).join(",")})`;
      }
      this.reportParameterValueModel.value = value;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private getDateValue(parameter: ReportParameterValueModel): any {
      if (RelativeDatesService.IsRelativeDateValue(parameter.value)) {
         return RelativeDatesService.getRelativeDate(RelativeDatesService.getEnumValue(parameter.value));
      } else {
         return parameter.value;
      }
   }
}
