import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UserDefinedFieldFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/user-defined-field.fields";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { UserDefinedFieldRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-related-object-types.enum";
import { UserDefinedFieldType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/user-defined-field-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { VendorsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/vendors.service";
import { Subject, take } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
@Component({
   selector: "lcs-vendor-name-udf-name-report-parameter",
   templateUrl: "vendor-name-udf-name-report-parameter.component.html",
   styleUrls: ["vendor-name-udf-name-report-parameter.component.scss"],
})
export class VendorNameAndUDFNameReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }
   vendorNameAndUDFNameReportParameter: ReportReportParameterViewModel | undefined;

   udfNameReportParameter: ReportReportParameterViewModel | undefined;

   vendorNameValue: ReportParameterValueModel | undefined;

   udfNameValue: ReportParameterValueModel | undefined;

   vendorUDFItems = new Array<SelectorItemModel>();

   vendorUDFID: string;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService, private vendorsService: VendorsService) {
      this.initializeVendorUDFs();
   }

   ngOnInit(): void {
      this.initializeParameters();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.vendorNameAndUDFNameReportParameter!.IsAsk = isAsk;
      this.udfNameReportParameter!.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(ReportParameter.VENDORNAMESOURCE, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.VendorUDFName, isAsk);
   }

   onVendorUDFIDChange(udfID: string) {
      this.vendorUDFID = udfID;
      if (this.udfNameValue) {
         this.udfNameValue.value = udfID.toString();
         this.reportParametersService.updateParameterValue.next(this.udfNameValue);
      }
   }

   private initializeParameters() {
      this.vendorNameAndUDFNameReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.VENDORNAMESOURCE
      );

      this.udfNameReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.VendorUDFName
      );

      this.vendorNameValue = this.reportParametersService.reportParameterValues.get(ReportParameter.VENDORNAMESOURCE);
      this.udfNameValue = this.reportParametersService.reportParameterValues.get(ReportParameter.VendorUDFName);

      if (this.udfNameValue) {
         this.vendorUDFID = this.udfNameValue.value;
      }
   }

   private initializeVendorUDFs() {
      const items = new Array<SelectorItemModel>();

      const filters = new Array<FilterOption>();
      filters.push(
         new FilterOption(UserDefinedFieldFields.FieldType, FilterOperations.EqualTo, [+UserDefinedFieldType.Text])
      );
      filters.push(
         new FilterOption(UserDefinedFieldFields.ParentType, FilterOperations.EqualTo, [
            +UserDefinedFieldRelatedObjectTypes.Vendor,
         ])
      );

      this.vendorsService
         .getAllUserDefinedFieldsCollection(filters)
         .pipe(take(1))
         .subscribe((udfs) => {
            if (udfs) {
               udfs.forEach((udf) => {
                  const item = new SelectorItemModel(udf.UserDefinedFieldID, udf.Name);
                  items.push(item);
               });
            }
            this.vendorUDFItems = items;
         });
   }
}
