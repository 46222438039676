import { formatDate } from "@angular/common";
import { GlobalsService } from "@lcs/core/globals.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { DayOfWeek } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/day-of-week.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { Month } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/month.enum";
import { WeeklyCount } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/weekly-count.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { DateParts } from "../inputs/date-picker/date-parts.interface";

export class DatesService {
   static readonly nullDate: string = "0001-01-01T00:00:00";
   static readonly defaultDateFormat: string = "MM/dd/yyyy";
   static readonly defaultShortDateFormat: string = "MM/dd/yy";
   static readonly defaultDateTimeFormat: string = "MM/dd/yyyy hh:mm aa";
   static readonly defaultDateTimeWithSecondsFormat: string = "MM/dd/yyyy hh:mm:ss aa";
   static readonly defaultDateTimeWithSecondsMobileFormat: string = "MM/dd/yyyy hh:mm:ss a";

   /**
    * Convenience method to format date.
    *
    * First converts date arg to Date object or null if invalid.
    * Then formats date using angular's formatDate() method.
    *
    * @param format defaults to DatesService.defaultDateFormat
    * @param useNullDate if true, will return unformatted DatesService.nullDate value otherwise returns empty string.
    *    Defaults to false.  Setting to true is useful when posting a null date to a non-nullable date field on a Model.
    */
   static formatDate(
      date: string | number | Date | null | undefined,
      format: string = DatesService.defaultDateFormat,
      locale: string = GlobalsService.locale,
      useNullDate: boolean = false
   ): string {
      const normDate: Date | null = DatesService.convertToDateOrNull(date);
      if (normDate === null) {
         return useNullDate ? DatesService.nullDate : "";
      }
      return formatDate(normDate, format, locale);
   }

   /**
    * Convenience wrapper method for DatesService.formatDate(...)
    * where format defaults to DatesService.defaultShortDateFormat
    */
   static formatShortDate(
      date: string | number | Date | null | undefined,
      format: string = DatesService.defaultShortDateFormat,
      locale: string = GlobalsService.locale,
      useNullDate: boolean = false
   ): string {
      return DatesService.formatDate(date, format, locale, useNullDate);
   }

   /**
    * Convenience wrapper method for DatesService.formatDate(...)
    * where format defaults to DatesService.defaultDateTimeFormat
    */
   static formatDateTime(
      date: string | number | Date | null | undefined,
      format: string = DatesService.defaultDateTimeFormat,
      locale: string = GlobalsService.locale,
      useNullDate: boolean = false
   ): string {
      return DatesService.formatDate(date, format, locale, useNullDate);
   }

   /**
    * Convenience wrapper method for DatesService.formatDate(...)
    * where format defaults to DatesService.defaultDateTimeWithSecondsFormat
    */
   static formatDateTimeWithSeconds(
      date: string | number | Date | null | undefined,
      format: string = DatesService.defaultDateTimeWithSecondsFormat,
      locale: string = GlobalsService.locale,
      useNullDate: boolean = false
   ): string {
      return DatesService.formatDate(date, format, locale, useNullDate);
   }

   /**
    * Returns true if leftDate and rightDate are both valid Date objects
    * and dateTimes are the same or if ignoreTime is true then returns
    * true if the Date part of the DateTimes are the same.
    */
   static isSameDate(
      leftDate: Date | null | undefined,
      rightDate: Date | null | undefined,
      ignoreTime: boolean = false
   ): boolean {
      if (this.isValidDateObj(leftDate) && this.isValidDateObj(rightDate)) {
         if (ignoreTime) {
            // @ts-ignore - Argument of type 'Date | null | undefined' is not assignable to parameter of type 'string | number | Date'.
            const a = new Date(leftDate);
            // @ts-ignore - Argument of type 'Date | null | undefined' is not assignable to parameter of type 'string | number | Date'.
            const b = new Date(rightDate);
            a.setHours(0, 0, 0, 0);
            b.setHours(0, 0, 0, 0);
            return a.getTime() === b.getTime();
         }
         // @ts-ignore - Object is possibly 'null' or 'undefined'.
         return leftDate.getTime() === rightDate.getTime();
      }
      return false;
   }

   /**
    * Returns true only if date is a valid instance of a Date object
    * otherwise returns false
    */
   static isValidDateObj(date: Date | null | undefined): boolean {
      if (date == null || !(date instanceof Date) || isNaN(date.getTime())) {
         return false;
      }
      return true;
   }

   static getDate(date?: Date) {
      if (!date) {
         date = new Date();
      }
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
   }

   static convertStringToDate(date: Date | string): Date {
      if (!date || <any>date === DatesService.nullDate) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
         return null;
      }
      return new Date(date as string);
   }

   static convertToDateOrNull(date: string | number | Date | null | undefined): Date | null {
      if (date == null || date === "" || (typeof date === "string" && date === DatesService.nullDate)) {
         // null, undefined, empty string, or LCS API nullDate string
         return null;
      }
      const dt = new Date(date);
      return isNaN(dt.getTime()) ? null : dt;
   }

   static convertToDateOrNullDateStringAsDate(date: string | number | Date | null | undefined): Date {
      if (date == null || date === "" || (typeof date === "string" && date === DatesService.nullDate)) {
         // null, undefined, empty string, or LCS API nullDate string
         return DatesService.nullDateStringToDate();
      }
      const dt = new Date(date);
      return isNaN(dt.getTime()) ? DatesService.nullDateStringToDate() : dt;
   }

   /**
    * Coerces LCS nullDate string as a Date type.
    *
    * Use if you need to post a null date for a field on a Model that is non-nullable
    */
   static nullDateStringToDate(): Date {
      return DatesService.nullDate as unknown as Date;
   }

   static fixLeapDayDate(year: number, month: number, day: number) {
      // Month here is 0-indexed (January is 0, February is 1, etc)
      if (month === 1 && day === 29) {
         if (new Date(year, 1, 29).getDate() === 29) {
            return new Date(year, month, day);
         } else {
            return new Date(year, month, 28);
         }
      } else {
         return new Date(year, month, day);
      }
   }

   /**
    * Returns days in month given oneIndexedMonth [1-12], and year
    *
    * Example: daysInMonth(2,2000) = 29
    * Example: daysInMonth(2,2001) = 28
    */
   static daysInMonth(oneIndexedMonth: number, year: number) {
      // Month here is 1-indexed (January is 1, February is 2, etc)
      // This works because setting day=0 returns last day of previous month
      return new Date(year, oneIndexedMonth, 0).getDate();
   }

   public static getQuarterFromMonth(month: number) {
      const quarters = [1, 2, 3, 4];
      return quarters[Math.floor(month / 3)];
   }

   public static getBeginningOfQuarter(quarter: number, year: number) {
      // Account for 0-indexed months by subtracting 1 from the quarter.
      return new Date(year, (quarter - 1) * 3, 1);
   }

   public static getDateWithSuffix(date: String): String {
      // Takes in a number day as a string, return that date with the proper numerical suffix
      let correct = date;
      if (date === "1") {
         correct += "st";
      } else if (date === "2") {
         correct += "nd";
      } else if (date === "3") {
         correct += "rd";
      } else {
         correct += "th";
      }
      return correct;
   }

   static getDateParts(value: string): DateParts {
      value = value.trim().replace(/[^\/\d]/g, "");
      let month = "";
      let day = "";
      let year = "";
      let split = [];
      if (/^[0-9]{0,2}\/[0-9]{0,2}\/[0-9]{2,4}$/.test(value)) {
         // mm/dd/yy or m/d/y or /dd/yy or mm//yy
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
         split = value.split("/");
         // @ts-ignore ts-migrate(2339) FIXME: Property 'substr' does not exist on type 'never'.
         month = split[0].substr(0, 2);
         // @ts-ignore ts-migrate(2339) FIXME: Property 'substr' does not exist on type 'never'.
         day = split[1].substr(0, 2);
         // @ts-ignore ts-migrate(2339) FIXME: Property 'substr' does not exist on type 'never'.
         year = split[2].substr(0, 4);
      } else if (/^[0-9]{1,2}\/[0-9]{0,2}/.test(value)) {
         // mm/dd or m/d or m/ or mm/ or mm/dd/y or mm/ddy
         // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
         split = value.split("/");
         // @ts-ignore ts-migrate(2339) FIXME: Property 'substr' does not exist on type 'never'.
         month = split[0].substr(0, 2);
         day = split[1];
         if (split[2]) {
            // mm/dd/y
            year = split[2];
         } else if (day.length > 2) {
            // mm/ddy
            year = day.substr(2);
            day = day.substr(0, 2);
         }
      } else if (value.length > 4) {
         // mmddy[y]
         month = value.substr(0, 2);
         day = value.substr(2, 2);
         year = value.substr(4);
      } else if (value.length > 2) {
         // mmd[d]
         month = value.substr(0, 2);
         day = value.substr(2);
         year = "";
      } else {
         month = value;
         day = "";
         year = "";
      }

      if (value !== "") {
         // whatever date part is missing, trying using Today's date's part
         // Especially, handle when date format is mm/dd
         const backupDate = new Date();
         if (month === "") {
            month = (backupDate.getMonth() + 1).toString();
         }
         if (day === "") {
            day = backupDate.getDate().toString();
         }
         if (year === "") {
            year = backupDate.getFullYear().toString();
         }
      }

      if (month.length === 1) {
         month = "0" + month;
      }
      if (day.length === 1) {
         day = "0" + day;
      }

      let yearNumber = +year;
      if (yearNumber < 100) {
         // this may seem arbitrary, but it matches the WPF client
         if (yearNumber > 70) {
            // >70 => 1970
            yearNumber += 1900;
         } else {
            // <70 => 2069
            yearNumber += 2000;
         }
      }
      year = yearNumber.toString();

      let masked = month + "/" + day + "/" + year;

      if (!+month || !+day || !+year || +month > 12 || +day > 31 || +year > 9999) {
         month = "";
         day = "";
         year = "";
         masked = "";
      }

      return {
         month: month,
         day: day,
         year: year,
         masked: masked,
      };
   }

   static getDateFilter(filterName: string, firstDate: Date, secondDate: Date): FilterOption {
      let values = new Array<Date>();
      let operation: FilterOperations;
      if (firstDate) {
         DatesService.setTimeToBeginningOfDay(firstDate);
      }
      if (secondDate) {
         DatesService.setTimeToEndOfDay(secondDate);
      }

      if (!DatesService.rm12NullDate(firstDate) && !DatesService.rm12NullDate(secondDate)) {
         values.push(firstDate);
         values.push(secondDate);
         operation = FilterOperations.Between;
      } else if (!DatesService.rm12NullDate(firstDate)) {
         values.push(firstDate);
         operation = FilterOperations.GreaterThanOrEqualTo;
      } else if (!DatesService.rm12NullDate(secondDate)) {
         values.push(secondDate);
         operation = FilterOperations.LessThanOrEqualTo;
      }
      if (!values.length) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date[]'.
         values = null;
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'operation' is used before being assigned... Remove this comment to see the full error message
      return new FilterOption(filterName, operation, values, null, null, null, ExpressDataTypes.DateTime);
   }

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Month'.
   static dayOfMonthValueSource(withLast: boolean = false, month: Month = null): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.StaticValues = this.getDaysInMonth(month);
      if (withLast) {
         valueSource.StaticValues = valueSource.StaticValues.concat(new SelectorItemModel(-1, "last"));
      }
      return valueSource;
   }

   static setTimeToBeginningOfDay(date: Date) {
      date.setHours(0, 0, 0, 0);
   }

   static setTimeToEndOfDay(date: Date) {
      date.setHours(23, 59, 59, 999);
   }

   /**
    * Returns an array of SelectorItemModels from 1 to Max number of days in the Month
    * based on Month enum.
    */
   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Month'.
   private static getDaysInMonth(month: Month = null): SelectorItemModel[] {
      let daysInMonth: number = 31;
      switch (month) {
         case Month.April:
         case Month.June:
         case Month.September:
         case Month.November:
            daysInMonth = 30;
            break;
         case Month.February:
            daysInMonth = 29;
            break;
         default:
            daysInMonth = 31;
            break;
      }
      return Array.from({ length: daysInMonth }, (_, index) => {
         const value = index + 1;
         return new SelectorItemModel(value, value.toString());
      });
   }

   static dayOfWeekValueSource(): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.StaticValues = [
         new SelectorItemModel(DayOfWeek.Sunday, "Sunday"),
         new SelectorItemModel(DayOfWeek.Monday, "Monday"),
         new SelectorItemModel(DayOfWeek.Tuesday, "Tuesday"),
         new SelectorItemModel(DayOfWeek.Wednesday, "Wednesday"),
         new SelectorItemModel(DayOfWeek.Thursday, "Thursday"),
         new SelectorItemModel(DayOfWeek.Friday, "Friday"),
         new SelectorItemModel(DayOfWeek.Saturday, "Saturday"),
      ];
      return valueSource;
   }

   static daysOfWeekValueSource(): ValueSourceModel {
      const valueSource = this.dayOfWeekValueSource();
      valueSource.AllowsMultipleValues = true;
      return valueSource;
   }

   static weekOfMonthValueSource(): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.StaticValues = [
         new SelectorItemModel(WeeklyCount.first, "first"),
         new SelectorItemModel(WeeklyCount.second, "second"),
         new SelectorItemModel(WeeklyCount.third, "third"),
         new SelectorItemModel(WeeklyCount.fourth, "fourth"),
         new SelectorItemModel(WeeklyCount.last, "last"),
      ];
      return valueSource;
   }

   static monthOfYearValueSource(): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.StaticValues = [
         new SelectorItemModel(Month.January, "January"),
         new SelectorItemModel(Month.February, "February"),
         new SelectorItemModel(Month.March, "March"),
         new SelectorItemModel(Month.April, "April"),
         new SelectorItemModel(Month.May, "May"),
         new SelectorItemModel(Month.June, "June"),
         new SelectorItemModel(Month.July, "July"),
         new SelectorItemModel(Month.August, "August"),
         new SelectorItemModel(Month.September, "September"),
         new SelectorItemModel(Month.October, "October"),
         new SelectorItemModel(Month.November, "November"),
         new SelectorItemModel(Month.December, "December"),
      ];
      return valueSource;
   }
   static addDays(date: Date, days: number): Date {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
   }

   static addMonths(date: Date, months: number): Date {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + months);
      return newDate;
   }

   static addYears(date: Date, years: number): Date {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() + years);
      return newDate;
   }

   static addHours(date: Date, hours: number): Date {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + hours);
      return newDate;
   }

   static isDate(value: any) {
      if (!(value && value instanceof Date)) {
         return false;
      }
      return true;
   }

   static rm12NullDate(date: Date): boolean {
      return !(date && date.getFullYear() > 1000);
   }

   static getDayOfWeek(date: Date): string {
      const day = date.getDay();
      switch (day) {
         case DayOfWeek.Sunday:
            return "Sunday";
         case DayOfWeek.Monday:
            return "Monday";
         case DayOfWeek.Tuesday:
            return "Tuesday";
         case DayOfWeek.Wednesday:
            return "Wednesday";
         case DayOfWeek.Thursday:
            return "Thursday";
         case DayOfWeek.Friday:
            return "Friday";
         case DayOfWeek.Saturday:
            return "Saturday";
      }
      return "";
   }

   static getMonthName(date: Date, showShortName = false): string {
      const month = date.getMonth();
      switch (month) {
         case 0:
            return showShortName ? "Jan" : "January";
         case 1:
            return showShortName ? "Feb" : "February";
         case 2:
            return showShortName ? "Mar" : "March";
         case 3:
            return showShortName ? "Apr" : "April";
         case 4:
            return showShortName ? "May" : "May";
         case 5:
            return showShortName ? "Jun" : "June";
         case 6:
            return showShortName ? "Jul" : "July";
         case 7:
            return showShortName ? "Aug" : "August";
         case 8:
            return showShortName ? "Sep" : "September";
         case 9:
            return showShortName ? "Oct" : "October";
         case 10:
            return showShortName ? "Nov" : "November";
         case 11:
            return showShortName ? "Dec" : "December";
      }
      return "";
   }

   static daysBetween(firstDate: any, secondDate: any): number {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      return Math.round(Math.abs((firstDate - secondDate) / oneDay));
   }

   static daysElapsed(firstDate: any, secondDate: any): number {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      return Math.round((firstDate - secondDate) / oneDay) + 1;
   }

   static isInDayLightSavings(date) {
      const jan = new Date(date.getFullYear(), 0, 1);
      const jul = new Date(date.getFullYear(), 6, 1);
      const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

      return date.getTimezoneOffset() < stdTimezoneOffset;
   }

   static isLastDayOfMonth(date: Date): boolean {
      return DatesService.daysInMonth(date.getMonth() + 1, date.getFullYear()) === date.getDate();
   }

   static convertDateToUTC(date) {
      const utcDate = new Date(
         date.getUTCFullYear(),
         date.getUTCMonth(),
         date.getUTCDate(),
         date.getUTCHours(),
         date.getUTCMinutes(),
         date.getUTCSeconds()
      );

      return utcDate;
   }

   static getStartOfWeek(date: Date): Date {
      return DatesService.addDays(date, -date.getDay());
   }

   static getEndOfWeek(date: Date): Date {
      return DatesService.addDays(DatesService.getStartOfWeek(date), 6);
   }

   static getDurationInMinutes(timeString: string): number {
      const period: string | undefined = timeString.match(/(AM|PM)+\b/i)?.at(0);
      const timeComponents: Array<number> = GlobalsService.getAllNumerics(timeString)?.map((c) => +c) ?? [];
      let days: number = 0;
      let hours: number = 0;
      let minutes: number = 0;
      if (timeComponents.length === 3) {
         [days, hours, minutes] = timeComponents;
      } else if (timeComponents.length === 2) {
         [hours, minutes] = timeComponents;
      } else if (timeComponents.length === 1) {
         minutes = timeComponents[0];
      }
      hours = this.convertTo24HourPeriod(hours, period);
      return (days * 24 + hours) * 60 + minutes;
   }

   static convertTo24HourPeriod(hours: number, period: string | undefined): number {
      if (period && period !== "") {
         if (period.toLowerCase() === "pm" && hours < 12) {
            hours = hours + 12;
         } else if (period.toLowerCase() === "am" && hours === 12) {
            hours = hours - 12;
         }
      }
      return hours;
   }
}
