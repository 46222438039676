<input
   #inputEl
   [attr.id]="path"
   type="text"
   maxlength="21"
   [(ngModel)]="value"
   [ngModelOptions]="{ standalone: true }"
   [disabled]="disabled"
   (blur)="onBlur()"
/>
<i class="material-icons validation-error-icon">error_outline</i>
<lcs-validation-overlay-panel
   [parentElement]="inputEl"
   [displayName]="displayName"
   [name]="name"
></lcs-validation-overlay-panel>