import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { DateRangeModel } from "../../../inputs/date-range-picker/date-range.model";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterComponents } from "../report-parameter-components.enum";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-date-range-report-parameter",
   templateUrl: "date-range-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
})
export class DateRangeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() set reportReportParameters(values: Array<ReportReportParameterViewModel>) {
      this._reportReportParameters = values;
   }

   @Input() dateRangeReportParameterType: ReportParameterComponents = ReportParameterComponents.DateRange;

   @Input() hasAsk: boolean;

   @Input() set label(value: string) {
      if (value) {
         this._label = value;
         this._isProvidedLabel = true;
      } else {
         this._label = "Date Range";
      }
   }

   get label(): string {
      return this._label;
   }

   dateRange: DateRangeModel;

   startDateParameter: ReportReportParameterViewModel;

   startDateValueModel: ReportParameterValueModel;

   endDateParameter: ReportReportParameterViewModel;

   endDateValueModel: ReportParameterValueModel;

   dateStartFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   dateEndFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   private _label: string;

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private _isProvidedLabel: boolean = false;

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   initializeParameters() {
      if (this.dateRangeReportParameterType === ReportParameterComponents.DateRange2) {
         this.startDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartDate2
         )[0];
         this.endDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndDate2
         )[0];
         if (!this._isProvidedLabel) {
            this.label = "Second Date Range";
         }
      } else {
         this.startDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartDate
         )[0];
         this.endDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndDate
         )[0];
      }
      if (
         this.startDateParameter.ReportParameterValueSource &&
         this.startDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateStartFormat = this.startDateParameter.ReportParameterValueSource.FormatString;
      }
      if (
         this.endDateParameter.ReportParameterValueSource &&
         this.endDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateEndFormat = this.endDateParameter.ReportParameterValueSource.FormatString;
      }
   }

   valueChange(event: DateRangeModel) {
      this.dateRange = event;
      if (event) {
         let startDateValue: string;
         if (event.startDate && !isNaN(event.startDate.getTime())) {
            startDateValue = formatDate(event.startDate, "MM/dd/yyyy H:mm:ss", GlobalsService.locale);
         }
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'startDateValue' is used before being ass... Remove this comment to see the full error message
         if (this.startDateValueModel.value !== startDateValue) {
            // @ts-ignore ts-migrate(2454) FIXME: Variable 'startDateValue' is used before being ass... Remove this comment to see the full error message
            this.startDateValueModel.value = startDateValue;
            this.reportParametersService.updateParameterValue.next(this.startDateValueModel);
         }

         let endDateValue: string;
         if (event.endDate && !isNaN(event.endDate.getTime())) {
            event.endDate.setHours(23, 59, 59);
            endDateValue = formatDate(event.endDate, "MM/dd/yyyy H:mm:ss", GlobalsService.locale);
         }
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'endDateValue' is used before being assig... Remove this comment to see the full error message
         if (this.endDateValueModel.value !== endDateValue) {
            // @ts-ignore ts-migrate(2454) FIXME: Variable 'endDateValue' is used before being assig... Remove this comment to see the full error message
            this.endDateValueModel.value = endDateValue;
            this.reportParametersService.updateParameterValue.next(this.endDateValueModel);
         }
      }
   }

   private initializeReportParameterValues() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.startDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.startDateParameter.ReportParameterID
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.endDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.endDateParameter.ReportParameterID
      );
      this.updateReportParameterStatus(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameterValueModel | unde... Remove this comment to see the full error message
         this.reportParametersService.reportParameterValues.get(ReportParameter.BEFOREPOSTING)
      );

      if (!this.isRelative) {
         this.dateRange = new DateRangeModel();
         this.dateRange.startDate = new Date(this.startDateValueModel.value);
         this.dateRange.endDate = new Date(this.endDateValueModel.value);
         this.valueChange(this.dateRange);
      }

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameterStatus(updatedValue);
         });
   }

   private updateReportParameterStatus(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.startDateParameter.ReportID,
         updatedValue,
         this.startDateValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
