import { Component, OnInit } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { ConstantsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/constants.service";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";

@Component({
   selector: "owa-estimates-dashboard",
   templateUrl: "./estimates-dashboard.component.html",
   styleUrls: ["./estimates-dashboard.component.css"],
   providers: [EstimateService],
})
export class EstimatesDashboardComponent implements OnInit {
   estimateList: Array<EstimateViewModel>;
   message: string;
   hasEstimatesToView: boolean = false;
   canApprove: boolean = false;
   url: string;
   showOpenEstimatesOWAPreference: boolean;
   showClosedEstimatesOWAPreference: boolean;

   private unsubscribe = new Subject<void>();

   constructor(
      private estimatesService: EstimateService,
      private errorMessageService: ErrorMessageService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private constantService: ConstantsService
   ) {}

   ngOnInit() {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               const pref = prefs.find((a) => a.Name === "EnableApprovalRejectionEstimatesOWA");
               // @ts-ignore ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
               this.canApprove = pref && pref.Value === "True";

               this.showOpenEstimatesOWAPreference =
                  prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA").Value === "True"
                        ? true
                        : false
                     : false;
            }
         }
      );
      if (this.canApprove && this.showOpenEstimatesOWAPreference) {
         this.retrieveEstimates();
      }
   }

   retrieveEstimates() {
      this.estimatesService.getEstimatesToApprove().subscribe(
         (estimates) => {
            if (estimates) {
               this.estimateList = estimates;
               this.hasEstimatesToView = this.estimateList.length > 0;
               if (this.hasEstimatesToView) {
                  this.parseEstimates();
               }
            }
         },
         (_err) => {
            this.errorMessageService.triggerHttpErrorMessage(_err);
         }
      );
   }

   parseEstimates() {
      if (this.estimateList.length > 1) {
         this.message = this.constantService.EstimateBannerMultipleApprovalText;
         this.url = "/estimates";
      } else {
         this.message = this.constantService.EstimateBannerSingleApprovalText;
         this.url = "/estimates/" + this.estimateList[0].EstimateID;
      }
   }

   getURL() {
      return [this.url];
   }
}
