import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { WebChatOptions } from "projects/libraries/owa-gateway-sdk/src/lib/models/web-chat-options.model";
import { WebChatService } from "projects/libraries/owa-gateway-sdk/src/lib/services/web-chat.service";
import { map, Observable, Subject, Subscription, takeUntil } from "rxjs";

declare var jquery: any;
declare var $: any;

@Component({
   selector: "owa-web-chat",
   templateUrl: "./web-chat.component.html",
   styleUrls: ["./web-chat.component.css"],
})
export class WebChatComponent implements OnInit, OnDestroy {
   public webChatOptions = new WebChatOptions();
   public routeSubscription: Subscription;
   private unsubscribe = new Subject<void>();
   constructor(private webChatService: WebChatService, private router: Router) {}

   ngOnInit() {
      this.getWebChatOptions()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((res) => {
            this.webChatOptions = res;
            this.configureWebChat();
         });

      this.routeSubscription = this.router.events.subscribe((e) => {
         if (e instanceof NavigationEnd && e.url !== "/login") {
            this.configureWebChat();
         }
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
      this.routeSubscription.unsubscribe();
      this.unsubscribe.complete();
   }

   configureWebChat() {
      if (this.webChatOptions.IsWebChatEnabled) {
         $("#chatContent").empty();
         const len = $("script[src='" + this.webChatOptions.WebChatScriptURL + "']").length;
         if (len === 0) {
            const cdnScript = document.createElement("script");
            cdnScript.src = this.webChatOptions.WebChatScriptURL;
            cdnScript.onload = () => {
               const cdnStyle = document.createElement("link");
               cdnStyle.href = this.webChatOptions.WebChatStylingURL;
               cdnStyle.rel = "stylesheet";
               cdnStyle.type = "text/css";
               cdnStyle.onload = () => {
                  this.initializeWebChat();
               };
               document.head.append(cdnStyle);
            };
            document.head.append(cdnScript);
         } else {
            this.initializeWebChat();
         }
      }
   }

   getWebChatOptions(): Observable<WebChatOptions> {
      return this.webChatService.getWebChatOptions().pipe(map((response) => response));
   }

   initializeWebChat() {
      const options = this.webChatOptions;
      $("#chatContent").WebBasedChatWizard("initialize", options);
   }
}
