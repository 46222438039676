import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { LoadingOverlayConfigurationModel } from "@lcs/loading-overlay/loading-overlay-configuration.model";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";

export interface SelectedItemMouseEvent {
   mouseEvent: MouseEvent;
   item: SelectorItemModel;
}

@Component({
   selector: "lcs-overlay-panel-section",
   templateUrl: "./overlay-panel-section.component.html",
   styleUrls: ["./overlay-panel-section.component.scss"],
})
export class OverlayPanelSectionComponent implements OnInit {
   @Input() set itemSet(value: SelectorItemSetModel) {
      if (value) {
         value.items.forEach(() => {
            this.showItemTooltip.push(false);
         });
         this._itemSet = value;
      }
   }
   get itemSet(): SelectorItemSetModel {
      return this._itemSet;
   }

   @Input() itemSetIsLoading: boolean = false;

   @Input() errorMessage: string;

   @Input() additionalMessage: string;

   @Input() selectorOptionTemplate: TemplateRef<any>;

   @Input() selectable = true;

   get selectedItemIndex(): number {
      return this._selectedItemIndex;
   }

   showItemTooltip: Array<boolean> = [];

   private _itemSet: SelectorItemSetModel;

   @Input() set selectedItemIndex(val: number) {
      if (
         val != null &&
         this.itemSet &&
         this.itemSet.items &&
         this._selectedItemIndex !== val &&
         val >= 0 &&
         val < this.itemSet.items.length
      ) {
         this._selectedItemIndex = val;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
         this._selectedItemIndex = null;
      }
   }

   @Output() selected = new EventEmitter<SelectorItemModel>(true);

   @Output() selectedItemIndexChange = new EventEmitter<number>();

   @Output() selectedItemEmitsEvent = new EventEmitter<SelectedItemMouseEvent>();

   @Output() selectedItemHoverIn = new EventEmitter<SelectedItemMouseEvent>();

   @Output() selectedItemHoverOut = new EventEmitter<SelectedItemMouseEvent>();

   @ViewChild("commandLaunchItemElementRef") commandLaunchItemElementRef: HTMLElement;

   public loadingOverlayConfiguration: LoadingOverlayConfigurationModel;

   private _selectedItemIndex: number;

   constructor() {}

   ngOnInit(): void {
      this.loadingOverlayConfiguration = new LoadingOverlayConfigurationModel();
      this.loadingOverlayConfiguration.HasOverlay = false;
      this.loadingOverlayConfiguration.Diameter = 30;
   }

   onItemSelected(mouseEvent: MouseEvent, item: SelectorItemModel): void {
      if (this.selectable) {
         this.selectedItemEmitsEvent.emit({ mouseEvent: mouseEvent, item: item });
         this.selected.emit(item);
      }
      mouseEvent.stopPropagation();
      mouseEvent.preventDefault();
   }

   onItemHoverIn(mouseEvent: MouseEvent, item: SelectorItemModel, index: number): void {
      if (this.selectable) {
         this.showItemTooltip[index] = true;
         this.selectedItemHoverIn.emit({
            mouseEvent: mouseEvent,
            item: item,
         });
      }
      mouseEvent.stopPropagation();
      mouseEvent.preventDefault();
   }

   onItemHoverOut(mouseEvent: MouseEvent, item: SelectorItemModel, index: number): void {
      if (this.selectable) {
         // Suspend setting the value to false right away so user has time to move mouse into tooltip
         setTimeout(() => {
            this.showItemTooltip[index] = false;
            this.selectedItemHoverOut.emit({ mouseEvent: mouseEvent, item: item });
         }, 100);
      }
      mouseEvent.stopPropagation();
      mouseEvent.preventDefault();
   }
}
