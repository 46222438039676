import { Injectable, OnDestroy } from "@angular/core";
import { DialogRegistrationService } from "@lcs/dialog/services/dialog-registration.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { OwnerModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { BehaviorSubject, forkJoin, Observable, Subject, takeUntil } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class UpdateAccountInformationService implements OnDestroy {
   public results = new Subject<Response>();
   public refresh = new BehaviorSubject<boolean>(false);
   private unsubscribe = new Subject<void>();
   private ownerEndpoint = "owners/OwnerPersonalInformation";
   private ownerProspectEndPoint = "ownerprospects/OwnerProspectPersonalInformation";

   constructor(private apiService: ApiService, private dialogRegistrationService: DialogRegistrationService) {}

   getOwnerInformation(): Observable<[OwnerModel, AddressTypeModel[], PhoneNumberTypeModel[]]> {
      return forkJoin(
         this.apiService.getSingle(this.apiService.getUrl(this.ownerEndpoint)),
         this.apiService.getCollection("Owners/AddressTypes") as Observable<AddressTypeModel[]>,
         this.apiService.getCollection("Owners/PhoneNumberTypes") as Observable<PhoneNumberTypeModel[]>
      );
   }

   saveOwnerInformation(ownerModel: OwnerModel) {
      this.refresh.next(false);
      this.apiService
         .post("owners/SaveOwnerPersonalInformation", ownerModel)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               if (success) {
                  this.dialogRegistrationService.closeAllModals();
               }
            },
            (_err) => {
               this.results.next(_err);
            }
         );
   }

   getOwnerProspectInformation(): Observable<[OwnerProspectModel, AddressTypeModel[], PhoneNumberTypeModel[]]> {
      return forkJoin(
         this.apiService.getSingle(this.apiService.getUrl(this.ownerProspectEndPoint)),
         this.apiService.getCollection("OwnerProspects/AddressTypes") as Observable<AddressTypeModel[]>,
         this.apiService.getCollection("OwnerProspects/PhoneNumberTypes") as Observable<PhoneNumberTypeModel[]>
      );
   }

   saveOwnerProspectInformation(ownerProspectModel: OwnerProspectModel) {
      this.refresh.next(false);
      this.apiService
         .post("OwnerProspects/SaveOwnerProspectPersonalInformation", ownerProspectModel)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               if (success) {
                  this.refresh.next(true);
                  this.dialogRegistrationService.closeAllModals();
               }
            },
            (_err) => {
               this.results.next(_err);
            }
         );
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
