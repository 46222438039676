import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";

import { EntityEventResult } from "./entity-event-result.enum";

export class EntityEventResultModel {
   entityEventResult: EntityEventResult = EntityEventResult.Unset;

   expressEntityEvent: ExpressEntityEvents = ExpressEntityEvents.Unset;

   triggerAction: ExpressActions = ExpressActions.NotSet;

   triggerActionPayloads: Array<ActionTriggerPayloadModel>;

   public constructor(
      expressEntityEvent: ExpressEntityEvents | null,
      entityEventResult: EntityEventResult,
      triggerAction?: ExpressActions,
      triggerActionPayloads?: Array<ActionTriggerPayloadModel>
   ) {
      if (expressEntityEvent != null) {
         this.expressEntityEvent = expressEntityEvent;
      }
      if (entityEventResult != null) {
         this.entityEventResult = entityEventResult;
      }
      if (triggerAction != null) {
         this.triggerAction = triggerAction;
      }
      if (triggerActionPayloads != null) {
         this.triggerActionPayloads = triggerActionPayloads;
      }
   }
}
