import { Injectable } from "@angular/core";
import { ExpressActionEntityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action-entity-event.model";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ExpressFooterButtonModel } from "./express-footer-button.model";
import { FooterButtonRaisedPipe } from "./footer-button-raised.pipe";

export function convertActionEntityEventsToFooterButtons(
   actionEntityEvents: ExpressActionEntityEventModel[]
): ExpressFooterButtonModel[] {
   const footerButtons = actionEntityEvents.map((actionEntityEvent: ExpressActionEntityEventModel) => {
      const expressFooterButton = new ExpressFooterButtonModel();
      expressFooterButton.label = actionEntityEvent.Label;
      expressFooterButton.expressActionEntityEvent = actionEntityEvent;
      expressFooterButton.isRaised = FooterButtonRaisedPipe.isFooterButtonRaised(expressFooterButton);
      return expressFooterButton;
   });

   return footerButtons;
}

@Injectable()
export class FooterService {
   siteFooterVisibilitySubject: BehaviorSubject<boolean>;

   /**
    * Enables/Disables save entity event buttons for all current and future footers.
    *
    * @deprecated use the CurrentFooterService > disableSave instead.
    */
   disableSave = new BehaviorSubject<boolean>(false);

   /**
    * Enables/Disables save for all current and future footers
    *
    * @deprecated use the CurrentFooterService > disableAll instead.
    */
   disableAll = new BehaviorSubject<boolean>(false);

   /**
    * Enables/Disables save for all current and future footers
    *
    * @deprecated use the CurrentFooterService > disableAll instead.
    */
   disableAllSite = new BehaviorSubject<boolean>(false);

   runCD: boolean = false;

   hideFooter: Observable<boolean>;

   /**
    * Set true if you want your component to manage the enabled/disabled state of all the footer buttons,
    * otherwise the enabled/disabled state may be implicitly modified by the footer component.
    *
    * @deprecated use the FooterConfigurationModel > AllowAutoButtonDisableStateChanging
    */
   enabledStateOverriddenForAllButtons: boolean = false;

   private _hideFooter = new Subject<boolean>();

   constructor() {
      this.siteFooterVisibilitySubject = new BehaviorSubject(false);
      this.hideFooter = this._hideFooter.asObservable();
   }

   toggleAllFooters(isFooterHidden: boolean): void {
      this._hideFooter.next(isFooterHidden);
   }
}
