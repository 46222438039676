<lcs-table [dataSource]="checkListItems">
   <tr lcs-header-row *lcsHeaderRowDef="checkListColumns; sticky: false"></tr>
   <tr lcs-row *lcsRowDef="let row; columns: checkListColumns"></tr>
   <ng-container lcsColumnDef="IsClosed" [width]="6" [priority]="1">
      <th lcs-header-cell *lcsHeaderCellDef>Closed</th>
      <td lcs-cell *lcsCellDef="let row; let index=index" class="boolean">
         <div *ngIf="row.IsClosed">
            <i class="material-icons">check</i>
         </div>
      </td>
   </ng-container>
   <ng-container lcsColumnDef="Description" [width]="30" [priority]="2">
      <th lcs-header-cell *lcsHeaderCellDef>Description</th>
      <td lcs-cell *lcsCellDef="let row; let index=index">{{row.Description}}</td>
   </ng-container>
   <ng-container lcsColumnDef="ClosedDate" [width]="10" [priority]="3">
      <th lcs-header-cell *lcsHeaderCellDef>Closed Date</th>
      <td lcs-cell *lcsCellDef="let row; let index=index">{{row.ClosedDate !== '0001-01-01T00:00:00' ? (row.ClosedDate | date: 'MM/dd/yyyy h:mm a') : ''}}</td>
   </ng-container>
   <ng-container lcsColumnDef="ClosedByUser" [width]="10" [priority]="4">
      <th lcs-header-cell *lcsHeaderCellDef>Closed By User</th>
      <td lcs-cell *lcsCellDef="let row; let index=index">{{row.ClosedByUser?.Name}}</td>
   </ng-container>
</lcs-table>