import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class UniqueIDService {
   private uniqueID: number = -2;
   public getUniqueID() {
      return this.uniqueID--;
   }
}
