import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { SingleLineMultiSelectComponent } from "@lcs/single-line-multi-select/single-line-multi-select.component";
import { ConstantsService } from "projects/libraries/lcs/src/lib/core/constants.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { Vendor1099CategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor1099category.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Vendor1099CategoriesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/vendor1099categories.service";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-associated-1099-categories-report-parameter",
   templateUrl: "associated-1099-categories-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class Associated1099CategoriesReportParametersComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   @ViewChild("selectorcomponent") misc1099CategoriesSelector: SingleLineMultiSelectComponent;

   selectedCategoryGroup: number;

   valueSource: ValueSourceModel;

   selectedValues: Array<number>;

   selectionChange: SelectionChangeModel;

   reportParameterValueModel: ReportParameterValueModel;

   necCategoryID: number;

   private _parameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private vendor1099CategoriesService: Vendor1099CategoriesService
   ) {}

   ngOnInit(): void {
      this.initializeReportParameterValue();
      this.getVendor1099Categories();
      this.initializeValidation();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onCategoryGroupChange(categoryID: number): void {
      this.selectedCategoryGroup = categoryID;
      if (categoryID > 0) {
         this.reportParameterValueModel.value = categoryID;
         this.reportParameterValueModel.rawValues = [categoryID];
      } else {
         if (
            this.misc1099CategoriesSelector &&
            this.misc1099CategoriesSelector.selectedItemCount !== this.misc1099CategoriesSelector.items?.length
         ) {
            const checkedItems: Array<SelectorItemModel> = this.misc1099CategoriesSelector.items.filter(
               (c) => c.value != null && c.value !== "" && c.isChecked === true
            );
            const values: Array<any> = checkedItems?.map((f) => f.value);
            this.reportParameterValueModel.value = values.join(",");
            this.reportParameterValueModel.rawValues = values;
         } else {
            this.reportParameterValueModel.value = this.valueSource.AllValue;
            this.reportParameterValueModel.rawValues = [this.valueSource.AllValue];
         }
      }
      this.selectedValues = this.reportParameterValueModel.rawValues;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   selectionChanged(): void {
      const checkedItems: Array<SelectorItemModel> = this.selectionChange.checkedItems.filter(
         (c) => c.value != null && c.value !== ""
      );
      const selectAll: boolean = this.selectionChange.selectAll;
      const allValue: string = this.valueSource.AllValue;
      let values = new Array<any>();
      this.reportParameterValueModel.value = "";

      if (selectAll && allValue) {
         this.reportParameterValueModel.value = allValue;
         values = [allValue];
      } else if (checkedItems && checkedItems.length > 0) {
         values = checkedItems.map((f) => f.value);
         this.reportParameterValueModel.value = values.join(",");
      } else {
         this.reportParameterValueModel.value = "";
      }
      this.reportParameterValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean): void {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue(): void {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }

   private getVendor1099Categories(): void {
      const isVisibleFilter: FilterOption = new FilterOption("IsVisible", FilterOperations.EqualTo, [true]);
      this.vendor1099CategoriesService
         .getCollection([isVisibleFilter])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((associated1099CategoriesModels: Array<Vendor1099CategoryModel>) => {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Vendor1099CategoryModel | undefined' is not ... Remove this comment to see the full error message
            const necModel: Vendor1099CategoryModel = associated1099CategoriesModels.find(
               (x: Vendor1099CategoryModel) => x.Description === "Nonemployee Compensation"
            );
            this.necCategoryID = necModel ? necModel.Vendor1099CategoryID : 0;
            this.processCollection(
               associated1099CategoriesModels.filter((x) => x.Description !== "Nonemployee Compensation")
            );
         });
   }

   private processCollection(results: Array<Vendor1099CategoryModel>): void {
      const valueSource = this.initializeValueSource(results);

      if (this.reportParameterValueModel) {
         let selected1099Categories = new Array<number>();

         if (this.reportParameterValueModel.value === "") {
            this.reportParameterValueModel.value = valueSource.DefaultValue;
            this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
         }

         if (this.reportParameterValueModel.value === this.necCategoryID.toString()) {
            this.selectedCategoryGroup = this.necCategoryID;
         } else {
            this.selectedCategoryGroup = 0;
            if (this.reportParameterValueModel.value === valueSource.AllValue) {
               selected1099Categories = valueSource.StaticValues?.map((v) => v.value);
            } else {
               selected1099Categories = this.reportParameterValueModel.value
                  .toString()
                  .split(",")
                  .map((v: string) => v.replace(" ", ""))
                  .map((v: string) => +v);
            }
         }
         valueSource.SelectedValues = this.selectedValues = selected1099Categories;
      }
      this.valueSource = valueSource;
   }

   private initializeValueSource(results: Array<Vendor1099CategoryModel>): ValueSourceModel {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.AllowsMultipleValues = true;
      valueSource.AllowsSelectAll = true;
      valueSource.AllValue = ConstantsService.NullFK.toString();
      valueSource.DefaultValue = ConstantsService.NullFK.toString();
      if (results) {
         for (const vendor1099Category of results) {
            const item = new SelectorItemModel();
            item.displayValue = vendor1099Category.Description;
            item.value = vendor1099Category.Vendor1099CategoryID;
            valueSource.StaticValues.push(item);
         }
      }
      return valueSource;
   }

   private initializeValidation() {
      if (this.validation) {
         this.validation.required = true;
      } else {
         const validation = new ValidationModel();
         validation.required = true;
         validation.dataType = ExpressDataTypes.Key;
         this.validation = validation;
      }
   }
}
