import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { LinkModule } from "@lcs/link/link.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";

import { ValidationModule } from "../inputs/validation/validation.module";
import { MultiSelectComponent } from "./multi-select.component";

@NgModule({
   imports: [CommonModule, FormsModule, LoadingOverlayModule, CheckboxModule, LinkModule, ValidationModule],
   declarations: [MultiSelectComponent],
   exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
