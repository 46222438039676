export class VerifyEmailAddressViewModel {
   Message: string;
   Username: string;
   DesiredUsername: string;
   Password: string;
   ConfirmPassword: string;
   LocationID: number;
   WebUserID: number;
   UserNameIsVerified: boolean;
   SourceStep: string;
   accountId: number;
}
