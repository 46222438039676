import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "@lcs/pipes/pipes.module";
import { ColorPickerModule as ColorPicker } from "ngx-color-picker";

import { ColorPickerComponent } from "./color-picker.component";

@NgModule({
   imports: [CommonModule, FormsModule, ColorPicker, ReactiveFormsModule, PipesModule],
   declarations: [ColorPickerComponent],
   exports: [ColorPickerComponent],
})
export class ColorPickerModule {}
