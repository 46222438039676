import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { CheckboxModule as PrimengCheckboxModule } from "primeng/checkbox";

import { CheckboxComponent } from "./checkbox.component";

@NgModule({
   imports: [CommonModule, FormsModule, PrimengCheckboxModule, MatTooltipModule],
   declarations: [CheckboxComponent],
   exports: [CheckboxComponent],
})
export class CheckboxModule { }
