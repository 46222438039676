import { FilterDataTypes } from "@lcs/filters/filter-data-types.enum";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";

import { ValidationModel } from "../../../../lcs/src/lib/inputs/validation/validation.model";
import { FilterHelper } from "../core/filter-helper";
import { EntityType } from "../enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "../enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "../enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "../enumerations/generated/filter-operations.enum";
import { FilterOption } from "./filter-option.model";
import { FilterDefinitionModel } from "./generated/filter-definition.model";
import { SelectorItemTransferModelConverter } from "./selector-item-model.converter";

export class FilterControlConfigurationModel {
   /**
    * Typically the property path. Utilized for making filter options.
    */
   FilterName: string;

   /**
    * Utilized by search for making filter options.
    */
   PropertyName: string;

   Operations: Array<FilterOperations>;

   ControlType: ExpressLayoutControlTypes;

   DataSourceFilters: Array<FilterOption>;

   DataType: ExpressDataTypes;

   EntityType: EntityType;

   EnumerationType: string;

   IsAllValueNull: boolean;

   IsListViewDefault: boolean;

   Label: string;

   ListViewFilterOrder: number;

   OperationsToExclude: Array<FilterOperations>;

   ValidationModel: ValidationModel;

   ValuesToExclude: Array<string>;

   ValuesToInclude: Array<string>;

   StaticValues?: Array<SelectorItemModel>;

   /**.
    * Wether or not this filter config represents a KEY on the server side
    */
   IsKey: boolean;

   static fromTransferModel(filterDefinitionModel: FilterDefinitionModel): FilterControlConfigurationModel {
      const filterConfigurationModel = new FilterControlConfigurationModel();

      filterConfigurationModel.FilterName = filterDefinitionModel.FilterField.FilterName;
      filterConfigurationModel.Label = filterDefinitionModel.Label;
      filterConfigurationModel.ControlType = filterDefinitionModel.ControlType;
      filterConfigurationModel.EntityType = filterDefinitionModel.EntityType;
      filterConfigurationModel.EnumerationType = filterDefinitionModel.EnumerationType;
      filterConfigurationModel.IsAllValueNull = filterDefinitionModel.IsAllValueNull;

      filterConfigurationModel.OperationsToExclude = filterDefinitionModel.OperationsToExclude
         ? [...filterDefinitionModel.OperationsToExclude]
         : [];
      filterConfigurationModel.ValuesToExclude = filterDefinitionModel.ValuesToExclude
         ? [...filterDefinitionModel.ValuesToExclude]
         : [];
      filterConfigurationModel.ValuesToInclude = filterDefinitionModel.ValuesToInclude
         ? [...filterDefinitionModel.ValuesToInclude]
         : [];
      filterConfigurationModel.Operations = filterDefinitionModel.FilterField.Operations
         ? [...filterDefinitionModel.FilterField.Operations]
         : [];

      filterConfigurationModel.StaticValues = filterDefinitionModel.StaticValues?.map((item) =>
         SelectorItemTransferModelConverter.convert(item)
      );

      if (
         filterDefinitionModel.DataType === ExpressDataTypes.Key ||
         filterDefinitionModel.FilterField.DataType.Name === FilterDataTypes[FilterDataTypes.PrimaryKey] ||
         filterDefinitionModel.FilterField.DataType.Name === FilterDataTypes[FilterDataTypes.Key]
      ) {
         filterConfigurationModel.IsKey = true;
      }

      filterConfigurationModel.DataType = filterDefinitionModel.DataType;

      if (filterConfigurationModel.DataType == null || filterConfigurationModel.DataType === ExpressDataTypes.NotSet) {
         filterConfigurationModel.DataType = FilterHelper.convertFilterFieldDataTypeToExpressDataType(
            filterDefinitionModel.FilterField.DataType.Name
         );
      }

      if (filterDefinitionModel.DataSourceFilters) {
         filterConfigurationModel.DataSourceFilters = filterDefinitionModel.DataSourceFilters.map(
            (controlDataSourceFilter) =>
               new FilterOption(
                  controlDataSourceFilter.PropertyPath,
                  controlDataSourceFilter.FilterOperation,
                  controlDataSourceFilter.Values,
                  null,
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                  null,
                  null,
                  ExpressDataTypes.String
               )
         );
      }

      filterConfigurationModel.ValidationModel = ValidationModel.FromExpressValidationModel(
         filterDefinitionModel.ValidationModel
      );

      return filterConfigurationModel;
   }
}
