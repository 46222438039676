import { Injectable, OnDestroy } from "@angular/core";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import clone from "lodash/clone";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { EnumerationInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/enumeration-information.model";
import { ReportParameterValueEnumSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-enum-source.model";
import { ReportParameterValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value-source.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { EnumerationInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/services/enumeration-information.service";
import { ExpressSavedRegisterFiltersService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/express-saved-register-filters.service";
import { forkJoin, Observable, Subject, takeUntil } from "rxjs";

import { FilterPayloadService } from "../../../filters/filter-payload.service";
import { isAllValueType } from "../all-value.type";

@Injectable()
export class SelectorReportParameterService implements OnDestroy {
   private unsubscribe = new Subject<void>();
   constructor(
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private savedRegisterFiltersService: ExpressSavedRegisterFiltersService,
      private enumerationInformationService: EnumerationInformationService
   ) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   isEntityReportParameterValueSource(reportParameterValueSource: ReportParameterValueSourceModel): boolean {
      const result = reportParameterValueSource?.EntitySources?.length === 1;
      return result;
   }

   isEnumReportParameterValueSource(reportParameterValueSource: ReportParameterValueSourceModel): boolean {
      const result = reportParameterValueSource?.EnumSources?.length === 1;
      return result;
   }

   isStaticValuesReportParameterValueSource(reportParameterValueSource: ReportParameterValueSourceModel): boolean {
      const result = reportParameterValueSource?.StaticValues?.length > 0;
      return result;
   }

   getStaticValuesFromReportParameterValueSource(
      reportParameterValueSourceModel: ReportParameterValueSourceModel
   ): Array<SelectorItemModel> {
      const selectorItems: Array<SelectorItemModel> = new Array<SelectorItemModel>();

      for (const value of reportParameterValueSourceModel.StaticValues) {
         const item = new SelectorItemModel();
         item.displayValue = value.DisplayValue;
         item.value = value.Value;
         item.isChecked = item.value === reportParameterValueSourceModel.DefaultValue;
         selectorItems.push(item);
      }

      return selectorItems;
   }

   createValueSourceFromReportParameterValueSource(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): ValueSourceModel {
      let valueSource: ValueSourceModel;

      if (this.isEntityReportParameterValueSource(reportParameterValueSource)) {
         valueSource = this.createValueSourceFromReportParameterEntityValueSource(reportParameterValueSource);
      } else if (this.isEnumReportParameterValueSource(reportParameterValueSource)) {
         valueSource = this.createValueSourceFromReportParameterEnumValueSource(reportParameterValueSource);
      } else if (this.isStaticValuesReportParameterValueSource(reportParameterValueSource)) {
         valueSource = this.createValueSourceFromReportParameterStaticValueSource(reportParameterValueSource);
      } else {
         console.warn(
            "createValueSourceFromReportParameterValueSource unknown report parameter value source. reportParameterValueSource=",
            JSON.stringify(reportParameterValueSource, null, 2)
         );
         valueSource = new ValueSourceModel();
      }

      return valueSource;
   }

   createValueSourceFromReportParameterEntityValueSource(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): ValueSourceModel {
      const valueSourceModel = new ValueSourceModel();
      if (!this.isEntityReportParameterValueSource(reportParameterValueSource)) {
         console.error(
            "createValueSourceFromReportParameterEntityValueSource - Invalid argument error. Does not appear to be an entity value source. reportParameterValueSource=",
            reportParameterValueSource
         );
      }
      const entityValueSource = reportParameterValueSource.EntitySources[0];

      valueSourceModel.Type = ValueSourceTypes.Entity;
      valueSourceModel.EntityType = entityValueSource.EntityType;
      valueSourceModel.AllowsSelectAll = reportParameterValueSource.AllowsSelectAll;
      valueSourceModel.AllValue = reportParameterValueSource.AllValue;
      valueSourceModel.AllowsMultipleValues = reportParameterValueSource.AllowsMultipleValues;
      valueSourceModel.MaximumItemsToRetrieve = 50;
      valueSourceModel.EagerLoadItems = true;

      valueSourceModel.StaticValues = this.getStaticValuesFromReportParameterValueSource(reportParameterValueSource);

      if (!valueSourceModel.AllowsMultipleValues && valueSourceModel.AllowsSelectAll) {
         if (
            !valueSourceModel.StaticValues.find((v) => v.value === valueSourceModel.AllValue) &&
            !valueSourceModel.StaticValues.find((v) => v.displayValue === "Any")
         ) {
            const item = new SelectorItemModel();
            item.displayValue = "All";
            item.value = valueSourceModel.AllValue;
            item.isChecked = item.value === reportParameterValueSource.DefaultValue;
            valueSourceModel.StaticValues.unshift(item);
         }
      }

      if (entityValueSource.ModelSource !== EntityType[entityValueSource.EntityType].toString()) {
         valueSourceModel.Endpoint = entityValueSource.ModelSource;
      }

      valueSourceModel.DisplayValueSourcePath = entityValueSource.DisplayNameProperty;
      valueSourceModel.EntityValueSourcePath = entityValueSource.ValueProperty;

      if (EntityType[entityValueSource.EntityType].toString() === EntityType[EntityType.Filter].toString()) {
         valueSourceModel.Endpoint = this.savedRegisterFiltersService.getSavedRegisterFiltersByEntityTypeCollectionUrl(
            EntityType.ServiceManagerIssue
         );
         valueSourceModel.EntityValueSourcePath = "SavedRegisterFilterID";
      }

      valueSourceModel.DefaultValue = reportParameterValueSource.DefaultValue;
      valueSourceModel.SelectedValues = this.getValueSourceSelectedValuesFromDefaultValue(valueSourceModel);

      if (reportParameterValueSource.AddBlankValue) {
         valueSourceModel.AddBlankValue();
      }

      if (entityValueSource.Filters && entityValueSource.Filters.length > 0) {
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         valueSourceModel.Filters = valueSourceModel.Filters.concat(
            FilterPayloadService.parseFilterPayloads(entityValueSource.Filters)
         );
      }

      forkJoin([
         this.entityRequestEndpointService.getEndpointInformation(entityValueSource.EntityType),
         this.entityViewInformationService.getViewInformation(entityValueSource.EntityType),
         this.entitySearchConfigurationService.getSearchConfiguration(entityValueSource.EntityType),
      ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(([entityRequestInformation, entityViewInformation, searchConfiguration]) => {
            if (entityRequestInformation) {
               valueSourceModel.EndpointIsSearch = entityRequestInformation.IsSearchEnabled;
            }

            valueSourceModel.AllValueLabel = `All ${entityViewInformation.CollectionLabel}`;

            if (searchConfiguration) {
               if (searchConfiguration.DefaultOrderingOption) {
                  valueSourceModel.OrderingOptions = [searchConfiguration.DefaultOrderingOption.Name];
               }
               if (searchConfiguration.DefaultSearchFields) {
                  valueSourceModel.SearchFields = clone(searchConfiguration.DefaultSearchFields);
               }
            }
         });

      return valueSourceModel;
   }

   createValueSourceFromReportParameterEnumValueSource(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): ValueSourceModel {
      if (!this.isEnumReportParameterValueSource(reportParameterValueSource)) {
         console.error(
            "createValueSourceFromReportParameterEnumValueSource - Invalid argument error. Does not appear to be an enum value source. reportParameterValueSource=",
            reportParameterValueSource
         );
      }
      const valueSourceModel = new ValueSourceModel();
      const enumerationValueSource = reportParameterValueSource.EnumSources[0];
      valueSourceModel.Type = ValueSourceTypes.Enumeration;
      valueSourceModel.EnumerationName = enumerationValueSource.EnumSource;
      valueSourceModel.ValuesToExclude = enumerationValueSource.ValuesToExclude;
      valueSourceModel.AllValue = reportParameterValueSource.AllValue;
      valueSourceModel.AllowsMultipleValues = reportParameterValueSource.AllowsMultipleValues;
      valueSourceModel.DefaultValue = reportParameterValueSource.DefaultValue;
      valueSourceModel.StaticValues = this.getStaticValuesFromReportParameterValueSource(reportParameterValueSource);
      valueSourceModel.SelectedValues = this.getValueSourceSelectedValuesFromDefaultValue(valueSourceModel);
      return valueSourceModel;
   }

   createValueSourceFromReportParameterStaticValueSource(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): ValueSourceModel {
      if (!this.isStaticValuesReportParameterValueSource(reportParameterValueSource)) {
         console.error(
            "createValueSourceFromReportParameterStaticValueSource - Invalid argument error. Does not appear to be an static value source. reportParameterValueSource=",
            reportParameterValueSource
         );
      }
      const valueSourceModel = new ValueSourceModel();
      valueSourceModel.Type = ValueSourceTypes.Static;
      valueSourceModel.AllValue = reportParameterValueSource.AllValue;
      valueSourceModel.AllowsMultipleValues = reportParameterValueSource.AllowsMultipleValues;
      valueSourceModel.DefaultValue = reportParameterValueSource.DefaultValue;
      valueSourceModel.StaticValues = this.getStaticValuesFromReportParameterValueSource(reportParameterValueSource);
      valueSourceModel.SelectedValues = this.getValueSourceSelectedValuesFromDefaultValue(valueSourceModel);
      return valueSourceModel;
   }

   /**
    * Returns an array of selectedValues based on valueSource DefaultValue
    * Ensures that if ValuesSourceType is an Enumeration or Entity, that the values returned
    * in array will be of type number not string.
    * Note for EmbeddedSet and Static value source types, an array containing an
    * empty string is valid because an empty string is a valid value for these types.
    */
   getValueSourceSelectedValuesFromDefaultValue(valueSource: ValueSourceModel): Array<any> {
      let selectedValues: Array<any>;

      if (valueSource?.DefaultValue == null) {
         // if valueSource.DefaultValue is null or undefined return an empty array
         selectedValues = new Array<any>();
         return selectedValues;
      }

      if (valueSource.AllowsMultipleValues) {
         if (typeof valueSource.DefaultValue === "string") {
            const delimiter = valueSource.MultipleValueDelimiter ? valueSource.MultipleValueDelimiter : ",";
            selectedValues = valueSource.DefaultValue.replace(/[()]/g, "").split(delimiter);
            if (valueSource.Type === ValueSourceTypes.Enumeration || valueSource.Type === ValueSourceTypes.Entity) {
               if (selectedValues.length && !isAllValueType(selectedValues[0])) {
                  selectedValues = selectedValues.map((v) => parseInt(v)).filter((v) => !isNaN(v));
               }
            }
         } else if (Array.isArray(valueSource.DefaultValue)) {
            selectedValues = valueSource.DefaultValue;
         } else {
            selectedValues = [valueSource.DefaultValue];
         }
      } else {
         if (valueSource.Type === ValueSourceTypes.Enumeration || valueSource.Type === ValueSourceTypes.Entity) {
            if (isAllValueType(valueSource.DefaultValue)) {
               selectedValues = [valueSource.DefaultValue];
            } else {
               const defaultVal = parseInt(valueSource.DefaultValue);
               selectedValues = isNaN(defaultVal) ? [] : [defaultVal];
            }
         } else if (Array.isArray(valueSource.DefaultValue)) {
            selectedValues =
               valueSource.DefaultValue.length > 0 ? valueSource.DefaultValue.slice(0, 1) : new Array<any>();
         } else {
            selectedValues = [valueSource.DefaultValue];
         }
      }

      return selectedValues;
   }

   /**
    * This method ensures any invalid values in the defaultValue are removed, to avoid trying to
    * set/select invalid values by default.
    * This can happen when for example when a report parameter is of type int (as with enums) and unless otherwise
    * set, the DefaultValue for an int report parameter is "0".  If 0 equals the NotSet value in an
    * enum, and that value is removed from the set or otherwise not included to avoid the user choosing it. This can result
    * in a ExpressionChangedAfterItHasBeenCheckedError error when it tries to replace a "" value with "0"
    * during initialization.
    */
   getValidDefaultValueForReportParameterValueSourceWithEnumValueSource(
      reportParameterValueSource: ReportParameterValueSourceModel
   ): string {
      if (!(reportParameterValueSource?.EnumSources?.length > 0)) {
         throw Error(
            `Invalid argument exception. reportParameterValueSource?.EnumSources is null, undefined or empty. reportParameterValueSource=${JSON.stringify(
               reportParameterValueSource,
               null,
               2
            )}`
         );
      }
      const enumValueSource: ReportParameterValueEnumSourceModel = reportParameterValueSource.EnumSources[0];
      let defaultValue: string = "";
      const excludeNumericValues: Array<number> = enumValueSource.ValuesToExclude?.map((s) => parseInt(s)) ?? [];

      // Add values from AllValue if it contains 1 or more numbers. ex. "1,2,3"
      let availableNumericValues = reportParameterValueSource.AllValue?.split(",")
         .map((s) => parseInt(s))
         .filter((n) => !isNaN(n) && !excludeNumericValues.includes(n));

      // if we didn't get the available values from AllValue, then get values from enum,
      // removing any excluded items and adding any static values
      if (!(availableNumericValues?.length > 0)) {
         const enumInfos$: Observable<Array<EnumerationInformationModel>> =
            this.enumerationInformationService.getEnumerationInformationCollection(enumValueSource.EnumSource);
         forkJoin([enumInfos$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([enumInfos]) => {
               availableNumericValues = enumInfos
                  .map((ei: EnumerationInformationModel) => ei.Value)
                  .filter((n: number) => !excludeNumericValues.includes(n));
            });

         // Add any Static Values
         reportParameterValueSource.StaticValues?.forEach((v) => availableNumericValues.push(parseInt(v.Value)));
      }

      if (reportParameterValueSource.AllowsMultipleValues) {
         defaultValue = reportParameterValueSource.DefaultValue?.split(",")
            .filter((v) => availableNumericValues.includes(parseInt(v)))
            .join(",");
      } else {
         defaultValue = availableNumericValues.includes(parseInt(defaultValue)) ? "" : defaultValue;
      }

      return defaultValue;
   }
}
