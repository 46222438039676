import { NgModule } from "@angular/core";
import { TileComponent } from "./tile.component";
import { CommonModule } from "@angular/common";
import { LinkModule } from "@lcs/link/link.module";

@NgModule({
   imports: [
      CommonModule,
      LinkModule,
   ],
   declarations: [
      TileComponent,
   ],
   exports: [
      TileComponent,
   ],
})

export class TileModule {}