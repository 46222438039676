<owa-layout [headerTitle]="'User Profile'">
   <div class="content-wrapper detail-layout">
      <div class="flex userprofile-wrapper">
         <div class="flex-half">
            <div class="tile userprofile-tile personal-info-margin">
               <div class="tile-header-wrapper">
                  <div class="userprofile-label">
                     <label>PERSONAL INFORMATION</label>
                  </div>
               </div>
               <div
                  class="tile-content  tile-height"
                  style="padding-top: 10px;"
               >
                  <div class="field-controls info-label">
                     <label>
                        This information is associated with your user login. Information provided can help with account
                        recovery
                        and
                        verification.
                     </label>
                  </div>
                  <div
                     *ngIf="errorMessage"
                     class="field-controls"
                  >
                     <label class="error-message">{{errorMessage}}</label>
                  </div>
                  <form
                     #form="ngForm"
                     (ngSubmit)="saveUserProfile(form)"
                  >
                     <div class="field-controls">
                        <label>First Name *</label>
                        <lcs-text-box
                           [(ngModel)]="webUser.FirstName"
                           name="FirstName"
                           displayName="First Name"
                           [required]="true"
                        ></lcs-text-box>
                     </div>


                     <div class="field-controls">
                        <label>Last Name *</label>
                        <lcs-text-box
                           [(ngModel)]="webUser.LastName"
                           name="LastName"
                           displayName="Last Name"
                           [required]="true"
                        ></lcs-text-box>
                     </div>


                     <div class="field-controls">
                        <label>Phone Number</label>
                        <owa-phone-number
                           [(ngModel)]="phoneNumber"
                           name="ProfilePhoneNumber"
                           displayName="Phone Number"
                        ></owa-phone-number>
                     </div>
                     <div class="userprofile-button-wrapper">

                        <lcs-progress-button
                           [(status)]="saveButtonStatus"
                           [raised]="true"
                           class="userprofile-button"
                        >Save Profile
                        </lcs-progress-button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <div class="flex-half">
            <div class="tile userprofile-tile">
               <div class="tile-header-wrapper">
                  <div class="userprofile-button-wrapper">
                     <div class="userprofile-label">
                        <label>LOGIN INFORMATION</label>
                     </div>
                  </div>
               </div>
               <div
                  class="tile-content"
                  style="padding-top: 10px;"
               >
                  <div class="login-info-wrapper">
                     <div class="field-controls">
                        <div class="info-label userprofile-info">
                           <label class="bold">Username</label>
                           <div>{{userName}}</div>
                        </div>

                     </div>
                     <div style="display: flex;">
                        <div
                           class="flex-half signup"
                           lcsFooter
                        >
                           <button
                              type="submit"
                              id="changeusername"
                              class="login-info-button"
                              (click)="openChangeEmailAddressDialog()"
                           >Change Username</button>
                        </div>
                        <div
                           class="flex-half signup"
                           style="margin-right: 0 !important;"
                           lcsFooter
                        >
                           <button
                              type="submit"
                              id="changepassword"
                              class="login-info-button"
                              (click)="openChangePasswordDialog()"
                           >Change Password</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</owa-layout>