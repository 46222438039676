import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, NgControl, NgModel } from "@angular/forms";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationHelper } from "@lcs/inputs/validation/validation-helper";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { CurrentSystemPreferencesService } from "@lcs/session/current-system-preferences.service";
import isDate from "lodash/isDate";
import { CalendarTypeView } from "primeng/calendar";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { ControlContainerViewProvider } from "../control-container-view-providers";
import { RelativeDateOptions } from "../date-picker/relative-date-options.enum";
import { RelativeDatesService } from "../date-picker/relative-dates.service";
import { DateRangePickerOption } from "./date-range-picker-options.enum";
import { DateRangePickerOtherOption } from "./date-range-picker-other-options.enum";
import { DateRangeModel, isDateRangeModel } from "./date-range.model";

@Component({
   selector: "lcs-date-range-picker",
   templateUrl: "date-range-picker.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class DateRangePickerComponent extends ValueAccessorBase<DateRangeModel> implements OnInit, OnDestroy {
   @Input() customValidatorData: any;

   @Input() dateEndFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   @Input() dateStartFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() endValidation: ValidationModel;

   @Input() name: string;

   @Input() startValidation: ValidationModel;

   @Input() standalone: boolean;

   @Input() hideAdditionalOptions: boolean;

   @Input() isPeriod: boolean = false;

   @Input() hasOtherOptions: boolean = true;

   @Input() dateView: CalendarTypeView = "date";

   @Output() valueChange = new EventEmitter<DateRangeModel>();

   childInputHasFocus: boolean;

   currentOtherOptionSelection: DateRangePickerOtherOption;

   endName: string;

   moreOptionsName: string;

   optionsValueSource = new ValueSourceModel();

   relativeDayOptions = DateRangePickerOption;

   set showOverlayPanel(value: boolean) {
      this._showOverlayPanel = value;
   }
   get showOverlayPanel() {
      return this._showOverlayPanel;
   }

   startName: string;

   childInputHasError: boolean;

   @ViewChild("StartDate", { static: true }) startDate: NgModel;

   @ViewChild("EndDate", { static: true }) endDate: NgModel;

   private unsubscribe = new Subject<void>();

   private glStartDate: Date = new Date();

   private existingStartDateValidation;

   private existingEndDateValidation;

   private fiscalYearStartDate: Date;

   private fiscalYearEndDate: Date;

   private _showOverlayPanel: boolean;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private currentSystemPreferenceService: CurrentSystemPreferencesService,
      private errorMessageService: ErrorMessageService
   ) {
      super(changeDetectorRef, ngControl);
      this.registerOnValueWritten((value: DateRangeModel) => {
         if (value && typeof value === "string") {
            value = DateRangeModel.FromUdvString(value);
            this.innerValue = new DateRangeModel();
         } else if (value && value instanceof Array) {
            if (value.length === 2) {
               value = DateRangeModel.FromUdvString(value.join(" - "));
            }
         }
         if (value && isDateRangeModel(value)) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            let newStartDate: Date = value.startDate;
            if (value.startDate && !isDate(value.startDate)) {
               if (value.startDate === null) {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
                  newStartDate = null;
               } else {
                  newStartDate = new Date(value.startDate);
               }
               if (newStartDate !== null && isNaN(newStartDate.valueOf())) {
                  this.errorMessageService.triggerErrorMessage("Received invalid start date string:", value.startDate);
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'DateRangeMo... Remove this comment to see the full error message
                  value = null;
               }
            }
            value.startDate = this.getValidDate(newStartDate);

            // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
            let newEndDate: Date = value.endDate;
            if (value.endDate && !isDate(value.endDate)) {
               if (value.endDate === null) {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
                  newEndDate = null;
               } else {
                  newEndDate = new Date(value.endDate);
                  newEndDate.setHours(23, 59, 59);
               }
               if (newEndDate !== null && isNaN(newEndDate.valueOf())) {
                  this.errorMessageService.triggerErrorMessage("Received invalid end date string:", value.endDate);
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'DateRangeMo... Remove this comment to see the full error message
                  value = null;
               }
            }
            this.innerValue.startDate = value.startDate;
            this.innerValue.endDate = newEndDate;
         } else {
            this.innerValue = new DateRangeModel();
         }
      });
   }

   ngOnInit() {
      if (!this.value) {
         this.innerValue = new DateRangeModel();
      }

      this.startName = `${this.name}Start`;
      this.endName = `${this.name}End`;
      this.moreOptionsName = `${this.name}MoreOptions`;
      this.loadPreferences();
      this.buildOtherOptionsSource();
      this.setupValidation();
      return super.ngOnInit();
   }

   setupValidation() {
      if (!this.startValidation) {
         const startValidation = new ValidationModel();
         startValidation.dataType = ExpressDataTypes.Date;
         startValidation.customValidator = this.defaultStartDateCustomValidation.bind(this);
         setTimeout(() => (this.startValidation = startValidation));
      } else {
         this.existingStartDateValidation = this.startValidation.customValidator;
         this.startValidation.customValidator = this.defaultStartDateCustomValidation.bind(this);
      }
      if (!this.endValidation) {
         const endValidation = new ValidationModel();
         endValidation.dataType = ExpressDataTypes.Date;
         endValidation.customValidator = this.defaultEndDateCustomValidation.bind(this);
         setTimeout(() => (this.endValidation = endValidation));
      } else {
         this.existingEndDateValidation = this.endValidation.customValidator;
         this.endValidation.customValidator = this.defaultEndDateCustomValidation.bind(this);
      }
   }

   onStartDateChange(value: Date) {
      this.innerValue.startDate = value;
      this.currentOtherOptionSelection = DateRangePickerOtherOption.OtherOptions;
      this.propagateChanged();
      setTimeout(() => {
         this.setDateRangePickersValidity();
      });
      this.valueChange.emit(this.innerValue);
   }

   onEndDateChange(value: Date) {
      if (value !== null) {
         value.setHours(23, 59, 59);
      }
      this.innerValue.endDate = value;
      this.currentOtherOptionSelection = DateRangePickerOtherOption.OtherOptions;
      this.propagateChanged();
      setTimeout(() => {
         this.setDateRangePickersValidity();
      });
      this.valueChange.emit(this.innerValue);
   }

   loadPreferences() {
      this.currentSystemPreferenceService
         .getSystemPreferences([
            SystemPreference.GeneralLedgerStartDate,
            SystemPreference.FiscalYearStartDate,
            SystemPreference.FiscalYearEndDate,
         ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((preferences: Map<SystemPreference, SystemPreferenceModel>) => {
            let pref = preferences.get(SystemPreference.GeneralLedgerStartDate);
            if (pref) {
               const glStartNumber = Date.parse(pref.Value);
               if (glStartNumber) {
                  this.glStartDate = new Date(glStartNumber.valueOf());
               }
            }

            pref = preferences.get(SystemPreference.FiscalYearStartDate);
            if (pref) {
               this.fiscalYearStartDate = new Date(Date.parse(`${pref.Value}/${new Date().getFullYear()}`));
            }

            pref = preferences.get(SystemPreference.FiscalYearEndDate);
            if (pref) {
               this.fiscalYearEndDate = new Date(Date.parse(`${pref.Value}/${new Date().getFullYear()}`));
            }
         });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   buildOtherOptionsSource() {
      this.currentOtherOptionSelection = DateRangePickerOtherOption.OtherOptions;

      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;

      if (!this.isPeriod) {
         valueSource.StaticValues = [
            new SelectorItemModel(DateRangePickerOtherOption.OtherOptions, "Other Options"),
            new SelectorItemModel(DateRangePickerOtherOption.LastQuarter, "Last Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.ThisQuarter, "This Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.NextQuarter, "Next Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.LastFiscalYear, "Last Fiscal Year"),
            new SelectorItemModel(DateRangePickerOtherOption.ThisFiscalYear, "This Fiscal Year"),
            new SelectorItemModel(DateRangePickerOtherOption.NextFiscalYear, "Next Fiscal Year"),
            new SelectorItemModel(DateRangePickerOtherOption.FiscalYearToDate, "Fiscal Year To Date"),
            new SelectorItemModel(DateRangePickerOtherOption.Previous12Months, "Previous 12 Months"),
            new SelectorItemModel(DateRangePickerOtherOption.Next12Months, "Next 12 Months"),
            new SelectorItemModel(DateRangePickerOtherOption.SinceGLStartDate, "Since GL Start Date"),
         ];
      } else {
         valueSource.StaticValues = [
            new SelectorItemModel(DateRangePickerOtherOption.OtherOptions, "Other Options"),
            new SelectorItemModel(DateRangePickerOtherOption.LastQuarter, "Last Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.ThisQuarter, "This Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.NextQuarter, "Next Quarter"),
            new SelectorItemModel(DateRangePickerOtherOption.Previous12Months, "Previous 12 Periods"),
            new SelectorItemModel(DateRangePickerOtherOption.Next12Months, "Next 12 Periods"),
         ];
      }

      this.optionsValueSource = valueSource;
   }

   optionSelected(option: DateRangePickerOtherOption) {
      this.currentOtherOptionSelection = option;

      const dateRangeModel = new DateRangeModel();

      switch (option) {
         case DateRangePickerOtherOption.LastQuarter:
            dateRangeModel.startDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.LastQuarterStart);
            dateRangeModel.endDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.LastQuarterEnd);
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.ThisQuarter:
            dateRangeModel.startDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.CurrentQuarterStart);
            dateRangeModel.endDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.CurrentQuarterEnd);
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.NextQuarter:
            dateRangeModel.startDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.NextQuarterStart);
            dateRangeModel.endDate = RelativeDatesService.getRelativeDate(RelativeDateOptions.NextQuarterEnd);
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.LastFiscalYear:
            dateRangeModel.startDate = new Date(
               new Date().getFullYear() - 1,
               this.fiscalYearStartDate.getMonth(),
               this.fiscalYearStartDate.getDate()
            );
            dateRangeModel.endDate = new Date(
               new Date().getFullYear() - 1,
               this.fiscalYearEndDate.getMonth(),
               this.fiscalYearEndDate.getDate()
            );
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.ThisFiscalYear:
            dateRangeModel.startDate = new Date(
               new Date().getFullYear(),
               this.fiscalYearStartDate.getMonth(),
               this.fiscalYearStartDate.getDate()
            );
            dateRangeModel.endDate = new Date(
               new Date().getFullYear(),
               this.fiscalYearEndDate.getMonth(),
               this.fiscalYearEndDate.getDate()
            );
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.NextFiscalYear:
            dateRangeModel.startDate = new Date(
               new Date().getFullYear() + 1,
               this.fiscalYearStartDate.getMonth(),
               this.fiscalYearStartDate.getDate()
            );
            dateRangeModel.endDate = new Date(
               new Date().getFullYear() + 1,
               this.fiscalYearEndDate.getMonth(),
               this.fiscalYearEndDate.getDate()
            );
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.FiscalYearToDate:
            dateRangeModel.startDate = new Date(
               new Date().getFullYear(),
               this.fiscalYearStartDate.getMonth(),
               this.fiscalYearStartDate.getDate()
            );
            dateRangeModel.endDate = new Date();
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.Previous12Months:
            dateRangeModel.startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1);
            dateRangeModel.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.Next12Months:
            dateRangeModel.startDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
            dateRangeModel.endDate = new Date(
               dateRangeModel.startDate.getFullYear(),
               dateRangeModel.startDate.getMonth() + 12,
               0
            );
            this.value = dateRangeModel;
            break;

         case DateRangePickerOtherOption.SinceGLStartDate:
            dateRangeModel.startDate = new Date(this.glStartDate.valueOf());
            dateRangeModel.endDate = new Date();
            this.value = dateRangeModel;
            break;
      }
   }

   selectRelativeDate(selOption: DateRangePickerOption) {
      this.currentOtherOptionSelection = DateRangePickerOtherOption.OtherOptions;
      this.value = RelativeDatesService.getRelativeDateRange(
         selOption,
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
         this.innerValue.startDate,
         this.innerValue.endDate
      );
   }

   setFocus(focus: boolean) {
      this.childInputHasFocus = focus;
   }

   toggleDateRangeOverlay(event: any) {
      if (this.disabled) {
         return;
      }
      event.stopPropagation();
      setTimeout(() => {
         this.showOverlayPanel = !this.showOverlayPanel;
      });
   }

   private getValidDate(date: Date): Date {
      if (date === null || date === undefined || date.getFullYear() < 1000) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Date'.
         return null;
      } else {
         return date;
      }
   }

   private setDateRangePickersValidity() {
      this.startDate.control.updateValueAndValidity({ emitEvent: false });
      this.endDate.control.updateValueAndValidity({ emitEvent: false });
      if (this.startDate.invalid || this.endDate.invalid) {
         this.childInputHasError = true;
      } else {
         this.childInputHasError = false;
      }
   }

   private defaultStartDateCustomValidation(control: AbstractControl, customValidatorData: any) {
      let rangeEndControl: NgModel | AbstractControl | null;
      if (!control.parent) {
         rangeEndControl = this.endDate;
      } else {
         rangeEndControl = control.parent.get(this.endName);
      }
      const fromDate = control.value;
      const toDate = this.innerValue ? this.innerValue.endDate : null;
      let errors = null;
      // If we want to compare using getTime, we need to set both the times to start of the day.
      // Else if we select the same date, even the seconds difference would trigger error
      if (fromDate) {
         fromDate.setHours(0, 0, 0, 0);
      }
      if (toDate) {
         toDate.setHours(0, 0, 0, 0);
      }
      if (fromDate && toDate && fromDate.getTime() > toDate.getTime()) {
         // @ts-ignore ts-migrate(2322) FIXME: Type '{ dateRangeStart: boolean; }' is not assigna... Remove this comment to see the full error message
         errors = { dateRangeStart: true };
      }
      if (rangeEndControl && rangeEndControl.errors) {
         setTimeout(() => {
            if (rangeEndControl instanceof AbstractControl) {
               /*
               This was throwing an error before if rangeEndControl evaluated to NgModel, so we removed its' call to updateValueAndValidity
               as it was not necessary for validation to work. We're not sure if this next part ever gets called, but just in case, we're leaving
               it.
               */
               rangeEndControl.updateValueAndValidity();
            }
         });
      }
      if (this.existingStartDateValidation) {
         const otherErrors = this.existingStartDateValidation(control, customValidatorData);
         return ValidationHelper.mergeErrorMessages([errors, otherErrors]);
      } else {
         return errors;
      }
   }

   private defaultEndDateCustomValidation(control: AbstractControl, customValidatorData: any) {
      let rangeStartControl: NgModel | AbstractControl | null;
      if (!control.parent) {
         rangeStartControl = this.startDate;
      } else {
         rangeStartControl = control.parent.get(this.endName);
      }
      const fromDate = this.innerValue ? this.innerValue.startDate : null;
      const toDate = control.value;
      let errors;
      // If we want to compare using getTime, we need to set both the times to start of the day.
      // Else if we select the same date, even the seconds difference would trigger error
      if (fromDate) {
         fromDate.setHours(0, 0, 0, 0);
      }
      if (toDate) {
         toDate.setHours(0, 0, 0, 0);
      }
      if (fromDate && toDate && fromDate.getTime() > toDate.getTime()) {
         errors = { dateRangeEnd: true };
      }
      if (rangeStartControl && rangeStartControl.errors) {
         setTimeout(() => {
            if (rangeStartControl instanceof AbstractControl) {
               /*
               This was throwing an error before if rangeStartControl evaluated to NgModel, so we removed its' call to updateValueAndValidity
               as it was not necessary for validation to work. We're not sure if this next part ever gets called, but just in case, we're leaving
               it.
               */
               rangeStartControl.updateValueAndValidity();
            }
         });
      }
      if (this.existingEndDateValidation) {
         const otherErrors = this.existingEndDateValidation(control, customValidatorData);
         const allErrors = ValidationHelper.mergeErrorMessages([errors, otherErrors]);
         return allErrors;
      } else {
         return errors;
      }
   }
}
