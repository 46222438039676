import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { Observable } from "rxjs";

import { ApplicationInfoModel } from "./application-info.model";

@Injectable({
   providedIn: "root",
})
export class ApplicationInfoService {
   static applicationInfoUrl = "applicationinfo";

   constructor(private apiRequestHelperService: ApiRequestHelperService, private httpClient: HttpClient) { }

   public GetApplicationInfo(): Observable<ApplicationInfoModel> {
      const headers = this.apiRequestHelperService.buildInitialHeaders();
      return this.httpClient
         .get<ApplicationInfoModel>(this.apiRequestHelperService.getUrl(ApplicationInfoService.applicationInfoUrl), {
            headers: headers,
            withCredentials: true,
         });
   }
}
