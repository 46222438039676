<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
   >Owners to Include</label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="askParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="tab-wrapper">
   <div class="single-tab-wrapper">
      <lcs-dynamic-selector-report-parameter
         [hideLabel]="true"
         [name]="name + '-owner'"
         [disabled]="disableOwnerMultiSelectors || disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
         [parameter]="ownerIdsReportParameter"
         [entityValueSourceFilters]="ownerFilters"
         [selectAllItemsOnFilterChange]="false"
      ></lcs-dynamic-selector-report-parameter>
      <label
         *ngIf="groupValueSources"
         class="glLedger-report"
         [class.disabled]="disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
      >Group</label>
      <owa-dynamic-selector
         *ngIf="groupValueSources"
         [name]="name + '-group'"
         [displayName]="'Group'"
         [disabled]="disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
         [valueSources]="groupValueSources"
         [singleValue]="selectedGroupID"
         (selectionChanged)="groupSelected($event)"
      ></owa-dynamic-selector>

      <lcs-checkbox-report-parameter
         *ngIf="showInactiveOwnerOption"
         [name]="name + '-include-inactive-owner'"
         [disabled]="disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
         [parameter]="includeInactiveOwnerReportParameter"
      ></lcs-checkbox-report-parameter>
      <lcs-checkbox-report-parameter
         [name]="name + '-include-inactive-property'"
         [disabled]="disabled || (hasAsk && ownerIdsReportParameter.IsAsk)"
         [parameter]="showInactivePropertiesReportParameter"
      ></lcs-checkbox-report-parameter>

   </div>
</div>