/* eslint-disable no-underscore-dangle */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { AuthenticationStep } from "./authentication-step.enum";

@Injectable({
   providedIn: "root",
})
export class AuthenticationStatusService {
   authenticationStatusObs: Observable<AuthenticationStep>;

   get authenticationStatus(): AuthenticationStep {
      return this._authenticationStatus;
   }

   private _authenticationStatus = AuthenticationStep.NotStarted;

   private _authenticationStatusChangeListeners = new Array<(step: AuthenticationStep) => {}>();

   // this value should only ever be set from this authentication status and never from the outside
   private _authenticationStatusObs = new BehaviorSubject<AuthenticationStep>(AuthenticationStep.NotStarted);

   constructor() {
      this.authenticationStatusObs = this._authenticationStatusObs.asObservable();
   }

   updateAuthenticationStatus(step: AuthenticationStep) {
      if (step !== this._authenticationStatus) {
         this._authenticationStatus = step;
         this.notifyListeners();
         this._authenticationStatusObs.next(step);
      }
   }

   // This is intentionally difficult to use - the AuthenticationService should be the only place authentication logic is triggered from
   // If this was a simple Subject, it would be to easy to subscribe to it and add more spaghetti back in to authentication
   // *Update - KR 1.11.2023 - authentication status observable has become necessary for broadcast
   registerAuthenticationStatusChangeListener(listener: (step: AuthenticationStep) => {}) {
      this._authenticationStatusChangeListeners.push(listener);
      this.notifyListeners();
   }

   private notifyListeners() {
      for (const listener of this._authenticationStatusChangeListeners) {
         listener(this._authenticationStatus);
      }
   }
}
