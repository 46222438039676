import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";

import { PipesModule } from "../../pipes/pipes.module";
import { StaticFieldComponent } from "./static-field.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, PipesModule],
   declarations: [StaticFieldComponent],
   exports: [StaticFieldComponent],
})
export class StaticFieldModule {}
