import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

import { ReportParameterComponents } from "../report-parameter-components.enum";

export class CombinedReportParameterModel {
   reportParameterComponent: ReportParameterComponents;
   reportParameters: Array<ReportParameter>;
   conditionalGroup: boolean;
   displayName: string;
   constructor(reportParameterComponent: ReportParameterComponents) {
      this.reportParameterComponent = reportParameterComponent;
      this.reportParameters = new Array<ReportParameter>();
   }
}
