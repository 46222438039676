import { CommonModule } from "@angular/common";
import { ApplicationModule, NgModule } from "@angular/core";
import { ScrollPanelModule } from "primeng/scrollpanel";

import { DragScrollComponent } from "./drag-scroll.component";

@NgModule({
   imports: [ScrollPanelModule, CommonModule, ApplicationModule],
   exports: [DragScrollComponent],
   declarations: [DragScrollComponent],
})
export class DragScrollModule {}
