import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { clone } from "lodash";

export class SelectorItemSearchHelper {
   private static orderByStartsWith = false;

   public static search(items: Array<SelectorItemModel>, searchText: string | null): Array<SelectorItemModel> {
      items = clone(items);

      if (searchText) {
         const lowerSearchText = searchText.toLowerCase();
         if (SelectorItemSearchHelper.orderByStartsWith) {
            const itemsThatStartWithSearchText = new Array<SelectorItemModel>();
            const itemsThatContainSearchText = new Array<SelectorItemModel>();
            for (const item of items) {
               const displayValueIndex = item.displayValue?.toLowerCase().indexOf(lowerSearchText);
               if (displayValueIndex === 0) {
                  itemsThatStartWithSearchText.push(item);
               } else if (
                  (displayValueIndex && displayValueIndex > -1) ||
                  (item.additionalInfoValue && item.additionalInfoValue.toLowerCase().indexOf(lowerSearchText) > -1)
               ) {
                  itemsThatContainSearchText.push(item);
               }
            }
            items = [...itemsThatStartWithSearchText, ...itemsThatContainSearchText];
         } else {
            items = items.filter(
               (i) =>
                  (i.displayValue && i.displayValue.toLowerCase().indexOf(lowerSearchText) > -1) ||
                  (i.additionalInfoValue && i.additionalInfoValue.toLowerCase().indexOf(lowerSearchText) > -1)
            );
         }
      }

      return items;
   }
}
