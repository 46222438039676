import { Injectable } from "@angular/core";
import { ExpressReports } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-reports.enum";
import { PrivilegeType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege-type.enum";
import { Privilege } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/privilege.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { CurrentUserService } from "projects/libraries/owa-gateway-sdk/src/lib/session/current-user.service";

import { CurrentSystemPreferencesService } from "../../../../../libraries/lcs/src/lib/session/current-system-preferences.service";
import { ExpressReportDefinitionsService } from "../../../../../libraries/lcs/src/lib/session/express-report-definitions.service";

@Injectable()
export class ReportRoutingService {
   constructor(
      private currentUserService: CurrentUserService,
      private expressReportDefinitionsService: ExpressReportDefinitionsService,
      private currentSystemPreferencesService: CurrentSystemPreferencesService
   ) {}
   currentUserHasAccessToReport(reportID: ExpressReports | Report, isExpressReport: boolean): boolean {
      if (reportID === Report.MakeReadyDetail || reportID === ExpressReports.MakeReadyDetailFromMakeReady) {
         return (
            this.currentUserService.hasPrivilege(Privilege.MakeReadyBoard, PrivilegeType.View) ||
            // @ts-ignore - error TS2531: Object is possibly 'null'.
            this.currentUserService.currentUser.value.ReportIDs.some((id) => id === reportID)
         );
      } else if (reportID === Report.Inspection || reportID === Report.InspectionSummary) {
         const inspectionsEnabled =
            this.currentSystemPreferencesService
               .getSystemPreferenceCacheValue(SystemPreference.RmInspectionWebAccessEnabled)
               .toLowerCase() === true.toString();
         return inspectionsEnabled && this.currentUserService.hasPrivilege(Privilege.inspections, PrivilegeType.View);
      } else if (
         (reportID === Report.PollDetails || reportID === Report.PollResults) &&
         this.currentUserService.currentUser.value
      ) {
         return (
            this.currentUserService.hasPrivilege(Privilege.HOAPolls, PrivilegeType.View) ||
            this.currentUserService.currentUser.value.ReportIDs.some((id) => id === reportID)
         );
      } else {
         if (isExpressReport) {
            const expressReport = this.expressReportDefinitionsService.expressReports.value.find(
               (r) => r.ExpressReport === reportID
            );
            if (expressReport) {
               if (
                  expressReport.ExpressReport === ExpressReports.DepositDetailFromDeposit ||
                  expressReport.ExpressReport === ExpressReports.DepositDetailMICRFromDeposit
               ) {
                  return true;
               } else {
                  // @ts-ignore - error TS2531: Object is possibly 'null'.
                  return this.currentUserService.currentUser.value.ReportIDs.some((id) => id === expressReport.Report);
               }
            }
            return false;
         } else {
            // @ts-ignore - error TS2531: Object is possibly 'null'.
            return this.currentUserService.currentUser.value.ReportIDs.some((id) => id === reportID);
         }
      }
   }
}
