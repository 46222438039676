<div
   class="collapsible-context-bar-title context-bar user-select-none"
   [class.add-width]="addActionBarWidth"
   (click)="(!isListViewToggleVisible || menuTriggerVisible) && contextBarClicked.emit()"
>
   <div class="collapsible-context-bar-content">
      <button
         *ngIf="!isCollapsed && menuTriggerVisible"
         type="button"
         class="content-header-arrow"
         (click)="showMenuClicked.emit(false)"
      >
         <i class="material-icons">keyboard_double_arrow_left</i>
      </button>
      <button
         *ngIf="isCollapsed && menuTriggerVisible"
         type="button"
         class="content-header-arrow"
         (click)="showMenuClicked.emit(true)"
      >
         <i class="material-icons">keyboard_double_arrow_right</i>
      </button>
      <div
         *ngIf="!hideSubTitle; else titleOnly"
         class="content-display-title-value"
      >
         <div class="content-display-title">
            {{ title }}<span [hidden]="!title || !subTitle">:</span>
         </div>
         <div class="content-display-value">{{ subTitle }}</div>
      </div>
      <ng-template #titleOnly>
         <div class="context-bar-title">
            {{ title }}
         </div>
      </ng-template>
   </div>
   <div class="collapsible-context-bar-links">
      <button
         type="button"
         class="list-view-toggle-btn"
         [hidden]="!isListViewToggleVisible"
         (click)="listViewToggleBtnClicked($event)"
      >
         <i class="material-icons">keyboard_return</i>
         <span class="text">{{ listViewToggleTitle }}</span>
      </button>
      <lcs-link
         *ngIf="showRefresh"
         [cssClasses]="['list-view-toggle-btn']"
         [contentTemplate]="autoRenewTemplate"
         title="Refresh"
         [disabled]="disableRefresh"
         (clicked)="onRefresh()"
      >
         <ng-template #autoRenewTemplate>
            <i class="material-icons">autorenew</i>
         </ng-template>
      </lcs-link>
   </div>
</div>