import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OwnerContractFields extends EntityField {
   static readonly BankBalance: OwnerContractFields = new OwnerContractFields("BankBalance");

   static readonly BillApprovalThreshold: OwnerContractFields = new OwnerContractFields("BillApprovalThreshold");

   static readonly ContractFrom: OwnerContractFields = new OwnerContractFields("ContractFrom");

   static readonly ContractTo: OwnerContractFields = new OwnerContractFields("ContractTo");

   static readonly CreateDate: OwnerContractFields = new OwnerContractFields("CreateDate");

   static readonly CreateUserID: OwnerContractFields = new OwnerContractFields("CreateUserID");

   static readonly DocumentID: OwnerContractFields = new OwnerContractFields("DocumentID");

   static readonly IsBillApprovalRequired: OwnerContractFields = new OwnerContractFields("IsBillApprovalRequired");

   static readonly MetaTag: OwnerContractFields = new OwnerContractFields("MetaTag");

   static readonly Owner: OwnerContractFields = new OwnerContractFields("Owner");

   static readonly OwnerContractID: OwnerContractFields = new OwnerContractFields("OwnerContractID");

   static readonly OwnerID: OwnerContractFields = new OwnerContractFields("OwnerID");

   static readonly Partners: OwnerContractFields = new OwnerContractFields("Partners");

   static readonly Percentage: OwnerContractFields = new OwnerContractFields("Percentage");

   static readonly Property: OwnerContractFields = new OwnerContractFields("Property");

   static readonly PropertyID: OwnerContractFields = new OwnerContractFields("PropertyID");

   static readonly Reserve: OwnerContractFields = new OwnerContractFields("Reserve");

   static readonly SecurityDepositsHeld: OwnerContractFields = new OwnerContractFields("SecurityDepositsHeld");

   static readonly UpdateDate: OwnerContractFields = new OwnerContractFields("UpdateDate");

   static readonly UpdateUserID: OwnerContractFields = new OwnerContractFields("UpdateUserID");
}
