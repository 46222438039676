import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationHelper } from "@lcs/inputs/validation/validation-helper";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { StateSelectorItemHelper } from "@lcs/select/helpers/state-selector-item.helper";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { DatesService } from "@lcs/utils/dates.service";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { Countries } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/countries.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { OwnerContributionPaymentDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-paymentdetail-view.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { OwnerContributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-contributions.service";
import { SystemPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/system-preferences.service";
import { Subject, takeUntil } from "rxjs";

import { CompletedComponent } from "../Completed/completed.component";
import { ConstantsService } from "./../../../../../libraries/owa-gateway-sdk/src/lib/services/constants.service";

@Component({
   selector: "owa-epay-cc",
   templateUrl: "./epay-cc.component.html",
   styleUrls: ["./epay-cc.component.css"],
})
export class EpayCCComponent implements OnInit, OnDestroy {
   accountNumberValidation: ValidationModel;
   cvv2Validation: ValidationModel = new ValidationModel();
   accountTypeControlName: string = "accountType";
   accountTypeValueSource: ValueSourceModel;
   buttonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   contributionType = new Array<string>();
   cvv2Digits: number;
   monthValueSource: ValueSourceModel;
   selectedAccountType: EpayAccountType;
   yearValueSource: ValueSourceModel;
   unsubscribe = new Subject<void>();
   configuration: OwnerContributionPaymentDetailViewModel;
   ownerContributionPaymentModel = new OwnerContributionPaymentDetailViewModel();
   isUnitedStates: boolean;
   checked: boolean;
   stateOptions: Array<SelectorItemModel>;

   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(refresh: b... Remove this comment to see the full error message
   valueCallBack: (refresh: boolean) => void = null;

   constructor(
      private errorMessageService: ErrorMessageService,
      private systemPreferencesService: SystemPreferencesService,
      private ownerContributionService: OwnerContributionsService,
      private dialogueService: CurrentDialogService,
      private dialogCreationService: DialogCreationService,
      public constantsService: ConstantsService
   ) {}

   ngOnInit(): void {
      this.getAccountTypes();
      this.buildMonthAndYearValueSources();
      this.accountNumberValidation = ValidationHelper.AccountNumber(this.accountTypeControlName);
      this.cvv2Validation.dataType = ExpressDataTypes.Numeric;
      this.cvv2Validation.pattern = /^\d*$/;
      this.ownerContributionPaymentModel = this.configuration;
      this.isUnitedStates = this.ownerContributionPaymentModel.BankCountryID === Countries.UnitedStates ? true : false;
      this.checked = this.ownerContributionPaymentModel.EpayCC.IsDisclaimerChecked;
      this.stateOptions = StateSelectorItemHelper.buildStateOptions(this.isUnitedStates);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onAccountTypeChange(accountType: EpayAccountType, accountNumber: NgModel): void {
      this.selectedAccountType = accountType;
      this.setCvv2Digits(accountType);
      this.ownerContributionPaymentModel.EpayCC.AccountTypeID = accountType;
      accountNumber.control.updateValueAndValidity();
   }

   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.CreditCardNumberValidator(this.ownerContributionPaymentModel.EpayCC.AccountNumber);
         this.Cvv2NumberValidator(this.ownerContributionPaymentModel.EpayCC.AccountNumber);
         this.buttonStatus = LcsProgressButtonStatus.InProgress;
         this.ownerContributionService
            .SaveContributions(this.ownerContributionPaymentModel)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (result) => {
                  if (result) {
                     this.dialogueService.closeDialog();
                     const config = new DialogConfigurationModel();
                     config.componentInstance = CompletedComponent;
                     config.title = "Payment Success";
                     this.ownerContributionService.PaymentType.next("CC");
                     this.dialogCreationService.open(config, null, null, null, null, null, (v) => {
                        this.valueCallBack(v);
                     });
                  }
               },
               (_err) => {
                  this.errorMessageService.triggerHttpErrorMessage(_err);
                  this.buttonStatus = LcsProgressButtonStatus.Error;
               }
            );
      } else {
         this.buttonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   allSelected(value: boolean) {
      this.ownerContributionPaymentModel.EpayCC.IsDisclaimerChecked = value;
      this.checked = value;
   }

   private buildMonthAndYearValueSources(): void {
      this.monthValueSource = DatesService.monthOfYearValueSource();

      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      const startYear = new Date().getFullYear();
      const endYear = startYear + 10;
      for (let year = startYear; year < endYear; year++) {
         valueSource.StaticValues.push(new SelectorItemModel(year, year.toString()));
      }

      this.yearValueSource = valueSource;
   }

   private getAccountTypes(): void {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Enumeration;
      valueSource.EnumerationName = "eEpayAccountType";
      valueSource.DefaultToFirstValue = true;
      valueSource.ValuesToExclude = [
         EpayAccountType.BusinessChecking,
         EpayAccountType.CashPay,
         EpayAccountType.Checking,
         EpayAccountType.GenericACH,
         EpayAccountType.GenericCC,
         EpayAccountType.NotSet,
         EpayAccountType.Savings,
      ];

      const filter = new FilterOption("Name", FilterOperations.In, [
         SystemPreference[SystemPreference.EpayAccountsVisaMasterCard],
         SystemPreference[SystemPreference.EpayAccountsAmex],
         SystemPreference[SystemPreference.EpayAccountsDinersClub],
         SystemPreference[SystemPreference.EpayAccountsDiscover],
      ]);
      this.systemPreferencesService
         .getCollection([filter])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (systemPreferences: SystemPreferenceModel[]) => {
               const visaMasterCard = systemPreferences.find(
                  (p) =>
                     p.Name === SystemPreference[SystemPreference.EpayAccountsVisaMasterCard] &&
                     p.Value.toLowerCase() === "true"
               );
               if (!visaMasterCard) {
                  valueSource.ValuesToExclude.push(EpayAccountType.MasterCard);
                  valueSource.ValuesToExclude.push(EpayAccountType.Visa);
               }
               const amex = systemPreferences.find(
                  (p) =>
                     p.Name === SystemPreference[SystemPreference.EpayAccountsAmex] && p.Value.toLowerCase() === "true"
               );
               if (!amex) {
                  valueSource.ValuesToExclude.push(EpayAccountType.AmericanExpress);
               }
               // const dinersClub = systemPreferences.find(
               //    (p) =>
               //       p.Name === SystemPreference[SystemPreference.EpayAccountsDinersClub] &&
               //       p.Value.toLowerCase() === "true"
               // );
               // if (!dinersClub) {
               //    valueSource.ValuesToExclude.push(EpayAccountType.DinersClub);
               // }
               const discover = systemPreferences.find(
                  (p) =>
                     p.Name === SystemPreference[SystemPreference.EpayAccountsDiscover] &&
                     p.Value.toLowerCase() === "true"
               );
               if (!discover) {
                  valueSource.ValuesToExclude.push(EpayAccountType.Discover);
               }
               this.accountTypeValueSource = valueSource;
               this.accountTypeValueSource.ValuesToExclude = [EpayAccountType.DinersClub];

               this.setCvv2Digits(this.selectedAccountType);
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   private setCvv2Digits(accountType: EpayAccountType) {
      this.cvv2Digits = accountType === EpayAccountType.AmericanExpress ? 4 : 3;
   }

   private Cvv2NumberValidator(accountNumber: string) {
      accountNumber = accountNumber.replace("-", "");

      let paymentMethod: EpayAccountType;

      const issuerId = +accountNumber.slice(0, 4);

      if (
         accountNumber.length === 15 &&
         ((issuerId >= 3400 && issuerId <= 3499) || (issuerId >= 3700 && issuerId <= 3799))
      ) {
         paymentMethod = EpayAccountType.AmericanExpress;
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'paymentMethod' is used before being assi... Remove this comment to see the full error message
      this.cvv2Digits = paymentMethod === EpayAccountType.AmericanExpress ? 4 : 3;
   }

   private CreditCardNumberValidator(accountNumber: string) {
      accountNumber = accountNumber.replace("-", "");

      let paymentMethod: EpayAccountType;

      const issuerId = +accountNumber.slice(0, 4);

      // Set-check account type
      if (
         accountNumber.length === 16 &&
         ((issuerId >= 5000 && issuerId <= 5999) || (issuerId >= 2221 && issuerId <= 2720))
      ) {
         paymentMethod = EpayAccountType.MasterCard;
      } else if ((accountNumber.length === 16 || accountNumber.length === 13) && issuerId >= 4000 && issuerId <= 4999) {
         paymentMethod = EpayAccountType.Visa;
      } else if (
         accountNumber.length === 15 &&
         ((issuerId >= 3400 && issuerId <= 3499) || (issuerId >= 3700 && issuerId <= 3799))
      ) {
         paymentMethod = EpayAccountType.AmericanExpress;
      } else if (
         accountNumber.length === 14 &&
         ((issuerId >= 3000 && issuerId <= 3099) ||
            (issuerId >= 3600 && issuerId <= 3699) ||
            (issuerId >= 3800 && issuerId <= 3899))
      ) {
         paymentMethod = EpayAccountType.DinersClub;
      } else if (accountNumber.length === 16 && issuerId === 6011) {
         paymentMethod = EpayAccountType.Discover;
      }

      // Validate allowed type if exists
      if (this.ownerContributionPaymentModel.EpayCC.AccountTypes) {
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'paymentMethod' is used before being assi... Remove this comment to see the full error message
         if (this.ownerContributionPaymentModel.EpayCC.AccountTypes.includes(paymentMethod)) {
            // @ts-ignore ts-migrate(2454) FIXME: Variable 'paymentMethod' is used before being assi... Remove this comment to see the full error message
            this.ownerContributionPaymentModel.EpayCC.AccountTypeID = paymentMethod;
         }
      }
   }
}
