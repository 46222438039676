<div
   #informationOverlayWrapper
   class="information-overlay-wrapper"
>
   <lcs-link
      [contentTemplate]="contentTemplate"
      (clicked)="toggleOverlay()"
      (focusout)="onFocusOut()"
   >
      <ng-template #contentTemplate>
         <i
            class="material-icons info-outline"
            #icon
         >info_outline</i>
      </ng-template>
   </lcs-link>
</div>
<lcs-overlay-panel
   #informationOverlayPanel
   [parentElement]="informationOverlayWrapper"
   [innerElementSelector]="'.information-overlay-wrapper'"
   [alignToRight]="alignToRight"
   [overlayPanelStaticWidth]="overlayPanelStaticWidth"
>
   <div class="form-container">
      <ng-container *ngTemplateOutlet="informationTemplate"></ng-container>
   </div>
</lcs-overlay-panel>