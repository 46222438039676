import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { EpayPaymentType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/epay-paymentype.enum";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";
import { PaymentTypeSelection } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/paymenttype-selection";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { OwnerContributionACHPaymentViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-achpayment-view-model";
import { OwnerContributionCCPaymentViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-ccpayment-view.model";
import { OwnerContributionPaymentDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution-paymentdetail-view.model";
import { OwnerContributionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-contribution.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { OwnerContributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-contributions.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { EpayACHComponent } from "./epay-ach/epay-ach.component";
import { EpayCCComponent } from "./epay-cc/epay-cc.component";
import { EpayComponent } from "./epay/epay.component";

@Component({
   selector: "owa-owner-contributions",
   templateUrl: "./owner-contributions.component.html",
   styleUrls: ["./owner-contributions.component.css"],
})
export class OwnerContributionsComponent implements OnInit {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   @Input() showReserve: boolean;
   @Input() showSecurityDeposit: boolean;
   @Input() showPercentageOwnerShip: boolean;
   @Input() showBalance: boolean;

   contributionTypes: ValueSourceModel;

   public contributionType = new Array<EpayPaymentType>();
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public contributions = new BehaviorSubject<Array<OwnerContributionModel>>(null);
   public unsubscribe: Subject<void> = new Subject<void>();
   public selectedContributionType: EpayPaymentType;
   public paymentTypeSelection: PaymentTypeSelection;
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public contributionTypeSelected = new BehaviorSubject<EpayPaymentType>(null);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public ownercontributionPaymentModel = new BehaviorSubject<OwnerContributionPaymentDetailViewModel>(null);
   public systemWebPrefernces = new Array<SystemWebPreferenceModel>();
   public comment: string;
   public val: boolean;
   public ownerContributionPaymentDetailModel = new OwnerContributionPaymentDetailViewModel();
   public prefList: string[] = ["ShowOwnershipBalanceOWA", "ShowOwnershipPercentOWA", "ShowSecurityDepositsOWA"];
   public disableButton: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   public columns: string[] = ["Partners", "Property", "ContributionAmount"];
   constructor(
      private ownerContributionsService: OwnerContributionsService,
      private dialogCreationService: DialogCreationService,
      private errorMessageService: ErrorMessageService,
      private router: Router
   ) {}

   ngOnInit() {
      this.getData();
   }

   getData() {
      if (this.showBalance) {
         this.columns.push("BankBalance");
      }
      if (this.showSecurityDeposit) {
         this.columns.push("SecurityDepositHeldTotal");
      }

      if (this.showPercentageOwnerShip) {
         this.columns.push("PercentageOfOwnership");
      }

      if (this.showReserve) {
         this.columns.push("Reserve");
      }

      this.comment = "";
      const contributions = this.ownerContributionsService.get();
      contributions.pipe(takeUntil(this.unsubscribe)).subscribe(
         (result) => {
            if (result) {
               // we can't use ValueSourceTypes.Enum here, because there are two eEpayPaymentType enums, and it uses the wrong one
               this.contributionTypes = new ValueSourceModel();
               this.contributionTypes.Type = ValueSourceTypes.Static;
               this.contributionTypes.StaticValues = [];
               this.contributionTypes.ValuesToExclude = [EpayPaymentType.SavedPaymentInformation];

               if (result.IsAccountOnFileavailable) {
                  this.contributionTypes.StaticValues.push(
                     new SelectorItemModel(PaymentTypeSelection.AccountOnFile, "Account on File")
                  );
               }
               if (result.IsACHPaymentEnabled) {
                  this.contributionTypes.StaticValues.push(
                     new SelectorItemModel(PaymentTypeSelection.Checking, "ACH - Checking")
                  );
               }
               if (result.IsACHPaymentEnabled) {
                  this.contributionTypes.StaticValues.push(
                     new SelectorItemModel(PaymentTypeSelection.Savings, "ACH - Savings")
                  );
               }
               if (result.IsCreditCardPaymentEnabled) {
                  this.contributionTypes.StaticValues.push(
                     new SelectorItemModel(PaymentTypeSelection.CreditCard, "Credit Card")
                  );
               }
               if (result.IsDebitCardPaymentEnabled && result.IsCreditCardPaymentEnabled) {
                  this.contributionTypes.StaticValues.push(
                     new SelectorItemModel(PaymentTypeSelection.DebitCard, "Debit Card")
                  );
               }
               this.paymentTypeSelection = this.contributionTypes.StaticValues[0].value;
               if (this.paymentTypeSelection === PaymentTypeSelection.Checking) {
                  this.selectedContributionType = EpayPaymentType.ACH;
               } else if (this.paymentTypeSelection === PaymentTypeSelection.Savings) {
                  this.selectedContributionType = EpayPaymentType.ACH;
               } else {
                  this.selectedContributionType = this.contributionTypes.StaticValues[0].value;
               }
               this.disableButton = LcsProgressButtonStatus.Unset;
               this.contributions.next(result.Items);
               this.ownerContributionPaymentDetailModel.ContributionType = this.selectedContributionType;
            }
         },
         (error) => {
            this.errorMessageService.triggerHttpErrorMessage(error);
            this.router.navigate(["/dashboard"]);
         }
      );
   }

   setContributionType(paymentTypeSelection: PaymentTypeSelection) {
      this.paymentTypeSelection = paymentTypeSelection;
      if (this.paymentTypeSelection === PaymentTypeSelection.Checking) {
         this.selectedContributionType = EpayPaymentType.ACH;
      } else if (this.paymentTypeSelection === PaymentTypeSelection.Savings) {
         this.selectedContributionType = EpayPaymentType.ACH;
      } else {
         this.selectedContributionType = this.paymentTypeSelection.valueOf();
      }
      this.contributionTypeSelected.next(this.selectedContributionType);
      this.ownerContributionPaymentDetailModel = new OwnerContributionPaymentDetailViewModel();
      this.ownerContributionPaymentDetailModel.ContributionType = this.selectedContributionType;
   }

   refresh(refresh: boolean) {
      if (refresh) {
         this.getData();
      }
   }

   onClickContinue() {
      this.disableButton = LcsProgressButtonStatus.InProgress;
      this.ownerContributionPaymentDetailModel.EpayACH = new OwnerContributionACHPaymentViewModel();
      this.ownerContributionPaymentDetailModel.EpayACH.AccountTypes = new Array<EpayAccountType>();
      if (this.paymentTypeSelection === 3) {
         this.ownerContributionPaymentDetailModel.EpayACH.AccountTypeID = 6;
      }
      if (this.paymentTypeSelection === 4) {
         this.ownerContributionPaymentDetailModel.EpayACH.AccountTypeID = 7;
      }

      this.ownerContributionPaymentDetailModel.EpayCC = new OwnerContributionCCPaymentViewModel();
      this.ownerContributionPaymentDetailModel.EpayCC.AccountTypes = new Array<EpayAccountType>();

      this.contributions.pipe(takeUntil(this.unsubscribe)).subscribe((a) => {
         if (a) {
            this.ownerContributionPaymentDetailModel.Items = new Array<OwnerContributionModel>();
            this.ownerContributionPaymentDetailModel.Amount = 0;
            this.ownerContributionPaymentDetailModel.Items = this.ownerContributionPaymentDetailModel.Items.concat(a);
            this.ownerContributionPaymentDetailModel.Items.forEach((item) => {
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               if (item.ContributuionAmount > 0) {
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  this.ownerContributionPaymentDetailModel.Amount += item.ContributuionAmount;
               }
            });
         }
      });

      this.ownerContributionPaymentDetailModel.Comment = this.comment;

      if (this.ownerContributionPaymentDetailModel.ContributionType === EpayPaymentType.DebitCard) {
         this.ownerContributionPaymentDetailModel.IsDebitCard = true;
         //this.ownerContributionPaymentDetailModel.ContributionType = EpayPaymentType.CreditCard;
      }

      this.ownerContributionsService
         .getContributionDetails(this.ownerContributionPaymentDetailModel)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (values) => {
               if (values) {
                  this.ownerContributionPaymentDetailModel = values.body;

                  if (this.ownerContributionPaymentDetailModel.Items.length > 0) {
                     if (this.selectedContributionType === EpayPaymentType.ACH) {
                        const config = new DialogConfigurationModel();
                        config.componentInstance = EpayACHComponent;
                        config.title = "Make Contribution";
                        this.dialogCreationService.open(
                           config,
                           this.ownerContributionPaymentDetailModel,
                           null,
                           null,
                           null,
                           () => {
                              this.disableButton = LcsProgressButtonStatus.Unset;
                           },
                           (v) => {
                              this.refresh(v);
                           }
                        );
                     } else if (
                        this.selectedContributionType === EpayPaymentType.CreditCard ||
                        this.selectedContributionType === EpayPaymentType.DebitCard
                     ) {
                        const config = new DialogConfigurationModel();
                        config.componentInstance = EpayCCComponent;
                        config.title = "Make Contribution";
                        this.dialogCreationService.open(
                           config,
                           this.ownerContributionPaymentDetailModel,
                           null,
                           null,
                           null,
                           () => {
                              this.disableButton = LcsProgressButtonStatus.Unset;
                           },
                           (v) => {
                              this.refresh(v);
                           }
                        );
                     } else if (this.selectedContributionType === EpayPaymentType.AccountOnFile) {
                        const config = new DialogConfigurationModel();
                        config.componentInstance = EpayComponent;
                        config.title = "Make Contribution";
                        this.dialogCreationService.open(
                           config,
                           this.ownerContributionPaymentDetailModel,
                           null,
                           null,
                           null,
                           () => {
                              this.disableButton = LcsProgressButtonStatus.Unset;
                           },
                           (v) => {
                              this.refresh(v);
                           }
                        );
                     }
                  }
               }
            },
            (_err) => {
               this.errorMessageService.triggerHttpErrorMessage(_err);
               this.disableButton = LcsProgressButtonStatus.Error;
            }
         );
   }
}
