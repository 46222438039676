import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";

import { FilterOption } from "./filter-option.model";
import { OrderingOption } from "./ordering-option.model";

export class PostableGetModel {
   embeds: EntityEmbedOption | Array<string | EntityEmbedOption> | null;
   filters: Array<FilterOption | string> | null;
   fields: Array<string | EntityField> | null;
   orderingOptions: Array<string | OrderingOption> | null;
   getOptions: Array<string | EntityGetOption> | null;
   pageSize: number | null;
   pageNumber: number | null;
}
