import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DialogRegistrationService } from "@lcs/dialog/services/dialog-registration.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { AccountSignupViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/account-signup-view.model";
import { ChangeEmailAddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/change-emailaddress.model";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({
   providedIn: "root",
})
export class ChangeEmailAddressService implements OnDestroy {
   public changeEmailAddressResults = new BehaviorSubject<ChangeEmailAddressModel>(new ChangeEmailAddressModel());
   public error = new BehaviorSubject<boolean>(false);

   private endpoint = "changeemailaddress";
   private unsubscribe: Subject<void> = new Subject<void>();
   private email = new ChangeEmailAddressModel();

   constructor(
      private apiService: ApiService,
      private httpClient: HttpClient,
      private apiRequestHelperService: ApiRequestHelperService,
      private errorMesssageService: ErrorMessageService,
      private router: Router,
      private authenticationService: AuthenticationService,
      private dialogRegistrationService: DialogRegistrationService
   ) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   public GetChangeEmailAddressModelInfo(
      sourceStep: string,
      webUserid: number,
      locationid: number
   ): Observable<ChangeEmailAddressModel> {
      const additionalParameters = new Array<string>();
      const endpoint = this.endpoint + "/changeemailaddress";
      additionalParameters.push("sourceStep=" + sourceStep);
      additionalParameters.push("webUserid=" + webUserid);
      additionalParameters.push("locationid=" + locationid);
      return this.apiService.directGet(endpoint, additionalParameters).pipe(map((response) => response.body));
   }

   public changeEmailAddress(emailaddressModel: ChangeEmailAddressModel) {
      const endpoint = this.endpoint + "/changeemailaddress";
      this.httpClient
         .post<ChangeEmailAddressModel>(
            this.apiRequestHelperService.url + endpoint,
            JSON.stringify(emailaddressModel),
            {
               headers: this.apiRequestHelperService.initialHeaders,
               withCredentials: true,
            }
         )
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               if (success) {
                  this.email = success;
                  if (this.email.WorkflowSteps === WorkflowSteps[WorkflowSteps.MergeUsers]) {
                     this.changeEmailAddressResults.next(this.email);
                     this.router.navigateByUrl("/mergeusers");
                  } else if (
                     this.email.Message == null &&
                     this.email.WorkflowSteps === WorkflowSteps[WorkflowSteps.VerificationPending]
                  ) {
                     this.changeEmailAddressResults.next(this.email);
                     this.authenticationService.logOutWithoutNavigation();
                     this.dialogRegistrationService.closeAllModals();
                     this.router.navigateByUrl("/password/verifypending");
                  } else {
                     this.errorMesssageService.triggerErrorMessage(this.email.Message);
                     this.changeEmailAddressResults.next(this.email);
                  }
               }
            },
            (err) => {
               this.errorMesssageService.triggerHttpErrorMessage(err);
               this.changeEmailAddressResults.next(this.email);
               this.error.next(true);
            }
         );
   }

   public CreateNewAccount(emailaddressModel: ChangeEmailAddressModel): Observable<AccountSignupViewModel> {
      const endpoint = this.endpoint + "/createnewaccount";
      return this.httpClient.post<AccountSignupViewModel>(
         this.apiRequestHelperService.url + endpoint,
         JSON.stringify(emailaddressModel),
         {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         }
      );
   }
}
