import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { ValidationModule } from "../validation/validation.module";
import { SecureAnnualIncomeInputComponent } from "./secure-annual-income-input.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule],
   declarations: [SecureAnnualIncomeInputComponent],
   exports: [SecureAnnualIncomeInputComponent],
})
export class SecureAnnualIncomeInputModule {}
