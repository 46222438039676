<div
   class="tooltip-arrow-container"
   [ngStyle]="styles"
>
   <div class="block-bottom-shadow"></div>

   <div class="tooltip-arrow-shadow-container">
      <div
         #tooltipArrow
         class="tooltip-arrow"
      >
      </div>
   </div>
</div>