<div class="report-parameter-label">
   <label class="parameter-label">
      {{ parameterLabel }}
   </label>
</div>
<div class="values-wrapper">
   <div class="individual-report-parameter-option parameter-padding">
      <lcs-dynamic-selector-report-parameter
         [name]="name + '-months'"
         [parameter]="months"
      ></lcs-dynamic-selector-report-parameter>
   </div>
   <div class="input-container">
      <div class="individual-report-parameter-option parameter-padding">
         <lcs-numeric-input-report-parameter
            [name]="name + '-age-from'"
            [parameter]="ageFrom"
         ></lcs-numeric-input-report-parameter>
      </div>
      <div class="individual-report-parameter-option parameter-padding">
         <lcs-numeric-input-report-parameter
            [name]="name + '-age-to'"
            [parameter]="ageTo"
         ></lcs-numeric-input-report-parameter>
      </div>
   </div>
   <div class="individual-report-parameter-option parameter-padding">
      <lcs-date-report-parameter
         [name]="name + '-age-as-of'"
         [displayName]="ageAsOf.DisplayName"
         [parameter]="ageAsOf"
      ></lcs-date-report-parameter>
   </div>
   <div class="individual-report-parameter-option parameter-padding">
      <lcs-checkbox-report-parameter
         [name]="name + '-missing-birthdays'"
         [displayName]="'Show Missing Birthdays'"
         [parameter]="missingBirthDays"
      ></lcs-checkbox-report-parameter>
   </div>
</div>