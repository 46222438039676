<div
   class="login-input-row"
   *ngIf="authModel.IsLocationInfoMissing === false && authModel.IsShowLocations === true; else error"
>
   <i class="material-icons">
      place
   </i>
   <div
      *ngIf="locationModelList.length > 0"
      class="location-dropdown-value-container"
   >
      <select
         [(ngModel)]="selectedlocation"
         (ngModelChange)="setLocation(selectedlocation)"
         name="locationSelect"
         id="locationSelect"
         class="login-location-dropdown"
      >
         <option
            *ngFor="let locations of locationModelList"
            [ngValue]="locations.LocationID"
         >
            {{locations.FriendlyName}}
         </option>
      </select>
   </div>
</div>
<ng-template #error>
   <p class="login-location-single">{{authModel.Message}}</p>
</ng-template>