import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";

import { ChangeEmailAddressModule } from "../change-emailaddress/change-emailaddress.module";
import { LayoutModule } from "../layout/layout.module";
import { TileModule } from "../tile/tile.module";
import { ValidatePasswordFormatModule } from "./../validate-password-format/validate-password-format.module";
import { ChangePasswordComponent } from "./change-password.component";

@NgModule({
   declarations: [ChangePasswordComponent],
   imports: [
      CommonModule,
      FormsModule,
      LayoutModule,
      TextBoxModule,
      LcsProgressButtonModule,
      SelectModule,
      ChangeEmailAddressModule,
      TileModule,
      ValidatePasswordFormatModule,
   ],
   exports: [ChangePasswordComponent],
})
export class ChangePasswordModule {}
