/**
 * Takes a value of some type that could be null or undefined and returns the value
 * only if it not null or undefined, otherwise it throws an error.
 *
 * This can be used to wrap code that is written to expect a value, but its declared
 * type includes null or undefined and therefore creates errors if strictNullChecks
 * is enabled.
 */
export function valueOrThrow<T>(value: T | null | undefined, additionalContext?: string): T {
   if (value === undefined) {
      throw new Error(`Unexpected undefined value! ${additionalContext}`);
   }

   if (value === null) {
      throw new Error(`Unexpected null value! ${additionalContext}`);
   }

   return value;
}

/**
 * Takes an array of some type T an index value and an optional contextMessage to add
 * additional context in the event an error is thrown.  Method will always return
 * a value of type T or throw an error.
 *
 * An error is thrown if:
 * - the array is null or undefined
 * - value passed to array is not an array
 * - index is invalid
 * - value at index is null or undefined
 */
export function valueAtArrayIndexOrThrow<T>(
   array: Array<T | null | undefined> | null | undefined,
   index: number,
   additionalContext?: string
): T {
   additionalContext = additionalContext ?? "";

   if (array === undefined) {
      throw new Error(`Unexpected undefined array! ${additionalContext}`);
   }

   if (array === null) {
      throw new Error(`Unexpected null array! ${additionalContext}`);
   }

   if (!Array.isArray(array)) {
      throw new Error(`Expected an array but did not get one! ${additionalContext}`);
   }

   if (index < 0 || index >= array.length) {
      throw new Error(`Invalid index [${index}] for array of length [${array.length}]! ${additionalContext}`);
   }

   const value: T | null | undefined = array[index];

   if (value === undefined) {
      throw new Error(`Unexpected undefined value at index[${index}]! ${additionalContext}`);
   }

   if (value === null) {
      throw new Error(`Unexpected null value at index[${index}]! ${additionalContext}`);
   }

   return value;
}
