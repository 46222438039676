import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AssignedToUserSelectorDirective } from "./assigned-to-user-selector.directive";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
   ],
   declarations: [
      AssignedToUserSelectorDirective,
   ],
   exports: [
      AssignedToUserSelectorDirective,
   ],
})
export class AssignedToUserSelectorModule { }
