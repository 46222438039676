<div
   #datePickerWrapper
   class="flex-container date-range-wrapper"
   [class.child-input-has-focus]="childInputHasFocus"
   [class.child-input-has-error]="childInputHasError"
>
   <div class="date-range-date-picker-wrapper">
      <lcs-date-picker
         #StartDate="ngModel"
         (inputFocus)="setFocus(true)"
         (inputBlur)="setFocus(false)"
         [disabled]="disabled"
         [ngModel]="value?.startDate"
         [placeholder]="'From'"
         (ngModelChange)="onStartDateChange($event)"
         [lcsValidate]="startValidation"
         [ngModelOptions]="{ standalone: standalone }"
         [name]="startName"
         [displayName]="'Start Date'"
         [dateFormat]="dateStartFormat"
         [view]="dateView"
      ></lcs-date-picker>
   </div>
   <div class="date-range-date-picker-wrapper">
      <lcs-date-picker
         #EndDate="ngModel"
         (inputFocus)="setFocus(true)"
         (inputBlur)="setFocus(false)"
         [disabled]="disabled"
         [ngModel]="value?.endDate"
         [placeholder]="'To'"
         (ngModelChange)="onEndDateChange($event)"
         [lcsValidate]="endValidation"
         [ngModelOptions]="{ standalone: standalone }"
         [name]="endName"
         [displayName]="'End Date'"
         [dateFormat]="dateEndFormat"
         [view]="dateView"
      ></lcs-date-picker>
   </div>
   <div
      *ngIf="!hideAdditionalOptions"
      (mousedown)="toggleDateRangeOverlay($event)"
      class="date-span-button"
      [class.date-span-button-focused]="showOverlayPanel"
   >
      <i class="material-icons date-range-open-overlay-icon">{{ showOverlayPanel ? "clear" : "reorder" }}</i>
   </div>
</div>

<lcs-overlay-panel
   #overlayPanelComponent
   [(show)]="showOverlayPanel"
   [parentElement]="datePickerWrapper"
   [innerElementSelector]="'.date-range-wrapper'"
   [toggleOnClick]="false"
   [showOnFocus]="false"
   [showOnClick]="false"
>
   <div class="date-range-overlay-wrapper">
      <div
         *ngIf="!isPeriod; else periodOptions"
         class="date-range-button-grid"
      >
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.PrevDay)"
         >
            <i class="material-icons ">keyboard_arrow_left</i>
         </div>
         <div
            class="date-range-button today"
            (click)="selectRelativeDate(relativeDayOptions.Today)"
         >
            Today
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.NextDay)"
         >
            <i class="material-icons ">keyboard_arrow_right</i>
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.PrevWeek)"
         >
            <i class="material-icons ">keyboard_arrow_left</i>
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.ThisWeek)"
         >
            This Week
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.WeekToDate)"
         >
            WTD
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.NextWeek)"
         >
            <i class="material-icons ">keyboard_arrow_right</i>
         </div>

         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.PrevMonth)"
         >
            <i class="material-icons ">keyboard_arrow_left</i>
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.ThisMonth)"
         >
            This Month
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.MonthToDate)"
         >
            MTD
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.NextMonth)"
         >
            <i class="material-icons ">keyboard_arrow_right</i>
         </div>

         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.PrevYear)"
         >
            <i class="material-icons ">keyboard_arrow_left</i>
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.ThisYear)"
         >
            This Year
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.YearToDate)"
         >
            YTD
         </div>
         <div
            class="date-range-button"
            (click)="selectRelativeDate(relativeDayOptions.NextYear)"
         >
            <i class="material-icons ">keyboard_arrow_right</i>
         </div>
      </div>
      <ng-template #periodOptions>
         <div class="date-range-button-grid period">
            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.PrevMonth)"
            >
               <i class="material-icons ">keyboard_arrow_left</i>
            </div>
            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.ThisMonth)"
            >
               This Period
            </div>
            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.NextMonth)"
            >
               <i class="material-icons ">keyboard_arrow_right</i>
            </div>

            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.PrevYear)"
            >
               <i class="material-icons ">keyboard_arrow_left</i>
            </div>
            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.ThisYear)"
            >
               This Year
            </div>
            <div
               class="date-range-button"
               (click)="selectRelativeDate(relativeDayOptions.NextYear)"
            >
               <i class="material-icons ">keyboard_arrow_right</i>
            </div>
         </div>
      </ng-template>
      <div
         class="date-range-option-selector"
         *ngIf="hasOtherOptions"
      >
         <lcs-select
            lcsLegacySingleSelector
            [name]="moreOptionsName"
            [displayName]="'Other Options'"
            [valueSource]="optionsValueSource"
            [ngModel]="currentOtherOptionSelection"
            (ngModelChange)="optionSelected($event)"
            [ngModelOptions]="{ standalone: true }"
            [parentOverlayPanelRef]="overlayPanelComponent"
         >
         </lcs-select>
      </div>
   </div>
</lcs-overlay-panel>