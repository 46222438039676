import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityFilterField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/filter-field";

import { OrderingDirections } from "../enumerations/generated/ordering-directions.enum";

export class OrderingOption {
   Name: string;
   Description: string;
   TableNameOrAlias: string;
   ColumnName: string;
   OrderingDirection: OrderingDirections;

   constructor(name?: string | EntityField, orderingDirection?: OrderingDirections) {
      if ((name as EntityFilterField).Identifier) {
         this.Name = (name as EntityFilterField).Identifier;
      } else {
         this.Name = name as string;
      }
      if (orderingDirection) {
         this.OrderingDirection = orderingDirection;
      }
   }

   toApiString(): string {
      const name = encodeURIComponent(this.escapeInvalidFilterOptionValueCharacters(this.Name));

      let result = name;
      if (this.OrderingDirection) {
         const direction = encodeURIComponent(
            this.escapeInvalidFilterOptionValueCharacters(
               OrderingDirections[this.OrderingDirection].toString().toLowerCase()
            )
         );
         result += `:${direction}`;
      }
      return result;
   }

   private escapeInvalidFilterOptionValueCharacters(value: string): string {
      if (value === "") {
         return '""';
      }
      const backlash = "\\";
      const doubleQuote = '"';

      value = value.replace(backlash, backlash + backlash);
      value = value.replace("'", backlash + "'");
      value = value.replace(doubleQuote, backlash + doubleQuote);
      value = value.replace("(", backlash + "(");
      value = value.replace(")", backlash + ")");

      value = `${value}`;

      return value;
   }

   static toApiString(orderingOptions?: Array<string | OrderingOption> | null): string | null {
      if (orderingOptions == null) {
         return null;
      }

      if (!(orderingOptions instanceof Array)) {
         orderingOptions = [orderingOptions];
      }

      return orderingOptions
         .map((orderingOption) => {
            if (OrderingOption.isOrderingOption(orderingOption)) {
               return orderingOption.toApiString();
            } else {
               return orderingOption;
            }
         })
         .join(",");
   }

   static isOrderingOption(obj: any): obj is OrderingOption {
      return obj.Name !== undefined && obj.toApiString !== undefined;
   }
}
