import { Component, ElementRef, OnDestroy, Renderer2, ViewChild, ViewContainerRef } from "@angular/core";
import { StartupService } from "@lcs/application-status/startup.service";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { ZIndexContextService } from "@lcs/styles/z-index-context.service";
import { AuthenticationStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-status.service";
import { AuthenticationStep } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-step.enum";
import { Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "./authentication/authentication.service";
import { LocationsQueryStringValuesService } from "./authentication/locations-query-string-values.service";
import { SystemWebPreferencesSessionService } from "./session/systemwebpreference-session.service";
import { TopBarService } from "./top-bar/top-bar.service";

@Component({
   selector: "owa-root",
   templateUrl: "./app.component.html",
   styleUrls: ["./app.component.css"],
   providers: [ZIndexContextService],
})
export class AppComponent implements OnDestroy {
   @ViewChild("pageHeader") pageHeader: ElementRef;
   title = "OWA";
   currentYear = new Date().getFullYear();
   private unsubscribe = new Subject<void>();
   private overrideDefaultHeaderImageOWA: string = "false";
   private owaCustomHeaderBackgroundColor: string = "#F2F5FA";
   // private themeColor: string;

   constructor(
      private startupService: StartupService,
      private dialogCreationService: DialogCreationService,
      private viewContainerRef: ViewContainerRef,
      private authenticationStatusService: AuthenticationStatusService,
      private locationsQueryStringValuesService: LocationsQueryStringValuesService,
      private topBarService: TopBarService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private renderer: Renderer2,
      private authenticationService: AuthenticationService
   ) {
      const browser = window.navigator.userAgent;
      const msie = browser.indexOf("MSIE ");
      const trident = browser.indexOf("Trident/");
      const isIE = msie > 0 || trident > 0;

      if (!isIE) {
         this.dialogCreationService.initialize(this.viewContainerRef);

         this.configureSessionDeathEvents();

         this.startupService.initialize();
         this.getSystsemWebPreference();
      } else {
         alert(
            "The Internet Explorer browser is not currently supported. Please use one of the following browsers: Chrome, Firefox, or Edge."
         );
         window.history.go(-1);
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   private configureSessionDeathEvents() {
      this.authenticationStatusService.registerAuthenticationStatusChangeListener(
         this.processAuthenticationStatusChange.bind(this)
      );
   }

   private processAuthenticationStatusChange(step: AuthenticationStep) {
      if (step === AuthenticationStep.ReauthenticationNeeded) {
         this.locationsQueryStringValuesService.redirectWithLocationInformation(true);
         this.authenticationStatusService.updateAuthenticationStatus(AuthenticationStep.LoggingOut);
      }
      if (step === AuthenticationStep.LoggingOut) {
         this.topBarService.topBarIsVisible.next(false);
      }
   }

   public getSystsemWebPreference() {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               this.overrideDefaultHeaderImageOWA =
                  prefs.find((a) => a.Name === "OverrideDefaultHeaderImageOWA") != null
                     ? // @ts-ingore
                       // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                       prefs.find((a) => a.Name === "OverrideDefaultHeaderImageOWA").Value.toLowerCase()
                     : "false";
               if (this.overrideDefaultHeaderImageOWA === "true") {
                  // @ts-ingore
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  this.owaCustomHeaderBackgroundColor =
                     prefs.find((a) => a.Name === "OWACustomHeaderBackgroundColor") != null
                        ? prefs.find((a) => a.Name === "OWACustomHeaderBackgroundColor")?.Value.toString()
                        : "#F2F5FA";
                  this.renderer.setStyle(
                     this.pageHeader.nativeElement,
                     "background-color",
                     this.owaCustomHeaderBackgroundColor
                  );
               }
               this.authenticationService.setTheme(prefs);
            }
         }
      );
   }
}
