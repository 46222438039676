import { Component, OnInit } from "@angular/core";
import { ActionBarService } from "@lcs/action-bar/action-bar.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ExpressContextHelp } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-context-help.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { SignableDocumentPacketStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/signable-document-packet-status.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { SignableDocumentPacketSummariesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packet-summaries.service";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
   selector: "owa-signable-documents-list",
   templateUrl: "./signable-documents-list.component.html",
   styleUrls: ["./signable-documents-list.component.css"],
})
export class SignableDocumentsListComponent implements OnInit {
   isLoading = new Observable<boolean>();
   signableDocumentPackets: Observable<Array<SignableDocumentPacketSummaryModel>>;
   private _isLoading = new BehaviorSubject<boolean>(true);
   private _signableDocumentPackets = new BehaviorSubject<Array<SignableDocumentPacketSummaryModel>>(
      new Array<SignableDocumentPacketSummaryModel>()
   );

   constructor(
      private signableDocumentPacketSummariesService: SignableDocumentPacketSummariesService,
      private errorMessageService: ErrorMessageService,
      private actionBarService: ActionBarService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.signableDocumentPackets = this._signableDocumentPackets.asObservable();
      this.actionBarService.actionBarHelp.next(ExpressContextHelp.SignableDocuments);
   }

   ngOnInit() {
      this._isLoading.next(true);
      this.retrieveSignableDocumentPackets();
   }

   retrieveSignableDocumentPackets() {
      const filterOptions = new Array<FilterOption>();
      filterOptions.push(
         new FilterOption(
            "Status",
            FilterOperations.In,
            [
               SignableDocumentPacketStatus.Published,
               SignableDocumentPacketStatus.PendingAcceptance,
               SignableDocumentPacketStatus.Completed,
            ],
            null,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            ExpressDataTypes.Numeric
         )
      );
      const embedOptions = [
         "SignableDocuments",
         "SignableDocuments.SignatureBlockGroups",
         "SignableDocuments.SignatureBlocks",
         "SignableDocuments.Webusers",
         "SignableDocuments.SignableDocumentFields",
      ];

      this.signableDocumentPacketSummariesService.getCollection(filterOptions, embedOptions).subscribe(
         (signableDocumentPacketSummaries) => {
            if (signableDocumentPacketSummaries) {
               this._signableDocumentPackets.next(signableDocumentPacketSummaries);
               this._isLoading.next(false);
            }
         },
         (_err) => {
            this.errorMessageService.triggerHttpErrorMessage(_err);
            this._isLoading.next(false);
         }
      );
   }
}
