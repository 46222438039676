import { CDK_ROW_TEMPLATE, CdkHeaderRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
   selector: "lcs-header-row, tr[lcs-header-row]",
   template: CDK_ROW_TEMPLATE,
   changeDetection: ChangeDetectionStrategy.OnPush,
   exportAs: "lcsHeaderRow",
   providers: [{ provide: CdkHeaderRow, useExisting: LcsHeaderRowComponent }],
})
export class LcsHeaderRowComponent extends CdkHeaderRow {
   @HostBinding("class.lcs-header-row") class: boolean = true;

   @HostBinding("attr.role") role: string = "row";
}
