import {
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   Output,
   ViewChild,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { fromEvent, Subject, Subscription, takeUntil } from "rxjs";

@Component({
   selector: "lcs-text-area",
   templateUrl: "text-area.component.html",
})
export class TextAreaComponent extends ValueAccessorBase<string> implements OnDestroy {
   @Input() disabled: boolean;

   @Input() expandable: boolean;

   @Input() name: string;

   @Input() showTooltip: boolean;

   @Input() tooltipOverride: string;

   @Input() overlayValue: string;

   @Input() textAreaCustomStyle = {};

   @Input() textAreaWrapperCustomStyle = {};

   @Input() maxLength: string;

   @Input() placeholder: string = "";

   @Output() focused = new EventEmitter<boolean>();

   @ViewChild("overlayTextArea") overlayTextArea: ElementRef;

   @ViewChild("expandableTextBox") expandableTextBox: ElementRef;

   @ViewChild("textArea") textArea: ElementRef;

   show: boolean;

   textAreaFocused: boolean;

   overlayPanelValue: string;

   private keyPressSubscription: Subscription;

   private unsubscribe = new Subject<void>();

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onTextAreaBlur() {
      this.textAreaFocused = false;
      this.focused.emit(false);
      this.propagateTouched();
   }

   onTextAreaFocus() {
      this.textAreaFocused = true;
      this.focused.emit(true);
   }

   onExpandableTextboxFocus() {
      this.overlayPanelValue = this.value;
      this.show = true;
   }

   onOverlayFocusOut() {
      this.value = this.overlayPanelValue;
      this.show = false;
   }

   showChanged(show: boolean) {
      if (this.keyPressSubscription) {
         this.keyPressSubscription.unsubscribe();
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Subscriptio... Remove this comment to see the full error message
         this.keyPressSubscription = null;
      }
      this.show = show;
      if (this.show) {
         setTimeout(() => {
            this.overlayTextArea.nativeElement.focus();
            this.overlayTextArea.nativeElement.select();
            this.keyPressSubscription = fromEvent(this.overlayTextArea.nativeElement, "keydown")
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((e: KeyboardEvent) => {
                  this.handleOverlayTextAreaKeypress(e);
               });
         });
      }
   }

   focus() {
      if (this.textArea) {
         this.textArea?.nativeElement?.focus();
      }
   }

   private handleOverlayTextAreaKeypress(event: KeyboardEvent) {
      if (event.key === "Tab") {
         event.preventDefault();
         event.stopPropagation();
         this.focusNextControl(event.shiftKey);
      }
   }

   private focusNextControl(shiftHeld: boolean): any {
      const focussableElements =
         'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

      const expandableTextBox = this.expandableTextBox;
      const focusableElements = Array.prototype.filter.call(
         document.querySelectorAll(focussableElements),
         function (element) {
            return element.offsetWidth > 0 || element.offsetHeight > 0 || element === expandableTextBox.nativeElement;
         }
      );
      const index = focusableElements.indexOf(this.expandableTextBox.nativeElement);
      if (index > -1) {
         let nextElementIndex = 0;
         if (shiftHeld) {
            if (index === 0) {
               nextElementIndex = focusableElements.length - 1;
            } else {
               nextElementIndex = index - 1;
            }
         } else {
            if (index === focusableElements.length - 1) {
               nextElementIndex = 0;
            } else {
               nextElementIndex = index + 1;
            }
         }
         const nextElement = focusableElements[nextElementIndex] || focusableElements[0];
         nextElement.focus();
      }
   }
}
