<div class="charge-type-order-report-parameter">
   <div class="report-parameter-label">
      <label
         class="parameter-label"
         [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      >
         {{ parameter?.DisplayName }}
      </label>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="parameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
   <lcs-sortable-list
      [items]="chargeTypeItems"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      (itemsSorted)="onItemsSorted($event)"
   >
   </lcs-sortable-list>
</div>