<ng-template #udvFilterSelector>
   <div class="filter filter-selector">
      <label class="datatable-filter-label-section">User Defined Values</label>
      <lcs-select
         lcsStaticSetSelector
         #udfSelector
         *ngIf="udfSelectorItems"
         [ngModel]="currentUserDefinedFieldID"
         [placeholder]="'Select a user defined field'"
         (ngModelChange)="onUserDefinedFieldSelected()"
         [ngModelOptions]="{ standalone: true }"
         [items]="udfSelectorItems"
      >
      </lcs-select>
   </div>
</ng-template>
<div
   class="udv-filters"
   *ngIf="userDefinedValueFilterDefinitions && userDefinedValueFilterDefinitions.length > 0"
>
   <lcs-datatable-user-defined-value-filter
      *ngFor="let filterDefinition of userDefinedValueFilterDefinitions"
      class="filter"
      [filterDefinition]="filterDefinition"
      [filterOperations]="filterOperations"
      [showRemoveIcon]="allowMultipleFilters"
      (removeClicked)="removeField(filterDefinition)"
      (valueChange)="onValueChange()"
   ></lcs-datatable-user-defined-value-filter>
</div>
<ng-container #udvFilterContainer></ng-container>