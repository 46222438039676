import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";

import { DataTypes } from "./data-types.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class FilterFieldInformationModel {
   DataType: DataTypes;
   FilterDefinition: string;
   FilterName: string;
   IsAutogenerated: boolean;
   Operations: Array<FilterOperations>;
   PropertyName: string;
}
