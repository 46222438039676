import { Component, OnInit } from "@angular/core";
import { OwnerProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-prospect.model";
import { OwnerProspectsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-prospects.service";
import { Observable } from "rxjs";

import { UpdateAccountInformationService } from "../../update-account-information/update-account-information.service";

@Component({
   selector: "owa-dashboard-owner-prospect-info",
   templateUrl: "./dashboard-tile-owner-prospect-info.component.html",
})
export class DashboardOwnerProspectInfoComponent implements OnInit {
   observableOwnerProspect: Observable<OwnerProspectModel>;
   ownerProspect: OwnerProspectModel;

   constructor(
      private ownerProspectsService: OwnerProspectsService,
      private updatePersonalInfoService: UpdateAccountInformationService
   ) {}

   ngOnInit() {
      this.getData();
      this.updatePersonalInfoService.refresh.subscribe((res) => {
         if (res) {
            this.getData();
            this.updatePersonalInfoService.refresh.next(false);
         }
      });
   }

   getData() {
      this.observableOwnerProspect = this.ownerProspectsService.get();
      this.observableOwnerProspect.subscribe((res) => {
         this.ownerProspect = res;
      });
   }
}
