import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { SignableDocumentPacketStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/signable-document-packet-status.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { SignableDocumentPacketSummariesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packet-summaries.service";

@Component({
   selector: "owa-signable-documents-dashboard",
   templateUrl: "./signable-documents-dashboard.component.html",
   styleUrls: ["./signable-documents-dashboard.component.css"],
   providers: [SignableDocumentPacketSummariesService],
})
export class SignableDocumentsDashboardComponent implements OnInit {
   signableDocumentPackets: Array<SignableDocumentPacketSummaryModel>;
   message: string;
   hasDocumentsToSign: boolean = false;

   constructor(
      private signableDocumentPacketSummariesService: SignableDocumentPacketSummariesService,
      private errorMessageService: ErrorMessageService
   ) {}

   ngOnInit() {
      this.retrieveSignableDocumentPackets();
   }

   retrieveSignableDocumentPackets() {
      const filterOptions = new Array<FilterOption>();
      filterOptions.push(
         new FilterOption(
            "Status",
            FilterOperations.In,
            [
               SignableDocumentPacketStatus.Published,
               SignableDocumentPacketStatus.PendingAcceptance,
               SignableDocumentPacketStatus.Completed,
            ],
            null,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            ExpressDataTypes.Numeric
         )
      );

      this.signableDocumentPacketSummariesService.getCollection(filterOptions).subscribe(
         (signableDocumentPacketSummaries) => {
            if (signableDocumentPacketSummaries) {
               this.signableDocumentPackets = signableDocumentPacketSummaries;
               this.parsePacketInformation(signableDocumentPacketSummaries);
            }
         },
         (_err) => {
            this.errorMessageService.triggerHttpErrorMessage(_err);
         }
      );
   }

   parsePacketInformation(signableDocumentPacketSummaries: Array<SignableDocumentPacketSummaryModel>) {
      let message = "";
      let nextExpirationDate: Date;
      let availableDocumentCount: number = 0;
      let completedDocumentCount: number = 0;
      signableDocumentPacketSummaries.forEach((sdp) => {
         if (
            sdp.Status === SignableDocumentPacketStatus.Published ||
            sdp.Status === SignableDocumentPacketStatus.PendingAcceptance
         ) {
            availableDocumentCount++;
         } else if (sdp.Status === SignableDocumentPacketStatus.Completed || sdp.IsViewOnly) {
            completedDocumentCount++;
         }
         if (!sdp.IsViewOnly && sdp.Status === SignableDocumentPacketStatus.Published) {
            this.hasDocumentsToSign = true;
         }
         const expirationDate = Date.parse(sdp.ExpirationDate.toString());
         if (
            sdp.Status === SignableDocumentPacketStatus.Published &&
            expirationDate > 0 &&
            (!nextExpirationDate || Date.parse(nextExpirationDate.toString()) > expirationDate)
         ) {
            nextExpirationDate = sdp.ExpirationDate;
         }
      });
      if (availableDocumentCount === 1 || (completedDocumentCount === 1 && availableDocumentCount <= 1)) {
         message = "A new document is available for you to ";
         if (this.hasDocumentsToSign) {
            message += "sign";
         } else {
            message += "view";
         }

         // @ts-ignore ts-migrate(2454) FIXME: Variable 'nextExpirationDate' is used before being... Remove this comment to see the full error message
         if (nextExpirationDate && Date.parse(nextExpirationDate.toString()) > 0) {
            message +=
               " that is going to expire on " + formatDate(nextExpirationDate, "MM/dd/yy", GlobalsService.locale);
         }
      } else if (availableDocumentCount > 1 || completedDocumentCount > 1) {
         message = "There are multiple documents available for you to ";
         if (this.hasDocumentsToSign) {
            message += "sign";
         } else {
            message += "view";
         }

         // @ts-ignore ts-migrate(2454) FIXME: Variable 'nextExpirationDate' is used before being... Remove this comment to see the full error message
         if (nextExpirationDate && Date.parse(nextExpirationDate.toString()) > 0) {
            message +=
               ". The next document will expire on " +
               formatDate(nextExpirationDate, "MM/dd/yy", GlobalsService.locale);
         }
      }
      message += ".";
      this.message = message;
   }
}
