import { CdkColumnDef } from "@angular/cdk/table";
import { ContentChild, Directive, Input } from "@angular/core";

import { LcsHeaderCellDirective } from "./header-cell.directive";

@Directive({
   selector: "[lcsColumnDef]",
   providers: [
      { provide: CdkColumnDef, useExisting: LcsColumnDefDirective },
      { provide: "MAT_SORT_HEADER_COLUMN_DEF", useExisting: LcsColumnDefDirective },
   ],
})
export class LcsColumnDefDirective extends CdkColumnDef {
   @Input() set lcsColumnDef(value: string) {
      this.name = value;
   }

   get lcsColumnDef(): string {
      return this.name;
   }

   @Input()
   set width(width: number) {
      this._width = width;
      this.updateCellWidths();
   }
   get width(): number {
      return this._width;
   }

   @Input() priority: number;

   @Input() fillRemainingSpace: boolean;

   @Input() sticky: boolean;

   @Input() skipForHiddenRows: boolean;

   @ContentChild(LcsHeaderCellDirective, { static: true }) lcsHeaderCell: LcsHeaderCellDirective;

   overrideToFill: boolean = false;

   set hidden(hidden: boolean) {
      this._hidden = hidden;
      if (this.lcsHeaderCell) {
         this.lcsHeaderCell.setHidden(this._hidden);
      }
   }
   get hidden(): boolean {
      return this._hidden;
   }

   get widthStyle(): string | null {
      return this.fillRemainingSpace || this.overrideToFill ? null : `${this.width}em`;
   }

   private _hidden: boolean;

   private _width: number;

   private updateCellWidths(): void {
      if (this.lcsHeaderCell) {
         this.lcsHeaderCell.widthStyle = this.widthStyle;
      }
   }
}
