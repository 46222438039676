import { formatDate } from "@angular/common";
import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { GlobalsService } from "@lcs/core/globals.service";
import { QuickFilterModel } from "@lcs/filters/quick-filter.model";
import { IndexedDBService } from "@lcs/indexed-db/indexed-db.service";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { EnumerationInformationService } from "projects/libraries/lcs/src/lib/utils/enumeration-information.service";
import { BehaviorSubject, catchError, forkJoin, map, mergeMap, Observable, of, Subject, takeUntil } from "rxjs";

import { ApiService } from "../core/api.service";
import { EntityType } from "../enumerations/generated/entity-type.enum";
import { ExpressReplacementTags } from "../enumerations/generated/express-replacement-tags.enum";
import { DataTableColumnModel } from "../models/datatable-column.model";
import { EntityTypeInformationModel } from "../models/generated/entity-type-information.model";
import { ExpressColumnDefinitionModel } from "../models/generated/express-column-definition.model";
import { ExpressDefaultListViewFiltersModel } from "../models/generated/express-default-list-view-filters.model";
import { ExpressPropertyBindingModel } from "../models/generated/express-property-binding.model";
import { OrderingOption } from "../models/ordering-option.model";
import { CurrentUserService } from "../session/current-user.service";
import { SessionStatusService } from "../session/session-status.service";

@Injectable()
export class EntityInformationService implements SessionCacheProvider, OnDestroy {
   cacheKey = "EntityInformationService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   private entityTypeInformationMap: Map<EntityType, EntityTypeInformationModel> = new Map<
      EntityType,
      EntityTypeInformationModel
   >();

   private endpoint = "expressentityinformation";

   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private enumerationInformationService: EnumerationInformationService,
      private sessionStatusService: SessionStatusService,
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService,
      private indexedDBService: IndexedDBService,
      private currentUserService: CurrentUserService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         if (!this.cachePopulated.value) {
            this.populateCache();
         }
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean): void {
      this.entityTypeInformationMap.clear();
      this.cachePopulated.next(false);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
         this.indexedDBService.deleteByKey(IndexedDBService.IDBLocalStorage, this.cacheKey);
      }
   }

   public populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }
      const actionName = "EntityTypeInformationList";
      this.apiService
         .directGet(`${this.endpoint}/${actionName}`)
         .pipe(
            catchError((error) => {
               console.error(`Failed to load "${this.cacheKey}" from server. -- `, error);
               return of(null);
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe({
            next: (entityTypeInfo: any): void => {
               if (entityTypeInfo) {
                  this.processEntityTypeInformation(entityTypeInfo.body);
                  this.cachePopulated.next(true);
               }
            },
         });
   }

   defaultColumnsForEntityListView(entityType: EntityType): Observable<Array<ExpressColumnDefinitionModel>> {
      return of(this.entityTypeInformationMap.get(entityType)?.DefaultColumns).pipe(
         mergeMap((columns: Array<ExpressColumnDefinitionModel>) => {
            const enumInfoObservables = new Array<Observable<any>>();
            if (columns && columns.length > 0) {
               for (const column of columns) {
                  if (column.EnumerationType) {
                     const enumerationInformation = this.enumerationInformationService.getEnumerationInformation(
                        column.EnumerationType
                     );
                     if (enumerationInformation) {
                        enumInfoObservables.push(enumerationInformation);
                     }
                  }
               }
            }
            if (enumInfoObservables.length > 0) {
               return forkJoin(enumInfoObservables).pipe(map(() => columns));
            } else {
               return of(columns);
            }
         })
      );
   }

   defaultFiltersForEntityListView(entityType: EntityType): ExpressDefaultListViewFiltersModel {
      return this.getEntityTypeInformation(entityType).DefaultFilters;
   }

   defaultQuickFilterList(entityType: EntityType): Array<QuickFilterModel> | null {
      if (this.getEntityTypeInformation(entityType).QuickFilters) {
         const quickFilterDefinitions = this.getEntityTypeInformation(entityType).QuickFilters;
         return quickFilterDefinitions.map((dto) => {
            const quickFilter = QuickFilterModel.fromTransferModel(dto);

            if (quickFilter.CheckboxFilterValue === ExpressReplacementTags[ExpressReplacementTags.RMToday]) {
               quickFilter.CheckboxFilterValue = formatDate(new Date().toString(), "MM/dd/yyyy", GlobalsService.locale);
            } else if (
               quickFilter.CheckboxFilterValue === ExpressReplacementTags[ExpressReplacementTags.CurrentUserId]
            ) {
               quickFilter.CheckboxFilterValue = this.currentUserService.currentUser.value?.UserID.toString() ?? "";
            }

            return quickFilter;
         });
      }
      return null;
   }

   getEntityTypeInformation(entityType: EntityType): EntityTypeInformationModel {
      const entityTypeInformationModel: EntityTypeInformationModel | undefined =
         this.entityTypeInformationMap.get(entityType);

      if (!entityTypeInformationModel) {
         throw new Error(`No entity information defined for ${EntityType[entityType]}`);
      }
      return entityTypeInformationModel;
   }

   entityTypeHasSearchEndpoint(entityType: EntityType): boolean {
      if (entityType === EntityType.NotSet) {
         console.warn("NO ENTITY TYPE FOUND!");
         return false;
      }
      return this.getEntityTypeInformation(entityType).EntityHasSearchEndpoint;
   }

   // TODO: this seems unused, should it be deleted?
   getLayoutBindableProperties(entityType: EntityType): Observable<Array<ExpressPropertyBindingModel>> {
      const actionName = "LayoutBindableProperties";
      return this.apiService.get(`${this.endpoint}/${actionName}?entityType=${entityType}`).pipe(
         map((response) => {
            return response.body;
         })
      );
   }

   convertExpressColumnDefinitionsToDataTableColumns(
      columnDefinitions: Array<ExpressColumnDefinitionModel>
   ): Array<DataTableColumnModel> {
      return columnDefinitions.map((cd) => {
         const column = new DataTableColumnModel();
         column.FromExpressColumnDefinitionModel(cd);
         return column;
      });
   }

   getDefaultDataTableColumnsForEntity(entityType: EntityType): Observable<Array<DataTableColumnModel>> {
      return this.defaultColumnsForEntityListView(entityType).pipe(
         map((columnDefinitions) => {
            return columnDefinitions.map((cd) => {
               const column = new DataTableColumnModel();
               column.FromExpressColumnDefinitionModel(cd);
               return column;
            });
         })
      );
   }

   buildEntityEndpoint(
      entityType: EntityType,
      resource?: string,
      entityID?: number,
      isQuickSearch: boolean = false
   ): string {
      const entityTypeInformationModel = this.getEntityTypeInformation(entityType);
      if (!entityTypeInformationModel) {
         throw new Error(
            `Unable to build EntityEndpoint - unable to get EntityInformation for ${EntityType[entityType]}`
         );
      }
      let endpoint = entityTypeInformationModel.ApiUrlString;

      if (entityID) {
         endpoint += `/${entityID}`;
      }
      if (resource) {
         endpoint += `/${resource}`;
      }

      let searchEndpoint = "Search";
      if (isQuickSearch) {
         searchEndpoint = "QuickSearch";
      }
      if (entityTypeInformationModel && entityTypeInformationModel.EntityHasSearchEndpoint && !resource && !entityID) {
         endpoint = `${entityTypeInformationModel.ApiUrlString}/${searchEndpoint}`;
      }
      return endpoint;
   }

   private processEntityTypeInformation(entityTypeInfo: any) {
      this.entityTypeInformationMap = new Map<EntityType, EntityTypeInformationModel>();
      for (const info of entityTypeInfo) {
         const infoModel = info.EntityTypeInformation as EntityTypeInformationModel;
         infoModel.CollectionLabel = info.CollectionLabel;
         infoModel.SingleLabel = info.SingleLabel;
         const entityTypeInformationMapKey = infoModel.EntityType;
         if (infoModel.DefaultOrderingOption) {
            infoModel.DefaultOrderingOption = new OrderingOption(
               infoModel.DefaultOrderingOption.Name,
               infoModel.DefaultOrderingOption.OrderingDirection
            );
         }
         this.entityTypeInformationMap.set(entityTypeInformationMapKey, infoModel);
      }
   }
}
