<lcs-overlay-panel
   #overlayPanelComponent
   [(show)]="show"
   [parentElement]="parentElement"
   [innerElementTags]="innerElementTags"
   [innerElementSelector]="innerElementSelector"
   [alignToRight]="alignToRight"
   [staticOverlay]="staticOverlay"
   [disabled]="disabled"
   [toggleOnClick]="toggleOnClick"
   [showOnFocus]="false"
   [hideOnBlur]="false"
   [hideOnOutsideClick]="true"
   [overlayPanelStaticWidth]="overlayPanelStaticWidth"
   [overlayPanelStaticMinWidth]="overlayPanelStaticMinWidth"
>
   <ng-container *ngIf="show">
      <lcs-validation-error-display-panel
         *ngIf="control?.invalid && (control?.touched || control?.dirty) && !isLoading"
         [displayName]="displayName"
         [errors]="$any(control?.errors)"
      ></lcs-validation-error-display-panel>
      <div class="chart-mappings-container form-container">
         <div
            *ngIf="showMapping"
            class="form-element flex-full"
         >
            <label>Mapping</label>
            <lcs-select
               lcsLegacySingleSelector
               *ngIf="glAccountMapValueSource"
               [valueSource]="glAccountMapValueSource"
               [(ngModel)]="glAccountMapID"
               (ngModelChange)="mapSelectionChanged.emit($event)"
               [name]="'account-map'"
               [parentOverlayPanelRef]="overlayPanelComponent"
            >
            </lcs-select>
         </div>
         <mat-divider class="flex-full"></mat-divider>
         <lcs-loading-overlay [show]="isLoading">
            <div class="form-element multi-selector-search-header flex-wrapper">
               <lcs-checkbox
                  [name]="'select-all-checkbox'"
                  [ngModel]="isAllSelected"
                  (ngModelChange)="allSelected($event)"
                  [disabled]="disabled"
               ></lcs-checkbox>
               <lcs-text-box
                  class="multi-selector-overlay-panel-search"
                  [disabled]="isLoading"
                  [(ngModel)]="searchValue"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholder]="'Search'"
                  [name]="'search-textbox'"
               ></lcs-text-box>
            </div>
            <mat-divider class="flex-full"></mat-divider>
            <cdk-virtual-scroll-viewport
               class="multiselector-wrapper"
               itemSize="22"
               minBufferPx="88"
               maxBufferPx="220"
            >
               <lcs-checkbox
                  *cdkVirtualFor="let item of filteredItems; trackBy: trackByFn"
                  [ngModel]="item.isChecked"
                  [disabled]="disabled"
                  (ngModelChange)="item.isChecked = $event; onItemSelected()"
               >
                  <div
                     class="selector-overlay-item"
                     title="{{ item.displayValue }}"
                  >
                     <div lcsOverlayPanelItem>
                        <span lcsOverlayPanelItemLabel>
                           {{ item.displayValue }}
                        </span>
                     </div>
                  </div>
               </lcs-checkbox>
            </cdk-virtual-scroll-viewport>
         </lcs-loading-overlay>
         <mat-divider class="flex-full"></mat-divider>
         <div class="form-element flex-full">
            <label>From Account</label>
            <lcs-select
               *ngIf="glAccountSelectorsConfigured"
               lcsEntitySelector
               [entityType]="glAccountSelectorEntityType"
               [endpoint]="glAccountSelectorEndpoint"
               [endpointIsSearch]="glAccountSelectorEndpointIsSearch"
               [filters]="glAccountSelectorFilters"
               [addBlankItem]="true"
               [(ngModel)]="fromGlAccountID"
               [name]="'from-account'"
               (ngModelChange)="fromAccountChanged.emit($event)"
               [parentOverlayPanelRef]="overlayPanelComponent"
            >
            </lcs-select>
         </div>
         <div class="form-element flex-full">
            <label>To Account</label>
            <lcs-select
               *ngIf="glAccountSelectorsConfigured"
               lcsEntitySelector
               [entityType]="glAccountSelectorEntityType"
               [endpoint]="glAccountSelectorEndpoint"
               [endpointIsSearch]="glAccountSelectorEndpointIsSearch"
               [filters]="glAccountSelectorFilters"
               [addBlankItem]="true"
               [(ngModel)]="toGlAccountID"
               [name]="'to-account'"
               (ngModelChange)="toAccountChanged.emit($event)"
               [parentOverlayPanelRef]="overlayPanelComponent"
            >
            </lcs-select>
         </div>
         <div class="form-element flex-full">
            <label>Group</label>
            <lcs-select
               #accountGroupSelector
               lcsEnumerationSelector
               enumerationName="eGeneralLedgerAccountType"
               [name]="'account-type'"
               [valuesToExclude]="accountTypeValuesToExclude"
               [addBlankItem]="true"
               [(ngModel)]="glAccountTypeID"
               (ngModelChange)="onAccountGroupChanged()"
               [parentOverlayPanelRef]="overlayPanelComponent"
            ></lcs-select>
         </div>
         <div class="form-element flex-full">
            <div class="multi-selector-search-footer flex-space-between">
               <div class="flex-space-between flex-center">
                  <label class="selected-item-count">{{ selectedItemCount }} selected</label>
               </div>
               <a (click)="clear()">clear</a>
            </div>
         </div>
      </div>
   </ng-container>
</lcs-overlay-panel>