import { formatDate } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import cloneDeep from "lodash/cloneDeep";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { AccountingPeriodYearEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/accounting-period-year.embed-options";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountingPeriodSeriesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period-series.model";
import { AccountingPeriodYearModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period-year.model";
import { AccountingPeriodModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/accounting-period.model";
import { SystemPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/system-preference.model";
import { AccountingPeriodSeriesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/accounting-period-series.service";
import { AccountingPeriodYearsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/accounting-period-years.service";
import { Subject, takeUntil } from "rxjs";

import { DateRangeModel } from "../../../inputs/date-range-picker/date-range.model";
import { ReportParameterComponents } from "../../../reports/report-parameters/report-parameter-components.enum";
import { CurrentSystemPreferencesService } from "../../../session/current-system-preferences.service";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-date-range-and-accounting-period-report-parameter",
   templateUrl: "date-range-and-accounting-period-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class DateRangeAndAccountingPeriodReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() isRelative: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameterComponents = values;
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }
   get reportReportParameterComponents(): ReportReportParameterComponentModel[] {
      return this._reportReportParameterComponents;
   }

   @Input() set reportReportParameters(values: Array<ReportReportParameterViewModel>) {
      this._reportReportParameters = values;
   }

   @Input() dateRangeReportParameterType: ReportParameterComponents = ReportParameterComponents.DateRange;

   @Input() hasAsk: boolean;

   @ViewChild("startYearSelector") startYearSelector: SelectComponent;

   @ViewChild("endYearSelector") endYearSelector: SelectComponent;

   @ViewChild("startPeriodSelector") startPeriodSelector: SelectComponent;

   @ViewChild("endPeriodSelector") endPeriodSelector: SelectComponent;

   dateRange: DateRangeModel = new DateRangeModel(new Date(), new Date());

   startDateParameter: ReportReportParameterViewModel;

   startDateValueModel: ReportParameterValueModel;

   endDateParameter: ReportReportParameterViewModel;

   endDateValueModel: ReportParameterValueModel;

   startPeriodParameter: ReportReportParameterViewModel;

   startPeriodValueModel: ReportParameterValueModel;

   endPeriodParameter: ReportReportParameterViewModel;

   endPeriodValueModel: ReportParameterValueModel;

   isRunByPeriodParameter: ReportReportParameterViewModel;

   isRunByPeriodValueModel: ReportParameterValueModel;

   dateStartFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   dateEndFormat: string = "mm/dd/yy"; // uses primeNG's date format instead of angular's

   label: string = "Date Range";

   isRunByPeriod: boolean = false;

   selectedSeriesID: number;

   selectedStartYearID: number;

   selectedEndYearID: number;

   yearData: SelectorItemModel[];

   endYearData: SelectorItemModel[];

   selectedStartPeriodID: number;

   startPeriodData: SelectorItemModel[];

   selectedEndPeriodID: number;

   endPeriodData: SelectorItemModel[];

   isSinglePeriodSelected: boolean = true;

   isMultiplePeriodSelected: boolean = false;

   seriesData: SelectorItemModel[];

   private unsubscribe = new Subject<void>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private _reportReportParameterComponents: ReportReportParameterComponentModel[];

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService,
      private currentSystemPreferenceService: CurrentSystemPreferencesService,
      private accountingPeriodSeriesService: AccountingPeriodSeriesService,
      private accountingPeriodYearsService: AccountingPeriodYearsService
   ) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
      this.currentSystemPreferenceService
         .getSystemPreferences([
            SystemPreference.AccountingPeriodEnable,
            SystemPreference.AccountingPeriodDefaultForReports,
         ])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((pref: Map<SystemPreference, SystemPreferenceModel>) => {
            if (
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               GlobalsService.convertStringToBoolean(pref.get(SystemPreference.AccountingPeriodEnable).Value) &&
               // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
               GlobalsService.convertStringToBoolean(pref.get(SystemPreference.AccountingPeriodDefaultForReports).Value)
            ) {
               this.isRunByPeriod = true;
            }
         });
      this.loadSeriesData();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
   multiplePeriodSelected(value: boolean) {
      this.isSinglePeriodSelected = !value;
      this.updateReportParameterValues();
   }
   singlePeriodSelected(value: boolean) {
      this.isMultiplePeriodSelected = !value;
      if (this.startPeriodSelector.selectedItem) {
         this.dateRange.endDate = this.startPeriodSelector.selectedItem.additionalData.get("EndDate");
      }
      this.updateReportParameterValues();
   }
   initializeParameters() {
      if (this.dateRangeReportParameterType === ReportParameterComponents.DateRangeWithAccountingPeriod2) {
         this.startDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartDate2
         )[0];
         this.endDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndDate2
         )[0];

         this.startPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartPeriodID2
         )[0];
         this.endPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndPeriodID2
         )[0];

         this.isRunByPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.RunByPeriod2
         )[0];
         this.label = "Second Date Range";
      } else {
         this.startDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartDate
         )[0];
         this.endDateParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndDate
         )[0];
         this.startPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.StartPeriodID
         )[0];
         this.endPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.EndPeriodID
         )[0];

         this.isRunByPeriodParameter = this._reportReportParameters.filter(
            (p) => p.ReportParameterID === ReportParameter.RunByPeriod
         )[0];
      }
      if (
         this.startDateParameter &&
         this.startDateParameter.ReportParameterValueSource &&
         this.startDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateStartFormat = this.startDateParameter.ReportParameterValueSource.FormatString;
      }
      if (
         this.endDateParameter &&
         this.endDateParameter.ReportParameterValueSource &&
         this.endDateParameter.ReportParameterValueSource.FormatString
      ) {
         this.dateEndFormat = this.endDateParameter.ReportParameterValueSource.FormatString;
      }
   }

   isRunByPeriodChanged(runByPeriod: boolean) {
      this.isRunByPeriod = runByPeriod;
      this.updateReportParameterValues();
   }

   dateRangeChanged(event: DateRangeModel) {
      this.dateRange = event;
      this.updateReportParameterValues();
   }

   updateReportParameterValues() {
      let startDateValue: string;
      let endDateValue: string;
      let startPeriodIdValue: number = 0;
      let endPeriodIdValue: number = 0;
      let runByPeriodValue = this.isRunByPeriod;
      if (this.dateRange) {
         if (this.dateRange.startDate) {
            startDateValue = formatDate(this.dateRange.startDate, "MM/dd/yyyy", GlobalsService.locale);
         }
         if (this.dateRange.endDate) {
            endDateValue = formatDate(this.dateRange.endDate, "MM/dd/yyyy", GlobalsService.locale);
         }
      }

      if (this.isRunByPeriod) {
         if (this.selectedStartPeriodID) {
            startPeriodIdValue = this.selectedStartPeriodID;
            endPeriodIdValue =
               this.isMultiplePeriodSelected && this.selectedEndPeriodID
                  ? this.selectedEndPeriodID
                  : this.selectedStartPeriodID;
         } else {
            runByPeriodValue = false;
         }
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'startDateValue' is used before being ass... Remove this comment to see the full error message
      if (this.startDateValueModel.value !== startDateValue) {
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'startDateValue' is used before being ass... Remove this comment to see the full error message
         this.startDateValueModel.value = startDateValue;
         this.reportParametersService.updateReportParameterValue(this.startDateValueModel);
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'endDateValue' is used before being assig... Remove this comment to see the full error message
      if (this.endDateValueModel.value !== endDateValue) {
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'endDateValue' is used before being assig... Remove this comment to see the full error message
         this.endDateValueModel.value = endDateValue;
         this.reportParametersService.updateReportParameterValue(this.endDateValueModel);
      }

      this.reportParametersService.updateReportParameterValue(
         new ReportParameterValueModel(this.startPeriodParameter.ReportParameterID, startPeriodIdValue)
      );
      this.reportParametersService.updateReportParameterValue(
         new ReportParameterValueModel(this.endPeriodParameter.ReportParameterID, endPeriodIdValue)
      );
      this.reportParametersService.updateReportParameterValue(
         new ReportParameterValueModel(this.isRunByPeriodParameter.ReportParameterID, runByPeriodValue)
      );
   }

   onAccountingSeriesChange(seriesId: number) {
      this.selectedSeriesID = seriesId;
      this.setYearData(false);
   }

   onAccountingYearStartChange(yearId: number) {
      this.selectedStartYearID = yearId;
      this.setStartPeriodData(this.startYearSelector.selectedItem.additionalData.get("AccountingPeriods"), false);

      this.endYearData = this.yearData.filter(
         (x) => x.additionalData.get("StartDate") >= this.startYearSelector.selectedItem.additionalData.get("StartDate")
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedEndYearID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedEndPeriodID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'SelectorIte... Remove this comment to see the full error message
      this.endPeriodData = null;
   }

   onAccountingPeriodStartChange(periodId: number) {
      this.selectedStartPeriodID = periodId;
      this.dateRange.startDate = this.startPeriodSelector.selectedItem.additionalData.get("StartDate");
      this.dateRange.endDate = this.startPeriodSelector.selectedItem.additionalData.get("EndDate");

      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedEndYearID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedEndPeriodID = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'SelectorIte... Remove this comment to see the full error message
      this.endPeriodData = null;

      this.updateReportParameterValues();
   }

   onAccountingYearEndChange(yearId: number) {
      this.selectedEndYearID = yearId;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.selectedEndPeriodID = null;

      this.setEndPeriodData(
         this.endYearSelector.selectedItem.additionalData
            .get("AccountingPeriods")
            .filter(
               (x) => new Date(x.StartDate) >= this.startPeriodSelector.selectedItem.additionalData.get("StartDate")
            )
      );
   }

   onAccountingPeriodEndChange(periodId: number) {
      this.selectedEndPeriodID = periodId;
      this.dateRange.endDate = this.endPeriodSelector.selectedItem.additionalData.get("EndDate");
      this.updateReportParameterValues();
   }

   private initializeReportParameterValues() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.startDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.startDateParameter.ReportParameterID
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.endDateValueModel = this.reportParametersService.reportParameterValues.get(
         this.endDateParameter.ReportParameterID
      );

      this.updateReportParameterStatus(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ReportParameterValueModel | unde... Remove this comment to see the full error message
         this.reportParametersService.reportParameterValues.get(ReportParameter.BEFOREPOSTING)
      );

      this.dateRange = new DateRangeModel(this.startDateValueModel.value, this.endDateValueModel.value);

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameterStatus(updatedValue);
         });
   }

   private updateReportParameterStatus(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this.startDateParameter.ReportID,
         updatedValue,
         this.startDateValueModel.reportParameter
      );
      if (isDisabled) {
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }

   private loadSeriesData() {
      this.accountingPeriodSeriesService
         .getCollection()
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((seriesModels: AccountingPeriodSeriesModel[]) => {
            this.seriesData = seriesModels.map(
               (model) => new SelectorItemModel(model.AccountingPeriodSeriesID, model.Name)
            );
            const selectedSeries = this.selectedSeriesID
               ? seriesModels.find((x) => x.AccountingPeriodSeriesID === this.selectedSeriesID)
               : seriesModels.find((x) => x.IsDefault);
            if (selectedSeries) {
               this.selectedSeriesID = selectedSeries.AccountingPeriodSeriesID;
               this.setYearData(true);
            }
         });
   }

   private setYearData(selectDefaultStartYear: boolean) {
      const filterOption = new Array<FilterOption>();
      filterOption.push(
         new FilterOption("AccountingPeriodSeriesID", FilterOperations.EqualTo, [this.selectedSeriesID])
      );
      this.accountingPeriodYearsService
         .getCollection(filterOption, [AccountingPeriodYearEmbedOptions.AccountingPeriods])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((yearsModels: AccountingPeriodYearModel[]) => {
            this.yearData = yearsModels.map((model) => {
               const yearModel = new SelectorItemModel(model.AccountingPeriodYearID, model.Name);
               yearModel.additionalData.set("AccountingPeriods", model.AccountingPeriods);
               yearModel.additionalData.set("StartDate", new Date(model.StartDate));
               yearModel.additionalData.set("EndDate", new Date(model.EndDate));
               return yearModel;
            });

            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const selectedYearModel = yearsModels.find((x) => {
               const startDate = new Date(x.StartDate);
               const endDate = new Date(x.EndDate);
               return startDate.getTime() <= now.getTime() && now.getTime() <= endDate.getTime();
            });
            if (selectDefaultStartYear && selectedYearModel) {
               this.selectedStartYearID = selectedYearModel.AccountingPeriodYearID;
               this.endYearData = yearsModels
                  .filter((x) => x.Year >= selectedYearModel.Year)
                  .map((model) => {
                     const yearModel = new SelectorItemModel(model.AccountingPeriodYearID, model.Name);
                     yearModel.additionalData.set("AccountingPeriods", model.AccountingPeriods);
                     return yearModel;
                  });
               this.setStartPeriodData(selectedYearModel.AccountingPeriods, true);
            } else {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
               this.selectedEndYearID = null;
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
               this.selectedStartYearID = null;
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
               this.selectedStartPeriodID = null;
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
               this.selectedEndPeriodID = null;
               this.endYearData = cloneDeep(this.yearData);
               this.updateReportParameterValues();
            }
         });
   }

   private setStartPeriodData(accountingPeriodModels: AccountingPeriodModel[], selectDefaultStartPeriod: boolean) {
      this.startPeriodData = accountingPeriodModels.map((model: AccountingPeriodModel) => {
         const selectorModel = new SelectorItemModel(model.AccountingPeriodID, model.Name);
         selectorModel.additionalData.set("StartDate", new Date(model.StartDate));
         selectorModel.additionalData.set("EndDate", new Date(model.EndDate));
         return selectorModel;
      });
      let defaultStartPeriod: AccountingPeriodModel;
      if (selectDefaultStartPeriod) {
         const now = new Date();
         now.setHours(0, 0, 0, 0);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'AccountingPeriodModel | undefined' is not as... Remove this comment to see the full error message
         defaultStartPeriod = accountingPeriodModels.find((x) => {
            const startDate = new Date(x.StartDate);
            const endDate = new Date(x.EndDate);
            return startDate.getTime() <= now.getTime() && now.getTime() <= endDate.getTime();
         });
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'defaultStartPeriod' is used before being... Remove this comment to see the full error message
      this.dateRange.startDate = defaultStartPeriod ? new Date(defaultStartPeriod.StartDate) : new Date();
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'defaultStartPeriod' is used before being... Remove this comment to see the full error message
      this.dateRange.endDate = defaultStartPeriod ? new Date(defaultStartPeriod.EndDate) : new Date();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      this.selectedStartPeriodID = defaultStartPeriod ? defaultStartPeriod.AccountingPeriodID : null;
      this.updateReportParameterValues();
   }

   private setEndPeriodData(accountingPeriodModels: AccountingPeriodModel[]) {
      this.endPeriodData = accountingPeriodModels.map((model: AccountingPeriodModel) => {
         const selectorModel = new SelectorItemModel(model.AccountingPeriodID, model.Name);
         selectorModel.additionalData.set("StartDate", new Date(model.StartDate));
         selectorModel.additionalData.set("EndDate", new Date(model.EndDate));
         return selectorModel;
      });
   }
}
