import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   Output,
   ViewChild,
} from "@angular/core";
import { NgControl, UntypedFormControl } from "@angular/forms";
import { ErrorMessageModel } from "@lcs/error-message/error-message.model";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";

@Component({
   selector: "lcs-text-box",
   templateUrl: "text-box.component.html",
})
export class TextBoxComponent extends ValueAccessorBase<string> implements AfterViewInit {
   @Input() public set placeholder(value: string) {
      this._placeholder = value || "";
   }
   public get placeholder() {
      return this._placeholder;
   }

   @Input() inputType = "text";

   @Input() showTextBoxSuggestions: boolean = true;

   @Input() autofocus: boolean;

   @Input() displayName: string;

   @Input() icon: string = "";

   @Input() hideTitle: boolean = false;

   @Input() tooltipOverride: string;

   @Input() inputCustomStyle: {};

   @Input() numericMaxValueAllowed: any;

   @Input() set dontAllowNegatives(value: any) {
      if (value === true) {
         this.min = 0;
      }
   }

   @Input() showErrorOverlay: boolean = true;

   @Input() textBoxCustomStyle: object | null = null;

   @Output() blurEvent = new EventEmitter<any>();

   @Output() focusEvent = new EventEmitter<any>();

   @Output() enterKeyEvent = new EventEmitter<any>();

   @ViewChild("textBox", { static: false }) inputEl: ElementRef;

   inputFocused: boolean;

   min: any = null;

   control: UntypedFormControl;

   private _placeholder: string = "";

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl,
      private errorMessageService: ErrorMessageService
   ) {
      super(changeDetectorRef, ngControl);
   }

   ngAfterViewInit() {
      if (this.autofocus) {
         this.inputEl.nativeElement.focus();
      }
   }

   onFocus(event: FocusEvent) {
      this.focusEvent.emit(event);
   }

   onBlur(event: FocusEvent) {
      if (this.numericMaxValueAllowed) {
         if (Number(this.numericMaxValueAllowed) < Number(this.value)) {
            const errorMessage: ErrorMessageModel = {
               Title: "Invalid Value",
               Message: `Value must be less than or equal to ${this.numericMaxValueAllowed}.`,
               Dismissible: true,
               AdditionalDetails: "",
               closeCallBack: () => {
                  this.value = this.numericMaxValueAllowed;
               },
            };
            this.errorMessageService.triggerError(errorMessage);
         }
      } else {
         this.blurEvent.emit(event);
         this.propagateTouched();
      }
   }

   onEnterKey(event: Event) {
      this.enterKeyEvent.emit(event as MouseEvent);
   }

   onModelChange(value: string) {
      this.value = value;
   }
}
