import { WebuserTokenTypes } from "../enumerations/webuser-token-types.enum";

export class VerificationTokenInformationModel {
   TokenType: WebuserTokenTypes;
   LocationID: number;
   WebUserID: number;
   UserName: string;
   DesiredUserName: string;
   AccountDisplayID: number;
   UsernameIsVerified: boolean;
   CorporateID: string;
   CreateDateTime: Date;
   Token: string;
}
