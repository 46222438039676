<ng-container *ngFor="let pdf of selectedPdfs">
   <div
      class="pdf-container"
      [hidden]="pdf?.UniqueIdentifier !== selectedPdf?.UniqueIdentifier"
   >
      <owa-pdf-viewer
         [downloadUrl]="pdf.DownloadUrl"
         [errorHeader]="errorHeader"
         [errorInformation]="errorInformation"
         [errorIcon]="errorIcon"
         [errorContentTemplate]="errorContentTemplate"
      >
      </owa-pdf-viewer>
   </div>
</ng-container>