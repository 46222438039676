////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressReplacementTags {
   ID = 1,
   EntityType = 2,
   RMToday = 3,
   RMNow = 4,
   CurrentUserId = 5,
}
