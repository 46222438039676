import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { LocationsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/locations.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Injectable({ providedIn: "root" })
export class CurrentLocationService implements SessionCacheProvider, OnDestroy {
   cacheKey = "CurrentLocationService";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject(false);

   // @ts-ignore ts-migrate(2322) FIXME: Type 'BehaviorSubject<null>' is not assignable to ... Remove this comment to see the full error message
   currentLocation: BehaviorSubject<LocationModel> = new BehaviorSubject(null);

   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private locationsService: LocationsService,
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService,
      private sessionStatusService: SessionStatusService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.currentLocation.next(null);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
      }
   }

   public populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      const storedValue = this.localStorageService.getItem(this.cacheKey);
      if (storedValue) {
         this.currentLocation.next(JSON.parse(storedValue));
         this.cachePopulated.next(true);
      } else {
         this.apiService
            .directGet(this.locationsService.getCurrentLocationUrl())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (response) => {
                  const results: LocationModel = response.body;
                  this.currentLocation.next(results);
                  this.cachePopulated.next(true);
                  this.localStorageService.setItem(this.cacheKey, JSON.stringify(results));
               },
               (_) => {
                  this.cacheMonitorService.reportError(this.cacheKey);
               }
            );
      }
   }
}
