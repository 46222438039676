import { Injectable, TemplateRef } from "@angular/core";
import { ExpressFooterButtonModel } from "@lcs/footer/express-footer-button.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressActionViewModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-action-view-modes.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressActionEntityEventModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action-entity-event.model";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";
import { BehaviorSubject } from "rxjs";

import { ConstantsService } from "../core/constants.service";
import { ContextModel } from "./context.model";
import { CreateUpdateUserInfoModel } from "./create-update-user-info.model";
import { LinkedInformationModel } from "./linked-information.model";

@Injectable()
export class ContextService {
   actionEntityEvents: BehaviorSubject<ExpressActionEntityEventModel[]>;

   context: BehaviorSubject<ContextModel | null>;

   footerButtons: BehaviorSubject<Array<ExpressFooterButtonModel> | null>;

   createAndUpdateUserInfo: BehaviorSubject<CreateUpdateUserInfoModel | null>;

   additionalInformation: BehaviorSubject<string | null>;

   linkedInformation: BehaviorSubject<LinkedInformationModel | null>;

   currentViewMode: BehaviorSubject<ExpressActionViewModes>;

   /**
    * @deprecated use CurrentFooterService > setAdditionalFooterStyling
    */
   footerButtonAdditionalClasses: BehaviorSubject<string | null>;

   /**
    * @deprecated use CurrentFooterService > setMaxNumberOfButtons
    */
   maxFooterButtonsMobile: BehaviorSubject<number | null>;

   /**
    * @deprecated use CurrentFooterService > setInformationTemplate
    */
   footerInformationTemplate: BehaviorSubject<TemplateRef<any> | null>;

   get action(): ExpressActionModel | null {
      const context = this.context.value;
      return context ? context.Action : null;
   }

   get actionID(): ExpressActions {
      const action = this.action;
      return action ? action.ExpressActionID : ExpressActions.NotSet;
   }

   get entityType(): EntityType {
      const action = this.action;
      return action ? action.EntityType : EntityType.NotSet;
   }

   get parentAction(): ExpressActionModel | null | undefined {
      const context = this.context.value;
      return context ? context.ParentAction : null;
   }

   get parentActionID(): ExpressActions {
      const parentAction = this.parentAction;
      return parentAction ? parentAction.ExpressActionID : ExpressActions.NotSet;
   }

   get parentEntityID(): number | null {
      const context = this.context.value;
      return context ? context.ParentEntityID : ConstantsService.NullFK;
   }

   set parentEntityID(val: number | null) {
      if (this.context.value) {
         this.context.value.ParentEntityID = val;
      }
   }

   get parentEntityType(): EntityType {
      const context = this.context.value;

      if (context && context.ParentEntityType) {
         return context.ParentEntityType;
      } else if (context && context.ParentAction) {
         return context.ParentAction.EntityType;
      }

      return this.entityType;
   }

   get isRootAction(): boolean {
      // Modal Dialogs and Slideup Panels will have a parent action id, the main (parent) page will not.
      return this.parentActionID === ExpressActions.NotSet ? true : false;
   }

   constructor() {
      this.context = new BehaviorSubject<ContextModel | null>(null);
      this.footerButtons = new BehaviorSubject<Array<ExpressFooterButtonModel> | null>(null);
      this.maxFooterButtonsMobile = new BehaviorSubject<number | null>(null);
      this.footerButtonAdditionalClasses = new BehaviorSubject<string>("");
      this.createAndUpdateUserInfo = new BehaviorSubject<CreateUpdateUserInfoModel | null>(null);
      this.actionEntityEvents = new BehaviorSubject(new Array<ExpressActionEntityEventModel>());
      this.additionalInformation = new BehaviorSubject<string | null>(null);
      this.linkedInformation = new BehaviorSubject<LinkedInformationModel | null>(null);
      this.footerInformationTemplate = new BehaviorSubject<TemplateRef<any> | null>(null);
      this.currentViewMode = new BehaviorSubject<ExpressActionViewModes>(ExpressActionViewModes.FullPage);
   }

   setContext(context: ContextModel, actionEntityEvents: ExpressActionEntityEventModel[]) {
      this.context.next(context);
      this.actionEntityEvents.next(actionEntityEvents || new Array<ExpressActionEntityEventModel>());
   }
}
