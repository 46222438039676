<ng-container *ngIf="!vendorsReportParameter.IsHidden">
   <div class="report-parameter-label">
      <label
         class="parameter-label"
         [class.disabled]="disabled || (hasAsk === true && vendorsReportParameter.IsAsk === true)"
      >{{ $any(vendorsReportParameter?.PromptText?.length) > 0 ? vendorsReportParameter.PromptText :
         vendorsReportParameter.DisplayName }}
      </label>
      <lcs-report-parameter-ask
         *ngIf="hasAsk"
         [isAsk]="vendorsReportParameter.IsAsk"
         (askChanged)="askChanged($event)"
         [name]="name"
      ></lcs-report-parameter-ask>
   </div>
   <div class="tab-wrapper">
      <div class="single-tab-wrapper">
         <lcs-dynamic-selector-report-parameter
            [hideLabel]="true"
            [name]="name + '-vendor'"
            [disabled]="disabled || (hasAsk === true && vendorsReportParameter.IsAsk === true)"
            [parameter]="vendorsReportParameter"
            [entityValueSourceFilters]="vendorsFilters"
            [selectAllItemsOnFilterChange]="false"
         ></lcs-dynamic-selector-report-parameter>
         <lcs-checkbox-report-parameter
            [name]="name + '-include-inactive-vendor'"
            [disabled]="disabled || (hasAsk === true && vendorsReportParameter.IsAsk === true)"
            [parameter]="includeInactiveVendorsReportParameter"
         ></lcs-checkbox-report-parameter>
      </div>
   </div>
</ng-container>