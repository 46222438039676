import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

@Component({
   selector: "lcs-enum-multi-selector",
   templateUrl: "enum-multi-selector.component.html",
})
export class EnumMultiSelectorComponent extends ValueAccessorBase<any> implements OnChanges {
   @Input() addBlankItem: boolean;

   @Input() allValue: string;

   @Input() filters: Array<FilterOption>;

   @Input() hideLabel: boolean;

   @Input() inlineLabel: boolean;

   @Input() label: string;

   @Input() orderingOptions: Array<string>;

   @Input() set enumName(val: string) {
      if (val && val !== this._enumName) {
         this._enumName = val;
      }
   }

   get enumName(): string {
      return this._enumName;
   }

   @Input() valuesToExclude: Array<string>;

   @Input() valuesToInclude: Array<string>;

   @Input() singleSelectStyleOverride = {};

   @Output() panelClose = new EventEmitter<void>();

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   allSelected: boolean;

   valueSource: ValueSourceModel;

   private _enumName: string;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);

      this.registerOnValueWritten((value: any) => {
         if (!this.valueSource) {
            return;
         }
         if (value) {
            this.valueSource.SelectedValues = Array.isArray(value) ? value : [value];
         } else {
            this.valueSource.SelectedValues = [];
         }
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (
         (changes.enumName && changes.enumName.currentValue !== changes.enumName.previousValue) ||
         (changes.valuesToExclude && changes.valuesToExclude.currentValue !== changes.valuesToExclude.previousValue) ||
         (changes.valuesToInclude && changes.valuesToInclude.currentValue !== changes.valuesToInclude.previousValue)
      ) {
         this.initialize();
      }
   }

   initialize() {
      if (this.enumName) {
         const valueSource = new ValueSourceModel();
         valueSource.Type = ValueSourceTypes.Enumeration;
         valueSource.EnumerationName = this.enumName;
         if (this.label) {
            valueSource.Label = this.label;
         }

         if (this.addBlankItem) {
            valueSource.AddBlankValue();
         }

         valueSource.Filters = new Array<FilterOption>();
         valueSource.ValuesToExclude = this.valuesToExclude;
         valueSource.ValuesToInclude = this.valuesToInclude;
         if (this.filters && this.filters.length > 0) {
            valueSource.Filters = this.filters;
         }
         if (this.orderingOptions) {
            valueSource.OrderingOptions = this.orderingOptions;
         }
         if (this.value) {
            valueSource.SelectedValues = Array.isArray(this.value) ? this.value : [this.value];
         }

         if (this.allValue != null) {
            valueSource.AllValue = this.allValue;
         }

         this.valueSource = valueSource;
      }
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.selectionChange.emit(selectionChange);
   }

   onPanelClose() {
      this.panelClose.emit();
   }

   onChange(value: any) {
      this.value = value;
   }

   onAllSelected(value: boolean) {
      this.allSelected = value;
   }
}
