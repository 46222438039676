import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { OwnerDistributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-distributions.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { OWASessionService } from "../../session/owa-session.service";
import { OwnerDistributionListItemModel } from "../models/owner-distribution-list-item.model";

@Component({
   selector: "owa-owner-distribution-list",
   templateUrl: "./owner-distribution-list.component.html",
   styleUrls: ["./owner-distribution-list.component.css"],
   providers: [DataTableFiltersService],
})
export class OwnerDistributionListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   columns: string[] = ["Date", "Property", "Reference", "Amount"];
   groupRows: boolean = true;
   rowGroupColumns = ["group-header-toggle", "group-header-amount", "group-header-spacer"];

   observableOwnerDistributions: Observable<Array<OwnerDistributionListItemModel>>;
   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   isInfiniteScrollLoading = new Observable<boolean>();
   threshold: string = "30%";
   results: BehaviorSubject<number>;
   totalResults: BehaviorSubject<number>;

   distributionFilters: Array<DataTableFilterModel>;
   filterOperations: Array<FilterOperationModel>;
   appliedSideBarFilters: Array<FilterOption>;
   showFilters: boolean = false;
   groupToggleColspan: number = 1;
   spacerToggleColspan: number = 2;

   private pageNumber: number = 0;
   private scrollPageSize: number = 10;
   private rowHeight: number = 35;
   private initialLoadComplete: boolean = false;

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _observableOwnerDistributions = new BehaviorSubject<Array<any>>(null);
   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);
   private unsubscribe = new Subject<void>();

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setFullYear(new Date().getFullYear() - 1), "MM/dd/yy", "en-US");

   constructor(
      private ownerDistributionService: OwnerDistributionsService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.isInfiniteScrollLoading = this._isInfiniteScrollLoading.asObservable();
      this.observableOwnerDistributions = this._observableOwnerDistributions.asObservable();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.distributionFilters = this.getFilters();
      this.setDefaultDateFilter();
   }

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.distributionFilters.find((f) => f.FilterOption.FilterName === "CheckDate").FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.distributionFilters.find((f) => f.FilterOption.FilterName === "CheckDate").FilterOption
      );
   }

   isFirstInGroup = (index: number, row: any) => {
      const distributions = this._observableOwnerDistributions.value;
      let firstInGroup: boolean = false;
      if (index === 0) {
         firstInGroup = true;
      } else {
         if (distributions[index - 1].CheckDate !== row.CheckDate) {
            firstInGroup = true;
         }
      }
      return firstInGroup;
   };

   toggleRowGroup(groupName: Date) {
      for (const row of this._observableOwnerDistributions.value) {
         if (row.CheckDate === groupName) {
            row.RowGroupExpanded = !row.RowGroupExpanded;
         }
      }
      const groupList = this._observableOwnerDistributions.value.filter((f) => f.CheckDate === groupName);
      for (const row of groupList) {
         this.table.rowsExpanded.set(row, false);
      }
      this.table.renderRows();
   }

   onVisibleColumnsChanged(visibleColumnNames: string[]): void {
      if (visibleColumnNames.length <= 3) {
         this.groupToggleColspan = 2;
         this.rowGroupColumns = ["group-header-toggle", "group-header-amount"];
      } else {
         this.groupToggleColspan = 1;
         this.spacerToggleColspan = 2;
         if (visibleColumnNames.length < this.columns.length + 1) {
            if (visibleColumnNames[0] === "responsive-toggle") {
               this.groupToggleColspan = 2;
               this.spacerToggleColspan = 1;
            }
         }
         this.rowGroupColumns = ["group-header-toggle", "group-header-amount", "group-header-spacer"];
      }
   }

   getGroupCount() {
      const groups = Array.from(new Set(this._observableOwnerDistributions.value.map((item: any) => item.CheckDate)));
      this.results.next(groups.length);
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      const request = this.ownerDistributionService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const distributionList = response.body;
               const newdistributions = [];
               distributionList.forEach((element) => {
                  const item = new OwnerDistributionListItemModel(element);
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'OwnerDistributionListItemModel' ... Remove this comment to see the full error message
                  newdistributions.push(item);
               });
               return newdistributions;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newData =
                  this._observableOwnerDistributions && this._observableOwnerDistributions.value
                     ? this._observableOwnerDistributions.value
                     : [];
               if (this._isInfiniteScrollLoading.value) {
                  newData = newData.concat(results);
               } else {
                  newData = results;
               }
               this._observableOwnerDistributions.next(newData);
               this.getGroupCount();
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll($event): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      if (!this._isLoading.value && (this.totalResults.value === 0 || this.results.value < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true, refreshData);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.distributionFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const checkDateFilter = this.getDataTableFilterModel(
         "CheckDate",
         "Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between]
      );

      const propertyFiltervalueSourceModel = new ValueSourceModel();
      propertyFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      propertyFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      propertyFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "All Ownerships")
      );
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });

      const propertyFilter = this.getDataTableFilterModel(
         "PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         propertyFiltervalueSourceModel
      );

      const filterList = [checkDateFilter, propertyFilter];
      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.distributionFilters = this.resetFilter(this.distributionFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (dataType === ExpressDataTypes.Date) {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.Between, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
