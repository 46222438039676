<lcs-table [dataSource]="billDetailItems" >
   <tr lcs-header-row *lcsHeaderRowDef="billDetailColumns;"></tr>
   <tr lcs-row *lcsRowDef="let row; columns: billDetailColumns"></tr>
      <ng-container lcsColumnDef="Property" [width]="15" [priority]="1">
         <th lcs-header-cell *lcsHeaderCellDef>Property </th>
         <td lcs-cell *lcsCellDef="let row; let index=index">{{row.Property.Name}}</td>
      </ng-container>
      <ng-container lcsColumnDef="Unit" [width]="10" [priority]="2">
            <th lcs-header-cell *lcsHeaderCellDef>Unit</th>
            <td lcs-cell *lcsCellDef="let row; let index=index">{{row.Unit.Name}}</td>
      </ng-container>
      <ng-container lcsColumnDef="Is1099" [width]="4" [priority]="3">
            <th lcs-header-cell *lcsHeaderCellDef>1099</th>
            <td lcs-cell *lcsCellDef="let row; let index=index">{{row.Is1099?'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container lcsColumnDef="ExpenseAccount" [width]="10" [priority]="4">
            <th lcs-header-cell *lcsHeaderCellDef>Expense Account</th>
            <td lcs-cell *lcsCellDef="let row; let index=index">{{row.GLAccount?.Name}}</td>
      </ng-container>
      <ng-container lcsColumnDef="Comment" [width]="10" [priority]="5">
            <th lcs-header-cell *lcsHeaderCellDef>Comment</th>
            <td lcs-cell *lcsCellDef="let row; let index=index">{{row.Comment}}</td>
      </ng-container>
      <ng-container lcsColumnDef="Amount" [width]="8" [priority]="6">
            <th lcs-header-cell *lcsHeaderCellDef>Amount</th>
            <td lcs-cell *lcsCellDef="let row; let index=index" class="numeric">{{row.Amount | currency}}</td>
      </ng-container>
      <ng-container lcsColumnDef="AmountAllocated" [width]="8" [priority]="7">
            <th lcs-header-cell *lcsHeaderCellDef>Amount Due</th>
            <td lcs-cell *lcsCellDef="let row; let index=index" class="numeric">{{row.Amount - row.AmountAllocated | currency}}</td>
      </ng-container>
</lcs-table>