import { ComponentRef, Injectable } from "@angular/core";
import { ConfirmationService } from "@lcs/dialog/services/confirmation.service";
import { FooterComponent } from "@lcs/footer/footer.component";
import { AuthenticationStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-status.service";
import { AuthenticationStep } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-step.enum";

import { DialogComponent } from "../dialog.component";
import { DialogConfigurationModel } from "../dialog.configuration.model";
import { DialogCreationService } from "../services/dialog-creation.service";
import { ConfirmationDialogConfigurationModel } from "./confirmation-dialog-configuration.model";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable({
   providedIn: "root",
})
export class ConfirmationDialogService {
   constructor(
      private dialogCreationService: DialogCreationService,
      private confirmationService: ConfirmationService,
      private authenticationStatusService: AuthenticationStatusService
   ) {
      this.confirmationService.confirmationNeeded.subscribe((configuration: ConfirmationDialogConfigurationModel) => {
         this.openConfirmation(configuration);
      });
   }

   openAlert(message: string, icon?: string | null, callBack?: () => void, title?: string | null) {
      const confirmationDialogSettings = new ConfirmationDialogConfigurationModel();
      confirmationDialogSettings.message = message;

      const dialogConfiguration = new DialogConfigurationModel();
      dialogConfiguration.componentInstance = ConfirmationDialogComponent;
      dialogConfiguration.disableClose = true;

      if (icon != null) {
         confirmationDialogSettings.icon = icon;
      } else {
         confirmationDialogSettings.icon = "warning";
      }
      if (title != null) {
         dialogConfiguration.title = title;
         dialogConfiguration.disableClose = false;
      }

      if (callBack) {
         confirmationDialogSettings.accept = callBack;
      }

      this.dialogCreationService.open(dialogConfiguration, confirmationDialogSettings, (ref) =>
         this.createDialogCallback(ref)
      );
   }

   openConfirmation(confirmationDialogSettings: ConfirmationDialogConfigurationModel) {
      const dialogConfiguration = new DialogConfigurationModel();
      dialogConfiguration.componentInstance = ConfirmationDialogComponent;
      dialogConfiguration.disableClose = confirmationDialogSettings.disableClose;
      dialogConfiguration.title = confirmationDialogSettings.title;
      this.dialogCreationService.open(dialogConfiguration, confirmationDialogSettings, (ref) =>
         this.createDialogCallback(ref)
      );
   }

   openLogoutNowLater(onLogOutLater?: () => void, onLogOutNow?: () => void): void {
      const confirmationDialogSettings = new ConfirmationDialogConfigurationModel();
      confirmationDialogSettings.message = "The saved changes require logging out before they can take effect.";
      confirmationDialogSettings.icon = "warning";
      confirmationDialogSettings.acceptButtonText = "Log out now";
      confirmationDialogSettings.cancelButtonText = "Log out later";
      confirmationDialogSettings.showCancelButton = true;
      if (onLogOutLater) {
         confirmationDialogSettings.cancel = onLogOutLater;
      }
      confirmationDialogSettings.accept = () => {
         if (onLogOutNow) {
            onLogOutNow();
         }
         this.authenticationStatusService.updateAuthenticationStatus(AuthenticationStep.LoggingOut);
      };

      const dialogConfiguration = new DialogConfigurationModel();
      dialogConfiguration.componentInstance = ConfirmationDialogComponent;
      dialogConfiguration.disableClose = true;
      this.dialogCreationService.open(dialogConfiguration, confirmationDialogSettings, (ref) =>
         this.createDialogCallback(ref)
      );
   }

   private createDialogCallback(dialogComponentRef: ComponentRef<DialogComponent>) {
      dialogComponentRef.instance.footerPlaceholder.createComponent(FooterComponent);
   }
}
