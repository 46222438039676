import { Injectable, isDevMode } from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";

import { StorageService } from "./storage.service";

/**
 * Wrapper for Web Storage API - localStorage - allows you to access a Storage object
 * for the Document's origin which is available to all tabs from the same origin (e.g. protocol, domain and port);
 * The stored data is saved across browser sessions.
 *
 * NOTE: RMX clears localStorage when a user logs out and/or changes location.
 *
 * NOTE: localStorage data has no expiration time. However, (localStorage data for
 *       a document loaded in a "private browsing" or "incognito" session is cleared
 *       when the last "private" tab is closed.)
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */
@Injectable({
   providedIn: "root",
})
export class LocalStorageService extends StorageService implements Storage {
   /** Observable which normalizes the storage event to only fire on windows in
    *  the same domain that do not have focus per spec.
    */
   storageChanged$: Observable<StorageEvent>;
   private storageChanged: Subject<StorageEvent> = new Subject<StorageEvent>();
   private unsubscribe: Subject<void> = new Subject<void>();

   constructor() {
      super(StorageService.localStorageType);

      this.storageChanged$ = this.storageChanged.asObservable();

      // NOTE: The StorageEvent is fired whenever a change is made to the Storage object
      //       On most browsers like Chrome, Firefox, Edge, Opera this event is raised under the
      //       following conditions:
      //        - the value for the item has changed
      //        - this change is originating from the same domain, but a different document(i.e.other tab / window)
      //       However, on Safari (at least on versions tested), this event is also fired in the same window
      //       in which the change occurs.
      //
      //       The primary purpose is to provide a way for other windows on the the same domain using the storage
      //       to sync any changes that are made.
      window.addEventListener("storage", (storageEvent: StorageEvent): void => {
         if (!document.hasFocus()) {
            this.storageChanged.next(storageEvent);
         }
      });

      if (isDevMode()) {
         this.storageChanged$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((storageEvent: StorageEvent) => console.log(`DevMode: storageEvent=`, storageEvent));
      }
   }
}
