import { Component, OnDestroy, OnInit } from "@angular/core";
import { EstimateStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eestimatestatus.enum";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { Observable, Subject, takeUntil } from "rxjs";

import { EstimateDetailPreferences } from "../estimate-detail-preferences.interface";
import { EstimateDetailService } from "../estimate-detail.service";

@Component({
   selector: "owa-estimate-detail-container",
   templateUrl: "estimate-detail-container.component.html",
})
export class EstimateDetailContainerComponent implements OnInit, OnDestroy {
   estimate: Observable<EstimateViewModel>;

   loading = new Observable<boolean>();

   preferences: Observable<EstimateDetailPreferences>;

   private unsubscribe = new Subject<void>();

   constructor(estimateDetailService: EstimateDetailService) {
      this.loading = estimateDetailService.loading;
      this.estimate = estimateDetailService.estimate;
      this.preferences = estimateDetailService.preferences;
   }

   ngOnInit(): void {
      this.estimate.pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
         result.CanApprove =
            result.Status === EstimateStatus["Awaiting Approval"].valueOf() && result.IsActive && !result.IsExpired;
      });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
