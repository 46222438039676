import { SelectorItemModel } from "@lcs/selectors/selector-item.model";

import { SelectorItemTransferModel } from "./generated/selector-item-transfer.model";

export class SelectorItemTransferModelConverter {
   static convert(transferModel: SelectorItemTransferModel): SelectorItemModel {
      const selectorItemModel = new SelectorItemModel();
      selectorItemModel.additionalInfoValue = transferModel.AdditionalInfoValue;
      selectorItemModel.displayValue = transferModel.DisplayValue;

      let convertValueToNumber = true;
      if (transferModel.AdditionalData) {
         const value = transferModel.AdditionalData.convertValueToNumber;
         convertValueToNumber = !(value && value === "false");
      }

      if (convertValueToNumber && transferModel.Value !== null && !isNaN(+transferModel.Value)) {
         selectorItemModel.value = +transferModel.Value;
      } else {
         selectorItemModel.value = transferModel.Value;
      }

      if (transferModel.AdditionalData) {
         selectorItemModel.additionalData = new Map<string, any>();
         Object.keys(transferModel.AdditionalData).forEach((key) => {
            if (key !== "convertValueToNumber") {
               selectorItemModel.additionalData.set(key, transferModel.AdditionalData[key]);
            }
         });
      }

      return selectorItemModel;
   }
}
