import { Injectable } from "@angular/core";
import { BudgetYearModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/budget-year.model";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class BudgetYearsService {
   static readonly BudgetYearsResource = "BudgetYears";
   static readonly endpoint = "BudgetYearsView";
   constructor(private apiService: ApiService) {}
   getBudgetYearsCollectionUrl(
      currentYear?: number | null,
      propertyIDs?: string | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (currentYear !== undefined && currentYear !== null) {
         additionalParameters.push("currentYear=" + currentYear);
      }
      if (propertyIDs !== undefined && propertyIDs !== null) {
         additionalParameters.push("propertyIDs=" + propertyIDs);
      }
      return this.apiService.getUrl(
         BudgetYearsService.endpoint + "/BudgetYears",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBudgetYearsCollection(
      currentYear?: number | null,
      propertyIDs?: string | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BudgetYearModel>> {
      return this.apiService.getCollection(
         this.getBudgetYearsCollectionUrl(currentYear, propertyIDs, additionalParameters)
      );
   }
}
