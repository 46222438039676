////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum SystemPreference {
   NotSet = 0,
   StopTransactionPopupInfo = 1,
   DisableAutoUpdateNotification = 2,
   EnableAutoLogin = 3,
   ProratePostedCharges = 4,
   ProrateBasedOn30DaysInMonth = 5,
   AllowMultipleCustomersPerUnit = 6,
   DisableLockingOfHistoryNotes = 7,
   RequireCategoryForHistoryNotes = 8,
   WarnWhenDepositingToBankNotDefault = 9,
   NumberOfBackupsToKeep = 10,
   TitleBarPrefix = 11,
   MultipleCustomerLeasesInList = 12,
   EnableJobCosting = 13,
   DefaultHolidays = 14,
   AddressCustomerPrimary = 15,
   AddressProspectPrimary = 16,
   AddressPropertyPrimary = 17,
   AddressPropertyBilling = 18,
   AddressUnitPrimary = 19,
   AddressVendorPrimary = 20,
   AddressOwnerPrimary = 21,
   AddressAssetManufacturerPrimary = 22,
   AddressDefaultParser = 23,
   AddressProspectContactPrimary = 24,
   AddressCustomerContactPrimary = 25,
   WarnIfPaymentOrChargeIsPriorToPrevious = 26,
   WarnIfPaymentOrChargeIsInFuture = 27,
   EnablePaymentDiscountsOnPaymentScreen = 28,
   UseEnterKeyToMoveBetweenFieldsOnPayments = 29,
   ShowReferenceNumberOnPaymentScreen = 30,
   DefaultPaymentToFullAmountOwed = 31,
   UseReceiptPrinterFormatForReceipt = 32,
   UseManagementCompanyInfoOnReceipts = 33,
   AlwaysPrintReceipt = 34,
   UseNumberedPaymentReceipts = 35,
   StartingReceiptNumber = 36,
   AutoApplyAccountGroupCredits = 37,
   MoveoutConfirmation = 38,
   SecurityDepositChargeType = 39,
   SDInterestCalculationSimple = 40,
   SDInterestCalculationAnnualCompound = 41,
   SDInterestDisbursementMethodCredit = 42,
   SDInterestDisbursementMethodApplyToSD = 43,
   SDInterestDisbursementMethodCreditCharge = 44,
   SDInterestRate = 45,
   SDInterestRateAddYear = 46,
   SDInterestChargeType = 47,
   RequireWriteLettersBeforePostingLateFees = 48,
   RequirePrintoutBeforePostingLateFees = 49,
   DoNotEnableAutoFillOnNewVendorsByDefault = 50,
   DoNotAllowDuplicateVendorNames = 51,
   SynchronizePrimaryContactAndAccountName = 52,
   BackgroundFormatting = 53,
   FormatNamesByLastNameFirstName = 54,
   DoNotShowUnappliedCreditsOnStatements = 55,
   AlternatingShadesColor = 56,
   CheckPrintingTopPortion = 57,
   CheckPrintingMiddlePortion = 58,
   CheckPrintingBottomPortion = 59,
   PadCheckAmountsWithAsterisks = 60,
   PrintCanadianStyleChecks = 61,
   IncludeTheWordDollarsAfterAmountOnChecks = 62,
   ReplaceVendorStubWithOwnerStubOnOwnerPayments = 63,
   PrintChecksInQuickbooksFormat = 64,
   FormatCheckPayeeNameAsUpper = 65,
   PrinterAdjustmentChecks = 66,
   PrinterAdjustmentDeposits = 67,
   PrinterAdjustment1099 = 68,
   PrinterAdjustment1098 = 69,
   PrinterAdjustment1096 = 70,
   PrinterAdjustmentStatements = 71,
   PrinterAdjustmentInvoices = 72,
   PrinterAdjustmentPaymentCoupons = 73,
   PrinterAdjustmentPostCardStatements = 74,
   PrinterAdjustmentAddressLabels5160n5260 = 75,
   PrinterAdjustmentAddressLabels5161n5261 = 76,
   PrinterAdjustmentAddressLabels5162n5262 = 77,
   PrinterAdjustmentShippingLabels5163n5263 = 78,
   PrinterAdjustmentShippingLabels5164n5264 = 79,
   PrinterAdjustmentReturnLabels5167n5267 = 80,
   AdjustStatementFormatForMailingEnvelope = 81,
   EntityTypesEntitySingularName = 82,
   EntityTypesEntityShortName = 83,
   EntityTypesEntityPluralName = 84,
   EntityTypesSubEntitySingularName = 85,
   EntityTypesSubEntityShortName = 86,
   EntityTypesSubEntityPluralName = 87,
   EntityTypesAccountSingularName = 88,
   EntityTypesAccountShortName = 89,
   EntityTypesAccountPluralName = 90,
   ServiceManagerDetailShowIsAllowedToEnter = 91,
   ServiceManagerDetailShowIsSignatureOnFile = 92,
   ServiceManagerDetailShowHasPets = 93,
   ServiceManagerDetailShowOwnerInfo = 94,
   ServiceManagerRegisterDisableTooltipPreview = 95,
   ServiceManagerNotifyEnabledByDefault = 96,
   ServiceManagerRecalculateSalePrice = 97,
   ServiceManagerUseWoNumAsInvoiceNum = 98,
   ServiceManagerWorkOrderPrefix = 99,
   ServiceManagerPrintDetailSignatureBoxText = 100,
   ServiceManagerCloseFromGridShowDialog = 101,
   ServiceManagerDefaultAssignee = 102,
   ServiceManagerBillingUseNameFrom = 103,
   ServiceManagerBillingUseAddressFrom = 104,
   ServiceManagerAutoLinkPropertyForCustomer = 105,
   ServiceManagerAutoLinkUnitForCustomer = 106,
   ServiceManagerAutoLinkPropertyForUnit = 107,
   ServiceManagerAutoLinkCustomerForUnit = 108,
   MakeReadyIssueStatusInProgress = 109,
   AvidXchangeUserName = 110,
   AvidXchangePassword = 111,
   AvidXchangeAccountingSystemID = 112,
   AvidDefaultPaymentTypeToAvidPay = 113,
   AvidUseBatchPostingDate = 114,
   AvidAutomaticPosting = 115,
   AvidPayPayerID = 116,
   AvidVendorSyncIncludePrivateVendors = 117,
   AvidVendorAutomaticWeeklySync = 118,
   AvidVendorAutoSyncCompletionEmail = 119,
   AvidImportBillsUnapproved = 120,
   EpayProvider = 121,
   EpayMerchantID = 122,
   EpayMerchantUsername = 123,
   EpayMerchantPassword = 124,
   HavePayLeaseManageConvenienceFees = 125,
   DisablePaymentByPhone = 126,
   EpayAccountsACH = 127,
   EpayAccountsDinersClub = 128,
   EpayAccountsVisaMasterCard = 129,
   EpayAccountsAmex = 130,
   EpayAccountsDiscover = 131,
   EpaySeparateDeposits = 132,
   EpayACHDepositCutoffTime = 133,
   EpayCCDepositCutoffTime = 134,
   EpayAutoDepositPayments = 135,
   CashPay = 136,
   CashPayDepositCutoffTime = 137,
   EpayManagedDeposits = 138,
   EpayEnableDataRetentionPolicy = 139,
   EpayPurgeEpayDataAfterXDaysOfInactivity = 140,
   EpayMinimumPasswordLength = 141,
   EpayMaximumPasswordLength = 142,
   EpayMaximumPasswordAge = 143,
   EpayPasswordKeyAge = 144,
   EpayPasswordMustContainXNumbers = 145,
   EpayPasswordMustContinaXSpecialChars = 146,
   EpayPasswordMayNotMatchLastXPasswords = 147,
   CHECKSCAN_EPAY = 148,
   EpayUseProxyServer = 149,
   EpayProxyHost = 150,
   EpayProxyPort = 151,
   EpayBatchCalculation = 152,
   EpayBatchSetAmount = 153,
   EpayBatchNeverChargeMoreThan = 154,
   EpayBatchNeverChargeMoreThanAmount = 155,
   EpayBatchCharges = 156,
   EpayEmailConfirmationLetter = 157,
   EpayEmailDeclinedLetter = 158,
   EpayAttachInvoices = 159,
   EpayConfirmationLetter = 160,
   EpayDeclinedLetter = 161,
   EpayDefaultCustomerBank = 162,
   EpayCustomerWarningLimit = 163,
   EpayCustomerConvenienceFeeChargeType = 164,
   EpayCustomerChargeConvenienceFeesInRMForACH = 165,
   EpayCustomerRMACHConvenienceFee = 166,
   EpayCustomerChargeConvenienceFeesInRMForCreditCards = 167,
   EpayCustomerRMCreditCardConvenienceFee = 168,
   EpayCustomerChargeConvenienceFeesInRWAForACH = 169,
   EpayCustomerRWAACHConvenienceFee = 170,
   EpayCustomerChargeConvenienceFeesInRWAForCreditCards = 171,
   EpayCustomerRWACreditCardConvenienceFee = 172,
   EpayOwnerTerminalID = 173,
   EpayOwnerDefaultBankAccount = 174,
   EpayEnableEpayForOwners = 175,
   EpayOwnerWarningLimit = 176,
   EpayOwnerEpayLiabilityAccount = 177,
   EpayOwnerChargeConvenienceFeesInRMForACH = 178,
   EpayOwnerRMACHConvenienceFee = 179,
   EpayOwnerChargeConvenienceFeesInRMForCreditCards = 180,
   EpayOwnerRMCreditCardConvenienceFee = 181,
   EpayOwnerChargeConvenienceFeesInOWAForACH = 182,
   EpayOwnerOWAACHConvenienceFee = 183,
   EpayOwnerChargeConvenienceFeesInOWAForCreditCards = 184,
   EpayOwnerOWACreditCardConvenienceFee = 185,
   EpayEnableEpayForVendors = 186,
   EpayVendorWarningLimit = 187,
   EpayVendorTerminalID = 188,
   EpayVendorDefaultBankAccount = 189,
   EpayCashPayAcceptPartialPayments = 190,
   EpayCashPayAcceptOverPayments = 191,
   EpayCashPayShowAccountGroupBalance = 192,
   OwnerDefaultBankAccount = 193,
   OwnerDefaultExpenseAccount = 194,
   OwnerDefaultDrawAccount = 195,
   OwnerDefaultContributionAccount = 196,
   OwnerDefaultCalculationMethod = 197,
   OwnerEnableOwnerBillApproval = 198,
   OwnerDefaultCheckTemplate = 199,
   OwnerCheckTemplatesSeparateChecksByOwnership = 200,
   OwnerCheckTemplatesBalanceReservesAccrossOwnerships = 201,
   OwnerCheckTemplatesBalanceAccount = 202,
   OwnerCheckTemplatesBalanceMemo = 203,
   ManagementCompanyProperty = 204,
   ManagementCompanyIncomeAccount = 205,
   ManagementCompanyVendor = 206,
   ManagementCompanyIncomeChargeType = 207,
   ManagementFeesBill = 208,
   ManagementFeesCheck = 209,
   ManagementFeesCharge = 210,
   ManagementFeesInvoice = 211,
   ManagementFeesPayment = 212,
   ManagementFeesDeposit = 213,
   ManagementFeesUseFeeAccountForPassThru = 214,
   DefaultManagementFeeSetup = 215,
   ShowTransferGridWhenSettingUpManagementFees = 216,
   IncludeIncomeAccountsInFeeSetup = 217,
   IncludeExpenseAccountsInFeeSetup = 218,
   IncludeAssetAccountsInFeeSetup = 219,
   IncludeLiabilityAccountsInFeeSetup = 220,
   OwnerOverDraftActions = 221,
   OwnerOverDraftOwnerShipLevel = 222,
   BalanceWhereOwnerOverdraftOccurs = 223,
   DefaultOwnerReportBatch = 224,
   CoverLetterClosingComments = 225,
   StatementComment = 226,
   GeneralLedgerStartDate = 227,
   ShowAccountNumbersOnGeneralLedger = 228,
   DisplayGeneralLedgerAccountsWithFullPath = 229,
   TurnOffChartAccountNumberingSuggestions = 230,
   FiscalYearStartDate = 231,
   FiscalYearEndDate = 232,
   DefaultBasisForJournalEntries = 233,
   DefaultBasisForARnAPJournals = 234,
   DefaultBasisForReports = 235,
   RollNetIncomeFromPrevYearToRetainedEarningsOnBalanceSheet = 236,
   RecordCashCreditReallocations = 237,
   RecordCashPreallocationsAsLiability = 238,
   RecordAccrualPrepayments = 239,
   ReportingAccountsReceivableForAccrualMethod = 240,
   ReportingUndepositedFundsAccount = 241,
   ReportingAccountsPayableForAccrualMethod = 242,
   ReportingPrepayLiabilityAccount = 243,
   ReportingPrepayLiabilityAccountAccrual = 244,
   ReportingNetIncomeAccount = 245,
   ReportingRetainedEarningsRolloverAccount = 246,
   ReportingAccountForNotLinkedChargeTypes = 247,
   TenantCheckPassThroughAccount = 248,
   RentChargeChargeType = 249,
   BeginningBalanceChargeType = 250,
   NonSufficientFundsFeeChargeType = 251,
   NonSufficientFundsAdjustmentChargeType = 252,
   LateChargeChargeType = 253,
   EPayRefundAdjustmentChargeType = 254,
   AccountGroupAdjustmentChargeType = 255,
   SecurityDepositTransferChargeType = 256,
   SMTPMailServer = 257,
   SMTPMailPort = 258,
   EnableSMTPAuthentication = 259,
   SMTPUsername = 260,
   SMTPPassword = 261,
   SMTPUseSSL = 262,
   AlwaysUseSMTPFromAddress = 263,
   SMTPFromAddress = 264,
   UseDefaultExternalMailClient = 265,
   UseProxyForInternetAccess = 266,
   SendAsHTML = 267,
   ProxyServer = 268,
   ProxyPort = 269,
   IntegrateWithExternalCalendarApp = 270,
   CalendarIntegrationMethod = 271,
   LoansPrincipalChargeType = 272,
   LoansInterestChargeType = 273,
   LoansPrincipalPrepaymentChargeType = 274,
   LoansNotesReceivableChargeType = 275,
   LoansSalesIncomeChargeType = 276,
   LoansBadDebtChargeType = 277,
   ProspectLeaseDocument = 278,
   ProspectUnitInfoDocument = 279,
   EnableSpokeWithProspectOnNewCallsByDefault = 280,
   AccountingCloseDateType = 281,
   AccountingCloseSpecificDate = 282,
   AccountingCloseMonthlyDay = 283,
   AccountingCloseMonthlyEffective = 284,
   AccountingCloseYearlyMonthDay = 285,
   AccountingCloseYearlyEffective = 286,
   AccountingCloseXDaysAgo = 287,
   CreateInvoiceWhenPostingRecurringCharges = 288,
   InvoiceReportHideProperty = 289,
   InvoiceReportHideUnit = 290,
   InvoiceReportHideAccountNumber = 291,
   InvoiceReportShowItemCode = 292,
   ShowMarkupOnInvoices = 293,
   RequireBillApprovalBeforePayment = 294,
   BillApprovalMaximumTier1 = 295,
   WarnIfBankAccountUsedToPayBillsOrChecksIsNotDefault = 296,
   EnforceSequentialCheckNumbers = 297,
   DefaultCheckMemoFieldToVendorAccountNumber = 298,
   RequireTwoSignaturesOnChecksOverAmount = 299,
   CheckAmountThatRequiresTwoSignatures = 300,
   SelectPropertyFirstOnChecksAndBills = 301,
   UseVendorNameInsteadOfPayeeOnCheckStatus = 302,
   CheckForDuplicateReferenceNumbersOnBills = 303,
   HideBankBalanceOnCheck = 304,
   DuplicateBillReferenceNumberAction = 305,
   AllowBillPaymentsWithCreditCards = 306,
   CheckBillAccountPrefix = 307,
   CheckReference = 308,
   IgnoreExpiredVendorInsurance = 309,
   IgnoreExpiredVendorWorkersComp = 310,
   NextCheckNumber = 311,
   DefaultBillTerms = 312,
   AttachCheckImagesToHistoryNotes = 313,
   AttachCheckImagesToPaymentTransactions = 314,
   AutoFillCustomerBalanceWhenScanningChecks = 315,
   WarnWhenProcessingSameCheckMoreThanOnce = 316,
   RequireConfirmationOfBatchTotalWhenPostingScannedChecks = 317,
   CheckScanTransferTimeout = 318,
   UseDifferentCheckScannersInOtherLocations = 319,
   CheckScanners = 320,
   CheckScannerUsers = 321,
   BeginCSVImportFromLine = 322,
   CSVImportDescriptionColumn = 323,
   CSVImportDateColumn = 324,
   CSVImportCheckNumberColumn = 325,
   OneAmountOrSeparateDebitCreditColumns = 326,
   OneAmountColumnNumber = 327,
   OneAmountColumnDebitsPositive = 328,
   SeparateColumnsDebitColumn = 329,
   SeparateColumnsCreditColumn = 330,
   SeparateColumnsDebitsPositiveByDefault = 331,
   SeparateColumnsCreditsPositiveByDefault = 332,
   CSVImportDateFormat = 333,
   AssociateQIFFiles = 334,
   AssociateCSVFiles = 335,
   RemoveNonNumeric = 336,
   BeginCSVImportFromLineCC = 337,
   CSVImportDescriptionColumnCC = 338,
   CSVImportDateColumnCC = 339,
   CSVImportReferenceColumnCC = 340,
   OneAmountOrSeparateDebitCreditColumnsCC = 341,
   OneAmountColumnNumberCC = 342,
   OneAmountColumnDebitsPositiveCC = 343,
   SeparateColumnsDebitColumnCC = 344,
   SeparateColumnsCreditColumnCC = 345,
   SeparateColumnsDebitsPositiveByDefaultCC = 346,
   SeparateColumnsCreditsPositiveByDefaultCC = 347,
   CSVImportDateFormatCC = 348,
   AssociateQIFFilesCC = 349,
   AssociateCSVFilesCC = 350,
   RemoveNonNumericCC = 351,
   PurchaseOrderPrefix = 352,
   AllowPONumberChange = 353,
   DefaultShippingAddress = 354,
   DefaultBillingAddress = 355,
   ShowPropertyAndUnitInPOPrintoutHeader = 356,
   ShowPropertyAndUnitForEachLineItemOnPOPrintout = 357,
   TrackInventoryByProperty = 358,
   TrackCostOfGoodsSold = 359,
   ActionWhenInventoryNegative = 360,
   DefaultAssetAccount = 361,
   DefaultCOGSAccount = 362,
   LockPODates = 363,
   AllowCreateLinkedBillWithoutPO = 364,
   InventoryValuationMethods = 365,
   OwnerBillInvoiceProperty = 366,
   TenantInvoiceProperty = 367,
   DefaultBirdsEyeViewShapeColor = 368,
   DefaultBirdsEyeViewDoubleClickAction = 369,
   ScreeningContactDefaultAddress = 370,
   Metro2UseTransunion = 371,
   Metro2TransunionIdentifier = 372,
   Metro2UseExperian = 373,
   Metro2ExperianIdentifier = 374,
   Metro2UseEquifax = 375,
   Metro2EquifaxIdentifier = 376,
   Metro2UseInnovis = 377,
   Metro2InnovisIdentifier = 378,
   Metro2ReporterName = 379,
   Metro2ReporterPhone = 380,
   Metro2ReporterAddress = 381,
   Metro2ReporterIdentifier = 382,
   Metro2DefaultPeopleInidcator = 383,
   Metro2DefaultPeopleECOA = 384,
   Metro2DefaultLoanAccountType = 385,
   Metro2ExportInclusion = 386,
   DefaultPayrollImportType = 387,
   CSVPayrollImportHeaderRowsToSkip = 388,
   CSVPayrollImportAccountColumn = 389,
   CSVPayrollImportPropertyColumn = 390,
   CSVPayrollImportUnitColumn = 391,
   CSVPayrollImportMemoColumn = 392,
   CSVPayrollImportDebitAndCreditColumns = 393,
   CSVPayrollImportOneAmountColumnNumber = 394,
   CSVPayrollImportOneAmountColumnDebitsArePositive = 395,
   CSVPayrollImportDebitColumn = 396,
   CSVPayrollImportCreditColumn = 397,
   EnableAdvancedPasswordSecurity = 398,
   MinPasswordLength = 399,
   MaxPasswordLength = 400,
   MaxPasswordAge = 401,
   PasswordMustContainAtLeastXNumbers = 402,
   PasswordMustContainAtLeastXSpecialChars = 403,
   PasswordMayNotMatchLastXPasswords = 404,
   EnableInstantMessaging = 405,
   InstantMessagingServerHost = 406,
   InstantMessagingServerPort = 407,
   VPOUserName = 408,
   VPOPassword = 409,
   VPOMaxFeePerPrintJob = 410,
   VPOEmailAddress = 411,
   VPOSendEmailWhenJobSubmitted = 412,
   VPOSendEmailWhenJobSentForFulfillment = 413,
   VPOSendEmailWhenJobCancelled = 414,
   VPODefaultFromAddress = 415,
   VPOForceFormsToPrintInVPOFormat = 416,
   TrackCustomerRetailSales = 417,
   LeaseOptionsForLeaseAbstractReport = 418,
   NDTUserName = 419,
   NDTPassword = 420,
   NDTConnectOnStartup = 421,
   NDTShowIncomingPopups = 422,
   NDTFocusIncomingCalls = 423,
   NDTShowHuntGroupPopups = 424,
   NDTShowPresenceListing = 425,
   NDTShowExtensionsInPhoneExtensionList = 426,
   PhoneBroadcastUserName = 427,
   PhoneBroadcastPassword = 428,
   PhoneBroadcastDefaultAreaCode = 429,
   Depot = 430,
   TenantDefaultAddWizard = 431,
   ProspectDefaultAddWizard = 432,
   PropertyDefaultAddWizard = 433,
   VendorDefaultAddWizard = 434,
   OwnerDefaultAddWizard = 435,
   UnitDefaultAddWizard = 436,
   MultiUnitDefaultAddWizard = 437,
   ServiceManagerProjectDefaultAddWizard = 438,
   AssetDefaultAddWizard = 439,
   DocumentPacketDefaultAddWizard = 440,
   DocumentPublishDefaultAddWizard = 441,
   OwnerProspectDefaultAddWizard = 442,
   MoveInDefaultAddWizard = 443,
   MoveOutDefaultAddWizard = 444,
   Screening = 445,
   BlueMoonPassword = 446,
   BlueMoonUserName = 447,
   BlueMoonDefaultSerialNumber = 448,
   BlueMoonSerialNumber = 449,
   BlueMoonPropertyID = 450,
   BlueMoonShowOnStatement = 451,
   APIFileTokenLifetime = 452,
   AutomatedReportBatchAdminEmail = 453,
   OnlineListingsPerLocationListings = 454,
   OnlineListingsFeedEnabledMyFeed = 455,
   OnlineListingsFeedEnabledTruliaZillow = 456,
   OnlineListingsFeedEnabledRentLiveLovely = 457,
   OnlineListingsFeedEnabledHomes = 458,
   OnlineListingsAvailableUnitOption = 459,
   OnlineListingsShowWithinXDays = 460,
   OnlineListingsXDays = 461,
   OnlineListingsExcludeFutureUnits = 462,
   OnlineListingsUnitUdfID = 463,
   OnlineListingsPropertyUdfID = 464,
   OnlineListingsUnitAvailabilityTemplateID = 465,
   OnlineListingsCompanyName = 466,
   OnlineListingsCompanyWebsite = 467,
   OnlineListingsVacancyDate = 468,
   APITestValue = 469,
   UseDifferentNDTCredentialsInLocations = 470,
   UseDifferentBroadcastCredentialsInLocations = 471,
   EcheckRequestToken = 472,
   EcheckAccessToken = 473,
   EcheckNotificationEmail = 474,
   EcheckAutoProcessTenants = 475,
   EcheckAutoProcessVendors = 476,
   EcheckAutoProcessOwners = 477,
   EcheckAutoReorderChecks = 478,
   EcheckAutoReorderThreshold = 479,
   EcheckAutoReorderCount = 480,
   EcheckReorderBillingProperty = 481,
   EcheckReorderBillingVendor = 482,
   EcheckSetupEmail = 483,
   EcheckSetupPhone = 484,
   EcheckSetupAddress = 485,
   EcheckSetupBank = 486,
   EcheckReorderExpenseAccount = 487,
   EcheckForceSyncWithMainLocation = 488,
   EcheckTokenExpiresAt = 489,
   EcheckUserID = 490,
   EcheckUserRepCode = 491,
   IsEcheckEnabled = 492,
   IsEcheckConciergeUser = 493,
   EnableBillableExpenses = 494,
   BillableExpenseDefaultMarkup = 495,
   BillableExpenseDefaultBillbackEntity = 496,
   NextEcheckCheckNumber = 497,
   NextAvidCheckNumber = 498,
   UseNextNumberSeriesForEcheckCheck = 499,
   NextNachaCheckNumber = 500,
   AccountsReceivableAutomationEnabled = 501,
   AccountsReceivableAutomationScheduleID = 502,
   IsPayLeaseUtilityBillingEnabled = 503,
   PayLeaseBillingSelectedOccupantTypes = 504,
   PayLeaseBillingDefaultAddressType = 505,
   PayLeaseUtilityBillingAPIClientID = 506,
   PayLeaseBillingContactType = 507,
   CorpID = 508,
   RmInspectionWebAccessEnabled = 509,
   RmInspectionWebAccessPurchased = 510,
   RmInspectionWebAccessAvailable = 511,
   RmInspectionWebAccessTenantEmailMessage = 512,
   RmInspectionWebAccessOwnerEmailMessage = 513,
   RmInspectionWebAccessAllowToSendToTenantEmailAddresses = 514,
   RmInspectionWebAccessAllowToSendToOwnerEmailAddresses = 515,
   RmInspectionWebAccessAllowToSendToCustomEmailAddresses = 516,
   RmServiceWebAccessEnabled = 517,
   RmServiceWebAccessPurchased = 518,
   RmServiceWebAccessAvailable = 519,
   RmServiceViewIssuesAssignedToOthers = 520,
   RmServiceAllowToMakeListsAvailableOffline = 521,
   EnableGoogleCloudPrint = 522,
   GoogleCloudPrintUsers = 523,
   UseSystemGoogleAccount = 524,
   AllowPersonalGoogleAccounts = 525,
   EnableGoogleDrive = 526,
   GoogleDriveUsers = 527,
   UseSystemGoogleDriveAccount = 528,
   AllowPersonalGoogleDriveAccounts = 529,
   MgtReceivablesAddPaymentsForBillPaidtoMgtCompany = 531,
   MgtReceivablesDepositCommingledFunds = 532,
   MgtReceivablesDepositNonCommingledFunds = 533,
   MgtReceivablesAllowManualAllocation = 534,
   AllowAgentPendingConversation = 535,
   ShowFullNameOfAgent = 536,
   BudgetAllowEditTotalColumn = 537,
   BudgetTotalColumnCalculation = 538,
   BudgetAllowEditFinalColumn = 539,
   BudgetFinalColumnCalculation = 540,
   BudgetCalculationMethod = 541,
   BudgetValidationBasis = 542,
   BudgetApprovedPOsInCalculations = 543,
   BudgetRestrictPOApproval = 544,
   BudgetWarningPercentage = 545,
   BudgetWarningPO = 546,
   BudgetWarningBill = 547,
   BudgetWarningCheck = 548,
   BudgetWarningCC = 549,
   Markup = 550,
   CreateLinkedLease = 551,
   FutureDateAccountsReceivableWarning = 552,
   FutureDateAccountsReceivableDays = 553,
   FutureDateAccountsPayableWarning = 554,
   FutureDateAccountsPayableDays = 555,
   FutureDateJournalsWarning = 556,
   FutureDateJournalsDays = 557,
   AccountingPeriodEnable = 558,
   AccountingPeriodDefaultForReports = 559,
   AccountingCloseSystemDefaultType = 560,
   OnlineListingsIsShowAllProperties = 561,
   OnlineListingsIsFollowUnitCriteria = 562,
   OnlineListingsPropertyFilterOption = 563,
   TextBroadcastDIDSelection = 564,
   EpayCustomerRMACHConvenienceFeeIsPercent = 565,
   EpayCustomerRMCreditCardConvenienceFeeIsPercent = 566,
   EpayCustomerRWAACHConvenienceFeeIsPercent = 567,
   EpayCustomerRWACreditCardConvenienceFeeIsPercent = 568,
   EpayOwnerRMACHConvenienceFeeIsPercent = 569,
   BlueMoonAllowOtherLeaseForms = 570,
   EpayOwnerOWAACHConvenienceFeeIsPercent = 571,
   EpayOwnerOWACreditCardConvenienceFeeIsPercent = 572,
   EpayOwnerRMCreditCardConvenienceFeeIsPercent = 573,
   UnitLicensingCountAutomationEnabled = 574,
   ServiceManagerAutoLinkUnitForAsset = 575,
   ServiceManagerAutoLinkAssetForUnit = 576,
   AutomaticallyMoveOutHomeAssets = 578,
   UniqueAssetNames = 579,
   ServiceManagerAutoLinkAssetForTenant = 580,
   RequireReasonForLostRejected = 581,
   ProspectDefaultStage = 582,
   PreventVendorCreditsAcrossProperties = 583,
   EstimateDefaultStatus = 584,
   EstimateExpirationDaysOffset = 585,
   LeaseRenewalCreateNewRecurringCharges = 586,
   LeaseRenewalUseEndDateOnNewCharges = 587,
   SMTPSetupType = 588,
   SMTPAllowUserToOverrideAccountInformation = 589,
   PromptToChargeUnpostedChargesAtMoveOut = 590,
   ServiceManagerAutoAdvanceChecklist = 591,
   ShowChargesPaidOnReceipt = 592,
   ShowUnpostedLateFeesOnReceipt = 593,
   ShowPaymentCommentOnReceipt = 594,
   ReceiptNotes = 595,
   GuestReservationOption = 596,
   OwnerDefaultPassThruHeldAccount = 597,
   EpayAccountsDebitCard = 598,
   EpayCustomerChargeConvenienceFeesInRMForDebitCards = 599,
   EpayCustomerRMDebitCardConvenienceFee = 600,
   EpayCustomerChargeConvenienceFeesInRWAForDebitCards = 601,
   EpayCustomerRWADebitCardConvenienceFee = 602,
   EpayCustomerRMDebitCardConvenienceFeeIsPercent = 603,
   EpayCustomerRWADebitCardConvenienceFeeIsPercent = 604,
   EpayOwnerChargeConvenienceFeesInRMForDebitCards = 605,
   EpayOwnerRMDebitCardConvenienceFee = 606,
   EpayOwnerChargeConvenienceFeesInOWAForDebitCards = 607,
   EpayOwnerOWADebitCardConvenienceFee = 608,
   EpayOwnerOWADebitCardConvenienceFeeIsPercent = 609,
   EpayOwnerRMDebitCardConvenienceFeeIsPercent = 610,
   ServiceManagerRequirePropertyLink = 611,
   ServiceManagerRequireUnitLink = 612,
   AvidSyncProperties = 613,
   AvidSyncUnits = 614,
   AvidSyncGLAccounts = 615,
   AllowPropertyQuickSearchMultiSelect = 617,
   DoNotAllowDigitalSignaturesOverAmount = 618,
   CheckAmountThatDigitalSignaturesAreNotAllowed = 619,
   AvidSyncJobs = 620,
   BillApprovalMaximumTier2 = 621,
   BillApprovalMaximumTier3 = 622,
   AutomaticallySelectAllBillsToBePaidWhenOpeningPayBills = 623,
   IsLockboxEnabled = 624,
   IsAllowDataToBeSharedWithZego = 625,
   IsEnableRentersInsuranceTracking = 626,
   IsEnforceRentersInsuranceMasterPolicy = 627,
   RentersInsuranceMasterPolicyChargeType = 628,
   RentersInsuranceMasterPolicyChargeComment = 629,
   OnlineListingsShowWithinXDaysOfOccupancy = 631,
   OnlineListingsXDaysOccupancy = 632,
   OnlineListingsEnabled = 635,
   AllowLockboxToAcceptPartialPayments = 636,
   AllowLockboxToAcceptOverPayments = 637,
   STRChargeDateOption = 638,
   RmInspectionVideoInspectionsOption = 639,
   EnableEmailReplyTracking = 642,
   RentersInsuranceDefaultChargeAmount = 643,
   StatementMethod = 644,
   TaskAutomationTenantStatements = 645,
   RequireMultifactorAuthentication = 646,
   RmViolationsAddFromMapView = 647,
   RmViolationsCustomizePinColors = 648,
   EpayCashPaySendTotalOrCurrentBalance = 649,
   AllowLeaseTrackNotifications = 650,
   AllowLeaseTrackDataTracking = 651,
   RentersInsuranceMasterPolicyNumber = 652,
   RentersInsuranceMasterPolicyEffectiveDate = 653,
   IsLeaseTrackExcludeUnitTypes = 654,
   LeaseTrackExcludeUnitTypes = 655,
   RentersInsuranceMasterPolicyProperties = 656,
}
