import { HttpResponse } from "@angular/common/http";
import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { DatesService } from "@lcs/utils/dates.service";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import { ApiServiceHelpers } from "projects/libraries/owa-gateway-sdk/src/lib/core/api-service.helpers";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { LogicalOperators } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/logical-operators.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { DataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/data-types.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, Subject, takeUntil } from "rxjs";

import { SelectorItemSearchHelper } from "../helpers/selector-item-search.helper";
import { ValueComparerHelper } from "../helpers/value-comparer.helper";

@Directive()
export abstract class EndpointSelectorDirectiveBase implements OnDestroy, OnInit {
   public allItems: Array<SelectorItemModel>;

   protected static startTypingMessage = "Start typing to search...";

   protected _endpoint: string;

   protected _itemEndpoint: string;

   protected _isSubItem: boolean;

   protected _endpointIsSearch: boolean;

   protected _allowForceRefresh: boolean = true;

   protected _endpointIsQuickSearch: boolean;

   protected _eagerLoadItems: boolean;

   protected _loadItemsOnFocus: boolean;

   protected _loadAllItems: boolean;

   protected _addBlankItem: boolean;

   protected _valuesToExclude: Array<string | number | boolean>;

   protected _valueSourcePath: string;

   protected _primaryKeySourcePath: string;

   protected _displayValueSourcePath: string;

   protected _additionalInfoSourcePath: string | EntityField;

   protected _additionalDataFields: Array<string>;

   protected _staticValues: Array<SelectorItemModel>;

   protected _filters: Array<FilterOption> | null;

   protected _filterExpression: FilterExpression;

   protected _searchFields: Array<FilterField>;

   protected _embeds: Array<string>;

   protected _orderingOptions: Array<string>;

   protected _fields: Array<string | EntityField>;

   protected _maximumItemsToRetrieve: number = 10;

   protected _valueComparer: (selectedItemValue: any, value: any) => boolean;

   private searchText: string;

   protected unsubscribe = new Subject<void>();

   protected refreshSelectedItemSubject = new Subject<boolean>();

   constructor(
      protected errorMessageService: ErrorMessageService,
      protected objectMapResolverService: ObjectMapResolverService,
      protected valueSourceService: ValueSourceService,
      protected apiService: ApiService,
      protected selectComponent: SelectComponent
   ) {
      this._valueComparer = ValueComparerHelper.simpleComparer;

      this.selectComponent.searchTextChanged
         .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe))
         .subscribe((searchText) => {
            this.searchText = searchText ?? "";
            if (this.allItems?.length > 0) {
               this.searchLoadedItems();
            } else {
               this.retrieveItemsFromAPI();
            }
         });

      this.selectComponent.focused.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         if (this._loadAllItems && !(this.allItems?.length > 0)) {
            this.retrieveItemsFromAPI();
         }
         if (this._loadItemsOnFocus) {
            this.retrieveItemsFromAPI();
         }
      });

      this.refreshSelectedItemSubject.pipe(debounceTime(1), takeUntil(this.unsubscribe)).subscribe((force: boolean) => {
         this.refreshSelectedItem(force);
      });

      this.selectComponent.registerOnValueWritten(() => {
         this.refreshSelectedItemSubject.next(false);
      });
   }

   ngOnInit(): void {
      this.selectComponent.additionalMessage = EndpointSelectorDirectiveBase.startTypingMessage;
      this.selectComponent.isSearching = false;
      this.selectComponent.valueComparer = this._valueComparer;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   protected refreshSelectedItem(force?: boolean) {
      if (
         this._allowForceRefresh &&
         (force ||
            !this.selectComponent.selectedItem ||
            (this._valueComparer &&
               !this._valueComparer(this.selectComponent.selectedItem.value, this.selectComponent.value)))
      ) {
         this.findSelectedItem()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((selectedItem) => {
               if (selectedItem) {
                  this.selectComponent.selectItem(selectedItem, false, false);
                  if (this.allItems?.length > 0) {
                     const matchInAllItems = this.allItems.find((item) =>
                        this._valueComparer(selectedItem.value, item.value)
                     );
                     if (matchInAllItems) {
                        const matchIndex = this.allItems.indexOf(matchInAllItems);
                        this.allItems[matchIndex] = selectedItem;
                     }
                  }
               }
            });
      }
   }

   protected clearAllItems() {
      this.allItems = new Array<SelectorItemModel>();
   }

   protected clearResults() {
      this.processResults(new Array<SelectorItemModel>());
      this.selectComponent.additionalMessage = EndpointSelectorDirectiveBase.startTypingMessage;
      this.selectComponent.errorMessage = "";
   }

   protected processResults(items: Array<SelectorItemModel>) {
      this.selectComponent.isSearching = false;
      this.selectComponent.selectorItems = items;
   }

   protected setEagerLoadItems() {
      this.retrieveItemsFromAPI();
   }

   private checkForInvalidConfigurationValues(): Array<string> {
      const invalidConfigurationValues = new Array<string>();
      if (!this._valueSourcePath) {
         invalidConfigurationValues.push("valueSourcePath");
      }

      if (!this._displayValueSourcePath) {
         invalidConfigurationValues.push("displayValueSourcePath");
      }

      if (!this._endpoint) {
         invalidConfigurationValues.push("endpoint");
      }

      if (!this._searchFields) {
         invalidConfigurationValues.push("searchFields");
      }

      return invalidConfigurationValues;
   }

   private findSelectedItem(): Observable<SelectorItemModel | null> {
      if (this._staticValues && this._staticValues.length > 0 && this._valueComparer) {
         const matchinStaticValue = this._staticValues.find((item) =>
            this._valueComparer(this.selectComponent.value, item.value)
         );
         if (matchinStaticValue) {
            return of(matchinStaticValue);
         }
      }
      if (this.checkForInvalidConfigurationValues().length > 0) {
         return of(null);
      }
      if (
         this.selectComponent.value === null ||
         this.selectComponent.value === undefined ||
         this.selectComponent.value === ConstantsService.NullFK ||
         this.selectComponent.value === ""
      ) {
         return of(null);
      }

      return this.getSingleItem();
   }

   private getSingleItem(): Observable<SelectorItemModel | null> {
      let apiUrl = this._endpoint;

      if (this._itemEndpoint) {
         apiUrl = this._itemEndpoint;
      }
      if (!apiUrl) {
         return of(null);
      }
      if (this._endpointIsQuickSearch) {
         apiUrl = apiUrl.replace(/quicksearch/gi, "search");
      }

      const embeds = this.buildEmbeds();

      const fields = this.buildFields();

      let observable: Observable<any>;

      if (typeof this.selectComponent.value === "object") {
         observable = of(this.selectComponent.value);
      } else if (this._endpointIsSearch) {
         const selectedItemFilterExpression: FilterExpression =
            this.buildSearchableEndpointFilterExpressionForFieldValue(
               this._valueSourcePath,
               this.selectComponent.value
            );

         observable = this.apiService
            .getSearchResponse(apiUrl, selectedItemFilterExpression, embeds, this._orderingOptions, fields)
            .pipe(
               map((response: HttpResponse<any>): Observable<any> => {
                  if (response?.body) {
                     return response.body;
                  }
                  console.warn(`Failed to retrieve selected item. No Response Body found.`);
                  return of(null);
               }),
               catchError((error) => {
                  console.error(`Failed to retrieve selected item. Error=`, error);
                  return of(null);
               })
            );
      } else if (this._isSubItem) {
         observable = this.apiService.getSingleSubItem(apiUrl, +this.selectComponent.value, embeds, fields);
      } else {
         observable = this.apiService.getSingle(apiUrl, +this.selectComponent.value, embeds, fields);
      }
      return observable.pipe(map((result: any) => (result ? this.processResultItem(result) : null)));
   }

   /**
    * Build a FilterExpression ANDing together any base filterOptions, base filterExpression and a value
    * which should return a single record matching the value.
    *
    * @param field
    *    value field name
    * @param value
    *    value
    * @returns
    *    FilterExpression
    */
   private buildSearchableEndpointFilterExpressionForFieldValue(
      field: string,
      value: FilterValueType
   ): FilterExpression {
      if (field === null || field === undefined) {
         console.warn("Missing expected value for 'field' parameter.");
      }
      const filterExpression: FilterExpression = new FilterExpression();
      filterExpression.LogicalOperator = LogicalOperators.And;

      if (this._filters) {
         // init with any base filterOptions to restrict the set of items from which the single item can be selected
         filterExpression.FilterOptions = clone(this._filters);
      }

      if (this._filterExpression) {
         // Use any base filterExpression to restrict the set of items from which the single item can be selected
         const baseFilterExpression: FilterExpression = Object.assign(new FilterExpression(), this._filterExpression);
         filterExpression.SubExpressions.push(baseFilterExpression);
      }

      if (field) {
         // Restrict returned items to the item matching the key
         const primaryKeyFilterOption = new FilterOption(field, FilterOperations.EqualTo, [value]);
         filterExpression.FilterOptions.push(primaryKeyFilterOption);
      }

      return filterExpression;
   }

   private searchLoadedItems() {
      this.selectComponent.isSearching = true;

      const items = SelectorItemSearchHelper.search(this.allItems, this.searchText);

      if (items.length === 0) {
         this.selectComponent.errorMessage = SelectComponent.noItemsFoundMessage;
      } else {
         this.selectComponent.errorMessage = null;
      }

      this.processResults(items);
   }

   private retrieveItemsFromAPI() {
      const invalidConfigurationValues = this.checkForInvalidConfigurationValues();
      if (invalidConfigurationValues.length > 0) {
         this.selectComponent.errorMessage = "Invalid Configuration.";
         this.selectComponent.isSearching = false;
         console.warn(`Invalid Configuration. Missing fields: ${invalidConfigurationValues.join(", ")}`);
         return;
      }

      this.selectComponent.isSearching = true;

      this.getData(this.searchText)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (results: any[]) => {
               let items = new Array<SelectorItemModel>();
               items = results;

               this.selectComponent.isSearching = false;

               if (this._loadAllItems) {
                  this.allItems = items;
               }

               this.processResults(items);

               if ((this.searchText && results) || this._loadAllItems) {
                  if (!items || items.length === 0) {
                     this.selectComponent.additionalMessage = "";
                     this.selectComponent.errorMessage = SelectComponent.noItemsFoundMessage;
                  } else {
                     this.selectComponent.additionalMessage = "";
                     this.selectComponent.errorMessage = "";
                  }
               } else {
                  this.selectComponent.additionalMessage = EndpointSelectorDirectiveBase.startTypingMessage;
                  this.selectComponent.errorMessage = "";
               }
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
               this.selectComponent.errorMessage = "Please enter valid input";
               this.selectComponent.isSearching = false;
               this.processResults(new Array<SelectorItemModel>());
            }
         );
   }
   private getData(searchText: string): Observable<Array<SelectorItemModel>> {
      return this.buildRequest(searchText).pipe(
         map((response) => {
            let results: Array<any> = response;
            const items = this.searchStaticItems(searchText);

            if (results && results.length > 0 && this._valuesToExclude && this._valuesToExclude.length > 0) {
               results = results.filter((result) => {
                  return (
                     this._valuesToExclude.indexOf(
                        ObjectMapResolverService.getPropertyValue(result, this._valueSourcePath).toString()
                     ) === -1
                  );
               });
            }
            if (results && results.length > 0) {
               let processedItems = results.map((result) => this.processResultItem(result));

               if (items.length > 0) {
                  processedItems = processedItems.filter(
                     (i) => !this.valueSourceService.itemAlreadyInItemSet(i, items)
                  );
               }

               items.push(...processedItems);
            }

            return items;
         })
      );
   }

   private buildRequest(searchText: string | null): Observable<any> {
      const filterExpression = this.getFilterExpression(searchText ?? "");

      if (filterExpression.ExcludeAllOverride) {
         return of(null);
      }

      let apiUrl = this._endpoint;

      if (!apiUrl) {
         return of(null);
      }

      if (!searchText && !this._eagerLoadItems && !this._loadAllItems && !this._loadItemsOnFocus) {
         this.selectComponent.additionalMessage = EndpointSelectorDirectiveBase.startTypingMessage;
         return of(null);
      }

      if (this._loadAllItems) {
         searchText = null;
      }

      const embeds = this.buildEmbeds();

      const fields = this.buildFields();

      const orderingOptions = [...Array.from(new Set(this._orderingOptions))];

      let additionalParameters = new Array<string>();
      if (this.isQuickSearch(searchText ?? "", filterExpression)) {
         additionalParameters = this.apiService.buildQuickSearchCollectionParameterArrayFromGetParameters(
            this._searchFields,
            searchText,
            embeds,
            orderingOptions,
            fields,
            this._loadAllItems ? undefined : this._maximumItemsToRetrieve
         );
      } else {
         apiUrl = apiUrl.replace(/quicksearch/gi, "search");
         if (this._endpointIsSearch) {
            additionalParameters = this.apiService.buildSearchCollectionParameterArrayFromGetParameters(
               filterExpression,
               embeds,
               orderingOptions,
               fields,
               this._loadAllItems ? undefined : this._maximumItemsToRetrieve
            );
         } else {
            additionalParameters = this.apiService.buildGetCollectionParameterArray(
               filterExpression,
               embeds,
               orderingOptions,
               fields,
               this._loadAllItems ? undefined : this._maximumItemsToRetrieve
            );
         }
      }

      return this.apiService.get(apiUrl, additionalParameters).pipe(map((response) => response.body));
   }

   private buildEmbeds() {
      const embeds = new Array<string>();

      if (this._embeds) {
         embeds.push(...this._embeds);
      }

      const templateList = [this._valueSourcePath, this._displayValueSourcePath];
      if (this._additionalInfoSourcePath) {
         if ((this._additionalInfoSourcePath as EntityField).Identifier) {
            templateList.push((this._additionalInfoSourcePath as EntityField).Identifier);
         } else {
            templateList.push(this._additionalInfoSourcePath as string);
         }
      }

      const paths = this.parseTemplatedPaths(templateList);
      embeds.push(...ApiServiceHelpers.extractEmbeds(paths));

      return embeds;
   }

   private buildFields(): Array<string> {
      let fields = new Array<string>();

      if (this._fields) {
         if ((this._fields[0] as EntityField).Identifier) {
            const identifiers = this._fields.map((f) => (f as EntityField).Identifier);
            fields.push(...identifiers);
         } else {
            fields.push(...(this._fields as string[]));
         }
      }

      if (this._valueSourcePath && this._valueSourcePath !== ObjectMapResolverService.rootPath) {
         fields.push(this._valueSourcePath);
      }
      if (this._displayValueSourcePath) {
         fields.push(this._displayValueSourcePath);
      }
      if (this._additionalInfoSourcePath) {
         if ((this._additionalInfoSourcePath as EntityField).Identifier) {
            fields.push((this._additionalInfoSourcePath as EntityField).Identifier);
         } else {
            fields.push(this._additionalInfoSourcePath as string);
         }
      }
      if (this._primaryKeySourcePath) {
         fields.push(this._primaryKeySourcePath);
      }
      if (this._additionalDataFields && this._additionalDataFields.length > 0) {
         fields.push(...this._additionalDataFields);
      }

      fields = [...Array.from(new Set(this.parseTemplatedPaths(fields)))];
      return fields;
   }

   private parseTemplatedPaths(paths: Array<string>) {
      // handle templated paths i.e. {name} - {reference}
      const values = new Array<string>();
      if (paths && paths.length > 0) {
         paths.forEach((f) => {
            const regexp = new RegExp(/\{.*?\}/g);
            const matches = f.match(regexp);
            if (matches && matches.length > 0) {
               matches.forEach((m) => {
                  m = m.replace("{", "").replace("}", "");

                  // handle coalesce equations i.e. {name ?? 'Unassociated'}
                  if (m.indexOf("??") > -1) {
                     const equationValues = m.split("??");
                     if (equationValues.length === 2) {
                        equationValues.forEach((v) => {
                           const val = v.trim();
                           if (!val.startsWith("'") && !val.endsWith("'") && isNaN(+val)) {
                              values.push(val);
                           }
                        });
                     }
                  } else {
                     values.push(m);
                  }
               });
            } else {
               values.push(f);
            }
         });
      }
      return values;
   }

   private isQuickSearch(searchText: string, filterExpression?: FilterExpression): boolean {
      return this._endpoint &&
         this._endpointIsQuickSearch &&
         searchText &&
         filterExpression &&
         filterExpression.FilterOptions &&
         filterExpression.FilterOptions.length <= 1 &&
         filterExpression.SubExpressions.length === 0
         ? true
         : false;
   }

   private getFilterExpression(searchText: string): FilterExpression {
      const expression = new FilterExpression();
      expression.LogicalOperator = LogicalOperators.And;

      if (this._filterExpression) {
         expression.SubExpressions.push(this._filterExpression);
      }

      if (this._filters) {
         expression.FilterOptions = clone(this._filters);
      }

      if (searchText) {
         if (this._searchFields && this._searchFields.length > 0) {
            const validFiltersForInput = this._searchFields.filter((f) =>
               this.isValidSearchForDataType(searchText, f.DataType)
            );

            if (validFiltersForInput.length === 0) {
               expression.ExcludeAllOverride = true;
            }

            const searchFilters = validFiltersForInput.map(
               (f) => new FilterOption(f.FilterName, FilterOperations.Contains, [searchText])
            );
            if (!this._endpointIsSearch) {
               expression.FilterOptions?.push(searchFilters[0]);
            } else {
               const filterSubExpression = new FilterExpression();
               filterSubExpression.LogicalOperator = LogicalOperators.Or;
               filterSubExpression.FilterOptions = searchFilters;

               expression.SubExpressions.push(filterSubExpression);
            }
         }
      }
      return expression;
   }

   private searchStaticItems(searchText: string | null = null): Array<SelectorItemModel> {
      let items = new Array<SelectorItemModel>();

      if (this._staticValues) {
         items = cloneDeep(this._staticValues);
      }
      if (this._addBlankItem) {
         if (items.filter((item) => item.value === "").length === 0) {
            items.unshift(new SelectorItemModel("", ""));
         }
      }
      if (items.length === 0) {
         return items;
      }

      if (this._valuesToExclude && this._valuesToExclude.length > 0) {
         items = items.filter((item) => this._valuesToExclude.indexOf(item.value.toString()) === -1);
      }

      items = SelectorItemSearchHelper.search(items, searchText ?? "");

      return items;
   }

   private isValidSearchForDataType(searchText: string, dataType: DataTypes): boolean {
      let isValid = true;
      switch (dataType.Name.toLowerCase()) {
         case "string":
            // this case should always be valid no matter the value passed
            break;
         case "int":
         case "decimal":
         case "key":
            isValid = !isNaN(Number(searchText));
            break;
         case "date":
            isValid = DatesService.isValidDateObj(new Date(searchText));
            break;
      }

      return isValid;
   }

   private processResultItem(searchResult: any): SelectorItemModel {
      if (searchResult.length) {
         let newSearchResult = searchResult[0];
         if (this.selectComponent.value != null) {
            newSearchResult =
               (searchResult as Array<any>).find(
                  (value) => value[this._valueSourcePath] === this.selectComponent.value
               ) ?? searchResult[0];
         }
         searchResult = newSearchResult;
      }

      const item = this.objectMapResolverService.buildSelectorItem(
         searchResult,
         this._valueSourcePath,
         this._displayValueSourcePath,
         this._additionalInfoSourcePath,
         this._additionalDataFields
      );
      return item;
   }
}
