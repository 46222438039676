<label
   *ngIf="!hideLabel && label"
   [class.disabled]="disabled"
>
   {{ label }}
</label>
<lcs-date-picker
   [name]="name"
   [displayName]="displayName+' Calendar'"
   [disabled]="disabled || useRelativeDate"
   [ngModel]="dateValue"
   [ngModelOptions]="{ standalone: true }"
   (ngModelChange)="onDateEntered($event)"
   [lcsValidate]="validation"
></lcs-date-picker>
<div
   class="date-relative-option-selector"
   *ngIf="isRelative"
>
   <lcs-checkbox
      [(ngModel)]="useRelativeDate"
      (ngModelChange)="onUseRelativeDateChecked()"
      [name]="name + '-relative-dates-checkbox'"
      [displayName]="'Use Relative Date'"
      [disabled]="disabled"
      [ngModelOptions]="{ standalone: true }"
   >
      Use Relative Date
   </lcs-checkbox>
   <lcs-select
      lcsLegacySingleSelector
      *ngIf="useRelativeDate"
      [name]="name + '-relative-options'"
      [displayName]="displayName"
      [disabled]="disabled"
      [valueSource]="relativeDatesValueSource"
      [ngModel]="selectedRelativeDateValue"
      (ngModelChange)="onRelativeOptionSelected($event)"
      [ngModelOptions]="{ standalone: true }"
   >
   </lcs-select>
</div>