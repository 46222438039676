import { FilterDataTypes } from "@lcs/filters/filter-data-types.enum";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { errorInDevMode, warnInDevMode } from "projects/libraries/lcs/src/lib/utils/logging";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";


/* eslint-disable @typescript-eslint/prefer-for-of */
export class FilterHelper {
   public static convertFilterFieldDataTypeToFilterDataTypes(dataTypeName: string): FilterDataTypes {
      let filterDataType = FilterDataTypes.String;
      if (dataTypeName === FilterDataTypes[FilterDataTypes.DateTime]) {
         filterDataType = FilterDataTypes.DateTime;
      }
      if (dataTypeName === FilterDataTypes[FilterDataTypes.Key]) {
         filterDataType = FilterDataTypes.Key;
      }
      if (dataTypeName === FilterDataTypes[FilterDataTypes.Int]) {
         filterDataType = FilterDataTypes.Int;
      }
      if (dataTypeName === FilterDataTypes[FilterDataTypes.Bool]) {
         filterDataType = FilterDataTypes.Bool;
      }
      if (dataTypeName === FilterDataTypes[FilterDataTypes.Decimal]) {
         filterDataType = FilterDataTypes.Decimal;
      }
      return filterDataType;
   }

   /**
    * Converts FilterDataTypes enum value to comparable ExpressDataTypes enum value
    *
    * @param filterDataType
    *
    * @returns ExpressDataTypes
    */
   static convertFilterDataTypeToExpressDataType(filterDataType: FilterDataTypes): ExpressDataTypes {
      switch (filterDataType) {
         case FilterDataTypes.Int:
         case FilterDataTypes.Key:
         case FilterDataTypes.PrimaryKey:
         case FilterDataTypes.Decimal: {
            return ExpressDataTypes.Numeric;
         }
         case FilterDataTypes.Bool: {
            return ExpressDataTypes.Boolean;
         }
         case FilterDataTypes.DateTime: {
            return ExpressDataTypes.Date;
         }
         case FilterDataTypes.String: {
            return ExpressDataTypes.String;
         }
         default: {
            warnInDevMode(`Unknown FilterDataType {${filterDataType}}`);
            return ExpressDataTypes.String;
         }
      }
   }

   /**
    * Converts FilterField DataType Name string to comparable ExpressDataTypes enum value
    *
    * @param dataTypeName
    *
    * @returns ExpressDataTypes
    */
   static convertFilterFieldDataTypeToExpressDataType(dataTypeName: string): ExpressDataTypes {
      const filterDataType = this.convertFilterFieldDataTypeToFilterDataTypes(dataTypeName);
      const expressDataType = this.convertFilterDataTypeToExpressDataType(filterDataType);
      return expressDataType;
   }

   /**
    * Converts string[] of filterValues to FilterValueType[]. Individual filterValue types are converted based upon given dataType
    *
    * @param values
    * @param dataType
    *
    * @returns Converted filterValues
    */
   static convertFilterValuesByDataType(filterValues: string[], dataType: ExpressDataTypes): FilterValueType[] {
      if (!Object.values(ExpressDataTypes).includes(dataType)) {
         warnInDevMode(`Unknown DataType: {${dataType}}`);
         return filterValues;
      }

      return filterValues.map((value) => this.convertFilterValueByDataType(value, dataType));
   }

   /**
    * Converts filterValue of type "string" to filterValue of correct data type for given dataType
    *
    * @param filterValue
    * @param dataType
    *
    * @returns converted filterValue
    */
   static convertFilterValueByDataType(filterValue: string, dataType: ExpressDataTypes): FilterValueType {
      let convertedFilterValue: FilterValueType;

      switch (dataType) {
         case ExpressDataTypes.Currency:
         case ExpressDataTypes.Numeric:
         case ExpressDataTypes.Percentage:
         case ExpressDataTypes.Count:
         case ExpressDataTypes.Key: {
            convertedFilterValue = Number(filterValue);

            if (Number.isNaN(convertedFilterValue)) {
               warnInDevMode(
                  `Invalid number conversion for filterValue {${filterValue}} / DataType {${ExpressDataTypes[dataType]}}`
               );
            }
            break;
         }
         case ExpressDataTypes.Boolean: {
            const lowercaseFilterValue = filterValue.toLocaleLowerCase();
            convertedFilterValue = lowercaseFilterValue === "true";

            if (lowercaseFilterValue !== "false" && lowercaseFilterValue !== "true") {
               warnInDevMode(`Invalid value for boolean conversion {${filterValue}}`);
            }
            break;
         }
         case ExpressDataTypes.Date:
         case ExpressDataTypes.DateTime: {
            convertedFilterValue = new Date(filterValue);

            if (isNaN(convertedFilterValue.getTime())) {
               warnInDevMode(`Invalid date conversion for filter value {${filterValue}}`);
            }
            break;
         }
         case ExpressDataTypes.String:
         case ExpressDataTypes.PhoneNumber: {
            convertedFilterValue = filterValue;
            break;
         }
         default: {
            warnInDevMode(`Unknown DataType {${dataType}}`);
            convertedFilterValue = filterValue;
         }
      }
      return convertedFilterValue;
   }

   /**
    *
    * Verifies filterValue is of correct data type for given dataType and attempts to convert if not (must be string for conversion)
    *
    * @param filterValue
    * @param dataType
    *
    * @returns converted filterValue (original datatype if not converted)
    */
   static verifyAndConvertValueForDataType(filterValue: FilterValueType, dataType: ExpressDataTypes): FilterValueType {
      let convertedFilterValue = filterValue;

      if (!this.validValueForDataType(filterValue, dataType)) {
         warnInDevMode(`Invalid DataType {${ExpressDataTypes[dataType]}} for FilterValue`, filterValue);

         if (typeof filterValue === "string") {
            convertedFilterValue = FilterHelper.convertFilterValueByDataType(filterValue, dataType);
         }
      }
      return convertedFilterValue;
   }

   /**
    * Verifies filterValue is of correct data type for given dataType
    *
    * @param filterValue
    * @param dataType
    *
    * @returns true / false
    */
   static validValueForDataType(filterValue: FilterValueType, dataType: ExpressDataTypes): boolean {
      if (filterValue == null) {
         return true;
      }

      let valid = false;

      switch (dataType) {
         case ExpressDataTypes.Currency:
         case ExpressDataTypes.Numeric:
         case ExpressDataTypes.Percentage:
         case ExpressDataTypes.Count:
         case ExpressDataTypes.Key: {
            if (typeof filterValue === "number") {
               valid = true;
            }
            break;
         }
         case ExpressDataTypes.Boolean: {
            if (typeof filterValue === "boolean") {
               valid = true;
            }
            break;
         }
         case ExpressDataTypes.Date:
         case ExpressDataTypes.DateTime:
         case ExpressDataTypes.Time:
         case ExpressDataTypes.TimeSpan: {
            if (filterValue instanceof Date) {
               valid = true;
            }
            break;
         }
         case ExpressDataTypes.String:
         case ExpressDataTypes.PhoneNumber: {
            if (typeof filterValue === "string") {
               valid = true;
            }
            break;
         }
         default: {
            warnInDevMode(`Unknown DataType: {${dataType}}`);
         }
      }

      return valid;
   }

   /**
    * Checks if value array is valid filter array. Checks if values in array are valid filter values.
    *
    * @param values The filter values to check.
    *
    * @returns true or false
    * @example
    *
    * [""]
    * // => true
    *
    * null
    * // => true
    *
    * undefined
    * // => false
    *
    * []
    * // => false
    *
    * [null]
    * // => false
    *
    * [undefined]
    * // => false
    *
    */
   static validFilterValueArray(values: Array<FilterValueType> | null | undefined): boolean {
      const errorMessage = "Invalid filter values!";

      if (values === undefined) {
         errorInDevMode(`${errorMessage} { undefined } not allowed.`);
         return false;
      }

      if (values) {
         if (values.length === 0) {
            errorInDevMode(`${errorMessage} { [] } not allowed.`);
            return false;
         }

         for (let i = 0; i < values.length; i++) {
            const val = values[i];
            if (val == null) {
               errorInDevMode(`${errorMessage} { [${val}] } not allowed.`);
               return false;
            }
         }
      }

      return true;
   }
}
