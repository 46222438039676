<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="name + '-values-to-include-selector'"
      [disabled]="disabled"
      [parameter]="valuesToIncludeParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<lcs-numeric-input-report-parameter
   *ngIf="valuesToIncludeAmountParameter"
   [name]="name + '-values-to-include-text-box'"
   [disabled]="isAllSelected || disabled || (hasAsk === true && valuesToIncludeParameter.IsAsk === true)"
   [parameter]="valuesToIncludeAmountParameter"
></lcs-numeric-input-report-parameter>