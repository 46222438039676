import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { InfiniteScrollModule } from "@lcs/infinite-scroll/infinite-scroll.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";

import { FileCarouselModule } from "../file-carousel/file-carousel.module";
import { FileIconModule } from "../file-icon/file-icon.module";
import { LayoutModule } from "../layout/layout.module";
import { OWATableModule } from "../owa-table/owa-table.module";
import { HistoryNotesAsSubListComponent } from "./containers/history-notes-as-sublist.component";
import { HistoryNotesListComponent } from "./containers/history-notes-list.component";

@NgModule({
   imports: [
      CommonModule,
      LayoutModule,
      InfiniteScrollModule,
      OWATableModule,
      DataTableModule,
      FormRegistrationModule,
      FormsModule,
      LoadingOverlayModule,
      FileCarouselModule,
      FileIconModule,
   ],
   declarations: [HistoryNotesListComponent, HistoryNotesAsSubListComponent],
   exports: [HistoryNotesListComponent, HistoryNotesAsSubListComponent],
})
export class HistoryNotesListModule {}
