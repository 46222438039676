import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { SystemWebPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/systemwebpreferences.service";
import { Observable, Subject, takeUntil } from "rxjs";

import { PasswordResetModel } from "../../../../libraries/owa-gateway-sdk/src/lib/models/password-reset.model";
import { VerificationTokenInformationModel } from "../../../../libraries/owa-gateway-sdk/src/lib/models/verification-token-information.model";
import { PasswordResetService } from "../../../../libraries/owa-gateway-sdk/src/lib/services/password-reset.service";
import { AuthenticationService } from "../authentication/authentication.service";

@Component({
   selector: "owa-password-reset",
   templateUrl: "./password-reset.component.html",
   styleUrls: ["./password-reset.component.css"],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
   public resetModel: PasswordResetModel = new PasswordResetModel();
   public observableToken: Observable<VerificationTokenInformationModel> =
      new Observable<VerificationTokenInformationModel>();
   public token = "";
   submitButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   tokenModel: VerificationTokenInformationModel = new VerificationTokenInformationModel();
   errorMessage: string;
   private postModel: VerificationTokenInformationModel = new VerificationTokenInformationModel();

   private unsubscribe = new Subject<void>();

   constructor(
      private activatedRoute: ActivatedRoute,
      private passwordResetService: PasswordResetService,
      private errorMessageService: ErrorMessageService,
      private sessionCheckService: SessionCheckService,
      private authenticationService: AuthenticationService,
      private systemWebPreferenceService: SystemWebPreferencesService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.token = this.activatedRoute.snapshot.queryParams.token;
      if (this.token === undefined) {
         this.token = this.activatedRoute.snapshot.queryParams.Token;
      }
      this.postModel.Token = this.token;
      this.passwordResetService
         .getTokenInformation(encodeURIComponent(this.token))
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (res) => {
               this.tokenModel = res;
               this.resetModel.LocationID = this.tokenModel.LocationID;
               this.systemWebPreferenceService
                  .GetThemeColorSystemWebPreference(this.resetModel.LocationID)
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((prefs) => {
                     if (prefs != null) {
                        this.authenticationService.setTheme(prefs);
                     }
                  });
            },
            (err) => {
               this.errorMessage = this.errorMessageService.processHttpError(err).Message;
            }
         );
      this.resetModel.Token = encodeURIComponent(this.token);
      this.passwordResetService.Results.pipe(takeUntil(this.unsubscribe)).subscribe((resetSuccessful) => {
         if (resetSuccessful != null) {
            if (!resetSuccessful.ok) {
               this.errorMessageService.triggerHttpErrorMessage(resetSuccessful);
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
   }

   onClick() {
      if (
         this.resetModel.Newpassword === undefined ||
         this.resetModel.Newpassword.trim() === "" ||
         this.resetModel.ConfirmPassword === undefined ||
         this.resetModel.ConfirmPassword.trim() === ""
      ) {
         this.errorMessageService.triggerErrorMessage("Passwords are Required");
         return;
      }
      this.submitButtonStatus = LcsProgressButtonStatus.InProgress;
      this.resetModel.Username = this.tokenModel.UserName;
      this.passwordResetService.updatePassword(this.resetModel);
   }
}
