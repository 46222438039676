import { Injectable } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Observable } from "rxjs";

import { OwnerProspectModel } from "../models/owner-prospect.model";

@Injectable({ providedIn: "root" })
export class OwnerProspectsService {
   readonly endpoint = "OwnerProspects";

   constructor(private apiService: ApiService) {}

   get(
      id?: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<OwnerProspectModel> {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUrl(
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters
      );
   }
}
