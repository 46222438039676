import { Injectable } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { AmountBasis } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/amount-basis.enum";
import { BudgetType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/budget-type.enum";
import { HistNoteFilter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/hist-note-filter.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

import { AssetPropVendorAssignment } from "./models/report-parameter-asset-prop-vendor-assignment.model";
import { ReportParameterValueModel } from "./models/report-parameter-value.model";
import { ReportParameterDependencyService } from "./report-parameter-dependency.service";
import { ReportParametersService } from "./report-parameters.service";

@Injectable()
export class ReportParameterControlStatusService {
   public reportParameterValueModel: ReportParameterValueModel;
   private dependencyService: ReportParameterDependencyService;
   private reportParametersService: ReportParametersService;

   constructor(dependencyService: ReportParameterDependencyService, reportParametersService: ReportParametersService) {
      this.dependencyService = dependencyService;
      this.reportParametersService = reportParametersService;
   }

   getInitialDisableValue(
      reportID: number,
      reportParameter: ReportParameter,
      reportParameterValues: Array<ReportParameterValueModel>
   ): boolean {
      let disabled = false;
      reportParameterValues.forEach((values) => {
         const disableResult = this.getDisableParameterValue(reportID, values, reportParameter);
         if (disableResult) {
            disabled = true;
         }
      });
      return disabled;
   }

   public getDisableParameterValue(
      reportID: number,
      updatedValue: ReportParameterValueModel,
      reportParameter: ReportParameter
   ) {
      let disabled: boolean;
      const dependencies = this.dependencyService.getDependencies(reportID);
      if (dependencies.includes(reportParameter)) {
         if (!updatedValue) {
            return false;
         }
         if (
            reportParameter === ReportParameter.BATCHCREDITCARDS &&
            updatedValue.reportParameter === ReportParameter.BatchProps
         ) {
            return (disabled = this.getParameterStatus(updatedValue));
         } else if (
            updatedValue.reportParameter === ReportParameter.BATCHBANKS &&
            reportParameter === ReportParameter.BatchProps
         ) {
            return (disabled = GlobalsService.convertStringToBoolean(updatedValue.value) === true);
         } else if (
            reportParameter === ReportParameter.BatchProps &&
            updatedValue.reportParameter === ReportParameter.BATCHCREDITCARDS
         ) {
            return (disabled = this.getParameterStatus(updatedValue));
         } else if (
            reportParameter === ReportParameter.IncludeOwnerProspects &&
            updatedValue.reportParameter === ReportParameter.HISTNOTESFILTER
         ) {
            return (disabled = this.getHistNotesFilterStatus(updatedValue, HistNoteFilter.Owner));
         } else if (
            reportParameter === ReportParameter.CustomerStatus &&
            updatedValue.reportParameter === ReportParameter.HISTNOTESFILTER
         ) {
            return (disabled = this.getHistNotesFilterStatus(updatedValue, HistNoteFilter.Tenant));
         } else if (
            reportParameter === ReportParameter.PropertyIDs &&
            updatedValue.reportParameter === ReportParameter.AssetUnassignedProps
         ) {
            return (disabled = this.getAssetPropVendorAssignmentStatus(updatedValue));
         } else if (
            (reportParameter === ReportParameter.VendorIDs ||
               reportParameter === ReportParameter.IncludeInactiveVendors) &&
            updatedValue.reportParameter === ReportParameter.AssetUnassignedVendors
         ) {
            return (disabled = this.getAssetPropVendorAssignmentStatus(updatedValue));
         } else if (
            (reportParameter === ReportParameter.StartDate || reportParameter === ReportParameter.EndDate) &&
            updatedValue.reportParameter === ReportParameter.BEFOREPOSTING
         ) {
            return (disabled = this.getDateRangeStatus(updatedValue));
         } else if (
            (reportParameter === ReportParameter.CRPPhone || reportParameter === ReportParameter.CRPOverridePhone) &&
            updatedValue.reportParameter === ReportParameter.CRPOverridePhoneChecked
         ) {
            if (reportParameter === ReportParameter.CRPPhone) {
               return (disabled = this.getCRPCheckStatus(updatedValue));
            } else {
               return (disabled = !this.getCRPCheckStatus(updatedValue));
            }
         } else if (
            (reportParameter === ReportParameter.CRPPreparerDefaultPhone ||
               reportParameter === ReportParameter.CRPPreparerInformationOverridePhone) &&
            updatedValue.reportParameter === ReportParameter.CRPPreparerInformationOverridePhoneChecked
         ) {
            if (reportParameter === ReportParameter.CRPPreparerDefaultPhone) {
               return (disabled = this.getCRPCheckStatus(updatedValue));
            } else {
               return (disabled = !this.getCRPCheckStatus(updatedValue));
            }
         } else if (
            (reportParameter === ReportParameter.CRPPreparerName ||
               reportParameter === ReportParameter.CRPPreparerInformationOverridePhoneChecked ||
               reportParameter === ReportParameter.CRPPreparerDefaultPhone ||
               reportParameter === ReportParameter.CRPPreparerInformationOverridePhone) &&
            updatedValue.reportParameter === ReportParameter.CRPPreparedByAgent
         ) {
            if (reportParameter === ReportParameter.CRPPreparerInformationOverridePhone) {
               return (disabled = true);
            } else {
               return (disabled = !this.getCRPCheckStatus(updatedValue));
            }
         } else if (
            updatedValue.reportParameter === ReportParameter.BATCHPROPSEXCLUDEEMPTY &&
            reportParameter === ReportParameter.PROPSEXCLUDEEMPTY
         ) {
            this.updateCheckedValue(
               GlobalsService.convertStringToBoolean(updatedValue.value) === false,
               reportParameter
            );
            return (disabled = GlobalsService.convertStringToBoolean(updatedValue.value) === false);
         } else if (
            updatedValue.reportParameter === ReportParameter.BATCHOWNERSEXCLUDEEMPTY &&
            reportParameter === ReportParameter.OwnersExcludeEmpty
         ) {
            this.updateCheckedValue(
               GlobalsService.convertStringToBoolean(updatedValue.value) === false,
               reportParameter
            );
            return (disabled = GlobalsService.convertStringToBoolean(updatedValue.value) === false);
         } else if (
            updatedValue.reportParameter === ReportParameter.BANKBALANCESELECTEDONLY &&
            reportParameter === ReportParameter.BALANCESELECTEDONLY
         ) {
            this.updateCheckedValue(
               GlobalsService.convertStringToBoolean(updatedValue.value) === false,
               reportParameter
            );
            return (disabled = GlobalsService.convertStringToBoolean(updatedValue.value) === false);
         } else if (
            updatedValue.reportParameter === ReportParameter.SHOWRENTESCALATION &&
            reportParameter === ReportParameter.IncludePastEscalations
         ) {
            this.updateCheckedValue(
               GlobalsService.convertStringToBoolean(updatedValue.value) === false,
               reportParameter
            );
            return (disabled = GlobalsService.convertStringToBoolean(updatedValue.value) === false);
         } else if (
            updatedValue.reportParameter === ReportParameter.BUDGETTYPE &&
            reportParameter === ReportParameter.AmountBasis
         ) {
            disabled = updatedValue.value !== BudgetType[BudgetType.ProfitLoss];
            if (disabled) {
               const reportParameterValueModel = new ReportParameterValueModel(reportParameter, AmountBasis.Actuals, [
                  AmountBasis.Actuals,
               ]);
               this.reportParametersService.updateParameterValue.next(reportParameterValueModel);
            }
            return disabled;
         } else if (
            updatedValue.reportParameter === ReportParameter.OAHideMgtFeeBreakdown &&
            reportParameter === ReportParameter.OAMgtFees
         ) {
            return GlobalsService.convertStringToBoolean(updatedValue.value) === true;
         }
         return undefined;
      }
   }

   public ClearValues(updatedValue: ReportParameterValueModel, reportParameter: ReportParameter) {
      // clear values
      if (updatedValue.reportParameter === ReportParameter.PropertyID && reportParameter === ReportParameter.UNITID) {
         return true;
      } else if (
         updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS &&
         (reportParameter === ReportParameter.PropertyID || reportParameter === ReportParameter.UNITID)
      ) {
         return true;
      } else if (
         updatedValue.reportParameter === ReportParameter.PropertyIDs &&
         reportParameter === ReportParameter.CUSTIDS
      ) {
         return true;
      }
   }

   private updateCheckedValue(status: boolean, reportParameter: ReportParameter) {
      if (status) {
         const reportParameterValueModel = new ReportParameterValueModel(reportParameter, false);
         this.reportParametersService.updateParameterValue.next(reportParameterValueModel);
      }
   }

   private getAssetPropVendorAssignmentStatus(updatedValue) {
      if (updatedValue.value === AssetPropVendorAssignment[AssetPropVendorAssignment.Only]) {
         return true;
      } else {
         return false;
      }
   }
   private getHistNotesFilterStatus(updatedValue, HistNoteFilterEnum) {
      const SelectedValues = updatedValue.value.slice(1, -1);
      if (SelectedValues.indexOf(HistNoteFilterEnum) > -1) {
         return true;
      } else {
         return false;
      }
   }
   private getParameterStatus(updatedValue) {
      if (GlobalsService.convertStringToBoolean(updatedValue.value)) {
         return true;
      } else {
         return false;
      }
   }
   private getDateRangeStatus(updatedValue) {
      if (updatedValue.value.toLowerCase() === false.toString()) {
         return false;
      } else {
         return true;
      }
   }

   private getCRPCheckStatus(updatedValue) {
      if (updatedValue.value.toString().toLowerCase() === true.toString()) {
         return true;
      } else {
         return false;
      }
   }
}
