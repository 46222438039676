import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

import { LoadingOverlayConfigurationModel } from "./loading-overlay-configuration.model";

@Component({
   selector: "lcs-loading-overlay",
   templateUrl: "loading-overlay.component.html",
})
export class LoadingOverlayComponent implements OnInit {
   @Input() show: boolean;

   @Input() loadingOverlayWrapperCustomStyle = {};

   @Input() loadingOverlayContainerCustomStyle = {};

   @Input() configuration: LoadingOverlayConfigurationModel | null | undefined;

   @Input() diameter: number;

   @Input() showSpinner: boolean;

   @Input() loadingText: string | null = "Loading...";

   @Input() set textWithSpinner(value: string | null) {
      this._textWithSpinner = value;
      if (value) {
         this.loadingText = value;
      } else {
         this.loadingText = "Loading";
      }
   }

   get textWithSpinner() {
      return this._textWithSpinner;
   }

   private _textWithSpinner: string | null;

   @ViewChild("loadingOverlayContainer") loadingOverlayContainer: ElementRef;

   constructor() {}

   ngOnInit() {
      if (this.configuration) {
         if (this.configuration.Diameter && this.configuration.Diameter > 0) {
            this.diameter = this.configuration.Diameter;
         } else {
            this.diameter = 50;
         }
      }
      if (!this.diameter) {
         this.diameter = 50;
      }
   }
}
