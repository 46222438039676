import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgControl, UntypedFormControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";

@Component({
   selector: "owa-numeric-input",
   templateUrl: "numeric-input.component.html",
})
export class NumericInputComponent extends ValueAccessorBase<number> implements OnInit {
   @Input() htmlInputType: string = "number";

   @Input() max: number;

   @Input() min: number;

   @Input() placeholder: string = "";

   @Input() step: number = 1;

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsValidate") validation: ValidationModel;

   @Output() blurEvent = new EventEmitter<any>();

   @Output() enterKeyEvent = new EventEmitter<any>();

   @Output() focusEvent = new EventEmitter<any>();

   @Output() valueChange = new EventEmitter<string>();

   inputFocused: boolean;

   control: UntypedFormControl;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   ngOnInit() {
      if (this.step === undefined) {
         let maxFractionalDigits = 0;
         if (this.validation) {
            if (typeof this.validation.maxFractionalDigits === "number") {
               maxFractionalDigits = this.validation.maxFractionalDigits;
            }
            if (typeof this.validation.min === "number") {
               this.min = this.validation.min;
            }
            if (typeof this.validation.max === "number") {
               this.max = this.validation.max;
            }
         }
         this.step = 1 / 10 ** maxFractionalDigits;
      }
      return super.ngOnInit();
   }

   onFocus(event: FocusEvent) {
      this.inputFocused = true;
      this.focusEvent.emit(event);
   }

   onBlur(event: FocusEvent) {
      this.inputFocused = false;
      this.blurEvent.emit(event);
      this.propagateTouched();
   }

   onEnterKey(event: Event) {
      this.enterKeyEvent.emit(event);
   }
}
