<div class="report-parameter-label">
   <label class="parameter-label">
      {{ parameterLabel }}
   </label>
</div>
<div class="values-wrapper">
   <div class="individual-report-parameter-option">
      <lcs-checkbox
         [name]="name"
         [displayName]="allLabel"
         (ngModelChange)="valueChanged($event)"
         [ngModel]="allChecked"
         [ngModelOptions]="{ standalone: true }"
      >
         {{ allLabel }}
      </lcs-checkbox>
   </div>
   <div class="individual-report-parameter-option">
      <div *ngFor="let parameter of reportReportValueModels">
         <lcs-checkbox
            (ngModelChange)="checkValueChange(parameter)"
            [ngModel]="parameter.value"
            [ngModelOptions]="{ standalone: true }"
            [displayName]="getParameterLabel(parameter)"
         >{{ getParameterLabel(parameter) }}</lcs-checkbox>
      </div>
   </div>
</div>