import { Component, OnInit } from "@angular/core";
import { DialogContentComponent } from "@lcs/dialog/dialog-content-component.interface";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { ContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";

import { TenantConfigurationModel } from "./tenant-configuration.model";

@Component({
   selector: "owa-tenant-detail",
   templateUrl: "./tenant-detail.component.html",
   styleUrls: ["./tenant-detail.component.css"],
})
export class TenantDetailComponent implements OnInit, DialogContentComponent {
   public configuration = new TenantConfigurationModel();
   public tenant: TenantModel;
   public primaryContact: ContactModel;

   constructor(private currentDialogService: CurrentDialogService) {}

   ngOnInit() {
      this.tenant = this.configuration.tenant;
      this.primaryContact = this.tenant.Contacts.filter((f) => f.IsPrimary)[0];
   }

   onOkClick(): void {
      this.currentDialogService.closeDialog();
   }
}
