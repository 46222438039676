import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { ChangeEmailAddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/change-emailaddress.model";
import { BehaviorSubject, Observable, skip, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";
import { ChangeEmailAddressService } from "./change-emailaddress.service";

@Component({
   selector: "owa-change-emailaddress",
   templateUrl: "./change-emailaddress.component.html",
   styleUrls: ["./change-emailaddress.component.css"],
})
export class ChangeEmailAddressComponent implements OnInit, OnDestroy {
   @Input() showChangeEmailAddress: boolean;

   @Input() disableSessionCheck: boolean;
   configuration: ChangeEmailAddressModel;
   public test = true;

   public observableEmailaddress: Observable<ChangeEmailAddressModel> = new Observable<ChangeEmailAddressModel>();

   public changeEmailAddressModel: ChangeEmailAddressModel = new ChangeEmailAddressModel();

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public emialaddress: BehaviorSubject<ChangeEmailAddressModel> = new BehaviorSubject<ChangeEmailAddressModel>(null);

   public sourceSteps: Array<string> = new Array<string>();

   public displayVerifiedEmailShortcut: boolean = false;

   public workflowStep: string;

   public name: string;

   public checked: boolean = true;

   public unsubscribe = new Subject<void>();

   public sourceStep: boolean;

   public isDisplayUpdateUserNameOrEmail: string;

   progressButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   progressButtonStatusSkip: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   isLoaded = new BehaviorSubject<boolean>(false);

   emailAddressModel: ChangeEmailAddressModel = new ChangeEmailAddressModel();

   @Input() locationId: number;

   @Input() webuserId: number;

   constructor(
      private changeEmailAddressService: ChangeEmailAddressService,
      private authenticationService: AuthenticationService,
      private errorMessageService: ErrorMessageService,
      private activatedRoute: ActivatedRoute,
      private sessionCheckService: SessionCheckService
   ) {}

   ngOnInit(): void {
      this.emailAddressModel = this.configuration;
      if (!this.disableSessionCheck) {
         this.sessionCheckService.checkSessionOnFocus = false;
      }
      this.changeEmailAddressService.changeEmailAddressResults.next(new ChangeEmailAddressModel());
      this.changeEmailAddressService.changeEmailAddressResults.pipe(skip(1), takeUntil(this.unsubscribe)).subscribe(
         (emailResult) => {
            if (
               emailResult.WorkflowSteps === WorkflowSteps[WorkflowSteps.MergeUsers] ||
               (emailResult.Message == null &&
                  emailResult.WorkflowSteps === WorkflowSteps[WorkflowSteps.VerificationPending])
            ) {
               this.progressButtonStatus = LcsProgressButtonStatus.Success;
            } else {
               this.progressButtonStatus = LcsProgressButtonStatus.Error;
            }
         },
         () => {
            this.progressButtonStatus = LcsProgressButtonStatus.Error;
         }
      );

      this.isLoaded.next(false);
      if (this.emailAddressModel != null) {
         this.setAllValues(this.emailAddressModel);
      } else {
         this.workflowStep = this.activatedRoute.snapshot.queryParams.sourceStep;
         this.sourceStep = this.workflowStep === WorkflowSteps[WorkflowSteps.Login] ? true : false;
         this.observableEmailaddress = this.changeEmailAddressService.GetChangeEmailAddressModelInfo(
            this.workflowStep,
            this.webuserId,
            this.locationId
         );
         this.observableEmailaddress.subscribe((result) => {
            if (result) {
               this.setAllValues(result);
            }
         });
      }
   }

   ngOnDestroy() {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
   }

   setAllValues(model: ChangeEmailAddressModel) {
      this.changeEmailAddressModel = model;
      this.emialaddress.next(this.changeEmailAddressModel);
      this.displayVerifiedEmailShortcut =
         this.changeEmailAddressModel.HasVerifiedEmailAddress && this.changeEmailAddressModel.EmailAddress != null
            ? true
            : false;
      this.isDisplayUpdateUserNameOrEmail =
         this.displayVerifiedEmailShortcut === true ? "update username" : "send verification email";
      this.name = model.UserNameIsVerified ? "email address" : "username";
      this.changeEmailAddressModel.locationID = model.locationID;
      this.isLoaded.next(true);
   }

   onClick() {
      if (
         this.changeEmailAddressModel.NewEmailAddress == null ||
         this.changeEmailAddressModel.ConfirmEmailAddress == null
      ) {
         this.errorMessageService.triggerErrorMessage("Please provide email address to be updated");
      } else if (this.changeEmailAddressModel.NewEmailAddress !== this.changeEmailAddressModel.ConfirmEmailAddress) {
         this.errorMessageService.triggerErrorMessage("The provided usernames do not match.");
      } else {
         this.progressButtonStatus = LcsProgressButtonStatus.InProgress;
         this.changeEmailAddressService.changeEmailAddress(this.changeEmailAddressModel);
      }
   }

   skipclick() {
      this.authenticationService.redirect();
   }
}
