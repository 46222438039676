<nav>
   <div class="site-menu-inner">
      <div class="menu-content-wrapper">
         <ul class="hamburger-menu-wrapper">
            <li
               class="menu-item hamburger"
               (click)="toggleHamburger()"
               #Hamburgler
            >
               <i class="material-icons">
                  menu
               </i>
            </li>
         </ul>
         <ul class="owa-desktop-menu">
            <li
               *ngFor="let menuItem of menuItems"
               class="menu-item"
            >
               <a
                  id={{menuItem.ID}}
                  routerLink="{{menuItem.Route}}"
               >
                  {{menuItem.Name}}
               </a>
            </li>
         </ul>
      </div>
      <div class="account-menu-wrapper">
         <owa-account-menu></owa-account-menu>
      </div>
   </div>
</nav>