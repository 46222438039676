<label>
   {{ label }}
</label>
<lcs-date-range-picker
   [name]="name"
   [disabled]="disabled"
   [isPeriod]="true"
   [dateStartFormat]="dateStartFormat"
   [dateEndFormat]="dateEndFormat"
   [dateView]="'month'"
   [(ngModel)]="dateRange"
   (ngModelChange)="valueChange($event)"
></lcs-date-range-picker>