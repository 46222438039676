import { Component, Input, TemplateRef } from "@angular/core";
import { MultiPdfViewerModel } from "./models/multi-pdf-viewer.model";

@Component({
   selector: "owa-multi-pdf-viewer-display",
   templateUrl: "multi-pdf-viewer-display.component.html",
})
export class MultiPdfViewerDisplayComponent {
   @Input() selectedPdf: MultiPdfViewerModel;

   @Input() selectedPdfs: Array<MultiPdfViewerModel>;

   @Input() errorHeader: string;

   @Input() errorInformation: string;

   @Input() errorIcon: string;

   @Input() errorContentTemplate: TemplateRef<any>;
}
