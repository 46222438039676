import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { valueOrThrow } from "@lcs/utils/strict-null-utils";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-show-on-report-report-parameter",
   templateUrl: "show-on-report-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class ShowOnReportReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   reportParameters = new Array<ReportReportParameterViewModel>();

   allChecked: boolean = true;

   parameterLabel: string = "Show On Report";

   allLabel: string = "Show All";

   checkCount: number = 7;

   reportReportValueModels = new Array<ReportParameterValueModel>();

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      updatedValue.value = GlobalsService.convertStringToBoolean(updatedValue.value);
      const parameter = this.reportReportValueModels.find((v) => v.reportParameter === updatedValue.reportParameter);
      if (parameter) {
         parameter.value = updatedValue.value;
      }
      if (
         updatedValue.reportParameter === ReportParameter.USERDEF ||
         updatedValue.reportParameter === ReportParameter.TRANH ||
         updatedValue.reportParameter === ReportParameter.HIST ||
         updatedValue.reportParameter === ReportParameter.OPENREC ||
         updatedValue.reportParameter === ReportParameter.CustomerProfileIncludeAddresses ||
         updatedValue.reportParameter === ReportParameter.CustomerProfileIncludePhone ||
         updatedValue.reportParameter === ReportParameter.IncludeBirthdateAndAge ||
         updatedValue.reportParameter === ReportParameter.IncludeStageHistory
      ) {
         if (!updatedValue.value && this.allChecked) {
            this.allChecked = false;
         } else if (updatedValue.value && this.checkStatus() === 7) {
            this.allChecked = true;
         }
      }
   }

   valueChanged(value: boolean) {
      this.allChecked = value;
      this.updateAll(value);
   }

   checkValueChange(value: ReportParameterValueModel): void {
      const parameter: ReportParameterValueModel = valueOrThrow(
         this.reportReportValueModels.find((r) => r.reportParameter === value.reportParameter),
         `Failed to find report parameter "${ReportParameter[value.reportParameter]}.`
      );
      parameter.value = !parameter.value;
      this.reportParametersService.updateParameterValue.next(parameter);
   }

   getParameterLabel(parameter: ReportParameterValueModel): string {
      const name: ReportReportParameterViewModel = valueOrThrow(
         this._reportReportParameters.find((r) => r.ReportParameterID === parameter.reportParameter),
         `Failed to find report parameter "${ReportParameter[parameter.reportParameter]}.`
      );
      return name.PromptText.length > 0 ? name.PromptText : name.DisplayName;
   }

   private updateAll(value: boolean) {
      this.reportReportValueModels.forEach((v) => {
         v.value = value;
         this.reportParametersService.updateParameterValue.next(v);
      });
   }

   private checkStatus(): number {
      this.checkCount = 0;
      this.reportReportValueModels.forEach((v) => {
         if (v.value) {
            this.checkCount++;
         }
      });
      return this.checkCount;
   }

   private initializeParameters() {
      this._reportReportParameters.forEach((parameter) => {
         if (
            parameter.ReportParameterID === ReportParameter.USERDEF ||
            parameter.ReportParameterID === ReportParameter.TRANH ||
            parameter.ReportParameterID === ReportParameter.HIST ||
            parameter.ReportParameterID === ReportParameter.OPENREC ||
            parameter.ReportParameterID === ReportParameter.CustomerProfileIncludeAddresses ||
            parameter.ReportParameterID === ReportParameter.CustomerProfileIncludePhone ||
            parameter.ReportParameterID === ReportParameter.IncludeBirthdateAndAge ||
            parameter.ReportParameterID === ReportParameter.IncludeStageHistory
         ) {
            this.reportParameters = this.reportParameters.concat(parameter);
            const parameterValueModel = new ReportParameterValueModel(parameter.ReportParameterID, true);
            this.reportReportValueModels = this.reportReportValueModels.concat(parameterValueModel);
         }
      });
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
