import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { RadioButtonModule } from "../../radio-button/radio-button.module";
import { ClickToCallBaseService } from "./click-to-call-base.service";
import { ClickToCallViewComponent } from "./click-to-call-view.component";
import { ClickToCallComponent } from "./click-to-call.component";

@NgModule({
   imports: [CommonModule, FormsModule, RadioButtonModule],
   declarations: [ClickToCallComponent, ClickToCallViewComponent],
   providers: [ClickToCallBaseService],
   exports: [ClickToCallComponent],
})
export class ClickToCallModule {}
