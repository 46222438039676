import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NumericInputModule } from "@lcs/inputs/numeric-input/numeric-input.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";

import { CalculatorModule } from "../calculator-input/calculator.module";
import { NumberRangeInputComponent } from "./number-range-input.component";

@NgModule({
   imports: [CommonModule, FormsModule, NumericInputModule, ValidationModule, CalculatorModule],
   declarations: [NumberRangeInputComponent],
   exports: [NumberRangeInputComponent],
})
export class NumberRangeInputModule {}
