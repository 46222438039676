import { HttpClient } from "@angular/common/http";
import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { map, Observable } from "rxjs";

import { RawFileModel } from "./raw-file.model";

@Injectable({
   providedIn: "root",
})
export class DownloaderService {
   private renderer2: Renderer2;

   constructor(private rendererFactory2: RendererFactory2, private httpClient: HttpClient) {
      this.renderer2 = this.rendererFactory2.createRenderer(null, null);
   }

   downloadFileFromURL(url: string, filename: string = "") {
      if (!url) {
         return;
      }

      const anchor: HTMLElement = this.renderer2.createElement("a");
      this.renderer2.setStyle(anchor, "display", "none");
      this.renderer2.setProperty(anchor, "href", url);

      this.renderer2.setProperty(anchor, "download", filename);
      this.renderer2.appendChild(document.body, anchor);
      anchor.click();
      setTimeout(() => {
         window.URL.revokeObjectURL(url);
         this.renderer2.removeChild(document.body, anchor);
      }, 100);
   }

   downloadRawFile(file: RawFileModel): Observable<boolean> {
      if (!file.dataURL) {
         throw new Error("Unable to download raw file - no URL");
      }
      return this.httpClient
         .get(file.dataURL, {
            responseType: "blob",
         })
         .pipe(
            map((response) => {
               if (response) {
                  file.data = response;
                  return true;
               } else {
                  return false;
               }
            })
         );
   }
}
