import { Injectable } from "@angular/core";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class EntitySearchService {
   public searchID: Observable<number | null>;
   public selectorItem: Observable<SelectorItemModel | null>;
   public rowValue: Observable<any | null>;

   private _searchID = new BehaviorSubject<number | null>(null);
   private _selectorItem = new BehaviorSubject<SelectorItemModel | null>(null);
   private _rowValue = new BehaviorSubject<any | null>(null);

   constructor() {
      this.searchID = this._searchID.asObservable();
      this.selectorItem = this._selectorItem.asObservable();
      this.rowValue = this._rowValue.asObservable();
   }
}
