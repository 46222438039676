import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProgressButtonExtModule } from "@lcs/progress-button/progress-button-ext/progress-button-ext.module";

import { ErrorDialogFooterComponent } from "./error-dialog-footer.component";
import { ErrorMessageComponent } from "./error-message.component";

@NgModule({
   imports: [CommonModule, ProgressButtonExtModule],
   declarations: [ErrorMessageComponent, ErrorDialogFooterComponent],
   exports: [ErrorMessageComponent],
})
export class ErrorMessageModule {}
