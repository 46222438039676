import { Component, EventEmitter, Input, Output } from "@angular/core";
import cloneDeep from "lodash/cloneDeep";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { LogicalOperators } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/logical-operators.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";

import { ConstantsService } from "../../core/constants.service";
import { DataTableFilterModel } from "./datatable-filter.model";

@Component({
   selector: "lcs-datatable-filters",
   templateUrl: "datatable-filters.component.html",
   providers: [],
})
export class DataTableFiltersComponent {
   isInverted = false;

   @Input() entityType: EntityType;

   @Input() entitySearchType: EntityType;

   @Input() isFiltersLoading: boolean;

   @Input() set dataTableFilters(values: Array<DataTableFilterModel>) {
      this._dataTableFilters = values;
   }

   get dataTableFilters(): Array<DataTableFilterModel> {
      return this._dataTableFilters;
   }

   @Input() filterOperations: Array<FilterOperationModel>;

   @Input() includeUserDefinedValueFilters: boolean;

   @Input() userDefinedValueFilters: Array<FilterOption>;

   @Input() filtersCollapsed: boolean;

   @Input() showInactivePropertiesDefault: boolean;

   @Output() filtersApplied = new EventEmitter<FilterExpression>();

   @Output() toggleSection = new EventEmitter<void>();

   @Output() clearFilters = new EventEmitter<void>();

   filterExpression: FilterExpression;

   private _dataTableFilters: Array<DataTableFilterModel>;

   onApplyFilters(filters: Array<FilterOption>) {
      let filterExpression = new FilterExpression();
      const OrFilterExpressionApplied = new FilterExpression(LogicalOperators.Or);

      // Default Filters
      for (const filterOption of filters) {
         if (this.isValidFilterOption(filterOption)) {
            filterExpression.FilterOptions.push(cloneDeep(filterOption));
         }

         if (filterOption.FilterName && filterOption.Value === ConstantsService.NullFK) {
            if (this.entityType === EntityType.Prospect) {
               if (
                  filterOption.Operation === FilterOperations.EqualTo &&
                  filterOption.FilterName === "ProspectColorID"
               ) {
                  const nullOption = new FilterOption(
                     "ProspectColor",
                     FilterOperations.HasValue,
                     [false],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.EqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (
                  filterOption.Operation === FilterOperations.NotEqualTo &&
                  filterOption.FilterName === "ProspectColorID"
               ) {
                  const nullOption = new FilterOption(
                     "ProspectColor",
                     FilterOperations.HasValue,
                     [true],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.NotEqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (filterOption.Operation === FilterOperations.In) {
                  OrFilterExpressionApplied.FilterOptions.push(filterOption);
                  filterExpression = OrFilterExpressionApplied;
               }
            }
            if (
               this.entityType === EntityType.Property ||
               this.entityType === EntityType.Vendor ||
               this.entityType === EntityType.OwnerProspects
            ) {
               if (filterOption.Operation === FilterOperations.EqualTo && filterOption.FilterName === "ColorID") {
                  const nullOption = new FilterOption(
                     "Color",
                     FilterOperations.HasValue,
                     [false],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.EqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (
                  filterOption.Operation === FilterOperations.NotEqualTo &&
                  filterOption.FilterName === "ColorID"
               ) {
                  const nullOption = new FilterOption(
                     "Color",
                     FilterOperations.HasValue,
                     [true],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.NotEqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (filterOption.Operation === FilterOperations.In) {
                  OrFilterExpressionApplied.FilterOptions.push(filterOption);
                  filterExpression = OrFilterExpressionApplied;
               }
            }

            if (
               this.entityType !== EntityType.Prospect &&
               this.entityType !== EntityType.Property &&
               this.entityType !== EntityType.Vendor &&
               this.entityType !== EntityType.OwnerProspects
            ) {
               if (filterOption.Operation === FilterOperations.EqualTo) {
                  const nullOption = new FilterOption(
                     filterOption.FilterName,
                     FilterOperations.HasValue,
                     [false],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.EqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (filterOption.Operation === FilterOperations.NotEqualTo) {
                  const nullOption = new FilterOption(
                     filterOption.FilterName,
                     FilterOperations.HasValue,
                     [true],
                     filterOption.Label,
                     filterOption.DisplayValue,
                     0,
                     ExpressDataTypes.Boolean,
                     FilterOperations.NotEqualTo
                  );
                  filterExpression.FilterOptions.push(nullOption);
               } else if (filterOption.Operation === FilterOperations.In) {
                  OrFilterExpressionApplied.FilterOptions.push(filterOption);
                  filterExpression = OrFilterExpressionApplied;
               }
            }
         }
      }
      // User Defined Filters
      if (this.userDefinedValueFilters) {
         for (const filterOption of this.userDefinedValueFilters) {
            if (this.isValidFilterOption(filterOption)) {
               filterExpression.FilterOptions.push(cloneDeep(filterOption));
            }
         }
      }
      this.filtersApplied.emit(filterExpression);
   }

   onClearFilters() {
      this.clearFilters.emit();
   }

   onToggleFilterSection() {
      this.toggleSection.emit();
   }

   onUserDefinedValueFiltersChange(filters) {
      this.userDefinedValueFilters = filters;
   }

   private isValidFilterOption(filterOption: FilterOption): boolean {
      if (filterOption.Operation === null || filterOption.Operation === undefined) {
         return false;
      }

      if (filterOption.Value === null || filterOption.Value === undefined || filterOption.Value === "") {
         return false;
      }

      if (filterOption.Value === ConstantsService.NullFK) {
         // Find filter config
         const dataTableFilter = this._dataTableFilters.find(
            (datatableFilter) => datatableFilter.FilterName === filterOption.FilterName
         );

         // Check if config is key
         if (dataTableFilter && dataTableFilter.IsKey) {
            return false;
         }
      }

      return true;
   }
}
