import { AbstractControl, ValidatorFn } from "@angular/forms";
import { EpayAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/epay-account-type.enum";

export class AccountNumberValidator {
   public static getValidator(paymentMethodControlName: string): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }

         const accountNumber: string = control.value.replace("-", "");

         const validationErrors = { accountNumber: true };

         if (!accountNumber) {
            return validationErrors;
         }

         if (isNaN(parseInt(accountNumber))) {
            if (accountNumber.includes("*") && accountNumber.lastIndexOf("*") === 11) {
               return null;
            }
            return validationErrors;
         }

         let isLuhnDigit = false;
         let sum = 0;
         const luhn = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

         for (let i = accountNumber.length - 1; i > -1; i--) {
            const val = parseInt(accountNumber.charAt(i), 10);
            sum += isLuhnDigit ? luhn[val] : val;
            isLuhnDigit = !isLuhnDigit;
         }

         if (!sum || sum % 10 !== 0) {
            return validationErrors;
         }

         const issuerId = +accountNumber.slice(0, 4);

         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
         const paymentMethod = control.parent.get(paymentMethodControlName).value;

         switch (paymentMethod) {
            case EpayAccountType.MasterCard:
               if (accountNumber.length === 16 && issuerId >= 5000 && issuerId <= 5999) {
                  return null;
               }
               return validationErrors;
            case EpayAccountType.Visa:
               if (
                  (accountNumber.length === 16 || accountNumber.length === 13) &&
                  issuerId >= 4000 &&
                  issuerId <= 4999
               ) {
                  return null;
               }
               return validationErrors;
            case EpayAccountType.AmericanExpress:
               if (
                  accountNumber.length === 15 &&
                  ((issuerId >= 3400 && issuerId <= 3499) || (issuerId >= 3700 && issuerId <= 3799))
               ) {
                  return null;
               }
               return validationErrors;
            case EpayAccountType.DinersClub:
               if (
                  accountNumber.length === 14 &&
                  ((issuerId >= 3000 && issuerId <= 3099) ||
                     (issuerId >= 3600 && issuerId <= 3699) ||
                     (issuerId >= 3800 && issuerId <= 3899))
               ) {
                  return null;
               }
               return validationErrors;
            case EpayAccountType.Discover:
               if (accountNumber.length === 16 && issuerId === 6011) {
                  return null;
               }
               return validationErrors;
            default:
               return null;
         }
      };
   }
}
