<owa-layout [headerTitle]="'Management Fees'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="mgtFeeFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>
      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(observableManagementFees | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>
               <ng-container
                  lcsColumnDef="FeeDate"
                  [width]="6"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Fee Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        *ngIf="row.IsByPayment"
                        class="drilldown-link"
                        style="color:#2c77bc"
                        [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
                     >{{ row.FeeDate | date: "MM/dd/yyyy" }}</a>
                     <a
                        *ngIf="!row.IsByPayment"
                        class="drilldown-link"
                     >{{ row.FeeDate | date: "MM/dd/yyyy" }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="PropertyName"
                  [width]="15"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        *ngIf="row.IsByPayment"
                        class="drilldown-link"
                        style="color:#2c77bc"
                        [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
                     >{{ row.PropertyName }}</a>
                     <a
                        *ngIf="!row.IsByPayment"
                        class="drilldown-link"
                     >{{ row.PropertyName }}</a>
                  </td>
               </ng-container>


               <ng-container
                  lcsColumnDef="FeeAmount"
                  [width]="8"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Fee Amount</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="numeric"
                     class="has-drilldown"
                  >
                     <a
                        *ngIf="row.IsByPayment"
                        class="drilldown-link"
                        style="text-align:right; color:#2c77bc;"
                        [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
                     >{{ row.FeeAmount | currency: "USD":"symbol":"1.2-2" }}</a>
                     <a
                        *ngIf="!row.IsByPayment"
                        class="drilldown-link"
                        style="text-align:right"
                     >{{ row.FeeAmount | currency: "USD":"symbol":"1.2-2" }}</a>
                  </td>
               </ng-container>
            </lcs-table>

            <!-- <div class="datatable-internal-spinner-wrapper" *ngIf="((!(isDataLoading | async)) && ((observableManagementFees | async)?.length < (totalResults | async))) ">
                     <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
               </div> -->
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>