import { AbstractControl, ValidatorFn } from "@angular/forms";

export class PhoneNumberValidator {
   static validatePhoneNumber(): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }
         const valueString = control.value.toString();

         if (this.containsValidOperators(valueString)) {
            return null;
         }
         return { phoneNumber: true };
      };
   }

   static containsValidOperators(value: string) {
      const regexp = new RegExp(/^(1\s?)?(\([0-9]{3}\)|[0-9]{3})?[\s\-]?([0-9]{3})[\s\-]?([0-9]{4})$/g);
      const isValidMatch = value.match(regexp);
      return isValidMatch ? true : false;
   }
}
