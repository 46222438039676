import { PhoneNumberModel } from "./phone-number.model";
import { WebUserAccountModel } from "./web-user-account.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class WebUserModel {
   ConcurrencyID: number;
   CreateDate: Date;
   CreateUserID: number;
   CreditReportingID: number;
   EmailAddress: string;
   FailedLogins: number;
   FirstName: string;
   IsOptInCreditReporting: boolean;
   IsPasswordReset: boolean;
   IsShowEmailAddressInDirectory: boolean;
   IsShowNameAddressInDirectory: boolean;
   IsShowPhoneNumberInDirectory: boolean;
   IsVerifiedEmail: boolean;
   LastFailedLogin?: Date | null ;
   LastLockout?: Date | null ;
   LastLogout?: Date | null ;
   LastName: string;
   LastSuccessfulLogin?: Date | null ;
   MetaTag?: string;
   Name: string;
   PhoneNumbers: Array<PhoneNumberModel>;
   TAToken: string;
   UpdateDate: Date;
   UpdateUserID: number;
   UserName: string;
   UserNameIsVerified: boolean;
   ViewedOWAWhatsNew: boolean;
   WebUserAccounts: Array<WebUserAccountModel>;
   WebUserID: number;
}
