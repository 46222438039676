import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconModule } from "@lcs/icon/icon.module";

import { InformationSplashScreenComponent } from "./information-splash-screen.component";

@NgModule({
   imports: [CommonModule, IconModule],
   declarations: [InformationSplashScreenComponent],
   exports: [InformationSplashScreenComponent],
})
export class InformationSplashScreenModule { }