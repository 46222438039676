import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { filter, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";
import { OWASessionService } from "../session/owa-session.service";
import { TopBarService } from "../top-bar/top-bar.service";

@Component({
   selector: "owa-account-updated",
   templateUrl: "./account-updated.component.html",
   styleUrls: ["./account-updated.component.css"],
})
export class AccountUpdatedComponent implements OnInit, OnDestroy {
   public authenticated: boolean = false;
   public userName: string;
   public locationId: number;

   logInButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   dashboardButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   private unsubscribe = new Subject<void>();

   constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private owaSessionService: OWASessionService,
      private activatedRoute: ActivatedRoute,
      private topbarservice: TopBarService,
      private sessionCheckService: SessionCheckService,
      private cacheMonitorService: CacheMonitorService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.authenticationService.authenticationResult.pipe(takeUntil(this.unsubscribe)).subscribe(
         (loginSuccessful) => {
            if (loginSuccessful != null) {
               if (!loginSuccessful) {
                  this.authenticated = false;
               } else {
                  this.authenticated = true;
                  this.owaSessionService.OWASessionInfo.subscribe((sessionInfo) => {
                     if (sessionInfo) {
                        this.authenticated = true;
                        this.userName = sessionInfo.CurrentUser.UserName;
                     }
                  });
               }
            }
         },
         () => {
            this.logInButtonStatus = LcsProgressButtonStatus.Error;
         }
      );

      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
         if (queryParams) {
            this.locationId = queryParams["locationid"];
         }
      });

      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });
   }

   onDashboardClick() {
      this.dashboardButtonStatus = LcsProgressButtonStatus.InProgress;
      this.router.navigateByUrl("/dashboard");
   }

   ngOnDestroy() {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
      this.topbarservice.topBarIsVisible.next(this.cacheMonitorService.cachesLoadedState);
   }
}
