import { DatePipe, PercentPipe } from "@angular/common";
import { Injectable, PipeTransform } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { RmCurrencyPipe } from "@lcs/pipes/rm-currency-pipe";
import { RmNumberPipe } from "@lcs/pipes/rm-number-pipe";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import isDate from "lodash/isDate";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import reduce from "lodash/reduce";
import uniq from "lodash/uniq";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressReplacementTags } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-replacement-tags.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ExpressControlConditionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-control-condition.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

import { EnumerationInformationService } from "../utils/enumeration-information.service";
import { PhoneNumberPipe } from "./phone-number.pipe";

interface DigitInfo {
   minIntegerDigits: number;
   minFractionDigits: number;
   maxFactionDigits: number;
}

@Injectable()
export class ObjectMapResolverService {
   static readonly rootPath: string = "root";

   // @ts-ignore ts-migrate(2322) FIXME: Type 'Map<ExpressDataTypes.Currency | ExpressDataT... Remove this comment to see the full error message
   private static DataTypePipes: Map<ExpressDataTypes, { pipe: PipeTransform; options: string }> = new Map([
      [ExpressDataTypes.Currency, { pipe: new RmCurrencyPipe(), options: "1.2-2" }],
      [ExpressDataTypes.Date, { pipe: new DatePipe(GlobalsService.locale), options: "MM/dd/yy" }],
      [ExpressDataTypes.DateTime, { pipe: new DatePipe(GlobalsService.locale), options: "MM/dd/yy hh:mm a" }],
      [ExpressDataTypes.Percentage, { pipe: new PercentPipe(GlobalsService.locale), options: ".2" }],
      [ExpressDataTypes.PhoneNumber, { pipe: new PhoneNumberPipe(), options: null }],
      [ExpressDataTypes.Numeric, { pipe: new RmNumberPipe(), options: "0.0-2" }],
   ]);

   private templateTagRegex = "({[0-9a-zA-Z:.]*})";

   private templateReplacementTagRegex = "({rmToken.[a-zA-Z]*})";

   private templateCoalesceRegex = "({[0-9a-zA-Z:.? ']*})";

   constructor(private enumerationInformationService: EnumerationInformationService) {}

   public setPropertyValue(obj: any, path: string, value: any): any {
      const pathParts = path.split(".");
      const valueName = pathParts.pop();
      const parent = ObjectMapResolverService.getPropertyValue(obj, pathParts);
      // @ts-ignore ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      parent[valueName] = value;
      return obj;
   }

   static getPropertyValue(obj: any, path: string | string[]): any {
      if (!path) {
         return null;
      } else if (path === ObjectMapResolverService.rootPath) {
         return obj;
      }

      let pathParts = path;
      if (isString(path)) {
         pathParts = path.split(".");
      }
      return reduce(
         pathParts,
         function (prev, curr): string {
            return prev ? prev[curr] : undefined;
         },
         obj
      );
   }

   /**
    * Adds the full property path to the given object and sets the value of the final property.
    */
   static addPropertyValue(obj: any, path: string, value: any) {
      const pathParts = path.split(".");
      pathParts.reduce((previousObject: any, currentKey: string, index: number) => {
         if (!previousObject) {
            previousObject = {};
         }
         if (!previousObject[currentKey] && index < pathParts.length - 1) {
            previousObject[currentKey] = {};
         }
         if (index === pathParts.length - 1) {
            previousObject[currentKey] = value;
         }
         return previousObject[currentKey];
      }, obj);
      return obj;
   }

   public getPropertyValues(obj: any, path: string, conditions?: Array<ExpressControlConditionModel>): Array<any> {
      if (!path || !obj) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'any[]'.
         return null;
      }

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ExpressControlConditionModel[] |... Remove this comment to see the full error message
      return this.getPropertyValuesByParts(obj, path.split("."), "", conditions);
   }

   public getBooleanDisplayValue(obj: any, path: string, template: string): string {
      const boolParts = template.split("||");
      const value = ObjectMapResolverService.getPropertyValue(obj, path);
      if (value) {
         return boolParts[0];
      } else {
         return boolParts[1];
      }
   }

   public getDisplayValue(
      obj: any,
      path: string,
      expressDataType?: ExpressDataTypes,
      enumerationType?: string,
      minFractionDigits?: number,
      maxFractionDigits?: number,
      IsUTCDateTime?: boolean,
      conditionalTemplate?: string,
      conditionalPropertyPath?: string
   ): string {
      if (!path) {
         return "";
      }
      if (path.indexOf("{") > -1) {
         return this.processDisplayTemplate(obj, path);
      } else if (conditionalPropertyPath) {
         return this.processConditionalPropertyPath(obj, path, conditionalPropertyPath);
      } else if (conditionalTemplate) {
         return this.processConditionalTemplate(obj, path, conditionalTemplate);
      } else {
         const displayValue = ObjectMapResolverService.getPropertyValue(obj, path);
         if (enumerationType) {
            const enumerationInfo = this.enumerationInformationService.getEnumerationInformationForValueFromCache(
               enumerationType,
               +displayValue
            );
            if (enumerationInfo) {
               return enumerationInfo.Description;
            } else {
               return "Error retrieving enum DisplayValue";
            }
         } else {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ExpressDataTypes | undefined' is... Remove this comment to see the full error message
            return this.formatValue(displayValue, expressDataType, minFractionDigits, maxFractionDigits, IsUTCDateTime);
         }
      }
   }

   public getDisplayValues(
      obj: any,
      path: string,
      conditions: Array<ExpressControlConditionModel>,
      expressDataType: ExpressDataTypes
   ): Array<string> {
      let displayValues = this.getPropertyValues(obj, path, conditions);

      if (displayValues.length > 0 && expressDataType) {
         displayValues = displayValues.map((value) => this.formatValue(value, expressDataType));
      }
      return displayValues;
   }

   public filterBySatisfiesFilterOperation(
      items: Array<any>,
      fieldName: string,
      filterOperation: FilterOperations,
      fieldValue: any
   ): Array<any> {
      let operator = "eq";
      if (filterOperation === FilterOperations.NotEqualTo) {
         operator = "ne";
      } else if (filterOperation === FilterOperations.StartsWith) {
         operator = "sw";
      } else if (filterOperation === FilterOperations.NotIn) {
         operator = "ni";
      } else if (filterOperation === FilterOperations.GreaterThan) {
         operator = "gt";
      }

      return ObjectMapResolverService.filterBySatisfiesCondition(items, fieldName, operator, fieldValue);
   }

   static filterBySatisfiesCondition(
      items: Array<Object>,
      path: string,
      operator: string,
      fieldValue: string | number | boolean | Array<string | number | boolean>
   ): Array<any> {
      let arrayValues = [];
      // if fieldValue is not an array, then we will create a new array and add fieldValue to it
      if (!(fieldValue instanceof Array)) {
         // @ts-ignore ts-migrate(2322) FIXME: Type '(string | number | boolean)[]' is not assign... Remove this comment to see the full error message
         arrayValues = new Array<string | number | boolean>();
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | number | boolean' is no... Remove this comment to see the full error message
         arrayValues.push(fieldValue);
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type '(string | number | boolean)[]' is not assign... Remove this comment to see the full error message
         arrayValues = fieldValue;
      }

      if (items === null || items === undefined) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'any[]'.
         return null;
      }

      if (items.length === 0 || !path) {
         return items;
      }

      return items.filter((item) => this.satisfiesCondition(item, path, operator, arrayValues));
   }

   static satisfiesCondition(
      item: Object,
      path: string,
      operator: string,
      values: Array<string | number | boolean>
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): boolean {
      const firstValue = values[0];
      let propertyValue = ObjectMapResolverService.getPropertyValue(item, path);
      if (propertyValue || propertyValue === 0 || propertyValue === false) {
         propertyValue = propertyValue.toString();
      }
      if (operator === "hv") {
         const mustHaveValue = values[0] === "true";
         const hasValue = !isEmpty(this.getPropertyValue(item, path));
         if ((mustHaveValue && hasValue) || (!mustHaveValue && !hasValue)) {
            return true;
         }
         return false;
      } else if (!propertyValue) {
         return false;
      }
      if (operator === "eq") {
         return propertyValue.toString().toLowerCase() === firstValue.toString().toLowerCase();
      } else if (operator === "ne") {
         return propertyValue.toString().toLowerCase() !== firstValue.toString().toLowerCase();
      } else if (operator === "sw") {
         return propertyValue.toString().toLowerCase().indexOf(firstValue.toString().toLowerCase()) === 0;
      } else if (operator === "ni") {
         if (values.length === 0) {
            return true;
         }
         return (
            values.findIndex((value) => propertyValue.toString().toLowerCase() === value.toString().toLowerCase()) ===
            -1
         );
      } else if (operator === "in") {
         if (values.length === 0) {
            return true;
         }
         return (
            values.findIndex((value) => propertyValue.toString().toLowerCase() === value.toString().toLowerCase()) > -1
         );
      } else if (operator === "ct") {
         if (values.length === 0) {
            return true;
         }
         return propertyValue.toLowerCase().indexOf(firstValue.toString().toLowerCase()) !== -1;
      } else if (operator === "ge") {
         if (values.length === 0) {
            return true;
         }
         const dateValue = new Date(<string>firstValue);
         if (isDate(dateValue)) {
            return new Date(propertyValue).getTime() >= new Date(firstValue.toString()).getTime();
         }
         return propertyValue.toString().toLowerCase() >= firstValue.toString().toLowerCase();
      } else if (operator === "gt") {
         if (values.length === 0) {
            return true;
         }
         const dateValue = new Date(<string>firstValue);
         if (isDate(dateValue)) {
            return new Date(propertyValue).getTime() > new Date(firstValue.toString()).getTime();
         }
         return propertyValue.toString().toLowerCase() > firstValue.toString().toLowerCase();
      } else if (operator === "le") {
         if (values.length === 0) {
            return true;
         }
         const dateValue = new Date(<string>firstValue);
         if (isDate(dateValue)) {
            return new Date(propertyValue).getTime() <= new Date(firstValue.toString()).getTime();
         }
         return propertyValue <= firstValue.toString();
      } else if (operator === "bt") {
         if (values.length === 0) {
            return true;
         }
         const dateValue0 = new Date(<string>values[0]);
         const dateValue1 = new Date(<string>values[1]);
         if (isDate(dateValue0) && isDate(dateValue1)) {
            return (
               new Date(propertyValue).getTime() >= dateValue0.getTime() &&
               new Date(propertyValue).getTime() <= dateValue1.getTime()
            );
         }
         return (
            propertyValue.toString().toLowerCase() >= values[0].toString().toLowerCase() &&
            propertyValue.toLowerCase() <= values[1].toString().toLowerCase()
         );
      }
   }

   public getEmbedsInPaths(paths: Array<string>): Array<string> {
      let embeds = new Array<string>();

      for (const path of paths) {
         embeds = embeds.concat(this.getEmbedsInPath(path));
      }

      return uniq(embeds);
   }

   public getEmbedsInPath(path: string, trimEnd: boolean = true) {
      const embeds = new Array<string>();
      let pathParts = path.split(".");
      if (trimEnd) {
         pathParts = pathParts.slice(0, pathParts.length - 1);
      }
      if (pathParts.length === 0) {
         return embeds;
      }
      let processedPath = "";
      for (const pathPart of pathParts) {
         if (pathPart !== "0") {
            if (processedPath) {
               processedPath = processedPath + "." + pathPart;
            } else {
               processedPath = pathPart;
            }
            embeds.push(processedPath);
         }
      }
      return embeds;
   }

   processEntityValueSourcePathInformation(source: ValueSourceModel): Array<string> {
      const regexp = new RegExp(this.templateTagRegex, "g");
      const matches = regexp.exec(source.DisplayValueSourcePath);
      let fields = new Array<string>();

      const numFields = matches ? matches.length : 0;
      if (numFields === 0) {
         fields.push(source.DisplayValueSourcePath);
         fields.push(source.EntityValueSourcePath);
         fields.push(source.IncludePropertyPath);
         fields.push(source.AdditionalInfoSourcePath);
         if (source.AdditionalDataFields && source.AdditionalDataFields.length > 0) {
            fields = fields.concat(source.AdditionalDataFields);
         }
         return fields.filter((f) => f !== undefined);
      }

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RegExpExecArray | null' is not a... Remove this comment to see the full error message
      fields = this.joinResults(regexp, matches, source.DisplayValueSourcePath);
      fields.push(source.EntityValueSourcePath);
      fields.push(source.IncludePropertyPath);
      if (source.AdditionalDataFields && source.AdditionalDataFields.length > 0) {
         fields = fields.concat(source.AdditionalDataFields);
      }
      fields = fields.map((field) => {
         if (field && field.indexOf(":") > -1) {
            return field.split(":")[0];
         }
         return field;
      });
      return fields.filter((f) => f !== undefined);
   }

   processDisplayTemplate(obj: any, template: string | EntityField): any {
      let isProcessed = false;

      if (template && (template as EntityField).Identifier) {
         return this.processTemplateFields(obj, (template as EntityField).Identifier);
      }

      const templateString = template as string;

      let regexp = new RegExp(this.templateTagRegex, "g");
      let matches = regexp.exec(templateString);
      let numFields = matches ? matches.length : 0;
      if (numFields > 0) {
         template = this.processTemplateFields(obj, templateString);
         isProcessed = true;
      }

      regexp = new RegExp(this.templateReplacementTagRegex, "g");
      matches = regexp.exec(templateString);
      const numTokens = matches ? matches.length : 0;
      if (numTokens > 0) {
         template = this.processReplacementTokens(obj, templateString);
         isProcessed = true;
      }

      regexp = new RegExp(this.templateCoalesceRegex, "g");
      matches = regexp.exec(templateString);
      numFields = matches ? matches.length : 0;
      if (numFields > 0 && !isProcessed) {
         template = this.processTemplateCoalesce(obj, templateString);
         isProcessed = true;
      }

      if (!isProcessed) {
         return ObjectMapResolverService.getPropertyValue(obj, templateString);
      }
      return template;
   }

   processTemplateFields(obj: any, template: string): any {
      const regexp = new RegExp(this.templateTagRegex, "g");
      const matches = regexp.exec(template);
      const numFields = matches ? matches.length : 0;
      if (numFields === 0) {
         return ObjectMapResolverService.getPropertyValue(obj, template);
      }

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RegExpExecArray | null' is not a... Remove this comment to see the full error message
      const fields = this.joinResults(regexp, matches, template);
      for (const field of fields) {
         let fieldPath = field;
         let format = "";
         if (field.indexOf(":") > -1) {
            const fieldParts = field.split(":");
            fieldPath = fieldParts[0];
            format = fieldParts[1];
         }
         let valueString = "";
         if (format === ExpressDataTypes[ExpressDataTypes.Count]) {
            const values = this.getPropertyValues(obj, fieldPath);
            valueString = this.formatValue(values, ExpressDataTypes.Count);
            valueString = values.length.toString();
         } else {
            const value = ObjectMapResolverService.getPropertyValue(obj, fieldPath);
            valueString = this.formatValue(value, ExpressDataTypes[format]);
         }

         template = template.replace("{" + field + "}", valueString);
      }
      return template;
   }

   processTemplateCoalesce(obj: any, template: string) {
      const regexp = new RegExp(this.templateCoalesceRegex, "g");
      const matches = regexp.exec(template);
      const numFields = matches ? matches.length : 0;
      if (numFields === 0) {
         return ObjectMapResolverService.getPropertyValue(obj, template);
      }

      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RegExpExecArray | null' is not a... Remove this comment to see the full error message
      const fields = this.joinResults(regexp, matches, template);
      for (const field of fields) {
         let fieldPath = field;

         let valueString = "";
         if (field.indexOf("??") > -1) {
            const fieldParts = field.split("??");
            fieldPath = fieldParts[0].trim();
            const value = ObjectMapResolverService.getPropertyValue(obj, fieldPath);
            if (value) {
               valueString = value;
            } else {
               const coalesceValue = fieldParts[1].trim();
               if (coalesceValue.startsWith("'") && coalesceValue.endsWith("'")) {
                  valueString = coalesceValue.substr(0, coalesceValue.length - 1).substr(1);
               } else if (!isNaN(+coalesceValue)) {
                  valueString = coalesceValue;
               } else {
                  valueString = ObjectMapResolverService.getPropertyValue(obj, fieldPath);
               }
            }
         }

         template = template.replace("{" + field + "}", valueString);
      }
      return template;
   }

   processReplacementTokens(obj: any, template: string): string {
      const regexp = new RegExp(this.templateReplacementTagRegex, "g");
      const matches = regexp.exec(template);
      const numFields = matches ? matches.length : 0;
      if (numFields === 0) {
         return ObjectMapResolverService.getPropertyValue(obj, template);
      }
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RegExpExecArray | null' is not a... Remove this comment to see the full error message
      const fields = this.joinResults(regexp, matches, template);
      for (const field of fields) {
         const fieldParts = field.split(".");
         const replacementTagName = fieldParts[1];
         if (replacementTagName === ExpressReplacementTags[ExpressReplacementTags.RMNow]) {
            template = template.replace("{" + field + "}", new Date().toString());
         }
      }
      return template;
   }

   processConditionalTemplate(obj: any, propertyPath: string, conditionalTemplate: string): string {
      const value = ObjectMapResolverService.getPropertyValue(obj, propertyPath);
      let displayValue = value;
      if (conditionalTemplate[0] !== "(" || conditionalTemplate[conditionalTemplate.length - 1] !== ")") {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
         return;
      }

      const groupOfConditions = conditionalTemplate.substr(1, conditionalTemplate.length - 2);
      groupOfConditions.split(";").forEach((condition: string) => {
         const valueReplacement = condition.split(":");
         const conditionValue = valueReplacement[0];
         if (conditionValue === "null") {
            displayValue = value ? value : valueReplacement[1];
         } else {
            if (value != null && value.toString() === conditionValue) {
               const conditionReplacement = valueReplacement[1];
               if (conditionReplacement.indexOf("{") > -1) {
                  displayValue = this.processDisplayTemplate(obj, conditionReplacement);
               } else {
                  displayValue = valueReplacement[1];
               }
               return;
            }
         }
      });

      return displayValue;
   }

   processConditionalPropertyPath(obj: any, propertyPath: string, conditionalPropertyPath: string): string {
      const value = ObjectMapResolverService.getPropertyValue(obj, propertyPath);
      let displayValue = value;
      if (conditionalPropertyPath[0] !== "(" || conditionalPropertyPath[conditionalPropertyPath.length - 1] !== ")") {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
         return;
      }

      let conditionSatisfied = false;
      const groupOfConditions = conditionalPropertyPath.substr(1, conditionalPropertyPath.length - 2);
      groupOfConditions.split(";").forEach((condition: string) => {
         if (conditionSatisfied) {
            return;
         }
         const conditionSet = condition.split(":");
         if (!conditionSet || conditionSet.length !== 2) {
            return;
         }
         const conditionEquation = conditionSet[0];
         const conditionPropertyPath = conditionSet[1];
         const equationSet = conditionEquation.split(",");
         if (!equationSet || equationSet.length !== 3) {
            return;
         }
         const equationPropertyPath = equationSet[0];
         const equationOperator = equationSet[1];
         const equationValue = equationSet[2];
         const propertyPathValue = ObjectMapResolverService.getPropertyValue(obj, equationPropertyPath);

         let equationResult = false;
         switch (equationOperator) {
            case "eq":
               if (equationValue === "null") {
                  equationResult = propertyPathValue == null || Object.keys(propertyPathValue).length !== 0;
               } else {
                  equationResult = String(propertyPathValue) === String(equationValue);
               }
               break;
            case "ne":
               if (equationValue === "null") {
                  equationResult = propertyPathValue != null && Object.keys(propertyPathValue).length !== 0;
               } else {
                  equationResult = String(propertyPathValue) !== String(equationValue);
               }
               break;
            case "mergecolumns":
               displayValue =
                  displayValue +
                  " " +
                  equationValue +
                  " " +
                  ObjectMapResolverService.getPropertyValue(obj, conditionPropertyPath);
               conditionSatisfied = true;
               return;
         }
         if (equationResult) {
            displayValue = ObjectMapResolverService.getPropertyValue(obj, conditionPropertyPath);
            conditionSatisfied = true;
            return;
         }
      });

      return displayValue;
   }

   formatValueTemplate(value: any, format: ExpressDataTypes) {
      let valueString = "";
      if ((value === false || value === undefined || value === null) && format !== ExpressDataTypes.Boolean) {
         return valueString;
      }
      const regexp = new RegExp(this.templateTagRegex, "g");
      const matches = regexp.exec(value.toString());
      const numFields = matches ? matches.length : 0;
      if (numFields === 0) {
         return this.formatValue(value, format);
      } else {
         let template = value.toString();
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RegExpExecArray | null' is not a... Remove this comment to see the full error message
         const fields = this.joinResults(regexp, matches, template);
         for (const field of fields) {
            let fieldValue = field;
            let formatString = "";
            if (field.indexOf(":") > -1) {
               const fieldParts = field.split(":");
               fieldValue = fieldParts[0];
               formatString = fieldParts[1];
            }
            valueString = this.formatValue(fieldValue, ExpressDataTypes[formatString]);
            template = template.replace("{" + field + "}", valueString);
         }
         return template;
      }
   }

   formatValue(
      value: any,
      format: ExpressDataTypes,
      minFractionDigits?: number,
      maxFractionDigits?: number,
      isUTCDateTime?: boolean
   ) {
      let digitInfo: DigitInfo;
      let valueString = "";
      if ((value === false || value === undefined || value === null) && format !== ExpressDataTypes.Boolean) {
         return valueString;
      }

      // TODO: add more null value handling here, or move it to the serializer
      if (format === ExpressDataTypes.Date || format === ExpressDataTypes.DateTime) {
         if (value.toString() === "0001-01-01T00:00:00" || value.toString() === "1/1/0001 12:00:00 AM") {
            return valueString;
         }
         if (typeof value === "string" && value.indexOf(" - ") > -1) {
            return value;
         } else if (isUTCDateTime) {
            value = new Date(value.toString().concat("Z"));
         }
      }

      if (format === ExpressDataTypes.TimeSpan) {
         const timeSpan = value.split(".");
         return valueString + timeSpan[0] + ":" + timeSpan[1];
      }

      if (format === ExpressDataTypes.Numeric || format === ExpressDataTypes.Key) {
         if (value === -1) {
            return valueString;
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'DigitInfo | null' is not assignable to type ... Remove this comment to see the full error message
         digitInfo = this.getDigitInfo(minFractionDigits, maxFractionDigits);
      }

      if (format === ExpressDataTypes.Boolean) {
         return value;
      }

      if (format === ExpressDataTypes.Count) {
         if ("length" in value) {
            valueString = value.length;
         } else {
            valueString = "--";
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'DigitInfo | null' is not assignable to type ... Remove this comment to see the full error message
         digitInfo = this.getDigitInfo(minFractionDigits, maxFractionDigits);
      }

      if (format === ExpressDataTypes.Currency) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'DigitInfo | null' is not assignable to type ... Remove this comment to see the full error message
         digitInfo = this.getDigitInfo(minFractionDigits, maxFractionDigits);
      }

      valueString = value.toString();
      if (format) {
         const dataTypePipe = ObjectMapResolverService.DataTypePipes.get(format);
         if (dataTypePipe) {
            // @ts-ignore ts-migrate(2454) FIXME: Variable 'digitInfo' is used before being assigned... Remove this comment to see the full error message
            if (digitInfo) {
               const options = this.formatOptions(format, digitInfo);
               valueString = dataTypePipe.pipe.transform(value, options);
            } else {
               valueString = dataTypePipe.pipe.transform(value, dataTypePipe.options);
            }
         }
      }
      return valueString;
   }

   getDigitInfo(minFractionDigits?: number, maxFractionDigits?: number): DigitInfo | null {
      if (!minFractionDigits && !maxFractionDigits) {
         return null;
      }

      const digitInfo = {
         minIntegerDigits: 0,
         minFractionDigits: minFractionDigits ? minFractionDigits : 0,
         maxFactionDigits: maxFractionDigits ? maxFractionDigits : 3,
      };
      return digitInfo;
   }

   formatOptions(dataType: ExpressDataTypes, digitInfo: DigitInfo): any {
      let options: any;

      if (dataType === ExpressDataTypes.Currency) {
         options = `${digitInfo.minIntegerDigits}.${digitInfo.minFractionDigits}-${digitInfo.maxFactionDigits}`;
      } else if (dataType === ExpressDataTypes.Numeric) {
         options = `${digitInfo.minIntegerDigits}.${digitInfo.minFractionDigits}-${digitInfo.maxFactionDigits}`;
      }

      return options;
   }

   processEntitySourceDisplayValueResults(source: ValueSourceModel, result: any) {
      return this.processDisplayTemplate(result, source.DisplayValueSourcePath);
   }

   processEntitySourceAdditionalInfoResults(source: ValueSourceModel, result: any) {
      return this.processDisplayTemplate(result, source.AdditionalInfoSourcePath);
   }

   buildSelectorItem(
      obj: any,
      valuePath: string,
      displayValuePath: string,
      additionalInfoSourcePath: string | EntityField,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string[]'.
      additionalDataFields: Array<string> = null
   ): SelectorItemModel {
      const item = new SelectorItemModel();
      item.displayValue = this.processDisplayTemplate(obj, displayValuePath);
      item.value = ObjectMapResolverService.getPropertyValue(obj, valuePath);

      item.additionalInfoValue = this.processDisplayTemplate(obj, additionalInfoSourcePath);
      if (additionalDataFields && additionalDataFields.length > 0) {
         for (const additionalDataField of additionalDataFields) {
            item.additionalData.set(
               additionalDataField,
               ObjectMapResolverService.getPropertyValue(obj, additionalDataField)
            );
         }
      }
      return item;
   }

   private joinResults(regexp: RegExp, matches: RegExpMatchArray, displayValue: string) {
      let match = matches;
      const fields = new Array<string>();
      while (match != null) {
         let matchText = match[0];
         matchText = matchText.substring(1, matchText.length - 1);
         fields.push(matchText);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'RegExpExecArray | null' is not assignable to... Remove this comment to see the full error message
         match = regexp.exec(displayValue);
      }
      return fields;
   }

   private getPropertyValuesByParts(
      obj: any,
      pathParts: Array<string>,
      processedPath: string,
      conditions: Array<ExpressControlConditionModel>
   ): Array<any> {
      let results = new Array<any>();
      let item = obj;
      for (let index = 0; index < pathParts.length; index++) {
         if (!item) {
            break;
         }
         const pathPart = pathParts[index];
         if (pathPart === "0") {
            let applicableConditions = new Array<ExpressControlConditionModel>();
            if (conditions) {
               applicableConditions = conditions.filter((condition) => {
                  return (
                     condition.ConditionTargetIdentifier.substring(
                        0,
                        condition.ConditionTargetIdentifier.lastIndexOf(".")
                     ) === processedPath
                  );
               });
            }
            for (const applicableCondition of applicableConditions) {
               const field = applicableCondition.ConditionTargetIdentifier.substring(
                  applicableCondition.ConditionTargetIdentifier.lastIndexOf(".") + 1
               );
               item = this.filterBySatisfiesFilterOperation(
                  item,
                  field,
                  applicableCondition.FilterOperation,
                  applicableCondition.Value
               );
            }

            const remainingPathParts = pathParts.slice(index + 1);
            for (const arrayItem of item) {
               let propertyValues = this.getPropertyValuesByParts(
                  arrayItem,
                  remainingPathParts,
                  processedPath,
                  conditions
               );
               propertyValues = propertyValues.filter((pv) => pv);
               results = results.concat(propertyValues);
            }
            return results;
         } else {
            if (processedPath) {
               processedPath = processedPath + "." + pathPart;
            } else {
               processedPath = pathPart;
            }
            item = item[pathPart];
         }
      }
      if (item) {
         results.push(item);
      }
      return results;
   }
}
