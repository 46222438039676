////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum Privilege {
   NotSet = 0,
   user = 1,
   pay = 2,
   cust = 3,
   unit = 4,
   unittype = 5,
   prop = 6,
   bankreg = 8,
   glacc = 9,
   pref = 10,
   liveupd = 11,
   clrdb = 12,
   crback = 13,
   rsback = 14,
   postlate = 15,
   post = 16,
   report = 17,
   modifyrecurringcharges = 18,
   chgtypes = 19,
   vendor = 20,
   histcat = 21,
   alcord = 22,
   util = 24,
   letters = 25,
   invoices = 26,
   upg = 28,
   imp = 29,
   accrep = 30,
   journal = 31,
   begbal = 32,
   tax = 33,
   editmergeddoc = 34,
   billview = 35,
   NonRecurringCRE = 36,
   ReconcileCAM = 37,
   epaysetup = 38,
   epaybatch = 39,
   epaysngl = 40,
   refunds = 41,
   backdate = 43,
   udflists = 44,
   backdatec = 45,
   budget = 46,
   owner = 47,
   alloc = 48,
   custcred = 49,
   remind = 50,
   billterms = 51,
   blankcheck = 52,
   loans = 53,
   sd_transfer = 54,
   custmove = 55,
   chglatefee = 56,
   custtrans = 57,
   amenities = 58,
   vendorcredits = 59,
   custhist = 60,
   mngsorts = 61,
   prospect = 62,
   floors = 63,
   leadsrs = 64,
   avexcept = 65,
   unitstat = 66,
   recurringcharges = 67,
   hcbypass = 68,
   items = 69,
   hcset = 70,
   bill = 71,
   check = 72,
   setbillpay = 73,
   EditAllMenuRibbons = 74,
   EditOwnMenuRibbon = 75,
   chkdefbank = 76,
   mmrent = 77,
   propgrps = 79,
   unitpick = 80,
   cctrans = 81,
   Locations = 82,
   chknumovrd = 83,
   ReconcileTrans = 85,
   signatures = 86,
   BirdsEyeViewMaps = 87,
   po = 88,
   po_admin = 89,
   InventoryTransactions = 90,
   bankdep = 91,
   edit1099 = 92,
   estimate = 93,
   estimateal = 94,
   secdepref = 95,
   Screenings = 96,
   AccessOtherUsersScreenings = 97,
   postgpr = 99,
   jrnlbasis = 100,
   prvtvendor = 101,
   ssnmask = 102,
   poworkflow = 103,
   chartmaps = 104,
   managetab = 105,
   customtab = 106,
   regjournal = 107,
   regcheck = 108,
   regbill = 109,
   proshist = 110,
   prophist = 111,
   unithist = 112,
   vendhist = 113,
   ownerhist = 114,
   issuehist = 115,
   esthist = 116,
   pohist = 117,
   rxchange = 118,
   cpi = 119,
   custsales = 120,
   CREandChargeTypeSetups = 121,
   memcomm = 122,
   propbanks = 123,
   stepthree = 124,
   advepay = 126,
   loanpost = 127,
   applicatio = 128,
   metrosu = 129,
   metrolo = 130,
   metroex = 131,
   epayocon = 132,
   owntaxid = 133,
   mgtfees = 134,
   exppospay = 135,
   ownerchecktemplates = 136,
   venssn = 137,
   email = 139,
   issuesMain = 141,
   issuesRecurring = 142,
   issuesProject = 143,
   issuesTemplate = 144,
   issuesFilter = 145,
   issuesLists = 146,
   issuesClose = 147,
   issuesLock = 148,
   vendcat = 149,
   ownergroups = 150,
   wp_customer = 151,
   wp_prospect = 152,
   wp_owner = 153,
   wp_unit = 154,
   wp_vendor = 155,
   wp_property = 156,
   wp_otheruser = 157,
   rw_customer = 158,
   rw_prospect = 159,
   rw_owner = 160,
   rw_unit = 161,
   rw_vendor = 162,
   rw_property = 163,
   rw_financial = 164,
   rw_otheruser = 165,
   checkprint = 166,
   ViewOtherUsersPrivateEmails = 167,
   editvendorepay = 168,
   procvendorepay = 169,
   chat = 170,
   prosstatus = 171,
   acctname = 172,
   userusers = 173,
   utilutils = 174,
   utiltypes = 175,
   utilcurr = 176,
   utilprev = 177,
   utilmeter = 178,
   utilpost = 179,
   utilrollb = 180,
   cf_customstatements = 181,
   cf_ownerstatements = 182,
   cf_invoices = 183,
   cf_po = 184,
   cf_wo = 185,
   cf_estimates = 186,
   voidchecks = 187,
   glaccountscripting = 188,
   colors = 190,
   usevpo = 191,
   managevpo = 192,
   subsidy = 193,
   subsidypaylist = 194,
   subsidypaydetail = 195,
   subsidypayform = 196,
   ndtlisten = 197,
   ndtplacecall = 198,
   ndtlinkother = 199,
   ndtmanage = 200,
   ndtuseredit = 202,
   setbillpaytier1 = 203,
   paybills = 204,
   addwizards = 205,
   entitytypes = 206,
   imagetypes = 208,
   contacttypes = 209,
   addresstypes = 210,
   phonenumbertypes = 211,
   OverrideGLDrilldown = 212,
   CheckScan = 213,
   bonus = 214,
   commadj = 215,
   unlockuser = 216,
   automatedreportbatches = 217,
   OwnershipTransferWizard = 218,
   twitter = 219,
   socialmedia = 220,
   assethist = 221,
   asset = 222,
   assetmanufacturers = 223,
   assetstatus = 224,
   assettype = 225,
   assetmaintenance = 226,
   ponumbers = 227,
   phonebroadcast = 229,
   ownerbillapvoverride = 230,
   sdappint = 231,
   sdovpref = 232,
   MGMTFEEBKDT = 233,
   forrentunitmanage = 234,
   rmcaptureUnit = 236,
   rmcaptureProperty = 237,
   rmcaptureCustomer = 238,
   rmcaptureProspect = 239,
   rmcaptureOwner = 240,
   rmcaptureVendor = 241,
   rmcaptureIssue = 242,
   rmcaptureBill = 243,
   rmcaptureAsset = 244,
   rmcapturePO = 245,
   rmcaptureEstimates = 246,
   rmcaptureFileUpload = 247,
   CheckScan_OtherUser = 249,
   acctbaldis = 250,
   mngbatch = 251,
   ovrdcpyattach = 252,
   mergeprospects = 253,
   mgtFeeSetup = 254,
   postMgtFees = 255,
   unithcbypass = 256,
   ownertrans = 257,
   ownerpay = 258,
   ownercred = 259,
   issuesCloseWithoutResolution = 260,
   PostOwnerPay = 261,
   primarystatus = 262,
   executeglaccountscripting = 263,
   inspections = 264,
   inspectionareas = 265,
   inspectionitems = 266,
   inspectiontemplates = 267,
   ownercontributions = 269,
   importProperties = 270,
   importUnitTypes = 271,
   importUnits = 272,
   importTenants = 273,
   importProspects = 274,
   importCharges = 275,
   importRecurringCharges = 276,
   importPayments = 277,
   importVendors = 278,
   importOwners = 279,
   importBills = 280,
   importJournals = 281,
   importPayroll = 282,
   importBudget = 283,
   importChartOfAccounts = 284,
   apiPrivilegeCategories = 286,
   apiPrivileges = 287,
   importManageTemplates = 288,
   appoint = 289,
   mngappttasks = 291,
   tasks = 292,
   histeditothers = 293,
   histdeleteothers = 294,
   epaydepositreconcile = 295,
   epaymanualdeposits = 296,
   inspectionappedit = 297,
   textbroadcast = 298,
   cf_ownerinvoices = 299,
   cash_pay = 300,
   inspectionstatuses = 301,
   OtherUsersDocumentPackets = 302,
   PublishDocumentPackets = 303,
   SignDocumentPackets = 304,
   ProtectedScreenings = 305,
   ModifyMoveInOutBeforeAccountingClose = 306,
   EditProspectsConvertedToTenants = 307,
   ModifyLeaseStartEndBeforeAccountingClose = 308,
   CreateMultipleLeases = 309,
   ChangeCustomerToProspect = 310,
   LCSVPOBilling = 311,
   procdepreconepay = 312,
   manualdepepay = 313,
   manageddepepay = 314,
   ePayFixWebTransactions = 315,
   importAssets = 316,
   bluemoonexport = 317,
   editbluemoonexport = 318,
   OwnAvidPayItems = 319,
   OthersAvidPayItems = 320,
   ConfirmMoveOut = 321,
   ServiceTechCheckIn = 322,
   ServiceTechCheckInOther = 323,
   SendReceiveTexts = 324,
   AvidInvoice = 325,
   SendReceiveTextsToOtherUsers = 326,
   DeleteTextMessages = 327,
   importOwnerContracts = 328,
   PayAvidInvoiceBills = 329,
   rmServiceViewIssuesAssignedToOthers = 330,
   export1099 = 331,
   EcheckProcessChecks = 332,
   EcheckReorderChecks = 333,
   EcheckEditEmailChecks = 334,
   EcheckEditEmailBills = 335,
   rw_servicemanager = 336,
   wp_servicemanager = 337,
   Jobs = 338,
   JobHistory = 339,
   ImportOwnerProspects = 340,
   OwnerProspects = 341,
   ChangeOwnerProspectStatus = 342,
   EditConvertedOwnerProspects = 343,
   OwnerProspectHist = 344,
   EditOwnDashboard = 345,
   EditAllDashboards = 346,
   EditOwnScoreboards = 347,
   EditAllScoreboards = 348,
   ApplicationTemplate = 349,
   AutomatePostRecurringCharges = 350,
   AutomatePostLateFees = 351,
   OwnerProspectLetterTemplates = 352,
   OwnerProspectLeadSources = 353,
   rw_ownerprospect = 354,
   ImportOwnerProspectOwnerships = 355,
   ImportInventoryItems = 356,
   JobTypes = 357,
   JobAssignment = 358,
   AutomatePostRecurringBills = 359,
   EditReconciledAmount = 360,
   EditDashboardDataFilters = 361,
   TaskAutomationBillRollback = 362,
   EditOwnCustomServiceIssueLayout = 363,
   EditAllCustomServiceIssueLayouts = 364,
   AutomatedNotifications = 365,
   SignableTemplates = 366,
   EditSignableDocumentUsers = 367,
   ARAutomationSchedules = 368,
   MakeReadyBoard = 369,
   MakeReadyProcess = 370,
   MakeReadyActions = 371,
   MakeReadyTemplates = 372,
   ImportTenantProspectContacts = 373,
   OverrideScreeningDecision = 374,
   AutomatePostLoanLateFees = 375,
   AutomatePostLoans = 376,
   AutomatePostManagementFees = 377,
   DeleteSignableDocumentPacket = 378,
   AutomatePostRecurringEpay = 379,
   AutomatePostUtilities = 380,
   AutomatePostGPR = 381,
   ManualAdjustInventoryItems = 382,
   ConfigureNACHABank = 383,
   MemorizedJournal = 384,
   LoanContactSSN = 385,
   BirdsEyeViewOverlays = 386,
   ViewAnnualIncome = 387,
   GenerateNACHAFile = 388,
   ViewAvidPayClearedChecks = 389,
   PetTypes = 399,
   ForecastModel = 400,
   NDTManagementTiles = 401,
   DeleteTenantContacts = 402,
   DeleteProspectContacts = 403,
   ImportCreditCardTransactions = 404,
   RecurringJournals = 405,
   AutomatePostRecurringJournals = 406,
   SetupePayPassword = 408,
   ManageIntegrations = 409,
   SecDepRefCheck = 410,
   SecDepRefBill = 411,
   RecurringBills = 412,
   ViewAnySubmittedVPO = 413,
   AllowRMExpressAccess = 414,
   ViewIntegrations = 415,
   ServiceManagerIssueChecklistItems = 416,
   CloseOtherUserServiceManagerChecklistItems = 417,
   ServiceManagerIssueChecklistTemplates = 418,
   ManageSystemFilters = 419,
   ManageReportLayout = 421,
   EditSystemUDFValues = 422,
   wp_violations = 423,
   rw_violations = 424,
   Queues = 426,
   ManageUnlinkedConversations = 427,
   AllowAgentToExceedMaximumConversations = 428,
   AllowAgentToTransferConversationsToOtherQueues = 429,
   Violations = 430,
   ImportViolationCodes = 431,
   LeaseTerms = 432,
   OverrideBudgetRestrictionWhenApprovingPO = 433,
   ImportJobs = 434,
   GuestCardTemplates = 435,
   ManageSMSMessaging = 437,
   SeeAllTexts = 438,
   ViolationCodeGroups = 439,
   ManageStateTaxChargeTypes = 440,
   AccountingPeriods = 441,
   ViolationHistory = 442,
   ViolationCategories = 443,
   AssetDepreciation = 444,
   PostAssetDepreciation = 445,
   DeleteClosedWebChatConversations = 447,
   HideUnhideImagesWebChatConversation = 448,
   PurchaseUnitLicenses = 449,
   AssignUnitLicenses = 450,
   PostUnitCountCharges = 451,
   ChangeRMOAccountPassword = 452,
   SystemPreferences = 453,
   SystemWebPreferences = 454,
   PostingRollback = 455,
   MergeChargeTypes = 456,
   MergeGLAccounts = 457,
   UnitMigrationWizard = 458,
   FilteredGLAccounts = 459,
   ImportDepreciationSetup = 464,
   UnitToAssetConversionWizard = 467,
   ImportMeterInformation = 468,
   ManageAssetTitleStatus = 471,
   wp_assets = 472,
   rw_assets = 473,
   ManageRMSessions = 474,
   ChangeAccountStatusToLostLostRejected = 475,
   ManageLostReasons = 476,
   ManageProspectStages = 477,
   ManageStageHistory = 478,
   ApproveRejectEstimates = 479,
   ManageLocationSessions = 481,
   NSFPayments = 482,
   ePayReturns = 483,
   ViewReconciliation = 484,
   ManageWebhooks = 485,
   ManageAssetWorkflows = 486,
   ViewOtherusersTextConversationsInRmAppSuite = 487,
   ManageLeaseRenewals = 488,
   ViewTenantFinancialInformation = 489,
   HOAPolls = 490,
   ViewHOAPollResults = 491,
   PublishHOAPolls = 492,
   AddHOAPollResponses = 493,
   ViewIndividualHOAPollResponses = 494,
   VoidCompletedSignableDocuments = 495,
   wp_job = 496,
   rw_job = 497,
   ManageCommunityEvents = 498,
   ManageAmenityReservations = 499,
   ManageArchitecturalRequests = 500,
   ApproveDenyArchitecturalRequests = 501,
   ManageCommittees = 502,
   ListenToRecordedCalls = 503,
   OverrideDigitalSignatureLimit = 506,
   OverrideBlueMoonDefaultValues = 507,
   ManageeSignatureDocuments = 508,
   ExecuteeSignatureDocuments = 509,
   DeleteeSignatureDocuments = 510,
   SetBillPayTier2 = 511,
   SetBillPayTier3 = 512,
   ManageSTRRateSchedules = 513,
   OverrideSTRCalculatedChargeAmount = 514,
   ResetOtherUsersRMOAccountPasswords = 515,
   ViewOtherUsersPaymentBatches = 516,
   ManageIntegratedAccounts = 517,
   AllowToNotRequireRentersInsurance = 518,
   DeleteVendorContacts = 519,
   AccessEncryptedUDF_Asset = 520,
   AccessEncryptedUDF_CommercialLease = 521,
   AccessEncryptedUDF_Contact = 522,
   AccessEncryptedUDF_Inventory = 523,
   AccessEncryptedUDF_Issue = 524,
   AccessEncryptedUDF_Job = 525,
   AccessEncryptedUDF_Loan = 526,
   AccessEncryptedUDF_NonCommercialLease = 527,
   AccessEncryptedUDF_Owner = 528,
   AccessEncryptedUDF_Property = 529,
   AccessEncryptedUDF_System = 530,
   AccessEncryptedUDF_Tenant = 531,
   AccessEncryptedUDF_Unit = 532,
   AccessEncryptedUDF_User = 533,
   AccessEncryptedUDF_Vendor = 534,
   DeleteEmails = 535,
   ViewOtherUsersEmailsToExternalRecipients = 536,
   AccessEncryptedUDF_OwnerProspect = 537,
   AccessEncryptedUDF_Prospect = 538,
   ManageSTRPaymentPolicies = 539,
   ManageArchitecturalRequestForms = 540,
   TenantStatementAutomations = 541,
   MovePaymentsBetweenCustomers = 542,
   ViewHistoryNotesInContacts = 543,
}
