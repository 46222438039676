<lcs-loading-overlay [show]="loading">
   <div class="flex-grid">
      <owa-tile
         [title]="'Estimate Approval'"
         class="flex-full"
         *ngIf="ShowApproval"
      >
         <div>
            <lcs-progress-button
               [raised]="true"
               (buttonClick)="approveClick()"
               [(status)]="approveButtonStatus"
               style="margin-right: 0.5em; width:80px;"
            >
               Approve
            </lcs-progress-button>

            <lcs-progress-button
               [raised]="true"
               (buttonClick)="rejectClick()"
               [(status)]="cancelButtonStatus"
               style="width: 80px"
            >
               Reject
            </lcs-progress-button>
         </div>
      </owa-tile>
      <owa-tile
         [title]="'Estimate Information'"
         class="flex-full"
         [showTag]="true"
         [tagText]="StatusName"
      >
         <div class="flex-grid">
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Property</div>
                  <div class="tile-value">{{ estimate?.PropertyName }}</div>
                  <div class="title">Reference</div>
                  <div class="tile-value">{{ estimate?.Reference }}</div>
               </div>
            </div>
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Amount</div>
                  <div class="tile-value">{{ estimate?.TotalValue | currency }}</div>
                  <div class="title">Date</div>
                  <div class="tile-value">{{ estimate?.IssueDate | date: "MM/dd/yyyy" }}</div>
               </div>
            </div>
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Estimate #</div>
                  <div class="tile-value">{{ estimate?.EstimateID }}</div>
                  <div *ngIf="ShowApprovedRejected">
                     <div class="title">{{ StatusName }}</div>
                     <div class="signatureContainer">
                        <div
                           *ngIf="estimate?.OwnerSignature !== '' && !estimate?.IsExpired"
                           class="tile-value signatureText"
                        ><span style="font-size: small;font-style: normal;">by</span> {{ estimate?.OwnerSignature }}
                        </div>
                     </div>
                     <div
                        *ngIf="!estimate?.IsExpired"
                        class="tile-value signatureDateText"
                     ><span style="font-size: small;">on</span> {{ estimate?.ApprovedRejectedOn | date: "MM/dd/yyyy" }}
                     </div>
                     <div
                        *ngIf="estimate?.IsExpired"
                        class="tile-value expiredDateText"
                     >on {{ estimate?.ExpirationDate | date: "MM/dd/yyyy" }}
                     </div>
                  </div>
                  <div *ngIf="!ShowApprovedRejected && estimate?.ExpirationDate">
                     <div class="title">Expiration Date</div>
                     <div class="tile-value signatureDateText">{{estimate?.ExpirationDate | date: "MM/dd/yyyy"}}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="flex-full">
            <div class="grid-container">
               <div class="title">Comment</div>
               <div class="tile-value">{{ estimate?.Comments }}</div>
            </div>
         </div>
      </owa-tile>
      <owa-tile
         title="details"
         class="flex-full full-width"
         [isViewMore]="true"
         (openComponent)="openEstimateDetailsMore()"
      >
         <owa-estimate-detail-list [estimateDetailItems]="estimate"> </owa-estimate-detail-list>
      </owa-tile>
      <owa-tile
         title="linked service issue"
         class="flex-full full-width"
         *ngIf="ShowServiceManagerIssue"
      >
         <owa-serviceissue-list [serviceIssueItem]="$any(estimate?.ServiceManagerIssue)"></owa-serviceissue-list>
      </owa-tile>
      <owa-tile
         title="history"
         class="flex-full full-width"
         *ngIf="ShowHistory"
         [isViewMore]="true"
         (openComponent)="openEstimateHistoryListMore()"
      >
         <owa-estimate-history-list [historyItems]="$any( $any(estimate?.History) | slice: 0:5 )">
         </owa-estimate-history-list>
      </owa-tile>

   </div>
</lcs-loading-overlay>