import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";

@Component({
   selector: "owa-estimate-list-container",
   templateUrl: "estimate-list-container.component.html",
})
export class EstimateListContainerComponent implements OnInit, OnDestroy {
   public showClosed: boolean = true;
   private unsubscribe = new Subject<void>();

   constructor(private systemWebPreferenceSessionService: SystemWebPreferencesSessionService) {}

   ngOnInit() {
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs) {
               this.showClosed =
                  prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA") &&
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA").Value.toLocaleLowerCase() === "true"
                     ? true
                     : false;
            }
         }
      );
   }
   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
