<owa-dashboard-tile
   [title]="'Management Fees'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'managementfees'"
>
   <lcs-table [dataSource]="$any(observableFees | async)">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="feeColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: feeColumns"
      ></tr>
      <ng-container
         lcsColumnDef="FeeDate"
         [width]="6"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Fee Date</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         ><a
               *ngIf="row.IsByPayment"
               class="drilldown-link"
               style="color: #2c77bc"
               [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
            >{{
               row.FeeDate | date: "MM/dd/yyyy"
               }}</a>
            <div
               *ngIf="!row.IsByPayment"
               class="drilldown-link"
            >{{ row.FeeDate | date: "MM/dd/yyyy"}}</div>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="PropertyName"
         [width]="12"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Property</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         ><a
               *ngIf="row.IsByPayment"
               class="drilldown-link"
               style="color: #2c77bc"
               [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
            >{{ row.PropertyName }}</a>
            <div
               *ngIf="!row.IsByPayment"
               class="drilldown-link"
            >{{ row.PropertyName }}</div>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="FeeTotal"
         [width]="8"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Total Fee Amount</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="numeric has-drilldown"
         ><a
               *ngIf="row.IsByPayment"
               class="drilldown-link"
               style="color: #2c77bc"
               [routerLink]="['/managementfeedetails', row.ManagementFeeHistoryID]"
            >{{
               row.FeeTotal | currency: "USD":"symbol":"1.2-2"
               }}</a>
            <div
               *ngIf="!row.IsByPayment"
               class="drilldown-link"
            >{{
               row.FeeTotal | currency: "USD":"symbol":"1.2-2"
               }}</div>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="NewRow"
         [width]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div *ngIf="row.IsNew">
               <span class="notification-icon"></span>
            </div>
         </td>
      </ng-container>
   </lcs-table>

   <ng-template #noFees>
      <div class="no-result">No Management Fees found</div>
   </ng-template>
</owa-dashboard-tile>