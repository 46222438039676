import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportParametersModule } from "@lcs/reports/report-parameters/report-parameters.module";

import { ChargeTypeOrderComponent } from "./charge-type-order/charge-type-order.component";
import { ReportParameterOverrideComponent } from "./report-parameter-override.component";

@NgModule({
   imports: [CommonModule, FormsModule, ReportParametersModule],
   declarations: [ReportParameterOverrideComponent, ChargeTypeOrderComponent],
   exports: [ReportParameterOverrideComponent],
})
export class ReportParameterOverrideModule {}
