import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LinkModule } from "@lcs/link/link.module";

import { LayoutComponent } from "./layout.component";

@NgModule({
   imports: [CommonModule, LinkModule],
   declarations: [LayoutComponent],
   exports: [LayoutComponent],
})
export class LayoutModule {}
