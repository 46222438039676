<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <label [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)">CAM Charge Type</label>
   <lcs-select
      lcsStaticSetSelector
      [name]="'cam-charge-type-selector'"
      [displayName]="'CAM Charge Type'"
      [(ngModel)]="reportParameterValueModel.value"
      [lcsValidate]="validation"
      [items]="chargeTypes"
      [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
      (ngModelChange)="onChargeTypeChange($event)"
   >
   </lcs-select>
</div>