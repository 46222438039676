import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { MakeReadyPostItemDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/make-ready-post-item.delete-options";
import { ServiceManagerIssueDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/service-manager-issue.delete-options";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { AppointmentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/appointment.embed-options";
import { CheckListItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check-list-item.embed-options";
import { FileEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/file.embed-options";
import { HistoryNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-note.embed-options";
import { HistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history.embed-options";
import { MakeReadyPostItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/make-ready-post-item.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { ProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect.embed-options";
import { ServiceManagerCategoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-category.embed-options";
import { ServiceManagerIssueLinkedPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-linked-property.embed-options";
import { ServiceManagerIssueLinkedProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-linked-prospect.embed-options";
import { ServiceManagerIssueLinkedTenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-linked-tenant.embed-options";
import { ServiceManagerIssueLinkedUnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-linked-unit.embed-options";
import { ServiceManagerIssueLockEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-lock.embed-options";
import { ServiceManagerIssueWorkOrderEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-work-order.embed-options";
import { ServiceManagerIssueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue.embed-options";
import { ServiceManagerPriorityEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-priority.embed-options";
import { ServiceManagerProjectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-project.embed-options";
import { ServiceManagerStatusEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-status.embed-options";
import { ServiceManagerTechTimeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-tech-time.embed-options";
import { TaskEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { UnitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/unit.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserListItemEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-list-item.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { VendorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor.embed-options";
import { WebUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/web-user.embed-options";
import { HistoryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/history.get-options";
import { UnitGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/unit.get-options";
import { CheckListItemSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check-list-item.save-options";
import { ServiceManagerIssueWorkOrderSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/service-manager-issue-work-order.save-options";
import { ServiceManagerIssueSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/service-manager-issue.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AppointmentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/appointment.model";
import { CheckListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check-list-item.model";
import { FileModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/file.model";
import { HistoryNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-note.model";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { MakeReadyPostItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/make-ready-post-item.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { ProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect.model";
import { ScriptBuilderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/script-builder.model";
import { ServiceManagerCategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-category.model";
import { ServiceManagerIssueHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-history.model";
import { ServiceManagerIssueLinkedPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-linked-property.model";
import { ServiceManagerIssueLinkedProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-linked-prospect.model";
import { ServiceManagerIssueLinkedTenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-linked-tenant.model";
import { ServiceManagerIssueLinkedUnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-linked-unit.model";
import { ServiceManagerIssueLockModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-lock.model";
import { ServiceManagerIssueSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-summary.model";
import { ServiceManagerIssueWorkOrderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-work-order.model";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";
import { ServiceManagerPriorityModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-priority.model";
import { ServiceManagerProjectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-project.model";
import { ServiceManagerStatusModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-status.model";
import { ServiceManagerTechTimeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-tech-time.model";
import { TaskModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { UnitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/unit.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-list-item.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { ServiceManagerIssueSearchPostModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/service-manager-issue-search-post.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ServiceManagerIssuesService {
   static readonly AdvanceResource = "Advance";
   static readonly AppointmentsResource = "Appointments";
   static readonly AssignedToUserResource = "AssignedToUser";
   static readonly AvailableChildLinksForProspectResource = "AvailableChildLinksForProspect";
   static readonly AvailableChildLinksForTenantResource = "AvailableChildLinksForTenant";
   static readonly AvailableChildLinksForUnitResource = "AvailableChildLinksForUnit";
   static readonly CategoryResource = "Category";
   static readonly CheckListItemsResource = "CheckListItems";
   static readonly CloseUserResource = "CloseUser";
   static readonly CreateAndLinkAllItemsResource = "CreateAndLinkAllItems";
   static readonly CreateAndLinkOwnerBillsResource = "CreateAndLinkOwnerBills";
   static readonly CreateAndLinkPurchaseOrdersResource = "CreateAndLinkPurchaseOrders";
   static readonly CreateAndLinkTenantInvoicesResource = "CreateAndLinkTenantInvoices";
   static readonly CreateAndLinkVendorBillsResource = "CreateAndLinkVendorBills";
   static readonly CreateUserResource = "CreateUser";
   static readonly CreateWebUserResource = "CreateWebUser";
   static readonly DetailsSearchResource = "DetailsSearch";
   static readonly GetByPostResource = "GetByPost";
   static readonly HistoryResource = "History";
   static readonly HistoryNotesResource = "HistoryNotes";
   static readonly LinkedPropertiesResource = "LinkedProperties";
   static readonly LinkedProspectsResource = "LinkedProspects";
   static readonly LinkedTenantsResource = "LinkedTenants";
   static readonly LinkedUnitsResource = "LinkedUnits";
   static readonly LinkPropertiesResource = "LinkProperties";
   static readonly LinkPropertyResource = "LinkProperty";
   static readonly LinkProspectResource = "LinkProspect";
   static readonly LinkTenantResource = "LinkTenant";
   static readonly LinkTenantsResource = "LinkTenants";
   static readonly LinkUnitResource = "LinkUnit";
   static readonly LinkUnitsResource = "LinkUnits";
   static readonly MakeReadyPostItemResource = "MakeReadyPostItem";
   static readonly MakeReadyPostItemsResource = "MakeReadyPostItems";
   static readonly PayeeAccountResource = "PayeeAccount";
   static readonly PriorityResource = "Priority";
   static readonly ProjectResource = "Project";
   static readonly PropertiesResource = "Properties";
   static readonly ProspectsResource = "Prospects";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RecentIssuesForTenantResource = "RecentIssuesForTenant";
   static readonly RelatedIssuesResource = "RelatedIssues";
   static readonly SaveResource = "Save";
   static readonly saveServiceIssueToMemorizeIssueResource = "saveServiceIssueToMemorizeIssue";
   static readonly SearchResource = "Search";
   static readonly SearchExtResource = "SearchExt";
   static readonly ServiceManagerIssueLinkedPropertiesResource = "ServiceManagerIssueLinkedProperties";
   static readonly ServiceManagerIssueLinkedProspectsResource = "ServiceManagerIssueLinkedProspects";
   static readonly ServiceManagerIssueLinkedTenantsResource = "ServiceManagerIssueLinkedTenants";
   static readonly ServiceManagerIssueLinkedUnitsResource = "ServiceManagerIssueLinkedUnits";
   static readonly ServiceManagerIssueLockResource = "ServiceManagerIssueLock";
   static readonly SignatureFileResource = "SignatureFile";
   static readonly StatusResource = "Status";
   static readonly TasksResource = "Tasks";
   static readonly TechTimesResource = "TechTimes";
   static readonly TenantsResource = "Tenants";
   static readonly TestScriptResource = "TestScript";
   static readonly UnitsResource = "Units";
   static readonly UnlinkPropertiesResource = "UnlinkProperties";
   static readonly UnlinkPropertyResource = "UnlinkProperty";
   static readonly UnlinkProspectResource = "UnlinkProspect";
   static readonly UnlinkTenantResource = "UnlinkTenant";
   static readonly UnlinkTenantsResource = "UnlinkTenants";
   static readonly UnlinkUnitResource = "UnlinkUnit";
   static readonly UnlinkUnitsResource = "UnlinkUnits";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadSignatureFileResource = "UploadSignatureFile";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly UsersThatCanBeAssignedToServiceIssueResource = "UsersThatCanBeAssignedToServiceIssue";
   static readonly VendorResource = "Vendor";
   static readonly WorkOrdersResource = "WorkOrders";
   static readonly endpoint = "ServiceManagerIssuesView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | ServiceManagerIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   post(
      item?: ServiceManagerIssueModel | null,
      saveOptions?: Array<string | ServiceManagerIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | ServiceManagerIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint,
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCollection(
      items: Array<ServiceManagerIssueModel>,
      saveOptions?: Array<string | ServiceManagerIssueSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | ServiceManagerIssueDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint,
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   delete(
      id: number,
      deleteOptions?: Array<string | ServiceManagerIssueDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(ServiceManagerIssuesService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   postAdvanceUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Advance",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postAdvance(id: number, additionalParameters?: Array<string>): Observable<ServiceManagerIssueModel> {
      return this.apiService.post(this.postAdvanceUrl(id, additionalParameters)).pipe(map((response) => response.body));
   }

   getAppointmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Appointments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAppointments(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.getSingle(
         this.getAppointmentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAppointmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Appointments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAppointmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.getCollection(
         this.getAppointmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAppointmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Appointments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAppointments(
      id: number,
      item?: AppointmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.postSingle(
         this.postAppointmentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAppointmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Appointments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAppointmentsCollection(
      id: number,
      appointmentItems?: Array<AppointmentModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.postCollection(
         this.postAppointmentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         appointmentItems
      );
   }

   deleteAppointmentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/Appointments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAppointments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAppointmentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getAssignedToUserUrl(
      id: number,
      embeds?: Array<string | UserListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/AssignedToUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssignedToUser(
      id: number,
      embeds?: Array<string | UserListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserListItemModel> {
      return this.apiService.getSingle(this.getAssignedToUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getAvailableChildLinksForProspectUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/AvailableChildLinksForProspect",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForProspect(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedProspectModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForProspectUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForTenantUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/AvailableChildLinksForTenant",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForTenant(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedTenantModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForTenantUrl(id, embeds, additionalParameters));
   }

   getAvailableChildLinksForUnitUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/AvailableChildLinksForUnit",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAvailableChildLinksForUnit(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedUnitModel> {
      return this.apiService.getSingle(this.getAvailableChildLinksForUnitUrl(id, embeds, additionalParameters));
   }

   getCategoryUrl(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Category",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCategory(
      id: number,
      embeds?: Array<string | ServiceManagerCategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerCategoryModel> {
      return this.apiService.getSingle(this.getCategoryUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCheckListItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/CheckListItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCheckListItems(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.getSingle(
         this.getCheckListItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCheckListItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/CheckListItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCheckListItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.getCollection(
         this.getCheckListItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCheckListItemsUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItems(
      id: number,
      item?: CheckListItemModel | null,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckListItemModel> {
      return this.apiService.postSingle(
         this.postCheckListItemsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCheckListItemsCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CheckListItems",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCheckListItemsCollection(
      id: number,
      items: Array<CheckListItemModel>,
      saveOptions?: Array<string | CheckListItemSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CheckListItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckListItemModel>> {
      return this.apiService.postCollection(
         this.postCheckListItemsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCheckListItemsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/CheckListItems",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCheckListItems(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCheckListItemsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCloseUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/CloseUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCloseUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCloseUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postCreateAndLinkAllItemsUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CreateAndLinkAllItems",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreateAndLinkAllItems(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService
         .post(this.postCreateAndLinkAllItemsUrl(id, saveOptions, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postCreateAndLinkOwnerBillsUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CreateAndLinkOwnerBills",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreateAndLinkOwnerBills(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService
         .post(this.postCreateAndLinkOwnerBillsUrl(id, saveOptions, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postCreateAndLinkPurchaseOrdersUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CreateAndLinkPurchaseOrders",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreateAndLinkPurchaseOrders(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService
         .post(this.postCreateAndLinkPurchaseOrdersUrl(id, saveOptions, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postCreateAndLinkTenantInvoicesUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CreateAndLinkTenantInvoices",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreateAndLinkTenantInvoices(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService
         .post(this.postCreateAndLinkTenantInvoicesUrl(id, saveOptions, additionalParameters))
         .pipe(map((response) => response.body));
   }

   postCreateAndLinkVendorBillsUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/CreateAndLinkVendorBills",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreateAndLinkVendorBills(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService
         .post(this.postCreateAndLinkVendorBillsUrl(id, saveOptions, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCreateWebUserUrl(
      id: number,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/CreateWebUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateWebUser(
      id: number,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserModel> {
      return this.apiService.getSingle(this.getCreateWebUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postDetailsSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/DetailsSearch",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postDetailsSearch(
      serviceIssueSearchPostModel?: ServiceManagerIssueSearchPostModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService
         .post(this.postDetailsSearchUrl(additionalParameters), serviceIssueSearchPostModel)
         .pipe(map((response) => response.body));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/History",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/History",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/History",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistory(
      id: number,
      item?: ServiceManagerIssueHistoryModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueHistoryModel> {
      return this.apiService.postSingle(this.postHistoryUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postHistoryCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/History",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postHistoryCollection(
      id: number,
      historyItems?: Array<ServiceManagerIssueHistoryModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueHistoryModel>> {
      return this.apiService.postCollection(
         this.postHistoryCollectionUrl(id, saveOptions, fields, additionalParameters),
         historyItems
      );
   }

   deleteHistoryUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/History",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteHistory(id: number, ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryUrl(id, ids, additionalParameters));
   }

   getHistoryNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.getSingle(
         this.getHistoryNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotes(
      id: number,
      item?: HistoryNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotesCollection(
      id: number,
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/HistoryNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryNotesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getLinkedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getLinkedPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getLinkedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedProspects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(
         this.getLinkedProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedProspects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ProspectModel>> {
      return this.apiService.getCollection(
         this.getLinkedProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(
         this.getLinkedTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getLinkedTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLinkedUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedUnits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLinkedUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(
         this.getLinkedUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLinkedUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/LinkedUnits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLinkedUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getLinkedUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLinkPropertiesUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkProperties",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkProperties(
      id: number,
      propertyIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postLinkPropertiesUrl(id, additionalParameters), propertyIDs);
   }

   postLinkPropertyUrl(id: number, propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkProperty",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkProperty(
      id: number,
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkPropertyUrl(id, propertyID, additionalParameters));
   }

   postLinkProspectUrl(id: number, prospectID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("prospectID=" + prospectID);
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkProspect",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkProspect(
      id: number,
      prospectID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkProspectUrl(id, prospectID, additionalParameters));
   }

   postLinkTenantUrl(id: number, tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkTenant",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkTenant(id: number, tenantID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkTenantUrl(id, tenantID, additionalParameters));
   }

   postLinkTenantsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkTenants",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkTenants(
      id: number,
      tenantIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postLinkTenantsUrl(id, additionalParameters), tenantIDs);
   }

   postLinkUnitUrl(id: number, unitID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkUnit",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkUnit(id: number, unitID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLinkUnitUrl(id, unitID, additionalParameters));
   }

   postLinkUnitsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/LinkUnits",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLinkUnits(
      id: number,
      unitIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postLinkUnitsUrl(id, additionalParameters), unitIDs);
   }

   getMakeReadyPostItemUrl(
      id: number,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/MakeReadyPostItem",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMakeReadyPostItem(
      id: number,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MakeReadyPostItemModel> {
      return this.apiService.getSingle(
         this.getMakeReadyPostItemUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postMakeReadyPostItemUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/MakeReadyPostItem",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMakeReadyPostItem(
      id: number,
      item?: MakeReadyPostItemModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<MakeReadyPostItemModel> {
      return this.apiService
         .post(this.postMakeReadyPostItemUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteMakeReadyPostItemUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | MakeReadyPostItemDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/MakeReadyPostItem",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteMakeReadyPostItem(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | MakeReadyPostItemDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteMakeReadyPostItemUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getMakeReadyPostItemsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/MakeReadyPostItems",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getMakeReadyPostItems(
      id: number,
      itemID: number,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<MakeReadyPostItemModel> {
      return this.apiService.getSingle(
         this.getMakeReadyPostItemsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getMakeReadyPostItemsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/MakeReadyPostItems",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getMakeReadyPostItemsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | MakeReadyPostItemEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<MakeReadyPostItemModel>> {
      return this.apiService.getCollection(
         this.getMakeReadyPostItemsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPayeeAccountUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/PayeeAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPayeeAccount(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getPayeeAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPriorityUrl(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Priority",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPriority(
      id: number,
      embeds?: Array<string | ServiceManagerPriorityEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerPriorityModel> {
      return this.apiService.getSingle(this.getPriorityUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getProjectUrl(
      id: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Project",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProject(
      id: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerProjectModel> {
      return this.apiService.getSingle(this.getProjectUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Properties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Properties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Prospects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(
         this.getProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Prospects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ProspectModel>> {
      return this.apiService.getCollection(
         this.getProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRecentIssuesForTenantCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/RecentIssuesForTenant",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRecentIssuesForTenantCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.getCollection(
         this.getRecentIssuesForTenantCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRelatedIssuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/RelatedIssues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRelatedIssues(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService.getSingle(
         this.getRelatedIssuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRelatedIssuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/RelatedIssues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRelatedIssuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.getCollection(
         this.getRelatedIssuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSaveUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Save",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSave(
      serviceManagerIssueModel?: ServiceManagerIssueModel | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService
         .post(this.postSaveUrl(additionalParameters), serviceManagerIssueModel)
         .pipe(map((response) => response.body));
   }

   postsaveServiceIssueToMemorizeIssueUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/saveServiceIssueToMemorizeIssue",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postsaveServiceIssueToMemorizeIssue(
      serviceManagerIssueSummaryModel?: ServiceManagerIssueSummaryModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postsaveServiceIssueToMemorizeIssueUrl(additionalParameters), serviceManagerIssueSummaryModel)
         .pipe(map((response) => response.body));
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | ServiceManagerIssueEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Search",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   postSearchExtUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/SearchExt",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSearchExt(
      serviceIssueSearchPostModel?: ServiceManagerIssueSearchPostModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueModel>> {
      return this.apiService
         .post(this.postSearchExtUrl(additionalParameters), serviceIssueSearchPostModel)
         .pipe(map((response) => response.body));
   }

   getServiceManagerIssueLinkedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedPropertyModel> {
      return this.apiService.getSingle(
         this.getServiceManagerIssueLinkedPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedPropertyModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerIssueLinkedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerIssueLinkedPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedProperties(
      id: number,
      item?: ServiceManagerIssueLinkedPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedPropertyModel> {
      return this.apiService.postSingle(
         this.postServiceManagerIssueLinkedPropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerIssueLinkedPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedPropertiesCollection(
      id: number,
      propertiesToLink?: Array<ServiceManagerIssueLinkedPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedPropertyModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerIssueLinkedPropertiesCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         propertiesToLink
      );
   }

   deleteServiceManagerIssueLinkedPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerIssueLinkedProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerIssueLinkedPropertiesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedProspectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProspects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedProspects(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedProspectModel> {
      return this.apiService.getSingle(
         this.getServiceManagerIssueLinkedProspectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedProspectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProspects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedProspectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedProspectModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerIssueLinkedProspectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerIssueLinkedProspectsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedProspects(
      id: number,
      item?: ServiceManagerIssueLinkedProspectModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedProspectModel> {
      return this.apiService.postSingle(
         this.postServiceManagerIssueLinkedProspectsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerIssueLinkedProspectsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProspects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedProspectsCollection(
      id: number,
      prospectsToLink?: Array<ServiceManagerIssueLinkedProspectModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedProspectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedProspectModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerIssueLinkedProspectsCollectionUrl(
            id,
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         prospectsToLink
      );
   }

   deleteServiceManagerIssueLinkedProspectsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedProspects",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerIssueLinkedProspects(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerIssueLinkedProspectsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedTenantModel> {
      return this.apiService.getSingle(
         this.getServiceManagerIssueLinkedTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedTenantModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerIssueLinkedTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerIssueLinkedTenantsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedTenants(
      id: number,
      item?: ServiceManagerIssueLinkedTenantModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedTenantModel> {
      return this.apiService.postSingle(
         this.postServiceManagerIssueLinkedTenantsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerIssueLinkedTenantsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedTenantsCollection(
      id: number,
      tenantsToLink?: Array<ServiceManagerIssueLinkedTenantModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedTenantModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerIssueLinkedTenantsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         tenantsToLink
      );
   }

   deleteServiceManagerIssueLinkedTenantsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedTenants",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerIssueLinkedTenants(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerIssueLinkedTenantsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedUnits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedUnitModel> {
      return this.apiService.getSingle(
         this.getServiceManagerIssueLinkedUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLinkedUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedUnits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLinkedUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedUnitModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerIssueLinkedUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerIssueLinkedUnitsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedUnits(
      id: number,
      item?: ServiceManagerIssueLinkedUnitModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLinkedUnitModel> {
      return this.apiService.postSingle(
         this.postServiceManagerIssueLinkedUnitsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerIssueLinkedUnitsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedUnits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLinkedUnitsCollection(
      id: number,
      unitsToLink?: Array<ServiceManagerIssueLinkedUnitModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLinkedUnitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLinkedUnitModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerIssueLinkedUnitsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         unitsToLink
      );
   }

   deleteServiceManagerIssueLinkedUnitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLinkedUnits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerIssueLinkedUnits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerIssueLinkedUnitsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getServiceManagerIssueLockUrl(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLockEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLock",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLock(
      id: number,
      embeds?: Array<string | ServiceManagerIssueLockEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLockModel> {
      return this.apiService.getSingle(
         this.getServiceManagerIssueLockUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   postServiceManagerIssueLockUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLockEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLock",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerIssueLock(
      id: number,
      requestModel?: ServiceManagerIssueLockModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLockEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLockModel> {
      return this.apiService
         .post(this.postServiceManagerIssueLockUrl(id, saveOptions, fields, embeds, additionalParameters), requestModel)
         .pipe(map((response) => response.body));
   }

   deleteServiceManagerIssueLockUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/ServiceManagerIssueLock",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteServiceManagerIssueLock(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteServiceManagerIssueLockUrl(id, itemID, deleteOptions, additionalParameters)
      );
   }

   getSignatureFileUrl(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/SignatureFile",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSignatureFile(
      id: number,
      embeds?: Array<string | FileEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<FileModel> {
      return this.apiService.getSingle(this.getSignatureFileUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getStatusUrl(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Status",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getStatus(
      id: number,
      embeds?: Array<string | ServiceManagerStatusEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerStatusModel> {
      return this.apiService.getSingle(this.getStatusUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Tasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.getSingle(this.getTasksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Tasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.getCollection(
         this.getTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTasksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Tasks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTasks(
      id: number,
      item?: TaskModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.postSingle(this.postTasksUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postTasksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/Tasks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTasksCollection(
      id: number,
      taskItems?: Array<TaskModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.postCollection(
         this.postTasksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         taskItems
      );
   }

   deleteTasksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/Tasks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTasks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTasksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTechTimesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTechTimes(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerTechTimeModel> {
      return this.apiService.getSingle(
         this.getTechTimesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTechTimesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTechTimesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerTechTimeModel>> {
      return this.apiService.getCollection(
         this.getTechTimesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllTechTimesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllTechTimesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerTechTimeModel>> {
      return this.apiService.getCollection(
         this.getAllTechTimesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTechTimesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTechTimes(
      id: number,
      item?: ServiceManagerTechTimeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerTechTimeModel> {
      return this.apiService.postSingle(
         this.postTechTimesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postTechTimesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTechTimesCollection(
      id: number,
      items: Array<ServiceManagerTechTimeModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerTechTimeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerTechTimeModel>> {
      return this.apiService.postCollection(
         this.postTechTimesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteTechTimesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/TechTimes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTechTimes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTechTimesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Tenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(
         this.getTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Tenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTestScriptUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/TestScript",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestScript(
      id: number,
      model?: ScriptBuilderModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postTestScriptUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getUnitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Units",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUnits(
      id: number,
      itemID: number,
      embeds?: Array<string | UnitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UnitModel> {
      return this.apiService.getSingle(this.getUnitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getUnitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Units",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUnitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UnitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | UnitGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UnitModel>> {
      return this.apiService.getCollection(
         this.getUnitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   deleteUnlinkPropertiesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkProperties",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteUnlinkProperties(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkPropertiesUrl(id, ids, additionalParameters));
   }

   deleteUnlinkPropertyUrl(id: number, propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkProperty",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkProperty(
      id: number,
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkPropertyUrl(id, propertyID, additionalParameters));
   }

   deleteUnlinkProspectUrl(id: number, prospectID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("prospectID=" + prospectID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkProspect",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkProspect(
      id: number,
      prospectID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkProspectUrl(id, prospectID, additionalParameters));
   }

   deleteUnlinkTenantUrl(id: number, tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkTenant",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkTenant(
      id: number,
      tenantID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkTenantUrl(id, tenantID, additionalParameters));
   }

   deleteUnlinkTenantsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkTenants",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteUnlinkTenants(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkTenantsUrl(id, ids, additionalParameters));
   }

   deleteUnlinkUnitUrl(id: number, unitID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkUnit",
         id,
         null,
         null,
         additionalParameters
      );
   }

   deleteUnlinkUnit(id: number, unitID: number, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkUnitUrl(id, unitID, additionalParameters));
   }

   deleteUnlinkUnitsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UnlinkUnits",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteUnlinkUnits(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUnlinkUnitsUrl(id, ids, additionalParameters));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(
         this.getUserDefinedFieldsUrl(
            itemID,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUserDefinedFieldsUrl(saveOptions, fields, additionalParameters), item);
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      id: number,
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      id: number,
      udvs?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         udvs
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUsersThatCanBeAssignedToServiceIssueCollectionUrl(issueID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("issueID=" + issueID);
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/UsersThatCanBeAssignedToServiceIssue",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsersThatCanBeAssignedToServiceIssueCollection(
      issueID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersThatCanBeAssignedToServiceIssueCollectionUrl(issueID, additionalParameters)
      );
   }

   getVendorUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/Vendor",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getVendorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getWorkOrdersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getWorkOrders(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueWorkOrderModel> {
      return this.apiService.getSingle(
         this.getWorkOrdersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getWorkOrdersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getWorkOrdersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService.getCollection(
         this.getWorkOrdersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllWorkOrdersCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllWorkOrdersCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService.getCollection(
         this.getAllWorkOrdersCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postWorkOrdersUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWorkOrders(
      id: number,
      item?: ServiceManagerIssueWorkOrderModel | null,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueWorkOrderModel> {
      return this.apiService.postSingle(
         this.postWorkOrdersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postWorkOrdersCollectionUrl(
      id: number,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWorkOrdersCollection(
      id: number,
      items: Array<ServiceManagerIssueWorkOrderModel>,
      saveOptions?: Array<string | ServiceManagerIssueWorkOrderSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueWorkOrderEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueWorkOrderModel>> {
      return this.apiService.postCollection(
         this.postWorkOrdersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteWorkOrdersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         ServiceManagerIssuesService.endpoint + "/WorkOrders",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteWorkOrders(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteWorkOrdersUrl(id, ids, deleteOptions, additionalParameters));
   }
}
