import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { MergeUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/merge-user.model";
import { Observable, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({
   providedIn: "root",
})
export class MergeUsersService implements OnDestroy {
   public mergeUsersResult = new Subject<Response>();
   public observableWebUserModel = new Observable<WebUserModel>();
   private endpoint = "mergeusers/mergeusers";
   private unsubscribe = new Subject<void>();
   private returnModel = new MergeUserModel();
   private webUserModel = new WebUserModel();

   constructor(
      private httpClient: HttpClient,
      private apiRequestHelperService: ApiRequestHelperService,
      private errorMesssageService: ErrorMessageService,
      private authenticationService: AuthenticationService,
      private cacheService: CacheMonitorService
   ) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   public mergeUsers(mergeUserModel: MergeUserModel) {
      this.httpClient
         .post<MergeUserModel>(this.apiRequestHelperService.url + this.endpoint, JSON.stringify(mergeUserModel), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               if (success) {
                  this.returnModel = success;
                  this.webUserModel.WebUserID = this.returnModel.TargetWebUserID;
                  if (new Date(this.returnModel.ConvertByDate) < new Date()) {
                     this.authenticationService.authenticationResult.next(false);
                  } else {
                     this.observableWebUserModel = this.authenticationService.reInitializeWebUser(this.webUserModel);
                  }
                  this.observableWebUserModel.subscribe((result) => {
                     if (result) {
                        this.cacheService.clear();
                        this.cacheService.load();
                     }
                  });
               }
            },
            (_err) => {
               this.mergeUsersResult.error(_err);
               this.errorMesssageService.triggerHttpErrorMessage(_err);
            }
         );
   }

   public getTokenInformation(token: string): Observable<MergeUserModel> {
      return this.httpClient.get<MergeUserModel>(
         this.apiRequestHelperService.url + "mergeusers/GetMergeUsers?token=" + token,
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }
}
