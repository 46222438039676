import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { IndexedDBService } from "@lcs/indexed-db/indexed-db.service";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { ExpressReports } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-reports.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ExpressReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-report.model";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, catchError, Observable, of, Subject, switchMap, takeUntil } from "rxjs";

/* eslint-disable prefer-const */
@Injectable()
export class ExpressReportDefinitionsService implements SessionCacheProvider, OnDestroy {
   cacheKey = "ExpressReportDefinitions";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject(false);

   // @ts-ignore ts-migrate(2322) FIXME: Type 'BehaviorSubject<null>' is not assignable to ... Remove this comment to see the full error message
   expressReports: BehaviorSubject<Array<ExpressReportModel>> = new BehaviorSubject(null);

   private unsubscribe = new Subject<void>();

   constructor(
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService,
      private indexedDBService: IndexedDBService,
      private sessionStatusService: SessionStatusService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean): void {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.expressReports.next(null);
      this.cachePopulated.next(false);
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
         this.indexedDBService.deleteByKey(IndexedDBService.IDBLocalStorage, this.cacheKey);
      }
   }

   public populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      this.indexedDBService
         .getByKey<Array<ExpressReportModel>>(IndexedDBService.IDBLocalStorage, this.cacheKey)
         .pipe(
            catchError((error) => {
               console.warn(`IDB: Failed to load "${this.cacheKey}" from cache. -- `, error);
               this.cacheMonitorService.reportError(this.cacheKey);
               return of(null); // if error attempting to load from cache, load from server
            }),
            switchMap((storedValue: Array<ExpressReportModel> | null): Observable<Array<ExpressReportModel>> => {
               if (storedValue) {
                  const expressReportModels: Array<ExpressReportModel> = storedValue;
                  return of(expressReportModels); // return cached data
               } else {
                  const expressReportModels: Array<ExpressReportModel> = this.buildExpressReportModels();
                  if (expressReportModels.length > 0) {
                     // Save to cache
                     this.indexedDBService
                        .updateByKey(IndexedDBService.IDBLocalStorage, expressReportModels, this.cacheKey)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe({
                           next: (result: Array<ExpressReportModel>): void => {
                              this.localStorageService.setItemToValueHashCode(this.cacheKey, expressReportModels);
                           },
                        });
                  }
                  return of(expressReportModels);
               }
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe({
            next: (expressReportModel: Array<ExpressReportModel>): void => {
               this.expressReports.next(expressReportModel);
               this.cachePopulated.next(true);
            },
         });
   }

   buildExpressReportModels(): Array<ExpressReportModel> {
      let expressReportModels = new Array<ExpressReportModel>();
      Object.values(ExpressReports).forEach((report) => {
         if (!isNaN(Number(report))) {
            if ((report as ExpressReports) !== ExpressReports.NotSet) {
               let reportModel = this.getExpressReportModel(report as ExpressReports);
               if (reportModel.Report) {
                  expressReportModels.push(reportModel);
               }
            }
         }
      });
      return expressReportModels;
   }

   getExpressReportModel(expressReport: ExpressReports): ExpressReportModel {
      let reportModel = new ExpressReportModel();
      reportModel.ExpressReport = expressReport;
      switch (reportModel.ExpressReport) {
         case ExpressReports.TenantProfileFromLink:
            reportModel.Report = Report.CustomerProfile;
            return reportModel;
         case ExpressReports.TenantStatement85x11FromLink:
            reportModel.Report = Report.Statement85x11;
            return reportModel;
         case ExpressReports.TenantStatement85x14MailerFromLink:
            reportModel.Report = Report.Statement85x14Mailer;
            return reportModel;
         case ExpressReports.TenantStatementPostcardFromLink:
            reportModel.Report = Report.TenantStatementPostcard;
            return reportModel;
         case ExpressReports.TenantMailingLabelsFromLink:
            reportModel.Report = Report.CustomerLabels;
            return reportModel;
         case ExpressReports.TenantPaymentCouponsFromLink:
            reportModel.Report = Report.PaymentCoupon;
            return reportModel;
         case ExpressReports.TenantLeaseAbstractFromLink:
            reportModel.Report = Report.LeaseAbstract;
            return reportModel;
         case ExpressReports.TenantMinnCRPFromLink:
            reportModel.Report = Report.MinnCRP;
            return reportModel;
         case ExpressReports.TenantMinnCRP2018AndPriorFromLink:
            reportModel.Report = Report.MinnCRP2018;
            return reportModel;
         case ExpressReports.TenantCAMReconciliationFromLink:
            reportModel.Report = Report.CAMReconciliationStatement;
            return reportModel;
         case ExpressReports.ProspectMailingLabelsFromLink:
            reportModel.Report = Report.ProspectLabels;
            return reportModel;
         case ExpressReports.OwnerStatementFromLink:
            reportModel.Report = Report.OwnerStatement;
            return reportModel;
         case ExpressReports.OwnerMailingLabelsFromLink:
            reportModel.Report = Report.OwnerLabels;
            return reportModel;
         case ExpressReports.OwnerProfileFromLink:
            reportModel.Report = Report.OwnerProfile;
            return reportModel;
         case ExpressReports.Owner1096FromLink:
            reportModel.Report = Report.Owner1096;
            return reportModel;
         case ExpressReports.Owner1099FromLink:
            reportModel.Report = Report.Owner1099;
            return reportModel;
         case ExpressReports.Owner1099CopyBFromLink:
            reportModel.Report = Report.Owner1099CopyB;
            return reportModel;
         case ExpressReports.VendorMailingLabelsFromLink:
            reportModel.Report = Report.VendorLabels;
            return reportModel;
         case ExpressReports.ServiceOrderListFromLink:
            reportModel.Report = Report.ServiceOrderList;
            return reportModel;
         case ExpressReports.DepositDetailFromDeposit:
         case ExpressReports.DepositDetailMICRFromDeposit:
            reportModel.Report =
               reportModel.ExpressReport === ExpressReports.DepositDetailFromDeposit
                  ? Report.DepositDetail
                  : Report.DepositDetailMICR;
            return reportModel;
         case ExpressReports.DepositDetailBreakDownFromDeposit:
            reportModel.Report = Report.DepositBreakdown;
            return reportModel;
         case ExpressReports.AssetProfileFromLink:
            reportModel.Report = Report.AssetProfile;
            return reportModel;
         case ExpressReports.AssetDepreciationFromLink:
            reportModel.Report = Report.DepreciationSchedule;
            return reportModel;
         case ExpressReports.MakeReadyDetailFromMakeReady:
            reportModel.Report = Report.MakeReadyDetail;
            return reportModel;
         case ExpressReports.BasicOwnerStatementFromLink:
            reportModel.Report = Report.BasicOwnerStatement;
            return reportModel;
         case ExpressReports.Vendor1099CopyBFromLink:
            reportModel.Report = Report.Vendor1099CopyB;
            return reportModel;
         case ExpressReports.ViolationCodeGroupPrintFromLink:
            reportModel.Report = Report.CodeGroupViolationsListing;
            return reportModel;
         default:
            return reportModel;
      }
   }
}
