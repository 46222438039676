<div
   class="selector-container"
   (window:resize)="onResize()"
>
   <lcs-loading-overlay
      [show]="isLoading"
      [diameter]="30"
   >
      <div
         #chartAccountsSelectorWrapper
         [attr.id]="path"
         class="single-line-multi-selector-wrapper select"
         [class.selector-disabled]="disabled"
         [class.selector-error]="
            control?.errors && !disabled && !control?.valid && (control?.touched || control?.dirty)
         "
         [class.open]="showOverlayPanel"
         (blur)="lostFocus()"
         [attr.tabindex]="disabled ? null : 0"
      >
         <div class="selector-content">
            <div class="selector-content-label">
               {{ selectedItemCountMessage }}
            </div>
            <div
               class="selector-icons"
               [hidden]="disabled"
            >
               <lcs-icon
                  [hidden]="control && !control?.valid"
                  [icon]="showOverlayPanel ? openIcon : closedIcon"
                  (click)="onIconClicked()"
               ></lcs-icon>
               <i
                  [hidden]="control?.valid || (!control?.touched && !control?.dirty)"
                  class="material-icons validation-error-icon"
               >error_outline</i>
            </div>
         </div>
      </div>
   </lcs-loading-overlay>
</div>
<lcs-chart-accounts-to-include-overlay-panel
   [show]="showOverlayPanel"
   [items]="items"
   [selectedItemCount]="selectedItemCount"
   [isAllSelected]="$any(isAllSelected | async)"
   (visibilityChanged)="onVisibilityChanged($event)"
   [control]="control"
   [displayName]="displayName"
   [showMapping]="showMapping"
   [isLoading]="isLoading"
   [parentElement]="chartAccountsSelectorWrapperElement"
   [innerElementTags]="['div']"
   [innerElementSelector]="'.single-line-multi-selector-wrapper'"
   [disabled]="disabled"
   [alignToRight]="alignOverlayPanelToRight"
   [staticOverlay]="staticOverlay"
   [glAccountMapValueSource]="glAccountMapValueSource"
   [glAccountSelectorsConfigured]="$any(glAccountSelectorsConfigured | async)"
   [glAccountSelectorEndpoint]="$any(glAccountSelectorEndpoint | async)"
   [glAccountSelectorEndpointIsSearch]="$any(glAccountSelectorEndpointIsSearch | async)"
   [glAccountSelectorFilters]="$any(glAccountSelectorFilters | async)"
   [glAccountMapID]="glAccountMapID"
   [fromGlAccountID]="$any(fromGlAccountID | async)"
   [toGlAccountID]="$any(toGlAccountID | async)"
   [glAccountTypeID]="glAccountTypeID"
   (selectionChange)="onSelectionChange($event)"
   (mapSelectionChanged)="changeMapSelection($event)"
   (fromAccountChanged)="changeFromAccount($event)"
   (toAccountChanged)="changeToAccount($event)"
   (groupSelectionChanged)="changeGroupSelection($event)"
   [overlayPanelStaticWidth]="overlayPanelStaticWidth"
   [overlayPanelStaticMinWidth]="overlayPanelStaticMinWidth"
>
</lcs-chart-accounts-to-include-overlay-panel>