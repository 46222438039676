import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";

export class NumberValidator {
   static maxFractionalDigits(maxFractionalDigits: number): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value || isNaN(maxFractionalDigits) || maxFractionalDigits < 0) {
            return null;
         }
         const valueString = control.value.toString();
         const decimalIndex = valueString.indexOf(".");
         if (decimalIndex > -1 && decimalIndex + 1 < valueString.length) {
            const scaleDigits = valueString.substring(decimalIndex + 1);
            if (scaleDigits.length > maxFractionalDigits) {
               return { maxFractionalDigits: maxFractionalDigits };
            }
         }
         return null;
      };
   }

   static range([rangeStart, rangeEnd]: [number, number], isExclusive: boolean): ValidatorFn {
      return (control: AbstractControl) => {
         if (control.value == null) {
            return null;
         }

         let isInRange: boolean;

         if (isExclusive) {
            isInRange = control.value > rangeStart && control.value < rangeEnd;
         } else {
            isInRange = control.value >= rangeStart && control.value <= rangeEnd;
         }

         if (!isInRange) {
            return { range: { start: rangeStart, end: rangeEnd } };
         }

         return null;
      };
   }

   static whitelist(whitelist: number[]): ValidatorFn {
      return (control: AbstractControl) => {
         let value = control.value;
         if (value) {
            value = value.getTime();
         }
         const isInWhitelist =
            whitelist.filter((whitelistedValue: number) => {
               return whitelistedValue === value;
            }).length > 0;

         if (!isInWhitelist) {
            return { whitelist: `[${whitelist.join(", ")}]` };
         }

         return null;
      };
   }

   static blacklist(blacklist: number[]): ValidatorFn {
      return (control: AbstractControl) => {
         let value = control.value;
         if (value) {
            value = value.getTime();
         }
         const isInBlacklist = blacklist.some((blacklistedValue: number) => {
            return blacklistedValue === value;
         });

         if (isInBlacklist) {
            return { blacklist: `[${blacklist.join(", ")}]` };
         }

         return null;
      };
   }

   static getValidators(validation: ValidationModel): ValidatorFn[] {
      const validators = new Array<ValidatorFn>();

      if (typeof validation.min === "number") {
         validators.push(Validators.min(validation.min));
      }
      if (typeof validation.max === "number") {
         validators.push(Validators.max(validation.max));
      }
      if (typeof validation.minLength === "number") {
         validators.push(Validators.minLength(validation.minLength));
      }
      if (typeof validation.maxLength === "number") {
         validators.push(Validators.maxLength(validation.maxLength));
      }

      if (typeof validation.maxFractionalDigits === "number") {
         validators.push(NumberValidator.maxFractionalDigits(validation.maxFractionalDigits));
      }
      if (validation.whitelist && validation.whitelist.length > 0) {
         validators.push(NumberValidator.whitelist(validation.whitelist));
      }
      if (validation.blacklist && validation.blacklist.length > 0) {
         validators.push(NumberValidator.blacklist(validation.blacklist));
      }
      if (validation.exclusiveRange && validation.exclusiveRange.length > 0) {
         validators.push(NumberValidator.range(validation.exclusiveRange, true));
      }
      if (validation.inclusiveRange && validation.inclusiveRange.length > 0) {
         validators.push(NumberValidator.range(validation.inclusiveRange, false));
      }

      return validators;
   }

   static minHelper(value: number, min: number) {
      if (!value) {
         return null;
      }

      if (min > value) {
         return { min: { min: min, actual: value } };
      }

      return null;
   }

   static maxHelper(value: number, max: number) {
      if (!value) {
         return null;
      }

      if (max < value) {
         return { max: { max: max, actual: value } };
      }

      return null;
   }

   static setValidationErrorsObject(errors: {}, errorObjectToAdd: {}) {
      Object.keys(errorObjectToAdd).forEach((key) => {
         errors[key] = errorObjectToAdd[key];
      });
   }
}
