import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { InputsModule } from "@lcs/inputs/inputs.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { OwaInputsModule } from "../inputs/inputs.module";
import { ValidatePasswordFormatModule } from "../validate-password-format/validate-password-format.module";
import { VerifyEmailAddressComponent } from "./verify-emailaddress.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      LcsProgressButtonModule,
      TextBoxModule,
      MatTooltipModule,
      ValidatePasswordFormatModule,
      InputsModule,
      OwaInputsModule,
   ],
   declarations: [VerifyEmailAddressComponent],
})
export class VerifyEmailAddressModule {}
