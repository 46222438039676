<div
   class="searchable-selector-wrapper"
   [class.label-selector]="isLabelSelector"
   (window:resize)="onResize()"
>
   <div
      #userInputWrapper
      class="searchable-selector-container"
      (focusin)="onFocusin()"
      (focusout)="onFocusout()"
      (keydown.enter)="onEnter($event)"
   >
      <div class="input-wrapper">
         <i
            *ngIf="isEntitySearchable && showIcon"
            class="material-icons search-icon"
            [class.disabled]="disabled"
         >search</i>
         <i
            *ngIf="optionalIcon && optionalIcon.length > 0"
            class="material-icons optional-icon"
            [class.disabled]="disabled"
            (mousedown)="onIconMousedown($event)"
         >{{optionalIcon}}</i>
         <ng-container *ngIf="!selectorInputTemplate">
            <input
               #inputEl
               type="search"
               autocomplete="off"
               title="{{ tooltipOverride ? tooltipOverride : displayValue }}"
               [attr.id]="path"
               [class.optional-icon]="(optionalIcon && optionalIcon.length > 0) || (isEntitySearchable && showIcon)"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [ngModel]="displayValue"
               (ngModelChange)="onSearchTextChanged($event)"
               [ngModelOptions]="{ standalone: true }"
            />
         </ng-container>
         <ng-container *ngTemplateOutlet="selectorInputTemplate;
            context: {
               $implicit: this,
               isDisabled: disabled,
               tooltipOverride: tooltipOverride
            }">
         </ng-container>

         <lcs-icon
            *ngIf="inputFocused || control?.valid || (control?.untouched && control?.pristine) || !control"
            [icon]="showOverlayPanel ? openIcon : closedIcon"
            [disabled]="disabled"
            class="selector-expand-icon"
            (mousedown)="onIconMousedown($event)"
         ></lcs-icon>
         <i
            *ngIf="!inputFocused && control?.invalid && (control?.touched || control?.dirty)"
            class="material-icons validation-error-icon"
            [class.disabled]="disabled"
            (mousedown)="onIconMousedown($event)"
         >error_outline</i>
      </div>
   </div>
   <lcs-selector-overlay-panel
      *ngIf="showOverlayPanel"
      [(show)]="showOverlayPanel"
      (showChange)="inputClosed($event)"
      [itemSet]="itemSet"
      [control]="control"
      [displayName]="displayName"
      [errorMessage]="$any(errorMessage)"
      [additionalMessage]="additionalMessage"
      [selectorOptionTemplate]="selectorOptionTemplate"
      [itemSetIsLoading]="isSearching"
      [parentElement]="userInputWrapper"
      [selectedItemIndex]="selectedOverlayItemIndex"
      (selected)="selectItem($event, true)"
      [alignToRight]="alignOverlayPanelToRight"
      [toggleOnClick]="false"
      [hideOnBlur]="hideOverlayOnBlur"
      [parentOverlayPanelRef]="parentOverlayPanelRef"
      [overlayPanelStaticWidth]="overlayPanelStaticWidth"
   >
   </lcs-selector-overlay-panel>
</div>