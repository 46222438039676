<owa-layout [headerTitle]="'Estimates'">
   <div>
      <owa-tile
         [title]="'Open'"
         [isViewMore]="false"
      >
         <owa-estimate-list
            [DefaultActiveFilter]="true"
            [EnableFilters]="true"
           
         ></owa-estimate-list>
      </owa-tile>
      <owa-tile
         [title]="'Closed'"
         [isViewMore]="false"
         *ngIf="showClosed"
      >
         <owa-estimate-list
            [DefaultActiveFilter]="false"
            [EnableFilters]="true"
         ></owa-estimate-list>
      </owa-tile>
   </div>
</owa-layout>