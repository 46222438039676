import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

export class ReportParameterValueModel {
   reportParameter: ReportParameter;
   value: any;
   rawValues: Array<any>;
   constructor(reportParameter: ReportParameter, value: any, rawValues?: Array<any>) {
      this.reportParameter = reportParameter;
      this.value = value;
      if (rawValues) {
         this.rawValues = rawValues;
      }
   }
}
