import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DateRangeModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/date-range-modes.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-date-range-with-modes-report-parameter",
   templateUrl: "date-range-with-modes-report-parameter.component.html",
})
export class DateRangeWithModesReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   forecastDateRangeModeParameter: ReportReportParameterViewModel;

   forecastStartYearParameter: ReportReportParameterViewModel;

   forecastEndYearParameter: ReportReportParameterViewModel;

   forecastStartMonthParameter: ReportReportParameterViewModel;

   forecastQuarterParameter: ReportReportParameterViewModel;

   dateRangeModes = DateRangeModes;

   selectedDateRangeMode: DateRangeModes;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.forecastDateRangeModeParameter.IsAsk = isAsk;
      this.forecastStartMonthParameter.IsAsk = isAsk;
      this.forecastStartYearParameter.IsAsk = isAsk;
      this.forecastEndYearParameter.IsAsk = isAsk;
      this.forecastQuarterParameter.IsAsk = isAsk;

      this.reportParametersService.updateReportParameterAsk(ReportParameter.ForecastDateRangeMode, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.ForecastStartYear, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.ForecastEndYear, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.ForecastStartMonth, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.ForecastQuarter, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.forecastDateRangeModeParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ForecastDateRangeMode
      );
      this.forecastDateRangeModeParameter.DisplayName = "";
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.forecastStartMonthParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ForecastStartMonth
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.forecastStartYearParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ForecastStartYear
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.forecastEndYearParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ForecastEndYear
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.forecastQuarterParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.ForecastQuarter
      );

      this.selectedDateRangeMode = DateRangeModes.Monthly;
      this.reportParametersService.reportParameterUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
         if (value.reportParameter === ReportParameter.ForecastDateRangeMode) {
            switch (value.value.toLowerCase()) {
               case "monthly":
                  this.selectedDateRangeMode = DateRangeModes.Monthly;
                  break;
               case "quarterly":
                  this.selectedDateRangeMode = DateRangeModes.Quarterly;
                  break;
               case "yearly":
                  this.selectedDateRangeMode = DateRangeModes.Yearly;
                  break;
            }
         }
      });
   }
}
