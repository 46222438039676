<label class="account-info-label">This is information for the primary contact and not your
   personal user
   login.</label>
<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>
   <lcs-loading-overlay [show]="loading">
      <div
         class="flex"
         style="padding-bottom: 11.6px;"
      >
         <div class="flex-half">
            <div class="tile leftTile">
               <div class="tile-header-wrapper">
                  <div class="tile-title">
                     Email / Phone Numbers
                  </div>
               </div>
               <div class="tile-content">
                  <div class="flex-full controls">
                     <label>Email Address</label>
                     <span
                        matTooltip="This is the primary contact's email address.&#13;Changing this will not change the login username."
                        matTooltipPosition="right"
                        matTooltipClass="password-tooltip email-tooltip"
                        class="tooltip-position"
                        style="margin-bottom:-4px;"
                     >
                        <i class="material-icons error-icon">info</i>
                     </span>
                     <span>
                        <lcs-text-box
                           [(ngModel)]="contact.Email"
                           name="emailAddress"
                           displayName="Email Address"
                           [disabled]="!canEdit"
                           [required]="false"
                           [lcsValidate]="emailValidationModel"
                           [maxlength]=255
                        ></lcs-text-box>
                     </span>
                  </div>
                  <div class="flex-full controls">
                     <label>Primary Phone Number</label>
                     <span>
                        <lcs-select
                           *ngIf="canEdit"
                           lcsLegacySingleSelector
                           [valueSource]="phoneNumberTypesValueSource"
                           [ngModel]="selectedPhoneType"
                           (ngModelChange)="setPhoneNumberType($event)"
                           name="phoneTypeSelect"
                        >
                        </lcs-select>
                        <lcs-text-box
                           *ngIf="!canEdit"
                           [(ngModel)]="selectedPhoneValue"
                           name="phoneTypeSelect"
                           displayName="Primary Phone Number Type"
                           [disabled]="!canEdit"
                           [required]="false"
                        >
                        </lcs-text-box>
                     </span>
                  </div>


                  <div *ngIf="isOwner; else ownerProspectPhone">
                     <div class="flex ">
                        <div
                           class=" flex-half controls"
                           *ngFor="let phoneNumberValue of owner?.PhoneNumbers; let index = index"
                        >
                           <label>{{ phoneNumberValue.PhoneNumberType.Name }}</label>

                           <owa-phone-number
                              [(ngModel)]="phoneNumberValue.PhoneNumber"
                              [disabled]="!canEdit"
                              name="{{ phoneNumberValue.PhoneNumberType.Name }} phonenumber"
                              displayName="{{ phoneNumberValue.PhoneNumberType.Name }} phone number"
                              class="phone-number-text"
                           ></owa-phone-number>

                        </div>
                     </div>
                  </div>

                  <ng-template #ownerProspectPhone>
                     <div class="flex">
                        <div
                           class=" flex-half controls"
                           *ngFor="let phoneNumberValue of ownerProspect?.Contact?.PhoneNumbers; let index = index"
                        >
                           <label>{{ phoneNumberValue.PhoneNumberType.Name }}</label>
                           <owa-phone-number
                              [(ngModel)]="phoneNumberValue.PhoneNumber"
                              [disabled]="!canEdit"
                              name="{{ phoneNumberValue.PhoneNumberType.Name }} phonenumber"
                              displayName="{{ phoneNumberValue.PhoneNumberType.Name }} phone number"
                              class="phone-number-text"
                           ></owa-phone-number>
                        </div>
                     </div>
                  </ng-template>
               </div>
            </div>
         </div>
         <div class="flex-half">
            <div class="tile rightTile">
               <div class="tile-header-wrapper">
                  <div class="tile-title">
                     Addresses
                  </div>
               </div>
               <div class="tile-content">
                  <div class="controls">
                     <label>Primary Address </label>
                     <span>
                        <lcs-select
                           *ngIf="canEdit"
                           lcsLegacySingleSelector
                           [valueSource]="addressTypesValueSource"
                           [ngModel]="selectedAddressType"
                           (ngModelChange)="setAddressType($event)"
                           name="addressTypeSelect"
                           class="testdropdown"
                        >
                        </lcs-select>
                        <lcs-text-box
                           *ngIf="!canEdit"
                           [(ngModel)]="selectedAddressValue"
                           name="addressTypeSelect"
                           displayName="Primary Address Type"
                           [disabled]="!canEdit"
                           [required]="false"
                        >
                        </lcs-text-box>
                     </span>
                  </div>
                  <div *ngIf="isOwner; else ownerProspectAddress">
                     <div *ngFor="let address of owner.Addresses; let index = index">
                        <div class="controls">
                           <label>{{ address.AddressType.Name }}</label>
                           <div class="text-area">
                              <lcs-text-area
                                 class="update-personal-information"
                                 [expandable]="false"
                                 [ngModel]="address.Address"
                                 (ngModelChange)="onAddressChange(address, $event)"
                                 name="{{ address.AddressType.Name }} address"
                                 displayName="{{ address.AddressType.Name }} address"
                                 [disabled]="!canEdit"
                              >
                              </lcs-text-area>
                           </div>
                        </div>
                     </div>
                  </div>
                  <ng-template #ownerProspectAddress>
                     <div *ngFor="let address of ownerProspect.Addresses; let index = index">
                        <div class="controls">
                           <label>{{ address.AddressType.Name }}</label>
                           <div class="text-area">
                              <lcs-text-area
                                 class="update-personal-information"
                                 [expandable]="false"
                                 [ngModel]="address.Address"
                                 (ngModelChange)="onAddressChange(address, $event)"
                                 name="{{ address.AddressType.Name }} address"
                                 displayName="{{ address.AddressType.Name }} address"
                                 [disabled]="!canEdit"
                              >
                              </lcs-text-area>
                           </div>
                        </div>
                     </div>
                  </ng-template>
               </div>
            </div>
         </div>
      </div>
   </lcs-loading-overlay>

   <div
      lcsFooter
      class="flex align-right button-control"
   >
      <lcs-progress-button
         style="padding-bottom: 7px; padding-right:2px; padding-top:7px;"
         *ngIf="canEdit"
         [(status)]="updateButtonStatus"
         [raised]="true"
      >
         {{ canEdit ? "Update" : "OK" }}
      </lcs-progress-button>
   </div>
</form>