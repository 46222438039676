<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
   >{{ parameterLabel }}</label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="askParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="tab-wrapper">
   <lcs-loading-overlay
      [show]="isLoadingGroups"
      [diameter]="60"
   >
      <mat-tab-group
         animationDuration="0"
         *ngIf="propertyOwnerReportParameter"
         (selectedIndexChange)="updateLabel($event);updateGroup($event)"
      >
         <mat-tab
            label="{{propertiesReplacementName}}"
            [disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
         >
            <ng-template matTabContent>
               <lcs-dynamic-selector-report-parameter
                  *ngIf="propertyReportParameter"
                  [hideLabel]="true"
                  [name]="name + '-property'"
                  [disabled]="disablePropertyMultiSelectors || disabled || (hasAsk && primaryParameter.IsAsk)"
                  [parameter]="propertyReportParameter"
                  [entityValueSourceFilters]="propertyFilters"
                  [selectAllItemsOnFilterChange]="false"
               ></lcs-dynamic-selector-report-parameter>
            </ng-template>
         </mat-tab>
         <mat-tab
            label="{{ownersReplacementName}}"
            [disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
         >
            <ng-template matTabContent>
               <lcs-dynamic-selector-report-parameter
                  *ngIf="ownerReportParameter"
                  [hideLabel]="true"
                  [name]="name + '-owner'"
                  [disabled]="disableOwnerMultiSelectors || disabled || (hasAsk && primaryParameter.IsAsk)"
                  [parameter]="ownerReportParameter"
               ></lcs-dynamic-selector-report-parameter>
            </ng-template>
         </mat-tab>
      </mat-tab-group>
      <div
         class="single-tab-wrapper"
         *ngIf="propertyReportParameter && !ownerReportParameter"
      >
         <lcs-dynamic-selector-report-parameter
            [hideLabel]="true"
            [name]="name + '-property'"
            [disabled]="disablePropertyMultiSelectors || disabled || (hasAsk && primaryParameter.IsAsk)"
            [parameter]="propertyReportParameter"
            [entityValueSourceFilters]="propertyFilters"
            [selectAllItemsOnFilterChange]="false"
         ></lcs-dynamic-selector-report-parameter>
      </div>
      <div
         class="single-tab-wrapper"
         *ngIf="ownerReportParameter && !propertyReportParameter"
      >
         <lcs-dynamic-selector-report-parameter
            [hideLabel]="true"
            [name]="name + '-owner'"
            [disabled]="disableOwnerMultiSelectors || disabled || (hasAsk && primaryParameter.IsAsk)"
            [parameter]="ownerReportParameter"
         ></lcs-dynamic-selector-report-parameter>
      </div>
      <div class="additional-controls-wrapper">
         <label
            *ngIf="groupValueSources"
            class="glLedger-report"
            [class.disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
         >Group</label>
         <owa-dynamic-selector
            *ngIf="groupValueSources"
            [name]="name + '-group'"
            [displayName]="'Group'"
            [disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
            [valueSources]="groupValueSources"
            [singleValue]="selectedGroupID"
            (selectionChanged)="groupSelected($event)"
         ></owa-dynamic-selector>
         <lcs-checkbox-report-parameter
            [name]="name + '-include-inactive'"
            [displayName]="'Include Inactive'"
            [disabled]="disabled || (hasAsk && primaryParameter.IsAsk)"
            [parameter]="includeInactiveReportParameter"
         ></lcs-checkbox-report-parameter>
      </div>

      <div
         *ngIf="isBatchReport && (propertyReportParameter || propertyOwnerReportParameter)"
         class="additional-controls-wrapper"
      >
         <label [class.disabled]="hasAsk && primaryParameter.IsAsk">Alternative Owner Batch Options</label>
         <lcs-checkbox-report-parameter
            [name]="name + '-use-owners-properties'"
            [disabled]="disableUseOwnerProperties || (hasAsk && primaryParameter.IsAsk)"
            [parameter]="useOwnerPropertiesReportParameter"
         ></lcs-checkbox-report-parameter>
         <lcs-checkbox-report-parameter
            [name]="name + '-include-owner-inactive'"
            [disabled]="disableIncludeInactiveProperties || (hasAsk && primaryParameter.IsAsk)"
            [parameter]="includeOwnerInactivePropertiesReportParameter"
         ></lcs-checkbox-report-parameter>
      </div>
   </lcs-loading-overlay>
</div>