import { ValidationErrors } from "@angular/forms";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";

import { AccountNumberValidator } from "./validators/account-number.validator";
import { RoutingNumberValidator } from "./validators/routing-number.validator";

export class ValidationHelper {
   public static Currency(required: boolean, allowZero: boolean, allowNegative: boolean): ValidationModel {
      const validation = new ValidationModel();
      validation.dataType = ExpressDataTypes.Numeric;
      validation.max = 999999;
      validation.maxFractionalDigits = 2;
      validation.required = required;
      if (!allowNegative) {
         validation.min = 0;
      }
      if (!allowZero) {
         validation.notUnset = true;
         validation.unsetValue = 0;
      }
      return validation;
   }

   public static FutureDate(required: boolean, allowToday: boolean): ValidationModel {
      const validation = new ValidationModel();
      validation.dataType = ExpressDataTypes.Date;
      validation.required = required;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (allowToday) {
         validation.minDate = today;
      } else {
         const tomorrow = new Date(today.setDate(today.getDate() + 1));
         validation.minDate = tomorrow;
      }
      return validation;
   }

   /**
    * Returns whether scalar or array value is null or empty
    * @param value: any
    */
   public static isNullOrEmpty(value: any): boolean {
      return value == null || value.length === 0;
   }

   public static isNotNullOrEmpty(value: any): boolean {
      return !ValidationHelper.isNullOrEmpty(value);
   }

   public static NotUnset(unsetValue: number): ValidationModel {
      const validation = new ValidationModel();
      validation.unsetValue = unsetValue;
      validation.notUnset = true;
      return validation;
   }

   public static AccountNumber(paymentMethodControlName: string): ValidationModel {
      const validation = new ValidationModel();
      validation.required = true;
      validation.customValidator = AccountNumberValidator.getValidator(paymentMethodControlName);
      return validation;
   }

   public static mergeErrorMessages(validationErrors: Array<ValidationErrors>): ValidationErrors | null {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
      const res: { [key: string]: any } = validationErrors.reduce(
         (allErrors: ValidationErrors | null, errors: ValidationErrors | null) => {
            return errors != null ? { ...allErrors, ...errors } : allErrors;
         },
         {}
      );
      return Object.keys(res).length === 0 ? null : res;
   }

   public static RoutingNumber(): ValidationModel {
      const validation = new ValidationModel();
      validation.required = true;
      validation.customValidator = RoutingNumberValidator.getValidator();
      return validation;
   }

   /**
    * Since javascript can allow a string to be passed to a Date at runtime
    * this static method will check if the date is actually a Date by
    * checking for getTime method and if not found will parse date instead.
    * Both return Milliseconds since 1/1/1970
    * @param date
    */
   public static getSafeDateTimeMs(date: Date) {
      const safeDateTimeMs = date.getTime ? date.getTime() : Date.parse(date.toString());
      return safeDateTimeMs;
   }
}
