import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TooltipModule } from "primeng/tooltip";

import { LayoutModule } from "../layout/layout.module";
import { MenuService } from "../top-bar/menu/menu.service";
import { ReportsListComponent } from "./containers/reports-list.component";

@NgModule({
   imports: [CommonModule, RouterModule, TooltipModule, LayoutModule, FormsModule],
   declarations: [ReportsListComponent],
   providers: [MenuService],
})
export class ReportsListModule { }
