import { PhoneNumberModel } from "./phone-number.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class EmailAddressSummaryModel {
   accountID: number;
   ConfirmPassword: string;
   DesiredUsername: string;
   FirstName: string;
   LastName: string;
   LocationID: number;
   Message: string;
   MetaTag?: string;
   Password: string;
   PhoneNumbers: Array<PhoneNumberModel>;
   SourceStep: string;
   Username: string;
   UserNameIsVerified: boolean;
   WebUserID: number;
}
