<lcs-multi-select
   *ngIf="allowsMultipleSelections"
   [name]="name"
   [displayName]="displayName"
   [disabled]="disabled"
   [allowsSelectAll]="allowsSelectAll"
   [valueSources]="valueSources"
   [ngModel]="selectedValues"
   [ngModelOptions]="{ standalone: standalone }"
   (valueSourceSelected)="propagateValueSourceSelected($event)"
   (selectionChanged)="propagateSelectionChanged($event)"
   [entityValueSourceFilters]="entityValueSourceFilters"
   [lcsValidate]="validation"
></lcs-multi-select>
<lcs-select
   lcsLegacySingleSelector
   *ngIf="
      !allowsMultipleSelections && firstValueSource
   "
   [name]="name"
   [displayName]="displayName"
   [disabled]="disabled"
   [valueSource]="firstValueSource"
   [filters]="entityValueSourceFilters"
   [filterExpression]="entityValueSourceFilterExpression"
   [(ngModel)]="singleValue"
   [ngModelOptions]="{ standalone: standalone }"
   [selectorInputTemplate]="selectorInputTemplate"
   [selectorOptionTemplate]="selectorOptionTemplate"
   [alignOverlayPanelToRight]="alignOverlayPanelToRight"
   [lcsValidate]="validation"
>
</lcs-select>