import { ChangeDetectorRef, Component } from "@angular/core";
import { NgControl } from "@angular/forms";

import { SecureInputBase } from "./secure-input-base";

@Component({
   selector: "lcs-secure-ssn-input",
   templateUrl: "./secure-input-view.html",
})
export class SecureSsnInputComponent extends SecureInputBase {
   constructor(protected changeDetectorRef: ChangeDetectorRef, public control: NgControl) {
      super(changeDetectorRef, control);
      this.defaultMaskValue = "XXX-XX-XXXX";
   }

   protected getDefaultPlaceholder(): string {
      return this.defaultMaskValue;
   }

   protected generateMaskValue(unmaskedValue: string): string {
      unmaskedValue = unmaskedValue == null ? "" : unmaskedValue.toString();
      const maxLast4DigitsValue = unmaskedValue.replace(/\D/g, "").replace(/^.*?(\d{1,4})$/, "$1");
      const maskedValue: string = this.defaultMaskValue
         .substr(0, this.defaultMaskValue.length - maxLast4DigitsValue.length)
         .concat(maxLast4DigitsValue);
      return maskedValue;
   }

   protected getVisibleMaskValue(maskedValue: string): string {
      const maxLast4DigitsValue = maskedValue.replace(/\D/g, "").replace(/^.*?(\d{1,4})$/, "$1");
      return maxLast4DigitsValue;
   }
}
