import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { BirdsEyeViewMapUserSettingEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/birds-eye-view-map-user-setting.embed-options";
import { LetterTemplateEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/letter-template.embed-options";
import { LocationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/location.embed-options";
import { PersonalAddressBookEntryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/personal-address-book-entry.embed-options";
import { ReportWriterReportEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report-writer-report.embed-options";
import { ReportEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/report.embed-options";
import { ServiceManagerIssueLayoutSectionEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-layout-section.embed-options";
import { ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-layout-user-defined-field.embed-options";
import { ServiceManagerIssueLayoutEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-issue-layout.embed-options";
import { UserPreferenceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-preference.embed-options";
import { UserPrivilegeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-privilege.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { PersonalAddressBookEntrySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/personal-address-book-entry.save-options";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { BirdsEyeViewMapUserSettingModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/birds-eye-view-map-user-setting.model";
import { EffectiveUserPrivilegeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/effective-user-privilege.model";
import { LetterTemplateModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/letter-template.model";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { PersonalAddressBookEntryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/personal-address-book-entry.model";
import { ReportWriterReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-writer-report.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { ServiceManagerIssueLayoutSectionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-layout-section.model";
import { ServiceManagerIssueLayoutUserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-layout-user-defined-field.model";
import { ServiceManagerIssueLayoutModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue-layout.model";
import { UserPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-preference.model";
import { UserPrivilegeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-privilege.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class CurrentService {
   static readonly BirdsEyeViewMapUserSettingsResource = "BirdsEyeViewMapUserSettings";
   static readonly CustomServiceManagerIssueLayoutResource = "CustomServiceManagerIssueLayout";
   static readonly CustomServiceManagerIssueLayoutSectionsResource = "CustomServiceManagerIssueLayoutSections";
   static readonly CustomServiceManagerIssueLayoutUserDefinedFieldsResource =
      "CustomServiceManagerIssueLayoutUserDefinedFields";
   static readonly EffectiveUserPrivilegesResource = "EffectiveUserPrivileges";
   static readonly EffectiveUserReportsResource = "EffectiveUserReports";
   static readonly LetterTemplatesResource = "LetterTemplates";
   static readonly LocationsResource = "Locations";
   static readonly PersonalAddressBookEntriesResource = "PersonalAddressBookEntries";
   static readonly PreferencesResource = "Preferences";
   static readonly PrivilegesResource = "Privileges";
   static readonly ReportsResource = "Reports";
   static readonly ReportWriterReportsResource = "ReportWriterReports";
   static readonly ServiceManagerIssueLayoutInUseResource = "ServiceManagerIssueLayoutInUse";
   static readonly UserResource = "User";
   static readonly endpoint = "CurrentView";
   constructor(private apiService: ApiService) {}
   getBirdsEyeViewMapUserSettingsUrl(
      itemID: number,
      embeds?: Array<string | BirdsEyeViewMapUserSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/BirdsEyeViewMapUserSettings",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBirdsEyeViewMapUserSettings(
      itemID: number,
      embeds?: Array<string | BirdsEyeViewMapUserSettingEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BirdsEyeViewMapUserSettingModel> {
      return this.apiService.getSingle(this.getBirdsEyeViewMapUserSettingsUrl(itemID, embeds, additionalParameters));
   }

   getBirdsEyeViewMapUserSettingsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BirdsEyeViewMapUserSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/BirdsEyeViewMapUserSettings",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBirdsEyeViewMapUserSettingsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BirdsEyeViewMapUserSettingEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BirdsEyeViewMapUserSettingModel>> {
      return this.apiService.getCollection(
         this.getBirdsEyeViewMapUserSettingsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCustomServiceManagerIssueLayoutUrl(
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayout",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCustomServiceManagerIssueLayout(
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLayoutModel> {
      return this.apiService.getSingle(
         this.getCustomServiceManagerIssueLayoutUrl(embeds, fields, additionalParameters)
      );
   }

   postCustomServiceManagerIssueLayoutUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayout",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCustomServiceManagerIssueLayout(
      layout?: ServiceManagerIssueLayoutModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLayoutModel> {
      return this.apiService
         .post(this.postCustomServiceManagerIssueLayoutUrl(saveOptions, fields, embeds, additionalParameters), layout)
         .pipe(map((response) => response.body));
   }

   deleteCustomServiceManagerIssueLayoutUrl(additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayout",
         null,
         null,
         null,
         additionalParameters
      );
   }

   deleteCustomServiceManagerIssueLayout(additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCustomServiceManagerIssueLayoutUrl(additionalParameters));
   }

   getCustomServiceManagerIssueLayoutSectionsCollectionUrl(
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutSections",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCustomServiceManagerIssueLayoutSectionsCollection(
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutSectionModel>> {
      return this.apiService.getCollection(
         this.getCustomServiceManagerIssueLayoutSectionsCollectionUrl(embeds, fields, additionalParameters)
      );
   }

   postCustomServiceManagerIssueLayoutSectionsUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutSections",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCustomServiceManagerIssueLayoutSections(
      item?: ServiceManagerIssueLayoutSectionModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLayoutSectionModel> {
      return this.apiService.postSingle(
         this.postCustomServiceManagerIssueLayoutSectionsUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCustomServiceManagerIssueLayoutSectionsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutSections",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCustomServiceManagerIssueLayoutSectionsCollection(
      sections?: Array<ServiceManagerIssueLayoutSectionModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutSectionModel>> {
      return this.apiService.postCollection(
         this.postCustomServiceManagerIssueLayoutSectionsCollectionUrl(
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         sections
      );
   }

   postAllCustomServiceManagerIssueLayoutSectionsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutSections",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllCustomServiceManagerIssueLayoutSectionsCollection(
      sections?: Array<ServiceManagerIssueLayoutSectionModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutSectionEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutSectionModel>> {
      return this.apiService.postCollection(
         this.postAllCustomServiceManagerIssueLayoutSectionsCollectionUrl(
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         sections
      );
   }

   deleteCustomServiceManagerIssueLayoutSectionsUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutSections",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteCustomServiceManagerIssueLayoutSections(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteCustomServiceManagerIssueLayoutSectionsUrl(ids, additionalParameters)
      );
   }

   getCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutUserDefinedFields",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCustomServiceManagerIssueLayoutUserDefinedFieldsCollection(
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutUserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(embeds, fields, additionalParameters)
      );
   }

   postCustomServiceManagerIssueLayoutUserDefinedFieldsUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutUserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCustomServiceManagerIssueLayoutUserDefinedFields(
      item?: ServiceManagerIssueLayoutUserDefinedFieldModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLayoutUserDefinedFieldModel> {
      return this.apiService.postSingle(
         this.postCustomServiceManagerIssueLayoutUserDefinedFieldsUrl(
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         item
      );
   }

   postCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutUserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCustomServiceManagerIssueLayoutUserDefinedFieldsCollection(
      userDefinedFields?: Array<ServiceManagerIssueLayoutUserDefinedFieldModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutUserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         userDefinedFields
      );
   }

   postAllCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutUserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllCustomServiceManagerIssueLayoutUserDefinedFieldsCollection(
      userDefinedFields?: Array<ServiceManagerIssueLayoutUserDefinedFieldModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerIssueLayoutUserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerIssueLayoutUserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllCustomServiceManagerIssueLayoutUserDefinedFieldsCollectionUrl(
            saveOptions,
            fields,
            embeds,
            additionalParameters
         ),
         userDefinedFields
      );
   }

   deleteCustomServiceManagerIssueLayoutUserDefinedFieldsUrl(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         CurrentService.endpoint + "/CustomServiceManagerIssueLayoutUserDefinedFields",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteCustomServiceManagerIssueLayoutUserDefinedFields(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteCustomServiceManagerIssueLayoutUserDefinedFieldsUrl(ids, additionalParameters)
      );
   }

   getEffectiveUserPrivilegesCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/EffectiveUserPrivileges",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEffectiveUserPrivilegesCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<EffectiveUserPrivilegeModel>> {
      return this.apiService.getCollection(this.getEffectiveUserPrivilegesCollectionUrl(additionalParameters));
   }

   getEffectiveUserReportsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/EffectiveUserReports",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEffectiveUserReportsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportModel>> {
      return this.apiService.getCollection(
         this.getEffectiveUserReportsCollectionUrl(filters, embeds, orderingOptions, fields, additionalParameters)
      );
   }

   getLetterTemplatesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LetterTemplateEmbedOptions> | null,
      ordering?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (ordering !== undefined && ordering !== null && ordering.length > 0) {
         additionalParameters.push("ordering=" + ordering);
      }
      return this.apiService.getUrl(
         CurrentService.endpoint + "/LetterTemplates",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLetterTemplatesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LetterTemplateEmbedOptions> | null,
      ordering?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LetterTemplateModel>> {
      return this.apiService.getCollection(
         this.getLetterTemplatesCollectionUrl(
            filters,
            embeds,
            ordering,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getLocationsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/Locations",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLocationsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService.getCollection(
         this.getLocationsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPersonalAddressBookEntriesUrl(
      itemID: number,
      embeds?: Array<string | PersonalAddressBookEntryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPersonalAddressBookEntries(
      itemID: number,
      embeds?: Array<string | PersonalAddressBookEntryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PersonalAddressBookEntryModel> {
      return this.apiService.getSingle(this.getPersonalAddressBookEntriesUrl(itemID, embeds, additionalParameters));
   }

   getPersonalAddressBookEntriesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PersonalAddressBookEntryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPersonalAddressBookEntriesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PersonalAddressBookEntryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PersonalAddressBookEntryModel>> {
      return this.apiService.getCollection(
         this.getPersonalAddressBookEntriesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPersonalAddressBookEntriesUrl(
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPersonalAddressBookEntries(
      item?: PersonalAddressBookEntryModel | null,
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PersonalAddressBookEntryModel> {
      return this.apiService.postSingle(
         this.postPersonalAddressBookEntriesUrl(saveOptions, fields, additionalParameters),
         item
      );
   }

   postPersonalAddressBookEntriesCollectionUrl(
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPersonalAddressBookEntriesCollection(
      addressBookEntries?: Array<PersonalAddressBookEntryModel> | null,
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PersonalAddressBookEntryModel>> {
      return this.apiService.postCollection(
         this.postPersonalAddressBookEntriesCollectionUrl(saveOptions, fields, additionalParameters),
         addressBookEntries
      );
   }

   postAllPersonalAddressBookEntriesCollectionUrl(
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllPersonalAddressBookEntriesCollection(
      addressBookEntries?: Array<PersonalAddressBookEntryModel> | null,
      saveOptions?: Array<string | PersonalAddressBookEntrySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PersonalAddressBookEntryModel>> {
      return this.apiService.postCollection(
         this.postAllPersonalAddressBookEntriesCollectionUrl(saveOptions, fields, additionalParameters),
         addressBookEntries
      );
   }

   deletePersonalAddressBookEntriesUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         CurrentService.endpoint + "/PersonalAddressBookEntries",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deletePersonalAddressBookEntries(
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePersonalAddressBookEntriesUrl(ids, additionalParameters));
   }

   getPreferencesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/Preferences",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPreferencesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPreferenceModel>> {
      return this.apiService.getCollection(
         this.getPreferencesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPrivilegesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/Privileges",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPrivilegesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPrivilegeModel>> {
      return this.apiService.getCollection(
         this.getPrivilegesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getReportsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/Reports",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReportsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportModel>> {
      return this.apiService.getCollection(
         this.getReportsCollectionUrl(filters, embeds, orderingOptions, fields, additionalParameters)
      );
   }

   getReportWriterReportsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportWriterReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/ReportWriterReports",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getReportWriterReportsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ReportWriterReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ReportWriterReportModel>> {
      return this.apiService.getCollection(
         this.getReportWriterReportsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getServiceManagerIssueLayoutInUseUrl(
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/ServiceManagerIssueLayoutInUse",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerIssueLayoutInUse(
      embeds?: Array<string | ServiceManagerIssueLayoutEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerIssueLayoutModel> {
      return this.apiService.getSingle(this.getServiceManagerIssueLayoutInUseUrl(embeds, fields, additionalParameters));
   }

   getUserUrl(
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         CurrentService.endpoint + "/User",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUser(
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUserUrl(embeds, fields, additionalParameters));
   }
}
