import { RelativePeriodOptions } from "./relative-period-options.enum";

export class RelativePeriodsService {
   static relativePeriodValueMap = new Map<string, RelativePeriodOptions>([
      ["Current Period", RelativePeriodOptions.CurrentPeriod],
      ["Current Year", RelativePeriodOptions.CurrentYear],
      ["Previous Period", RelativePeriodOptions.PreviousPeriod],
      ["Previous Year", RelativePeriodOptions.PreviousYear],
      ["Next Period", RelativePeriodOptions.NextPeriod],
      ["Next Year", RelativePeriodOptions.NextYear],
   ]);

   static relativePeriodEnumMap(): Map<RelativePeriodOptions, string> {
      const relativePeriodValueEnumMap = new Map<RelativePeriodOptions, string>();
      for (const key of Array.from(RelativePeriodsService.relativePeriodValueMap.keys())) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'RelativePeriodOptions | undefine... Remove this comment to see the full error message
         relativePeriodValueEnumMap.set(RelativePeriodsService.relativePeriodValueMap.get(key), key);
      }
      return relativePeriodValueEnumMap;
   }

   static IsRelativePeriodValue(relativePeriodValue: string): boolean {
      if (relativePeriodValue.toString().indexOf(":") > 0) {
         // WPF saves in this format -> "1:Previous Period"
         const parseString = relativePeriodValue.substring(relativePeriodValue.toString().indexOf(":") + 1);
         return RelativePeriodsService.relativePeriodValueMap.has(parseString);
      }
      return false;
   }

   static getEnumValue(stringValue: string): RelativePeriodOptions {
      // WPF saves in this format -> "1:Previous Period"
      const parseString = stringValue.substring(stringValue.toString().indexOf(":") + 1);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'RelativePeriodOptions | undefined' is not as... Remove this comment to see the full error message
      return RelativePeriodsService.relativePeriodValueMap.get(parseString);
   }
}
