<p-scrollPanel
   [style]="{ width: '100%', height: '100%' }"
   styleClass="drag-scroll-scrollpanel"
   [class.dragging]="isPressed"
   (mousedown)="onMousedown($event)"
   (dragstart)="onDragstart($event)"
   (touchstart)="onTouchStart($event)"
>
   <div [ngClass]="isIEOrEdge ? 'drag-scroll-inner-edge' : 'drag-scroll-inner'" [class.dragging]="isPressed">
      <ng-content></ng-content>
   </div>
</p-scrollPanel>
