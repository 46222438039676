<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && payableAccountTypesReportParameter.IsAsk === true)"
   >
      Payable Accounts
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="payableAccountTypesReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="'payable-account-types'"
      [displayName]="'Payable Accounts'"
      [disabled]="disabled || (hasAsk === true && payableAccountTypesReportParameter.IsAsk === true)"
      [parameter]="payableAccountTypesReportParameter"
   ></lcs-dynamic-selector-report-parameter>
</div>
<lcs-select
   owaEntitySelector
   [entityType]="payableAccountEntityType"
   [ngModel]="payableAccountsValueModel.value"
   [ngModelOptions]="{ standalone: true }"
   [name]="'payable-accounts'"
   [displayName]="'Selected Account'"
   [disabled]="disabled || (hasAsk === true && payableAccountsReportParameter.IsAsk === true)"
   [staticValues]="[payableAccountsAll]"
   (ngModelChange)="onPayableAccountChange($event)"
>
</lcs-select>