import { Component, Input, EventEmitter, Output } from "@angular/core";

@Component({
   selector: "owa-tile",
   templateUrl: "./tile.component.html",
   styleUrls: ["./tile.component.css"],
})
export class TileComponent {
   @Input() title: string;
   @Input() isViewMore: boolean;
   @Input() viewMoreComponent: string;
   @Input() showTag: boolean = false;
   @Input() tagText: string = "";

   @Output() openComponent = new EventEmitter<string>();

   openViewMoreAction(event) {
      this.openComponent.emit(event);
   }
}
