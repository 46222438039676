<div class="tenant-detail-wrapper">
   <div class="flex-grid tenant-detail">
      <div class="flex-two-thirds">
         <div class="grid-container">
            <div class="title">Name</div>
            <div class="tile-value">{{tenant?.Name}}</div>
            <div class="title">Unit Name</div>
            <div class="tile-value">{{$any(tenant)?.Leases[0]?.Unit?.Name}}</div>
            <div
               class="grid-container"
               *ngFor="let address of tenant?.Addresses"
            >
               <div
                  class="title"
                  *ngIf="address.IsPrimary"
               >Primary</div>
               <div
                  class="address tile-value"
                  *ngIf="address.IsPrimary"
               >{{address.Address}}</div>
               <div
                  class="title"
                  *ngIf="!address.IsPrimary"
               >Alternate</div>
               <div
                  class="address tile-value"
                  *ngIf="!address.IsPrimary"
               >{{address.Address}}</div>
            </div>
         </div>
      </div>
      <div class="flex-third">
         <div
            class="grid-container"
            *ngFor="let phone of primaryContact.PhoneNumbers"
         >
            <div class="title">{{phone.PhoneNumberType?.Name}}</div>
            <div class="tile-value">{{phone.PhoneNumber}}</div>
         </div>
      </div>
   </div>
</div>
<div lcsFooter>
   <lcs-progress-button
      [raised]="true"
      (buttonClick)="onOkClick()"
   >OK</lcs-progress-button>
</div>