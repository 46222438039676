////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressDataTypes {
   NotSet = 0,
   Currency = 1,
   Numeric = 2,
   String = 3,
   Date = 4,
   DateTime = 5,
   Percentage = 6,
   PhoneNumber = 7,
   Count = 8,
   Boolean = 9,
   TimeSpan = 10,
   Time = 11,
   Key = 12,
   Attachment = 13,
   Password = 14,
   Sequence = 15,
   Pinned = 16,
   SSN = 17,
   Lozenge = 18,
   Messages = 19,
}
