import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import cloneDeep from "lodash/cloneDeep";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { ExpressMenuItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-menu-item.model";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";

import { ActionRoutingService } from "../action-router/action-routing.service";
import { SideBarMenuItemConfigurationModel } from "./side-bar-menu-item-configuration.model";

@Component({
   selector: "lcs-side-bar-menu",
   templateUrl: "side-bar-menu.component.html",
})
export class SideBarMenuComponent implements OnInit, OnDestroy {
   @ViewChild("sideBarSubMenu0", { read: ElementRef }) sideBarSubMenu0: ElementRef;

   @ViewChild("sideBarSubMenu1", { read: ElementRef }) sideBarSubMenu1: ElementRef;

   @Input() hasSearch: boolean;

   @Input() searchablePlaceholderText = "Search";

   @Input() isExpressReport: boolean;

   @Input() reportID: Report;

   @Input() disabled: boolean;

   @Input() set currentEntityID(value: number) {
      if (value !== this._currentEntityID) {
         this._currentEntityID = value;
         if (this._currentEntityID && this.items && this.items.length > 0) {
            this.currentMenuItem = this.getMenuItem(this._currentEntityID, this.items, "EntityID", this._currentAction);
            if (this.currentMenuItem && this.currentMenuItem.ParentExpressMenuItemID !== this.currentParentMenuID) {
               if ((!this.searchValue || this.searchValue.length === 0) && !this.isExpressReport) {
                  this.fillMenu();
               } else {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
                  this.previousParentMenuID = this.currentMenuItem.ParentExpressMenuItemID;
                  this.previousParentMenuItem = this.getMenuItem(
                     // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
                     this.currentMenuItem.ParentExpressMenuItemID,
                     this.items,
                     "ExpressMenuItemID"
                  );
               }
            }
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
            this.currentMenuItem = null;
         }
      }
   }

   get currentEntityID(): number {
      return this._currentEntityID;
   }

   @Input() set currentAction(value: ExpressActions) {
      if (value !== this._currentAction) {
         this._currentAction = value;
         if (this._currentEntityID && this._currentAction && this.items && this.items.length > 0) {
            this.currentMenuItem = this.getMenuItem(this._currentEntityID, this.items, "EntityID", this._currentAction);
            if (this.currentMenuItem && this.currentMenuItem.ParentExpressMenuItemID !== this.currentParentMenuID) {
               if ((!this.searchValue || this.searchValue.length === 0) && !this.isExpressReport) {
                  this.fillMenu();
               } else {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
                  this.previousParentMenuID = this.currentMenuItem.ParentExpressMenuItemID;
                  this.previousParentMenuItem = this.getMenuItem(
                     // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
                     this.currentMenuItem.ParentExpressMenuItemID,
                     this.items,
                     "ExpressMenuItemID"
                  );
               }
            }
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
            this.currentMenuItem = null;
         }
      }
   }

   get currentAction(): ExpressActions {
      return this._currentAction;
   }

   @Input() set items(values: Array<ExpressMenuItemModel>) {
      this._items = values;
      this.buildMenuPaths();
      this.fillMenu();
   }

   get items(): Array<ExpressMenuItemModel> {
      return this._items;
   }

   @Input() useActionLinks: boolean;

   @Output() menuItemSelected = new EventEmitter<ExpressMenuItemModel>();

   buildURL;

   sideBarSubMenus: Array<SideBarMenuItemConfigurationModel>;

   get searchValue(): string {
      return this._searchValue;
   }

   set searchValue(val: string) {
      if (this._searchValue !== val) {
         this._searchValue = val;
         this.searchValueChange.next(this._searchValue);
      }
   }

   menuItemsHidden: boolean = true;

   showPaths: boolean = false;

   private subMenus = new Map<number, Array<ExpressMenuItemModel>>();

   private _items: Array<ExpressMenuItemModel>;

   private _currentEntityID: number;

   private _currentAction: ExpressActions;

   private currentMenuItem: ExpressMenuItemModel;

   private currentParentMenuID: number;

   private currentParentItem: ExpressMenuItemModel;

   private previousParentMenuID: number;

   private previousParentMenuItem: ExpressMenuItemModel;

   private unsubscribe = new Subject<void>();

   private _searchValue: string;

   private searchValueChange: EventEmitter<string> = new EventEmitter<string>();

   constructor(private actionRoutingService: ActionRoutingService, private renderer2: Renderer2) {
      this.buildURL = this.actionRoutingService.buildURL;

      this.sideBarSubMenus = new Array<SideBarMenuItemConfigurationModel>();
      this.sideBarSubMenus[0] = new SideBarMenuItemConfigurationModel();
      this.sideBarSubMenus[1] = new SideBarMenuItemConfigurationModel();
   }

   ngOnInit() {
      this.searchValueChange
         .pipe(
            debounceTime(500),
            distinctUntilChanged((emittedValue) => {
               return emittedValue === this._searchValue;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((searchText) => {
            this.searchMenu(searchText);
         });

      this.fillMenu();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   setCurrentItem(menuItem) {
      this.previousParentMenuID = menuItem.ParentExpressMenuItemID;
      this.previousParentMenuItem = this.getMenuItem(menuItem.ParentExpressMenuItemID, this.items, "ExpressMenuItemID");
      this.currentEntityID = menuItem.EntityID;
      this.menuItemSelected.emit(menuItem);
   }

   goBack() {
      const parentItem = this.getMenuItem(this.currentParentMenuID, this.items, "ExpressMenuItemID");
      let parentParentItem = null;
      if (parentItem) {
         if (this.sideBarSubMenus[0].IsCurrent) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
            this.sideBarSubMenus[1].MenuItems = this.subMenus.get(parentItem.ParentExpressMenuItemID);
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
            this.sideBarSubMenus[0].MenuItems = this.subMenus.get(parentItem.ParentExpressMenuItemID);
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel' is not assignable to t... Remove this comment to see the full error message
         parentParentItem = this.getMenuItem(parentItem.ParentExpressMenuItemID, this.items, "ExpressMenuItemID");
         if (!parentParentItem) {
            if (this.sideBarSubMenus[0].IsCurrent) {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
               this.sideBarSubMenus[1].ParentItem = null;
            } else {
               // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
               this.sideBarSubMenus[0].ParentItem = null;
            }
            // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
            this.currentParentItem = null;
         } else {
            if (this.sideBarSubMenus[0].IsCurrent) {
               this.sideBarSubMenus[1].ParentItem = parentParentItem;
            } else {
               this.sideBarSubMenus[0].ParentItem = parentParentItem;
            }
            this.currentParentItem = parentParentItem;
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
         this.currentParentMenuID = parentItem.ParentExpressMenuItemID;
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
      this.previousParentMenuID = parentItem ? parentItem.ParentExpressMenuItemID : null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
      this.previousParentMenuItem = parentParentItem;

      let currentMenuNativeElement;
      let previousMenuNativeElement;
      if (this.sideBarSubMenus[0].IsCurrent) {
         currentMenuNativeElement = this.sideBarSubMenu0.nativeElement;
         previousMenuNativeElement = this.sideBarSubMenu1.nativeElement;
         this.sideBarSubMenus[0].IsCurrent = false;
         this.sideBarSubMenus[1].IsCurrent = true;
      } else {
         currentMenuNativeElement = this.sideBarSubMenu1.nativeElement;
         previousMenuNativeElement = this.sideBarSubMenu0.nativeElement;
         this.sideBarSubMenus[1].IsCurrent = false;
         this.sideBarSubMenus[0].IsCurrent = true;
      }
      this.renderer2.setStyle(previousMenuNativeElement, "transition", "unset");
      this.renderer2.setStyle(previousMenuNativeElement, "left", "-120%");
      this.renderer2.setStyle(previousMenuNativeElement, "overflow-y", "hidden");

      this.renderer2.setStyle(currentMenuNativeElement, "left", "100%");

      setTimeout(() => {
         this.renderer2.setStyle(previousMenuNativeElement, "transition", "all 0.2s ease-in");
         this.renderer2.setStyle(previousMenuNativeElement, "left", "0");
         setTimeout(() => {
            this.renderer2.setStyle(previousMenuNativeElement, "overflow-y", "auto");
         }, 0.2 * 1000);
      });
   }

   clickParentItem(menuItem: ExpressMenuItemModel) {
      let currentMenuNativeElement;
      let nextMenuNativeElement;
      if (this.sideBarSubMenus[0].IsCurrent) {
         currentMenuNativeElement = this.sideBarSubMenu0.nativeElement;
         nextMenuNativeElement = this.sideBarSubMenu1.nativeElement;
         this.sideBarSubMenus[0].IsCurrent = false;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[1].MenuItems = this.subMenus.get(menuItem.ExpressMenuItemID);
         this.sideBarSubMenus[1].ParentItem = menuItem;
         this.sideBarSubMenus[1].IsCurrent = true;
      } else {
         currentMenuNativeElement = this.sideBarSubMenu1.nativeElement;
         nextMenuNativeElement = this.sideBarSubMenu0.nativeElement;
         this.sideBarSubMenus[1].IsCurrent = false;
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[0].MenuItems = this.subMenus.get(menuItem.ExpressMenuItemID);
         this.sideBarSubMenus[0].ParentItem = menuItem;
         this.sideBarSubMenus[0].IsCurrent = true;
      }
      this.currentParentItem = menuItem;
      this.currentParentMenuID = menuItem.ExpressMenuItemID;
      this.previousParentMenuID = menuItem.ExpressMenuItemID;
      this.previousParentMenuItem = menuItem;

      this.renderer2.setStyle(nextMenuNativeElement, "transition", "unset");
      this.renderer2.setStyle(nextMenuNativeElement, "left", "120%");

      this.renderer2.setStyle(currentMenuNativeElement, "left", "-120%");
      this.renderer2.setStyle(currentMenuNativeElement, "overflow-y", "hidden");

      setTimeout(() => {
         this.renderer2.setStyle(nextMenuNativeElement, "transition", "all 0.2s ease-in");
         this.renderer2.setStyle(nextMenuNativeElement, "left", "0");
         setTimeout(() => {
            this.renderer2.setStyle(currentMenuNativeElement, "overflow-y", "auto");
         }, 0.2 * 1000);
      });
   }

   private buildMenuPaths() {
      const path = [];
      this.items.forEach((item) => {
         this.buildMenuPathRecursive(item, path);
      });
   }

   private buildMenuPathRecursive(item: ExpressMenuItemModel, path: Array<string>) {
      if (!item.Children || item.Children.length === 0) {
         item.Information = path.join(" > ");
      } else {
         item.Children.forEach((child) => {
            this.buildMenuPathRecursive(child, path.concat(item.Label));
         });
      }
   }

   private getFilteredMenuItemsRecursive(
      searchText: string,
      currentMenuItems: Array<ExpressMenuItemModel>
   ): Array<ExpressMenuItemModel> {
      let menuItems = new Array<ExpressMenuItemModel>();
      if (!searchText || searchText.length === 0) {
         return menuItems;
      }
      currentMenuItems.forEach((menuItem) => {
         if (!menuItem.Children || menuItem.Children.length === 0) {
            const searchTextLower = searchText.toLowerCase();
            if (
               (menuItem.Information && menuItem.Information.toLowerCase().indexOf(searchTextLower) > -1) ||
               (menuItem.Label && menuItem.Label && menuItem.Label.toLowerCase().indexOf(searchTextLower) > -1) ||
               (menuItem.Keywords &&
                  menuItem.Keywords.some((keyword) => keyword.toLowerCase().indexOf(searchTextLower) > -1))
            ) {
               menuItem.ParentExpressMenuItemID = 0;
               menuItems.push(menuItem);
            }
         } else {
            menuItem.Children.forEach((childMenuItem) => {
               const results = this.getFilteredMenuItemsRecursive(searchText, [childMenuItem]);
               if (results && results.length > 0) {
                  menuItems = menuItems.concat(results);
               }
            });
         }
      });
      return menuItems;
   }

   private getMenuItem(
      id: number,
      items: Array<ExpressMenuItemModel>,
      propertyName: string,
      action?: ExpressActions
   ): ExpressMenuItemModel {
      const foundItems = items
         .map((item) => {
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ExpressActions | undefined' is n... Remove this comment to see the full error message
            const foundItem = this.getMenuItemRecursive(id, item, propertyName, action);
            if (foundItem) {
               return foundItem;
            }
         })
         .filter((i) => i);

      if (foundItems && foundItems.length > 0) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel | undefined' is not ass... Remove this comment to see the full error message
         return foundItems[0];
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
      return null;
   }

   private getMenuItemRecursive(
      id: number,
      item: ExpressMenuItemModel,
      propertyName: string,
      action: ExpressActions
      // @ts-ignore ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
   ): ExpressMenuItemModel {
      let found: boolean = false;
      if (item[propertyName] === id) {
         if (action) {
            if (item.ExpressActionID === action) {
               found = true;
            }
         } else {
            found = true;
         }
      }
      if (found) {
         return item;
      } else if (item.Children && item.Children.length > 0) {
         const foundItems = item.Children.map((child) => {
            const foundChild = this.getMenuItemRecursive(id, child, propertyName, action);
            if (foundChild) {
               return foundChild;
            }
         }).filter((i) => i);
         if (foundItems && foundItems.length > 0) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel | undefined' is not ass... Remove this comment to see the full error message
            return foundItems[0];
         }
      }
   }

   private buildItemDictionary(sideBarMenuItems: Array<ExpressMenuItemModel>) {
      const newSubMenu = Array<ExpressMenuItemModel>();
      let parentExpressMenuItemID: number;
      sideBarMenuItems.forEach((item) => {
         if (item.Children && item.Children.length > 0) {
            this.buildItemDictionary(item.Children);
         }
         newSubMenu.push(item);
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
         parentExpressMenuItemID = item.ParentExpressMenuItemID;
      });
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'parentExpressMenuItemID' is used before ... Remove this comment to see the full error message
      this.subMenus.set(parentExpressMenuItemID, newSubMenu);
   }

   private setCurrentMenu(parentMenuItemID: number, parentMenuItem: ExpressMenuItemModel) {
      // the root directory has an item id but parent menu item will be null so we need both
      if (this.sideBarSubMenus[0].IsCurrent) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[0].MenuItems = this.subMenus.get(parentMenuItemID);
         this.sideBarSubMenus[0].ParentItem = parentMenuItem;
      } else if (this.sideBarSubMenus[1].IsCurrent) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[1].MenuItems = this.subMenus.get(parentMenuItemID);
         this.sideBarSubMenus[1].ParentItem = parentMenuItem;
      } else if (parentMenuItemID != null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[0].MenuItems = this.subMenus.get(parentMenuItemID);
         this.sideBarSubMenus[0].IsCurrent = true;
         this.sideBarSubMenus[0].ParentItem = parentMenuItem;
      } else if (this.items != null && this.items.length > 0) {
         const firstItemParentID = this.items[0].ParentExpressMenuItemID;
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
         const firstItemParent = this.getMenuItem(firstItemParentID, this.items, "ExpressMenuItemID");
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[] | undefined' is not a... Remove this comment to see the full error message
         this.sideBarSubMenus[0].MenuItems = this.subMenus.get(firstItemParentID);
         this.sideBarSubMenus[0].IsCurrent = true;
         this.sideBarSubMenus[0].ParentItem = firstItemParent;
      }
   }

   private resetStyles() {
      if (this.sideBarSubMenu0) {
         this.renderer2.setStyle(this.sideBarSubMenu0.nativeElement, "left", "0");
         this.renderer2.setStyle(this.sideBarSubMenu0.nativeElement, "transition", "unset");
         this.renderer2.setStyle(this.sideBarSubMenu0.nativeElement, "overflow-y", "auto");
      }
      if (this.sideBarSubMenu1) {
         this.renderer2.setStyle(this.sideBarSubMenu1.nativeElement, "left", "100%");
         this.renderer2.setStyle(this.sideBarSubMenu1.nativeElement, "transition", "unset");
      }
   }

   private searchMenu(searchText: string) {
      searchText = searchText.trim();
      if (!searchText) {
         this.showPaths = false;
         this.navigateToPreviousParent();
      } else {
         this.showPaths = true;
         this.fillSearchResults(searchText);
      }
   }

   private fillMenu() {
      if (this.items && this.items.length > 0) {
         this.menuItemsHidden = false;
         this.showPaths = false;
         this.buildItemDictionary(this.items);
         if (this.currentEntityID) {
            if (this.isExpressReport) {
               this.currentEntityID = this.reportID;
            }
            const foundItem = this.getMenuItem(this.currentEntityID, this.items, "EntityID", this._currentAction);
            if (foundItem) {
               this.currentMenuItem = foundItem;
               // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
               this.currentParentMenuID = foundItem.ParentExpressMenuItemID;
               this.currentParentItem = this.getMenuItem(
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
                  this.currentMenuItem.ParentExpressMenuItemID,
                  this.items,
                  "ExpressMenuItemID"
               );
               this.previousParentMenuID = this.currentParentMenuID;
               this.previousParentMenuItem = this.currentParentItem;
            }
         }
         this.setCurrentMenu(this.currentParentMenuID, this.currentParentItem);
      } else {
         this.menuItemsHidden = true;
      }
   }

   private fillSearchResults(searchText: string) {
      let searchItems = [];
      const currentParentItem = this.previousParentMenuID ? this.previousParentMenuItem : this.currentParentItem;
      if (currentParentItem) {
         if (currentParentItem.Children && currentParentItem.Children.length > 0) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[]' is not assignable to... Remove this comment to see the full error message
            searchItems = currentParentItem.Children;
         } else {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel' is not assignable to t... Remove this comment to see the full error message
            searchItems = [currentParentItem];
         }
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressMenuItemModel[]' is not assignable to... Remove this comment to see the full error message
         searchItems = this.items;
      }
      const filteredMenuItems = this.getFilteredMenuItemsRecursive(searchText, cloneDeep(searchItems));
      if (filteredMenuItems && filteredMenuItems.length > 0) {
         this.menuItemsHidden = false;
         this.clearMenus();
         this.buildItemDictionary(filteredMenuItems);
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         this.setCurrentMenu(0, null);
      } else {
         this.menuItemsHidden = true;
      }
      if (currentParentItem) {
         if (this.sideBarSubMenus[0].IsCurrent) {
            this.sideBarSubMenus[0].ParentItem = currentParentItem;
            this.sideBarSubMenus[0].IsSearching = true;
         } else {
            this.sideBarSubMenus[1].ParentItem = currentParentItem;
            this.sideBarSubMenus[0].IsSearching = true;
         }
      }
   }

   private navigateToPreviousParent() {
      if (this.items && this.items.length > 0) {
         this.menuItemsHidden = false;
         this.clearMenus();
         this.buildItemDictionary(this.items);
         this.currentParentMenuID = this.previousParentMenuID;
         this.currentParentItem = this.previousParentMenuItem;
         this.setCurrentMenu(this.previousParentMenuID, this.previousParentMenuItem);
      } else {
         this.menuItemsHidden = true;
      }
   }

   private clearMenus() {
      this.sideBarSubMenus = new Array<SideBarMenuItemConfigurationModel>();
      this.sideBarSubMenus[0] = new SideBarMenuItemConfigurationModel();
      this.sideBarSubMenus[1] = new SideBarMenuItemConfigurationModel();
      this.subMenus = new Map<number, Array<ExpressMenuItemModel>>();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ExpressMenu... Remove this comment to see the full error message
      this.currentParentItem = null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      this.currentParentMenuID = null;

      this.resetStyles();
   }
}
