////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum FilterOperations {
   LessThan = 0,
   LessThanOrNull = 1,
   LessThanOrEqualTo = 2,
   LessThanOrEqualToOrNull = 3,
   GreaterThan = 4,
   GreaterThanOrNull = 5,
   GreaterThanOrEqualTo = 6,
   GreaterThanOrEqualToOrNull = 7,
   NotEqualTo = 8,
   EqualTo = 9,
   In = 10,
   NotIn = 11,
   Between = 12,
   Contains = 13,
   StartsWith = 14,
   EndsWith = 15,
   HasValue = 16,
}
