<div
   #inputWithOptionsWrapper
   class="input-with-options-wrapper"
   [class.open]="showOverlayPanel"
>
   <lcs-text-box
      class="text-box"
      [name]="name"
      [path]="path"
      [displayName]="displayName"
      [disabled]="disabled"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="disabled"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      (focusEvent)="selectAllText($event)"
      [placeholder]="'Type or select an entry'"
   ></lcs-text-box>
   <lcs-icon
      [hidden]="(control?.invalid && control?.dirty) || disabled"
      [icon]="showOverlayPanel ? 'expand_less' : 'expand_more'"
      (click)="onIconClicked()"
      (mousedown)="onIconMousedown()"
   ></lcs-icon>
   <i class="material-icons validation-error-icon">error_outline</i>
</div>
<lcs-selector-overlay-panel
   [control]="control"
   [(show)]="showOverlayPanel"
   [itemSet]="itemSet"
   [parentElement]="inputWithOptionsWrapper"
   [selectedItemIndex]="selectedItemIndex"
   [disabled]="disabled"
   [toggleOnClick]="false"
   [hideOnBlur]="hideOverlayOnBlur"
   (selected)="onItemSelected($event)"
   [alignToRight]="alignOverlayPanelToRight"
   [staticOverlay]="staticOverlay"
   [displayName]="displayName"
></lcs-selector-overlay-panel>