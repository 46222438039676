import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { FORM_CONTROL_METADATA_DISABLEDITEMS } from "@lcs/forms/form-control-metadata/form-control-metadata-disabled-items.interface";
import { FORM_CONTROL_METADATA_ENTITY_ID, FormControlMetadataEntityID } from "@lcs/forms/form-control-metadata/form-control-metadata-entity-id.interface";
import { FORM_CONTROL_METADATA_SELECTORFILTERS } from "@lcs/forms/form-control-metadata/form-control-metadata-selector-filters.interface";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { SingleLineMultiSelectComponent } from "@lcs/single-line-multi-select/single-line-multi-select.component";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { ApiServiceHelpers } from "projects/libraries/owa-gateway-sdk/src/lib/core/api-service.helpers";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { forkJoin, Subject, takeUntil } from "rxjs";

/* eslint-disable brace-style */
@Component({
   selector: "lcs-entity-multi-selector",
   templateUrl: "entity-multi-selector.component.html",
   providers: [
      {
         provide: FORM_CONTROL_METADATA_ENTITY_ID,
         useExisting: EntityMultiSelectorComponent,
      },
      {
         provide: FORM_CONTROL_METADATA_SELECTORFILTERS,
         useExisting: EntityMultiSelectorComponent,
      },
      {
         provide: FORM_CONTROL_METADATA_DISABLEDITEMS,
         useExisting: EntityMultiSelectorComponent,
      },
   ],
})
export class EntityMultiSelectorComponent
   extends ValueAccessorBase<any>
   implements OnChanges, OnDestroy, FormControlMetadataEntityID
{
   @Input() addBlankItem: boolean;

   @Input() additionalDataFields: Array<string>;

   @Input() allValue: string;

   @Input() endpointOverride: string;

   @Input() entityID: number;

   @Input() disabled: boolean;

   @Input() parentEntityType: EntityType;

   @Input() filters: Array<FilterOption>;

   @Input() skipGlobalFilters: boolean = false;

   @Input() hideLabel: boolean;

   @Input() inlineLabel: boolean;

   @Input() label: string;

   @Input() orderingOptions: Array<string>;

   @Input() resource: string;

   @Input() entityType: EntityType;

   @Input() staticValues: Array<SelectorItemModel>;

   @Input() embeds: Array<string>;

   @Input() singleSelectStyleOverride = {};

   @Input() NoOfSelectedItemsToDisplay = 1;

   disabledItems: Array<any>;

   @Output() panelClose = new EventEmitter<void>();

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   allSelected: boolean;

   valueSource: ValueSourceModel;

   @ViewChild("selector") selector: SingleLineMultiSelectComponent;

   private unsubscribe = new Subject<void>();

   constructor(
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private entityRequestService: EntityRequestService,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, ngControl);

      this.registerOnValueWritten((value: any) => {
         if (this.valueSource) {
            if (value) {
               this.valueSource.SelectedValues = Array.isArray(value) ? value : [value];
            } else {
               this.valueSource.SelectedValues = [];
            }
         }
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.entityType && changes.entityType.currentValue !== changes.entityType.previousValue) {
         this.initialize();
      }
      if (
         (changes.entityID && changes.entityID.currentValue !== changes.entityID.previousValue) ||
         (changes.filters && changes.filters.currentValue !== changes.filters.previousValue)
      ) {
         if (this.valueSource) {
            this.initialize();
         }
      }
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   onSelectionChange(selectionChange: SelectionChangeModel) {
      this.selectionChange.emit(selectionChange);
   }

   onPanelClose() {
      this.panelClose.emit();
   }

   onChange(value: any) {
      this.value = value;
   }

   setEntityID(entityID: number) {
      this.entityID = entityID;
   }

   setFilters(filters: Array<FilterOption>) {
      this.filters = filters;
      this.initialize();
   }

   setDisabledSelectorItems(disabledItems: Array<any>) {
      this.disabledItems = disabledItems;
   }

   onAllSelected(value: boolean) {
      this.allSelected = value;
   }

   getSelectedItems() {
      return this.selector.items.filter((f) => f.isChecked);
   }

   private initialize() {
      if (this.entityType) {
         let requestInfoEntityType = this.entityType;
         if (this.parentEntityType && this.entityID) {
            requestInfoEntityType = this.parentEntityType;
         }
         forkJoin([
            this.entityRequestEndpointService.getEndpointInformation(requestInfoEntityType),
            this.entityViewInformationService.getViewInformation(this.entityType),
            this.entitySearchConfigurationService.getSearchConfiguration(this.entityType),
         ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([requestInfo, viewInfo, searchConfig]) => {
               const valueSource = new ValueSourceModel();
               valueSource.Type = ValueSourceTypes.Entity;
               valueSource.EntityType = this.entityType;

               if (this.endpointOverride) {
                  valueSource.Endpoint = this.endpointOverride;
               } else {
                  valueSource.Endpoint = this.entityRequestService.buildEndpoint(
                     requestInfo,
                     this.resource,
                     this.entityID
                  );
                  if (!this.resource && !this.entityID) {
                     valueSource.EndpointIsSearch = requestInfo.IsSearchEnabled;
                  }
                  if (this.parentEntityType && this.parentEntityType !== EntityType.Unassociated && this.resource) {
                     if (this.entityID) {
                        valueSource.Endpoint = this.entityRequestService.buildEndpoint(
                           requestInfo,
                           this.resource,
                           this.entityID
                        );
                     } else {
                        valueSource.Endpoint = this.entityRequestService.buildEndpoint(requestInfo);
                     }
                  }
               }
               // TODO: Extract fields from templates
               valueSource.Embeds = ApiServiceHelpers.extractEmbeds([
                  viewInfo.EntityPrimaryKeyPropertyPath,
                  viewInfo.EntityDisplayTemplate,
                  viewInfo.AdditionalInformationDisplayTemplate,
               ]);

               if (this.embeds && this.embeds.length > 0) {
                  valueSource.Embeds.push(...this.embeds);
               }

               valueSource.Label = viewInfo.CollectionLabel;
               if (this.label) {
                  valueSource.Label = this.label;
               }

               valueSource.EntityValueSourcePath = viewInfo.EntityPrimaryKeyPropertyPath;
               valueSource.DisplayValueSourcePath = viewInfo.EntityListItemTemplate;
               valueSource.AdditionalInfoSourcePath = viewInfo.AdditionalInformationDisplayTemplate;
               valueSource.AdditionalDataFields = this.additionalDataFields;
               valueSource.SearchFields = searchConfig.DefaultSearchFields;

               if (this.staticValues) {
                  valueSource.StaticValues = this.staticValues;
               } else {
                  valueSource.StaticValues = viewInfo.AdditionalSelectorItems;
               }
               if (this.addBlankItem) {
                  valueSource.AddBlankValue();
               }

               if (this.value) {
                  valueSource.SelectedValues = Array.isArray(this.value) ? this.value : [this.value];
               }

               valueSource.Filters = new Array<FilterOption>();
               if (
                  (this.filters && this.filters.length > 0) ||
                  (searchConfig.GlobalFilters && searchConfig.GlobalFilters.length > 0)
               ) {
                  valueSource.Filters = FilterOption.Merge(
                     // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'FilterOption[] | null' is not as... Remove this comment to see the full error message
                     searchConfig.GlobalFilters && !this.skipGlobalFilters
                        ? searchConfig.GlobalFilters.map(FilterOption.FromExpressControlDataSourceFilterModel)
                        : null,
                     this.filters
                  );
               }

               if (this.orderingOptions) {
                  valueSource.OrderingOptions = this.orderingOptions;
               } else if (searchConfig.DefaultOrderingOption) {
                  valueSource.OrderingOptions = [searchConfig.DefaultOrderingOption.Name];
               }

               if (this.allValue != null) {
                  valueSource.AllValue = this.allValue;
               }

               this.valueSource = valueSource;
            });
      }
   }
}
