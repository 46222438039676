import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { RadioButtonModule } from "@lcs/radio-button/radio-button.module";

import { ChangeEmailAddressModule } from "../change-emailaddress/change-emailaddress.module";
import { MergeUsersComponent } from "./merge-users.component";

@NgModule({
   declarations: [MergeUsersComponent],
   imports: [
      CommonModule,
      FormsModule,
      RadioButtonModule,
      ChangeEmailAddressModule,
      LcsProgressButtonModule,
      ValidationModule,
      TextBoxModule,
   ],
})
export class MergeUsersModule {}
