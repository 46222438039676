import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TextAreaModule } from "@lcs/inputs/text-area/text-area.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { EmailComponent } from "./email.component";

@NgModule({
   imports: [CommonModule, FormsModule, LcsProgressButtonModule, TextBoxModule, TextAreaModule, ValidationModule],
   declarations: [EmailComponent],
   exports: [EmailComponent],
})
export class EmailModule {}
