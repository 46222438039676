import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { EntityInformationService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-information.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ChargeTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge-type.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-credits-to-include-report-parameter",
   templateUrl: "credits-to-include-report-parameter.component.html",
})
export class CreditsToIncludeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @ViewChild("multiselectwrapper") el: ElementRef;

   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   valueSources: Array<ValueSourceModel>;

   selectedValues: Array<any>;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private apiService: ApiService,
      private entityInformationService: EntityInformationService,
      private reportParametersService: ReportParametersService
   ) {}

   ngOnInit() {
      this.initializeReportParameterValue();
      this.initializeValueSource();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   selectionChanged(selectionChangeModel: SelectionChangeModel) {
      const values = selectionChangeModel.checkedItems;
      const selectAll = selectionChangeModel.selectAll;
      this.reportParameterValueModel.value = "";
      if (selectAll) {
         this.reportParameterValueModel.value = "All";
      }
      if (values.length > 0) {
         this.reportParameterValueModel.value = "(" + values.map((f) => f.value).join(",") + ")";
      }
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeValueSource() {
      const selectedValueSources = new Array<ValueSourceModel>();
      const valueSourceModel = new ValueSourceModel();
      if (this.parameter.ReportParameterValueSource.StaticValues.length > 0) {
         valueSourceModel.Type = ValueSourceTypes.Static;
         for (const value of this.parameter.ReportParameterValueSource.StaticValues) {
            const item = new SelectorItemModel();
            item.displayValue = value.DisplayValue;
            item.value = value.Value;
            item.isChecked = false;
            valueSourceModel.StaticValues.push(item);
         }
      }
      for (const entityValueSource of this.parameter.ReportParameterValueSource.EntitySources) {
         const entityInformation = this.entityInformationService.getEntityTypeInformation(
            EntityType[entityValueSource.ModelSource]
         );
         if (!entityInformation) {
            return;
         }
         const orderingOptions = new Array<string>();
         orderingOptions.push("Name");
         const apiURL = entityInformation.ApiUrlString;
         this.apiService
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            .getCollection(apiURL, null, [], orderingOptions)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((results) => {
               this.processCollection(results, valueSourceModel);
               this.setValueSources(selectedValueSources, valueSourceModel);
            });
      }
   }

   private processCollection(results: Array<ChargeTypeModel>, valueSourceModel: ValueSourceModel) {
      if (results) {
         for (const chargeType of results) {
            const item = new SelectorItemModel();
            item.displayValue = chargeType.Name + " - " + chargeType.Description;
            item.value = chargeType.ChargeTypeID.toString();
            item.isChecked = false;
            valueSourceModel.StaticValues.push(item);
         }
      }
   }

   private setValueSources(selectedValueSources, valueSourceModel) {
      valueSourceModel.AllValue = "all";
      let selectedValues = new Array<any>();
      if (this.parameter.DefaultValue) {
         selectedValues = this.parameter.DefaultValue.split(",").map((v) => v.replace(" ", ""));
      }
      this.selectedValues = selectedValues;
      selectedValueSources.push(valueSourceModel);
      this.valueSources = selectedValueSources;
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
   }
}
