import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";

import { ValueSourceTypes } from "../enumerations/value-source-types.enum";
import { FilterField } from "./generated/filter-field.model";

export class ValueSourceModel {
   AdditionalDataFields: Array<string>;
   AdditionalInfoSourcePath: string;
   AdditionalEntityItems: Array<SelectorItemModel>;
   AllValue: string;
   AllValueLabel: string;
   AllowsMultipleValues: boolean;
   AllowsSelectAll: boolean;
   AttachmentValueSourcePath: string;
   DefaultToFirstValue: boolean;
   DefaultToFirstValueOnReload: boolean;
   DefaultValue: any;
   DisplayValueSourcePath: string;
   EmbeddedSet: any;
   EmbeddedSetValueSourceDelimiter: string = "|";
   EmbeddedSetValueSourcePath: string;
   Embeds: Array<string>;
   Endpoint: string;
   EndpointIsSearch: boolean;
   EntityType: EntityType;
   EntityValueSourcePath: string;
   EnumerationName: string;
   Fields: Array<string>;
   FileUploadResource: string;
   Filters: Array<FilterOption> | null;
   IncludePropertyPath: string;
   IsQuickSearchEnabled: boolean;
   IsRequiredValueSourcePath: string;
   IsSubItem: boolean;
   ItemEndpoint: string;
   Label: string;
   MaximumItemsToRetrieve: number = 10;
   MultipleValueDelimiter: string;
   EagerLoadItems: boolean;
   OrderingOptions: Array<string>;
   QuickSearchEndpoint: string;
   SelectedDisplayValueOverride: string;
   SearchFields: Array<FilterField>;
   SelectedValues: Array<any>;
   StaticValues: Array<SelectorItemModel>;
   Type: ValueSourceTypes;
   ValuesToExclude: Array<string | number | boolean | Date>;
   ValuesToInclude: Array<string | number | boolean | Date>;
   HasUserSelectedItems: boolean;

   constructor() {
      this.AdditionalEntityItems = new Array<SelectorItemModel>();
      this.StaticValues = new Array<SelectorItemModel>();
      this.SelectedValues = new Array<string>();
      this.ValuesToExclude = new Array<string>();
      this.ValuesToInclude = new Array<string>();
      this.OrderingOptions = new Array<string>();
      this.SearchFields = new Array<FilterField>();
      this.Filters = new Array<FilterOption>();
   }

   AddBlankValue() {
      if (this.StaticValues.filter((item) => item.value === "").length === 0) {
         this.StaticValues.unshift(new SelectorItemModel("", ""));
      }
   }
}
