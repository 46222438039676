import { formatDate } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { InspectionReportViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/inspection-report-view.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { DateHelperService } from "projects/libraries/owa-gateway-sdk/src/lib/services/date-helper.service";
import { InspectionReportsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/inspection-reports.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { FilterValueType } from "../../../../libraries/lcs/src/lib/filters/filter-value.types";
import { OWASessionService } from "../session/owa-session.service";

@Component({
   selector: "owa-inspection-report-list",
   templateUrl: "./inspection-report-list.component.html",
   styleUrls: ["./inspection-report-list.component.css"],
   providers: [DataTableFiltersService],
})
export class InspectionReportListComponent implements OnInit {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;
   public unsubscribe: Subject<void> = new Subject<void>();
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public inspections = new BehaviorSubject<Array<InspectionReportViewModel>>(null);
   public initialLoadComplete: boolean = false;
   public pageSize: number = 10;
   public rowHeight: number = 35;
   public pageNumber: number = 0;
   public threshold: string = "30%";

   public columns: string[] = [
      "InspectionDate",
      "Scheduled",
      "Type",
      "Description",
      "Property",
      "Unit",
      "Completed",
      "Report",
   ];

   public showFilters: boolean;
   public appliedSideBarFilters: FilterOption[];
   public inspectionFilters: DataTableFilterModel[];
   public filterOperations: FilterOperationModel[];
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public results = new BehaviorSubject<number>(null);

   isLoading = new Observable<boolean>();
   isDataLoading: BehaviorSubject<boolean>;
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   totalResults = new BehaviorSubject<number>(null);
   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);

   private scrollPageSize: number = 10;

   private toDate = DateHelperService.getEndofDay();
   private displayToDate = formatDate(new Date(), "MM/dd/yy", "en-US");
   private fromDate = formatDate(new Date().setFullYear(new Date().getFullYear() - 1), "MM/dd/yy", "en-US");

   constructor(
      private inspectionReportService: InspectionReportsService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.inspectionFilters = this.getFilters();
      this.setDefaultDateFilter();
   }

   setDefaultDateFilter() {
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const dateFilter = this.inspectionFilters.find(
         (f) => f.FilterOption.FilterName === "InspectionDate"
      ).FilterOption;
      dateFilter.Values = [this.fromDate, this.toDate];
      dateFilter.DisplayValue = this.fromDate + " - " + this.displayToDate;
      this.appliedSideBarFilters.push(
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         this.inspectionFilters.find((f) => f.FilterOption.FilterName === "InspectionDate").FilterOption
      );
   }

   ngOnInit() {}
   public getInspectionReports(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }
      const inspectionResult = this.inspectionReportService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber,
         null
      );
      inspectionResult
         .pipe(
            map((result) => {
               if (result.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(result.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const inspectionList = result.body;
               return inspectionList;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (res) => {
               let newInspectionData = this.inspections && this.inspections.value ? this.inspections.value : [];
               if (this._isInfiniteScrollLoading.value) {
                  newInspectionData = newInspectionData.concat(res);
               } else {
                  newInspectionData = res;
               }
               this.inspections.next(newInspectionData);
               this.results.next(this.inspections.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
   }

   onInfiniteScroll($event): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      const data = this.inspections.value ? this.inspections.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || data.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getInspectionReports(this.appliedSideBarFilters, true, refreshData);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.inspectionFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getInspectionReports(this.appliedSideBarFilters);
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getInspectionReports(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const historyDateFilter = this.getDataTableFilterModel(
         "InspectionDate",
         "Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between]
      );
      const filterList = [historyDateFilter];
      const ownershipsFiltervalueSourceModel = new ValueSourceModel();
      ownershipsFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      ownershipsFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      ownershipsFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "All Ownerships")
      );
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         ownershipsFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });

      const ownershipFilter = this.getDataTableFilterModel(
         "PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         ownershipsFiltervalueSourceModel
      );

      filterList.push(ownershipFilter);
      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.inspectionFilters = this.resetFilter(this.inspectionFilters, option);
      this.resetPaging();
      this.getInspectionReports(this.appliedSideBarFilters);
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (dataType === ExpressDataTypes.Date) {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.Between, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(
      allFilters: DataTableFilterModel[],
      filterToReset: FilterOption,
      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterValue... Remove this comment to see the full error message
      defaultValue: FilterValueType[] = null
   ) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = [filter.ValueSource.DefaultValue];
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
