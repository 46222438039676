export class NumberRangeModel {
   min: number;
   max: number;
   constructor(minNum?: number, MaxNum?: number) {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      this.min = minNum || null;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      this.max = MaxNum || null;
   }
}
export function isNumberRangeModel(obj: any): obj is NumberRangeModel {
   return "min" in obj && "max" in obj;
}
