import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { Params } from "@angular/router";
import { LinkMode } from "@lcs/link/link-mode.enum";
import { ExpressActionViewModes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-action-view-modes.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";

import { ActionBarActionModel } from "../action-bar/action-bar-action.model";
import { ActionInformationService } from "../action-context/action-information.service";
import { RoutingService } from "../action-router/routing.service";
import { ActionTriggerPayloadModel } from "../action-trigger/action-trigger-payload.model";
import { ActionTriggerModel } from "../action-trigger/action-trigger.model";
import { ActionTriggerService } from "../action-trigger/action-trigger.service";
import { DatatableQuickActionModel } from "../datatable/datatable-quick-action.model";

@Component({
   selector: "lcs-action-link",
   templateUrl: "action-link.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLinkComponent {
   @Input() set actionBarAction(value: ActionBarActionModel) {
      this._actionBarAction = value;
      this._actionTrigger = this.actionTriggerService.buildActionTriggerFromActionBarAction(this._actionBarAction);
      this.setViewMode(this._actionBarAction.ExpressActionID, this._actionTrigger.Action, this._actionTrigger.ViewMode);
   }

   @Input() set actionTrigger(value: ActionTriggerModel) {
      this._actionTrigger = value;
      if (this._actionTrigger) {
         this.setViewMode(
            this._actionTrigger.ExpressActionID,
            this._actionTrigger.Action,
            this._actionTrigger.ViewMode
         );
      }
   }

   @Input() anchorTemplate: TemplateRef<any>;

   @Input() contentTemplate: TemplateRef<any>;

   @Input() cssClasses: Array<string>;

   @Input() datatableQuickAction: DatatableQuickActionModel;

   @Input() disabled: boolean;

   @Input() set entityID(value: number) {
      this._entityID = value;

      const payloads = new Array<ActionTriggerPayloadModel>();
      const payload = new ActionTriggerPayloadModel();
      payload.PayloadField = ExpressPayloadFields.ID;
      payload.Value = this._entityID;
      payloads.push(payload);
      this._payloads = payloads;

      if (this._expressAction) {
         this._actionTrigger = this.actionTriggerService.buildActionTriggerFromAction(
            this._expressAction,
            this._payloads
         );
         if (this.defaultViewMode === ExpressActionViewModes.FullPage) {
            this.buildRouterLink();
         }
      }
   }

   @Input() set expressAction(value: ExpressActions) {
      this._expressAction = value;
      this._actionTrigger = this.actionTriggerService.buildActionTriggerFromAction(this._expressAction, this._payloads);
      this.setViewMode(this._expressAction);
   }

   @Input() linkMode: LinkMode = LinkMode.Link;

   @Input() set payloads(value: Array<ActionTriggerPayloadModel>) {
      this._payloads = value;
      this._actionTrigger = this.actionTriggerService.buildActionTriggerFromAction(this._expressAction, this._payloads);
   }

   @Input() target: string;

   @Input() text: string;

   @Input() title: string;

   @Input() triggerLinkClickedForFullPage: boolean = false;

   @Output() callbackValue = new EventEmitter<any>();

   @Output() linkClicked: EventEmitter<void> = new EventEmitter<void>();

   routerLink: Array<string> | null;

   queryParams: Params;

   private defaultViewMode: ExpressActionViewModes;

   private expressActionModel: ExpressActionModel;

   private _actionBarAction: ActionBarActionModel;

   private _actionTrigger: ActionTriggerModel;

   private _entityID: number;

   private _expressAction: ExpressActions;

   private _payloads: Array<ActionTriggerPayloadModel>;

   constructor(
      private actionTriggerService: ActionTriggerService,
      private actionInformationService: ActionInformationService,
      private routingService: RoutingService
   ) {}

   triggerAction() {
      if (this.defaultViewMode === ExpressActionViewModes.FullPage || this.disabled) {
         if (this.triggerLinkClickedForFullPage && !this.disabled) {
            this.linkClicked.next();
         }
         return;
      }

      if (
         this.expressActionModel &&
         (this.expressActionModel.DefaultViewMode === ExpressActionViewModes.Dialog ||
            this.expressActionModel.DefaultViewMode === ExpressActionViewModes.Slideup)
      ) {
         this._actionTrigger.ValueCallBack = (callbackValue: any) => {
            this.callbackValue.next(callbackValue);
         };
      }

      if (this.datatableQuickAction) {
         this.actionTriggerService.triggerDataTableAction(this.datatableQuickAction);
      } else if (this._actionTrigger) {
         this.actionTriggerService.triggerActionWithModel(this._actionTrigger);
      }
      this.linkClicked.next();
   }

   private buildRouterLink() {
      this._actionTrigger.Action = this.expressActionModel;
      this.routerLink = this.routingService.resolveRoute(this._actionTrigger) as Array<string>;
      this.routingService.resolveTriggerPayloadValues(this._actionTrigger);
      this.queryParams = this.routingService.resolveQueryParams(this._actionTrigger);
   }

   private setViewMode(
      expressActionID: number | null,
      action: ExpressActionModel | null = null,
      viewMode: ExpressActionViewModes | null = null
   ) {
      if (viewMode) {
         this.defaultViewMode = viewMode;
      } else {
         if (expressActionID === null) {
            throw new Error("Unhandled undefined action ID");
         }

         const expressActionModel =
            action ||
            (this.actionInformationService.getExpressActionInformationForExpressAction(expressActionID) ?? null);
         if (!expressActionModel) {
            throw new Error("Unable to determine action");
         }
         this.expressActionModel = expressActionModel;
         this.defaultViewMode = this.expressActionModel.DefaultViewMode;
      }

      if (this.defaultViewMode === ExpressActionViewModes.FullPage) {
         this.buildRouterLink();
      } else {
         this.routerLink = null;
      }
   }
}
