import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";

export class ServiceIssueListItemModel extends ServiceManagerIssueModel {
   StatusName: string;
   PropertyName: string;

   constructor(issueModel?: ServiceManagerIssueModel) {
      super();
      if (issueModel) {
         this.CopyFromServiceIssueModel(issueModel);
      }
   }

   CopyFromServiceIssueModel(issueModel: ServiceManagerIssueModel) {
      for (const prop of Object.keys(issueModel)) {
         this[prop] = issueModel[prop];
      }
      if (this.Status !== undefined && this.Status != null) {
         this.StatusName = this.Status.Name;
      }
      if (this.ServiceManagerIssueLinkedProperties !== undefined && this.ServiceManagerIssueLinkedProperties != null) {
         this.PropertyName = Array.prototype.map
            .call(this.ServiceManagerIssueLinkedProperties, function(item) {
               return item.Property.Name;
            })
            .join(",");
      }
   }
}
