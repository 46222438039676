import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { SignableDocumentsSettings } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-documents-settings.model";
import { SignableDocumentPacketSummariesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packet-summaries.service";
import { SignableDocumentsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-documents.service";
import { forkJoin, map, Observable, Subject, switchMap, takeUntil } from "rxjs";

declare var jquery: any;
declare var $: any;

@Component({
   selector: "owa-signable-documents-view",
   templateUrl: "./signable-documents-view.component.html",
   styleUrls: ["./signable-documents-view.component.css"],
})
export class SignableDocumentsViewComponent implements OnInit, OnDestroy {
   public signableDocumentsSettings = new SignableDocumentsSettings();
   private defaultSmallMediaScreen = 640;
   private signableDocumentPacketId: number;
   private _signableDocumentPacket = new SignableDocumentPacketSummaryModel();
   private unsubscribe = new Subject<void>();

   constructor(
      private activatedRoute: ActivatedRoute,
      private signableDocumentPacketSummariesService: SignableDocumentPacketSummariesService,
      private errorMessageService: ErrorMessageService,
      private signableDocumentsService: SignableDocumentsService,
      private router: Router
   ) {}

   ngOnInit() {
      this.activatedRoute.paramMap
         .pipe(
            switchMap((paramMap) => {
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
               this.signableDocumentPacketId = parseInt(paramMap.get("id"));
               return forkJoin(this.getSignableDocumentPacketSummary(), this.getSignableDocumentSettings());
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            ([signableDocumentPacketSummary, signableDocumentSettings]: [
               SignableDocumentPacketSummaryModel,
               SignableDocumentsSettings
            ]) => {
               if (signableDocumentPacketSummary) {
                  this._signableDocumentPacket = signableDocumentPacketSummary;
               }

               if (signableDocumentSettings) {
                  this.signableDocumentsSettings = signableDocumentSettings;
               }

               this.initializeSignableDocumentPacketView();
            },
            (_err) => {
               this.errorMessageService.triggerHttpErrorMessage(_err);
               this.router.navigate(["/signabledocuments"]);
            }
         );
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   getSignableDocumentPacketSummary(): Observable<SignableDocumentPacketSummaryModel> {
      const embedOptions = [
         "SignableDocuments",
         "SignableDocuments.SignatureBlockGroups",
         "SignableDocuments.SignatureBlocks",
         "SignableDocuments.Webusers",
         "SignableDocuments.SignableDocumentFields",
         "SignableDocuments.CurrentFile.DownloadURL",
      ];
      return this.signableDocumentPacketSummariesService
         .get(this.signableDocumentPacketId, embedOptions)
         .pipe(map((response) => response));
   }

   getSignableDocumentSettings(): Observable<SignableDocumentsSettings> {
      return this.signableDocumentsService.getSignableDocumentsSettings().pipe(map((response) => response));
   }

   initializeSignableDocumentPacketView() {
      $(".PacketBanner").remove();
      let _iOS = false;
      let _Edge = false;
      let _chrome = false;
      let _safari = false;
      let _firefox = false;

      const ua = window.navigator.userAgent;
      _iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      _Edge = !!ua.match(/Edge/i);
      _chrome = !!ua.match(/Chrome/);
      _firefox = !!ua.match(/Firefox/);
      if (!_chrome) {
         _safari = !!ua.match(/Safari/);
      }
      const options = {
         PdfViewerOverlayID: "pdfViewerOverlay",
         PdfViewerID: "pdfViewer",
         PdfLoadErrorMessageID: "PdfLoadErrorMessage",
         Packet: this._signableDocumentPacket,
         Mode: "View",
         LoadPDFUrl: "@fileControllerUrl",
         MergeDocumentPacketsURL: this.signableDocumentsSettings.MergeDocumentPacketsURL,
         DefaultSmallMediaScreen: this.defaultSmallMediaScreen,
         iOS: _iOS,
         Edge: _Edge,
         safari: _safari,
         firefox: _firefox,
      };

      $("#ReviewDocumentContainer").PacketBanner("initialize", options);
      $(".PacketBanner").removeClass("show-for-large");
      $(".PacketBanner").addClass("show-for-medium");
      if (_iOS) {
         $(".PacketBanner").addClass("displayNone");
      }
      $("#pdfViewer").bind("mouseleave", function () {
         $("#pdfViewerOverlay").css("pointerEvents", "auto");
      });
   }
}
