import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { PhoneNumberValidationServiceBase } from "@lcs/inputs/phone-number/phone-number-validation-service.base";

import { PhoneNumberValidator } from "./validators/phone-number.validator";

@Injectable()
export class PhoneNumberValidationService implements PhoneNumberValidationServiceBase {
   constructor() {}

   getPhoneNumberValidator(): ValidatorFn {
      return PhoneNumberValidator.validatePhoneNumber();
   }
}
