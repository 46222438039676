import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { InformationSplashScreenModule } from "../information-splash-screen/information-splash-screen.module";
import { SidebarLayoutModule } from "../sidebar-layout/sidebar-layout.module";
import { PdfViewerComponent } from "./pdf-viewer.component";

@NgModule({
   imports: [CommonModule, SidebarLayoutModule, InformationSplashScreenModule, FormsModule],
   declarations: [PdfViewerComponent],
   exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
