import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { ActionTriggerPayloadModel } from "@lcs/action-trigger/action-trigger-payload.model";
import { ReceivesPayload } from "@lcs/component-interfaces/receives-payload.interface";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";

import { MultiPdfViewerModel } from "./models/multi-pdf-viewer.model";

@Component({
   selector: "owa-multi-pdf-viewer",
   templateUrl: "multi-pdf-viewer.component.html",
})
export class MultiPdfViewerComponent implements OnInit, ReceivesPayload {
   @Input() set pdfs(pdfs: Array<MultiPdfViewerModel>) {
      this._pdfs = pdfs;
      if (pdfs) {
         this._pdfs.forEach((pdf: MultiPdfViewerModel) => {
            if (pdf.UniqueIdentifier === undefined) {
               pdf.UniqueIdentifier = this.uniqueIDService.getUniqueID();
            }
         });
         if (this.initialized && this.pdfs.length > 0) {
            this.showPdf(this.pdfs[0].UniqueIdentifier);
         }
      }
   }
   get pdfs(): Array<MultiPdfViewerModel> {
      return this._pdfs;
   }

   @Input() rightTemplate: TemplateRef<any>;

   @Input() errorHeader: string;

   @Input() errorInformation: string;

   @Input() errorIcon: string;

   @Input() errorContentTemplate: TemplateRef<any>;

   payloads: ActionTriggerPayloadModel[];

   selectedPdf: MultiPdfViewerModel;

   selectedPdfs: Array<MultiPdfViewerModel>;

   private _pdfs: Array<MultiPdfViewerModel>;

   private initialized: boolean;

   constructor(private uniqueIDService: UniqueIDService) {
      this.selectedPdfs = new Array<MultiPdfViewerModel>();
   }

   ngOnInit() {
      if (this.payloads) {
         // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
         const valuePayload = this.payloads.find((p) => p.PayloadField === ExpressPayloadFields.Value).Value;

         if (valuePayload) {
            this.pdfs = valuePayload;
         }
      }

      this.showPdf(this.pdfs[0].UniqueIdentifier);

      this.initialized = true;
   }

   showPdf(uniqueIdentifier: number) {
      const pdfSelected = this.pdfs.find((p) => p.UniqueIdentifier === uniqueIdentifier);
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      let selectedPdf = this.selectedPdfs.find((m) => m.UniqueIdentifier === pdfSelected.UniqueIdentifier);
      if (!selectedPdf) {
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'MultiPdfViewerModel | undefined'... Remove this comment to see the full error message
         this.selectedPdfs.push(pdfSelected);
         selectedPdf = pdfSelected;
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'MultiPdfViewerModel | undefined' is not assi... Remove this comment to see the full error message
      this.selectedPdf = selectedPdf;
   }
}
