import {
   AfterViewInit,
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   Input,
   isDevMode,
   OnDestroy,
} from "@angular/core";
import { AbstractControl, ControlContainer, NgControl, UntypedFormControl, ValidationErrors } from "@angular/forms";
import {
   debounceTime,
   distinctUntilChanged,
   map,
   merge,
   Observable,
   of,
   startWith,
   Subject,
   switchMap,
   takeUntil,
} from "rxjs";

import { FormRegistrationService } from "../../../forms/form-registration/form-registration.service";

@Component({
   selector: "lcs-validation-overlay-panel",
   templateUrl: "./validation-overlay-panel.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationOverlayPanelComponent implements AfterViewInit, OnDestroy {
   @Input() displayName: string;

   @Input() name: string;

   @Input() parentElement: HTMLElement;

   @Input() validationOverlayInnerElementTags: Array<string>;

   @Input() modelRef: NgControl;

   showValidation: boolean = false;

   control: AbstractControl;

   errors = new Observable<ValidationErrors | null>();

   private unsubscribe = new Subject<void>();

   constructor(
      private controlContainer: ControlContainer,
      private formRegistrationService: FormRegistrationService,
      private changeDetectorRef: ChangeDetectorRef
   ) {}

   ngAfterViewInit() {
      setTimeout(() => {
         const idOfControlBeingValidated: string =
            this.parentElement && this.parentElement.id ? this.parentElement.id : this.name;

         if (this.modelRef) {
            if (this.modelRef.control) {
               this.control = this.modelRef.control;
            }
         } else if (this.controlContainer) {
            // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
            this.control = this.controlContainer.control.get(this.name) as UntypedFormControl;
         }

         if (this.control) {
            this.formRegistrationService.addControl(
               this.name,
               this.control,
               this.displayName,
               idOfControlBeingValidated
            );

            // NOTE: explicitly declaring this with a type instead of passing null prevents a tslint deprecated error
            //       caused due to a signature confusion error if raw untyped null is passed to startsWith
            const startWithValidationErrors: ValidationErrors | null = null;

            this.errors = this.control.valueChanges.pipe(
               startWith(startWithValidationErrors), // prevents blank error message when first displayed
               debounceTime(300),
               distinctUntilChanged(),
               switchMap(() => of(this.control.errors)),
               takeUntil(this.unsubscribe)
            );

            // NOTE: explicitly declaring this with a type prevents a tslint deprecated error
            //       caused due to a signature confusion error if left untyped
            const touchedHackObservable: Observable<void> = <Observable<void>>this.control["touchedHack"];

            merge(this.control.statusChanges, touchedHackObservable)
               .pipe(
                  map(() => this.getShowValidation(idOfControlBeingValidated)),
                  distinctUntilChanged(),
                  takeUntil(this.unsubscribe)
               )
               .subscribe((showValidation) => {
                  this.showValidation = showValidation;
                  if (this.changeDetectorRef) {
                     this.changeDetectorRef.markForCheck();
                  }
               });
         } else {
            if (isDevMode()) {
               console.warn(
                  `Unable to find control for id="${idOfControlBeingValidated}" name="${this.name}" displayName="${this.displayName}" validation overlay will not display. modelRef=`,
                  this.modelRef,
                  `controlContainer=`,
                  this.controlContainer
               );
            }
         }
      });
   }

   ngOnDestroy() {
      this.formRegistrationService.removeControl(this.control);
      this.unsubscribe.next();
      this.unsubscribe.complete();
   }

   /**
    * NOTE: optional _idOfControlBeingValidated is only used for debugging
    */
   private getShowValidation(_idOfControlBeingValidated?: string): boolean {
      let showValidation: boolean = false;

      // NOTE: we check !valid vs invalid per https://itnext.io/valid-and-invalid-in-angular-forms-61cfa3f2a0cd
      if (this.control) {
         showValidation =
            this.control.errors !== null &&
            this.control.enabled &&
            !this.control.valid &&
            (this.control.touched || this.control.dirty);
      }
      return showValidation;
   }
}
