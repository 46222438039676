import { ChangeDetectorRef, Component } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import isEqual from "lodash/isEqual";

@Component({
   selector: "lcs-slide-toggle",
   templateUrl: "slide-toggle.component.html",
})
export class SlideToggleComponent extends ValueAccessorBase<boolean> {
   constructor(changeDetectorRef: ChangeDetectorRef, ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   onValueChanged(value: boolean) {
      if (!isEqual(value, this.value)) {
         this.value = value;
      }
   }
}
