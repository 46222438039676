import { PhoneNumberRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/phone-number-related-object-types.enum";

import { ContactModel } from "./contact.model";
import { LoanContactModel } from "./loan-contact.model";
import { OwnerProspectModel } from "./owner-prospect.model";
import { PhoneNumberTypeModel } from "./phone-number-type.model";
import { PropertyModel } from "./property.model";
import { UserModel } from "./user.model";
import { UtilityModel } from "./utility.model";
import { VendorModel } from "./vendor.model";
import { WebUserModel } from "./web-user.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class PhoneNumberModel {
   Contact: ContactModel;
   Extension: string;
   IsOptOut: boolean;
   IsPrimary: boolean;
   IsTextReady: boolean;
   LoanContact: LoanContactModel;
   MetaTag?: string;
   OwnerProspect: OwnerProspectModel;
   ParentID: number;
   ParentType: PhoneNumberRelatedObjectTypes;
   PhoneNumber: string;
   PhoneNumberID: number;
   PhoneNumberType: PhoneNumberTypeModel;
   PhoneNumberTypeID: number;
   Property: PropertyModel;
   StrippedPhoneNumber: string;
   User: UserModel;
   Utility: UtilityModel;
   Vendor: VendorModel;
   WebUser: WebUserModel;
}
