import { Component, OnInit, OnDestroy } from "@angular/core";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { ServiceIssueDetailService } from "../service-issue-detail.service";
import { Subject, Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
   selector: "owa-issue-estimate-list",
   templateUrl: "./issue-estimate-list.component.html",
   providers: [EstimateService],
})
export class IssueEstimateListComponent implements OnInit, OnDestroy {
   estimateTileColumns: string[] = ["EstimateNumber", "CreateDate", "Property", "Unit", "Reference", "Amount"];
   observableEstimates: Observable<Array<EstimateViewModel>>;
   estimates: EstimateViewModel[];
   ShowEstimate: boolean = false;
   unsubscribe = new Subject<void>();

   constructor(
      private estimateService: EstimateService,
      private serviceManagerService: ServiceIssueDetailService,
      private router: Router
   ) {
      this.serviceManagerService.issue.subscribe((serviceIssue) => {
         this.estimateService.getEstimateForServiceIssue(serviceIssue.ServiceManagerIssueID).subscribe((e) => {
            this.estimates = e;
            this.ShowEstimate = e.length > 0;
         });
      });
   }

   ngOnInit(): void {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   ShowEstimateDetails(id: number) {
      if (id > 0) {
         this.router.navigate(["/estimates", id]);
      } else {
         return;
      }
   }
}
