import { CdkRowDef } from "@angular/cdk/table";
import { Directive, Input } from "@angular/core";

@Directive({
   selector: "[lcsRowDef]",
   providers: [{ provide: CdkRowDef, useExisting: LcsRowDefDirective }],
})
export class LcsRowDefDirective<T> extends CdkRowDef<T> {
   @Input("lcsRowDefColumns") set allColumns(columns: string[]) {
      this._allColumns = columns;
      this.columns = columns;
   }
   get allColumns(): string[] {
      return this._allColumns;
   }

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input("lcsRowDefWhen") when: (index: number, rowData: T) => boolean;

   private _allColumns: string[];
}
