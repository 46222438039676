// These values are based on the plugin mouse-event, do not change these values
// https://www.npmjs.com/package/mouse-event

export enum MouseEvents {
   leftClick = 1,
   rightClick = 2,
   middleClick = 4,
   button4 = 8,
   button5 = 16,
}
