import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";

export class ApiUrlConstants {
   static AndSeparator = ";";

   static OrSeparator = "|";

   static GetOperationString(operation: FilterOperations): string {
      switch (operation) {
         case FilterOperations.LessThan:
            return "lt";
         case FilterOperations.LessThanOrNull:
            return "ltn";
         case FilterOperations.LessThanOrEqualTo:
            return "le";
         case FilterOperations.LessThanOrEqualToOrNull:
            return "len";
         case FilterOperations.GreaterThan:
            return "gt";
         case FilterOperations.GreaterThanOrNull:
            return "gtn";
         case FilterOperations.GreaterThanOrEqualTo:
            return "ge";
         case FilterOperations.GreaterThanOrEqualToOrNull:
            return "gen";
         case FilterOperations.NotEqualTo:
            return "ne";
         case FilterOperations.EqualTo:
            return "eq";
         case FilterOperations.In:
            return "in";
         case FilterOperations.NotIn:
            return "ni";
         case FilterOperations.Between:
            return "bt";
         case FilterOperations.Contains:
            return "ct";
         case FilterOperations.StartsWith:
            return "sw";
         case FilterOperations.EndsWith:
            return "ew";
         case FilterOperations.HasValue:
            return "hv";
         default:
            return "ERR";
      }
   }

   static wrapExpression(expressionString: string): string {
      return `(${expressionString})`;
   }
}
