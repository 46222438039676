import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { filter, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";
import { AccountContextMenuComponent } from "../top-bar/account-context-menu/account-context-menu.component";
import { TopBarService } from "../top-bar/top-bar.service";

@Component({
   selector: "owa-change-emailaddress-view",
   templateUrl: "./change-emailaddress-view.component.html",
   styleUrls: ["./change-emailaddress-view.component.css"],
})
export class ChangeEmailAddressViewComponent implements OnInit, OnDestroy {
   public workflowStep: string;
   public accountcontextmenu: AccountContextMenuComponent;
   public workflowSteps: string;
   public sourceStep: boolean;
   public unsubscribe = new Subject<void>();

   public locationId: number;
   public webuserId: number;

   constructor(
      private authenticationService: AuthenticationService,
      private activatedRoute: ActivatedRoute,
      private topbarservice: TopBarService,
      private cacheMonitorService: CacheMonitorService,
      private sessionCheckService: SessionCheckService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.workflowStep = this.activatedRoute.snapshot.queryParams.sourceStep;
      this.locationId = this.authenticationService.locationID.value;
      this.webuserId = this.authenticationService.webUserID.value;
      this.sourceStep = this.workflowStep === WorkflowSteps[WorkflowSteps.Login] ? true : false;
      this.topbarservice.topBarIsVisible.next(false);
      this.topbarservice.topBarIsVisible
         .pipe(
            filter((v) => v),
            takeUntil(this.unsubscribe)
         )
         .subscribe((tbv) => {
            this.topbarservice.topBarIsVisible.next(false);
         });
   }

   ngOnDestroy() {
      this.sessionCheckService.checkSessionOnFocus = true;
      this.unsubscribe.next();
      this.topbarservice.topBarIsVisible.next(this.cacheMonitorService.cachesLoadedState);
   }
}
