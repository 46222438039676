<div class="image-carousel-full-container">
   <div
      *ngIf="isAllImages"
      class="image-carousel-preview-container"
   >
      <div class="image-icon-container">
         <a
            class="image-carousel-preview-link"
            href="{{ currentImage }}"
            target="_blank"
         >
            <img
               class="image-carousel-preview"
               src="{{ currentImage }}"
            >
         </a>
         <i
            class="material-icons ng-star-inserted image-close-icon"
            onclick="$('a.close-icon')[0].click();"
         >close</i>
      </div>
   </div>
   <div
      #imageCarouselWrapper
      class="image-carousel-wrapper"
   >
      <button
         type="button"
         class="image-carousel-arrow-left"
         (click)="moveLeft()"
         [disabled]="isAllImages ? '' : scrollLeftDisabled"
      >
         <i class="material-icons">keyboard_arrow_left</i>
      </button>
      <div class="image-carousel-container">
         <owa-drag-scroll
            #imageCarousel
            class="image-carousel"
            [yDisabled]="true"
            (reachesLeftBound)="scrollLeftDisabled = $event"
            (reachesRightBound)="scrollRightDisabled = $event"
         >
            <div class="image-type-container">
               <div class="image-type-label">Images</div>
               <div class="image-type-images">
                  <div
                     #dragScrollItem
                     *ngFor="let image of imageCarouselModels"
                     class="image-carousel-image"
                  >
                     <a
                        *ngIf="!isAllImages; else imagebox"
                        [ngClass]="{ 'image-carousel-file-container': !image.isImage }"
                        href="{{ image.fileLink }}"
                        target="_blank"
                     >
                        <div *ngIf="!image.isImage; else loadIcon">
                           <owa-file-icon
                              class="carousel-file-icon"
                              [extension]="image.extension"
                              [caption]="image.name + image.extension"
                           ></owa-file-icon>
                        </div>
                        <ng-template #loadIcon>
                           <img
                              class="image-carousel-img"
                              [ngClass]="{ 'image-carousel-file': !image.isImage }"
                              [hidden]="image.isLoading || image.isInFailedToLoadState"
                              [src]="image.source"
                              [attr.alt]="image.alt"
                              (load)="imageLoad(image)"
                              (error)="imageError(image)"
                           />
                           <img
                              class="image-carousel-img no-image-found"
                              *ngIf="image.isInFailedToLoadState"
                              src="../../../../../assets/images/no-image.png"
                           />
                        </ng-template>
                     </a>
                     <ng-template #imagebox>
                        <img
                           class="image-carousel-img"
                           [ngClass]="{ 'image-carousel-hidden': !image.isCurrentFile}"
                           [hidden]="image.isLoading || image.isInFailedToLoadState"
                           [src]="image.source"
                           [attr.alt]="image.alt"
                           (click)="imageClicked(image)"
                           (load)="imageLoad(image)"
                           (error)="imageError(image)"
                        />
                        <img
                           class="image-carousel-img no-image-found"
                           *ngIf="image.isInFailedToLoadState"
                           src="../../../../../assets/images/no-image.png"
                        />
                     </ng-template>
                  </div>
               </div>
            </div>
         </owa-drag-scroll>
      </div>
      <button
         type="button"
         class="image-carousel-arrow-right"
         (click)="moveRight()"
         [disabled]="isAllImages ? '' : scrollRightDisabled"
      >
         <i class="material-icons">keyboard_arrow_right</i>
      </button>
   </div>
</div>