import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { EstimateApprovalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-approval.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";

@Directive()
@Injectable()
export class EstimateDetailSignatureService {
   @Output() change: EventEmitter<EstimateApprovalModel> = new EventEmitter();

   constructor(private estimateService: EstimateService) { }

   sign(estimateApproval: EstimateApprovalModel) {
      return this.estimateService.ApproveRejectEstimate(estimateApproval);
   }
}
