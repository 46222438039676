import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";

@Component({
   selector: "lcs-birthdays-to-include-report-parameter",
   templateUrl: "birthdays-to-include-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class BirthdaysToIncludeReportParameterComponent implements OnInit, OnDestroy, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   parameterLabel: string = "Birthdays to Include";

   months: ReportReportParameterViewModel;

   ageAsOf: ReportReportParameterViewModel;

   ageFrom: ReportReportParameterViewModel;

   ageTo: ReportReportParameterViewModel;

   missingBirthDays: ReportReportParameterViewModel;

   monthsValueModel: ReportParameterValueModel;

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor() {}

   ngOnInit() {
      this.initializeParameters();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.months = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.MONTHNUMS);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.ageAsOf = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.AGEASOF);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.ageFrom = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.FROMAGE);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.ageTo = this._reportReportParameters.find((p) => p.ReportParameterID === ReportParameter.TOAGE);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.missingBirthDays = this._reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWMISSINGBDAYS
      );
      if (this.months) {
         this.monthsValueModel = new ReportParameterValueModel(this.months.ReportParameterID, "", [""]);
      }
   }
}
