import { UniqueIdentifier } from "@lcs/core/unique-identifier.interface";

export class MultiPdfViewerModel implements UniqueIdentifier {
   DownloadUrl: string;

   FileID: number;

   Name: string;

   Token: string;

   UniqueIdentifier: number;
}
