import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ResendVerificationComponent } from "./resend-verification.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ResendVerificationComponent]
})
export class ResendVerificationModule { }