import { Component, Input } from "@angular/core";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { Subject, takeUntil } from "rxjs";

import { FileCarouselComponent } from "../../file-carousel/file-carousel.component";
import { FileCarouselConfigurationModel } from "../../file-carousel/models/file-carousel-configuration.model";
import { FileCarouselModel } from "../../file-carousel/models/file-carousel.model";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-history-notes-as-sublist",
   templateUrl: "./history-notes-as-sublist.component.html",
})
export class HistoryNotesAsSubListComponent {
   @Input() totalCount: number; // TODO: use this

   @Input() set historyItems(historyItems: Array<HistoryModel>) {
      this._historyItems = historyItems;
      if (historyItems != null && historyItems.length !== 0 && historyItems.find((h) => h.IsPinned)) {
         this.hasPinnedNotes = true;
      }
   }
   get historyItems(): Array<HistoryModel> {
      return this._historyItems;
   }

   images: Map<number, Array<FileCarouselModel>>;
   hasPinnedNotes: boolean;
   hasThemePinnedNotes: boolean;
   private unsubscribe = new Subject<void>();

   historyColumns: string[] = ["Date", "Note", "File"];
   private _historyItems: Array<HistoryModel>;
   constructor(
      private dialogCreationService: DialogCreationService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      if (this.historyItems != null && this.historyItems.length > 0 && this.historyItems.find((r) => r.IsPinned)) {
         this.hasPinnedNotes = true;
         this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
            (prefs) => {
               if (prefs != null) {
                  var themePref = prefs.find((p) => p.Name === "OWAToolbarColor");
                  if (themePref != null && themePref.Value != "#F28228") {
                     this.hasThemePinnedNotes = true;
                  }
               }
            }
         );
      }
   }

   openCarousel(historyID: number, note: string) {
      if (
         this.historyItems != null &&
         this.historyItems.length > 0 &&
         (this.images == null || this.images.size === 0)
      ) {
         const historyWithFile = this.historyItems.filter(
            (historyItem) => historyItem.Attachments !== undefined && historyItem.Attachments.length > 0
         );
         if (historyWithFile.length > 0) {
            const imageMap = new Map<number, Array<FileCarouselModel>>();
            const imageExtensions = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tif", ".tiff"];
            historyWithFile.forEach(function (historyItem) {
               const imageList = new Array<FileCarouselModel>();
               historyItem.Attachments.forEach(function (file) {
                  const image = new FileCarouselModel();
                  image.isImage = imageExtensions.some(function (element) {
                     return element === file.Extension.toLowerCase();
                  });
                  image.source = image.fileLink = file.DownloadURL;
                  image.extension = file.Extension;
                  image.alt = "No preview available";
                  image.name = file.Name;
                  imageList.push(image);
               });
               imageMap.set(historyItem.HistoryID, imageList);
            });
            this.images = imageMap;
         }
      }
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = FileCarouselComponent;
      dialogConfigModel.disableClose = false;
      dialogConfigModel.title = note;

      const fileConfigModel = new FileCarouselConfigurationModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FileCarouselModel[] | undefined' is not assi... Remove this comment to see the full error message
      fileConfigModel.FileSource = this.images.get(historyID);
      dialogConfigModel.styleClass = fileConfigModel.FileSource.every(function (value, index) {
         return value.isImage;
      })
         ? "image-carousel"
         : "file-carousel";
      this.dialogCreationService.open(dialogConfigModel, fileConfigModel);
   }
}
