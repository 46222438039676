////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum UserDefinedFieldType {
   Text = 0,
   Dropdown = 1,
   YesNo = 2,
   Date = 3,
   Numeric = 4,
   File = 5,
   Image = 6,
   Checkedlist = 7,
   EncryptedText = 9,
   NotSet = -1,
}
