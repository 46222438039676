import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InputsModule } from "@lcs/inputs/inputs.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LinkModule } from "@lcs/link/link.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { SelectModule } from "@lcs/select/select.module";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

import { LocationModule } from "../authentication/rm-location/rm-location.module";
import { OwaInputsModule } from "../inputs/inputs.module";
import { NumericInputModule } from "../numeric-input/numeric-input.module";
import { AccountSignUpComponent } from "./account-signup.component";

@NgModule({
   declarations: [AccountSignUpComponent],
   imports: [
      CommonModule,
      FormsModule,
      LinkModule,
      TextBoxModule,
      NumericInputModule,
      SelectModule,
      LocationModule,
      LcsProgressButtonModule,
      InputsModule,
      OwaInputsModule,
      ValidationModule,
      RecaptchaModule,
      RecaptchaFormsModule,
   ],

   exports: [AccountSignUpComponent],
})
export class AccountSignUpModule {}
