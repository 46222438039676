import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { OwnerEstimateDatatablePropertySelectorDirective } from "./owner-estimate-datatable-property.directive";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
   ],
   declarations: [
      OwnerEstimateDatatablePropertySelectorDirective,
   ],
   exports: [
      OwnerEstimateDatatablePropertySelectorDirective,
   ],
})
export class OwnerEstimateDatatablePropertySelectorModule { }
