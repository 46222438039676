import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { ChargeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/charge.delete-options";
import { CheckDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/check.delete-options";
import { CreditReversalDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/credit-reversal.delete-options";
import { CreditDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/credit.delete-options";
import { InvoiceDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/invoice.delete-options";
import { LeaseDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/lease.delete-options";
import { LoanDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/loan.delete-options";
import { PaymentReversalDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/payment-reversal.delete-options";
import { PaymentDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/payment.delete-options";
import { PhoneNumberTypeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/phone-number-type.delete-options";
import { TenantBillDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/tenant-bill.delete-options";
import { TenantCheckDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/tenant-check.delete-options";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { AccountStatementEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/account-statement.embed-options";
import { AddressTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address-type.embed-options";
import { AddressEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address.embed-options";
import { AppointmentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/appointment.embed-options";
import { BillEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/bill.embed-options";
import { BillableExpenseEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/billable-expense.embed-options";
import { CashPayUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/cash-pay-user.embed-options";
import { ChargeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/charge.embed-options";
import { CheckEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check.embed-options";
import { ColorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/color.embed-options";
import { ContactTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/contact-type.embed-options";
import { ContactEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/contact.embed-options";
import { CreditReversalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/credit-reversal.embed-options";
import { CreditEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/credit.embed-options";
import { EpayInformationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/epay-information.embed-options";
import { HistoryCallEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-call.embed-options";
import { HistoryEmailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-email.embed-options";
import { HistoryNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-note.embed-options";
import { HistorySystemNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-system-note.embed-options";
import { HistoryViolationNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-violation-note.embed-options";
import { HistoryVisitEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-visit.embed-options";
import { HistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history.embed-options";
import { InvoiceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/invoice.embed-options";
import { LateFeeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/late-fee.embed-options";
import { LeaseEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/lease.embed-options";
import { LoanEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/loan.embed-options";
import { OpenPrepayEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/open-prepay.embed-options";
import { PaymentReversalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/payment-reversal.embed-options";
import { PaymentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/payment.embed-options";
import { PetEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/pet.embed-options";
import { PhoneNumberTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number-type.embed-options";
import { PhoneNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { ProspectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/prospect.embed-options";
import { RecurringChargeSummaryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/recurring-charge-summary.embed-options";
import { RecurringChargeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/recurring-charge.embed-options";
import { RevenueRenewalEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/revenue-renewal.embed-options";
import { RMVoIPCallHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rmvo-ipcall-history.embed-options";
import { ScreeningEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/screening.embed-options";
import { ServiceManagerProjectEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/service-manager-project.embed-options";
import { ShortTermReservationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/short-term-reservation.embed-options";
import { SubsidyTenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/subsidy-tenant.embed-options";
import { SubsidyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/subsidy.embed-options";
import { TaskEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task.embed-options";
import { TenantBillEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant-bill.embed-options";
import { TenantCheckDetailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant-check-detail.embed-options";
import { TenantCheckEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant-check.embed-options";
import { TenantEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/tenant.embed-options";
import { TransactionEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/transaction.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { VehicleEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vehicle.embed-options";
import { ViolationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/violation.embed-options";
import { WebUserAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/web-user-account.embed-options";
import { WebUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/web-user.embed-options";
import { HistoryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/history.get-options";
import { OpenPrepayGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/open-prepay.get-options";
import { OpenReceivableGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/open-receivable.get-options";
import { PaymentGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/payment.get-options";
import { RecurringChargeSummaryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/recurring-charge-summary.get-options";
import { TransactionGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/transaction.get-options";
import { BatchPaymentSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/batch-payment.save-options";
import { BillSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/bill.save-options";
import { ChargeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/charge.save-options";
import { CheckSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check.save-options";
import { ContactSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/contact.save-options";
import { CreditSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/credit.save-options";
import { HistoryCallSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-call.save-options";
import { HistoryEmailSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-email.save-options";
import { HistoryVisitSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/history-visit.save-options";
import { InvoiceSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/invoice.save-options";
import { LeaseSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/lease.save-options";
import { LoanSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/loan.save-options";
import { NSFFeeSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/nsffee.save-options";
import { PaymentReversalSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/payment-reversal.save-options";
import { PaymentSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/payment.save-options";
import { RefundSecurityDepositSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/refund-security-deposit.save-options";
import { RMVoIPCallHistorySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/rmvo-ipcall-history.save-options";
import { ScreeningSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/screening.save-options";
import { TenantBillSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/tenant-bill.save-options";
import { TenantCheckSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/tenant-check.save-options";
import { TenantSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/tenant.save-options";
import { TransferSecurityDepositSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/transfer-security-deposit.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { WebUserSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/web-user.save-options";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/account-type.enum";
import { ScreeningReport } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/screening-report.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AccountStatementModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/account-statement.model";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { AddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address.model";
import { AppointmentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/appointment.model";
import { BatchEmailEntitiesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entities.model";
import { BatchEmailEntitySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entity-summary.model";
import { BatchPaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-payment.model";
import { BillAllocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bill-allocation.model";
import { BillModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bill.model";
import { BillableExpenseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/billable-expense.model";
import { CashPayUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/cash-pay-user.model";
import { ChargeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/charge.model";
import { CheckModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check.model";
import { ColorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/color.model";
import { ContactScreeningInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact-screening-information.model";
import { ContactTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact-type.model";
import { ContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact.model";
import { CreatePaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/create-payment.model";
import { CreditReversalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/credit-reversal.model";
import { CreditModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/credit.model";
import { DepositModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/deposit.model";
import { EpayInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-information.model";
import { EpayPaymentConfirmationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-payment-confirmation.model";
import { ExpressColumnDefinitionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-column-definition.model";
import { HistoryCallModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-call.model";
import { HistoryEmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-email.model";
import { HistoryNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-note.model";
import { HistorySystemNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-system-note.model";
import { HistoryViolationNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-violation-note.model";
import { HistoryVisitModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-visit.model";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { InvoiceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/invoice.model";
import { LateFeeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/late-fee.model";
import { LeaseModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/lease.model";
import { LoanModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/loan.model";
import { LocationGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location-group.model";
import { NSFFeeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/nsffee.model";
import { OpenPrepayModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/open-prepay.model";
import { OpenReceivableModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/open-receivable.model";
import { PaymentReversalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/payment-reversal.model";
import { PaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/payment.model";
import { PetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/pet.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { ProspectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/prospect.model";
import { RecurringChargeSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/recurring-charge-summary.model";
import { RecurringChargeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/recurring-charge.model";
import { RefundSecurityDepositModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/refund-security-deposit.model";
import { RevenueRenewalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/revenue-renewal.model";
import { RMVoIPCallHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rmvo-ipcall-history.model";
import { ScreeningArgumentsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/screening-arguments.model";
import { ScreeningModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/screening.model";
import { ScriptBuilderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/script-builder.model";
import { SecurityDepositEpayPaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/security-deposit-epay-payment.model";
import { SecurityDepositSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/security-deposit-summary.model";
import { SecurityDepositModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/security-deposit.model";
import { SelectorItemTransferModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/selector-item-transfer.model";
import { ServiceManagerProjectModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-project.model";
import { ShortTermReservationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/short-term-reservation.model";
import { SubsidyTenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/subsidy-tenant.model";
import { SubsidyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/subsidy.model";
import { TaskModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task.model";
import { TenantBillModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant-bill.model";
import { TenantCheckDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant-check-detail.model";
import { TenantCheckModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant-check.model";
import { TenantModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/tenant.model";
import { TransactionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/transaction.model";
import { TransferSecurityDepositModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/transfer-security-deposit.model";
import { TransferTenantPaymentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/transfer-tenant-payment.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { VehicleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vehicle.model";
import { ViolationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/violation.model";
import { WebUserAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user-account.model";
import { WebUserAuthenticationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user-authentication.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class TenantsService {
   static readonly AccountStatementsResource = "AccountStatements";
   static readonly AddressesResource = "Addresses";
   static readonly AddressTypesResource = "AddressTypes";
   static readonly ApplyNSFFeesResource = "ApplyNSFFees";
   static readonly AppointmentsResource = "Appointments";
   static readonly BalanceResource = "Balance";
   static readonly BatchEmailContactListResource = "BatchEmailContactList";
   static readonly BatchEmailTenantsResource = "BatchEmailTenants";
   static readonly BatchEmailTenantsFromPropertiesResource = "BatchEmailTenantsFromProperties";
   static readonly BatchEmailTenantsFromUnitsResource = "BatchEmailTenantsFromUnits";
   static readonly BatchPaymentsResource = "BatchPayments";
   static readonly BillableExpensesResource = "BillableExpenses";
   static readonly BillAllocationsResource = "BillAllocations";
   static readonly BillsResource = "Bills";
   static readonly CashPayUserResource = "CashPayUser";
   static readonly ChargesResource = "Charges";
   static readonly ChecksResource = "Checks";
   static readonly ColorResource = "Color";
   static readonly ContactsResource = "Contacts";
   static readonly ContactTypesResource = "ContactTypes";
   static readonly CreateUserResource = "CreateUser";
   static readonly CreditReversalsResource = "CreditReversals";
   static readonly CreditsResource = "Credits";
   static readonly CustomersFromPropertyAndUnitResource = "CustomersFromPropertyAndUnit";
   static readonly DeleteCashpayAccountResource = "DeleteCashpayAccount";
   static readonly EpayInformationResource = "EpayInformation";
   static readonly GenerateCashpayAccountResource = "GenerateCashpayAccount";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetFromAllLocationsResource = "GetFromAllLocations";
   static readonly GetValidStatementMethodsResource = "GetValidStatementMethods";
   static readonly HistoryResource = "History";
   static readonly HistoryCallsResource = "HistoryCalls";
   static readonly HistoryEmailsResource = "HistoryEmails";
   static readonly HistoryNotesResource = "HistoryNotes";
   static readonly HistoryPaymentNotesResource = "HistoryPaymentNotes";
   static readonly HistorySystemNotesResource = "HistorySystemNotes";
   static readonly HistoryViolationNotesResource = "HistoryViolationNotes";
   static readonly HistoryVisitsResource = "HistoryVisits";
   static readonly HistoryWithUnitHistoryResource = "HistoryWithUnitHistory";
   static readonly InvoicesResource = "Invoices";
   static readonly LateFeesResource = "LateFees";
   static readonly LateFeeSetupResource = "LateFeeSetup";
   static readonly LeasesResource = "Leases";
   static readonly LeaseSortOrderResource = "LeaseSortOrder";
   static readonly LeasingAgentResource = "LeasingAgent";
   static readonly LoansResource = "Loans";
   static readonly MakePaymentsResource = "MakePayments";
   static readonly OpenPrepaysResource = "OpenPrepays";
   static readonly OpenReceivablesResource = "OpenReceivables";
   static readonly PaymentReversalsResource = "PaymentReversals";
   static readonly PaymentsResource = "Payments";
   static readonly PetsResource = "Pets";
   static readonly PhoneNumberTypesResource = "PhoneNumberTypes";
   static readonly PrimaryContactResource = "PrimaryContact";
   static readonly PrimaryContactPhoneNumbersResource = "PrimaryContactPhoneNumbers";
   static readonly PropertyResource = "Property";
   static readonly ProspectResource = "Prospect";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RecurringChargesResource = "RecurringCharges";
   static readonly RecurringChargeSummariesResource = "RecurringChargeSummaries";
   static readonly RefundSecurityDepositResource = "RefundSecurityDeposit";
   static readonly RevenueRenewalsResource = "RevenueRenewals";
   static readonly RMVoIPCallHistoryResource = "RMVoIPCallHistory";
   static readonly RunScreeningResource = "RunScreening";
   static readonly SaveRecurringChargeSortOrdersResource = "SaveRecurringChargeSortOrders";
   static readonly ScreeningMappedFieldValuesResource = "ScreeningMappedFieldValues";
   static readonly ScreeningReportTypesForUserResource = "ScreeningReportTypesForUser";
   static readonly ScreeningsResource = "Screenings";
   static readonly SearchResource = "Search";
   static readonly SecurityDepositHeldResource = "SecurityDepositHeld";
   static readonly SecurityDepositsResource = "SecurityDeposits";
   static readonly SecurityDepositSummariesResource = "SecurityDepositSummaries";
   static readonly SecurityDepositViaEpayResource = "SecurityDepositViaEpay";
   static readonly ServiceManagerProjectsResource = "ServiceManagerProjects";
   static readonly ShortTermReservationsResource = "ShortTermReservations";
   static readonly SubsidiesResource = "Subsidies";
   static readonly SubsidyTenantsResource = "SubsidyTenants";
   static readonly TasksResource = "Tasks";
   static readonly TenantBillsResource = "TenantBills";
   static readonly TenantCheckDetailsResource = "TenantCheckDetails";
   static readonly TenantChecksResource = "TenantChecks";
   static readonly TenantLeaseSummaryColumnsResource = "TenantLeaseSummaryColumns";
   static readonly TenantSDBankResource = "TenantSDBank";
   static readonly TestScriptResource = "TestScript";
   static readonly TransactionsResource = "Transactions";
   static readonly TransferPaymentResource = "TransferPayment";
   static readonly TransferSecurityDepositsResource = "TransferSecurityDeposits";
   static readonly UpdateCashPayAccountResource = "UpdateCashPayAccount";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly ValidateWebUserCredentialsResource = "ValidateWebUserCredentials";
   static readonly VehiclesResource = "Vehicles";
   static readonly ViolationsResource = "Violations";
   static readonly WebUserAccountsResource = "WebUserAccounts";
   static readonly WebUsersResource = "WebUsers";
   static readonly endpoint = "TenantsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | TenantSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(TenantsService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   post(
      item?: TenantModel | null,
      saveOptions?: Array<string | TenantSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | TenantSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(TenantsService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   postCollection(
      items: Array<TenantModel>,
      saveOptions?: Array<string | TenantSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(TenantsService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(TenantsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAccountStatementsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/AccountStatements",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAccountStatements(
      id: number,
      itemID: number,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AccountStatementModel> {
      return this.apiService.getSingle(
         this.getAccountStatementsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAccountStatementsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/AccountStatements",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAccountStatementsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountStatementModel>> {
      return this.apiService.getCollection(
         this.getAccountStatementsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAccountStatementsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/AccountStatements",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAccountStatements(
      id: number,
      item?: AccountStatementModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AccountStatementModel> {
      return this.apiService.postSingle(
         this.postAccountStatementsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAccountStatementsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/AccountStatements",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAccountStatementsCollection(
      id: number,
      items: Array<AccountStatementModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AccountStatementEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AccountStatementModel>> {
      return this.apiService.postCollection(
         this.postAccountStatementsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteAccountStatementsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/AccountStatements",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAccountStatements(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteAccountStatementsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getAddressesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Addresses",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddresses(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(
         this.getAddressesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAddressesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Addresses",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAddressesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Addresses",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAllAddressesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddresses(
      id: number,
      item?: AddressModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.postSingle(
         this.postAddressesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAddressesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddressesCollection(
      id: number,
      items: Array<AddressModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.postCollection(
         this.postAddressesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteAddressesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Addresses",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAddresses(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getAddressTypesUrl(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddressTypes(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.getSingle(this.getAddressTypesUrl(itemID, embeds, fields, additionalParameters));
   }

   getAddressTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/AddressTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAddressTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAllAddressTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddressTypes(
      item?: AddressTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.postSingle(
         this.postAddressTypesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAddressTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         addressTypes
      );
   }

   postAllAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAllAddressTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         addressTypes
      );
   }

   deleteAddressTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/AddressTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAddressTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressTypesUrl(ids, deleteOptions, additionalParameters));
   }

   postApplyNSFFeesUrl(
      itemID: number,
      saveOptions?: Array<string | NSFFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ApplyNSFFees",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postApplyNSFFees(
      itemID: number,
      fees?: Array<NSFFeeModel> | null,
      saveOptions?: Array<string | NSFFeeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(this.postApplyNSFFeesUrl(itemID, saveOptions, additionalParameters), fees);
   }

   getAppointmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Appointments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAppointments(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.getSingle(
         this.getAppointmentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAppointmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Appointments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAppointmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.getCollection(
         this.getAppointmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAppointmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Appointments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAppointments(
      id: number,
      item?: AppointmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.postSingle(
         this.postAppointmentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAppointmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Appointments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAppointmentsCollection(
      id: number,
      items: Array<AppointmentModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.postCollection(
         this.postAppointmentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteAppointmentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Appointments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAppointments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAppointmentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getBalanceUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Balance",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBalance(id: number, additionalParameters?: Array<string>): Observable<number> {
      return this.apiService.getSingle(this.getBalanceUrl(id, additionalParameters));
   }

   getBatchEmailContactListCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/BatchEmailContactList",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBatchEmailContactListCollection(additionalParameters?: Array<string>): Observable<Array<ContactModel>> {
      return this.apiService.getCollection(this.getBatchEmailContactListCollectionUrl(additionalParameters));
   }

   postBatchEmailTenantsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/BatchEmailTenants",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailTenants(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailTenantsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailTenantsFromPropertiesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/BatchEmailTenantsFromProperties",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailTenantsFromProperties(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailTenantsFromPropertiesUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchEmailTenantsFromUnitsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/BatchEmailTenantsFromUnits",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailTenantsFromUnits(
      request?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailTenantsFromUnitsUrl(additionalParameters), request)
         .pipe(map((response) => response.body));
   }

   postBatchPaymentsUrl(
      saveOptions?: Array<string | BatchPaymentSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/BatchPayments",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postBatchPayments(
      model?: BatchPaymentModel | null,
      saveOptions?: Array<string | BatchPaymentSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<DepositModel>> {
      return this.apiService
         .post(this.postBatchPaymentsUrl(saveOptions, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getBillableExpensesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillableExpenseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/BillableExpenses",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBillableExpensesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillableExpenseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillableExpenseModel>> {
      return this.apiService.getCollection(
         this.getBillableExpensesCollectionUrl(id, filters, embeds, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   postBillAllocationsUrl(
      saveOptions?: Array<string | BillSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/BillAllocations",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postBillAllocations(
      item?: BillAllocationModel | null,
      saveOptions?: Array<string | BillSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postBillAllocationsUrl(saveOptions, additionalParameters), item);
   }

   getBillsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Bills",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBills(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BillModel> {
      return this.apiService.getSingle(this.getBillsUrl(id, itemID, embeds, additionalParameters));
   }

   getBillsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Bills",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBillsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getBillsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllBillsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Bills",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllBillsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getAllBillsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCashPayUserUrl(
      id: number,
      embeds?: Array<string | CashPayUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/CashPayUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCashPayUser(
      id: number,
      embeds?: Array<string | CashPayUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CashPayUserModel> {
      return this.apiService.getSingle(this.getCashPayUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getChargesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Charges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCharges(
      id: number,
      itemID: number,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeModel> {
      return this.apiService.getSingle(
         this.getChargesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getChargesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Charges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChargesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.getCollection(
         this.getChargesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllChargesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Charges",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllChargesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.getCollection(
         this.getAllChargesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChargesUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Charges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCharges(
      id: number,
      item?: ChargeModel | null,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ChargeModel> {
      return this.apiService.postSingle(
         this.postChargesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postChargesCollectionUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Charges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChargesCollection(
      id: number,
      items: Array<ChargeModel>,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ChargeModel>> {
      return this.apiService.postCollection(
         this.postChargesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteChargesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | ChargeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Charges",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCharges(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | ChargeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChargesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getChecksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Checks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChecks(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.getSingle(this.getChecksUrl(id, itemID, embeds, fields, additionalParameters));
   }

   getChecksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Checks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChecksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.getCollection(
         this.getChecksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChecksUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Checks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postChecks(
      id: number,
      item?: CheckModel | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.postSingle(this.postChecksUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postChecksCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Checks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postChecksCollection(
      id: number,
      checks?: Array<CheckModel> | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.postCollection(
         this.postChecksCollectionUrl(id, saveOptions, fields, additionalParameters),
         checks
      );
   }

   deleteChecksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Checks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteChecks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChecksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getColorUrl(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Color",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getColor(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ColorModel> {
      return this.apiService.getSingle(this.getColorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getContactsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Contacts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContacts(
      id: number,
      itemID: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService.getSingle(
         this.getContactsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getContactsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Contacts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getContactsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactModel>> {
      return this.apiService.getCollection(
         this.getContactsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllContactsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Contacts",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllContactsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactModel>> {
      return this.apiService.getCollection(
         this.getAllContactsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postContactsUrl(
      id: number,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Contacts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContacts(
      id: number,
      item?: ContactModel | null,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService.postSingle(
         this.postContactsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postContactsCollectionUrl(
      id: number,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Contacts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactsCollection(
      id: number,
      items: Array<ContactModel>,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactModel>> {
      return this.apiService.postCollection(
         this.postContactsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteContactsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Contacts",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContacts(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteContactsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getContactTypesUrl(
      itemID: number,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContactTypes(
      itemID: number,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactTypeModel> {
      return this.apiService.getSingle(this.getContactTypesUrl(itemID, embeds, fields, additionalParameters));
   }

   getContactTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ContactTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getContactTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactTypeModel>> {
      return this.apiService.getCollection(
         this.getContactTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllContactTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllContactTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactTypeModel>> {
      return this.apiService.getCollection(
         this.getAllContactTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postContactTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactTypes(
      item?: ContactTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactTypeModel> {
      return this.apiService.postSingle(
         this.postContactTypesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postContactTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactTypesCollection(
      ContactTypes?: Array<ContactTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactTypeModel>> {
      return this.apiService.postCollection(
         this.postContactTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         ContactTypes
      );
   }

   postAllContactTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllContactTypesCollection(
      ContactTypes?: Array<ContactTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactTypeModel>> {
      return this.apiService.postCollection(
         this.postAllContactTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         ContactTypes
      );
   }

   deleteContactTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/ContactTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContactTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteContactTypesUrl(ids, deleteOptions, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCreditReversalsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditReversalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/CreditReversals",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreditReversals(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditReversalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditReversalModel> {
      return this.apiService.getSingle(
         this.getCreditReversalsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreditReversalsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditReversalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/CreditReversals",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCreditReversalsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditReversalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditReversalModel>> {
      return this.apiService.getCollection(
         this.getCreditReversalsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   deleteCreditReversalsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/CreditReversals",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCreditReversals(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCreditReversalsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCreditsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Credits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCredits(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditModel> {
      return this.apiService.getSingle(
         this.getCreditsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreditsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Credits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCreditsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditModel>> {
      return this.apiService.getCollection(
         this.getCreditsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllCreditsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Credits",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllCreditsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditModel>> {
      return this.apiService.getCollection(
         this.getAllCreditsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCreditsUrl(
      id: number,
      saveOptions?: Array<string | CreditSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Credits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCredits(
      id: number,
      item?: CreditModel | null,
      saveOptions?: Array<string | CreditSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditModel> {
      return this.apiService.postSingle(
         this.postCreditsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postCreditsCollectionUrl(
      id: number,
      saveOptions?: Array<string | CreditSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Credits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postCreditsCollection(
      id: number,
      items: Array<CreditModel>,
      saveOptions?: Array<string | CreditSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | CreditEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditModel>> {
      return this.apiService.postCollection(
         this.postCreditsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteCreditsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Credits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCredits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCreditsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCustomersFromPropertyAndUnitCollectionUrl(
      propertyID: number,
      unitID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.getUrl(
         TenantsService.endpoint + "/CustomersFromPropertyAndUnit",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCustomersFromPropertyAndUnitCollection(
      propertyID: number,
      unitID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getCustomersFromPropertyAndUnitCollectionUrl(propertyID, unitID, additionalParameters)
      );
   }

   deleteDeleteCashpayAccountUrl(
      customerID: number,
      accountType: AccountType,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("customerID=" + customerID);
      additionalParameters.push("accountType=" + accountType);
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/DeleteCashpayAccount",
         null,
         null,
         null,
         additionalParameters
      );
   }

   deleteDeleteCashpayAccount(
      customerID: number,
      accountType: AccountType,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteDeleteCashpayAccountUrl(customerID, accountType, additionalParameters)
      );
   }

   getEpayInformationUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/EpayInformation",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpayInformation(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.getSingle(
         this.getEpayInformationUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getEpayInformationCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/EpayInformation",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEpayInformationCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getEpayInformationCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllEpayInformationCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/EpayInformation",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllEpayInformationCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getAllEpayInformationCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postEpayInformationUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postEpayInformation(
      id: number,
      item?: EpayInformationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.postSingle(
         this.postEpayInformationUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postEpayInformationCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postEpayInformationCollection(
      id: number,
      EpayInfo?: Array<EpayInformationModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.postCollection(
         this.postEpayInformationCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         EpayInfo
      );
   }

   deleteEpayInformationUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/EpayInformation",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteEpayInformation(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteEpayInformationUrl(id, ids, deleteOptions, additionalParameters));
   }

   getGenerateCashpayAccountUrl(
      accountType: AccountType,
      customerID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountType=" + accountType);
      additionalParameters.push("customerID=" + customerID);
      return this.apiService.getUrl(
         TenantsService.endpoint + "/GenerateCashpayAccount",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGenerateCashpayAccount(
      accountType: AccountType,
      customerID: number,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService.getSingle(
         this.getGenerateCashpayAccountUrl(accountType, customerID, additionalParameters)
      );
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         TenantsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGetFromAllLocationsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (LocationIDs !== undefined && LocationIDs !== null && LocationIDs.length > 0) {
         additionalParameters.push("LocationIDs=" + LocationIDs);
      }
      return this.apiService.getUrl(
         TenantsService.endpoint + "/GetFromAllLocations",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getGetFromAllLocationsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      LocationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationGroupModel>> {
      return this.apiService.getCollection(
         this.getGetFromAllLocationsCollectionUrl(
            filters,
            embeds,
            fields,
            pageSize,
            pageNumber,
            LocationIDs,
            additionalParameters
         )
      );
   }

   getGetValidStatementMethodsCollectionUrl(
      entityKeyID: number,
      entityType: number,
      isTenantStatementOption?: boolean | null,
      isTenantStatementAutomation?: boolean | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("entityKeyID=" + entityKeyID);
      additionalParameters.push("entityType=" + entityType);
      if (isTenantStatementOption !== undefined && isTenantStatementOption !== null) {
         additionalParameters.push("isTenantStatementOption=" + isTenantStatementOption);
      }
      if (isTenantStatementAutomation !== undefined && isTenantStatementAutomation !== null) {
         additionalParameters.push("isTenantStatementAutomation=" + isTenantStatementAutomation);
      }
      return this.apiService.getUrl(
         TenantsService.endpoint + "/GetValidStatementMethods",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetValidStatementMethodsCollection(
      entityKeyID: number,
      entityType: number,
      isTenantStatementOption?: boolean | null,
      isTenantStatementAutomation?: boolean | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SelectorItemTransferModel>> {
      return this.apiService.getCollection(
         this.getGetValidStatementMethodsCollectionUrl(
            entityKeyID,
            entityType,
            isTenantStatementOption,
            isTenantStatementAutomation,
            additionalParameters
         )
      );
   }

   getHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/History",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/History",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getHistoryCallsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryCalls",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryCalls(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryCallModel> {
      return this.apiService.getSingle(
         this.getHistoryCallsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCallsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryCalls",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCallsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.getCollection(
         this.getHistoryCallsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryCallsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryCalls",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryCallsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryCallsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryCallsUrl(
      id: number,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryCalls",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryCalls(
      id: number,
      item?: HistoryCallModel | null,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryCallModel> {
      return this.apiService.postSingle(
         this.postHistoryCallsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryCallsCollectionUrl(
      id: number,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryCalls",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryCallsCollection(
      id: number,
      items: Array<HistoryCallModel>,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.postCollection(
         this.postHistoryCallsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryCallsCollectionUrl(
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryCalls",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryCallsCollection(
      items: Array<HistoryCallModel>,
      saveOptions?: Array<string | HistoryCallSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryCallEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryCallModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryCallsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryCallsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistoryCalls",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryCalls(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryCallsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryEmailsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryEmails",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryEmails(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryEmailModel> {
      return this.apiService.getSingle(
         this.getHistoryEmailsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryEmailsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryEmails",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryEmailsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.getCollection(
         this.getHistoryEmailsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryEmailsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryEmails",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryEmailsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryEmailsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryEmailsUrl(
      id: number,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryEmails",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryEmails(
      id: number,
      item?: HistoryEmailModel | null,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryEmailModel> {
      return this.apiService.postSingle(
         this.postHistoryEmailsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryEmailsCollectionUrl(
      id: number,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryEmails",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryEmailsCollection(
      id: number,
      items: Array<HistoryEmailModel>,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.postCollection(
         this.postHistoryEmailsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryEmailsCollectionUrl(
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryEmails",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryEmailsCollection(
      items: Array<HistoryEmailModel>,
      saveOptions?: Array<string | HistoryEmailSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryEmailEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryEmailModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryEmailsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryEmailsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistoryEmails",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryEmails(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryEmailsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.getSingle(
         this.getHistoryNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotes(
      id: number,
      item?: HistoryNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotesCollection(
      id: number,
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistoryNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryNotesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryPaymentNotesCollectionUrl(tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryPaymentNotes",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryPaymentNotesCollection(tenantID: number, additionalParameters?: Array<string>): Observable<Array<string>> {
      return this.apiService.getCollection(this.getHistoryPaymentNotesCollectionUrl(tenantID, additionalParameters));
   }

   getHistorySystemNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.getSingle(
         this.getHistorySystemNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistorySystemNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getHistorySystemNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistorySystemNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistorySystemNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistorySystemNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistorySystemNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotes(
      id: number,
      item?: HistorySystemNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.postSingle(
         this.postHistorySystemNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistorySystemNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotesCollection(
      id: number,
      items: Array<HistorySystemNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.postCollection(
         this.postHistorySystemNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistorySystemNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistorySystemNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistorySystemNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteHistorySystemNotesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getHistoryViolationNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryViolationNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryViolationNoteModel> {
      return this.apiService.getSingle(
         this.getHistoryViolationNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryViolationNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryViolationNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryViolationNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryViolationNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryViolationNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryViolationNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryViolationNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryViolationNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryViolationNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryViolationNotes(
      id: number,
      item?: HistoryViolationNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryViolationNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryViolationNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryViolationNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryViolationNotesCollection(
      id: number,
      items: Array<HistoryViolationNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryViolationNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryViolationNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryViolationNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryViolationNotesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistoryViolationNotes",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryViolationNotes(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteHistoryViolationNotesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getHistoryVisitsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryVisits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryVisits(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryVisitModel> {
      return this.apiService.getSingle(
         this.getHistoryVisitsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryVisitsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryVisits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryVisitsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.getCollection(
         this.getHistoryVisitsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryVisitsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryVisits",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryVisitsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryVisitsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryVisitsUrl(
      id: number,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryVisits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryVisits(
      id: number,
      item?: HistoryVisitModel | null,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryVisitModel> {
      return this.apiService.postSingle(
         this.postHistoryVisitsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryVisitsCollectionUrl(
      id: number,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryVisits",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryVisitsCollection(
      id: number,
      items: Array<HistoryVisitModel>,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.postCollection(
         this.postHistoryVisitsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllHistoryVisitsCollectionUrl(
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/HistoryVisits",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryVisitsCollection(
      items: Array<HistoryVisitModel>,
      saveOptions?: Array<string | HistoryVisitSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryVisitEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryVisitModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryVisitsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryVisitsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/HistoryVisits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteHistoryVisits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryVisitsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getHistoryWithUnitHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryWithUnitHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryWithUnitHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(this.getHistoryWithUnitHistoryUrl(id, itemID, embeds, additionalParameters));
   }

   getHistoryWithUnitHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/HistoryWithUnitHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryWithUnitHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryWithUnitHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getInvoicesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Invoices",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getInvoices(
      id: number,
      itemID: number,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<InvoiceModel> {
      return this.apiService.getSingle(
         this.getInvoicesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getInvoicesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Invoices",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getInvoicesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InvoiceModel>> {
      return this.apiService.getCollection(
         this.getInvoicesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postInvoicesUrl(
      id: number,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Invoices",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postInvoices(
      id: number,
      item?: InvoiceModel | null,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<InvoiceModel> {
      return this.apiService.postSingle(
         this.postInvoicesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postInvoicesCollectionUrl(
      id: number,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Invoices",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postInvoicesCollection(
      id: number,
      items: Array<InvoiceModel>,
      saveOptions?: Array<string | InvoiceSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | InvoiceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<InvoiceModel>> {
      return this.apiService.postCollection(
         this.postInvoicesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteInvoicesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InvoiceDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Invoices",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteInvoices(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | InvoiceDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteInvoicesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getLateFeesUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/LateFees",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLateFees(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService.getSingle(
         this.getLateFeesUrl(id, filters, embeds, orderingOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   postLateFeesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/LateFees",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLateFees(
      id: number,
      lateFee?: LateFeeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService
         .post(this.postLateFeesUrl(id, saveOptions, fields, additionalParameters), lateFee)
         .pipe(map((response) => response.body));
   }

   deleteLateFeesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(TenantsService.endpoint + "/LateFees", id, ids, null, additionalParameters);
   }

   deleteLateFees(id: number, ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLateFeesUrl(id, ids, additionalParameters));
   }

   getLateFeeSetupUrl(
      id: number,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/LateFeeSetup",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLateFeeSetup(
      id: number,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService.getSingle(this.getLateFeeSetupUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postLateFeeSetupUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/LateFeeSetup",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLateFeeSetup(
      id: number,
      item?: LateFeeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LateFeeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LateFeeModel> {
      return this.apiService
         .post(this.postLateFeeSetupUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteLateFeeSetupUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/LateFeeSetup",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLateFeeSetup(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLateFeeSetupUrl(id, itemID, deleteOptions, additionalParameters));
   }

   getLeasesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Leases",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLeases(
      id: number,
      itemID: number,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService.getSingle(this.getLeasesUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getLeasesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Leases",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLeasesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.getCollection(
         this.getLeasesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLeasesUrl(
      id: number,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Leases",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeases(
      id: number,
      item?: LeaseModel | null,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LeaseModel> {
      return this.apiService.postSingle(
         this.postLeasesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postLeasesCollectionUrl(
      id: number,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Leases",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLeasesCollection(
      id: number,
      items: Array<LeaseModel>,
      saveOptions?: Array<string | LeaseSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LeaseEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LeaseModel>> {
      return this.apiService.postCollection(
         this.postLeasesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteLeasesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LeaseDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Leases",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLeases(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LeaseDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLeasesUrl(id, ids, deleteOptions, additionalParameters));
   }

   postLeaseSortOrderUrl(
      customerID: number,
      leaseIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("customerID=" + customerID);
      if (leaseIDs !== undefined && leaseIDs !== null && leaseIDs.length > 0) {
         additionalParameters.push("leaseIDs=" + leaseIDs);
      }
      return this.apiService.postUrl(
         TenantsService.endpoint + "/LeaseSortOrder",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postLeaseSortOrder(
      customerID: number,
      leaseIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postLeaseSortOrderUrl(customerID, leaseIDs, additionalParameters));
   }

   getLeasingAgentUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/LeasingAgent",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLeasingAgent(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getLeasingAgentUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getLoansUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LoanEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Loans",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLoans(
      id: number,
      itemID: number,
      embeds?: Array<string | LoanEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LoanModel> {
      return this.apiService.getSingle(this.getLoansUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getLoansCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Loans",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLoansCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LoanModel>> {
      return this.apiService.getCollection(
         this.getLoansCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLoansUrl(
      id: number,
      saveOptions?: Array<string | LoanSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Loans",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLoans(
      id: number,
      item?: LoanModel | null,
      saveOptions?: Array<string | LoanSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<LoanModel> {
      return this.apiService.postSingle(this.postLoansUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postLoansCollectionUrl(
      id: number,
      saveOptions?: Array<string | LoanSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Loans",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postLoansCollection(
      id: number,
      items: Array<LoanModel>,
      saveOptions?: Array<string | LoanSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | LoanEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LoanModel>> {
      return this.apiService.postCollection(
         this.postLoansCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteLoansUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LoanDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Loans",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLoans(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | LoanDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLoansUrl(id, ids, deleteOptions, additionalParameters));
   }

   postMakePaymentsUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/MakePayments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMakePayments(
      id: number,
      item?: CreatePaymentModel | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CreatePaymentModel> {
      return this.apiService.postSingle(
         this.postMakePaymentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postMakePaymentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/MakePayments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postMakePaymentsCollection(
      id: number,
      payments?: Array<CreatePaymentModel> | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreatePaymentModel>> {
      return this.apiService.postCollection(
         this.postMakePaymentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         payments
      );
   }

   getOpenPrepaysCollectionUrl(
      id: number,
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/OpenPrepays",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOpenPrepaysCollection(
      id: number,
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenPrepayModel>> {
      return this.apiService.getCollection(
         this.getOpenPrepaysCollectionUrl(id, embeds, getOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   getAllOpenPrepaysCollectionUrl(
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/OpenPrepays",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOpenPrepaysCollection(
      embeds?: Array<string | OpenPrepayEmbedOptions> | null,
      getOptions?: Array<string | OpenPrepayGetOptions> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenPrepayModel>> {
      return this.apiService.getCollection(
         this.getAllOpenPrepaysCollectionUrl(embeds, getOptions, fields, pageSize, pageNumber, additionalParameters)
      );
   }

   getOpenReceivablesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/OpenReceivables",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getOpenReceivables(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<OpenReceivableModel> {
      return this.apiService.getSingle(
         this.getOpenReceivablesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getOpenReceivablesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/OpenReceivables",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getOpenReceivablesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenReceivableModel>> {
      return this.apiService.getCollection(
         this.getOpenReceivablesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllOpenReceivablesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/OpenReceivables",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllOpenReceivablesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | OpenReceivableGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<OpenReceivableModel>> {
      return this.apiService.getCollection(
         this.getAllOpenReceivablesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPaymentReversalsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PaymentReversals",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentReversals(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentReversalModel> {
      return this.apiService.getSingle(this.getPaymentReversalsUrl(id, itemID, embeds, additionalParameters));
   }

   getPaymentReversalsCollectionUrl(
      id: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PaymentReversals",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentReversalsCollection(
      id: number,
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.getCollection(this.getPaymentReversalsCollectionUrl(id, embeds, additionalParameters));
   }

   getAllPaymentReversalsCollectionUrl(
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PaymentReversals",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAllPaymentReversalsCollection(
      embeds?: Array<string | PaymentReversalEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.getCollection(this.getAllPaymentReversalsCollectionUrl(embeds, additionalParameters));
   }

   postPaymentReversalsUrl(
      id: number,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PaymentReversals",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPaymentReversals(
      id: number,
      item?: PaymentReversalModel | null,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentReversalModel> {
      return this.apiService.postSingle(this.postPaymentReversalsUrl(id, saveOptions, additionalParameters), item);
   }

   postPaymentReversalsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PaymentReversals",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postPaymentReversalsCollection(
      id: number,
      models?: Array<PaymentReversalModel> | null,
      saveOptions?: Array<string | PaymentReversalSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentReversalModel>> {
      return this.apiService.postCollection(
         this.postPaymentReversalsCollectionUrl(id, saveOptions, additionalParameters),
         models
      );
   }

   deletePaymentReversalsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/PaymentReversals",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePaymentReversals(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentReversalDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePaymentReversalsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPaymentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Payments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPayments(
      id: number,
      itemID: number,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentModel> {
      return this.apiService.getSingle(
         this.getPaymentsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPaymentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Payments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPaymentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | PaymentGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentModel>> {
      return this.apiService.getCollection(
         this.getPaymentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPaymentsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Payments",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPaymentsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentModel>> {
      return this.apiService.getCollection(
         this.getAllPaymentsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPaymentsUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Payments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPayments(
      id: number,
      item?: PaymentModel | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentModel> {
      return this.apiService.postSingle(
         this.postPaymentsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPaymentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Payments",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPaymentsCollection(
      id: number,
      items: Array<PaymentModel>,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PaymentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PaymentModel>> {
      return this.apiService.postCollection(
         this.postPaymentsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePaymentsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Payments",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePayments(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | PaymentDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePaymentsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPetsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PetEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Pets",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPets(
      id: number,
      itemID: number,
      embeds?: Array<string | PetEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PetModel> {
      return this.apiService.getSingle(this.getPetsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getPetsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Pets",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPetsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PetModel>> {
      return this.apiService.getCollection(
         this.getPetsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPetsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Pets",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPets(
      id: number,
      item?: PetModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PetModel> {
      return this.apiService.postSingle(this.postPetsUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postPetsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Pets",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPetsCollection(
      id: number,
      items: Array<PetModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PetEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PetModel>> {
      return this.apiService.postCollection(
         this.postPetsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deletePetsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(TenantsService.endpoint + "/Pets", id, ids, deleteOptions, additionalParameters);
   }

   deletePets(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePetsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPhoneNumberTypesUrl(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypes(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.getSingle(this.getPhoneNumberTypesUrl(itemID, embeds, fields, additionalParameters));
   }

   getPhoneNumberTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumberTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPhoneNumberTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPhoneNumberTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getAllPhoneNumberTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumberTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypes(
      item?: PhoneNumberTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.postSingle(
         this.postPhoneNumberTypesUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumberTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         phoneNumberTypes
      );
   }

   postAllPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postAllPhoneNumberTypesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         phoneNumberTypes
      );
   }

   deletePhoneNumberTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/PhoneNumberTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumberTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumberTypesUrl(ids, deleteOptions, additionalParameters));
   }

   getPrimaryContactUrl(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PrimaryContact",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrimaryContact(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService.getSingle(this.getPrimaryContactUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postPrimaryContactUrl(
      id: number,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/PrimaryContact",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPrimaryContact(
      id: number,
      item?: ContactModel | null,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService
         .post(this.postPrimaryContactUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   getPrimaryContactPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PrimaryContactPhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPrimaryContactPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getPrimaryContactPhoneNumbersUrl(
            id,
            itemID,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPrimaryContactPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/PrimaryContactPhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPrimaryContactPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPrimaryContactPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPropertyUrl(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Property",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperty(
      id: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(this.getPropertyUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getProspectUrl(
      id: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Prospect",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProspect(
      id: number,
      embeds?: Array<string | ProspectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ProspectModel> {
      return this.apiService.getSingle(this.getProspectUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         TenantsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRecurringChargesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RecurringCharges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRecurringCharges(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeModel> {
      return this.apiService.getSingle(
         this.getRecurringChargesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRecurringChargesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RecurringCharges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeModel>> {
      return this.apiService.getCollection(
         this.getRecurringChargesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRecurringChargesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RecurringCharges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRecurringCharges(
      id: number,
      item?: RecurringChargeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeModel> {
      return this.apiService.postSingle(
         this.postRecurringChargesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRecurringChargesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RecurringCharges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRecurringChargesCollection(
      id: number,
      items: Array<RecurringChargeModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RecurringChargeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeModel>> {
      return this.apiService.postCollection(
         this.postRecurringChargesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteRecurringChargesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/RecurringCharges",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteRecurringCharges(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteRecurringChargesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getRecurringChargeSummariesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RecurringChargeSummaries",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargeSummaries(
      id: number,
      itemID: number,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RecurringChargeSummaryModel> {
      return this.apiService.getSingle(
         this.getRecurringChargeSummariesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRecurringChargeSummariesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RecurringChargeSummaries",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRecurringChargeSummariesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RecurringChargeSummaryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | RecurringChargeSummaryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RecurringChargeSummaryModel>> {
      return this.apiService.getCollection(
         this.getRecurringChargeSummariesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRefundSecurityDepositUrl(
      id: number,
      saveOptions?: Array<string | RefundSecurityDepositSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RefundSecurityDeposit",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postRefundSecurityDeposit(
      id: number,
      refundSecurityDeposit?: RefundSecurityDepositModel | null,
      saveOptions?: Array<string | RefundSecurityDepositSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService
         .post(this.postRefundSecurityDepositUrl(id, saveOptions, additionalParameters), refundSecurityDeposit)
         .pipe(map((response) => response.body));
   }

   getRevenueRenewalsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RevenueRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RevenueRenewals",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRevenueRenewals(
      id: number,
      itemID: number,
      embeds?: Array<string | RevenueRenewalEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RevenueRenewalModel> {
      return this.apiService.getSingle(
         this.getRevenueRenewalsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRevenueRenewalsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RevenueRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RevenueRenewals",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRevenueRenewalsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RevenueRenewalEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RevenueRenewalModel>> {
      return this.apiService.getCollection(
         this.getRevenueRenewalsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRMVoIPCallHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RMVoIPCallHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.getSingle(
         this.getRMVoIPCallHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRMVoIPCallHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RMVoIPCallHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getRMVoIPCallHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllRMVoIPCallHistoryCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/RMVoIPCallHistory",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllRMVoIPCallHistoryCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getAllRMVoIPCallHistoryCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRMVoIPCallHistoryUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistory(
      id: number,
      item?: RMVoIPCallHistoryModel | null,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.postSingle(
         this.postRMVoIPCallHistoryUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRMVoIPCallHistoryCollectionUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistoryCollection(
      id: number,
      items: Array<RMVoIPCallHistoryModel>,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.postCollection(
         this.postRMVoIPCallHistoryCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postRunScreeningUrl(
      id: number,
      saveOptions?: Array<string | ScreeningSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/RunScreening",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postRunScreening(
      id: number,
      screeningArgumentsModel?: ScreeningArgumentsModel | null,
      saveOptions?: Array<string | ScreeningSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ScreeningModel>> {
      return this.apiService
         .post(this.postRunScreeningUrl(id, saveOptions, additionalParameters), screeningArgumentsModel)
         .pipe(map((response) => response.body));
   }

   postSaveRecurringChargeSortOrdersUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SaveRecurringChargeSortOrders",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSaveRecurringChargeSortOrders(
      id: number,
      recurringChargeSummaries?: Array<RecurringChargeSummaryModel> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.postCollection(
         this.postSaveRecurringChargeSortOrdersUrl(id, additionalParameters),
         recurringChargeSummaries
      );
   }

   getScreeningMappedFieldValuesCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ScreeningMappedFieldValues",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreeningMappedFieldValuesCollection(
      id: number,
      additionalParameters?: Array<string>
   ): Observable<Array<ContactScreeningInformationModel>> {
      return this.apiService.getCollection(this.getScreeningMappedFieldValuesCollectionUrl(id, additionalParameters));
   }

   getScreeningReportTypesForUserCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ScreeningReportTypesForUser",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreeningReportTypesForUserCollection(
      id: number,
      additionalParameters?: Array<string>
   ): Observable<Array<ScreeningReport>> {
      return this.apiService.getCollection(this.getScreeningReportTypesForUserCollectionUrl(id, additionalParameters));
   }

   getScreeningsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ScreeningEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Screenings",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getScreenings(
      id: number,
      itemID: number,
      embeds?: Array<string | ScreeningEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ScreeningModel> {
      return this.apiService.getSingle(
         this.getScreeningsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getScreeningsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Screenings",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getScreeningsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ScreeningEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ScreeningModel>> {
      return this.apiService.getCollection(
         this.getScreeningsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | TenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | TenantEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(TenantsService.endpoint + "/Search", null, null, null, null, additionalParameters);
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getSecurityDepositHeldUrl(
      id: number,
      propertyID: number,
      unitID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      additionalParameters.push("unitID=" + unitID);
      return this.apiService.getUrl(
         TenantsService.endpoint + "/SecurityDepositHeld",
         id,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSecurityDepositHeld(
      id: number,
      propertyID: number,
      unitID: number,
      additionalParameters?: Array<string>
   ): Observable<number> {
      return this.apiService.getSingle(this.getSecurityDepositHeldUrl(id, propertyID, unitID, additionalParameters));
   }

   postSecurityDepositsUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SecurityDeposits",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postSecurityDeposits(
      id: number,
      item?: SecurityDepositModel | null,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SecurityDepositModel> {
      return this.apiService.postSingle(this.postSecurityDepositsUrl(id, saveOptions, additionalParameters), item);
   }

   postSecurityDepositsCollectionUrl(
      id: number,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SecurityDeposits",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postSecurityDepositsCollection(
      id: number,
      items: Array<SecurityDepositModel>,
      saveOptions?: Array<string | ChargeSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SecurityDepositModel>> {
      return this.apiService.postCollection(
         this.postSecurityDepositsCollectionUrl(id, saveOptions, additionalParameters),
         items
      );
   }

   getSecurityDepositSummariesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/SecurityDepositSummaries",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSecurityDepositSummaries(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SecurityDepositSummaryModel> {
      return this.apiService.getSingle(this.getSecurityDepositSummariesUrl(id, itemID, embeds, additionalParameters));
   }

   getSecurityDepositSummariesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/SecurityDepositSummaries",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSecurityDepositSummariesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EntityEmbedOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SecurityDepositSummaryModel>> {
      return this.apiService.getCollection(
         this.getSecurityDepositSummariesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSecurityDepositViaEpayUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SecurityDepositViaEpay",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postSecurityDepositViaEpay(
      id: number,
      securityDeposit?: SecurityDepositEpayPaymentModel | null,
      additionalParameters?: Array<string>
   ): Observable<EpayPaymentConfirmationModel> {
      return this.apiService
         .post(this.postSecurityDepositViaEpayUrl(id, additionalParameters), securityDeposit)
         .pipe(map((response) => response.body));
   }

   getServiceManagerProjectsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ServiceManagerProjects",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerProjects(
      id: number,
      itemID: number,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerProjectModel> {
      return this.apiService.getSingle(
         this.getServiceManagerProjectsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getServiceManagerProjectsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ServiceManagerProjects",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerProjectsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerProjectModel>> {
      return this.apiService.getCollection(
         this.getServiceManagerProjectsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postServiceManagerProjectsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ServiceManagerProjects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerProjects(
      id: number,
      item?: ServiceManagerProjectModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ServiceManagerProjectModel> {
      return this.apiService.postSingle(
         this.postServiceManagerProjectsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postServiceManagerProjectsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ServiceManagerProjects",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postServiceManagerProjectsCollection(
      id: number,
      items: Array<ServiceManagerProjectModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ServiceManagerProjectEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ServiceManagerProjectModel>> {
      return this.apiService.postCollection(
         this.postServiceManagerProjectsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   getShortTermReservationsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ShortTermReservations",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getShortTermReservations(
      id: number,
      itemID: number,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ShortTermReservationModel> {
      return this.apiService.getSingle(
         this.getShortTermReservationsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getShortTermReservationsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/ShortTermReservations",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getShortTermReservationsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ShortTermReservationModel>> {
      return this.apiService.getCollection(
         this.getShortTermReservationsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postShortTermReservationsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ShortTermReservations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postShortTermReservations(
      id: number,
      item?: ShortTermReservationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ShortTermReservationModel> {
      return this.apiService.postSingle(
         this.postShortTermReservationsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postShortTermReservationsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ShortTermReservations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postShortTermReservationsCollection(
      id: number,
      items: Array<ShortTermReservationModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ShortTermReservationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ShortTermReservationModel>> {
      return this.apiService.postCollection(
         this.postShortTermReservationsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteShortTermReservationsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/ShortTermReservations",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteShortTermReservations(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteShortTermReservationsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getSubsidiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | SubsidyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Subsidies",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSubsidies(
      id: number,
      itemID: number,
      embeds?: Array<string | SubsidyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SubsidyModel> {
      return this.apiService.getSingle(
         this.getSubsidiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getSubsidiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SubsidyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Subsidies",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSubsidiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SubsidyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SubsidyModel>> {
      return this.apiService.getCollection(
         this.getSubsidiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getSubsidyTenantsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/SubsidyTenants",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSubsidyTenants(
      id: number,
      itemID: number,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SubsidyTenantModel> {
      return this.apiService.getSingle(
         this.getSubsidyTenantsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getSubsidyTenantsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/SubsidyTenants",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSubsidyTenantsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SubsidyTenantModel>> {
      return this.apiService.getCollection(
         this.getSubsidyTenantsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSubsidyTenantsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SubsidyTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSubsidyTenants(
      id: number,
      item?: SubsidyTenantModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SubsidyTenantModel> {
      return this.apiService.postSingle(
         this.postSubsidyTenantsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postSubsidyTenantsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/SubsidyTenants",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSubsidyTenantsCollection(
      id: number,
      items: Array<SubsidyTenantModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SubsidyTenantEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SubsidyTenantModel>> {
      return this.apiService.postCollection(
         this.postSubsidyTenantsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteSubsidyTenantsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/SubsidyTenants",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteSubsidyTenants(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteSubsidyTenantsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Tasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.getSingle(this.getTasksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Tasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.getCollection(
         this.getTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTasksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Tasks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTasks(
      id: number,
      item?: TaskModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.postSingle(this.postTasksUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postTasksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Tasks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTasksCollection(
      id: number,
      items: Array<TaskModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.postCollection(
         this.postTasksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteTasksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Tasks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTasks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTasksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTenantBillsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantBills",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantBills(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantBillModel> {
      return this.apiService.getSingle(
         this.getTenantBillsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTenantBillsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantBills",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantBillsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantBillModel>> {
      return this.apiService.getCollection(
         this.getTenantBillsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllTenantBillsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantBills",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllTenantBillsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantBillModel>> {
      return this.apiService.getCollection(
         this.getAllTenantBillsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTenantBillsUrl(
      id: number,
      saveOptions?: Array<string | TenantBillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TenantBills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTenantBills(
      id: number,
      item?: TenantBillModel | null,
      saveOptions?: Array<string | TenantBillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantBillModel> {
      return this.apiService.postSingle(
         this.postTenantBillsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postTenantBillsCollectionUrl(
      id: number,
      saveOptions?: Array<string | TenantBillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TenantBills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTenantBillsCollection(
      id: number,
      items: Array<TenantBillModel>,
      saveOptions?: Array<string | TenantBillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | TenantBillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantBillModel>> {
      return this.apiService.postCollection(
         this.postTenantBillsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteTenantBillsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | TenantBillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/TenantBills",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTenantBills(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | TenantBillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTenantBillsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTenantCheckDetailsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantCheckDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantCheckDetails",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantCheckDetails(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantCheckDetailEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantCheckDetailModel> {
      return this.apiService.getSingle(this.getTenantCheckDetailsUrl(id, itemID, embeds, fields, additionalParameters));
   }

   getTenantCheckDetailsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantCheckDetails",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantCheckDetailsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantCheckDetailModel>> {
      return this.apiService.getCollection(
         this.getTenantCheckDetailsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getTenantChecksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantChecks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantChecks(
      id: number,
      itemID: number,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantCheckModel> {
      return this.apiService.getSingle(
         this.getTenantChecksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTenantChecksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantChecks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTenantChecksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantCheckModel>> {
      return this.apiService.getCollection(
         this.getTenantChecksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllTenantChecksCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantChecks",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllTenantChecksCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantCheckModel>> {
      return this.apiService.getCollection(
         this.getAllTenantChecksCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTenantChecksUrl(
      id: number,
      saveOptions?: Array<string | TenantCheckSaveOptions> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TenantChecks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTenantChecks(
      id: number,
      item?: TenantCheckModel | null,
      saveOptions?: Array<string | TenantCheckSaveOptions> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<TenantCheckModel> {
      return this.apiService.postSingle(
         this.postTenantChecksUrl(id, saveOptions, embeds, fields, additionalParameters),
         item
      );
   }

   postTenantChecksCollectionUrl(
      id: number,
      saveOptions?: Array<string | TenantCheckSaveOptions> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TenantChecks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postTenantChecksCollection(
      id: number,
      items: Array<TenantCheckModel>,
      saveOptions?: Array<string | TenantCheckSaveOptions> | null,
      embeds?: Array<string | TenantCheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TenantCheckModel>> {
      return this.apiService.postCollection(
         this.postTenantChecksCollectionUrl(id, saveOptions, embeds, fields, additionalParameters),
         items
      );
   }

   deleteTenantChecksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | TenantCheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/TenantChecks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteTenantChecks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | TenantCheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTenantChecksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getTenantLeaseSummaryColumnsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantLeaseSummaryColumns",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantLeaseSummaryColumnsCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<ExpressColumnDefinitionModel>> {
      return this.apiService.getCollection(this.getTenantLeaseSummaryColumnsCollectionUrl(additionalParameters));
   }

   getTenantSDBankUrl(tenantID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("tenantID=" + tenantID);
      return this.apiService.getUrl(
         TenantsService.endpoint + "/TenantSDBank",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTenantSDBank(tenantID: number, additionalParameters?: Array<string>): Observable<number> {
      return this.apiService.getSingle(this.getTenantSDBankUrl(tenantID, additionalParameters));
   }

   postTestScriptUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TestScript",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestScript(
      id: number,
      model?: ScriptBuilderModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postTestScriptUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getTransactionsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | TransactionGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Transactions",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTransactionsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | TransactionGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TransactionModel>> {
      return this.apiService.getCollection(
         this.getTransactionsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllTransactionsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | TransactionGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Transactions",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllTransactionsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | TransactionGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TransactionModel>> {
      return this.apiService.getCollection(
         this.getAllTransactionsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTransferPaymentUrl(
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TransferPayment",
         null,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postTransferPayment(
      model?: TransferTenantPaymentModel | null,
      saveOptions?: Array<string | PaymentSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PaymentModel> {
      return this.apiService
         .post(this.postTransferPaymentUrl(saveOptions, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   postTransferSecurityDepositsUrl(
      id: number,
      saveOptions?: Array<string | TransferSecurityDepositSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/TransferSecurityDeposits",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postTransferSecurityDeposits(
      id: number,
      transferSecurityDeposit?: TransferSecurityDepositModel | null,
      saveOptions?: Array<string | TransferSecurityDepositSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(
         this.postTransferSecurityDepositsUrl(id, saveOptions, additionalParameters),
         transferSecurityDeposit
      );
   }

   getUpdateCashPayAccountUrl(
      accountID: number,
      accountNumber?: string | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("accountID=" + accountID);
      if (accountNumber !== undefined && accountNumber !== null) {
         additionalParameters.push("accountNumber=" + accountNumber);
      }
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UpdateCashPayAccount",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateCashPayAccount(
      accountID: number,
      accountNumber?: string | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService.getSingle(this.getUpdateCashPayAccountUrl(accountID, accountNumber, additionalParameters));
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(
         this.postUserDefinedFieldsUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      items: Array<UserDefinedFieldModel>,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      items: Array<UserDefinedFieldModel>,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      id: number,
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      id: number,
      items: Array<UserDefinedValueModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      items: Array<UserDefinedValueModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   postValidateWebUserCredentialsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/ValidateWebUserCredentials",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postValidateWebUserCredentials(
      webUserAuthentication?: WebUserAuthenticationModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postValidateWebUserCredentialsUrl(additionalParameters), webUserAuthentication);
   }

   getVehiclesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Vehicles",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVehicles(
      id: number,
      itemID: number,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VehicleModel> {
      return this.apiService.getSingle(
         this.getVehiclesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getVehiclesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Vehicles",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getVehiclesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VehicleModel>> {
      return this.apiService.getCollection(
         this.getVehiclesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postVehiclesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Vehicles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVehicles(
      id: number,
      item?: VehicleModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<VehicleModel> {
      return this.apiService.postSingle(
         this.postVehiclesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postVehiclesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Vehicles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVehiclesCollection(
      id: number,
      items: Array<VehicleModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VehicleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VehicleModel>> {
      return this.apiService.postCollection(
         this.postVehiclesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteVehiclesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Vehicles",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteVehicles(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteVehiclesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getViolationsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Violations",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getViolations(
      id: number,
      itemID: number,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ViolationModel> {
      return this.apiService.getSingle(
         this.getViolationsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getViolationsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/Violations",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getViolationsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ViolationModel>> {
      return this.apiService.getCollection(
         this.getViolationsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postViolationsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Violations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postViolations(
      id: number,
      item?: ViolationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ViolationModel> {
      return this.apiService.postSingle(
         this.postViolationsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postViolationsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/Violations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postViolationsCollection(
      id: number,
      items: Array<ViolationModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ViolationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ViolationModel>> {
      return this.apiService.postCollection(
         this.postViolationsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteViolationsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/Violations",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteViolations(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteViolationsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getWebUserAccountsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/WebUserAccounts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getWebUserAccounts(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserAccountModel> {
      return this.apiService.getSingle(
         this.getWebUserAccountsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getWebUserAccountsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/WebUserAccounts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getWebUserAccountsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserAccountModel>> {
      return this.apiService.getCollection(
         this.getWebUserAccountsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postWebUserAccountsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/WebUserAccounts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWebUserAccounts(
      id: number,
      item?: WebUserAccountModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserAccountModel> {
      return this.apiService.postSingle(
         this.postWebUserAccountsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postWebUserAccountsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/WebUserAccounts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWebUserAccountsCollection(
      id: number,
      items: Array<WebUserAccountModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserAccountEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserAccountModel>> {
      return this.apiService.postCollection(
         this.postWebUserAccountsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteWebUserAccountsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         TenantsService.endpoint + "/WebUserAccounts",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteWebUserAccounts(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteWebUserAccountsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getWebUsersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/WebUsers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getWebUsers(
      id: number,
      itemID: number,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserModel> {
      return this.apiService.getSingle(
         this.getWebUsersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getWebUsersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         TenantsService.endpoint + "/WebUsers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getWebUsersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserModel>> {
      return this.apiService.getCollection(
         this.getWebUsersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postWebUsersUrl(
      id: number,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/WebUsers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWebUsers(
      id: number,
      item?: WebUserModel | null,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<WebUserModel> {
      return this.apiService.postSingle(
         this.postWebUsersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postWebUsersCollectionUrl(
      id: number,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         TenantsService.endpoint + "/WebUsers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postWebUsersCollection(
      id: number,
      items: Array<WebUserModel>,
      saveOptions?: Array<string | WebUserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | WebUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<WebUserModel>> {
      return this.apiService.postCollection(
         this.postWebUsersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }
}
