<div class="tile-wrapper">
   <div class="tile header-border">
      <div class="tile-header-wrapper">
         <div class="tile-title">
            {{title}}
            <div
               *ngIf="showTag && $any(tagText?.length) > 0"
               class="tag tag-{{tagText | lowercase}}"
            >{{tagText}}</div>
         </div>
         <div class="action">
            <lcs-link
               *ngIf="isViewMore"
               [cssClasses]="['view-more-icon']"
               [contentTemplate]="contentTemplate"
               (clicked)="openViewMoreAction(viewMoreComponent)"
            >
               <ng-template #contentTemplate>
                  <i class="material-icons icon-color">filter_none</i>
               </ng-template>
            </lcs-link>
         </div>
      </div>
      <div class="tile-content">
         <ng-content></ng-content>
      </div>
   </div>
</div>