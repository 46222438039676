import { Component, OnDestroy } from "@angular/core";
import { EstimateDetailService } from "./estimate-detail.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";

@Component({
   selector: "owa-estimate-detail",
   templateUrl: "./estimate-detail.component.html",
   providers: [EstimateDetailService],
})
export class EstimateDetailComponent implements OnDestroy {
   public showPrintIcon: boolean;
   public unsubscribe: Subject<void> = new Subject<void>();

   constructor(
      private router: Router,
      private activateRoute: ActivatedRoute,
      private estimateDetailService: EstimateDetailService
   ) {
      this.estimateDetailService.showPrintIcon.subscribe((e) => (this.showPrintIcon = e));
   }

   printEstimate() {
      this.router.navigate(["./estimateprint"], {
         relativeTo: this.activateRoute,
      });
      this.estimateDetailService.showPrintIcon.next(false);
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
