import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DialogRegistrationService } from "@lcs/dialog/services/dialog-registration.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { EmailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/email.model";
import { EmailService } from "projects/libraries/owa-gateway-sdk/src/lib/services/email.service";

import { OWASessionService } from "../session/owa-session.service";
import { EmailConfigurationModel } from "./email-configuration.model";

@Component({
   selector: "owa-email",
   templateUrl: "./email.component.html",
   styleUrls: ["./email.component.css"],
})
export class EmailComponent implements OnInit {
   configuration = new EmailConfigurationModel();

   emailModel: EmailModel;

   sendButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   requiredValidation: ValidationModel;

   constructor(
      private dialogRegistrationService: DialogRegistrationService,
      private owaSessionService: OWASessionService,
      private errorMessageService: ErrorMessageService,
      private emailService: EmailService
   ) {
      this.requiredValidation = new ValidationModel();
      this.requiredValidation.required = true;
   }

   ngOnInit() {
      this.emailModel = this.configuration.Email;
      this.emailModel.From = this.owaSessionService.currentOWASessionInfo.CurrentUser.EmailAddress;
   }

   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.sendButtonStatus = LcsProgressButtonStatus.InProgress;
         this.emailService.sendEmail(this.emailModel).subscribe(
            () => {
               this.sendButtonStatus = LcsProgressButtonStatus.Success;
               this.dialogRegistrationService.closeAllModals();
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
               this.sendButtonStatus = LcsProgressButtonStatus.Error;
            }
         );
      } else {
         this.sendButtonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }
}
