import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { map, Observable } from "rxjs";

import { EstimateDetailService } from "../estimate-detail.service";

@Component({
   selector: "owa-estimatedetail-list-view-more",
   templateUrl: "./detail-list-view-more.component.html",
})
export class EstimateDetailListViewMoreComponent {
   estimateDetailViewItems: Observable<EstimateViewModel>;
   loading: Observable<boolean>;

   constructor(
      private estimateDetailService: EstimateDetailService,
      private router: Router,
      private activatedRoute: ActivatedRoute
   ) {
      this.estimateDetailViewItems = this.estimateDetailService.estimate.pipe(map((estimates) => estimates));
      this.loading = this.estimateDetailService.loading;
   }

   onCloseClick(): void {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
   }
}
