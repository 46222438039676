import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { FilterControlConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-control-configuration.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterDefinitionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-definition.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

export class DataTableFilterModel extends FilterControlConfigurationModel {
   FilterOption: FilterOption;

   DefaultOperation: FilterOperations;

   ValueSource: ValueSourceModel;

   static fromTransferModel(filterDefinitionModel: FilterDefinitionModel): DataTableFilterModel {
      const filterConfigurationModel = super.fromTransferModel(filterDefinitionModel);

      const filterOption = new FilterOption(
         filterConfigurationModel.FilterName,
         null,
         null,
         filterDefinitionModel.Label,
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         null,
         filterConfigurationModel.DataType
      );

      const dataTableFilterModel: DataTableFilterModel = {
         ...filterConfigurationModel,
         FilterOption: filterOption,
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterOpera... Remove this comment to see the full error message
         DefaultOperation: null,
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValueSource... Remove this comment to see the full error message
         ValueSource: null,
      };

      return dataTableFilterModel;
   }
}
