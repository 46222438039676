import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ContextService } from "../../action-context/context.service";

@Component({
   selector: "lcs-coming-soon",
   template: ComingSoonComponent.templateString,
})
export class ComingSoonComponent implements OnInit {
   static templateString: string = `
   <div class="coming-soon form-container" *ngIf="!isContentTile" [ngClass]="{'coming-soon-placeholder': !isFullPage && !isContentTile, 'coming-soon-full-page': isFullPage}">
      <div class="full-page form-element flex-full" *ngIf="isFullPage">
         <h1>{{featureTitle}} Coming Soon</h1>
         <span>We are currently working on this page and appreciate your patience.  Please try again later.</span>
         <button class="mat-button mat-raised-button" [routerLink]="['/']" (click)="emitReturnToDashboard()">Return Home</button>
      </div>
   </div>
   <div class="coming-soon coming-soon-placeholder" *ngIf="isContentTile"></div>`;

   @Input() isContentTile: boolean = false;

   @Input() isFullPage: boolean = false;

   @Input() title: string;

   @Output() returnToDashboard: EventEmitter<any> = new EventEmitter();

   featureTitle: string = "";

   constructor(private contextService: ContextService) {}

   ngOnInit(): void {
      if (this.contextService.action != null) {
         this.featureTitle = this.contextService.action.Description;

         if (this.featureTitle) {
            this.featureTitle += " - ";
         }
      }

      if (this.title != null) {
         this.featureTitle = this.title;
      }
   }

   emitReturnToDashboard() {
      this.returnToDashboard.emit();
   }
}

export function IsComingSoonComponent(obj: any): obj is ComingSoonComponent {
   let isComingSoon = true;
   if (!("isFullPage" in obj)) {
      isComingSoon = false;
   }
   if (!("isContentTile" in obj)) {
      isComingSoon = false;
   }
   if (!("returnToDashboard" in obj)) {
      isComingSoon = false;
   }
   if (!("_title" in obj)) {
      isComingSoon = false;
   }
   return isComingSoon;
}
