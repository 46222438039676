import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { OverlayPanelsModule } from "@lcs/overlay-panel/overlay-panels.module";
import { SelectModule } from "@lcs/select/select.module";

import { DatePickerModule } from "../date-picker/date-picker.module";
import { DateRangePickerComponent } from "./date-range-picker.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, DatePickerModule, OverlayPanelsModule, SelectModule],
   declarations: [DateRangePickerComponent],
   exports: [DateRangePickerComponent],
})
export class DateRangePickerModule {}
