import { Component, Input, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-toggleable-input-reportparameter",
   templateUrl: "toggleable-input-report-parameter.component.html",
})
export class ToggleableInputParameterComponent implements OnInit, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() set name(val: string) {
      this._name = val;
      this.checkboxName = val + "-checkbox";
      this.inputName = val + "-input";
   }

   get name(): string {
      return this._name;
   }

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   isChecked: boolean;

   value: any;

   public checkboxName: string;

   public inputName: string;

   private _name: string;

   private _parameter: ReportReportParameterViewModel;

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeReportParameterValue();
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   updateValue(value: any) {
      this.value = value;
      this.updateReportParameterValue();
   }

   onCheckboxChange(checkboxChange: boolean) {
      this.isChecked = checkboxChange;
      this.updateReportParameterValue();
   }

   private updateReportParameterValue() {
      const paramValue = this.value != null && this.value !== "" ? this.value : 0;
      this.reportParameterValueModel.value = `${paramValue} ${this.isChecked}`;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      if (this.reportParameterValueModel && this.reportParameterValueModel.value) {
         const values = this.reportParameterValueModel.value.split(" ");
         if (values.length === 2) {
            this.value = values[0];
            this.isChecked = values[1].toString().toLowerCase() === "true";
         } else {
            this.value = 0;
            this.isChecked = false;
         }
      }
   }
}
