<lcs-progress-button
   [disabled]="disabled"
   [formId]="formId"
   [type]="type"
   [raised]="isRaisedButton"
   [buttonCustomClasses]="buttonCustomClasses"
   [status]="status"
   [multiSelectActionButton]="multiSelectActionButton"
   [loadingOverlayConfiguration]="loadingOverlayConfiguration"
   (statusChange)="onStatusChange($event)"
   (buttonClick)="onButtonClick()"
>
   <div
      [class.flex]="actionBarAction || icon"
      [class.raised]="isRaisedButton"
   >
      <lcs-icon
         #iconComponent
         [class.raised]="isRaisedButton"
         [class.no-text]="!text"
         [style.margin-bottom]="actionBarAction?.bottomMarginForIcon"
         *ngIf="icon"
         [icon]="icon"
      ></lcs-icon>
      {{ text }}
   </div>
</lcs-progress-button>