import { Injectable } from "@angular/core";
import { EnumerationInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/enumeration-information.model";
import { Observable } from "rxjs";

import { ApiService } from "../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class EnumerationInformationService {
   static readonly EnumerationInformationResource = "EnumerationInformation";
   static readonly endpoint = "EnumerationInformation";
   constructor(private apiService: ApiService) {}
   getEnumerationInformationCollectionUrl(name?: string | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (name !== undefined && name !== null) {
         additionalParameters.push("name=" + name);
      }
      return this.apiService.getUrl(
         EnumerationInformationService.endpoint + "/EnumerationInformation",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEnumerationInformationCollection(
      name?: string | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EnumerationInformationModel>> {
      return this.apiService.getCollection(this.getEnumerationInformationCollectionUrl(name, additionalParameters));
   }
}
