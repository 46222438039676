////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum HistoryType {
   NotSet = 0,
   Note = 1,
   Task = 3,
   System = 5,
   ServiceManagerIssue = 6,
   Call = 7,
   Visit = 8,
   Email = 9,
   SentEmail = 10,
   Appointment = 11,
   Screening = 12,
   NDT = 13,
   OWAFile = 14,
   qManage = 15,
   Maintenance = 16,
   WebAccountUpdate = 17,
   PhoneBroadcast = 18,
   TextMessages = 19,
   SignableDocument = 20,
   Inspections = 21,
   ServiceTechTimes = 22,
   ProspectApplications = 23,
   OwnershipTransfer = 24,
   MakeReadyProcesses = 25,
   AccountStatements = 26,
   GuestCard = 27,
   Violations = 28,
   ViolationStages = 29,
   ViolationNotes = 30,
   ViolationStageReverts = 31,
   CompletedWebConversations = 32,
   Web = 33,
   LeaseRenewalOffers = 34,
   PollResponse = 35,
   AmenityReservationRequest = 36,
   ArchitecturalRequest = 37,
}
