import { Component, OnInit } from "@angular/core";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { OwnerContributionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/owner-contributions.service";

@Component({
   selector: "owa-completed",
   templateUrl: "./completed.component.html",
   styleUrls: ["./completed.component.css"],
})
export class CompletedComponent implements OnInit {
   // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(refresh: b... Remove this comment to see the full error message
   valueCallBack: (refresh: boolean) => void = null;
   paymentType: string = "";

   constructor(
      private dialgoueService: CurrentDialogService,
      private ownerContributionService: OwnerContributionsService
   ) {
      this.ownerContributionService.PaymentType.subscribe((s) => {
         this.paymentType = s;
      });
   }

   ngOnInit() { }

   onClickOk() {
      this.valueCallBack(true);
      this.dialgoueService.closeDialog();
   }
}
