import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { BudgetYearModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/budget-year.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { BudgetYearsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/budget-years.service";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-budget-years-report-parameter",
   templateUrl: "budget-years-report-parameter.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class BudgetYearsReportParameterComponent implements OnInit, OnDestroy {
   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   valueSource: ValueSourceModel;

   selectedValues: Array<number>;

   selectionChange: SelectionChangeModel;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private budgetYearsService: BudgetYearsService
   ) {}

   ngOnInit() {
      this.initializeReportParameterValue();

      let propertyIDs = "All";
      const propOwnerIDParameter = this.reportParametersService.reportParameterValues.get(ReportParameter.PropOwnerIDs);
      if (propOwnerIDParameter) {
         propertyIDs = propOwnerIDParameter.value;
      }
      this.getBudgetTypes(propertyIDs);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   selectionChanged() {
      const checkedItems = this.selectionChange.checkedItems.filter((c) => c.value != null && c.value !== "");
      let values = new Array<any>();
      const selectAll = this.selectionChange.selectAll;
      this.reportParameterValueModel.value = "";
      if (!this.parameter.ReportParameterValueSource.AllowsMultipleValues) {
         if (checkedItems.length === 1 && checkedItems[0].value != null) {
            this.reportParameterValueModel.value = checkedItems[0].value;
            values = [this.reportParameterValueModel.value];
         }
      } else {
         const allValue = this.parameter.ReportParameterValueSource.AllValue;
         if (selectAll && allValue) {
            this.reportParameterValueModel.value = allValue;
            values = [allValue];
         } else if (checkedItems.length > 0) {
            values = checkedItems.map((f) => f.value);
            this.reportParameterValueModel.value = "(" + values.join(",") + ")";
         } else {
            this.reportParameterValueModel.value = "";
         }
      }
      this.reportParameterValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   private getBudgetTypes(propertyIDs: string) {
      this.budgetYearsService
         .getBudgetYearsCollection(new Date().getFullYear(), propertyIDs)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            this.processCollection(results);
         });
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );

      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedParameter) => {
            if (
               updatedParameter.reportParameter === ReportParameter.PropOwnerIDs ||
               updatedParameter.reportParameter === ReportParameter.PropertyIDs
            ) {
               this.getBudgetTypes(updatedParameter.value);
            }
         });
   }

   private processCollection(results: Array<BudgetYearModel>) {
      const selectedValues = new Array<number>();
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.AllowsMultipleValues = true;
      valueSource.AllowsSelectAll = true;
      valueSource.AllValue = "All";
      if (results) {
         for (const budgetYear of results) {
            const item = new SelectorItemModel();
            item.displayValue = budgetYear.DisplayValue;
            item.value = budgetYear.Year;
            valueSource.StaticValues.push(item);
         }
      }
      this.valueSource = valueSource;
      this.selectedValues = selectedValues;
   }
}
