import { Component, Input, isDevMode, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-checkbox-report-parameter",
   templateUrl: "checkbox-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class CheckboxReportParameterComponent implements OnInit, UserInputComponent, OnDestroy {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      if (value) {
         this._parameter = value;
      } else if (isDevMode()) {
         console.warn("Invalid attempt to set parameter input to value=", value);
      }
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   reportParameterValueModel: ReportParameterValueModel;

   checked: boolean = false;

   private _parameter: ReportReportParameterViewModel = new ReportReportParameterViewModel();

   private unsubscribe = new Subject<void>();

   constructor(
      private reportParametersService: ReportParametersService,
      private reportParameterControlStatusService: ReportParameterControlStatusService
   ) {
      this.reportParametersService.reportParameterStatusChanged
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((status) => {
            if (this._parameter && status.reportParameter === this._parameter.ReportParameterID) {
               this.disabled = status.disabled;
               if (
                  this.disabled &&
                  (this._parameter.ReportParameterID === ReportParameter.RestrictByOwnerContractDates ||
                     this._parameter.ReportParameterID === ReportParameter.BatchProps)
               ) {
                  this.valueChanged(false);
               }
            }
         });
   }

   ngOnInit() {
      this.initializeReportParameterValue();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   valueChanged(checked: boolean) {
      if (this.checked === checked) {
         return;
      }
      this.reportParameterValueModel.value = checked;
      this.checked = checked;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      this.checked = GlobalsService.convertStringToBoolean(this.reportParameterValueModel.value);
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => {
            this.updateReportParameters(updatedValue);
         });

      if (!this.disabled) {
         this.disabled = this.reportParameterControlStatusService.getInitialDisableValue(
            this._parameter.ReportID,
            this.reportParameterValueModel.reportParameter,
            Array.from(this.reportParametersService.reportParameterValues.values())
         );
      }
   }

   private updateReportParameters(updatedValue: ReportParameterValueModel) {
      const isDisabled = this.reportParameterControlStatusService.getDisableParameterValue(
         this._parameter.ReportID,
         updatedValue,
         this.reportParameterValueModel.reportParameter
      );
      if (isDisabled) {
         this.checked = false;
         this.disabled = true;
      } else if (isDisabled === false) {
         this.disabled = false;
      }
   }
}
