<div
   class="text-area-wrapper"
   [ngStyle]="textAreaWrapperCustomStyle"
>
   <ng-container *ngIf="expandable; else default">
      <div
         #expandableTextBox
         class="input-styles"
         tabindex="0"
         [class.disabled]="disabled"
         (focus)="onExpandableTextboxFocus()"
      >{{ value }}</div>
      <lcs-overlay-panel
         [parentElement]="expandableTextBox"
         [show]="show"
         [showOnClick]="false"
         [hideOnBlur]="false"
         [toggleOnClick]="false"
         [openOverTopElement]="true"
         (showChange)="showChanged($event)"
      >
         <textarea
            #overlayTextArea
            #overlayTextAreaModel="ngModel"
            name="{{ name }}-expandable-text-area"
            tabindex="-1"
            [disabled]="disabled"
            [(ngModel)]="overlayPanelValue"
            (focusout)="onOverlayFocusOut()"
            [ngModelOptions]="{ standalone: true }"
            [ngStyle]="textAreaCustomStyle"
            [attr.maxLength]="maxLength ? maxLength : null"
         ></textarea>
      </lcs-overlay-panel>
      <lcs-validation-overlay-panel
         [modelRef]="ngControl"
         [parentElement]="expandableTextBox"
         [displayName]="displayName"
         [name]="name"
      ></lcs-validation-overlay-panel>
      <i
         *ngIf="!show && overlayTextAreaModel?.invalid && (overlayTextAreaModel?.touched || overlayTextAreaModel?.dirty)"
         class="material-icons validation-error-icon"
      >error_outline</i>
   </ng-container>
   <ng-template #default>
      <textarea
         #textArea
         #model="ngModel"
         [ngClass]="{'focused' : textAreaFocused}"
         title="{{ showTooltip ? value: tooltipOverride }}"
         [disabled]="disabled"
         [attr.id]="path"
         [placeholder]="placeholder"
         [attr.maxLength]="maxLength ? maxLength : null"
         [(ngModel)]="value"
         [ngModelOptions]="{ standalone: true }"
         [ngStyle]="textAreaCustomStyle"
         (blur)="onTextAreaBlur()"
         (focus)="onTextAreaFocus()"
      ></textarea>
      <lcs-validation-overlay-panel
         [modelRef]="ngControl"
         [parentElement]="textArea"
         [displayName]="displayName"
         [name]="name"
      ></lcs-validation-overlay-panel>
      <i
         *ngIf="!textAreaFocused && model.invalid && (model.touched || model.dirty)"
         class="material-icons validation-error-icon"
      >error_outline</i>
   </ng-template>
</div>