import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { SignableDocumentPacketsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packets.service";

import { SignableDocumentsListComponent } from "./containers/signable-documents-list.component";
import { SignableDocumentsListItemComponent } from "./views/signable-documents-list-item/signable-documents-list-item.component";

@NgModule({
   imports: [CommonModule, LoadingOverlayModule],
   providers: [SignableDocumentPacketsService],
   declarations: [SignableDocumentsListComponent, SignableDocumentsListItemComponent],
   exports: [SignableDocumentsListItemComponent],
})
export class SignableDocumentsListModule {}
