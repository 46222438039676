import { Component } from "@angular/core";

import { ServiceIssueDetailService } from "./service-issue-detail.service";

@Component({
   selector: "owa-service-issue-detail",
   templateUrl: "./service-issue-detail.component.html",
   providers: [ServiceIssueDetailService],
})
export class ServiceIssueDetailComponent {}
