<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ $any(parameter?.PromptText?.length) > 0 ? parameter.PromptText : parameter.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-text-box
   [(ngModel)]="reportParameterValueModel.value"
   [lcsValidate]="validation"
   (blurEvent)="update()"
   [name]="name"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   [customValidatorData]="customValidatorData"
   [displayName]="parameter?.DisplayName || displayName"
></lcs-text-box>