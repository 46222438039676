<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && amountBasisReportParameter!.IsAsk === true)"
   >
      Amount Selection
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="amountBasisReportParameter!.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<mat-radio-group
   [ngModel]="viewBasisValue"
   [ngModelOptions]="{ standalone: true }"
   (ngModelChange)="amountBasisChange($event)"
>
   <mat-radio-button
      class="actual-amounts-radio-button"
      [value]="amountBasis.Actuals"
   >Show Actual Amounts
   </mat-radio-button>
   <lcs-checkbox
      [disabled]="viewBasisValue !== +amountBasis.Actuals"
      [ngModel]="viewIncludeTotalPSF"
      (ngModelChange)="includeTotalPSFChange($event)"
      [ngModelOptions]="{ standalone: true }"
      [name]="'include-total-psf-amount'"
   >Include Total Per Sq. Ft. Amount</lcs-checkbox>
   <mat-radio-button [value]="amountBasis.AmountPerSqFeet">Show Amounts Per Sq. Ft.
   </mat-radio-button>
</mat-radio-group>