import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaskitoDirective } from "@maskito/angular";

import { PipesModule } from "../../pipes/pipes.module";
import { ValidationModule } from "../validation/validation.module";
import { NumericInputComponent } from "./numeric-input.component";

@NgModule({
   imports: [CommonModule, FormsModule, ValidationModule, PipesModule, MaskitoDirective],
   declarations: [NumericInputComponent],
   exports: [NumericInputComponent, MaskitoDirective],
})
export class NumericInputModule {}
