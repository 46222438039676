import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { EntityMultiSelectorModule } from "@lcs/entity-multi-selector/entity-multi-selector.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { BankSelectorModule } from "@lcs/inputs/bank-selector/bank-selector.module";
import { CalculatorModule } from "@lcs/inputs/calculator-input/calculator.module";
import { DatePickerModule } from "@lcs/inputs/date-picker/date-picker.module";
import { DateRangePickerModule } from "@lcs/inputs/date-range-picker/date-range-picker.module";
import { NumericInputModule } from "@lcs/inputs/numeric-input/numeric-input.module";
import { SlideToggleModule } from "@lcs/inputs/slide-toggle/slide-toggle.module";
import { TextAreaModule } from "@lcs/inputs/text-area/text-area.module";
import { TextBoxModule } from "@lcs/inputs/text-box/text-box.module";
import { ToggleableInputModule } from "@lcs/inputs/toggleable-input/toggleable-input.module";
import { ValidationModule } from "@lcs/inputs/validation/validation.module";
import { LinkModule } from "@lcs/link/link.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { MultiSelectModule } from "@lcs/multi-select/multi-select.module";
import { RadioButtonModule } from "@lcs/radio-button/radio-button.module";
import { DateAndAccountingPeriodReportParameterComponent } from "@lcs/reports/report-parameters/accounting-period/date-and-accounting-period-report-parameter.component";
import { DateRangeAndAccountingPeriodReportParameterComponent } from "@lcs/reports/report-parameters/accounting-period/date-range-and-accounting-period-report-parameter.component";
import { AccountsWrapperReportParameterComponent } from "@lcs/reports/report-parameters/accounts-wrapper/accounts-wrapper-report-parameter.component";
import { AddressTypeReportParameterComponent } from "@lcs/reports/report-parameters/address-type/address-type-report-parameter.component";
import { AmountSelectionPSFReportParameterComponent } from "@lcs/reports/report-parameters/amount-selection/amount-selection-psf-report-parameter.component";
import { Associated1099CategoriesReportParametersComponent } from "@lcs/reports/report-parameters/associated-1099-categories/associated-1099-categories-report-parameter.component";
import { BalanceDueValuesToIncludeParameterComponent } from "@lcs/reports/report-parameters/balance-due-values-to-include/balance-due-values-to-include-report-parameter.component";
import { BankAccountReportParameterComponent } from "@lcs/reports/report-parameters/bank-account/bank-account-report-parameter.component";
import { BankCCAccountsReportParameterComponent } from "@lcs/reports/report-parameters/bank-cc-accounts/bank-cc-accounts-report-parameter.component";
import { BirthdaysToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/birthdays-to-include/birthdays-to-include-report-parameter.component";
import { BudgetYearsReportParameterComponent } from "@lcs/reports/report-parameters/budget-years/budget-years-report-parameter.component";
import { CAMChargeTypeReportParameterComponent } from "@lcs/reports/report-parameters/cam-charge-type/cam-charge-type-report-parameter.component";
import { CAMDivisionReportParameterComponent } from "@lcs/reports/report-parameters/cam-division/cam-division-report-parameter.component";
import { CamEscrowReportParameterComponent } from "@lcs/reports/report-parameters/cam-escrow/cam-escrow-report-parameter.component";
import { ChargeTypeOrderReportParameterComponent } from "@lcs/reports/report-parameters/charge-type-order/charge-type-order-report-parameter.component";
import { ChartAccountsToIncludeReportParameterModule } from "@lcs/reports/report-parameters/chart-accounts-to-include/chart-accounts-to-include-report-parameter.module";
import { CheckboxGroupReportParameterComponent } from "@lcs/reports/report-parameters/checkbox-group/checkbox-group-report-parameter.component";
import { CheckboxReportParameterComponent } from "@lcs/reports/report-parameters/checkbox/checkbox-report-parameter.component";
import { CheckedDateRangeReportParameterComponent } from "@lcs/reports/report-parameters/checked-date-range/checked-date-range-report-parameter.component";
import { ClearedOptionsComponent } from "@lcs/reports/report-parameters/cleared-options/cleared-options.component";
import { ConsolidateAddressReportParameterComponent } from "@lcs/reports/report-parameters/consolidate-address/consolidate-address-report-parameter.component";
import { CreditsToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/credits-to-include/credits-to-include-report-parameter.component";
import { CRPYearReportParameterComponent } from "@lcs/reports/report-parameters/crp-year/crp-year-report-parameter.component";
import { DateRangeWithModesReportParameterComponent } from "@lcs/reports/report-parameters/date-range-with-modes/date-range-with-modes-report-parameter.component";
import { DateRangeReportParameterComponent } from "@lcs/reports/report-parameters/date-range/date-range-report-parameter.component";
import { DateReportParameterComponent } from "@lcs/reports/report-parameters/date-report/date-report-parameter.component";
import { IncludeExpectedMoveDatesReportParameterComponent } from "@lcs/reports/report-parameters/expected-move-dates/include-expected-move-dates-report-parameter.component";
import { JobReportParameterComponent } from "@lcs/reports/report-parameters/job-report/job-report-parameter.component";
import { LabelSelectionReportParameterComponent } from "@lcs/reports/report-parameters/label-selection/label-selection-report-parameter.component";
import { LeadSourcesToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/lead-sources/lead-sources-to-include-report-parameter.component";
import { MarketRentReportParameterComponent } from "@lcs/reports/report-parameters/market-rent/market-rent-report-parameter.component";
import { NumericInputReportParameterComponent } from "@lcs/reports/report-parameters/numeric-input/numeric-input-report-parameter.component";
import { OwnerCoverPageInformationComponent } from "@lcs/reports/report-parameters/owner-cover-page-information/owner-cover-page-information.component";
import { OwnersToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/owners-to-include/owners-to-include-report-parameter.component";
import { PercentReportParameterComponent } from "@lcs/reports/report-parameters/percent/percent-report-parameter.component";
import { PeriodRangeReportParameterComponent } from "@lcs/reports/report-parameters/period-range/period-range-report-parameter.component";
import { PropertiesAndOwnersToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/properties-and-owners/properties-and-owners-to-include-report-parameter.component";
import { ProspectStageIDReportParameterComponent } from "@lcs/reports/report-parameters/prospect-stage/prospect-stage-id-report-parameter.component";
import { RelativeDatePickerComponent } from "@lcs/reports/report-parameters/relative-date-picker/relative-date-picker.component";
import { ReportParameterAskModule } from "@lcs/reports/report-parameters/report-parameter-ask/report-parameter-ask.module";
import { ProspectStagesIsActiveFilterComponent } from "@lcs/reports/report-parameters/report-parameter-filters/prospect-stages-is-active-filter.component";
import { ReportParametersSingleLineMultiSelectFiltersDirective } from "@lcs/reports/report-parameters/report-parameter-filters/report-parameters-single-line-multi-select-filters.directive";
import { ReportPeriodTypeReportParameterComponent } from "@lcs/reports/report-parameters/report-period-type/report-period-type-report-parameter.component";
import { SelectLeaseReportParameterComponent } from "@lcs/reports/report-parameters/select-lease/select-lease-report-parameter.component";
import { SelectUnitReportParameterComponent } from "@lcs/reports/report-parameters/select-unit/select-unit-report-parameter.component";
import { SelectorReportParameterComponent } from "@lcs/reports/report-parameters/selector/selector-report-parameter.component";
import { ShowOnReportReportParameterComponent } from "@lcs/reports/report-parameters/show-on-report/show-on-report-report-parameter.component";
import { SortOptionsReportParameterComponent } from "@lcs/reports/report-parameters/sort-options/sort-options-report-parameter.component";
import { StatementMethodReportParameterModule } from "@lcs/reports/report-parameters/statement-method/statement-method-report-parameter.module";
import { StatesReportParameterComponent } from "@lcs/reports/report-parameters/states/states-report-parameter.component";
import { TextAreaReportParameterComponent } from "@lcs/reports/report-parameters/textarea/textarea-report-parameter.component";
import { TextBoxReportParameterComponent } from "@lcs/reports/report-parameters/textbox/textbox-report-parameter.component";
import { ToggleableInputParameterComponent } from "@lcs/reports/report-parameters/toggleable-input/toggleable-input-report-parameter.component";
import { UsersToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/users-to-include/users-to-include-report-parameter.component";
import { ValuesToIncludeReportParameterComponent } from "@lcs/reports/report-parameters/values-to-include/values-to-include-report-parameter.component";
import { VendorNameAndUDFNameReportParameterComponent } from "@lcs/reports/report-parameters/vendor-udf-name/vendor-name-udf-name-report-parameter.component";
import { VendorReportParameterComponent } from "@lcs/reports/report-parameters/vendor/vendor-report-parameter.component";
import { ViolationNotesReportParameterComponent } from "@lcs/reports/report-parameters/violation-notes/violation-notes-report-parameter.component";
import { Year1099ReportParameterComponent } from "@lcs/reports/report-parameters/year-1099/year-1099-report-parameter.component";
import { YearRangeReportParameterComponent } from "@lcs/reports/report-parameters/year-range/year-range-report-parameter.component";
import { SelectModule } from "@lcs/select/select.module";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";
import { SortableListModule } from "@lcs/sortable-list/sortable-list.module";
import { EntitySelectorModule } from "projects/libraries/owa-gateway-sdk/src/lib/entity-selector/entity-selector.module";
import { DynamicSelectorModule } from "projects/owa/src/app/controls/dynamic-selector/dynamic-selector.module";
import { ReportParameterRendererComponent } from "projects/owa/src/app/reports/report-parameter-renderer/report-parameter-renderer.component";
import { PayableAccountsReportParameterComponent } from "projects/owa/src/app/reports/report-parameters/payable-accounts/payable-accounts-report-parameter.component";
import { PropertyUnitMultiSelectorReportParameterComponent } from "projects/owa/src/app/reports/report-parameters/property-unit-multi-selector/property-unit-multi-selector-report-parameter.component";
import { ReconciliationsReportParameterComponent } from "projects/owa/src/app/reports/report-parameters/reconciliations/reconciliations-report-parameter.component";
import { ReportParametersComponent } from "projects/owa/src/app/reports/report-parameters/report-parameters.component";
import { TransactionsToIncludeReportParameterComponent } from "projects/owa/src/app/reports/report-parameters/transactions-to-include/transactions-to-include-report-parameter.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      DatePickerModule,
      CheckboxModule,
      TextBoxModule,
      ToggleableInputModule,
      DateRangePickerModule,
      LoadingOverlayModule,
      LinkModule,
      FormRegistrationModule,
      ValidationModule,
      SlideToggleModule,
      SelectModule,
      DynamicSelectorModule,
      SingleLineMultiSelectModule,
      MultiSelectModule,
      EntitySelectorModule,
      ChartAccountsToIncludeReportParameterModule,
      ReportParameterAskModule,
      SortableListModule,
      BankSelectorModule,
      EntityMultiSelectorModule,
      CalculatorModule,
      MatDividerModule,
      PortalModule,
      NumericInputModule,
      TextAreaModule,
      RadioButtonModule,
      MatTabsModule,
      MatRadioModule,
      StatementMethodReportParameterModule,
   ],
   exports: [ReportParametersComponent, ReportParameterRendererComponent],
   declarations: [
      ReportParametersComponent,
      ReportParameterRendererComponent,
      DateReportParameterComponent,
      TextBoxReportParameterComponent,
      SelectorReportParameterComponent,
      ToggleableInputParameterComponent,
      BalanceDueValuesToIncludeParameterComponent,
      ConsolidateAddressReportParameterComponent,
      CRPYearReportParameterComponent,
      AddressTypeReportParameterComponent,
      CreditsToIncludeReportParameterComponent,
      SortOptionsReportParameterComponent,
      CAMDivisionReportParameterComponent,
      LabelSelectionReportParameterComponent,
      TextAreaReportParameterComponent,
      DateRangeReportParameterComponent,
      PeriodRangeReportParameterComponent,
      YearRangeReportParameterComponent,
      CheckboxReportParameterComponent,
      CamEscrowReportParameterComponent,
      PercentReportParameterComponent,
      CheckboxGroupReportParameterComponent,
      ChargeTypeOrderReportParameterComponent,
      PropertiesAndOwnersToIncludeReportParameterComponent,
      SelectUnitReportParameterComponent,
      UsersToIncludeReportParameterComponent,
      Year1099ReportParameterComponent,
      JobReportParameterComponent,
      IncludeExpectedMoveDatesReportParameterComponent,
      CheckedDateRangeReportParameterComponent,
      ReconciliationsReportParameterComponent,
      RelativeDatePickerComponent,
      VendorReportParameterComponent,
      LeadSourcesToIncludeReportParameterComponent,
      BankAccountReportParameterComponent,
      ViolationNotesReportParameterComponent,
      PayableAccountsReportParameterComponent,
      DateRangeWithModesReportParameterComponent,
      BudgetYearsReportParameterComponent,
      SelectLeaseReportParameterComponent,
      CAMChargeTypeReportParameterComponent,
      ReportPeriodTypeReportParameterComponent,
      StatesReportParameterComponent,
      CamEscrowReportParameterComponent,
      PercentReportParameterComponent,
      AccountsWrapperReportParameterComponent,
      MarketRentReportParameterComponent,
      TransactionsToIncludeReportParameterComponent,
      PropertyUnitMultiSelectorReportParameterComponent,
      OwnersToIncludeReportParameterComponent,
      ValuesToIncludeReportParameterComponent,
      BirthdaysToIncludeReportParameterComponent,
      ShowOnReportReportParameterComponent,
      ProspectStagesIsActiveFilterComponent,
      ReportParametersSingleLineMultiSelectFiltersDirective,
      BankCCAccountsReportParameterComponent,
      NumericInputReportParameterComponent,
      OwnerCoverPageInformationComponent,
      DateRangeAndAccountingPeriodReportParameterComponent,
      DateAndAccountingPeriodReportParameterComponent,
      Associated1099CategoriesReportParametersComponent,
      ProspectStageIDReportParameterComponent,
      VendorNameAndUDFNameReportParameterComponent,
      AmountSelectionPSFReportParameterComponent,
      ClearedOptionsComponent,
   ],
   providers: [],
})
export class ReportParametersModule {}
