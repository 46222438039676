import { Component, OnInit } from "@angular/core";
import { IssuesService } from "../../../../../libraries/owa-gateway-sdk/src/lib/services/serviceissues.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ServiceIssueDashboardModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/service-issue-dashboard.model";

@Component({
   selector: "owa-dashboard-issues",
   templateUrl: "./dashboard-tile-issues.component.html",
   providers: [IssuesService],
})
export class DashboardIssuesComponent implements OnInit {
   observableIssues: Observable<Array<ServiceIssueDashboardModel>>;
   notificationCount: number;

   constructor(private issueService: IssuesService, private router: Router) {}

   ngOnInit() {
      this.observableIssues = this.issueService.getIssuesForDashboard();
      this.observableIssues.subscribe((res) => {
         if (res != null && res.length > 0) {
            const newIssues = res.filter((i) => i.IsNew);
            this.notificationCount = newIssues != null ? newIssues.length : 0;
         }
      });
   }

   showDetails(id: number) {
      this.router.navigate(["/serviceissues", id]);
   }
}
