import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../../authentication/authentication.service";
import { OWASessionService } from "../../session/owa-session.service";
import { WebUserService } from "./../../../../../libraries/owa-gateway-sdk/src/lib/services/web-user.service";

@Component({
   selector: "owa-user-profile-modal",
   templateUrl: "./user-profile-modal.component.html",
   styleUrls: ["./user-profile-modal.component.css"],
})
export class UserProfileModalComponent implements OnInit {
   configuration: any;
   continueButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   errorMessage: string;
   webUser = new WebUserModel();
   phoneNumber: string;
   phoneNumberModel = new PhoneNumberModel();
   private unsubscribe = new Subject<void>();

   constructor(
      private webUserService: WebUserService,
      private errorMessageService: ErrorMessageService,
      private authenticationService: AuthenticationService,
      private dialogueService: CurrentDialogService,
      private owaSessionService: OWASessionService
   ) {}
   ngOnInit() {
      this.webUserService
         .getUserProfile(this.configuration)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (res) => {
               if (res) {
                  this.webUser = res;
                  if (res.PhoneNumbers.length > 0) {
                     this.phoneNumber = res.PhoneNumbers[0].PhoneNumber;
                  }
               }
            },
            (error) => {
               this.errorMessage = this.errorMessageService.processHttpError(error).Message;
            }
         );
   }

   saveUserProfile(form: NgForm) {
      if (form.valid) {
         this.continueButtonStatus = LcsProgressButtonStatus.InProgress;
         if (this.webUser.PhoneNumbers.length > 0) {
            this.webUser.PhoneNumbers[0].PhoneNumber = this.phoneNumber;
         } else {
            this.phoneNumberModel.PhoneNumber = this.phoneNumber;
            this.webUser.PhoneNumbers.push(this.phoneNumberModel);
         }
         this.webUserService
            .saveUserProfile(this.webUser)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
               (res) => {
                  this.continueButtonStatus = LcsProgressButtonStatus.Success;
                  this.dialogueService.closeDialog();
                  this.owaSessionService.clearCache(true);
                  this.owaSessionService.populateCache();
               },
               (error) => {
                  this.continueButtonStatus = LcsProgressButtonStatus.Error;
                  this.errorMessageService.triggerHttpErrorMessage(error);
               }
            );
      } else {
         this.continueButtonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   logOut() {
      this.dialogueService.closeDialog();
      this.authenticationService.logOut();
   }
}
