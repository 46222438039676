<div style="display:flex; flex-direction: column;">
   <div
      class="datatable-top-filter-section"
      *ngIf="EnableFilters"
   >
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="estimateFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>
      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) >= 0"
      >
         <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
         <div *ngIf="$any((results | async)) > 0">{{ results | async }} of&nbsp;</div> {{ totalResults | async }}
         Estimates
      </div>
      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            [ngClass]="{'table-wrapper-open': DefaultActiveFilter, 'table-wrapper-half': showClosed }"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(estimates | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: true"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>
               <ng-container
                  lcsColumnDef="Estimate #"
                  [width]="8"
                  [priority]="1"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Estimate #</th>
                  <td
                     style="padding-left: 25px;"
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >
                        {{ row.EstimateID }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Property"
                  [width]="14"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Property</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >{{ row.PropertyName }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Reference"
                  [width]="14"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Reference</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >{{ row.Reference }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Date"
                  [width]="6"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown "
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >{{
                        row.IssueDate | date: "MM/dd/yyyy"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Expiration"
                  [width]="6"
                  [priority]="4"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Expiration</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown "
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >{{
                        row.ExpirationDate | date: "MM/dd/yyyy"
                        }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Status"
                  [width]="8"
                  [priority]="6"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Status</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >{{ row.StatusName }}</a>
                  </td>
               </ng-container>
               <ng-container
                  lcsColumnDef="Amount"
                  [width]="8"
                  [priority]="7"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Amount</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="has-drilldown numeric"
                     style="padding-right: 25px;"
                  >
                     <a
                        class="drilldown-link"
                        [routerLink]="['/estimates', row.EstimateID]"
                     >${{ row.TotalValue | number: "1.2" }}</a>
                  </td>
               </ng-container>
            </lcs-table>
            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any(estimates | async)?.length < $any(totalResults | async)"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</div>