import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class ApplicationStateService {
   public isRouting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   public _state: Map<string, any> = new Map<string, any>();

   /**
    * Already return a clone of the current state.
    */
   public get state() {
      return (this._state = this._clone(this._state));
   }
   /**
    * Never allow mutation
    */
   public set state(_value) {
      throw new Error("do not mutate the `.state` directly");
   }

   public get(prop?: any) {
      /**
       * Use our state getter for the clone.
       */
      const state = this.state;
      return state.hasOwnProperty(prop) ? state[prop] : state;
   }

   public set(prop: string, value: any) {
      /**
       * Internally mutate our state.
       */
      return (this._state[prop] = value);
   }

   private _clone(object: Map<string, any>) {
      /**
       * Simple object clone.
       */
      return JSON.parse(JSON.stringify(object));
   }
}
