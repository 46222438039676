/**
 * Static class with single getUID() method used to get a
 * unique ID value for *temporary* use within application
 * when a unique id value is required.
 *
 * See Also: UniqueID which as a non-static injectable service
 */
export class UIDService {
   public static readonly MAX_VALUE = -2;
   public static readonly MIN_VALUE = Number.MIN_SAFE_INTEGER;
   private static uid: number = UIDService.MAX_VALUE;

   /**
    * Returns decreasing integer value starting with UIDService.MAX_VALUE.
    * If UIDService.MIN_VALUE value is reached it resets to UIDService.MAX_VALUE
    */
   public static getUID(): Number {
      if (UIDService.uid === UIDService.MIN_VALUE) {
         return (UIDService.uid = UIDService.MAX_VALUE); // if reach limit, reset
      } else {
         return UIDService.uid--;
      }
   }
}
