import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CheckViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/check-view.model";
import { Observable } from "rxjs";

import { ChecksService } from "../../../../../libraries/owa-gateway-sdk/src/lib/services/checks.service";

@Component({
   selector: "owa-dashboard-checks",
   templateUrl: "./dashboard-tile-checks.component.html",
   providers: [ChecksService],
})
export class DashboardChecksComponent implements OnInit {
   public observChecks: Observable<Array<CheckViewModel>>;
   public checks: Array<CheckViewModel> = new Array<CheckViewModel>();
   public newChecks: Array<CheckViewModel> = new Array<CheckViewModel>();
   public notificationCount: number;
   public router: Router;
   public isNew: boolean;
   public checkColumns: string[] = ["TransactionDate", "Payee", "Amount", "NewRow"];
   constructor(private checkService: ChecksService) {}

   ngOnInit() {
      this.observChecks = this.checkService.getChecksForDashboard();
      this.observChecks.subscribe((result) => {
         this.checks = result;
         if (this.checks != null && this.checks.length > 0) {
            const newChecks = this.checks.filter((chks) => chks.IsNew);
            this.notificationCount = newChecks != null ? newChecks.length : 0;
         }
      });
   }
}
