<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ parameter?.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<owa-dynamic-selector
   *ngIf="allValuesLoaded"
   [name]="name"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   [allowsSelectAll]="parameter.ReportParameterValueSource.AllowsSelectAll"
   [valueSources]="valueSources"
   (selectionChanged)="selectionChanged($event)"
></owa-dynamic-selector>