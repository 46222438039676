import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectModule } from "@lcs/select/select.module";

import { FilterOperationSelectorComponent } from "./filter-operation-selector.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      SelectModule,
   ],
   declarations: [
      FilterOperationSelectorComponent,
   ],
   exports: [
      FilterOperationSelectorComponent,
   ],
})
export class FilterOperationSelectorModule { }
