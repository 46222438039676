<p-calendar
   #calendarPicker
   title="{{ showTooltip ? (tooltipOverride ? tooltipOverride : (value | valueFormatter:expressDataTypes.Date)) : '' }}"
   [baseZIndex]="zIndex"
   [ngModel]="value"
   [ngModelOptions]="{ standalone: standalone }"
   (onSelect)="onDateSelect($event)"
   (onClearClick)="onDateSelect($any(null))"
   [placeholder]="placeholder"
   [keepInvalid]="false"
   [showOnFocus]="false"
   [dateFormat]="dateFormat"
   [showIcon]="true"
   [showButtonBar]="true"
   [disabled]="disabled"
   [appendTo]="'body'"
   (click)="setLeft()"
   (onBlur)="onBlur()"
   (onFocus)="onFocus()"
   (window:resize)="onResize()"
   (keydown.enter)="onEnter($event)"
   [inputId]="path"
   [touchUI]="mobileOpen"
   [view]="view"
>
</p-calendar>
<i class="material-icons validation-error-icon">error_outline</i>
<lcs-validation-overlay-panel
   [hidden]="calendarPicker.overlayVisible"
   [modelRef]="modelRef"
   [parentElement]="dateInputElement"
   [displayName]="displayName"
   [name]="path"
></lcs-validation-overlay-panel>