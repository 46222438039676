import { AbstractControl, ValidationErrors } from "@angular/forms";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressValidationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-validation.model";

export class ValidationModel {
   dataType: ExpressDataTypes;
   exclusiveRange: [any, any];
   inclusiveRange: [any, any];
   whitelist: Array<any>;
   blacklist: Array<any>;
   maxFractionalDigits?: number | null;
   customValidator: (control: AbstractControl, customValidatorData: any) => ValidationErrors | null;

   minDate: Date;
   maxDate: Date;

   minNumberOccurrences: number;
   minSpecialCharacterOccurrences: number;

   notUnset: boolean;
   unsetValue: any = -1;

   bypassValidation: boolean;

   parentControlNames: Array<string>;
   dependentControlNames: Array<string>;
   relatedControlNames: Array<string>;

   min?: number | null;
   max?: number | null;
   required: boolean;
   requiredTrue: boolean;
   email: boolean;
   minLength?: number | null;
   maxLength?: number | null;
   pattern: RegExp;
   patternValidationMessage: string;
   requiredIfDirty: boolean = false;

   validateOnlyIfDirty: boolean = false;

   constructor() {
      this.whitelist = [];
      this.blacklist = [];
      this.parentControlNames = [];
      this.dependentControlNames = [];
      this.relatedControlNames = [];
   }

   static FromExpressValidationModel(expressValidationModel: ExpressValidationModel): ValidationModel {
      const validationModel = new ValidationModel();

      validationModel.dataType = expressValidationModel.DataType;

      if (
         (validationModel.dataType === ExpressDataTypes.Date ||
            validationModel.dataType === ExpressDataTypes.DateTime) &&
         expressValidationModel.DateValidation
      ) {
         if (expressValidationModel.DateValidation.ExclusiveRange) {
            validationModel.exclusiveRange = [
               expressValidationModel.DateValidation.ExclusiveRange.Item1,
               expressValidationModel.DateValidation.ExclusiveRange.Item2,
            ];
         }
         if (expressValidationModel.DateValidation.InclusiveRange) {
            validationModel.inclusiveRange = [
               expressValidationModel.DateValidation.InclusiveRange.Item1,
               expressValidationModel.DateValidation.InclusiveRange.Item2,
            ];
         }
         validationModel.whitelist = expressValidationModel.DateValidation.WhiteList;
         validationModel.blacklist = expressValidationModel.DateValidation.BlackList;
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         validationModel.minDate = new Date(expressValidationModel.DateValidation.Minimum);
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         validationModel.maxDate = new Date(expressValidationModel.DateValidation.Maximum);
      }
      if (
         (validationModel.dataType === ExpressDataTypes.Numeric ||
            validationModel.dataType === ExpressDataTypes.Currency) &&
         expressValidationModel.NumericValidation
      ) {
         if (expressValidationModel.NumericValidation.ExclusiveRange) {
            validationModel.exclusiveRange = [
               expressValidationModel.NumericValidation.ExclusiveRange.Item1,
               expressValidationModel.NumericValidation.ExclusiveRange.Item2,
            ];
         }
         if (expressValidationModel.NumericValidation.InclusiveRange) {
            validationModel.inclusiveRange = [
               expressValidationModel.NumericValidation.InclusiveRange.Item1,
               expressValidationModel.NumericValidation.InclusiveRange.Item2,
            ];
         }
         validationModel.whitelist = expressValidationModel.NumericValidation.WhiteList;
         validationModel.blacklist = expressValidationModel.NumericValidation.BlackList;
         validationModel.min = expressValidationModel.NumericValidation.Minimum;
         validationModel.max = expressValidationModel.NumericValidation.Maximum;
         validationModel.maxFractionalDigits = expressValidationModel.NumericValidation.MaxFractionalDigits;
      }
      validationModel.required = expressValidationModel.Required;
      validationModel.requiredTrue = expressValidationModel.RequiredTrue;
      validationModel.email = expressValidationModel.IsEmail;
      validationModel.minLength = expressValidationModel.MinumumLength;
      validationModel.maxLength = expressValidationModel.MaximumLength;
      validationModel.parentControlNames = expressValidationModel.ParentControlNames;
      validationModel.dependentControlNames = expressValidationModel.DependentControlNames;
      validationModel.relatedControlNames = expressValidationModel.RelatedControlNames;
      if (
         expressValidationModel.RegularExpressionPattern &&
         expressValidationModel.RegularExpressionPattern.length > 0
      ) {
         validationModel.pattern = new RegExp(expressValidationModel.RegularExpressionPattern);
      }

      return validationModel;
   }
}
