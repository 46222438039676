import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eaccounttype.enum";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { ManagementFeeViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/management-fee-view.model";
import { ManagementFeesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/management-fees.service";
import { Observable, Subject, takeUntil } from "rxjs";

import { OWASessionModel } from "../../session/models/owa-session.model";
import { OWASessionService } from "../../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-dashboard-tile-management-fee",
   templateUrl: "./dashboard-tile-management-fee.component.html",
   providers: [ManagementFeesService],
})
export class DashboardManagementFeeComponent implements OnInit, OnDestroy {
   observableFees: Observable<Array<ManagementFeeViewModel>>;
   fees: Array<ManagementFeeViewModel>;
   notificationCount: number;
   lastLoginDate: Date;
   webuser: WebUserModel;
   feeColumns: string[] = ["FeeDate", "PropertyName", "FeeTotal", "NewRow"];
   showPassthru: boolean;
   private unsubscribe = new Subject<void>();
   private owaSessionModel = new OWASessionModel();
   private currentOwnerId: number;
   constructor(
      private managementFeeServices: ManagementFeesService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      private owaSessionService: OWASessionService
   ) {
      this.fees = new Array<ManagementFeeViewModel>();
   }

   ngOnInit() {
      this.owaSessionService.OWASessionInfo.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
         if (res != null) {
            this.owaSessionModel = res;
            if (this.owaSessionModel != null) {
               this.webuser = this.owaSessionModel.CurrentUser;
               if (this.owaSessionModel.CurrentWebUserAccount.AccountType == AccountType.Owner) {
                  this.currentOwnerId = this.owaSessionModel.CurrentAccount.OwnerID;
               } else {
                  this.currentOwnerId = this.owaSessionModel.CurrentOwnerProspectAccount.OwnerProspectID;
               }
            }
         }
      });
      if (this.webuser != null) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
         this.lastLoginDate = this.webuser.LastSuccessfulLogin;
         this.observableFees = this.managementFeeServices.getFeesForDashboard(this.currentOwnerId);
         this.observableFees.subscribe((res) => {
            this.fees = res;
            if (this.fees != null && this.fees.length > 0) {
               const newFees = this.fees.filter((fee) => fee.IsNew);
               this.notificationCount = newFees != null ? newFees.length : 0;
            }
         });
      }
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            this.showPassthru = false;
         }
      );
   }
   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
