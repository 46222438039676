import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SignableDocumentPacketSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-document-packet-summary.model";
import { SignableDocumentsSettings } from "projects/libraries/owa-gateway-sdk/src/lib/models/signable-documents-settings.model";
import { SignableDocumentPacketSummariesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-document-packet-summaries.service";
import { SignableDocumentsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/signable-documents.service";
import { forkJoin, map, Observable, Subject, switchMap, takeUntil } from "rxjs";

import { OWASessionService } from "../../session/owa-session.service";

declare var jquery: any;
declare var $: any;

@Component({
   selector: "owa-signable-documents-sign",
   templateUrl: "./signable-documents-sign.component.html",
   styleUrls: ["./signable-documents-sign.component.css"],
})
export class SignableDocumentsSignComponent implements OnInit, OnDestroy {
   public signableDocumentsSettings = new SignableDocumentsSettings();
   private signableDocumentPacketId: number;
   private unsubscribe = new Subject<void>();

   private _signableDocumentPacket = new SignableDocumentPacketSummaryModel();

   constructor(
      private activatedRoute: ActivatedRoute,
      private signableDocumentPacketSummariesService: SignableDocumentPacketSummariesService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService,
      private signableDocumentsService: SignableDocumentsService,
      private router: Router
   ) {}

   ngOnInit() {
      this.activatedRoute.paramMap
         .pipe(
            switchMap((paramMap) => {
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
               this.signableDocumentPacketId = parseInt(paramMap.get("id"));
               return forkJoin(this.getSignableDocumentPacketSummary(), this.getSignableDocumentSettings());
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            ([signableDocumentPacketSummary, signableDocumentSettings]: [
               SignableDocumentPacketSummaryModel,
               SignableDocumentsSettings
            ]) => {
               if (signableDocumentPacketSummary) {
                  this._signableDocumentPacket = signableDocumentPacketSummary;
               }
               if (signableDocumentSettings) {
                  this.signableDocumentsSettings = signableDocumentSettings;
               }
               this.initializeSignableDocumentControls();
            },
            (_err) => {
               this.errorMessageService.triggerHttpErrorMessage(_err);
               this.router.navigate(["/signabledocuments"]);
            }
         );
   }

   ngOnDestroy() {
      $("#popup-sign-main").remove();
      $("#popup-input-main").remove();
      $("#sign-invalidation-confirm").remove();
      $("#popup-completed-main").remove();
      $("#text-overflow").remove();
      $("#timeout-error").remove();
      this.unsubscribe.next();
   }

   getSignableDocumentPacketSummary(): Observable<SignableDocumentPacketSummaryModel> {
      const embedOptions = [
         "SignableDocuments",
         "SignableDocuments.SignatureBlockGroups",
         "SignableDocuments.SignatureBlocks",
         "SignableDocuments.Webusers",
         "SignableDocuments.SignableDocumentFields",
      ];
      return this.signableDocumentPacketSummariesService
         .get(this.signableDocumentPacketId, embedOptions)
         .pipe(map((response) => response));
   }

   getSignableDocumentSettings(): Observable<SignableDocumentsSettings> {
      return this.signableDocumentsService.getSignableDocumentsSettings().pipe(map((response) => response));
   }

   initializeSignableDocumentControls() {
      $("#MainContent").empty();
      $("#previous-signature-pane").removeData();
      $("#previous-initial-pane").removeData();
      $("#previous-signature").html("");
      $("#previous-initial").html("");

      $(document).foundation();
      const options = {
         Packet: this._signableDocumentPacket,
         DocumentSelectionCallback: function (documentID, fromPageNavigationFlag) {
            $("#MainContent").DocumentSigningWizard("setdocument", {
               DocumentID: documentID,
               Flag: fromPageNavigationFlag,
            });
         },
      };
      $("#MainContent").PacketBanner("initialize", options);
      $(".PacketBanner").hide();

      const wizardOptions = {
         WebUserID: this.owaSessionService.currentOWASessionInfo.CurrentUser.WebUserID,
         FirstDocumentID: this._signableDocumentPacket.FirstDocumentID,
         LastDocumentID: this._signableDocumentPacket.LastDocumentID,
         PropertyName: this.signableDocumentsSettings.PropertyName,
         SignatureFont: this.signableDocumentsSettings.SignatureFont,
         GetSignableDocumentURL: this.signableDocumentsSettings.GetSignableDocumentURL,
         GetPageImageURL: this.signableDocumentsSettings.GetPageImageURL,
         PostSignatureURL: this.signableDocumentsSettings.PostSignatureURL,
         PostFieldURL: this.signableDocumentsSettings.PostFieldURL,
         PostCompleteDocumentURL: this.signableDocumentsSettings.PostCompleteDocumentURL,
         PostDisplayTextURL: this.signableDocumentsSettings.PostDisplayTextURL,
         DocumentWidth: this.signableDocumentsSettings.DocumentWidthInPixels.toString(),
         SignatureBlockTypeID: this.signableDocumentsSettings.SignatureBlockTypeID,
         InitialBlockTypeID: this.signableDocumentsSettings.InitialBlockTypeID,
         PointToPixelFactor: this.signableDocumentsSettings.PointToPixelFactor,
         Packet: this._signableDocumentPacket,
         RefreshDocumentCallback: function (signableDocument) {
            $(".PacketBanner").PacketBanner("refreshDocumentSummary", signableDocument);
         },
         DocumentPagingCallback: function (direction, fromPageNavigationFlag) {
            $(".PacketBanner").PacketBanner("moveToDocument", { Direction: direction, Flag: fromPageNavigationFlag });
         },
      };

      $("#MainContent").DocumentSigningWizard("initialize", wizardOptions);

      $("#MainContent").DocumentSigningWizard("setdocument", {
         DocumentID: this._signableDocumentPacket.SignableDocuments[0].SignableDocumentID,
         Flag: "",
      });
   }
}
