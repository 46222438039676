import {
   AfterViewInit,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   NgZone,
   OnDestroy,
   Output,
   TemplateRef,
   ViewChild,
   ViewContainerRef,
} from "@angular/core";
import { Params } from "@angular/router";
import { fromEvent, Subject, takeUntil } from "rxjs";

import { LinkMode } from "./link-mode.enum";

@Component({
   selector: "lcs-link",
   templateUrl: "./link.component.html",
})
export class LinkComponent implements AfterViewInit, OnDestroy {
   @Input() anchorTemplate: TemplateRef<any>;

   @Input() contentTemplate: TemplateRef<any>;

   @Input() set cssClasses(value: Array<string>) {
      if (!value) {
         if (this._cssClasses.length > 0) {
            this._cssClasses = new Array<string>();
         }
      } else if (this._cssClasses !== value) {
         this._cssClasses = value.filter((v) => v !== null);
      }
   }

   get cssClasses(): Array<string> {
      return this._cssClasses;
   }

   @Input() disabled: boolean;

   @Input() set href(href: string) {
      this._href = href;
      this.determineDefaultTarget();
   }

   get href(): string {
      return this._href;
   }

   @Input() linkMode: LinkMode = LinkMode.Link;

   @Input() set routerLink(routerLink: Array<string> | string) {
      this._routerLink = routerLink;
      this.determineDefaultTarget();
   }

   get routerLink(): Array<string> | string {
      return this._routerLink;
   }

   @Input() set target(target: string) {
      this._target = target;
   }

   get target(): string {
      if (this._target != null) {
         return this._target;
      } else {
         return this._defaultTarget;
      }
   }

   @Input() text: string;

   @Input() title: string;

   @Input() queryParams: Params;

   @Input() data: any;

   @Input() additionalData: any;

   @Output() clicked = new EventEmitter<MouseEvent>();

   @ViewChild("actionLink") actionLinkViewChild: ElementRef;

   @ViewChild("default", { read: ViewContainerRef }) default: ViewContainerRef;

   linkModes = LinkMode;

   actionInputElement: HTMLInputElement;

   private _target: string;

   private _href: string;

   private _routerLink: Array<string> | string;

   private _defaultTarget;

   private unsubscribe = new Subject<void>();

   private _cssClasses = new Array<string>();

   constructor(private ngZone: NgZone) {}

   emitClicked($event: MouseEvent) {
      if (!this.disabled) {
         this.clicked.emit($event);
      }
   }

   ngAfterViewInit() {
      this.actionInputElement = this.default.element.nativeElement.parentElement.querySelector("a");

      this.ngZone.runOutsideAngular(() => {
         if (this.actionInputElement) {
            fromEvent(this.actionInputElement, "keydown")
               .pipe(takeUntil(this.unsubscribe))
               .subscribe((event: KeyboardEvent) => {
                  this.ngZone.run(() => {
                     if (event.key === "Enter") {
                        this.emitClicked(new MouseEvent("click"));
                     }
                  });
               });
         }
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private determineDefaultTarget() {
      if (this.href) {
         this._defaultTarget = "_blank";
      } else {
         this._defaultTarget = "";
      }
   }
}
