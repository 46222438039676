////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum BudgetType {
   NotSet = 0,
   ProfitLoss = 1,
   BalanceSheet = 2,
   Both = 3,
}
