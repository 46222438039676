import { TemplatePortal } from "@angular/cdk/portal";
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { OverlayPortalAttachedModel } from "@lcs/single-line-multi-select/models/overlay-portal-attached.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { SystemPreference } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/system-preference.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { forkJoin, map, Observable, of, Subject, switchMap, takeUntil } from "rxjs";

import { OverlayPanelComponent } from "../../../overlay-panel/overlay-panel.component";
import { CurrentSystemPreferencesService } from "../../../session/current-system-preferences.service";
import { coerceBoolean } from "../../../utils/boolean-coercion";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-bank-cc-accounts-report-parameter",
   templateUrl: "bank-cc-accounts-report-parameter.component.html",
   styleUrls: ["./bank-cc-accounts-report-parameter.component.scss"],
   viewProviders: [ControlContainerViewProvider],
})
export class BankCCAccountsReportParameterComponent implements OnInit, OnDestroy, AfterViewInit {
   @ViewChild("accountSelector") accountSelector: TemplateRef<any>;

   @Input() disabled: boolean;

   @Input() name: string;

   @Input() set parameter(value: ReportReportParameterViewModel) {
      this._parameter = value;
   }

   get parameter(): ReportReportParameterViewModel {
      return this._parameter;
   }

   @Input() hasAsk: boolean;

   displayName: string = "Banks";

   reportParameterValueModel: ReportParameterValueModel;

   valueSource: ValueSourceModel;

   selectedValues: Array<any>;

   selectionChange: SelectionChangeModel;

   portal: TemplatePortal<any>;

   selectedAccountType: EntityType;

   accountTypes: Array<SelectorItemModel>;

   parentOverlayPanelRef: OverlayPanelComponent;

   hasSelector: boolean;

   validation: ValidationModel;

   private bankIDs: Set<number>;

   private ccIDs: Set<number>;

   private unsubscribe = new Subject<void>();

   private _parameter: ReportReportParameterViewModel;

   constructor(
      private reportParametersService: ReportParametersService,
      private valueSourceService: ValueSourceService,
      private entityViewInformationServiceBase: EntityViewInformationServiceBase,
      private viewContainerRef: ViewContainerRef,
      private changeDetectorRef: ChangeDetectorRef,
      private currentSystemPreferencesService: CurrentSystemPreferencesService
   ) {}

   ngOnInit() {
      this.buildAccountTypes();
      this.initializeAccounts();
      this.initializeReportParameterValue();
      this.initializeValidation();
   }

   ngOnDestroy() {
      if (this.portal) {
         if (this.portal.isAttached) {
            this.portal.detach();
         }
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'TemplatePor... Remove this comment to see the full error message
         this.portal = null;
      }
      this.unsubscribe.next();
   }

   ngAfterViewInit() {
      if (this.accountSelector) {
         this.portal = new TemplatePortal(this.accountSelector, this.viewContainerRef);
         this.changeDetectorRef.detectChanges();
      }
   }

   askChanged(isAsk: boolean) {
      this.parameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.parameter.ReportParameterID, isAsk);
   }

   selectionChanged() {
      const checkedItems = this.selectionChange.checkedItems.filter((c) => c.value != null && c.value !== "");
      this.updateReportParameterValues(checkedItems.map((i) => i.value));
   }

   updateReportParameterValues(selectedValues: Array<number>) {
      this.reportParameterValueModel.value = "";

      let selectAll = false;
      let values = new Array<any>();
      if (
         this.valueSource &&
         this.valueSource.AllValue &&
         this.selectedValues.length === this.valueSource.StaticValues.length &&
         this.valueSource.AllowsSelectAll
      ) {
         selectAll = true;
      }
      const allValue = this.parameter.ReportParameterValueSource.AllValue;
      if (selectAll && allValue) {
         this.reportParameterValueModel.value = allValue;
         values = [allValue];
      } else if (selectedValues.length > 0) {
         this.reportParameterValueModel.value = "(" + selectedValues.join(",") + ")";
         values = selectedValues;
      } else {
         this.reportParameterValueModel.value = "";
      }

      this.reportParameterValueModel.rawValues = values;
      this.reportParametersService.updateParameterValue.next(this.reportParameterValueModel);
   }

   onPortalAttached(portalAttached: OverlayPortalAttachedModel) {
      if (portalAttached && portalAttached.parentOverlayRef) {
         this.parentOverlayPanelRef = portalAttached.parentOverlayRef as OverlayPanelComponent;
      }
   }

   onSelectedAccountsChange(value: any) {
      const currentValuesSet = new Set<number>(this.selectedValues);
      if (value === EntityType.Bank) {
         this.bankIDs.forEach((id) => {
            if (!currentValuesSet.has(id)) {
               currentValuesSet.add(id);
            }
         });
      } else if (value === EntityType.CreditCard) {
         this.ccIDs.forEach((id) => {
            if (!currentValuesSet.has(id)) {
               currentValuesSet.add(id);
            }
         });
      }
      this.selectedValues = Array.from(currentValuesSet);

      // update the selectionChange model or it won't have current values when selectionChanged() is called
      const selectionChange = new SelectionChangeModel();
      selectionChange.checkedItems = this.selectedValues.map((selectedValue) => {
         const item = new SelectorItemModel(selectedValue);
         item.isChecked = true;
         return item;
      });
      this.selectionChange = selectionChange;

      this.updateReportParameterValues(this.selectedValues);

      setTimeout(() => {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'EntityType'... Remove this comment to see the full error message
         this.selectedAccountType = null;
         this.changeDetectorRef.detectChanges();
      });
   }

   private initializeReportParameterValue() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportParameterValueModel | undefined' is no... Remove this comment to see the full error message
      this.reportParameterValueModel = this.reportParametersService.reportParameterValues.get(
         this._parameter.ReportParameterID
      );

      if (this.reportParameterValueModel.value) {
         const values = this.reportParameterValueModel.value.toString().split(",");
         this.selectedValues = values;
      }
   }

   private initializeValidation() {
      if (this.parameter.ReportParameterValueSource.IsRequired) {
         if (this.validation) {
            this.validation.required = true;
         } else {
            const validation = new ValidationModel();
            validation.required = true;
            validation.dataType = ExpressDataTypes.Key;
            this.validation = validation;
         }
      }
   }

   private processCollection(results: Array<SelectorItemModel>) {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;
      valueSource.AllowsMultipleValues = true;
      valueSource.AllowsSelectAll = true;
      valueSource.AllValue = "All";
      valueSource.StaticValues.push(...results);
      this.valueSource = valueSource;
   }

   private buildAccountTypes() {
      const accountTypes = new Array<SelectorItemModel>();
      accountTypes.push(new SelectorItemModel(EntityType.Bank, "Banks"));
      accountTypes.push(new SelectorItemModel(EntityType.CreditCard, "Credit Cards"));

      this.accountTypes = accountTypes;
   }

   private initializeAccounts() {
      let includeBanksObservable = of(false);
      let includeCreditCardsObservable = of(false);
      if (this.parameter.ReportID === Report.CheckDepositList) {
         includeBanksObservable = of(true);
         includeCreditCardsObservable = this.currentSystemPreferencesService
            .getSystemPreference(SystemPreference.AllowBillPaymentsWithCreditCards)
            .pipe(
               map((pref) => {
                  if (!pref) {
                     return false;
                  }
                  return coerceBoolean(pref.Value);
               })
            );
      } else if (this.parameter.ReportID === Report.ReconciliationListing) {
         this.displayName = "Accounts";
         this.hasSelector = true;
         includeBanksObservable = of(true);
         includeCreditCardsObservable = of(true);
      } else if (this.parameter.ReportID === Report.CreditCardTransactionListing) {
         this.displayName = "Credit Card Accounts";
         includeCreditCardsObservable = of(true);
      } else if (
         [
            Report.AllReceiptsDeposited,
            Report.DrumlinBankRegister,
            Report.PanAmericanCheckRegister,
            Report.CheckWorksheet,
            Report.DepositBreakdown,
            Report.DDIRealtyDepositBreakdown,
            Report.BluestoneHockleyORTrustAccountReconciliation,
            Report.JDPendingCheck,
            Report.BluestoneHockleyWATrustAccountReconciliation,
            Report.WindermereDepositBreakdown,
         ].some((r) => r === this.parameter.ReportID)
      ) {
         includeBanksObservable = of(true);
      }

      forkJoin([includeBanksObservable, includeCreditCardsObservable])
         .pipe(
            map(([includeBanks, includeCreditCards]) => {
               this.buildAccountItems(includeBanks, includeCreditCards);
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe();
   }

   private buildAccountItems(includeBanks: boolean, includeCreditCards: boolean) {
      const obs = new Array<Observable<any>>();
      if (includeBanks) {
         obs.push(this.getEntityItems(EntityType.Bank));
      } else {
         obs.push(of([]));
      }
      if (includeCreditCards) {
         obs.push(this.getEntityItems(EntityType.CreditCard));
      } else {
         obs.push(of([]));
      }

      forkJoin(obs)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(([bankItems, creditCardItems]) => {
            this.bankIDs = new Set<number>();
            const items = new Array<SelectorItemModel>();
            bankItems?.forEach((bank) => {
               this.bankIDs.add(bank.value);
               items.push(bank);
            });
            this.ccIDs = new Set<number>();
            creditCardItems?.forEach((creditCard) => {
               this.ccIDs.add(creditCard.value);
               items.push(creditCard);
            });
            items.sort((a: SelectorItemModel, b: SelectorItemModel) => a.displayValue.localeCompare(b.displayValue));
            this.processCollection(items);
         });
   }

   private getEntityItems(entityType: EntityType): Observable<Array<SelectorItemModel>> {
      return this.entityViewInformationServiceBase.getViewInformation(entityType).pipe(
         switchMap((entityInformation) => {
            const valueSource = new ValueSourceModel();
            valueSource.Type = ValueSourceTypes.Entity;
            valueSource.EntityType = entityType;
            valueSource.EntityValueSourcePath = entityInformation.EntityPrimaryKeyPropertyPath;
            valueSource.DisplayValueSourcePath = entityInformation.EntityListItemTemplate;
            return this.valueSourceService.process(valueSource);
         }),
         takeUntil(this.unsubscribe)
      );
   }
}
