<div *ngIf="showTextBoxSuggestions; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
   <input
      #textBox
      type="{{ inputType }}"
      min="{{min}}"
      [attr.id]="path"
      [ngModel]="value"
      title="{{ hideTitle ? '' : tooltipOverride ? tooltipOverride : value }}"
      (ngModelChange)="onModelChange($event)"
      [ngModelOptions]="{ standalone: true }"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [autocomplete]="'new-' + name"
      [ngStyle]="inputCustomStyle"
      [ngStyle]="textBoxCustomStyle"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (keyup.enter)="onEnterKey($event)"
   />
   <i
      *ngIf="icon"
      class="text-box-icon material-icons"
   >{{ icon }}</i>
   <lcs-validation-overlay-panel
      [style.visibility]="showErrorOverlay? 'hidden': 'visible'"
      [modelRef]="ngControl"
      [parentElement]="textBox"
      [displayName]="displayName"
      [name]="name"
   ></lcs-validation-overlay-panel>
   <i class="material-icons validation-error-icon">error_outline</i>
</ng-template>
<ng-template #elseBlock>
   <input
      #textBox
      type="{{ inputType }}"
      min="{{min}}"
      [attr.id]="path"
      [ngModel]="value"
      [ngStyle]="textBoxCustomStyle"
      title="{{ value }}"
      (ngModelChange)="onModelChange($event)"
      [ngModelOptions]="{ standalone: true }"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      autocomplete="off"
      (keyup.enter)="onEnterKey($event)"
   />
   <i
      *ngIf="icon"
      class="text-box-icon material-icons"
   >{{ icon }}</i>
   <lcs-validation-overlay-panel
      *ngIf="showErrorOverlay"
      [modelRef]="ngControl"
      [parentElement]="textBox"
      [displayName]="displayName"
      [name]="name"
   ></lcs-validation-overlay-panel>
   <i class="material-icons validation-error-icon">error_outline</i>
</ng-template>