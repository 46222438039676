import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { AmountBasis } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/amount-basis.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Subject } from "rxjs";

import { coerceBoolean } from "../../../utils/boolean-coercion";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
@Component({
   selector: "lcs-amount-selection-psf-report-parameter",
   templateUrl: "amount-selection-psf-report-parameter.component.html",
   styleUrls: ["amount-selection-psf-report-parameter.component.scss"],
   viewProviders: [ControlContainerViewProvider],
})
export class AmountSelectionPSFReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   amountBasisReportParameter: ReportReportParameterViewModel | undefined;

   includeTotalPSFReportParameter: ReportReportParameterViewModel | undefined;

   amountBasisValue: ReportParameterValueModel | undefined;

   includeTotalPSFValue: ReportParameterValueModel | undefined;

   amountBasis = AmountBasis;

   viewBasisValue: AmountBasis;

   viewIncludeTotalPSF: boolean;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit(): void {
      this.initializeParameters();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.amountBasisReportParameter!.IsAsk = isAsk;
      this.includeTotalPSFReportParameter!.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(ReportParameter.AmountBasis, isAsk);
      this.reportParametersService.updateReportParameterAsk(ReportParameter.IncludeTotalPSF, isAsk);
   }

   amountBasisChange(value: AmountBasis) {
      if (!this.amountBasisValue || value === this.amountBasisValue.value) {
         return;
      }
      this.viewBasisValue = value;
      this.amountBasisValue.value = value;
      this.reportParametersService.updateParameterValue.next(this.amountBasisValue);

      if (value !== AmountBasis.Actuals) {
         this.includeTotalPSFChange(false);
      }
   }

   includeTotalPSFChange(value: boolean) {
      if (!this.includeTotalPSFValue || value === this.includeTotalPSFValue.value) {
         return;
      }
      this.viewIncludeTotalPSF = value;
      this.includeTotalPSFValue.value = value;
      this.reportParametersService.updateParameterValue.next(this.includeTotalPSFValue);
   }

   private initializeParameters() {
      this.amountBasisReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.AmountBasis
      );

      this.includeTotalPSFReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IncludeTotalPSF
      );

      this.amountBasisValue = this.reportParametersService.reportParameterValues.get(ReportParameter.AmountBasis);
      if (this.amountBasisValue) {
         this.viewBasisValue = +this.amountBasisValue.value ?? AmountBasis.Actuals;
      }
      this.includeTotalPSFValue = this.reportParametersService.reportParameterValues.get(
         ReportParameter.IncludeTotalPSF
      );
      if (this.includeTotalPSFValue) {
         this.viewIncludeTotalPSF = coerceBoolean(this.includeTotalPSFValue.value) ?? false;
      }
   }
}
