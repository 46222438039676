import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { PasswordResetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/password-reset.model";
import { PasswordResetService } from "projects/libraries/owa-gateway-sdk/src/lib/services/password-reset.service";
import { Subject, take, takeUntil } from "rxjs";

import { AccountContextMenuComponent } from "../top-bar/account-context-menu/account-context-menu.component";

@Component({
   selector: "owa-change-password",
   templateUrl: "./change-password.component.html",
   styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
   @Input() disableSessionCheck: boolean;

   public workflowStep: string;
   public accountcontextmenu: AccountContextMenuComponent;
   public workflowSteps: string;
   public sourceStep: boolean;
   public unsubscribe = new Subject<void>();
   public currentPasswordValidationModel = new ValidationModel();
   public newPasswordValidationModel = new ValidationModel();
   public confirmNewPasswordValidationModel = new ValidationModel();

   progressButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   public passwordResetModel = new PasswordResetModel();

   constructor(
      private passwordResetService: PasswordResetService,
      private errorMessageService: ErrorMessageService,
      private sessionCheckService: SessionCheckService
   ) {}

   ngOnInit() {
      if (!this.disableSessionCheck) {
         this.sessionCheckService.checkSessionOnFocus = false;
      }
   }

   ngOnDestroy() {
      this.sessionCheckService.checkSessionOnFocus = true;
   }

   changePassword() {
      this.progressButtonStatus = LcsProgressButtonStatus.InProgress;
      if (
         this.passwordResetModel.Currentpassword === undefined ||
         this.passwordResetModel.ConfirmPassword === undefined ||
         this.passwordResetModel.Newpassword === undefined
      ) {
         this.errorMessageService.triggerErrorMessage("Password Fields are Required");
         this.progressButtonStatus = LcsProgressButtonStatus.Error;
         return;
      }
      this.passwordResetService.changePassword(this.passwordResetModel);
      this.passwordResetService.Results.pipe(take(1), takeUntil(this.unsubscribe)).subscribe((res) => {
         if (res != null) {
            if (!res.ok) {
               this.progressButtonStatus = LcsProgressButtonStatus.Error;
               this.errorMessageService.triggerHttpErrorMessage(res);
            } else {
               this.progressButtonStatus = LcsProgressButtonStatus.Success;
            }
         }
      });
   }
}
