import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TrackByFunction, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { SelectComponent } from "@lcs/select/components/select.component";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { GeneralLedgerAccountType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/general-ledger-account-type.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";

@Component({
   selector: "lcs-chart-accounts-to-include-overlay-panel",
   templateUrl: "./chart-accounts-to-include-overlay-panel.component.html",
})
export class ChartAccountsToIncludeOverlayPanelComponent implements OnInit, OnDestroy {
   @Input() alignToRight: boolean;

   @Input() control: UntypedFormControl;

   @Input() disabled: boolean;

   @Input() showMapping: boolean;

   @Input() displayName: string = "Value";

   @Input() innerElementSelector: string;

   @Input() innerElementTags: Array<string>;

   @Input() overlayPanelStaticWidth: number;

   @Input() overlayPanelStaticMinWidth: number;

   @Input() set items(value: Array<SelectorItemModel>) {
      if (value) {
         this._items = value;
         this.updateUI();
      }
   }

   get items(): Array<SelectorItemModel> {
      return this._items;
   }

   @Input() glAccountMapValueSource: ValueSourceModel;

   @Input() glAccountSelectorsConfigured: boolean;

   @Input() glAccountSelectorEndpoint: string;

   @Input() glAccountSelectorEndpointIsSearch: boolean;

   @Input() glAccountSelectorFilters: Array<FilterOption>;

   @Input() toggleOnClick: boolean = true;

   @Input() glAccountMapID: number;

   @Input() fromGlAccountID: any;

   @Input() toGlAccountID: any;

   @Input() glAccountTypeID: number;

   @Input() isLoading: boolean = false;

   @Input() parentElement: ElementRef;

   @Input() set show(val: boolean) {
      if (val !== this._show) {
         this._show = val;
         this.visibilityChanged.emit(this._show);
      }
   }

   @Input() staticOverlay: boolean;

   @Input() isAllSelected: boolean;

   @Input() selectedItemCount: number = 0;

   @Output() visibilityChanged = new EventEmitter<boolean>(false);

   @Output() selectionChange = new EventEmitter<SelectionChangeModel>();

   @Output() mapSelectionChanged = new EventEmitter<number>();

   @Output() fromAccountChanged = new EventEmitter<any>();

   @Output() toAccountChanged = new EventEmitter<any>();

   @Output() groupSelectionChanged = new EventEmitter<SelectorItemModel>();

   @ViewChild("accountGroupSelector") accountGroupSelector: SelectComponent;

   get show(): boolean {
      return this._show;
   }

   filteredItems: SelectorItemModel[];

   get searchValue(): string {
      return this._searchValue;
   }

   set searchValue(val: string) {
      if (this._searchValue !== val) {
         this._searchValue = val;
         this.searchValueChange.next(this._searchValue);
      }
   }

   accountTypeValuesToExclude = [GeneralLedgerAccountType.NotSet];

   glAccountSelectorEntityType = EntityType.GLAccount;

   private _items = new Array<SelectorItemModel>();

   private _show: boolean = false;

   private unsubscribe = new Subject<void>();

   private _searchValue: string;

   private searchValueChange: EventEmitter<string> = new EventEmitter<string>();

   trackByFn: TrackByFunction<SelectorItemModel> = (_: number, item: SelectorItemModel) => item.value;

   ngOnInit() {
      this.searchValueChange
         .pipe(
            debounceTime(500),
            distinctUntilChanged((emittedValue) => {
               return emittedValue === this._searchValue;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((searchText) => {
            this.filterItems(searchText);
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   onItemSelected() {
      this.updateUI();
      this.selectedItemsChanged();
   }

   allSelected(isSelected: boolean) {
      this.items.forEach((item) => {
         if (!item.isHidden) {
            item.isChecked = isSelected;
         }
      });
      this.updateUI();
      this.selectedItemsChanged();
   }

   clear() {
      this.items.forEach((item) => {
         if (!item.isHidden) {
            item.isChecked = false;
         }
      });
      this.fromAccountChanged.emit("");
      this.toAccountChanged.emit("");
      this.mapSelectionChanged.emit(0);

      const selectorItemModel = new SelectorItemModel();
      selectorItemModel.value = null;
      this.groupSelectionChanged.emit(selectorItemModel);

      this.updateUI();
      this.selectedItemsChanged();
   }

   onAccountGroupChanged() {
      this.groupSelectionChanged.emit(this.accountGroupSelector.selectedItem);
   }

   private filterItems(searchText: string) {
      if (!searchText || searchText.length === 0) {
         this.items.forEach((i) => (i.isHidden = false));
      } else {
         this.items.forEach((i) => {
            i.isHidden = i.displayValue.toLowerCase().indexOf(searchText.toLowerCase()) < 0;
         });
      }
      this.updateUI();
   }

   private updateUI() {
      this.selectedItemCount = this.items.filter((i) => i.isChecked).length;
      this.isAllSelected = this.items.length > 0 && !this.items.some((i) => !i.isHidden && !i.isChecked);
      this.filteredItems = this.items.filter((item) => !item.isHidden);
   }

   private selectedItemsChanged() {
      const selection = new SelectionChangeModel();
      selection.checkedItems = this.items.filter((i) => i.isChecked);
      selection.items = this.items;
      selection.selectAll = !this.items.some((i) => !i.isChecked);
      this.selectionChange.emit(selection);
   }
}
