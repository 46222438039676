import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ElementRef, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { MobileFocusOverlayComponent } from "./mobile-focus-overlay.component";

@Injectable({
   providedIn: "root",
})
export class MobileFocusOverlayService {
   public mobileFocusModeSet: Observable<boolean>;
   public mobileFocusModeAllowed: Observable<boolean>;
   public get isMobileFocusModeActive(): boolean {
      return this.mobileFocusModeActive;
   }

   private _mobileFocusModeSet: Subject<boolean> = new Subject<boolean>();
   private _mobileFocusModeAllowed: Subject<boolean> = new Subject<boolean>();
   private mobileFocusOverlayComponent: MobileFocusOverlayComponent;
   private currentlyRequestedMobileFocusElement: ElementRef | null;
   private breakpointMobileFocus: boolean = false;
   private mobileFocusModeActive: boolean = false;

   constructor(private breakpointObserver: BreakpointObserver) {
      this.mobileFocusModeSet = this._mobileFocusModeSet.asObservable();
      this.mobileFocusModeAllowed = this._mobileFocusModeAllowed.asObservable();

      this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
         this.breakpointMobileFocus = state.matches;
         this._mobileFocusModeAllowed.next(this.breakpointMobileFocus);
      });
   }

   public setMobileFocusOverlayComponent(component: MobileFocusOverlayComponent) {
      this.mobileFocusOverlayComponent = component;
   }

   public requestMobileFocusMode(elementToFocus: ElementRef) {
      if (elementToFocus != null && this.mobileFocusOverlayComponent != null && !this.mobileFocusModeActive) {
         this.currentlyRequestedMobileFocusElement = elementToFocus;
         if (this.breakpointMobileFocus) {
            this.mobileFocusModeActive = true;
            this.mobileFocusOverlayComponent.setFocusedElement(this.currentlyRequestedMobileFocusElement);
            this._mobileFocusModeSet.next(true);
         }
      }
   }

   public cancelMobileFocusModeRequest() {
      this.currentlyRequestedMobileFocusElement = null;
   }

   public endMobileFocusMode() {
      if (
         this.mobileFocusOverlayComponent != null &&
         this.currentlyRequestedMobileFocusElement != null &&
         this.mobileFocusModeActive
      ) {
         this.mobileFocusModeActive = false;
         this.mobileFocusOverlayComponent.revertFocusedElement(this.currentlyRequestedMobileFocusElement);
         this._mobileFocusModeSet.next(false);
      }
   }

   public canGoIntoMobileFocusMode(): boolean {
      return this.mobileFocusOverlayComponent != null && this.breakpointMobileFocus;
   }

   public inMobileFocusMode(): boolean {
      return this.mobileFocusModeActive;
   }

   public isCurrentlyRequestedMobileFocusElement(elRef: ElementRef): boolean {
      if (this.currentlyRequestedMobileFocusElement != null) {
         return elRef === this.currentlyRequestedMobileFocusElement;
      }
      return false;
   }
}
