import { Directive, Input, OnChanges, OnDestroy, Self, SimpleChanges } from "@angular/core";
import { SelectComponent } from "@lcs/select/components/select.component";
import { StaticSetSelectorDirectiveBase } from "@lcs/select/directives/static-set-selector.directive.base";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { takeUntil } from "rxjs";

import { EnumerationInformationService } from "../utils/enumeration-information.service";

@Directive({
   selector: "[lcsEnumerationSelector]",
})
export class EnumerationSelectorDirective extends StaticSetSelectorDirectiveBase implements OnDestroy, OnChanges {
   @Input() enumerationName: string;

   @Input() valuesToExclude: Array<string | number | boolean>;

   @Input() valuesToInclude: Array<string | number | boolean>;

   @Input() addBlankItem: boolean;

   @Input() blankItemOverride: SelectorItemModel | null;

   @Input() orderAlphabetically: boolean;

   constructor(
      private enumerationInformationService: EnumerationInformationService,
      @Self() protected selectComponent: SelectComponent
   ) {
      super(selectComponent);
      this.selectComponent.selectFirstItemOnSearch = true;
   }

   ngOnDestroy() {
      super.ngOnDestroy();
   }

   ngOnChanges(changes: SimpleChanges): void {
      this.retrieveItems();
   }

   private retrieveItems() {
      if (!this.enumerationName) {
         this.selectComponent.errorMessage = "Invalid Configuration.";
         this.selectComponent.isSearching = false;
         console.warn(`Invalid Configuration. Enumeration Name is required.`);
         return;
      }

      this.enumerationInformationService
         .getEnumerationInformation(this.enumerationName)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            const items = [];
            for (const result of results) {
               if (
                  this.valuesToExclude &&
                  this.valuesToExclude.some(
                     (value) => value.toString().toLowerCase() === result.Value.toString().toLowerCase()
                  )
               ) {
                  continue;
               }
               if (
                  this.valuesToInclude &&
                  !this.valuesToInclude.some(
                     (value) => value.toString().toLowerCase() === result.Value.toString().toLowerCase()
                  )
               ) {
                  continue;
               }
               const selectorItem = new SelectorItemModel();
               selectorItem.value = result.Value;
               selectorItem.displayValue = result.Description;
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'SelectorItemModel' is not assign... Remove this comment to see the full error message
               items.push(selectorItem);
            }
            if (this.addBlankItem) {
               const blankItemOverride: SelectorItemModel = this.blankItemOverride
                  ? this.blankItemOverride
                  : new SelectorItemModel("", "");
               // @ts-ignore ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
               if (items.filter((item) => item.value === blankItemOverride.value).length === 0) {
                  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'SelectorItemModel' is not assign... Remove this comment to see the full error message
                  items.unshift(blankItemOverride);
               }
            }
            if (this.orderAlphabetically) {
               // @ts-ignore ts-migrate(2339) FIXME: Property 'displayValue' does not exist on type 'ne... Remove this comment to see the full error message
               items.sort((a, b) => (a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1 : -1));
            }
            this.itemSet = items;
         });
   }
}
