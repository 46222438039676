<lcs-slide-panel (close)="onCloseClick()">
   <h2 lcsSlidePanelTitle>Work Orders</h2>
   <lcs-slide-panel-content>
      <lcs-loading-overlay [show]="$any(loading | async)">
         <owa-issue-workorder-list
            [workOrderItems]="$any(workOrders | async)"
            [totalCount]="$any((workOrders | async)?.length)"
            [showBills]="$any((preferences | async)?.showBills)"
         ></owa-issue-workorder-list>
      </lcs-loading-overlay>
   </lcs-slide-panel-content>
</lcs-slide-panel>