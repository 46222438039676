////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum EpayAccountType {
   NotSet = 0,
   Visa = 1,
   MasterCard = 2,
   AmericanExpress = 3,
   Discover = 4,
   DinersClub = 5,
   Checking = 6,
   Savings = 7,
   BusinessChecking = 8,
   GenericCC = 9,
   GenericACH = 10,
   CashPay = 11,
   CreditCard = 12,
   DebitCard = 13,
   Lockbox = 14,
}
