import { ReportFieldDataType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-field-data-type.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ReportUserControlFrame } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-user-control-frame.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";

import { ReportParameterValueSourceModel } from "./report-parameter-value-source.model";
import { ReportParameterModel } from "./report-parameter.model";
import { ReportModel } from "./report.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class MemorizedReportReportParameterModel {
   ColNumber: number;
   ColSpan: number;
   DataType: ReportFieldDataType;
   DefaultValue: string;
   DisplayName: string;
   IsHidden: boolean;
   MemorizedReportID: number;
   MetaTag?: string;
   PromptText: string;
   PropertyName: string;
   Report: ReportModel;
   ReportID: Report;
   ReportParameter: ReportParameterModel;
   ReportParameterID: ReportParameter;
   ReportParameterValueSource: ReportParameterValueSourceModel;
   RowNumber: number;
   RowSpan: number;
   SortOrder: number;
   ToolTip: string;
   UserControlFrame: ReportUserControlFrame;
}
