import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import cloneDeep from "lodash/cloneDeep";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { PropertyFields } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-fields/property.fields";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OwnerGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/owner-group.model";
import { PropertyGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-group.model";
import { ReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { PropertyGroupsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/property-groups.service";
import { OwnerGroupsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/owner-groups.service";
import { forkJoin, of, Subject, takeUntil } from "rxjs";

import { ContextService } from "../../../action-context/context.service";
import { coerceBoolean } from "../../../utils/boolean-coercion";
import { AllValueType, isAllValueType } from "../all-value.type";
import { ReportParameterControlStatusModel } from "../models/report-parameter-control-status.model";
import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-properties-and-owners-to-include-report-parameter",
   templateUrl: "properties-and-owners-to-include-report-parameter.component.html",
   styleUrls: ["properties-and-owners-to-include-report-parameter.component.scss"],
})
export class PropertiesAndOwnersToIncludeReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() disabled: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() hasAsk: boolean;

   @Input() report: ReportModel;

   @Output() selectionChanged = new EventEmitter<SelectionChangeModel>();

   propertyOwnerReportParameter: ReportReportParameterViewModel;

   propertyReportParameter: ReportReportParameterViewModel;

   ownerReportParameter: ReportReportParameterViewModel;

   runByOwnerReportParameter: ReportReportParameterViewModel;

   includeInactiveReportParameter: ReportReportParameterViewModel;

   useOwnerPropertiesReportParameter: ReportReportParameterViewModel;

   includeOwnerInactivePropertiesReportParameter: ReportReportParameterViewModel;

   disableUseOwnerProperties: boolean = false;

   disableIncludeInactiveProperties: boolean = true;

   isBatchReport: boolean;

   groupReportParameter: ReportReportParameterViewModel;

   groupValueSources: Array<ValueSourceModel>;

   propertiesReplacementName: string;

   ownersReplacementName: string;

   parameterLabel: string;

   isPropertiesTabSelected: boolean = true;

   includeInactiveProperties: boolean = false;

   propertyFilters: Array<FilterOption>;

   selectedGroupID: number | "" = "";

   disablePropertyMultiSelectors = false;

   disableOwnerMultiSelectors = false;

   isLoadingGroups = false;

   tabActiveIndex = 0;

   askParameter: ReportReportParameterViewModel = new ReportReportParameterViewModel();

   primaryParameter: ReportReportParameterViewModel;

   private unsubscribe = new Subject<void>();

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private propertyGroups = new Array<PropertyGroupModel>();

   private selectedPropertyGroupID: number | "" = "";

   private selectedPropertyIDs: Array<number | AllValueType> = new Array<number | AllValueType>();

   private ownerGroups = new Array<OwnerGroupModel>();

   private selectedOwnerGroupID: number | "" = "";

   private selectedOwnerIDs: Array<number | AllValueType> = new Array<number | AllValueType>();

   private groupMode = EntityType.Property;

   private reportParameter: ReportParameter;

   constructor(
      private reportParametersService: ReportParametersService,
      private propertyGroupsService: PropertyGroupsService,
      private ownerGroupsService: OwnerGroupsService,
      private errorMessageService: ErrorMessageService,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private contextService: ContextService
   ) {
      this.groupValueSources = new Array<ValueSourceModel>();

      this.entityViewInformationService
         .getViewInformation(EntityType.Property)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((viewInformation) => {
            this.propertiesReplacementName = viewInformation.CollectionLabel;
            this.parameterLabel = `${this.propertiesReplacementName} to Include`;
         });

      this.entityViewInformationService
         .getViewInformation(EntityType.Owner)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((viewInformation) => {
            this.ownersReplacementName = viewInformation.CollectionLabel;
         });
   }

   ngOnInit() {
      this.initializeReportParameterValues();
      this.initializeParameters();
      this.initializePropertyFilters();

      if (this.primaryParameter.ReportParameterID === ReportParameter.OWNERIDS) {
         this.parameterLabel = `${this.ownersReplacementName} To Include`;
         this.groupMode = EntityType.Owner;
      }
      let hasPropertyGroups = false;
      let hasOwnerGroups = false;

      this.reportReportParameters.forEach((rrp) => {
         if (
            rrp.ReportParameterID === ReportParameter.GroupID &&
            (this.reportParameter === ReportParameter.PropOwnerIDs || this.reportParameter === ReportParameter.OWNERIDS)
         ) {
            hasOwnerGroups = true;
         }

         if (
            rrp.ReportParameterID === ReportParameter.GroupID &&
            (this.reportParameter === ReportParameter.PropOwnerIDs ||
               this.reportParameter === ReportParameter.PropertyIDs)
         ) {
            hasPropertyGroups = true;
         }
      });

      if (hasPropertyGroups || hasOwnerGroups) {
         this.getPropertyAndOwnerGroups(hasPropertyGroups, hasOwnerGroups);
      }
      this.reportParametersService.propertyOrOwnerActiveIndex.next(this.tabActiveIndex);
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.PropOwnerIDs) {
         if (this.groupMode === EntityType.Property) {
            this.selectedPropertyIDs = updatedValue.rawValues;
         } else if (this.groupMode === EntityType.Owner) {
            this.selectedOwnerIDs = updatedValue.rawValues;
         }
      }
      if (updatedValue.reportParameter === ReportParameter.SHOWINACTIVEPROPS) {
         this.inactivePropertiesChecked(updatedValue.value);
      }
      if (updatedValue.reportParameter === ReportParameter.USEOWNERPROPERTIES && coerceBoolean(updatedValue.value)) {
         this.disableIncludeInactiveProperties = false;
         this.disabled = true;
      } else if (
         updatedValue.reportParameter === ReportParameter.USEOWNERPROPERTIES &&
         !coerceBoolean(updatedValue.value)
      ) {
         this.disableIncludeInactiveProperties = true;
         this.disabled = false;
      }
   }

   getPropertyAndOwnerGroups(hasPropertyGroups: boolean, hasOwnerGroups: boolean) {
      this.isLoadingGroups = true;

      let ownerGroupRequest = of(null);
      let propertyGroupRequest = of(null);

      const propertyFilters = new Array<FilterOption>();
      if (
         this.report &&
         this.report.PropertyTypes &&
         this.report.PropertyTypes.length &&
         this.report.PropertyTypes.length > 0
      ) {
         propertyFilters.push(
            new FilterOption("Properties.PropertyType", FilterOperations.In, this.report.PropertyTypes)
         );
      }

      if (hasPropertyGroups) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<PropertyGroupModel[]>' is not ass... Remove this comment to see the full error message
         propertyGroupRequest = this.propertyGroupsService.getCollection(
            propertyFilters,
            ["Properties"],
            [new OrderingOption("Name")]
         );
      }
      if (hasOwnerGroups) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<OwnerGroupModel[]>' is not assign... Remove this comment to see the full error message
         ownerGroupRequest = this.ownerGroupsService.getCollection(null, ["Owners"]);
      }
      forkJoin([propertyGroupRequest, ownerGroupRequest])
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            ([propertyGroups, ownerGroups]) => {
               if (propertyGroups) {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'PropertyGro... Remove this comment to see the full error message
                  this.propertyGroups = propertyGroups;
                  this.toggleGroup();
                  if (this.selectedPropertyGroupID) {
                     this.updateForGroupSelected(this.selectedPropertyGroupID);
                  }
               }
               if (ownerGroups) {
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'OwnerGroupM... Remove this comment to see the full error message
                  this.ownerGroups = ownerGroups;
                  this.toggleGroup();
                  if (this.selectedOwnerGroupID) {
                     this.updateForGroupSelected(this.selectedOwnerGroupID);
                  }
               }
               this.isLoadingGroups = false;
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
            }
         );
   }

   toggleGroup() {
      let groupItems: Array<PropertyGroupModel> | Array<OwnerGroupModel>;
      let selectedGroupID: number | "";
      if (this.groupMode === EntityType.Property) {
         groupItems = this.propertyGroups;
         selectedGroupID = this.selectedPropertyGroupID;
         this.disableOwnerMultiSelectors = true; // disable to avoid possible duplicate validation error messages
         this.disablePropertyMultiSelectors = selectedGroupID !== ""; // disable if group is selected
         this.updateReportParameterStatusesForProperties();
         this.updateReportParameter(this.selectedPropertyIDs);
      } else if (this.groupMode === EntityType.Owner) {
         groupItems = this.ownerGroups;
         selectedGroupID = this.selectedOwnerGroupID;
         this.disablePropertyMultiSelectors = true; // disable to avoid possible duplicate validation error messages
         this.disableOwnerMultiSelectors = selectedGroupID !== ""; // disable if group is selected
         this.updateReportParameterStatusesForOwners();
         this.updateReportParameter(this.selectedOwnerIDs);
      }

      // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValueSource... Remove this comment to see the full error message
      this.groupValueSources = null;
      const selectedValueSources = new Array<ValueSourceModel>();
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'groupItems' is used before being assigne... Remove this comment to see the full error message
      if (groupItems.length > 0) {
         const valueSourceModel = new ValueSourceModel();
         valueSourceModel.Type = ValueSourceTypes.Static;
         let item = new SelectorItemModel();
         item.displayValue = "None";
         item.value = "";
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedGroupID' is used before being as... Remove this comment to see the full error message
         if (selectedGroupID == null || selectedGroupID === "") {
            item.isChecked = true;
         }
         valueSourceModel.StaticValues.push(item);
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'groupItems' is used before being assigne... Remove this comment to see the full error message
         for (const group of groupItems) {
            item = new SelectorItemModel();
            item.displayValue = group.Name;
            item.value = group.GroupID;
            if (
               item.value &&
               // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedGroupID' is used before being as... Remove this comment to see the full error message
               selectedGroupID &&
               item.value.toString().toLowerCase() === selectedGroupID.toString().toLowerCase()
            ) {
               item.isChecked = true;
            }
            valueSourceModel.StaticValues.push(item);
         }
         selectedValueSources.push(valueSourceModel);
         this.groupValueSources = selectedValueSources;
      }
      // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedGroupID' is used before being as... Remove this comment to see the full error message
      this.selectedGroupID = selectedGroupID;
   }

   updateGroup(index: any) {
      let groupItems: Array<PropertyGroupModel> | Array<OwnerGroupModel>;
      let selectedGroupID: number | "";
      if (index === 0) {
         this.groupMode = EntityType.Property;
         groupItems = this.propertyGroups;
         selectedGroupID = this.selectedPropertyGroupID;
         this.toggleGroup();
      } else if (index === 1) {
         this.groupMode = EntityType.Owner;
         groupItems = this.ownerGroups;
         selectedGroupID = this.selectedOwnerGroupID;
         this.toggleGroup();
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'groupItems' is used before being assigne... Remove this comment to see the full error message
      if (!groupItems || groupItems.length === 0) {
         this.updateForGroupSelected("");
      } else {
         // @ts-ignore ts-migrate(2454) FIXME: Variable 'selectedGroupID' is used before being as... Remove this comment to see the full error message
         this.updateForGroupSelected(selectedGroupID);
      }

      const isRunByOwnerValueModel = new ReportParameterValueModel(
         ReportParameter.RUNBYOWNER,
         this.groupMode === EntityType.Owner
      );
      this.reportParametersService.updateReportParameterValue(isRunByOwnerValueModel);
   }

   updateLabel(event: any) {
      this.isPropertiesTabSelected = !this.isPropertiesTabSelected;
      if (this.isPropertiesTabSelected) {
         this.disableUseOwnerProperties = false;
      } else {
         this.disableUseOwnerProperties = true;
         this.disableIncludeInactiveProperties = true;
      }
      this.parameterLabel = `${
         this.isPropertiesTabSelected ? this.propertiesReplacementName : this.ownersReplacementName
      } to Include`;
      this.reportParametersService.propertyOrOwnerActiveIndex.next(event.index);
   }

   /**
    * Coerce value to boolean then set propertyFilters accordingly
    */
   inactivePropertiesChecked(value: any) {
      this.includeInactiveProperties = coerceBoolean(value);

      const newFilters = new Array<FilterOption>();
      if (!this.includeInactiveProperties) {
         newFilters.push(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new FilterOption("IsActive", FilterOperations.EqualTo, [true], null, null, null, ExpressDataTypes.Boolean)
         );
      }
      if (
         this.report &&
         this.report.PropertyTypes &&
         this.report.PropertyTypes.length &&
         this.report.PropertyTypes.length > 0
      ) {
         newFilters.push(
            new FilterOption(PropertyFields.PropertyType.Identifier, FilterOperations.In, this.report.PropertyTypes)
         );
      }

      this.propertyFilters = newFilters;
      if (this.selectedPropertyGroupID) {
         const propertyGroup = this.propertyGroups.find(
            (pg) => pg.GroupID.toString() === this.selectedPropertyGroupID.toString()
         );
         if (propertyGroup) {
            let propertyIDs = new Array<number>();
            if (!this.includeInactiveProperties) {
               propertyIDs = propertyGroup.Properties.filter((property) => property.IsActive).map((p) => p.PropertyID);
            } else {
               propertyIDs = propertyGroup.Properties.map((p) => p.PropertyID);
            }
            this.updateReportParameter(propertyIDs);
         }
      }
   }

   groupSelected(selection: SelectionChangeModel) {
      if (this.selectedGroupID === selection.checkedItems[0]?.value) {
         return;
      }
      this.isLoadingGroups = true;
      this.updateForGroupSelected(selection.checkedItems[0]?.value);
      setTimeout(() => {
         this.isLoadingGroups = false;
      });
   }

   updateForGroupSelected(selectedGroupID: number | "") {
      this.selectedGroupID = selectedGroupID;

      if (!(parseInt(selectedGroupID.toString()) > 0)) {
         // selectedGroupID is not a positive integer
         if (this.groupMode === EntityType.Property) {
            this.selectedPropertyGroupID = "";
            this.disablePropertyMultiSelectors = false;
            this.disableOwnerMultiSelectors = true; // disable to prevent any validation issues on non-selected tab
         } else if (this.groupMode === EntityType.Owner) {
            this.selectedOwnerGroupID = "";
            this.disableOwnerMultiSelectors = false;
            this.disablePropertyMultiSelectors = true; // disable to prevent any validation issues on non-selected tab
         }

         const noGroupParameterValueModel = new ReportParameterValueModel(ReportParameter.GroupID, 0, [0]);
         this.reportParametersService.updateReportParameterValue(noGroupParameterValueModel);
      } else if (typeof selectedGroupID === "number") {
         // selectedGroupID is a positive integer
         if (this.groupMode === EntityType.Property && this.propertyGroups.length > 0) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'PropertyGroupModel | undefined' is not assig... Remove this comment to see the full error message
            const selectedPropertyGroup: PropertyGroupModel = this.propertyGroups.find(
               (pg) => pg.GroupID === selectedGroupID
            );

            if (selectedPropertyGroup) {
               this.disablePropertyMultiSelectors = true; // disable if group is selected
               this.disableOwnerMultiSelectors = true; // disable to prevent any validation issues on non-selected tab
               this.selectedPropertyGroupID = selectedPropertyGroup.GroupID;
               let propertyIDs = new Array<number>();
               if (!this.includeInactiveProperties) {
                  propertyIDs = selectedPropertyGroup.Properties.filter((property) => property.IsActive).map(
                     (p) => p.PropertyID
                  );
               } else {
                  propertyIDs = selectedPropertyGroup.Properties.map((p) => p.PropertyID);
               }

               this.updateReportParameter(propertyIDs);
            }
         } else if (this.groupMode === EntityType.Owner && this.ownerGroups.length > 0) {
            // @ts-ignore ts-migrate(2322) FIXME: Type 'OwnerGroupModel | undefined' is not assignab... Remove this comment to see the full error message
            const selectedOwnerGroup: OwnerGroupModel = this.ownerGroups.find((pg) => pg.GroupID === selectedGroupID);

            if (selectedOwnerGroup) {
               this.disableOwnerMultiSelectors = true; // disable if group is selected
               this.disablePropertyMultiSelectors = true; // disable to prevent any validation issues on non-selected tab
               this.selectedOwnerGroupID = selectedOwnerGroup.GroupID;
               const ownerIDs = selectedOwnerGroup.Owners.map((p) => p.OwnerID);
               this.updateReportParameter(ownerIDs);
            }
         }

         const groupParameterValueModel = new ReportParameterValueModel(ReportParameter.GroupID, selectedGroupID, [
            selectedGroupID,
         ]);

         this.reportParametersService.updateReportParameterValue(groupParameterValueModel);
      }
   }

   askChanged(isAsk: boolean) {
      this.askParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.askParameter.ReportParameterID, isAsk);
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   private updateReportParameter(ids: Array<number | AllValueType> | null) {
      if (ids) {
         let values: string = "";
         if (ids.length > 0) {
            if (isAllValueType(ids[0])) {
               values = ids[0];
            } else {
               values = `(${ids.join(",")})`;
            }
         }
         const valueModel = new ReportParameterValueModel(this.reportParameter, values, ids);
         this.reportParametersService.updateReportParameterValue(valueModel);
      }
   }
   private updateReportParameterStatusesForProperties() {
      const ownerContractReportParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.RestrictByOwnerContractDates
      );
      ownerContractReportParameterStatus.disabled = true;

      const separationMethodReportParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.BatchPropsOrUnits
      );
      separationMethodReportParameterStatus.disabled = false;

      const propertiesSeparationMethodParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.BatchProps
      );
      propertiesSeparationMethodParameterStatus.disabled = false;

      this.reportParametersService.reportParameterStatusChanged.next(ownerContractReportParameterStatus);
      this.reportParametersService.reportParameterStatusChanged.next(separationMethodReportParameterStatus);
      this.reportParametersService.reportParameterStatusChanged.next(propertiesSeparationMethodParameterStatus);
   }

   private updateReportParameterStatusesForOwners() {
      const ownerContractReportParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.RestrictByOwnerContractDates
      );
      ownerContractReportParameterStatus.disabled = false;

      const separationMethodReportParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.BatchPropsOrUnits
      );
      separationMethodReportParameterStatus.disabled = true;

      const propertiesSeparationMethodParameterStatus = new ReportParameterControlStatusModel(
         ReportParameter.BatchProps
      );
      propertiesSeparationMethodParameterStatus.disabled = true;

      this.reportParametersService.reportParameterStatusChanged.next(ownerContractReportParameterStatus);
      this.reportParametersService.reportParameterStatusChanged.next(separationMethodReportParameterStatus);
      this.reportParametersService.reportParameterStatusChanged.next(propertiesSeparationMethodParameterStatus);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.propertyOwnerReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.PropOwnerIDs
      );
      if (this.propertyOwnerReportParameter) {
         this.reportParameter = ReportParameter.PropOwnerIDs;
         const property = cloneDeep(this.propertyOwnerReportParameter);
         property.ReportParameterValueSource.EntitySources = property.ReportParameterValueSource.EntitySources.filter(
            (e) => e.EntityType === EntityType.Property
         );
         this.propertyReportParameter = property;
         const owner = cloneDeep(this.propertyOwnerReportParameter);
         owner.ReportParameterValueSource.EntitySources = owner.ReportParameterValueSource.EntitySources.filter(
            (e) => e.EntityType === EntityType.Owner
         );
         owner.ReportParameterValueSource.DefaultValue = owner.DefaultValue;
         this.ownerReportParameter = owner;
         this.primaryParameter = this.propertyOwnerReportParameter;
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
         this.propertyReportParameter = this.reportReportParameters.find(
            (p) => p.ReportParameterID === ReportParameter.PropertyIDs
         );
         // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
         this.ownerReportParameter = this.reportReportParameters.find(
            (p) => p.ReportParameterID === ReportParameter.OWNERIDS
         );
         if (this.propertyReportParameter && !this.ownerReportParameter) {
            this.reportParameter = ReportParameter.PropertyIDs;
            this.primaryParameter = this.propertyReportParameter;
         } else if (!this.propertyReportParameter && this.ownerReportParameter) {
            this.reportParameter = ReportParameter.OWNERIDS;
            this.primaryParameter = this.ownerReportParameter;
         }
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.runByOwnerReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.RUNBYOWNER
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.includeInactiveReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWINACTIVEPROPS
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.groupReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.GroupID
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.useOwnerPropertiesReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.USEOWNERPROPERTIES
      );

      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.includeOwnerInactivePropertiesReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES
      );

      this.isBatchReport = false;
      if (
         this.contextService.actionID === ExpressActions.ReportBatch_Details ||
         this.contextService.actionID === ExpressActions.ReportBatch_Add
      ) {
         this.isBatchReport = true;
      }

      if (this.isBatchReport) {
         if (!this.useOwnerPropertiesReportParameter && !this.includeOwnerInactivePropertiesReportParameter) {
            const useOwnerPropertiesValueModel = new ReportParameterValueModel(
               ReportParameter.USEOWNERPROPERTIES,
               "false"
            );
            this.reportParametersService.updateReportParameterValue(useOwnerPropertiesValueModel);

            const useOwnerPropertiesReportParameter = new ReportReportParameterViewModel();

            useOwnerPropertiesReportParameter.DefaultValue = "false";
            useOwnerPropertiesReportParameter.ReportParameterID = ReportParameter.USEOWNERPROPERTIES;
            this.useOwnerPropertiesReportParameter = useOwnerPropertiesReportParameter;

            const includeOwnerInactivePropertiesValueModel = new ReportParameterValueModel(
               ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES,
               "false"
            );
            this.reportParametersService.updateReportParameterValue(includeOwnerInactivePropertiesValueModel);

            const includeOwnerInactivePropertiesReportParameter = new ReportReportParameterViewModel();

            includeOwnerInactivePropertiesReportParameter.DefaultValue = "false";
            includeOwnerInactivePropertiesReportParameter.ReportParameterID =
               ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES;
            this.includeOwnerInactivePropertiesReportParameter = includeOwnerInactivePropertiesReportParameter;
         } else {
            this.reportParametersService.updateReportParameterValue(
               new ReportParameterValueModel(
                  ReportParameter.USEOWNERPROPERTIES,
                  this.useOwnerPropertiesReportParameter.DefaultValue
               )
            );
            this.reportParametersService.updateReportParameterValue(
               new ReportParameterValueModel(
                  ReportParameter.SHOWINACTIVEPROPUSEOWNERPROPERTIES,
                  this.includeOwnerInactivePropertiesReportParameter.DefaultValue
               )
            );
         }
         this.includeOwnerInactivePropertiesReportParameter.PromptText = `Include Inactive ${this.propertiesReplacementName}`;
         this.useOwnerPropertiesReportParameter.PromptText = `Use Owner's ${this.propertiesReplacementName}`;
      }

      if (!this.includeInactiveReportParameter) {
         const includeInactiveValueModel = new ReportParameterValueModel(ReportParameter.SHOWINACTIVEPROPS, "false");
         this.reportParametersService.updateReportParameterValue(includeInactiveValueModel);

         const includeInactiveReportParameter = new ReportReportParameterViewModel();
         includeInactiveReportParameter.PromptText = `Inactive ${this.propertiesReplacementName}`;
         includeInactiveReportParameter.DefaultValue = "false";
         includeInactiveReportParameter.ReportParameterID = ReportParameter.SHOWINACTIVEPROPS;
         this.includeInactiveReportParameter = includeInactiveReportParameter;
      }

      this.inactivePropertiesChecked(this.includeInactiveReportParameter.DefaultValue);

      let isRunByOwner: string = "false";
      if (this.runByOwnerReportParameter?.ReportParameterValueSource) {
         isRunByOwner = this.runByOwnerReportParameter.ReportParameterValueSource.DefaultValue;
      }

      this.initializeRunByOwnerOrProperty(isRunByOwner);

      if (this.groupReportParameter?.ReportParameterValueSource) {
         const grpDefaultValue: string = this.groupReportParameter.ReportParameterValueSource.DefaultValue;
         const defaultGroupID: number | "" = parseInt(grpDefaultValue) > 0 ? parseInt(grpDefaultValue) : "";
         if (this.groupMode === EntityType.Owner) {
            this.selectedOwnerGroupID = defaultGroupID;
         } else {
            this.selectedPropertyGroupID = defaultGroupID;
         }
      }
      this.toggleGroup();

      if (this.propertyOwnerReportParameter) {
         this.askParameter = this.propertyOwnerReportParameter;
      } else if (this.propertyReportParameter && !this.ownerReportParameter) {
         this.askParameter = this.propertyReportParameter;
      } else if (!this.propertyReportParameter && this.ownerReportParameter) {
         this.askParameter = this.ownerReportParameter;
      }
   }

   /**
    * Initialize whether report is run by owner or property
    */
   private initializeRunByOwnerOrProperty(value: any) {
      const isRunByOwner: boolean = coerceBoolean(value);
      this.groupMode = isRunByOwner ? EntityType.Owner : EntityType.Property;
      this.tabActiveIndex = isRunByOwner ? 1 : 0;
      this.reportParametersService.propertyOrOwnerActiveIndex.next(this.tabActiveIndex);

      this.parameterLabel = `${isRunByOwner ? this.ownersReplacementName : this.propertiesReplacementName} to Include`;

      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const selectedValues: string = this.reportParametersService.reportParameterValues.get(this.reportParameter).value;
      if (isRunByOwner) {
         this.isPropertiesTabSelected = !this.isPropertiesTabSelected;
         if (isAllValueType(selectedValues)) {
            this.selectedOwnerIDs = ["all"];
            this.selectedPropertyIDs = ["all"]; // if OwnerIDs defaults to All, then init PropertyIDs to default to All also
         } else if (selectedValues?.length > 0) {
            this.selectedOwnerIDs = selectedValues.split(",").map((s) => parseInt(s));
         }
      } else {
         if (isAllValueType(selectedValues)) {
            this.selectedPropertyIDs = ["all"];
            this.selectedOwnerIDs = ["all"]; // if PropertyIDs defaults to All, then init OwnerIDs to default to All also
         } else if (selectedValues?.length > 0) {
            this.selectedPropertyIDs = selectedValues.split(",").map((s) => parseInt(s));
         }
      }

      this.toggleGroup();
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }

   private initializePropertyFilters(): void {
      const propertyFilters = new Array<FilterOption>();

      propertyFilters.push(
         new FilterOption(
            PropertyFields.IsActive.Identifier,
            FilterOperations.EqualTo,
            [!this.includeInactiveProperties],
            null,
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            null,
            ExpressDataTypes.Boolean
         )
      );

      if (
         this.report &&
         this.report.PropertyTypes &&
         this.report.PropertyTypes.length &&
         this.report.PropertyTypes.length > 0
      ) {
         propertyFilters.push(
            new FilterOption(PropertyFields.PropertyType.Identifier, FilterOperations.In, this.report.PropertyTypes)
         );
      }
      this.propertyFilters = propertyFilters;
   }
}
