import { Component, OnInit, Renderer2 } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { TopBarService } from "./top-bar.service";

@Component({
   selector: "owa-top-bar",
   templateUrl: "./top-bar.component.html",
   styleUrls: ["./top-bar.component.css"],
})
export class TopBarComponent implements OnInit {
   isVisible: boolean;

   private unsubscribe = new Subject<void>();

   private topBarVisibleClass = "top-bar-visible";

   constructor(private topBarService: TopBarService, private renderer: Renderer2) {
      this.isVisible = false;
      this.topBarService.topBarIsVisible.pipe(takeUntil(this.unsubscribe)).subscribe((isTopBarVisible) => {
         this.isVisible = isTopBarVisible;
         if (isTopBarVisible) {
            if (this.isVisible) {
               this.renderer.addClass(document.body, this.topBarVisibleClass);
            } else {
               this.renderer.removeClass(document.body, this.topBarVisibleClass);
            }
         }
      });
   }

   ngOnInit() {}
}
