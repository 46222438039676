<div
   id="signatureBox"
   *ngIf="!isSigned; else confirmation"
>
   <div class="customLabelText" *ngIf="approvalMessage.trim().length > 0">
      {{ approvalMessage }}
   </div>
   <div class="commentLabelText">{{ constantService.EstimateSignatureLabel }}</div>
   <input
      [(ngModel)]="approvalModel.Signature"
      type="text"
      class="signatureInput"
      id="signatureInput"
      maxlength="255"
   />
   <div class="signatureCommentLabelText">Additional Comments</div>
   <textarea
      type="text"
      class="signatureInput commentText"
      maxlength="255"
      [(ngModel)]="approvalModel.Comments"
   ></textarea>
   <div class="buttonContainer flex">
      <div class="flex-half">
         <lcs-progress-button
            [(status)]="progressButtonStatus"
            [raised]="true"
            (buttonClick)="signClick()"
            class="signatureButton"
         >
            Sign
         </lcs-progress-button>
      </div>
      <div class="flex-half">
         <lcs-progress-button
            [raised]="true"
            (buttonClick)="cancelClick()"
            class="cancelButton"
            [disabled]="isProcessing"
         >
            Cancel
         </lcs-progress-button>
      </div>
   </div>
</div>
<ng-template #confirmation>
   <div id="confirmationBox">
      <div
         class="labelText confirmationMessageText"
         style="list-style: inherit !important;padding: 5px;"
         [innerHTML]="confirmationMessage"
      ></div>
      <div
         class="buttonContainer"
         style="text-align: center;"
      >
         <lcs-progress-button
            [raised]="true"
            (buttonClick)="confirmationClick()"
            class="confirmationButton"
         >
            OK
         </lcs-progress-button>
      </div>
   </div>
</ng-template>