export enum WorkflowSteps {
   Signup,
   VerificationPending,
   MergeUsers,
   Login,
   AccountUpdatedSplashScreen,
   InvalidToken,
   RecoverAccount,
   Unset,
   ResendVerification,
}
