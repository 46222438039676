<div class="dashboard-message" *ngIf="signableDocumentPackets && signableDocumentPackets.length > 0">
   <div class="dashboard-message-value">
      {{message}}
   </div>
   <div class="dashboard-message-link-container">
      <a *ngIf="signableDocumentPackets.length > 1" routerLink="/signabledocuments">
         <div class="dashboard-message-link">
            documents
         </div>
      </a>

      <a *ngIf="signableDocumentPackets.length === 1 && hasDocumentsToSign" [routerLink]="['/signabledocuments', signableDocumentPackets[0].SignableDocumentPacketID, 'sign']">
         <div class="dashboard-message-link">
            sign
         </div>
      </a>

      <a *ngIf="signableDocumentPackets.length === 1 && !hasDocumentsToSign" [routerLink]="['/signabledocuments', signableDocumentPackets[0].SignableDocumentPacketID, 'view']">
         <div class="dashboard-message-link">
            view
         </div>
      </a>
      
   </div>
</div>