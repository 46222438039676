import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DataTableModule } from "@lcs/datatable/datatable.module";
import { FormRegistrationModule } from "@lcs/forms/form-registration/form-registration.module";
import { InfiniteScrollModule } from "@lcs/infinite-scroll/infinite-scroll.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";

import { LayoutModule } from "../layout/layout.module";
import { OWATableModule } from "../owa-table/owa-table.module";
import { TileModule } from "../tile/tile.module";
import { EstimateListContainerComponent } from "./estimate-list-container.component";
import { EstimatesListComponent } from "./estimate-list.component";

@NgModule({
   imports: [
      CommonModule,
      LayoutModule,
      InfiniteScrollModule,
      OWATableModule,
      DataTableModule,
      FormRegistrationModule,
      FormsModule,
      LoadingOverlayModule,
      RouterModule,
      TileModule,
      LcsSlidePanelModule,
   ],
   declarations: [EstimatesListComponent, EstimateListContainerComponent],
   exports: [EstimatesListComponent, EstimateListContainerComponent],
})
export class EstimatesListModule {}
