import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { LoadingOverlayConfigurationModel } from "@lcs/loading-overlay/loading-overlay-configuration.model";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";

import { ActionBarActionModel } from "../../action-bar/action-bar-action.model";
import { EntityEventResult } from "../../entity-events/entity-event-result.enum";

export const EntityEventResultProgressButtonStatus = new Map<EntityEventResult, LcsProgressButtonStatus>([
   [EntityEventResult.Unset, LcsProgressButtonStatus.Unset],
   [EntityEventResult.Success, LcsProgressButtonStatus.Success],
   [EntityEventResult.Error, LcsProgressButtonStatus.Error],
   [EntityEventResult.FailedValidation, LcsProgressButtonStatus.Warning],
   [EntityEventResult.InProgress, LcsProgressButtonStatus.InProgress],
]);

export const ProgressButtonStatusEntityEventResult = new Map<LcsProgressButtonStatus, EntityEventResult>([
   [LcsProgressButtonStatus.Unset, EntityEventResult.Unset],
   [LcsProgressButtonStatus.Success, EntityEventResult.Success],
   [LcsProgressButtonStatus.Error, EntityEventResult.Error],
   [LcsProgressButtonStatus.Warning, EntityEventResult.FailedValidation],
   [LcsProgressButtonStatus.InProgress, EntityEventResult.InProgress],
]);

@Component({
   selector: "lcs-progress-button-ext",
   templateUrl: "progress-button-ext.component.html",
   styleUrls: ["progress-button-ext.component.scss"],
})
export class ProgressButtonExtComponent implements OnDestroy {
   @Input() set loadingOverlayConfiguration(loadingOverlayConfiguration: LoadingOverlayConfigurationModel) {
      this._loadingOverlayConfiguration = loadingOverlayConfiguration;
   }

   get loadingOverlayConfiguration(): LoadingOverlayConfigurationModel {
      return this._loadingOverlayConfiguration;
   }

   @Input() set entityEventResult(entityEventResult: EntityEventResult | undefined) {
      if (entityEventResult != undefined) {
         this.status = this.getStatus(entityEventResult);
      }

      this._entityEventResult = entityEventResult;

      const hasStatusDelay = this.hasStatusChangeDelay(entityEventResult);
      if (hasStatusDelay && !this.waitingOnStatusChange) {
         this.waitingOnStatusChange = hasStatusDelay;
      } else {
         this.entityEventResultChange.emit(this._entityEventResult);
      }
   }
   get entityEventResult(): EntityEventResult | undefined {
      return this._entityEventResult;
   }

   @Input() set actionBarAction(actionBarAction: ActionBarActionModel | undefined) {
      if (actionBarAction) {
         this._actionBarAction = actionBarAction;
         if (actionBarAction.Icon) {
            this._icon = actionBarAction.Icon;
         } else if (actionBarAction.Action?.Icon) {
            this._icon = actionBarAction.Action?.Icon;
         }
      }
   }

   @Input() disabled: boolean;

   @Input() formId: string;

   @Input() text: string;

   @Input() type: string = "submit";

   @Input() isRaisedButton: boolean = true;

   @Input() isCancelEvent: boolean = false;

   @Input() multiSelectActionButton: boolean;

   @Input() buttonCustomClasses: Array<string>;

   @Input() set icon(icon: string | undefined) {
      if (icon) {
         this._icon = icon;
      }
   }

   @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

   @Output() entityEventResultChange = new EventEmitter<EntityEventResult>();

   @Output() buttonDestroyed = new EventEmitter<void>();

   @ViewChild("iconComponent", { read: ElementRef }) iconComponent: ElementRef;

   get actionBarAction(): ActionBarActionModel | undefined {
      return this._actionBarAction;
   }

   get icon(): string | undefined {
      return this._icon;
   }

   status: LcsProgressButtonStatus;

   private _entityEventResult: EntityEventResult | undefined;

   private waitingOnStatusChange: boolean = false;

   private _actionBarAction: ActionBarActionModel | undefined;

   private _icon: string | undefined;

   private _loadingOverlayConfiguration: LoadingOverlayConfigurationModel;

   constructor() {
      if (!this._loadingOverlayConfiguration) {
         this._loadingOverlayConfiguration = new LoadingOverlayConfigurationModel();
         this._loadingOverlayConfiguration.Diameter = 32;
         this._loadingOverlayConfiguration.HasOverlay = false;
      }
   }

   ngOnDestroy(): void {
      if (this.waitingOnStatusChange) {
         this.entityEventResultChange.emit(this._entityEventResult);
      }
      this.buttonDestroyed.emit();
   }

   onStatusChange(status: LcsProgressButtonStatus): void {
      this._entityEventResult = ProgressButtonStatusEntityEventResult.get(status);
      this.waitingOnStatusChange = false;
      this.entityEventResultChange.emit(this._entityEventResult);
   }

   onButtonClick() {
      if (this.disabled) return;
      this.buttonClick.emit();
   }

   private hasStatusChangeDelay(entityEventResult: EntityEventResult | undefined): boolean {
      const statusesWithoutDelay = [LcsProgressButtonStatus.Unset, LcsProgressButtonStatus.InProgress];
      const isSuccessfulCancel = this.isCancelEvent && entityEventResult === EntityEventResult.Success;

      return (
         entityEventResult != undefined &&
         !isSuccessfulCancel &&
         !statusesWithoutDelay.some((status) => ProgressButtonStatusEntityEventResult.get(status) === entityEventResult)
      );
   }

   private getStatus(entityEventResult: EntityEventResult | undefined): LcsProgressButtonStatus {
      let status: LcsProgressButtonStatus | undefined;
      if (entityEventResult && !(this.isCancelEvent && entityEventResult === EntityEventResult.Success)) {
         status = EntityEventResultProgressButtonStatus.get(entityEventResult);
      }
      return status ?? LcsProgressButtonStatus.Unset;
   }
}
