////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExpressEntityEvents {
   Unset = 0,
   Save = 1,
   Cancel = 2,
   TriggerAction = 3,
   Delete = 4,
   Refresh = 5,
}
