import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceManagerIssueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/service-manager-issue.model";

import { ServiceIssueListItemModel } from "../../service-issue-list/models/service-issue-list-item.model";
import { EstimateDetailPreferences } from "../estimate-detail-preferences.interface";

@Component({
   selector: "owa-serviceissue-list",
   templateUrl: "./estimate-serviceissue-list.component.html",
})
export class ServiceIssueListComponent {
   serviceIssueColumns: string[] = ["ServiceManagerIssueID", "OpenDate", "Closed", "StatusName", "Title", "Description", "PropertyName"];
   @Input() preferences: EstimateDetailPreferences;
   @Input() set serviceIssueItem(issue: ServiceManagerIssueModel) {
      this._serviceIssueItem = issue;
      if (issue != null) {
         this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
         this.serviceIssueListItems.push(new ServiceIssueListItemModel(this.serviceIssueItem));
      } else {
         this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
      }
   }
   get serviceIssueItem(): ServiceManagerIssueModel {
      return this._serviceIssueItem;
   }

   get serviceIssuePropertyName(): string {
      if (this.serviceIssueItem.Properties.length > 1) {
         return "<Multiple>";
      }
      return this.serviceIssueItem.Properties[0].Name;
   }

   serviceIssueListItems: ServiceIssueListItemModel[] = new Array<ServiceIssueListItemModel>();

   private _serviceIssueItem: ServiceManagerIssueModel;

   constructor(private router: Router) {
      this.serviceIssueListItems = new Array<ServiceIssueListItemModel>();
      this.serviceIssueListItems.push(new ServiceIssueListItemModel(this.serviceIssueItem));
   }

   showServiceIssueDetails(id: number) {
      if (id > 0) {
         this.router.navigate(["/serviceissues", id]);
      } else {
         return;
      }
   }
}
