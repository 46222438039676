import { MidpointRounding } from "./midpoint-rounding";

export function roundCurrency(
   value: number,
   decimalPlaces: number = 2,
   midPointRounding: MidpointRounding = MidpointRounding.ToEven
) {
   decimalPlaces = decimalPlaces || 0;
   const mod = Math.pow(10, decimalPlaces);
   if (midPointRounding === MidpointRounding.ToEven) {
      const n = +(decimalPlaces ? value * mod : value).toFixed(8); // Avoid rounding errors
      const i = Math.floor(n);
      const f = n - i;
      const e = 1e-8; // Allow for rounding errors in f
      const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
      return decimalPlaces ? r / mod : r;
   } else if (midPointRounding === MidpointRounding.ToZero) {
      return Math.floor(value * mod) / mod;
   } else {
      return Math.round(value * mod) / mod;
   }
}
