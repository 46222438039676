import { Params } from "@angular/router";
import { ActionMappingModel } from "@lcs/action-router/action-mapping.model";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";

export class RouteInformationModel {
   ActionMap: ActionMappingModel;
   ExpressAction: ExpressActionModel;
   EntityID: number | null = null;
   QueryParams: Params | null = null;
}
