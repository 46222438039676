<lcs-table [dataSource]="serviceIssueListItems">
   <tr lcs-header-row *lcsHeaderRowDef="serviceIssueColumns"></tr>
   <tr lcs-row *lcsRowDef="let row; columns: serviceIssueColumns"></tr>

   <ng-container lcsColumnDef="ServiceManagerIssueID" [width]="6" [priority]="1">
      <th lcs-header-cell *lcsHeaderCellDef>Issue#</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">
         <div (click)="showServiceIssueDetails(row.ServiceManagerIssueID)" class="link">
            {{ row.ServiceManagerIssueID }}
         </div>
      </td>
   </ng-container>

   <ng-container lcsColumnDef="OpenDate" [width]="6" [priority]="2">
      <th lcs-header-cell *lcsHeaderCellDef>Open Date</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.AssignedOpenDate | date: "MM/dd/yyyy" }}</td>
   </ng-container>

   <ng-container lcsColumnDef="Closed" [width]="4" [priority]="3">
      <th lcs-header-cell *lcsHeaderCellDef>Closed</th>
      <td lcs-cell *lcsCellDef="let row; let index = index" class="boolean">
         <div *ngIf="row.IsClosed">
            <i class="material-icons">check</i>
         </div>
      </td>
   </ng-container>

   <ng-container lcsColumnDef="StatusName" [width]="8" [priority]="4">
      <th lcs-header-cell *lcsHeaderCellDef>Status</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.StatusName }}</td>
   </ng-container>

   <ng-container lcsColumnDef="Title" [width]="15" [priority]="5">
      <th lcs-header-cell *lcsHeaderCellDef="let row">Title</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.Title }}</td>
   </ng-container>

   <ng-container lcsColumnDef="Description" [width]="20" [priority]="6">
      <th lcs-header-cell *lcsHeaderCellDef="let row">Description</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.Description }}</td>
   </ng-container>

   <ng-container lcsColumnDef="PropertyName" [width]="10" [priority]="7">
      <th lcs-header-cell *lcsHeaderCellDef="let row">Property</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ serviceIssuePropertyName }}</td>
   </ng-container>
</lcs-table>
