import { Component, Input, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";

@Component({
   selector: "lcs-select-unit-report-parameter",
   templateUrl: "select-unit-report-parameter.component.html",
})
export class SelectUnitReportParameterComponent implements OnInit, UserInputComponent {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   @Input() hasAsk: boolean;

   propertyParameter: ReportReportParameterViewModel;

   unitParameter: ReportReportParameterViewModel;

   showInactivePropertiesParameter: ReportReportParameterViewModel;

   _reportReportParameters: Array<ReportReportParameterViewModel>;

   ngOnInit() {
      this.propertyParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === ReportParameter.PropertyID
      )[0];
      this.propertyParameter.ReportParameterValueSource.AllowsMultipleValues = false;
      this.propertyParameter.ReportParameterValueSource.AllowsSelectAll = false;
      this.unitParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === ReportParameter.UNITID
      )[0];
      this.showInactivePropertiesParameter = this._reportReportParameters.filter(
         (p) => p.ReportParameterID === ReportParameter.SHOWINACTIVEPROPS
      )[0];
   }
}
