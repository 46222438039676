import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";
import { LcsSlidePanelModule } from "@lcs/slide-panel/slide-panel.module";
import { TableModule } from "@lcs/table/table.module";

import { LayoutModule } from "../layout/layout.module";
import { TileModule } from "../tile/tile.module";
import { BillDetailComponent } from "./bill-detail.component";
import { BillDetailListViewMoreComponent } from "./billdetail-list/billdetails-list-view-more.component";
import { BillDetailsListComponent } from "./billdetail-list/billdetails-list.component";
import { BillDetailContainerComponent } from "./details/bill-detail-container.component";
import { BillDetailsComponent } from "./details/bill-details.component";
import { ServiceIssueListComponent } from "./serviceissue-list/serviceissue-list.component";

@NgModule({
   imports: [
      CommonModule,
      TileModule,
      LayoutModule,
      TableModule,
      LcsProgressButtonModule,
      LoadingOverlayModule,
      LcsSlidePanelModule,
      //  ServiceIssueListModule,
   ],
   declarations: [
      ServiceIssueListComponent,
      BillDetailComponent,
      BillDetailContainerComponent,
      BillDetailsComponent,
      BillDetailsListComponent,
      BillDetailListViewMoreComponent,
   ],
})
export class BillDetailModule {}
