<owa-dashboard-tile
   [title]="'Bills'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'bills'"
>
   <lcs-table [dataSource]="$any(observableBills | async)">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="billTileColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: billTileColumns"
      ></tr>
      <ng-container
         lcsColumnDef="TransactionDate"
         [width]="8"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Bill Date </th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/bills', row.ID]"
            >{{
               row.TransactionDate | date: "MM/dd/yyyy"
               }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Payee"
         [width]="15"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Payee</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/bills', row.ID]"
            >{{ row.Payee }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Amount"
         [width]="10"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Amount</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="numeric has-drilldown"
         >
            <a
               class="drilldown-link"
               [routerLink]="['/bills', row.ID]"
            >{{
               row.Amount | currency: "USD":"symbol":"1.2-2"
               }}</a>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="NewRow"
         [width]="1"
         [priority]="4"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         ></th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div *ngIf="row.IsNew">
               <span class="notification-icon"></span>
            </div>
         </td>
      </ng-container>
   </lcs-table>

   <ng-template #noBills>
      <div class="no-result">No Bills found</div>
   </ng-template>
</owa-dashboard-tile>