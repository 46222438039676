import { Injectable } from "@angular/core";

import { IconDefinitionModel } from "./icon-definition.model";

@Injectable({
   providedIn: "root",
})
export class IconService {
   constructor() {}

   buildIconDefinition(iconName: string): IconDefinitionModel {
      return IconService.buildDefaultIconDefinition(iconName);
   }

   static buildDefaultIconDefinition(iconName: string): IconDefinitionModel {
      const result = new IconDefinitionModel();
      result.iconClass = "material-icons";
      result.iconInnerHtml = iconName;

      return result;
   }
}
