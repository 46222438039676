<ng-container [ngSwitch]="parameter?.ReportParameterComponent">
   <!-- Single Report Parameters -->
   <ng-container *ngIf="!parameter?.ReportReportParameter?.IsHidden">
      <lcs-checkbox-report-parameter
         *ngSwitchCase="reportParameterComponents.Checkbox"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-checkbox-report-parameter>
      <lcs-prospect-stage-id-report-parameter
         *ngSwitchCase="reportParameterComponents.ProspectStageID"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-prospect-stage-id-report-parameter>
      <lcs-dynamic-selector-report-parameter
         *ngSwitchCase="reportParameterComponents.Selector"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-dynamic-selector-report-parameter>
      <lcs-date-report-parameter
         *ngSwitchCase="reportParameterComponents.Date"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [reportReportParameterComponents]="parameterComponents"
         [dateReportParameterType]="parameter.ReportParameterComponent"
         [isRelative]="isRelative"
         [hasAsk]="hasAsk"
      ></lcs-date-report-parameter>
      <lcs-date-report-parameter
         *ngSwitchCase="reportParameterComponents.Date2"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [reportReportParameterComponents]="parameterComponents"
         [dateReportParameterType]="parameter.ReportParameterComponent"
         [isRelative]="isRelative"
         [hasAsk]="hasAsk"
      ></lcs-date-report-parameter>
      <lcs-checked-date-range-report-parameter
         *ngSwitchCase="reportParameterComponents.CheckedDateRange"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [isRelative]="isRelative"
         [hasAsk]="hasAsk"
      ></lcs-checked-date-range-report-parameter>
      <lcs-textbox-report-parameter
         *ngSwitchCase="reportParameterComponents.Textbox"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-textbox-report-parameter>
      <lcs-textarea-report-parameter
         *ngSwitchCase="reportParameterComponents.Textarea"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-textarea-report-parameter>
      <lcs-toggleable-input-reportparameter
         *ngSwitchCase="reportParameterComponents.ToggleableInput"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-toggleable-input-reportparameter>
      <lcs-charge-type-order-report-parameter
         *ngSwitchCase="reportParameterComponents.ChargeTypeOrder"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-charge-type-order-report-parameter>
      <lcs-crp-year-report-parameter
         *ngSwitchCase="reportParameterComponents.CRPYear"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-crp-year-report-parameter>
      <lcs-address-type-report-parameter
         *ngSwitchCase="reportParameterComponents.AddressType"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-address-type-report-parameter>
      <lcs-credits-to-include-report-parameter
         *ngSwitchCase="reportParameterComponents.CreditsToInclude"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-credits-to-include-report-parameter>
      <lcs-sort-options-report-parameter
         *ngSwitchCase="reportParameterComponents.SortOptions"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-sort-options-report-parameter>
      <lcs-year-1099-report-parameter
         *ngSwitchCase="reportParameterComponents.Year1099"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
         [report]="report"
      ></lcs-year-1099-report-parameter>
      <lcs-include-expected-move-dates-report-parameter
         *ngSwitchCase="reportParameterComponents.IncludeExpectedMoveDatesReportParameter"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-include-expected-move-dates-report-parameter>
      <owa-reconciliations-report-parameter
         *ngSwitchCase="reportParameterComponents.Reconciliations"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></owa-reconciliations-report-parameter>
      <lcs-cam-escrow-report-parameter
         *ngSwitchCase="reportParameterComponents.CAMEscrow"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-cam-escrow-report-parameter>
      <lcs-bank-account-report-parameter
         *ngSwitchCase="reportParameterComponents.BankAccountSelector"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-bank-account-report-parameter>
      <lcs-budget-years-report-parameter
         *ngSwitchCase="reportParameterComponents.BudgetYears"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      >
      </lcs-budget-years-report-parameter>
      <lcs-cam-charge-type-report-parameter
         *ngSwitchCase="reportParameterComponents.CAMChargeTypes"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-cam-charge-type-report-parameter>
      <lcs-cam-escrow-report-parameter
         *ngSwitchCase="reportParameterComponents.CAMEscrow"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-cam-escrow-report-parameter>
      <lcs-percent-report-parameter
         *ngSwitchCase="reportParameterComponents.PercentReportParameter"
         [name]="controlName"
         [displayName]="displayName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-percent-report-parameter>
      <owa-property-unit-multi-selector-report-parameter
         *ngSwitchCase="reportParameterComponents.PropertyUnitMultiSelector"
         [name]="controlName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      >
      </owa-property-unit-multi-selector-report-parameter>
      <lcs-bank-cc-accounts-report-parameter
         *ngSwitchCase="reportParameterComponents.BankCCAccountsParameter"
         [name]="controlName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      >
      </lcs-bank-cc-accounts-report-parameter>
      <lcs-numeric-input-report-parameter
         *ngSwitchCase="reportParameterComponents.NumericInput"
         [name]="controlName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-numeric-input-report-parameter>
      <lcs-associated-1099-categories-report-parameter
         *ngSwitchCase="reportParameterComponents.Associated1099Categories"
         [name]="controlName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-associated-1099-categories-report-parameter>
      <lcs-cleared-options
         *ngSwitchCase="reportParameterComponents.ClearedOptions"
         [name]="controlName"
         [parameter]="parameter.ReportReportParameter"
         [hasAsk]="hasAsk"
      ></lcs-cleared-options>
   </ng-container>
   <!-- Grouped Report Parameters -->
   <lcs-checkbox-group-report-parameter
      *ngSwitchCase="reportParameterComponents.CheckboxGroup"
      [displayName]="displayName"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-checkbox-group-report-parameter>
   <lcs-date-range-report-parameter
      *ngSwitchCase="reportParameterComponents.DateRange"
      [name]="controlName"
      [displayName]="displayName"
      [dateRangeReportParameterType]="parameter.ReportParameterComponent"
      [isRelative]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
      label="Date Range"
   ></lcs-date-range-report-parameter>
   <lcs-date-range-report-parameter
      *ngSwitchCase="reportParameterComponents.DateRange2"
      [name]="controlName"
      [displayName]="displayName"
      [dateRangeReportParameterType]="parameter.ReportParameterComponent"
      [isRelative]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-date-range-report-parameter>
   <lcs-date-range-and-accounting-period-report-parameter
      *ngSwitchCase="reportParameterComponents.DateRangeWithAccountingPeriod"
      [name]="controlName"
      [dateRangeReportParameterType]="parameter.ReportParameterComponent"
      [isRelative]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-date-range-and-accounting-period-report-parameter>
   <lcs-date-range-and-accounting-period-report-parameter
      *ngSwitchCase="reportParameterComponents.DateRangeWithAccountingPeriod2"
      [name]="controlName"
      [dateRangeReportParameterType]="parameter.ReportParameterComponent"
      [isRelative]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-date-range-and-accounting-period-report-parameter>
   <lcs-date-and-accounting-period-report-parameter
      *ngSwitchCase="reportParameterComponents.AsOfDateWithAccountingPeriod"
      [name]="controlName"
      [displayName]="displayName"
      [dateReportParameterType]="parameter.ReportParameterComponent"
      [showRelativeOption]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-date-and-accounting-period-report-parameter>
   <lcs-date-and-accounting-period-report-parameter
      *ngSwitchCase="reportParameterComponents.AsOfDateWithAccountingPeriod2"
      [name]="controlName"
      [displayName]="displayName"
      [dateReportParameterType]="parameter.ReportParameterComponent"
      [showRelativeOption]="isRelative"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-date-and-accounting-period-report-parameter>
   <lcs-period-range-report-parameter
      *ngSwitchCase="reportParameterComponents.PeriodRange"
      [name]="controlName"
      [dateRangeReportParameterType]="parameter.ReportParameterComponent"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-period-range-report-parameter>
   <lcs-chart-accounts-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.ChartAccountsToInclude"
      [name]="controlName"
      [displayName]="displayName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-chart-accounts-to-include-report-parameter>
   <lcs-accounts-wrapper-report-parameter
      *ngSwitchCase="reportParameterComponents.AccountsWrapper"
      [name]="controlName"
      [displayName]="displayName"
      [parameter]="parameter"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-accounts-wrapper-report-parameter>
   <lcs-cam-division-report-parameter
      *ngSwitchCase="reportParameterComponents.CAMDivExpMethod"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-cam-division-report-parameter>
   <lcs-balance-due-values-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.BalanceDueValuesToInclude"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-balance-due-values-to-include-report-parameter>
   <lcs-consolidate-address-report-parameter
      *ngSwitchCase="reportParameterComponents.ConsolidateAddressVendor1096"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-consolidate-address-report-parameter>
   <lcs-consolidate-address-report-parameter
      *ngSwitchCase="reportParameterComponents.ConsolidateAddressVendor1099"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-consolidate-address-report-parameter>
   <lcs-consolidate-address-report-parameter
      *ngSwitchCase="reportParameterComponents.ConsolidateAddressLoan"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-consolidate-address-report-parameter>
   <lcs-label-selection-report-parameter
      *ngSwitchCase="reportParameterComponents.LabelSelection"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-label-selection-report-parameter>
   <lcs-year-range-report-parameter
      *ngSwitchCase="reportParameterComponents.YearRange"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [isRelative]="isRelative"
      [hasAsk]="hasAsk"
   ></lcs-year-range-report-parameter>
   <lcs-properties-and-owners-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.PropertiesAndOwnersToInclude"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
      [report]="report"
   ></lcs-properties-and-owners-to-include-report-parameter>
   <lcs-owners-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.OwnersToInclude"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
      [report]="report"
   ></lcs-owners-to-include-report-parameter>
   <lcs-select-unit-report-parameter
      *ngSwitchCase="reportParameterComponents.SelectUnit"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-select-unit-report-parameter>
   <lcs-users-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.UsersToInclude"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-users-to-include-report-parameter>
   <lcs-vendor-report-parameter
      *ngSwitchCase="reportParameterComponents.VendorReportParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-vendor-report-parameter>
   <lcs-lead-sources-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.LeadSourcesToIncludeReportParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-lead-sources-to-include-report-parameter>

   <lcs-job-report-parameter
      *ngSwitchCase="reportParameterComponents.JobReportParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-job-report-parameter>
   <lcs-violation-notes-report-parameter
      *ngSwitchCase="reportParameterComponents.ViolationNotes"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-violation-notes-report-parameter>
   <owa-payable-accounts-report-parameter
      *ngSwitchCase="reportParameterComponents.PayableAccounts"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></owa-payable-accounts-report-parameter>
   <lcs-date-range-with-modes-report-parameter
      *ngSwitchCase="reportParameterComponents.DateRangeWithModes"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-date-range-with-modes-report-parameter>

   <lcs-select-lease-report-parameter
      *ngSwitchCase="reportParameterComponents.SelectLease"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-select-lease-report-parameter>
   <lcs-report-period-type-report-parameter
      *ngSwitchCase="reportParameterComponents.ReportPeriodType"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-report-period-type-report-parameter>
   <lcs-states-report-parameter
      *ngSwitchCase="reportParameterComponents.States"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-states-report-parameter>
   <lcs-market-rent-report-parameter
      *ngSwitchCase="reportParameterComponents.MarketRent"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-market-rent-report-parameter>
   <owa-transactions-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.TransactionsToInclude"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </owa-transactions-to-include-report-parameter>
   <lcs-values-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.ValuesToIncludeParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-values-to-include-report-parameter>
   <lcs-birthdays-to-include-report-parameter
      *ngSwitchCase="reportParameterComponents.BirthdaysToIncludeParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-birthdays-to-include-report-parameter>
   <lcs-show-on-report-report-parameter
      *ngSwitchCase="reportParameterComponents.ShowOnReportParameter"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   >
   </lcs-show-on-report-report-parameter>
   <lcs-vendor-name-udf-name-report-parameter
      *ngSwitchCase="reportParameterComponents.VendorNameAndUDFName"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-vendor-name-udf-name-report-parameter>
   <lcs-amount-selection-psf-report-parameter
      *ngSwitchCase="reportParameterComponents.AmountSelectionPSF"
      [name]="controlName"
      [reportReportParameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
   ></lcs-amount-selection-psf-report-parameter>
</ng-container>