import { NgModule } from "@angular/core";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import { CommonModule } from "@angular/common";

import { SliderComponent } from "./slider.component";
import { FormsModule } from "@angular/forms";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      MatSliderModule,
   ],
   declarations: [
      SliderComponent,
   ],
   exports: [
      SliderComponent,
   ],
})
export class SliderModule { }
