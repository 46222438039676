import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConstantsService } from "@lcs/core/constants.service";
import { DataTableFilterModel } from "@lcs/datatable/datatable-filters/datatable-filter.model";
import { DataTableFiltersService } from "@lcs/datatable/datatable-filters/datatable-filters.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { TableComponent } from "@lcs/table/table.component";
import { EstimateStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eestimatestatus.enum";
import { ExpressDataTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-data-types.enum";
import { ExpressLayoutControlTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-layout-control-types.enum";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { HttpStatusCode } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/http-status-codes.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";

import { OWASessionService } from "../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";

@Component({
   selector: "owa-estimate-list",
   templateUrl: "./estimate-list.component.html",
   styleUrls: ["./estimate-list.component.css"],
   providers: [DataTableFiltersService],
})
export class EstimatesListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent, { static: true }) table: TableComponent<any>;

   @Input() EnableFilters: boolean = true;
   @Input() DefaultActiveFilter: boolean = true;
   @Input() RegisterHeight: string = "";

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public estimates = new BehaviorSubject<Array<EstimateViewModel>>(null);
   public unsubscribe: Subject<void> = new Subject<void>();
   public initialLoadComplete: boolean = false;
   public pageSize: number = 10;
   public rowHeight: number = 35;
   public pageNumber: number = 0;
   public threshold: string = "30%";

   public columns: string[] = ["Estimate #", "Property", "Reference", "Date", "Expiration", "Status", "Amount"];

   public showFilters: boolean;
   public appliedSideBarFilters: FilterOption[];
   public estimateFilters: DataTableFilterModel[];
   public filterOperations: FilterOperationModel[];
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public results = new BehaviorSubject<number>(null);
   public showOpen: boolean = false;
   public showClosed: boolean = false;

   public isLoading = new Observable<boolean>();
   public isDataLoading: BehaviorSubject<boolean>;
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public totalResults = new BehaviorSubject<number>(null);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   public totalOpenResults = new BehaviorSubject<number>(null);
   private _isLoading = new BehaviorSubject<boolean>(false);
   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   private _isInfiniteScrollLoading = new BehaviorSubject<boolean>(null);
   private scrollPageSize: number = 10;

   constructor(
      private estimateService: EstimateService,
      private errorMessageService: ErrorMessageService,
      private owaSessionService: OWASessionService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      this.isLoading = this._isLoading.asObservable();
      this.isDataLoading = new BehaviorSubject<boolean>(false);
      this.totalResults = new BehaviorSubject<number>(0);
      this.totalOpenResults = new BehaviorSubject<number>(0);
      this.results = new BehaviorSubject<number>(0);
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.estimateFilters = this.getFilters();
      this.setDefaultFilters();
   }

   setDefaultFilters() {}

   ngOnInit() {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   getData(filters?: FilterOption[], isInfiniteScroll: boolean = false, refreshData = true) {
      this._isLoading.next(true);
      if (isInfiniteScroll) {
         this._isInfiniteScrollLoading.next(true);
      } else {
         this.isDataLoading.next(true);
      }

      if (refreshData) {
         this.isDataLoading.next(true);
      }

      if (!filters) {
         filters = new Array<FilterOption>();
      }

      filters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [this.DefaultActiveFilter]));

      const request = this.estimateService.getCollectionResponse(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         filters,
         null,
         null,
         null,
         null,
         this.scrollPageSize,
         this.pageNumber,
         null
      );
      request
         .pipe(
            map((response) => {
               if (response.status === HttpStatusCode.NoContent) {
                  return [];
               }
               this.totalResults.next(Number(response.headers.get(ApiRequestHelperService.totalResultsHeaderName)));
               const estimateList = response.body;
               estimateList.forEach((element) => {
                  if (element.IsExpired) {
                     element.StatusName = "Expired";
                  } else {
                     element.StatusName = EstimateStatus[element.Status];
                  }
               });

               return estimateList;
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe(
            (results) => {
               let newEstimatesData = this.estimates && this.estimates.value ? this.estimates.value : [];
               if (this._isInfiniteScrollLoading.value) {
                  newEstimatesData = newEstimatesData.concat(results);
               } else {
                  newEstimatesData = results;
               }
               this.estimates.next(newEstimatesData);
               this.results.next(this.estimates.value.length);
               this._isLoading.next(false);
               this.isDataLoading.next(false);
               if (this._isInfiniteScrollLoading && this._isInfiniteScrollLoading.value) {
                  this._isInfiniteScrollLoading.next(false);
               }
            },
            (error) => {
               this.errorMessageService.triggerHttpErrorMessage(error);
            }
         );
      this.removeAppliedSideBarFilter();
   }

   onInfiniteScroll($event): void {
      let refreshData = false;
      if (!this.initialLoadComplete) {
         this.scrollPageSize = Math.ceil($event.clientHeight / this.rowHeight);
         refreshData = true;
         this.initialLoadComplete = true;
      } else {
         refreshData = false;
      }
      const data = this.estimates.value ? this.estimates.value : [];
      if (!this._isLoading.value && (this.totalResults.value === 0 || data.length < this.totalResults.value)) {
         this.pageNumber++;
         this.getData(this.appliedSideBarFilters, true, refreshData);
      }
   }

   resetPaging() {
      this.pageNumber = 1;
      this.results.next(0);
      this.totalResults.next(0);
   }

   getValidFilterOperations(): Array<FilterOperationModel> {
      const eq = new FilterOperationModel();
      eq.BackingEnumeration = FilterOperations.EqualTo;
      eq.Description = "Equal To";
      eq.Name = "Equal To";
      eq.OpCode = "eq";

      const bt = new FilterOperationModel();
      bt.BackingEnumeration = FilterOperations.Between;
      bt.Description = "Between";
      bt.Name = "Between";
      bt.OpCode = "bt";

      const filterOps = [eq, bt];
      return filterOps;
   }

   toggleFiltersPanel(): void {
      this.showFilters = !this.showFilters;
   }

   onClearFilters(): void {
      this.estimateFilters = this.getFilters();
      this.filterOperations = this.getValidFilterOperations();
      this.appliedSideBarFilters = new Array<FilterOption>();
      this.resetPaging();
      this.getData();
   }

   onFiltersApplied(filters: FilterExpression): void {
      this.appliedSideBarFilters = filters.FilterOptions;
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   getFilters(): Array<DataTableFilterModel> {
      const filterList: any[] = Array<DataTableFilterModel>();

      const estimateFilter = this.getDataTableFilterModel(
         "IssueDate",
         "Estimate Date",
         ExpressDataTypes.Date,
         ExpressLayoutControlTypes.DatePicker,
         [FilterOperations.Between, FilterOperations.EqualTo],
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
         null,
         FilterOperations.Between
      );

      filterList.push(estimateFilter);

      const propertyFiltervalueSourceModel = new ValueSourceModel();
      propertyFiltervalueSourceModel.Type = ValueSourceTypes.Static;
      propertyFiltervalueSourceModel.StaticValues = new Array<SelectorItemModel>();
      propertyFiltervalueSourceModel.StaticValues.push(
         new SelectorItemModel(ConstantsService.unselected, "All Properties")
      );
      this.owaSessionService.OWASessionInfo.value.CurrentAccount.Ownerships.forEach((os) => {
         propertyFiltervalueSourceModel.StaticValues.push(new SelectorItemModel(os.PropertyID, os.Property.Name));
      });

      const propertyFilter = this.getDataTableFilterModel(
         "EstimateDetails.PropertyID",
         "Properties",
         ExpressDataTypes.Numeric,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         propertyFiltervalueSourceModel
      );
      filterList.push(propertyFilter);

      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs) {
               this.showOpen =
                  prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA") &&
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  prefs.find((a) => a.Name === "IncludeOpenEstimatesOWA").Value.toLocaleLowerCase() === "true"
                     ? true
                     : false;
               this.showClosed =
                  prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA") &&
                  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  prefs.find((a) => a.Name === "IncludeClosedEstimatesOWA").Value.toLocaleLowerCase() === "true"
                     ? true
                     : false;
            }
         }
      );

      const estimateStatusSourceModel = new ValueSourceModel();
      estimateStatusSourceModel.Type = ValueSourceTypes.Static;
      estimateStatusSourceModel.StaticValues = new Array<SelectorItemModel>();
      estimateStatusSourceModel.StaticValues.push(new SelectorItemModel(ConstantsService.unselected, "All Statuses"));
      estimateStatusSourceModel.StaticValues.push(
         new SelectorItemModel(EstimateStatus["Awaiting Approval"], "Awaiting Approval")
      );
      estimateStatusSourceModel.StaticValues.push(new SelectorItemModel(EstimateStatus.Approved, "Approved"));
      estimateStatusSourceModel.StaticValues.push(new SelectorItemModel(EstimateStatus.Rejected, "Rejected"));

      const statusFilter = this.getDataTableFilterModel(
         "Status",
         "Status",
         ExpressDataTypes.String,
         ExpressLayoutControlTypes.SingleSelector,
         [FilterOperations.EqualTo],
         estimateStatusSourceModel
      );
      filterList.push(statusFilter);

      return filterList;
   }

   onFilterRemoved(option: FilterOption): void {
      const index: number = this.appliedSideBarFilters.indexOf(option);
      this.appliedSideBarFilters.splice(index, 1);
      this.estimateFilters = this.resetFilter(this.estimateFilters, option);
      this.resetPaging();
      this.getData(this.appliedSideBarFilters);
   }

   removeAppliedSideBarFilter(): void {
      this.appliedSideBarFilters.pop();
   }

   getDataTableFilterModel(
      filterField: string,
      labelText: string,
      dataType: ExpressDataTypes,
      controlType: ExpressLayoutControlTypes,
      filterOpertations: Array<FilterOperations>,
      valueSource?: ValueSourceModel,
      defaultFilterOperation?: FilterOperations
   ): DataTableFilterModel {
      const filter = new DataTableFilterModel();
      filter.ControlType = controlType;
      filter.DataType = dataType;
      filter.FilterName = filterField;
      filter.FilterName = filterField;
      filter.Operations = filterOpertations;
      filter.PropertyName = filterField;
      if (defaultFilterOperation) {
         filter.FilterOption = new FilterOption(filterField, defaultFilterOperation, null, labelText);
      } else {
         filter.FilterOption = new FilterOption(filterField, FilterOperations.EqualTo, null, labelText);
      }
      filter.Label = labelText;
      if (valueSource) {
         filter.ValueSource = valueSource;
      }

      return filter;
   }

   private resetFilter(allFilters: DataTableFilterModel[], filterToReset: FilterOption, defaultValue: any = null) {
      return allFilters.map((filter: DataTableFilterModel) => {
         if (filter.FilterName === filterToReset.FilterName) {
            let newValue = defaultValue;
            if (filter && filter.ValueSource && filter.ValueSource.DefaultValue !== undefined) {
               newValue = filter.ValueSource.DefaultValue;
            }
            return {
               ...filter,
               FilterOption:
                  filter.DataType === ExpressDataTypes.Date
                     ? new FilterOption(filter.FilterName, FilterOperations.Between, null, filterToReset.Label)
                     : new FilterOption(filter.FilterName, FilterOperations.EqualTo, newValue, filterToReset.Label),
            };
         }
         return filter;
      });
   }
}
