<div class="modal-header-border"></div>
<form
   class="user-profile-form"
   #form="ngForm"
   (ngSubmit)="saveUserProfile(form)"
>
   <lcs-loading-overlay>
      <div class="flex user-profile-modal">
         <div class="modal-message field-controls">
            <label>
               Providing the following information will help with account recovery and verification. The
               information submitted is associated with your user profile.
            </label>
         </div>
         <div
            *ngIf="errorMessage"
            class="field-controls"
         >
            <label class="error-message">{{errorMessage}}</label>
         </div>
         <div class="flex-full controls field-controls">
            <label>First Name *</label>
            <lcs-text-box
               [(ngModel)]="webUser.FirstName"
               name="firstName"
               displayName="First Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="flex-full controls field-controls">
            <label>Last Name *</label>
            <lcs-text-box
               [(ngModel)]="webUser.LastName"
               name="lastName"
               displayName="Last Name"
               [required]="true"
            ></lcs-text-box>
         </div>
         <div class="flex-full controls field-controls">
            <label>Phone Number</label>
            <owa-phone-number
               [(ngModel)]="phoneNumber"
               name="PhoneNumber"
               displayName="Phone Number"
            ></owa-phone-number>
         </div>
      </div>
   </lcs-loading-overlay>
   <lcs-progress-button
      [(status)]="continueButtonStatus"
      [raised]="true"
      class="continue-button"
   >Submit
   </lcs-progress-button>
   <a
      class="logout-link"
      (click)="logOut()"
   >Logout</a>
</form>