import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { EntityTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/entity-type.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { Observable } from "rxjs";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class ExpressUserDefinedFieldService {
   static readonly GetFieldsToLinkResource = "GetFieldsToLink";
   static readonly GetLicensedUDFTypesResource = "GetLicensedUDFTypes";
   static readonly GetReadOnlyUserDefinedFieldIDsResource = "GetReadOnlyUserDefinedFieldIDs";
   static readonly endpoint = "ExpressUserDefinedField";
   constructor(private apiService: ApiService) {}
   getGetFieldsToLinkCollectionUrl(entityType: EntityType, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("entityType=" + entityType);
      return this.apiService.getUrl(
         ExpressUserDefinedFieldService.endpoint + "/GetFieldsToLink",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetFieldsToLinkCollection(
      entityType: EntityType,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(this.getGetFieldsToLinkCollectionUrl(entityType, additionalParameters));
   }

   getGetLicensedUDFTypesCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         ExpressUserDefinedFieldService.endpoint + "/GetLicensedUDFTypes",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetLicensedUDFTypesCollection(additionalParameters?: Array<string>): Observable<Array<EntityTypeModel>> {
      return this.apiService.getCollection(this.getGetLicensedUDFTypesCollectionUrl(additionalParameters));
   }

   getGetReadOnlyUserDefinedFieldIDsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         ExpressUserDefinedFieldService.endpoint + "/GetReadOnlyUserDefinedFieldIDs",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetReadOnlyUserDefinedFieldIDsCollection(additionalParameters?: Array<string>): Observable<Array<number>> {
      return this.apiService.getCollection(this.getGetReadOnlyUserDefinedFieldIDsCollectionUrl(additionalParameters));
   }
}
