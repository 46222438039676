<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && customerReportParameter.IsAsk === true)"
   >
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="customerReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <label [class.disabled]="disabled || (hasAsk === true && customerReportParameter.IsAsk === true)">{{
      tenantReplacementLabel }}</label>
   <lcs-select
      lcsStaticSetSelector
      [name]="'customer-selector'"
      [displayName]="tenantReplacementLabel"
      [(ngModel)]="customerValueModel.value"
      [lcsValidate]="validation"
      [items]="customers"
      [disabled]="disabled || (hasAsk === true && customerReportParameter.IsAsk === true)"
      (ngModelChange)="onCustomerChange($event)"
   >
   </lcs-select>
</div>
<label [class.disabled]="disabled || (hasAsk === true && leaseReportParameter.IsAsk === true)">Lease</label>
<lcs-select
   lcsStaticSetSelector
   [name]="'lease-selector'"
   [displayName]="'Lease'"
   [(ngModel)]="leaseValueModel.value"
   [lcsValidate]="validation"
   [items]="leases"
   [disabled]="disabled || (hasAsk === true && leaseReportParameter.IsAsk === true)"
   (ngModelChange)="onLeaseChange($event)"
>
</lcs-select>