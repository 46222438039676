import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DragScrollComponent } from "projects/owa/src/app/file-carousel/drag-scroll/drag-scroll.component";

import { FileCarouselConfigurationModel } from "./models/file-carousel-configuration.model";
import { FileCarouselModel } from "./models/file-carousel.model";

@Component({
   selector: "owa-file-carousel",
   templateUrl: "./file-carousel.component.html",
   styleUrls: ["./file-carousel.component.css"],
})
export class FileCarouselComponent implements OnInit {
   @ViewChild("imageCarousel", { static: true }) carouselNavControl: DragScrollComponent;

   public scrollLeftDisabled: boolean = true;

   public scrollRightDisabled: boolean = false;

   public isAllImages: boolean = false;

   public currentImage: string;

   @Input() configuration: FileCarouselConfigurationModel;

   imageCarouselModels: Array<FileCarouselModel>;

   constructor() {}

   ngOnInit(): void {
      if (this.configuration) {
         this.imageCarouselModels = this.configuration.FileSource;
         this.isAllImages = this.imageCarouselModels.every(function (element, index, array) {
            return element.isImage;
         });
         if (this.imageCarouselModels.length > 0) {
            this.currentImage = this.imageCarouselModels[0].source;
            this.imageCarouselModels[0].isCurrentFile = true;
         }
      }
   }

   imageClicked(image: FileCarouselModel) {
      this.currentImage = image.source;
      this.imageCarouselModels.forEach(function (value, index, array) {
         value.isCurrentFile = false;
      });
      image.isCurrentFile = true;
   }

   imageLoad(image: FileCarouselModel) {
      image.isLoading = false;
      this.carouselNavControl.checkNavStatus();
   }

   imageError(image: FileCarouselModel) {
      image.isLoading = false;
      image.isInFailedToLoadState = true;
      this.carouselNavControl.checkNavStatus();
   }

   moveLeft() {
      for (const i in this.imageCarouselModels) {
         if (this.imageCarouselModels[+i].isCurrentFile) {
            if (this.imageCarouselModels[+i - 1]) {
               this.imageClicked(this.imageCarouselModels[+i - 1]);
            }
            break;
         }
      }
      if (!this.scrollLeftDisabled) {
         this.carouselNavControl.moveLeft();
      }
   }

   moveRight() {
      for (const i in this.imageCarouselModels) {
         if (this.imageCarouselModels[+i].isCurrentFile) {
            if (this.imageCarouselModels[+i + 1]) {
               this.imageClicked(this.imageCarouselModels[+i + 1]);
            }
            break;
         }
      }
      if (!this.scrollRightDisabled) {
         this.carouselNavControl.moveRight();
      }
   }
}
