import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class OutboundCallRequestModel {
   EntityKeyID: number;
   EntityType: EntityType;
   PhoneNumber: string;
}
