import { Injectable, isDevMode } from "@angular/core";
import isString from "lodash/isString";
import { Observable, Subject } from "rxjs";

import { ErrorMessageModel } from "./error-message.model";

@Injectable({
   providedIn: "root",
})
export class ErrorMessageService {
   errors: Observable<ErrorMessageModel>;

   private _errors = new Subject<ErrorMessageModel>();

   constructor() {
      this.errors = this._errors.asObservable();
   }

   triggerError(error: ErrorMessageModel): void {
      this._errors.next(error);
   }

   triggerErrorMessage(message: string, additionalDetails?: any, dismissible: boolean = true, title?: string): void {
      const errorMessage: ErrorMessageModel = {
         Message: message,
         AdditionalDetails: additionalDetails,
         Dismissible: dismissible,
         Title: title,
      };

      if (isDevMode()) {
         console.error(errorMessage);
      }
      if (!errorMessage.Dismissible) {
      }
      this.triggerError(errorMessage);
   }

   /**
    * Triggers an error message based on the error received from the server.
    *
    * @param error - The error received from the server
    * @param title - Optional custom title to display in the error message
    * @param prefixMessage - Optional message to prepend to the server error message. Note you must include any
    *                        desired spacing or punctuation in the prefix message.
    */
   triggerHttpErrorMessage(error: any, title?: string, prefixMessage?: string): void {
      const errorMessageModel: ErrorMessageModel = this.processHttpError(error, title);
      if (prefixMessage) {
         errorMessageModel.Message = `${prefixMessage}${errorMessageModel.Message}`;
      }
      this.triggerError(errorMessageModel);
   }

   errorRequiresSpecialHandling(err: any): boolean {
      const error = this.parseHttpError(err);

      if (error.Exception === "LcsLicenseException") {
         return true;
      }

      return false;
   }

   processHttpError(err: any, title?: string): ErrorMessageModel {
      if (err.message && err.stack) {
         return {
            AdditionalDetails: err.stack,
            Message: err.message,
            Dismissible: true,
            Title: title,
         };
      }

      const error = this.parseHttpError(err);

      let dismissible = true;

      let message: string;
      switch (err.status) {
         case 400: {
            message = error.Message || error;
            break;
         }
         case 401:
         case 403:
         case 404:
         case 500:
         case 503: {
            if (error.Exception === "LcsLicenseException") {
               dismissible = false;
            } else {
               dismissible = true;
            }
            message = error.DeveloperMessage || error;
            break;
         }
         default: {
            message = "Unspecified Error";
         }
      }

      let additionalDetails: string = "";
      if (err._body) {
         additionalDetails = err._body;
      } else if (error) {
         additionalDetails = JSON.stringify(error);
      }

      return {
         AdditionalDetails: additionalDetails,
         Message: message,
         Dismissible: dismissible,
         Title: title,
      };
   }

   public parseHttpError(err: any) {
      let error: any;

      if (err.error != null) {
         return err.error;
      } else if (isString(err._body)) {
         error = JSON.parse(err._body);
      } else {
         err._body = JSON.stringify(err._body);
         error = err._body || err.statusText;
      }

      return error;
   }

   /**
    * Mutates the error received from the server to prepend an additional message prior
    * to any Developer Message provided from the server to add context.
    *
    * NOTE: This uses DeveloperMessage because that is what is currently used in processHttpError()
    *       DeveloperMessage is used instead of UserMessage because UserMessage is almost always set
    *       to "Unspecified Error" and that is not very helpful if the user calls in to report an issue.
    *
    * NOTE: this method only works if error object contains an error object containing a DeveloperMessage
    *       as is returned from the LCS API
    */
   prependToHttpServerErrorMessage(error: any, message: string) {
      if (error?.error?.DeveloperMessage !== undefined && message) {
         error.error.DeveloperMessage = `${message} ${error.error.DeveloperMessage}`;
      }
   }
}
