import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityEmbedOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/embed-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { BillDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/bill.delete-options";
import { CheckDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/check.delete-options";
import { CreditCardTransactionDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/credit-card-transaction.delete-options";
import { PhoneNumberTypeDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/phone-number-type.delete-options";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { VendorContactDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/vendor-contact.delete-options";
import { VendorCreditDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/vendor-credit.delete-options";
import { AddressTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address-type.embed-options";
import { AddressEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address.embed-options";
import { AppointmentEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/appointment.embed-options";
import { BillEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/bill.embed-options";
import { CheckEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/check.embed-options";
import { ColorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/color.embed-options";
import { ContactEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/contact.embed-options";
import { CreditCardTransactionEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/credit-card-transaction.embed-options";
import { EpayInformationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/epay-information.embed-options";
import { GLAccountEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/glaccount.embed-options";
import { HistoryNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-note.embed-options";
import { HistorySystemNoteEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history-system-note.embed-options";
import { HistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/history.embed-options";
import { PhoneNumberTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number-type.embed-options";
import { PhoneNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number.embed-options";
import { RMVoIPCallHistoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/rmvo-ipcall-history.embed-options";
import { TaskEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task.embed-options";
import { TermEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/term.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { VendorContactEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor-contact.embed-options";
import { VendorCreditEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor-credit.embed-options";
import { VendorPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor-property.embed-options";
import { VendorTransactionEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor-transaction.embed-options";
import { VendorEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor.embed-options";
import { Vendor1099BeginningBalanceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor1099beginning-balance.embed-options";
import { Vendor1099CategoryEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/vendor1099category.embed-options";
import { HistoryGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-get-options/history.get-options";
import { BillSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/bill.save-options";
import { CheckSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/check.save-options";
import { ContactSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/contact.save-options";
import { CreditCardTransactionSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/credit-card-transaction.save-options";
import { RMVoIPCallHistorySaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/rmvo-ipcall-history.save-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { VendorContactSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/vendor-contact.save-options";
import { VendorCreditSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/vendor-credit.save-options";
import { BillPayMethod } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/bill-pay-method.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AddressTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-type.model";
import { AddressModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address.model";
import { AppointmentModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/appointment.model";
import { BatchEmailEntitiesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entities.model";
import { BatchEmailEntitySummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/batch-email-entity-summary.model";
import { BillModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bill.model";
import { CheckModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/check.model";
import { ColorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/color.model";
import { ContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/contact.model";
import { CreditCardTransactionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/credit-card-transaction.model";
import { EpayInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/epay-information.model";
import { ExpenseManagementInvoiceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/expense-management-invoice.model";
import { GLAccountModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/glaccount.model";
import { HistoryNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-note.model";
import { HistorySystemNoteModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history-system-note.model";
import { HistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/history.model";
import { MergeVendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/merge-vendor.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { PropertyListItemModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property-list-item.model";
import { RMVoIPCallHistoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/rmvo-ipcall-history.model";
import { ScriptBuilderModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/script-builder.model";
import { TaskModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task.model";
import { TermModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/term.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { VendorContactModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-contact.model";
import { VendorCreditModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-credit.model";
import { VendorPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-property.model";
import { VendorTransactionGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-transaction-get.model";
import { VendorTransactionListModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-transaction-list.model";
import { VendorTransactionTotalsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-transaction-totals.model";
import { VendorTransactionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor-transaction.model";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";
import { Vendor1099BalanceSummaryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor1099balance-summary.model";
import { Vendor1099BeginningBalanceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor1099beginning-balance.model";
import { Vendor1099CategoryModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor1099category.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class VendorsService {
   static readonly AddressesResource = "Addresses";
   static readonly AddressTypesResource = "AddressTypes";
   static readonly AppointmentsResource = "Appointments";
   static readonly AssociatedPropertiesResource = "AssociatedProperties";
   static readonly BatchEmailVendorsResource = "BatchEmailVendors";
   static readonly BillPayMethodsResource = "BillPayMethods";
   static readonly BillsResource = "Bills";
   static readonly ChecksResource = "Checks";
   static readonly ColorResource = "Color";
   static readonly ContactResource = "Contact";
   static readonly ContactsResource = "Contacts";
   static readonly CreateUserResource = "CreateUser";
   static readonly CreditCardTransactionsResource = "CreditCardTransactions";
   static readonly CreditsResource = "Credits";
   static readonly DefaultAccountResource = "DefaultAccount";
   static readonly DefaultTermResource = "DefaultTerm";
   static readonly EpayInfoResource = "EpayInfo";
   static readonly EpayInformationResource = "EpayInformation";
   static readonly GetByPostResource = "GetByPost";
   static readonly HistoryResource = "History";
   static readonly HistoryNotesResource = "HistoryNotes";
   static readonly HistorySystemNotesResource = "HistorySystemNotes";
   static readonly MergeResource = "Merge";
   static readonly MergeVendorsResource = "MergeVendors";
   static readonly PayLeaseExpenseManagementBillsPostingBatchResource = "PayLeaseExpenseManagementBillsPostingBatch";
   static readonly PaymentMethodsResource = "PaymentMethods";
   static readonly PhoneNumbersResource = "PhoneNumbers";
   static readonly PhoneNumberTypesResource = "PhoneNumberTypes";
   static readonly PrimaryAddressResource = "PrimaryAddress";
   static readonly PropertiesResource = "Properties";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly RMVoIPCallHistoryResource = "RMVoIPCallHistory";
   static readonly SearchResource = "Search";
   static readonly TasksResource = "Tasks";
   static readonly TestScriptResource = "TestScript";
   static readonly TransactionsResource = "Transactions";
   static readonly UpdateGlAccountIDOnCreditResource = "UpdateGlAccountIDOnCredit";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly Vendor1099BeginningBalancesResource = "Vendor1099BeginningBalances";
   static readonly Vendor1099CategoryResource = "Vendor1099Category";
   static readonly Vendor1099YTDBalanceResource = "Vendor1099YTDBalance";
   static readonly VendorPropertiesResource = "VendorProperties";
   static readonly VendorTransactionsSummaryResource = "VendorTransactionsSummary";
   static readonly endpoint = "VendorsView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(VendorsService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   post(
      item?: VendorModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(VendorsService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   postCollection(
      items: Array<VendorModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(VendorsService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(VendorsService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAddressesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Addresses",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddresses(
      id: number,
      itemID: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(
         this.getAddressesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAddressesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Addresses",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAddressesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Addresses",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.getCollection(
         this.getAllAddressesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddresses(
      id: number,
      item?: AddressModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.postSingle(
         this.postAddressesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postAddressesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Addresses",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAddressesCollection(
      id: number,
      addresses?: Array<AddressModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | AddressEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressModel>> {
      return this.apiService.postCollection(
         this.postAddressesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         addresses
      );
   }

   deleteAddressesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Addresses",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteAddresses(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getAddressTypesUrl(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAddressTypes(
      itemID: number,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.getSingle(this.getAddressTypesUrl(itemID, embeds, additionalParameters));
   }

   getAddressTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/AddressTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAddressTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllAddressTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllAddressTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.getCollection(
         this.getAllAddressTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddressTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypes(
      item?: AddressTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressTypeModel> {
      return this.apiService.postSingle(this.postAddressTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   postAllAddressTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllAddressTypesCollection(
      addressTypes?: Array<AddressTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressTypeModel>> {
      return this.apiService.postCollection(
         this.postAllAddressTypesCollectionUrl(saveOptions, fields, additionalParameters),
         addressTypes
      );
   }

   deleteAddressTypesUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/AddressTypes",
         null,
         ids,
         null,
         additionalParameters
      );
   }

   deleteAddressTypes(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAddressTypesUrl(ids, additionalParameters));
   }

   getAppointmentsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Appointments",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAppointments(
      id: number,
      itemID: number,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.getSingle(this.getAppointmentsUrl(id, itemID, embeds, additionalParameters));
   }

   getAppointmentsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Appointments",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAppointmentsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AppointmentEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.getCollection(
         this.getAppointmentsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAppointmentsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Appointments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAppointments(
      id: number,
      item?: AppointmentModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<AppointmentModel> {
      return this.apiService.postSingle(this.postAppointmentsUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postAppointmentsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Appointments",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAppointmentsCollection(
      id: number,
      appointmentItems?: Array<AppointmentModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AppointmentModel>> {
      return this.apiService.postCollection(
         this.postAppointmentsCollectionUrl(id, saveOptions, fields, additionalParameters),
         appointmentItems
      );
   }

   deleteAppointmentsUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(VendorsService.endpoint + "/Appointments", id, ids, null, additionalParameters);
   }

   deleteAppointments(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteAppointmentsUrl(id, ids, additionalParameters));
   }

   getAssociatedPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/AssociatedProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getAssociatedProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | EntityEmbedOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyListItemModel> {
      return this.apiService.getSingle(
         this.getAssociatedPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getAssociatedPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/AssociatedProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAssociatedPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyListItemModel>> {
      return this.apiService.getCollection(
         this.getAssociatedPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postBatchEmailVendorsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/BatchEmailVendors",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postBatchEmailVendors(
      model?: BatchEmailEntitiesModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BatchEmailEntitySummaryModel>> {
      return this.apiService
         .post(this.postBatchEmailVendorsUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getBillPayMethodsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/BillPayMethods",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBillPayMethodsCollection(additionalParameters?: Array<string>): Observable<Array<BillPayMethod>> {
      return this.apiService.getCollection(this.getBillPayMethodsCollectionUrl(additionalParameters));
   }

   getBillsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Bills",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBills(
      id: number,
      itemID: number,
      embeds?: Array<string | BillEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<BillModel> {
      return this.apiService.getSingle(this.getBillsUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getBillsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Bills",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBillsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getBillsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllBillsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Bills",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllBillsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.getCollection(
         this.getAllBillsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postBillsUrl(
      id: number,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Bills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBills(
      id: number,
      item?: BillModel | null,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<BillModel> {
      return this.apiService.postSingle(this.postBillsUrl(id, saveOptions, fields, embeds, additionalParameters), item);
   }

   postBillsCollectionUrl(
      id: number,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Bills",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postBillsCollection(
      id: number,
      bills?: Array<BillModel> | null,
      saveOptions?: Array<string | BillSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | BillEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BillModel>> {
      return this.apiService.postCollection(
         this.postBillsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         bills
      );
   }

   deleteBillsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | BillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Bills",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteBills(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | BillDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteBillsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getChecksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Checks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getChecks(
      id: number,
      itemID: number,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.getSingle(this.getChecksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getChecksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Checks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getChecksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.getCollection(
         this.getChecksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChecksUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Checks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChecks(
      id: number,
      item?: CheckModel | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<CheckModel> {
      return this.apiService.postSingle(
         this.postChecksUrl(id, saveOptions, embeds, fields, additionalParameters),
         item
      );
   }

   postChecksCollectionUrl(
      id: number,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Checks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postChecksCollection(
      id: number,
      checks?: Array<CheckModel> | null,
      saveOptions?: Array<string | CheckSaveOptions> | null,
      embeds?: Array<string | CheckEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CheckModel>> {
      return this.apiService.postCollection(
         this.postChecksCollectionUrl(id, saveOptions, embeds, fields, additionalParameters),
         checks
      );
   }

   deleteChecksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Checks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteChecks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CheckDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteChecksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getColorUrl(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Color",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getColor(
      id: number,
      embeds?: Array<string | ColorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ColorModel> {
      return this.apiService.getSingle(this.getColorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getContactUrl(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Contact",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContact(
      id: number,
      embeds?: Array<string | ContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService.getSingle(this.getContactUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postContactUrl(
      id: number,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Contact",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContact(
      id: number,
      item?: ContactModel | null,
      saveOptions?: Array<string | ContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | ContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<ContactModel> {
      return this.apiService
         .post(this.postContactUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteContactUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Contact",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContact(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteContactUrl(id, itemID, deleteOptions, additionalParameters));
   }

   getContactsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Contacts",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getContacts(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorContactModel> {
      return this.apiService.getSingle(
         this.getContactsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getContactsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Contacts",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getContactsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorContactModel>> {
      return this.apiService.getCollection(
         this.getContactsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postContactsUrl(
      id: number,
      saveOptions?: Array<string | VendorContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Contacts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContacts(
      id: number,
      item?: VendorContactModel | null,
      saveOptions?: Array<string | VendorContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorContactModel> {
      return this.apiService.postSingle(
         this.postContactsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postContactsCollectionUrl(
      id: number,
      saveOptions?: Array<string | VendorContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Contacts",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postContactsCollection(
      id: number,
      vendorContacts?: Array<VendorContactModel> | null,
      saveOptions?: Array<string | VendorContactSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorContactEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorContactModel>> {
      return this.apiService.postCollection(
         this.postContactsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         vendorContacts
      );
   }

   deleteContactsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | VendorContactDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Contacts",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteContacts(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | VendorContactDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteContactsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCreditCardTransactionsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditCardTransactionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/CreditCardTransactions",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreditCardTransactions(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditCardTransactionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditCardTransactionModel> {
      return this.apiService.getSingle(
         this.getCreditCardTransactionsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreditCardTransactionsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditCardTransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/CreditCardTransactions",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCreditCardTransactionsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditCardTransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditCardTransactionModel>> {
      return this.apiService.getCollection(
         this.getCreditCardTransactionsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCreditCardTransactionsUrl(
      id: number,
      saveOptions?: Array<string | CreditCardTransactionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/CreditCardTransactions",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreditCardTransactions(
      id: number,
      item?: CreditCardTransactionModel | null,
      saveOptions?: Array<string | CreditCardTransactionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditCardTransactionModel> {
      return this.apiService.postSingle(
         this.postCreditCardTransactionsUrl(id, saveOptions, additionalParameters),
         item
      );
   }

   postCreditCardTransactionsCollectionUrl(
      id: number,
      saveOptions?: Array<string | CreditCardTransactionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/CreditCardTransactions",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreditCardTransactionsCollection(
      id: number,
      transactions?: Array<CreditCardTransactionModel> | null,
      saveOptions?: Array<string | CreditCardTransactionSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditCardTransactionModel>> {
      return this.apiService.postCollection(
         this.postCreditCardTransactionsCollectionUrl(id, saveOptions, additionalParameters),
         transactions
      );
   }

   deleteCreditCardTransactionsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditCardTransactionDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/CreditCardTransactions",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCreditCardTransactions(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | CreditCardTransactionDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteCreditCardTransactionsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getCreditsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorCreditEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Credits",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCredits(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorCreditEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorCreditModel> {
      return this.apiService.getSingle(
         this.getCreditsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreditsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorCreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Credits",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCreditsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorCreditEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorCreditModel>> {
      return this.apiService.getCollection(
         this.getCreditsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postCreditsUrl(
      id: number,
      saveOptions?: Array<string | VendorCreditSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Credits",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCredits(
      id: number,
      item?: VendorCreditModel | null,
      saveOptions?: Array<string | VendorCreditSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorCreditModel> {
      return this.apiService.postSingle(this.postCreditsUrl(id, saveOptions, additionalParameters), item);
   }

   postCreditsCollectionUrl(
      id: number,
      saveOptions?: Array<string | VendorCreditSaveOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Credits",
         id,
         saveOptions,
         null,
         null,
         additionalParameters
      );
   }

   postCreditsCollection(
      id: number,
      credits?: Array<VendorCreditModel> | null,
      saveOptions?: Array<string | VendorCreditSaveOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorCreditModel>> {
      return this.apiService.postCollection(
         this.postCreditsCollectionUrl(id, saveOptions, additionalParameters),
         credits
      );
   }

   deleteCreditsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | VendorCreditDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Credits",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteCredits(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | VendorCreditDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteCreditsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getDefaultAccountUrl(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/DefaultAccount",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultAccount(
      id: number,
      embeds?: Array<string | GLAccountEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<GLAccountModel> {
      return this.apiService.getSingle(this.getDefaultAccountUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getDefaultTermUrl(
      id: number,
      embeds?: Array<string | TermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/DefaultTerm",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getDefaultTerm(
      id: number,
      embeds?: Array<string | TermEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TermModel> {
      return this.apiService.getSingle(this.getDefaultTermUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getEpayInfoUrl(
      id: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/EpayInfo",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpayInfo(
      id: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.getSingle(this.getEpayInfoUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   postEpayInfoUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/EpayInfo",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postEpayInfo(
      id: number,
      item?: EpayInformationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService
         .post(this.postEpayInfoUrl(id, saveOptions, fields, embeds, additionalParameters), item)
         .pipe(map((response) => response.body));
   }

   deleteEpayInfoUrl(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("itemID=" + itemID);
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/EpayInfo",
         id,
         null,
         deleteOptions,
         additionalParameters
      );
   }

   deleteEpayInfo(
      id: number,
      itemID: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteEpayInfoUrl(id, itemID, deleteOptions, additionalParameters));
   }

   getEpayInformationUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/EpayInformation",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEpayInformation(
      id: number,
      itemID: number,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.getSingle(this.getEpayInformationUrl(id, itemID, embeds, additionalParameters));
   }

   getEpayInformationCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/EpayInformation",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEpayInformationCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getEpayInformationCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllEpayInformationCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/EpayInformation",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllEpayInformationCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EpayInformationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.getCollection(
         this.getAllEpayInformationCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postEpayInformationUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postEpayInformation(
      id: number,
      item?: EpayInformationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<EpayInformationModel> {
      return this.apiService.postSingle(
         this.postEpayInformationUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postEpayInformationCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/EpayInformation",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postEpayInformationCollection(
      id: number,
      EpayInfo?: Array<EpayInformationModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<EpayInformationModel>> {
      return this.apiService.postCollection(
         this.postEpayInformationCollectionUrl(id, saveOptions, fields, additionalParameters),
         EpayInfo
      );
   }

   deleteEpayInformationUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/EpayInformation",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteEpayInformation(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteEpayInformationUrl(id, ids, additionalParameters));
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(
         VendorsService.endpoint + "/GetByPost",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/History",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryModel> {
      return this.apiService.getSingle(
         this.getHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/History",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | HistoryGetOptions> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryModel>> {
      return this.apiService.getCollection(
         this.getHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getHistoryNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistoryNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.getSingle(this.getHistoryNotesUrl(id, itemID, embeds, additionalParameters));
   }

   getHistoryNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistoryNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistoryNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getHistoryNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistoryNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistoryNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistoryNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistoryNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistoryNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotes(
      id: number,
      item?: HistoryNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistoryNoteModel> {
      return this.apiService.postSingle(
         this.postHistoryNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistoryNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/HistoryNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistoryNotesCollection(
      id: number,
      historyNoteItems?: Array<HistoryNoteModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postHistoryNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         historyNoteItems
      );
   }

   postAllHistoryNotesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/HistoryNotes",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllHistoryNotesCollection(
      items: Array<HistoryNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistoryNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistoryNoteModel>> {
      return this.apiService.postCollection(
         this.postAllHistoryNotesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistoryNotesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(VendorsService.endpoint + "/HistoryNotes", id, ids, null, additionalParameters);
   }

   deleteHistoryNotes(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistoryNotesUrl(id, ids, additionalParameters));
   }

   getHistorySystemNotesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotes(
      id: number,
      itemID: number,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.getSingle(
         this.getHistorySystemNotesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getHistorySystemNotesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getHistorySystemNotesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getHistorySystemNotesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllHistorySystemNotesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllHistorySystemNotesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.getCollection(
         this.getAllHistorySystemNotesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postHistorySystemNotesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotes(
      id: number,
      item?: HistorySystemNoteModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HistorySystemNoteModel> {
      return this.apiService.postSingle(
         this.postHistorySystemNotesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postHistorySystemNotesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postHistorySystemNotesCollection(
      id: number,
      items: Array<HistorySystemNoteModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | HistorySystemNoteEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<HistorySystemNoteModel>> {
      return this.apiService.postCollection(
         this.postHistorySystemNotesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteHistorySystemNotesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/HistorySystemNotes",
         id,
         ids,
         null,
         additionalParameters
      );
   }

   deleteHistorySystemNotes(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteHistorySystemNotesUrl(id, ids, additionalParameters));
   }

   postMergeUrl(destinationVendorID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("destinationVendorID=" + destinationVendorID);
      return this.apiService.postUrl(VendorsService.endpoint + "/Merge", null, null, null, null, additionalParameters);
   }

   postMerge(
      sourceVendorID: number,
      destinationVendorID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postMergeUrl(destinationVendorID, additionalParameters), sourceVendorID);
   }

   postMergeVendorsUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/MergeVendors",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postMergeVendors(
      item?: MergeVendorModel | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postMergeVendorsUrl(additionalParameters), item);
   }

   postPayLeaseExpenseManagementBillsPostingBatchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PayLeaseExpenseManagementBillsPostingBatch",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPayLeaseExpenseManagementBillsPostingBatch(
      item?: ExpenseManagementInvoiceModel | null,
      additionalParameters?: Array<string>
   ): Observable<ExpenseManagementInvoiceModel> {
      return this.apiService.postSingle(
         this.postPayLeaseExpenseManagementBillsPostingBatchUrl(additionalParameters),
         item
      );
   }

   postPayLeaseExpenseManagementBillsPostingBatchCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PayLeaseExpenseManagementBillsPostingBatch",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPayLeaseExpenseManagementBillsPostingBatchCollection(
      invoices?: Array<ExpenseManagementInvoiceModel> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ExpenseManagementInvoiceModel>> {
      return this.apiService.postCollection(
         this.postPayLeaseExpenseManagementBillsPostingBatchCollectionUrl(additionalParameters),
         invoices
      );
   }

   postAllPayLeaseExpenseManagementBillsPostingBatchCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PayLeaseExpenseManagementBillsPostingBatch",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postAllPayLeaseExpenseManagementBillsPostingBatchCollection(
      invoices?: Array<ExpenseManagementInvoiceModel> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<ExpenseManagementInvoiceModel>> {
      return this.apiService.postCollection(
         this.postAllPayLeaseExpenseManagementBillsPostingBatchCollectionUrl(additionalParameters),
         invoices
      );
   }

   getPaymentMethodsCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PaymentMethods",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPaymentMethodsCollection(additionalParameters?: Array<string>): Observable<Array<BillPayMethod>> {
      return this.apiService.getCollection(this.getPaymentMethodsCollectionUrl(additionalParameters));
   }

   getPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getPhoneNumbersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPhoneNumberTypesUrl(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypes(
      itemID: number,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.getSingle(this.getPhoneNumberTypesUrl(itemID, embeds, additionalParameters));
   }

   getPhoneNumberTypesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumberTypesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllPhoneNumberTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllPhoneNumberTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getAllPhoneNumberTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumberTypesUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypes(
      item?: PhoneNumberTypeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberTypeModel> {
      return this.apiService.postSingle(this.postPhoneNumberTypesUrl(saveOptions, fields, additionalParameters), item);
   }

   postPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   postAllPhoneNumberTypesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllPhoneNumberTypesCollection(
      phoneNumberTypes?: Array<PhoneNumberTypeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.postCollection(
         this.postAllPhoneNumberTypesCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumberTypes
      );
   }

   deletePhoneNumberTypesUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/PhoneNumberTypes",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumberTypes(
      ids: Array<number>,
      deleteOptions?: Array<string | PhoneNumberTypeDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumberTypesUrl(ids, deleteOptions, additionalParameters));
   }

   getPrimaryAddressUrl(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/PrimaryAddress",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrimaryAddress(
      id: number,
      embeds?: Array<string | AddressEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<AddressModel> {
      return this.apiService.getSingle(this.getPrimaryAddressUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Properties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyListItemModel> {
      return this.apiService.getSingle(this.getPropertiesUrl(id, itemID, embeds, additionalParameters));
   }

   getPropertiesCollectionUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Properties",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPropertiesCollection(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyListItemModel>> {
      return this.apiService.getCollection(this.getPropertiesCollectionUrl(id, embeds, additionalParameters));
   }

   postPropertiesUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Properties",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postProperties(
      id: number,
      item?: PropertyListItemModel | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyListItemModel> {
      return this.apiService.postSingle(this.postPropertiesUrl(id, additionalParameters), item);
   }

   postPropertiesCollectionUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Properties",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPropertiesCollection(
      id: number,
      properties?: Array<PropertyListItemModel> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyListItemModel>> {
      return this.apiService.postCollection(this.postPropertiesCollectionUrl(id, additionalParameters), properties);
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         VendorsService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getRMVoIPCallHistoryUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/RMVoIPCallHistory",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistory(
      id: number,
      itemID: number,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.getSingle(
         this.getRMVoIPCallHistoryUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getRMVoIPCallHistoryCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/RMVoIPCallHistory",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRMVoIPCallHistoryCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getRMVoIPCallHistoryCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllRMVoIPCallHistoryCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/RMVoIPCallHistory",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllRMVoIPCallHistoryCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.getCollection(
         this.getAllRMVoIPCallHistoryCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRMVoIPCallHistoryUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistory(
      id: number,
      item?: RMVoIPCallHistoryModel | null,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<RMVoIPCallHistoryModel> {
      return this.apiService.postSingle(
         this.postRMVoIPCallHistoryUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postRMVoIPCallHistoryCollectionUrl(
      id: number,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/RMVoIPCallHistory",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postRMVoIPCallHistoryCollection(
      id: number,
      items: Array<RMVoIPCallHistoryModel>,
      saveOptions?: Array<string | RMVoIPCallHistorySaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | RMVoIPCallHistoryEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<RMVoIPCallHistoryModel>> {
      return this.apiService.postCollection(
         this.postRMVoIPCallHistoryCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | VendorEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | VendorEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(VendorsService.endpoint + "/Search", null, null, null, null, additionalParameters);
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Tasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.getSingle(this.getTasksUrl(id, itemID, embeds, additionalParameters));
   }

   getTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Tasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.getCollection(
         this.getTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTasksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Tasks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postTasks(
      id: number,
      item?: TaskModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.postSingle(this.postTasksUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postTasksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Tasks",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postTasksCollection(
      id: number,
      taskItems?: Array<TaskModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.postCollection(
         this.postTasksCollectionUrl(id, saveOptions, fields, additionalParameters),
         taskItems
      );
   }

   deleteTasksUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(VendorsService.endpoint + "/Tasks", id, ids, null, additionalParameters);
   }

   deleteTasks(id: number, ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteTasksUrl(id, ids, additionalParameters));
   }

   postTestScriptUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/TestScript",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTestScript(
      id: number,
      model?: ScriptBuilderModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postTestScriptUrl(id, additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getTransactionsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorTransactionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Transactions",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTransactions(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorTransactionEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorTransactionModel> {
      return this.apiService.getSingle(
         this.getTransactionsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getTransactionsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorTransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Transactions",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTransactionsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorTransactionEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorTransactionModel>> {
      return this.apiService.getCollection(
         this.getTransactionsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postTransactionsUrl(
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (pageSize !== undefined && pageSize !== null) {
         additionalParameters.push("pageSize=" + pageSize);
      }
      if (pageNumber !== undefined && pageNumber !== null) {
         additionalParameters.push("pageNumber=" + pageNumber);
      }
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Transactions",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postTransactions(
      getModel?: VendorTransactionGetModel | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorTransactionListModel>> {
      return this.apiService
         .post(this.postTransactionsUrl(pageSize, pageNumber, additionalParameters), getModel)
         .pipe(map((response) => response.body));
   }

   postUpdateGlAccountIDOnCreditUrl(
      vendorCreditID: number,
      glAccountID: number,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("vendorCreditID=" + vendorCreditID);
      additionalParameters.push("glAccountID=" + glAccountID);
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UpdateGlAccountIDOnCredit",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postUpdateGlAccountIDOnCredit(
      vendorCreditID: number,
      glAccountID: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(
         this.postUpdateGlAccountIDOnCreditUrl(vendorCreditID, glAccountID, additionalParameters)
      );
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(this.postUserDefinedFieldsUrl(saveOptions, fields, additionalParameters), item);
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      id: number,
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      id: number,
      items: Array<UserDefinedValueModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getVendor1099BeginningBalancesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Vendor1099BeginningBalances",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor1099BeginningBalances(
      id: number,
      itemID: number,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Vendor1099BeginningBalanceModel> {
      return this.apiService.getSingle(
         this.getVendor1099BeginningBalancesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getVendor1099BeginningBalancesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Vendor1099BeginningBalances",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getVendor1099BeginningBalancesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<Vendor1099BeginningBalanceModel>> {
      return this.apiService.getCollection(
         this.getVendor1099BeginningBalancesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postVendor1099BeginningBalancesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Vendor1099BeginningBalances",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVendor1099BeginningBalances(
      id: number,
      item?: Vendor1099BeginningBalanceModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Vendor1099BeginningBalanceModel> {
      return this.apiService.postSingle(
         this.postVendor1099BeginningBalancesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postVendor1099BeginningBalancesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/Vendor1099BeginningBalances",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVendor1099BeginningBalancesCollection(
      id: number,
      items: Array<Vendor1099BeginningBalanceModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | Vendor1099BeginningBalanceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<Vendor1099BeginningBalanceModel>> {
      return this.apiService.postCollection(
         this.postVendor1099BeginningBalancesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteVendor1099BeginningBalancesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/Vendor1099BeginningBalances",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteVendor1099BeginningBalances(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteVendor1099BeginningBalancesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getVendor1099CategoryUrl(
      id: number,
      embeds?: Array<string | Vendor1099CategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Vendor1099Category",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor1099Category(
      id: number,
      embeds?: Array<string | Vendor1099CategoryEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<Vendor1099CategoryModel> {
      return this.apiService.getSingle(
         this.getVendor1099CategoryUrl(id, embeds, fields, getOptions, additionalParameters)
      );
   }

   getVendor1099YTDBalanceCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/Vendor1099YTDBalance",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendor1099YTDBalanceCollection(
      additionalParameters?: Array<string>
   ): Observable<Array<Vendor1099BalanceSummaryModel>> {
      return this.apiService.getCollection(this.getVendor1099YTDBalanceCollectionUrl(additionalParameters));
   }

   getVendorPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/VendorProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getVendorProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorPropertyModel> {
      return this.apiService.getSingle(
         this.getVendorPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getVendorPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         VendorsService.endpoint + "/VendorProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getVendorPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorPropertyModel>> {
      return this.apiService.getCollection(
         this.getVendorPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postVendorPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/VendorProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVendorProperties(
      id: number,
      item?: VendorPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<VendorPropertyModel> {
      return this.apiService.postSingle(
         this.postVendorPropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postVendorPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/VendorProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postVendorPropertiesCollection(
      id: number,
      vendorProperties?: Array<VendorPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | VendorPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<VendorPropertyModel>> {
      return this.apiService.postCollection(
         this.postVendorPropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         vendorProperties
      );
   }

   deleteVendorPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         VendorsService.endpoint + "/VendorProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteVendorProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteVendorPropertiesUrl(id, ids, deleteOptions, additionalParameters));
   }

   postVendorTransactionsSummaryUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         VendorsService.endpoint + "/VendorTransactionsSummary",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postVendorTransactionsSummary(
      getModel?: VendorTransactionGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<VendorTransactionTotalsModel> {
      return this.apiService
         .post(this.postVendorTransactionsSummaryUrl(additionalParameters), getModel)
         .pipe(map((response) => response.body));
   }
}
