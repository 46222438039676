<div
   cdkScrollable
   class="sidebar-layout"
   [class.sidebar-layout-left-hidden]="isLeftCollapsed"
>
   <!-- Left -->
   <div
      [hidden]="isLeftCollapsed"
      class="sidebar-layout-sidebar-wrapper left"
   >
      <div
         class="sidebar-layout-sidebar"
         *ngIf="left"
         [ngClass]="overrideWidth ? 'override-left-width' : ''"
      >
         <div
            class="sidebar-layout-header-wrapper"
            *ngIf="leftHeader"
         >
            <h2 class="sidebar-layout-header">{{ leftHeader }}</h2>
         </div>
         <ng-container
            *ngIf="leftHeaderContent"
            [class.editable]="leftEditable"
         >
            <ng-container [ngTemplateOutlet]="leftHeaderContent"></ng-container>
         </ng-container>
         <div
            cdkScrollable
            class="sidebar-layout-sidebar-content"
            [class.editable]="leftEditable"
         >
            <ng-container [ngTemplateOutlet]="left"></ng-container>
         </div>
      </div>
   </div>

   <!-- Center -->
   <div
      class="sidebar-layout-content-wrapper"
      [ngClass]="overrideHeight ? 'override-center-height' : ''"
   >
      <div
         class="sidebar-layout-header-wrapper"
         *ngIf="centerHeader"
      >
         <h2 class="sidebar-layout-header">{{ centerHeader }}</h2>
      </div>
      <ng-container *ngIf="centerHeaderContent">
         <ng-container [ngTemplateOutlet]="centerHeaderContent"></ng-container>
      </ng-container>
      <div
         cdkScrollable
         #centerScrollable
         class="sidebar-layout-content"
      >
         <ng-container [ngTemplateOutlet]="center"></ng-container>
      </div>
   </div>

   <!-- Right -->
   <div class="sidebar-layout-sidebar-wrapper right">
      <div
         class="sidebar-layout-sidebar"
         *ngIf="right"
      >
         <div
            class="sidebar-layout-header-wrapper"
            *ngIf="rightHeader"
         >
            <h2 class="sidebar-layout-header">{{ rightHeader }}</h2>
         </div>
         <ng-container *ngIf="rightHeaderContent">
            <ng-container [ngTemplateOutlet]="rightHeaderContent"></ng-container>
         </ng-container>
         <div
            cdkScrollable
            class="sidebar-layout-sidebar-content"
            [class.editable]="rightEditable"
         >
            <ng-container [ngTemplateOutlet]="right"></ng-container>
         </div>
      </div>
   </div>
</div>