import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDeleteOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/delete-option";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityGetOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/get-option";
import { EntitySaveOption } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/save-option";
import { UserDefinedFieldDeleteOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-delete-options/user-defined-field.delete-options";
import { AddressBookDetailEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/address-book-detail.embed-options";
import { BankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/bank.embed-options";
import { CreditCardEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/credit-card.embed-options";
import { EmailSignatureEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/email-signature.embed-options";
import { LocationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/location.embed-options";
import { PhoneNumberTypeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number-type.embed-options";
import { PhoneNumberEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/phone-number.embed-options";
import { PropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/property.embed-options";
import { SignablePDFTemplateUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/signable-pdftemplate-user.embed-options";
import { TaskUserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task-user.embed-options";
import { TaskEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/task.embed-options";
import { UserBankEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-bank.embed-options";
import { UserDefinedFieldEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-field.embed-options";
import { UserDefinedValueEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-defined-value.embed-options";
import { UserLetterTemplateEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-letter-template.embed-options";
import { UserLocationEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-location.embed-options";
import { UserPreferenceEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-preference.embed-options";
import { UserPrivilegeEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-privilege.embed-options";
import { UserPropertyGroupEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-property-group.embed-options";
import { UserPropertyEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-property.embed-options";
import { UserReportEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-report.embed-options";
import { UserRoleEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user-role.embed-options";
import { UserEmbedOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-embed-options/user.embed-options";
import { UserDefinedFieldSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user-defined-field.save-options";
import { UserSaveOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-save-options/user.save-options";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { AddUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/add-user.model";
import { AddressBookDetailModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/address-book-detail.model";
import { BankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/bank.model";
import { ChangePasswordModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/change-password.model";
import { CreditCardModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/credit-card.model";
import { EmailSignatureModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/email-signature.model";
import { LocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/location.model";
import { MergeUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/merge-user.model";
import { PhoneNumberTypeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number-type.model";
import { PhoneNumberModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/phone-number.model";
import { PropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/property.model";
import { PushToLocationsWithSynchronizeRolesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/push-to-locations-with-synchronize-roles.model";
import { PushToLocationsModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/push-to-locations.model";
import { SignablePDFTemplateUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/signable-pdftemplate-user.model";
import { TaskUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task-user.model";
import { TaskModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/task.model";
import { UserBankModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-bank.model";
import { UserDefinedFieldModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-field.model";
import { UserDefinedValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-defined-value.model";
import { UserLetterTemplateModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-letter-template.model";
import { UserLocationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-location.model";
import { UserPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-preference.model";
import { UserPrivilegeModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-privilege.model";
import { UserPropertyGroupModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-property-group.model";
import { UserPropertyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-property.model";
import { UserReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-report.model";
import { UserRoleModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user-role.model";
import { UserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/user.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { SearchPostBodyModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/post-body-models/search-post-body.model";
import { PostableGetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/postable-get.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "../../core/api.service";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

@Injectable({ providedIn: "root" })
export class UsersService {
   static readonly AddressBookDetailsResource = "AddressBookDetails";
   static readonly AddressBookDetailsSearchResource = "AddressBookDetailsSearch";
   static readonly AddUserResource = "AddUser";
   static readonly BanksResource = "Banks";
   static readonly ChangePasswordResource = "ChangePassword";
   static readonly CheckAndReturnPasswordRulesResource = "CheckAndReturnPasswordRules";
   static readonly CreateUserResource = "CreateUser";
   static readonly CreditCardsResource = "CreditCards";
   static readonly CurrentUserResource = "CurrentUser";
   static readonly CurrentUserExtResource = "CurrentUserExt";
   static readonly EmailSignatureResource = "EmailSignature";
   static readonly EmailSignaturesResource = "EmailSignatures";
   static readonly GetByPostResource = "GetByPost";
   static readonly GetUserItemsForSiteManagerResource = "GetUserItemsForSiteManager";
   static readonly LetterTemplatesResource = "LetterTemplates";
   static readonly LocationsResource = "Locations";
   static readonly MergeUsersResource = "MergeUsers";
   static readonly PhoneNumbersResource = "PhoneNumbers";
   static readonly PhoneNumberTypesResource = "PhoneNumberTypes";
   static readonly PreferencesResource = "Preferences";
   static readonly PrivilegesResource = "Privileges";
   static readonly PropertiesResource = "Properties";
   static readonly PushToLocationsResource = "PushToLocations";
   static readonly PushToLocationsWithSynchronizeRolesResource = "PushToLocationsWithSynchronizeRoles";
   static readonly PushUserPrivilegesToLocationsResource = "PushUserPrivilegesToLocations";
   static readonly QuickSearchResource = "QuickSearch";
   static readonly ReportsResource = "Reports";
   static readonly RolesResource = "Roles";
   static readonly SearchResource = "Search";
   static readonly ServiceManagerAssignedToUsersResource = "ServiceManagerAssignedToUsers";
   static readonly SignablePDFTemplateUsersResource = "SignablePDFTemplateUsers";
   static readonly SupervisorResource = "Supervisor";
   static readonly TasksResource = "Tasks";
   static readonly TaskUserResource = "TaskUser";
   static readonly UpdateUserResource = "UpdateUser";
   static readonly UploadUserDefinedValueAttachmentResource = "UploadUserDefinedValueAttachment";
   static readonly UserBanksResource = "UserBanks";
   static readonly UserDefinedFieldsResource = "UserDefinedFields";
   static readonly UserDefinedValuesResource = "UserDefinedValues";
   static readonly UserLocationsResource = "UserLocations";
   static readonly UserPrivilegesResource = "UserPrivileges";
   static readonly UserPropertiesResource = "UserProperties";
   static readonly UserPropertiesExtResource = "UserPropertiesExt";
   static readonly UserPropertyGroupsResource = "UserPropertyGroups";
   static readonly UserReportsResource = "UserReports";
   static readonly UserRoleResource = "UserRole";
   static readonly UserRolesResource = "UserRoles";
   static readonly UsersWithAccessToPropertyResource = "UsersWithAccessToProperty";
   static readonly UserTasksResource = "UserTasks";
   static readonly endpoint = "UsersView";
   constructor(private apiService: ApiService) {}
   getUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   get(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint,
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUrl(
      saveOptions?: Array<string | UserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(UsersService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   post(
      item?: UserModel | null,
      saveOptions?: Array<string | UserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.postSingle(this.postUrl(saveOptions, fields, embeds, additionalParameters), item);
   }

   postCollectionUrl(
      saveOptions?: Array<string | UserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(UsersService.endpoint, null, saveOptions, embeds, fields, additionalParameters);
   }

   postCollection(
      items: Array<UserModel>,
      saveOptions?: Array<string | UserSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.postCollection(
         this.postCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUrl(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(UsersService.endpoint, id, null, deleteOptions, additionalParameters);
   }

   delete(
      id: number,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUrl(id, deleteOptions, additionalParameters));
   }

   deleteCollectionUrl(ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(UsersService.endpoint, null, ids, null, additionalParameters);
   }

   deleteCollection(ids: Array<number>, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.deleteCollection(this.deleteCollectionUrl(ids, additionalParameters));
   }

   getAddressBookDetailsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressBookDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/AddressBookDetails",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAddressBookDetailsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | AddressBookDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressBookDetailModel>> {
      return this.apiService.getCollection(
         this.getAddressBookDetailsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAddressBookDetailsSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AddressBookDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/AddressBookDetailsSearch",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getAddressBookDetailsSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | AddressBookDetailEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<AddressBookDetailModel>> {
      return this.apiService.getCollection(
         this.getAddressBookDetailsSearchCollectionUrl(
            filterExpression,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postAddUserUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(UsersService.endpoint + "/AddUser", null, null, null, null, additionalParameters);
   }

   postAddUser(model?: AddUserModel | null, additionalParameters?: Array<string>): Observable<UserModel> {
      return this.apiService
         .post(this.postAddUserUrl(additionalParameters), model)
         .pipe(map((response) => response.body));
   }

   getBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | BankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Banks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | BankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<BankModel> {
      return this.apiService.getSingle(this.getBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Banks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | BankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<BankModel>> {
      return this.apiService.getCollection(
         this.getBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postChangePasswordUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/ChangePassword",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postChangePassword(
      id: number,
      passwordModel?: ChangePasswordModel | null,
      additionalParameters?: Array<string>
   ): Observable<Boolean> {
      return this.apiService
         .post(this.postChangePasswordUrl(id, additionalParameters), passwordModel)
         .pipe(map((response) => response.body));
   }

   postCheckAndReturnPasswordRulesUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/CheckAndReturnPasswordRules",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postCheckAndReturnPasswordRules(
      passwordModel?: ChangePasswordModel | null,
      additionalParameters?: Array<string>
   ): Observable<string> {
      return this.apiService
         .post(this.postCheckAndReturnPasswordRulesUrl(additionalParameters), passwordModel)
         .pipe(map((response) => response.body));
   }

   getCreateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/CreateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCreateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getCreditCardsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditCardEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/CreditCards",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCreditCards(
      id: number,
      itemID: number,
      embeds?: Array<string | CreditCardEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<CreditCardModel> {
      return this.apiService.getSingle(
         this.getCreditCardsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getCreditCardsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditCardEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/CreditCards",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getCreditCardsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | CreditCardEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<CreditCardModel>> {
      return this.apiService.getCollection(
         this.getCreditCardsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCurrentUserUrl(embeds?: Array<string | UserEmbedOptions> | null, additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/CurrentUser",
         null,
         null,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCurrentUser(
      embeds?: Array<string | UserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getCurrentUserUrl(embeds, additionalParameters));
   }

   getCurrentUserExtUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/CurrentUserExt",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getCurrentUserExt(additionalParameters?: Array<string>): Observable<UserModel> {
      return this.apiService.getSingle(this.getCurrentUserExtUrl(additionalParameters));
   }

   getEmailSignatureUrl(
      id: number,
      embeds?: Array<string | EmailSignatureEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/EmailSignature",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getEmailSignature(
      id: number,
      embeds?: Array<string | EmailSignatureEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<EmailSignatureModel> {
      return this.apiService.getSingle(this.getEmailSignatureUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getEmailSignaturesUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EmailSignatureEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/EmailSignatures",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getEmailSignatures(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | EmailSignatureEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<EmailSignatureModel> {
      return this.apiService.getSingle(
         this.getEmailSignaturesUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postGetByPostUrl(id: number, model?: PostableGetModel | null, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (model !== undefined && model !== null) {
         additionalParameters.push("model=" + model);
      }
      return this.apiService.postUrl(UsersService.endpoint + "/GetByPost", id, null, null, null, additionalParameters);
   }

   postGetByPost(
      id: number,
      model?: PostableGetModel | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService
         .post(this.postGetByPostUrl(id, model, additionalParameters))
         .pipe(map((response) => response.body));
   }

   getGetUserItemsForSiteManagerCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/GetUserItemsForSiteManager",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getGetUserItemsForSiteManagerCollection(additionalParameters?: Array<string>): Observable<Array<UserModel>> {
      return this.apiService.getCollection(this.getGetUserItemsForSiteManagerCollectionUrl(additionalParameters));
   }

   getLetterTemplatesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserLetterTemplateEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/LetterTemplates",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLetterTemplates(
      id: number,
      itemID: number,
      embeds?: Array<string | UserLetterTemplateEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserLetterTemplateModel> {
      return this.apiService.getSingle(
         this.getLetterTemplatesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLetterTemplatesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserLetterTemplateEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/LetterTemplates",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLetterTemplatesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserLetterTemplateEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserLetterTemplateModel>> {
      return this.apiService.getCollection(
         this.getLetterTemplatesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postLetterTemplatesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/LetterTemplates",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLetterTemplates(
      id: number,
      item?: UserLetterTemplateModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserLetterTemplateModel> {
      return this.apiService.postSingle(
         this.postLetterTemplatesUrl(id, saveOptions, fields, additionalParameters),
         item
      );
   }

   postLetterTemplatesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/LetterTemplates",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postLetterTemplatesCollection(
      id: number,
      letters?: Array<UserLetterTemplateModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserLetterTemplateModel>> {
      return this.apiService.postCollection(
         this.postLetterTemplatesCollectionUrl(id, saveOptions, fields, additionalParameters),
         letters
      );
   }

   deleteLetterTemplatesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/LetterTemplates",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteLetterTemplates(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteLetterTemplatesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getLocationsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Locations",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getLocations(
      id: number,
      itemID: number,
      embeds?: Array<string | LocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<LocationModel> {
      return this.apiService.getSingle(
         this.getLocationsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getLocationsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Locations",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getLocationsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | LocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<LocationModel>> {
      return this.apiService.getCollection(
         this.getLocationsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postMergeUsersUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/MergeUsers",
         null,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postMergeUsers(model?: MergeUserModel | null, additionalParameters?: Array<string>): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postMergeUsersUrl(additionalParameters), model);
   }

   getPhoneNumbersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/PhoneNumbers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbers(
      id: number,
      itemID: number,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.getSingle(
         this.getPhoneNumbersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPhoneNumbersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/PhoneNumbers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumbersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumbersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPhoneNumbersUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PhoneNumbers",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumbers(
      item?: PhoneNumberModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<PhoneNumberModel> {
      return this.apiService.postSingle(this.postPhoneNumbersUrl(saveOptions, fields, additionalParameters), item);
   }

   postPhoneNumbersCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PhoneNumbers",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPhoneNumbersCollection(
      phoneNumbers?: Array<PhoneNumberModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.postCollection(
         this.postPhoneNumbersCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumbers
      );
   }

   postAllPhoneNumbersCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PhoneNumbers",
         null,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postAllPhoneNumbersCollection(
      phoneNumbers?: Array<PhoneNumberModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberModel>> {
      return this.apiService.postCollection(
         this.postAllPhoneNumbersCollectionUrl(saveOptions, fields, additionalParameters),
         phoneNumbers
      );
   }

   deletePhoneNumbersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/PhoneNumbers",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePhoneNumbers(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePhoneNumbersUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPhoneNumberTypesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/PhoneNumberTypes",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPhoneNumberTypesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PhoneNumberTypeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PhoneNumberTypeModel>> {
      return this.apiService.getCollection(
         this.getPhoneNumberTypesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getPreferencesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Preferences",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPreferences(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPreferenceModel> {
      return this.apiService.getSingle(
         this.getPreferencesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPreferencesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Preferences",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPreferencesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPreferenceModel>> {
      return this.apiService.getCollection(
         this.getPreferencesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPreferencesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Preferences",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPreferences(
      id: number,
      item?: UserPreferenceModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPreferenceModel> {
      return this.apiService.postSingle(
         this.postPreferencesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postPreferencesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Preferences",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postPreferencesCollection(
      id: number,
      userPreferences?: Array<UserPreferenceModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPreferenceEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPreferenceModel>> {
      return this.apiService.postCollection(
         this.postPreferencesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userPreferences
      );
   }

   deletePreferencesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/Preferences",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deletePreferences(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePreferencesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getPrivilegesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Privileges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getPrivileges(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPrivilegeModel> {
      return this.apiService.getSingle(this.getPrivilegesUrl(id, itemID, embeds, additionalParameters));
   }

   getPrivilegesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Privileges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPrivilegesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPrivilegeModel>> {
      return this.apiService.getCollection(
         this.getPrivilegesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPrivilegesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Privileges",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPrivileges(
      id: number,
      item?: UserPrivilegeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPrivilegeModel> {
      return this.apiService.postSingle(this.postPrivilegesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postPrivilegesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Privileges",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postPrivilegesCollection(
      id: number,
      userPrivileges?: Array<UserPrivilegeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPrivilegeModel>> {
      return this.apiService.postCollection(
         this.postPrivilegesCollectionUrl(id, saveOptions, fields, additionalParameters),
         userPrivileges
      );
   }

   deletePrivilegesUrl(id: number, ids: Array<number>, additionalParameters?: Array<string>): string {
      return this.apiService.deleteUrl(UsersService.endpoint + "/Privileges", id, ids, null, additionalParameters);
   }

   deletePrivileges(
      id: number,
      ids: Array<number>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deletePrivilegesUrl(id, ids, additionalParameters));
   }

   getPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Properties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<PropertyModel> {
      return this.apiService.getSingle(
         this.getPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Properties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | PropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<PropertyModel>> {
      return this.apiService.getCollection(
         this.getPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postPushToLocationsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PushToLocations",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPushToLocations(
      id: number,
      pushToLocations?: PushToLocationsModel | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService
         .post(this.postPushToLocationsUrl(id, additionalParameters), pushToLocations)
         .pipe(map((response) => response.body));
   }

   postPushToLocationsWithSynchronizeRolesUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PushToLocationsWithSynchronizeRoles",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPushToLocationsWithSynchronizeRoles(
      id: number,
      pushToLocations?: PushToLocationsWithSynchronizeRolesModel | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService
         .post(this.postPushToLocationsWithSynchronizeRolesUrl(id, additionalParameters), pushToLocations)
         .pipe(map((response) => response.body));
   }

   postPushUserPrivilegesToLocationsUrl(id: number, additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/PushUserPrivilegesToLocations",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postPushUserPrivilegesToLocations(
      id: number,
      locationIDs?: Array<number> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.postCollection(
         this.postPushUserPrivilegesToLocationsUrl(id, additionalParameters),
         locationIDs
      );
   }

   getQuickSearchCollectionUrl(
      filterFields?: Array<string> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (searchText !== undefined && searchText !== null) {
         additionalParameters.push("searchText=" + searchText);
      }
      return this.apiService.getUrl(
         UsersService.endpoint + "/QuickSearch",
         null,
         null,
         null,
         null,
         filterFields,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getQuickSearchCollection(
      filterFields?: Array<string> | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      searchText?: string | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getQuickSearchCollectionUrl(
            filterFields,
            embeds,
            orderingOptions,
            fields,
            searchText,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getReportsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Reports",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getReports(
      id: number,
      itemID: number,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserReportModel> {
      return this.apiService.getSingle(this.getReportsUrl(id, itemID, embeds, additionalParameters));
   }

   getReportsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Reports",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getReportsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserReportModel>> {
      return this.apiService.getCollection(
         this.getReportsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postReportsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Reports",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postReports(
      id: number,
      item?: UserReportModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserReportModel> {
      return this.apiService.postSingle(this.postReportsUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postReportsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Reports",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postReportsCollection(
      id: number,
      userReports?: Array<UserReportModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserReportModel>> {
      return this.apiService.postCollection(
         this.postReportsCollectionUrl(id, saveOptions, fields, additionalParameters),
         userReports
      );
   }

   getRolesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Roles",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getRoles(
      id: number,
      itemID: number,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserRoleModel> {
      return this.apiService.getSingle(this.getRolesUrl(id, itemID, embeds, additionalParameters));
   }

   getRolesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Roles",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getRolesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserRoleModel>> {
      return this.apiService.getCollection(
         this.getRolesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postRolesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Roles",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRoles(
      id: number,
      item?: UserRoleModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserRoleModel> {
      return this.apiService.postSingle(this.postRolesUrl(id, saveOptions, fields, additionalParameters), item);
   }

   postRolesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/Roles",
         id,
         saveOptions,
         null,
         fields,
         additionalParameters
      );
   }

   postRolesCollection(
      id: number,
      userRoles?: Array<UserRoleModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserRoleModel>> {
      return this.apiService.postCollection(
         this.postRolesCollectionUrl(id, saveOptions, fields, additionalParameters),
         userRoles
      );
   }

   getSearchUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Search",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         true
      );
   }

   getSearch(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getSearchUrl(id, embeds, fields, additionalParameters));
   }

   getSearchCollectionUrl(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Search",
         null,
         null,
         null,
         filterExpression,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         true
      );
   }

   getSearchCollection(
      filterExpression?: FilterExpression | null,
      embeds?: Array<string | UserEmbedOptions> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null | null,
      pageNumber?: number | null | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getSearchCollectionUrl(
            filterExpression,
            embeds,
            getOptions,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSearchUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(UsersService.endpoint + "/Search", null, null, null, null, additionalParameters);
   }

   postSearch(
      searchBody?: SearchPostBodyModel | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService
         .post(this.postSearchUrl(additionalParameters), searchBody)
         .pipe(map((response) => response.body));
   }

   getServiceManagerAssignedToUsersCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/ServiceManagerAssignedToUsers",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getServiceManagerAssignedToUsersCollection(additionalParameters?: Array<string>): Observable<Array<UserModel>> {
      return this.apiService.getCollection(this.getServiceManagerAssignedToUsersCollectionUrl(additionalParameters));
   }

   getSignablePDFTemplateUsersUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/SignablePDFTemplateUsers",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSignablePDFTemplateUsers(
      id: number,
      itemID: number,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<SignablePDFTemplateUserModel> {
      return this.apiService.getSingle(
         this.getSignablePDFTemplateUsersUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getSignablePDFTemplateUsersCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/SignablePDFTemplateUsers",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getSignablePDFTemplateUsersCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignablePDFTemplateUserModel>> {
      return this.apiService.getCollection(
         this.getSignablePDFTemplateUsersCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postSignablePDFTemplateUsersUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/SignablePDFTemplateUsers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSignablePDFTemplateUsers(
      id: number,
      item?: SignablePDFTemplateUserModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<SignablePDFTemplateUserModel> {
      return this.apiService.postSingle(
         this.postSignablePDFTemplateUsersUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postSignablePDFTemplateUsersCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/SignablePDFTemplateUsers",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postSignablePDFTemplateUsersCollection(
      id: number,
      models?: Array<SignablePDFTemplateUserModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | SignablePDFTemplateUserEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<SignablePDFTemplateUserModel>> {
      return this.apiService.postCollection(
         this.postSignablePDFTemplateUsersCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         models
      );
   }

   deleteSignablePDFTemplateUsersUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/SignablePDFTemplateUsers",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteSignablePDFTemplateUsers(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteSignablePDFTemplateUsersUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getSupervisorUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Supervisor",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getSupervisor(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getSupervisorUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Tasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskModel> {
      return this.apiService.getSingle(this.getTasksUrl(id, itemID, embeds, fields, getOptions, additionalParameters));
   }

   getTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/Tasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskModel>> {
      return this.apiService.getCollection(
         this.getTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getTaskUserCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/TaskUser",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getTaskUserCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskUserModel>> {
      return this.apiService.getCollection(
         this.getTaskUserCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUpdateUserUrl(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UpdateUser",
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUpdateUser(
      id: number,
      embeds?: Array<string | UserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserModel> {
      return this.apiService.getSingle(this.getUpdateUserUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getUserBanksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserBanks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserBanks(
      id: number,
      itemID: number,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserBankModel> {
      return this.apiService.getSingle(
         this.getUserBanksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserBanksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserBanks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserBanksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserBankModel>> {
      return this.apiService.getCollection(
         this.getUserBanksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserBanksUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserBanks(
      id: number,
      item?: UserBankModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserBankModel> {
      return this.apiService.postSingle(
         this.postUserBanksUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserBanksCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserBanks",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserBanksCollection(
      id: number,
      userBanks?: Array<UserBankModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserBankEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserBankModel>> {
      return this.apiService.postCollection(
         this.postUserBanksCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userBanks
      );
   }

   deleteUserBanksUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserBanks",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserBanks(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserBanksUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUserDefinedFieldsUrl(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFields(
      itemID: number,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.getSingle(this.getUserDefinedFieldsUrl(itemID, embeds, fields, additionalParameters));
   }

   getUserDefinedFieldsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedFields",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedFieldsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      orderingOptions?: Array<OrderingOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedFieldsCollectionUrl(
            id,
            filters,
            embeds,
            fields,
            orderingOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedFieldsCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         null,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedFieldsCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedFieldsCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedFieldsUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFields(
      item?: UserDefinedFieldModel | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedFieldModel> {
      return this.apiService.postSingle(
         this.postUserDefinedFieldsUrl(saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedFieldsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         udfs
      );
   }

   postAllUserDefinedFieldsCollectionUrl(
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedFieldsCollection(
      udfs?: Array<UserDefinedFieldModel> | null,
      saveOptions?: Array<string | UserDefinedFieldSaveOptions> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedFieldEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedFieldModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedFieldsCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         udfs
      );
   }

   deleteUserDefinedFieldsUrl(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserDefinedFields",
         null,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedFields(
      ids: Array<number>,
      deleteOptions?: Array<string | UserDefinedFieldDeleteOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserDefinedFieldsUrl(ids, deleteOptions, additionalParameters));
   }

   getUserDefinedValuesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedValues",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValues(
      id: number,
      itemID: number,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.getSingle(
         this.getUserDefinedValuesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserDefinedValuesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedValues",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserDefinedValuesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getUserDefinedValuesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getAllUserDefinedValuesCollectionUrl(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserDefinedValues",
         null,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getAllUserDefinedValuesCollection(
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.getCollection(
         this.getAllUserDefinedValuesCollectionUrl(
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserDefinedValuesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValues(
      id: number,
      item?: UserDefinedValueModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserDefinedValueModel> {
      return this.apiService.postSingle(
         this.postUserDefinedValuesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserDefinedValuesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedValues",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserDefinedValuesCollection(
      id: number,
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postUserDefinedValuesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   postAllUserDefinedValuesCollectionUrl(
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserDefinedValues",
         null,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postAllUserDefinedValuesCollection(
      userDefinedValues?: Array<UserDefinedValueModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserDefinedValueEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserDefinedValueModel>> {
      return this.apiService.postCollection(
         this.postAllUserDefinedValuesCollectionUrl(saveOptions, fields, embeds, additionalParameters),
         userDefinedValues
      );
   }

   deleteUserDefinedValuesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserDefinedValues",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserDefinedValues(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserDefinedValuesUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUserLocationsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserLocations",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserLocations(
      id: number,
      itemID: number,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserLocationModel> {
      return this.apiService.getSingle(
         this.getUserLocationsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserLocationsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserLocations",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserLocationsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserLocationModel>> {
      return this.apiService.getCollection(
         this.getUserLocationsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserLocationsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserLocations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserLocations(
      id: number,
      item?: UserLocationModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserLocationModel> {
      return this.apiService.postSingle(
         this.postUserLocationsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserLocationsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserLocations",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserLocationsCollection(
      id: number,
      userLocations?: Array<UserLocationModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserLocationEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserLocationModel>> {
      return this.apiService.postCollection(
         this.postUserLocationsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userLocations
      );
   }

   deleteUserLocationsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserLocations",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserLocations(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserLocationsUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUserPrivilegesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserPrivileges",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserPrivileges(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPrivilegeModel> {
      return this.apiService.getSingle(
         this.getUserPrivilegesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserPrivilegesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserPrivileges",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserPrivilegesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPrivilegeModel>> {
      return this.apiService.getCollection(
         this.getUserPrivilegesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserPrivilegesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserPrivileges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPrivileges(
      id: number,
      item?: UserPrivilegeModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPrivilegeModel> {
      return this.apiService.postSingle(
         this.postUserPrivilegesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserPrivilegesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserPrivileges",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPrivilegesCollection(
      id: number,
      userPrivileges?: Array<UserPrivilegeModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPrivilegeEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPrivilegeModel>> {
      return this.apiService.postCollection(
         this.postUserPrivilegesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userPrivileges
      );
   }

   deleteUserPrivilegesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserPrivileges",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserPrivileges(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserPrivilegesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUserPropertiesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserProperties",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserProperties(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyModel> {
      return this.apiService.getSingle(
         this.getUserPropertiesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserPropertiesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserProperties",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserPropertiesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyModel>> {
      return this.apiService.getCollection(
         this.getUserPropertiesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserPropertiesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserProperties(
      id: number,
      item?: UserPropertyModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyModel> {
      return this.apiService.postSingle(
         this.postUserPropertiesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserPropertiesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserProperties",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPropertiesCollection(
      id: number,
      userProperties?: Array<UserPropertyModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyModel>> {
      return this.apiService.postCollection(
         this.postUserPropertiesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userProperties
      );
   }

   deleteUserPropertiesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserProperties",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserProperties(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserPropertiesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUserPropertiesExtCollectionUrl(additionalParameters?: Array<string>): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserPropertiesExt",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserPropertiesExtCollection(additionalParameters?: Array<string>): Observable<Array<UserPropertyModel>> {
      return this.apiService.getCollection(this.getUserPropertiesExtCollectionUrl(additionalParameters));
   }

   getUserPropertyGroupsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserPropertyGroups",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserPropertyGroups(
      id: number,
      itemID: number,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyGroupModel> {
      return this.apiService.getSingle(
         this.getUserPropertyGroupsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserPropertyGroupsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserPropertyGroups",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserPropertyGroupsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyGroupModel>> {
      return this.apiService.getCollection(
         this.getUserPropertyGroupsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserPropertyGroupsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserPropertyGroups",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPropertyGroups(
      id: number,
      item?: UserPropertyGroupModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserPropertyGroupModel> {
      return this.apiService.postSingle(
         this.postUserPropertyGroupsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserPropertyGroupsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserPropertyGroups",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserPropertyGroupsCollection(
      id: number,
      items: Array<UserPropertyGroupModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserPropertyGroupEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserPropertyGroupModel>> {
      return this.apiService.postCollection(
         this.postUserPropertyGroupsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserPropertyGroupsUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserPropertyGroups",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserPropertyGroups(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(
         this.deleteUserPropertyGroupsUrl(id, ids, deleteOptions, additionalParameters)
      );
   }

   getUserReportsUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserReports",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserReports(
      id: number,
      itemID: number,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserReportModel> {
      return this.apiService.getSingle(
         this.getUserReportsUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserReportsCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserReports",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserReportsCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserReportModel>> {
      return this.apiService.getCollection(
         this.getUserReportsCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserReportsUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserReports",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserReports(
      id: number,
      item?: UserReportModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserReportModel> {
      return this.apiService.postSingle(
         this.postUserReportsUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserReportsCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserReports",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserReportsCollection(
      id: number,
      userReports?: Array<UserReportModel> | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserReportEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserReportModel>> {
      return this.apiService.postCollection(
         this.postUserReportsCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         userReports
      );
   }

   postUserRoleUrl(additionalParameters?: Array<string>): string {
      return this.apiService.postUrl(UsersService.endpoint + "/UserRole", null, null, null, null, additionalParameters);
   }

   postUserRole(userRole?: UserRoleModel | null, additionalParameters?: Array<string>): Observable<UserRoleModel> {
      return this.apiService
         .post(this.postUserRoleUrl(additionalParameters), userRole)
         .pipe(map((response) => response.body));
   }

   getUserRolesUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserRoles",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserRoles(
      id: number,
      itemID: number,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<UserRoleModel> {
      return this.apiService.getSingle(
         this.getUserRolesUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserRolesCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserRoles",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserRolesCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserRoleModel>> {
      return this.apiService.getCollection(
         this.getUserRolesCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   postUserRolesUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserRoles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserRoles(
      id: number,
      item?: UserRoleModel | null,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<UserRoleModel> {
      return this.apiService.postSingle(
         this.postUserRolesUrl(id, saveOptions, fields, embeds, additionalParameters),
         item
      );
   }

   postUserRolesCollectionUrl(
      id: number,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.postUrl(
         UsersService.endpoint + "/UserRoles",
         id,
         saveOptions,
         embeds,
         fields,
         additionalParameters
      );
   }

   postUserRolesCollection(
      id: number,
      items: Array<UserRoleModel>,
      saveOptions?: Array<string | EntitySaveOption> | null,
      fields?: Array<string | EntityField> | null,
      embeds?: Array<string | UserRoleEmbedOptions> | null,
      additionalParameters?: Array<string>
   ): Observable<Array<UserRoleModel>> {
      return this.apiService.postCollection(
         this.postUserRolesCollectionUrl(id, saveOptions, fields, embeds, additionalParameters),
         items
      );
   }

   deleteUserRolesUrl(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.deleteUrl(
         UsersService.endpoint + "/UserRoles",
         id,
         ids,
         deleteOptions,
         additionalParameters
      );
   }

   deleteUserRoles(
      id: number,
      ids: Array<number>,
      deleteOptions?: Array<string | EntityDeleteOption> | null,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.deleteSingle(this.deleteUserRolesUrl(id, ids, deleteOptions, additionalParameters));
   }

   getUsersWithAccessToPropertyCollectionUrl(propertyID: number, additionalParameters?: Array<string>): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      additionalParameters.push("propertyID=" + propertyID);
      return this.apiService.getUrl(
         UsersService.endpoint + "/UsersWithAccessToProperty",
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUsersWithAccessToPropertyCollection(
      propertyID: number,
      additionalParameters?: Array<string>
   ): Observable<Array<UserModel>> {
      return this.apiService.getCollection(
         this.getUsersWithAccessToPropertyCollectionUrl(propertyID, additionalParameters)
      );
   }

   getUserTasksUrl(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserTasks",
         id,
         itemID,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters,
         undefined
      );
   }

   getUserTasks(
      id: number,
      itemID: number,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      additionalParameters?: Array<string>
   ): Observable<TaskUserModel> {
      return this.apiService.getSingle(
         this.getUserTasksUrl(id, itemID, embeds, fields, getOptions, additionalParameters)
      );
   }

   getUserTasksCollectionUrl(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): string {
      return this.apiService.getUrl(
         UsersService.endpoint + "/UserTasks",
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters,
         undefined
      );
   }

   getUserTasksCollection(
      id: number,
      filters?: Array<FilterOption> | null,
      embeds?: Array<string | TaskUserEmbedOptions> | null,
      orderingOptions?: Array<OrderingOption> | null,
      fields?: Array<string | EntityField> | null,
      getOptions?: Array<string | EntityGetOption> | null,
      pageSize?: number | null,
      pageNumber?: number | null,
      additionalParameters?: Array<string>
   ): Observable<Array<TaskUserModel>> {
      return this.apiService.getCollection(
         this.getUserTasksCollectionUrl(
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }
}
