import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { EntityChangeModel } from "./entity-change.model";

@Injectable({
   providedIn: "root",
})
export class EntityChangeService {
   public entityChange: Subject<EntityChangeModel>;

   constructor() {
      this.entityChange = new Subject<EntityChangeModel>();
   }
}
