export class SliderConfigurationModel {
   invert: any;
   max: number | null = 10;
   min: number | null = 0;
   step: number = 1;
   thumbLabel: boolean = true;
   showValueLabel: boolean = true;
   tickInterval: "auto" | number;
   vertical: any;
}
