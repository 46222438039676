import { Injectable } from "@angular/core";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { Observable } from "rxjs";

import { EntityRequestEndpointInformationModel } from "./entity-request-endpoint-information.model";

@Injectable()
export abstract class EntityRequestEndpointServiceBase {
   abstract getEndpointInformation(entityType: EntityType | null): Observable<EntityRequestEndpointInformationModel>;
}
