import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";

export class PasswordValidator {
   public static validatePassword(validation: ValidationModel): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }
         let passedValidation = true;
         const validationErrors: ValidationErrors = {};

         if (control.value && validation.pattern && !control.value.match(validation.pattern)) {
            passedValidation = false;
            validationErrors["pattern.patternValidationMessage"] = validation.patternValidationMessage;
         }

         // fire validation for control that needs to match
         if (validation.dependentControlNames && validation.dependentControlNames.length === 1) {
            const matchControl = control.root.get(validation.dependentControlNames[0]);
            if (matchControl !== undefined && matchControl != null) {
               if (matchControl.dirty) {
                  matchControl.updateValueAndValidity();
               }
            }
         }

         // validate this control matches original control value
         if (validation.parentControlNames && validation.parentControlNames.length === 1) {
            const matchToControl = control.root.get(validation.parentControlNames[0]);
            if (matchToControl !== undefined && matchToControl != null) {
               if (matchToControl.value !== control.value) {
                  passedValidation = false;
                  validationErrors["confirmNewPassword"] = true;
               }
            }
         }

         if (passedValidation) {
            return null;
         } else {
            return validationErrors;
         }
      };
   }
}
