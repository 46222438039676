/**
 * Convert a Map to JSON string
 */
export function mapToJson<K, T>(map: Map<K, T>): string {
   return JSON.stringify(Array.from(map.entries()));
}

/**
 * Deserialize a JSON string to a Map
 */
export function mapFromJson<K, T>(jsonString: string): Map<K, T> {
   try {
      const parsedJson: any = jsonString ? JSON.parse(jsonString) : null;
      return parsedJson ? new Map<K, T>(parsedJson) : new Map<K, T>();
   } catch (error) {
      return new Map<K, T>();
   }
}
