<lcs-table [dataSource]="historyItems">
   <tr
      lcs-header-row
      *lcsHeaderRowDef="historyColumns; sticky: false"
   ></tr>
   <tr
      lcs-row
      *lcsRowDef="let row; columns: historyColumns"
   ></tr>
   <ng-container
      lcsColumnDef="Date"
      [width]="9"
      [priority]="1"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Date</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >
         <div class="tableCell"><img
               *ngIf="row.IsPinned  && !hasThemePinnedNotes; else themePinned "
               class="pin-icon"
               src="/assets/images/pin.png"
            />
         </div>
         <div
            *ngIf="!row.IsPinned && hasPinnedNotes"
            class="pin-icon alignLineWithPinIcon"
         ></div>
         <div class="tableCell">{{ row.HistoryDate | date: "MM/dd/yyyy" }}</div>
      </td>
   </ng-container>
   <ng-container
      lcsColumnDef="Note"
      [width]="30"
      [priority]="2"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Note</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
      >
         {{ row.Note }}
      </td>
   </ng-container>
   <ng-container
      lcsColumnDef="File"
      [width]="10"
      [priority]="3"
   >
      <th
         lcs-header-cell
         *lcsHeaderCellDef
      >Files</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="attachment"
      >

         <div *ngIf="row.Attachments">
            <div *ngIf="row.Attachments.length === 1">
               <div *ngFor="let file of row.Attachments">
                  <a
                     href="{{ file.DownloadURL }}"
                     target="_blank"
                  >
                     <i class="material-icons">save_alt</i>
                  </a>
               </div>
            </div>
            <div *ngIf="row.Attachments.length > 1">
               <a
                  (click)="openCarousel(row.HistoryID, row.Note)"
                  target="_blank"
                  style="cursor: pointer;"
               >
                  <i class="far fa-images"></i>
               </a>
            </div>
         </div>
      </td>
      <ng-template #themePinned>
         <img
            class="pin-icon"
            src="/assets/images/pin-theme.png"
         />
      </ng-template>
   </ng-container>
</lcs-table>