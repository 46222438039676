<ul
   class="sidebar-menu-page"
   [style.left]="left"
   [style.overflow-y]="overflowY"
   [style.transition]="transitionStyle | async"
>
   <ng-container *ngIf="parentMenuItem">
      <li
         class="sidebar-menu-page-header"
         *ngIf="parentMenuItem?.label"
      >
         {{ parentMenuItem?.label }}
      </li>

      <li
         class="sidebar-menu-page-back"
         (click)="goBack()"
      >
         <lcs-link
            [contentTemplate]="contentTemplate"
            [cssClasses]="['user-select-none']"
         >
            <ng-template #contentTemplate>
               <i class="material-icons">keyboard_arrow_left</i>
               BACK
            </ng-template>
         </lcs-link>
      </li>
   </ng-container>

   <li
      *ngFor="let menuItem of menuItems"
      (click)="onMenuItemClick(menuItem)"
      [pTooltip]="isIOS ? '' : menuItem.tooltip"
      tooltipStyleClass="sidebar-menu-tooltip"
   >
      <lcs-link
         *ngIf="menuItem.children && menuItem.children.length > 0; else menuItemLink"
         [contentTemplate]="menuPageLink"
         [cssClasses]="['user-select-none']"
         id="{{menuItem.id}}"
      >
         <ng-template #menuPageLink>
            <div>
               {{ menuItem.label }}
            </div>
            <i class="material-icons">keyboard_arrow_right</i>
         </ng-template>
      </lcs-link>
      <ng-template #menuItemLink>
         <lcs-link
            *ngIf="(menuItem.routerLink && menuItem.routerLink[0] !== '')"
            id="{{menuItem.id}}"
            [text]="menuItem.label"
            [routerLink]="menuItem.routerLink"
            [cssClasses]="['user-select-none']"
         >
         </lcs-link>
         <lcs-link
            *ngIf="menuItem.clickEvent"
            id="{{menuItem.id}}"
            [text]="menuItem.label"
            [cssClasses]="['user-select-none']"
            (clicked)="menuItem.clickEvent()"
         >
         </lcs-link>
         <!-- NOTE: following ngIf condition should never happen in production as these items are filtered out -->
         <lcs-link
            *ngIf="menuItem.routerLink && menuItem.routerLink[0] === '' && !menuItem.clickEvent"
            [contentTemplate]="contentTemplateNoAction"
            [disabled]="true"
         >
            <ng-template #contentTemplateNoAction>
               <div
                  title="Displays in DEV/QA only!!!"
                  class="side-bar-menu-display-title strike-through"
               >{{ menuItem.label }}</div>
            </ng-template>
         </lcs-link>
      </ng-template>
   </li>
</ul>
<lcs-sidebar-menu-page
   *ngFor="let menuItem of menuItemsWithChildren"
   [parentMenuItem]="menuItem"
   [grandParentMenuItem]="parentMenuItem"
   [menuItems]="menuItem.children"
>
</lcs-sidebar-menu-page>