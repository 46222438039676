import { formatDate } from "@angular/common";
import { GlobalsService } from "@lcs/core/globals.service";
import { FilterDataTypes } from "@lcs/filters/filter-data-types.enum";
import { FilterValueType } from "@lcs/filters/filter-value.types";
import { errorInDevMode, warnInDevMode } from "projects/libraries/lcs/src/lib/utils/logging";
import { EntityFilterField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/filter-field";

import { ApiUrlConstants } from "../core/api-url.constants";
import { FilterHelper } from "../core/filter-helper";
import { ExpressDataTypes } from "../enumerations/generated/express-data-types.enum";
import { ExpressSystemSettingField } from "../enumerations/generated/express-system-setting-field.enum";
import { FilterOperations } from "../enumerations/generated/filter-operations.enum";
import { ExpressControlDataSourceFilterModel } from "./generated/express-control-data-source-filter.model";
import { FilterOptionTransferModel } from "./generated/filter-option-transfer.model";

export interface FilterOptionKeyValuePair {
   filterName: string;
   filterOption: FilterOption | null;
}

export class FilterOption {
   set DisplayValue(value: string) {
      this._displayValue = value ?? "";
   }

   get DisplayValue(): string {
      return this._displayValue || (this.Values?.join(" - ") ?? "");
   }

   set Value(value: FilterValueType | null) {
      if (value === undefined) {
         errorInDevMode(`Invalid filter value! {undefined} not allowed.`);
         return;
      }

      if (value == null) {
         this._values = null;
      } else {
         let convertedValue = this.processReplacementString(value);
         convertedValue = FilterHelper.verifyAndConvertValueForDataType(value, this.DataType);
         this._values = [convertedValue];
      }
   }

   get Value(): FilterValueType | null {
      if (!this._values) {
         return null;
      }

      return this._values[0];
   }

   set Values(values: Array<FilterValueType> | null) {
      if (!FilterHelper.validFilterValueArray(values)) {
         this._values = null;
         return;
      }

      if (values == null) {
         this._values = null;
      } else {
         this._values = values.map((value) => {
            let convertedValue = this.processReplacementString(value);
            convertedValue = FilterHelper.verifyAndConvertValueForDataType(convertedValue, this.DataType);
            return convertedValue;
         });
      }
   }

   get Values(): Array<FilterValueType> | null {
      return this._values;
   }

   static GetOperationFromOperationString(operation: string) {
      switch (operation) {
         case "lt":
            return FilterOperations.LessThan;
         case "ltn":
            return FilterOperations.LessThanOrNull;
         case "le":
            return FilterOperations.LessThanOrEqualTo;
         case "len":
            return FilterOperations.LessThanOrEqualToOrNull;
         case "gt":
            return FilterOperations.GreaterThan;
         case "gtn":
            return FilterOperations.GreaterThanOrNull;
         case "ge":
            return FilterOperations.GreaterThanOrEqualTo;
         case "gen":
            return FilterOperations.GreaterThanOrEqualToOrNull;
         case "ne":
            return FilterOperations.NotEqualTo;
         case "eq":
            return FilterOperations.EqualTo;
         case "in":
            return FilterOperations.In;
         case "ni":
            return FilterOperations.NotIn;
         case "bt":
            return FilterOperations.Between;
         case "ct":
            return FilterOperations.Contains;
         case "sw":
            return FilterOperations.StartsWith;
         case "ew":
            return FilterOperations.EndsWith;
         case "hv":
            return FilterOperations.HasValue;
         default:
            return null;
      }
   }

   static toApiString(filters?: Array<FilterOption | string> | null, uriEncode = true): string | null {
      if (filters == null) {
         return null;
      }

      if (!(filters instanceof Array)) {
         filters = [filters];
      }

      return filters
         .map((filter) => {
            if (FilterOption.isFilterOption(filter)) {
               return filter.toApiString(uriEncode);
            } else {
               return filter;
            }
         })
         .join(ApiUrlConstants.AndSeparator);
   }

   static isFilterOption(obj: any): obj is FilterOption {
      return (
         obj.FilterName !== undefined &&
         obj.Operation !== undefined &&
         obj.Values !== undefined &&
         obj.toApiString !== undefined
      );
   }

   static FromExpressControlDataSourceFilterModel(model: ExpressControlDataSourceFilterModel) {
      if (!model || !model.PropertyPath || !model.FilterOperation) {
         warnInDevMode("Invalid DataSourceFilterModel: ", model);
      }

      return new FilterOption({ Identifier: model.PropertyPath }, model.FilterOperation, model.Values);
   }

   /** Merge multiple sets of filterOptions into a single array by Name using the first FilterOption of a given name wins
    * approach and any additional filterOptions with the same name are ignored.
    *
    * See also: MergeByUniqueApiString
    *
    * NOTE: this could potentially lead to incorrect results, if for example you were filtering on Status
    * and the first filterOption was Status,in,(1,2,4) and the second filterOption was Status,eq,2,
    * or Status,in,(2).  This method would only return the first filter, and therefore return Past,Current and Future
    * tenants, whereas if the second filterOption was also used, only Current tenants would be returned, because
    * the ANDed intersection of the 2 filters would be Status,eq,2.
    *
    * Consider depracating in favor of MergeByUniqueApiString, which addresses the cases noted above.
    */
   static Merge(...filterGroups: FilterOption[][]) {
      if (!filterGroups) {
         return null;
      }
      return filterGroups.reduce((filters: FilterOption[], filterGroup: FilterOption[]) => {
         if (!filterGroup) {
            return filters;
         }
         return filterGroup.reduce((mergedFilters: FilterOption[], filter: FilterOption) => {
            if (!mergedFilters.find((f) => f.FilterName === filter.FilterName)) {
               mergedFilters.push(filter);
            }
            return mergedFilters;
         }, filters);
      }, []);
   }
   /** Merge multiple sets of filterOptions into a single unique array of filterOptions based on the ApiString for each
    * filterOption. This allows multiple unique filterOptions with the same name to be merged into a single filterOptions
    * array as opposed to the Merge method which only returns the first filterOption of a given name.
    */
   static MergeByUniqueApiString(
      ...filterOptionsOrGroups: Array<Array<FilterOption> | FilterOption | null | undefined>
   ): Array<FilterOption> | null {
      if (!filterOptionsOrGroups.some((f) => f)) {
         // if not at least one FilterOption or array of FilterOptions return null
         return null;
      }
      const mergedFilterResult = filterOptionsOrGroups.reduce(
         (filterOptions: FilterOption[], filterOptionOrGroup: FilterOption[] | FilterOption | null | undefined) => {
            if (!filterOptionOrGroup) {
               return filterOptions;
            }
            if (FilterOption.isFilterOption(filterOptionOrGroup)) {
               filterOptionOrGroup = [filterOptionOrGroup]; // normalize to a FilterOption Group and reduce
            }
            return filterOptionOrGroup.reduce((mergedFilters: FilterOption[], filterOption: FilterOption) => {
               if (!mergedFilters.find((f) => f.toApiString() === filterOption.toApiString())) {
                  // add unique filterOption to list
                  mergedFilters.push(filterOption);
               }
               return mergedFilters;
            }, filterOptions);
         },
         []
      );
      if (FilterOption.isFilterOption(mergedFilterResult)) {
         return [mergedFilterResult];
      } else if (mergedFilterResult) {
         return mergedFilterResult;
      } else {
         return null;
      }
   }

   static validValueTypeForFilter(value: any): boolean {
      switch (value) {
         case typeof value === "string":
         case typeof value === "number":
         case typeof value === "boolean":
         case value instanceof Date: {
            return true;
         }
      }
      return false;
   }

   Label: string;

   /**
    * Typically the property path
    */
   FilterName: string;

   Operation: FilterOperations;

   AssociatedFilterOptions: Array<FilterOption>;

   FilterType: number;

   FilterOperationToDisplay: FilterOperations;

   DataType: ExpressDataTypes;

   SystemSettingFieldID: ExpressSystemSettingField;

   AdditionalData: any;

   private _displayValue = "";

   private _values: Array<FilterValueType> | null;

   constructor(
      field: EntityFilterField | string,
      operation: FilterOperations | null,
      values: Array<FilterValueType> | null,
      label?: string | null,
      displayValue = "",
      filterType?: number | null,
      dataType?: ExpressDataTypes | null,
      userSelectedFilterOperation?: FilterOperations | null,
      systemSettingFieldID?: ExpressSystemSettingField | null
   ) {
      if (values && !Array.isArray(values)) {
         values = [values as FilterValueType];
      }

      if (field && (field as EntityFilterField).Identifier) {
         this.Label = (field as EntityFilterField).Identifier;
         this.FilterName = (field as EntityFilterField).Identifier;
      } else {
         this.FilterName = field as string;
      }
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      this.Label = label;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
      this.FilterType = filterType;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOperations | null' is not assignable t... Remove this comment to see the full error message
      this.Operation = operation;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOperations | null' is not assignable t... Remove this comment to see the full error message
      this.FilterOperationToDisplay = userSelectedFilterOperation || operation;
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ExpressSystemSettingField | null | undefined... Remove this comment to see the full error message
      this.SystemSettingFieldID = systemSettingFieldID;

      this.DisplayValue = displayValue ?? "";

      if (dataType) {
         this.DataType = dataType;
      } else {
         // Currently, the API isn't able to set filterDataType for all filters (like GlobalFilters, or DataSourceFilters)
         // We can remove this (setting of FilterDataTypes.String) when all filters from API are able to set filterDataType and we can enforce the
         // filterDataType parameter.
         this.DataType = ExpressDataTypes.String;
      }
      this.Values = values;
   }

   operationString(): string {
      return ApiUrlConstants.GetOperationString(this.Operation);
   }

   shortOperationLabel(): string {
      switch (this.FilterOperationToDisplay ?? this.Operation) {
         case FilterOperations.LessThan:
            return "<";
         case FilterOperations.LessThanOrNull:
            return "<";
         case FilterOperations.LessThanOrEqualTo:
            return "<=";
         case FilterOperations.LessThanOrEqualToOrNull:
            return "<=";
         case FilterOperations.GreaterThan:
            return ">";
         case FilterOperations.GreaterThanOrNull:
            return ">";
         case FilterOperations.GreaterThanOrEqualTo:
            return ">=";
         case FilterOperations.GreaterThanOrEqualToOrNull:
            return ">=";
         case FilterOperations.NotEqualTo:
            return "!=";
         case FilterOperations.EqualTo:
            return "=";
         case FilterOperations.In:
            return "In";
         case FilterOperations.NotIn:
            return "Not In";
         case FilterOperations.Between:
            return "Between";
         case FilterOperations.Contains:
            return "Contains";
         case FilterOperations.StartsWith:
            return "Starts w/";
         case FilterOperations.EndsWith:
            return "Ends w/";
         case FilterOperations.HasValue:
            return "Has Value";
         default:
            return "Unknown Operation";
      }
   }

   clone(): FilterOption {
      return new FilterOption(
         { Identifier: "" + this.FilterName },
         this.Operation,
         this.Values,
         this.Label,
         this.DisplayValue,
         this.FilterType,
         this.DataType,
         this.FilterOperationToDisplay
      );
   }

   toApiString(uriEncode = true): string {
      let valueString: string =
         this.Values?.map((value: FilterValueType) => {
            if (value instanceof Date) {
               value = value.toJSON();
            }

            let typeOfValue = typeof value;

            if (
               this.DataType === ExpressDataTypes.Numeric ||
               this.DataType === ExpressDataTypes.Boolean ||
               typeOfValue === "number" ||
               typeOfValue === "boolean"
            ) {
               // avoid unnecessarily encoding numbers and booleans which can significantly increase the size of the query string
               // for large numbers of items resulting in errors exceeding the max header length.
               return value;
            } else if (uriEncode) {
               return encodeURIComponent(this.escapeInvalidFilterOptionValueCharacters(value.toString()));
            } else {
               return this.escapeInvalidFilterOptionValueCharacters(value.toString());
            }
         }).join(",") ?? "";

      if (this.Values && this.Values.length > 1) {
         valueString = `(${valueString})`;
      }

      // OWA Vacancy report breaks if the AmenityType enum is not manually replaced here.
      // AmenityType enum is not converted to it's value or recognized as valid by the Gateway
      if (this.FilterName === "AmenityType") {
         valueString = valueString.replace(/unit/gi, "4");
      }

      let apiString = "";
      if (this.operationString() === "ne" || this.operationString() === "ni") {
         if (this.FilterName === "ProspectColorID") {
            apiString = `${this.FilterName},${this.operationString()},${valueString}|ProspectColor,hv, false`;
         } else if (this.FilterName === "ColorID") {
            apiString = `${this.FilterName},${this.operationString()},${valueString}|Color,hv,false`;
         } else {
            apiString = `${this.FilterName},${this.operationString()},${valueString}`;
         }
      } else if (this.operationString() !== "ne") {
         if (this.FilterName === "CurrentUnitStatus") {
            apiString = `(${
               this.FilterName
            },${this.operationString()},${valueString}|CurrentUnitStatus.UnitStatusTypeID,ne,1);Property.IsActive,eq, true`;
         } else {
            apiString = `${this.FilterName},${this.operationString()},${valueString}`;
         }
      }

      if (this.AssociatedFilterOptions && this.AssociatedFilterOptions.length) {
         const associatedFilterStrings = this.AssociatedFilterOptions.map((filter: FilterOption) =>
            filter.toApiString(uriEncode)
         ).join(ApiUrlConstants.AndSeparator);
         return `${apiString};${associatedFilterStrings}`;
      } else {
         return apiString;
      }
   }

   toTransferModel(): FilterOptionTransferModel {
      const filterOptionTransferModel = new FilterOptionTransferModel();
      filterOptionTransferModel.Field = this.FilterName;
      filterOptionTransferModel.FilterOperation = this.Operation;
      filterOptionTransferModel.Values = this._values?.map((v) => v.toString()) ?? [];
      return filterOptionTransferModel;
   }

   validateValueForFilter(dataType: FilterDataTypes, value: string): boolean {
      if (
         dataType === FilterDataTypes.Int ||
         dataType === FilterDataTypes.PrimaryKey ||
         dataType === FilterDataTypes.Decimal
      ) {
         if (isNaN(+value)) {
            return false;
         }
      }
      return true;
   }

   private processReplacementString(value: FilterValueType) {
      if (value === "RMToday") {
         value = formatDate(new Date().toString(), "MM/dd/yyyy", GlobalsService.locale);
      }
      return value;
   }

   private escapeInvalidFilterOptionValueCharacters(value: string): string {
      if (value === "") {
         return '""';
      }
      const backlash = "\\";

      // eslint-disable-next-line @typescript-eslint/quotes
      const doubleQuote = '"';

      // mySQL formatting - mysql needs 4 for every 1
      value = value.replace(/\\/g, backlash + backlash + backlash + backlash);

      if (value.indexOf("'") === -1 && value.indexOf('"') !== -1) {
         value = value.replace(/\"/g, backlash + doubleQuote);
         value = `'${value}'`;
      } else if (value.indexOf("'") !== -1 && value.indexOf('"') !== -1) {
         return '""';
      } else {
         value = `"${value}"`;
      }

      return value;
   }
}
