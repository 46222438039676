import { HttpResponse } from "@angular/common/http";
import { Component, OnDestroy } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ReportGetOptions } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/entity-report-get-options/report.report-get-options";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { Report } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report.enum";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { ReportParameterValueModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/report-parameter-value.model";
import { RunReportModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/run-report.model";
import { ReportsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/reports.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { EstimateDetailService } from "../estimate-detail.service";

@Component({
   selector: "owa-estimate-print",
   templateUrl: "estimate-print.component.html",
})
export class EstimatePrintComponent implements OnDestroy {
   estimate: Observable<EstimateViewModel>;
   isLoading = new BehaviorSubject<boolean>(true);
   reportURL: string = "";

   private unsubscribe = new Subject<void>();

   constructor(private apiService: ApiService, private estimateDetailService: EstimateDetailService) {
      estimateDetailService.estimate.subscribe((estimate) => {
         const rrm = new RunReportModel();
         const v = new ReportParameterValueModel();

         v.Parameter = ReportParameter.EstimateID;
         v.Value = estimate.EstimateID.toString();

         rrm.CustomFormID = estimate.EstimateCustomFormID;
         rrm.Parameters = new Array<ReportParameterValueModel>();
         rrm.Parameters.push(v);

         this.postRunReport(Report.Estimate, rrm, new Array<string>("ReturnPDFUrl")).subscribe((report) => {
            this.reportURL = report.body;
            this.isLoading.next(false);
         });
      });
   }

   ngOnDestroy(): void {
      this.estimateDetailService.showPrintIcon.next(true);
      this.unsubscribe.next();
   }

   postRunReportUrl(
      id: Report,
      getOptions?: Array<string | ReportGetOptions>,
      additionalParameters?: Array<string>
   ): string {
      if (!additionalParameters) {
         additionalParameters = new Array<string>();
      }
      if (getOptions !== undefined && getOptions !== null && getOptions.length > 0) {
         additionalParameters.push("getOptions=" + getOptions);
      }
      return this.apiService.postUrl(
         ReportsService.endpoint + "/RunCustomForm",
         id,
         null,
         null,
         null,
         additionalParameters
      );
   }

   postRunReport(
      id: Report,
      runReportModel?: RunReportModel,
      getOptions?: Array<string | ReportGetOptions>,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.post(this.postRunReportUrl(id, getOptions, additionalParameters), runReportModel);
   }
}
