export class EntityField {
   Identifier: string;

   constructor(identifer: string) {
      this.Identifier = identifer;
   }

   toString(): string {
      return this.Identifier;
   }

   static toApiString(fields?: Array<string | EntityField> | null): string | null {
      if (fields == null) {
         return "";
      }

      if (!(fields instanceof Array)) {
         fields = [fields];
      }

      return fields.join(",");
   }
}
