import { Component, OnInit } from "@angular/core";
import { DialogConfigurationModel } from "@lcs/dialog/dialog.configuration.model";
import { DialogCreationService } from "@lcs/dialog/services/dialog-creation.service";
import { OwnerHistoryNotesModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/owner-history-notes.model";
import { HistoryService } from "projects/libraries/owa-gateway-sdk/src/lib/services/history.service";
import { Observable, Subject, takeUntil } from "rxjs";

import { FileCarouselComponent } from "../../file-carousel/file-carousel.component";
import { FileCarouselConfigurationModel } from "../../file-carousel/models/file-carousel-configuration.model";
import { FileCarouselModel } from "../../file-carousel/models/file-carousel.model";
import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-dashboard-notes",
   templateUrl: "./dashboard-tile-history.component.html",
   providers: [HistoryService],
})
export class DashboardNotesComponent implements OnInit {
   observableHistory: Observable<Array<OwnerHistoryNotesModel>>;
   history: Array<OwnerHistoryNotesModel>;
   notificationCount: number;
   private unsubscribe = new Subject<void>();
   images: Map<number, Array<FileCarouselModel>>;
   hasPinnedNotes: boolean;
   hasThemePinnedNotes: boolean;
   columns: string[] = ["HistoryDate", "Note", "File", "NewRow"];
   constructor(
      private historyService: HistoryService,
      private dialogCreationService: DialogCreationService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      this.history = new Array<OwnerHistoryNotesModel>();
      this.images = new Map<number, Array<FileCarouselModel>>();
   }

   ngOnInit() {
      this.observableHistory = this.historyService.getHistoryForDashboard();
      this.observableHistory.subscribe((res) => {
         this.history = res;
         if (this.history != null && this.history.length > 0) {
            const newNotes = this.history.filter((i) => i.IsNew);
            this.notificationCount = newNotes != null ? newNotes.length : 0;
            const historyWithFile = this.history.filter(
               (historyItem) => historyItem.HasFile && historyItem.Files !== undefined && historyItem.Files.length > 0
            );

            if (historyWithFile.length > 0) {
               const imageMap = new Map<number, Array<FileCarouselModel>>();
               const imageExtensions = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tif", ".tiff"];
               historyWithFile.forEach(function (historyItem) {
                  const imageList = new Array<FileCarouselModel>();
                  historyItem.Files.forEach(function (file) {
                     const image = new FileCarouselModel();
                     file.IsImage = image.isImage = imageExtensions.some(function (element) {
                        return element === file.Extension.toLowerCase();
                     });
                     image.source = image.fileLink = file.DownloadURL;
                     image.extension = file.Extension;
                     image.alt = "No preview available";
                     image.name = file.Name;
                     imageList.push(image);
                  });
                  imageMap.set(historyItem.HistoryID, imageList);
               });
               this.images = imageMap;
            }
            if (this.history.find((r) => r.IsPinned)) {
               this.hasPinnedNotes = true;
               this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(
                  takeUntil(this.unsubscribe)
               ).subscribe((prefs) => {
                  if (prefs != null) {
                     var themePref = prefs.find((p) => p.Name === "OWAToolbarColor");
                     var theme = prefs.find((p) => p.Name === "OWAThemeColor");
                     if (
                        (themePref != null && themePref.Value != "#F28228") ||
                        (theme != null && theme.Value !== "4")
                     ) {
                        this.hasThemePinnedNotes = true;
                     }
                  }
               });
            }
         }
      });
   }

   openCarousel(historyID: number, note: string) {
      const dialogConfigModel = new DialogConfigurationModel();
      dialogConfigModel.componentInstance = FileCarouselComponent;
      dialogConfigModel.disableClose = false;
      dialogConfigModel.title = note;

      const fileConfigModel = new FileCarouselConfigurationModel();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FileCarouselModel[] | undefined' is not assi... Remove this comment to see the full error message
      fileConfigModel.FileSource = this.images.get(historyID);

      dialogConfigModel.styleClass = fileConfigModel.FileSource.every(function (value, index) {
         return value.isImage;
      })
         ? "image-carousel"
         : "file-carousel";
      this.dialogCreationService.open(dialogConfigModel, fileConfigModel);
   }
}
