<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>
   <div>
      <label>NOTE: Do not refresh this page or you will be redirected to start over.</label>
   </div>
   <div class="flex">
      <div class=" flex-half">
         <h5 class="form-header">Payment Information</h5>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Amount</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.Amount | currency }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Convenience Fee</div>
            <div class=" flex-half">{{
               (ownerContributionPaymentModel.ConvenienceFee === null
               ? 0
               : ownerContributionPaymentModel.ConvenienceFee) | currency
               }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Total</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.Total | currency }}</div>
         </div>
      </div>
      <div class=" flex-half">
         <h5 class="form-header flex-space-between"> Billing Information </h5>
         <div class="div-text flex flex-full">
            <div class=" flex-half">First Name</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.BillingNameFirst }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Last Name</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.BillingNameLast }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Street 1</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.Street1 }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Street 2</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.Street2 }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">City</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.City }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">State</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.State }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">Postal Code</div>
            <div class=" flex-half">{{ ownerContributionPaymentModel.EpayInfo.PostalCode }}</div>
         </div>
      </div>
      <div class=" flex-full">
         <h5 class="form-header flex-space-between"> Account Information </h5>
         <div class="div-text flex flex-full">
            <div class=" flex-half">{{typeLabel}} Type</div>
            <div class=" flex-half">{{ paymentMethod }} {{ cardType }}</div>
         </div>
         <div class="div-text flex flex-full">
            <div class=" flex-half">{{typeLabel}} Number</div>
            <div class=" flex-half">{{ accountNumber }}</div>
         </div>
         <div
            *ngIf="isShowExpirationMonth"
            class="div-text flex flex-full"
         >
            <div class=" flex-half">Expiration Month</div>
            <div class=" flex-half">{{ showDateYear }}</div>
         </div>
      </div>
   </div>
   <div>
      <div *ngIf="ownerContributionPaymentModel.IsDisclaimerRequired">
         <lcs-checkbox
            [name]="'ownerContributionPaymentModel.IsDisclaimerMessage'"
            [ngModel]="checked"
            (ngModelChange)="allSelected($event)"
         >{{ ownerContributionPaymentModel.DisclaimerMessage }}</lcs-checkbox>
      </div>
      <div lcsFooter>
         <lcs-progress-button
            class="contribution-button"
            [(status)]="buttonStatus"
            [raised]="true"
         >Make Contribution</lcs-progress-button>
         <a
            class="zego-link"
            target="_blank"
            [href]="constantsService.ZegoPrivacyPage"
         > Zego Privacy Policy </a>
      </div>
   </div>
</form>