import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { AuthenticationStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-status.service";
import { AuthenticationStep } from "projects/libraries/owa-gateway-sdk/src/lib/authentication/authentication-step.enum";
import { WorkflowSteps } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/workflowsteps.enum";
import { WebUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/web-user.model";
import { SystemWebPreferenceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/system-web-preference.model";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, catchError, Observable, of, Subject, takeUntil, throwError } from "rxjs";

import { OWASessionService } from "../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { MenuService } from "../top-bar/menu/menu.service";
import { TopBarService } from "../top-bar/top-bar.service";
import { LocationsQueryStringValuesService } from "./locations-query-string-values.service";
import { OwaAuthenticationModel } from "./models/owa-authentication.model";

@Injectable()
export class AuthenticationService implements OnDestroy {
   returnURL: string;

   loginResults = new Subject<HttpResponse<WebUserModel>>();

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   authenticationResult = new BehaviorSubject<boolean>(null);

   loginResultsForTopBar = new BehaviorSubject<boolean>(false);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   locationID = new BehaviorSubject<number>(null);

   // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
   webUserID = new BehaviorSubject<number>(null);

   locationfromUrl = new BehaviorSubject<string>("");

   private unsubscribe = new Subject<void>();

   private sourceStep: string;

   private model: WebUserModel;

   constructor(
      private httpClient: HttpClient,
      private apiRequestHelperService: ApiRequestHelperService,
      private router: Router,
      private topBarService: TopBarService,
      private sessionCheckService: SessionCheckService,
      private sessionStatusService: SessionStatusService,
      private menuService: MenuService,
      private owaSessionInfoService: OWASessionService,
      private locationsQueryStringValuesService: LocationsQueryStringValuesService,
      private webpreferenceSessionService: SystemWebPreferencesSessionService,
      private authenticationStatusService: AuthenticationStatusService,
      private cacheMonitorService: CacheMonitorService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {}

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   public logIn(auth: OwaAuthenticationModel, preventRedirect?: boolean) {
      this.httpClient
         .post<WebUserModel>(this.apiRequestHelperService.url + "authentication/Authenticate", JSON.stringify(auth), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
            observe: "response",
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (success) => {
               if (success) {
                  this.loginResults.next(success);
                  if (success.ok) {
                     this.authenticationResult.next(true);
                  }
                  this.locationfromUrl.next(auth.LocationId.toString());
                  // @ts-ignore ts-migrate(2322) FIXME: Type 'WebUserModel | null' is not assignable to ty... Remove this comment to see the full error message
                  this.model = success.body;
                  if (
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     success.body.IsVerifiedEmail &&
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     success.body.UserNameIsVerified &&
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     !success.body.IsPasswordReset
                  ) {
                     this.sessionCheckService.checkSessionAsync(false);
                     if (!preventRedirect) {
                        this.redirectWithLocation(auth);
                     }
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                  } else if (success.body.IsPasswordReset) {
                     this.updateLocationID(auth.LocationId);
                     this.webUserID.next(this.model.WebUserID);
                     this.router.navigate(["password/reset/forced"]);
                  } else if (
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     !success.body.IsVerifiedEmail &&
                     // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                     !success.body.UserNameIsVerified
                  ) {
                     this.authenticationResult.next(false);
                     this.router.navigateByUrl("/resendverification");
                  } else {
                     this.loginResultsForTopBar.next(true);
                     this.topBarService.topBarIsVisible.next(false);
                     this.sourceStep = WorkflowSteps[WorkflowSteps.Login];
                     this.updateLocationID(auth.LocationId);
                     this.webUserID.next(this.model.WebUserID);
                     this.router.navigate(["/changeemailaddress"], { queryParams: { sourceStep: this.sourceStep } });
                  }
               }
            },
            (_err) => {
               this.loginResults.next(_err);
            }
         );
   }

   public logOut() {
      this.sessionStatusService.clearSession();
      this.cacheMonitorService.clear();
      localStorage.clear();
      this.httpClient
         .post(this.apiRequestHelperService.url + "authentication/Logout", JSON.stringify(""), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            () => {
               this.locationsQueryStringValuesService.redirectWithLocationInformation();
               this.authenticationStatusService.updateAuthenticationStatus(AuthenticationStep.LoggingOut);
            },
            (_err) => {
               this.loginResults.error(_err);
               this.router.navigate(["/login"]);
               this.authenticationStatusService.updateAuthenticationStatus(AuthenticationStep.LoggingOut);
            }
         );
   }

   public switchAccount(webUserAccountId: number) {
      this.httpClient
         .post<WebUserModel>(
            this.apiRequestHelperService.url + "authentication/SwitchAccount?webUserAccountID=" + webUserAccountId,
            JSON.stringify(""),
            {
               headers: this.apiRequestHelperService.initialHeaders,
               withCredentials: true,
               observe: "response",
            }
         )
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            (response) => {
               this.loginResults.next(response);
               const sessionStatus = this.sessionStatusService.currentSessionStatus;
               // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
               sessionStatus.UserID = webUserAccountId;
               // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'ExpressSessionStatusModel | null... Remove this comment to see the full error message
               this.sessionStatusService.updateSession(sessionStatus);
               this.owaSessionInfoService.clearCache(true);
               this.owaSessionInfoService.populateCache();
               this.menuService.clearCache(true);
               this.menuService.populateCache();
               this.webpreferenceSessionService.clearCache(true);
               this.webpreferenceSessionService.populateCache();
               this.router.navigate(["/switchaccountloading"]).then((a) => this.router.navigate(["/dashboard"]));
            },
            (_err) => {
               this.loginResults.next(_err);
               this.router.navigate(["/login"]);
            }
         );
   }

   public logOutWithoutNavigation() {
      this.sessionStatusService.clearSession();
      localStorage.clear();
      this.httpClient
         .post(this.apiRequestHelperService.url + "authentication/Logout", JSON.stringify(""), {
            headers: this.apiRequestHelperService.initialHeaders,
            withCredentials: true,
         })
         .pipe(takeUntil(this.unsubscribe))
         .subscribe(
            () => {
               // this.router.navigate(["/login"]);
            },
            (_err) => {
               this.loginResults.next(_err);
               this.router.navigate(["/login"]);
            }
         );
   }

   public getWebUser(authModel: OwaAuthenticationModel): Observable<WebUserModel> {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<WebUserModel | null>' is not assi... Remove this comment to see the full error message
      return this.httpClient
         .post<WebUserModel>(
            this.apiRequestHelperService.url + "authentication/GetWebUser",
            JSON.stringify(authModel),
            {
               headers: this.apiRequestHelperService.initialHeaders,
               withCredentials: true,
            }
         )
         .pipe(
            catchError((err: HttpErrorResponse) => {
               if (err.status === 404) {
                  return of(null);
               } else {
                  return throwError(err);
               }
            })
         );
   }

   public reInitializeWebUser(webuserModel: WebUserModel): Observable<WebUserModel> {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'Observable<WebUserModel | null>' is not assi... Remove this comment to see the full error message
      return this.httpClient
         .post<WebUserModel>(
            this.apiRequestHelperService.url + "authentication/ReInitializeWebUser",
            JSON.stringify(webuserModel),
            {
               headers: this.apiRequestHelperService.initialHeaders,
               withCredentials: true,
            }
         )
         .pipe(
            catchError((err: HttpErrorResponse) => {
               if (err.status === 404) {
                  return of(null);
               } else {
                  return throwError(err);
               }
            })
         );
   }

   public redirectWithLocation(auth: OwaAuthenticationModel) {
      if (!this.returnURL) {
         this.router.navigate(["/dashboard"], {
            queryParams: { locations: auth.LocationId },
         });
      } else {
         this.router.navigate([this.returnURL]);
      }
   }

   public redirect() {
      if (!this.returnURL) {
         this.router.navigate(["/dashboard"]);
      } else {
         this.router.navigate([this.returnURL]);
      }
   }

   public setTheme(prefs: Array<SystemWebPreferenceModel>) {
      const val = document.getElementsByTagName("body")[0];
      var buttonColor = prefs.find((a) => a.Name == "OWAButtonsAndAccentColor");
      var textButtonAccentcolor = "";
      var themeColor = "";
      var filterColor = "";
      if (buttonColor != null && buttonColor.Value != "#F28228") {
         var buttondarken5 = this.hex2rgba(buttonColor.Value, 0.8);
         themeColor =
            "--owa-theme-button-accent-color:" +
            buttonColor.Value +
            ";--owa-theme-button-accent-color-darken-5:" +
            buttondarken5;
         textButtonAccentcolor = this.systemWebPreferenceSessionService.getContrastColor(buttonColor.Value, true);
         themeColor += ";--owa-theme-text-button-accent-contrast-color:" + textButtonAccentcolor;
      }

      var toolbarColor = prefs.find((a) => a.Name === "OWAToolbarColor");
      var themeColorValue = prefs.find((a) => a.Name === "OWAThemeColor");

      if (
         (toolbarColor != null && toolbarColor.Value !== "#F28228") ||
         (themeColorValue != null && themeColorValue.Value !== "4")
      ) {
         var color = toolbarColor?.Value;
         themeColor = themeColor != null ? themeColor + ";--owa-theme-color:" + color : "--owa-theme-color:" + color;

         var darkenColor = this.hex2rgba(color, 0.8);
         var darkencolor10 = this.hex2rgba(color, 0.55);

         var textColor = "#fff";
         if (color != null && color !== "#F28228") {
            textColor = this.systemWebPreferenceSessionService.getContrastColor(color, true);
         }
         if (buttonColor != null) {
            filterColor = this.systemWebPreferenceSessionService.getContrastColor(buttonColor?.Value, false);
         }

         //  var iconColorLighten5 = this.hex2rgba("#000", 1.25);
         var iconColorLighten5 = this.hex2rgba(filterColor, 1.25);

         themeColor =
            themeColor +
            ";--owa-theme-color-darken-5:" +
            darkenColor +
            ";--owa-theme-color-darken-10:" +
            darkencolor10 +
            ";--owa-theme-text-contrast-color:" +
            textColor +
            ";--primary-icons:" +
            filterColor +
            ";--primary-icons-lighten-5:" +
            iconColorLighten5 +
            ";--filter-text:" +
            "$black";
      }
      val.style.cssText = themeColor;
   }

   public hex2rgba(hex, percent = 0.75): string {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      const rgbr = Number(r * percent);
      const rgbg = Number(g * percent);
      const rgbb = Number(b * percent);
      return `rgba(${rgbr},${rgbg},${rgbb})`;
   }

   private updateLocationID(locationID: number) {
      this.locationID.next(locationID);
      this.locationsQueryStringValuesService.LocationIDValue = locationID;
   }
}
