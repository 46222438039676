import { Injectable, OnDestroy } from "@angular/core";
import { EntityEventService } from "@lcs/entity-events/entity-event.service";
import { UniqueIDService } from "@lcs/unique-ids/unique-id.service";
import { ExpressEntityEvents } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-entity-events.enum";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";

import { DialogComponent } from "../dialog.component";
import { DialogRegistrationService } from "./dialog-registration.service";

@Injectable({
   providedIn: "root",
})
export class CurrentDialogService implements OnDestroy {
   closeOnCancel = true;

   dialogClosed: Observable<boolean>;

   private _dialogClosed = new BehaviorSubject<boolean>(false);

   private dialogID: string;

   private registered: boolean;

   private dialog: DialogComponent;

   private unsubscribe = new Subject<void>();

   constructor(
      private dialogRegistrationService: DialogRegistrationService,
      private uniqueIdService: UniqueIDService,
      private entityEventService: EntityEventService
   ) {
      this.dialogClosed = this._dialogClosed.asObservable();
   }

   initialize(dialogComponent: DialogComponent) {
      this.dialog = dialogComponent;
      this.dialogID = `dialog${this.uniqueIdService.getUniqueID()}`;
      this.dialogRegistrationService.register(this.dialogID, dialogComponent);
      this.registered = true;

      this.entityEventService.entityEvents.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
         if (event === ExpressEntityEvents.Cancel && this.closeOnCancel) {
            this.closeDialog();
         }
      });
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      this.closeDialog(true);
   }

   setTitle(title: string) {
      this.dialog.title = title;
   }

   setCloseCallbackOverride(callback: (() => void) | null) {
      if (this.dialog) {
         this.dialog.closeCallbackOverride = callback;
      }
   }

   closeDialog(ignoreCloseCallbackOverride?: boolean) {
      this._dialogClosed.next(true);
      if (this.registered) {
         this.dialogRegistrationService.deregister(this.dialogID);
         this.registered = false;
      }
      this.dialog.closeDialog(ignoreCloseCallbackOverride);
   }
}
