import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
   AbstractControl,
   NgControl,
   UntypedFormControl,
   ValidationErrors,
   Validator,
   ValidatorFn,
   Validators,
} from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { PhoneService } from "@lcs/inputs/phone-number/phone.service";

import { PhoneNumberValidationService } from "../validation/phone-number-validation.service";

@Component({
   selector: "owa-phone-number",
   templateUrl: "phone-number.component.html",
})
export class PhoneNumberComponent extends ValueAccessorBase<string> implements OnInit, Validator {
   private validator: ValidatorFn;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      private phoneService: PhoneService,
      public ngControl: NgControl,
      private phoneNumberValidationService: PhoneNumberValidationService
   ) {
      super(changeDetectorRef, ngControl);
      this.registerOnValueWritten((value: string) => {
         if (value) {
            this.innerValue = this.phoneService.maskPhoneNumber(value);
         }
      });
   }

   ngOnInit() {
      this.validator = (control: AbstractControl) => {
         const validatorFunction = this.phoneNumberValidationService.getPhoneNumberValidator();
         return validatorFunction(control);
      };
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.ngControl.control.setValidators(Validators.compose([this.ngControl.validator, this.validator]));
      return super.ngOnInit();
   }

   onBlur() {
      if (this.innerValue) {
         this.innerValue = this.phoneService.maskPhoneNumber(this.innerValue.trim());
         this.propagateChanged();
      }
      this.propagateTouched();
   }

   validate(control: UntypedFormControl): ValidationErrors {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
      return this.validator(control);
   }

   registerOnValidatorChange(_: () => void): void {
      // no-op
   }
}
