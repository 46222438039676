import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { LocationsQueryStringValuesService } from "../authentication/locations-query-string-values.service";
import { OWASessionModel } from "./models/owa-session.model";

@Injectable({
   providedIn: "root",
})
export class OWASessionService implements SessionCacheProvider, OnDestroy {
   cacheKey = "OWASessionInfo";

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject(false);

   // @ts-ignore ts-migrate(2322) FIXME: Type 'BehaviorSubject<null>' is not assignable to ... Remove this comment to see the full error message
   OWASessionInfo: BehaviorSubject<OWASessionModel> = new BehaviorSubject(null);

   private unsubscribe = new Subject<void>();

   public get currentOWASessionInfo(): OWASessionModel {
      return this.OWASessionInfo.value;
   }

   constructor(
      private apiService: ApiService,
      private cacheMonitorService: CacheMonitorService,
      private locationsQueryStringValuesService: LocationsQueryStringValuesService
   ) {
      this.cacheMonitorService.setServiceCacheState(this.cacheKey, false);
      this.cachePopulated.next(false);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         if (!this.cachePopulated.value) {
            this.populateCache();
         }
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.clearCache(true));
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.OWASessionInfo.next(null);
      this.cachePopulated.next(false);
      this.cacheMonitorService.setServiceCacheState(this.cacheKey, false);
      if (sessionExpired) {
         localStorage.removeItem(this.cacheKey);
      }
   }

   public populateCache() {
      const storedValue = localStorage.getItem(this.cacheKey);
      if (storedValue) {
         this.OWASessionInfo.next(JSON.parse(storedValue));
         this.cachePopulated.next(true);
         this.cacheMonitorService.setServiceCacheState(this.cacheKey, true);
      } else {
         this.apiService
            .directGet(this.apiService.getUrl("SessionInformation/SessionCache"))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((owaSessionInfo) => {
               if (owaSessionInfo) {
                  const session: OWASessionModel = owaSessionInfo.body;
                  try {
                     localStorage.setItem(this.cacheKey, JSON.stringify(session));
                  } catch {
                     // ignore
                  }
                  this.OWASessionInfo.next(session);
                  this.locationsQueryStringValuesService.LocationIDValue = session.LocationID;
                  this.cachePopulated.next(true);
                  this.cacheMonitorService.setServiceCacheState(this.cacheKey, true);
               }
            });
      }
   }
}
