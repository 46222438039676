import { Component, OnInit } from "@angular/core";
import { DialogContentComponent } from "@lcs/dialog/dialog-content-component.interface";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { VendorModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/vendor.model";

import { VendorConfigurationModel } from "./vendor-configuration.model";

@Component({
   selector: "owa-vendor-detail",
   templateUrl: "./vendor-detail.component.html",
   styleUrls: ["./vendor-detail.component.css"],
})
export class VendorDetailComponent implements OnInit, DialogContentComponent {
   public configuration = new VendorConfigurationModel();
   public vendor: VendorModel;

   constructor(private currentDialogService: CurrentDialogService) {}

   ngOnInit() {
      this.vendor = this.configuration.vendor;
   }

   onOkClick(): void {
      this.currentDialogService.closeDialog();
   }
}
