import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import isEqual from "lodash/isEqual";

import { ControlContainerViewProvider } from "../control-container-view-providers";
import { SliderConfigurationModel } from "./slider-configuration.model";

@Component({
   selector: "lcs-slider",
   templateUrl: "slider.component.html",
   viewProviders: [ControlContainerViewProvider],
})
export class SliderComponent extends ValueAccessorBase<number> implements OnInit {
   @Input() configuration: SliderConfigurationModel;

   @Input() disabled: boolean;

   public get sliderValue(): number {
      return this.value;
   }

   public tickInterval: "auto" | number = 1;

   public step: number = 1;

   constructor(protected changeDetectorRef: ChangeDetectorRef, public ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
      this.configuration = new SliderConfigurationModel();
   }

   ngOnInit() {
      if (this.configuration.tickInterval) {
         this.tickInterval = this.configuration.tickInterval;
      }
      if (this.configuration.step) {
         this.step = this.configuration.step;
      }
      return super.ngOnInit();
   }

   valueChanged(val: number) {
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (!val || val < this.configuration.min) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
         val = this.configuration.min;
         // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      } else if (val > this.configuration.max) {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
         val = this.configuration.max;
      } else {
         val = val;
      }
      if (!isEqual(val, this.value)) {
         this.value = val;
      }
   }
}
