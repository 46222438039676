<form
   #form="ngForm"
   (ngSubmit)="onSubmit(form)"
>

   <div class="linked-accounts-wrapper">
      <div class="linked-accounts">
         <lcs-table [dataSource]="availableAccounts">
            <tr
               lcs-header-row
               *lcsHeaderRowDef="columns;"
            ></tr>
            <tr
               lcs-row
               *lcsRowDef="let row; columns: columns"
            ></tr>

            <ng-container
               lcsColumnDef="Name"
               [width]="12"
               [priority]="1"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef="let row"
               >Name</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >{{
                  row.AccountType == accountTypeEnum.Owner ? row.Owner?.Name : row.OwnerProspect?.Name
                  }}</td>
            </ng-container>

            <ng-container
               lcsColumnDef="Nickname"
               [width]="20"
               [priority]="2"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Nickname *</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
               >
                  <lcs-text-box
                     [(ngModel)]="row.Nickname"
                     name="nickname:{{
                        row.AccountType == accountTypeEnum.Owner
                           ? row.Owner?.OwnerID
                           : row.OwnerProspect?.OwnerProspectID
                     }}"
                     displayName="Nickname"
                     [required]="true"
                     maxlength="50"
                  >
                  </lcs-text-box>
               </td>
            </ng-container>

            <ng-container
               lcsColumnDef="AccountID"
               [width]="8"
               [priority]="3"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Account#</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index=index"
                  class="numeric"
               >{{row.AccountID}}</td>
            </ng-container>

            <ng-container
               lcsColumnDef="Default"
               [width]="10"
               [priority]="4"
            >
               <th
                  lcs-header-cell
                  *lcsHeaderCellDef
               >Default</th>
               <td
                  lcs-cell
                  *lcsCellDef="let row; let index = index"
                  class="boolean"
               >
                  <lcs-radio-button
                     [(ngModel)]="defaultOwnerID"
                     (ngModelChange)="updateDefault(row, $event)"
                     name="default"
                     [value]="
                        row.AccountType == accountTypeEnum.Owner
                           ? row.Owner?.OwnerID
                           : row.OwnerProspect?.OwnerProspectID
                     "
                  >
                  </lcs-radio-button>
               </td>
            </ng-container>

         </lcs-table>
      </div>

      <div
         lcsFooter
         class="flex align-right saveButton"
      >
         <lcs-progress-button
            [raised]="true"
            [(status)]="saveButtonStatus"
         >Save</lcs-progress-button>
      </div>
   </div>

</form>