import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ManagementFeesDetailViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/management-fee-detail-view.model";
import { ManagementFeeViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/management-fee-view.model";
import { ManagementFeesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/management-fees.service";
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subject, takeUntil } from "rxjs";

import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-management-fees-detail",
   templateUrl: "./management-fees-detail.component.html",
   styleUrls: ["./management-fees-detail.component.css"],
})
export class ManagementFeesDetailComponent implements OnInit {
   @Input() totalCount: number; // TODO: use this

   @Input() managementFee: ManagementFeeViewModel;
   @Input() managementFeeDetail: ManagementFeesDetailViewModel;
   @Input() propertyId: number;
   @Input() propertyName: string;
   public feeTotal: number;

   public obervableManagementFeeDetails: Observable<ManagementFeesDetailViewModel> =
      new Observable<ManagementFeesDetailViewModel>();
   public managementFeeDetails: ManagementFeesDetailViewModel = new ManagementFeesDetailViewModel();

   managementFeesDetailColumns: string[] = [
      "ChargeTypeName",
      "AccountName",
      "TotalAmount",
      "FeePercentage",
      "TotalFeeAmount",
      "SlideOverIcon",
   ];

   mgmtFeeHistoryID: number;

   mgmtFeeArrayData: Array<Array<any>> = new Array<Array<any>>();
   otherFees: Array<any> = new Array<any>();
   otherFeeTotal: number;

   showPassthru: boolean;
   hasPassthruCharges: boolean = false;
   isLoading: BehaviorSubject<boolean>;
   private unsubscribe = new Subject<void>();

   constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private managementFeeServices: ManagementFeesService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService
   ) {
      const params = this.activatedRoute.paramMap.pipe(
         // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
         map((paramMap: ParamMap) => parseInt(paramMap.get("id"))),
         // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
         filter((id: number) => id && !isNaN(id)), // ignore invalid urls for now. TODO: throw an error? re-route to the list view?
         distinctUntilChanged() // ignore duplicates
      );

      params.subscribe((id) => {
         // @ts-ignore (error TS2322) FIXME: Type 'unknown' is not assignable to type 'number'.
         this.mgmtFeeHistoryID = id;
      });

      this.isLoading = new BehaviorSubject<boolean>(false);
   }

   ngOnInit() {
      this.isLoading.next(true);
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.pipe(takeUntil(this.unsubscribe)).subscribe(
         (prefs) => {
            if (prefs != null) {
               if (prefs.find((a) => a.Name === "ShowPassthruFeesOWA") != null) {
                  this.showPassthru =
                     // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                     prefs.find((a) => a.Name === "ShowPassthruFeesOWA").Value.toLocaleLowerCase() === "true"
                        ? true
                        : false;
               } else {
                  this.showPassthru = true;
               }
            }
         }
      );
      this.managementFeeDetails.ChargesBreakdown = new Array();

      this.isLoading = new BehaviorSubject<boolean>(true);
      this.obervableManagementFeeDetails = this.managementFeeServices.getFeesForDetailPage(this.mgmtFeeHistoryID);
      this.obervableManagementFeeDetails.subscribe((res) => {
         this.managementFeeDetails = res;
         this.managementFeeDetails.TotalAmount = 0;
         this.managementFeeDetails.FeeTotal = 0;
         this.managementFeeDetails.TotalPassThruAmount = 0;
         this.managementFeeDetails.TotalPassThruFeeAmount = 0;
         this.otherFeeTotal = 0;
         this.managementFeeDetails.ChargesBreakdown.forEach((element) => {
            if (element.IsPassThru) {
               this.hasPassthruCharges = true;
               this.managementFeeDetails.TotalPassThruAmount += element.TotalAmount;
               this.managementFeeDetails.TotalPassThruFeeAmount += element.FeeAmount;
            } else {
               this.managementFeeDetails.TotalAmount += element.TotalAmount;
               this.managementFeeDetails.FeeTotal += element.FeeAmount;
            }
         });
         this.mgmtFeeArrayData = this.managementFeeDetails.ChargesDetails;
         this.otherFees = this.managementFeeDetails.OtherFees;
         this.otherFees.forEach((element) => {
            this.otherFeeTotal += element.FeeAmount;
         });
         this.isLoading = new BehaviorSubject<boolean>(false);
      });
   }

   public printManagementFee() {
      this.router.navigate(["./managementfeeprint/" + this.mgmtFeeHistoryID]);
   }
}
