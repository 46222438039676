<lcs-table [dataSource]="managementFeeDetailListViewItems">
   <tr lcs-header-row *lcsHeaderRowDef="managementFeeDetailsListColumns"></tr>
   <tr lcs-row *lcsRowDef="let row; columns: managementFeeDetailsListColumns"></tr>
   <ng-container lcsColumnDef="FeeDate" [width]="8" [priority]="1">
      <th lcs-header-cell *lcsHeaderCellDef>Date </th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         [ngStyle]="{ 'font-weight': row.IsTotalRow ? 'bold' : 'normal' }"
         >{{ row.FeeDate }}</td
      >
   </ng-container>
   <ng-container lcsColumnDef="Unit" [width]="10" [priority]="2">
      <th lcs-header-cell *lcsHeaderCellDef>Unit</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.Unit }}</td>
   </ng-container>
   <ng-container lcsColumnDef="Comment" [width]="12" [priority]="3">
      <th lcs-header-cell *lcsHeaderCellDef>Comment</th>
      <td lcs-cell *lcsCellDef="let row; let index = index">{{ row.Comment }}</td>
   </ng-container>
   <ng-container lcsColumnDef="Amount" [width]="10" [priority]="6">
      <th lcs-header-cell *lcsHeaderCellDef>Amount Paid</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="numeric"
         [ngStyle]="{ 'font-weight': row.IsTotalRow ? 'bold' : 'normal' }"
         >{{ row.Amount | currency }}</td
      >
   </ng-container>
   <ng-container lcsColumnDef="FeeAmount" [width]="10" [priority]="7">
      <th lcs-header-cell *lcsHeaderCellDef>Fee Amount</th>
      <td
         lcs-cell
         *lcsCellDef="let row; let index = index"
         class="numeric"
         [ngStyle]="{ 'font-weight': row.IsTotalRow ? 'bold' : 'normal' }"
         >{{ row.FeeAmount | currency }}</td
      >
   </ng-container>
</lcs-table>
