import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { SessionCheckService } from "@lcs/authentication/session-check.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { markFormGroupAsTouchedAndDirty } from "@lcs/utils/form-utils";
import { PasswordResetModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/password-reset.model";
import { PasswordResetService } from "projects/libraries/owa-gateway-sdk/src/lib/services/password-reset.service";

import { AuthenticationService } from "../../authentication/authentication.service";

@Component({
   selector: "owa-password-reset-forced",
   templateUrl: "./password-reset-forced.component.html",
   styleUrls: ["./password-reset-forced.component.css"],
})
export class PasswordResetForcedComponent implements OnInit, OnDestroy {
   public resetModel: PasswordResetModel = new PasswordResetModel();
   errorMessage: string;

   buttonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;

   constructor(
      private passwordResetService: PasswordResetService,
      private errorMessageService: ErrorMessageService,
      private authService: AuthenticationService,
      private router: Router,
      private sessionCheckService: SessionCheckService
   ) {
      this.sessionCheckService.checkSessionOnFocus = false;
   }

   ngOnInit() {
      this.resetModel.IsForceReset = true;
      this.resetModel.LocationID = this.authService.locationID.value;
      this.resetModel.WebUserID = this.authService.webUserID.value;
   }

   onSubmit(form: NgForm): void {
      if (form.valid) {
         this.buttonStatus = LcsProgressButtonStatus.InProgress;
         this.passwordResetService.forcePasswordReset(this.resetModel).subscribe(
            (res) => {
               this.buttonStatus = LcsProgressButtonStatus.Success;
               this.router.navigate(["/login"], {
                  queryParams: { locations: this.resetModel.LocationID, locationID: this.resetModel.LocationID },
               });
            },
            (error) => {
               this.errorMessage = this.errorMessageService.processHttpError(error).Message;
               this.buttonStatus = LcsProgressButtonStatus.Error;
            }
         );
      } else {
         this.buttonStatus = LcsProgressButtonStatus.Warning;
         markFormGroupAsTouchedAndDirty(form.control);
      }
   }

   ngOnDestroy() {
      this.sessionCheckService.checkSessionOnFocus = true;
   }
}
