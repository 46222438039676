<ng-container [ngSwitch]="parameter?.ReportParameterComponent">
   <!-- <owa-charge-type-order *ngSwitchCase="reportParameterComponents.ChargeTypeOrder"></owa-charge-type-order> -->
   <owa-report-parameter-renderer
      *ngSwitchDefault
      [controlName]="controlName"
      [parameter]="parameter"
      [parameterComponents]="parameterComponents"
      [hasAsk]="hasAsk"
      [isRelative]="isRelative"
   >
   </owa-report-parameter-renderer>
<ng-container>