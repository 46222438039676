export class EntityEmbedOption {
   Identifier: string;

   constructor(identifer: string) {
      this.Identifier = identifer;
   }

   toString(): string {
      return this.Identifier;
   }

   static toApiString(embeds: EntityEmbedOption | Array<string | EntityEmbedOption> | null): string | null {
      if (embeds == null) {
         return "";
      }

      if (!(embeds instanceof Array)) {
         embeds = [embeds];
      }

      return embeds.join(",");
   }
}
