<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   >
      {{ parameter?.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="parameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<lcs-single-line-multi-select
   [name]="name"
   [displayName]="displayName"
   [valueSource]="valueSource"
   [(ngModel)]="selectedValues"
   [lcsValidate]="validation"
   [disabled]="disabled || (hasAsk === true && parameter.IsAsk === true)"
   [afterItemsPortal]="portal"
   (afterItemsPortalAttached)="onPortalAttached($event)"
   (selectionChange)="selectionChange = $event"
   (panelClose)="selectionChanged()"
   (allSelected)="allSelected($event)"
></lcs-single-line-multi-select>
<ng-template #inactiveStagesCheckbox>
   <div class="form-element flex-full">
      <lcs-checkbox
         [name]="'inactive-stages-checkbox'"
         [ngModel]="includeInactiveProspectStages"
         (ngModelChange)="onInactiveFilterValue($event)"
      >Inactive Stages</lcs-checkbox>
   </div>
   <mat-divider class="flex-full"></mat-divider>
</ng-template>