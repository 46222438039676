<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && leadSourcesReportParameter.IsAsk === true)"
   >{{ $any(leadSourcesReportParameter?.PromptText?.length) > 0 ? leadSourcesReportParameter.PromptText :
      leadSourcesReportParameter.DisplayName }}
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="leadSourcesReportParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="tab-wrapper">
   <div class="single-tab-wrapper">
      <lcs-dynamic-selector-report-parameter
         [hideLabel]="true"
         [name]="name"
         [disabled]="disabled || (hasAsk === true && leadSourcesReportParameter.IsAsk === true)"
         [parameter]="leadSourcesReportParameter"
         [entityValueSourceFilters]="leadSourcesFilters"
         [selectAllItemsOnFilterChange]="false"
      ></lcs-dynamic-selector-report-parameter>
      <lcs-checkbox-report-parameter
         [name]="name"
         [disabled]="disabled || (hasAsk === true && leadSourcesReportParameter.IsAsk === true)"
         [parameter]="inactiveLeadSourcesReportParameter"
      ></lcs-checkbox-report-parameter>
   </div>
</div>