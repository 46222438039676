import { formatDate } from "@angular/common";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { GlobalsService } from "@lcs/core/globals.service";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";

export class DateValidator {
   static defaultMinDate: Date = new Date(1900, 0, 1, 0, 0, 0, 0);

   static defaultMaxDate: Date = new Date(9999, 11, 31, 0, 0, 0, 0);

   static range([rangeStart, rangeEnd]: [Date, Date], isExclusive: boolean): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }

         let isInRange: boolean;

         const value = control.value.getTime();
         const start = rangeStart.getTime();
         const end = rangeEnd.getTime();

         if (isExclusive) {
            isInRange = value > start && value < end;
         } else {
            isInRange = value >= start && value <= end;
         }

         if (!isInRange) {
            return { range: { start: rangeStart, end: rangeEnd } };
         }

         return null;
      };
   }

   static min(min: Date): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }

         if (min.getTime() > control.value.getTime()) {
            return { minDate: min };
         }

         return null;
      };
   }

   static max(max: Date): ValidatorFn {
      return (control: AbstractControl) => {
         if (!control.value) {
            return null;
         }

         if (max.getTime() < control.value.getTime()) {
            return { maxDate: max };
         }

         return null;
      };
   }

   static whitelist(whitelist: Date[]): ValidatorFn {
      return (control: AbstractControl) => {
         let value = control.value;
         if (value) {
            value = value.getTime();
         }
         const isInWhitelist = whitelist.some((whitelistedValue: Date) => {
            return whitelistedValue.getTime() === value;
         });

         if (!isInWhitelist) {
            const errorDisplay = `[${whitelist
               .map((whitelistedValue: Date) => formatDate(whitelistedValue, "mediumDate", GlobalsService.locale))
               .join(", ")}]`;
            return { whitelist: errorDisplay };
         }

         return null;
      };
   }

   static blacklist(blacklist: Date[]): ValidatorFn {
      return (control: AbstractControl) => {
         let value = control.value;
         if (value) {
            value = value.getTime();
         }
         const isInBlacklist = blacklist.some((blacklistedValue: Date) => {
            return blacklistedValue.getTime() === value;
         });

         if (isInBlacklist) {
            const errorDisplay = `[${blacklist
               .map((blacklistedValue: Date) => formatDate(blacklistedValue, "mediumDate", GlobalsService.locale))
               .join(", ")}]`;
            return { blacklist: errorDisplay };
         }

         return null;
      };
   }

   static getValidators(validation: ValidationModel): ValidatorFn[] {
      const validators = new Array<ValidatorFn>();

      validators.push(DateValidator.min(validation.minDate || DateValidator.defaultMinDate));
      validators.push(DateValidator.max(validation.maxDate || DateValidator.defaultMaxDate));

      if (validation.whitelist && validation.whitelist.length > 0) {
         validators.push(DateValidator.whitelist(validation.whitelist));
      }
      if (validation.blacklist && validation.blacklist.length > 0) {
         validators.push(DateValidator.blacklist(validation.blacklist));
      }
      if (validation.exclusiveRange && validation.exclusiveRange.length > 0) {
         validators.push(DateValidator.range(validation.exclusiveRange, true));
      }
      if (validation.inclusiveRange && validation.inclusiveRange.length > 0) {
         validators.push(DateValidator.range(validation.inclusiveRange, false));
      }

      return validators;
   }
}
