import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
   AbstractControl,
   NgControl,
   UntypedFormControl,
   ValidationErrors,
   Validator,
   ValidatorFn,
   Validators,
} from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { PhoneNumberValidationServiceBase } from "@lcs/inputs/phone-number/phone-number-validation-service.base";
import { valueOrThrow } from "@lcs/utils/strict-null-utils";

import { PhoneService } from "./phone.service";

@Component({
   selector: "lcs-phone-number",
   templateUrl: "phone-number.component.html",
})
export class PhoneNumberComponent extends ValueAccessorBase<string> implements OnInit, Validator {
   @Input() disabled;

   @Input() defaultValue: string;

   private validator: ValidatorFn;

   constructor(
      protected changeDetectorRef: ChangeDetectorRef,
      private phoneService: PhoneService,
      public ngControl: NgControl,
      private phoneNumberValidationService: PhoneNumberValidationServiceBase
   ) {
      super(changeDetectorRef, ngControl);
      if (!this.defaultValue) {
         this.registerOnValueWritten((value: string) => {
            if (this.defaultValue) {
               this.innerValue = this.defaultValue;
            } else if (value) {
               this.innerValue = this.phoneService.maskPhoneNumber(value);
            }
         });
      }
   }

   ngOnInit() {
      this.validator = (controlParam: AbstractControl) => {
         const validatorFunction = this.phoneNumberValidationService.getPhoneNumberValidator();
         return validatorFunction(controlParam);
      };
      const control: AbstractControl = valueOrThrow<AbstractControl>(this.ngControl.control);
      control.setValidators(Validators.compose([this.ngControl.validator, control.validator, this.validator]));
      return super.ngOnInit();
   }

   onBlur() {
      if (this.innerValue) {
         this.innerValue = this.phoneService.maskPhoneNumber(this.innerValue);
      }
      this.propagateTouched();
   }

   validate(control: UntypedFormControl): ValidationErrors {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ValidationErrors | null' is not assignable t... Remove this comment to see the full error message
      return this.validator(control);
   }

   registerOnValidatorChange(_: () => void): void {
      // no-op
   }
}
