<div class="report-parameter-label">
   <label
      class="parameter-label"
      [class.disabled]="disabled || (hasAsk === true && statesParameter.IsAsk === true)"
   >States
   </label>
   <lcs-report-parameter-ask
      *ngIf="hasAsk"
      [isAsk]="statesParameter.IsAsk"
      (askChanged)="askChanged($event)"
      [name]="name"
   ></lcs-report-parameter-ask>
</div>
<div class="individual-report-parameter-option">
   <lcs-single-line-multi-select
      [name]="name"
      [valueSource]="valueSource"
      [ngModel]="selectedValues"
      [lcsValidate]="validation"
      [disabled]="disabled || (hasAsk === true && statesParameter.IsAsk === true)"
      (selectionChange)="selectionChange = $event"
      (panelClose)="selectionChanged()"
   ></lcs-single-line-multi-select>
</div>
<div
   *ngIf="cityParameter"
   class="individual-report-parameter-option"
>
   <lcs-textbox-report-parameter
      [name]="'city-textbox'"
      [disabled]="disabled || isCityDisabled || (hasAsk === true && cityParameter.IsAsk === true)"
      [parameter]="cityParameter"
   ></lcs-textbox-report-parameter>
</div>