<form>
   <owa-rm-report-hub
      #reportHub
      [reportID]="reportID"
      [exportType]="selectedExportType"
      [overriddenReportParameterRenderer]="overriddenReportParameterRenderer"
      (reportExportTypesChanged)="reportExportTypesChanged($event)"
      [isOWAReport]="true"
   ></owa-rm-report-hub>

   <ng-template
      #overriddenReportParameterRenderer
      let-controlName="controlName"
      let-parameter="parameter"
      let-parameterComponents="parameterComponents"
      let-hasAsk="hasAsk"
      let-isRelative="isRelative"
   >
      <owa-report-parameter-override
         [controlName]="controlName"
         [parameter]="parameter"
         [parameterComponents]="parameterComponents"
         [hasAsk]="hasAsk"
         [isRelative]="isRelative"
      >
      </owa-report-parameter-override>
   </ng-template>
   <owa-footer [footerTemplate]="FooterTemplate">
      <ng-template #FooterTemplate>
         <lcs-select
            lcsLegacySingleSelector
            [name]="'output-format'"
            [displayName]="'Output Format'"
            [ngModel]="selectedExportTypeValue"
            [valueSource]="exportTypesForReport"
            (ngModelChange)="reportFormatSelectionChanged($event)"
            [alignOverlayPanelToRight]="true"
            style="padding-right:1em"
         >
         </lcs-select>
         <lcs-progress-button
            [(status)]="runReportButtonStatus"
            [raised]="true"
            (buttonClick)="generateReport()"
         >Run Report</lcs-progress-button>
      </ng-template>
   </owa-footer>
</form>