import { AfterViewInit, Directive, Host, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Self, SimpleChanges } from "@angular/core";
import { EntityChangeService } from "@lcs/entity-events/entity-change.service";
import { EntitySearchService } from "@lcs/entity-search/entity-search.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { FORM_CONTROL_METADATA_ENTITY_ID, FormControlMetadataEntityID } from "@lcs/forms/form-control-metadata/form-control-metadata-entity-id.interface";
import { FORM_CONTROL_METADATA_SELECTORFILTERS, FormControlMetadataSelectorFilters } from "@lcs/forms/form-control-metadata/form-control-metadata-selector-filters.interface";
import { ObjectMapResolverService } from "@lcs/pipes/object-map-resolver.service";
import { SelectComponent } from "@lcs/select/components/select.component";
import { EndpointSelectorDirectiveBase } from "@lcs/select/directives/endpoint-selector.directive.base";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { ValueSourceService } from "@lcs/single-line-multi-select/value-source.service";
import { EntityRequestEndpointInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-information.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { EntitySearchConfigurationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration-service.base";
import { EntitySearchConfigurationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-search-configuration/entity-search-configuration.model";
import { EntityViewInformationServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information-service.base";
import { EntityViewInformationModel } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-view-information/entity-view-information.model";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { EntityField } from "projects/libraries/owa-gateway-sdk/src/lib/entity-request-options/base-options/field";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { SelectorBehavior } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/selector-behavior.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { FilterField } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-field.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { combineLatest, forkJoin, fromEvent, Observable, of, Subject, switchMap, take, takeUntil } from "rxjs";

/* eslint-disable brace-style */
@Directive({
   selector: "[lcsEntitySelector]",
   providers: [
      {
         provide: FORM_CONTROL_METADATA_ENTITY_ID,
         useExisting: EntitySelectorDirective,
      },
      {
         provide: FORM_CONTROL_METADATA_SELECTORFILTERS,
         useExisting: EntitySelectorDirective,
      },
      EntitySearchService,
   ],
})
export class EntitySelectorDirective
   extends EndpointSelectorDirectiveBase
   implements
      OnDestroy,
      OnInit,
      OnChanges,
      FormControlMetadataEntityID,
      FormControlMetadataSelectorFilters,
      AfterViewInit
{
   /** The type of entity to retrieve. Used to build the request and define the way the SelectorItemModels should be constructed. */
   @Input() set entityType(entityType: EntityType) {
      this._entityType = entityType;
      this.setIsSearchable();
   }

   /** The type of parent entity. Used when retrieving objects from a subresource. */
   @Input() parentEntityType: EntityType;

   /** The id of the parent entity. Used when retrieving objects from a subresource. */
   @Input() entityID: number;

   /** Defines values to exclude from the result set. Expects a list of the item values, not the items themselves. */
   @Input() valuesToExclude: Array<string | number | boolean>;

   /** Defines filters to apply to the endpoint request. */
   @Input() filters: Array<FilterOption>;

   /** Defines a filter expression to apply to the endpoint request. */
   @Input() filterExpression: FilterExpression;

   /** Whether or not to add a blank item to the set. Allows selectors to be "unset" in a consistent manner. */
   @Input() addBlankItem: boolean;

   /** Defines additional items for the result set. They are prepended to data returned from the endpoint. */
   @Input() staticValues: Array<SelectorItemModel>;

   /** A set of additional embeds to add to the request in addition to the embeds used to construct the SelectorItemModels. */
   @Input() embeds: Array<string>;

   /** A set of additional fields to add to the request in addition to the fields used to construct the SelectorItemModels. */
   @Input() additionalDataFields: Array<string>;

   /** The set of orderingOptions to use on the endpoint request. */
   @Input() orderingOptions: Array<string>;

   /** Whether or not to load all items in the set before the selector is focused. */
   @Input() set eagerLoadItems(val: boolean) {
      this.eagerLoadItemsBase = val;
      if (this._valueSourcePath && this._displayValueSourcePath && this._endpoint && this._searchFields) {
         this.setEagerLoadItems();
      } else {
         const requiredFields = combineLatest(this.requiredConfigurationValues);
         requiredFields.pipe(take(1)).subscribe(() => {
            this.setEagerLoadItems();
         });
      }
   }

   /** Whether or not to load all items in the set when the selector is focused. */
   @Input() set loadItemsOnFocus(val: boolean) {
      this.loadItemsOnFocusBase = val;
   }

   /** OVERRRIDE, MAY BE DEPRECATED: Url to retrieve data from. */
   @Input() endpoint: string;

   /** OVERRRIDE, MAY BE DEPRECATED: Whether or not the dataset should be retrieved from a subresource. */
   @Input() isSubItem: boolean;

   /** OVERRRIDE, MAY BE DEPRECATED: Whether or not the request can use the search syntax. */
   @Input() endpointIsSearch: boolean;

   /** OVERRRIDE, MAY BE DEPRECATED: Resource to retrieve data from. */
   @Input() resource: string;

   /** OVERRRIDE, MAY BE DEPRECATED: The path the ObjectMapResolver will use to locate the item value when processing the dataset. */
   @Input() valueSourcePath: string;

   /** OVERRRIDE, MAY BE DEPRECATED: A set of fields to use on the endpoint request. Needs to be removed/replaced with the additionalDataFields input. */
   @Input() set fields(val: Array<string | EntityField>) {
      this._fields = val;
   }
   @Input() isEntitySearchDisabled: boolean;

   @HostListener("mousedown", ["$event.target"]) onClick(element) {
      if (element.classList.contains("search-icon")) {
         if (this.filterExpression === undefined) {
            this.setFilterExpression(this.entityFilters);
         }
         //this.entitySearchService.openSearchRegister(this._entityType, this.filterExpression);
      }
   }

   /** Angular Issue: You cannot set a protected field from a base class directly inside an input set method */
   private set loadItemsOnFocusBase(val: boolean) {
      this._loadItemsOnFocus = val;
   }

   /** Angular Issue: You cannot set a protected field from a base class directly inside an input set method */
   private set eagerLoadItemsBase(val: boolean) {
      this._eagerLoadItems = val;
   }

   private set valueSourcePathBase(val: string) {
      this._valueSourcePath = val;
      this.requiredConfigurationValues.valueSourcePath.next(val);
   }

   private set displayValueSourcePathBase(val: string) {
      this._displayValueSourcePath = val;
      this.requiredConfigurationValues.displayValueSourcePath.next(val);
   }

   private set endpointBase(val: string) {
      this._endpoint = val;
      this.requiredConfigurationValues.endpoint.next(val);
   }

   private set searchFieldsBase(val: Array<FilterField>) {
      this._searchFields = val;
      this.requiredConfigurationValues.searchFields.next(val);
   }

   private _entityType: EntityType;

   private filtersFromMetadata: Array<FilterOption> | null;

   private entityValueSource: ValueSourceModel;

   private entityFilters: Array<FilterOption>;

   private searchableEntities: Array<EntityType> = [
      EntityType.ChargeTypes,
      EntityType.Owner,
      EntityType.Property,
      EntityType.PropertyGroup,
      EntityType.Prospect,
      EntityType.Tenant,
      EntityType.Unit,
      EntityType.UnitType,
      EntityType.Vendor,
      EntityType.InventoryItem,
      EntityType.Job,
      EntityType.JobType,
      EntityType.LetterTemplate,
      EntityType.User,
      EntityType.Asset,
   ];

   private isEntitySearchable: boolean;

   private keyCode = "F3";

   private selectorValuesSet = new Subject<void>();

   private requiredConfigurationValues = {
      valueSourcePath: new Subject<string>(),
      displayValueSourcePath: new Subject<string>(),
      endpoint: new Subject<string>(),
      searchFields: new Subject<Array<FilterField>>(),
   };

   constructor(
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private entityViewInformationService: EntityViewInformationServiceBase,
      private entitySearchConfigurationService: EntitySearchConfigurationServiceBase,
      private entityRequestService: EntityRequestService,
      private entityChangeService: EntityChangeService,
      protected errorMessageService: ErrorMessageService,
      protected objectMapResolverService: ObjectMapResolverService,
      protected valueSourceService: ValueSourceService,
      protected apiService: ApiService,
      @Host() @Self() private entitySearchService: EntitySearchService,
      private ngZone: NgZone,
      @Host() @Self() protected selectComponent: SelectComponent
   ) {
      super(errorMessageService, objectMapResolverService, valueSourceService, apiService, selectComponent);

      this.entityChangeService.entityChange.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
         if (event.entityType === this._entityType && event.entityID === this.selectComponent.value) {
            this.refreshSelectedItemSubject.next(true);
         }
         if (event.entityType === EntityType.SavedList) {
            this.getEntityInformation();
         }
      });
   }

   ngOnInit(): void {
      super.ngOnInit();
      if (this.isEntitySearchable) {
         this.entitySearchService.searchID.pipe(takeUntil(this.unsubscribe)).subscribe((id: number | null) => {
            if (id != null) {
               this.selectComponent.value = id;
               this.refreshSelectedItemSubject.next(true);
            }
         });
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      const filtersChanged = changes.filters && changes.filters.currentValue !== changes.filters.previousValue;
      const filterExpressionChanged =
         changes.filterExpression && changes.filterExpression.currentValue !== changes.filterExpression.previousValue;

      if (filtersChanged) {
         const currentFilterOptions: Array<FilterOption> = changes.filters.currentValue ?? new Array<FilterOption>();
         this.setFilters(currentFilterOptions);
         this.setFilterExpression(currentFilterOptions); // DO NOT REMOVE, see following comment
         // The above call to setFilterExpression(...) setting to the filters.currentValue is required to ensure that the
         // EntitySelector in the Links tile on the Service Manager Details page displays the selected entity picked from the
         // Entity Selector Dialog opened via the search icon.  If not set to the current filters, the value does not display
         // and if the line is removed entirely, then switching Entity Types results in a server error, because old filters from
         // the previous entity type are carried over to the new entity type, and are not valid for the new entity type.
         // NOTE: there may ultimately be a better solution, but for now this at least fixes this one issue and does not
         //       appear to cause any other issues.
      } else if (filterExpressionChanged) {
         this.setSelectorValues();
      }

      if (
         (changes.entityType && changes.entityType.currentValue !== changes.entityType.previousValue) ||
         (changes.parentEntityType &&
            changes.parentEntityType.currentValue !== changes.parentEntityType.previousValue) ||
         (changes.entityID && changes.entityID.currentValue !== changes.entityID.previousValue)
      ) {
         this.getEntityInformation();
      } else {
         this.setSelectorValues();
      }
   }

   ngOnDestroy(): void {
      super.ngOnDestroy();
   }
   ngAfterViewInit(): void {
      const inputElement = this.selectComponent.userInputWrapper.nativeElement.querySelector("input");
      this.ngZone.runOutsideAngular(() => {
         fromEvent<KeyboardEvent>(inputElement, "keydown")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
               this.ngZone.run(() => {
                  if (event.key === this.keyCode && this.selectComponent.isEntitySearchable) {
                     event.preventDefault();
                     if (this.filterExpression === undefined) {
                        this.setFilterExpression(this.filters);
                     }
                     //this.entitySearchService.openSearchRegister(this._entityType, this.filterExpression);
                  }
               });
            });
      });
   }

   setFilters(filters: Array<FilterOption>): void {
      this.filtersFromMetadata = filters;
      this.entityFilters = filters ?? [];
      this.setSelectorValues();
   }

   setEntityID(entityID: number): void {
      this.entityID = entityID;
      this.getEntityInformation();
   }

   private setIsSearchable(): void {
      if (this.isEntitySearchDisabled) {
         this.isEntitySearchable = false;
      } else {
         this.isEntitySearchable = this.searchableEntities.some((se) => se === this._entityType);
      }
      this.selectComponent.isEntitySearchable = this.isEntitySearchable;
   }

   private getEntityInformation(): void {
      this.clearResults();
      let parentEntityInformationRequest: Observable<EntityRequestEndpointInformationModel | null>;
      if (this.parentEntityType && this.parentEntityType !== EntityType.Unassociated) {
         parentEntityInformationRequest = this.entityRequestEndpointService.getEndpointInformation(
            this.parentEntityType
         );
      } else {
         parentEntityInformationRequest = of(null);
      }

      if (
         this._entityType == null ||
         this._entityType === EntityType.Unassociated ||
         this._entityType === EntityType.NotSet
      ) {
         return;
      }

      forkJoin([
         this.entityRequestEndpointService.getEndpointInformation(this._entityType),
         this.entityViewInformationService.getViewInformation(this._entityType),
         this.entitySearchConfigurationService.getSearchConfiguration(this._entityType),
         parentEntityInformationRequest,
      ])
         .pipe(
            switchMap(([endpointInformation, viewInformation, searchConfiguration, parentEndpointInformation]) => {
               if (viewInformation.DefaultSelectorBehavior === SelectorBehavior.All) {
                  this._loadAllItems = true;
               } else {
                  this._loadAllItems = false;
               }
               return this.buildSearchableValueSource(
                  endpointInformation,
                  viewInformation,
                  searchConfiguration,
                  parentEndpointInformation
               );
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((valueSource) => {
            this.entityValueSource = valueSource;
            this.setSelectorValues();
         });
   }

   private buildSearchableValueSource(
      endpointInformation: EntityRequestEndpointInformationModel,
      viewInformation: EntityViewInformationModel,
      searchConfiguration: EntitySearchConfigurationModel,
      parentEndpointInformation: EntityRequestEndpointInformationModel | null
   ): Observable<ValueSourceModel> {
      const valueSource = new ValueSourceModel();
      valueSource.ValuesToExclude = this.valuesToExclude;
      if (this.endpoint) {
         valueSource.Endpoint = this.endpoint;
      } else {
         if (!this.resource && !this.entityID) {
            valueSource.EndpointIsSearch = endpointInformation.IsSearchEnabled;
            valueSource.IsQuickSearchEnabled = endpointInformation.IsSearchEnabled;
         }
         if (parentEndpointInformation && this.resource) {
            valueSource.Endpoint = this.entityRequestService.buildEndpoint(
               parentEndpointInformation,
               this.resource,
               this.entityID,
               false
            );
            valueSource.ItemEndpoint = this.entityRequestService.buildEndpoint(
               endpointInformation,
               null,
               null,
               valueSource.IsQuickSearchEnabled
            );
            if (valueSource.IsQuickSearchEnabled) {
               valueSource.QuickSearchEndpoint = this.entityRequestService.buildEndpoint(
                  parentEndpointInformation,
                  this.resource,
                  this.entityID,
                  true
               );
            }
         } else {
            valueSource.Endpoint = this.entityRequestService.buildEndpoint(
               endpointInformation,
               this.resource,
               this.entityID,
               false
            );
            if (valueSource.IsQuickSearchEnabled) {
               valueSource.QuickSearchEndpoint = this.entityRequestService.buildEndpoint(
                  endpointInformation,
                  this.resource,
                  this.entityID,
                  true
               );
            }
         }
      }
      valueSource.EntityValueSourcePath = viewInformation.EntityPrimaryKeyPropertyPath;
      valueSource.DisplayValueSourcePath = viewInformation.EntityListItemTemplate;
      valueSource.AdditionalInfoSourcePath = viewInformation.AdditionalInformationDisplayTemplate;
      valueSource.AdditionalDataFields = this.additionalDataFields;
      valueSource.SearchFields = searchConfiguration.DefaultSearchFields;
      valueSource.StaticValues = viewInformation.AdditionalSelectorItems;

      if (searchConfiguration.GlobalFilters && searchConfiguration.GlobalFilters.length > 0) {
         valueSource.Filters = searchConfiguration.GlobalFilters.map(
            FilterOption.FromExpressControlDataSourceFilterModel
         );
      }

      if (searchConfiguration.DefaultOrderingOption) {
         valueSource.OrderingOptions = [searchConfiguration.DefaultOrderingOption.Name];
      }

      if (viewInformation.DefaultSelectorBehavior === SelectorBehavior.All) {
         this._loadAllItems = true;
      } else {
         this._loadAllItems = false;
      }

      return of(valueSource);
   }

   private setSelectorValues(): void {
      this.clearAllItems();
      this.clearResults();
      if (!this.entityValueSource) {
         return;
      }

      if (this.endpoint) {
         this.endpointBase = this.endpoint;
         this._endpointIsSearch = this.endpointIsSearch;
      } else {
         if (this.entityValueSource.IsQuickSearchEnabled) {
            this.endpointBase = this.entityValueSource.QuickSearchEndpoint;
         } else {
            this.endpointBase = this.entityValueSource.Endpoint;
         }
      }
      if (this.isSubItem) {
         this._isSubItem = this.isSubItem;
      }

      this._endpointIsSearch ??= this.entityValueSource.EndpointIsSearch;
      this._isSubItem = this.entityValueSource.IsSubItem;
      this._endpointIsQuickSearch = this.entityValueSource.IsQuickSearchEnabled;

      // TODO: itemEndpoint could be different, prolly needs to get added here
      if (this.valueSourcePath) {
         this.valueSourcePathBase = this.valueSourcePath;
      } else {
         this.valueSourcePathBase = this.entityValueSource.EntityValueSourcePath;
      }

      this._primaryKeySourcePath = this.entityValueSource.EntityValueSourcePath;
      this.displayValueSourcePathBase = this.entityValueSource.DisplayValueSourcePath;
      this._additionalInfoSourcePath = this.entityValueSource.AdditionalInfoSourcePath;
      this._additionalDataFields = this.entityValueSource.AdditionalDataFields;
      this.searchFieldsBase = this.entityValueSource.SearchFields;
      this._embeds = this.embeds;

      const staticValues = new Array<SelectorItemModel>();

      if (this.staticValues) {
         staticValues.push(...this.staticValues);
      } else if (this.entityValueSource.StaticValues) {
         staticValues.push(...this.entityValueSource.StaticValues);
      }

      this._staticValues = staticValues;

      this._additionalDataFields = this.additionalDataFields;

      let filters: FilterOption[] | null = new Array<FilterOption>();
      if (this.entityValueSource.Filters) {
         filters.push(...this.entityValueSource.Filters);
      }

      if (this.filtersFromMetadata && this.filtersFromMetadata.length > 0) {
         filters = FilterOption.Merge(this.filtersFromMetadata, filters);
      }

      if (this.filters && this.filters.length > 0 && filters && filters.length > 0) {
         filters = FilterOption.Merge(this.filters, filters);
      }

      this._filters = filters;

      this._filterExpression = this.filterExpression;

      if (this.addBlankItem) {
         this._addBlankItem = this.addBlankItem;
      }

      if (this.isSubItem) {
         this._isSubItem = this.isSubItem;
      }

      this._maximumItemsToRetrieve = this.entityValueSource.MaximumItemsToRetrieve;

      this.setSelectorOrderingOptions();

      this.selectorValuesSet.next();
      this.refreshSelectedItemSubject.next(false);
   }

   private setSelectorOrderingOptions(): void {
      const orderingOptions = new Array<string>();

      if (this.orderingOptions) {
         orderingOptions.push(...this.orderingOptions);
      } else if (this.entityValueSource.OrderingOptions) {
         orderingOptions.push(...this.entityValueSource.OrderingOptions);
      }

      // TODO: Why is this here, instead of provided or defined in entityinformation
      if (this._entityType === EntityType.Property) {
         orderingOptions.push("Name");
      }

      this._orderingOptions = orderingOptions;
   }

   private setFilterExpression(filterOptions: Array<FilterOption>): void {
      const filterExpression = new FilterExpression();
      filterExpression.FilterOptions = filterOptions ?? new Array<FilterOption>();
      filterExpression.LogicalOperator = 1;
      filterExpression.ExcludeAllOverride = false;
      this.filterExpression = filterExpression;
   }
}
