import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ValidationModel } from "@lcs/inputs/validation/validation.model";
import { ControlContainerViewProvider } from "projects/libraries/lcs/src/lib/inputs/control-container-view-providers";
import { UserInputComponent } from "projects/libraries/lcs/src/lib/inputs/user-input-component.interface";
import { Subject } from "rxjs";

import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParameterControlStatusService } from "../report-parameters-control-status.service";

@Component({
   selector: "lcs-checkbox-group-report-parameter",
   templateUrl: "checkbox-group-report-parameter.component.html",
   providers: [ReportParameterControlStatusService],
   viewProviders: [ControlContainerViewProvider],
})
export class CheckboxGroupReportParameterComponent implements OnInit, UserInputComponent, OnDestroy {
   @Input() customValidatorData: any;

   @Input() disabled: boolean;

   @Input() displayName: string;

   @Input() name: string;

   @Input() validation: ValidationModel;

   @Input() standalone: boolean;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      this._reportReportParameters = values
         .filter((v) => v.ReportReportParameter)
         .map((v) => v.ReportReportParameter)
         .concat(
            values
               .filter((v) => v.GroupedReportReportParameters)
               .map((v) => v.GroupedReportReportParameters)
               .reduce(function (a, b) {
                  return a.concat(b);
               })
         );
   }

   get reportReportParameters(): Array<ReportReportParameterViewModel> {
      return this._reportReportParameters;
   }

   @Input() hasAsk: boolean;

   private _reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   ngOnInit() {
      if (!this.displayName) {
         this.displayName = this._reportReportParameters[0].DisplayName;
      }
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
