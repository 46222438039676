////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum ExportType {
   NotSet = 0,
   PDF = 1,
   Email = 2,
   HTML = 3,
   Excel = 4,
   Text = 5,
   CSV = 6,
   JSON = 7,
   XLSX = 8,
}
