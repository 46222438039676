import { ApplicantType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/applicant-type.enum";
import { ContactRelatedObjectTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/contact-related-object-types.enum";

import { AddressModel } from "./address.model";
import { ContactTypeModel } from "./contact-type.model";
import { ImageModel } from "./image.model";
import { OwnerProspectModel } from "./owner-prospect.model";
import { OwnerModel } from "./owner.model";
import { PhoneNumberModel } from "./phone-number.model";
import { ProspectModel } from "./prospect.model";
import { TenantModel } from "./tenant.model";
import { UserDefinedValueModel } from "./user-defined-value.model";
import { VendorModel } from "./vendor.model";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class ContactModel {
   Addresses: Array<AddressModel>;
   AnnualIncome?: number | null ;
   ApplicantType: ApplicantType;
   Comment: string;
   ContactID: number;
   ContactType: ContactTypeModel;
   ContactTypeID: number;
   CreateDate: Date;
   CreateUserID: number;
   DateOfBirth?: Date | null ;
   Email: string;
   Employer: string;
   FederalTaxID: string;
   FirstName: string;
   Image: ImageModel;
   ImageID: number;
   IsActive: boolean;
   IsPrimary: boolean;
   IsShowOnBill: boolean;
   LastName: string;
   License: string;
   MetaTag?: string;
   MiddleName: string;
   Owner: OwnerModel;
   OwnerProspect: OwnerProspectModel;
   ParentID: number;
   ParentType: ContactRelatedObjectTypes;
   PhoneNumbers: Array<PhoneNumberModel>;
   Prospect: ProspectModel;
   Tenant: TenantModel;
   UpdateDate: Date;
   UpdateUserID: number;
   UserDefinedValues: Array<UserDefinedValueModel>;
   Vehicle: string;
   Vendor: VendorModel;
}
