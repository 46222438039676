export class FooterButtonModel {
   set deleteConfirmationOverride(value: string) {
      this._deleteConfirmationOverride = value;
   }

   get deleteConfirmationOverride(): string {
      return this._deleteConfirmationOverride;
   }

   disabled: boolean;

   isRaised: boolean;

   label: string;

   onClick: Function;

   private _deleteConfirmationOverride: string;
}
