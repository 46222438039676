import { EntityField } from "../base-options/field";

////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export class UnitFields extends EntityField {
   static readonly Addresses: UnitFields = new UnitFields("Addresses");

   static readonly Amenities: UnitFields = new UnitFields("Amenities");

   static readonly Amps: UnitFields = new UnitFields("Amps");

   static readonly AssetLocationHistory: UnitFields = new UnitFields("AssetLocationHistory");

   static readonly Assets: UnitFields = new UnitFields("Assets");

   static readonly Bathrooms: UnitFields = new UnitFields("Bathrooms");

   static readonly Bedrooms: UnitFields = new UnitFields("Bedrooms");

   static readonly Color: UnitFields = new UnitFields("Color");

   static readonly ColorID: UnitFields = new UnitFields("ColorID");

   static readonly Comment: UnitFields = new UnitFields("Comment");

   static readonly CreateDate: UnitFields = new UnitFields("CreateDate");

   static readonly CreateUser: UnitFields = new UnitFields("CreateUser");

   static readonly CreateUserID: UnitFields = new UnitFields("CreateUserID");

   static readonly CurrentAssetLocation: UnitFields = new UnitFields("CurrentAssetLocation");

   static readonly CurrentMarketRent: UnitFields = new UnitFields("CurrentMarketRent");

   static readonly CurrentOccupancyStatus: UnitFields = new UnitFields("CurrentOccupancyStatus");

   static readonly CurrentOccupants: UnitFields = new UnitFields("CurrentOccupants");

   static readonly CurrentUnitStatus: UnitFields = new UnitFields("CurrentUnitStatus");

   static readonly FinancialAsset: UnitFields = new UnitFields("FinancialAsset");

   static readonly Floor: UnitFields = new UnitFields("Floor");

   static readonly FloorID: UnitFields = new UnitFields("FloorID");

   static readonly GeoLocationCode: UnitFields = new UnitFields("GeoLocationCode");

   static readonly History: UnitFields = new UnitFields("History");

   static readonly HistoryNotes: UnitFields = new UnitFields("HistoryNotes");

   static readonly HistorySystemNotes: UnitFields = new UnitFields("HistorySystemNotes");

   static readonly Images: UnitFields = new UnitFields("Images");

   static readonly InspectionTemplate: UnitFields = new UnitFields("InspectionTemplate");

   static readonly InspectionTemplateID: UnitFields = new UnitFields("InspectionTemplateID");

   static readonly IsAsset: UnitFields = new UnitFields("IsAsset");

   static readonly IsLicensed: UnitFields = new UnitFields("IsLicensed");

   static readonly IsPullThrough: UnitFields = new UnitFields("IsPullThrough");

   static readonly IsVacant: UnitFields = new UnitFields("IsVacant");

   static readonly LastSuccessfulOnlineListingsPosting: UnitFields = new UnitFields(
      "LastSuccessfulOnlineListingsPosting"
   );

   static readonly Leases: UnitFields = new UnitFields("Leases");

   static readonly Length: UnitFields = new UnitFields("Length");

   static readonly MarketingData: UnitFields = new UnitFields("MarketingData");

   static readonly MarketingSetup: UnitFields = new UnitFields("MarketingSetup");

   static readonly MarketingValues: UnitFields = new UnitFields("MarketingValues");

   static readonly MarketRent: UnitFields = new UnitFields("MarketRent");

   static readonly MaxOccupancy: UnitFields = new UnitFields("MaxOccupancy");

   static readonly MetaTag: UnitFields = new UnitFields("MetaTag");

   static readonly Name: UnitFields = new UnitFields("Name");

   static readonly OccupancyStatusHistory: UnitFields = new UnitFields("OccupancyStatusHistory");

   static readonly OnlineListingsUsage: UnitFields = new UnitFields("OnlineListingsUsage");

   static readonly OpenViolations: UnitFields = new UnitFields("OpenViolations");

   static readonly PrimaryAddress: UnitFields = new UnitFields("PrimaryAddress");

   static readonly Property: UnitFields = new UnitFields("Property");

   static readonly PropertyID: UnitFields = new UnitFields("PropertyID");

   static readonly RecurringCharges: UnitFields = new UnitFields("RecurringCharges");

   static readonly RecurringChargeSummaries: UnitFields = new UnitFields("RecurringChargeSummaries");

   static readonly RevenuePricing: UnitFields = new UnitFields("RevenuePricing");

   static readonly SiteGroup: UnitFields = new UnitFields("SiteGroup");

   static readonly SiteGroupID: UnitFields = new UnitFields("SiteGroupID");

   static readonly SiteTypeUnitTypes: UnitFields = new UnitFields("SiteTypeUnitTypes");

   static readonly SortOrder: UnitFields = new UnitFields("SortOrder");

   static readonly SquareFootage: UnitFields = new UnitFields("SquareFootage");

   static readonly STRMarketingDescription: UnitFields = new UnitFields("STRMarketingDescription");

   static readonly SupportedRVSlideOut: UnitFields = new UnitFields("SupportedRVSlideOut");

   static readonly UnitAmenities: UnitFields = new UnitFields("UnitAmenities");

   static readonly UnitDefaultSecurityDeposits: UnitFields = new UnitFields("UnitDefaultSecurityDeposits");

   static readonly UnitID: UnitFields = new UnitFields("UnitID");

   static readonly UnitLicenseHistory: UnitFields = new UnitFields("UnitLicenseHistory");

   static readonly UnitProhibitedRVTypes: UnitFields = new UnitFields("UnitProhibitedRVTypes");

   static readonly UnitStatuses: UnitFields = new UnitFields("UnitStatuses");

   static readonly UnitType: UnitFields = new UnitFields("UnitType");

   static readonly UnitTypeID: UnitFields = new UnitFields("UnitTypeID");

   static readonly UpdateDate: UnitFields = new UnitFields("UpdateDate");

   static readonly UpdateUser: UnitFields = new UnitFields("UpdateUser");

   static readonly UpdateUserID: UnitFields = new UnitFields("UpdateUserID");

   static readonly UserDefinedValues: UnitFields = new UnitFields("UserDefinedValues");

   static readonly UtilityMeterReadings: UnitFields = new UnitFields("UtilityMeterReadings");

   static readonly Violations: UnitFields = new UnitFields("Violations");

   static readonly Width: UnitFields = new UnitFields("Width");
}
