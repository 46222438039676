import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { SelectionChangeModel } from "@lcs/selectors/selection-change.model";
import { SelectorItemSetModel } from "@lcs/selectors/selector-item-set.model";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityRequestEndpointServiceBase } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request-endpoints/entity-request-endpoint-service.base";
import { EntityRequestService } from "projects/libraries/owa-gateway-sdk/src/lib/api-information/entity-request.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterExpression } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-expression.model";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";
import { Subject, switchMap, takeUntil } from "rxjs";

import { SubComponentStatusService } from "../action-context/sub-component-status.service";
import { SubComponentStatuses } from "../action-context/sub-component-statuses.enum";
import { ValueAccessorSubComponentBase } from "../action-context/value-accessor-sub-component-base.class";
import { ObjectMapResolverService } from "../pipes/object-map-resolver.service";
import { EnumerationInformationService } from "../utils/enumeration-information.service";

/* eslint-disable curly */
@Component({
   selector: "lcs-multi-select",
   templateUrl: "multi-select.component.html",
})
export class MultiSelectComponent extends ValueAccessorSubComponentBase<any> implements OnInit, OnDestroy {
   @Output() selectionChanged = new EventEmitter<SelectionChangeModel>();

   @Output() valueSourceSelected = new EventEmitter<number>();

   @Input() allowsSelectAll: boolean;

   @Input() hidelabel: boolean = false;

   @Input() set valueSources(value: Array<ValueSourceModel>) {
      if (!value) {
         return;
      }
      this._valueSources = value;
      if (this.initialized) {
         this.processValueSources();
      }
   }

   get valueSources(): Array<ValueSourceModel> {
      return this._valueSources;
   }

   @Input() set valueSource(value: ValueSourceModel | null) {
      if (!value) {
         return;
      }
      const requiresReload = !this._valueSources || this._valueSources.length > 0;
      this._valueSources = [value];
      if (this.initialized && requiresReload) {
         this.itemSets = new Array<SelectorItemSetModel>();
         this.processValueSources();
      }
   }

   @Input() set entityValueSourceFilters(filters: Array<FilterOption>) {
      if (filters && this.allSourcesLoaded) {
         this.filterEntityValueSource(filters);
      }
   }

   @Input() ignoreSelectedItemUpdate: boolean = false;

   @ViewChild("multiselectorWrapper", { static: true }) multiselectorWrapper: ElementRef;

   allSourcesLoaded = false;

   errorMessage: string;

   itemSets: Array<SelectorItemSetModel>;

   selectAll: boolean;

   private initialized: boolean = false;

   private loadedItemSetCount = 0;

   private selectedSourceIndex: number = 0;

   private unsubscribe = new Subject<void>();

   private _valueSources: Array<ValueSourceModel>;

   private setDefault: boolean;

   constructor(
      private apiService: ApiService,
      private entityRequestService: EntityRequestService,
      private entityRequestEndpointService: EntityRequestEndpointServiceBase,
      private enumerationInformationService: EnumerationInformationService,
      private objectMapResolverService: ObjectMapResolverService,
      private errorMessageService: ErrorMessageService,
      @Optional() protected subComponentStatusService: SubComponentStatusService,
      protected changeDetectorRef: ChangeDetectorRef,
      public ngControl: NgControl
   ) {
      super(changeDetectorRef, subComponentStatusService, ngControl);
      this.itemSets = new Array<SelectorItemSetModel>();

      this.registerOnValueWritten((value: any) => {
         if (value === undefined) {
            return;
         }
         if (!this.ignoreSelectedItemUpdate) this.updateSelectedItems();
      });
   }

   ngOnInit() {
      this.setDefault = true;
      this.processValueSources();
      return super.ngOnInit();
   }

   ngOnDestroy() {
      this.unsubscribe.next();
      return super.ngOnDestroy();
   }

   processValueSources() {
      if (this._valueSources && this._valueSources.length > 0) {
         for (const source of this._valueSources) {
            if (source.Type === ValueSourceTypes.Static) {
               const itemSet = this.processStaticValueSource(source);
               this.itemSets.push(itemSet);
               this.loadedItemSetCount++;
               this.checkAllItemSetsLoaded();
               this.setSelectAll(source);
               this.initialized = true;
            } else if (source.Type === ValueSourceTypes.Enumeration) {
               const itemSet = new SelectorItemSetModel();
               const index = this.itemSets.push(itemSet) - 1;
               this.processEnumerationValueSource(source, index);
            } else if (source.Type === ValueSourceTypes.Entity) {
               const itemSet = new SelectorItemSetModel();
               const index = this.itemSets.push(itemSet) - 1;
               this.processEntityValueSource(source, index);
            }
         }
      } else {
         this.initialized = true;
      }
   }

   processStaticValueSource(source: ValueSourceModel): SelectorItemSetModel {
      const itemSet = new SelectorItemSetModel();
      itemSet.identifier = "static";
      if (source.Label) {
         itemSet.label = source.Label;
      }
      for (const value of source.StaticValues) {
         const selectorItem = new SelectorItemModel();
         selectorItem.displayValue = value.displayValue;
         selectorItem.value = value.value;
         selectorItem.isChecked = value.isChecked || source.SelectedValues.indexOf(selectorItem.value) > -1;
         selectorItem.alternateStyleClass = value.alternateStyleClass;
         itemSet.items.push(selectorItem);
      }
      return itemSet;
   }

   processEnumerationValueSource(source: ValueSourceModel, itemSetIndex: number) {
      this.itemSets[itemSetIndex].identifier = source.EnumerationName;
      this.itemSets[itemSetIndex].defaultValue = source.DefaultValue;

      this.setSelectAll(source);

      this.enumerationInformationService
         .getEnumerationInformation(source.EnumerationName)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((results) => {
            for (const result of results) {
               if (source.ValuesToExclude.indexOf(result.Value.toString()) > -1) {
                  continue;
               }
               const selectorItem = new SelectorItemModel();
               selectorItem.value = result.Value.toString();
               selectorItem.displayValue = result.Description;
               selectorItem.isChecked = source.SelectedValues.indexOf(selectorItem.value) > -1;
               this.itemSets[itemSetIndex].items.push(selectorItem);
            }
            this.loadedItemSetCount++;
            this.checkAllItemSetsLoaded();
            this.initialized = true;
         });
   }

   processEntityValueSource(source: ValueSourceModel, itemSetIndex: number) {
      this.updateSubComponentStatus(SubComponentStatuses.Loading);
      this.itemSets[itemSetIndex].identifier = source.EntityType.toString();
      this.itemSets[itemSetIndex].defaultValue = source.DefaultValue;

      // TODO: detect and pass embeds
      const filterExpression = new FilterExpression();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOption[] | null' is not assignable to ... Remove this comment to see the full error message
      filterExpression.FilterOptions = source.Filters;

      this.entityRequestService
         .getEntityEndpoint(source.EntityType, null, null, source.IsQuickSearchEnabled)
         .pipe(
            takeUntil(this.unsubscribe),
            switchMap((apiUrl) => {
               let additionalParameters = new Array<string>();

               if (source.EndpointIsSearch) {
                  additionalParameters = this.apiService.buildSearchCollectionParameterArrayFromGetParameters(
                     filterExpression,
                     source.Embeds,
                     source.OrderingOptions,
                     source.Fields
                  );
               } else {
                  additionalParameters = this.apiService.buildGetCollectionParameterArray(
                     filterExpression,
                     source.Embeds,
                     source.OrderingOptions,
                     source.Fields
                  );
               }

               return this.apiService.get(apiUrl, additionalParameters);
            })
         )
         .subscribe(
            (results) => {
               this.setSelectAll(source);
               this.processEntitySourceResults(source, itemSetIndex, results.body);
               this.loadedItemSetCount++;
               this.checkAllItemSetsLoaded();
               this.initialized = true;
            },
            (err) => {
               this.errorMessageService.triggerHttpErrorMessage(err);
               this.updateSubComponentStatus(SubComponentStatuses.Errored);
            }
         );
   }

   filterEntityValueSource(filters: Array<FilterOption>) {
      if (
         this._valueSources &&
         this._valueSources.length === 1 &&
         this._valueSources[0].Type === ValueSourceTypes.Entity
      ) {
         this.allSourcesLoaded = false;
         this._valueSources[0].Filters = filters;
         this.reloadEntityValueSource(this._valueSources[0], 0);
      }
   }

   reloadEntityValueSource(source: ValueSourceModel, itemSetIndex: number) {
      this.allSourcesLoaded = false;
      this.itemSets[itemSetIndex].items = [];
      // TODO: detect and pass embeds
      const filterExpression = new FilterExpression();
      // @ts-ignore ts-migrate(2322) FIXME: Type 'FilterOption[] | null' is not assignable to ... Remove this comment to see the full error message
      filterExpression.FilterOptions = source.Filters;
      this.entityRequestEndpointService
         .getEndpointInformation(source.EntityType)
         .pipe(
            switchMap((endpointInformation) => {
               return this.apiService.getCollection(
                  endpointInformation.APIUrl,
                  filterExpression,
                  [],
                  source.OrderingOptions ?? [],
                  this.objectMapResolverService.processEntityValueSourcePathInformation(source)
               );
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe((results) => {
            this.processEntitySourceResults(source, itemSetIndex, results);
            this.checkAllItemSetsLoaded();
         });
   }

   processEntitySourceResults(source: ValueSourceModel, itemSetIndex: number, results: Array<any>) {
      if (results) {
         for (const result of results) {
            const selectorItem = new SelectorItemModel();
            selectorItem.value = result[source.EntityValueSourcePath].toString();
            selectorItem.displayValue = this.objectMapResolverService.processEntitySourceDisplayValueResults(
               source,
               result
            );
            selectorItem.additionalInfoValue = this.objectMapResolverService.processEntitySourceAdditionalInfoResults(
               source,
               result
            );
            selectorItem.isChecked =
               this.setDefault && selectorItem.value === source.DefaultValue
                  ? true
                  : source.SelectedValues.indexOf(selectorItem.value) > -1;
            this.itemSets[itemSetIndex].items.push(selectorItem);
         }
         if (source.StaticValues) {
            for (const staticValue of source.StaticValues) {
               const selectorItem = new SelectorItemModel();
               selectorItem.value = staticValue.value;
               selectorItem.displayValue = staticValue.displayValue;
               selectorItem.additionalInfoValue = staticValue.additionalInfoValue;
               selectorItem.isChecked = staticValue.isChecked;
               if (!this.itemSets?.[itemSetIndex]?.items.find((i) => i.displayValue === selectorItem.displayValue)) {
                  this.itemSets[itemSetIndex].items.unshift(selectorItem);
               }
            }
         }
         this.update(false);
         this.allSourcesLoaded = true;
      }
      this.setDefault = false;
   }

   setSelectAll(source: ValueSourceModel) {
      if (source.SelectedValues.length > 0) {
         if (
            source.AllValue &&
            source.SelectedValues.length === 1 &&
            source.SelectedValues[0].toString().toUpperCase() === source.AllValue.toString().toUpperCase()
         ) {
            this.selectAll = true;
         } else {
            this.selectAll = false;
         }
      } else if (
         source.DefaultValue &&
         source.AllValue &&
         source.DefaultValue.toString().toUpperCase() === source.AllValue.toString().toUpperCase()
      ) {
         this.selectAll = true;
      } else if (source.SelectedValues.length === 0) {
         this.selectAll = false;
      }
      if (source.SelectedValues.length === source.StaticValues.length) {
         this.selectAll = true;
      }
   }

   checkAllItemSetsLoaded() {
      if (this.loadedItemSetCount === this._valueSources.length) {
         this.allSourcesLoaded = true;
         this.updateSubComponentStatus(SubComponentStatuses.Ready);
      }
   }

   toggleTab(index: number, event) {
      if (this.selectedSourceIndex !== index) {
         this.selectedSourceIndex = index;
         if (this._valueSources.length > 1) {
            this.valueSourceSelected.emit(this.selectedSourceIndex);
            this.update(event);
         }
      }
   }

   updateSelectedItems() {
      if (this.itemSets[this.selectedSourceIndex] && this.itemSets[this.selectedSourceIndex].items) {
         if (this.innerValue && this.innerValue.length) {
            if (
               this.valueSources &&
               this.valueSources[this.selectedSourceIndex] &&
               this.valueSources[this.selectedSourceIndex].AllValue &&
               this.innerValue.length === 1 &&
               this.innerValue[0].toString().toUpperCase() ===
                  this.valueSources[this.selectedSourceIndex].AllValue.toUpperCase()
            ) {
               this.updateSelectAll(true);
               return;
            }

            const stringSelectedValues = this.innerValue.map((v) => v.toString());
            this.selectAll = false;
            for (const item of this.itemSets[this.selectedSourceIndex].items) {
               if (stringSelectedValues.indexOf(item.value.toString()) > -1) {
                  item.isChecked = true;
               } else {
                  item.isChecked = false;
               }
            }
            if (this.valueSources && this.valueSources[this.selectedSourceIndex]) {
               this.valueSources[this.selectedSourceIndex].SelectedValues = stringSelectedValues;
            }
         } else {
            this.itemSets[this.selectedSourceIndex].items.forEach((item) => {
               item.isChecked = false;
            });
            this.selectAll = false;
         }
      }
   }

   updateSelectAll(checked: boolean) {
      this._valueSources[this.selectedSourceIndex].SelectedValues = new Array<any>();
      this.selectAll = checked;
      for (const checkedItem of this.itemSets[this.selectedSourceIndex].items.filter(
         (i) => i.isChecked !== this.selectAll
      )) {
         checkedItem.isChecked = this.selectAll;
      }
      const selectionChangeModel = new SelectionChangeModel();
      selectionChangeModel.checkedItems = new Array<SelectorItemModel>();
      selectionChangeModel.selectAll = this.selectAll;
      this.selectionChanged.emit(selectionChangeModel);
      if (this.selectAll) {
         this.value = this.itemSets[this.selectedSourceIndex].items.map((item) => item.value);
      } else {
         this.value = [];
      }
   }

   update(checked: boolean) {
      const checkedItems = new Array<SelectorItemModel>();
      if (checked) {
         this.selectAll = false;
      }
      const selectedValues = new Array<any>();
      for (const checkedItem of this.itemSets[this.selectedSourceIndex].items.filter((i) => i.isChecked)) {
         checkedItems.push(checkedItem);
         selectedValues.push(checkedItem.value);
      }
      const selectionChangeModel = new SelectionChangeModel();
      selectionChangeModel.checkedItems = checkedItems;
      selectionChangeModel.selectAll = this.selectAll;
      this._valueSources[this.selectedSourceIndex].SelectedValues = selectedValues;
      this.setSelectAll(this._valueSources[this.selectedSourceIndex]);
      this.selectionChanged.emit(selectionChangeModel);
      this.value = selectedValues;
   }

   onControlClick() {
      this.multiselectorWrapper.nativeElement.querySelector("input").focus();
   }
}
