////////////////////////////////////////////////////////
// 3.1.7 autogenerated - Do not make manual edits to this file
////////////////////////////////////////////////////////

export enum GeneralLedgerAccountType {
   NotSet = 0,
   Income = 1,
   Expense = 2,
   Equity = 3,
   OtherCurrentLiability = 4,
   OtherIncome = 5,
   OtherExpense = 6,
   OtherCurrentAsset = 7,
   AccountsReceivable = 8,
   AccountsPayable = 9,
   CostOfGoodsSold = 10,
   LongTermLiability = 11,
   FixedAsset = 12,
   OtherAsset = 13,
   Bank = 14,
   CreditCard = 15,
   NonOperatingIncome = 16,
   NonOperatingExpense = 17,
   NonControllableIncome = 18,
   NonControllableExpense = 19,
   Assets = 20,
   Expenses = 21,
}
