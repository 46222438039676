import { Injectable, OnDestroy } from "@angular/core";
import { Params } from "@angular/router";
import { ExpressPayloadFields } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-payload-fields.enum";
import { BehaviorSubject, Observable, of, Subject, switchMap, takeUntil } from "rxjs";

import { RouteInformationModel } from "../action-router/route-information.model";
import { RoutingService } from "../action-router/routing.service";
import { ActionTriggerPayloadModel } from "../action-trigger/action-trigger-payload.model";
import { base64DecodedIfNecessary } from "../utils/string-extensions";

@Injectable()
export class ActionPayloadsService implements OnDestroy {
   getPayloadValueByKey<T>(key: string): T | null {
      const payload: ActionTriggerPayloadModel | undefined = this.payloads.find((p): boolean => p.PayloadKey === key);
      return payload ? (payload.Value as T) : null;
   }

   getPayloadValueByField<T>(payloadField: ExpressPayloadFields): T | null {
      const payload: ActionTriggerPayloadModel | undefined = this.payloads.find(
         (p): boolean => p.PayloadField === payloadField
      );
      return payload ? (payload.Value as T) : null;
   }

   /**
    * Synchronous property available for backward compatibility
    */
   get payloads(): Array<ActionTriggerPayloadModel> {
      return this._payloads.value;
   }

   /**
    * Synchronous property available for backward compatibility
    */
   set payloads(payloads: Array<ActionTriggerPayloadModel>) {
      this._payloads.next(payloads);
   }

   payloads$: Observable<Array<ActionTriggerPayloadModel>>;

   private _payloads: BehaviorSubject<Array<ActionTriggerPayloadModel>> = new BehaviorSubject<
      Array<ActionTriggerPayloadModel>
   >(new Array<ActionTriggerPayloadModel>());

   private unsubscribe: Subject<void> = new Subject<void>();

   constructor() {
      this.payloads$ = this._payloads.asObservable();
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }

   /**
    * Sets up a subscription which updates the payloads when the routeInformation changes.
    * NOTE: We do NOT inject the routingService in the constructor because the ActionPayloadService
    *       is dynamically injected into the DialogCreationService which is used by OWA also, but
    *       OWA does not use the RoutingService.
    */
   initializeForRootAction(routingService: RoutingService) {
      this.getPayloads(routingService)
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((payloads: Array<ActionTriggerPayloadModel>): void => {
            this._payloads.next(payloads);
         });
   }

   private getRouteQueryParamPayloads(keys: Array<string>, params: Params): Array<ActionTriggerPayloadModel> {
      const payloads = new Array<ActionTriggerPayloadModel>();
      for (const paramKey of keys) {
         const payload = new ActionTriggerPayloadModel();
         if (ExpressPayloadFields[paramKey]) {
            payload.PayloadField = ExpressPayloadFields[paramKey];
         } else {
            payload.PayloadKey = paramKey;
         }
         payload.Value = base64DecodedIfNecessary(params[paramKey]);
         payloads.push(payload);
      }
      return payloads;
   }

   private getPayloads(routingService: RoutingService): Observable<Array<ActionTriggerPayloadModel>> {
      return routingService.routeInformation.pipe(
         switchMap((routeInformation: RouteInformationModel | null): Observable<Array<ActionTriggerPayloadModel>> => {
            let actionTriggerPayloads: Array<ActionTriggerPayloadModel>;
            if (routeInformation && routeInformation.QueryParams) {
               let queryParamKeys: Array<string> = Object.keys(routeInformation.QueryParams).filter(
                  (key) => key !== ActionTriggerPayloadModel.actionTriggerPayloadsCacheKey
               );
               actionTriggerPayloads = this.getRouteQueryParamPayloads(queryParamKeys, routeInformation.QueryParams);
            } else {
               actionTriggerPayloads = new Array<ActionTriggerPayloadModel>();
            }
            return of(actionTriggerPayloads);
         })
      );
   }
}
