import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { EntityType } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/entity-type.enum";
import { SelectorBehavior } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/selector-behavior.enum";

export class EntityViewInformationModel {
   EntityType: EntityType;
   EntityPrimaryKeyPropertyPath: string;
   EntityListItemTemplate: string;
   EntityDisplayTemplate: string;
   EntityDisplayIdPath: string;
   AdditionalSelectorItems: Array<SelectorItemModel>;
   DefaultSelectorBehavior: SelectorBehavior;
   SingleLabel: string;
   CollectionLabel: string;
   AdditionalInformationDisplayTemplate: string;
}
