import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValueAccessorBase } from "@lcs/inputs-framework/value-accessor-base";
import { SelectorItemModel } from "@lcs/selectors/selector-item.model";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ValueSourceTypes } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/value-source-types.enum";
import { FilterOperationModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/filter-operation.model";
import { ValueSourceModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/value-source.model";

@Component({
   selector: "lcs-filter-operation-selector",
   templateUrl: "./filter-operation-selector.component.html",
})
export class FilterOperationSelectorComponent extends ValueAccessorBase<FilterOperations> {
   @Input() set filterOperations(val: Array<FilterOperationModel>) {
      if (val) {
         this.buildValueSource(val);
      } else {
         // @ts-ignore ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ValueSource... Remove this comment to see the full error message
         this.valueSource = null;
      }
   }

   @Input() path: string;

   valueSource: ValueSourceModel;

   constructor(changeDetectorRef: ChangeDetectorRef, ngControl: NgControl) {
      super(changeDetectorRef, ngControl);
   }

   private buildValueSource(filterOperations: Array<FilterOperationModel>) {
      const valueSource = new ValueSourceModel();
      valueSource.Type = ValueSourceTypes.Static;

      let equalToOperation: SelectorItemModel;
      let containsOperation: SelectorItemModel;

      for (const filterOperation of filterOperations) {
         const staticValue = new SelectorItemModel();
         staticValue.value = filterOperation.BackingEnumeration;
         staticValue.displayValue = filterOperation.Description;

         if (filterOperation.BackingEnumeration === FilterOperations.EqualTo) {
            equalToOperation = staticValue;
         } else if (filterOperation.BackingEnumeration === FilterOperations.Contains) {
            containsOperation = staticValue;
         } else {
            valueSource.StaticValues.push(staticValue);
         }
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'containsOperation' is used before being ... Remove this comment to see the full error message
      if (containsOperation) {
         valueSource.StaticValues.unshift(containsOperation);
      }

      // @ts-ignore ts-migrate(2454) FIXME: Variable 'equalToOperation' is used before being a... Remove this comment to see the full error message
      if (equalToOperation) {
         valueSource.StaticValues.unshift(equalToOperation);
      }

      const defaultValue = new SelectorItemModel();
      defaultValue.value = null;
      defaultValue.displayValue = "Don't Filter";
      valueSource.StaticValues.push(defaultValue);

      if (this.value) {
         valueSource.DefaultValue = this.value;
      }

      this.valueSource = valueSource;
   }
}
