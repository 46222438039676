import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
   selector: "owa-layout",
   templateUrl: "./layout.component.html",
   styleUrls: ["./layout.component.css"],
})
export class LayoutComponent {
   @Input() headerTitle: string;
   @Input() backgroundColorClass: string = "default";
   @Input() titleIcon: string = "";
   @Input() showIcon: boolean = false;
   @Output() titleIconClicked = new EventEmitter<void>();

   onTitleIconClicked() {
      this.titleIconClicked.emit();
   }
}
