import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

import { SortableListItemModel } from "./sortable-list-item.model";

@Component({
   selector: "lcs-sortable-list",
   templateUrl: "sortable-list.component.html",
   styleUrls: ["./sortable-list.component.scss"],
})
export class SortableListComponent {
   @Input() items: Array<SortableListItemModel>;

   @Input() customHeaderTemplate: TemplateRef<any>;

   @Input() customTemplate: TemplateRef<any>;

   @Input() sortableListItemCustomStyle: {};

   @Input() sortableListCustomStyle: {};

   @Output() itemsSorted = new EventEmitter<Array<SortableListItemModel>>();

   onItemSorted(event: any) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
      this.itemsSorted.emit(this.items);
   }

   moveItemTop(item: SortableListItemModel) {
      if (item) {
         const index = this.items.findIndex((i) => i === item);
         if (index >= 0) {
            moveItemInArray(this.items, index, 0);
            this.itemsSorted.emit(this.items);
         }
      }
   }

   moveItemBottom(item: SortableListItemModel) {
      if (item) {
         const index = this.items.findIndex((i) => i === item);
         if (index >= 0) {
            moveItemInArray(this.items, index, this.items.length - 1);
            this.itemsSorted.emit(this.items);
         }
      }
   }
}
