import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LinkModule } from "@lcs/link/link.module";
import { LcsProgressButtonModule } from "@lcs/progress-button/progress-button.module";

import { AuthenticationService } from "./authentication.service";
import { LoginComponent } from "./login/login.component";
import { LocationModule } from "./rm-location/rm-location.module";

@NgModule({
   imports: [
      CommonModule,
      HttpClientModule,
      FormsModule,
      LocationModule,
      LcsProgressButtonModule,
      LinkModule,
   ],
   declarations: [
      LoginComponent,
   ],
   providers: [
      AuthenticationService,
   ],
   exports: [
      LoginComponent,
   ],
})
export class AuthenticationModule { }
