<owa-tile
   title="estimate"
   class="flex-full full-width"
   *ngIf="ShowEstimate"
>
   <lcs-table [dataSource]="estimates">
      <tr
         lcs-header-row
         *lcsHeaderRowDef="estimateTileColumns; sticky: false"
      ></tr>
      <tr
         lcs-row
         *lcsRowDef="let row; columns: estimateTileColumns"
      ></tr>
      <ng-container
         lcsColumnDef="EstimateNumber"
         [width]="8"
         [priority]="1"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Estimate #</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            <div
               (click)="ShowEstimateDetails(row.EstimateID)"
               class="link"
            >{{ row.EstimateID }}
            </div>
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="CreateDate"
         [width]="8"
         [priority]="3"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Date</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         > {{ row.CreateDate | date: "MM/dd/yyyy"}}
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Property"
         [width]="14"
         [priority]="7"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Property</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
         >
            {{ row.PropertyName }}
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Unit"
         [width]="8"
         [priority]="6"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Unit</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            >
            {{ row.UnitName }}
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Reference"
         [width]="14"
         [priority]="5"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Reference</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            >
            {{ row.Reference }}
         </td>
      </ng-container>
      <ng-container
         lcsColumnDef="Amount"
         [width]="8"
         [priority]="2"
      >
         <th
            lcs-header-cell
            *lcsHeaderCellDef
         >Amount</th>
         <td
            lcs-cell
            *lcsCellDef="let row; let index = index"
            class="numeric"
             >
            {{row.TotalValue | currency: "USD":"symbol":"1.2-2"}}
         </td>
      </ng-container>
   </lcs-table>
</owa-tile>