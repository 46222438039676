<div
   *ngIf="label"
   [attr.title]="labelTooltip"
   class="icon-label"
   [class.clickable-icon]="applyClickableStylesToLabel"
>
   {{ label }}
</div>
<i
   *ngIf="showIcon"
   [ngClass]="iconClass"
   [class.clickable-icon]="applyClickableStylesToIcon"
   [class.disabled]="disabled"
   [attr.title]="iconTooltip"
   [attr.name]="iconName"
   (click)="onClick($event)"
>{{ iconInnerHtml }}</i>