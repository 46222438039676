<lcs-loading-overlay [show]="loading">
   <div class="flex-grid">
      <owa-tile
         [title]="'bill information'"
         class="flex-full"
      >
         <div class="flex-grid">
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Property</div>
                  <div class="tile-value">{{ bill?.PropertyName }}</div>
                  <div class="title">Payee</div>
                  <div class="tile-value">{{
                     $any(bill)?.Owner.OwnerID > 0
                     ? $any(bill)?.Owner.Name
                     : $any(bill)?.Tenant.TenantID > 0
                     ? $any(bill)?.Tenant.Name
                     : $any(bill)?.Vendor.VendorID > 0
                     ? $any(bill)?.Vendor.Name
                     : $any(bill)?.Prospect.ProspectID > 0
                     ? $any(bill)?.Prospect.Name
                     : ""
                     }}</div>
                  <div class="title">Memo</div>
                  <div class="tile-value">{{ bill?.Comment }}</div>
                  <div class="title">Status</div>
                  <div class="tile-value">{{ bill?.IsFullyAllocated ? "Paid" : "Unpaid" }}</div>
               </div>
            </div>
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Amount</div>
                  <div class="tile-value">{{ bill?.Amount | currency }}</div>
                  <div class="title">Terms</div>
                  <div class="tile-value">{{ $any(bill?.TermID) > 0 ? bill.Term.Name : "" }}</div>
                  <div class="title">Invoice Number</div>
                  <div class="tile-value">{{ bill?.Reference }}</div>
                  <div *ngIf="$any(bill)?.CheckNumber.length > 0">
                     <div class="title">Check Number(s)</div>
                     <div class="tile-value">{{ bill?.CheckNumber }}</div>
                  </div>
               </div>
            </div>
            <div class="flex-third">
               <div class="grid-container">
                  <div class="title">Bill Date</div>
                  <div class="tile-value">{{ bill?.TransactionDate | date: "MM/dd/yyyy" }}</div>
                  <div class="title">Post Date</div>
                  <div class="tile-value">{{ bill?.PostDate | date: "MM/dd/yyyy" }}</div>
                  <div class="title">Due Date</div>
                  <div class="tile-value">{{ bill?.DueDate | date: "MM/dd/yyyy" }}</div>
               </div>
            </div>
         </div>
      </owa-tile>
      <owa-tile
         *ngIf="preferences?.showBillAttachments && $any(bill)?.Attachments.length > 0"
         [title]="'Attachments'"
         class="flex-third"
      >
         <div class="grid-container">
            <div
               class="tile-value"
               *ngFor="let attachment of bill?.Attachments"
            >
               <a
                  href="{{ attachment?.DownloadURL }}"
                  target="_blank"
               >
                  <span>{{ attachment.Name }}{{ attachment.Extension }} </span>
               </a>
            </div>
         </div>
      </owa-tile>
      <owa-tile
         *ngIf="bill?.OwnerApprovalStatus === -1 || bill?.OwnerApprovalStatus === 1"
         [title]="'Bill Approval'"
         class="flex-third"
      >
         <div *ngIf="bill.OwnerApprovalStatus === -1">
            <lcs-progress-button
               [raised]="true"
               [(status)]="approveButtonStatus"
               (buttonClick)="approveBill(bill.ID)"
            >Approve this Bill</lcs-progress-button>
         </div>
         <div *ngIf="bill.OwnerApprovalStatus === 1">
            This bill was approved on {{ bill.OwnerApprovalDate | date: "MM/dd/yyyy" }}
         </div>
      </owa-tile>

      <owa-tile
         *ngIf="bill == undefined || $any(bill?.ServiceManagerIssue?.ServiceManagerIssueID) > 0"
         title="linked service issues"
         class="flex-full"
      >
         <owa-serviceissue-list
            [serviceIssueItem]="$any(bill?.ServiceManagerIssue)"
            [showClosedIssues]="$any(preferences?.showClosedIssues)"
            [showOpenIssues]="$any(preferences?.showOpenIssues)"
            [hasCategoriesPermission]="$any(preferences?.hasCategoriesPermission)"
         ></owa-serviceissue-list>
      </owa-tile>

      <owa-tile
         title="details"
         class="flex-full full-width"
         [isViewMore]="true"
         (openComponent)="openBillDetailsMore()"
      >
         <owa-billdetail-list [billDetailItems]="$any( $any(bill?.BillDetails) | slice: 0 - 5 )"></owa-billdetail-list>
      </owa-tile>
   </div>
</lcs-loading-overlay>