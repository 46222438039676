import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { DialogContentComponent } from "@lcs/dialog/dialog-content-component.interface";
import { CurrentDialogService } from "@lcs/dialog/services/current-dialog.service";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { LcsProgressButtonStatus } from "@lcs/progress-button/progress-button-status.enum";
import { EstimateStatus } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/eestimatestatus.enum";
import { EstimateApprovalModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-approval.model";
import { ConstantsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/constants.service";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { Subject } from "rxjs";

import { SystemWebPreferencesSessionService } from "../../session/systemwebpreference-session.service";

@Component({
   selector: "owa-estimate-detail-signature-dialog",
   templateUrl: "estimate-detail-signature.component.html",
   styleUrls: ["./estimate-detail-signature.component.css"],
})
export class EstimateDetailSignatureDialogComponent implements AfterViewInit, DialogContentComponent, OnDestroy {
   configuration: any;
   approvalModel: EstimateApprovalModel;
   progressButtonStatus: LcsProgressButtonStatus = LcsProgressButtonStatus.Unset;
   isSigned: boolean;
   approvalMessage: string;
   confirmationMessage: string;
   isProcessing = false;
   unsubscribe = new Subject<void>();

   constructor(
      private dialog: CurrentDialogService,
      private errorMessageService: ErrorMessageService,
      public estimateService: EstimateService,
      private systemWebPreferenceSessionService: SystemWebPreferencesSessionService,
      public constantService: ConstantsService
   ) {
      this.approvalModel = new EstimateApprovalModel();
      this.isSigned = false;
      this.systemWebPreferenceSessionService.OWASystemWebPreferences.subscribe((pref) => {
         this.approvalMessage =
            pref.filter((f) => f.Name === "ApprovalMessageEstimatesOWA").length !== 0
               ? pref.filter((f) => f.Name === "ApprovalMessageEstimatesOWA")[0].Value
               : "";
         this.confirmationMessage =
            pref.filter((f) => f.Name === "ApprovalConfirmationMessageEstimateOWA").length !== 0
               ? pref.filter((f) => f.Name === "ApprovalConfirmationMessageEstimateOWA")[0].Value
               : "";
      });
   }

   signClick(): void {
      this.isProcessing = true;
      if (this.approvalModel.Signature === undefined || this.approvalModel.Signature.trim() === "") {
         this.errorMessageService.triggerErrorMessage("Please enter a valid signature");
         this.progressButtonStatus = LcsProgressButtonStatus.Error;
         this.isProcessing = false;
         return;
      }

      this.progressButtonStatus = LcsProgressButtonStatus.InProgress;
      this.approvalModel.Status = EstimateStatus.Approved;
      this.approvalModel.EstimateID = this.configuration;

      this.estimateService.ApproveRejectEstimate(this.approvalModel).subscribe(
         (am) => {
            this.progressButtonStatus = LcsProgressButtonStatus.Success;

            if (this.confirmationMessage === "") {
               this.estimateService.Approved();
               setTimeout(() => this.dialog.closeDialog(), 1000);
            } else {
               setTimeout(() => {
                  this.estimateService.Approved();
                  this.isSigned = true;
                  this.dialog.setTitle("Approved");
               }, 1000);
            }
         },
         (error) => {
            this.progressButtonStatus = LcsProgressButtonStatus.Error;
            this.errorMessageService.triggerHttpErrorMessage(error);
            this.isProcessing = false;
         }
      );
   }

   confirmationClick() {
      this.dialog.closeDialog();
   }
   cancelClick() {
      this.dialog.closeDialog();
   }

   ngAfterViewInit() {
      // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      setTimeout(() => document.getElementById("signatureInput").focus());
   }

   ngOnDestroy(): void {
      this.unsubscribe.next();
   }
}
