import { HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { CacheMonitorService } from "@lcs/caching/cache-monitor.service";
import { SessionCacheProvider } from "@lcs/caching/session-cache-provider.interface";
import { ErrorMessageService } from "@lcs/error-message/error-message.service";
import { IndexedDBService } from "@lcs/indexed-db/indexed-db.service";
import { LocalStorageService } from "@lcs/storage/local-storage.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { ExpressActions } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/express-actions.enum";
import { ActionForCurrentUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/action-for-current-user.model";
import { ActionsForCurrentUserModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/actions-for-current-user.model";
import { ExpressActionModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/generated/express-action.model";
import { ExpressActionsService } from "projects/libraries/owa-gateway-sdk/src/lib/services/report-parameter-services/express-actions.service";
import { SessionStatusService } from "projects/libraries/owa-gateway-sdk/src/lib/session/session-status.service";
import { BehaviorSubject, catchError, Observable, of, Subject, switchMap, takeUntil } from "rxjs";

import { AreaActionMappingModel } from "../action-router/area-action-mapping.model";

@Injectable()
export class ActionInformationService implements SessionCacheProvider, OnDestroy {
   cacheKey = "ActionInformationService";

   areaActionMappingCacheIsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(true);

   cachePopulated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   expressActionInformationCache: Map<ExpressActions, ActionForCurrentUserModel> = new Map<
      ExpressActions,
      ActionForCurrentUserModel
   >();

   private areaActionMappingCache: Map<string, AreaActionMappingModel> = new Map<string, AreaActionMappingModel>();

   private unsubscribe = new Subject<void>();

   constructor(
      private expressActionService: ExpressActionsService,
      private errorMessageService: ErrorMessageService,
      private apiService: ApiService,
      private cacheMonitorService: CacheMonitorService,
      private localStorageService: LocalStorageService,
      private indexedDBService: IndexedDBService,
      private sessionStatusService: SessionStatusService
   ) {
      this.cacheMonitorService.registerSessionCacheProvider(this);

      this.cacheMonitorService.loadCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.populateCache();
      });

      this.cacheMonitorService.clearCaches.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
         this.clearCache(true);
      });
   }

   ngOnDestroy(): void {
      this.clearCache(false);
      this.unsubscribe.next();
   }

   public clearCache(sessionExpired: boolean) {
      this.areaActionMappingCacheIsLoaded.next(false);
      this.cachePopulated.next(false);
      this.expressActionInformationCache.clear();
      this.areaActionMappingCache.clear();
      if (sessionExpired) {
         this.localStorageService.removeItem(this.cacheKey);
         this.indexedDBService.deleteByKey(IndexedDBService.IDBLocalStorage, this.cacheKey);
      }
   }

   public populateCache() {
      if (!this.sessionStatusService.currentSessionStatus) {
         throw new Error(`Cache "${this.cacheKey}" cannot populate if session has not been set up.`);
      }

      this.indexedDBService
         .getByKey<ActionsForCurrentUserModel>(IndexedDBService.IDBLocalStorage, this.cacheKey)
         .pipe(
            catchError((error) => {
               console.warn(`IDB: Failed to load "${this.cacheKey}" from cache. -- `, error);
               return of(null); // if error attempting to load from cache, load from server
            }),
            switchMap((storedValue: ActionsForCurrentUserModel | null): Observable<ActionsForCurrentUserModel> => {
               if (storedValue) {
                  const actionsForCurrentUserModel: ActionsForCurrentUserModel = storedValue;
                  return of(actionsForCurrentUserModel); // return cached data
               } else {
                  return this.apiService.directGet(this.expressActionService.getActionsForCurrentUserUrl()).pipe(
                     switchMap(
                        (
                           response: HttpResponse<ActionsForCurrentUserModel>
                        ): Observable<ActionsForCurrentUserModel> => {
                           const actionsForCurrentUserModel: ActionsForCurrentUserModel =
                              response.body ?? new ActionsForCurrentUserModel();
                           if (actionsForCurrentUserModel) {
                              // cache value returned from the server
                              this.indexedDBService
                                 .updateByKey(
                                    IndexedDBService.IDBLocalStorage,
                                    actionsForCurrentUserModel,
                                    this.cacheKey
                                 )
                                 .pipe(takeUntil(this.unsubscribe))
                                 .subscribe({
                                    next: (result: ActionsForCurrentUserModel): void => {
                                       this.localStorageService.setItemToValueHashCode(
                                          this.cacheKey,
                                          actionsForCurrentUserModel
                                       );
                                    },
                                 });
                           }
                           return of(actionsForCurrentUserModel); // return data retrieved from server
                        }
                     )
                  );
               }
            }),
            takeUntil(this.unsubscribe)
         )
         .subscribe({
            next: (result: ActionsForCurrentUserModel): void => {
               this.populateExpressActionInformationCache(result.ExpressActions);
               this.populateAreaActionMappingCache(result.AreaActionMappings);
               this.cachePopulated.next(true);
            },
            error: (error: any): void => {
               this.errorMessageService.triggerHttpErrorMessage(error);
               this.cachePopulated.next(false);
            },
         });
   }

   public getAreaActionMappingForArea(area: string): AreaActionMappingModel | null {
      return this.areaActionMappingCache.get(area.toLowerCase()) ?? null;
   }

   public getExpressActionInformationForExpressAction(expressAction: ExpressActions | null): ExpressActionModel | null {
      if (expressAction === null) {
         return null;
      }
      return this.expressActionInformationCache.get(expressAction) ?? null;
   }

   private populateAreaActionMappingCache(areaActionMappings: any) {
      for (const key of Object.keys(areaActionMappings)) {
         this.areaActionMappingCache.set(key, areaActionMappings[key]);
      }
      this.areaActionMappingCacheIsLoaded.next(true);
   }

   private populateExpressActionInformationCache(expressActions: Array<ActionForCurrentUserModel>) {
      for (const expressAction of expressActions) {
         this.expressActionInformationCache.set(expressAction.ExpressActionID, expressAction);
      }
   }
}
