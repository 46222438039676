import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService } from "@lcs/core/globals.service";
import { FilterOperations } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/filter-operations.enum";
import { ReportParameter } from "projects/libraries/owa-gateway-sdk/src/lib/enumerations/generated/report-parameter.enum";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { Subject, takeUntil } from "rxjs";

import { ReportParameterValueModel } from "../models/report-parameter-value.model";
import { ReportReportParameterComponentModel } from "../models/report-report-parameter-component.model";
import { ReportReportParameterViewModel } from "../models/report-report-parameter.viewmodel";
import { ReportParametersService } from "../report-parameters.service";

@Component({
   selector: "lcs-job-report-parameter",
   templateUrl: "job-report-parameter.component.html",
})
export class JobReportParameterComponent implements OnInit, OnDestroy {
   @Input() name: string;

   @Input() set reportReportParameterComponents(values: Array<ReportReportParameterComponentModel>) {
      const groupedParameters = values.filter((v) => v.GroupedReportReportParameters);
      const individualParameters = values.filter((v) => v.ReportReportParameter);
      if (groupedParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter)
            .concat(
               values
                  .filter((v) => v.GroupedReportReportParameters)
                  .map((v) => v.GroupedReportReportParameters)
                  .reduce(function (a, b) {
                     return a.concat(b);
                  }, new Array<ReportReportParameterViewModel>())
            );
      } else if (individualParameters.length > 0) {
         this.reportReportParameters = values
            .filter((v) => v.ReportReportParameter)
            .map((v) => v.ReportReportParameter);
      }
   }

   @Input() disabled: boolean;

   @Input() hasAsk: boolean;

   jobReportParameter: ReportReportParameterViewModel;

   includeInactiveJobsReportParameter: ReportReportParameterViewModel;

   jobsFilters: Array<FilterOption>;

   private reportReportParameters: Array<ReportReportParameterViewModel>;

   private unsubscribe = new Subject<void>();

   constructor(private reportParametersService: ReportParametersService) {}

   ngOnInit() {
      this.initializeParameters();
      this.initializeReportParameterValues();
   }

   reportParameterValuesUpdated(updatedValue: ReportParameterValueModel) {
      if (updatedValue.reportParameter === ReportParameter.IncludeInactiveJobs) {
         this.includeInactiveJobsChecked(updatedValue.value);
      }
   }

   includeInactiveJobsChecked(value: boolean) {
      const newFilters = new Array<FilterOption>();
      const isChecked = GlobalsService.convertStringToBoolean(value);
      if (!isChecked) {
         newFilters.push(new FilterOption("IsActive", FilterOperations.EqualTo, [true]));
      }
      this.jobsFilters = newFilters;
   }

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   askChanged(isAsk: boolean) {
      this.jobReportParameter.IsAsk = isAsk;
      this.reportParametersService.updateReportParameterAsk(this.jobReportParameter.ReportParameterID, isAsk);
   }

   private initializeParameters() {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.jobReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.JobID || p.ReportParameterID === ReportParameter.JobIDs
      );
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ReportReportParameterViewModel | undefined' ... Remove this comment to see the full error message
      this.includeInactiveJobsReportParameter = this.reportReportParameters.find(
         (p) => p.ReportParameterID === ReportParameter.IncludeInactiveJobs
      );
   }

   private initializeReportParameterValues() {
      this.reportParametersService.reportParameterUpdated
         .pipe(takeUntil(this.unsubscribe))
         .subscribe((updatedValue) => this.reportParameterValuesUpdated(updatedValue));
   }
}
