import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportParametersModule } from "@lcs/reports/report-parameters/report-parameters.module";
import { ReportRoutingService } from "@lcs/reports/report-routing.service";
import { SideBarMenuModule } from "@lcs/side-bar-menu/side-bar-menu.module";
import { CollapsibleContextBarModule } from "projects/owa/src/app/controls/collapsible-context-bar/collapsible-context-bar.module";

import { PdfViewerModule } from "../pdf-viewer/pdf-viewer.module";
import { RMReportHubComponent } from "./rm-report-hub.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      CollapsibleContextBarModule,
      SideBarMenuModule,
      ReportParametersModule,
      PdfViewerModule,
      ScrollingModule,
   ],
   declarations: [RMReportHubComponent],
   exports: [RMReportHubComponent],
   providers: [ReportRoutingService],
})
export class RMReportHubModule {}
