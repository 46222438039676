import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { CheckboxModule } from "@lcs/checkbox/checkbox.module";
import { LoadingOverlayModule } from "@lcs/loading-overlay/loading-overlay.module";
import { SelectModule } from "@lcs/select/select.module";
import { SingleLineMultiSelectModule } from "@lcs/single-line-multi-select/single-line-multi-select.module";

import { PropertyOwnerSelectorComponent } from "./property-owner-selector.component";

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      LoadingOverlayModule,
      MatTabsModule,
      CheckboxModule,
      SingleLineMultiSelectModule,
      SelectModule,
   ],
   declarations: [PropertyOwnerSelectorComponent],
   exports: [PropertyOwnerSelectorComponent],
})
export class PropertyOwnerSelectorModule {}
