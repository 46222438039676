<lcs-loading-overlay [show]="loading">
   <div class="flex-grid">
      <owa-tile
         [title]="'service issue information'"
         class="flex-full"
      >
         <div class="flex-grid">
            <div class="flex-fourth">
               <div class="grid-container">
                  <div class="title">Issue</div>
                  <div class="tile-value">{{issue?.ServiceManagerIssueID}}</div>
                  <div class="title">Category</div>
                  <div class="tile-value">{{issue?.Category?.Name}}</div>
                  <div class="title">Created By</div>
                  <div class="tile-value">{{issue?.CreateUser?.Name}}</div>
               </div>
            </div>
            <div class="flex-fourth">
               <div class="grid-container">
                  <div class="title">Modified By</div>
                  <div class="tile-value">{{issue?.UpdateUser?.Name}}</div>
                  <div class="title">Open Date</div>
                  <div class="tile-value">{{issue?.AssignedOpenDate | date: 'MM/dd/yyyy'}}</div>
                  <div class="title">Status</div>
                  <div class="tile-value">{{issue?.Status?.Name}}</div>
               </div>
            </div>
            <div class="flex-fourth">
               <div class="grid-container">
                  <div class="title">Created</div>
                  <div class="tile-value">{{issue?.CreateDate | date: 'MM/dd/yyyy'}}</div>
                  <div class="title">Modified</div>
                  <div class="tile-value">{{issue?.UpdateDate | date: 'MM/dd/yyyy'}}</div>
                  <div class="title">Closed</div>
                  <div class="tile-value">{{issue?.IsClosed ? 'Yes' : 'No'}}</div>
               </div>
            </div>
            <div class="flex-fourth">
               <div class="grid-container">
                  <div class="title">Closed On</div>
                  <div class="tile-value">{{issue?.CloseDate !== defaultDate ? (issue?.CloseDate | date:
                     'MM/dd/yyyy') : ''}} </div>
                  <div
                     *ngIf="!preferences?.excludeVendorInfo"
                     class="title"
                  >Vendor Name</div>
                  <div *ngIf="!preferences?.excludeVendorInfo">
                     <div
                        *ngIf="$any(issue?.PayeeAccount?.VendorID) > 0; else noVendor"
                        class="tile-value link"
                        (click)="showVendorDialog()"
                     >{{issue?.PayeeAccount?.Name}}
                     </div>
                     <ng-template #noVendor>
                        <div class="tile-value">{{''}} </div>
                     </ng-template>
                  </div>
                  <div class="title">Scheduled</div>
                  <div class="tile-value">{{issue?.ScheduledDate !== defaultDate ? (issue?.ScheduledDate | date:
                     'MM/dd/yyyy') : ''}} </div>
               </div>
            </div>
         </div>
      </owa-tile>

      <owa-tile
         [title]="'service issue detail'"
         class="flex-half"
      >
         <div class="grid-container">
            <div class="title">Issue</div>
            <div class="tile-value">{{issue?.Title}}</div>
            <div class="title">Description</div>
            <div class="tile-value">{{issue?.Description}}</div>
            <div class="title">Resolution</div>
            <div class="tile-value">{{issue?.Resolution}}</div>
         </div>
      </owa-tile>

      <owa-tile
         [title]="'links'"
         class="flex-half"
      >
         <div class="grid-container address">
            <div
               *ngIf="!preferences?.excludeTenantInfo"
               class=title
            >Tenants/Prospects</div>
            <div *ngIf="!preferences?.excludeTenantInfo">
               <div
                  class="tile-value"
                  *ngFor="let tenant of issue?.Tenants"
               >
                  <div class="address-container">
                     <span
                        class="link"
                        *ngIf="tenant.TenantID > 0"
                        (click)="showTenantDialog(tenant.TenantID)"
                     >{{tenant.Name}} </span>
                     <span *ngFor="let address of tenant?.Addresses">
                        <span *ngIf="address.IsPrimary">
                           <span>{{address.Address}}</span>
                        </span>
                     </span>
                  </div>
               </div>
            </div>
            <div class="title">Units</div>
            <div>
               <div
                  class="tile-value address"
                  *ngFor="let unit of issue?.Units"
               >
                  <div class="address-container">
                     <span>{{unit.Name}} </span>
                     <span *ngFor="let address of unit?.Addresses">
                        <span *ngIf="address.IsPrimary">
                           <span>{{address.Address}}</span>
                        </span>
                     </span>
                  </div>
               </div>
            </div>
            <div class="title">Properties</div>
            <div>
               <div
                  class="tile-value address"
                  *ngFor="let prop of issue?.Properties"
               >
                  <div class="address-container">
                     <span>{{prop.Name}} </span>
                     <span *ngFor="let address of prop?.Addresses">
                        <span *ngIf="address.IsPrimary">
                           <span>{{address.Address}}</span>
                        </span>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </owa-tile>

      <owa-tile
         title="work orders"
         class="flex-full full-width"
         *ngIf="preferences?.showWorkOrders"
         [isViewMore]="true"
         (openComponent)="openWorkOrderViewMore()"
      >
         <owa-issue-workorder-list
            [workOrderItems]="$any( $any(issue?.WorkOrders) | slice:0:5 )"
            [showBills]="$any(preferences?.showBills)"
         ></owa-issue-workorder-list>
      </owa-tile>

      <owa-tile
         #tile
         title="check list"
         class="flex-half half-width"
         [isViewMore]="true"
         (openComponent)="openChecklistViewMore()"
      >
         <owa-issue-checklist-list [checkListItems]="$any( $any(issue?.CheckListItems) | slice:0:5 )">
         </owa-issue-checklist-list>
      </owa-tile>

      <owa-tile
         title="history"
         class="flex-half half-width"
         *ngIf="preferences?.showHistory"
         [isViewMore]="true"
         (openComponent)="openHistoryViewMore()"
      >
         <owa-history-notes-as-sublist [historyItems]="$any( $any(issue?.History) | slice:0:5 )">
         </owa-history-notes-as-sublist>
      </owa-tile>

      <owa-issue-estimate-list class="flex-half half-width"></owa-issue-estimate-list>
   </div>
</lcs-loading-overlay>