import { Component, OnInit } from "@angular/core";
import { EstimateViewModel } from "projects/libraries/owa-gateway-sdk/src/lib/models/estimate-view.model";
import { EstimateService } from "projects/libraries/owa-gateway-sdk/src/lib/services/estimate.service";
import { Observable } from "rxjs";

@Component({
   selector: "owa-dashboard-estimates",
   templateUrl: "./dashboard-tile-estimates.component.html",
   providers: [EstimateService],
})
export class DashboardEstimatesComponent implements OnInit {
   observableEstimates: Observable<Array<EstimateViewModel>>;
   public estimates: Array<EstimateViewModel> = new Array<EstimateViewModel>();
   public newEstimates: Array<EstimateViewModel> = new Array<EstimateViewModel>();
   notificationCount: number;
   public isNew: boolean;
   estimateTileColumns: string[] = ["CreateDate", "EstimateNumber", "Reference", "TotalValue", "NewRow"];
   constructor(private estimateService: EstimateService) {}

   ngOnInit() {
      this.observableEstimates = this.estimateService.getEstimatesForDashboard();
      this.observableEstimates.subscribe((res) => {
         this.estimates = res;
         if (this.estimates != null && this.estimates.length > 0) {
            const newEstimates = this.estimates.filter((estimate) => estimate.IsNew);
            this.notificationCount = newEstimates != null ? newEstimates.length : 0;
         }
      });
   }
}
