import { HttpResponse } from "@angular/common/http";
import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { FilterOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/filter-option.model";
import { OrderingOption } from "projects/libraries/owa-gateway-sdk/src/lib/models/ordering-option.model";
import { Observable } from "rxjs";

import { EstimateApprovalModel } from "../models/estimate-approval.model";
import { EstimateViewModel } from "../models/estimate-view.model";

@Directive()
@Injectable({ providedIn: "root" })
export class EstimateService {
   readonly endpoint = "Estimates";

   @Output() approved: EventEmitter<boolean> = new EventEmitter();

   constructor(private apiService: ApiService) {}

   getCollectionUrl(
      id: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         filters,
         null,
         null,
         embeds,
         orderingOptions,
         fields,
         getOptions,
         pageSize,
         pageNumber,
         additionalParameters
      );
   }

   getCollection(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<Array<EstimateViewModel>> {
      return this.apiService.getCollection(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getCollectionResponse(
      id?: number,
      filters?: Array<FilterOption>,
      embeds?: Array<string>,
      orderingOptions?: Array<OrderingOption>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      pageSize?: number,
      pageNumber?: number,
      additionalParameters?: Array<string>
   ): Observable<HttpResponse<any>> {
      return this.apiService.getCollectionResponse(
         this.getCollectionUrl(
            // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            id,
            filters,
            embeds,
            orderingOptions,
            fields,
            getOptions,
            pageSize,
            pageNumber,
            additionalParameters
         )
      );
   }

   getUrl(
      id: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): string {
      const endpoint = this.endpoint;
      return this.apiService.getUrl(
         endpoint,
         id,
         null,
         null,
         null,
         null,
         embeds,
         null,
         fields,
         getOptions,
         null,
         null,
         additionalParameters
      );
   }

   get(
      id?: number,
      embeds?: Array<string>,
      fields?: Array<string>,
      getOptions?: Array<string>,
      additionalParameters?: Array<string>
   ): Observable<EstimateViewModel> {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return this.apiService.getSingle(this.getUrl(id, embeds, fields, getOptions, additionalParameters));
   }

   getEstimatesForDashboard(): Observable<Array<EstimateViewModel>> {
      const endpoint = this.endpoint + "/GetEstimatesForDashboard";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }

   getEstimatesToApprove(): Observable<Array<EstimateViewModel>> {
      const endpoint = this.endpoint + "/GetEstimatesToApprove";
      return this.apiService.getCollection(this.apiService.getUrl(endpoint));
   }
   getEstimateForServiceIssue(id: number): Observable<any> {
      const endpoint = this.endpoint + "/GetEstimateForServiceIssue/";
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      return this.apiService.getCollection(this.apiService.getUrl(endpoint), null, null, null, null, null, null, [
         "serviceIssueID=" + id.toString(),
      ]);
   }
   ApproveRejectEstimate(estimateApproval: EstimateApprovalModel): Observable<HttpResponse<any>> {
      return this.apiService.post(this.endpoint + "/ApproveRejectEstimate", estimateApproval);
   }

   Approved(): void {
      this.approved.emit(true);
   }
}
