<div class="individual-report-parameter-option">
   <lcs-dynamic-selector-report-parameter
      [name]="name + '-selector'"
      [parameter]="camDivReportParameter"
      [hasAsk]="hasAsk"
   ></lcs-dynamic-selector-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-checkbox-report-parameter
      *ngIf="selectedCamDivisionMethod === camDivisionMethods.CRESetup"
      [name]="name + '-pro-rata'"
      [parameter]="proRataShareReportParameter"
   ></lcs-checkbox-report-parameter>
</div>
<div class="individual-report-parameter-option">
   <lcs-checkbox-report-parameter
      *ngIf="selectedCamDivisionMethod === camDivisionMethods.CRESetup"
      [name]="name + '-admin-fees'"
      [parameter]="adminFeesReportParameter"
   ></lcs-checkbox-report-parameter>
</div>
<lcs-dynamic-selector-report-parameter
   *ngIf="selectedCamDivisionMethod === camDivisionMethods.UDFField"
   [name]="name + '-udfs'"
   [parameter]="tenantUDFReportParameter"
></lcs-dynamic-selector-report-parameter>