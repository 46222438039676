<div *ngIf="!isRelative">
   <div class="report-parameter-label">
      <label>
         {{ label }}
      </label>
      <div class="report-parameter-link-wrapper">
         <lcs-link
            [text]="'Date'"
            (clicked)="isRunByPeriodChanged(false)"
            [ngClass]="{'secondary-parameter-label':isRunByPeriod}"
         ></lcs-link>
         <lcs-link
            [text]="'Period'"
            (clicked)="isRunByPeriodChanged(true)"
            [ngClass]="{'secondary-parameter-label':!isRunByPeriod}"
         ></lcs-link>
      </div>
   </div>
   <lcs-date-range-picker
      *ngIf="!isRunByPeriod"
      [name]="name"
      [disabled]="disabled"
      [dateStartFormat]="dateStartFormat"
      [dateEndFormat]="dateEndFormat"
      [(ngModel)]="dateRange"
      (ngModelChange)="dateRangeChanged($event)"
   ></lcs-date-range-picker>

   <div
      class="tab-wrapper"
      *ngIf="isRunByPeriod"
   >
      <div class="single-tab-wrapper">
         <label>Series</label>
         <lcs-select
            lcsStaticSetSelector
            #seriesSelector
            [name]="'accounting-series-selector' + startPeriodParameter.ReportParameterID"
            [disabled]="disabled"
            [ngModel]="selectedSeriesID"
            [items]="seriesData"
            (ngModelChange)="onAccountingSeriesChange($event)"
         >
         </lcs-select>
         <div class="radio-button-wrapper flex-space-between">
            <lcs-radio-button
               [inputId]="'SinglePeriodRadioButton' + startPeriodParameter.ReportParameterID"
               name="SingleOrMultiplePeriod"
               label="Single Period"
               [(ngModel)]="isSinglePeriodSelected"
               (ngModelChange)="singlePeriodSelected($event)"
               [value]="true"
            ></lcs-radio-button>
            <lcs-radio-button
               [inputId]="'MultiplePeriodRadioButton' + startPeriodParameter.ReportParameterID"
               name="SingleOrMultiplePeriod"
               label="Multiple Period"
               [(ngModel)]="isMultiplePeriodSelected"
               (ngModelChange)="multiplePeriodSelected($event)"
               [value]="true"
            ></lcs-radio-button>
         </div>
         <div>
            <label>{{isSinglePeriodSelected ? "Year" : "Start Year"}}</label>
            <lcs-select
               lcsStaticSetSelector
               #startYearSelector
               [name]="'accounting-start-year-selector'+ startPeriodParameter.ReportParameterID"
               [disabled]="disabled"
               [ngModel]="selectedStartYearID"
               [items]="yearData"
               (ngModelChange)="onAccountingYearStartChange($event)"
            >
            </lcs-select>
         </div>
         <div>
            <label>{{isSinglePeriodSelected ? "Period" : "Start Period"}}</label>
            <lcs-select
               lcsStaticSetSelector
               #startPeriodSelector
               [name]="'accounting-start-period-selector'+ startPeriodParameter.ReportParameterID"
               [disabled]="disabled"
               [ngModel]="selectedStartPeriodID"
               [items]="startPeriodData"
               (ngModelChange)="onAccountingPeriodStartChange($event)"
            >
            </lcs-select>
         </div>
         <div [ngStyle]="{'display': isMultiplePeriodSelected ? 'contents' : 'none'}">
            <div>
               <label>End Year</label>
               <lcs-select
                  lcsStaticSetSelector
                  #endYearSelector
                  [name]="'accounting-end-year-selector'+ endPeriodParameter.ReportParameterID"
                  [disabled]="disabled"
                  [ngModel]="selectedEndYearID"
                  [items]="endYearData"
                  (ngModelChange)="onAccountingYearEndChange($event)"
               >
               </lcs-select>
            </div>
            <div>
               <label>End Period</label>
               <lcs-select
                  lcsStaticSetSelector
                  #endPeriodSelector
                  [name]="'accounting-end-period-selector'+ endPeriodParameter.ReportParameterID"
                  [disabled]="disabled"
                  [ngModel]="selectedEndPeriodID"
                  [items]="endPeriodData"
                  (ngModelChange)="onAccountingPeriodEndChange($event)"
               >
               </lcs-select>
            </div>
         </div>
      </div>
   </div>

</div>
<div
   class="memorized-date-range-report-parameter-start"
   *ngIf="isRelative"
>

   <lcs-date-and-accounting-period-report-parameter
      [name]="name + '-start-date'"
      [displayName]="'Start'"
      [showRelativeOption]="isRelative"
      [reportReportParameterComponents]="reportReportParameterComponents"
      [asOfDateReportParameterId]="startDateParameter.ReportParameterID"
      [accountingPeriodReportParameterId]="startPeriodParameter.ReportParameterID"
      [runByPeriodReportParameterId]="isRunByPeriodParameter.ReportParameterID"
      [hasAsk]="hasAsk"
   ></lcs-date-and-accounting-period-report-parameter>

</div>
<div
   class="memorized-date-range-report-parameter-end"
   *ngIf="isRelative"
>
   <lcs-date-and-accounting-period-report-parameter
      [name]="name + '-end-date'"
      [displayName]="'End'"
      [showRelativeOption]="isRelative"
      [reportReportParameterComponents]="reportReportParameterComponents"
      [asOfDateReportParameterId]="endDateParameter.ReportParameterID"
      [accountingPeriodReportParameterId]="endPeriodParameter.ReportParameterID"
      [runByPeriodReportParameterId]="isRunByPeriodParameter.ReportParameterID"
      [hasAsk]="hasAsk"
   ></lcs-date-and-accounting-period-report-parameter>
</div>